<msc-common-modal size="30vw" #modal [title]="(step === 'normal' ? 'words.reset-password' : 'words.reset-password.success') |
translate">
    <ng-template>
        <div>
            <p><strong class="mr-2">{{ 'auth.identifier' | translate }}:</strong> <span>
                    <span class="email" *ngIf="user.account?.email || user.externalId; else noUserEmail">{{
                        user.account.email ?
                        user.account.email : (company.prefix + '-' + (user.externalId || '')) }}</span>
                    <ng-template #noUserEmail>
                        <span>{{user.account?.name}} <span class="text-gray-400"><i>({{ 'words.guest' | translate
                                    }})</i></span>
                        </span>
                    </ng-template>
                </span>
            </p>
        </div>
        <div>
            <ng-container *ngIf="step === 'normal'; else successTemplate">
                <div>
                    <msc-input [label]="'words.password' | translate" type="password" [(ngModel)]="data.password"
                        [debounce]="500" (onDebounce)="trimPassword()"></msc-input>
                    <p class="color--red text-error" *ngIf="data.password === data.old_password">{{
                        "error.password.same" | translate }}</p>
                </div>

                <p class="color--grey text-error" [ngClass]="{ 'active' : error.short, 'valid': valid.short }">
                    <i *ngIf="error.short" class="icon icon-close"></i>
                    <i *ngIf="valid.short" class="icon icon-checkmark-1"></i>
                    {{ "error.password.too-short" | translate }}
                </p>
                <p class="color--grey text-error" [ngClass]="{ 'active' : error.number, 'valid': valid.number }">
                    <i *ngIf="error.number" class="icon icon-close"></i>
                    <i *ngIf="valid.number" class="icon icon-checkmark-1"></i>
                    {{ "error.password.number" | translate }}
                </p>
                <p class="color--grey text-error" [ngClass]="{ 'active' : error.uppercase, 'valid': valid.uppercase }">
                    <i *ngIf="error.uppercase" class="icon icon-close"></i>
                    <i *ngIf="valid.uppercase" class="icon icon-checkmark-1"></i>
                    {{ "error.password.uppercase" | translate }}
                </p>
                <p class="color--grey text-error" [ngClass]="{ 'active' : error.lowercase, 'valid': valid.lowercase }">
                    <i *ngIf="error.lowercase" class="icon icon-close"></i>
                    <i *ngIf="valid.lowercase" class="icon icon-checkmark-1"></i>
                    {{ "error.password.lowercase" | translate }}
                </p>
            </ng-container>
            <ng-template #successTemplate>
                <div class="flex flex-col gap-4">
                    <div>
                        <p class="mb-4">1. {{ 'user.password-transmit.v2' | translate }}</p>
                        <div class="flex items-center space-x-2">
                            <msc-input class="w-full" name="input" id="input" type="password"
                                [(ngModel)]="data.password" disabled>
                            </msc-input>
                            <msc-button class="msc-button--outline" (click)="clipboard()">
                                {{ 'words.copy-clipboard' | translate }}
                            </msc-button>
                        </div>
                    </div>
                    <div>
                        <p class="mb-4">{{ 'user.password-transmit.v2' | translate }}</p>
                        <div class="flex items-center justify-between">
                            <p>2. {{ 'user.password.send-mail' | translate }}</p>

                            <msc-button class="msc-button--flat" (click)="send()">
                                {{ 'words.send' | translate }}
                            </msc-button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="flex items-center justify-end space-x-2 mt-8">
            <msc-common2-checkbox class="checkbox-label--wrap text-xs" *ngIf="step === 'normal'"  [value]="data.forced_reset" [label]="'user.need-password-change' | translate" (changeEvent)="data.forced_reset = !data.forced_reset"></msc-common2-checkbox>
            <div class="flex space-x-2 justify-self-end">
                <msc-button (click)="modal.onClose()">
                    {{ 'words.cancel' | translate }}
                </msc-button>
    
                <msc-button *ngIf="step === 'normal'" [loading]="loading" class="msc-button--primary" palette="primary"
                    [disabled]="data.password.length === 0 || error.characters || error.number || error.lowercase || error.uppercase || error.short || error.confirm || loading"
                    (click)="reset()">
                    {{ 'words.confirm' | translate }}
                </msc-button>
            </div>
        </div>
    </ng-template>
</msc-common-modal>