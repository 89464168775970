import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmitterService, TranslateService } from '@services';

@Component({
    selector: 'msc-manage-team-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.scss']
})

export class ManageTeamWatchComponent {
    public navData: Array<any>;

    constructor(
        private route: ActivatedRoute,
        private emitterService: EmitterService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.navData = this.getNav();
    }

    /**
     * Get the nav data of the page
     */
    getNav(): Array<any> {
        return [
            {
                label: this.translateService.instant(`words.members`),
                value: {
                    routerLink: 'list'
                }
            },
            {
                label: this.translateService.instant(`words.conversation`),
                value: {
                    routerLink: 'timeline',
                }
            },
            {
                label: this.translateService.instant(`words.settings`),
                value: {
                    routerLink: 'settings',
                }
            },
        ]
    }
}
