<msc-common-aside #aside [title]="'integrations.add-playlist' | translate" (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <ul class="flex justify-center space-x-4" *ngIf="manage">
                <li class="active tab__item" [ngClass]="{'active': currentTab === 'playlist'}"
                    (click)="changeTab('playlist')">
                    <a class="tab__link"> {{ 'integrations.playlist' | translate }} </a>
                </li>

                <li class="tab__item" [ngClass]="{'active': currentTab === 'access'}" (click)="changeTab('access')">
                    <a class="tab__link"> {{ 'words.access' | translate }} </a>
                </li>
            </ul>

            <div class="flex flex-col space-y-4" *ngIf="currentTab === 'playlist'">
                <msc-input label="URL" type="text" [(ngModel)]="data.url" [disabled]="playlist"></msc-input>

                <msc-input [label]="'words.name' | translate" type="text" [(ngModel)]="data.name"></msc-input>

                <div class="slide__input">
                    <div class="input">
                        <label class="input__label">{{ 'words.language' | translate }}</label>

                        <div class="select">
                            <select name="select" [(ngModel)]="data.lang">
                                <option value="multi">{{ 'words.multilingual' | translate }}</option>
                                <option value="fr">{{ 'words.french' | translate}}</option>
                                <option value="en">{{ 'words.english' | translate}}</option>
                                <option value="nl">{{ 'words.dutch' | translate }}</option>
                                <option value="it">{{ 'words.italian' | translate }}</option>
                                <option value="de">{{ 'words.german' | translate }}</option>
                                <option value="es">{{ 'words.spanish' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <msc-common-spinner *ngIf="loading || playlist && videos.length === 0"></msc-common-spinner>

                <div class="flex flex-col space-y-2" *ngIf="videos.length > 0">
                    <p>{{ 'integrations.playlist.video-list' | translate }}</p>

                    <div class="flex flex-col space-y-1 p-4">
                        <span class="border-b" *ngFor="let video of videos">{{ video.name }}</span>
                    </div>
                </div>
            </div>

            <div class="slide__form" *ngIf="currentTab === 'access'">
                <div class="flex flex-col space-y-2 my-2">
                    <msc-common2-radio groupName="allCampsAccess" [label]="'integrations.playlist.all' | translate"
                        [(ngModel)]="data.allCamps" [valueName]="true"></msc-common2-radio>
                    <msc-common2-radio groupName="allCampsAccess" [label]="'integrations.playlist.camp' | translate"
                        [(ngModel)]="data.allCamps" [valueName]="false"></msc-common2-radio>
                </div>

                <div class="slide__camp" *ngIf="!data.allCamps">
                    <div class="slide__search">
                        <msc-common-search [placeholder]="'common.search' | translate"
                            (searchEvent)="search($event.value)">
                        </msc-common-search>
                    </div>

                    <div class="list-camp slide__list" data-simplebar>
                        <p class="list-camp__title">{{ 'access.learn.camps-list' | translate }}</p>

                        <div class="list-camp__list" *ngIf="camps.length > 0">
                            <div class="card-camp card-camp--flat card-camp--no-effect" *ngFor="let camp of camps">
                                <div class="card-camp__content">
                                    <a class="content__action" (click)="removeCamp(camp); stopPropagation($event)">
                                        <i class="icon icon-minus"></i>
                                    </a>

                                    <div class="content__logo">
                                        <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                                    </div>

                                    <div class="content__data">
                                        <p>{{ camp.name | truncate: 30 }}</p>

                                        <div class="data__pictos">
                                            <div class="data__members">
                                                <i class="icon icon-people"></i>
                                                <p>{{ camp.usersCount }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list-camp__list">
                            <div class="card-camp card-camp--flat card-camp--no-effect"
                                *ngFor="let camp of availableCamps">
                                <div class="card-camp__content">
                                    <a class="content__action content__action--blue bg-secondary-500"
                                        (click)="addCamp(camp); stopPropagation($event)">
                                        <i class="icon icon-plus"></i>
                                    </a>

                                    <div class="content__logo">
                                        <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                                    </div>

                                    <div class="content__data">
                                        <p>{{ camp.name | truncate: 30 }}</p>

                                        <div class="data__pictos">
                                            <div class="data__members">
                                                <i class="icon icon-people"></i>
                                                <p>{{ camp.usersCount }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</msc-common-aside>