<div class="table-headline__sort" *ngIf="!label">
    <i class="icon icon-arrow-up-1" (click)="sort('asc')" [ngClass]="{'active': type === 'asc'}"></i>

    <i class="icon icon-arrow-down-1" (click)="sort('desc')" [ngClass]="{'active': type === 'desc'}"></i>
</div>

<div class="dynamic-table__head__sort" *ngIf="label">
    <div class="head-sort">
        <i class="icon icon-arrow-up-1" (click)="sort('asc')" [ngClass]="{'active': type === 'asc'}"></i>
        <i class="icon icon-arrow-down-1" (click)="sort('desc')" [ngClass]="{'active': type === 'desc'}"></i>
    </div>

    <div class="label-sort" [ngClass]="{'active': type}">{{ label }}</div>
</div>
