<button element #origin="element" type="button" [ngClass]="{ 'is-open': isOpen }"
    (click)="overlay.open(origin.elementRef); isOpen=true">
    <span>{{ 'test' }}</span>
    <i class="icon icon-arrow-ios-down"></i>
</button>

<msc-common-overlay #overlay [root]="overlayRoot" direction="bottom-start" (onClose)="isOpen=false">
    <ng-template>
        <div infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="0"
            (scrolled)="(!isLoading && infiniteLoad) ? loadData(query) : null" [ngStyle]="{'width': width + 'px'}">
            <ng-container *ngIf="hasSearch">
                <msc-common-search class="shadow-none bg-brandedDarkGrey-100" [data]="!infiniteLoad ? items : undefined"
                    [key]="'label'" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)">
                </msc-common-search>
            </ng-container>
            <msc-common-list2 class="w-full" [type]="type === 'single' ? 'checkmark' : 'checkbox'" [root]="true"
                [selectionActive]="true" [data]="itemsClone" [itemsModel]="model" [selectionModel]="selectionModel"
                [expandWithOverlay]="true" (selectionModelChange)="handleSelection($event)"
                (treeUpdate)="updateOverlayRoot($event)"></msc-common-list2>
            <ng-container *ngIf="isLoading">
                <div class="loader-container">
                    <msc-common-spinner></msc-common-spinner>
                </div>
            </ng-container>
        </div>
    </ng-template>
</msc-common-overlay>