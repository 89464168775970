import { Component, ChangeDetectionStrategy } from '@angular/core';
//
import { BehaviorSubject, interval } from 'rxjs';
import { tap, take } from 'rxjs/operators';
//
import { CommonToastOptions, CommonToastService } from '@common2/services/toast.service';
//
export type CommonToastType = 'error' | 'info' | 'success' | 'warning';
export interface CommonToastDatum {
    id: number;
    message: string;
    type: CommonToastType;
    opts?: CommonToastOptions;
}
//
const COMMON_TOAST_INTERVAL_TIME_MILISECONDS: number = 6000;

@Component({
    selector: 'msc-common-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonToastComponent {
    public toasts$: BehaviorSubject<Array<CommonToastDatum>> = new BehaviorSubject<Array<CommonToastDatum>>([]);

    constructor(
        private readonly toastService: CommonToastService,
    ) { }

    ngOnInit(): void {
        this.setObs();
    }

    /**
     * Set the component observables
     */
    setObs(): void {
        this.setObsPush();
    }

    /**
     * Set the component observable for push
     */
    setObsPush(): void {
        this.toastService.getSubject()
            .pipe(
                tap((toast: CommonToastDatum) => this.toasts$.next([...this.toasts$.getValue(), toast])),
                tap(() => this.setObsInterval()),
            )
            .subscribe();
    }

    /**
     * Set the component observable for interval (when to dismiss toast)
     */
    setObsInterval(): void {
        interval(COMMON_TOAST_INTERVAL_TIME_MILISECONDS * this.toasts$.getValue().length)
            .pipe(
                take(1),
                tap(() => this.onClose(this.toasts$.getValue()[0])),
            )
            .subscribe();
    }

    /**
     * Event handler for close
     */
    onClose(toast: CommonToastDatum): void {
        const toasts = this.toasts$.getValue().filter((t) => t.id !== toast.id);
        this.toasts$.next(toasts);
    }
}
