<msc-common-aside #aside (saveEvent)="save()">
    <ng-template #headerTemplate>
        <h3>{{ 'integration.exception.manage' | translate }}</h3>
        <small>{{ 'integration.exception.description' | translate }}</small>
    </ng-template>

    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <ng-container *ngIf="!loading">
                <div class="flex flex-col space-y-2">
                    <p>{{ 'integration.exception.current' | translate }}</p>
                    <p *ngIf="currentContent?.skills.length == 0">
                        {{ 'integration.exception.none' | translate }}</p>
                    <div class="flex flex-wrap">
                        <div class="skills__item" *ngFor="let skill of currentContent?.skills">
                            <div class="skill skill--shadow-none skill--level">
                                <div class="skill__content">
                                    <a class="skill__icon" *ngIf="skill.linkedRule?.type === ADD">
                                        <i class="icon icon-checkmark"></i>
                                    </a>
                                    <p class="skill__text">{{skill.name}}</p>
                                    <a class="skill__close"
                                        *ngIf="!skill.checked && (!skill.linkedRule || skill.linkedRule.type === ADD)"
                                        (click)="skill.linkedRule ? removeRule(skill.linkedRule) : addSkillToException(skill, REMOVE)">
                                        <i class="icon icon-close"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="flex flex-col space-y-2">
                    <p>{{ 'integration.exception.list' | translate }}</p>
                    <p *ngIf="currentContent?.skillRules.length == 0">
                        {{ 'integration.exception.none' | translate }}</p>
                    <div class="flex flex-wrap">
                        <div class="skills__item" *ngFor="let rule of currentContent?.skillRules">
                            <div class="skill skill--shadow-none skill--level">
                                <div class="skill__content">
                                    <a class="skill__icon">
                                        <i class="icon icon-slash-1" *ngIf="rule.type === REMOVE"></i>
                                        <i class="icon icon-checkmark" *ngIf="rule.type === ADD"></i>
                                    </a>

                                    <p class="skill__text">{{rule.skill.name}}</p>

                                    <a class="skill__close" *ngIf="!rule.id || rule.type === REMOVE"
                                        (click)="removeRule(rule)">
                                        <i class="icon icon-close"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="flex flex-col space-y-2">
                    <p>{{ 'integration.exception.available' | translate }}</p>
                    <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
                        (searchEvent)="searchSkill($event.value)"></msc-common-search>
                    <ng-container *ngIf="!query && availableSkills.length == 0">
                        <p class="slide__desc flex">{{ 'integration.exception.no-skill' | translate }}</p>
                        <br>
                        <msc-button class="msc-button--primary" palette="primary"
                            routerLink="/organize/{{companySlug}}/knowledge">
                            {{ 'skill.create-title' | translate }}
                        </msc-button>
                    </ng-container>
                    <div class="flex flex-wrap">
                        <div class="skills__item" *ngFor="let skill of availableSkills">
                            <div class="skill skill--shadow-none skill--level skill--pointer"
                                (click)="addSkillToException(skill, ADD)">
                                <div class="skill__content">
                                    <p class="skill__text">{{ skill.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <msc-common-spinner *ngIf="loadingSkills"></msc-common-spinner>
            </ng-container>
            <msc-common-spinner *ngIf="loading"></msc-common-spinner>
        </div>
    </ng-template>
</msc-common-aside>