<div class="content-corrections content-corrections-list content-corrections-session">
    <div class="content-corrections__filter">
        <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
    </div>

    <div class="content-corrections__table dynamic-table">
        <div class="dynamic-table__head">
            <div class="dynamic-table__cell cell-title--large">{{ 'words.name' | translate }}</div>

            <div class="dynamic-table__cell"></div>

            <div class="dynamic-table__cell">{{ 'words.content' | translate }}</div>
        </div>

        <div class="content-corrections__list dynamic-table__body"  *ngFor="let correctible of instances, let index=index"
            [ngClass]="{ 'active': displays[index], 'content' : correctible.type === 'content' }"
            (click)="open(correctible, index)">
<!--            [ngStyle]="{'': correctible.type === 'course' && ((correctible.correctibleInstanceIds | keyvalue).length < 1) ? 'none' : null}"-->
            <div class="box-session-correction box-session-correction--no-progress dynamic-table__row row-radius row-shadow row-with-contents ">
                <div class="dynamic-table__content">
                    <div class="box-session-correction__header dynamic-table__cell">
                        <i class="icon" [className]="getActivityIcon(correctible)"></i>
                        <h3 class="box-session-correction__title">
                            <!--<span class="box-session-correction__notification"></span>-->
                            {{ (correctible.title | truncate: 30) || ('words.untitled' | translate) }}
                        </h3>
                    </div>

                    <div class="box-session-correction__infos dynamic-table__cell"></div>

                    <div class="box-session-correction__infos dynamic-table__cell">
                        <ul *ngIf="correctible.type === 'course'">
                            <li [tooltip]="'words.projects' | translate">
                                <i class="icon icon-clipboard"></i>
                                {{ correctible.template.resume.project || '0' }}
                            </li>

                            <li [tooltip]="'words.exercise' | translate">
                                <i class="icon icon-checkmark-square"></i>
                                {{ correctible.template.resume.exercise || '0' }}
                            </li>

                            <li [tooltip]="'words.selfassessment' | translate">
                                <i class="icon icon-content"></i>
                                {{ correctible.template.resume.selfassessment || '0' }}
                            </li>
                        </ul>
                    </div>

                    <div class="box-session-correction__arrow dynamic-table__cell cell-actions cell-actions__down" *ngIf="correctible.type === 'course' && ((correctible.correctibleInstanceIds | keyvalue).length > 0)">
                        <a><i class="icon icon-arrow-ios-right"></i></a>
                    </div>

                    <div class="box-session-correction__arrow dynamic-table__cell cell-actions cell-actions__down" *ngIf="correctible.type === 'content' && (correctible.resume.project || correctible.resume.exercise || correctible.resume.selfassessment)" [routerLink]="goToCorrectible(correctible, correctible.type)">
                        <a><i class="icon icon-arrow-ios-up"></i></a>
                    </div>
                </div>
            </div>

            <msc-common-spinner *ngIf="loading.section[index]"></msc-common-spinner>
            <msc-empty-state *ngIf="!loading.section[index] && sectionContent[index]?.correctibles.length < 1 && displays[index]"
                             [title]="'words.corrections'"
                             [description]="'empty.correction'">
            </msc-empty-state>

            <div class="box-session-correction-details">
                <div class="box-session-correction-details__body">
                    <div class="box-session-correction-details__list">
                        <div class="box-session-correction-details__item box-session-correction-details__item--no-progress"
                            *ngFor="let content of sectionContent[index]?.correctibles"
                            [routerLink]="goToCorrectible(content, sectionContent[index].type)"
                            (click)="saveInstance(content); $event.stopPropagation()">
                            <div class="box-session-correction-details__content">
                                <div class="box-session-correction-details__title dynamic-table__cell cell-large">
                                    <h3 class="box-session-correction__title">
                                        {{ ( content.title | truncate: 30 ) || ( 'words.untitled-activity' | translate ) }}
                                    </h3>
                                </div>

                                <div class="box-session-correction__arrow dynamic-table__cell cell-actions">
                                    <a><i class="icon icon-arrow-ios-up"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="instances.length < 1 && !loading.global">{{ 'empty.correction' | translate }}</div>
    <msc-common-spinner *ngIf="loading.global" ></msc-common-spinner>
</div>
