export class Auth {
    access_token: string;
    expires_at: Date;
    refresh_token: string;
    tokenType: string;
    forcedPasswordReset?: string;

    constructor(auth: object) {
        this.assign(auth);
    }

    assign(auth: object): void {
        Object.assign(this, auth);
    }
}
