<ng-container *ngFor="let toast of toasts$ | async">
    <div class="toast {{ toast.type }} toast--{{toast.display}}">
        <i class="icon icon-info-1"></i>
        <div class="content">
            <h3>{{toast.title || ('words.notification' | translate)}}</h3>
            <small>{{ toast.message }}</small>
            <div class="actions" *ngIf="toast.actions && toast.actions.length">
                <ng-container *ngFor="let action of toast.actions">
                    <msc-button *ngIf="action.type === 'button'" class="msc-button--small {{action.buttonClassType ? ('msc-button--' + action.buttonClassType) : ''}}" [palette]="action.palette ? action.palette : 'slate'" (click)="action.callback(action.label)">{{action.label}}</msc-button>
                    <a *ngIf="action.type === 'action'" (click)="action.callback(action.label)">{{action.label}}</a>
                </ng-container>
            </div>
        </div>
        <msc-button class="close msc-button--tiny" type="fab" icon="icon-close" (click)="onClose(toast)"></msc-button>
    </div>
</ng-container>