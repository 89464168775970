import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Company } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { CalendarDate } from '@interfaces';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'msc-daterangepicker',
    templateUrl: './daterange-picker.component.html'
})
export class DateRangePickerComponent implements OnInit {
    @Input() overlay: boolean = false;
    @Input() class: string = '';
    @Input() range: boolean = false;
    @Input() dates: any;
    @Output() datesChange: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    private company: Company;
    private status: string = 'start';
    private type: string = 'custom';
    public currentDates: any = {
        start: null,
        end: null
    };
    public selectedDates: any = {
        start: null,
        end: null
    };
    public weeksStart: any[] = [];
    public weeksEnd: any[] = [];
    public dayNames: any = {
        en: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
        fr: ['lu', 'ma', 'me', 'je', 've', 'sa', 'di'],
        nl: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo']
    };
    public lang: string;
    public isActive: boolean = false;
    public dateRange: string = 'last30days';

    constructor(private storageService: StorageService, private translateService: TranslateService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.lang = this.translateService.getCurrentLang();
        this.currentDates.start = this.dates && this.dates.start ? moment(this.dates.start).format() : moment().format();
        this.currentDates.end = this.dates && this.dates.end ? moment(this.dates.end).format() : moment(this.currentDates.start).add(1, 'months').format();

        if (this.dates) {
            this.generateDates();

            this.type = this.dates.type || 'custom';
        }
        this.generateCalendar();

        this.isActive = true;
    }

    changeMonths(type: string, direction: string): void {
        if (direction === 'prev') {
            this.currentDates[type] = moment(this.currentDates[type]).subtract(1, 'months').format();
        } else {
            this.currentDates[type] = moment(this.currentDates[type]).add(1, 'months').format();
        }

        if (type === 'start' && direction === 'next') {
            if (moment(this.currentDates.start).isSame(this.currentDates.end, 'month')) {
                this.currentDates.end = moment(this.currentDates.end).add(1, 'months').format();
            }
        }

        if (type === 'end' && direction === 'prev') {
            if (moment(this.currentDates.end).isSame(this.currentDates.start, 'month')) {
                this.currentDates.start = moment(this.currentDates.start).subtract(1, 'months').format();
            }
        }

        this.generateCalendar();
    }

    selectDay(day: CalendarDate): void {
        if (day.isSameMonth) {
            this.selectedDates[this.status] = day;

            if (this.status === 'start' && this.selectedDates.end) {
                this.selectedDates.end = null;
            }

            if (this.status === 'end' && this.isBefore(day.moment)) {
                this.selectedDates.start = null;
                this.selectedDates.end = null;
                this.selectedDates.start = day;
                this.status = 'start';
            }

            this.status = this.status === 'start' ? 'end' : 'start';
            this.type = 'custom';
        }
    }

    isSelected(date: CalendarDate): boolean {
        if (this.selectedDates.start && date.isSameMonth) {
            const start = moment(this.selectedDates.start.moment).isSame(date.moment, 'day');
            if (!start && this.selectedDates.end) {
                return moment(this.selectedDates.end.moment).isSame(date.moment, 'day');
            }
            return start;
        }
        return false;
    }

    isBetween(day: CalendarDate): boolean {
        if (this.selectedDates.start && this.selectedDates.end) {
            return moment(day.moment.format('YYYY-MM-DD')).isBetween(this.selectedDates.start.moment.format('YYYY-MM-DD'), this.selectedDates.end.moment.format('YYYY-MM-DD'), null, '[]');
        }
        return false;
    }

    isStart(date: CalendarDate): boolean {
        if (this.selectedDates.start && date.isSameMonth) {
            return moment(this.selectedDates.start.moment).isSame(date.moment, 'day');
        }
        return false;
    }

    isEnd(date: CalendarDate): boolean {
        if (this.selectedDates.end && date.isSameMonth) {
            return moment(this.selectedDates.end.moment).isSame(date.moment, 'day');
        }
        return false;
    }

    save(): void {
        if (this.selectedDates.start && this.selectedDates.end) {
            const dates: any = {
                start: moment(this.selectedDates.start.moment).format('YYYY-MM-DD'),
                end: moment(this.selectedDates.end.moment).format('YYYY-MM-DD'),
                diff: moment(this.selectedDates.end.moment).diff(this.selectedDates.start.moment, 'days'),
                type: this.type
            };
            this.datesChange.emit(dates);
            this.onSave.emit();
            this.onClose.emit();
        }
    }

    close(): void {
        this.onClose.emit();
    }

    changeRange(): void {
        switch (this.dateRange) {
            case 'last7days':
                this.dates.start = moment().subtract(7, 'days').format();
                this.dates.end = moment().format();
                break;
            case 'last14days':
                this.dates.start = moment().subtract(14, 'days').format();
                this.dates.end = moment().format();
                break;
            case 'currentMonth':
                this.dates.start = moment().startOf('month').format();
                this.dates.end = moment().endOf('month').format();
                break;
            case 'lastMonth':
                this.dates.start = moment(moment().subtract(1, 'months')).startOf('month').format();
                this.dates.end = moment(moment().subtract(1, 'months')).endOf('month').format();
                break;
            case 'currentQuarter':
                this.dates.start = moment().startOf('quarter').format();
                this.dates.end = moment().endOf('quarter').format();
                break;
            case 'lastQuarter':
                this.dates.start = moment(moment().subtract(1, 'quarter')).startOf('quarter').format();
                this.dates.end = moment(moment().subtract(1, 'quarter')).endOf('quarter').format();
                break;
            case 'currentYear':
                this.dates.start = moment().startOf('year').format();
                this.dates.end = moment().endOf('year').format();
                break;
            case 'lastYear':
                this.dates.start = moment(moment().subtract(1, 'year')).startOf('year').format();
                this.dates.end = moment(moment().subtract(1, 'year')).endOf('year').format();
                break;
            case 'wholePeriod':
                this.dates.start = moment(this.company.createdAt).format();
                this.dates.end = moment().format();
                break;
            default:
                this.dates.start = moment().subtract(30, 'days').format();
                this.dates.end = moment().format();
        }

        this.currentDates.start = moment(this.dates.start).format();
        this.currentDates.end = moment(this.dates.end).format();

        if (moment(this.currentDates.end).isSame(this.currentDates.start, 'month')) {
            this.currentDates.start = moment(this.dates.end).subtract(1, 'months').format();
        }

        this.type = this.dateRange;

        this.generateCalendar();
        this.generateDates();
    }

    private generateDates(): void {
        this.selectedDates.start = {
            moment: moment(this.dates.start),
            selected: true
        };

        this.selectedDates.end = {
            moment: moment(this.dates.end),
            selected: true
        };
    }

    private generateCalendar(): void {
        let dates = this.fillDates(this.currentDates.start);
        let weeks: CalendarDate[][] = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeksStart = weeks;

        dates = this.fillDates(this.currentDates.end);
        weeks = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeksEnd = weeks;
    }

    private fillDates(currentDate: moment.Moment): CalendarDate[] {
        const firstOfMonth = moment(currentDate).startOf('month').day();
        const firstDayOfGrid = firstOfMonth === 0 ? moment(currentDate).startOf('month').subtract(6, 'days') : moment(currentDate).startOf('month').subtract(firstOfMonth - 1, 'days');
        const start = firstDayOfGrid.date();
        return _.range(start, start + 42)
            .map((date: number): CalendarDate => {
                const d = moment(firstDayOfGrid).date(date);
                return {
                    today: this.isSame(d),
                    isSameMonth: this.isSame(d, 'month', currentDate),
                    moment: d
                };
            });
    }

    private isSame(date: moment.Moment, granularity: any = 'day', compareDate?: moment.Moment): boolean {
        if (compareDate) {
            return moment(compareDate).isSame(moment(date), granularity);
        }
        return moment().isSame(moment(date), granularity);
    }

    private isBefore(date: moment.Moment): boolean {
        return moment(date).isBefore(this.selectedDates.start.moment);
    }
}
