import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MediaProvider } from '@lighty';
import { DownloadHelper, MediaHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-media-manager',
    templateUrl: './media-manager.component.html'
})

export class MediaManagerComponent {
    @ViewChild('uploadedFile') upload: ElementRef;
    @Input() medias: any[];
    @Input() options: any;
    @Output() newMediaEvent: EventEmitter<any> = new EventEmitter();
    @Output() deleteMediaEvent: EventEmitter<any> = new EventEmitter();

    private readonly TYPES = {
        AUDIO: [
            { ext: 'mp3', mime: 'audio/mp3' },
            { ext: 'mpeg', mime: 'audio/mpeg' },
            { ext: 'm4a', mime: 'audio/m4a' }
        ],
        APPLICATION: [
            { ext: 'pdf', mime: 'application/pdf' },
            { ext: 'doc', mime: 'application/msword' },
            { ext: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
            { ext: 'ppt', mime: 'application/vnd.ms-powerpoint' },
            { ext: 'pptx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
            { ext: 'ods', mime: 'application/vnd.oasis.opendocument.spreadsheet' },
            { ext: 'odt', mime: 'application/vnd.oasis.opendocument.text' },
            { ext: 'odp', mime: 'application/vnd.oasis.opendocument.presentation' },
            { ext: 'xls', mime: 'application/vnd.ms-excel' },
            { ext: 'xslx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
            { ext: 'xlsm', mime: 'application/vnd.ms-excel.sheet.macroenabled.12' },
            { ext: 'xlsb', mime: 'application/vnd.ms-excel.sheet.binary.macroenabled.12' },
            { ext: 'xltm', mime: 'application/vnd.ms-excel.template.macroenabled.12' },
            { ext: 'xml', mime: 'application/xml' },
            { ext: 'zip', mime: 'application/zip' }
        ],
        IMAGE: [
            { ext: 'jpg', mime: 'image/jpeg' },
            { ext: 'jpeg', mime: 'image/jpeg' },
            { ext: 'jfif', mime: 'image/jpeg' },
            { ext: 'gif', mime: 'image/gif' },
            { ext: 'png', mime: 'image/png' },
            { ext: 'webp', mime: 'image/webp' }
        ],
        TEXT: [
            { ext: 'xml', mime: 'text/xml' },
            { ext: 'csv', mime: 'text/csv' },
            { ext: 'txt', mime: 'text/plain' }
        ],
        VIDEO: [
            { ext: 'mov', mime: 'video/quicktime' },
            { ext: 'movie', mime: 'video/x-sgi-movie' },
            { ext: 'flv', mime: 'video/x-flv' },
            { ext: 'webm', mime: 'video/webm' },
            { ext: 'mp4', mime: 'video/x-m4v' },
            { ext: '3gp', mime: 'video/3gpp' },
            { ext: 'mkv', mime: 'video/x-matroska' },
            { ext: 'wmv', mime: 'video/x-ms-wmv' },
            { ext: 'avi', mime: 'video/x-msvideo' },
            { ext: 'avi', mime: 'video/avi' },
            { ext: 'mp4', mime: 'video/mp4' },
            { ext: 'ogg', mime: 'video/ogg' }
        ]
    };
    private defaultOptions: any = {
        canUploadMedia: false,
        canDownloadMedia: true,
        canDeleteMedia: false,
        canUploadFileTypes: '*',
        canUploadFileSize: 2000000000
    };
    public loading: boolean = false;
    public advertises: any = {
        extensions: {
            show: false,
            value: null
        },
        size: {
            show: false,
            value: null
        }
    };

    constructor(
        private toastService: CommonToastService,
        private mediaProvider: MediaProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.setOptions(this.options);
    }

    /**
     * @description Set configuration and values of advertises used on the view
     *
     * @param options Value of current options
     */
    private setAdvertises(options: any): void {
        this.advertises.extensions.show = options.canUploadMedia &&
            (
                (typeof options.canUploadFileTypes === 'string' && (Object.keys(this.TYPES).includes(options.canUploadFileTypes.toUpperCase()) || options.canUploadFileTypes.includes('::'))) ||
                (Array.isArray(options.canUploadFileTypes) && options.canUploadFileTypes.length <= 3 && options.canUploadFileTypes.every(key => Object.keys(this.TYPES).includes(key.toUpperCase())))
            );

        if (this.advertises.extensions.show) {
            this.advertises.extensions.value = this.getAcceptedExtensions(options.canUploadFileTypes).join(', ');
        }

        this.advertises.size.show = options.canUploadMedia && Number(options.canUploadFileSize);

        if (this.advertises.size.show) {
            this.advertises.size.value = options.canUploadFileSize;
        }
    }

    /**
     * @description Get media type(s) information (ext|mime) when options.canUploadMediaType is only a string type definition. IE image|audio|application|text|video.
     *
     * @param types Keys of generic types supported by component
     * @param configuration Current options.canUploadMediaTypes
     * @param property Key of the property to return
     *
     */
    private getTypeInfoByGenericType(types: string[], configuration: string, property: string = 'mime'): string[] {
        return types.filter(type => type === configuration.toUpperCase()).map(type => this.TYPES[type].map(type => type[property]));
    }

    /**
     * @description Get media type(s) information (ext|mime) when options.canUploadMediaType is an array of strings type definitions. IE [image,audio,application,text,video].
     *
     * @param types Keys of generic types supported by component
     * @param configuration Current options.canUploadMediaTypes
     * @param property Key of the property to return
     */
    private getTypeInfoByGenericTypes(types: string[], configuration: string[], property: string = 'mime'): string[] {
        return types.filter(type => configuration.map(type => type.toUpperCase()).includes(type)).map(type => this.TYPES[type].map(type => type[property]));
    }

    /**
     * @description Get media type(s) information (ext|mime) when options.canUploadMediaType is a detailed string type definition. IE image::jpg,jpeg,gif
     *
     * @param types Keys of generic types supported by component
     * @param configuration Current options.canUploadMediaTypes
     * @param property Key of the property to return
     */
    private getTypeInfoByExtensions(types: string[], configuration: string, property: string = 'mime'): string[] {
        return types.filter(type => type === configuration.split('::')[0].toUpperCase()).map(type => this.TYPES[type].filter(type => configuration.split('::')[1].split(',').includes(type.ext)).map(type => type[property]));
    }

    /**
     * @description Get list of accepted MIME types according to current options definition.
     *
     * @param configuration Value of current options.canUploadMediaTypes
     */
    private getAcceptedMimeTypes(configuration: string | string[]): string[] {
        const types = Object.keys(this.TYPES);

        let mimes: string[] = [];
        let exts: string[] = [];

        if (typeof configuration === 'string') {
            mimes = configuration === '*' ? types.map(type => this.TYPES[type].map(type => type.mime)) : configuration.includes('::') ? this.getTypeInfoByExtensions(types, configuration) : this.getTypeInfoByGenericType(types, configuration);
            exts = configuration === '*' ? types.map(type => this.TYPES[type].map(type => type.ext)) : configuration.includes('::') ? this.getTypeInfoByExtensions(types, configuration) : this.getTypeInfoByGenericType(types, configuration);
        }

        if (Array.isArray(configuration)) {
            mimes = this.getTypeInfoByGenericTypes(types, configuration);
            exts = this.getTypeInfoByGenericTypes(types, configuration);
        }

        return [].concat.apply([], [...mimes, ...exts]).filter((item, idx, current) => current.indexOf(item) === idx);
    }

    /**
     * @description Get list of accepted extensions according to current options definition.
     *
     * @param configuration Value of current options.canUploadMediaTypes
     */
    private getAcceptedExtensions(configuration: string | string[]): string[] {
        const types = Object.keys(this.TYPES);

        let extensions: string[] = [];

        if (typeof configuration === 'string') {
            extensions = !configuration.includes('::') ? this.getTypeInfoByGenericType(types, configuration, 'ext') : this.getTypeInfoByExtensions(types, configuration, 'ext');
        }

        if (Array.isArray(configuration)) {
            extensions = this.getTypeInfoByGenericTypes(types, configuration, 'ext');
        }

        return [].concat.apply([], extensions).filter((item, idx, current) => current.indexOf(item) === idx);
    }

    /**
     * @description Set options values
     *
     * @param options Value of current options
     */
    setOptions(options: any): void {
        if (typeof options !== 'object') {
            this.options = this.defaultOptions;
            return;
        }

        const cleanOptions = Object.keys(options)
            .filter(property => ['canUploadMedia', 'canDownloadMedia', 'canDeleteMedia', 'canUploadFileTypes', 'canUploadFileSize'].includes(property))
            .reduce((accumulator, current) => {
                accumulator[current] = options[current];
                return accumulator;
            }, {});

        Object.assign(this.defaultOptions, cleanOptions);

        this.options = this.defaultOptions;

        this.setAdvertises(this.options);
    }

    getIcon(media: any): string {
        return MediaHelper.getIcon(media);
    }

    onDownloadMedia(media: any): void {
        DownloadHelper.downloadMedia(media, media.title);
    }

    onDeleteMedia(media: any): void {
        this.deleteMediaEvent.emit(media);
    }

    onMediaUpload(event: any): void {
        const extension = event.target.files[0].name.substr(event.target.files[0].name.lastIndexOf('.') + 1);
        this.loading = true;

        if (!this.getAcceptedMimeTypes(this.options.canUploadFileTypes).includes(event.target.files[0].type || extension)) {
            this.loading = false;
            this.toastService.onError(this.translateService.instant('toast.error.file-type.not-supported'));
            return;
        }

        if (event.target.files[0].size > this.options.canUploadFileSize) {
            this.loading = false;
            this.toastService.onError(this.translateService.instant('toast.error.file-size.max-file-size-exceeded'));
            return;
        }

        this.mediaProvider
            .uploadMedia(event.target.files[0])
            .subscribe((media) => {
                this.newMediaEvent.emit(media);
            },
                (error) => {
                    if (error.status === 400) {
                        this.toastService.onError(this.translateService.instant('toast.error.file-type.not-supported'));
                    }
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                });
    }
}
