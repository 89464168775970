<div class="" *ngIf="parameters.display === 'users'">
    <div class="box box--shadow">
        {{ header.score }} - {{ header.progress }}
    </div>

    <div class="box bow-shadow flex" *ngFor="let user of items; let index=index" (click)="navigate('course', parameters.courseId, 'activities', {accountId: user.accountId})">
        <div class="">
            <msc-common-avatar [label]="user?.name"
                [pictureURL]="user?.picture ? (user?.picture + '?size=128') : null">
            </msc-common-avatar>
        </div>

        <div class="">
            {{ user.name }} - {{ user.duration }}
        </div>

        <div class="">
            <msc-chart-pie [value]="user.progress" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
        </div>

        <div class="">
            <msc-chart-pie [value]="user.score" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
        </div>
    </div>
</div>

<div class="" *ngIf="parameters.display === 'contents'">
    <div class="box box--shadow">
        {{ header.score }} - {{ header.duration }} {{ header.progress }}
    </div>

    <div class="box bow-shadow flex" *ngFor="let content of items; let index=index" (click)="contentNavigate(content.context, content.contextId)">
        <div class="">
            {{ content.context }}
        </div>

        <div class="">
            {{ content.title }}
        </div>

        <div class="">
            <msc-chart-pie [value]="content.progress" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
        </div>

        <div class="">
            <msc-chart-pie [value]="content.score" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
        </div>
    </div>
</div>

<div class="" *ngIf="parameters.display === 'activities'">
    <div class="box box--shadow">
        {{ header.score }} - {{ header.duration }} {{ header.progress }}
    </div>

    <div class="box bow-shadow flex" *ngFor="let activity of items; let index=index" (click)="navigate('activity', activity.id, 'users', {}, activity.context === 'exercise')">
        <div class="">
            {{ activity.context }}
        </div>

        <div class="">
            {{ activity.title }}
        </div>

        <div class="">
            <msc-chart-pie [value]="activity.progress" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
        </div>

        <div class="">
            <msc-chart-pie [value]="activity.score" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
        </div>
    </div>
</div>
