<div class="block-onboarding">
    <div class="block-onboarding__header">
        <p>{{ 'user.slow-invite.coach.label' | translate }}</p>
        <h3>{{ 'user.slow-invite.coach.title' | translate }}</h3>
    </div>

    <msc-onboarding-topbar-filters [type]="'catalogue'" [color]="'white'" [buildFilters]="buildFilters"
        [(query)]="query" [mode]="'coach'" [(filters)]="filters" [(activeFilters)]="activeFilters"
        (queryChange)="search()" (filtersChange)="search()" (onlySelectionChange)="toggleSelection($event)"
        [context]="'accesses'"></msc-onboarding-topbar-filters>
    <div class="text-center">
        <p *ngIf="catalogue.length === 0">{{ 'empty.available.data' | translate }}</p>
    </div>
    <div class="block-onboarding__cards flex justify-start wrap">
        <div class="col-xxs-12">
            <msc-common-spinner *ngIf="loading"></msc-common-spinner>
        </div>
        <div class="cards-container row">
            <div class="card card--skip card--coach col-2 col-xxs-12 col-card" (click)="updateSession()">

                <div class="card__body">
                    <p class="text-center text-white">
                        {{ 'coach.skip' | translate }}
                    </p>
                </div>
            </div>
            <div class="card card--coach col-2 col-xxs-12 col-card" *ngFor="let instance of catalogue"
                [ngClass]="{'card--checked': isSessionSelected(instance)}" (click)="updateSession(instance)">
                <div class="card__header">

                    <div class="card__banner card__banner--container flex between"
                        *ngIf="instance.type === 'external_content'">
                        <div class="card__radio"></div>
                        <div class="card__banner--container__picture"
                            [style.backgroundImage]="'url(' + (instance.data.provider?.logo ? (instance.data.provider.logo + '?size=256') : ('https://static.myskillcamp.com/images/integrations/' + instance.data.provider?.key + '.svg')) + ')'">
                        </div>
                        <msc-tag [color]="instance.type === 'traject' ? 'red' : ''"
                            [text]="'filter.content-type.' + instance.data.type | translate">
                        </msc-tag>
                    </div>

                    <ng-container *ngIf="instance.type !== 'external_content'">
                        <div class="card__radio"></div>
                        <div class="card__tags">
                            <msc-tag [color]="instance.type === 'traject' ? 'red' : ''"
                                [text]="'filter.content-type.' + instance.data.type | translate">
                            </msc-tag>
                        </div>
                    </ng-container>

                    <div class="card__tags flex-start" [ngClass]="{'card__tags--banner': !instance.data.nextEvent}">
                        <msc-tag [type]="'date'" [day]="instance.data.nextEvent | date: 'dd'"
                            [month]="instance.data.nextEvent | localizedDate: 'MMM'" *ngIf="instance.data.nextEvent">
                        </msc-tag>
                    </div>

                    <div class="card__image">
                        <div *ngIf="instance.data.picture"
                            [ngStyle]="{'background-image': 'url(' + instance.data.picture + '?size=512)'}">
                        </div>
                        <div *ngIf="!instance.data.picture"
                            [ngStyle]="{'background-image': 'url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + instance.data.id % 7 + '.jpg)'}">
                        </div>
                    </div>
                </div>

                <div class="card__body">
                    <div class="card__time" *ngIf="instance.data.duration">
                        <i class="icon icon-clock"></i>
                        <span class="card_time_text">{{instance.data.duration | duration}}</span>
                    </div>
                    <p class="card__title"
                        [tooltip]="instance.data.title?.length > 30 ? instance.data.title : ''">
                        <ng-container *ngIf="instance.data.title">
                            {{ instance.data.title | truncate: 30 }}
                        </ng-container>
                        <ng-container *ngIf="!instance.data.title">
                            {{ 'words.untitled' | translate }}
                        </ng-container>
                        <p class="card__desc leading-3"
                            [tooltip]="instance.data.internalName?.length > 80 ? instance.data.internalName : ''"
                            [innerHTML]="instance.data.internalName | truncate: 80">
                        </p>
                </div>
            </div>
        </div>
        <div class="col-xxs-12">
            <msc-load-more *ngIf="catalogue.length" [pagination]="pagination" (click)="loadMore()">
            </msc-load-more>
        </div>
    </div>

    <div class="actions">
        <msc-button class="msc-button--primary" palette="secondary" [disabled]="!role.selectedSessions.length" (click)="isValid()">
            {{ 'words.continue' | translate }}
        </msc-button>
    </div>
</div>
