import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-organize-experience-watch-url',
    templateUrl: './url.component.html',
    styleUrls: ['./url.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchUrlComponent {

}
