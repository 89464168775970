<div class="stats-header">
    <div class="breadcrumb">
        <p class="breadcrumb-item" (click)="goBack()"><span>{{ 'stats.title' | translate }}</span></p>
        <p class="breadcrumb-item">
            <ng-container *ngIf="content">
                <i class="icon icon-{{ content.icon }}"></i>
                <span>{{ content.title }}</span>
            </ng-container>
        </p>
    </div>
    <div *ngIf="lastChange">
        <a class="text-indigo-600 underline" (click)="changelogAside.onOpen()">Last edit {{lastChange | lowercase}}</a>
    </div>
</div>

<div class="stats-cards">
    <ng-container *ngFor="let card of cards">
        <div class="stats-card">
            <h6>{{ card.label }}</h6>
            <ng-container *ngIf="card.type === 'chart'">
                <msc-chart-pie [size]="'medium'" [value]="card.value"></msc-chart-pie>
            </ng-container>
        </div>
    </ng-container>
</div>

<div class="toolbar">
    <div class="wrapper">
        <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)">
        </msc-common-search>
        <msc-common2-switch [value]="isSubscribed" [label]="'words.only-registered-learners' | translate"
            (changeEvent)="onSwitch($event)"></msc-common2-switch>
    </div>

    <div class="flex space-x-2" *ngIf="content?.context === 'traject'">
        <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" icon="icon-download"
            (click)="modalExports.open({format: 'csv', type: 'traject-history-export'})">
            CSV
        </msc-button>
        <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" icon="icon-download"
            (click)="modalExports.open({format: 'xlsx', type: 'traject-history-export'})">
            XLSX
        </msc-button>
    </div>
</div>

<div class="content">
    <ng-container [ngSwitch]="status$ | async">
        <ng-container *ngSwitchCase="'success'">
            <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                <msc-common-table [columns]="columns" [rows]="rows$ | async" [style.height]="tableHeight">
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                        </msc-common-th-sort>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span></span>
                    </ng-template>

                    <ng-template #tdTemplate let-datum="datum">
                        <msc-common-avatar class="mr-2" [label]="datum?.name"
                            [pictureURL]="datum?.avatar ? (datum?.avatar + '?size=128') : null"></msc-common-avatar>
                        <span>{{ datum.name }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ (datum.isSubscribed ? 'words.subscribed' : 'words.unsubscribed') | translate }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.duration | duration }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <ng-container *ngIf="datum.isChartProgressVisible; else emptyChartProgressTemplate">
                            <msc-chart-pie [size]="'small'" [value]="datum.chartProgress">
                            </msc-chart-pie>
                        </ng-container>
                        <ng-template #emptyChartProgressTemplate>
                            <span>{{ 'common.empty' | translate }}</span>
                        </ng-template>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <ng-container *ngIf="datum.isChartScoreVisible; else emptyChartScoreTemplate">
                            <msc-chart-pie [size]="'small'" [value]="datum.chartScore">
                            </msc-chart-pie>
                        </ng-container>
                        <ng-template #emptyChartScoreTemplate>
                            <span>{{ 'common.empty' | translate }}</span>
                        </ng-template>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <button *ngIf="content.context !== 'external_content'" class="arrow" type="button"
                            (click)="goToUserDetails(datum)"></button>
                    </ng-template>
                </msc-common-table>
            </ng-container>

            <ng-template #emptyTemplate>
                <msc-common-empty [message]="'common.empty' | translate">
                    <ng-template></ng-template>
                </msc-common-empty>
            </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'loading'">
            <msc-common-spinner></msc-common-spinner>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
            <msc-common-error>
                <ng-template></ng-template>
            </msc-common-error>
        </ng-container>
    </ng-container>
</div>

<ng-container *ngIf="hasLoadMore">
    <div class="load-more">
        <msc-button class="msc-button-flat" palette="primary" (click)="onLoad()">{{ 'words.load-more' | translate }}
        </msc-button>
    </div>
</ng-container>

<msc-email-export-modal #modalExports (onSave)="download(modalConfirm, $event)"></msc-email-export-modal>

<msc-common-modal size="30vw" #modalConfirm>
    <ng-template>
        <p [innerHTML]="'export.export_is_being_created' | translate"></p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="msc-button--primary" palette="primary" (click)="modalConfirm.onClose()">Ok</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
<msc-learning-path-changelog #changelogAside [learningPath]="content"></msc-learning-path-changelog>