<div class="comments__item">
    <div class="comment">
        <div class="comment__header">
            <div class="comment__profile">
                <msc-common-avatar [label]="comment.account?.name" [pictureURL]="comment.account?.picture ? (comment.account?.picture + '?size=128') : null"></msc-common-avatar>
                <p class="profile__name">{{ comment.account.name }}</p>
            </div>

            <span>{{ comment.createdAt | timeAgo }}</span>

            <div class="comment__header__delete" (click)="deleteComment()" *ngIf="canDelete(comment.account.id)">
                <i class="icon icon-trash"></i>
            </div>
        </div>

        <div class="comment__content" [innerHTML]="(comment.content.message || comment.content.content) | nl2br | url"></div>

        <msc-link-manager [content]="comment.content.message || comment.content.content"></msc-link-manager>

        <div class="comment__resources" *ngIf="comment.media">
            <img [src]="comment.media.pictureUrl + '?size=512'" alt="" class="content__image" *ngIf="comment.media.type === 'IMG'">

            <iframe [src]="getEmbed(comment.media.url)" *ngIf="comment.media.type === 'EMBED'" class="content__embed" allow="fullscreen" allowFullScreen></iframe>

            <msc-vimeo [media]="comment.media" *ngIf="comment.media.type === 'VID'"></msc-vimeo>
        </div>

        <div class="comment__preview" *ngIf="comment.media && (comment.media.type === 'DOC' || comment.media.type === 'PDF' || comment.media.type === 'NONE' || comment.media.type === 'SND')" [ngClass]="{'comment__preview--processing': comment.media.status !== 1}" (click)="downloadFile(comment.media)">
            <p>
                <i class="icon icon-file-text color--red"></i>
                {{ comment.media.title }}
            </p>

            <p class="comment__preview__status" *ngIf="comment.media.status !== 1" [ngClass]="{'comment__preview__status--failed': comment.media.status === -1}">
                {{ (comment.media.status === 0 ? 'timeline.file.processing' : 'timeline.file.failed') | translate }}
            </p>
        </div>

        <!--<div class="comment__footer">
            <i class="icon icon-like-social"></i>
            <i class="icon icon-message-circle"></i>
        </div>-->
    </div>
</div>
