import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService, TranslateService, ExternalAppsService } from '@services';
import { DataHelper, HttpHelper } from '@helpers';
import { CompanySettings, Company, CompanyUserPermissionsProvider } from '@lighty';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-company-advanced-settings',
    templateUrl: './advanced.component.html'
})
export class OrganizeCompanyAdvancedSettingsComponent {
    @ViewChild('upload') upload: ElementRef;
    private me: any;
    public company: Company;
    public settings: CompanySettings;
    public loading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private companyUserPermissionsProvider: CompanyUserPermissionsProvider,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.settings = this.storageService.get('companySettings') || this.route.parent.parent.snapshot.data.settings;
    }

    addPermission(permission) {
        const params = HttpHelper.cleanParams({
            permission: DataHelper.changeCase(permission)
        });

        this.companyUserPermissionsProvider.addCompanyPermission(this.company.id, params).subscribe(() => {
            this.company.permissions[permission] = true;
            this.toastService.onSuccess(this.translateService.instant('toast.feature.activated'));
        });
    }
}
