<section class="head">
    <h3>Spinner</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Spinner</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-spinner></msc-common-spinner>
            </div>
        </div>
    </div>  
</section>
