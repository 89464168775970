import { Component } from '@angular/core';

@Component({
    selector: 'msc-manage-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})

export class ManageTeamComponent {

}
