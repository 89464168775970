module.exports = {
    prefix: '',
    important: true, //until we get rid of codylab
    content: [
        './src/**/*.{html,ts}',
    ],
    safelist: [
        'sm:flex',
        'md:flex',
    ],
    darkMode: 'class', // or 'media' or 'class'
    theme: {
        extend: {
            screens: {
                // adding xs to the rest
                'xs':'320px',
                '3xl':'1889px',
                '4xl':'2000px',
              },
            colors: {
                primary: {
                    100: 'var(--v2-color-primary-100)',
                    200: 'var(--v2-color-primary-200)',
                    300: 'var(--v2-color-primary-300)',
                    400: 'var(--v2-color-primary-400)',
                    500: 'var(--v2-color-primary-500)',
                    600: 'var(--v2-color-primary-600)',
                    700: 'var(--v2-color-primary-700)',
                    800: 'var(--v2-color-primary-800)',
                    900: 'var(--v2-color-primary-900)'
                },
                secondary: {
                    100: 'var(--v2-color-secondary-100)',
                    200: 'var(--v2-color-secondary-200)',
                    300: 'var(--v2-color-secondary-300)',
                    400: 'var(--v2-color-secondary-400)',
                    500: 'var(--v2-color-secondary-500)',
                    600: 'var(--v2-color-secondary-600)',
                    700: 'var(--v2-color-secondary-700)',
                    800: 'var(--v2-color-secondary-800)',
                    900: 'var(--v2-color-secondary-900)'
                },
                success: {
                    100: 'var(--v2-color-success-100)',
                    200: 'var(--v2-color-success-200)',
                    300: 'var(--v2-color-success-300)',
                    400: 'var(--v2-color-success-400)',
                    500: 'var(--v2-color-success-500)',
                    600: 'var(--v2-color-success-600)',
                    700: 'var(--v2-color-success-700)',
                    800: 'var(--v2-color-success-800)',
                    900: 'var(--v2-color-success-900)'
                },
                danger: {
                    100: 'var(--v2-color-danger-100)',
                    200: 'var(--v2-color-danger-200)',
                    300: 'var(--v2-color-danger-300)',
                    400: 'var(--v2-color-danger-400)',
                    500: 'var(--v2-color-danger-500)',
                    600: 'var(--v2-color-danger-600)',
                    700: 'var(--v2-color-danger-700)',
                    800: 'var(--v2-color-danger-800)',
                    900: 'var(--v2-color-danger-900)'
                },
                error: {
                    100: 'var(--v2-color-error-100)',
                    200: 'var(--v2-color-error-200)',
                    300: 'var(--v2-color-error-300)',
                    400: 'var(--v2-color-error-400)',
                    500: 'var(--v2-color-error-500)',
                    600: 'var(--v2-color-error-600)',
                    700: 'var(--v2-color-error-700)',
                    800: 'var(--v2-color-error-800)',
                    900: 'var(--v2-color-error-900)'
                },
                info: {
                    100: 'var(--v2-color-info-100)',
                    200: 'var(--v2-color-info-200)',
                    300: 'var(--v2-color-info-300)',
                    400: 'var(--v2-color-info-400)',
                    500: 'var(--v2-color-info-500)',
                    600: 'var(--v2-color-info-600)',
                    700: 'var(--v2-color-info-700)',
                    800: 'var(--v2-color-info-800)',
                    900: 'var(--v2-color-info-900)'
                },
                black: {
                    100: '#D3D5E4',
                    200: '#B2B5CD',
                    300: '#8C91B4',
                    400: '#7D83AA',
                    500: '#F59833',
                    600: '#535A8B',
                    700: '#414776',
                    800: '#282D58',
                    900: '#020D38'
                },
                brandedPrimary: {
                    100: '#F3F0FF',
                    200: '#E1DBFF',
                    300: '#BAADFB',
                    400: '#8D77F8',
                    500: '#6C51F6',
                    600: '#6951E0',
                    700: '#5842C1',
                    800: '#3923A9',
                    900: '#211075'
                },
                brandedDarkGrey: {
                    100: '#F4F5FB',
                    200: '#E4E5F6',
                    300: '#C9C9DE',
                    400: '#8F92BD',
                    500: '#6A6CAF',
                    600: '#525298',
                    700: '#373781',
                    800: '#212163',
                    900: '#10103C'
                },
                brandedGray: {
                    100: '#F9F9FA',
                    200: '#F4F5F6',
                    300: '#EEEFF2',
                    400: '#E2E4E9',
                    500: '#C9CCD4',
                    600: '#B2B7C2',
                    700: '#A4AAB6',
                    800: '#959CAC',
                    900: '#8B92A2'
                },
                boxShadow: {
                  sm: '0px 4px 8px rgb(0 0 0 / 0.05)',
                  DEFAULT: '0px 12px 24px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
                  md: 'box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
                  lg: '0px 24px 128px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                  '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
                  inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
                  none: 'none',
                },
            },
            templateCards: {
                'cardMinWidth': '280px',
                'cardMaxWidth': '400px'
            },
            borderRadius: {
                none: '0px',
                xs: '4px',
                sm: '6px',
                DEFAULT: '8px',
                md: '10px',
                lg: '16px',
                xl: '20px',
                '2xl': '24px',
                '3xl': '28px',
                full: '9999px',
            }
        },
    },
    variants: {
        extend: {},
    },
    plugins: [],
};
