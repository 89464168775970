import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AdminSettingsMentoringProvider {

    constructor(private http: HttpClient) {}

    getCategories(campId): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${campId}/admin/skill-categories`).pipe(
            map(data => data)
        );
    }

    createCategory(campId: number): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/camp/${campId}/admin/skill-categories`, {}).pipe(
            map(data => data)
        );
    }

    updateCategory(campId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/camp/${campId}/admin/skill-categories/${params.id}`, params).pipe(
            map(data => data)
        );
    }

    deleteCategory(campId: number, categoryId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/camp/${campId}/admin/skill-categories/${categoryId}`).pipe(
            map(data => data)
        );
    }

    createSkill(campId: number, params): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/camp/${campId}/admin/skills`, params).pipe(
            map(data => data)
        );
    }

    updateSkill(campId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/camp/${campId}/admin/skills/${params.id}`, params).pipe(
            map(data => data)
        );
    }

    deleteSkill(campId: number, skillId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/camp/${campId}/admin/skills/${skillId}`).pipe(
            map(data => data)
        );
    }
}
