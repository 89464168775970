import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpUtils } from '@common2/utils/http.utils';

import { environment } from '@env/environment';

import { ApiDiscussionThreadAccount } from './discussion.provider';

export interface ApiLineupUserRole {
    createdAt: string | Date;
    id: number;
    name: string;
    type: string;
    updatedAt: string | Date;
}

export interface ApiLineupUser {
    account: ApiDiscussionThreadAccount[];
    actionToProcess: number;
    id: number;
    lastLearningActivity: unknown;
    roles: ApiLineupUserRole[];
    totalLearningTime: string;
}

export enum ApiLineupSubjectStatus {
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

export enum ApiLineupSubjectKey {
    OWNER = 'owner',
    MEMBER = 'member',
}

export interface ApiLineupSubject {
    owner: {
        items: ApiLineupUser[];
        status: ApiLineupSubjectStatus;
        page: number | null;
    };
    member: {
        items: ApiLineupUser[];
        status: ApiLineupSubjectStatus;
        page: number | null;
    };
}

@Injectable({ providedIn: 'root' })
export class LineupProvider {
    private readonly root = `${environment.envVar.API_URL}/companies`;
    private readonly subject: BehaviorSubject<ApiLineupSubject> = new BehaviorSubject<ApiLineupSubject>({
        owner: {
            items: [],
            status: ApiLineupSubjectStatus.LOADING,
            page: 1,
        },
        member: {
            items: [],
            status: ApiLineupSubjectStatus.LOADING,
            page: 1,
        },
    });

    constructor(
        private http: HttpClient,
    ) { }

    /**
     *
     */
    setSubjectLineup(datum: Partial<any>, key: ApiLineupSubjectKey, isAppend: boolean = false) {
        const aux = this.subject.getValue();
        Object.keys(datum).forEach((k) => {
            if (k === 'items' && isAppend) { // TODO remove hardcoded value
                aux[key][k] = [...aux[key][k], ...datum[k]];
            } else {
                aux[key][k] = datum[k];
            }
        });

        this.subject.next(aux);
    }

    /**
     *
     */
    getSubjectLineup(): Observable<any> {
        return this.subject.asObservable();
    }

    /**
     * Get a list of users
     */
    getUsers(companyId: number, groupId: number, params: any = {}, key: ApiLineupSubjectKey, isAppend: boolean = true) {
        return this.http.get<any>(`${this.root}/${companyId}/manage/groups/${groupId}/users`, {
            observe: 'response',
            params: HttpUtils.getHttpParams({ ...params, page: this.subject.getValue()[key].page }),
        }).pipe(
            map((r: HttpResponse<any>) => this.onMapGetUsers(r.body)),
            tap((d: ApiLineupSubject[keyof ApiLineupSubject]) => this.onTapGetUsers(d, key, isAppend))
        );
    }

    /**
     * Post the creation of a user
     */
    postUser(companyId: number, groupId: number, params: any): Observable<any> {
        return this.http.post(`${this.root}/${companyId}/manage/groups/${groupId}/users`, params, {
            observe: 'body',
        }).pipe(
            tap((d: any) => this.onTapPostUser(d))
        );
    }

    /**
     * Handler for map on getUsers
     */
    onMapGetUsers(response: any) {
        const items = response.users;
        const status = ApiLineupSubjectStatus.SUCCESS;
        const page = response.pagination.currentPage === response.pagination.lastPage ? null : response.pagination.currentPage + 1;

        return { items, status, page };
    }

    /**
     * Handler for tap on getUsers
     */
    onTapGetUsers(datum: ApiLineupSubject[keyof ApiLineupSubject], key: ApiLineupSubjectKey, isAppend: boolean): void {
        this.setSubjectLineup(datum, key, isAppend);
    }

    /**
     * Handler for tap on postUser
     */
    onTapPostUser(datum: any): void {
        return;
    }
}
