<div class="container-integration__body col-xs-12 col-md-9 col-9 no-gutter">
    <div class="content-integration content-integration-catalogue-export box">
        <div class="content-integration-catalogue-export__header">
            <h3 class="content-integration-catalogue-export__title">
                {{ 'integrations.catalogue-export.title' | translate: {value: provider.name} }}
            </h3>

            <msc-button class="msc-button--primary" palette="primary" (click)="exportCatalogue()" [loading]="loaders.export">
                {{ 'integrations.catalogue-export.export-button' | translate }}
            </msc-button>
        </div>

        <p class="content-integration-catalogue-export__description">
            {{ 'integrations.catalogue-export.description' | translate }}
        </p>
    </div>
</div>
