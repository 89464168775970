<msc-header [company]="company" [access]="access"></msc-header>
<msc-sidebar [me]="me" [company]="company" [access]="access" [sidebar]="sidebar"></msc-sidebar>

<section>
    <div class="flex gap-4 relative">
        <aside class="chat__aside" [ngClass]="{'active': displays.conversations}">
            <div class="chat__aside-header">
                <div class="chat__list">
                    <div class="chat-list__header">
                        <div class="chat-list__title">
                            <div class="chat-list__back" *ngIf="referrer" (click)="back()">
                                <i class="icon icon-arrow-left"></i>
                            </div>

                            <h2>{{ 'words.conversation' | translate }}</h2>

                            <div class="chat-list__action bg-secondary-600">
                                <div class="dropdown-search-member__selected" [routerLink]="['create']">
                                    <i class="icon icon-add-message"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="chat-list__search">
                    <div class="chat-list__form">
                        <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
                            (searchEvent)="search($event.value)"></msc-common-search>
                    </div>
                </div>
            </div>

            <div class="chat__conversation" data-simplebar>
                <div class="chat__item" *ngFor="let conversation of conversations" [routerLink]="[conversation.slug]"
                    [routerLinkActive]="'active'">
                    <div class="chat__indicator" *ngIf="conversation.unreadMessages">{{ conversation.unreadMessages }}
                    </div>
                    <div class="chat__author">
                        <msc-common-avatar *ngIf="conversation.accounts.length > 0"
                            [label]="conversation.accounts[0]?.name"
                            [pictureURL]="conversation.accounts[0]?.picture ? (conversation.accounts[0]?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="details">
                            <p>{{ conversation.accounts.length > 0 ? conversation.accounts[0].name : '' }}</p>
                            <p>{{ conversation.message | truncate: 30 }}</p>

                            <ul class="avatar-group" *ngIf="othersCorrespondents(conversation.accounts)">
                                <li
                                    *ngFor="let correspondent of othersCorrespondents(conversation.accounts) | slice:0:4;">
                                    <a [tooltip]="correspondent.name">
                                        <msc-common-avatar class="tiny" [label]="correspondent?.name"
                                            [pictureURL]="correspondent?.picture ? (correspondent?.picture + '?size=64') : null">
                                        </msc-common-avatar>
                                    </a>
                                </li>

                                <li *ngIf="othersCorrespondents(conversation.accounts).length > 3">
                                    <span class="avatar-group__text text-primary-500">+ {{
                                        othersCorrespondents(conversation.accounts).length - 4 }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="chat__date">
                        <p>{{ conversation.updatedAt | timeElapsed }}</p>
                    </div>

                    <div class="chat__notification" *ngIf="conversation.unreadMessages !== 0">
                        <div>{{ conversation.unreadMessages }}</div>
                    </div>
                </div>
            </div>
        </aside>

        <div class="chat__content grow">
            <router-outlet></router-outlet>
        </div>
    </div>
</section>