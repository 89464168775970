import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService, TranslateService} from '@services';
import { ManageCorrectionProvider } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-manage-instance-session-correction',
    templateUrl: './correction-session.component.html'
})
export class ManageInstanceCorrectionSessionComponent implements OnInit {
    private instanceId: number;
    public company: any;
    public instance: any;
    public journey: any;
    public query: string;
    public sections: any[] = [];
    public displays: any = {};
    public dataFilters: any;
    public filters: any;
    public isFilterActive: boolean = false;
    public loading: boolean = false;

    constructor(private route: ActivatedRoute, private storageService: StorageService, private correctionProvider: ManageCorrectionProvider,
        private translateService: TranslateService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.instance = this.storageService.get('instance');
        this.journey = this.storageService.get('journey');

        this.route.params.subscribe((params) => {
            this.instanceId = params.courseId;
        });

        this.setFilters();
        this.search();

        this.displays = {
            sections: []
        };
    }

    private setFilters(): void {
        this.filters = [
            {
                name: 'corrected',
                type: 'radio',
                label: this.translateService.instant('correction.corrected'),
                value: null,
                options: [{
                    label: this.translateService.instant('correction.corrected'),
                    value: 1,
                }, {
                    label: this.translateService.instant('correction.not-corrected'),
                    value: 0,
                }]
            },
            {
                name: 'content_type',
                type: 'checkbox',
                label: this.translateService.instant('words.content-type'),
                options: [{
                    label: this.translateService.instant('words.exercise'),
                    value: 'exercise',
                    isChecked: false,
                }, {
                    label: this.translateService.instant('words.project'),
                    value: 'project',
                    isChecked: false,
                }, {
                    label: this.translateService.instant('words.selfassessment'),
                    value: 'selfassessment',
                    isChecked: false,
                }]
            }
        ];
    }

    displayed(type: string, index: number): void {
        this.displays[type][index] = !this.displays[type][index];
    }

    openActivityDetails(id: string): string[] {
        return ['activity', id];
    }

    search(query?): void {
        this.query = query;
        this.loading = true;

        const params = HttpHelper.cleanParams({
            traject_id: this.journey ? this.journey.id : null,
            q: this.query,
            corrected: this.dataFilters ? this.dataFilters.corrected : null,
            'content_type[]': this.dataFilters && this.dataFilters.content_type ? this.dataFilters.content_type.join(',') : null,
        });

        this.correctionProvider.getSectionsCorrectible(this.company.id, this.instance ? this.instance.id : this.instanceId, params).subscribe((data) => {
            this.sections = data.corrections;
            this.loading = false;
        });
    }

    countQuestion(section: any): number {
        const value = section.correctedAnswers + section.notCorrectedAnswers + section.notAnswered;
        return (value) ? value : false;
    }

    progressBarWidth(base: number, value: number): string {
        return Number(100 / base * value).toString() + '%';
    }

    activityProgressState(activity: any): string {
        if (activity.notCorrectedAnswers === 0 && activity.notAnswered === 0) {
            return 'box-session-correction-details__item--done';
        } else if (activity.notCorrectedAnswers !== 0) {
            return 'box-session-correction-details__item--progress';
        }
        return 'box-session-correction-details__item--empty';
    }

    saveSection(section: any): void {
        this.storageService.set('section', section);
    }

    onFilterSave(data) {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.search();
    }

    onFilterReset() {
        this.dataFilters = {};
        this.isFilterActive = true;
        this.search();
    }
}
