import { NgModule } from '@angular/core';
import { ScormProvider } from './models/activity/scorm';
import {
    AdminContentGroupProvider,
    AdminContentProvider,
    AdminCorrectionProvider,
    AdminGroupContentProvider,
    AdminGroupProvider,
    AdminGroupResourceProvider,
    AdminGroupUserProvider,
    AdminJourneyMemberProvider,
    AdminJourneyProvider,
    AdminMemberProvider,
    AdminSettingsCatalogueProvider,
    AdminSettingsMentoringProvider,
    AdminSubscriptionProvider,
    AdminTemplateGroupProvider,
    AdminTemplateMemberProvider,
    AdminTimelineProvider,
    AdminYoutubeIntegrationProvider,
} from './models/admin';
import {
    ManageAttendanceProvider,
    ManageCalendarProvider,
    ManageConversationProvider,
    ManageCorrectionProvider,
    ManageFollowUpProvider,
    ManageInstanceProvider,
    ManageLearnerProvider,
    ManageTeamProvider,
} from './models/manage';
import { AuthProvider } from './models/auth';
import { CampProvider } from './models/camp';
import {
    CompanyColorProvider,
    CompanyExperienceProvider,
    CompanyExperienceResourceProvider,
    CompanyProvider,
    CompanySettingsProvider,
    CompanyUrlProvider,
    CompanyUserAccessManageProvider,
    CompanyUserAccessProvider,
    CompanyUserPermissionsProvider,
    CompanyUserProvider,
} from './models/company';
import { CompanyUrlProvider2 } from './models/company-url';
import {
    ContentProvider,
    ExternalContentProvider,
    InstanceProvider,
    JourneyProvider,
    TemplateProvider,
} from './models/content';
import { ConversationProvider } from './models/conversation';
import { MediaProvider } from './models/media';
import { StatsProvider } from './models/stats';
import { ReactionProvider } from './models/reaction';
import { TimelineProvider } from './models/timeline';
import { UserProvider } from './models/user';
import {
    IntegrationProvider,
    YoutubeIntegrationProvider,
} from './models/integration';
import { KnowledgeProvider } from './models/knowledge';
import { BillingProvider } from './models/billing';
import { OverviewProvider } from './models/overview';
import { GroupProvider } from './models/group';
import { CourseProvider } from './models/course';
import { DiscussionProvider, LineupProvider } from './models/discussion';
import { UtilityProvider } from './models/utility';
import { MentoringProvider } from './models/mentoring';
import { SubscriptionProvider } from './models/subscription';
import { ApplicationProvider } from './models/application';
import { SkillProvider } from './models/skill';
import { ReportingProvider } from './models/reporting';
import { CompanySenderProvider } from './models/sender';
import { OnboardingProvider } from './models';

import { LanguageProvider } from './models/language';

import {
    ActivityFactory,
    IntegrationFactory,
    CorrectionFactory,
} from './factories';
import { StatisticsProvider } from './models/statistics';
import { StatisticsProvider2 } from './models/statistics2';
import { UserContentProvider } from './models/user-content';
import { CompanyCertificatesProvider } from './models/company';

@NgModule({
    providers: [
        ActivityFactory,
        AdminContentGroupProvider,
        AdminContentProvider,
        AdminCorrectionProvider,
        AdminGroupContentProvider,
        AdminGroupProvider,
        AdminGroupResourceProvider,
        AdminGroupUserProvider,
        AdminJourneyMemberProvider,
        AdminJourneyProvider,
        AdminMemberProvider,
        AdminSettingsCatalogueProvider,
        AdminSettingsMentoringProvider,
        AdminSubscriptionProvider,
        AdminTemplateGroupProvider,
        AdminTemplateMemberProvider,
        AdminTimelineProvider,
        AdminYoutubeIntegrationProvider,
        ApplicationProvider,
        AuthProvider,
        BillingProvider,
        CampProvider,
        CompanyColorProvider,
        CompanyExperienceProvider,
        CompanyExperienceResourceProvider,
        CompanyProvider,
        CompanySenderProvider,
        CompanySettingsProvider,
        CompanyUrlProvider,
        CompanyUrlProvider2,
        CompanyUserAccessManageProvider,
        CompanyUserAccessProvider,
        CompanyUserPermissionsProvider,
        CompanyUserProvider,
        CompanyCertificatesProvider,
        ContentProvider,
        ConversationProvider,
        CorrectionFactory,
        CourseProvider,
        DiscussionProvider,
        ExternalContentProvider,
        GroupProvider,
        InstanceProvider,
        IntegrationFactory,
        IntegrationProvider,
        JourneyProvider,
        KnowledgeProvider,
        LanguageProvider,
        LineupProvider,
        ManageAttendanceProvider,
        ManageCalendarProvider,
        ManageConversationProvider,
        ManageCorrectionProvider,
        ManageFollowUpProvider,
        ManageInstanceProvider,
        ManageLearnerProvider,
        ManageTeamProvider,
        MediaProvider,
        MentoringProvider,
        OnboardingProvider,
        OverviewProvider,
        ReactionProvider,
        ReportingProvider,
        ScormProvider,
        SkillProvider,
        StatsProvider,
        SubscriptionProvider,
        TemplateProvider,
        TimelineProvider,
        UserProvider,
        UtilityProvider,
        UserContentProvider,
        YoutubeIntegrationProvider,
        StatisticsProvider,
        StatisticsProvider2,
    ],
})
export class LightyModule {}
