<div class="container-contents__main row">
    <div class="col-12 col-xxs-12">
        <msc-shared-topbar-filters [type]="'saved-content'" [(query)]="query" [(filters)]="filters"
            (queryChange)="search()" (filtersChange)="search()"></msc-shared-topbar-filters>

        <div class="container-contents__list row animate" *ngIf="!searching && contents">
            <div class="card-container">
                <content-card *ngFor="let content of contents" [content]="content" [saved]="true"></content-card>
            </div>

            <msc-empty-state *ngIf="contents.length === 0" [title]="'empty.content.search.title'"
                [description]="'empty.content.search.description'">
            </msc-empty-state>
        </div>

        <div class="container-contents__load-more col-12 col-xxs-12">
            <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>

        <msc-common-spinner *ngIf="searching"></msc-common-spinner>
    </div>
</div>