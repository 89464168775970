import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminTemplateGroupProvider, AdminGroupContentProvider, Pagination, Camp } from '@lighty';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-camp-aside-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class CampAsideContentComponent {
    @ViewChild('aside') aside;
    @Input() camp: Camp;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public currentGroup: any;
    public isAllSelected: boolean = false;
    public contents: any;
    public contentsSelected: any[] = [];
    public pagination: any;
    public availableContents: any;
    public ready: boolean = false;
    public loading: boolean = false;
    public query: string;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public display: boolean = false;
    public tab: string = 'list';

    constructor(
        private templateGroupProvider: AdminTemplateGroupProvider,
        private toastService: CommonToastService,
        private groupContentProvider: AdminGroupContentProvider,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.pagination = {
            content: Pagination,
            addContent: Pagination
        };

        this.getContents();
        this.getAvailableContents();
    }

    public onOpen(group?) {
        this.currentGroup = group;
        this.init();
        this.aside.onOpen();
    }

    getContents(): void {
        this.ready = false;
        this.contents = [];
        this.groupContentProvider.getContents(this.currentGroup.id).subscribe(data => {
            this.ready = true;
            this.contents = data.contents;
            this.pagination.content = data.pagination;
        });
    }

    search(query?): void {
        this.query = query;
        if (this.tab === 'contents') {
            this.searchAvailableContents();
        } else {
            const params = HttpHelper.cleanParams({
                q: this.query
            });

            this.groupContentProvider.getContents(this.currentGroup.id, params).subscribe(data => {
                this.contents = data.contents;
                this.pagination.content = data.pagination;
            });
        }
    }

    getAvailableContents(): void {
        this.ready = false;
        this.availableContents = [];
        this.groupContentProvider.getAvailableContents(this.currentGroup.id).subscribe(data => {
            this.ready = true;
            this.availableContents = data.contents;
            this.pagination.addContent = data.pagination;
        });
    }

    searchAvailableContents(): void {
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.groupContentProvider.getAvailableContents(this.currentGroup.id, params).subscribe(data => {
            this.availableContents = data.contents;
        });
    }

    addToGroup(content: any): void {
        const params = {
            context: content.type,
            contextId: content.data.id
        };

        this.groupContentProvider.addToGroup(this.currentGroup.id, params).subscribe(() => {
            this.contents.push(content);
            const index = this.availableContents.findIndex((ct) => {
                return ct.data.id === content.data.id;
            });

            if (index > -1) {
                this.availableContents.splice(index, 1);
            }
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    deleteFromGroup(content: any): void {
        const params = {
            context: content.type,
            contextId: content.data.id
        };

        this.groupContentProvider.deleteFromGroup(this.currentGroup.id, params).subscribe(() => {
            this.availableContents.push(content);
            const index = this.contents.findIndex((ct) => {
                return ct.data.id === content.data.id;
            });

            if (index > -1) {
                this.contents.splice(index, 1);
            }
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    loadMore(type: string): void {
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            page: (type === 'list') ? this.pagination.content.currentPage + 1 : this.pagination.addContent.currentPage + 1
        });

        if (type === 'list') {
            this.groupContentProvider.getContents(this.currentGroup.id, params).subscribe(data => {
                this.loading = false;
                this.contents = this.contents.concat(data.contents);
                this.pagination.content = data.pagination;
            });
        } else {
            this.groupContentProvider.getAvailableContents(this.currentGroup.id, params).subscribe(data => {
                this.loading = false;
                this.availableContents = this.availableContents.concat(data.contents);
                this.pagination.addContent = data.pagination;
            });
        }

    }

    changeTab(tab: string): void {
        this.tab = tab;
        this.query = null;
    }

    addContent(content: any): void {
        const index = this.contentsSelected.findIndex((contentSelected) => contentSelected === content);
        if (index > -1) {
            this.contentsSelected.splice(index, 1);
        } else {
            this.contentsSelected.push(content);
        }
    }

    isContentSelected(content: any): boolean {
        return this.contentsSelected.indexOf(content) > -1 || this.isAllSelected;
    }

    getColorType(content: any): string {
        switch (content.type) {
            case 'external_video':
                return 'blue';
            case 'bookboon':
            case 'audio':
                return 'orange';
            case 'mymooc':
                return 'purple';
            case 'external_resource':
                switch (content.data.type) {
                    case 'course':
                        return 'purple';
                    case 'video':
                        return 'blue';
                    case 'article':
                        return 'orange';
                    case 'other':
                        return 'red';
                }
        }
    }

    getType(content: any): string {
        if (content.type !== 'external_resource') {
            if (content.type === 'external_video') {
                return 'video';
            } else if (content.type === 'bookboon') {
                return 'ebook';
            } else if (content.type === 'mymooc') {
                return 'template';
            } else if (content.type === 'openclassroom') {
                return 'template';
            } else {
                return content.type;
            }
        } else {
            return content.data.type;
        }
    }

    selectAll(): void {
        this.isAllSelected = !this.isAllSelected;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        for (const course of this.contentsSelected) {
            this.addToGroup(course);
        }
        this.back();
    }

    back(): void {
        this.tab = 'list';
    }
}
