import { Pipe, PipeTransform } from '@angular/core';
//
export type UuidAffix = 'prefix' | 'suffix';

@Pipe({
    name: 'uuid',
})

export class UuidPipe implements PipeTransform {
    transform(value?: string, affix?: UuidAffix): string {
        const uuid = Array(4).fill(0).reduce((acc) => [...acc, Math.random().toString(36).replace('0.', '')], []).join('-');

        switch (affix) {
            case 'prefix': return `${value}-${uuid}`;
            case 'suffix': return `${uuid}-${value}`;
            default: return uuid;
        }
    }
}
