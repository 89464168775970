<div class="flex flex-col">
    <div class="slide-external-link__body grow">
        <p class="slide-external-link__paragraph">{{ 'extension.form.intro' | translate }}</p>

        <div class="slide-external-link__form">
            <div class="slide-external-link__fieldset">
                <div class="slide-external-link__input">
                    <msc-input [label]="'URL'" [placeholder]="'placeholder.extension-form-search' | translate" [hasErrors]="wrongURL" [(ngModel)]="extensionLink.url" [debounce]="800" (onDebounce)="onChange.emit()"></msc-input>
                    <p *ngIf="wrongURL" class="color--red">
                        {{ 'extension.form.wrongURL' | translate }}
                    </p>
                </div>
            </div>

            <div class="slide-external-link__fieldset">
                <div class="slide-external-link__input">
                    <div class="input form-select">
                        <label for="type" class="input__label">
                            {{ 'extension.form.type-label' | translate }}
                        </label>

                        <div class="select">
                            <select name="select" id="type" [(ngModel)]="extensionLink.type">
                                <option value="other" selected>{{ 'filter.content-type.other' | translate }}</option>
                                <option value="course">{{ 'filter.content-type.course' | translate }}</option>
                                <option value="video">{{ 'filter.content-type.video' | translate }}</option>
                                <option value="article">{{ 'filter.content-type.article' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="slide-external-link__input">
                    <div class="flex flex-wrap justify-between">
                        <label for="duration" class="input__label w-full">
                            {{ 'extension.form.time-label' | translate }}
                        </label>

                        <input autocomplete="off" type="number" id="duration" name="input" class="input__text w-[45%] h-10" [value]="extensionLink.duration" [(ngModel)]="extensionLink.duration">

                        <div class="select w-[45%]">
                            <select name="duration-unit" id="duration-unit" [(ngModel)]="extensionLink.durationUnit">
                                <option value="minutes" selected>{{ 'words.minutes' | translate }}</option>
                                <option value="hours">{{ 'words.hours' | translate}}</option>
                                <option value="days">{{ 'words.days' | translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex justify-end">
        <msc-button class="msc-button--primary" palette="secondary" (click)="nextPanel()" [loading]="loading" [disabled]="disabled">
            {{ 'words.next' | translate }}
        </msc-button>
    </div>
</div>
