import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { LoaderService, StorageService } from '@services';

/**
 * HTTP Interceptor Service
 * Add Bearer and stop loader service when request has been finished
 */
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    /**
     * Import LoaderService
     * @constructor
     * @param loaderService
     */
    constructor(private loaderService: LoaderService, private storageService: StorageService) { }

    /**
     * Intercept logic
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (sessionStorage.getItem(environment.tokenSessionName)) {
            if (environment.name.short === 'dev' || request.url.indexOf(environment.domain) > -1) {
                request = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + sessionStorage.getItem(environment.tokenSessionName))
                });

                const company = this.storageService.get('company');
                if (company && request.url.indexOf('companies') === -1 && request.url.indexOf('company') === -1 && request.url.indexOf('assets') === -1) {
                    request = request.clone({
                        params: request.params.set('company_id', company.id)
                    });
                }
            }
        }

        if (!request.url.includes('media') && (request.method === 'PUT' || request.method === 'POST')) {
            if (request.body && !(request.body instanceof FormData)) {
                request = request.clone({
                    body: this.getBodyHttp(request.body)
                });
            }
        }

        request = request.clone({
            headers: request.headers.set('Access-Control-Max-Age','3600'),
        });

        return next.handle(request).pipe(
            finalize(() => this.loaderService.stop())
        );
    }

    getBodyHttp(datum: any = {}) {
        return Object.keys(datum).reduce((acc, key) => {
            switch (key) {
                case 'createdAt':
                case 'deletedAt':
                case 'deploymentDoneAt':
                case 'deploymentStartedAt':
                case 'endAt':
                case 'expiresAt':
                case 'lastConnection':
                case 'lastLogCreatedAt':
                case 'lastSync':
                case 'nextEvent':
                case 'registrationEndAt':
                case 'registrationStartAt':
                case 'startAt':
                case 'subscriptionDate':
                case 'subscriptionDeadline':
                case 'synchronizedAt':
                case 'updatedAt':
                case 'validatedAt':
                    if (datum[key] && !isNaN(new Date(datum[key]) as any)) {
                        datum[key] = new Date(datum[key]).toISOString();
                    }
                    break;
            }

            if (datum[key] && typeof datum[key] === 'object' && datum[key] instanceof Object && !Array.isArray(datum[key])) {
                datum[key] = this.getBodyHttp(datum[key]);
            }

            if (datum[key] && typeof datum[key] === 'object' && datum[key] instanceof Array && Array.isArray(datum[key])) {
                datum[key] = (datum[key] as Array<any>).reduce((accTemp, item) => {
                    const d = item instanceof Object ? this.getBodyHttp(item) : item;
                    return [...accTemp, d];
                }, []);
            }

            return { ...acc, [key]: datum[key] };
        }, {});
    }
}
