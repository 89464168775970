<div class="content-experience content-experience--theme">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title box-experience__title--theme">
                <h3>{{ 'theme.title' | translate }}</h3>

                <div class=box-experience__data>
                    <div class="flex align-center">
                        <div>
                            {{ 'theme.available' | translate }} : <span>{{ themes?.length }} / {{ company.colorTemplateLimit }}</span>
                        </div>

                        <div class="box-experience__data__alert" [tooltip]="'theme.tooltip.request' | translate" *ngIf="themes?.length >= company.colorTemplateLimit">
                            <i class="icon icon-alert-triangle"></i>
                        </div>
                    </div>

                    <div class="box-experience__data__actions" *ngIf="themes?.length >= company.colorTemplateLimit">
                        <msc-button class="msc-button--outline" (click)="openModalRequest(modalRequest)">
                            {{ 'theme.increase.request' | translate }}
                        </msc-button>
                    </div>
                </div>
            </div>

            <div class="box-experience__content">
                <div class="content__theme content__theme--left col-6 col-xxs-12">
                    <div class="content__theme__title">
                        <p>
                            {{ 'theme.default' | translate }}

                            <span class="content__theme-activate" [tooltip]="'theme.current' | translate" *ngIf="isActivate(1)"></span>
                        </p>

                        <div class="flex">
                            <div (click)="preview({primaryColor:'#FEB88A', secondaryColor:'#5150C4'})" [tooltip]="'words.preview' | translate">
                                <i class="icon icon-eye"></i>
                            </div>

                            <div (click)="apply({id: 1, name: 'default', stylesheet: 'default.theme.css'})" [tooltip]="'words.apply' | translate">
                                <i class="icon icon-checkmark" [ngClass]="{'icon-theme--green': isActivate(1)}"></i>
                            </div>
                        </div>
                    </div>

                    <div class="content__theme__picture">
                        <div class="content__theme__picture__counter" [tooltip]="getExperiences(defaultTheme.experiences)" direction="left" *ngIf="defaultTheme.experiences">
                            {{ defaultTheme.experiences.length }}
                        </div>

                        <img src="https://static.myskillcamp.com/images/custom-colors/default-theme.svg">
                    </div>

                    <div class="content__theme__menu">
                        <div class="theme__colors__watcher">
                            <div class="watcher">
                                <div class="watcher-color" [ngStyle]="{'background-color': '#FEB88A'}"></div>
                                <div class="watcher-hex">#FEB88A</div>
                            </div>

                            <div class="watcher">
                                <div class="watcher-color" [ngStyle]="{'background-color': '#5150C4'}"></div>
                                <div class="watcher-hex">#5150C4</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content__theme content__theme--right col-6 col-xxs-12">
                    <div class="content__theme__title">
                        <p>
                            {{ 'theme.variant' | translate }}

                            <span class="content__theme-activate" [tooltip]="'theme.current' | translate" *ngIf="isActivate(2)"></span>
                        </p>

                        <div class="flex">
                            <div (click)="preview({primaryColor:'#5150C4', secondaryColor:'#FEB88A'})" [tooltip]="'words.preview' | translate">
                                <i class="icon icon-eye"></i>
                            </div>

                            <div (click)="apply({id: 2, name: 'variant', stylesheet: 'variant.theme.css'})" [tooltip]="'words.apply' | translate">
                                <i class="icon icon-checkmark" [ngClass]="{'icon-theme--green': isActivate(2)}"></i>
                            </div>
                        </div>
                    </div>

                    <div class="content__theme__picture">
                        <div class="content__theme__picture__counter" [ooltip]="getExperiences(variantTheme.experiences)" direction="left" *ngIf="variantTheme.experiences">
                            {{ variantTheme.experiences.length }}
                        </div>

                        <img src="https://static.myskillcamp.com/images/custom-colors/variant-theme.svg">
                    </div>

                    <div class="content__theme__menu">
                        <div class="theme__colors__watcher">
                            <div class="watcher">
                                <div class="watcher-color" [ngStyle]="{'background-color': '#5150C4'}"></div>
                                <div class="watcher-hex">#5150C4</div>
                            </div>

                            <div class="watcher">
                                <div class="watcher-color" [ngStyle]="{'background-color': '#FEB88A'}"></div>
                                <div class="watcher-hex">#FEB88A</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content__theme-row">
                    <div class="content__theme col-6 col-xxs-12" *ngFor="let theme of themes; let index=index" [ngClass]="{'content__theme--left': index % 2 === 0, 'content__theme--right': index % 2 === 1}">
                        <div class="content__theme__title">
                            <p>
                                {{ theme.name }}

                                <span class="content__theme-activate" [tooltip]="'theme.current' | translate" *ngIf="isActivate(theme.id)"></span>
                            </p>

                            <div class="flex">
                                <div [tooltip]="'theme.tooltip.limit-reached' | translate" *ngIf="theme.isDraft">
                                    <i class="icon icon-alert-triangle"></i>
                                </div>
                                <div (click)="preview(theme)" [tooltip]="'words.preview' | translate">
                                    <i class="icon icon-eye"></i>
                                </div>

                                <div (click)="setSelectedTheme(theme); createThemeAside.onOpen(selectedTheme)" [tooltip]="'words.edit' | translate">
                                    <i class="icon icon-edit"></i>
                                </div>

                               <!-- <div (click)="openModalRequest(theme)" [tooltip]="'theme.send-request' | translate"
                                    *ngIf="theme.isDraft && !theme.requestedAt">
                                    <i class="icon icon-add-message"></i>
                               </div> -->

                                <div (click)="apply(theme)" [tooltip]="'words.apply' | translate" *ngIf="!theme.isDraft">
                                    <i class="icon icon-checkmark" [ngClass]="{'icon-theme--green': isActivate(theme.id)}"></i>
                                </div>

                                <div (click)="openModalDelete(modalDelete, theme)" [tooltip]="'words.delete' | translate">
                                    <i class="icon icon-trash icon-theme--red"></i>
                                </div>
                            </div>
                        </div>

                        <div class="content__theme__picture">
                            <div class="content__theme__picture__counter" [tooltip]="getExperiences(theme.experiences)" direction="left" *ngIf="theme.experiences">
                                {{ theme.experiences.length }}
                            </div>

                            <msc-organize-experience-custom-svg [theme]="theme"></msc-organize-experience-custom-svg>

                            <div class="content__theme__picture__banner" *ngIf="theme.isDraft">
                                {{ (theme.requestedAt ? 'theme.request' : 'theme.draft') | translate }}
                            </div>
                        </div>

                        <div class="content__theme__menu">
                            <div class="theme__colors__watcher">
                                <div class="watcher">
                                    <div class="watcher-color" [ngStyle]="{'background-color': theme.primaryColor}"></div>
                                    <div class="watcher-hex">{{ theme.primaryColor }}</div>
                                </div>

                                <div class="watcher">
                                    <div class="watcher-color" [ngStyle]="{'background-color': theme.secondaryColor}"></div>
                                    <div class="watcher-hex">{{ theme.secondaryColor }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content__theme content__theme--new col-6 col-xxs-12" [ngClass]="{'content__theme--left': themes.length % 2 === 0, 'content__theme--right': themes.length % 2 === 1}">
                        <div class="content__theme__title">
                            <p>{{ 'theme.new' | translate }}</p>

                            <div class="flex">
                                <div (click)="setSelectedTheme(null); createThemeAside.onOpen(selectedTheme)" [tooltip]="'words.create' | translate">
                                    <i class="icon icon-settings icon-theme--secondary"></i>
                                </div>
                            </div>
                        </div>

                        <div class="content__theme__picture">
                            <img src="https://static.myskillcamp.com/images/custom-colors/default-theme.svg">

                            <div class="content__theme__overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-organize-experience-aside-create-template #createThemeAside (onSave)="process($event)"></msc-organize-experience-aside-create-template>

<msc-common-modal size="30vw" #modalDelete [title]="'words.delete' | translate">
    <ng-template>
        <p>{{ 'theme.modal.delete.desc' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDelete.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="delete(); modalDelete.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalRequest [title]="'theme.send-request' | translate">
    <ng-template>
        <p>{{ 'theme.modal.request.desc' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalRequest.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="request(); modalRequest.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
