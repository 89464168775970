<div class="login__body box box--shadow">
    <div class="reset__content">
        <i class="icon icon-checkmark-1 bullet-success"></i>
        <h2>{{ 'auth.success' | translate }}</h2>
        <p>{{ 'words.reset-password.success' | translate }}</p>

        <msc-button [routerLink]="['/login']" class="msc-button--primary" palette="primary">
            {{ 'words.continue' | translate }}
        </msc-button>
    </div>
</div>
