import { Injectable } from '@angular/core';
import { ApplicationProvider, Company } from '@lighty';
import { StorageService } from './storage.service';
import { ScriptInjectorService } from './script-injector.service';
import { find } from '@functions';
import { environment } from '@env/environment';
import { JobService } from './job.service';
import { DataHelper } from '@helpers';
import * as Sentry from '@sentry/angular';

declare const zE: any;
interface Me {
    id: string;
    uuid: string;
    name: string;
    email: string;
    firstname: string;
    lastname: string;
    language: {
        code: string;
        name: string;
    };
    timezone: string;
    department: string;
    learningLanguages: Array<{ name: string }>;
    zendeskJwt: string;
}

/**
 * External Apps Service
 */
@Injectable({ providedIn: 'root' })
export class ExternalAppsService {
    /**
     * me - Object contains account information
     */
    private me: Me;
    /**
     * company - Object contains company information
     */
    private company: Company;
    /**
     * Booted - Object to boot
     */
    private booted: any;
    /**
     * appIds - Id from object to boot
     */
    private appIds: any;

    /**
     * Init Object to boot and appIds
     * @constructor
     */
    constructor(
        private applicationProvider: ApplicationProvider,
        private storageService: StorageService,
        private scriptInjectorService: ScriptInjectorService,
        private jobService: JobService,
    ) {
        this.booted = {
            zendesk:false,
            sentry: false,
            beamer: false,
        };

        this.appIds = {
            beamer: environment.integrations.beamer
        };
    }

    /**
     * Boot specific apps
     * @param {Me} me - Current user
     * @param {Company} company - Current company
     * @param {any} access - Current access
     * @param {string} role - Current role
     * @param {any} applications - Applications system
     */
    boot(me: Me, company: Company, access: any, role?: string, applications?: any): void {
        this.me = me;
        this.company = company;

        if (environment.name.short !== 'local') {
            this.bootSentry();
            this.bootAppcues(me, company, applications);
        }

       // this.bootZendesk();
        this.bootBeamer(me, company, access);
    }

    private bootZendesk(){
        this.booted.zendesk = true;
    }

    /**
     * Boot sentry system
     */
    bootSentry(): void {
        if (!this.booted.sentry) {
            this.booted.sentry = true;

            Sentry.setUser(
                {
                    id: this.me.id,
                    username: this.me.name,
                    email: this.me.email
                }
            );
        }
    }

    /**
     * Boot appcues
     * @param {Me} me - Current user
     * @param {Company} company - Current company
     * @param {any} applications - Applications system
     */
    private bootAppcues(me: Me, company: Company, applications: any): void {
        if (!this.booted.appcues) {
            this.booted.appcues = true;
            this.scriptInjectorService.appcues();
            const timeOut = setTimeout(() => {
                if ((window as any).Appcues) {
                    (window as any).Appcues.identify(me.id, {
                        name: me.name,
                        email: me.email,
                        created_at: new Date().getTime(),
                        language: me.language.code,
                        companySlug: company.slug,
                        isEnabled: true
                    });
                }
                clearTimeout(timeOut);
            }, 3000);
        }
    }

    /**
     * Boot Beamer
     * @param {Me} me - Current user
     * @param {Company} company - Current company
     * @param {any} access - Current access
     */
    public bootBeamer(me: Me, company: Company, access: any): void {
        if (!this.booted.beamer) {
            this.booted.beamer = true;

            access = DataHelper.filterCheckbox(access, ',');
            const filters = ['learner'];

            if (access.indexOf('companyLearner') !== -1) {
                filters.push('learner-only');
            }

            if (access.indexOf('companyAuthor') !== -1 || access.indexOf('companyManager') !== -1 || access.indexOf('companyAdministrator') !== -1) {
                filters.push('super-user');
            }

            if (access.indexOf('companyOwner') !== -1) {
                filters.push('super-admin');
            }

            const timeOut = setTimeout(() => {
                this.scriptInjectorService.beamer(this.appIds.beamer, me, company?.id, filters.join(','));
                clearTimeout(timeOut);
            }, 3000);
        }
    }

    /**
     * Is booted system
     * @params {string} key - Key
     * @return {boolean} - Value
     */
    isBooted(key: string): boolean {
        return this.booted[key];
    }

    /**
     * Unboot system
     * @params {string} key - Key
     */
    unboot(key: string): void {
        this.booted[key] = false;
    }

    /**
     * Appues management
     * @return {any} Appcues system
     */
    appcues(): any {
        return (window as any).Appcues;
    }

    /**
     * Freshchat management
     * @param {string} action - Available action
     */
    freshchat(action: string): void {
        if (this.booted.freshchat) {
            switch (action) {
                case 'open':
                    (window as any).fcWidget.open();
                    break;
                case 'shutdown':
                    this.booted.freshchat = false;
                    find('#custom-app').innerHTML = '';
                    (window as any).fcWidget.destroy();
                    break;
            }
        }
    }

        /**
     * Freshchat management
     * @param {string} action - Available action
     */
    zendesk(action: string): void {
      /*  if (this.booted.zendesk) {
            switch (action) {
                case 'open':
                    const token = this.me?.zendeskJwt;
                    zE('messenger', 'loginUser', function (callback) { callback(token) });
                    zE('messenger:set', 'cookies', true);
                     setTimeout(() => {
                         zE('messenger', 'open');
                     }, 1000);
                    break;
                case 'close':
                    zE('messenger:set', 'cookies', false);
                    break;
            }
        }*/
    }

    /**
     * Beamer management
     * @return {any} beamer system
     */
    beamer(): any {
        return (window as any).Beamer;
    }
}
