import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthProvider, Auth } from '@lighty';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { StorageService } from './storage.service';
import { UrlHelper } from '@helpers';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Auth Service
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly TOKEN_TEMP_SESSION: string = 'token_temp_session';
    private lock: boolean = false;

    /**
     * Init listener
     * @constructor
     * @param cookieService
     * @param authProvider
     * @param refreshAuthService
     * @param router
     * @param cacheService
     */
    constructor(private cookieService: CookieService, private authProvider: AuthProvider,
        private cacheService: CacheService, private storageService: StorageService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    refresh(): Observable<any> {
        const refreshToken = this.cookieService.get(environment.refreshTokenName);
        return this.authProvider.refresh(refreshToken).pipe(
            map((auth: Auth) => {
                this.createCookies(auth);
                return auth;
            }),
            catchError((err) => {
                this.logout(true);
                return throwError(err);
            })
        );
    }

    /**
     * Log user in application
     * @param {any} credentials - User credentials
     * @return {Observable<any>} - Observable from watch
     */
    login(credentials: any): Observable<any> {
        this.clearCookies();
        return this.authProvider.login(credentials).pipe(
            map((auth: Auth) => {
                if (auth.forcedPasswordReset) {
                    this.createTemporarySession(auth);
                }
                this.createCookies(auth);
                return auth;
            })
        );
    }

    /**
     * Define current session as temporary session
     * @param {Auth} auth
     */
    private createTemporarySession(auth: Auth): void {
        this.cacheService.set(this.TOKEN_TEMP_SESSION, auth.forcedPasswordReset);
    }

    /**
     * Create cookie with user data
     * @param {Auth} data - Data authentication
     */
    public createCookies(data: Auth): void {
        this.clearCookies();
        this.cookieService.set(environment.tokenName, data.access_token, 7, '/', UrlHelper.getDomain(), false, 'Lax');
        this.cookieService.set(environment.refreshTokenName, data.refresh_token, 7, '/', UrlHelper.getDomain(), false, 'Lax');
        sessionStorage.setItem(environment.tokenSessionName, data.access_token);
    }

    public clearCookies(): void {
        this.cookieService.delete(environment.tokenName, '/', `.${UrlHelper.getDomain()}`, false);
        this.cookieService.delete(environment.refreshTokenName, '/', `.${UrlHelper.getDomain()}`, false);
        sessionStorage.removeItem(environment.tokenSessionName);
    }

    /**
     * Logout user from app
     * @param {boolean} force - Force logout from app
     */
    logout(force?: boolean): void {
        const company = this.storageService.get('company');
        let url = 'login';
        let urlParams = '';
        const ssoKey = localStorage.getItem('ssoKey');

        if (company) {
            url += '?company=' + company.slug;
            urlParams += '?company=' + company.slug;
            if (company.experience) {
                url += '&experience=' + company.experience?.slug;
                urlParams += '&experience=' + company.experience?.slug;
            }
        }

        if (!force) {
            this.authProvider.logout().subscribe(() => {
                if(ssoKey) {   
                   window.location.replace(`sso/login/${ssoKey}${urlParams}`);
                } else {
                    window.location.replace(url);
                }
            });
        }

        if (force) {
            if(ssoKey) {   
                window.location.replace(`sso/login/${ssoKey}${urlParams}`);
            } else {
                window.location.replace(url);
            }
        }
        
        this.cookieService.deleteAll('/', `.${UrlHelper.getDomain()}`, false, 'Lax');
        this.cookieService.deleteAll('/', `.${location.hostname.split('.').slice(-2).join('.')}`, false, 'Lax');
        sessionStorage.clear();
        localStorage.clear();
    }

    /**
     * Checks if user is looged or not
     * @return {boolean} - Logged status
     */
    isLogged(): boolean {
        if (this.cookieService.get(environment.tokenName) && !this.isTemporarySession()) {
            sessionStorage.setItem(environment.tokenSessionName, this.cookieService.get(environment.tokenName));
            return true;
        }
        return false;
    }

    /**
     * Check if current logged user is with temporary session
     * @return {boolean} - Temporary session status
     */
    isTemporarySession(): boolean {
        return this.cacheService.get(this.TOKEN_TEMP_SESSION);
    }

    /**
     * Close a temporary session by session storage clearing
     */
    removeTemporarySession(): void {
        this.cacheService.remove(this.TOKEN_TEMP_SESSION);
    }

    /**
     * Find the best authenticate method, used by SSO system
     * @param {any} params - Parameters
     * @return {Observable<any>} Observable from watch
     */
    authenticateMethod(params: any): Observable<any> {
        return this.authProvider.authenticateMethod(params);
    }

    //get Sso items
    public getSso(params: any): Observable<any> {
        return this.authProvider.getSso(params);
    }

    //validate account with sso
   public validateSsoAccount(params: any): Observable<any> {
        return this.authProvider.validateSsoAccount(params);
    }
    //validate account with sso and custom url
    public validateCustomUrlSso(params: any): Observable<any> {
        return this.authProvider.validateCustomUrlSso(params);
    }
}
