import { Component, Input, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { TimelineProvider, Media, Camp, Pagination, CompanyProvider } from '@lighty';
import { EmitterService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { SubscriptionCollection } from '@classes';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-timeline',
    templateUrl: './timeline.component.html'
})
export class TimelineComponent implements OnInit, OnDestroy {
    @Input() me: any;
    @Input() context: string;
    @Input() contextId: number;
    @Input() manage: boolean = false;
    @Input() displayCamp: boolean = false;
    @Input() dynamic: boolean = true;
    @Input() headerFilters: boolean = false;
    @Input() show: string = 'all';
    @Input() small: boolean = false;
    @Input() isDashboard: boolean = false;
    @Input() options: any = {};
    @Input() instanceId: number;
    @Input() newPost: boolean = false;
    @Input() customParams: any;
    private company: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public roles: any;
    public timelineId: number;
    public camps: Camp[];
    public pagination: Pagination;
    public message: string;
    public items: any[] = [];
    public selectedCamp: Camp;
    public loading: boolean = false;
    public ready: boolean = false;
    public displayComposer: boolean = false;
    public unlock: EventEmitter<boolean> = new EventEmitter();
    public meta: any;
    public asides: any;
    public loadingMeta: boolean = false;
    public timelineFiltered: boolean = false;
    public modalEnable: boolean = false;
    public displayPost: boolean = false;

    constructor(
        private timelineProvider: TimelineProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private companyProvider: CompanyProvider,
        private externalAppsService: ExternalAppsService,
        private emitterService: EmitterService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        if (!this.me) {
            this.me = this.storageService.get('me');
        }
        this.company = this.storageService.get('company');

        this.asides = {
            filters: false
        };

        this.getRoles();

        this.subscriptionCollection.subscribe = this.emitterService.get('timeline.modal').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.modalEnable = data;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    get isAdmin() { return this.getIsAdmin(); }

    getRoles(): void {
        this.companyProvider.getRoles(this.company.slug).subscribe((data) => {
            this.roles = data.roles;
            this.getTimeline();
        });
    }

    getTimeline(): void {
        if (this.context === 'company') {
            this.timelineProvider.getSpecificTimeline(this.context, this.contextId).subscribe((data) => {
                this.pagination = data.pagination;
                this.camps = data.camps.filter((camp) => camp.post);
                this.timelineId = data.timelineId;
                this.items = data.items;
                this.displayComposer = data.post;
                this.ready = true;
            });
        } else if (this.context === 'course' || this.context === 'traject') {
            this.ready = false;
            this.displayComposer = true;

            const params = HttpHelper.cleanParams({
                context: this.context,
                context_id: this.contextId,
                show: this.show
            });

            this.timelineProvider.getMergedTimelines(params).subscribe((data) => {
                this.pagination = data.pagination;
                this.timelineId = data.timelineId;
                this.items = data.items;
                this.ready = true;
                this.timelineFiltered = false;
                this.getTimelineMeta(this.timelineId);
            });
        } else {
            this.displayComposer = true;

            const params = {
                context: this.context,
                context_id: this.contextId,
                company_id: this.company.id
            };

            this.timelineProvider.getTimelineId(params).subscribe((data) => {
                this.timelineId = data.id;
                this.timelineProvider.getTimeline(this.timelineId).subscribe((timeline) => {
                    this.pagination = timeline.pagination;
                    this.items = timeline.items;
                    this.ready = true;
                });
            });
        }
    }

    getTimelineMeta(timelineId: number): void {
        this.loadingMeta = true;

        this.timelineProvider.getMeta(timelineId).subscribe((data) => {
            this.loadingMeta = false;
            this.meta = data.metas;
        });
    }

    send(media?: Media): void {
        if (this.message || media) {
            const params = this.buildParams(media);

            this.timelineProvider.send(params).subscribe((data) => {
                this.items.unshift(data);
                this.message = null;
                this.unlock.emit(true);
            });
        } else {
            this.toastService.onWarning(this.translateService.instant('toast.message-required'));
            this.unlock.emit(true);
        }
    }

    private buildParams(media?: any): any {
        const params = {
            timeline_id: this.timelineId,
            content: {
                message: this.message,
                media_id: media ? media.id : null
            },
            type: 'mixed',
            company_id: this.company.id,
            context: this.context,
            context_id: this.contextId
        };

        if (this.camps) {
            params.timeline_id = null;
            params['context'] = 'camp';
            params['context_id'] = this.selectedCamp.id;
            params['camp_id'] = this.selectedCamp.id;
        }

        if (this.options.notifyLearners) {
            params['notify_learners'] = true;
        }

        switch (this.context) {
            case 'camp':
                params['camp_id'] = this.contextId;
                break;
            case 'group':
                params['group_id'] = this.contextId;
                break;
            case 'course':
                params['instance_id'] = this.contextId;
                break;
            case 'question-session':
                params['instance_id'] = this.instanceId;
                break;
            case 'traject':
                params['traject_id'] = this.contextId;
                break;
            case 'external_content':
                params['external_content_id'] = this.contextId;
                break;
        }

        return HttpHelper.cleanParams(params);
    }

    deletePost(item: any): void {
        this.timelineProvider.deleteItem(this.timelineId, item.id).subscribe(() => {
            const index = this.items.findIndex((data) => {
                return data.id === item.id;
            });

            if (index !== -1) {
                this.items.splice(index, 1);
                this.toastService.onSuccess(this.translateService.instant('toast.message-deleted'));
            }
        });
    }

    selectCamp(camp: Camp): void {
        this.selectedCamp = camp;
    }

    loadMore(): void {
        this.loading = true;

        const params = HttpHelper.cleanParams({
            context: this.context,
            context_id: this.contextId,
            show: this.show,
            page: this.pagination.currentPage + 1
        });

        if (this.context === 'company') {
            this.timelineProvider.getSpecificTimeline(this.context, this.contextId, params).subscribe((data) => {
                this.pagination = data.pagination;
                this.items = this.items.concat(data.items);
                this.loading = false;
            });
        } else {
            this.timelineProvider.getMergedTimelines(params).subscribe((data) => {
                this.pagination = data.pagination;
                this.items = this.items.concat(data.items);
                this.loading = false;
            });
        }
    }

    filterTimeline(event: any): void {
        this.displays('filters');
        this.displayComposer = true;
        this.ready = false;

        // if (event.context === 'exercise') {
        const params = {
            context: event.context,
            context_id: event.contextId,
            show: 'all'
        };

        this.timelineProvider.getMergedTimelines(params).subscribe((data) => {
            this.pagination = data.pagination;
            this.timelineId = data.timelineId;
            this.items = data.items;
            this.ready = true;
            this.timelineFiltered = true;
        });
        // } else {
        //     const params = {
        //         context: event.context,
        //         context_id: event.contextId,
        //         company_id: this.company.id
        //     };
        //
        //     this.timelineProvider.getTimelineId(params).subscribe((data) => {
        //         this.timelineProvider.getTimeline(data.id).subscribe((timeline) => {
        //             this.pagination = timeline.pagination;
        //             this.items = timeline.items;
        //             this.timelineFiltered = true;
        //             this.ready = true;
        //         });
        //     });
        // }
    }

    displays(type: string): void {
        this.asides[type] = !this.asides[type];
    }

    resetTimeline(): void {
        this.getTimeline();
    }

    isCompanyAdmin(): boolean {
        return this.roles.companyAdministrator || this.roles.companyOwner;
    }

    getIsAdmin(): boolean {
        const team = this.me.teams.find((team: any) => team.id === this.contextId);
        return (() => {
            if (!team) { return false; }
            return team.roles.includes('group_owner');
        })() || this.isCompanyAdmin();
    }

    canDisplayComposer(): boolean {
        if (this.isCompanyAdmin()) {
            return true;
        } else {
            if (this.isDashboard) {
                return this.camps.length > 0;
            }
            return true;
        }
    }

    displayedPost(): void {
        this.displayPost = !this.displayPost;
    }
}
