import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company, ContentProvider, KnowledgeSkill } from '@lighty';
import { EmitterService, StorageService, TranslateService } from '@services';
import { DataHelper } from '@helpers';

const FILTER_DATE_OPTIONS = (translateService)=>{
    return [{
        label: translateService.instant('words.addition-date'),
        value: '-date',
    }, {
        label: 'A-Z',
        value: 'title'
    }, {
        label: 'Z-A',
        value: '-title'
    }]
};

@Component({
    selector: 'msc-shared-topbar-filters',
    templateUrl: './topbar-filters.component.html',
    styleUrls: ['./topbar-filters.component.scss']
})
export class SharedTopbarFiltersComponent implements OnInit, AfterViewInit {
    @Input() type: string;
    @Input() color: string = '';
    @Input() query: string;
    @Input() mode: string;
    @Input() filters: any;
    @Input() activeFilters: boolean = false;
    @Input() buildFilters: EventEmitter<any> = new EventEmitter();
    //
    @Output() queryChange: EventEmitter<any> = new EventEmitter();
    @Output() modeChange: EventEmitter<any> = new EventEmitter();
    @Output() filtersChange: EventEmitter<any> = new EventEmitter();
    @Output() activeFiltersChange: EventEmitter<any> = new EventEmitter();
    //
    private me: any;
    private company: Company;
    private availableSkills: KnowledgeSkill[];
    public availableTypes: any[] = [];
    public availableLanguages: any[] = [];
    public availableProviders: any[];
    public filtersModal: any;
    public slider: any;
    public tempSlider: any;
    public dates: any;
    public orderDate: any = '-date';
    public selectOrderOptions: any[] = [];

    constructor(
        private contentProvider: ContentProvider,
        private emitterService: EmitterService,
        private router: Router,
        private storageService: StorageService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');

        this.selectOrderOptions = this.getSelectOrderOptions(FILTER_DATE_OPTIONS(this.translateService));

        this.getMeta();
        this.initSlider();

        this.buildFilters.subscribe((filters) => {
            this.activeFilters = true;
            this.activeFiltersChange.emit(this.activeFilters);
            DataHelper.merge(this.filters, filters);
            this.filtersChange.emit(this.filters);
        });
    }

    ngAfterViewInit(): void {
        const timeOut = setTimeout(() => {
            this.getStoredFilters();
            clearTimeout(timeOut);
        }, 150);
    }

    getOrderDate(): string {
        switch (this.orderDate) {
            case '-date':
                return this.translateService.instant('words.addition-date');
            case 'title':
                return 'A-Z';
            case '-title':
                return 'Z-A';
        }
    }

    getSelectOrderOptions(data) {
        return data.map((d, index) => {
            const id = index;
            const label = d.label;
            const value = d.value;
            const isSelected = d.label === this.getOrderDate();
            return { id, label, value, isSelected };
        });
    }

    getSubTypesOptions(data: any, key: string) {
        const subtypes = data[`${key}Types`];
        if (!subtypes) {
            return [];
        }

        return Object.keys(subtypes)
            .map((subtype) => ({
                id: `type-${key}-subtype-${subtype}`,
                label: this.translateService.instant(`words.course-type.${subtype}`),
                value: subtype,
                isSelected: this.filters[`${key}Types`]?.includes(subtype)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getTypesOptions(data: any) {
        return Object.keys(data.contentTypes)
            .map((type) => ({
                id: `type-${type}`,
                label: this.translateService.instant(`api.content-type.${type.split(/(?=[A-Z])/).join('_').toLowerCase()}`),
                value: type.split(/(?=[A-Z])/).join('_').toLowerCase(),
                isSelected: this.filters.contentTypes?.includes(type.split(/(?=[A-Z])/).join('_').toLowerCase()),
                children: this.getSubTypesOptions(data, type)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getProvidersOptions(providers) {
        const providerIds = this.filters?.providers ? this.filters.providers : [];
        const label = this.company.experience
            ? this.company.experience.displayName
                ? this.company.experience.displayName
                : this.company.experience.name
            : this.company.name;

        return providers.map(provider => ({
            label: provider.id === 'company' ? label : provider.name,
            value: provider.id,
            isChecked: providerIds.includes(provider.id)
        }));
    }

    getLanguagesOptions(languages) {
        const languageIds = this.filters?.langs ? this.filters.langs : this.me.learningLanguages.map(lang => lang.id);

        return languages.map((lang, idx) => ({
            id: idx,
            label: lang.id === 'undefined' ? this.translateService.instant('words.multilingual') : lang.name,
            value: lang.id,
            isSelected: languageIds.includes(lang.id)
        }));
    }

    private getMeta(): void {
        this.contentProvider.getMeta(this.type).subscribe((meta) => {
            this.availableSkills = meta.contentSkills;
            this.availableTypes = this.getTypesOptions(meta);
            this.availableLanguages = this.getLanguagesOptions(meta.languages);
            this.availableProviders = this.getProvidersOptions(meta.providers);

            this.setFilters();
        });
    }

    private getStoredFilters(): void {
        const filters = this.storageService.getAllCache().filter((record: any) => {
            return record.key.match(this.type + '.filter.');
        });

        for (const filter of filters) {
            switch (filter.key) {
                case this.type + '.filter.order':
                    this.orderDate = filter.data;
                    this.filters = DataHelper.merge(this.filters, { order: this.orderDate });
                    break;
                case this.type + '.filter.slider':
                    const translate = this.slider.translate;
                    this.slider = filter.data;
                    this.slider.translate = translate;
                    this.tempSlider = DataHelper.clone(this.slider, null, true);
                    this.tempSlider.translate = this.slider.translate;
                    this.filters = DataHelper.merge(this.filters, { slider: this.slider });
                    break;
                case this.type + '.filter.dates':
                    this.dates = filter.data;
                    this.filters = DataHelper.merge(this.filters, { dates: this.dates });
                    break;
                default:
                    this.filters = DataHelper.merge(this.filters, { [filter.key.replace(this.type + '.filter.', '')]: filter.data });
            }
        }

        if (filters?.length > 0) {
            this.activeFilters = true;
            this.activeFiltersChange.emit(this.activeFilters);
            this.filtersChange.emit(this.filters);
        }
    }

    private initSlider(): void {
        this.slider = {
            minValue: 0,
            maxValue: 100,
            values: {
                min: 2,
                max: 24
            },
            step: 5,
            stepBottom: true,
            displayStepValue: true,
            customValues: [2, 4, 8, 16, 24],
            displayedValues: {
                2: '<2',
                4: '1/2',
                8: '1',
                16: '2',
                24: '3+'
            },
            translate: (value: string): string => {
                let translate = 'h';
                switch (value) {
                    case '1/2':
                    case '1':
                        translate = this.translateService.instant('words.day').toLowerCase();
                        break;
                    case '2':
                    case '3+':
                        translate = this.translateService.instant('words.days').toLowerCase();
                        break;
                }

                return value + ' ' + translate;
            }
        };
    }

    private setFilters(): void {
        this.filtersModal = [
            {
                name: 'langs',
                type: 'multiselect',
                label: this.translateService.instant('words.languages'),
                displayValue: this.availableLanguages.filter(lang => lang.isSelected).map(lang => lang.label).join(', '),
                options: this.availableLanguages
            },
            {
                name: 'providers',
                type: 'checkbox',
                label: this.translateService.instant('words.providers'),
                options: this.availableProviders
            },
            {
                name: 'skill',
                type: 'skill',
                label: this.translateService.instant('words.skill'),
                selected: { skill: null, level: [] },
                query: null,
                displayLimit: 12,
                options: this.availableSkills
            }
        ];
    }

    private storeFilters(): void {
        this.removeStoredFilters();
        for (const key in this.filters) {
            if (this.filters.hasOwnProperty(key)) {
                if (key !== 'skill') {
                    if (!DataHelper.isEmpty(this.filters[key])) {
                        this.storageService.setCache(this.type + '.filter.' + key, this.filters[key], 10);
                    }
                }
            }
        }
    }

    private removeStoredFilters(): void {
        const filters = this.storageService.getAllCache().filter((record: any) => {
            return record.key.match(this.type + '.filter.');
        });

        for (const filter of filters) {
            this.storageService.deleteCache(filter.key);
        }
    }

    getIterativeCheck(parent: any) {
        parent.children = parent.children.map((child: any) => {
            child.isSelected = parent.isSelected;
            return child;
        });
        return parent;
    }

    getSubTypesFilters(key: string) {
        const subTypes = this.availableTypes.find(t => t.value === key && t.isSelected);

        return subTypes ? subTypes.children.filter(t => t.isSelected).map(t => t.value) : [];
    }

    saveFilters(): void {
        this.filters = DataHelper.merge(this.filters, { contentTypes: this.availableTypes.filter(t => t.isSelected).map(t => t.value) });
        this.filters = DataHelper.merge(this.filters, { courseTypes: this.getSubTypesFilters('course') });
        this.filters = DataHelper.merge(this.filters, { externalContentTypes: this.getSubTypesFilters('external_content') });
        this.filters = DataHelper.merge(this.filters, { externalResourceTypes: this.getSubTypesFilters('external_resource') });
        this.filters = DataHelper.merge(this.filters, { dates: this.dates });
        this.filters = DataHelper.merge(this.filters, { slider: this.slider });

        this.activeFilters = true;
        this.activeFiltersChange.emit(this.activeFilters);
        this.filtersChange.emit(this.filters);

        this.storeFilters();
    }

    selectOrderDate(order: string): void {
        this.orderDate = order;

        this.activeFilters = true;
        this.activeFiltersChange.emit(this.activeFilters);
        this.filters = DataHelper.merge(this.filters, { order: this.orderDate });
        this.filtersChange.emit(this.filters);
        this.storeFilters();
    }

    onSelectOption(option) {
        this.selectOrderOptions = this.selectOrderOptions.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });

        this.selectOrderDate(option.value);
    }

    getCheckboxType(type) {
        switch (true) {
            case type.children.every(subtype => subtype.isSelected): 
                type.isSelected = true;
                return false;
            case type.children.some(subtype => subtype.isSelected):
                type.isSelected = true;
                return true;
            default:
                type.isSelected = false;
                return true;
        }
    }

    onSelectType(option) {
        option.isSelected = !option.isSelected        
        
        if (option.children) {
            this.getIterativeCheck(option);
        }  else {
            const selectedType = this.availableTypes.find(type => {
                return type.children.find(subtype => subtype.id.includes(option.id))
            });
            selectedType.isSelected = selectedType.children.some(subtype => subtype.isSelected);
        }

        this.saveFilters();
    }

    resetDropdownFilters(): void {
        this.availableTypes = this.availableTypes.map(type => {
            type.isSelected = false
            type.children.map(subtype => {
                subtype.isSelected = false;
                return subtype;
            });
            return type;
        });

        this.selectOrderOptions = this.getSelectOrderOptions(FILTER_DATE_OPTIONS(this.translateService));
    }

    onFilterReset(): void {
        this.query = null;
        this.dates = null;
        this.filters = {};
        this.orderDate = '-date';
        this.activeFilters = false;
        this.activeFiltersChange.emit(this.activeFilters);
        this.filtersChange.emit(this.filters);
        this.initSlider();
        this.resetDropdownFilters();
        this.removeStoredFilters();
    }

    onFilterSave(data): void {
        this.filters = DataHelper.merge(this.filters, data);
        this.saveFilters();
    }

    onDurationOpen() {
        this.tempSlider = DataHelper.clone(this.slider, null, true);
        this.tempSlider.translate = this.slider.translate;
    }

    saveDuration(): void {
        this.slider = this.tempSlider;
        this.saveFilters();
    }

    search(query?): void {
        this.query = query;
        this.queryChange.emit(this.query);
    }

    changeMode(): void {
        this.mode = this.mode === 'list' ? 'calendar' : 'list';
        this.modeChange.emit(this.mode);
    }

    navigateSavedContent(): void {
        this.router.navigate(['learn', this.company.slug, 'contents', 'saved']);
    }

    toggleExtension(): void {
        this.emitterService.toggleExtension.emit();
    }
}
