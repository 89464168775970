export class ColorHelper {

    public static hexToRGB(color: string, alpha: number = 1): string {
        const red = parseInt(color.slice(1, 3), 16);
        const green = parseInt(color.slice(3, 5), 16);
        const blue = parseInt(color.slice(5, 7), 16);

        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    }

    public static lightenDarken(color: string, amount: number = 0): string {
        let red = parseInt(color.slice(1, 3), 16) + amount;
        let green = parseInt(color.slice(3, 5), 16) + amount;
        let blue = parseInt(color.slice(5, 7), 16) + amount;

        if (red > 255) {
            red = 255;
        } else if (red < 0) {
            red = 0;
        }

        if (green > 255) {
            green = 255;
        } else if (green < 0) {
            green = 0;
        }

        if (blue > 255) {
            blue = 255;
        } else if (blue < 0) {
            blue = 0;
        }

        return `rgb(${red}, ${green}, ${blue})`;
    }
}
