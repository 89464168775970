<div class="content-summary__main row animate">
    <div class="col-8 col-md-8 col-xxs-12 journey-timeline">
        <div class="journey-timeline__item" *ngFor="let content of journey.contents; let index=index" [ngClass]="{'journey-timeline__item--success' : (content.session?.progress === 100) && !content.isLocked,
             'block-task': content.context === 'content', 'block-module': content.context === 'course' || content.context === 'external_content', 'block-external-content': content.context === 'external_content',
             'block-task--locked' : content.context === 'content' && (content.isLocked || journey.isLocked),
             'journey-timeline__item--locked' : content.context === 'course' && (content.isLocked || journey.isLocked),
             'journey-timeline__card-quizz' : content.context === 'content' && content.playerContext === 'exercise',
             'journey-timeline__item--progress': content.session && content.session.progress < 100,
             'journey-timeline__item--last': index === journey.contents.length - 1,
             'active' : dropdownExerciseContent[index], 'first-block': index === 0}"
            [attr.data-timeline]="content.session ? content.session.progress ? content.session.progress + '%' : '0%' : '0%'">
            <div class="block-module__wrapper" *ngIf="content.context === 'course'">
                <div class="block-module__image"
                    [ngStyle]="{'background-image': 'url(' + (content.data.picture ? content.data.picture + '?size=512' : 'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.data.id % 7 + '.jpg') + ')'}">
                    <msc-tag [text]="'words.template' | translate"></msc-tag>
                </div>

                <div class="block-module__desc">
                    <div class="module__skills">
                        <div class="skill skill--level" *ngFor="let skill of content.skills | slice:0:2">
                            <div class="skill__content">
                                <div class="skill__level">
                                    <span [ngClass]="{'active': skill.level > 0}"></span>
                                    <span [ngClass]="{'active': skill.level > 1}"></span>
                                    <span [ngClass]="{'active': skill.level > 2}"></span>
                                </div>

                                <p class="skill__text">{{ skill.name | truncate:7 }}</p>
                            </div>
                        </div>

                        <div class="skill skill--more" *ngIf="content.skills.length > 2">
                            <div class="skill__content">
                                <div class="skill__text"></div>
                            </div>
                        </div>
                    </div>

                    <div class="block-module__title">{{ content.data.title || ('words.untitled' | translate) }}</div>

                    <div class="block-module__widgets" *ngIf="content.data.resume">
                        <div class="block-module__stats">
                            <span class="session__activity color-gray" *ngIf="content.data.resume.media">
                                <span [tooltip]="'stats.content.type-media' | translate">
                                    <i class="icon icon-play-circle"></i>{{ content.data.resume.media }}
                                </span>
                            </span>

                            <span class="session__activity color-gray" *ngIf="content.data.resume.certificate">
                                <span [tooltip]="'stats.content.type-certificate' | translate">
                                    <i class="icon icon-award"></i>{{ content.data.resume.certificate }}
                                </span>
                            </span>

                            <span class="session__activity color-gray" *ngIf="content.data.resume.exchange">
                                <span [tooltip]="'stats.content.type-exchange' | translate">
                                    <i class="icon icon-message-circle"></i>{{ content.data.resume.exchange }}
                                </span>
                            </span>

                            <span class="session__activity color-gray" *ngIf="content.data.resume.project">
                                <span [tooltip]="'stats.content.type-project' | translate">
                                    <i class="icon icon-clipboard"></i>{{ content.data.resume.project }}
                                </span>
                            </span>

                            <span class="session__activity color-gray" *ngIf="content.data.resume.cheatsheet">
                                <span [tooltip]="'stats.content.type-cheatsheet' | translate">
                                    <i class="icon icon-file-text"></i>{{ content.data.resume.cheatsheet }}
                                </span>
                            </span>

                            <span class="session__activity color-gray" *ngIf="content.data.resume.exercise">
                                <span [tooltip]="'stats.content.type-exercise' | translate">
                                    <i class="icon icon-checkmark-square"></i>{{ content.data.resume.exercise }}
                                </span>
                            </span>

                            <span class="session__activity color-gray" *ngIf="content.data.resume.event">
                                <span [tooltip]="'stats.content.type-event' | translate">
                                    <i class="icon icon-calendar"></i>{{ content.data.resume.event }}
                                </span>
                            </span>
                        </div>

                        <msc-button class="msc-button--primary" palette="secondary"
                            (click)="redirectActivityToPlayer(content)" icon="icon-arrow-right"
                            *ngIf="!content.isLocked && content.subscriptionCourseId">
                            {{ getTranslateButton(content) }}
                        </msc-button>

                        <a class="summary__locked" [tooltip]="'instance.locked-course' | translate" direction="bottom" *ngIf="content.isLocked">
                            <i class="icon icon-lock"></i>
                        </a>
                    </div>

                    <div class="block-module__progress" *ngIf="content.session">
                        <msc-progress-bar [color]="content.session.progress !== 100 ? 'blue': ''"
                            [progress]="content.session.progress" [label]="false"></msc-progress-bar>
                    </div>
                </div>
            </div>

            <div class="block-module__wrapper" *ngIf="content.context === 'external_content'">
                <div class="block-module__image"
                [ngStyle]="{'background-image': content.data?.picture ? ('url(' + content.data?.picture + ((content.data?.provider?.key !== 'linkedin') ? '?size=512)' : ')')) : ('url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.data?.id % 7 + '.webp)')}">
                    <div class="block-external-content__banner" *ngIf="content.data.provider">
                        <div class="block-external-content__banner__logo"
                            [style.backgroundImage]="'url(' + getLogo(content) + ')'"></div>

                        <msc-tag [color]="getColor(content)" [text]="getType(content) | translate"></msc-tag>
                    </div>

                    <msc-tag [color]="getColor(content)" [text]="getType(content) | translate"
                        *ngIf="!content.data.provider"></msc-tag>
                </div>

                <div class="block-module__desc">
                    <div class="block-module__title">{{ (content.data.title || ('words.untitled' | translate) |
                        truncate: 100) }}</div>

                    <div class="block-module__widgets">
                        <button class="btn btn--secondary btn--secondary-pulse btn--shadow" [ngClass]="{'pointer-events-none opacity-60': contentIdLoading === content.data.id}" (click)="runExternalContent(content)">
                            <ng-container *ngIf="content.data?.type !== 'ebook'">{{ contentIdLoading === content.data.id ? ('words.course-loading'| translate) + '&#46;&#46;&#46;' : ('words.start' | translate) }}</ng-container>
                            <ng-container *ngIf="content.data?.provider?.key == 'bookboon' && content.data?.type == 'ebook'"> {{ contentIdLoading === content.data.id ? ('words.course-loading'| translate) + '&#46;&#46;&#46;' : ('words.download' | translate) }} </ng-container>
                            <i *ngIf="contentIdLoading !== content.data.id && (content.data?.provider?.key !== 'bookboon' && content.data?.type !== 'ebook')" class="icon icon-arrow-right"></i>
                            <i *ngIf="contentIdLoading !== content.data.id && (content.data?.provider?.key == 'bookboon' && content.data?.type == 'ebook')" class="icon icon-download"></i>
                            <i *ngIf="contentIdLoading !== content.data.id && (content.data?.provider?.key == 'bookboon' && content.data?.type == 'audio')" class="icon icon-arrow-right"></i>
                        </button>
                    </div>

                    <div class="block-module__progress" *ngIf="content.session">
                        <msc-progress-bar [color]="content.session.progress !== 100 ? 'blue': ''"
                            [progress]="content.session.progress" [label]="false"></msc-progress-bar>
                    </div>
                </div>
            </div>

            <div class="dropdown-event block-task--planned" [ngClass]="{'active': displays[index]}"
                [(autoClose)]="displays[index]" *ngIf="content.data.context === 'event'">
                <div>
                    <div class="block-task__wrapper block-activity-event">
                        <div class="block-task__header">
                            <div class="tag tag--year tag--multi-date tag--layer">
                                <div class="tag__text">
                                    <span class="text__day">{{ content.data.event.eventSessions[0].startAt | date:'dd'}}
                                        <span
                                            *ngIf="(content.data.event.eventSessions[0].startAt | date:'dd') !== (content.data.event.eventSessions[0].endAt | date:'dd')">-
                                            {{ content.data.event.eventSessions[0].endAt | date:'dd'}}</span>
                                    </span>

                                    <span class="text__month">{{ content.data.event.eventSessions[0].startAt | localizedDate:'MMM' }}
                                        <span
                                            *ngIf="(content.data.event.eventSessions[0].startAt | date:'MMMM') !== (content.data.event.eventSessions[0].endAt | date:'MMMM')">{{ 'month.' + (content.data.event.eventSessions[0].endAt | date:'MMMM') | translate}}</span>
                                    </span>

                                    <span class="text__year">{{ content.data.event.eventSessions[0].startAt |
                                        date:'YY'}}</span>
                                </div>
                            </div>

                            <p class="block-task__time">
                                <span>{{content.data.event.eventSessions[0].startAt | date:'HH' }}</span>
                                {{content.data.event.eventSessions[0].endAt | date:'HH' }}
                            </p>

                            <p class="block-task__title">
                                {{ content.data.event.title || ('words.untitled' | translate) }}

                                <span class="block-task__localisation" *ngIf="content.data.event.type === 'place'">
                                    <i class="icon icon-pin"></i>
                                    {{ content.data.event.eventSessions[0].address || ('instance.address.no-specified' |
                                    translate ) }}
                                </span>

                                <span class="block-task__localisation block-task__calendar"
                                    (click)="addToCalendar(content)">
                                    <i class="icon icon-calendar"></i>
                                    {{ 'words.events-add-calendar' | translate }}
                                </span>
                            </p>
                        </div>

                        <div class="block-task__widgets">
                            <msc-button class="msc-button--primary" palette="secondary" type="fab" icon="icon-video"
                                [tooltip]="'manage.instance.event.edit.virtual-class' | translate"
                                *ngIf="(content.data.event.type === 'virtual-class') && (content.data.event.url !== null) && !(isEventDisabled(content.data.event.eventSessions[0].startAt))"
                                (click)="goToLink(content)">
                            </msc-button>

                            <msc-button class="msc-button--primary" palette="secondary" type="fab" icon="icon-video"
                                [tooltip]="'manage.instance.event.edit.video-conference' | translate"
                                *ngIf="(content.data.event.type === 'visio') && (content.data.event.link !== null) && !(isEventDisabled(content.data.event.eventSessions[0].startAt))"
                                (click)="goToLink(content)">
                            </msc-button>

                            <msc-button class="msc-button--primary" palette="secondary" type="fab" icon="icon-video"
                                [disabled]="content.data.event.url === null"
                                [tooltip]="'manage.instance.event.link-empty' | translate"
                                *ngIf="(content.data.event.type === 'virtual-class') && (content.data.event.url === null) &&!(isEventDisabled(content.data.event.eventSessions[0].startAt))">
                            </msc-button>

                            <msc-button class="msc-button--primary" palette="secondary" type="fab" icon="icon-video"
                                [disabled]="content.data.event.link === null"
                                [tooltip]="'manage.instance.event.link-empty' | translate"
                                *ngIf="(content.data.event.type === 'visio') && (content.data.event.link === null) && !(isEventDisabled(content.data.event.eventSessions[0].startAt))">
                            </msc-button>

                            <msc-button class="msc-button--primary" palette="secondary" type="fab" icon="icon-video"
                                [disabled]="isEventDisabled(content.data.event.eventSessions[0].startAt)"
                                [tooltip]="'manage.instance.event.invalid-date' | translate"
                                *ngIf="(content.data.event.type === 'visio' || content.data.event.type === 'virtual-class') && (isEventDisabled(content.data.event.eventSessions[0].startAt))">
                            </msc-button>
                        </div>

                        <div class="block-section__toggle" (click)="displayed(index)"
                            *ngIf="(content.data.event.eventSessions[0].eventSessionMedia.length > 0) || (content.data.event.eventTrainers.length > 0) || (content.data.description)">
                            <i class="icon icon-arrow-ios-down text-secondary-500"></i>
                        </div>
                    </div>

                    <div class="block-task__footer dropdown-event__progress-bar">
                        <msc-progress-bar [progress]="content.session?.progress"
                            [color]="content.session?.progress !== 0 ? 'blue': ''"></msc-progress-bar>
                    </div>
                </div>

                <div class="journey-event__subcontent">
                    <div class="journey-event__subcontent__content">
                        <div *ngIf="content.data.event.eventTrainers.length > 0">
                            <h2> {{ 'words.trainers' | translate }} </h2>

                            <ul class="avatar-group">
                                <li *ngFor="let trainer of content.data.event.eventTrainers">
                                    <a [tooltip]="trainer.name">
                                        <msc-common-avatar class="tiny" [label]="trainer.account?.name || trainer.name"
                                            [pictureURL]="trainer.account?.picture ? (trainer.account?.picture + '?size=64') : null">
                                        </msc-common-avatar>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="content.data.description">
                            <h2> {{ 'words.description' | translate }} </h2>

                            <p> {{ content.data.description }}</p>
                        </div>

                        <div *ngIf="content.data.event.eventSessions[0].eventSessionMedia.length > 0">
                            <h2> {{ 'words.resources' | translate }} </h2>

                            <div class="journey-event__subcontent__resource">
                                <msc-shared-resource [truncate]="20" [resource]="resource" [size]="'mini'"
                                    *ngFor="let resource of content.data.event.eventSessions[0].eventSessionMedia">
                                </msc-shared-resource>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-task__wrapper" *ngIf="content.context === 'content' && content.data.context !== 'event'">
                <div class="block-task__header block-task__header--media">
                    <div class="block-task__top">
                        <div class="block-task__title">
                            <div class="block-task__tag">
                                <msc-tag [text]="'stats.content.type-' + content.data.context | translate"
                                    [color]="'orange'"></msc-tag>
                            </div>

                            <div class="block-task__data">
                                <i [attr.class]="getIcon(content.data.context)"></i>
                                <span [tooltip]="content.data.title && content.data.title.length >= 30 ? content.data.title : '' "> {{ content.data.title ? (content.data.title | truncate: 30
                                ) : ('words.untitled' |
                                    translate) }}</span>
                            </div>
                        </div>

                        <div class="block-task__widgets">
                            <div class="block-task__duration">
                                <i class="icon icon-clock"></i>

                                <span>
                                    {{ content.session?.duration | duration }}
                                </span>
                            </div>

                            <a (click)="redirectActivityToPlayer(content)" class="link link-play text-primary-700"
                                *ngIf="!content.isLocked && !isLoading(content.contextId, 'player')">
                                {{ getTranslateButton(content) }}
                                <i class="icon icon-arrow-right bg-primary-700"></i>
                            </a>

                            <div class="spinner spinner--orange" *ngIf="isLoading(content.contextId, 'player')">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <a class="summary__locked" [tooltip]="'instance.locked-course' | translate" direction="bottom" *ngIf="content.isLocked">
                                <i class="icon icon-lock"></i>
                            </a>
                        </div>
                    </div>

                    <ng-container *ngIf="content.data.description">
                        <div class="block-task__description">
                            <div [innerHTML]="content.data.description | safe: 'html'"></div>
                        </div>
                    </ng-container>

                    <div class="block-task__media flex justify-end" *ngIf="content.data.context === 'media'">
                        <div class="flex align-center">
                            <div [tooltip]="'words.i-finished' | translate" (click)="finished(content)" *ngIf="content.session?.progress !== 100">
                                <i class="icon icon-checkmark color--green" *ngIf="!isLoading(content.id, 'finished')"></i>

                                <div class="spinner spinner--secondary" *ngIf="isLoading(content.id, 'finished')">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div [tooltip]="'words.download' | translate" (click)="download(content.data.media)" *ngIf="content.data.media.type !== 'EMBED' && content.data.media.type !== 'VID'">
                                <i class="icon icon-download color--grey"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-task__footer">
                    <msc-progress-bar [color]="content.session?.progress !== 100 ? 'blue': ''" [label]="false"
                        [progress]="content.session?.progress"></msc-progress-bar>
                </div>
            </div>
        </div>
        <msc-common-spinner *ngIf="journeyContentLoading"></msc-common-spinner>
    </div>

    <aside class="col-4 col-md-4 col-xxs-12 summary__aside">
        <div class="block-stats">
            <div class="stats">
                <div class="stats__size">
                    <msc-chart-pie [value]="journey.session?.progress" [valueColor]="'#ED8371'"
                        [backgroundColor]="'#FFCBC4'"></msc-chart-pie>
                </div>

                <div class="stats__text">
                    <p>{{ 'words.progress' | translate }}</p>
                </div>
            </div>

            <div class="stats">
                <div class="stats__size">
                    <msc-chart-pie [value]="journey.session?.score"></msc-chart-pie>
                </div>

                <div class="stats__text">
                    <p>{{ 'words.score' | translate }}</p>
                </div>
            </div>

            <div class="stats">
                <div class="stats__icon">
                    <i class="icon icon-clock"></i>
                </div>

                <div class="stats__duration">
                    <p>{{ journey.session?.duration | duration }}</p>
                </div>
            </div>
        </div>
    </aside>
</div>
