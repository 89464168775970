<!--content general = empresa = settings-->
<!--col 1 -->
<div class="content-settings grid md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 grid-cols-1 gap-4 rounded-lg">
    <div class="content-settings__main grid-autofill  col-xxs-12 m-3 shadow-md grid grid-cols-subgrid gap-4 col-span-2">
        <div class="block-company-settings">
            <div class="block-company-settings__header">
                <h1>{{ 'words.settings.general' | translate }}</h1>
            </div>
            <div class="block-company-settings__content">
                <div class="block-company-settings__identity w-3/4">
                    <div class="logo__editable">
                        <img [src]="settings.picture + '?size=512'" alt="Logo" *ngIf="settings.mediaId">
                        <msc-common-spinner *ngIf="loading"></msc-common-spinner>

                        <msc-button type="fab" icon="icon-close" *ngIf="settings.mediaId" (click)="deletePicture()">
                        </msc-button>
                    </div>

                    <div class="logo__upload">
                        <a class="link__upload text-secondary-500" (click)="uploadPicture()">
                            <i class="icon icon-image"></i> {{ 'words.upload' | translate }}
                        </a>

                        <p>{{ 'words.upload-info.256' | translate }}</p>
                        <input #upload class="display-none" type="file"
                            accept="image/png,image/jpeg,image/jpg,image/gif" fileUpload [fileUrl]="'media'"
                            (fileStatus)="updatePicture($event)">
                    </div>
                </div>

                <div class="block-company-settings__data--item item-half w-3/4">
                    <h4>{{ 'words.name' | translate }}</h4>

                    <div class="input">
                        <input autocomplete="off" type="text" name="input" class="input__text"
                            [(ngModel)]="settings.name" [debounce]="800" (onDebounce)="update()">
                    </div>
                </div>

                <div class="block-company-settings__data--item data-item__country w-3/4">
                    <h4>{{ 'words.country' | translate }}</h4>

                    <msc-common-select [data]="selectData">
                        <ng-template #buttonTemplate let-data="data">
                            <button class="select-main">
                                <ng-container *ngIf="data?.length">
                                    <span>{{ data[0].label }}</span>
                                </ng-container>
                            </button>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <button class="select-option" (click)="onSelectOption(datum)">
                                <span>{{ datum.label }}</span>
                            </button>
                        </ng-template>
                    </msc-common-select>
                </div>

                <div class="block-company-settings__data--item item-half w-3/4">
                    <h4>{{ 'words.vat' | translate }}</h4>

                    <div class="input">
                        <input autocomplete="off" type="text" name="input" class="input__text"
                            [(ngModel)]="settings.vat" [debounce]="800" (onDebounce)="update()">
                    </div>
                </div>

                <div class="block-company-settings__data--item w-3/4" >
                    <h4>{{ 'company.management.offer' | translate }}</h4>

                    <p>{{ 'company.management.' + settings.billingType | translate }}</p>
                </div>

                <div class="block-company-settings__data--item item-half w-3/4">
                    <h4>{{ 'company.management.custom-package' | translate }}</h4>

                    <p>{{ 'words.no' | translate }}</p>
                </div>
            </div>

            <a class="link text-secondary-500" (click)="redirect()">
                {{ 'company.management.question' | translate }}
            </a>
        </div>
    </div>

    <!--col 2 -->
    <aside class="col-xxs-12 flex flex-col space-y-4 my-3">
        <div class="flex flex-col space-y-2 p-4 bg-white shadow-md rounded-sm">
            <h5>{{ 'company.management.active-accounts' | translate }}</h5>

            <msc-progress-bar [progress]="settings.usersCount > settings.usersLimit ? 100 : (settings.usersCount / settings.usersLimit) * 100" [color]="'orange'" [label]="false" [icon]="false"></msc-progress-bar>

            <p [innerHtml]="'company.management.active-accounts.range' | translate : { active: settings.usersCount, total: settings.usersLimit }">
            </p>
        </div>

        <div class="flex flex-col space-y-2 p-4 bg-white shadow-md rounded-sm">
            <h5>{{ 'company.management.main-contact' | translate }}</h5>

            <ng-container *ngIf="availableContacts">
                <msc-common-select [data]="availableContacts">
                    <ng-template #buttonTemplate let-data="data">
                        <div class="flex items-center p-1 space-x-2 w-full">
                            <msc-common-avatar class="tiny mr-2" [label]="data[0]?.value.name"
                                [pictureURL]="data[0]?.value.picture ? (data[0]?.value.picture + '?size=128') : null">
                            </msc-common-avatar>

                            <p class="whitespace-nowrap text-ellipsis overflow-hidden">{{ data[0]?.label }}</p>
                        </div>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <div class="flex items-center p-1 space-x-2" (click)="selectContact(datum.value)">
                            <msc-common-avatar class="tiny" [label]="datum.value.name"
                                [pictureURL]="datum.value.picture ? (datum.value.picture + '?size=128') : null">
                            </msc-common-avatar>

                            <p>{{ datum.label }}</p>
                        </div>
                    </ng-template>
                </msc-common-select>
            </ng-container>
        </div>
    </aside>
</div>
