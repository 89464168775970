import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Instance, Correction, CorrectionFactory } from '@lighty';

@Component({
    selector: 'msc-learn-detail-instance-correction',
    templateUrl: './correction.component.html'
})
export class LearnDetailInstanceCorrectionComponent implements OnInit {
    public instance: Instance;
    public corrections: Correction[];
    public pagination: any;

    constructor(private route: ActivatedRoute, private factory: CorrectionFactory) {}

    ngOnInit(): void {
        this.instance = this.route.parent.snapshot.data.instance;
        this.corrections = this.route.parent.snapshot.data.corrections.corrections
            .map(correction => {
                if (correction.corrections.length === 0) {
                    return null;
                }
                return this.factory.get([].concat(correction.corrections).shift(), correction.context, correction.title, correction.description);
            })
            .filter(correction => correction);

        this.pagination = this.route.parent.snapshot.data.corrections.pagination;
    }
}
