import { Component, EventEmitter, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DownloadHelper, UrlHelper, DataHelper } from '@helpers';
import { CourseProvider } from '@lighty';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-player-light-modal-activity',
    templateUrl: './modal-activity.component.html'
})
export class PlayerLightModalActivityComponent implements OnInit, OnDestroy, AfterViewInit {
    private timers: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private preview: SafeResourceUrl;
    public onClose: EventEmitter<boolean> = new EventEmitter();
    public onSave: EventEmitter<any> = new EventEmitter();
    public active: boolean = false;
    public content: any;
    public session: any;
    public internalTimer: number;

    constructor(private sanitizer: DomSanitizer, private courseProvider: CourseProvider) {}

    ngOnInit(): void {
        this.active = true;
        this.internalTimer = this.session.duration || 0;

        this.timers = {
            duration: null,
            internal: null
        };

        this.timers.duration = setInterval(() => {
            this.session.duration += 5;
            this.updateSectionContent();
        }, 5000);

        this.timers.internal = setInterval(() => {
            this.internalTimer++;
        }, 1000);
    }

    ngAfterViewInit(): void {
        if (this.content.context === 'external_link') {
            this.buildLTIForm(this.content.externalLink);
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.timers.duration);
        clearInterval(this.timers.internal);
        this.subscriptionCollection.unsubscribe();
    }

    close(): void {
        this.session.duration = this.internalTimer;
        const params = {
            isDone: !!this.session.doneAt
        };

        this.courseProvider.updateSectionContentSessions(this.session.id, this.session, params).subscribe((data) => {
            this.session = data;
        });
        this.active = false;
        this.onClose.emit(true);
    }

    finished(): void {
        this.onSave.emit(this.session);
    }

    download(): void {
        DownloadHelper.downloadMedia(this.content.media, this.content.media.title);
    }

    getUrl(url: string, type?: string): SafeResourceUrl {
        return this.preview ? this.preview : this.preview = this.sanitizer.bypassSecurityTrustResourceUrl(type ? UrlHelper.parse(url) : url);
    }

    private buildLTIForm(integration: any): void {
        const form = document.createElement('form');
        form.target = 'embed-iframe';
        form.method = 'POST';
        form.action = integration.url;
        for (const key in integration.fields) {
            if (integration.fields.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = DataHelper.changeCase(key);
                input.setAttribute('value', integration.fields[key]);
                form.appendChild(input);
            }
        }
        document.querySelector('#embed-code').appendChild(form);
        form.submit();
    }

    private updateSectionContent(): void {
        const params = {
            isDone: !!this.session.doneAt
        };

        this.subscriptionCollection.subscribe = this.courseProvider.updateSectionContentSessions(this.session.id, this.session, params).subscribe((data) => {
            this.session = data;
        });
    }
}
