<ng-template #data>
    <aside>
        <header>
            <ng-container *ngTemplateOutlet="templateHeader || defaultTemplateHeader"></ng-container>
        </header>
        <div class="body">
            <ng-container *ngTemplateOutlet="templateAside || defaultTemplateAside"></ng-container>
        </div>
        <footer *ngIf="hasActionButtons">
            <ng-container *ngTemplateOutlet="templateFooter || defaultTemplateFooter"></ng-container>
        </footer>
    </aside>
</ng-template>

<ng-template #defaultTemplateFooter>
    <msc-button (click)="onClose()">{{ 'words.cancel' | translate }}</msc-button>
    <msc-button class="msc-button--primary" palette="primary" (click)="onSave()">{{ 'words.confirm' | translate
        }}</msc-button>
</ng-template>

<ng-template #defaultTemplateAside>
    <p>There is nothing here yet...</p>
</ng-template>

<ng-template #defaultTemplateHeader>
    <div class="headerTitle">
        <h3 *ngIf="title">{{title}}</h3>
        <msc-button type="fab" icon="icon-close" (click)="onClose()"></msc-button>
    </div>
</ng-template>