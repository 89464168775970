import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminContentGroupProvider, Pagination } from '@lighty';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-camp-aside-groups',
    templateUrl: './aside-groups.component.html'
})
export class CampAsideGroupsComponent {
    @ViewChild('aside') aside;
    @Input() camp: any;
    @Input() context: string;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public courseCamp: any;
    public externalContent: any;
    private currentId: number;
    public loading: boolean = false;
    public queries: any;
    public groups: any;
    public pagination: Pagination;
    public paginationAvailable: Pagination;
    public availableGroups: any = [];
    public onCloseByUser: EventEmitter<any> = new EventEmitter();
    public tab: string = 'list';
    public data: any = {
        groupChecked: [],
        groupExcluded: [],
        groupCheckedId: []
    };

    constructor(
        private contentGroupProvider: AdminContentGroupProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.availableGroups = [];
        this.groups = [];
        this.data = {
            groupChecked: [],
            groupExcluded: [],
            groupCheckedId: []
        };
        this.context = this.context ? this.context : this.courseCamp ? 'course_instance' : 'external_resource';
        this.currentId = this.context === 'course_instance' ? (this.courseCamp.courseId || this.courseCamp.course.id) : this.externalContent.id;

        const params = HttpHelper.cleanParams({
            context: this.context,
            camp_id: this.camp.id
        });

        this.queries = {
            general: '',
            available: ''
        };

        this.contentGroupProvider.getGroups(this.currentId, params).subscribe((data) => {
            this.groups = data.groups;
            this.pagination = data.pagination;
        });

        this.getAvailableGroups();
    }

    onOpen(item, type) {
        if (type === 'courseCamp') {
            this.courseCamp = item;
        } else if (type === 'externalResource') {
            this.externalContent = item;
        }
        this.init();
        this.aside.onOpen();
    }

    search(): void {
        const params = HttpHelper.cleanParams({
            context: this.context,
            camp_id: this.camp.id,
            q: this.queries.general
        });

        this.contentGroupProvider.getGroups(this.courseCamp.courseId || this.courseCamp.course.id, params).subscribe((data) => {
            this.groups = data.groups;
            this.pagination = data.pagination;
        });
    }

    getAvailableGroups(): void {
        const params = HttpHelper.cleanParams({
            context: this.context,
            camp_id: this.camp.id,
            q: this.queries.available
        });

        this.contentGroupProvider.getAvailableGroups(this.currentId, params).subscribe((data) => {
            this.availableGroups = data.groups;
            this.paginationAvailable = data.pagination;
        });
    }

    searchAvailable(query?): void {
        this.queries.available = query;
        const params = HttpHelper.cleanParams({
            context: this.context,
            camp_id: this.camp.id,
            q: this.queries.available
        });

        this.contentGroupProvider.getAvailableGroups(this.currentId, params).subscribe((data) => {
            this.availableGroups = data.groups;
            this.paginationAvailable = data.pagination;
        });
    }

    checkGroup(group: any): void {
        const index = {
            selected: null,
            excluded: null
        };

        index.selected = this.data.groupCheckedId.indexOf(group);

        if (index.selected > -1) {
            this.data.groupCheckedId.splice(index.selected, 1);

            if (this.data.isAllSelected) {
                index.excluded = this.data.groupExcluded.indexOf(group);

                if (index.excluded > -1) {
                    this.data.groupExcluded.splice(index.excluded, 1);
                } else {
                    this.data.groupExcluded.push(group);
                }
            }
        } else {
            this.data.groupCheckedId.push(group);
        }
    }

    isChecked(group: any): boolean {
        return this.data.groupCheckedId.indexOf(group) > -1;
    }

    addGroup(): void {
        this.loading = true;

        const params = HttpHelper.cleanParams({
            context: this.context,
            group_ids: this.data.groupCheckedId
        });

        this.contentGroupProvider.addToGroup(this.currentId, params).subscribe(() => {
            this.groups.push(this.data.groupChecked);
            this.loading = false;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.aside.onClose();
        });

        for (const group of this.data.groupChecked) {
            const index = this.availableGroups.findIndex((data) => {
                return data.id === group.id;
            });

            if (index > -1) {
                this.availableGroups.splice(index, 1);
            }
        }
    }

    removeGroup(group: any): void {
        const params = HttpHelper.cleanParams({
            context: this.context,
            context_id: this.currentId,
        });

        this.contentGroupProvider.deleteFromGroup(group.id, params).subscribe(() => {
            const index = this.groups.findIndex((data) => {
                return data.id === group.id;
            });
            this.groups.splice(index, 1);
            this.availableGroups.push(group);
            this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
        });
    }

    close(): void {
        this.onClose.emit(true);
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    changeTab(tab: string): void {
        this.tab = tab;
    }
}
