import { Subscription } from 'rxjs';

/**
 * SubscriptionCollection class allows to manage subscriptions easily
 */
export class SubscriptionCollection {
    /**
     * Collection of subscriptions
     */
    private collection: Subscription[] = [];

    /**
     * Init class
     * @constructor
     */
    constructor() {}

    /**
     * Add subscription
     * @param {Subscription} subscription - Subscription
     */
    public set subscribe(subscription: Subscription) {
        this.collection.push(subscription);
    }

    /**
     * Unsubscribe each subscriptions into collection
     */
    public unsubscribe(): void {
        for (const subscription of this.collection) {
            subscription.unsubscribe();
        }
        this.collection = [];
    }
}
