import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { DataHelper } from '@helpers';
import { EmitterService } from '@services';

@Component({
    selector: 'msc-sort',
    templateUrl: './sort.component.html'
})
export class SortComponent implements OnInit, OnChanges {
    @Input() default: string;
    @Input() reset: boolean;
    @Input() label: string = '';
    @Output() onSort: EventEmitter<any> = new EventEmitter();
    private internalId: string;
    public type: string = null;

    constructor(private emitterService: EmitterService) {}

    ngOnInit(): void {
        this.internalId = DataHelper.generateId();

        this.emitterService.sort.subscribe((id) => {
            if (id === 'reset' && this.default) {
                this.sort(this.default, true);
            } else if (id !== this.internalId) {
                this.type = null;
            }
        });

        if (this.default) {
            this.type = this.default;
        }
    }

    ngOnChanges(): void {
        if (!this.reset) {
            this.type = null;
        }
    }

    sort(type: string, force?: boolean): void {
        this.type = this.type !== type ? type : (force ? type : null);
        this.onSort.emit(this.type);
        this.emitterService.sort.emit(this.internalId);
    }
}
