import { Component, ChangeDetectionStrategy } from '@angular/core';
//
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-showroom-exhibit-empty-page',
    templateUrl: './showroom-exhibit-empty-page.component.html',
    styleUrls: ['./showroom-exhibit-empty-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitEmptyPageComponent {
    constructor(
        private readonly toastService: CommonToastService,
    ) { }

    /**
     *
     */
    onEvent(): void {
        this.toastService.onSuccess('Ring acquired');
    }
}
