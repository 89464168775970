import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminGroupUserProvider, Camp, Pagination } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { GroupRoles } from '@enums';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-camp-aside-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})

export class CampAsideUsersComponent {
    @ViewChild('aside') aside;
    @Input() camp: Camp;
    public currentGroup: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public membersSelected: any[] = [];
    public excludedMembers: any[] = [];
    public isAllSelected: boolean = false;
    public me: any;
    public members: any;
    public pagination: any;
    public availableMembers: any;
    public ready: boolean;
    public roles: any = ['member', 'owner'];
    public loading: boolean = false;
    public loadingUsers: boolean = false;
    public query: string;
    public displayDropdowns: any;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public tab: string = 'list';
    private company;

    constructor(
        private toastService: CommonToastService,
        private groupUserProvider: AdminGroupUserProvider,
        private storageService: StorageService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');

        this.displayDropdowns = {
            role: []
        };

        this.pagination = {
            users: Pagination,
            addUsers: Pagination
        };

        this.ready = false;

        this.refreshMembers();
        this.getAvailableMembers();
    }

    public onOpen(group?) {
        this.currentGroup = group;
        this.init();
        this.aside.onOpen();
    }

    refreshMembers(): void {
        this.loadingUsers = true;
        this.groupUserProvider.getUsers(this.company.id, this.currentGroup.id).subscribe(data => {
            this.pagination.users = data.pagination;
            this.members = data.users;
            this.loadingUsers = false;
        });
    }

    search(query?): void {
        this.query = query;
        if (this.tab === 'list') {
            this.searchMembers();
        } else {
            this.searchAvailableMembers();
        }
    }

    searchMembers(): void {
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.groupUserProvider.getUsers(this.company.id, this.currentGroup.id, params).subscribe(data => {
            this.pagination.users = data.pagination;
            this.members = data.users;
        });
    }

    getAvailableMembers(): void {
        this.groupUserProvider.getAvailableUsers(this.currentGroup.id).subscribe(data => {
            this.ready = true;
            this.availableMembers = data.users;
            this.pagination.addUsers = data.pagination;
        });
    }

    searchAvailableMembers(): void {
        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.addUsers.currentPage
        });

        this.groupUserProvider.getAvailableUsers(this.currentGroup.id, params).subscribe(data => {
            this.availableMembers = data.users;
            this.pagination.addUsers = data.pagination;
        });
    }

    inviteAll(): void {
        const params = {
            role: 'group_member',
            invite_all_accounts: true,
            excluded_ids: this.excludedMembers
        };

        this.groupUserProvider.addUser(this.company.id, this.currentGroup.id, params).subscribe(() => {
            this.refreshMembers();
            this.getAvailableMembers();
            this.toastService.onSuccess(this.translateService.instant('toast.users-added-undefined'));
            this.back();
        });
    }

    addToGroup(): void {
        const params = {
            role: 'group_member',
            account_ids: (this.membersSelected.length > 0) ? this.membersSelected : null
        };

        this.groupUserProvider.addUser(this.company.id, this.currentGroup.id, params).subscribe(() => {
            this.refreshMembers();
            this.searchAvailableMembers();
            this.toastService.onSuccess(this.translateService.instant((this.membersSelected.length < 20) ? 'toast.saved' : 'toast.users-added-undefined-new'));
        });
    }

    updateMember(member: any, role: any): void {
        const params = {
            role,
        };

        this.groupUserProvider.updateUserRole(this.company.id, this.currentGroup.id, member.account.id, params).subscribe(() => {
            this.refreshMembers();
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    deleteFromGroup(member: any): void {
        this.groupUserProvider.deleteUser(this.company.id, this.currentGroup.id, member.account.id).subscribe(() => {
            this.refreshMembers();
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    loadMore(type: string): void {
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            page: (type === 'list') ? this.pagination.users.currentPage + 1 : this.pagination.addUsers.currentPage + 1
        });

        if (type === 'list') {
            this.groupUserProvider.getUsers(this.company.id, this.currentGroup.id, params).subscribe(data => {
                this.members = this.members.concat(data.users);
                this.pagination.users = data.pagination;
                this.loading = false;
            });
        } else {
            this.groupUserProvider.getAvailableUsers(this.currentGroup.id, params).subscribe(data => {
                this.availableMembers = this.availableMembers.concat(data.users);
                this.pagination.addUsers = data.pagination;
                this.loading = false;
            });
        }

    }

    displayed(key: string, index: number): void {
        this.displayDropdowns[key][index] = !this.displayDropdowns[key][index];
    }

    addToMemberAll(): void {
        this.isAllSelected = !this.isAllSelected;
        this.membersSelected = [];

        if (this.isAllSelected) {
            const members = this.availableMembers;
            for (const member of members) {
                this.addToMember(member.id);
            }
        } else {
            this.membersSelected = [];
        }
    }

    addToMember(member: any): void {
        const index = {
            selected: null,
            excluded: null
        };
        index.selected = this.membersSelected.indexOf(member);
        if (index.selected > -1) {
            this.membersSelected.splice(index.selected, 1);
            if (this.isAllSelected) {
                index.excluded = this.excludedMembers.indexOf(member);
                if (index.excluded > -1) {
                    this.excludedMembers.splice(index.excluded, 1);
                } else {
                    this.excludedMembers.push(member);
                }
            }
        } else {
            this.membersSelected.push(member);
        }
    }

    isMemberSelected(member: any): boolean {
        return this.membersSelected.indexOf(member) > -1;
    }

    changeTab(tab: string): void {
        this.tab = tab;
        this.membersSelected = [];
        this.query = null;
        this.isAllSelected = false;
    }

    close(): void {
        this.onClose.emit();
    }

    save(): void {
        if (this.isAllSelected) {
            this.inviteAll();
        } else {
            this.addToGroup();
        }
        this.back();
    }

    back(): void {
        this.changeTab('list');
    }
}
