import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { catchError, mergeScan, map, tap, repeat, finalize, takeUntil } from 'rxjs/operators';
//
import { StatisticsProvider2, StatsProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonApiContentTypeIconMapping } from '@common2/common.constants';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-stats-content',
    templateUrl: './stats-content.component.html',
    styleUrls: ['./stats-content.component.scss'],
})

export class CampStatsContentComponent {
    private campId: number;
    private query: string;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public company: any;
    public cards: Array<any>;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public hasLoadMore: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly toastService: CommonToastService,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly statsProvider: StatsProvider,
        private readonly statisticsProvider2: StatisticsProvider2,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.campId = this.route.parent.parent.snapshot.params['campId'];
        this.setContent();
        this.onQueryCamp(this.campId);
        this.onQueryContentList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'title',
                label: this.translateService.instant('words.title'),
                width: '25%',
            },
            {
                key: '',
                width: '30%',
            },
            {
                key: 'chartProgress',
                label: this.translateService.instant('words.progress-average'),
                width: '20%',
            },
            {
                key: 'chartScore',
                label: this.translateService.instant('words.score-average'),
                width: '20%',
            },
            {
                key: '',
                width: '5%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryContentList(pageValue: number) {
        return Object.assign({
            page: pageValue,
        }, this.query ? { q: this.query } : {});
    }

    /**
     *
     */
    onQueryCamp(campId: number): void {
        this.statisticsProvider2
            .getCampList(this.company.id, { camp_ids: campId })
            .pipe(
                catchError(() => of([])),
                tap((datum) => this.onTapQueryCamp(datum[campId]?.context)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapQueryCamp(datum: any): void {
        if (!datum) { return; }
        this.cards = [
            {
                label: this.translateService.instant('words.contents'),
                value: datum.contentsCount,
                icon: 'catalogue',
            },
            {
                label: this.translateService.instant('words.instances'),
                value: datum.sessionsCount,
                icon: 'calendar',
            },
            {
                label: this.translateService.instant('words.students'),
                value: datum.learnersCount,
                icon: 'people',
            },
            {
                label: this.translateService.instant('words.hours'),
                value: Math.floor(datum.duration / 3600),
                icon: 'clock',
            },
        ];
    }

    /**
     *
     */
    onQueryContentList(isAppend: boolean = true): void {
        this.statisticsProvider2
            .getContentList(this.company.id, this.campId, this.getParamsOnQueryContentList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryContentList(response.pagination)),
                map((response) => this.onMapOnQueryContentList(response.templates)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => this.status$.next(CommonSubjectStatus.SUCCESS)),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentList(pagination: any): void {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryContentList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            context: datum.context === 'course_template' ? 'course' : datum.context,
            hasDetails: false,
            height: `${(datum.instances || []).length * 70 + 120}px`,
            icon: CommonApiContentTypeIconMapping.get(datum.context),
            id: datum.id,
            instances: (datum.instances || []).map((instance: any) => ({
                context: datum.context === 'course_template' ? 'course' : datum.context,
                icon: CommonApiContentTypeIconMapping.get(datum.context),
                id: instance.id,
                title: instance.title
            })),
            isActive: false,
            title: datum.title,
        }));
    }

    /**
     *
     */
    onQueryContentInstanceList(row: any) {
        const instanceIds = (row?.instances || []).map((instance: any) => instance.id);
        this.statisticsProvider2
            .getContentInstanceList(this.company.id, this.campId, { context_ids: instanceIds, context: row.context })
            .pipe(
                tap((datum) => this.onTapOnQueryContentInstanceList(row, datum)),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentInstanceList(row: any, datum: any) {
        if (!datum) { return; } // || (Array.isArray(datum) && !datum.length)
        row.instances = row.instances.map((instance: any) => ({
            ...instance,
            isChartProgressVisible: typeof datum[instance.id]?.context?.progressAvg === 'number',
            isChartScoreVisible: typeof datum[instance.id]?.context?.scoreAvg === 'number',
            chartProgress: datum[instance.id]?.context?.progressAvg ?? 0,
            chartScore: datum[instance.id]?.context?.scoreAvg ?? 0,
            countLearner: datum[instance.id] ? datum[instance.id]?.context?.learnersCount ?? 0 : 0,
            duration: datum[instance.id] ? datum[instance.id]?.context?.durationSum ?? 0 : 0,
        }));
        row.hasDetails = true;
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.query = value || null;
        this.page$.next(1);
        this.onQueryContentList(false);
    }

    /**
     *
     */
    onOpen(row: any) {
        row.isActive = !row.isActive;
        if (row.isActive) {
            this.onQueryContentInstanceList(row);
        }
    }

    /**
     *
     */
    onLoad(): void {
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryContentList();
    }

    /**
     *
     */
    onDownloadHistory() {
        this.statsProvider
            .downloadHistory(this.campId)
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: (data: any) => this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail')),
                error: () => this.toastService.onError(this.translateService.instant('toast.error-occurred')),
            });
    }

    /**
     *
     */
    onDownloadStats() {
        this.statsProvider
            .downloadStats(this.campId)
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: (data: any) => this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail')),
                error: () => this.toastService.onError(this.translateService.instant('toast.error-occurred')),
            });
    }

    /**
     *
     */
    onGoToDetails(row: any, content: any): void {
        this.router.navigate(['../details', row.context === 'course_template' ? 'course' : row.context, content.id], {
            relativeTo: this.route,
            state: { content }
        });
    }
}
