<div class="content-access__manage__tab">
    <div class="content-access__manage__title flex between" [ngClass]="{ 'active': displays.lists['contents'] }">
        <div class="content-access__manage__title__block flex align-center" (click)="unfold('contents')">
            <i class="icon icon-arrow-ios-up arrow"></i>

            <h3>
                {{ 'words.contents' | translate }}

                <div [tooltip]="'access.manage.info' | translate">
                    <i class="icon icon-info"></i>
                </div>
            </h3>
        </div>

        <msc-button class="msc-button--primary" palette="secondary" (click)="addInstanceAside.onOpen()">
            {{ 'words.add-content'| translate }}
        </msc-button>
    </div>

    <div class="content-access__manage__list">
        <h3 *ngIf="(user.companyRoles.companyOwner || user.companyRoles.companyAdministrator) && instances.length === 0">{{ 'access.disabled-manage-empty'| translate }}</h3>

        <div class="content-access__manage__item box box-content flex center between"
            [tooltip]="!instance.data.canBeUpdated ? ('access.disabled-instance-not-allowed' | translate) : ''"
            *ngFor="let instance of instances">
            <div class="box-content__infos flex align-center justify-start">
                <i class="icon" [ngClass]="{'icon-trending-up': instance.type === 'traject', 'icon-cube': instance.type === 'course_instance'}"></i>

                <p [tooltip]="instance.data.title.length > 30 ? instance.data.title : ''">
                    {{ instance.data.title | truncate: 30 }}
                    <span *ngIf="instance.type === 'course_instance'" [tooltip]="instance.data.internalName > 30 ? instance.data.internalName: ''">
                        {{ instance.data.internalName | truncate: 30 }}
                    </span>
                </p>
            </div>

            <div class="justify-end flex">
                <div class="box-content__participant flex align-center">
                    <i class="icon icon-people"></i>
                    <p>{{ instance.data.studentsCount }}</p>
                </div>

                <div *ngIf="instance.data.canBeUpdated" class="box-content__line flex align-center"></div>

                <div *ngIf="instance.data.canBeUpdated" class="box-content__trash flex align-center justify-end" (click)="removeInstance(instance)">
                    <i class="icon icon-trash-2"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center" *ngIf="displays.lists['contents']">
        <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
    </div>

    <div class="content-access__manage__title flex between" [ngClass]="{ 'active': displays.lists['collaborators'] }" *ngIf="user.teams.length > 0">
        <div class="content-access__manage__title__block flex align-center" (click)="unfold('collaborators')">
            <i class="icon icon-arrow-ios-up arrow"></i>

            <h3>
                {{ 'words.collaborators' | translate }}
            </h3>
        </div>

        <div class="content-access__manage__user-authorization flex align-center">
            <msc-common2-checkbox [value]="allowMemberAssignment" [label]="'group.member-assignment-authorize' | translate" (changeEvent)="allowMemberAssignment = !allowMemberAssignment; updatePermissionToAddUsers()"></msc-common2-checkbox>
        </div>
    </div>

    <div class="content-access__manage__list">
        <div class="content-access__manage__item box box-content flex items-center"
            *ngFor="let collaborator of collaborators">
            <div class="box-content__picture avatar" [style.backgroundImage]="'url(' + collaborator.account.picture + '?size=64)'"></div>

            <div class="box-content__infos">
                <p class="box-content__collaborator-name">{{ collaborator.account.name }}</p>
                <span class="box-content__company-role">{{ collaborator.account.headline }}</span>
            </div>
        </div>
    </div>

    <div class="text-center" *ngIf="displays.lists['collaborators']">
        <msc-load-more [loading]="collaboratorsLoading" [pagination]="collaboratorsPagination" (onLoad)="loadMoreCollaborators()"></msc-load-more>
    </div>

    <msc-aside-access-manage-instance #addInstanceAside
                                      [company]="company"
                                      [accountId]="accountId"
                                      [instances]="instances">
    </msc-aside-access-manage-instance>
</div>
