import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UserProvider, Company, AuthProvider } from '@lighty';
import { ErrorStreamService, StorageService, TranslateService } from '@services';
import { DataHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-user-modal-reset',
    templateUrl: './reset-password.component.html'
})
export class OrganizeUserModalResetComponent implements OnInit {
    @ViewChild('modal') modal;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    private loading = false;
    public error: any = {
        short: false,
        characters: false,
        number: false,
        uppercase: false,
        lowercase: false
    };
    public valid: any = {
        short: false,
        characters: false,
        number: false,
        uppercase: false,
        lowercase: false
    };
    public user;
    public company: Company;
    public data: any;
    public step = 'normal';
    public userMail: string;

    constructor(
        private userProvider: UserProvider,
        private authProvider: AuthProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
    }

    onOpen(user) {
        this.data = {
            account_id: user.account.id,
            password: '',
            forced_reset: false
        };

        this.error = {
            short: false,
            characters: false,
            number: false,
            uppercase: false,
            lowercase: false
        };

        this.valid = {
            short: false,
            characters: false,
            number: false,
            uppercase: false,
            lowercase: false
        };

        this.step = 'normal';
        this.userMail = user.account.email || '';
        this.user = user;
        this.modal.onOpen();
    }

    trimPassword(): void {
        this.data.password = this.data.password.trim();
        if (this.data.password.length > 0) {
            const regNumber = RegExp(/^.*(?=.*[\d\x]).*$/);
            const regUppercase = RegExp(/^.*(?=.*[A-Z]).*$/);
            const regLowercase = RegExp(/^.*(?=.*[a-z]).*$/);

            this.error.short = this.data.password.length > 0 && this.data.password.length < 8;
            this.error.number = !regNumber.test(this.data.password);
            this.error.uppercase = !regUppercase.test(this.data.password);
            this.error.lowercase = !regLowercase.test(this.data.password);

            this.valid.short = this.data.password.length >= 8;
            this.valid.number = regNumber.test(this.data.password) && this.data.password.length > 0;
            this.valid.uppercase = regUppercase.test(this.data.password) && this.data.password.length > 0;
            this.valid.lowercase = regLowercase.test(this.data.password) && this.data.password.length > 0;
        } else {
            this.error = {
                short: false,
                characters: false,
                number: false,
                uppercase: false,
                lowercase: false
            };
            this.valid = {
                short: false,
                characters: false,
                number: false,
                uppercase: false,
                lowercase: false
            };
        }
    }

    reset(): void {
        this.loading = true;
        if (this.data.password.length >= 8) {
            this.errorStreamService.locked();
            this.authProvider.resetPasswordAdmin(this.data).subscribe(() => {
                this.step = 'reset';
                this.toastService.onSuccess(this.translateService.instant('toast.reset-password'));
                this.errorStreamService.unlocked();
                this.loading = false;
            }, (failure) => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                const msg = failure.error.message.password;
                msg.forEach(message => {
                    switch (message) {
                        case 'too-short':
                            this.error.short = true;
                            break;
                        case 'num-maj-min':
                            this.error.caracters = true;
                            break;
                    }
                });
                this.loading = false;
                this.errorStreamService.unlocked();
            });
        } else {
            this.loading = false;
            this.toastService.onWarning(this.translateService.instant('toast.password.too-short'));
        }
    }

    mail(): void {
        this.step = 'mail';
    }

    send(): void {
        const params = {
            password: this.data.password,
            forced: this.data.forced_reset
        };

        this.userProvider.notifyPassword(this.company.id, this.user.account.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.send-password-mail'));
        });
    }

    clipboard(): void {
        DataHelper.clipboard(this.data.password);
        this.toastService.onSuccess(this.translateService.instant('toast.organize.users.modal.reset-password.copy-password'));
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }
}
