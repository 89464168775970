import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { DataHelper, ColorHelper } from '@helpers';

@Component({
    selector: 'msc-organize-experience-custom-svg',
    templateUrl: './custom-svg.component.html'
})
export class OrganizeExperienceCustomSVGComponent implements OnInit, DoCheck {
    @Input() theme: any;
    @Input() autoCheck: boolean = false;
    private ready: boolean = false;
    public id: string;

    constructor() {}

    ngOnInit(): void {
        this.id = DataHelper.generateId('custom-theme-svg-');

        const timeOut = setTimeout(() => {
            this.customize();
            this.ready = true;
            clearTimeout(timeOut);
        }, 250);
    }

    ngDoCheck(): void {
        if (this.autoCheck && this.ready) {
            this.customize();
        }
    }

    private customize(): void {
        if (this.theme.primaryColor && this.theme.secondaryColor) {
            const svg: any = document.querySelector(`#${this.id}`);

            for (const primary of svg.querySelectorAll('.primary-color')) {
                primary.style.fill = this.theme.primaryColor;
            }

            for (const primaryLight of svg.querySelectorAll('.primary-color--light')) {
                primaryLight.style.fill = ColorHelper.lightenDarken(this.theme.primaryColor, 80);
            }

            for (const primaryDark of svg.querySelectorAll('.primary-color--dark')) {
                primaryDark.style.fill = ColorHelper.lightenDarken(this.theme.primaryColor, -15);
            }

            for (const primaryDarker of svg.querySelectorAll('.primary-color--darker')) {
                primaryDarker.style.fill = ColorHelper.lightenDarken(this.theme.primaryColor, -30);
            }

            for (const secondary of svg.querySelectorAll('.secondary-color')) {
                secondary.style.fill = this.theme.secondaryColor;
            }
        }
    }
}
