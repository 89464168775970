import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Group, GroupProvider } from '@lighty';
import { LoaderService, StorageService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-learn-community-watch',
    styleUrls: ['./watch.component.scss'],
    templateUrl: './watch.component.html'
})

export class LearnCommunityWatchComponent {
    private company: any;
    public group: Group;
    public currentTab: string;
    public pagination: any;
    public query: any;
    public loading: any;
    public staffs: any[] = [];
    public members: any[] = [];
    public resources: any[] = [];
    public contents: any[] = [];
    public displays: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private groupProvider: GroupProvider,
        private loaderService: LoaderService,
        private storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(() => {
            this.loading = {
                content: false,
                user: {}
            };

            this.pagination = {
                content: null,
                user: {}
            };

            this.displays = {
                mobileMenu: false,
                timeline: false
            };

            this.group = this.route.snapshot.data.group;
            this.currentTab = 'timeline';
            this.query = null;
            this.company = this.storageService.get('company');
            this.getContents();
            this.getUsers();
            this.getUsers(1, true);
            this.getResources();

            const timeOut = setTimeout(() => {
                this.displayed('timeline');
                clearTimeout(timeOut);
            }, 50);
        });
    }

    changeTab(tab: string): void {
        this.currentTab = tab;
    }

    getContents(): void {
        const params = {
        };

        this.groupProvider.getLearningGroupContent(this.company.id, this.group.id, params).subscribe((data) => {
            this.contents = data.contents;
            this.pagination.content = data.pagination;
        });
    }

    getResources(): void {
        this.groupProvider.getResourcesV2(this.company.id, this.group.id).subscribe((data) => {
            this.resources = data.resources;
        });
    }

    getUsers(page: number = 1, staff?: any): void {
        this.loading.user[staff ? 'staff' : 'members'] = true;
        const params = HttpHelper.cleanParams({
            role: 'member',
            q: this.query,
            page,
            staff: staff ? 1 : 0
        });

        this.groupProvider.getUsers(this.company.id, this.group.id, params).subscribe((data) => {
            this.pagination.user[staff ? 'staff' : 'members'] = data.pagination;
            if (this.pagination.user[staff ? 'staff' : 'members'].currentPage > 1) {
                this[staff ? 'staffs' : 'members'] = [...this[staff ? 'staffs' : 'members'], ...data.users];
            } else {
                this[staff ? 'staffs' : 'members'] = data.users;
            }
            this.loading.user[staff ? 'staff' : 'members'] = false;
        });


        const managerParams = HttpHelper.cleanParams({
            q: this.query,
            role: 'owner'
        });
        this.groupProvider.getLearningGroupMembers(this.company.id, this.group.id, managerParams).subscribe(data => {
            this.staffs = data.users;
        })

    }

    startDiscussion(user: any): void {
        this.storageService.set('conversationUsers', [user.account]);
        this.router.navigate(['conversation/create']);
    }

    search(query?): void {
        this.query = query;
        this.loaderService.run();

        if (this.currentTab === 'members') {
            this.getUsers();
            this.getUsers(1, true);
        } else if (this.currentTab === 'contents') {
            const params = HttpHelper.cleanParams({
                include: 'course.categories,seats.users,session',
                q: this.query
            });

            this.groupProvider.getContents(this.group.id, params).subscribe((data) => {
                this.contents = data.contents;
                this.pagination.content = data.pagination;
            });
        } else {
            const params = HttpHelper.cleanParams({
                q: this.query
            });

            this.groupProvider.getResourcesV2(this.company.id, this.group.id, params).subscribe((data) => {
                this.resources = data.resources;
            });
        }
    }

    loadMore(staff?: any): void {
        if (this.currentTab === 'members') {
            this.getUsers(this.pagination.user[staff ? 'staff' : 'members'].currentPage + 1, staff);
        } else {
            this.loading.content = true;
            const params = HttpHelper.cleanParams({
                include: 'course.categories,seats.users,session',
                page: this.pagination.content.currentPage + 1
            });

            this.groupProvider.getContents(this.group.id, params).subscribe((data) => {
                this.contents = this.contents.concat(data.contents);
                this.pagination.content = data.pagination;
                this.loading.content = false;
            });
        }
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }
}
