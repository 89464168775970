import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-learn-detail-template',
    templateUrl: './template.component.html'
})
export class LearnDetailTemplateComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
