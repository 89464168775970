<section class="body">
    <div class="container-applications__section row" *ngIf="providers.length > 0">
        <h2 class="container-applications__title">
            {{ 'integrations.providers-connected' | translate }}
        </h2>

        <div class="container-applications__list">
            <div class="container-applications__item" *ngFor="let provider of providers"
                (click)="redirectProvider(provider.provider)">
                <ul class="block-provider block-provider--selectable">
                    <li class="block-provider__card">
                        <div class="block-provider__card__spinner" *ngIf="provider.deploymentRunning">
                            <div class="spinner-lines spinner-lines--orange">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>

                        <ng-container *ngIf="provider.isPredefined; else notPredefinedTemplate1">
                            <div class="block-provider__card__picture"
                                [style.backgroundImage]="'url(https://static.myskillcamp.com/images/integrations/' + provider.provider.key + '.svg)'">
                            </div>
                        </ng-container>
                        <ng-template #notPredefinedTemplate1>
                            <ng-container *ngIf="provider.mediaUrl; else defaultTemplate">
                                <div class="block-provider__card__picture" [style.backgroundImage]="provider.mediaUrl">
                                </div>
                            </ng-container>
                            <ng-template #defaultTemplate>
                                <div class="block-provider__card__picture card-with-text">
                                    <p>{{ provider.provider.key }}</p>
                                </div>
                            </ng-template>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container-applications__section row col-xxs-12">
        <h2 class="container-applications__title">
            {{ 'integrations.providers-disconnected' | translate }}
        </h2>

        <div class="container-applications__list">
            <div class="container-applications__item col-4 col-md-4 col-xxs-12"
                *ngFor="let provider of availableProviders">
                <ul class="block-provider">
                    <li class="block-provider__card">
                        <div class="block-provider__card__new"
                            *ngIf="provider.key === 'linkedin'">
                            <span class="text-primary-800 text-xs">new</span>
                        </div>

                        <ng-container *ngIf="provider.isPredefined; else notPredefinedTemplate">
                            <div class="block-provider__card__picture"
                                [style.backgroundImage]="'url(https://static.myskillcamp.com/images/integrations/' + provider.key + '.svg)'">
                            </div>
                        </ng-container>
                        <ng-template #notPredefinedTemplate>
                            <ng-container *ngIf="provider.mediaUrl; else titleTemplate">
                                <div class="block-provider__card__picture" [style.backgroundImage]="provider.mediaUrl">
                                </div>
                            </ng-container>
                            <ng-template #titleTemplate>
                                <div class="block-provider__card__picture card-with-text">
                                    <p>{{ provider.key }}</p>
                                </div>
                            </ng-template>
                        </ng-template>

                        <div class="block-provider__incoming">
                            <msc-button *ngIf="checkButtonWord(provider)" class="msc-button--primary" [palette]="checkButtonWord(provider) === 'words.connect' ? 'primary' : 'secondary'"
                                (click)="redirectAvailableProvider(provider)">
                                {{ checkButtonWord(provider) | translate }}
                            </msc-button>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="container-applications__item col-4 col-md-4 col-xxs-12"
                *ngFor="let integration of integrationCollection">
                <ul class="block-provider">
                    <li class="block-provider__card">
                        <div class="block-provider__card__picture"
                            [style.backgroundImage]="'url(' + getResource(integration) + ')'"></div>

                        <div class="block-provider__incoming">
                            <msc-button class="msc-button--primary" palette="secondary" (click)="contact()">
                                {{ 'words.contact-us' | translate }}
                            </msc-button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
