import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OrganizeRoutingModule } from './organize-routing.module';

import { SharedModule } from '../../shared.module';
import { AppCommonModule } from '@common/common.module';
import { AppCommon2Module } from '@common2/common.module';

import { OrganizeComponent } from './organize.component';

// Pages Users
import { OrganizeUserComponent } from './user/user.component';
import { OrganizeUserConfigureAccessesComponent } from './user/watch/configure/accesses/accesses.component';
import { OrganizeUserConfigureCoachComponent } from './user/watch/configure/coach/coach.component';
import { OrganizeUserConfigureComponent } from './user/watch/configure/configure.component';
import { OrganizeUserConfigureOnboardingComponent } from './user/watch/configure/onboarding/onboarding.component';
import { OrganizeUserConfigurePersonalDataComponent } from './user/watch/configure/personal-data/personal-data.component';
import { OrganizeUserConfigureRecapComponent } from './user/watch/configure/recap/recap.component';
import { OrganizeUserConfigureSettingsComponent } from './user/watch/configure/settings/settings.component';
import { OrganizeUserInvitationComponent } from './user/watch/invitation/invitation.component';
import { OrganizeUserUpdateComponent } from './user/watch/update/update.component';
import { OrganizeUserListComponent } from './user/list/list.component';
import { OrganizeUserModalManageInvitationComponent } from './user/modal/manage-invitation/manage-invitation.component';
import { OrganizeUserModalMassiveInvitationComponent } from './user/modal/massive-invitation/massive-invitation.component';
import { OrganizeUserModalResetComponent } from './user/modal/reset-password/reset-password.component';
import { OrganizeUsersAsideReassignComponent } from './user/list/aside/reassing-manager.component';
import { OrganizeUserWatchComponent } from './user/watch/watch.component';

// Pages Experiences
import { OrganizeExperienceAsideCreateTemplateComponent } from './experience/watch/theme/aside/create-template/create-template.component';
import { OrganizeExperienceComponent } from './experience/experience.component';
import { OrganizeExperienceCustomSVGComponent } from './experience/watch/theme/custom-svg/custom-svg.component';
import { OrganizeExperienceListComponent } from './experience/list/list.component';
import { OrganizeExperienceWatchCatalogueComponent } from './experience/watch/catalogue/catalogue.component';
import { OrganizeExperienceWatchComponent } from './experience/watch/watch.component';
import { OrganizeExperienceWatchDashboardComponent } from './experience/watch/dashboard/dashboard.component';
import { OrganizeExperienceWatchLoginComponent } from './experience/watch/login/login.component';
import { OrganizeExperienceWatchMailComponent } from './experience/watch/mail/mail.component';
import { OrganizeExperienceWatchMailCreateComponent } from './experience/watch/mail/mail-create/mail-create.component';
import { OrganizeExperienceWatchMailDetailsComponent } from './experience/watch/mail/mail-details/mail-details.component';
import { OrganizeExperienceWatchMailEditComponent } from './experience/watch/mail/mail-edit/mail-edit.component';
import { OrganizeExperienceWatchMailItemComponent } from './experience/watch/mail/mail-item/mail-item.component';
import { OrganizeExperienceWatchMailListComponent } from './experience/watch/mail/mail-list/mail-list.component';
import { OrganizeExperienceWatchThemeComponent } from './experience/watch/theme/theme.component';
import { OrganizeExperienceWatchUrlComponent } from './experience/watch/url/url.component';
import { OrganizeExperienceWatchUrlCreateComponent } from './experience/watch/url/url-create/url-create.component';
import { OrganizeExperienceWatchUrlDetailsComponent } from './experience/watch/url/url-details/url-details.component';
import { OrganizeExperienceWatchUrlItemComponent } from './experience/watch/url/url-item/url-item.component';
import { OrganizeExperienceWatchUrlListComponent } from './experience/watch/url/url-list/url-list.component';

// Pages Company
import { OrganizeCompanyAsideAddressComponent } from './company/subscription/aside/address/address.component';
import { OrganizeCompanyComponent } from './company/company.component';
import { OrganizeCompanyInvoiceComponent } from './company/invoice/invoice.component';
import { OrganizeCompanySettingsComponent } from './company/settings/settings.component';
import { OrganizeCompanyAdvancedSettingsComponent } from './company/advanced/advanced.component';
import { OrganizeCompanySubscriptionComponent } from './company/subscription/subscription.component';

// Pages Integrations
import { OrganizeIntegrationAsideExceptionManageComponent } from './integration/watch/skills/exception/aside/manage/manage.component';
import { OrganizeIntegrationAsideFreshChatComponent } from './integration/aside/freshchat/aside-freshchat.component';
import { OrganizeIntegrationComponent } from './integration/integration.component';
import { OrganizeIntegrationListComponent } from './integration/list/list.component';
import { OrganizeIntegrationsProvidersComponent } from './integration/providers/providers.component';
import { OrganizeIntegrationWatchCatalogueExportComponent } from './integration/watch/catalogue-export/catalogue-export.component';
import { OrganizeIntegrationWatchComponent } from './integration/watch/watch.component';
import { OrganizeIntegrationWatchExceptionComponent } from './integration/watch/skills/exception/exception.component';
import { OrganizeIntegrationWatchMappingComponent } from './integration/watch/skills/mapping/mapping.component';
import { OrganizeIntegrationWatchMappingEditComponent } from './integration/watch/skills/mapping/edit/edit.component';
import { OrganizeIntegrationWatchRulesComponent } from './integration/watch/rules/rules.component';
import { OrganizeIntegrationWatchRulesEditAccessibilityPanelComponent } from './integration/watch/rules/edit/accessibility-panel/accessibility-panel.component';
import { OrganizeIntegrationWatchRulesEditAsideSelectionPanelFilteredComponent } from './integration/watch/rules/edit/selection-panel/filtered/aside/aside-selection-panel-filtered.component';
import { OrganizeIntegrationWatchRulesEditComponent } from './integration/watch/rules/edit/edit.component';
import { OrganizeIntegrationWatchRulesEditNamePanelComponent } from './integration/watch/rules/edit/name-panel/name-panel.component';
import { OrganizeIntegrationWatchRulesEditSelectionPanelComponent } from './integration/watch/rules/edit/selection-panel/selection-panel.component';
import { OrganizeIntegrationWatchRulesEditSelectionPanelFilteredComponent } from './integration/watch/rules/edit/selection-panel/filtered/selection-panel-filtered.component';
import { OrganizeIntegrationWatchRulesEditSelectionPanelManualComponent } from './integration/watch/rules/edit/selection-panel/manual/selection-panel-manual.component';
import { OrganizeIntegrationWatchRulesEditSummaryPanelComponent } from './integration/watch/rules/edit/summary-panel/summary-panel.component';
import { OrganizeIntegrationWatchRulesRecapComponent } from './integration/watch/rules/edit/recap/recap.component';
import { OrganizeIntegrationWatchSettingsComponent } from './integration/watch/settings/settings.component';
import { OrganizeIntegrationWatchStatsComponent } from './integration/watch/stats/stats.component';

// Pages Knowledge
import { OrganizeKnowledgeAsideCreateComponent } from './knowledge/list/aside/create-skill/create-skill.component';
import { OrganizeKnowledgeAsideMergeComponent } from './knowledge/list/aside/merge-skill/merge-skill.component';
import { OrganizeKnowledgeComponent } from './knowledge/knowledge.component';
import { OrganizeKnowledgeListComponent } from './knowledge/list/list.component';
import { OrganizeKnowledgeWatchComponent } from './knowledge/watch/watch.component';
import { OrganizeKnowledgeWatchContentAddComponent } from './knowledge/watch/content/components/add/add.component';
import { OrganizeKnowledgeWatchContentComponent } from './knowledge/watch/content/content.component';
import { OrganizeKnowledgeWatchContentListComponent } from './knowledge/watch/content/components/list/list.component';
import { OrganizeKnowledgeWatchSummaryComponent } from './knowledge/watch/summary/summary.component';

// Pages Statistics
import { OrganizeStatisticAdoptionComponent } from './statistic/adoption/adoption.component';
import { OrganizeStatisticComponent } from './statistic/statistic.component';
import { OrganizeStatisticProvidersComponent } from './statistic/providers/providers.component';
import { OrganizeStatisticProvidersDetailComponent } from './statistic/providers/detail/detail.component';
import { OrganizeStatisticProvidersListComponent } from './statistic/providers/list/list.component';

// Pages Camp
import { CampAsideCatalogueComponent } from './camp/settings/settings-features/aside/aside-catalogue/aside-catalogue.component';
import { CampAsideContentComponent } from './camp/community/aside/content/content.component';
import { CampAsideGroupsComponent } from './camp/template/aside/aside-groups/aside-groups.component';
import { CampAsideInviteMembersChoiceComponent } from './camp/member/aside/aside-invite-members/aside-invite-members-choice/aside-invite-members-choice.component';
import { CampAsideInviteMembersComponent } from './camp/member/aside/aside-invite-members/aside-invite-members.component';
import { CampAsideInviteMembersRightsComponent } from './camp/member/aside/aside-invite-members/aside-invite-members-rights/aside-invite-members-rights.component';
import { CampAsideMembersComponent } from './camp/template/aside/aside-members/aside-members.component';
import { CampAsideOnDemandComponent } from './camp/template/aside/aside-ondemand/aside-ondemand.component';
import { CampAsidePublicationComponent } from './camp/template/aside/aside-publication/aside-publication.component';
import { CampAsideRefusedSubscriptionComponent } from './camp/subscription/aside/aside-refused/aside-refused.component';
import { CampAsideResourcesComponent } from './camp/community/aside/resources/resources.component';
import { CampAsideSettingsComponent } from './camp/community/aside/settings/settings.component';
import { CampAsideSubscriptionComponent } from './camp/template/aside/aside-subscription/aside-subscription.component';
import { CampAsideTimelineComponent } from './camp/settings/settings-features/aside/aside-timeline/aside-timeline.component';
import { CampAsideTrainersComponent } from './camp/template/aside/aside-trainers/aside-trainers.component';
import { CampAsideUsersComponent } from './camp/community/aside/users/users.component';
import { CampCommunityComponent } from './camp/community/community.component';
import { CampComponent } from './camp/camp.component';
import { CampCorrectionComponent } from './camp/correction/correction.component';
import { CampMemberComponent } from './camp/member/member.component';
import { CampProjectCorrectionComponent } from './camp/correction/project/project.component';
import { CampQuestionCorrectionComponent } from './camp/correction/question/question.component';
import { CampSelfAssessmentCorrectionComponent } from './camp/correction/self-assessment/self-assessment.component';
import { CampSettingsComponent } from './camp/settings/settings.component';
import { CampSettingsFeaturesComponent } from './camp/settings/settings-features/settings-features.component';
import { CampSettingsGeneralComponent } from './camp/settings/settings-general/settings-general.component';
import { CampSettingsNotificationsComponent } from './camp/settings/settings-notifications/settings-notifications.component';
import { CampStatsAdoptionComponent } from './camp/stats/stats-adoption/stats-adoption.component';
import { CampStatsComponent } from './camp/stats/stats.component';
import { CampStatsContentComponent } from './camp/stats/stats-content/stats-content.component';
import { CampStatsDetailComponent } from './camp/stats/stats-detail/stats-detail.component';
import { CampStatsProvidersComponent } from './camp/stats/stats-providers/stats-providers.component';
import { CampStatsProvidersDetailComponent } from './camp/stats/stats-providers/detail/detail.component';
import { CampStatsProvidersListComponent } from './camp/stats/stats-providers/list/list.component';
import { CampStatsTemplateComponent } from './camp/stats/stats-template/stats-template.component';
import { CampStatsUserComponent } from './camp/stats/stats-user/stats-user.component';
import { CampSubscriptionComponent } from './camp/subscription/subscription.component';
import { CampTemplateComponent } from './camp/template/template.component';
import { CampTimelineComponent } from './camp/timeline/timeline.component';
import { CampStatsExportsComponent } from './camp/stats/stats-exports/stats-exports.component';
import { OrganizeExportsComponent } from './statistic/exports/exports.component';

import { Organize2Module } from '@modules/organize/organize.module';
import { WatchSettingsComponent } from './experience/watch/settings/settings.component';
import { WatchCertificatesComponent } from './experience/watch/certificates/certificates.component';
import { DashboardModalComponent } from './experience/watch/settings/components/dashboard-modal/dashboard-modal.component';
import { DashboardComponent } from './statistic/dashboard/dashboard.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { GeneratedCertificatesComponent } from './certificates/tabs/generated-certificates/generated-certificates.component';
import { TransactionsComponent } from './certificates/tabs/transactions/transactions.component'; // Temporary
import { OnDemandComponent } from './on-demand/on-demand.component'; // Temporary

@NgModule({
    declarations: [
        CampAsideCatalogueComponent,
        CampAsideContentComponent,
        CampAsideGroupsComponent,
        CampAsideInviteMembersChoiceComponent,
        CampAsideInviteMembersComponent,
        CampAsideInviteMembersRightsComponent,
        CampAsideMembersComponent,
        CampAsideOnDemandComponent,
        CampAsidePublicationComponent,
        CampAsideRefusedSubscriptionComponent,
        CampAsideResourcesComponent,
        CampAsideSettingsComponent,
        CampAsideSubscriptionComponent,
        CampAsideTimelineComponent,
        CampAsideTrainersComponent,
        CampAsideUsersComponent,
        CampCommunityComponent,
        CampComponent,
        CampCorrectionComponent,
        CampMemberComponent,
        CampProjectCorrectionComponent,
        CampQuestionCorrectionComponent,
        CampSelfAssessmentCorrectionComponent,
        CampSettingsComponent,
        CampSettingsFeaturesComponent,
        CampSettingsGeneralComponent,
        CampSettingsNotificationsComponent,
        CampStatsAdoptionComponent,
        CampStatsComponent,
        CampStatsContentComponent,
        CampStatsDetailComponent,
        CampStatsProvidersComponent,
        CampStatsProvidersDetailComponent,
        CampStatsProvidersListComponent,
        CampStatsTemplateComponent,
        CampStatsExportsComponent,
        CampStatsUserComponent,
        CampSubscriptionComponent,
        CampTemplateComponent,
        CampTimelineComponent,
        OrganizeCompanyAsideAddressComponent,
        OrganizeCompanyComponent,
        OrganizeCompanyInvoiceComponent,
        OrganizeCompanySettingsComponent,
        OrganizeCompanyAdvancedSettingsComponent,
        OrganizeCompanySubscriptionComponent,
        OrganizeComponent,
        OrganizeExperienceAsideCreateTemplateComponent,
        OrganizeExperienceComponent,
        OrganizeExperienceCustomSVGComponent,
        OrganizeExperienceListComponent,
        OrganizeExperienceWatchCatalogueComponent,
        OrganizeExperienceWatchComponent,
        OrganizeExperienceWatchMailCreateComponent,
        OrganizeExperienceWatchMailDetailsComponent,
        OrganizeExperienceWatchMailEditComponent,
        OrganizeExperienceWatchMailItemComponent,
        OrganizeExperienceWatchMailListComponent,
        OrganizeExperienceWatchDashboardComponent,
        OrganizeExperienceWatchLoginComponent,
        OrganizeExperienceWatchMailComponent,
        OrganizeExperienceWatchThemeComponent,
        OrganizeExperienceWatchUrlComponent,
        OrganizeExperienceWatchUrlCreateComponent,
        OrganizeExperienceWatchUrlDetailsComponent,
        OrganizeExperienceWatchUrlItemComponent,
        OrganizeExperienceWatchUrlListComponent,
        OrganizeIntegrationAsideExceptionManageComponent,
        OrganizeIntegrationAsideFreshChatComponent,
        OrganizeIntegrationComponent,
        OrganizeIntegrationListComponent,
        OrganizeIntegrationsProvidersComponent,
        OrganizeIntegrationWatchCatalogueExportComponent,
        OrganizeIntegrationWatchComponent,
        OrganizeIntegrationWatchExceptionComponent,
        OrganizeIntegrationWatchMappingComponent,
        OrganizeIntegrationWatchMappingEditComponent,
        OrganizeIntegrationWatchRulesComponent,
        OrganizeIntegrationWatchRulesEditAccessibilityPanelComponent,
        OrganizeIntegrationWatchRulesEditAsideSelectionPanelFilteredComponent,
        OrganizeIntegrationWatchRulesEditComponent,
        OrganizeIntegrationWatchRulesEditNamePanelComponent,
        OrganizeIntegrationWatchRulesEditSelectionPanelComponent,
        OrganizeIntegrationWatchRulesEditSelectionPanelFilteredComponent,
        OrganizeIntegrationWatchRulesEditSelectionPanelManualComponent,
        OrganizeIntegrationWatchRulesEditSummaryPanelComponent,
        OrganizeIntegrationWatchRulesRecapComponent,
        OrganizeIntegrationWatchSettingsComponent,
        OrganizeIntegrationWatchStatsComponent,
        OrganizeKnowledgeAsideCreateComponent,
        OrganizeKnowledgeAsideMergeComponent,
        OrganizeKnowledgeComponent,
        OrganizeKnowledgeListComponent,
        OrganizeKnowledgeWatchComponent,
        OrganizeKnowledgeWatchContentAddComponent,
        OrganizeKnowledgeWatchContentComponent,
        OrganizeKnowledgeWatchContentListComponent,
        OrganizeKnowledgeWatchSummaryComponent,
        OrganizeStatisticAdoptionComponent,
        OrganizeExportsComponent,
        OrganizeStatisticComponent,
        OrganizeStatisticProvidersComponent,
        OrganizeStatisticProvidersDetailComponent,
        OrganizeStatisticProvidersListComponent,
        OrganizeUserComponent,
        OrganizeUserConfigureAccessesComponent,
        OrganizeUserConfigureCoachComponent,
        OrganizeUserConfigureComponent,
        OrganizeUserConfigureOnboardingComponent,
        OrganizeUserConfigurePersonalDataComponent,
        OrganizeUserConfigureRecapComponent,
        OrganizeUserConfigureSettingsComponent,
        OrganizeUserInvitationComponent,
        OrganizeUserUpdateComponent,
        OrganizeUserListComponent,
        OrganizeUserModalManageInvitationComponent,
        OrganizeUserModalMassiveInvitationComponent,
        OrganizeUserModalResetComponent,
        OrganizeUsersAsideReassignComponent,
        OrganizeUserWatchComponent,
        WatchSettingsComponent,
        WatchCertificatesComponent,
        DashboardModalComponent,
        DashboardComponent,
        CertificatesComponent,
        GeneratedCertificatesComponent,
        TransactionsComponent,
        OnDemandComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        AppCommonModule,
        AppCommon2Module,
        OrganizeRoutingModule,
        Organize2Module,
    ],
})
export class OrganizeModule {}
