<div class="login login--background animate">
    <div class="login__header">
        <a class="login__logo">
            <img *ngIf="!display.logo" src="https://webservices.griky.co/cdn/img/Griky-Logotipo.webp" alt="MySkillCamp">
            <img *ngIf="display.logo" [src]="display.logo + '?size=256'" [alt]="display.name">
        </a>
    </div>

    <div class="login__body login__body--corporate box">
        <msc-common-spinner *ngIf="loading"></msc-common-spinner>

        <div id="kyc-container"></div>
    </div>
</div>
