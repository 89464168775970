<section class="head">
    <h3>Overlay</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Basic overlay</h4>
        <hr>
        <div class="partition">
            <div class="segment gap-4">
                <div class="w-fit">
                    <msc-button element #origin="element" (click)="overlayDemo.open(origin.elementRef)">Pop me over
                    </msc-button>
                    <msc-common-overlay #overlayDemo>
                        <ng-template>
                            <div class="flex flex-col justify-center align-center p-6">
                                <h4>hooooray!!!</h4>
                            </div>
                        </ng-template>
                    </msc-common-overlay>
                </div>
                <div class="w-fit">
                    <msc-button element #originMenu="element" (click)="overlayMenuDemo.open(originMenu.elementRef)">Overlay menu
                    </msc-button>
                    <msc-common-overlay-menu #overlayMenuDemo [menuItems]="menuList">
                    </msc-common-overlay-menu>
                </div>
            </div>
        </div>
    </div>
</section>