<div class="block-event-card block-event flex flex-col space-y-4 items-center bg-white shadow-md py-8 px-4 justify-center "
    [ngClass]="{'block-event--centered': !loading && events.length === 0}" style="    height: 400px;">

    <msc-empty-state *ngIf="!loading && events.length === 0" [title]="'overview.next-events'"
        [description]="'empty.event'" [cypressHook]="'events'"></msc-empty-state>

    <ul class="block-event__list" *ngIf="!loading && events.length > 0">
        <li class="block-event__item" *ngFor="let event of events; let index=index" (click)="redirectPlayer(event)"
            [ngStyle]="{'cursor': event.isLocked ? 'not-allowed' : 'pointer'}">
            <div class="event__content">
                <div class="tag tag--date bg-primary-500">
                    <div class="tag__text">
                        <span class="text__day">{{ event.nextEvent | date:'dd' }}</span>

                        <span class="text__month">{{ event.nextEvent | localizedDate:'MMM' }}</span>
                    </div>
                </div>

                <div class="event__desc">
                    <p class="event__title" [tooltip]="event.title?.length > 40 ? event.title : ''">
                        {{ (event.title || ('overview.event-untitled' | translate) | truncate: 40) }}
                    </p>

                    <p class="event__camp">
                        <span *ngIf="event.internalName"
                            [tooltip]="event.internalName.length > 20 ? event.internalName : ''">
                            {{ event.internalName | truncate: 20 }}
                        </span>
                    </p>
                </div>
            </div>

            <div class="event__action">
                <i class="icon icon-arrow-right text-primary-600 text-lg"></i>
            </div>
        </li>
    </ul>

    <msc-common-spinner *ngIf="loading"></msc-common-spinner>
</div>
