<div class="container-integration__fieldset form-filter-fieldset col-xs-12 col-9">
    <h1 class="container-integration__fieldset__title">{{ 'words.selection' | translate }}</h1>
    <p class="container-integration__fieldset__description">{{ 'integrations.rules.selection-description' | translate }}
    </p>

    <div class="flex flex-col space-y-2">
        <msc-common2-radio groupName="selection" [(ngModel)]="rule.selectionType" [valueName]="'all'"
            [label]="'organize.integration.all-catalogue' | translate"></msc-common2-radio>

        <ng-container *ngIf="collection.hasSeveralLanguages || collection.hasSeveralCategories">
            <msc-common2-radio groupName="selection" [(ngModel)]="rule.selectionType" [valueName]="'filters'"
                [label]="'organize.integration.filetered-selection' | translate"></msc-common2-radio>
        </ng-container>

        <msc-common2-radio groupName="selection" [(ngModel)]="rule.selectionType" [valueName]="'manual'"
            [label]="'organize.integration.manual-selection' | translate"></msc-common2-radio>
    </div>
</div>