import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
//
import { UuidPipe } from '@common2/pipes/uuid.pipe';
import { CommonApiGetListOrderParams } from '@common2/common.types';
//
export type CommonThSortType = CommonApiGetListOrderParams['order'] | undefined;
export interface CommonThSortDatum {
    id: string;
    type: CommonThSortType;
    value: boolean;
}
//
const COMMON_TH_SORT_TYPE_LIST: ReadonlyArray<CommonThSortType> = ['asc', 'desc'];

@Component({
    selector: 'msc-common-th-sort',
    templateUrl: './th-sort.component.html',
    styleUrls: ['./th-sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UuidPipe],
})

export class CommonThSortComponent {
    @Input() label: string;
    @Input() name?: string; // used for table incapsulation of this component, when you have multiple tables on same page
    @Output() sortEvent: EventEmitter<CommonThSortType | undefined> = new EventEmitter<CommonThSortType | undefined>();

    public data: Array<CommonThSortDatum> = [];
    public id: string = this.uuidPipe.transform();

    constructor(
        private readonly uuidPipe: UuidPipe,
    ) { }

    ngOnInit(): void {
        this.setData();
    }

    /**
     * Set the th initial data
     */
    setData(): void {
        this.data = this.getData();
    }

    /**
     * Get the th initial data
     */
    getData(): Array<CommonThSortDatum> {
        return COMMON_TH_SORT_TYPE_LIST.map((type) => ({
            id: `${this.id}-${type}`,
            type,
            value: false,
        }));
    }

    /**
     * Get the th remapped data (when we start interacting)
     */
    getRemappedData(datum: CommonThSortDatum, value: boolean): Array<CommonThSortDatum> {
        return this.data.map((d) => ({
            ...d,
            value: d.id === datum.id ? value : false,
        }));
    }

    /**
     * Event handler for reset (this can be used with ViewChild from parent)
     */
    onReset(): void {
        this.setData();
    }

    /**
     * Event handler for sort next state
     */
    onSort(): void {
        const selected = this.data.find((d) => d.value)?.type;

        switch (selected) {
            case 'asc':
                this.data = this.getRemappedData(this.data[1], true);
                this.sortEvent.emit(this.data[1].type);
                break;
            case 'desc':
                this.data = this.getData();
                this.sortEvent.emit(undefined);
                break;
            default:
                this.data = this.getRemappedData(this.data[0], true);
                this.sortEvent.emit(this.data[0].type);
                break;
        }
    }
}
