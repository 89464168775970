import { CompanyExperience } from './experience';

export class Company {
    id: number;
    slug: string;
    name: string;
    type?: string;
    mode?: string;
    size?: string;
    picture?: string;
    prefix?: string;
    roles?: any;
    vat?: string;
    billing?: any;
    customUrl?: string;
    experience?: CompanyExperience;
    defaultExperienceSlug?: string;
    saleEmail?: string;
    colorTemplateLimit?: number;
    createdAt: Date;
    permissions: any;
    walletSettings?: any;
    usersCount:any;

    constructor(company: object) {
        this.assign(company);
    }

    assign(company: object): void {
        Object.assign(this, company);
    }
}
