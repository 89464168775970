import { Account } from '../account';
import { ConversationMessage } from './message';

export class Conversation {
    id: number;
    slug?: string;
    accounts?: Account[];
    members?: Account[];
    name?: string[];
    mentoring?: any;
    message?: string;
    messages?: ConversationMessage[];
    updatedAt?: number;
    connected?: any[];
    unreadMessages: number;

    constructor(conversation: object) {
        this.assign(conversation);
    }

    assign(conversation: object): void {
        Object.assign(this, conversation);

        if (this.accounts && this.accounts.length > 0) {
            this.accounts = this.accounts.map((account) => new Account(account));
        }

        if (this.members && this.members.length > 0) {
            this.members = this.members.map((account) => new Account(account));
        }

        if (this.messages && this.messages.length > 0) {
            this.messages = this.messages.map((message) => new ConversationMessage(message));
        }
    }
}
