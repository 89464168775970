import { Injectable, EventEmitter } from '@angular/core';
import { Camp } from '@lighty';
import { BehaviorSubject } from 'rxjs';

/**
 * Emitter Service
 */
@Injectable({providedIn: 'root'})
export class EmitterService {
    /**
     * Emitter collection
     */
    private subjectCollection: BehaviorSubject<any>[] = [];

    /**
     * Load more event emitter for section level
     */
    public loadMoreSection: EventEmitter<any> = new EventEmitter();

    /**
     * Refresh current user emitter
     */
    public refreshMe: EventEmitter<any> = new EventEmitter();

    /**
     * Refresh current experience emitter
     */
    public refreshExperience: EventEmitter<any> = new EventEmitter();

    /**
     * Camp active emitter (used by header)
     */
    public campActive: EventEmitter<Camp> = new EventEmitter();

    /**
     * Invitation user emitter (used by quick action)
     */
    public actionInvitationUser: EventEmitter<any> = new EventEmitter();

    /**
     * Create camp emitter (used by quick action)
     */
    public actionCreateCamp: EventEmitter<any> = new EventEmitter();

    /**
     * Create experience emitter (used by quick action)
     */
    public actionCreateExperience: EventEmitter<any> = new EventEmitter();

    /**
     * Open sidebar emitter
     */
    public openSidebar: EventEmitter<any> = new EventEmitter();

    /**
     * Sort emitter
     */
    public sort: EventEmitter<any> = new EventEmitter();

    /**
     * toggle extension emitter
     */
    public toggleExtension: EventEmitter<any> = new EventEmitter();

    /**
     * validate extension form
     */
    public extensionPage: EventEmitter<any> = new EventEmitter();

    /**
     * add new resource
     */
    public newResourceAdded: EventEmitter<any> = new EventEmitter();

    /**
     * load new resource url
     */
    public loadingExternalResource: EventEmitter<any> = new EventEmitter();

    /**
     * update team members
     */
    public totalTeamMembers: EventEmitter<any> = new EventEmitter();

    /**
     * Defer an emitter
     * @param {string} emitter - Emitter's name
     * @param {number} duration - Defer duration
     */
    public deferEmitter(emitter: string, duration: number = 2000): void {
        const timeOut = setTimeout(() => {
            this[emitter].emit(true);
            clearTimeout(timeOut);
        }, duration);
    }

    /**
     * Get emitter
     * @param {string} key - Emitter key
     * @return {BehaviorSubject<any>} - Emitter
     */
    get(key: string): BehaviorSubject<any> {
        if (!this.subjectCollection[key]) {
            this.set(key, {});
        }
        return this.subjectCollection[key];
    }

    /**
     * Set an emitter
     * @param {string} key - Emitter key
     * @param {any} data - Any data you need
     * @return {BehaviorSubject<any>} - Emitter
     */
    set(key: string, data: any): BehaviorSubject<any> {
        if (!this.subjectCollection[key]) {
            this.subjectCollection[key] = new BehaviorSubject(data);
        } else {
            this.subjectCollection[key].next(data);
        }

        return this.get(key);
    }

    /**
     * Delete an emitter or all emitters
     * @param {string} key - Emitter key
     */
    delete(key?: string): void {
        if (key) {
            delete this.subjectCollection[key];
        } else {
            this.subjectCollection = [];
        }
    }
}
