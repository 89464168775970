export class CompanyPermission {

    constructor(permission: object) {
        this.assign(permission);
    }

    assign(permission: object): void {
        Object.assign(this, permission);
    }
}
