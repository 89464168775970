import { Directive, OnInit, OnDestroy } from '@angular/core';
import { addClass, removeClass } from '@functions';

@Directive({
    selector: '[modal]'
})
export class ModalDirective implements OnInit, OnDestroy {

    constructor() {}

    ngOnInit(): void {
        addClass('.app-main', 'disable-overflow');
    }

    ngOnDestroy(): void {
        removeClass('.app-main', 'disable-overflow');
    }
}
