<div class="content-invitation w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4">
    <div class="content-invitation__header">
        <ng-container>
            <div class="flex flex-import">
                <h3>{{ "user.update-data" | translate }}</h3>
                <msc-button
                    class="msc-button--outline ml-2"
                    (click)="downloadExample()"
                >
                    {{ "user.import-invite.download-example" | translate }}
                </msc-button>

                <a
                    #downloadUrl
                    [href]="exampleUrl"
                    target="_blank"
                    download
                    class="display-none"
                ></a>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="currentStep === 0">
        <p class="content-invitation__subheader">
            <ng-container>
                {{ "user.update-data.description" | translate }}
            </ng-container>
        </p>

        <div class="content-invitation__content">
            <div class="content-invitation__dataset">
                <div
                    class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5"
                >
                    <msc-button
                        class="msc-button--primary"
                        icon="icon-upload"
                        palette="secondary"
                        (click)="importCSV()"
                        *ngIf="!data.file"
                    >
                        {{ "words.upload" | translate }}
                    </msc-button>

                    <input
                        #csvFile
                        class="display-none"
                        type="file"
                        accept=".xlsx,.xls,.csv"
                        (change)="
                            updateFile($event.target.files[0]);
                            csvFile.value = null
                        "
                    />

                    <div class="flex">
                        <div
                            class="content-invitation__tag content-invitation__tag--file"
                            *ngIf="data.file"
                        >
                            <i class="icon icon-file"></i>
                            <p>{{ data.file.name }}</p>
                            <span (click)="updateFile()"
                                ><i class="icon icon-close"></i
                            ></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-invitation__dataset">
                <div
                    class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5"
                >
                    <label class="flex text-sm mb-1">{{
                        "words.experience" | translate
                    }}</label>
                    <ng-container *ngIf="selectData.experiences.length">
                        <msc-common-select [data]="selectData.experiences">
                            <ng-template #buttonTemplate let-data="data">
                                <button
                                    class="flex text-sm text-black-400 w-full"
                                >
                                    <ng-container *ngIf="data?.length">
                                        <span class="truncate">{{
                                            data[0].label
                                        }}</span>
                                    </ng-container>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <button
                                    class="flex text-left text-sm py-1"
                                    (click)="
                                        onSelectOption(datum, 'experiences')
                                    "
                                >
                                    <span>{{ datum.label }}</span>
                                </button>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>
            </div>

            <msc-button
                class="msc-button--primary"
                palette="secondary"
                (click)="openConfirmacionModal()"
                [disabled]="!data.file"
                >{{ "words.continue" | translate }}</msc-button
            >
        </div>
    </ng-container>
</div>


<msc-common-modal size="30vw" #modalConfirmation [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'words.massive-users' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalConfirmation.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--flat" palette="primary" (click)="updateUsers()">{{'common.confirm' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

