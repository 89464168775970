export class Media {
    id: number;
    url: string;
    originalUrl?: string;
    pictureUrl?: string;
    title: string;
    type: string;
    storage: string;

    constructor(media: object) {
        this.assign(media);
    }

    assign(media: object): void {
        Object.assign(this, media);
    }
}
