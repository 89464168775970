import { Injectable } from '@angular/core';
//
import { Subject } from 'rxjs';
//
import { CommonNavDatum } from '@common2/components/nav/nav.component';

@Injectable()
export abstract class PageHubModel {
    public nav: Array<CommonNavDatum>;
    protected destroy$: Subject<void> = new Subject<void>();
    protected repeat$: Subject<void> = new Subject<void>();
    //
    protected abstract getNav(): Array<CommonNavDatum>;

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the nav data of the page
     */
    setNav(): void {
        this.nav = this.getNav();
    }
}
