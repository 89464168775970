import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../../pagination';
import { environment } from '@env/environment';

@Injectable()
export class AdminGroupResourceProvider {

    constructor(private http: HttpClient) { }

    getResource(company_id: string, group_id: number, params?: any): Observable<any> {
        const response = {
            resources: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/resources`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.resources.map(element => {
                    response.resources.push(element);
                });
                return response;
            })
        );
    }

    storeResource(company_id: string, group_id: number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/resources`, data).pipe(
            map(data => data)
        );
    }

    updateResource(groupId: number, resourceId: number, title: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/group/${groupId}/resource/${resourceId}`, title).pipe(
            map(data => data)
        );
    }

    deleteResource(company_id: string, group_id: number, resource_id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/resources/${resource_id}`).pipe(
            map(data => data)
        );
    }
}
