import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'msc-camp-timeline',
    templateUrl: './timeline.component.html'
})
export class CampTimelineComponent implements OnInit {
    public campId: number;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.parent.params.subscribe((params) => {
            this.campId = params.campId;
        });
    }
}
