import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { AdminTimelineProvider } from '@lighty';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-camp-aside-timeline',
    templateUrl: './aside-timeline.component.html'
})
export class CampAsideTimelineComponent {
    @ViewChild('aside') aside;
    @Input() campId: number;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public timeline: any;
    public ready: boolean = false;
    public onCloseByUser: EventEmitter<any> = new EventEmitter();

    constructor(
        private timelineProvider: AdminTimelineProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.getSettings();
    }

    public onOpen() {
        this.init();
        this.aside.onOpen();
    }

    getSettings(): void {
        const params = {
            context: 'camp',
            context_id: this.campId
        };

        this.timelineProvider.getSettings(params).subscribe((data) => {
            this.timeline = data;
            this.ready = true;
        });
    }

    changeSettings(item: string, type: string, value: string): void {
        this.timeline.settings[item][type] = value;
    }

    update(): void {
        this.timelineProvider.update(this.timeline.id, this.timeline).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.aside.onClose();
        });
    }

    close(): void {
        this.onClose.emit(true);
    }
}
