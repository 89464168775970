<div [class]="palette">
    <ng-container *ngIf="icon">
        <i class="icon {{icon}}"></i>
    </ng-container>
    <ng-container *ngIf="pictureURL">
        <img *ngIf="pictureURL" [src]="pictureURL">
    </ng-container>
    <span>{{ label }}</span>
    <ng-container *ngIf="hasRemoveButton">
        <button (click)="onRemove($event)"></button>
    </ng-container>
</div>