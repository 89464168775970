<section class="head">
    <h3>{{ 'words.contents' | translate }} <sup>{{ total }}</sup></h3>
    <div class="toolbar">
        <div class="toolbar-content-nav flex space-x-2 items-center">
            <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>

            <ng-container *ngIf="availableTypes">
                <msc-common-select class="msc-select--small" [data]="availableTypes" [isButton]="true" type="multi">
                    <ng-template #buttonTemplate let-data="data">
                        <span class="font-bold text-xs text-gray-600">{{ 'words.types' | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <label class="flex py-2 w-full">
                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectType(datum)"></msc-common2-checkbox>
                            <span class="ml-2">{{ datum.label }}</span>
                        </label>
                    </ng-template>
                </msc-common-select>
            </ng-container>

            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                {{ 'words.filters' | translate }}
            </msc-button>

            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>

            <msc-common2-checkbox [value]="isTrainer" [label]="'filter.contents-trainer' | translate" (changeEvent)="isTrainer = !isTrainer; onFilter()"></msc-common2-checkbox>
        </div>
    </div>
</section>

<section class="body">
    <div class="content">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async" (onScrollEnd)="hasLoadMore ? onLoad() : null">
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <button [ngClass]="{ 'is-favorite' : datum.isFavorite }" type="button" (click)="addToFavorite(datum)"><i class="icon icon-star-1"></i></button>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <i class="icon icon-{{ datum.icon }} icon-table"></i>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="grouping">
                                <span class="cursor-pointer hover:underline" [tooltip]="datum.data.title" [direction]="'bottom'" (click)="goToContent(datum)">{{ datum.data.title | truncate: 20 }}</span>
                                <small class="mt-1" [tooltip]="datum.data.internalName">{{ datum.data.internalName | truncate: 20 }}</small>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="wrapper">
                                <i class="icon icon-calendar icon-table" *ngIf="datum.data.nextEvent"></i>
                                <span *ngIf="datum.data.nextEvent">{{ datum.data.nextEvent | date:'dd'}} {{ datum.data.nextEvent | date:'MMMM'}}</span>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="wrapper">
                                <i class="icon icon-people icon-table"></i>
                                <span>{{ datum.data.studentsCount }}</span>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common-select class="table-select msc-select--small" [isButton]="true" [data]="datum.dataSelect" (selectEvent)="updateStatus(datum, $event)"></msc-common-select>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common2-switch [value]="datum.data.hidden" (changeEvent)="addToArchived(datum)"></msc-common2-switch>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'common.empty' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <div class="flex justify-center">
                    <msc-common-spinner></msc-common-spinner>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="(isLoadingMore$ | async) === 'loading'">
        <div class="flex justify-center">
            <msc-common-spinner></msc-common-spinner>
        </div>
    </ng-container>
</section>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>
