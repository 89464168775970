import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { IntegrationProvider, Pagination, KnowledgeProvider } from '@lighty';
import { HttpHelper } from '@helpers';
import * as _ from 'lodash';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-organize-integration-aside-exception-manage',
    templateUrl: './manage.component.html'
})
export class OrganizeIntegrationAsideExceptionManageComponent {
    @ViewChild('aside') aside;
    public REMOVE = "remove";
    public ADD = "add";

    @Input() companySlug;

    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();

    public isActive: boolean = false;
    public query: string;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public data;

    loadingSkills: boolean = false;
    loading: boolean = false;
    availableSkills: any[] = [];
    pagination: any = new Pagination({
        perPage: 5,
    });

    removedExceptions = [];
    addedExceptions = [];

    public currentContent;

    saving: boolean = false;

    constructor(
        private integrationProvider: IntegrationProvider,
        private knowledgeProvider: KnowledgeProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    public onOpen(currentEdit) {
        this.data = currentEdit;
        this.aside.onOpen();

        this.isActive = true;
        const params = HttpHelper.cleanParams({
            context: this.data.context,
        });
        this.loading = true;
        this.integrationProvider.getProviderContent(this.data.companyId, this.data.providerId, this.data.contentId, params).subscribe((data: any) => {
            this.currentContent = {
                ...data,
                ...{
                    skills: data.skills.map((skill) => ({
                        ...skill,
                        linkedRule: data.skillRules.filter((rule) => rule.skill.id == skill.id)[0]
                    }))
                }
            };
            this.getAvailableSkills();
            this.loading = false;
        });
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        const params = {
            context: this.data.context,
            rules: this.currentContent.skillRules.map(rule => ({ content_skill_id: rule.skill.id, type: rule.type }))
        }

        this.saving = true;
        this.integrationProvider.updateProviderContent(this.data.companyId, this.data.providerId, this.data.contentId, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.integration.exception-updated'));
            this.saving = false;
            this.currentContent.skillRules.filter((rule) => !rule.id).forEach(rule => {
                if (rule.type === this.REMOVE) {
                    this.currentContent.skills = this.currentContent.skills.filter((skill) => skill.id !== rule.skill.id);
                } else if (rule.type === this.ADD) {
                    this.currentContent.skills.push(rule.skill);
                }
            });
            this.onSave.emit({
                id: this.data.contentId,
                skills: this.currentContent.skills
            });
        }, (err) => {
            this.onClose.emit(true);
        });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    removeRule(rule) {
        if (rule.type === this.ADD) {
            this.availableSkills.push(rule.skill);
            this.currentContent.skills = this.currentContent.skills.filter(existingSkill => existingSkill.id != rule.skill.id);
        }

        if (rule.type === this.REMOVE) {
            let skillIndex = this.currentContent.skills.findIndex(skill => skill.id == rule.skill.id);
            if (skillIndex !== -1) {
                this.currentContent.skills[skillIndex].checked = false;
            } else {
                this.currentContent.skills.push(rule.skill);
            }
        }

        this.currentContent.skillRules = this.currentContent.skillRules.filter(existingRule => existingRule.skill.id != rule.skill.id);
    }

    addSkillToException(skill, action) {
        if (action === this.ADD) {
            this.availableSkills = this.availableSkills.filter((item) => item.id != skill.id);
        } else if (action === this.REMOVE) {
            skill.checked = true;
        }

        this.currentContent.skillRules.push({
            skill,
            type: action
        });
    }

    private getAvailableSkills(): void {
        this.loadingSkills = true;
        this.knowledgeProvider.get(this.data.slug).subscribe((data) => {
            this.availableSkills = _.differenceWith(data.skills, [...this.currentContent.skillRules, ...this.currentContent.skills], (val1, val2) => _.isEqual(val1.id, val2.skill ? val2.skill.id : val2.id));
            this.loadingSkills = false;
        });
    }

    searchSkill(query?): void {
        this.query = query;
        this.loadingSkills = true;
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.knowledgeProvider.get(this.data.slug, params).subscribe((data) => {
            this.availableSkills = _.differenceWith(data.skills, [...this.currentContent.skillRules, ...this.currentContent.skills], (val1, val2) => _.isEqual(val1.id, val2.skill ? val2.skill.id : val2.id));
            this.loadingSkills = false;
        });
    }
}
