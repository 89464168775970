import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
//
export type CommonTooltipDirection = 'top' | 'right' | 'bottom' | 'left';
//
const COMMON_TOOLTIP_DELAY_TIME_MILISECONDS: number = 300;

@Component({
    selector: 'msc-common-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('tooltip', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(COMMON_TOOLTIP_DELAY_TIME_MILISECONDS, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate(COMMON_TOOLTIP_DELAY_TIME_MILISECONDS, style({ opacity: 0 })),
            ]),
        ]),
    ]
})

export class CommonTooltipComponent {
    @Input() direction: CommonTooltipDirection;
    @Input() message: string;
}
