import { Component, OnInit, OnDestroy } from '@angular/core';
import { Company, Camp, CompanyUserAccessProvider, UserProvider, CompanyUserPermissionsProvider } from '@lighty';
import { ActivatedRoute } from '@angular/router';
import { StorageService, EmitterService, TranslateService } from '@services';
import { HttpHelper, DataHelper } from '@helpers';
import { SubscriptionCollection } from '@classes';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-account-access-organize',
    templateUrl: './organize.component.html'
})
export class AccountAccessOrganizeComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public me: any;
    public accountId: number;
    public company: Company;
    public camps: Camp[];
    public availableCamps: Camp[];
    public user: any;
    public queries: any;
    public loading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private companyUserAccessProvider: CompanyUserAccessProvider,
        private userProvider: UserProvider,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private emitterService: EmitterService,
        private companyUserPermissionsProvider: CompanyUserPermissionsProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.route.parent.parent.snapshot.data.company;
        this.camps = this.route.snapshot.data.access.camps;
        this.availableCamps = this.route.snapshot.data.access.availableCamps;
        this.user = this.route.parent.parent.snapshot.data.user;

        this.queries = {
            current: '',
            available: ''
        };

        this.route.parent.parent.params.subscribe((params) => {
            this.accountId = parseInt(params.accountId, 10);
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('update.access.refresh').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.search();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    search(query?): void {
        this.queries.current = query;
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            q1: this.queries.current
        });

        this.companyUserAccessProvider.getOrganize(this.company.id, params).subscribe((data) => {
            this.camps = data.camps;
        });
    }

    searchAvailable(query?): void {
        this.queries.available = query;
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            q: this.queries.available
        });

        this.companyUserAccessProvider.getOrganize(this.company.id, params).subscribe((data) => {
            this.availableCamps = data.availableCamps;
        });
    }

    updateAccess(): void {
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            company_owner: this.user.companyRoles.companyOwner
        });

        this.loading = true;

        this.companyUserAccessProvider.update(this.company.id, params, 'administrator').subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));

            this.userProvider.getAccount(this.company.id, this.accountId).subscribe((data) => {
                this.user.companyRoles = data.companyRoles;
                this.loading = false;
            });
        });
    }

    updatePermission(permission: string, force?: boolean): void {
        if (this.user.permissions[permission] || force) {
            const params = HttpHelper.cleanParams({
                permission: DataHelper.changeCase(permission)
            });

            this.companyUserPermissionsProvider.addPermission(this.company.id, this.accountId, params).subscribe(() => {
                this.user.permissions[permission] = true;
            });
        } else {
            this.deletePermission(permission);
        }
    }

    deletePermission(permission: string): void {
        const params = HttpHelper.cleanParams({
            permission: DataHelper.changeCase(permission)
        });

        this.companyUserPermissionsProvider.deletePermission(this.company.id, this.accountId, params).subscribe(() => {
            this.user.permissions[permission] = false;
        });
    }

    updateAccessSettings(camp: Camp): void {
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            camp_id: camp.id,
            manage_camp_settings: camp.permissions.manageCampSettings
        });

        this.companyUserAccessProvider.update(this.company.id, params, 'administrator').subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    addCamp(camp: Camp): void {
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            camp_ids: [camp.id]
        });

        this.companyUserAccessProvider.addCamps(this.company.id, params, 'administrator').subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));

            this.companyUserAccessProvider.getOrganize(this.company.id, { account_id: this.accountId }).subscribe((data) => {
                this.camps = data.camps;
                this.availableCamps = data.availableCamps;

                this.emitAccess();
            });
        });
    }

    removeCamp(camp: Camp): void {
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            camp_id: camp.id
        });

        this.companyUserAccessProvider.removeCamp(this.company.id, params, 'administrator').subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.availableCamps.push(camp);

            const index = this.camps.findIndex((data) => {
                return data.id === camp.id;
            });

            if (index !== -1) {
                this.camps.splice(index, 1);
            }

            this.emitAccess();
        });
    }

    emitAccess(): void {
        if (this.camps.length === 0) {
            this.emitterService.set('update.access', { role: 'companyAdministrator', enable: this.user.companyRoles.companyOwner });
        } else if (this.camps.length === 1) {
            this.emitterService.set('update.access', { role: 'companyAdministrator', enable: true });
        }
    }

    disableAccess(): void {
        this.user.companyRoles.companyAdministrator = false;
        this.user.companyRoles.companyOwner = false;
        const roles = [
            { role: 'companyAdministrator', enable: this.user.companyRoles.companyAdministrator },
            { role: 'companyOwner', enable: this.user.companyRoles.companyOwner }
        ];
        this.emitterService.set('update.access', roles);
        this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        this.camps = [];

        this.queries.current = null;
    }
}
