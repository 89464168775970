declare var require: any;

/**
 * Countries class
 */
export class Countries {
    /**
     * List of countries
     */
    private countries: string[] = [];

    /**
     * Insert each list of countries, sort by lang
     * @constructor
     */
    constructor() {
        this.countries['fr'] = require('../../assets/countries/fr.json');
        this.countries['en'] = require('../../assets/countries/en.json');
        this.countries['it'] = require('../../assets/countries/en.json');
        this.countries['nl'] = require('../../assets/countries/nl.json');
        this.countries['de'] = require('../../assets/countries/de.json');
    }

    /**
     * Get collection of countries
     * @param {string} lang - Language
     * @return {any} - collection
     */
    get(lang: string): any {
        const countries = this.countries[lang];
        const countriesCollection = [];
        for (const code in countries) {
            if (countries.hasOwnProperty(code)) {
                countriesCollection.push({code, name: countries[code]});
            }
        }

        return countriesCollection.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }
}
