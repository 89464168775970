import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataHelper, HttpHelper } from '@helpers';
import { StorageService, TranslateService } from '@services';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityFactory, ManageCorrectionProvider, Pagination, TimelineProvider } from '@lighty';
import { TimelineComponent } from '@components/timeline/timeline.component';
import { MediaManagerComponent } from '@components/media-manager/media-manager.component';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-manage-instance-activity-correction',
    templateUrl: './correction-activity.component.html',
    styleUrls: ['./correction-activity.component.scss']
})
export class ManageInstanceCorrectionActivityComponent implements OnInit {
    @ViewChild('uploadedFile') upload: ElementRef;
    @ViewChild('correctionTimeline') correctionTimeline: TimelineComponent;
    @ViewChild('mediaManagerLearner') mediaManagerLearner: MediaManagerComponent;
    @ViewChild('mediaManagerTrainer') mediaManagerTrainer: MediaManagerComponent;
    private me: any;
    private company: any;
    private activityId: number;
    private instanceId: number;
    private journey: any;
    public pagination: Pagination;
    public unCorrectedOnly: boolean = false;
    public allowCorrecting: boolean = false;
    public loading: boolean = false;
    public query: string = '';
    public corrections: any[] = [];
    public section: any = {};
    public activeFilters: any;
    public dataFilters: any;
    public correction: any = {};
    public selectedCorrection: any;
    public breadcrumbs: any;
    public displays: any;
    public data: any;
    public preview: any = {};
    public answer: any;
    public loadings: any = {
        media: false,
        updateCorrection: false,
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private correctionProvider: ManageCorrectionProvider,
        private toastService: CommonToastService,
        private timelineProvider: TimelineProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.journey = this.storageService.get('journey');

        this.displays = {
            answer: true,
            correction: false,
            comments: false,
            aside: false
        };

        this.route.parent.params.subscribe((params) => {
            if (params.courseId) {
                this.instanceId = parseInt(params.courseId, 10);
            }
        });

        this.route.params.subscribe((params) => {
            if (params.activityId) {
                this.activityId = params.activityId;
            }
        });

        this.search();

        this.correctionProvider.getActivityParent(this.company.id, this.instanceId, this.activityId).subscribe((breadcrumb) => {
            this.breadcrumbs = breadcrumb;
        });
    }

    searchUncorrected(): void {
        this.unCorrectedOnly = !this.unCorrectedOnly;

        if (!this.dataFilters) {
            this.dataFilters = {
                corrected: null
            };
        }

        if (this.unCorrectedOnly) {
            this.dataFilters.corrected = null;
        } else {
            this.dataFilters.corrected = 1;
        }
        this.search();
    }

    search(query?): void {
        this.query = query;
        this.loading = true;

        const params = HttpHelper.cleanParams({
            traject_id: this.journey ? this.journey.id : null,
            q: this.query,
            corrected: this.dataFilters ? this.dataFilters.corrected : null,
            'content_type[]': this.dataFilters ? DataHelper.filterCheckbox(this.dataFilters.content_type, ',') : null,
        });

        this.correctionProvider.getActivityDetail(this.company.id, this.instanceId, this.activityId, params).subscribe((data) => {
            this.loading = false;
            this.corrections = data.corrections;
            this.pagination = data.pagination;

            if (this.corrections.length > 0) {
                this.selectCorrection(this.corrections[0]);
            }
        });
    }

    selectCorrection(correction: any): void {
        this.initSave(correction);
        this.selectedCorrection = correction;
        this.allowCorrecting = false;
        this.answer = (correction.answer) ? this.jsonParse(correction.answer) : null;
        if (this.correctionTimeline && this.correctionTimeline.ready) {
            this.correctionTimeline.contextId = this.selectedCorrection.activitySessionId;
            this.correctionTimeline.getTimeline();
        }
    }

    editCorrecting(): void {
        this.allowCorrecting = !this.allowCorrecting;
        if (!this.displays.correction) {
            this.displays.correction = true;
        }
        this.mediaManagerTrainer.setOptions({
            canUploadMedia: this.allowCorrecting,
            canDownloadMedia: true,
            canDeleteMedia: this.allowCorrecting
        });
    }

    resetFilters(): void {
        this.activeFilters = false;
        this.pagination.currentPage = 1;
        this.search();
    }

    loadMore(): void {
        this.loading = true;

        const params = HttpHelper.cleanParams({
            traject_id: this.journey ? this.journey.id : null,
            q: this.query,
            corrected: this.dataFilters ? this.dataFilters.corrected : null,
            'content_type[]': this.dataFilters ? DataHelper.filterCheckbox(this.dataFilters.content_type, ',') : null,
            page: this.pagination.currentPage += 1
        });

        this.correctionProvider.getActivityDetail(this.company.id, this.instanceId, this.activityId, params).subscribe((data) => {
            this.corrections = this.corrections.concat(data.corrections);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    initSave(correction: any): void {
        this.correction = correction;
    }

    save(): void {
        this.correction.isCorrected = true;
        this.updateCorrection();
    }

    updateCorrection(): void {
        this.loadings.updateCorrection = true;

        const params = HttpHelper.cleanParams({
            isCorrected: this.correction.isCorrected || false,
            score: this.correction.score || 0,
            type: this.correction.context,
            questionId: this.correction.questionId,
            feedback: this.correction.feedback,
            mediaIds: this.correction.correctionMedia.length > 0 ? this.correction.correctionMedia.map(media => media.id) : null
        });

        this.editCorrecting();

        this.correctionProvider.updateCorrectible(this.company.id, this.instanceId, this.activityId, this.correction.account.id, params).subscribe(() => {
            this.loadings.updateCorrection = false;
            if (this.query !== '') {
                this.search();
            }

            if (this.selectedCorrection.context === 'selfassessment') {
                const parameters = HttpHelper.cleanParams({
                    company_id: this.company.id,
                    timeline_id: this.correctionTimeline.timelineId,
                    content: {
                        message: this.translateService.instant('self-assessment.timeline.correction.manager'),
                        media_id: null
                    },
                    type: 'mixed'
                });

                this.timelineProvider.send(parameters).subscribe(() => {
                    this.correctionTimeline.getTimeline();
                });
            }

            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        }, () => {
            this.loadings.updateCorrection = false;
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
        );
    }

    back(): void {
        if (!this.breadcrumbs.isTrajectActivity) {
            const urlSplit = this.router.url.split('/');
            this.router.navigate([urlSplit.splice(0, urlSplit.length - 2).join('/')], { relativeTo: this.route.parent });
        }
    }

    jsonParse(value: string): any {
        try {
            JSON.parse(value);
        } catch (e) {
            return value;
        }
        return JSON.parse(value).text;
    }

    spy(): void {
        this.correction.score = this.correction.score > 100 ? 100 : this.correction.score;
    }

    getContext(correction: any): string {
        switch (correction.context) {
            case 'exercise':
                return 'question-session';
            case 'project':
                return 'project-session';
            default:
                return 'selfassessment-answer';
        }
    }

    addMedia(media: any): void {
        if (media) {
            this.correction.correctionMedia.push(media);
        }
    }

    deleteMedia(media: any): void {
        const index = this.correction.correctionMedia.indexOf(media);

        if (index !== -1) {
            this.correction.correctionMedia.splice(index, 1);
        }
    }

    getCustomParams(): any {
        return {
            instance_id: this.instanceId,
            traject_id: this.journey?.id
        };
    }
}
