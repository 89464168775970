<p class="content-integration-mapping__description">
    <i class="icon icon-move"></i>

    <span>{{ 'integrations.mapping.edit-description' | translate: {value: provider.name, name: provider.name} }}</span>
</p>

<div class="content-integration-mapping__wrapper">
    <div class="content-integration-mapping__block content-integration-mapping__selector row-radius">
        <div class="content-integration-mapping__block__header flex between align-center">
            <h2 class="content-integration-mapping__block__header__title">
                {{ 'integrations.mapping.your-skills' | translate }}
            </h2>
        </div>

        <div class="content-integration-mapping__block__body">
            <div class="flex align-center between">
                <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="searchAssociation($event.value)"></msc-common-search>
                <msc-common2-checkbox [value]="filterByThemes" [label]="'integrations.mapping.themes-only' | translate" (changeEvent)="checkFilterByThemes()"></msc-common2-checkbox>
            </div>

            <div class="content-integration-mapping__list" data-simplebar>
                <msc-common-spinner *ngIf="loaders.associations"></msc-common-spinner>

                <div class="content-integration-mapping__list__items" *ngIf="!loaders.associations">
                    <div class="content-integration-mapping__selector__map" *ngFor="let association of associations">
                        <div class="block-provider-skill block-provider-skill--padding cursor-pointer" [ngClass]="{'active dragged-in': displayAssociations[association.id]}" msc-dropzone (onDrop)="onSkillDrop($event, association)" (click)="onAssociationClick(association); $event.stopPropagation()">
                            <div class="block-provider-skill__header">
                                <div class="block-provider-skill__title">
                                    <i *ngIf="association.thematic" class="icon icon-grid"></i>

                                    {{ association.name }}
                                </div>

                                <div class="block-provider-skill__content">
                                    <div class="block-provider-skill__tags" *ngIf="association.providerSkills.length === 0" >
                                        <div class="block-provider-skill__content__empty"></div>
                                    </div>

                                    <div class="block-provider-skill__tags" *ngIf="association.providerSkills.length > 0">
                                        <div class="skill skill--association skill--shadow-none no-cursor" (click)="$event.stopPropagation()">
                                            <div class="skill__content">
                                                <p class="skill__text" [ngClass]="{'tooltip tooltip--top tooltip--large' : association.providerSkills[0].category?.name.length > 15 || association.providerSkills[0].subcategory?.name.length > 10 }"
                                                    [attr.data-tooltip]="(association.providerSkills[0].category ? association.providerSkills[0].category.name : association.providerSkills[0].subcategory.name)">
                                                    {{ (association.providerSkills[0].category ? association.providerSkills[0].category.name : association.providerSkills[0].subcategory.name) | truncate: 10 }}
                                                </p>
                                                <a class="skill__close">
                                                    <i class="icon icon-close" (click)="onSkillRemove(association.providerSkills[0], association); $event.stopPropagation()"></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="skill skill--shadow-none skill--level" *ngIf="association.providerSkills.length > 1 && !displayAssociations[association.id]">
                                            <div class="skill__content">
                                                <p class="skill__text">+ {{ association.providerSkills.length - 1 }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="block-provider-skill__actions">
                                        <div class="action-toggle">
                                            <i class="icon icon-arrow-ios-down" *ngIf="association.providerSkills?.length > 1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="block-provider-skill__body">
                                <div class="block-provider-skill__empty"></div>

                                <div class="block-provider-skill__content">
                                    <div class="block-provider-skill__tags">
                                        <div class="skill skill--association skill--shadow-none" *ngFor="let skill of association.providerSkills | slice: 1">
                                            <div class="skill__content">
                                                <p class="skill__text">{{ skill.category ? skill.category.name : skill.subcategory.name }}</p>
                                                <a class="skill__close">
                                                    <i class="icon icon-close" (click)="onSkillRemove(skill, association); $event.stopPropagation()"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-integration-mapping__block__footer flex between align-center">
            <div>
                <i class="icon icon-chevron-left" (click)="navigateThroughAssociations(paginations.associations.currentPage - 1)" *ngIf="paginations.associations.currentPage > 1"></i>
            </div>
            {{ 'words.page' | translate }} {{ paginations.associations.currentPage }}/{{ paginations.associations.lastPage }}
            <div>
                <i class="icon icon-chevron-right" (click)="navigateThroughAssociations(paginations.associations.currentPage + 1)" *ngIf="paginations.associations.currentPage < paginations.associations.lastPage"></i>
            </div>
        </div>
    </div>

    <div class="content-integration-mapping__block__wrapper flex direction-column">
        <div class="content-integration-mapping__block content-integration-mapping__skills row-radius">
            <div class="content-integration-mapping__block__header">
                <h2 class="content-integration-mapping__skills__title">{{ 'integrations.mapping.associated-third-party-skills' | translate: {value: provider.name} }}</h2>
            </div>

            <div class="content-integration-mapping__body">
                <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="searchCategories($event.value)"></msc-common-search>

                <msc-common-spinner *ngIf="loaders.categories"></msc-common-spinner>

                <ul class="content-integration-mapping__skills__list" data-simplebar *ngIf="provider.hasSubcategories">
                    <li *ngFor="let category of categories; let categoryIndex = index" class="content-integration-mapping__skills__list__category mapping-category">
                        <div class="mapping-category__title" [ngClass]="{'active': displayCategories[categoryIndex]}" (click)="displayedCategories(categoryIndex)">
                            <div>
                                <p [tooltip]="category.name">{{ category.name | truncate: 15 }}</p>
                            </div>

                            <div class="flex align-center">
                                <div class="skill skill--association skill--shadow-none" msc-draggable [dragData]="category" (onSimpleClick)="onDraggableSimpleClick($event)">
                                    <i class="icon icon-move"></i>
                                    <div>{{ 'filter.all' | translate }}</div>
                                    <span>({{category.subcategories.length}})</span>
                                </div>

                                <div class="action-toggle">
                                    <i class="icon icon-arrow-ios-down"></i>
                                </div>
                            </div>
                        </div>

                        <ul *ngIf="displayCategories[categoryIndex]" class="mapping-category__skills">
                            <li *ngFor="let subcategory of category.subcategories; let index = index" msc-draggable [dragData]="subcategory" (onSimpleClick)="onDraggableSimpleClick($event)">
                                <div class="skill skill--association skill--shadow-none" [ngClass]="{'selected': subcategory.selected}">
                                    <div class="skill__content">
                                        <p class="skill__text">{{ subcategory.name }}</p>2
                                        <ng-container *ngIf="subcategory.contentSkills.length">
                                            <p class="skill__associations-number" [tooltip]="getAssociationsNames(subcategory)" [direction]="index === 0 ? 'bottom' : 'top'">{{ subcategory.contentSkills.length }}</p>
                                        </ng-container>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <msc-load-more [loading]="loaders.categoriesLoadMore" [pagination]="paginations.categories" (onLoad)="loadMoreCategories()"></msc-load-more>
                </ul>

                <ul class="content-integration-mapping__skills__list" data-simplebar *ngIf="!provider.hasSubcategories">
                    <li *ngFor="let category of categories; let index = index" msc-draggable [dragData]="category" (onSimpleClick)="onDraggableSimpleClick($event)">
                        <div class="skill skill--association skill--shadow-none" [ngClass]="{'selected': category.selected}">
                            <div class="skill__content">
                                <p class="skill__text">{{ category.name }}</p>
                                <ng-container *ngIf="category.contentSkills.length">
                                    <p class="skill__associations-number" [tooltip]="getAssociationsNames(category)">{{ category.contentSkills.length }}</p>
                                </ng-container>
                            </div>
                        </div>
                    </li>

                    <msc-load-more [loading]="loaders.categoriesLoadMore" [pagination]="paginations.categories" (onLoad)="loadMoreCategories()"></msc-load-more>
                </ul>
            </div>
        </div>
    </div>
</div>
