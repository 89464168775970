 <div class="swiper-container">
    <swiper #swiper [config]="config">
        <ng-container *ngFor="let content of contents">
            <ng-template swiperSlide>
                <content-card [width]="280" [content]="content"></content-card>
            </ng-template>
        </ng-container>
    </swiper>

    <div class="swiper-controls">
        <msc-button [id]="'prev-' + id" class="msc-button--large swiper-prev-btn inline-block  cursor-pointer  bg-white rounded-full " type="fab" icon="icon-arrow-left-1" palette="secondary"></msc-button>
        <msc-button [id]="'next-' + id" class="msc-button--large swiper-next-btn inline-block  cursor-pointer  bg-white rounded-full " type="fab" icon="icon-arrow-right-1" palette="secondary"></msc-button>
    </div>
 </div>


