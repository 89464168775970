<div class="container-catalog__session">
    <h2 class="container-catalog__title container-catalog__title--white">
        <span>
            {{ level.name }} - {{ getTranslation(level.level) | translate }}
        </span>
    </h2>

    <div class="container-catalog-internal__list row animate">
        <div class="card-container">
            <msc-card-manager *ngFor="let content of level.contents" [content]="content"></msc-card-manager>
        </div>
    </div>
</div>
