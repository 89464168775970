import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class CompanyCertificatesProvider {
    constructor(private http: HttpClient) {}

    getCertificatesRequest(slug: string | number): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/requests/${slug}`)
            .pipe(map((data: any) => data));
    }

    create(slug: string | number, params: any): Observable<any> {
        return this.http
            .post(`${environment.envVar.API_URL}/xertify/requests/generate`, params)
            .pipe(map((data) => data));
    }

    getCertificates(slug: string | number): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/transactions/${slug}`)
            .pipe(map((data: any) => data));
    }

    getTemplates(slug: string | number, params: any): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/templates/${slug}`, {params})
            .pipe(map((data: any) => data));
    }

    setTemplates(params: any): Observable<any> {
        return this.http
            .post(
                `${environment.envVar.API_URL}/xertify/templates/create`,
                params
            )
            .pipe(map((data: any) => data));
    }

    updateTemplate(params: any, templateId: any): Observable<any> {
        return this.http
            .put(
                `${environment.envVar.API_URL}/xertify/templates/update/${templateId}`,
                params
            )
            .pipe(map((data: any) => data));
    }

    deleteTemplate(templateId: any): Observable<any> {
        return this.http
            .delete(
                `${environment.envVar.API_URL}/xertify/templates/${templateId}`
            )
            .pipe(map((data: any) => data));
    }


    toggleTemplateStatus(templateId: any): Observable<any> {
        return this.http
            .put(
                `${environment.envVar.API_URL}/xertify/templates/${templateId}/change-status`,
                null
            )
            .pipe(map((data: any) => data));
    }

    updateTransaction(slug: string | number, params: any): Observable<any> {
        return this.http
            .put(
                `${environment.envVar.API_URL}/xertify/requests/update/${slug}`,
                params
            )
            .pipe(map((data: any) => data));
    }

    sendTransaction(slug: string | number, params: any): Observable<any> {
        return this.http
            .put(
                `${environment.envVar.API_URL}/xertify/requests/resend/${slug}`,
                params
            )
            .pipe(map((data: any) => data));
    }

    getFilter( params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/xertify/filters?place=transactions`, {params}).pipe(
            map((data: any) => data)
        );
    }

    getFilterCertificates( params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/xertify/filters?place=certificates`, {params}).pipe(
            map((data: any) => data)
        );
    }

    getCertificatesRequestFiltered(slug: string | number, filter?: any): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/requests/${slug}?filter=${filter?.filter}&value=${filter?.value}`)
            .pipe(map((data: any) => data));
    }

    getCertificatesRequestPaginated(slug: string | number, params: any): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/requests/${slug}`, {params} )
            .pipe(map((data: any) => data));
    }


    getCertificatesFiltered(slug: string | number, filter?: any): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/transactions/${slug}?filter=${filter?.filter}&value=${filter?.value}`)
            .pipe(map((data: any) => data));
    }

    getCertificatesPaginated(slug: string | number, params: any): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/transactions/${slug}`, {params})
            .pipe(map((data: any) => data));
    }


    revokeCertificate( params: any ): Observable<any> {
        return this.http
            .put(
                `${environment.envVar.API_URL}/xertify/transactions/certificate/revoke`, params,
                params
            )
            .pipe(map((data: any) => data));
    }

    downloadCertificate(params: any, fileName: string): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/xertify/transactions/certificate/download`, {responseType: 'blob', params}).pipe(
            map(data => {
                       
        const blob = new Blob([data], { type: 'text/pdf' });
     
        const url = window.URL.createObjectURL(blob);

  
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.pdf`; 


        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
            })
        );
    }

    sendCertificate(params: any): Observable<any> {
        return this.http
            .get(`${environment.envVar.API_URL}/xertify/transactions/certificate/send/${params}`,)
            .pipe(map((data: any) => data));
    }
}
