<div class="head">
    <ng-container *ngIf="icon">
        <i class="icon icon-{{ icon }}"></i>
    </ng-container>
    <h6>{{ title }}</h6>
    <hr>
    <button type="button" [ngClass]="{ 'is-open': isOpen }" (click)="onOpen()"></button>
</div>

<div class="body" [ngClass]="{ 'is-open': isOpen }">
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
</div>
