<div class="content-access content-access__settings">
    <div class="block-access-settings col-6">
        <h1>{{ 'words.validity-period' | translate }}</h1>

        <div class="block-access-settings__list">
            <div class="block-access-settings__item">
                <p>
                    <i class="icon icon-activity text-primary-500"></i>
                    {{ 'words.status' | translate }}
                </p>

                <msc-tag [text]="'words.' + settings.accessStatus?.licenseStatus | translate" [color]="getStatusColor(settings.accessStatus?.licenseStatus)"></msc-tag>
            </div>

            <div class="block-access-settings__item" *ngIf="settings.accessStatus?.licenseStatus !== 'active'">
                <div class="block-access-settings__form">
                    <p>
                        <i class="icon icon-play-circle text-primary-500"></i>
                        {{ 'words.access-start.date' | translate }}
                    </p>

                    <div class="input input--date input--icon-right" [ngClass]="{'active': displays.startDate}" [(autoClose)]="displays.startDate">
                        <input type="text" readonly="true" name="input" autocomplete="off" class="input__text" (focus)="displayed('startDate'); displayed('endDate', false)" [ngModel]="settings.accessStatus?.accessStartAt | date">

                        <span class="input__icon input__icon--trash" *ngIf="settings.accessStatus?.accessStartAt" (click)="user.id !== me.id && resetDateInput('accessStartAt')">
                            <i class="icon icon-trash-2"></i>
                        </span>

                        <span class="input__icon">
                            <i class="icon icon-calendar text-secondary-500"></i>
                        </span>

                        <div class="dropdown-datepicker">
                            <msc-datepicker [date]="settings.accessStatus?.accessStartAt" [minDate]="dates.tomorrow" [limit]="{min: dates.now, max: settings.accessStatus?.accessEndAt}" [actions]="true" (onUpdate)="updateDate('accessStartAt', $event)" (onClose)="displayed('startDate')"></msc-datepicker>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-access-settings__item">
                <div class="block-access-settings__form">
                    <p>
                        <i class="icon icon-hourglass text-primary-500"></i>
                        {{ 'words.access-end.date' | translate }}
                    </p>

                    <div class="input input--date input--icon-right" [ngClass]="{'active': displays.endDate}" [(autoClose)]="displays.endDate">
                        <input type="text" readonly="true" name="input" autocomplete="off" class="input__text" (focus)="displayed('endDate'); displayed('startDate', false)" [ngModel]="settings.accessStatus?.accessEndAt | date">

                        <span class="input__icon input__icon--trash" *ngIf="settings.accessStatus?.accessEndAt" (click)="user.id !== me.id && resetDateInput('accessEndAt')">
                            <i class="icon icon-trash-2"></i>
                        </span>

                        <span class="input__icon">
                            <i class="icon icon-calendar text-secondary-500"></i>
                        </span>

                        <div class="dropdown-datepicker">
                            <msc-datepicker [date]="settings.accessStatus?.accessEndAt" [minDate]="getMostRecentDate(dates.tomorrow, settings.accessStatus?.accessStartAt)" [limit]="{min: getMostRecentDate(dates.tomorrow, settings.accessStatus?.accessStartAt)}" [actions]="true" (onUpdate)="updateDate('accessEndAt', $event)" (onClose)="displayed('endDate')"></msc-datepicker>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-access-settings__actions" *ngIf="user.id !== me.id && dateUpdated">
                <msc-button class="msc-button--primary" palette="secondary" (click)="checkModal()">
                    {{ (settings.accessStatus?.licenseStatus === 'inactive' ? 'words.reactivate.access' : 'words.save') | translate }}
                </msc-button>
            </div>
        </div>
    </div>
</div>

<msc-common-modal size="70vw" #modalNotify [title]="'access.modal.notify-user.title' | translate">
    <ng-template>
        <div class="modal-invitation-notify__description" *ngIf="!settings.accessStatus?.accessStartAt">
            {{ 'access.modal.notify-user.message' | translate: {user: user.name} }}
        </div>

        <div class="modal-invitation-notify__description" *ngIf="settings.accessStatus?.accessStartAt && settings.accessStatus?.accessStartAt !== savedDate">
            {{ 'access.modal.notify-user.message-change' | translate: {user: user.name} }}
        </div>

        <div class="modal-invitation-notify__input">
            <label>
                {{ 'user.mail-notification' | translate }}
            </label>

            <div class="input input--experience">
                <div class="select" (click)="displayed('notification')">
                    <div class="select__text">
                        {{ 'words.notification.' + data.email_status | translate }}
                    </div>

                    <div class="dropdown dropdown-notification" [ngClass]="{'active': displays.notification}" [(autoClose)]="displays.notification">
                        <div class="dropdown-experience__content">
                            <div class="dropdown-experience__item" (click)="changed('email_status', 'auto')">
                                <p> {{ 'words.notification.auto' | translate }} </p>
                            </div>

                            <div class="dropdown-experience__item" (click)="changed('email_status', 'custom')">
                                <p> {{ 'words.notification.custom' | translate }} </p>
                            </div>

                            <div class="dropdown-experience__item" (click)="changed('email_status', 'none')">
                                {{ 'words.notification.none' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-invitation-notify__input" *ngIf="data.email_status === 'custom'">
            <msc-textarea [label]="'words.custom-text' | translate" rows="5" cols="33" [(ngModel)]="data.custom_message"></msc-textarea>
        </div>

        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalNotify.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="update(); modalNotify.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
