import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'msc-organize-integration-watch-rules-edit-summary-panel',
    templateUrl: './summary-panel.component.html'
})
export class OrganizeIntegrationWatchRulesEditSummaryPanelComponent implements OnInit {
    @Input() rule: any;
    @Input() navigations: any;
    @Input() navigationIndex: any;
    @Input() providerName: string;

    constructor() {}

    ngOnInit(): void {}
}
