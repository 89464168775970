import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class UtilityProvider {

    constructor(private http: HttpClient) { }

    checkMaintenance(): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/system/maintenance`).pipe(
            map((data) => data)
        );
    }

    sendMail(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/company/${id}/conversations/direct-email`, params).pipe(
            map((data) => data)
        );
    }

    getLinkPreview(url: string): Observable<any> {
        const params = {
            url
        };

        return this.http.get(`${environment.envVar.API_URL}/link-preview`, { params }).pipe(
            map(data => data)
        );
    }

    getVimeoReady(id: number): Observable<any> {
        const params = {
            url: `https://vimeo.com/${id}?dnt=1`,
            version: new Date().getTime().toString()
        };

        return this.http.get(`https://vimeo.com/api/oembed.json`, { params }).pipe(
            map(data => data)
        );
    }
}
