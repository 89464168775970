import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-showroom-exhibit-overlay-page',
    templateUrl: './showroom-exhibit-overlay-page.component.html',
    styleUrls: ['./showroom-exhibit-overlay-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitOverlayPageComponent {
    menuList = [
        {
            iconLeft: 'icon-add-message',
            label: 'Option 1',
            callback: () => {
                window.alert('option 1 callback');
            }
        },
        {
            iconLeft: 'icon-add-message',
            label: 'Go to input page',
            routerLink: ['/showroom', 'input']
        },
        {
            iconLeft: 'icon-add-message',
            label: 'Option 3',
            callback: () => {
                window.alert('option 3 callback');
            }
        },
        {
            iconLeft: 'icon-add-message',
            label: 'Option 4',
            callback: () => {
                window.alert('option 4 callback');
            }
        },
        {
            iconLeft: 'icon-add-message',
            label: 'Option 5',
            callback: () => {
                console.log('option 5 callback');
            }
        },
    ];

}
