<ng-container *ngIf="url$ | async as url; else errorTemplate">
    <h3 class="title">
        <span>{{ url.label }}</span>
        <div class="wrapper">
            <ng-container *ngIf="url.isActive">
                <msc-button class="msc-button--primary" (click)="onDeactivate(url)" palette="secondary">{{'words.deactivate' | translate}}</msc-button>
            </ng-container>
            <ng-container *ngIf="!url.isActive && url.isValid">
                <msc-button class="msc-button--primary" (click)="onActivate(url)" palette="primary">{{'organize.experience.watch.mail.list.btn.activate' | translate}}</msc-button>
            </ng-container>
            <ng-container *ngIf="!url.isValid">
                <msc-button class="msc-button--primary" (click)="onDelete(url)" palette="secondary">{{'words.delete' | translate}}</msc-button>
            </ng-container>
        </div>
    </h3>

    <div class="grouping">
        <h4 class="heading">Favicon</h4>
        <div class="preview"><img #img [src]="url.image"></div>
        <p class="description">{{ 'url.favicon.description' | translate }}</p>
        <div class="wrapper buttons">
            <msc-button [label]="'url.button.favicon.add' | translate" (click)="onImageAdd()" palette="primary"></msc-button>
            <ng-container *ngIf="url.image || form.controls['file'].value">
                <msc-button class="msc-button--primary" (click)="onImageRemove()" palette="secondary">{{'url.button.favicon.remove' | translate}}</msc-button>
            </ng-container>
        </div>
        <p class="description">{{ 'url.recommended.size' | translate }}</p>
    </div>

    <div class="grouping" [ngClass]="{ 'margin-top-40' : url.isValid }">
        <form [formGroup]="form">
            <input type="file" accept="image/*" #file (change)="onImageChange($event.target.files)">
            <ng-container *ngIf="url.isValid">
                <div class="grouping">
                    <h4 class="heading">{{ 'url.branding.page' | translate }}</h4>
                    <p class="description">{{ 'url.branding.page.description' | translate: {value: experience} }}</p>
                    <div class="wrapper margin-top-20">
                        <msc-common2-switch
                            [value]="isDefaultBranding"
                            [label]="'url.branding.page.experience.set' | translate: {value: experience}"
                            (changeEvent)="onSwitch($event)">
                        </msc-common2-switch>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>

    <ng-container *ngIf="url.type === 'custom'">
        <div class="grouping margin-top-40">
            <p class="description">{{ 'url.custom.edit.description.row.one' | translate }}</p>
            <p class="description"><span>&#8226;&nbsp;</span>{{ 'url.custom.edit.description.row.two' | translate }}</p>
            <p class="description"><span>&#8226;&nbsp;</span><span [innerHTML]="'url.custom.edit.description.row.three' | translate"></span></p>
            <p class="description">
                <span>{{ 'url.custom.info.more' | translate }}</span>&#44;
                <a class="guide" [href]="lang" target="_blank">{{ 'url.custom.info.guide' | translate }}</a>
            </p>
        </div>
    </ng-container>

    <div class="grouping margin-top-40">
        <div class="wrapper">
            <msc-button class="msc-button--primary" palette="primary" [loading]="isLoadingEdit" (click)="onEdit(url)">{{'words.save.real' | translate}}</msc-button>
            <ng-container *ngIf="!url.isValid">
                <msc-button class="msc-button--primary" palette="primary" [loading]="isLoadingValidate" (click)="onValidate(url)">{{'providers.test.connection' | translate}}</msc-button>
            </ng-container>
            <ng-container *ngIf="!url.isValid; else isValidTemplate">
                <msc-common-led [label]="'url.custom.config.confirmation.no' | translate" [isOn]="false"></msc-common-led>
            </ng-container>
            <ng-template #isValidTemplate>
                <msc-common-led [label]="'url.custom.config.confirmation.yes' | translate" [isOn]="true"></msc-common-led>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #errorTemplate>
    <!-- TODO -->
</ng-template>
