import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { Company } from '@lighty';
import { StorageService, TranslateService, ExternalAppsService, EmitterService } from '@services';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private currentRoute: string;
    public me: any;
    public company: Company;
    public access: any;
    public sidebar: any;
    public user: any;
    public myAccount: boolean = true;
    public displays: any;

    constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService,
                private translateService: TranslateService, private externalAppsService: ExternalAppsService, private emitterService: EmitterService) {}

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.access = this.storageService.set('access', this.route.snapshot.data.access);
        this.sidebar = this.storageService.set('sidebar', this.route.snapshot.data.sidebar);
        this.user = this.route.snapshot.data.user;

        this.subscriptionCollection.subscribe = this.route.params.subscribe((params) => {
            if (params.accountId && parseInt(params.accountId) !== this.me.id) {
                this.myAccount = false;
            }
        });

        this.subscriptionCollection.subscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setCurrentRoute();
            }
        });

        this.translateService.setLang(this.me.language.code);
        this.externalAppsService.boot(this.me, this.company, this.access.roles);

        this.displays = {
            access: false
        };

        this.setCurrentRoute();

        this.emitterService.set('theme.change', this.company.experience.colorTemplate);
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    private setCurrentRoute(): void {
        this.currentRoute = this.router.url.split('/')[3];
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    isActivatedRoute(route: string): boolean {
        return this.currentRoute && route.indexOf(this.currentRoute) > -1;
    }
}
