<section class="head">
    <h3>Modal</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Modal</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-button class="msc-button--primary" palette="primary" (click)="modal1.onOpen()">The Riddle of Steel</msc-button>
            </div>
        </div>
    </div>
</section>

<msc-common-modal size="30vw" #modal1 [title]="'Thulsa Doom'">
    <ng-template>
        <p>
            That is strength, boy! That is <b [tooltip]="'POWAH!'">power</b>! What is steel compared to the hand that wields it?
            Look at the strength in your body, the desire in your heart, I gave you this! Such a waste.
            Contemplate this on the tree of woe.
        </p>
    </ng-template>
</msc-common-modal>
