import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TranslateService, StorageService } from '@services';
import { UtilityProvider } from '@lighty';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-shared-aside-mail-user',
    templateUrl: './aside-mail-user.component.html'
})
export class SharedMailUserComponent {
    @ViewChild('aside') aside;
    @Input() customHandler: boolean = false;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public user: any;
    public lang: string;
    public company: any;
    public message: string;

    constructor(
        private translateService: TranslateService,
        private storageService: StorageService,
        private utilityProvider: UtilityProvider,
        private toastService: CommonToastService,
    ) { }

    init(): void {
        this.company = this.storageService.get('company');
        this.lang = this.user.lang || this.translateService.getCurrentLang();
        this.message = '';
    }

    public onOpen(user) {
        this.user = user;
        this.init();
        this.aside.onOpen();
    }

    close(message?): void {
        this.aside.onClose();
        this.onClose.emit(message);
    }

    save(): any {
        const params: any = {
            customMessage: this.message,
            accountId: this.user.id ? this.user.id : this.user.accountId,
        };

        if (this.customHandler) {
            this.close(this.message);
        } else {
            this.utilityProvider.sendMail(this.company.id, params).subscribe(() => {
                this.toastService.onSuccess(this.translateService.instant('toast.mail-successfully-sent'));
                this.close();
            });
        }
    }
}
