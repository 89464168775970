import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Journey } from '@lighty';

@Component({
    selector: 'msc-learn-detail-journey-discussion',
    templateUrl: './discussion.component.html'
})
export class LearnDetailJourneyDiscussionComponent implements OnInit {
    public journey: Journey;
    public pagination: any;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.journey = this.route.parent.snapshot.data.journey;
    }
}
