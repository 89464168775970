import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
//
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { OrganizeTableMarketplaceCatalogueDatum } from '@modules/organize/components/table-marketplace-catalogue/organize-table-marketplace-catalogue.component';

@Component({
    selector: 'msc-organize-toolbar-marketplace-catalogue-component',
    templateUrl: './organize-toolbar-marketplace-catalogue.component.html',
    styleUrls: ['./organize-toolbar-marketplace-catalogue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeToolbarMarketplaceCatalogueComponent {
    @Input() total: number;
    @Input() filters: any;
    @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() searchEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() openModal: EventEmitter<any> = new EventEmitter<any>();

    public counter: number;

    constructor(
        private readonly ref: ChangeDetectorRef,
    ) { }

    get appliedFiltersLength() { return this.getAppliedFiltersLength(); }

    getAppliedFiltersLength() {
        if (!this.filters) { return; }
        let counter = 0;
        if (this.filters.languages && this.filters.languages.length) {
            counter = counter + [...this.filters.languages].filter((d: any) => d.isSelected).length;
        }
        if (this.filters.types && this.filters.types.length) {
            counter = counter + [...this.filters.types].filter((d: any) => d.isSelected).length;
        }
        return counter;
    }

    /**
     *
     */
    onSet(value: any, datum: any, key: string) {
        datum.isSelected = value;
        this.filters[key] = [...this.filters[key]];
    }

    /**
     * Event handler for delete of rows
     */
    onDelete(): void {
        this.deleteEvent.emit();
    }

    /**
     * Event handler for filter of rows
     */
    onFilter(): void {
        const languages = this.filters.languages.filter((d: any) => d.isSelected).map((d: any) => d.value);
        const types = this.filters.types.filter((d: any) => d.isSelected).map((d: any) => d.value);
        this.filterEvent.emit(Object.assign({},
            languages && languages.length ? { languages } : {},
            types && types.length ? { types } : {},
        ));
    }

    /**
     *  Event handler for search of rows
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.searchEvent.emit(value);
    }

    /**
     * Event handler for open modal
     */
    onOpenModal(): void {
        this.openModal.emit();
    }

    /**
     *  Event handler for update of rows info
     */
    onUpdate(rows: Array<OrganizeTableMarketplaceCatalogueDatum>, otherParams?: any): void {
        if (!Array.isArray(rows)) { return; }
        if (otherParams && otherParams.select_all) {
            this.counter = this.total - rows.filter((row) => !row.isSelected).length;
        } else {
            this.counter = rows.filter((row) => row.isSelected).length;
        }
        this.ref.detectChanges();
    }
}
