<section class="head">
    <h3>Table</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Table</h4>
        <hr>
        <div class="partition full">
            <div class="segment">
                <msc-common-table [columns]="columns" [rows]="rows">
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-checkbox [isPartial]="thCheckboxIsPartial" [value]="thCheckboxIsSelected" (changeEvent)="onModify($event)"></msc-common-th-checkbox>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                    </ng-template>

                    <ng-template #tdTemplate let-datum="datum">
                        <msc-common2-checkbox type="tick" [value]="datum.isSelected" (changeEvent)="onChange($event, datum)"></msc-common2-checkbox>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.title }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.category }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.type }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.date }}</span>
                    </ng-template>
                </msc-common-table>
            </div>
        </div>
    </div>
</section>
