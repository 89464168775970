<div class="div-export flex justify-center aling-center">
    <div class="basis-2/3 bg-white rounded-sm shadow-lg px-6 m-2">
        <div class="py-5">
            <h1 class="font-bold my-2">{{ "exports.title" | translate }}</h1>
            <h4>
                {{ "exports.subtitle" | translate : { company: company.name } }}
            </h4>
        </div>

        <!--OVERVIEW-->
        <div class="mb-5">
            <h4 class="font-bold my-2">
                {{ "exports.learner_overview" | translate }}
            </h4>
            <p>{{ "exports.learner_overview.description" | translate }}</p>
            <ul class="list-disc my-2">
                <li>
                    {{
                        "exports.learner_overview.total_learning_time"
                            | translate
                    }}
                </li>
                <li>
                    {{ "exports.learner_overview.total_courses" | translate }}
                </li>
                <li>
                    {{
                        "exports.learner_overview.certificate_and_skills"
                            | translate
                    }}
                </li>
            </ul>
            <div class="flex">
                <msc-button
                    class="msc-button--primary"
                    palette="secondary"
                    icon="icon-download"
                    (click)="
                        modalExports.open({
                            name: company.name,
                            format: 'csv',
                            type: 'learner-overview'
                        })
                    "
                    >{{ "common.request" | translate }}
                </msc-button>
                <!-- <msc-button
                class="msc-button--primary ml-2"
                palette="secondary"
                icon="icon-download"
                (click)="
                    modalExports.open({
                        name: company.name,
                        format: 'xlsx',
                        type: 'learner-overview'
                    })
                "
                >XLSX
            </msc-button> -->
            </div>
        </div>

        <!--ENGAGEMENT-->
        <div class="mb-5">
            <h4 class="font-bold my-2">
                {{ "exports.learner_engagement" | translate }}
            </h4>
            <p>{{ "exports.learner_engagement.description" | translate }}</p>
            <ul class="list-disc my-2">
                <li>{{ "exports.learner_engagement.signups" | translate }}</li>
                <li>
                    {{
                        "exports.learner_engagement.progress_score" | translate
                    }}
                </li>
                <li>
                    {{
                        "exports.learner_engagement.content_details" | translate
                    }}
                </li>
            </ul>
            <div class="flex">
                <msc-button
                    class="msc-button--primary"
                    palette="secondary"
                    icon="icon-download"
                    (click)="
                        modalExports.open({
                            name: company.name,
                            format: 'csv',
                            type: 'learner-engagement'
                        })
                    "
                    >{{ "common.request" | translate }}
                </msc-button>
                <!-- <msc-button
                class="msc-button--primary ml-2"
                palette="secondary"
                icon="icon-download"
                (click)="
                    modalExports.open({
                        name: company.name,
                        format: 'xlsx',
                        type: 'learner-engagement'
                    })
                "
                >XLSX
            </msc-button> -->
            </div>
        </div>

        <!--TRAJECTS-->
        <div class="mb-5">
            <h4 class="font-bold my-2">
                {{ "exports.traject-title" | translate }}
            </h4>
            <p>{{ "exports.traject-description" | translate }}</p>
            <ul class="list-disc my-2">
                <li>
                    {{
                        "exports.traject-option-1" | translate
                    }}
                </li>
                <li>
                    {{
                        "exports.traject-option-2" | translate
                    }}
                </li>
            </ul>
            <div class="flex">
                <msc-button
                    class="msc-button--primary"
                    palette="secondary"
                    icon="icon-download"
                    (click)="
                        modalExports.open({
                            name: company.name,
                            format: 'csv',
                            type: 'learner-trajects'
                        })
                    "
                    >{{ "common.request" | translate }}
                </msc-button>
                <!-- <msc-button
                class="msc-button--primary ml-2"
                palette="secondary"
                icon="icon-download"
                (click)="
                    modalExports.open({
                        name: company.name,
                        format: 'xlsx',
                        type: 'learner-engagement'
                    })
                "
                >XLSX
            </msc-button> -->
            </div>
        </div>

        <!--LICENSES-->
        <div class="mb-5">
            <h4 class="font-bold my-2">
                {{ "exports.licenses-title" | translate }}
            </h4>
            <p>{{ "exports.licenses-description" | translate }}</p>
            <ul class="list-disc my-2">
                <li>
                    {{
                        "exports.licenses-option-1" | translate
                    }}
                </li>
            </ul>
            <div class="flex">
                <msc-button
                    class="msc-button--primary"
                    palette="secondary"
                    icon="icon-download"
                    (click)="
                        modalExports.open({
                            name: company.name,
                            format: 'csv',
                            type: 'learner-licenses'
                        })
                    "
                    >{{ "common.request" | translate }}
                </msc-button>
                <!-- <msc-button
                class="msc-button--primary ml-2"
                palette="secondary"
                icon="icon-download"
                (click)="
                    modalExports.open({
                        name: company.name,
                        format: 'xlsx',
                        type: 'learner-engagement'
                    })
                "
                >XLSX
            </msc-button> -->
            </div>
        </div>


        <!--
        <div class="mb-5">
            <h4 class="font-bold my-2">
                {{ "exports.content_engagement" | translate }}
            </h4>
            <p>{{ "exports.content_engagement.description" | translate }}</p>
            <ul class="list-disc my-2">
                <li>{{ "exports.content_engagement.signups" | translate }}</li>
                <li>
                    {{
                        "exports.content_engagement.progress_score" | translate
                    }}
                </li>
                <li>
                    {{
                        "exports.content_engagement.content_details" | translate
                    }}
                </li>
            </ul>
            <div class="flex">
                <msc-button
                    class="msc-button--primary"
                    palette="secondary"
                    (click)="
                        modalExports.open({
                            name: company.name,
                            format: 'csv',
                            type: 'content-engagement'
                        })
                    "
                    icon="icon-download"
                    >{{ "common.request" | translate }}
                </msc-button>
                <msc-button
                class="msc-button--primary ml-2"
                palette="secondary"
                (click)="
                    modalExports.open({
                        name: company.name,
                        format: 'xlsx',
                        type: 'content-engagement'
                    })
                "
                icon="icon-download"
                >XLSX</msc-button
            >
            </div>
        </div> -->
    </div>
    <div class="basis-1/3 bg-white rounded-sm shadow-lg p-4 m-2">
        <!-- component -->
        <style>
            #journal-scroll::-webkit-scrollbar {
                width: 4px;
                cursor: pointer;
                /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
            }
            #journal-scroll::-webkit-scrollbar-track {
                background-color: rgba(229, 231, 235, var(--bg-opacity));
                cursor: pointer;
                /*background: red;*/
            }
            #journal-scroll::-webkit-scrollbar-thumb {
                cursor: pointer;
                background-color: #a0aec0;
                /*outline: 1px solid slategrey;*/
            }
        </style>

        <div
            class="bg-white text-sm text-gray-500 font-bold p-4 shadow border-b border-gray-300 bg-blue-500 bg-opacity-25 mb-4 place-content-between flex"
        >
            {{ "common.last-report-generated" | translate }}
            <i class="icon icon-flip"  [tooltip]="'words.refresh' | translate" (click)="getReports()"></i>
        </div>

        <div
            class="w-full rounded-sm bg-white"
            id="journal-scroll"
        >
            <div *ngIf="loading" class="col-12 col-xxs-12 flex content-center justify-center">
                <msc-common-spinner></msc-common-spinner>
            </div>
            <msc-empty-state *ngIf="!loading && firtsOverview == undefined && firstEngagement == undefined" [title]="'boclips.no-found' | translate"></msc-empty-state>
            <div class="w-full" *ngIf="!loading">
                <!--first overview select-->
                <ng-container *ngIf="firtsOverview != undefined">
                    <h3 class="mb-2"> {{ "exports.learner_overview" | translate }}</h3>
                
                <div class="w-full flex align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default mb-3">

                    <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                        <div><h2>{{firtsOverview.day}}</h2></div>
                        <div class="whitespace-no-wrap">{{firtsOverview.rest}}</div>
                        <div class="text-gray-400">{{getTimeWithAMPM(firtsOverview.updatedAt)}}</div>
                    </div>
                    <div class="w-full px-2 py-2 whitespace-no-wrap">
                        <div class="flex flex-row justify-center aling-center">
                            <div class="w-full basis-3/4">
                                <div
                                    class="leading-5 text-gray-500 font-medium"
                                >
                                    {{firtsOverview.account.firstname}} {{firtsOverview.account.lastname}}
                                </div>
                                <div class="leading-5 text-gray-900">
                                    {{firtsOverview.filename? firtsOverview.filename : '- - -'}}
                                </div>
                                <div class="leading-5 text-gray-800">
                                    {{ 'stats.' + firtsOverview.status | translate }}
                                </div>
                            </div>
                            <div class="flex basis-1/4 listItem">
                                <a
                                    class="text-blue-500 hover:underline"
                                    href="javascript:void(0)"
                                    [tooltip]="'common.download' | translate"
                                    (click)="downloadReport(firtsOverview.uuid, firtsOverview.filename)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                    </svg>
                                </a>
                                <i [ngClass]="{ 'iconFlip': showOverview }" class="icon icon-arrow-ios-down ml-6" (click)="toggleOverview()"></i>
                            </div>
                        </div>
                    </div>
                </div>


                    <!--expandable overview section-->
                    <div *ngFor="let overviewItem of reports.learnerOverview" [ngClass]="{ 'expandableSectionShow': showOverview }" class="w-full flex expandableSection align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default">
                        <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                            <div><h2>{{overviewItem.day}}</h2></div>
                            <div class="whitespace-no-wrap">{{overviewItem.rest}}</div>
                            <div class="text-gray-400">{{getTimeWithAMPM(overviewItem.updatedAt)}}</div>
                        </div>
                        <div class="w-full px-2 py-2 whitespace-no-wrap">
                            <div class="flex flex-row justify-center aling-center">
                                <div class="w-full basis-3/4">
                                    <div
                                        class="leading-5 text-gray-500 font-medium"
                                    >
                                    {{overviewItem.account.firstname}} {{overviewItem.account.lastname}}
                                    </div>
                                    <div class="leading-5 text-gray-900">
                                        {{overviewItem.filename? overviewItem.filename : '- - -'}}
                                    </div>
                                    <div class="leading-5 text-gray-800">
                                        {{ 'stats.' + overviewItem.status | translate }}
                                    </div>
                                </div>
                                <div class="flex basis-1/4 listItem">
                                    <a
                                        class="text-blue-500 hover:underline"
                                        href="javascript:void(0)"
                                        [tooltip]="'common.download' | translate"
                                        (click)="downloadReport(overviewItem.uuid, overviewItem.filename)"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-6 h-6"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!--second engagement select-->
                <ng-container *ngIf="firstEngagement != undefined">
                    <h3  class="mb-2">{{ "exports.learner_engagement" | translate }}</h3>
                <div class="w-full flex align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default mb-3">
                    <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                        <div><h2>{{firstEngagement.day}}</h2></div>
                        <div class="whitespace-no-wrap">{{firstEngagement.rest}}</div>
                        <div class="text-gray-400">{{getTimeWithAMPM(firstEngagement.updatedAt)}}</div>
                    </div>
                    <div class="w-full px-2 py-2 whitespace-no-wrap">
                        <div class="flex flex-row justify-center aling-center">
                            <div class="w-full basis-3/4">
                                <div
                                    class="leading-5 text-gray-500 font-medium"
                                >
                                {{firstEngagement.account.firstname}} {{firstEngagement.account.lastname}}
                                </div>
                                <div class="leading-5 text-gray-900">
                                    {{firstEngagement.filename? firstEngagement.filename : '- - -'}}
                                </div>
                                <div class="leading-5 text-gray-800">
                                    {{ 'stats.' + firstEngagement.status | translate }}
                                </div>
                            </div>
                            <div class="flex basis-1/4 listItem">
                                <a
                                    class="text-blue-500 hover:underline"
                                    href="javascript:void(0)"
                                    [tooltip]="'common.download' | translate"
                                    (click)="downloadReport(firstEngagement.uuid, firstEngagement.filename)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                    </svg>
                                </a>
                                <i class="icon icon-arrow-ios-down ml-6" [ngClass]="{ 'iconFlip': showEngagement }" (click)="toggleEngagement()"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <!--expandable engagement section-->
                <div *ngFor="let engagementItem of reports.learnerEngagement" [ngClass]="{ 'expandableSectionShow': showEngagement }" class="w-full flex expandableSection align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default">
                    <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                        <div><h2>{{engagementItem.day}}</h2></div>
                        <div class="whitespace-no-wrap">{{engagementItem.rest}}</div>
                        <div class="text-gray-400">{{getTimeWithAMPM(engagementItem.updatedAt)}}</div>
                    </div>
                    <div class="w-full px-2 py-2 whitespace-no-wrap">
                        <div class="flex flex-row justify-center aling-center">
                            <div class="w-full basis-3/4">
                                <div
                                    class="leading-5 text-gray-500 font-medium"
                                >
                                    {{engagementItem.account.firstname}} {{engagementItem.account.lastname}}
                                </div>
                                <div class="leading-5 text-gray-900">
                                    {{engagementItem.filename? engagementItem.filename : '- - -'}}
                                </div>
                                <div class="leading-5 text-gray-800">
                                    {{ 'stats.' + engagementItem.status | translate }}
                                </div>
                            </div>
                            <div class="flex basis-1/4 listItem">
                                <a
                                    class="text-blue-500 hover:underline"
                                    href="javascript:void(0)"
                                    [tooltip]="'common.download' | translate"
                                    (click)="downloadReport(engagementItem.uuid, engagementItem.filename)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-container>

                 <!--third traject select-->
                 <ng-container *ngIf="firstTraject != undefined">
                    <h3  class="mb-2">{{ "exports.traject-title" | translate }}</h3>
                <div class="w-full flex align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default mb-3">
                    <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                        <div><h2>{{firstTraject.day}}</h2></div>
                        <div class="whitespace-no-wrap">{{firstTraject.rest}}</div>
                        <div class="text-gray-400">{{getTimeWithAMPM(firstTraject.updatedAt)}}</div>
                    </div>
                    <div class="w-full px-2 py-2 whitespace-no-wrap">
                        <div class="flex flex-row justify-center aling-center">
                            <div class="w-full basis-3/4">
                                <div
                                    class="leading-5 text-gray-500 font-medium"
                                >
                                {{firstTraject.account.firstname}} {{firstTraject.account.lastname}}
                                </div>
                                <div class="leading-5 text-gray-900">
                                    {{firstTraject.filename? firstTraject.filename : '- - -'}}
                                </div>
                                <div class="leading-5 text-gray-800">
                                    {{ 'stats.' + firstTraject.status | translate }}
                                </div>
                            </div>
                            <div class="flex basis-1/4 listItem">
                                <a
                                    class="text-blue-500 hover:underline"
                                    href="javascript:void(0)"
                                    [tooltip]="'common.download' | translate"
                                    (click)="downloadReport(firstTraject.uuid, firstTraject.filename)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                    </svg>
                                </a>
                                <i class="icon icon-arrow-ios-down ml-6" [ngClass]="{ 'iconFlip': showTraject }" (click)="toggleTraject()"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <!--expandable traject section-->
                <div *ngFor="let trajectItem of reports.learnerTrajects" [ngClass]="{ 'expandableSectionShow': showTraject }" class="w-full flex expandableSection align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default">
                    <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                        <div><h2>{{trajectItem.day}}</h2></div>
                        <div class="whitespace-no-wrap">{{trajectItem.rest}}</div>
                        <div class="text-gray-400">{{getTimeWithAMPM(trajectItem.updatedAt)}}</div>
                    </div>
                    <div class="w-full px-2 py-2 whitespace-no-wrap">
                        <div class="flex flex-row justify-center aling-center">
                            <div class="w-full basis-3/4">
                                <div
                                    class="leading-5 text-gray-500 font-medium"
                                >
                                    {{trajectItem.account.firstname}} {{trajectItem.account.lastname}}
                                </div>
                                <div class="leading-5 text-gray-900">
                                    {{trajectItem.filename? trajectItem.filename : '- - -'}}
                                </div>
                                <div class="leading-5 text-gray-800">
                                    {{ 'stats.' + trajectItem.status | translate }}
                                </div>
                            </div>
                            <div class="flex basis-1/4 listItem">
                                <a
                                    class="text-blue-500 hover:underline"
                                    href="javascript:void(0)"
                                    [tooltip]="'common.download' | translate"
                                    (click)="downloadReport(trajectItem.uuid, trajectItem.filename)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-container>

                <!--fourth licenses select-->
                <ng-container *ngIf="firstLicense != undefined">
                    <h3  class="mb-2">{{ "exports.licenses-title" | translate }}</h3>
                <div class="w-full flex align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default mb-3">
                    <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                        <div><h2>{{firstLicense.day}}</h2></div>
                        <div class="whitespace-no-wrap">{{firstLicense.rest}}</div>
                        <div class="text-gray-400">{{getTimeWithAMPM(firstLicense.updatedAt)}}</div>
                    </div>
                    <div class="w-full px-2 py-2 whitespace-no-wrap">
                        <div class="flex flex-row justify-center aling-center">
                            <div class="w-full basis-3/4">
                                <div
                                    class="leading-5 text-gray-500 font-medium"
                                >
                                {{firstLicense.account.firstname}} {{firstLicense.account.lastname}}
                                </div>
                                <div class="leading-5 text-gray-900">
                                    {{firstLicense.filename? firstLicense.filename : '- - -'}}
                                </div>
                                <div class="leading-5 text-gray-800">
                                    {{ 'stats.' + firstLicense.status | translate }}
                                </div>
                            </div>
                            <div class="flex basis-1/4 listItem">
                                <a
                                    class="text-blue-500 hover:underline"
                                    href="javascript:void(0)"
                                    [tooltip]="'common.download' | translate"
                                    (click)="downloadReport(firstLicense.uuid, firstLicense.filename)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                    </svg>
                                </a>
                                <i class="icon icon-arrow-ios-down ml-6" [ngClass]="{ 'iconFlip': showLicense }" (click)="toggleLicense()"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <!--expandable license section-->
                <div *ngFor="let licenseItem of reports.learnerLicenses" [ngClass]="{ 'expandableSectionShow': showLicense }" class="w-full flex expandableSection align-center transform scale-100 text-xs py-1 border-b-2 border-blue-100 cursor-default">
                    <div class="flex flex-col justify-center items-center whitespace-no-wrap w-28">
                        <div><h2>{{licenseItem.day}}</h2></div>
                        <div class="whitespace-no-wrap">{{licenseItem.rest}}</div>
                        <div class="text-gray-400">{{getTimeWithAMPM(licenseItem.updatedAt)}}</div>
                    </div>
                    <div class="w-full px-2 py-2 whitespace-no-wrap">
                        <div class="flex flex-row justify-center aling-center">
                            <div class="w-full basis-3/4">
                                <div
                                    class="leading-5 text-gray-500 font-medium"
                                >
                                    {{licenseItem.account.firstname}} {{licenseItem.account.lastname}}
                                </div>
                                <div class="leading-5 text-gray-900">
                                    {{licenseItem.filename? licenseItem.filename : '- - -'}}
                                </div>
                                <div class="leading-5 text-gray-800">
                                    {{ 'stats.' + licenseItem.status | translate }}
                                </div>
                            </div>
                            <div class="flex basis-1/4 listItem">
                                <a
                                    class="text-blue-500 hover:underline"
                                    href="javascript:void(0)"
                                    [tooltip]="'common.download' | translate"
                                    (click)="downloadReport(licenseItem.uuid, licenseItem.filename)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<msc-stats-export-modal
    #modalExports
    (onSave)="download(modalConfirm, $event)"
    (onSend)="getReports()"
></msc-stats-export-modal>

<msc-common-modal size="30vw" #modalConfirm>
    <ng-template>
        <p [innerHTML]="'export.export_is_being_created' | translate"></p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button
                class="msc-button--primary"
                palette="primary"
                (click)="modalConfirm.onClose()"
                >Ok</msc-button
            >
        </div>
    </ng-template>
</msc-common-modal>
