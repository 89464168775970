<div class="range" [ngClass]="{'range--selectable': typeMove}">
    <div [attr.class]="'range-line range-line-' + uniqueId">
        <span [attr.class]="'range-line__between range-line__between-' + uniqueId"></span>
    </div>

    <div class="steps" *ngIf="config.step">
        <span class="step step-{{uniqueId}}" *ngFor="let step of config.step | toArray"
              [ngStyle]="{'transform': translateX(step)}"
              [ngClass]="{'active': isStepActive(step), 'displayStep': config.displayStepValue, 'displayBottom': config.stepBottom}"
              [attr.data-position]="getPosition(step)"
              [attr.data-value]="getValue(step)"
              [attr.data-translate]="getTranslate(step)">
        </span>
    </div>

    <div [attr.class]="'range-min range-min-' + uniqueId" [ngClass]="{'selected': typeMove === 'min'}"></div>
    <div [attr.class]="'range-max range-max-' + uniqueId" [ngClass]="{'selected': typeMove === 'max'}"></div>

    <span class="min--value" *ngIf="!config.displayStepValue">
        {{ values.min }}
    </span>

    <span class="max--value" *ngIf="!config.displayStepValue">
        {{ values.max }}
    </span>
</div>
