<div class="content-access content-access__create">
    <div class="content-access__create__header">
        <h3>{{ 'words.company' | translate }}</h3>

        <div class="content-access__create__enable">
            <msc-button class="msc-button--primary" palette="primary" (click)="modalAccess.onOpen()"
                [disabled]="!user.companyRoles.companyAuthor">{{ 'words.revoke-access' | translate }}</msc-button>
        </div>
    </div>

    <div class="content-access__create__data">
        <div class="data__logo">
            <msc-common-avatar [label]="company.name"
                [pictureURL]="company.picture ? (company.picture + '?size=256') : null"></msc-common-avatar>
        </div>

        <div class="data__form gap-2">
            <msc-common2-radio groupName="companyRole" [label]="'words.no-access' | translate" [ngModel]="true" [valueName]="!user.companyRoles.companyAuthor" (click)="enableAccess(false)"></msc-common2-radio>
            <msc-common2-radio groupName="companyRole" [label]="'access.create.private' | translate" [ngModel]="true" [valueName]="user.companyRoles.companyAuthor && !access.accessCompanyCourses" (click)="setAccess(false)"></msc-common2-radio>
            <msc-common2-radio groupName="companyRole" [label]="'access.create.company' | translate" [ngModel]="true" [valueName]="user.companyRoles.companyAuthor && access.accessCompanyCourses" (click)="setAccess(true)"></msc-common2-radio>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalAccess [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'access.disabled-create' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button (click)="modalAccess.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="disableAccess(); modalAccess.onClose()">
                {{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>