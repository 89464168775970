import { Directive, OnInit, Input, ElementRef, HostListener } from '@angular/core';
import { addClass } from '@functions';

@Directive({
    selector: '[autoSize]'
})
export class AutoSizeDirective implements OnInit {
    @Input('autoSize') enable: boolean = false;
    @Input('identifier') identifier: string;
    @Input('forceRezize') force: boolean = false;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        if (this.enable) {
            this.element.nativeElement.style.overflow = 'hidden';

            if (this.force) {
                const timeOut = setTimeout(() => {
                    addClass(this.identifier, 'composer__message--no-transform');
                    clearTimeout(timeOut);
                }, 10);

                this.resize();
            }
        }
    }

    private resize(): void {
        if (this.enable) {
            addClass(this.identifier, 'composer__message--no-transform');

            const timeOut = setTimeout(() => {
                this.element.nativeElement.style.height = `auto`;
                this.element.nativeElement.style.height = `${this.element.nativeElement.scrollHeight}px`;
                clearTimeout(timeOut);
            }, 10);
        }
    }

    @HostListener('paste')
    onPast(): void {
        this.resize();
    }

    @HostListener('keydown')
    onKeyDown(): void {
        this.resize();
    }
}
