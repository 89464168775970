<ng-container *ngIf="!isLoading;else loadingTemplate">
    <ng-container *ngIf="rows.length; else emptyTemplate">
        <msc-common-table [columns]="columns" [rows]="rows">
            <ng-template #thTemplate let-datum="datum">
                <span>{{ datum.label }}</span>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <span>{{ datum.label }}</span>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <span>{{ datum.label }}</span>
            </ng-template>
            <ng-template #thTemplate let-datum="datum">
                <span>{{ datum.label }}</span>
            </ng-template>


            <ng-template #tdTemplate let-datum="datum">
                <div class="flex flex-col cursor-pointer" (click)="goToTemplateDetails(datum)">
                    <span class="hover:underline">{{ datum.title || ('words.untitled' | translate) }}</span>
                    <div class="tag flex space-x-1 items-center w-fit">
                        <div class="tag__text">{{ datum.label }}</div>
                    </div>
                </div>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <span>{{ datum.duration | duration }}</span>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <msc-chart-pie [size]="'small'" [value]="datum.progress" [valueColor]="'#8290fd'">
                </msc-chart-pie>
            </ng-template>
            <ng-template #tdTemplate let-datum="datum">
                <msc-chart-pie [size]="'small'" [value]="datum.score" [valueColor]="'#8290fd'">
                </msc-chart-pie>
            </ng-template>
        </msc-common-table>
    </ng-container>

    <ng-template #emptyTemplate>
        <msc-common-empty [message]="'common.empty' | translate">
            <ng-template></ng-template>
        </msc-common-empty>
    </ng-template>
</ng-container>

<ng-template #loadingTemplate>
    <msc-common-spinner></msc-common-spinner>
</ng-template>