<div class="content-stats-providers__header">
    <div class="content-stats-providers__resume box flex align-center between">
        <div class="content-stats-providers__logo">
            <img [src]="'https://static.myskillcamp.com/images/integrations/' + provider.name + '-picto.svg'">
        </div>

        <div class="content-stats-providers__data flex between">
            <p class="content-stats-providers__label">
                {{ 'providers.licenses-used' | translate }}
            </p>

            <div class="content-stats-providers__progress" *ngIf="!displays.setUp">
                <div class="progress">
                    <div class="progress-content">
                        <div class="progress-content__bar"></div>
                        <div class="progress-content__full"></div>
                    </div>
                </div>
            </div>

            <div class="content-stats-providers__duration" *ngIf="!displays.setUp">
                {{ provider.licensesUsed || 0 }} / {{ provider.licensesAvailable | empty }}
            </div>

            <a class="content-stats-providers__edit" [tooltip]="'providers.set-up.license' | translate" (click)="displayed('setUp')" *ngIf="!displays.setUp">
                <i class="icon icon-edit"></i>
            </a>
        </div>

        <div class="content-stats-providers__set" *ngIf="displays.setUp">
            <div class="content-stats-providers__set__actions">
                <input type="number" min="0" class="input-number input-number--tiny" [(ngModel)]="provider.licensesAvailable">

                <div class="">
                    <i class="icon icon-checkmark" (click)="updateLicense(provider)"></i>

                    <i class="icon icon-close" (click)="displayed('setUp')"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="content-stats-providers__filter flex align-center between">
        <div class="content-stats-providers__search flex align-center">
           <div class="flex space-x-2">
                <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                    {{ 'words.filters' | translate }}
                </msc-button>
            
                <ng-container *ngIf="isFilterActive">
                    <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                        {{ 'words.reset-filters' | translate }}
                    </msc-button>
                </ng-container>
            </div>

            <div class="date-till flex align-center justify-start">
                <p>{{ 'words.period' | translate }}</p>

                <div class="select" [ngClass]="{'active': displays.date}" (click)="displayed('date')">
                    <div class="select__content">
                        <p class="select__placeholder">
                            {{ dates.start | date: 'dd MMM YYYY' }} - {{ dates.end | date: 'dd MMM YYYY' }}
                            <i class="icon icon-arrow-ios-down"></i>
                        </p>

                        <div class="select__dropdown" (click)="$event.stopPropagation()">
                            <msc-daterangepicker [range]="true" [(dates)]="dates" (onSave)="updateDates()" (onClose)="displayed('date', true)"></msc-daterangepicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-stats-providers__action">
            <msc-button class="msc-button--primary" (click)="exportReportingAside.onOpen()" type="fab" palette="primary" icon="icon-download-1" [disabled]="loaders.download" [loading]="loaders.download"></msc-button>
        </div>
    </div>
</div>

<msc-common-spinner *ngIf="loaders.loading"></msc-common-spinner>

<div class="content-stats-providers__body" *ngIf="!loaders.loading">
    <div class="stats-usage">
        <div class="col-12 col-separator no-gutter">
            <div class="stats-usage__item col-6 col-md-6 usage-item--orange">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-calendar text-primary-500"></i>

                        <span>{{ 'providers.total-subscriptions' | translate }}</span>
                    </div>

                    <div class="stats-usage__item__header__count text-primary-500">
                        {{ total(providerStat.learnerSubscriptions) | empty }}
                    </div>
                </div>

                <msc-shared-stats-provider-manager [title]="'providers.total-subscriptions'" [stats]="providerStat.learnerSubscriptions" [dates]="dates" [granularity]="granularity" [color]="'#feb88a'" [legend]="'usage.subscription'"></msc-shared-stats-provider-manager>
            </div>

            <div class="stats-usage__item col-6 col-md-6 usage-item--green">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-people"></i>

                        <span>{{ 'providers.learners-subscribed' | translate }}</span>
                    </div>

                    <div class="stats-usage__item__header__count">
                        {{ total(providerStat.learnerContentSubscribed) | empty }}
                    </div>
                </div>

                <msc-shared-stats-provider-manager [title]="'providers.learners-subscribed'" [stats]="providerStat.learnerContentSubscribed" [dates]="dates" [granularity]="granularity" [legend]="'words.learner'"></msc-shared-stats-provider-manager>
            </div>
        </div>

        <div class="col-12 col-separator no-gutter">
            <div class="stats-usage__item col-6 col-md-6 usage-item--red">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-people"></i>

                        <span>{{ 'providers.learners-content-done' | translate }}</span>
                    </div>

                    <div class="stats-usage__item__header__count">
                        {{ total(providerStat.learnerContentDone) | empty }}
                    </div>
                </div>

                <msc-shared-stats-provider-manager [title]="'providers.learners-content-done'" [stats]="providerStat.learnerContentDone" [dates]="dates" [granularity]="granularity" [color]="'#f44436'" [legend]="'words.learner'" [warning]="warning"></msc-shared-stats-provider-manager>
            </div>

            <div class="stats-usage__item col-6 col-md-6 usage-item--grey">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-content"></i>

                        <span>{{ 'providers.contents-done' | translate }}</span>
                    </div>

                    <div class="stats-usage__item__header__count">
                        {{ total(providerStat.contentDone) | empty }}
                    </div>
                </div>

                <msc-shared-stats-provider-manager [title]="'providers.contents-done'" [stats]="providerStat.contentDone" [dates]="dates" [granularity]="granularity" [color]="'#8c91b4'" [legend]="'words.content'" [warning]="warning"></msc-shared-stats-provider-manager>
            </div>
        </div>

        <div class="col-12 col-separator no-gutter">
            <div class="stats-usage__item col-12 usage-item--blue">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-clock text-secondary-500"></i>

                        <span>{{ 'providers.learning-duration' | translate }}</span>
                    </div>

                    <div class="stats-usage__item__header__count text-secondary-500" *ngIf="!total(providerStat.learningDuration)">-</div>

                    <div class="stats-usage__item__header__count" *ngIf="total(providerStat.learningDuration)">
                        {{ getDuration('days', total(providerStat.learningDuration)) }} {{ getDuration('hours', total(providerStat.learningDuration)) }} {{ getDuration('minutes', total(providerStat.learningDuration)) }}
                    </div>
                </div>

                <msc-shared-stats-provider-manager [title]="'providers.learning-duration'" [stats]="providerStat.learningDuration" [dates]="dates" [granularity]="granularity" [duration]="true" [color]="'#5150c4'" [warning]="warning"></msc-shared-stats-provider-manager>
            </div>
        </div>
    </div>
</div>

<msc-shared-aside-export-reporting #exportReportingAside [diff]="dates.diff" [options]="{providers: true, extend: true}" (onSave)=download($event)></msc-shared-aside-export-reporting>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>
