import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef } from '@angular/core';

@Component({
    selector: 'msc-common-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonErrorComponent {
    @Input() message?: string;
    @ContentChild(TemplateRef) bodyTemplate: TemplateRef<any>;
}
