<h3 class="title">
    <span>{{ 'words.create' | translate }}</span>
</h3>

<div class="grouping">
    <h4 class="heading">Favicon</h4>
    <div class="preview"><img #img></div>
    <p class="description">{{ 'url.favicon.description' | translate }}</p>
    <div class="wrapper buttons">
        <msc-button class="msc-button--primary" palette="primary" (click)="onImageAdd()">{{'url.button.favicon.add' | translate}}</msc-button>
        <msc-button class="msc-button--primary" (click)="onImageRemove()" palette="secondary">{{'url.button.favicon.remove' | translate}}</msc-button>
    </div>
    <p class="description">{{ 'url.recommended.size' | translate }}</p>
</div>

<div class="grouping margin-top-40">
    <form [formGroup]="form">
        <input type="file" accept="image/*" #file (change)="onImageChange($event.target.files)">
        <div class="grouping margin-top-20">
            <h4 class="heading">URL</h4>
            <div class="grouping">
                <msc-common-input-radio controlName="type" [label]="'words.subdomain' | translate" [value]="'subdomain'"></msc-common-input-radio>
                <ng-container *ngIf="form.controls['type'].value === 'subdomain'">
                    <div class="flex my-2">
                        <msc-input class="grow" formControlName="url"></msc-input>
                        <msc-input class="w-60" [placeholder]="domain" [disabled]="true"></msc-input>
                    </div>
                </ng-container>
            </div>
            <div class="grouping" [ngClass]="{ 'margin-top-20' : form.controls['type'].value === 'custom' }">
                <msc-common-input-radio controlName="type" [label]="'words.custom' | translate" [value]="'custom'"></msc-common-input-radio>
                <ng-container *ngIf="form.controls['type'].value === 'custom'">
                    <msc-input class="my-2 grow" formControlName="url"></msc-input>
                    <div class="grouping">
                        <p class="description">{{ 'url.custom.create.description.row.one' | translate }}</p>
                        <p class="description"><span>&#8226;&nbsp;</span>{{ 'url.custom.create.description.row.two' | translate }}</p>
                        <p class="description"><span>&#8226;&nbsp;</span>{{ 'url.custom.create.description.row.three' | translate }}</p>
                        <p class="description">
                            <span>{{ 'url.custom.info.more' | translate }}</span>&#44;
                            <a class="guide" [href]="guide" target="_blank">{{ 'url.custom.info.guide' | translate }}</a>
                        </p>
                    </div>
                    <div class="grouping margin-top-20">
                        <p class="description">{{ 'organize.experience.watch.mail.details.dkim.value' | translate }}&#58;</p>
                        <div class="clipboard">
                            <input type="text" [value]="hashString$ | async" #clip>
                            <msc-button class="msc-button--outline" icon="icon-arrow-right-1" (click)="onCopy(clip)">
                                {{ 'words.copy-clipboard' | translate }}
                            </msc-button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>

<div class="grouping margin-top-40">
    <ng-container *ngIf="hashId$ | async as hashId">
        <msc-button class="msc-button--primary" palette="primary" [loading]="isLoadingSubmit" [disabled]="!form.controls['url'].value" (click)="onSubmit(hashId)">{{'words.create' | translate}}</msc-button>
    </ng-container>
</div>
