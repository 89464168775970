export enum GroupTypes {
    TEAM = 'team',
    COMMUNITY = 'community',
}

export enum GroupRoles {
    MEMBER = 'group_member',
    OWNER = 'group_owner',
    MANAGER = 'group_manager'
}

export enum GroupPermissions {
    MANAGE = 'group_manage_user'
}

export enum GroupUserContentTypes {
    SUBSCRIBED = 'subscribed',
    STARTED = 'started',
    COMPLETED = 'completed'
}

export enum GroupUserContentState {
    FINISHED = 'finished',
    START = 'start',
    CONTINUE = 'continue',
    PENDING_VALIDATION = 'pending',
    REFUSED = 'refused'
}