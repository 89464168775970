<msc-common-aside #aside [title]="'instance.add' | translate" [hasActionButtons]="false">
    <ng-template #asideTemplate>
        <div class="slide__content slide-access-manage-v2">
            <div class="content-slide-access">
                <div class="slide__search">
                    <msc-common-search trigger="auto" [placeholder]="'common.search' | translate"
                        (searchEvent)="search($event.value)"></msc-common-search>
                </div>
    
                <div class="content-slide-access__available">
                    <div class="box box-content flex center between" *ngFor="let instance of availableInstances">
                        <div class="box-content__infos flex align-center justify-start">
                            <i class="icon"
                                [ngClass]="{'icon-trending-up': instance.type === 'traject', 'icon-cube': instance.type === 'course_instance'}"></i>
                            <p>{{ instance.data.title | truncate: 30 }}
                                <span *ngIf="instance.type === 'course_instance'">
                                    {{ instance.data.internalName | truncate: 30 }}
                                </span>
                            </p>
                        </div>
    
                        <div class="justify-end flex">
                            <div class="box-content__participant flex align-center">
                                <i class="icon icon-people"></i>
                                <p>{{ instance.data.studentsCount }}</p>
                            </div>
    
                            <div class="box-content__line flex align-center"></div>
    
                            <div class="flex align-center justify-end">
                                <a *ngIf="instance.data.canBeUpdated" class="box-content__plus text-secondary-500"
                                    (click)="addInstance(instance)">
                                    <i class="icon icon-plus"></i>
                                </a>
    
                                <a *ngIf="!instance.data.canBeUpdated" class="box-content__plus box-content__plus--disabled"
                                    [tooltip]="'access.disabled-instance-not-allowed' | translate">
                                    <i class="icon icon-plus"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="content-slide-access__manage__more text-center">
                    <div class="load-more">
                        <msc-load-more [loading]="loading.more" [pagination]="pagination" (onLoad)="loadMore()">
                        </msc-load-more>
                    </div>
                </div>
    
                <msc-common-spinner *ngIf="loading.load"></msc-common-spinner>
            </div>
        </div>
    </ng-template>
</msc-common-aside>