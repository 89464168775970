<label *ngIf="label" class="flex text-sm mb-1 mr-2" [for]="name">{{label}}{{ formField?.hasValidator(requiredValidator) ? '*' :
    ''}}</label>
<div class="input-container flex flex-col relative" [ngClass]="palette">
    <i *ngIf="icon" class="icon {{icon}}"></i>
    <textarea role="presentation" autocomplete="off" [maxlength]="maxlength"
        [ngClass]="{'has-errors': (formField?.invalid && formField?.dirty) || hasErrors, 'has-success': hasSuccess}"
        [name]="name" [(ngModel)]="value" [class.resize-none]="!autosize" [placeholder]="placeholder" [disabled]="disabled" (input)="onChange(value)"
        (blur)="onTouched()" [cols]="cols" [rows]="rows" [autosize]="autosize"></textarea>
</div>
<div class="mt-1" *ngIf="formField?.invalid || hints">
    <field-errors class="mt-1" [class.hidden]="formField?.valid" [formField]="formField">
    </field-errors>
    <p class="text-black-400 text-xs leading-4" *ngFor="let hint of hints">
        <span><i class="icon icon-bulb text-sm"></i></span> <span [innerHtml]="hint"></span>
    </p>
</div>