<msc-common-modal #modal size="30vw" [title]="'stats.email-export.title' | translate" [tooltip]="settings.year ? {icon:'icon-info-1',text:('stats.email-export.tooltip' | translate)} : false">
    <ng-template>
        <div class="">
            <p class="mb-3" [innerHtml]="'stats.email-export.label' | translate:{name: exportName} "></p>

            <ng-container *ngIf="settings.year">
                <msc-common-select class="mb-2 w-full max-w-full" [data]="years" (selectEvent)="onSelectYear($event)">
                    <ng-template #buttonTemplate let-data="data">
                        <ng-container *ngIf="data?.length; else emptyTemplate">
                            <button class="flex items-center overflow-hidden">
                                <i class="icon-calendar text-secondary-500"></i>
                                <span class="truncate ml-2">{{ data[0].label }}</span>
                            </button>
                        </ng-container>
                        <ng-template #emptyTemplate>
                            <span>{{'user.slow-invite.select-manager' | translate}}</span>
                        </ng-template>   
                    </ng-template>
                </msc-common-select>
            </ng-container>

            <div #emailsContainer class="max-h-80 overflow-y-auto">
                <div class="flex items-center mb-2" *ngFor="let emailField of data.emails; let i = index">
                    <div class="w-full relative">
                        <msc-input type="text" [disabled]="i === 0" [hasErrors]="!emailField.isValid"
                            (focusout)="isValidEmail(emailField)" [(ngModel)]="emailField.value"></msc-input>
                        <div *ngIf="i > 0" class="flex flex-col absolute right-0 top-0 px-2 h-full justify-center">
                            <span class="cursor-pointer" (click)="removeEmailField(i)"><i
                                    class="icon icon-close"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center text-indigo-600 cursor-pointer w-fit" (click)="addEmailField()">
            <i class="icon icon-plus-circle-1 text-lg mr-1"></i>
            <small>{{ 'stats.email-export.add_email' | translate}}</small>
        </div>

        <div class="flex justify-center mt-5">
            <div class="modal__buttons">
                <msc-button class="msc-button--primary" palette="secondary" [disabled]="invalid" (click)="save()">
                    {{ 'stats.email-export.send_export' | translate }}</msc-button>
            </div>
        </div>
    </ng-template>
</msc-common-modal>