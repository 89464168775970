import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmitterService } from '@services';

@Component({
    selector: 'msc-learn-detail-journey',
    templateUrl: './journey.component.html'
})
export class LearnDetailJourneyComponent implements OnInit {
    public hasCorrections: boolean;

    constructor(private route: ActivatedRoute, private emitterService: EmitterService) {}

    ngOnInit(): void {
        this.hasCorrections = this.route.snapshot.data.corrections
            .filter(correction => correction.corrections && correction.corrections.length)
            .reduce((accumulator, current) => [...accumulator, current.corrections], []).length > 0;
        this.emitterService.set('has-corrections', this.hasCorrections);
    }
}
