<div class="flex justify-between items-center my-2 py-1 border-b">
    <div class="flex space-x-2 items-center">
        <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

        <div class="filters-container">
            <msc-button icon="icon-options-2" (click)="onToggleFilters()">
                {{ 'words.filters' | translate }}
            </msc-button>

            <div class="filters-options flex-col space-y-6 p-4" [ngClass]="{'active': isFiltersVisible}">
                <div class="w-[300px]">
                    <ng-container *ngIf="selectFilterData">
                        <msc-common-select [data]="selectFilterData.types" type="multi">
                            <ng-template #buttonTemplate let-data="data">
                                <span>{{ 'words.types' | translate }}</span>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <label class="flex py-2 w-full">
                                    <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectFilterOption(datum, 'types')"></msc-common2-checkbox>
                                    <span class="ml-2">{{ datum.label }}</span>
                                </label>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>

                <div class="w-[300px]">
                    <ng-container *ngIf="selectFilterData">
                        <msc-common-select [data]="selectFilterData.providers" type="multi">
                            <ng-template #buttonTemplate let-data="data">
                                <span>{{ 'words.providers' | translate }}</span>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <label class="flex py-2 w-full">
                                    <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectFilterOption(datum, 'providers')"></msc-common2-checkbox>
                                    <span class="ml-2">{{ datum.label }}</span>
                                </label>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>

                <div class="w-[300px]">
                    <ng-container *ngIf="selectFilterData">
                        <msc-common-select [data]="selectFilterData.languages" type="multi">
                            <ng-template #buttonTemplate let-data="data">
                                <span>{{ 'words.languages' | translate }}</span>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <label class="flex py-2 w-full">
                                    <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectFilterOption(datum, 'languages')"></msc-common2-checkbox>
                                    <span class="ml-2">{{ datum.label }}</span>
                                </label>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>

                <div class="flex items-center justify-end space-x-2">
                    <msc-button class="msc-button--tiny" (click)="onToggleFilters(false)">{{ 'words.cancel' | translate}}</msc-button>
                    <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" (click)="onFilter()">{{ 'words.apply' | translate }}</msc-button>
                </div>
            </div>
        </div>

        <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" *ngIf="activeFilters" (click)="onFiltersReset()">
            {{ 'words.reset-filters' | translate }}
        </msc-button>
        <div *ngIf="mode === 'coach'">
            <div class="form-selection__switch flex align-center">

                <msc-common2-switch [value]="onlySelection" [label]="'words.my-selection-contents' | translate" (changeEvent)="showMySelection()"></msc-common2-switch>
            </div>
        </div>
    </div>

    <div class="w-[240px] text-sm">
        <msc-common-select [data]="selectData">
            <ng-template #buttonTemplate let-data="data">
                <button>
                    <span>{{ 'filter.sorted-by' | translate }}</span>
                    <ng-container *ngIf="data?.length">
                        <span class="hidden-md"> : {{ data[0].label | translate }}</span>
                    </ng-container>
                </button>
            </ng-template>
            <ng-template #optionTemplate let-datum="datum">
                <button class="flex py-2 w-full" (click)="onSelectOption(datum)">
                    <span>{{ datum.label | translate}}</span>
                </button>
            </ng-template>
        </msc-common-select>
    </div>
</div>
