import { Directive, Input, ElementRef, HostListener, ComponentRef, OnDestroy } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef, OverlayPositionBuilder, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
//
import { CommonTooltipComponent, CommonTooltipDirection } from '@common2/components/tooltip/tooltip.component';
//
export const CommonTooltipPositionMapping = new Map<CommonTooltipDirection, ConnectedPosition>([
    ['top', { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -8 }],
    ['right', { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: 16 }],
    ['bottom', { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 8 }],
    ['left', { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -16 }],
]);

@Directive({
    selector: '[tooltip]'
})

export class CommonTooltipDirective implements OnDestroy {
    @Input() direction: CommonTooltipDirection = 'top';
    @Input('tooltip') message: string;

    private overlayRef: OverlayRef;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly overlay: Overlay,
        private readonly overlayPositionBuilder: OverlayPositionBuilder,
    ) { }

    ngOnInit(): void {
        this.setOverlay();
    }

    /**
     * Set the overlay
     */
    setOverlay(): void {
        this.overlayRef = this.overlay.create(this.getConfig());
    }

    /**
     * Get the config
     */
    getConfig(): OverlayConfig {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([CommonTooltipPositionMapping.get(this.direction)]);

        return { positionStrategy };
    }

    ngOnDestroy(): void {
        this.overlayRef?.detach();
    }

    /**
     * Event handler for hide
     */
    @HostListener('click')
    @HostListener('scroll')
    @HostListener('mouseleave')
    onHide(): void {
        this.overlayRef.detach();
    }

    /**
     * Event handler for show
     */
    @HostListener('mouseenter')
    onShow(): void {
        if (!this.message) { return; }
        const tooltipRef: ComponentRef<CommonTooltipComponent> = this.overlayRef.attach(new ComponentPortal(CommonTooltipComponent));
        tooltipRef.instance.direction = this.direction;
        tooltipRef.instance.message = this.message;
    }
}
