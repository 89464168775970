import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, ContentProvider, Pagination } from '@lighty';
import { EmitterService, LoaderService, ParamsService, StorageService } from '@services';
import { SubscriptionCollection } from '@classes';
import Glide from '@glidejs/glide';
import { DataHelper } from '@helpers';
import * as moment from 'moment';
import SwiperCore from 'swiper';

@Component({
    selector: 'msc-learn-catalogue-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class LearnCatalogueListComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private glide: any;
    private me: any;
    public company: Company;
    public background: string = 'https://static.myskillcamp.com/lxp/images/hero-catalog.png';
    public featured: any;
    public contents: any;
    public externals: any;
    public events: any;
    public contentsCollection: any;
    public thematics: any;
    public providers: any;
    public catalogue: any = [];
    public pagination: Pagination;
    public paginationEvent: Pagination;
    public mode: string = 'list';
    public query: string;
    public filters: any = {};
    public activeFilters: boolean = false;
    public searching: boolean = false;
    public isSearching: boolean = false;
    public loading: boolean = false;
    public loaders: any = {
        list: false,
        calendar: false,
    };
    public action: any;
    public glideView: number = 2;
    public modalOn: boolean = false;
    public cardParams: any;
    public buildFilters: EventEmitter<any> = new EventEmitter();

    constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService, private contentProvider: ContentProvider,
                private loaderService: LoaderService, private emitterService: EmitterService, private paramsService: ParamsService) {}

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.background = this.company.experience && this.company.experience.cataloguePicture ? this.company.experience.cataloguePicture + '?size=raw' : this.background;
        this.featured = this.route.snapshot.data.catalogue.featured;
        this.contents = this.route.snapshot.data.catalogue.contents;
        this.externals = this.route.snapshot.data.catalogue.externals;

        this.thematics = {
            contents: [],
            externals: []
        };

        this.providers = {
            internals: [],
            externals: []
        };

        this.action = {
            back: false,
            next: true,
            index: 0
        };

        this.cardParams = {
            catalogue: true
        };

        this.glideView = window.outerWidth > 425 ? this.glideView : 1;
        if (this.featured.length > this.glideView) {
            this.glideInit();
        }

        this.getEvents();
        this.getThematics();

        this.subscriptionCollection.subscribe = this.emitterService.get('search.catalogue').subscribe((query) => {
            if (typeof query === 'string') {
                const timeOut = setTimeout(() => {
                    this.isSearching = true;
                    clearTimeout(timeOut);
                }, 200);
                this.search(query);
                this.emitterService.set('search.catalogue', {});
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('availableProviders').subscribe((data) => {
            if (Array.isArray(data)) {
                this.providers.internals = data.filter((provider) => {
                    return provider.type === 'internal';
                });

                this.providers.externals = data.filter((provider) => {
                    return provider.type === 'external';
                });
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('catalogue.modal').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.modalOn = data;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    private getEvents(): void {
        this.loaders.calendar = true;
        this.contentProvider.getEventsCatalogue().subscribe((data) => {
            this.paginationEvent = data.pagination;
            this.contentsCollection = data.contents;

            this.groupEvents(data);
        });
    }

    private groupEvents(data): void {
        for (const content of data.contents) {
            content.eventDate =  moment(content.event.startAt).format('YYYY-MM-DD');
        }
        this.events = DataHelper.groupBy(this.contentsCollection, 'eventDate', {key: 'date', value: 'items'});
        this.loaders.calendar = false;
    }

    private getThematics(): void {
        this.contentProvider.getThematics(this.company.id).subscribe((data) => {
            this.thematics.contents = data[0].skills;
            this.thematics.externals = data[1].skills;
        });
    }

    private glideInit(): void {
        const timeOut = setTimeout(() => {
            if (!document.querySelector('#slider')) { return; }
            this.glide = new Glide('#slider', {
                perView: this.glideView
            });

            this.glide.on('run', (move) => {
                if (move.direction === '=') {
                    this.action.index = parseInt(move.steps, 10);
                }

                if (move.direction === '>') {
                    this.action.index++;
                }

                if (move.direction === '<') {
                    this.action.index--;
                }

                this.action.back = this.action.index > 0;
                this.action.next = this.action.index < this.featured.length - this.glideView;
            });

            this.glide.mount();

            clearTimeout(timeOut);
        }, 50);
    }

    changeMode(): void {
        this.mode = this.mode === 'list' ? 'calendar' : 'list';
    }

    search(query?: string): void {
        if (this.activeFilters || !!this.query || query) {
            this.searching = true;
            this.loaderService.run();

            const params = this.paramsService.getCatalogue(this.filters, this.company.id, this.query || query, null, null, this.me);

            this.contentProvider.getCatalogue(params).subscribe((data) => {
                this.pagination = data.pagination;
                this.catalogue = data.catalogue;
                this.isSearching = true;
                this.searching = false;
            });
        } else {
            this.isSearching = false;
            this.glideInit();
        }
    }

    loadMore(): void {
        this.loading = true;
        this.loaderService.run();

        const params = this.paramsService.getCatalogue(this.filters, this.company.id, null, this.pagination, null, this.me);

        this.contentProvider.getCatalogue(params).subscribe((data) => {
            this.catalogue = this.catalogue.concat(data.catalogue);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    loadMoreEvents(): void {
        const params = {
            page: this.paginationEvent.currentPage + 1
        };

        this.loaders.calendar = true;

        this.contentProvider.getEventsCatalogue(params).subscribe((data) => {
            this.paginationEvent = data.pagination;
            this.contentsCollection = this.contentsCollection.concat(data.contents);

            this.groupEvents(data);
        });
    }

    change(): void {
        if (this.mode === 'list' && this.featured.length > 2) {
            this.glideInit();
        }
    }

    details(content: any): void {
        this.router.navigate(['learn', this.company.slug, 'detail', content.type === 'traject' ? 'journey' : 'template', content.id]);
    }

    translate(provider: any): string {
        if (provider.name === 'company') {
            return this.company.name;
        } else if (!isNaN(parseInt(provider.id, 10))) {
            return provider.name;
        }
        return 'words.' + provider.name;
    }

    filter(provider: any): void {
        const params = {
            company: false
        };
        params[provider.id] = true;

        const filter = {
            providers: params
        };

        this.buildFilters.emit(filter);
    }
}
