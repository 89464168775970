import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Pages
import { OrganizeMarketplacePageComponent } from './pages/marketplace/organize-marketplace-page.component';
import { OrganizeMarketplaceCataloguePageComponent } from './pages/marketplace-catalogue/organize-marketplace-catalogue-page.component';
import { OrganizeMarketplaceCustomersPageComponent } from './pages/marketplace-customers/organize-marketplace-customers-page.component';
import { OrganizeMarketplaceSettingsPageComponent } from './pages/marketplace-settings/organize-marketplace-settings-page.component';

const routes: Routes = [
    {
        path: '', // TODO // path: 'marketplace',
        component: OrganizeMarketplacePageComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'catalogue',
            },
            {
                path: 'catalogue',
                component: OrganizeMarketplaceCataloguePageComponent,
            },
            {
                path: 'customers',
                component: OrganizeMarketplaceCustomersPageComponent,
            },
            {
                path: 'settings',
                component: OrganizeMarketplaceSettingsPageComponent,
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class Organize2RoutingModule { }
