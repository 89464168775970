import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'msc-organize-integration-watch-rules-edit-selection-panel',
    templateUrl: './selection-panel.component.html'
})
export class OrganizeIntegrationWatchRulesEditSelectionPanelComponent implements OnInit {
    @Input() rule: any;
    @Output() allowNavigation: EventEmitter<any> = new EventEmitter<any>();

    public collection: any;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.allowNavigation.emit(true);
        this.collection = this.route.snapshot.data.collection;
        if (!this.rule.selectionType) {
            this.rule.selectionType = 'all';
        }
    }
}
