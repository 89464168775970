import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ErrorStreamService } from './error-stream.service';

/**
 * Upload service
 */
@Injectable({providedIn: 'root'})
export class UploadService {
    /**
     * Status emitter
     */
    private status: EventEmitter<any> = new EventEmitter();

    /**
     * Import HttpClient from Angular, ErrorStreamService from @services
     * @construtor
     * @param {HttpClient} http - HttpClient from Angular
     * @param {ErrorStreamService} errorStreamService from @services
     */
    constructor(private http: HttpClient, private errorStreamService: ErrorStreamService) {}

    /**
     * Upload file
     * @param {File} file - File
     * @param {string} attributeFilename - Attribute Filename
     * @param {string} url - Url where to store file
     */
    upload(file: File, attributeFilename: string = 'file', url: string = 'media'): void {
        this.errorStreamService.locked();
        this.uploadRequest(file, attributeFilename, url).subscribe((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
                const progress = Math.round(100 * event.loaded / event.total);
                this.status.emit({type: 'progress', value: progress });
            } else if (event instanceof HttpResponse) {
                this.status.emit({type: 'finished', value: event.body});
            }
            this.errorStreamService.unlocked();
        }, (error) => {
            this.status.emit({type: 'error', error});
            this.errorStreamService.unlocked();
        });
    }

    /**
     * Get status emitter
     * @return {EventEmitter<any>} Status emitter
     */
    getStatus(): EventEmitter<any> {
        return this.status;
    }

    /**
     * Upload request
     * @param {File} file - File
     * @param {string} attributeFilename - Attribute Filename
     * @param {string} url - Url where to store file
     * @return {Observable<HttpEvent<{}>>} Observable HttpRequest
     */
    private uploadRequest(file: File, attributeFilename: string = 'file', url: string = 'name'): Observable<HttpEvent<{}>> {
        const formData: FormData = new FormData();
        formData.append(attributeFilename, file);

        const request = new HttpRequest('POST', `${environment.envVar.API_URL}/${url}`, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(request);
    }
}
