import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//
import { RedirectService, StorageService, TranslateService } from '@services';
import { DownloadHelper, HttpHelper } from '@helpers';
import { ManageCalendarProvider } from '@lighty';
//
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-manage-instance-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: ['./agenda.component.scss'],
})

export class ManageInstanceAgendaComponent {
    private company: any;
    public events: any[];
    public displays: any[] = [];
    public dropdowns: any;
    public selectedEvent: any;
    public asides: any;
    public instance: any;
    public maxPages: number = 0;
    public currentPage: number = 1;
    public hasLoadMore: boolean = false;
    public isLoadingMore: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private manageCalendarProvider: ManageCalendarProvider,
        private toastService: CommonToastService,
        private redirectService: RedirectService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.instance = this.storageService.get('content');
        this.events = this.route.snapshot.data.events.events;
        if(this.route.snapshot.data.events.pagination.lastPage > 1){
            this.hasLoadMore = true;
        }
        this.asides = {
            attendance: false,
            edit: false
        };

        this.dropdowns = {
            parameters: [],
            exports: []
        };

        this.route.queryParams.subscribe(params => {
            if (params.action && params.event) {
                if (params.action === 'periods') {
                    const event = this.events.find(data => data.id === parseInt(params.event, 10));
                    if (event) {
                        this.openSidebar('periods', event);
                    }
                }
            }
        });
       // this.updateDataPagination();
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    openSidebar(type: string, event?: any): void {
        if (event) {
            this.selectedEvent = event;
        }
        this.asides[type] = !this.asides[type];
    }

    updateEvent(updatedEvent: any): void {
        this.asides['edit'] = false;
        const index = this.events.findIndex((event) => {
            return event.id === updatedEvent.id;
        });

        if (index !== -1) {
            this.events[index] = updatedEvent;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        }
    }

    checkIfPast(event: any, ignoreTime: boolean = false): boolean {
        const start = new Date(event.startAt);
        const end = new Date(event.endAt);
        const now = new Date();

        if (ignoreTime) {
            return end.getTime() - now.getTime() < 1000 * 60 * 60 * 24;
        } else {
            return start.getTime() - now.getTime() <= 1000;
        }
    }

    checkNextEvent(date: string): boolean {
        let closest = null;
        let eventToReturn = null;

        this.events.forEach((event) => {
            if (event.startAt) {
                const start = new Date(event.startAt);
                const now = new Date();
                const diff = now.getTime() - start.getTime();
                if ((diff <= closest && diff >= 0) || ((diff >= closest || closest > 0) && diff <= 0) || closest == null) {
                    closest = diff;
                    eventToReturn = event;
                }
            }
        });
        return eventToReturn.startAt === date;
    }

    addEvent(): void {
        switch (this.instance.type) {
            case 'traject':
                this.redirectService.navigate('studio', ['company', this.company.slug, 'journey', 'preview', this.instance.slug]);
                break;
            case 'course_instance':
                this.redirectService.navigate('studio', ['company', this.company.slug, 'course', this.instance.id]);
                break;
        }
    }

    displayedDropdowns(type: string, index: number): void {
        this.dropdowns[type][index] = !this.dropdowns[type][index];
    }

    exportAttendances(): void {
        const params = HttpHelper.cleanParams({
            context: this.instance.type,
            event_ids: []
        });

        this.manageCalendarProvider.exportAttendances(this.company.id, this.instance.id, params).subscribe((data) => {
            DownloadHelper.downloadBlob(data, `history-${this.company.id}.xlsx`);
        });
    }

    getAttendanceListPaper(event: any): void {
        const params = HttpHelper.cleanParams({
            context: this.instance.type
        });

        this.manageCalendarProvider.getAttendanceListPaper(this.company.id, this.instance.id, event.id, params).subscribe((data) => {
            const title = event.title || 'untitled';
            DownloadHelper.downloadBlob(data, 'attendance-' + title + '.pdf');
        });
    }

    downloadIcs(event: any): void {
        DownloadHelper.download(event.icsLink, event.title, false);
    }

    openModalDeleteEvent(event: any): void {
        this.selectedEvent = event;
    }

    deleteEvent(): void {
        const params: any = {
            context: this.instance.type
        };

        this.manageCalendarProvider.delete(this.company.id, this.instance.id, this.selectedEvent.id, params).subscribe(() => {
            const index = this.events.findIndex((event) => {
                return event.id === this.selectedEvent.id;
            });

            if (index !== -1) {
                this.events.splice(index, 1);
                this.toastService.onSuccess(this.translateService.instant('toast.event.deleted'));
            }
        });
    }

   public updateDataPagination(): void {
        this.isLoadingMore = true;
        this.currentPage ++;
        let params: any = {
            context: this.instance.type,
            page: this.currentPage
        };
        if(this.hasLoadMore){
            this.manageCalendarProvider.get(this.company.id, this.instance.id, params).subscribe(data => {
                this.hasLoadMore = false;
                this.maxPages = data.pagination.lastPage;
                this.events.push(...data.events);
                this.isLoadingMore = false;
                if(this.currentPage < this.maxPages) {
                    this.hasLoadMore = true;
                } else {
                    this.hasLoadMore = false;
                }
            });
        }
    }
}
