<div class="container-catalog__session" *ngFor="let data of explore; let index=index">
    <h2 class="container-catalog__title mb-2" [ngClass]="{'container-catalog__title--white': index === 0}" *ngIf="!checkDisplayContents()">
        <span>
            {{ data.id === 0 ? ('words.miscellaneous' | translate) : data.name }}
        </span>

        <a class="link link-showmore" [ngClass]="{'link-showmore--light': index === 0}" (click)="navigate(data)">
            {{ 'words.show-more' | translate }}

            <i class="icon icon-arrow-right-1 text-primary-600"></i>
        </a>
    </h2>

    <msc-learn-catalogue-slider [contents]="data.contents" *ngIf="!checkDisplayContents()"></msc-learn-catalogue-slider>

    <div class="container-catalog-internal__list row animate" *ngIf="data.contents && checkDisplayContents()">
        <div class="card-container">
            <content-card *ngFor="let content of data.contents" [content]="content"></content-card>
        </div>
    </div>
</div>
