export class Pagination {
    currentPage: number;
    lastPage: number;
    total: number;
    perPage?: number;

    constructor(pagination: object) {
        this.assign(pagination);
    }

    assign(pagination: object): void {
        Object.assign(this, pagination);
    }
}
