import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AdminGroupProvider {

    constructor(private http: HttpClient) { }

    getGroups(companyId: string | number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/groups`, { params });
    }

    getGroup(company_id: string | number, groupId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${groupId}`);
    }

    /**
     * {
        "type": "community|team",
        "name": "MyTeam",
        "subscription_type": "free|admin",
        "timeline_visibility": true,
        "validation": true,
        "history": "all|specific"
        }
    */
    createGroup(company_id: string | number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups`, data);
    }

    updateGroup(company_id: string | number, group_id: number, body: any, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}`, body, params);
    }

    deleteGroup(company_id: string | number, group_id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}`);
    }

    updateGroupCamps(company_id: string | number, group_id: number, data) {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/camps`, data);
    }

    getCollaborators(companyId: string | number, teamId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/groups/${teamId}/users`, { params }).pipe(
            map(data => data)
        );
    }

    updateManagerPermission(companyId: string | number, groupId: number, accountId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${companyId}/groups/${groupId}/users/${accountId}/permission`, params).pipe(
            map(data => data)
        );
    }
}
