import { Component, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';
//
import * as d3 from 'd3';
//
import { GraphicUtils } from '@common2/utils/graphic.utils';

export interface CommonChartDonutDatum {
    color: string;
    value: number;
}

@Component({
    selector: 'msc-common-chart-donut',
    templateUrl: './chart-donut.component.html',
    styleUrls: ['./chart-donut.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonChartDonutComponent {
    @Input() data: Array<CommonChartDonutDatum> = [];
    @Input() label: string = '0%';
    @Input() config: any = {
        fontSize: '16px',
    };

    // main content
    private container: d3.Selection<HTMLElement, {}, HTMLElement, any>;
    private svg: d3.Selection<SVGElement, {}, HTMLElement, any>;
    // groups
    private groupLabels: any = null;
    private groupArcs: any = null;
    // config
    public graphicModel = {
        dom: {
            svg: 'chart-donut-svg',
            groups: {
                labels: 'chart-donut-labels',
                arcs: 'chart-donut-arcs'
            }
        },
        ui: {
            height: 0,
            width: 0,
            radius: 0,
            offset: 0
        }
    }

    constructor(
        private readonly elementRef: ElementRef,
    ) { }

    ngAfterViewInit(): void {
        this.setContent();
        this.setConfig();
        this.setGroups();
        this.onRender();
    }

    /**
     *
     */
    setContent(): void {
        this.container = d3.select(this.elementRef.nativeElement);
        this.svg = this.container.select(`.${this.graphicModel.dom.svg}`);
    }

    /**
     *
     */
    setConfig(): void {
        this.graphicModel.ui.height = parseInt(this.container.style('height'), 10);
        this.graphicModel.ui.width = parseInt(this.container.style('width'), 10);
        this.graphicModel.ui.offset = 5;
        this.graphicModel.ui.radius = GraphicUtils.getRadiusByDimensions(this.graphicModel.ui);
    }

    /**
     *
     */
    setGroups(): void {
        this.groupLabels = this.svg
            .select(`g.${this.graphicModel.dom.groups.labels}`)
            .attr('transform', () => `translate(${this.graphicModel.ui.width / 2}, ${this.graphicModel.ui.height / 2})`);

        this.groupArcs = this.svg
            .select(`g.chart-donut-arcs`)
            .attr('transform', () => `translate(${this.graphicModel.ui.width / 2}, ${this.graphicModel.ui.height / 2})`);
    }

    /**
     *
     */
    onRender(): void {
        this.svg.attr('width', this.graphicModel.ui.width).attr('height', this.graphicModel.ui.height);
        this.onRenderArcs();
        this.onRenderLabels();
    }

    /**
     *
     */
    onRenderArcs(): void {
        const data = this.data;

        const arcSelection = this.groupArcs
            .selectAll('path')
            .data(data);

        const arcDrawing = d3.arc()
            .innerRadius(this.graphicModel.ui.radius - 5)
            .outerRadius(this.graphicModel.ui.radius)
            .startAngle((_, i) => GraphicUtils.getArcAngle(data, i, 'value'))
            .endAngle((_, i) => GraphicUtils.getArcAngle(data, i + 1, 'value'));

        let arcs = arcSelection
            .enter()
            .append('path')
            .attr('d', arcDrawing)
            .style('fill', function(d: any) {
                return d.color;
            });
    }

    /**
     *
     */
    onRenderLabels(): void {
        const label = this.label;

        const labelSelection = this.groupLabels
            .selectAll('text')
            .data([null]);

        const labelDrawing = labelSelection
            .attr('y', function() { return 5; })
            .attr('text-anchor', 'middle')
            .attr('font-size', this.config.fontSize)
            .text(function(d) { return label; });

        let labels = labelSelection
            .enter()
            .append('text')
            .attr('y', function() { return 5; })
            .attr('text-anchor', 'middle')
            .attr('font-size', this.config.fontSize)
            .text(function(d) { return label; });
    }
}
