<a class="resource" [ngClass]="{'resource--light': size === 'mini'}" (click)="download()" *ngIf="resource">
    <img [src]="icon" alt="resources-file">

    <div class="resource__desc">
        <p class="resource__name">{{ (resource.title ? resource.title : resource.media.title) | truncate: truncate }}</p>
        <p class="resource__size">{{ resource.media.size | fileSize }}<span> | {{ resource.media.createdAt | timeAgo }}</span></p>
    </div>

    <i class="icon icon-download" *ngIf="downloadIcon"></i>
</a>

<a class="resource" *ngIf="file">
    <img [src]="icon" alt="resources-file">

    <div class="resource__desc">
        <p class="resource__name">{{ (file.title ? file.title : file.name) | truncate: truncate }}</p>
        <p class="resource__size">{{ file.size | fileSize }}</p>
    </div>
</a>
