<ng-container *ngIf="sender$ | async as sender; else errorTemplate">
    <h3 class="title">
        <span>{{ sender.email }}</span>
        <div class="wrapper">
            <ng-container *ngIf="sender.isActive">
                <msc-button class="msc-button--primary" (click)="onDeactivate()" palette="secondary">{{'words.deactivate' | translate}}</msc-button>
            </ng-container>
            <ng-container *ngIf="!sender.isActive && sender.isValid">
                <msc-button class="msc-button--primary" (click)="onActivate(sender)" palette="primary">{{'organize.experience.watch.mail.list.btn.activate' | translate}}</msc-button>
            </ng-container>
            <msc-button class="msc-button--primary" (click)="goToEdit(sender.id)" palette="primary">{{'organize.experience.watch.mail.details.btn.edit' | translate}}</msc-button>
            <msc-button class="msc-button--primary" (click)="modal.onOpen()" palette="secondary">{{'organize.experience.watch.mail.details.btn.delete' | translate}}</msc-button>
        </div>
    </h3>

    <ng-container *ngIf="!isContinued; else isContinuedTemplate">
        <div class="grouping">
            <h4 class="heading">{{ 'organize.experience.watch.mail.details.email-verify.title' | translate }}</h4>
            <p class="description">{{ 'organize.experience.watch.mail.details.email-verify.description' | translate }}</p>
            <msc-common-led class="margin-top-20" [isOn]="sender.ui.email.isSet" [label]="sender.ui.email.label"></msc-common-led>
            <ng-container *ngIf="!sender.isConfirmed; else isConfirmedTemplate">
                <msc-button class="msc-button--secondary margin-top-20" icon="refresh" palette="secondary" [loading]="isLoadingValidate" (click)="onValidate(sender, 'email')">{{'words.refresh' | translate}}</msc-button>
                <div class="flex space-x-2 mt-4">
                    <span class="info-resend">{{ 'organize.experience.watch.mail.details.email-resend.info' | translate }}</span>
                    <msc-button class="msc-button--outline msc-button--tiny" (click)="onConfirm(sender)">{{ 'organize.experience.watch.mail.details.email-resend.btn' | translate }}</msc-button>
                </div>
            </ng-container>

            <ng-template #isConfirmedTemplate>
                <div class="wrapper margin-top-20">
                    <msc-button class="msc-button--primary" (click)="onContinue()" palette="primary">{{'words.continue' | translate}}</msc-button>
                </div>
            </ng-template>
        </div>
    </ng-container>

    <ng-template #isContinuedTemplate>
        <div class="grouping">
            <h3>{{ 'organize.experience.watch.mail.details.server-config.title' | translate }}</h3>
            <ng-container *ngIf="!sender.isConfigured; else isConfiguredTemplate">
                <p class="description">{{ 'organize.experience.watch.mail.details.server-config.false.description' | translate }}</p>

                <h4>{{ 'url.custom.create.step1.title' | translate }}</h4>
                <p class="description">{{ 'url.custom.create.step1.description' | translate }}</p>

                <div class="grouping margin-top-20">
                    <p class="description">{{ 'organize.experience.watch.mail.details.dkim.host' | translate }}&#58;</p>
                    <div class="clipboard">
                        <input type="text" [value]="sender.dkimHost" #clipDkimHost>
                        <msc-button class="msc-button--outline" icon="icon-arrow-right-1" (click)="onCopy(clipDkimHost)">
                            {{ 'words.copy-clipboard' | translate }}
                        </msc-button>
                    </div>
                </div>
                <div class="grouping margin-top-20">
                    <p class="description">{{ 'organize.experience.watch.mail.details.dkim.value' | translate }}&#58;</p>
                    <div class="clipboard">
                        <input type="text" [value]="sender.dkimValue" #clipDkimValue>
                        <msc-button class="msc-button--outline" icon="icon-arrow-right-1" (click)="onCopy(clipDkimValue)">
                            {{ 'words.copy-clipboard' | translate }}
                        </msc-button>
                    </div>
                </div>

                <h4>{{ 'url.custom.create.step2.title' | translate }}</h4>
                <p class="description">{{ 'url.custom.create.step2.description' | translate }}</p>

                <div class="grouping margin-top-20">
                    <p class="description">{{ 'organize.experience.watch.mail.details.dkim.host' | translate }}&#58;</p>
                    <div class="clipboard">
                        <input type="text" [value]="'pm-bounces'" #clipReturnPathHost>
                        <msc-button class="msc-button--outline" icon="icon-arrow-right-1" (click)="onCopy(clipReturnPathHost)">
                            {{ 'words.copy-clipboard' | translate }}
                        </msc-button>
                    </div>
                </div>
                <div class="grouping margin-top-20">
                    <p class="description">{{ 'organize.experience.watch.mail.details.dkim.value' | translate }}&#58;</p>
                    <div class="clipboard">
                        <input type="text" [value]="'pm.mtasv.net'" #clipReturnPathValue>
                        <msc-button class="msc-button--outline" icon="icon-arrow-right-1" (click)="onCopy(clipReturnPathValue)">
                            {{ 'words.copy-clipboard' | translate }}
                        </msc-button>
                    </div>
                </div>

                <div class="grouping margin-top-20">
                    <msc-common-led [isOn]="sender.ui.dkim.isSet" [label]="sender.ui.dkim.label"></msc-common-led>
                    <msc-button class="margin-top-20" icon="refresh" palette="secondary" [loading]="isLoadingValidate" (click)="onValidate(sender, 'dkim')">{{'words.refresh' | translate}}</msc-button>
                </div>
            </ng-container>

            <ng-template #isConfiguredTemplate>
                <p class="description">{{ 'organize.experience.watch.mail.details.server-config.true.description' | translate }}</p>
                <div class="grouping margin-top-20">
                    <msc-common-led [isOn]="sender.ui.dkim.isSet" [label]="sender.ui.dkim.label"></msc-common-led>
                    <msc-button class="margin-top-20" palette="primary" (click)="goToList()">{{'words.close' | translate}}</msc-button>
                </div>
            </ng-template>
        </div>
    </ng-template>

    <msc-common-modal size="50vw" #modal [title]="sender.modal.title">
        <ng-template>
            <ng-container *ngIf="sender.modal.description">
                <div [innerHTML]="sender.modal.description"></div>
            </ng-container>
            <div class="flex items-center mt-5 space-x-5 justify-end">
                <msc-button class="" palette="primary" (click)="modal.onClose()">{{'common.cancel' | translate}}</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onDelete(modal, sender); modal.onClose()">{{'common.save' | translate}}</msc-button>
            </div>
        </ng-template>
    </msc-common-modal>
</ng-container>

<ng-template #errorTemplate>
    <!-- TODO -->
</ng-template>
