import { Component, OnInit } from '@angular/core';
import { AuthProvider } from '@lighty';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'msc-forgotten-password',
    templateUrl: './forgotten-password.component.html'
})
export class ForgottenPasswordComponent implements OnInit {
    public email: string;
    public sent: boolean = false;
    public loading: boolean = false;
    public errors: any = {
        email: false
    };

    constructor(private authProvider: AuthProvider, private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: any) => {
            this.email = params.email;
        });
    }

    navigate(): void {
        this.router.navigate(['login']);
    }

    submit(): void {
        const params = {
            email: this.email
        };

        this.loading = true;
        this.authProvider.sendForgottenPasswordRequest(params).subscribe(() => {
            this.loading = false;
            this.sent = true;
        }, () => {
            this.loading = false;
        });
    }
}
