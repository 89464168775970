<form [formGroup]="form">
    <div class="grouping">
        <msc-input type="text" [label]="'words.camp-name' | translate" formControlName="name"></msc-input>
    </div>

    <div class="grouping">
        <msc-input type="text" [label]="'settings.camp-description' | translate" formControlName="headline"></msc-input>
    </div>

    <div class="grouping">
        <label>{{ 'settings.camp-provider' | translate }}</label>
        <div class="wrapper">
            <msc-common2-radio [valueName]="true" [label]="'common.yes' | translate" formControlName="provider"></msc-common2-radio>
            <msc-common2-radio [valueName]="false" [label]="'common.no' | translate" formControlName="provider"></msc-common2-radio>
        </div>
    </div>

    <div class="grouping">
        <div class="flex items-center mt-5 space-x-5 justify-end group-button">
            <msc-button class="msc-button--secondary" palette="primary" icon="icon-trash-2" (click)="modalDeleteCamp.onOpen()">{{ 'settings.camp-delete' | translate }}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onSubmit()">{{ 'common.save' | translate }}</msc-button>
        </div>
    </div>
</form>

<msc-common-modal size="30vw" #modalDeleteCamp [title]="'settings.camp-delete' | translate">
    <ng-template>
        <p>{{ 'settings.camp-delete-confirmation' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end group-button">
            <msc-button palette="primary" (click)="modalDeleteCamp.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onDelete(); modalDeleteCamp.onClose()">{{'common.delete' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
