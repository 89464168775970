<div class="card card--progress card--hover-effect" (click)="navigateContent()">
    <div class="card__header">
        <div class="card__banner card__banner--container flex between">
            <div class="card__banner--container__picture" [style.backgroundImage]="logo"></div>
            <msc-tag [type]="'text'" [text]="'words.template' | translate"></msc-tag>
        </div>

        <div class="card__tags" [ngClass]="{'card__tags--banner': !template.nextEvent}">
            <msc-tag [type]="'date'" [day]="template.nextEvent | date: 'dd'" [month]="template.nextEvent | localizedDate: 'MMM'" *ngIf="template.nextEvent"></msc-tag>

            <!-- <msc-tag [type]="'text'" [text]="'words.template' | translate" *ngIf="!template.provider?.logo && !company.experience?.displayPicture"></msc-tag> -->
        </div>

        <div class="card__image">
            <div [ngStyle]="{'background-image': 'url(' + (template.picture ? (template.picture + '?size=512') : 'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + template.id % 7 + '.jpg') + ')'}"></div>
        </div>
    </div>

    <div class="card__body">
        <div class="card__time" *ngIf="template.totalDuration">
            <span class="card_time_text">{{ template.totalDuration | duration }}</span>
        </div>

        <div class="card__states card__states--registered" *ngIf="template.subscribed && template.state !== 'refused' && template.state !== 'pending'">
            <p><i class="icon icon-checkmark-circle text-primary-500 text-lg"></i> {{ 'words.already-subscribed' | translate }}</p>
        </div>

        <div class="card__skills" *ngIf="template.skills && template.skills.length > 0">
            <div class="skill skill--level skill--no-hover" *ngFor="let skill of template.skills | slice:0:1" [tooltip]="skill.name">
                <div class="skill__content">
                    <div class="skill__level">
                        <span [ngClass]="{'active': skill.level > 0}"></span>
                        <span [ngClass]="{'active': skill.level > 1}"></span>
                        <span [ngClass]="{'active': skill.level > 2}"></span>
                    </div>

                    <p class="skill__text">{{ skill.name | truncate: 10 }}</p>
                </div>
            </div>

            <div class="skill skill--more" *ngIf="template.skills.length > 1">
                <div class="skill__content">
                    <div class="skill__text"></div>
                </div>
            </div>
        </div>

        <p class="card__title">
            <ng-container *ngIf="template?.title?.length > 50; else titleTemplate">
                <span [tooltip]="template?.title">{{ template.title | truncate: 50 }}</span>
            </ng-container>
            <ng-template #titleTemplate>
                <span>{{ template.title || ('words.untitled' | translate) }}</span>
            </ng-template>
        </p>

        <p class="card__desc leading-3" [innerHTML]="template.description || ('words.undescribed-content' | translate) | truncate: 130"></p>
    </div>

    <div class="card__footer">
        <div class="card__actions">
            <div class="card__buttons flex space-x-2">
                <msc-button class="msc-button--primary" type="fab" palette="primary" icon="icon-search" [tooltip]="'words.details' | translate"></msc-button>
            </div>

            <div class="card__widgets">
                <a (click)="changeLearnLaterContent(); $event.stopPropagation()" [tooltip]="'words.learn-later' | translate" direction="left">
                    <i class="icon icon-bookmark" [ngClass]="{'icon-bookmark-1 color--orange' : saved, 'icon-bookmark' : !saved}"></i>
                </a>
            </div>
        </div>
    </div>
</div>
