import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminMemberProvider, Camp, Pagination } from '@lighty';
import { EmitterService } from '@services';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-camp-aside-invite-members',
    templateUrl: './aside-invite-members.component.html',
    styleUrls: ['./aside-invite-members.component.scss']
})
export class CampAsideInviteMembersComponent implements OnDestroy {
    @ViewChild('aside') aside;
    @ViewChild('memberChoice') memberChoice;
    @Input() camp: Camp;
    @Input() availableMembers: any;
    @Input() pagination: Pagination;
    @Input() loading: boolean = false;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    private visitedPages: string[] = [];
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public page: string = 'choice';
    public data: any;
    public onCloseByUser: EventEmitter<any> = new EventEmitter();

    constructor(private memberProvider: AdminMemberProvider, private emitterService: EmitterService) { }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    init(): void {
        this.page = 'choice';
        this.visitedPages.push(this.page);
        this.data = {
            account_ids: [],
            availableMembers: this.availableMembers,
            pagination: this.pagination,
            roles: {
                camp_learner: true,
                camp_administrator: false
            },
            permissions: {
                manage_camp_settings: false
            },
            send_mail: true,
            invite_all_accounts: false
        };
    }

    goToRights() {
        this.page = 'rights';
        this.visitedPages.push('rights');
    }

    onOpen() {
        this.init();
        this.aside.onOpen();
    }

    breadcrumb(destination: string): void {
        const validated = this.visitedPages.indexOf(destination);

        if (validated !== -1) {
            this.page = destination;
        }
    }

    isDisabled(name: string): boolean {
        return !(this.visitedPages.indexOf(name) > -1);
    }

    close(): void {
        this.onClose.emit(true);
    }

    prevPanel(): void {
        this.page = 'choice';
    }

    save(): void {
        this.onSave.emit(this.data);
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }
}
