<div class="content-catalogue-calendar">
    <div class="container-catalogue-calendar__section animate" *ngFor="let event of events; let index = index">
        <h3 class="container-catalogue-calendar__subtitle text-primary-500" [ngClass]="{'color--white' : type === 'catalogue' && index === 0}">
            <span>{{ event.date | date: 'dd MMMM YYYY' }}</span>
        </h3>

        <div class="container-catalogue-calendar__list">
            <div class="container-catalogue-calendar__item" *ngFor="let item of event.items" (click)="redirect(item)">
                <div class="block-details-list-hour">
                    <div class="block-details-list-hour__date">
                        <p>
                            {{ (item.event ? item.event.startAt : item.start) | date: 'HH' }}<span>{{ (item.event ? item.event.endAt : item.end) | date: 'HH' }}</span>
                        </p>
                    </div>

                    <div class="block-details-list-hour__content">
                        <div class="block-details-list-hour__title">
                            <i *ngIf="!item.isTraject" class="icon icon-cube color--orange"></i>
                            <i *ngIf="item.isTraject" class="icon icon-trending-up color--orange"></i>

                            <p [tooltip]="item.title.length > 20 ? item.title : ''">
                                {{ item.title | truncate : 20 }}
                            </p>
                        </div>

                        <div class="block-details-list-hour__desc hidden-xs">
                            {{ item.eventTitle || item.event.name }}
                        </div>

                        <div class="block-details-list-hour__infos hidden-sm">
                            <p *ngIf="item.address">
                                <i class="icon icon-pin"></i>
                                {{ item.address }}
                            </p>

                            <p *ngIf="item.event?.address">
                                <i class="icon icon-pin"></i>
                                {{ item.event.address }}
                            </p>

                            <p *ngIf="!item.address && !item.event?.address">
                                {{ 'words.empty-adress' | translate }}
                            </p>
                        </div>

                        <div class="block-details-list-hour__action">
                            <i class="icon icon-arrow-ios-right text-secondary-500"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
