import { Component, Input, TrackByFunction, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { CommonOverlayComponent } from '../overlay/overlay.component';

export interface CommonListDatum<T> {
    children?: Array<CommonListDatum<T>>;
    id?: number | string;
    icon?: string;
    image?: string;
    expanded?: boolean;
    isSelected?: boolean;
    label: string;
    value: T;
}

export interface CommonListSelection<T> extends CommonListDatum<T> {
    selectionModel?: Map<string, CommonListSelection<T>>
}

@Component({
    selector: 'msc-common-list2',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})

export class CommonListComponent2<T> {
    @Input() data: Array<CommonListDatum<T>> = []; // data of list
    @Input() selectionActive = false;
    @Input() type = 'checkbox'; // 'checkbox' | 'checkmark'
    @Input() expandWithOverlay = true;
    @Input() itemsModel;
    @Input() root = false;
    @ViewChildren(CommonOverlayComponent) overlayList: QueryList<CommonOverlayComponent>;
    @Input() selectionModel: Map<string, CommonListSelection<T>>;
    @Output() selectionModelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() treeUpdate: EventEmitter<any> = new EventEmitter<any>();
    init = false;

    constructor() { }

    ngOnInit() {
        if (this.type === 'checkmark') {
            const toUpdate = this.data.find(el => el.id === this.itemsModel?.id);
            if (toUpdate) {
                this.onSelect(this.selectionModel, toUpdate, true);
            }
        } else {
            this.itemsModel?.forEach(existingModel => {
                const toUpdate = this.data.find(el => el.id === existingModel.id);
                if (toUpdate) {
                    this.onSelect(this.selectionModel, toUpdate, true);
                }
            });
        }
        this.init = true;
    }
    public onTrackByIndex: TrackByFunction<CommonListDatum<T>> = (i: number) => i;

    isSelected(item: CommonListDatum<T>) {
        if (item.children?.length) {
            return this.selectionModel?.get(`${item.id}`)?.selectionModel.size === item.children?.length;
        } else {
            return this.selectionModel?.has(`${item.id}`)
        }
    }

    isIndeterminate(item: CommonListDatum<T>) {
        if (!this.isSelected(item) && this.selectionModel?.has(`${item.id}`))
            return item.children?.length && this.selectionModel.get(`${item.id}`).selectionModel.size > 0;
        else
            return false;
    }

    onSelect(model: Map<string, CommonListSelection<T>>, selection: CommonListDatum<T>, value, init?) {
        if (this.type === 'checkmark') {
            this.selectionModel.clear();
        }
        if (!model && init) {
            this.selectionModel = new Map<string, CommonListSelection<T>>();
            model = this.selectionModel;
        }
        if (value) {
            model.set(`${selection.id}`, { ...selection, ...(selection.children?.length && { selectionModel: new Map<string, CommonListSelection<T>>() }) });
            if (selection.children?.length) {
                selection.children.forEach((child) => {
                    this.onSelect(model.get(`${selection.id}`).selectionModel, child, true);
                })
            }
        } else {
            this.selectionModel.delete(`${selection.id}`);
            if (this.selectionModel.size === 0) {
                this.selectionModel = undefined;
            }
        }
        if (!this.root) {
            this.selectionModelChange.emit(this.selectionModel);
        } else {
            if (this.init) {
                this.selectionModelChange.emit(this.selectionModel);
            }
        }
    }

    updateSelectionModel(datum, selectionModel) {
        if (!selectionModel) {
            this.selectionModel = selectionModel;
        } else {
            if (!this.selectionModel) {
                this.selectionModel = new Map<string, CommonListSelection<T>>();
            }
            if (this.selectionModel.has(datum.id)) {
                this.selectionModel.get(datum.id).selectionModel = selectionModel;
            } else {
                this.selectionModel.set(`${datum.id}`, { ...datum, selectionModel: selectionModel })
            }
        }
        this.selectionModelChange.emit(this.selectionModel);

    }

    openTree(datum: CommonListDatum<T>, overlay: CommonOverlayComponent, origin) {
        this.data.forEach((el) => el.expanded = false);
        datum.expanded = true;
        if (this.expandWithOverlay) {
            this.overlayList.forEach((overlay) => overlay.close());
            this.treeUpdate.emit(overlay.open(origin));
        }
    }
}
