<div class="min-h-full flex flex-col">
    <div class="container-sessions__row row flex flex-col grow">
        <div class="container-sessions__back" (click)="back()">
            <a class="link link-back link-back--transparent">
                <span>{{ 'manage.instance.all' | translate }}</span>
                <i class="icon icon-arrow-ios-up"></i>
            </a>
        </div>

        <header class="container-sessions__header">
            <div class="blocks-header-sessions">
                <div class="blocks-header-sessions__title">
                    <div class="blocks-header-sessions__bookmark" (click)="changeInstanceFavorite()">
                        <div class="bookmark bookmark--large" [ngClass]="{'bookmark--active': instance.favorite}">
                            <i class="bookmark__star icon icon-star-1"></i>
                        </div>
                    </div>

                    <div class="blocks-header-sessions__name">
                        <i [attr.class]="getIcon(instance.type)" *ngIf="!instance.provider"></i>

                        <img [src]="getLogo()"  *ngIf="instance.provider">

                        <h3>{{ (instance.title | truncate: 40) || ('words.untitled' | translate) }}</h3>
                    </div>
                </div>

                <div class="blocks-header-sessions__actions">
                    <div *ngIf="lastChange">
                        <a class="text-indigo-600 underline" (click)="changelogAside.onOpen();">Last edit {{lastChange | lowercase}}</a>
                    </div>
                    <div class="blocks-header-sessions__counts">
                        <p *ngIf="instance.nextEvent">
                            <i class="icon icon-calendar"></i>
                            {{ instance.nextEvent | date:'dd' }} {{ instance.nextEvent | date:'dd' }}
                        </p>

                        <p>
                            <i class="icon icon-person"></i>
                            {{ instance.studentsCount > 0 ? instance.studentsCount : 0 }}
                        </p>
                    </div>

                    <div class="blocks-header-sessions__dropdown">
                        <msc-common-select class="rounded-[14px] text-xs h-7"
                            [ngClass]="'status-' + instance.status" [data]="statusOptions" [isButton]="true">
                            <ng-template #buttonTemplate let-data="data">
                                <span>{{ ('manage.instance.status-' + instance.status) | translate }}</span>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <ng-container *ngIf="instance.status !== datum.value">
                                    <span class="rounded-[14px] py-2 px-2 my-2 text-xs" [ngClass]="'status-' + datum.value"
                                        (click)="changeInstanceStatus(datum.value)">{{ datum.label }}</span>
                                </ng-container>
                            </ng-template>
                        </msc-common-select>
                    </div>

                    <div class="blocks-header-sessions__switch" *ngIf="instance.type !== 'external_content'">
                        <msc-common2-switch [value]="instance.archived" [label]="'words.hide' | translate" (changeEvent)="archiveInstance()"></msc-common2-switch>
                    </div>
                </div>
            </div>
        </header>

        <div class="container-sessions__main row grow flex">
            <aside class="container-sessions__aside  col-md-3 col-3">
                <div class="navigations-sessions">
                    <ul class="navigations-content">
                       <!-- <li class="navigations-content__item">
                           <p>{{ 'words.overview' | translate }}</p>
                           <p class="navigations-content__notifications">Soon</p>
                       </li> -->

                       <!-- <li class="navigations-content__item" [routerLink]="['dashboard']" [routerLinkActive]="'active'">
                           <p>{{ 'words.overview' | translate }}</p>
                       </li> -->

                       <!-- <li class="navigations-content__item" [routerLink]="['actualities']" [routerLinkActive]="'active'">
                           <p>{{ 'words.news' | translate }}</p>
                       </li> -->

                        <li class="navigations-content__item" (click)="displayed('dynamic')"
                            [ngClass]="{'displayed': displays.dynamic, 'active': isActivatedRoute(dynamicMenu[instance.status])}" *ngIf="instance.status">
                            <p>{{ 'manage.instance.status-' + instance.status | translate }}</p>

                            <a class="toggle">
                                <i class="icon icon-arrow-ios-down text-secondary-500"></i>
                            </a>
                        </li>

                        <li class="navigations-sub" *ngIf="instance.status">
                            <ul>
                                <ng-container *ngFor="let menu of dynamicMenu[instance.status]">
                                    <ng-container *ngIf="menu.display">
                                        <li class="navigations-sub__item" [routerLink]="menu.route" [routerLinkActive]="'active'">
                                            <p>
                                                <i [attr.class]="'icon ' + menu.icon"></i>
                                                {{ menu.translate | translate }}
                                            </p>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </li>

                        <li class="navigations-content__item" (click)="displayed('more')"
                            [ngClass]="{'displayed': displays.more, 'active': isActivatedRoute(moreMenu[instance.status])}" *ngIf="instance.status && moreMenu[instance.status].length > 0 && instance.type !== 'external_content'">
                            <p>{{ 'words.more-information' | translate }}</p>

                            <a class="toggle">
                                <i class="icon icon-arrow-ios-down"></i>
                            </a>
                        </li>

                        <li class="navigations-sub" *ngIf="instance.status">
                            <ul>
                                <li class="navigations-sub__item" [routerLink]="menu.route" [routerLinkActive]="'active'" *ngFor="let menu of moreMenu[instance.status]" [ngClass]="{'display-none': !menu.display}">
                                    <p>
                                        <i [attr.class]="'icon ' + menu.icon"></i>
                                        {{ menu.translate | translate }}
                                    </p>
                                </li>
                            </ul>
                        </li>

                        <li class="navigations-content__item" (click)="displayed('admin')" [ngClass]="{'displayed': displays.admin, 'active': currentRoute === 'settings' || currentRoute === 'notifications'}">
                            <p>{{ 'manage.instance.admin' | translate }}</p>

                            <a class="toggle">
                                <i class="icon icon-arrow-ios-down"></i>
                            </a>
                        </li>

                        <li class="navigations-sub">
                            <ul>
                                <li class="navigations-sub__item" [routerLink]="['settings']"
                                    [routerLinkActive]="'active'">
                                    <p>
                                        <i class="icon icon-settings-2"></i>
                                        {{ 'words.settings' | translate }}
                                    </p>
                                </li>

                               <!-- <li class="navigations-sub__item" [routerLink]="['notifications']"
                                   [routerLinkActive]="'active'">
                                   <p>
                                       <i class="icon icon-bell"></i>
                                       {{ 'words.notifications' | translate }}
                                   </p>

                                   <p class="navigations-sub__item__notifications">0</p>
                               </li> -->
                            </ul>
                        </li>
                    </ul>
                </div>
            </aside>

            <div class="container-sessions__body col-xxs-12 col-md-9 col-9 no-gutter row min-h-full">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<msc-learning-path-changelog #changelogAside [learningPath]="instance"></msc-learning-path-changelog>
