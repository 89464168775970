import { Injectable } from '@angular/core';
import { HttpHelper } from '@helpers';
import { Pagination } from '@lighty';

/**
 * Params Service
 */
@Injectable({ providedIn: 'root' })
export class ParamsService {
    /** Init
     * @constructor
     */
    constructor() { }

    /**
     * Get params for content and catalogue
     * @param {any} filters - Filters collection
     * @param {number} companyId - Company's id
     * @param {string} query - Query
     * @param {Pagination} pagination - Pagination
     * @param {any} options - Sup options
     * @param {any} me - Current User
     * @return {any} params - Cleaned params
     */
    getCatalogue(filters: any, companyId: number, query: string, pagination?: Pagination, options?: any, me?: any): any {
        return HttpHelper.cleanParams({
            company_id: companyId,
            include: options && options.mode === 'calendar' ? 'attendees' : null,
            user_course: options && options.mode === 'calendar' ? 1 : null,
            browse: options && options.browse ? options.browse : null,
            q: query,
            page: pagination && pagination.currentPage ? pagination.currentPage + 1 : null,
            perPage: pagination && pagination.perPage ? pagination.perPage : null,
            langs: filters && filters.langs ? filters.langs.join(',') : null,
            order: filters ? filters.order : null,
            providers: filters && filters.providers ? filters.providers.join(',') : null,
            event_dates: filters && filters.dates && filters.dates.start && filters.dates.end ? [filters.dates.start, filters.dates.end].toString() : null,
            course_types: filters && filters.courseTypes && filters.courseTypes.length > 0 ? filters.courseTypes.join() : null,
            external_content_types: filters && filters.externalContentTypes && filters.externalContentTypes.length > 0 ? filters.externalContentTypes.join() : null,
            external_resource_types: filters && filters.externalResourceTypes && filters.externalResourceTypes.length > 0 ? filters.externalResourceTypes.join() : null,
            content_types: filters && filters.contentTypes && filters.contentTypes.length > 0 ? filters.contentTypes.join() : null,
            durations: filters && filters.slider ? [filters.slider.values.min, filters.slider.values.max].toString() : null,
            camp_id: filters ? filters.camp : null,
            'skills[]': filters && filters.skill && filters.skill.id ? [filters.skill.id] : null,
            'skill_level[]': filters && filters.skill && filters.skill.level.length > 0 ? filters.skill.level : null
        });
    }

    getOnboarding(filters: any, query: string, campIds: any[], pagination?: Pagination): any {
        return HttpHelper.cleanParams({
            q: query,
            page: pagination && pagination.currentPage ? pagination.currentPage + 1 : null,
            perPage: pagination && pagination.perPage ? pagination.perPage : null,
            langs: filters && filters.langs && filters.langs.length > 0 ? filters.langs.join(',') : null,
            order: filters ? filters.order : null,
            providers: filters && filters.providers && filters.providers.length > 0 ? filters.providers.join(',') : null,
            course_types: filters && filters.courseTypes && filters.courseTypes.length > 0 ? filters.courseTypes.join() : null,
            content_types: filters && filters.contentTypes && filters.contentTypes.length > 0 ? filters.contentTypes.join() : null,
            external_content_types: filters && filters.externalContentTypes && filters.externalContentTypes.length > 0 ? filters.externalContentTypes.join() : null,
            external_resource_types: filters && filters.externalResourceTypes && filters.externalResourceTypes.length > 0 ? filters.externalResourceTypes.join() : null,
            camp_ids: campIds ? campIds.join(',') : null
        });
    }
}
