import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { AdminGroupProvider, Camp } from '@lighty';
import { StorageService, TranslateService } from '@services';

@Component({
    selector: 'msc-camp-aside-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class CampAsideSettingsComponent {
    @ViewChild('aside') aside;
    @Input() camp: Camp;
    public currentGroup: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public company;
    public profileMedia: any;
    public backgroundMedia: any;
    public loadProfile: boolean = false;
    public loadBackground: boolean = false;
    public visible: boolean = true;
    public public: boolean = true;
    public closeByUser: EventEmitter<any> = new EventEmitter();

    constructor(
        private toastService: CommonToastService,
        private groupProvider: AdminGroupProvider,
        private storageService: StorageService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.company = this.storageService.get('company');

        if (this.currentGroup.media && this.currentGroup.media.pictureUrl) {
            this.profileMedia = this.currentGroup.media.pictureUrl + '?size=128';
        }

        if (this.currentGroup.background && this.currentGroup.background.pictureUrl) {
            this.backgroundMedia = this.currentGroup.background.pictureUrl + '?size=128';
        }
    }

    public onOpen(group?) {
        this.currentGroup = group;
        this.init();
        this.aside.onOpen();
    }

    updateProfileMedia(event: any): void {
        this.loadProfile = true;
        if (event.type === 'finished') {
            this.loadProfile = false;
            if (!this.currentGroup.media) { this.currentGroup.media = {}; }
            this.currentGroup.media.id = event.value.id;
            this.profileMedia = event.value.pictureUrl + '?size=128';
        }

        if (event.type === 'error') {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    updateBackgroundMedia(event: any): void {
        this.loadBackground = true;
        if (event.type === 'finished') {
            this.loadBackground = false;
            if (!this.currentGroup.background) { this.currentGroup.background = {}; }
            this.currentGroup.background.id = event.value.id;
            this.backgroundMedia = event.value.pictureUrl + '?size=128';
        }

        if (event.type === 'error') {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    getUpdateParams(datum: any) {
        return Object.keys(datum).reduce((acc, key) => {
            if (key === 'media') {
                return { ...acc, mediaId: datum[key].id }
            }
            if (key === 'background') {
                return { ...acc, backgroundId: datum[key].id }
            }
            return { ...acc, [key]: datum[key] };
        }, {});
    }

    updateGroupSettings(): void {
        const params = {
            include: 'account,media'
        };
        this.groupProvider.updateGroup(this.company.id, this.currentGroup.id, this.getUpdateParams(this.currentGroup), params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        });
    }

    setPublic(value: string): void {
        this.currentGroup.subscriptionType = value;
    }

    setVisible(value: boolean): void {
        this.currentGroup.timelineVisibility = value;
    }

    close(refresh?: boolean): void {
        this.onClose.emit(refresh);
    }
}
