import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { KnowledgeProvider, KnowledgeSkill, Company } from '@lighty';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-organize-knowledge-aside-merge',
    templateUrl: './merge-skill.component.html'
})
export class OrganizeKnowledgeAsideMergeComponent implements OnInit {
    @ViewChild('aside') aside;
    @Input() company: Company;
    @Input() selectedSkills: KnowledgeSkill[];
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public skills: KnowledgeSkill[];
    public query: string;
    public loading: boolean = false;
    public activeSkill: number;

    constructor(
        private knowledgeProvider: KnowledgeProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.search();
    }

    public onOpen(){
        this.aside.onOpen();
    }

    search(query?): void {
        this.query = query;
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            order_by: 'total_usage',
            order_way: 'desc'
        });

        this.knowledgeProvider.get(this.company.slug, params).subscribe((data) => {
            this.skills = this.filtering(data.skills);
            this.loading = false;
        });
    }

    removeSkill(index: number): void {
        this.selectedSkills.splice(index, 1);
        if (this.selectedSkills.length === 0) {
            this.closeByUser.emit(true);
        }
        this.search();
    }

    private filtering(skills: KnowledgeSkill[]): KnowledgeSkill[] {
        return skills.filter((skill) => {
            return this.selectedSkills.findIndex((data) => {
                return data.id === skill.id;
            }) === -1 && skill.visible;
        });
    }

    selectSkill(id: number): void {
        this.activeSkill = this.activeSkill === id ? null : id;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (this.selectedSkills.length > 0 && this.activeSkill) {
            const params = {
                source_ids: this.selectedSkills.map((skill) => skill.id),
                destination_id: this.activeSkill,
                company_id: this.company.id
            };

            this.knowledgeProvider.merge(params).subscribe(() => {
                this.onSave.emit();
                this.closeByUser.emit();
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            });
        }
    }
}
