import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'msc-organize-integration-watch-rules-edit-name-panel',
    templateUrl: './name-panel.component.html'
})
export class OrganizeIntegrationWatchRulesEditNamePanelComponent implements OnInit {
    @Input() rule: any;
    @Input() allowNextNavigation: boolean;
    @Output() allowNextNavigationChange: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        this.onInputChange();
    }

    onInputChange(): void {
        this.allowNextNavigation = !!this.rule.name;
        this.allowNextNavigationChange.emit(this.allowNextNavigation);
    }
}
