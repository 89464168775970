


<div class="sidebar-header" >
    <span style="margin-left: 0.2rem;">{{ 'words.menu' | translate | uppercase }}</span>
    <msc-button #btn class="msc-button--secondary" palette="secondary" type="fab" icon="icon-arrow-ios-left"
        (click)="toggle(btn)" ></msc-button>
</div>

<div class="sidebar-header hidden md:hidden lg:hidden sidebar-header" >
    <!-- pin vinculado con header==  -->
    <div class="hidden md:hidden lg:hidden" style="margin-top: -1.5rem; margin-left: 0.2rem;" *ngIf="currentJob === 'organize'">
       <msc-button icon="icon-pin-2" palette="primary" type="fab" [tooltip]="'words.pin-content' | translate"
           direction="bottom" (click)="addPinnedContentAside.onOpen()" style="margin-top: -1.5rem; margin-left: 0.2rem;"></msc-button>
    </div>
</div>

<ul class="sidebar-content" *ngIf="menu">
    <ng-container *ngFor="let item of menu[currentJob]">
        <!-- only for organize -->
        <ng-container *ngIf="(!isMobileView || item.isMobile) && currentJob == 'organize'">
            <li *ngIf="!item.hidden" [routerLink]="item.routerLink" [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="item.routerOptions || {}">
                <i class="icon {{item.icon}}"></i>
                <span>{{ item.label | translate }}</span>
                <div class="helper" [tooltip]="item.label | translate" direction="right"></div>
            </li>
        </ng-container>
        <!-- rest of sections -->
        <ng-container *ngIf="currentJob != 'organize'">
            <li *ngIf="!item.hidden" [routerLink]="item.routerLink" [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="item.routerOptions || {}">
                <i *ngIf="item.label != 'Boclips'" class="icon {{item.icon}}"></i>
                <svg
                class="icon"
                style="margin-right: 5px;"
                *ngIf="item.label == 'Boclips'"
                id="Boclips"
                width="24"
                height="20"
                viewBox="0 0 24 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:svg="http://www.w3.org/2000/svg">
                <defs
                    id="defs15" />
                <path
                    id="Path_5085"
                    data-name="Path 5085"
                    d="m 27.429126,13.567345 h 3.604556 a 0.96631666,0.96631666 0 0 0 0.966316,-0.966317 V 5.2622097 A 0.96761373,0.96761373 0 0 0 31.169874,4.3049725 L 1.1076976,0.01037589 A 0.96631666,0.96631666 0 0 0 0.01037698,0.82623251 0.94296942,0.94296942 0 0 0 4.2124281e-7,0.96761307 V 8.0301557 A 0.96501959,0.96501959 0 0 0 0.96631709,8.9951753 H 22.856956 L 0.77824203,13.410399 A 0.98058443,0.98058443 0 0 0 4.2124281e-7,14.370231 V 30.944183 A 0.97409908,0.97409908 0 0 0 0.97539657,31.918282 0.93907821,0.93907821 0 0 0 1.1180742,31.907905 L 31.169874,27.400589 a 0.96761373,0.96761373 0 0 0 0.830124,-0.958534 v -7.534676 a 1.8612945,1.8612945 0 0 0 -0.14138,-0.709497 v 0 a 1.8574033,1.8574033 0 0 0 -0.403389,-0.604434 z"
                    fill="#eafcf6"
                    style="fill:#6b7280;fill-opacity:1;stroke-width:1.29707" />
                </svg>
                <span>{{ item.label | translate }}</span>
                <div class="helper" [tooltip]="item.label | translate" direction="right"></div>
            </li>
        </ng-container>
    </ng-container>

    <!--Just for Corona-->
    <li *ngIf="currentJob == 'learn' && company.id == 1843" (click)="openCoronaSiemens()">
        <i class="icon icon-external-link"></i>
        <span>{{ 'sidebar.corona.siemens' | translate }}</span>
        <div class="helper" [tooltip]="'sidebar.corona.siemens' | translate" direction="right"></div>
    </li>

    <div *ngIf="isOnboarding">
        <div class="flex space-x-2 align-center justify-center bg-gray-100 p-4 overflow-hidden" (click)="onboarding()">
            <i class="icon icon-lock text-primary-500"></i>

            <p [hidden]="isCollapsed" class="text-sm"><i>{{ 'onboarding.lock' | translate }}</i></p>
        </div>
    </div>
</ul>
