<div class="content-settings">
    <div class="content-settings__main m-3">
        <div class="block-company-settings">
            <div class="block-company-settings__header">
                <h1>{{ 'words.settings.advanced' | translate }}</h1>
            </div>
            <div class="block-company-settings__content" *ngIf="company.permissions">
                <div class="block-company-settings__advanced-setting">
                    <h3>{{ 'words.feature.session-lock.name' | translate }}</h3>
                    <p>{{ 'words.feature.session-lock.description' | translate }}</p>
                    <msc-button class="msc-button--primary" palette="secondary" (click)="modalEnable.onOpen()" *ngIf="!company.permissions.companyLockSession">{{ 'words.feature.activate' | translate }}</msc-button>
                    <div class="block-company-settings__advanced-setting__status" *ngIf="company.permissions.companyLockSession">
                        <i class="icon icon-checkmark-circle-3"></i>
                        <span>{{ 'words.feature.activated' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<msc-common-modal size="30vw" #modalEnable [title]="'words.feature.session-lock.undone.warning' | translate">
    <ng-template>
        <div class="block-company-settings__advanced-modal">
            <p>{{ 'words.feature.session-lock.confirm.consent' | translate }}</p>
            <div class="tip">
                <span>
                    <b>💡{{ 'words.tip' | translate }}:</b>
                    {{ 'words.feature.session-lock.confirm.consent-2' | translate }}
                </span>
            </div>
        </div>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalEnable.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="addPermission('companyLockSession'); modalEnable.onClose()">{{'common.confirm' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
