import { Component, ChangeDetectionStrategy, SkipSelf, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { ControlContainer } from '@angular/forms';
//
import { UuidPipe } from '@common2/pipes/uuid.pipe';

@Component({
    selector: 'msc-common2-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UuidPipe],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ],
})

export class CommonSwitchComponent {
    @Input() controlName?: string; // form control name
    @Input() isDisabled: boolean = false; // non-form property
    @Input() label?: string;
    @Input() value: boolean; // non-form property
    @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();
    @HostBinding('class.reverse') @Input() startWithLabel: boolean;

    public id: string = this.uuidPipe.transform();

    constructor(
        private readonly uuidPipe: UuidPipe,
    ) { }

    /**
     * Event handler for change
     */
    onChange(event: Event): void {
        event.stopPropagation();
        this.changeEvent.emit((event.target as HTMLInputElement).checked);
    }
}
