<!-- branding -->
<section class="head">
    <h3>{{ 'words.experiences' | translate }}</h3>

    <div class="flex items-center justify-between gap-2">
        <!--search-->
        <div class="w-full 2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2">
            <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)" style=" margin-right:20px;">
            </msc-common-search>
        </div>

        <msc-button class="msc-button--primary" palette="secondary" icon="icon-plus"
            (click)="openModalCreateExperience(modalCreateExperience)">
            {{ 'words.create' | translate }}
        </msc-button>
    </div>
</section>

<section class="body">
    <div class="content">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async"
                        (onScrollEnd)="hasLoadMore ? onLoad() : null">
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <span [tooltip]="datum.name" class="cursor-pointer hover:underline"
                                (click)="editExperience(datum.id)">{{ datum.name | truncate: 35 }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="picture" [style.backgroundImage]="datum.pictureUrl"></div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span [tooltip]="datum.displayName">{{ datum.displayName | truncate: 30 }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="wrapper">
                                <i class="icon icon-people"></i>
                                <div>{{ datum.usersCount }}</div>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-button element #origin="element" type="fab" icon="icon-more-horizontal"
                                (click)="experienceOptions.open(origin.elementRef)">
                            </msc-button>

                            <msc-common-overlay-menu #experienceOptions [menuItems]="datum.experienceMenu" direction="bottom-end">
                            </msc-common-overlay-menu>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'common.empty' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <div class="flex justify-center">
                    <msc-common-spinner></msc-common-spinner>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="(isLoadingMore$ | async) === 'loading'">
        <div class="flex justify-center">
            <msc-common-spinner></msc-common-spinner>
        </div>
    </ng-container>
</section>

<msc-common-modal size="30vw" #modalCreateExperience [title]="'experience.create' | translate">
    <ng-template>
        <msc-input [label]="'experience.name' | translate" name="input" id="experienceName"
            [(ngModel)]="experienceName"></msc-input>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalCreateExperience.onClose()">{{'common.cancel' |
                translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary"
                (click)="createExperience(); modalCreateExperience.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalRenameExperience [title]="'words.rename' | translate">
    <ng-template>
        <msc-input [label]="'experience.name' | translate" name="input" id="experienceRename"
            [(ngModel)]="experienceName"></msc-input>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalRenameExperience.onClose()">{{'common.cancel' |
                translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary"
                (click)="renameExperience(); modalRenameExperience.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalDeleteExperience [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'experience.delete.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDeleteExperience.onClose()">{{'common.cancel' |
                translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary"
                (click)="deleteExperience(); modalDeleteExperience.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>




<!-- <div class="content-experience row">
    <h3 class="content-experience__title col-12">
        {{ 'words.experiences' | translate }}
    </h3>

    <div class="content-experience__menu col-12">
        <div class="menu-action">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

            <div class="menu-action__buttons">
                <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" icon="icon-plus" (click)="openModalCreateExperience(modalCreateExperience)">
                    {{ 'words.create' | translate }}
                </msc-button>

                <div class="navigations__dropdown" *ngIf="demo">
                    <div class="dropdown-actions dropdown-invitation-users">
                        <div class="dropdown-actions__selected">
                            <i class="icon icon-more-horizontal"></i>
                        </div>

                        <div class="dropdown-actions__content">
                            <ul class="dropdown-actions__list">
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-experience__list animate col-12">
        <div class="dynamic-table">
            <div class="dynamic-table__head">
                <div class="dynamic-table__cell cell-title cell-title--small">
                    {{ 'words.name' | translate }}
                </div>

                <div class="dynamic-table__cell">
                    {{ 'words.logo' | translate }}
                </div>

                <div class="dynamic-table__cell">
                    {{ 'words.space-name' | translate }}
                </div>

                <div class="dynamic-table__cell">
                    {{ 'words.users' | translate }}
                </div>

                <div class="dynamic-table__cell cell-actions"></div>
            </div>

            <div class="dynamic-table__body">
                <div class="dynamic-table__row row-radius row-shadow" *ngFor="let experience of experiences; let index=index">
                    <div class="dynamic-table__cell cell-title cell-title--small">
                        {{ experience.name | truncate: 35 }}
                    </div>

                    <div class="dynamic-table__cell">
                        <div *ngIf="experience.displayPicture" class="cell-picture" [style.backgroundImage]="'url(' + experience.displayPicture + '?size=256)'"></div>
                    </div>

                    <div class="dynamic-table__cell">
                        {{ experience.displayName | truncate: 30 }}
                    </div>

                    <div class="dynamic-table__cell cell-with-icon">
                        <i class="icon icon-people"></i>

                        <div>{{ experience.usersCount }}</div>
                    </div>

                    <div class="dynamic-table__cell cell-actions">
                        <div class="cell-details" (click)="editExperience(experience.id)">
                            {{ 'words.see-details' | translate }}
                        </div>

                        <div class="dropdown-actions" [ngClass]="{'active': displaysActionDropdown[index]}" [(autoClose)]="displaysActionDropdown[index]">
                            <div class="dropdown-actions__selected" (click)="displayedActionDropdown(index)">
                                <i class="icon icon-more-horizontal"></i>
                            </div>

                            <div class="dropdown-actions__content">
                                <ul class="dropdown-actions__list">
                                    <li class="dropdown-actions__item" (click)="openModalRename(modalRenameExperience, experience); displayedActionDropdown(index)">
                                        <a>
                                            <i class="icon icon-edit-2"></i>
                                            {{ 'words.rename' | translate }}
                                        </a>
                                    </li>

                                    <li class="dropdown-actions__item" (click)="editExperience(experience.id)">
                                        <a>
                                            <i class="icon icon-edit"></i>
                                            {{ 'words.edit' | translate }}
                                        </a>
                                    </li>

                                    <li class="dropdown-actions__item dropdown-actions__item--trash" (click)="openModalDeleteExperience(modalDeleteExperience, experience); displayedActionDropdown(index)">
                                        <a>
                                            <i class="icon icon-trash"></i>
                                            {{ 'words.delete' | translate }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
    </div>
</div>


<msc-common-modal size="30vw" #modalCreateExperience [title]="'experience.create' | translate">
    <ng-template>
        <msc-input [label]="'experience.name' | translate" name="input" id="experienceName" [(ngModel)]="experienceName"></msc-input>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalCreateExperience.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="createExperience(); modalCreateExperience.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalRenameExperience [title]="'words.rename' | translate">
    <ng-template>
        <msc-input [label]="'experience.name' | translate" name="input" id="experienceRename" [(ngModel)]="experienceName"></msc-input>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalRenameExperience.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="renameExperience(); modalRenameExperience.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalDeleteExperience [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'experience.delete.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDeleteExperience.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteExperience(); modalDeleteExperience.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal> -->
