<div class="content-camps">
    <div class="content-corrections-temp">
        <div class="content-corrections-temp__actions">
            <div class="action-correction" [ngClass]="{'active': isTab('question')}" (click)="setTab('question')">
                {{ 'words.questions' | translate }}
            </div>

            <div class="action-correction" [ngClass]="{'active': isTab('project')}" (click)="setTab('project')">
                {{ 'words.projects' | translate }}
            </div>

            <div class="action-correction" [ngClass]="{'active': isTab('selfassessment')}" (click)="setTab('selfassessment')">
                {{ 'words.self-assessments' | translate }}
            </div>
        </div>

        <div class="content-corrections-temp__list">
            <div class="list-accounts col-4 col-md-4" *ngIf="isTab('question') && counter.question > 0">
                <div class="box" *ngFor="let question of questions" (click)="selectItem('question', question)" [ngClass]="{'active': question.id === currentQuestion?.id}">
                    <div class="avatar-user">
                        <msc-common-avatar [label]="question.account?.name" [pictureURL]="question.account?.picture ? (question.account?.picture + '?size=64') : null"></msc-common-avatar>
                        <div class="avatar__content">
                            <p class="profile__name">{{ question.account.name }}</p>
                        </div>
                    </div>

                    <div class="title">{{ question.title | truncate: 30}}</div>

                    <div class="time">{{ question.createdAt | timeAgo }}</div>
                </div>

                <div class="list-load-more">
                    <msc-load-more [loading]="loading" [pagination]="getCurrentPagination()" (onLoad)="loadMore()"></msc-load-more>
                </div>
            </div>

            <div class="list-accounts col-4 col-md-4" *ngIf="isTab('project') && counter.project > 0">
                <div class="box" *ngFor="let project of projects" (click)="selectItem('project', project)" [ngClass]="{'active': project.id === currentProject?.id}">
                    <div class="avatar-user">
                        <msc-common-avatar [label]="project.account?.name" [pictureURL]="project.account?.picture ? (project.account?.picture + '?size=128') : null"></msc-common-avatar>

                        <div class="avatar__content">
                            <p class="profile__name">{{ project.account.name }}</p>
                        </div>
                    </div>

                    <div class="title">{{ project.project.title | truncate: 30}}</div>

                    <div class="time">{{ project.createdAt | timeAgo }}</div>
                </div>

                <div class="list-load-more">
                    <msc-load-more [loading]="loading" [pagination]="getCurrentPagination()" (onLoad)="loadMore()"></msc-load-more>
                </div>
            </div>

            <div class="list-accounts col-4 col-md-4" *ngIf="isTab('selfassessment') && counter.selfassessment > 0">
                <div class="box" *ngFor="let selfassessment of selfassessments" (click)="selectItem('selfassessment', selfassessment)" [ngClass]="{'active': selfassessment.id === currentSelfAssessment?.id}">
                    <div class="avatar-user">
                        <msc-common-avatar [label]="selfassessment.account?.name" [pictureURL]="selfassessment.account?.picture ? (selfassessment.account?.picture + '?size=128') : null"></msc-common-avatar>

                        <div class="avatar__content">
                            <p class="profile__name">{{ selfassessment.account.name }}</p>
                        </div>
                    </div>

                    <div class="title">{{ selfassessment.title | truncate: 30}}</div>

                    <div class="time">{{ selfassessment.createdAt | timeAgo }}</div>
                </div>

                <div class="list-load-more">
                    <msc-load-more [loading]="loading" [pagination]="getCurrentPagination()" (onLoad)="loadMore()"></msc-load-more>
                </div>
            </div>

            <div class="list-correction col-8 col-md-8">
                <msc-camp-question-correction *ngIf="isTab('question') && currentQuestion" [session]="currentQuestion" (onUpdate)="refresh($event)"></msc-camp-question-correction>
                <msc-camp-project-correction *ngIf="isTab('project') && currentProject" [session]="currentProject" (onUpdate)="refresh($event)"></msc-camp-project-correction>
                <msc-camp-self-assessment-correction *ngIf="isTab('selfassessment') && currentSelfAssessment" [session]="currentSelfAssessment" (onUpdate)="refresh($event)"></msc-camp-self-assessment-correction>
            </div>
        </div>
    </div>
</div>
