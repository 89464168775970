import { Component, ChangeDetectionStrategy, Input, forwardRef, Inject, Injector, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'msc-common2-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Common2RadioComponent),
            multi: true
        }
    ],
})

export class Common2RadioComponent implements ControlValueAccessor {
    @Input() groupName = '';
    @Input() label = '';
    @Input() valueName = '';
    @Input() disabled = false;
    @Input() checked;

    @ViewChild('rdbtn') rdbtn: ElementRef;

    private value: string;

    onChange: any = () => { };
    onTouch: any = () => { };


    get formField(): FormControl {
        return this.formControl?.control as FormControl;
    }

    get formControl(): NgControl {
        try {
            return (this.injector.get(NgControl));
        } catch (e) {
            return null;
        }
    }

    get id(): string {
        return `${this.groupName || this.formControl?.name}_${this.valueName}`;
    }

    constructor(
        @Inject(Injector) private injector: Injector,
    ) { }

    ngAfterViewInit(): void {
        this.updateChecked();
    }

    onInputChange(val: any) {
        this.onChange(this.valueName);
    }

    writeValue(value: any): void {
        this.value = value;
        this.updateChecked();
    }
    
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    updateChecked() {
        const checked = this.value == this.valueName;
        if (this.rdbtn) {
            this.rdbtn.nativeElement.checked = checked;
        }
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
