<section class="head">
    <h3>Checkbox</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Checkbox</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common2-checkbox type="tick" [value]="true"></msc-common2-checkbox>
                <msc-common2-checkbox type="tick" [value]="true" [label]="'Lorem ipsum'"></msc-common2-checkbox>
                <msc-common2-checkbox type="tick" [value]="true" [label]="'Lorem ipsum'" [isDisabled]="true"></msc-common2-checkbox>
            </div>
            <div class="segment">
                <msc-common2-checkbox type="half" [value]="true"></msc-common2-checkbox>
                <msc-common2-checkbox type="half" [value]="true" [label]="'Lorem ipsum'"></msc-common2-checkbox>
                <msc-common2-checkbox type="half" [value]="true" [label]="'Lorem ipsum'" [isDisabled]="true"></msc-common2-checkbox>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Checkbox with Form</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <form [formGroup]="form">
                    <msc-common2-checkbox type="tick" controlName="coffee" [label]="'Coffee'"></msc-common2-checkbox>
                    <msc-common2-checkbox type="tick" controlName="soda" [label]="'Soda'"></msc-common2-checkbox>
                    <msc-common2-checkbox type="tick" controlName="water" [label]="'Water'"></msc-common2-checkbox>
                </form>
            </div>

            <div class="segment">
                <code>{{ form.value | json }}</code>
            </div>
        </div>
    </div>
</section>
