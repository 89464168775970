import { NgModule } from '@angular/core';
//
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutosizeModule } from 'ngx-autosize';
// Components
import { CommonAccordionComponent } from './components/accordion/accordion.component';
import { CommonAsideComponent } from './components/aside/aside.component';
import { CommonAvatarComponent } from './components/avatar/avatar.component';
import { CommonButtonComponent } from './components/button/button.component';
import { CommonCalendarComponent } from './components/calendar/calendar.component';
import { CommonChartBarComponent } from './components/chart-bar/chart-bar.component';
import { CommonChartDonutComponent } from './components/chart-donut/chart-donut.component';
import { CommonChartLineComponent } from './components/chart-line/chart-line.component';
import { Common2CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonEmptyComponent } from './components/empty/empty.component';
import { CommonErrorComponent } from './components/error/error.component';
import { CommonInputComponent } from './components/input/input.component';
import { CommonLoaderComponent } from './components/loader/loader.component';
import { CommonModalComponent } from './components/modal/modal.component';
import { CommonNavComponent } from './components/nav/nav.component';
import { CommonOverlayComponent } from './components/overlay/overlay.component';
import { Common2RadioComponent } from './components/radio/radio.component';
import { CommonSearchComponent } from './components/search/search.component';
import { CommonSelectComponent } from './components/select/select.component';
import { CommonSliderComponent } from './components/slider/slider.component';
import { CommonSpinnerComponent } from './components/spinner/spinner.component';
import { CommonSwitchComponent } from './components/switch/switch.component';
import { CommonTabComponent } from './components/tab/tab.component';
import { CommonTableComponent } from './components/table/table.component';
import { CommonTagComponent } from './components/tag/tag.component';
import { CommonTextareaComponent } from './components/input/textarea/textarea.component';
import { CommonTdCategoriesComponent } from './components/table-td/td-categories/td-categories.component';
import { CommonTdStatusComponent } from './components/table-td/td-status/td-status.component';
import { CommonThCheckboxComponent } from './components/table-th/th-checkbox/th-checkbox.component';
import { CommonThSortComponent } from './components/table-th/th-sort/th-sort.component';
import { CommonToastComponent } from './components/toast/toast.component';
import { CommonTooltipComponent } from './components/tooltip/tooltip.component';
import { CommonTreeComponent } from './components/tree/tree.component';
import { CommonUploadComponent } from './components/upload/upload.component';
import { CommonWaveComponent } from './components/wave/wave.component';
import { FieldErrorsComponent } from './components/input/field-errors/field-errors.component';
// Directives
import { CommonClickStopPropagation } from './directives/click-stop-propagation.directive';
import { CommonClickOutsideDirective } from './directives/click-outside.directive';
import { CommonDragDropDirective } from './directives/drag-drop.directive';
import { CommonNgElementRef } from './directives/element-ref.directive';
import { CommonTooltipDirective } from './directives/tooltip.directive';
import { CommonInfiniteScrollTriggerDirective } from './directives/infinite-scroll-trigger.directive';
// Pipes
import { UuidPipe } from './pipes/uuid.pipe';
import { TranslatePipe } from '../pipes';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonListComponent2 } from './components/list/list.component';
import { CommonDropdownComponent } from './components/dropdown/dropdown.component';
import { CommonOverlayMenuComponent } from './components/overlay-menu/overlay-menu.component';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        OverlayModule,
        ScrollingModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AutosizeModule,
        InfiniteScrollModule,
    ],
    declarations: [
        // Components
        CommonAccordionComponent,
        CommonAsideComponent,
        CommonAvatarComponent,
        CommonButtonComponent,
        CommonCalendarComponent,
        CommonChartBarComponent,
        CommonChartDonutComponent,
        CommonChartLineComponent,
        Common2CheckboxComponent,
        CommonEmptyComponent,
        CommonErrorComponent,
        CommonInputComponent,
        CommonListComponent2,
        CommonLoaderComponent,
        CommonModalComponent,
        CommonNavComponent,
        CommonOverlayComponent,
        CommonOverlayMenuComponent,
        Common2RadioComponent,
        CommonSearchComponent,
        CommonSelectComponent,
        CommonDropdownComponent,
        CommonSliderComponent,
        CommonSpinnerComponent,
        CommonSwitchComponent,
        CommonTabComponent,
        CommonTableComponent,
        CommonTagComponent,
        CommonTextareaComponent,
        CommonTdCategoriesComponent,
        CommonTdStatusComponent,
        CommonThCheckboxComponent,
        CommonThSortComponent,
        CommonToastComponent,
        CommonTooltipComponent,
        CommonTreeComponent,
        CommonUploadComponent,
        CommonWaveComponent,
        FieldErrorsComponent,
        // Directives
        CommonClickStopPropagation,
        CommonClickOutsideDirective,
        CommonDragDropDirective,
        CommonNgElementRef,
        CommonTooltipDirective,
        CommonInfiniteScrollTriggerDirective,
        // Pipes
        UuidPipe,
        TranslatePipe,
    ],
    exports: [
        InfiniteScrollModule,
        ScrollingModule,
        // Components
        CommonAccordionComponent,
        CommonAsideComponent,
        CommonAvatarComponent,
        CommonButtonComponent,
        CommonCalendarComponent,
        CommonChartBarComponent,
        CommonChartDonutComponent,
        CommonChartLineComponent,
        Common2CheckboxComponent,
        CommonEmptyComponent,
        CommonErrorComponent,
        CommonInputComponent,
        CommonListComponent2,
        CommonLoaderComponent,
        CommonModalComponent,
        CommonNavComponent,
        CommonOverlayComponent,
        CommonOverlayMenuComponent,
        Common2RadioComponent,
        CommonSearchComponent,
        CommonSelectComponent,
        CommonDropdownComponent,
        CommonSliderComponent,
        CommonSpinnerComponent,
        CommonSwitchComponent,
        CommonTabComponent,
        CommonTableComponent,
        CommonTagComponent,
        CommonTextareaComponent,
        CommonTdCategoriesComponent,
        CommonTdStatusComponent,
        CommonThCheckboxComponent,
        CommonThSortComponent,
        CommonToastComponent,
        CommonTooltipComponent,
        CommonTreeComponent,
        CommonUploadComponent,
        CommonWaveComponent,
        FieldErrorsComponent,
        // Directives
        CommonDragDropDirective,
        CommonTooltipDirective,
        CommonClickOutsideDirective,
        CommonNgElementRef,
        // Pipes
        UuidPipe,
        TranslatePipe,
    ]
})

export class AppCommon2Module { }
