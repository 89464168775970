<msc-common-aside #aside [title]="'instance.publication.settings' | translate" (saveEvent)="update()">
    <ng-template #asideTemplate>
        <div class="slide-camps-sessions-params">
            <ng-container *ngIf="courseCamp">
                <div class="slide__body slide-camps-sessions-params__toggle"
                    [ngClass]="{'active': displays.accessibility}">
                    <div class="slide-camps-sessions-params__toggle__header" (click)="displayed('accessibility')">
                        <div class="slide-camps-sessions-params__toggle__block">
                            <i class="icon icon-options"></i>
                            <div>{{ 'instance.publication.accessibility' | translate }}</div>
                        </div>

                        <div>
                            <i class="icon icon-arrow-ios-down"></i>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="courseCampCopy.isCatalogue"
                                (changeEvent)="changeSwitch('isCatalogue')"></msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.publication.catalogue-diffusion' | translate }}</p>
                                <p>{{ 'instance.publication.catalogue-diffusion.description' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="courseCampCopy.notifyManager"
                                (changeEvent)="changeSwitch('notifyManager')"></msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.publication.notify-manager' | translate }}</p>
                                <p>{{ 'instance.publication.notify-manager.description' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="courseCampCopy.needValidation"
                                (changeEvent)="changeSwitch('needValidation')"></msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.manager-validation' | translate }}</p>
                                <p>{{ 'instance.publication.validation.description' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="courseCampCopy.isLocked"
                                (changeEvent)="changeSwitch('isLocked')">
                            </msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.publication.course-locked' | translate }}</p>
                                <p>{{ 'instance.publication.course-locked.description' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="slide__body slide-camps-sessions-params__toggle"
                    [ngClass]="{'active': displays.monitoring}">
                    <div class="slide-camps-sessions-params__toggle__header" (click)="displayed('monitoring')">
                        <div class="slide-camps-sessions-params__toggle__block">
                            <i class="icon icon-settings"></i>
                            <div>{{ 'instance.publication.monitoring' | translate }}</div>
                        </div>

                        <div>
                            <i class="icon icon-arrow-ios-down"></i>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="courseCampCopy.isOrdered"
                                (changeEvent)="changeSwitch('isOrdered')"></msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.publication.section-order' | translate }}</p>
                                <p>{{ 'instance.publication.section-order.description' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="courseCampCopy.hasSurvey"
                                (changeEvent)="changeSwitch('hasSurvey')"></msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.publication.survey' | translate }}</p>
                                <p>{{ 'instance.publication.survey.description' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="journey">
                <div class="slide__body slide-camps-sessions-params__toggle"
                    [ngClass]="{'active': displays.accessibility}">
                    <div class="slide-camps-sessions-params__toggle__header" (click)="displayed('accessibility')">
                        <div class="slide-camps-sessions-params__toggle__block">
                            <i class="icon icon-options"></i>
                            <div>{{ 'instance.publication.accessibility' | translate }}</div>
                        </div>

                        <div>
                            <i class="icon icon-arrow-ios-down"></i>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="journeyCopy.settings.isCatalogue"
                                (changeEvent)="changeSwitch('isCatalogue')"></msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.publication.catalogue-diffusion' | translate }}</p>
                                <p>{{ 'instance.publication.catalogue-diffusion.description' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="slide-camps-sessions-params__toggle__content">
                        <div class="slide__switch">
                            <msc-common2-switch [value]="journeyCopy.settings.notifyManager"
                                (changeEvent)="changeSwitch('notifyManager')"></msc-common2-switch>

                            <div class="slide-camps-sessions-params__toggle__content__text">
                                <p>{{ 'instance.publication.notify-manager' | translate }}</p>
                                <p>{{ 'instance.publication.notify-manager.description' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>
</msc-common-aside>