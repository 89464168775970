<div class="head">
    <ng-container *ngIf="templateListTabControl.length; else navigationTemplate">
        <ng-container *ngFor="let templateTabControl of templateListTabControl; let i=index">
            <button type="button" [ngClass]="{'is-active': i === index}" (click)="onSelect(i)">
                <ng-container *ngTemplateOutlet="templateTabControl"></ng-container>
            </button>
        </ng-container>
    </ng-container>
    <ng-template #navigationTemplate>
        <ng-container *ngFor="let navItem of navigation">
            <button type="button" [routerLink]="navItem.routerLink" [routerLinkActive]="'is-active'" *ngIf="!navItem.isHidden">
                <i *ngIf="navItem.iconLeft" class="icon {{navItem.iconLeft}}"></i>
                <span>{{navItem.label}}</span>
                <i *ngIf="navItem.iconRight" class="icon {{navItem.iconRight}}"></i>
            </button>
        </ng-container>
    </ng-template>
    <div class="fill"></div>
</div>

<div class="body" *ngIf="templateListTabPanel.length">
    <ng-container *ngTemplateOutlet="templateListTabPanel[index]"></ng-container>
</div>