<section class="head">
    <h3>Switch</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Switch</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common2-switch type="tick" [value]="true"></msc-common2-switch>
                <msc-common2-switch type="tick" [value]="true" [label]="'Lorem ipsum'"></msc-common2-switch>
                <msc-common2-switch type="tick" [value]="true" [label]="'Lorem ipsum'" [isDisabled]="true"></msc-common2-switch>
                <msc-common2-switch type="half" [value]="true"></msc-common2-switch>
                <msc-common2-switch type="half" [value]="true" [label]="'Lorem ipsum'" [startWithLabel]="true"></msc-common2-switch>
                <msc-common2-switch type="half" [value]="true" [label]="'Lorem ipsum'" [startWithLabel]="true" [isDisabled]="true"></msc-common2-switch>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Switch with Form</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <form [formGroup]="form">
                    <msc-common2-switch type="tick" controlName="coffee" [label]="'Coffee'"></msc-common2-switch>
                    <msc-common2-switch type="tick" controlName="soda" [label]="'Soda'"></msc-common2-switch>
                    <msc-common2-switch type="tick" controlName="water" [label]="'Water'"></msc-common2-switch>
                </form>
            </div>

            <div class="segment">
                <code>{{ form.value | json }}</code>
            </div>
        </div>
    </div>
</section>
