<form [formGroup]="form">
    <div class="grouping">
        <p>{{ 'organize.marketplace.customers.modal.form.description' | translate }}</p>
    </div>

    <div class="grouping">
        <label>{{ 'organize.marketplace.customers.modal.form.input.name' | translate }}<span class="asterix">&#42;</span></label>
        <div class="wrapper-input">
            <input type="text" formControlName="name" [ngClass]="{'error' : form.dirty && form.controls['name'].errors }">
            <ng-container *ngIf="form.dirty && form.controls['name'].errors">
                <span class="span-err">{{ 'organize.marketplace.customers.modal.form.input.name.error' | translate }}</span>
            </ng-container>
        </div>
    </div>

    <div class="grouping">
        <label>{{ 'organize.marketplace.customers.modal.form.input.email' | translate }}<span class="asterix">&#42;</span></label>
        <div class="wrapper-input">
            <input type="text" formControlName="email" [ngClass]="{'error' : form.dirty && form.controls['email'].errors }">
            <ng-container *ngIf="form.dirty && form.controls['email'].errors">
                <span class="span-err">{{ 'organize.marketplace.customers.modal.form.input.email.error' | translate }}</span>
            </ng-container>
        </div>
    </div>

    <div class="grouping">
        <label>{{ 'organize.marketplace.customers.modal.form.textarea' | translate }}</label>
        <textarea formControlName="comment" rows="10"></textarea>
    </div>
</form>

<div class="flex justify-end mt-4 space-x-2">
    <msc-button (click)="onCloseModal()">{{ 'common.close' | translate }}</msc-button>
    <msc-button class="msc-button--primary" palette="primary" (click)="onSubmit()" [disabled]="form.status === 'INVALID'">{{ 'common.send' | translate }}</msc-button>
</div>
