import { Component, OnInit } from '@angular/core';
import { EmitterService, StorageService, TranslateService } from '@services';
import { ActivatedRoute } from '@angular/router';
import { LanguageProvider } from '@lighty';
import { Language } from '@interfaces';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'msc-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public me: any;
    public email: string;
    public token: any;
    public display: any;
    public logo: string;
    public selectData: any[] = [];

    constructor(
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private emitterService: EmitterService,
        private languageProvider: LanguageProvider,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.email = params.email;
            this.token = params.token;
        });
        this.display = this.route.snapshot.data.display;
        this.emitterService.set('theme.change', this.display && this.display.colorTemplate ? this.display.colorTemplate : {});
        this.logo = this.display.logo ? `${this.display.logo}?size=512` : 'https://webservices.griky.co/cdn/img/Griky-Logotipo.webp';
        const setLang = this.storageService.get('lang');
        if (setLang) {
            this.translateService.setLang(setLang);
        }
        this.setLanguages(this.translateService.getCurrentLang());
    }

    getSelectData(languages: Language[], code) {
        return languages.map((language, index) => ({
            id: index,
            label: language.name,
            value: language,
            isSelected: language.code === code
        }));
    }

    setLanguages(code = 'en') {
        this.languageProvider
            .get('interface')
            .pipe(tap((response) => this.onTapSetLanguages(response, code)))
            .subscribe();
    }

    onTapSetLanguages(languages: Language[], code: string) {
        if (!Array.isArray(languages)) { return; }
        const currentLang = languages.find((language) => language.code === code) || languages[0];
        this.translateService.setLang(currentLang.code);
        this.selectData = this.getSelectData(languages, code);
        this.storageService.set('lang', currentLang.code);
    }

    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });
        this.translateService.setLang(option.value.code);
    }
}
