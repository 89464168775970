<div class="content-access content-access__manage">
    <div class="content-access__manage__header">
        <div class="content-access__manage__search">
            <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
        </div>

        <div class="content-access__manage__enable">
            <msc-button class="msc-button--flat" palette="primary"
                [disabled]="!user.companyRoles.companyManager || user.companyRoles.companyAdministrator || (user.companyRoles.companyOwner && instancesEmpty)"
                (click)="modalAccessManage.onOpen()">
                {{ 'words.revoke-access' | translate }}
            </msc-button>
        </div>
    </div>

    <div class="content-access__manage__content">
        <router-outlet></router-outlet>
    </div>
</div>

<msc-common-modal size="30vw" #modalAccessManage [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ (user.companyRoles.companyOwner ? 'access.disabled-instance-super-admin' : 'access.disabled-instance') | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalAccessManage.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="disableAccess(); modalAccessManage.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
