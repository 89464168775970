import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'msc-learn-catalogue-watch-thematic',
    templateUrl: './thematic.component.html'
})
export class LearnCatalogueWatchThematicComponent implements OnInit {
    @Input() thematic: any;

    constructor() {}

    ngOnInit(): void {}

    getTranslation(level: number): string {
        switch (level) {
            case 1:
                return 'words.introductive';
            case 2:
                return 'words.intermediate';
            case 3:
                return 'words.confirmed';
            default:
                return 'words.appropriate-for-all';
        }
    }
}
