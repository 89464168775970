import { Component, ViewChild, ElementRef, Renderer2, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, Account, CompanyExperience, CompanyUserProvider, CompanyExperienceProvider, CompanyExperienceResourceProvider, MediaProvider, Media } from '@lighty';
import { StorageService, UploadService, EmitterService, TranslateService } from '@services';
import { CommonToastService } from '@common2/services/toast.service';
import { firstValueFrom } from 'rxjs';
@Component({
    selector: 'msc-organize-experience-watch-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class OrganizeExperienceWatchDashboardComponent {
    @ViewChild('resourceUpload') resourceUpload: ElementRef;
    private company: Company;
    public resourceFile: any;
    public experience: CompanyExperience;
    public resources: any[];
    public contactUsers: any[];
    public queryContact: string;
    public loadDisplayEmitter: EventEmitter<any> = new EventEmitter();
    public loadAnnounceEmitter: EventEmitter<any> = new EventEmitter();
    public loaders: any;
    public data: any;

    constructor(
        private route: ActivatedRoute,
        private companyUserProvider: CompanyUserProvider,
        private companyExperienceProvider: CompanyExperienceProvider,
        private companyExperienceResourceProvider: CompanyExperienceResourceProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private mediaProvider: MediaProvider,
        private renderer: Renderer2,
        private uploadService: UploadService,
        private emitterService: EmitterService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.experience = this.storageService.get('currentExperience');
        this.resources = this.route.snapshot.data.resources;

        this.data = {
            displayName: this.experience.displayName,
            announceMessage: this.experience.announceMessage,
            announceUrl: this.experience.announceUrl
        };

        this.resetLoaders();
        this.resetResource();
    }

    private resetLoaders(): void {
        this.loaders = {
            display: false,
            announce: false
        };
    }

    update(): void {
        this.experience.displayName = this.data.displayName;
        this.experience.announceMessage = this.data.announceMessage;
        this.experience.announceUrl = this.data.announceUrl;

        this.companyExperienceProvider.update(this.company.slug, this.experience.id, this.experience).subscribe((experience) => {
            this.experience = this.storageService.set('currentExperience', experience);
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.resetLoaders();

            if (this.company.experience && this.company.experience.id === experience.id) {
                this.emitterService.refreshExperience.emit(true);
            }
        });
    }

    load(key: string): void {
        this.loaders[key] = true;
    }

    loadPicture(key: string): void {
        if (key === 'display') {
            this.loadDisplayEmitter.emit(true);
        } else {
            this.loadAnnounceEmitter.emit(true);
        }
    }

    updatePicture(key: string, media?: Media): void {
        this.experience[key + 'MediaId'] = media ? media.id : null;
        this.update();
    }

    focusResource(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.resourceUpload.nativeElement).dispatchEvent(event);
    }

    loadResource(file: File): void {
        if (file) {
            this.resourceFile.file = file;
        }
    }

    uploadResource(): void {
        if (this.resourceFile.title && this.resourceFile.file) {
            this.uploadService.upload(this.resourceFile.file, 'file', 'media');
            const subscription = this.uploadService.getStatus().subscribe((data) => {
                if (data.type === 'progress') {
                    this.resourceFile.progress = data.value;
                }

                if (data.type === 'error') {
                    this.resetResource();
                    if (data.error.status === 415) {
                        this.toastService.onError(this.translateService.instant('toast.error.file-type.not-supported'));
                    }
                }

                if (data.type === 'finished') {
                    const params = {
                        media_id: data.value.id,
                        title: this.resourceFile.title
                    };
                    this.companyExperienceResourceProvider.create(this.company.slug, this.experience.id, params).subscribe((resource) => {
                        this.resources.push(resource);
                        this.toastService.onSuccess(this.translateService.instant('toast.file-uploaded'));
                        this.resetResource();
                        subscription.unsubscribe();
                    });
                }
            });
        } else {
            this.toastService.onWarning(this.translateService.instant('toast.warning.required'));
        }
    }

    private resetResource(): void {
        this.resourceFile = {
            file: '',
            title: '',
            progress: ''
        };
    }

    deleteResource(resourceId: number): void {
        this.companyExperienceResourceProvider.delete(this.company.slug, this.experience.id, resourceId).subscribe(() => {
            const index = this.resources.findIndex((resource) => {
                return resource.id === resourceId;
            });

            if (index !== -1) {
                this.resources.splice(index, 1);
            }
        });
    }

    onSelectContactUser(account: Account): void{
        this.experience.contactId = account.id;
        this.update();
    }

    getContactUserData(users) {
        return users.filter(user => user.account).map(user => ({
            id: user.id,
            label: user.account.name,
            value: user.account,
            isSelected: this.experience.contact?.id === user.id
        }));
    }

    loadMoreContactUsers(): CallableFunction {
        return (async (query, pagination) => {
            const params = {
                ...(query && { q: query }),
                page: pagination.currentPage + 1
            };

            const result = await firstValueFrom(this.companyUserProvider.getUsers(this.company.id, params));

            return {
                data: this.getContactUserData(result.users),
                pagination: result.pagination
            };
        })
    }
}
