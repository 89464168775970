import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService, TranslateService } from '@services';
import { Company, Integration, IntegrationFactory, IntegrationProvider, YoutubePlaylist, ApplicationProvider } from '@lighty';
import { UrlHelper } from '@helpers';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-organize-integration-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})

export class OrganizeIntegrationListComponent {
    @ViewChild('modalDeleteLink') modalDeleteLink: CommonModalComponent;
    public company: Company;
    public connectedApplications: any[] = [];
    public availableApplications: any[] = [];
    public displayedAvailableApplications: any[] = [];
    public displayedConnectedApplications: any[] = [];
    public externalLinks: Integration[];
    public playlists: YoutubePlaylist[];
    public decrypt: string = 'https://plus.google.com/_/favicon?domain_url=';
    public selected: any;
    public displayAsides: any;
    public displayDropdowns: any;
    public linkType: string = 'url';

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private integrationProvider: IntegrationProvider,
        private integrationFactory: IntegrationFactory,
        private applicationProvider: ApplicationProvider,
        private toastService: CommonToastService,
        private router: Router,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.externalLinks = this.route.snapshot.data.integrations.externalLinks;
        this.connectedApplications = this.route.snapshot.data.integrations.connectedApplications;
        this.availableApplications = this.route.snapshot.data.integrations.availableApplications;

        this.applyDisplayApplication(this.connectedApplications, this.displayedConnectedApplications);
        this.applyDisplayApplication(this.availableApplications, this.displayedAvailableApplications);

        this.selected = {
            link: null,
            playlist: null,
            integration: null,
            application: null,
            feature: null
        };

        this.displayAsides = {
            link: false,
            playlist: false,
            bookboon: false,
            freshchat: false,
            integration: true
        };

        this.displayDropdowns = {
            link: [],
            playlist: []
        };

        this.getPlaylists();
    }

    private getPlaylists(): void {
        const params = {
            includes: 'permissions',
            company_id: this.company.id
        };

        this.integrationFactory.get('youtube-admin').get(params).subscribe((data) => {
            this.playlists = data.playlists;
        });
    }

    isPlaylistEnabled(): boolean {
        return this.connectedApplications.findIndex((integration) => {
            return integration.name === 'youtube';
        }) !== -1;
    }

    linkApplication(application: any): void {
        // switch (application.name) {
        //     case 'gotowebinar':
        //         window.location.replace('https://api.getgo.com/oauth/v2/authorize?client_id=' + environment.integrations.webinarKey + '&response_type=code');
        //         break;
        //     default:
        //         this.applicationProvider.link(this.company.id, { application_id: application.id }).subscribe(() => {
        //             this.toastService.onSuccess(this.translateService.instant('toast.integrations.app-connected'));
        //             this.refresh();
        //         });
        //         break;
        // }

        this.applicationProvider.link(this.company.id, { application_id: application.id }).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.integrations.app-connected'));
            this.refresh();
        });
    }

    unlinkApplication(application: any): void {
        // switch (application.name) {
        //     case 'gotowebinar':
        //         this.integrationProvider.unlinkGotowebinar().subscribe(() => {
        //             this.refresh();
        //         });
        //         break;
        //     default:
        //         this.applicationProvider.unlink(this.company.id, application.id).subscribe(() => {
        //             this.toastService.onSuccess(this.translateService.instant('toast.integrations.app-disconnected'));
        //             this.refresh();
        //         });
        //         break;
        // }

        this.applicationProvider.unlink(this.company.id, application.id).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.integrations.app-disconnected'));
            this.refresh();
        });
    }

    private refresh(): void {
        this.integrationProvider.get(this.company.slug).subscribe((data) => {
            this.connectedApplications = data.connectedApplications;
            this.availableApplications = data.availableApplications;
            this.displayedAvailableApplications = [];
            this.displayedConnectedApplications = [];

            this.applyDisplayApplication(this.connectedApplications, this.displayedConnectedApplications);
            this.applyDisplayApplication(this.availableApplications, this.displayedAvailableApplications);
        });
    }

    private applyDisplayApplication(applicationsCollection: any[], destinationCollection: any[]): void {
        for (const application of applicationsCollection) {
            for (const feature of application.features) {
                destinationCollection.push({
                    id: application.id,
                    name: application.name,
                    isConnectable: application.isConnectable,
                    displayName: application.features.length > 1,
                    featureLabel: feature.label,
                    features: [feature]
                });
            }
        }
    }

    displayed(type: string, index: number): void {
        this.displayDropdowns[type][index] = !this.displayDropdowns[type][index];
    }

    openAside(type: string): void {
        this.displayAsides[type] = true;
    }

    changeLinkType(type: string): void {
        this.linkType = type;
    }

    closeAside(type: string): void {
        this.displayAsides[type] = false;
        this.selected[type] = null;
    }

    editItem(type: string, item: any, idx: number): void {
        this.displayDropdowns[type][idx] = false;

        this.selected[type] = item;

        this.openAside(type);
    }

    manageLink({link, isEdit}): void {
        if (isEdit) {
            this.updateLink(link);
        } else {
            this.addLink(link);
        }
    }

    private addLink(link: any): void {
        this.integrationProvider.addCompanyLink(this.company.id, link).subscribe((data) => {
            this.externalLinks.push(data);

            if (link.camps) {
                const params = {
                    camp_ids: link.camps
                };

                this.integrationProvider.updateCompanyCampsLink(this.company.id, data.id, params).subscribe();
            }
        });
    }

    private updateLink(link: any): void {
        this.integrationProvider.updateCompanyLink(this.company.id, link.id, link).subscribe((data) => {
            const index = this.externalLinks.findIndex((exLink) => {
                return exLink.id === link.id;
            });

            if (index !== -1) {
                this.externalLinks[index] = data;
            }

            if (link.camps) {
                const params = {
                    camp_ids: link.camps
                };

                this.integrationProvider.updateCompanyCampsLink(this.company.id, link.id, params).subscribe();
            }
        });
    }

    manageDeleteLink(link: any, index: number): void {
        if (link.type === 'lti') {
            this.selected.link = link;
            this.modalDeleteLink.onOpen();
        } else {
            this.deleteLink(link, index);
        }
    }

    deleteLink(link: any, idx?: number): void {
        this.selected.link = null;
        if (idx) {
            this.displayDropdowns.link[idx] = false;
        } else {
            this.displayDropdowns.link = [];
        }

        this.integrationProvider.deleteCompanyLink(this.company.id, link.id).subscribe(() => {
            const index = this.externalLinks.findIndex((data) => {
                return data.id === link.id;
            });

            if (index !== -1) {
                this.externalLinks.splice(index, 1);
            }
        });
    }

    managePlaylist({playlist, isEdit}): void {
        if (isEdit) {
            this.updatePlaylist(playlist);
        } else {
            this.addPlaylist(playlist);
        }
    }

    private addPlaylist(playlist: any): void {
        const params = playlist;
        params['company_id'] = this.company.id;
        params['lastSync'] = moment().format();

        this.integrationFactory.get('youtube-admin').create(params).subscribe((data) => {
            this.playlists.push(data);
        });
    }

    private updatePlaylist(playlist: any): void {
        const params = playlist;
        params['company_id'] = this.company.id;

        this.integrationFactory.get('youtube-admin').update(playlist.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));

            const index = this.playlists.findIndex((data) => {
                return data.id === playlist.id;
            });

            if (index !== -1) {
                this.playlists[index] = playlist;
            }
        });
    }

    refreshPlaylist(playlist: any, idx: number): void {
        this.integrationFactory.get('youtube-learning').refresh(playlist.id).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.playlist-refreshed'));
            this.displayDropdowns.playlist[idx] = false;
            playlist.lastSync = moment().format();
        });
    }

    deletePlaylist(playlist: YoutubePlaylist, idx: number): void {
        this.displayDropdowns.playlist[idx] = false;
        const params = {
            company_id: this.company.id
        };

        this.integrationFactory.get('youtube-admin').delete(playlist.id, params).subscribe(() => {
            const index = this.playlists.findIndex((data) => {
                return data.id === playlist.id;
            });

            if (index !== -1) {
                this.playlists.splice(index, 1);
            }
        });
    }

    manageIntegration(params: any, type: string): void {
        this.integrationProvider.updatePermissions(this.company.id, this.selected.integration.id, params).subscribe(() => {
            this.closeAside(type);
        });
    }

    manageApplication(settings: any): void {
        const params = {
            settings
        };

        this.applicationProvider.update(this.company.id, params).subscribe(() => {
            this.closeAside('freshchat');
        });
    }

    editFeature(application: any, feature: any): void {
        switch (application.name) {
            case 'freshchat':
                this.selected.application = application;
                this.selected.feature = feature;
                break;
        }
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    contact(): void {
        UrlHelper.mailTo('sales@myskillcamp.com');
    }
}
