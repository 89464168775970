import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';

import Lottie from 'lottie-web';

export const COMMON_FEED_REACTIONS: any[] = [
    {
        id: 1,
        name: 'thumbs-up',
        file: 'thumbs-up',
        type: 'like',
    },
    {
        id: 2,
        name: 'skeptical',
        file: 'skeptical',
        type: 'not_understood',
    },
    {
        id: 3,
        name: 'excited',
        file: 'excited',
        type: 'love',
    },
    {
        id: 4,
        name: 'mind-blown',
        file: 'mind-blown',
        type: 'useful',
    },
];

@Component({
    selector: 'msc-common-feed-reaction',
    templateUrl: './feed-reaction.component.html',
    styleUrls: ['./feed-reaction.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonFeedReactionComponent {
    @Input() id?: number;
    @Output() delete?: EventEmitter<any> = new EventEmitter<any>();
    @Output() submit?: EventEmitter<any> = new EventEmitter<any>();

    public reactions: any[];

    private destroy$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this.reactions = this.getReactions();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        this.setAnimations();
    }

    get url(): string { return this.getOwnReactionUrl(); }

    /**
     * Sets the animations for the reactions
     */
    setAnimations(): void {
        this.reactions.forEach((reaction) => {
            const animation = Lottie.loadAnimation({
                autoplay: false,
                container: document.getElementById(`${reaction.contentId}`),
                loop: false,
                name: reaction.name,
                path: 'assets/animations/' + reaction.file + '.json',
                renderer: 'svg',
            });

            reaction.animation = animation;
        });
    }

    /**
     * Get a list of reactions
     */
    getReactions() {
        return COMMON_FEED_REACTIONS.map((reaction, i) => ({
            ...reaction,
            contentId: `id-${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 9)}-${i}`,
            url: `https://static.myskillcamp.com/images/animations/${reaction.name}.svg`,
        }));
    }

    /**
     * Get the user own reaction, if applicable
     */
    getOwnReactionUrl(): string {
        const reaction = this.reactions.find((r) => this.id === r.id);
        if (!reaction) { return ''; }
        return reaction.url;
    }

    /**
     * Event handler for mouseenter on reaction
     */
    onMouseEnter(reaction: any): void {
        reaction.animation && reaction.animation.play();
    }

    /**
     * Event handler for mouseleave on reaction
     */
    onMouseLeave(reaction: any): void {
        reaction.animation && reaction.animation.stop();
    }

    /**
     * Event handler for reaction remove/delete
     */
    onDelete(): void {
        this.delete && this.delete.emit(this.id);
        this.id = undefined;
    }

    /**
     * Event handler for button click/submit
     */
    onSubmit(reaction: any): void {
        this.submit && this.submit.emit(reaction.id);
        this.id = reaction.id; // check for issues
    }
}
