import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonToastService } from '@common2/services/toast.service';
import { Company, Camp, StatsProvider, AdminContentProvider } from '@lighty';
import { ErrorStreamService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-stats-exports',
    styleUrls: ['./stats-exports.component.scss'],
    templateUrl: './stats-exports.component.html'
})
export class CampStatsExportsComponent implements OnInit {
    @ViewChild('modalConfirm') modalConfirm: CommonModalComponent;
    private company: Company;
    public camp: Camp;
    public displays: any;
    private campId: number;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private statsProvider: StatsProvider,
        private contentProvider: AdminContentProvider,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.camp = this.storageService.get('camp');

        this.displays = {
            export: false
        };

        this.route.parent.parent.params.subscribe((params) => {
            if (params.campId) {
                this.campId = params.campId;
            }
        });
    }

    download(ev): void {
        this.errorStreamService.locked();
        const params = {
            type: ev.type,
            format: ev.format,
            emails: ev.emails,
            camp_id: this.campId,
            year: ev.year || undefined
        };

        this.statsProvider.getExport(this.company.id, params).subscribe({
            next: (data) => {
                this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail'));
                this.modalConfirm.onOpen();
                this.errorStreamService.unlocked();
            },
            error: () => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                this.errorStreamService.unlocked();
            }
        });
    }

    displayed(type: string, force?: boolean): void {
        this.displays[type] = force ? false : !this.displays[type];
    }

    downloadOverview(): void {
        this.errorStreamService.locked();

        const params = {
            content_type: 'internal'
        };

        this.contentProvider.getExport(this.camp.id, params).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail'));
            this.errorStreamService.unlocked();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            this.errorStreamService.unlocked();
        });
    }

    downloadStats(): void {
        this.errorStreamService.locked();

        this.statsProvider.downloadStats(this.campId).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail'));
            this.errorStreamService.unlocked();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            this.errorStreamService.unlocked();
        });
    }

    downloadHistory(): void {
        this.errorStreamService.locked();

        this.statsProvider.downloadHistory(this.campId).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail'));
            this.errorStreamService.unlocked();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            this.errorStreamService.unlocked();
        });
    }
}
