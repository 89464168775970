import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Conversation } from './conversation';
import { Account } from '../account';
import { environment } from '@env/environment';

@Injectable()
export class ConversationProvider {

    constructor(private http: HttpClient) {}

    getAll(params?: any): Observable<Conversation[]> {
        return this.http.get(`${environment.envVar.API_URL}/conversations`, {params}).pipe(
            map((data: any) => {
                return data.conversations.map((conversation) => {
                    return new Conversation(conversation);
                });
            })
        );
    }

    get(slug: string, params?: any): Observable<Conversation> {
        return this.http.get(`${environment.envVar.API_URL}/conversation/${slug}`, {params}).pipe(
            map(data => new Conversation(data))
        );
    }

    create(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/conversation`, params).pipe(
            map(data => data)
        );
    }

    leave(id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/conversation/${id}/members`).pipe(
            map(data => data)
        );
    }

    sendMessage(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/conversation/${id}/message`, params).pipe(
            map(data => data)
        );
    }

    searchMembers(params: any): Observable<Account[]> {
        return this.http.get(`${environment.envVar.API_URL}/search/accounts`,{params}).pipe(
            map((data: any) => {
                if (data.accounts) {
                    return data.accounts.map((account) => {
                        return new Account(account);
                    });
                }
            })
        );
    }

    addMembers(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/conversation/${id}/members`, params).pipe(
            map(data => data)
        );
    }
}
