<div class="content-experience content-experience--catalogue">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title mb-3">
                <h3>{{ "words.cerficates-title" | translate }}</h3>
            </div>
            <msc-button
                class="msc-button--primary"
                icon="icon-plus"
                palette="secondary"
                (click)="openModalAdd()"
            >
                {{ "words.add" | translate }}
            </msc-button>

            <div class="flex flex-col gap-4 mt-10">
                <div class="flex flex-row box-experience__title">
                    <div class="w-1/3">
                        <span>{{ "words.description" | translate }}</span>
                    </div>
                    <div class="w-1/3">
                        <span>{{ "words.groups" | translate }}</span>
                    </div>
                    <div class="w-1/3">
                        <span>{{ "words.template" | translate }}</span>
                    </div>
                    <div class="w-1/6">
                        <span>{{ "words.status" | translate }}</span>
                    </div>
                    <div class="w-1/6">
                        <span>{{ "words.create-at" | translate }}</span>
                    </div>
                    <div class="w-1/12">
                        <span></span>
                    </div>
                    <div class="w-1/12">
                        <span></span>
                    </div>
                </div>
                <ng-container *ngIf="isLoadingTable">
                    <msc-common-spinner></msc-common-spinner>
                </ng-container>
                <div class="flex flex-col gap-4 mt-6" *ngIf="!isLoadingTable">
                    <div
                        class="flex flex-row w-full box-experience__title"
                        *ngFor="let row of emitedTemplates?.templates"
                    >
                        <div class="w-1/3">
                            <span>{{ row.description }}</span>
                        </div>
                        <div class="w-1/3">
                            <span>{{ row.xGroupName }}</span>
                        </div>
                        <div class="w-1/3">
                            <span>{{ row.xTemplateName }}</span>
                        </div>
                        <div class="w-1/6 flex align-center">
                            <div class="mr-3">
                                <span *ngIf="row.status == 1">{{'words.active' | translate}}</span>
                                <span *ngIf="row.status == 0">{{'words.inactive' | translate}}</span>
                            </div>
                            <msc-common2-switch type="tick" [value]="row.status == 1 ? true : false" (changeEvent)="toggleTemplateStatus(row.id)"></msc-common2-switch>
                        </div>
                        <div class="w-1/6">
                            <span>{{ formatData(row.createdAt) }}</span>
                        </div>
                        <div class="w-1/12">
                            <msc-button
                                class="msc-button--secondary"
                                icon="icon-edit"
                                palette="primary"
                                [tooltip]="'words.edit' | translate"
                                (click)="openEdit(row)"
                            >
                            </msc-button>
                        </div>
                        <div class="w-1/12">
                            <msc-button
                                *ngIf="row.deletedAt == null"
                                class="msc-button--primary"
                                icon="icon-trash-2"
                                palette="secondary"
                                [tooltip]="'common.delete' | translate"
                                (click)="openModalDelete(row.id)"
                            >
                            </msc-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-common-modal
    size="40vw"
    #modalAdd
    [title]="'words.template-create' | translate"
>
    <ng-template>
        <ng-container *ngIf="isLoading">
            <msc-common-spinner></msc-common-spinner>
        </ng-container>
        <ng-container *ngIf="!isLoading && !hasError">
            <!--
            <div class="date-till flex align-center justify-start">
                <p>{{ "words.from-the" | translate }}</p>

                <div
                    class="select"
                    [ngClass]="{ active: showDate }"
                    (click)="displayed('date_from')"
                >
                    <div class="select__content">
                        <p class="select__placeholder">
                            date here
                            <i class="icon icon-arrow-ios-down"></i>
                        </p>

                        <div
                            class="select__dropdown"
                            (click)="$event.stopPropagation()"
                        >
                            <msc-datepicker
                                #dateFrom
                                [date]="dates.from"
                                [limit]="{ max: today }"
                                [actions]="true"
                                (onUpdate)="updateDateFrom($event)"
                                (onClose)="displayed('date_from', true)"
                            ></msc-datepicker>
                        </div>
                    </div>
                </div>
            </div>
-->
            <div class="flex justify-between mb-3">
                    <msc-input
                    [label]="'words.description' | translate"
                    [placeholder]="'words.description' | translate"
                    [(ngModel)]="templateItemDescription"
                    (ngModelChange)="onInputChange()"
                    class="w-full pr-3"
                ></msc-input>

                <div class="w-full pl-3">
                    <p>{{ "words.template" | translate }}</p>
                    <msc-common-select
                        class="text-sm max-w-full"
                        [data]="emitedTemplates.xTemplates"
                    >
                        <ng-template #buttonTemplate let-data="data">
                            <button class="select-main">
                                <ng-container>
                                    <span>{{ selectSelections.template.name }}</span>
                                </ng-container>
                            </button>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <button
                                class="select-option"
                                (click)="onSelectType(datum, 'template')"
                            >
                                <span>{{ datum.name }}</span>
                            </button>
                        </ng-template>
                    </msc-common-select>
                </div>
            </div>

            <div class="flex justify-between mb-3">
                <msc-input
                [label]="'words.start-certificate-date' | translate"
                [placeholder]="'words.start-certificate-date' | translate"
                [(ngModel)]="initialCertificateDate"
                (ngModelChange)="onInputChange()"
                type="date"
                class="w-full pr-3"
            ></msc-input>
                <msc-input
                    [label]="'words.minimum-score' | translate"
                    [placeholder]="'words.minimum-score' | translate"
                    [(ngModel)]="templateItemMinimumProgress"
                    (ngModelChange)="onInputChange()"
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    (change)="validateScale($event)"
                    class="w-full pl-3"
            ></msc-input>
            </div>

            <div class="flex justify-between mb-3">
                <div class="w-full pr-3">
                    <p>{{ "words.groups" | translate }}</p>
                    <msc-common-select
                        class="text-sm max-w-full"
                        [data]="emitedTemplates.xGroups"
                    >
                        <ng-template #buttonTemplate let-data="data">
                            <button class="select-main">
                                <ng-container>
                                    <span>{{ selectSelections.group.name }}</span>
                                </ng-container>
                            </button>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <button
                                class="select-option"
                                (click)="onSelectType(datum, 'group')"
                            >
                                <span>{{ datum.name }}</span>
                            </button>
                        </ng-template>
                    </msc-common-select>
                </div>
                <div class="w-full pl-3">
                    <p>{{ "words.catalogs" | translate }}</p>
                    <msc-common-select
                        type="multi"
                        [data]="emitedTemplates.catalogs"
                        [disabled]="loading"
                        style="overflow: hidden"
                    >
                        <ng-template #buttonTemplate let-data="data">
                            <ng-container
                                *ngIf="selectedItem.length; else emptyTemplate"
                            >
                                <div
                                    class="selections-content text-ellipsis overflow-hidden whitespace-nowrap"
                                >
                                    <ng-container
                                        *ngFor="
                                            let datum of selectedItem;
                                            let i = index
                                        "
                                    >
                                        <span class="selections">
                                            {{ datum.name }}
                                        </span>
                                        &nbsp;
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #emptyTemplate>&nbsp;</ng-template>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <div class="option">
                                <msc-common2-checkbox
                                    type="tick"
                                    [value]="datum.isSelected"
                                    [label]="datum.name"
                                    (changeEvent)="onSet($event, datum)"
                                ></msc-common2-checkbox>
                            </div>
                        </ng-template>
                    </msc-common-select>
                </div>
            </div>
            
            <div class="flex items-end">
                <div class="flex w-full">
                    <div class="w-3/6">
                        <msc-input
                            [label]="'words.xertify' | translate"
                            [placeholder]="'words.xertify' | translate"
                            [(ngModel)]="newItemName"
                        ></msc-input>
                    </div>
                    <div class="w-3/6 ml-3 mr-3">
                        <p>{{ "words.set-local-field" | translate }}</p>
                        <msc-common-select
                            class="text-sm max-w-full"
                            [data]="emitedTemplates.localFields"
                        >
                            <ng-template #buttonTemplate let-data="data">
                                <button class="select-main">
                                    <ng-container>
                                        <span>{{ selectedItemId }}</span>
                                    </ng-container>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <button
                                    class="select-option"
                                    (click)="onSelectType(datum, 'localField')"
                                >
                                    <span>{{ datum.name | translate }}</span>
                                </button>
                            </ng-template>
                        </msc-common-select>
                    </div>
                    <div class="w-3/6">
                        <msc-input
                            [label]="'words.field-value' | translate"
                            [placeholder]="'words.field-value' | translate"
                            [(ngModel)]="newItemLabel"
                            [disabled]="newItemId != 'custom'"
                        ></msc-input>
                    </div>
                </div>

                <msc-button
                    class="ml-3 mb-2"
                    type="fab"
                    icon="icon-plus"
                    (click)="addNewItemToNewList()"
                    [disabled]="verifyNewInputs()"
                ></msc-button>
            </div>

            <div
                class="flex mb-1 place-content-between w-full items-end"
                *ngFor="let item of newList; let i = index"
            >
                <div class="flex w-full">
                    <div class="flex w-3/6 flex-col">
                        <p>{{ newListDisplayed[i].xertifyField }}</p>
                    </div>
                    <div class="flex w-3/6 flex-col ml-3">
                        <p>{{ newListDisplayed[i].localField }}</p>
                    </div>
                    <div class="flex w-3/6 flex-col ml-3">
                        <p *ngIf="!newListDisplayed[i].valueField; else editValue">{{ item.local_value }}</p>
                        <ng-template #editValue>
                            <p>{{ newListDisplayed[i].valueField }}</p>
                        </ng-template>
                    </div>
                </div>
                <msc-button
                    [tooltip]="'words.delete' | translate"
                    type="fab"
                    icon="icon-trash"
                    (click)="removeItemFromNewList(i)"
                ></msc-button>
            </div>
            <div class="flex items-center mt-5 space-x-5 justify-end">
                <msc-button palette="primary" (click)="modalAdd.onClose()">
                    {{ "common.cancel" | translate }}
                </msc-button>

                <msc-button
                    *ngIf="!isEdit"
                    class="msc-button--primary"
                    palette="primary"
                    [disabled]="!checkInputs"
                    (click)="createTemplate()"
                >
                    {{ "common.save" | translate }}
                </msc-button>

                <msc-button
                    *ngIf="isEdit"
                    class="msc-button--primary"
                    palette="primary"
                    (click)="updateTemplate()"
                >
                    {{ "common.edit" | translate }}
                </msc-button>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading && hasError">
            <h2>{{ errorMsg | translate }}</h2>
        </ng-container>
    </ng-template>
</msc-common-modal>

<msc-common-modal
    size="20vw"
    #modalDelete
    [title]="'words.template-delete-title' | translate"
>
<ng-template>
    <span>{{'words.template-delete-text' | translate}}</span>
    <div class="flex items-center mt-3 space-x-5 justify-start">
        <msc-button
        class="msc-button--primary mr-3"
        palette="primary"
        (click)="deleteTemplate()"
    >
            {{ "common.delete" | translate }}
        </msc-button>

        <msc-button 
        palette="primary" 
        (click)="modalAdd.onClose()">
            {{ "common.cancel" | translate }}
        </msc-button>
    </div>
</ng-template>
</msc-common-modal>