import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, Conversation, ConversationProvider, Account } from '@lighty';
import { StorageService, TranslateService, EmitterService, SocketService, ExternalAppsService } from '@services';
import { HttpHelper } from '@helpers';
import { SubscriptionCollection } from '@classes';
import { CommonToastService } from '@common2/services/toast.service';
import { ThemingService } from '@common2/services/theming.service';

@Component({
    selector: 'msc-conversation',
    templateUrl: './conversation.component.html',
    styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private socket: any;
    public me: any;
    public company: Company;
    public access: any;
    public sidebar: any;
    public conversations: Conversation[];
    public query: string;
    public displays: any;
    public referrer: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private translateService: TranslateService,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private conversationProvider: ConversationProvider,
        private socketService: SocketService,
        private externalAppsService: ExternalAppsService,
        private themingService: ThemingService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.access = this.storageService.set('access', this.route.snapshot.data.access);
        this.sidebar = this.storageService.set('sidebar', this.route.snapshot.data.sidebar);
        this.conversations = this.route.snapshot.data.conversations;
        if (this.company.experience?.colorTemplate?.primaryColor && this.company.experience?.colorTemplate?.secondaryColor) {
            this.themingService.apply({ ...(this.company.experience?.colorTemplate?.primaryColor && { primary: this.company.experience.colorTemplate.primaryColor }), ...(this.company.experience?.colorTemplate?.secondaryColor && { secondary: this.company.experience.colorTemplate.secondaryColor }) });
        }

        this.translateService.setLang(this.me.language.code);
        this.externalAppsService.boot(this.me, this.company, this.access.roles);

        this.referrer = this.storageService.getFlash('referrer');

        this.displays = {
            conversations: false
        };

        if (!this.route.firstChild) {
            if (this.conversations.length > 0) {
                this.router.navigate(['conversation', this.conversations[0].slug]);
            } else {
                this.displays.conversations = true;
            }
        }

        this.conversationListener();
        this.socketListener();

        this.emitterService.set('theme.change', this.company.experience.colorTemplate);
    }

    ngOnDestroy(): void {
        this.socketService.disconnect();
        this.subscriptionCollection.unsubscribe();
    }

    private conversationListener(): void {
        this.subscriptionCollection.subscribe = this.emitterService.get('conversation.left').subscribe((id) => {
            if (typeof id === 'number') {
                this.toastService.onSuccess(this.translateService.instant('toast.conversation-left'));

                const index = this.conversations.findIndex((conversation) => {
                    return conversation.id === id;
                });

                if (index !== -1) {
                    this.conversations.splice(index, 1);
                    this.router.navigate(['conversation']);
                }
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('conversation.created').subscribe((slug) => {
            if (typeof slug === 'string') {
                this.conversationProvider.getAll().subscribe((data) => {
                    this.conversations = data;
                });
                this.router.navigate(['conversation', slug]);
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('conversation.back').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.displays.conversations = data;
            }
        });
    }

    private socketListener(): void {
        this.socket = this.socketService.getSocket();
        // Join new room for each conversation
        if (this.conversations) {
            this.conversations.forEach((conversation) => {
                this.socket.emit('joinRoom', {
                    account: this.me,
                    room: conversation.id
                });
            });
        }

        // Receiving new message
        this.socket.on('message', (data) => {
            const index = this.getConversationIndex(parseInt(data.room, 10));

            if (index !== -1) {
                this.conversations[index].message = data.text;
                this.conversations[index].updatedAt = data.created;
            }
        });
    }

    private getConversationIndex(id: number): number {
        return this.conversations.findIndex((conversation) => {
            return conversation.id === id;
        });
    }

    othersCorrespondents(accounts: Account[]): any {
        if (accounts.length > 1) {
            return accounts.filter((account, index) => {
                return index !== 0;
            });
        }
        return null;
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.conversationProvider.getAll(params).subscribe((data) => {
            this.conversations = data;
        });
    }

    back(): void {
        this.emitterService.set('conversation.created', null);
        this.router.navigateByUrl(this.referrer);
    }
}
