import { Component, Renderer2, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import { Camp, CompanyExperience, CompanyUserProvider, Company, LanguageProvider } from '@lighty';
import { StorageService, ErrorStreamService, TranslateService, CacheService } from '@services';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { HttpHelper } from '@helpers';
import { of, Observable } from 'rxjs';
import { OrganizeUserStepBaseComponent } from '../configure/configure.component.base';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-user-invitation',
    templateUrl: './invitation.component.html',
    styleUrls: ['./invitation.component.scss']
})
export class OrganizeUserInvitationComponent extends OrganizeUserStepBaseComponent implements OnInit {
    @ViewChild('csvFile') csvFile: ElementRef;
    @ViewChild('downloadUrl') downloadUrl: ElementRef;
    @ViewChild('onboardingForm') onboardingForm;
    private company: Company;
    private me;
    private emailRegex: RegExp = new RegExp(/^[a-zA-Z0-9.!#$'%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    public isImport: boolean = false;
    public currentStep: number = 0;
    public data: any;
    public campsSelected: any[] = [];
    public campsSelectedLabel: string = '';
    public emailsSelected: any[] = [];
    public experienceSelected: CompanyExperience;
    public formErrors: any = {};
    public exampleUrl: string = (environment.envVar.API_URL + '/').replace('/api/', '/template/import-users-example.xlsx');
    public loading: boolean;
    public invitationForm: OrganizeUserInvitationComponent;
    saved: boolean = false;
    customMessage: any;
    languages;
    customMessagesCount: any;
    emailTemplate: string;
    emailTemplateRaw: string;
    public selectData: any = {
        interfaceLanguages: [],
        messageLanguages: [],
        camps: [],
        experiences: []
    }

    constructor(
        private companyUserProvider: CompanyUserProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private router: Router,
        private translateService: TranslateService,
        public languageProvider: LanguageProvider,
        private cacheService: CacheService,
    ) {
        super();
        this.invitationForm = this;
    }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.data = {
            file: undefined,
            emailStatus: 'auto',
            language: this.me.language,
            languageId: this.me.language.id,
            emails: [],
            roles: ['company_learner'],
            camp_ids: [],
            experience_id: null,
            firstname: '',
            lastname: '',
            onboarding: false,
            custom_message: null,
            customMessages: this.cacheService.get('customMessages') || [],
            messageLanguage: this.storageService.get('me')?.language
        };
    
        this.languageProvider.get('interface').subscribe((langs) => {
            this.languages = langs;
            this.selectData.interfaceLanguages = this.getSelectData(langs, 'interfaceLanguages');
            this.selectData.messageLanguages = this.getSelectData(langs, 'messageLanguages');
        });

        this.isImport = !!this.route.snapshot.queryParamMap.get('type');
        this.company = this.storageService.get('company');
        this.getAvailableCamps();
        this.getAvailableExperiences();
        this.getEmailTemplate();

        this.customMessagesCount = this.data.customMessages.filter((customMessage) => customMessage.message).length;
        this.processMessage();
    }


    getMessage(languageId: number): string {
        return this.data.customMessages.find((customMessage) => {
            return customMessage.languageId === languageId;
        })?.message;
    }

    hasMessages(): boolean {
        return this.data.customMessages.filter((customMessage) => {
            return !!customMessage.message;
        }).length > 0;
    }

    messageMissing(): boolean {
        return this.data.customMessages.filter((customMessage) => {
            return !!customMessage.message;
        }).length !== this.languages.length;
    }

    private processMessage(): void {
        this.customMessage = this.data.customMessages.find((customMessage) => {
            return customMessage.languageId === this.data.messageLanguage.id;
        })?.message;
    }

    updateMessage(): void {
        const index = this.data.customMessages.findIndex((custonMessage) => {
            return custonMessage.languageId === this.data.messageLanguage.id;
        });

        if (index > -1) {
            this.data.customMessages[index].message = this.customMessage;
        } else {
            this.data.customMessages.push({ languageId: this.data.messageLanguage.id, message: this.customMessage });
        }

        this.customMessagesCount = this.data.customMessages.filter((customMessage) => customMessage.message).length;
    }

    private getAvailableCamps(): void {
        this.companyUserProvider.getCamps(this.company.id).subscribe((camps) => {
            this.selectData.camps = this.getSelectData(camps, 'camps');
        });
    }

    private getAvailableExperiences(): void {
        this.companyUserProvider.getExperiences(this.company.slug).subscribe((data) => {
            this.selectExperience(data[0]);
            this.selectData.experiences = this.getSelectData(data, 'experiences');
        });
    }

    getSelectData(data, key?: string) {
        switch (key) {
            case 'interfaceLanguages':
            case 'messageLanguages':
                return data.map(d => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: d.id === this.data.languageId
                }));
            case 'experiences':
                return data.map((d, index) => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: index === 0
                }));
            case 'camps':
                return data.map(d => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: false
                }));
        }
    }

    onSelectOption(option, key: string) {
        this.selectData[key] = this.selectData[key].map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });

        switch (key) {
            case 'interfaceLanguages': return this.onSelect(option.value);
            case 'messageLanguages': return this.changed(option.value);
            case 'experiences': return this.selectExperience(option.value);
        }
    }

    onSelectMultiOption(option) {
        option.isSelected = !option.isSelected;
        this.selectData.camps = [...this.selectData.camps];

        this.updateCamp(option.value);
    }

    private resetData(): void {
        this.data.emailStatus = 'auto';
        this.data.onboarding = false;
        this.data.emails = [];
        this.data.camp_ids = [];
        this.data.experience_id = null;
        this.data.firstname = '';
        this.data.lastname = '';
        this.data.language = this.me.language;
        this.data.custom_message = null;
        this.data.messageLanguage = this.storageService.get('me')?.language;
        this.data.file = undefined;
        this.emailsSelected = [];
        this.campsSelected = [];
        this.campsSelectedLabel = '';
    }

    isValid(): boolean {
        this.formErrors = {};

        this.data.emails = this.emailsSelected.filter(email => email.valid).map((email) => email.value);
        this.data.camp_ids = this.campsSelected.map((camp: Camp) => camp.id);
        this.data.experience_id = this.experienceSelected.id;
        if (this.isImport) {
            delete this.data.language;
        }

        const length = this.data.emails.length;
        if (length > 1) {
            this.data.firstname = '';
            this.data.lastname = '';
        }

        if (['none', 'auto'].indexOf(this.data.emailStatus) !== -1) {
            this.data.customMessages = [];
        }

        if (this.isImport && !this.data.file) {
            this.toastService.onError(this.translateService.instant('toast.import.file.required'));
            return false;
        }

        if (!this.isImport && length === 0) {
            this.formErrors.email = true;
        }

        if (this.data.camp_ids.length === 0) {
            this.formErrors.camp = true;
        }

        if (this.isImport) {
            this.data.custom_message = null;
            if (this.data.customMessages.length == 0 || this.data.customMessages.every((customMessage) => !customMessage.message)) {
                if (this.data.emailStatus === 'custom') {
                    this.formErrors.customMessage = true;
                } else {
                    this.data.customMessages = [];
                }
            }
        } else {
            this.data.customMessages = [];
            if (this.data.emailStatus === 'custom' && !this.data.custom_message) {
                this.formErrors.customMessage = true;
            }
        }

        return !Object.values(this.formErrors).some(error => error);
    }

    changed(value: string): void {
        this.data.messageLanguage = value;
        this.processMessage();
    }

    updateCamp(camp: Camp): void {
        if (this.isCampSelected(camp)) {
            this.removeCamp(camp);
        } else {
            this.addCamp(camp);
        }
        this.campsSelectedLabel = this.getSelectedCamps(3);
    }

    private addCamp(camp: Camp): void {
        this.campsSelected = [...this.campsSelected, camp];
        delete this.formErrors.camp;
    }

    public removeCamp(camp: Camp): void {
        const index = this.campsSelected.findIndex((data) => {
            return data.id === camp.id;
        });

        if (index !== -1) {
            this.campsSelected.splice(index, 1);
            this.selectData.camps = this.selectData.camps.map(d => {
                if (d.id === camp.id) { d.isSelected = false }
                return d;
            });
        }

        if (this.campsSelected.length === 0) {
            this.formErrors.camp = true;
        }
        this.campsSelected = [...this.campsSelected];
    }

    addEmail(input: HTMLInputElement): void {
        if (!input.value) {
            return;
        }
        const isValid = this.emailRegex.test(input.value);
        this.emailsSelected.push({ value: input.value, valid: isValid });
        if (isValid) {
            delete this.formErrors.email;
        }
        input.value = '';
    }

    removeEmail(email: string): void {
        this.emailsSelected.splice(this.emailsSelected.indexOf(email), 1);

        if (this.emailsSelected.length === 0) {
            this.formErrors.email = true;
        }
    }

    isCampSelected(camp: Camp): boolean {
        return this.campsSelected.findIndex((data) => data.id === camp.id) !== -1;
    }

    selectExperience(experience: CompanyExperience): void {
        this.experienceSelected = experience;
        this.data.experience_id = experience.id;
        this.updateEmailTemplate();
    }

    save(): void {
        if (this.isValid()) {
            this.loading = true;
            this.cacheService.set('customMessages', this.data.customMessages);
            this.data.messageLanguage = null;
            if (this.isImport) {
                this.importUser(this.data);
            } else {
                this.addUsers(this.data);
            }
            this.resetData();
        }
    }

    getSelectedCamps(limit: number): string {
        return this.campsSelected.slice(0, limit).map((camp) => {
            return camp.name;
        }).join(', ');
    }

    addUsers(params: any): void {
        if (!params.onboarding) {
            params.onboarding = null;
        }

        this.companyUserProvider.inviteMember(this.company.id, HttpHelper.cleanParams({ ...params, customMessages: null })).subscribe((data) => {
            this.processInviteResponse(data);
            this.loading = false;
            this.saved = true;
            this.router.navigate(['../../../users'], { relativeTo: this.route });
        }, (error) => {
            this.loading = false;
        });
    }

    importUser(params: any): void {
        if (!params.onboarding) {
            params.onboarding = null;
        }
        this.errorStreamService.locked();

        this.companyUserProvider.import(this.company.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-added-undefined'));
            this.loading = false;
            this.saved = true;
            this.router.navigate(['../../../users'], { relativeTo: this.route });
        }, (error) => {
            this.router.navigate(['../../../users'], { relativeTo: this.route });
            this.processErrorResponse(error);
            this.toastService.onError(this.translateService.instant('toast.users-bad-file'));
            this.loading = false;
        }, () => {
            this.errorStreamService.unlocked();
        });
    }

    private processErrorResponse(data: any): void {
        if (data.error && data.error.missingColumns) {
            this.toastService.onError(this.translateService.instant('toast.missing.columns', { value: data.error.missingColumns.join(', ') }));
        } else {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    private processInviteResponse(data: any): void {
        if (data.createdUsers.length > 0) {
            this.toastService.onSuccess(this.translateService.instant(data.createdUsers.length > 1 ? 'toast.users-added' : 'toast.user-added', { value: data.createdUsers.length }));
        }

        if (data.noPlaceLeft.length > 0) {
            this.toastService.onWarning(this.translateService.instant('toast.limit-users-reached'));
        }

        if (data.undefinedError.length > 0) {
            this.toastService.onError(this.translateService.instant(data.undefinedError.length > 1 ? 'toast.invitations-users-error' : 'toast.invitations-user-error', { value: data.undefinedError.length }));
        }
    }

    importCSV(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.csvFile.nativeElement).dispatchEvent(event);
    }

    updateFile(file?: File): void {
        this.data.file = file;
    }

    downloadExample(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.downloadUrl.nativeElement).dispatchEvent(event);
    }

    nextStep(onboardingData: any): void {
        if (onboardingData) {
            this.data.onboarding = onboardingData;
        }

        if (this.isValid()) {
            this.currentStep += this.currentStep === 0 && !this.data.onboarding ? 2 : 1;
        }
    }

    toReadableData(): any {
        return {
            label: 'words.personal-data',
            data: [
                {
                    label: 'words.upload',
                    data: [this.data.file?.name]
                },
                {
                    label: 'words.learn',
                    data: this.campsSelected.map(camp => camp.name)
                },
                {
                    label: 'words.experience',
                    data: [this.experienceSelected.name]
                },
                {
                    label: 'words.email-notification',
                    data: [
                        `words.notification.${this.data.emailStatus}`
                    ]
                },
            ]
        };
    }

    toApiData(): void {
        return;
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (!this.saved && (this.emailsSelected.length > 0 || this.campsSelected.length > 0 || this.data.file)) {
            const result = window.confirm(this.translateService.getTranslate('integrations.rules.leave-unsaved'));
            return of(result);
        }

        return true;
    }

    onSelect(data: any) {
        this.data.languageId = data.id;
    }

    getEmailTemplate() {
        this.companyUserProvider.getInvitationEmailTemplate(this.company.id, { language_id: this.me.language.id }).subscribe((data: any) => {
            this.emailTemplateRaw = data.paragraph;
            this.updateEmailTemplate();
        })
    }

    updateEmailTemplate() {
        if (this.experienceSelected) {
            this.emailTemplate = this.emailTemplateRaw?.replace(':company_name', this.company?.name).replace(':experience_name', this.experienceSelected.name);
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    leavePage($event: any): void {
        if (!this.saved && (this.emailsSelected.length > 0 || this.campsSelected.length > 0 || this.data.file)) {
            $event.returnValue = 'Your data will be lost!';
        }
    }
}
