import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, Pagination, CompanyUserAccessManageProvider, AdminGroupProvider } from '@lighty';
import { EmitterService, TranslateService } from '@services';
import { DataHelper, HttpHelper } from '@helpers';
import { SubscriptionCollection } from '@classes';
import { GroupRoles } from '@enums';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-account-access-manage-instance',
    templateUrl: './instance.component.html'
})
export class AccountAccessManageInstanceComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public accountId: number;
    public company: Company;
    public instances: any[];
    public pagination: Pagination;
    public displays: any;
    public query: string;
    public loading: boolean = false;
    public availableInstances: any = [];
    public user: any;
    public collaborators: any[] = [];
    public collaboratorsPagination: Pagination;
    public collaboratorsLoading: boolean = false;
    public allowMemberAssignment: boolean = false;
    public permissionLoading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private companyUserAccessManageProvider: CompanyUserAccessManageProvider,
        private emitterService: EmitterService,
        private toastService: CommonToastService,
        private adminGroupProvider: AdminGroupProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.route.parent.parent.parent.snapshot.data.company;
        this.instances = this.route.snapshot.data.access.instances;
        this.pagination = this.route.snapshot.data.access.pagination;
        this.user = this.route.parent.parent.parent.snapshot.data.user;

        this.route.parent.parent.parent.params.subscribe((params) => {
            this.accountId = parseInt(params.accountId, 10);
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('access.manage.search').subscribe((query) => {
            if (typeof query === 'string') {
                this.query = query;
                this.search();
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('update.access').subscribe((data) => {
            if (!DataHelper.isEmpty(data) && data.role === 'companyManager') {
                if (data.enable) {
                    this.query = null;
                    this.search();
                    this.emitterService.set('instances.empty', false);
                } else {
                    this.instances = [];
                    this.emitterService.set('instances.empty', true);
                }
            }
        });

        if (this.instances.length === 0) {
            this.emitterService.set('instances.empty', true);
        }

        this.displays = {
            content: true,
            aside: {
                addInstance: false
            },
            lists: []
        };
        this.displays.lists['contents'] = true;

        if (this.user.teams.length > 0) {
            this.adminGroupProvider.getCollaborators(this.company.id, this.user.teams[0].id).subscribe(data => {
                this.collaborators = data.users.filter(user => user.roles[0].name !== GroupRoles.OWNER);
                this.collaboratorsPagination = data.pagination;
            });

            if (this.user.teamsPermissions[this.user.teams[0].id]) {
                this.allowMemberAssignment = this.user.teamsPermissions[this.user.teams[0].id].permissions.groupManageUser;
            }
        }

    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    unfold(list: string): void {
        this.displays.lists[list] = !this.displays.lists[list];
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    search(): void {
        const params = HttpHelper.cleanParams({
            q: this.query,
            role: 'manager'
        });

        this.companyUserAccessManageProvider.get(this.company.id, this.accountId, params).subscribe((data) => {
            this.instances = data.instances;
            this.pagination = data.pagination;
        });
    }

    loadMore(): void {
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.currentPage + 1,
            role: 'manager'
        });

        this.companyUserAccessManageProvider.get(this.company.id, this.accountId, params).subscribe((data) => {
            this.instances = this.instances.concat(data.instances);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    loadMoreCollaborators(): void {
        this.collaboratorsLoading = true;
        const params = {
            page: this.collaboratorsPagination.currentPage + 1,
        }
        this.adminGroupProvider.getCollaborators(this.company.id, this.user.teams[0].id, params).subscribe((data) => {
            this.collaborators = this.collaborators.concat(data.users);
            this.collaboratorsPagination = data.pagination;
            this.collaboratorsLoading = false;
        })
    }

    removeInstance(instance): void {
        const params = HttpHelper.cleanParams({
            context: instance.type
        });

        this.companyUserAccessManageProvider.deleteInstance(this.company.id, this.accountId, instance.data.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.availableInstances.push(instance);

            const index = this.instances.findIndex((result) => {
                return result.data.id === instance.data.id;
            });

            if (index !== -1) {
                this.instances.splice(index, 1);
            }

            // this.emitAccess();  // MSCENG-2083
        });
    }

    updatePermissionToAddUsers(event?: any): void {
        // event.stopPropagation();
        const params = {
            groupManageUser: this.allowMemberAssignment
        }
        this.permissionLoading = true;
        this.adminGroupProvider.updateManagerPermission(this.company.id, this.user.teams[0].id, this.user.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.group.user-permission-update'));
            this.permissionLoading = false;
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occured'));
        });
    }

    emitAccess(): void {
        if (this.instances.length === 0) {
            this.emitterService.set('update.access', { role: 'companyManager', enable: false });
        }
    }
}
