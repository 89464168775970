import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CompanySettings, Company } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { addClass, removeClass } from '@functions';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-organize-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss']
})

export class OrganizeCompanyComponent {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private currentRoute: string;
    public company: Company;
    public settings: CompanySettings;
    public access: any;
    public navData: Array<any>;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.settings = this.route.snapshot.data.settings;
        this.company = this.storageService.get('company');
        this.access = this.storageService.get('access');
        this.navData = this.getNav();

        if (this.storageService.get('overlayStatus')) {
            removeClass('.app-main', 'disable-overflow');
            removeClass('.overlay', 'force-active');
        }

        this.subscriptionCollection.subscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setCurrentRoute();
            }
        });

        this.setCurrentRoute();
    }

    ngOnDestroy(): void {
        if (this.storageService.get('overlayStatus')) {
            addClass('.app-main', 'disable-overflow');
            addClass('.overlay', 'force-active');
        }

        this.storageService.delete('companySettings');
    }

    setCurrentRoute(): void {
        this.currentRoute = this.router.url.split('/')[4];
    }

    isActivatedRoute(route: string): boolean {
        return this.currentRoute && route.indexOf(this.currentRoute) > -1;
    }

    /**
     * Get the nav data of the page
     */
    getNav(): Array<any> {
        const data = [
            {
                label: this.translateService.instant(`words.settings.general`),
                value: {
                    routerLink: 'settings/general'
                }
            },
            {
                label: this.translateService.instant(`words.settings.advanced`),
                value: {
                    routerLink: 'settings/advanced'
                }
            },
        ];
        if (this.settings.billingType === 'self_service' || this.settings.billingType === 'demo') {
            data.push({
                label: this.translateService.instant(`words.billing`),
                value: {
                    routerLink: 'subscription',
                }
            });
            data.push({
                label: this.translateService.instant(`words.invoices`),
                value: {
                    routerLink: 'invoice'
                }
            })
        }

        return data;
    }
}
