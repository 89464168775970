import { Component, OnInit, EventEmitter } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { Company, CompanyExperience, CompanyExperienceProvider, Media } from '@lighty';
import { StorageService, TranslateService } from '@services';

@Component({
    selector: 'msc-organize-experience-watch-catalogue',
    templateUrl: './catalogue.component.html'
})
export class OrganizeExperienceWatchCatalogueComponent implements OnInit {
    private company: Company;
    public experience: CompanyExperience;
    public loadEmitter: EventEmitter<any> = new EventEmitter();
    public loading: boolean = false;

    constructor(
        private companyExperienceProvider: CompanyExperienceProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.experience = this.storageService.get('currentExperience');
    }

    update(): void {
        this.companyExperienceProvider.update(this.company.slug, this.experience.id, this.experience).subscribe((data) => {
            this.experience = this.storageService.set('currentExperience', data);
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.loading = false;
        });
    }

    load(): void {
        this.loading = true;
    }

    loadPicture(): void {
        this.loadEmitter.emit(true);
    }

    updatePicture(media: Media): void {
        this.experience.catalogueMediaId = media.id;
        this.update();
    }

    deletePicture(): void {
        this.experience.catalogueMediaId = null;
        this.update();
    }
}
