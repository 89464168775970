import { Injectable, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
//
import { Subject } from 'rxjs';

@Injectable()
export abstract class ComponentFormModel {
    public form: FormGroup;
    protected destroy$: Subject<void> = new Subject<void>();
    protected repeat$: Subject<void> = new Subject<void>();
    //
    protected abstract getFormGroup(): FormGroup;
    protected abstract submitEvent: EventEmitter<any>;

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the form group
     */
    setFormGroup(patch: Record<string, any> = {}): void {
        this.form = this.getFormGroup();
        this.form.patchValue(patch);
        this.form.markAsPristine();
        this.form.markAsUntouched();
    }

    /**
     * Event handler for form reset
     */
    _onReset(): void {
        this.setFormGroup({});
    }

    /**
     * Event handler for form submit
     */
    _onSubmit(formData: Record<string, any>): void {
        this.submitEvent.emit(formData);
    }
}
