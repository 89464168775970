import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//
import { environment } from '@env/environment';
//
import { map } from 'rxjs/operators';
//
import { HttpUtils } from '@common2/utils/http.utils';

@Injectable({ providedIn: 'root' })
export class UserContentProvider {
    private readonly rootCommon = `${environment.envVar.API_URL}/common`;

    constructor(
        private readonly http: HttpClient,
    ) { }

    /**
     *
     */
    getUserCourse(courseId: number, userId: number, params: any = {}) {
        return this.http.get<any>(`${this.rootCommon}/courses/${courseId}/accounts/${userId}/activities`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<any>) => response.body)
        );
    }

    /**
     *
     */
    getUserTraject(trajectId: number, userId: number, params: any = {}) {
        return this.http.get<any>(`${this.rootCommon}/trajects/${trajectId}/accounts/${userId}/contents`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<any>) => response.body)
        );
    }
}
