import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Inject,
    Injector,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export type MscTextInputTypes = 'text' | 'password' | 'email';
export type MscPaletteTypes = 'slate' | 'primary' | 'secondary';

/**
 * Msc design button.
 */
@Component({
    selector: `msc-input`,
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CommonInputComponent),
            multi: true
        }
    ]
})
export class CommonInputComponent implements OnInit, ControlValueAccessor {
    @Input() autocomplete: string;
    @Input() type: MscTextInputTypes;
    @Input() name: string;
    @Input() label: string;
    @Input() placeholder: string = '';
    @Input() pattern: RegExp;
    @Input() icon: string;
    @Input() iconRight: string;
    @Input() hints: Array<string>;
    @Input() helperText: string;
    @Input() clearBtn: boolean;
    @Input() hasErrors: boolean;
    @Input() hasSuccess: boolean;
    @Input() hasWarning: boolean;
    @Input() disabled = false;
    @Input() palette: MscPaletteTypes = 'secondary';
    @ViewChild('inputElement') inputElement;
    touched = false;
    initialType: MscTextInputTypes;

    value: string;
    requiredValidator = Validators.required;

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(
        @Inject(Injector) private injector: Injector,
    ) { }

    get formField(): FormControl {
        try {
            return (this.injector.get(NgControl)).control as FormControl;
        } catch (e) {
            return null;
        }
    }

    ngOnInit(): void {
        this.initialType = this.type;
    }

    public focusInput() {
        setTimeout(() => {
            this.inputElement.nativeElement.focus();
        }, 0);
    }

    toggleInputType() {
        this.type = this.type === 'password' ? 'text' : 'password';
    }

    writeValue(value: string): void {
        this.value = value;
        this.onChange(this.value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}