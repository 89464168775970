import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
    selector: 'msc-common-led',
    templateUrl: './led.component.html',
    styleUrls: ['./led.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonLedComponent {
    @Input() label?: string;
    @Input() isOn: boolean = true;

    private destroy$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
