import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, Company, CompanyUserProvider, Account, Camp, CompanyExperience, AdminGroupUserProvider, StatsProvider } from '@lighty';
import { StorageService, EmitterService, LoaderService, TranslateService, ErrorStreamService, ExternalAppsService } from '@services';
import { DataHelper, HttpHelper } from '@helpers';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { BehaviorSubject, finalize, firstValueFrom, map, mergeScan, of, repeat, Subject, takeUntil, tap } from 'rxjs';
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonMenuItem } from '@common2/components/overlay-menu/overlay-menu.component';

const ROLES_FILTER = [
    {
        label: 'words.learn',
        value: 'company_learner'
    }, {
        label: 'words.create',
        value: 'company_author'
    }, {
        label: 'words.coach',
        value: 'company_manager'
    }, {
        label: 'words.organise',
        value: 'company_administrator'
    }
];

@Component({
    selector: 'msc-organize-user-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})

export class OrganizeUserListComponent {
    @ViewChild('modalResetPassword') modalResetPassword;
    @ViewChild('modalDeleteUser') modalDeleteUser;
    public company: Company;
    public me: any;
    public pagination: Pagination;
    public paginationAvailableUsers: Pagination;
    public users: any[];
    public availableUsers: any[];
    public availableExperiences: CompanyExperience[];
    public availableCamps: Camp[];
    public queries: any;
    public dataFilters: any = {};
    public filters: any;
    public isFilterActive: boolean = false;
    public selectedUsers: any[] = [];
    public excludedUsers: any[] = [];
    public displays: any;
    public selectedUser: any;
    public counts: any;
    public allGroupsSelected: boolean = false;
    public allUsersSelected: boolean = false;
    public loading: boolean = false;
    public loadingAvailableUsers: boolean = false;
    public selectAll: boolean = false;
    public selectGroupsOptions: any = [];
    public addUserMenu: Array<CommonMenuItem>;
    public hasInvitations = false;

    // new
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public query$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore = false;
    public total: number;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private companyUserProvider: CompanyUserProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private loaderService: LoaderService,
        private groupUserProvider: AdminGroupUserProvider,
        private translateService: TranslateService,
        private statsProvider: StatsProvider,
        private errorStreamService: ErrorStreamService,
        private externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit(): void {
        this.setContent();
        this.resetDisplays();
        this.getMeta();
        this.onQueryContentList();
        this.getAvailableExperiences();

        this.getAvailableCamps();
        this.getInvitationsCount();

        this.emitterService.actionInvitationUser.subscribe(() => {
            this.openModal('invitation');
        });

        this.queries = {
            search: '',
            group: '',
            availableUser: []
        };

        this.route.queryParams.subscribe((params) => {
            if (params.reset) {
                this.companyUserProvider.getUser(this.company.id, params.reset).subscribe((user) => {
                    if (user) {
                        this.resetPassword(user);
                    }
                });
            }

            switch (params.actions) {
                case 'invitation':
                    this.openModal('invitation');
                    break;
                case 'massive_invitation':
                    this.openModal('massiveInvitation');
                    break;
            }
        });

        this.hasInvitations = this.isUsersInvitation();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    setFilters(): void {
        this.filters = [
            {
                name: 'camps',
                type: 'multiselect',
                label: this.translateService.instant('words.camps'),
                displayValue: '',
                options: this.availableCamps
            },
            {
                name: 'role',
                type: 'select',
                label: this.translateService.instant('words.role'),
                options: ROLES_FILTER.map((role, idx) => ({
                    id: idx,
                    label: this.translateService.instant(role.label),
                    value: role.value,
                    isSelected: false
                }))
            },
            {
                name: 'invitation_date',
                type: 'radio',
                label: this.translateService.instant('filter.invitation-date'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all'),
                    value: null,
                }, {
                    label: this.translateService.instant('filter.today'),
                    value: 'day',
                }, {
                    label: this.translateService.instant('filter.this-week'),
                    value: 'week',
                }, {
                    label: this.translateService.instant('filter.this-month'),
                    value: 'month',
                }]
            },
            {
                name: 'license_status',
                type: 'radio',
                label: this.translateService.instant('user.account-status'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all'),
                    value: null,
                }, {
                    label: this.translateService.instant('words.active'),
                    value: 'active',
                }, {
                    label: this.translateService.instant('words.inactive'),
                    value: 'inactive',
                }, {
                    label: this.translateService.instant('filter.pending'),
                    value: 'reserved',
                }]
            },
            {
                name: 'invitation_status',
                type: 'radio',
                label: this.translateService.instant('filter.invitation-status'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all'),
                    value: null,
                }, {
                    label: this.translateService.instant('filter.pending'),
                    value: 'pending',
                }, {
                    label: this.translateService.instant('filter.confirmed'),
                    value: 'active',
                }]
            },
            {
                name: 'account_type',
                type: 'radio',
                label: this.translateService.instant('words.account-type'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all'),
                    value: null,
                }, {
                    label: this.translateService.instant('words.guest'),
                    value: 'guest',
                }, {
                    label: this.translateService.instant('words.user'),
                    value: 'user',
                }]
            },
        ];
    }

    private getMeta() {
        this.groupUserProvider.getUsersMeta(this.company.id).subscribe((data) => {
            this.selectGroupsOptions = this.getSelectData(data.meta.teams);
        });
    }

    getSelectData(data) {
        const selectAllOption = {
            id: 'all',
            label: this.translateService.instant('words.select-all'),
            value: undefined,
            isSelected: false
        };
        const options = data.map(d => ({
            id: d.id,
            label: d.id === 'none' ? this.translateService.instant('words.no-manager') : d.teamManager && d.teamManager.account.name,
            value: d.id === 'none' ? {} : d.teamManager && d.teamManager.account,
            isSelected: false
        })).filter(d => d.label);

        const idx = options.findIndex(opt => opt.id === 'none');
        if (idx > -1) {
            options.unshift(options.splice(idx, 1)[0])
        }

        return [selectAllOption, ...options];
    }

    getUserExperienceData(users, experiences) {
        return users.map(user => {
            user.selectExperienceData = experiences.map(exp => ({
                id: exp.id,
                label: exp.name,
                value: exp,
                isSelected: user.experienceId === exp.id
            }));

            return user;
        })
    }

    onSelectExperience(user, option) {
        user.selectExperienceData = user.selectExperienceData.map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });

        this.updateExperience(user, option.value);
    }

    getUserManagerData(user, managers, currentPage) {
        const options = managers.filter(manager => manager.account).map(manager => ({
            id: manager.id,
            label: manager.account.name,
            value: manager,
            isSelected: user.manager?.id === manager.id
        }));

        if (currentPage === 1) {
            const noManagerOption = {
                id: 'none',
                label: this.translateService.instant('words.no-manager'),
                value: null,
                isSelected: false
            };

            options.unshift(noManagerOption);
        }

        return options;
    }

    onSelectUserManager(user, option) {
        this.updateManager(user, option.value);
    }

    loadMoreAvailableManagers(user): CallableFunction {
        return (async (query, pagination) => {
            const params = {
                ...(query && { q: query }),
                accountIds: [user.id],
                page: pagination.currentPage + 1
            };

            const result = await firstValueFrom(this.companyUserProvider.getUsers(this.company.id, params));

            return {
                data: this.getUserManagerData(user, result.users, result.pagination.currentPage),
                pagination: result.pagination
            };
        })
    }

    onSelectManager(option) {
        option.isSelected = !option.isSelected;

        if (option.id === 'all') {
            this.selectGroupsOptions = this.selectGroupsOptions.map(d => {
                d.isSelected = option.isSelected;
                return d;
            });
        } else {
            this.selectGroupsOptions = [...this.selectGroupsOptions];
        }

        this.allGroupsSelected = this.selectGroupsOptions.every(group => group.isSelected);
        this.dataFilters.groupIds = this.selectGroupsOptions.filter(group => group.isSelected).map(group => group.id).join(',')

        this.onFilter();
    }

    private getCampsOptions(camps) {
        return camps.map((camp, idx) => ({
            id: idx,
            label: camp.name,
            value: camp.id,
            isSelected: false
        }));
    }

    private getAvailableCamps(): void {
        this.companyUserProvider.getCamps(this.company.id).subscribe((camps) => {
            this.availableCamps = this.getCampsOptions(camps);
            this.setFilters();
        });
    }

    private getAvailableExperiences(): void {
        this.companyUserProvider.getExperiences(this.company.slug).subscribe((data) => {
            this.availableExperiences = data;
            this.getUserExperienceData(this.rows$.getValue(), data);
        });
    }

    private getInvitationsCount(): void {
        this.companyUserProvider.getInvitationsCount(this.company.id).subscribe((data) => {
            this.counts = data;
            if (this.counts) {
                this.addUserMenu.push({
                    iconLeft: 'icon-email',
                    label: this.translateService.instant('words.reinvite'),
                    callback: this.openModal.bind(this, 'massiveInvitation')
                })
            }
        });
    }

    private resetToDefaultState(): void {
        this.queries = {
            search: '',
            availableUser: []
        };

        this.allGroupsSelected = false;
        this.selectedUsers = [];

        this.onSearch({ value: '', data: null });
    }

    searchAvailableUsers(index, accountIds, query?): void {
        this.queries.availableUser[index] = query;
        const params = HttpHelper.cleanParams({
            q: this.queries.availableUser[index],
            accountIds
        });

        this.loadingAvailableUsers = true;
        this.availableUsers = [];
        this.companyUserProvider.getUsers(this.company.id, params).subscribe((data) => {
            this.loadingAvailableUsers = false;
            this.availableUsers = data.users;
            this.paginationAvailableUsers = data.pagination;
        });
    }

    openModal(type: string): void {
        this.displays.modals[type] = true;
    }

    closeModal(type: string): void {
        this.displays.modals[type] = false;
    }

    // loadMore(): void {
    //     this.loaderService.run();
    //     this.loading = true;

    //     const params = HttpHelper.cleanParams({
    //         q: this.queries.search,
    //         groupIds: this.allGroupsSelected ? '' : this.selectedGroups.map(group => group.id).join(','),
    //         camp_ids: this.dataFilters.camps && this.dataFilters.camps.length > 0 ? this.dataFilters.camps : null,
    //         role: this.dataFilters.role,
    //         invitation_status: this.dataFilters.invitation_status ? this.dataFilters.invitation_status : null,
    //         invitation_date: this.dataFilters.invitation_date ? this.dataFilters.invitation_date : null,
    //         license_status: this.dataFilters.license_status ? this.dataFilters.license_status : null,
    //         page: this.pagination.currentPage + 1
    //     });

    //     this.companyUserProvider.getUsers(this.company.slug, params).subscribe((data) => {
    //         this.pagination = data.pagination;
    //         this.getUserExperienceData(data.users, this.availableExperiences);
    //         data.users.map((user) => {
    //             this.rows$.getValue().push(user);
    //         });
    //         this.hasInvitations = this.isUsersInvitation();
    //         this.loading = false;
    //     });
    // }

    displayed(index: number): void {
        const enabler = this.displays.more[index];
        this.resetDisplays();
        this.displays.more[index] = !enabler;
    }

    displayedActionsDropdown(index: number): void {
        const enabler = this.displays.dropdowns.actions[index];
        this.resetDisplays();
        this.displays.dropdowns.actions[index] = !enabler;
    }

    displayedInvitationDropdown(): void {
        const enabler = this.displays.dropdowns.invitation;
        this.resetDisplays();
        this.displays.dropdowns.invitation = !enabler;
    }

    selectUser(user: any) {
        const index = this.selectedUsers.findIndex(item => item.account.id === user.account.id);
        if (index !== -1) {
            this.selectedUsers.splice(index, 1);
        } else {
            this.selectedUsers.push(user);
        }

        this.allUsersSelected = this.selectedUsers.length === this.pagination.total;
    }

    excludeUser(user: any) {
        const index = this.excludedUsers.findIndex(item => item.account.id === user.account.id);
        if (index !== -1) {
            this.excludedUsers.splice(index, 1);
        } else {
            this.excludedUsers.push(user);
        }
    }

    toggleSelectedUsers() {
        if (this.selectedUsers.length > 0) {
            this.selectedUsers = [];
            return;
        }
        this.excludedUsers = [];
        this.selectAll = !this.selectAll;
    }

    isSelected(user: any): boolean {
        return this.selectedUsers.findIndex(item => item.account.id === user.account.id) > -1;
    }

    isExcluded(user: any): boolean {
        return this.excludedUsers.findIndex(item => item.account.id === user.account.id) > -1;
    }

    updateManager(user: any, availableUser: any): void {
        const params = {
            manager_account_id: (availableUser) ? availableUser.account.id : 0
        };

        this.companyUserProvider.updateUser(this.company.id, user.id, params).subscribe(() => {
            user.manager = availableUser ? availableUser.account : null;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.getMeta();
        });
    }

    updateExperience(user: any, experience: any): void {
        const params = {
            experience_id: experience.id
        };

        this.companyUserProvider.updateUser(this.company.id, user.id, params).subscribe(() => {
            user.experience = experience;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));

            if (user.account.id === this.me.id) {
                this.emitterService.refreshExperience.emit(true);
            }
        });
    }

    editProfile(accountId: number): void {
        this.storageService.set('editProfile', accountId);
        this.router.navigate(['account', accountId, 'profile']);
    }

    displayHistory(accountId: number): void {
        this.storageService.set('editProfile', accountId);
        this.router.navigate(['account', accountId, 'history']);
    }

    conversationWith(user: any): void {
        this.storageService.set('conversationUsers', [user]);
        this.router.navigate(['conversation/create']);
    }

    manageRoles(account: Account): void {
        this.router.navigate(['account', account.id, 'access']);
    }

    manageInvitations(user: any): void {
        this.selectedUser = user;
        this.displays.modals.manageInvitation = true;
    }

    resetPassword(user: any): void {
        this.selectedUser = user;
        this.modalResetPassword.onOpen(user);
    }

    openModalDeleteUser(modal: CommonModalComponent, user: any): void {
        this.selectedUser = user;
        modal.onOpen();
    }

    deleteUser(modal: CommonModalComponent): void {
        modal.onClose();

        this.companyUserProvider.deleteUser(this.company.id, this.selectedUser.id).subscribe(() => {
            const index = this.rows$.getValue().findIndex((user) => {
                return user.id === this.selectedUser.id;
            });

            if (index !== -1) {
                this.rows$.getValue().splice(index, 1);
                this.rows$.next([...this.rows$.getValue()]);
                this.toastService.onSuccess(this.translateService.instant('toast.user-deleted'));
            }
        });
    }

    sendMail(event: any, massive: boolean = false): void {
        this.companyUserProvider.sendInvitation(this.company.slug, event).subscribe(() => {
            if (massive) {
                this.closeModal('massiveInvitation');
                this.toastService.onSuccess(this.translateService.instant('toast.invitations-sent'));
            } else {
                this.closeModal('manageInvitation');
                this.toastService.onSuccess(this.translateService.instant('toast.invitation-sent'));
            }
        });
    }

    isMSC(account: any): boolean {
        if (account?.email) {
            return account.email.indexOf('@myskillcamp.com') > -1;
        }
        return false;
    }

    isUsersInvitation(): boolean {
        let foundInvitation = false;
        for (const user of this.rows$.getValue()) {
            if (user.account && user.account.type === 'invitation') {
                foundInvitation = true;
            }
        }

        return foundInvitation;
    }

    getStatusColor(status: string): string {
        switch (status) {
            case 'inactive':
                return 'red';
            case 'reserved':
                return 'orange';
            default:
                return 'green';
        }
    }

    getTranslateDate(user: any): string {
        const today = moment().format('YYYY-MM-DD');
        const subscriptionDate = moment(user.subscriptionDate).format('YYYY-MM-DD');
        const startDate = moment(user.accessStatus.accessStartAt).format('YYYY-MM-DD');

        if (today >= subscriptionDate && (!user.accessStatus.accessStartAt || startDate <= today)) {
            return 'words.since-the';
        }
        if (user.accessStatus.accessStartAt && today < startDate) {
            return 'words.from-the';
        }

        return null;
    }

    getAddUserMenu(): Array<CommonMenuItem> {
        return [
            {
                iconLeft: 'icon-download',
                label: this.translateService.instant('user.import-invite.title'),
                routerLink: ['action', 'invitation'],
                queryParams: { type: 'import' }
            }
        ]
    }

    getUserMenu(datum): Array<CommonMenuItem> {
        return [
            {
                iconLeft: 'icon-clock',
                label: this.translateService.instant('words.download-history'),
                callback: this.downloadHistoryWrapper.bind(this, datum.account.id)
            },
            ... (datum.account.type === 'user' || datum.account.type === 'guest') && datum.account.id !== this.me.id ? [{
                iconLeft: 'icon-message-circle',
                label: this.translateService.instant('words.discuss'),
                callback: this.conversationWith.bind(this, datum.account)
            }] : [],
            {
                iconLeft: 'icon-lock',
                label: this.translateService.instant('words.manage-roles'),
                callback: this.manageRoles.bind(this, datum.account)
            },
            ...datum.account.type === 'invitation' ? [{
                iconLeft: 'icon-person-done',
                label: this.translateService.instant('words.manage-invitation'),
                callback: this.manageInvitations.bind(this, datum)
            }] : [],
            ...datum.account.id !== this.me.id && !this.isMSC(datum.account) && this.company.mode === 'default' ? [{
                iconLeft: 'icon-lock',
                label: this.translateService.instant('words.reset-password'),
                callback: this.resetPassword.bind(this, datum)
            }] : [],
            ...datum.account.id !== this.me.id && !this.isMSC(datum.account) && (this.company.mode === 'default' || this.isMSC(this.me))   ? [{
                iconLeft: 'icon-trash-2 text-danger-500',
                label: this.translateService.instant('words.delete'),
                callback: this.openModalDeleteUser.bind(this, this.modalDeleteUser, datum)
            }] : [],
        ]
    }

    openConversationWithUsers() {
        const params = {
            accountIds: this.selectedUsers.map((user) => user.account.id),
            excludedAccountIds: this.excludedUsers.map((user) => user.account.id),
            invite_all: this.selectAll
        };

        this.groupUserProvider.createConversation(this.company.id, params).subscribe((data: any) => {
            this.router.navigate([`conversation/${data.slug}`]);
        });
    }

    sendMailToUsers(event: any): void {
        const params = {
            content: event.message,
            accountIds: this.selectedUsers.map((user) => user.account.id),
            excludedAccountIds: this.excludedUsers.map((user) => user.account.id),
            invite_all: this.selectAll
        };

        this.groupUserProvider.sendEmailToUsers(this.company.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.mail-successfully-sent'));
        });
    }

    reassignManager(event: any): void {
        const params = {
            accountIds: this.selectedUsers.map((user) => user.account.id),
            excludedAccountIds: this.excludedUsers.map((user) => user.account.id),
            managerAccountId: event.userId !== 'none' ? event.userId : null,
            notify: event.notify,
            invite_all: this.selectAll
        };

        this.groupUserProvider.updateUserTeam(this.company.slug, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-added-undefined'));
            this.resetToDefaultState();
        });
    }

    private resetDisplays(): any {
        this.displays = {
            modals: {
                invitation: false,
                massiveImport: false,
                massiveInvitation: false,
                manageInvitation: false,
                reset: false
            },
            dropdowns: {
                multiselectManagers: false,
                invitation: false,
                actions: [],
                experiences: [],
                managers: []
            },
            aside: {
                mail: false,
                ressign: false
            },
            more: []
        };
    }

    download(ev, accountId): void {
        const params = {
            type: ev.type,
            format: ev.format,
            emails: [this.storageService.get('me').email],
            accountId
        };

        this.statsProvider.getExport(this.company.id, params).subscribe({
            next: (data) => {
                this.toastService.onSuccess(this.translateService.instant('toast.send-file-mail'));
                this.errorStreamService.unlocked();
            },
            error: () => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                this.errorStreamService.unlocked();
            }
        });
    }

    downloadHistoryWrapper(accountId) {
        this.download({ format: 'xlsx', type: 'learner-personal-history' }, accountId);
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    /**
     *
     */
    getParamsOnQueryContentList(pageValue: number) {
        return {
            page: pageValue,
            ...this.query$.getValue(),
        };
    }

    /**
     *
     */
    onTapOnQueryContentList(pagination: any) {
        this.pagination = pagination;
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
        this.total = pagination.total;
    }

    /**
     *
     */
    onMapOnQueryContentList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        const filterdData = data.filter(d => d.account);
        this.getUserExperienceData(filterdData, this.availableExperiences);
        this.hasInvitations = this.isUsersInvitation();
        return filterdData.map(userItem => ({ ...userItem, userMenu: this.getUserMenu(userItem) }));
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.page$.next(1);
        const { q, ...query } = this.query$.getValue();
        this.query$.next({ ...query, q: value });
        this.onQueryContentList(false);
    }

    onFilterSave(data): void {
        const params = {
            camp_ids: data.camps && data.camps.length > 0 ? data.camps.join() : null,
            role: data.role,
            invitation_status: data.invitation_status ? data.invitation_status : null,
            invitation_date: data.invitation_date ? data.invitation_date : null,
            license_status: data.license_status ? data.license_status : null,
            account_type: data.account_type ? data.account_type : null
        };

        this.dataFilters = HttpHelper.cleanParams(DataHelper.merge(this.dataFilters, params));
        this.isFilterActive = true;
        this.onFilter();
    }

    onFilterReset() {
        this.selectGroupsOptions = this.selectGroupsOptions.map(group => {
            group.isSelected = false;
            return group
        });

        this.dataFilters = {};
        this.isFilterActive = false;
        this.onFilter();
    }

    onFilter() {
        this.page$.next(1);
        const { groupIds,
            camp_ids, role, invitation_status, invitation_date,
            license_status, account_type,
            ...query } = this.query$.getValue();
        this.query$.next({
            ...query,
            ...this.dataFilters
        });
        this.onQueryContentList(false);
    }

    /**
     *
     */
    onLoad(): void {
        if (this.isLoadingMore$.getValue() === CommonSubjectStatus.LOADING)
            return;
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryContentList();
    }

    /**
     */
    onQueryContentList(isAppend = true) {
        this.companyUserProvider
            .getUsers(this.company.slug, this.getParamsOnQueryContentList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryContentList(response.pagination)),
                map((response) => this.onMapOnQueryContentList(response.users)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.columns = this.getColumns();
        this.addUserMenu = this.getAddUserMenu();
        this.addUserMenu.push({
            iconLeft: 'icon-download',
            label: this.translateService.instant('user.update-data'),
            routerLink: ['action', 'update'],
            queryParams: { type: 'import' }
        });
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: '',
                width: 'fit-content',
                hideBreakpoint: 'sm',
            },
            {
                key: 'title',
                label: this.translateService.instant('words.name'),
                width: '20%',
            },
            {
                key: 'account-status',
                label: this.translateService.instant('user.account-status'),
                width: '15%',
                hideBreakpoint: 'sm',
            },
            {
                key: 'role',
                label: this.translateService.instant('words.role'),
                width: '10%',
                hideBreakpoint: 'sm',
            },
            {
                key: 'camp',
                label: this.translateService.instant('words.camp'),
                width: '10%',
                hideBreakpoint: 'sm',
            },
            {
                key: 'manager',
                label: this.translateService.instant('words.manager'),
                width: '15%',
                hideBreakpoint: 'sm',
            },
            {
                key: 'experience',
                label: this.translateService.instant('words.experience'),
                width: '15%',
                hideBreakpoint: 'sm',
            },
            {
                key: '',
                width: '5%',
            },
            {
                key: '',
                width: '5%',
            },
        ];
    }

    formatCustomDate(dateInput: string): string {
        const date = new Date(dateInput);
    
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    
        const day = date.getDate().toString().padStart(2, '0');
        
        const month = date.toLocaleString('en-US', { month: 'short' });
        
        const year = date.getFullYear().toString().slice(-2);
        
        return `${day} ${month} ${year}`;
      }
    
}
