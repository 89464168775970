import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Company, KnowledgeProvider, KnowledgeSkill, Pagination } from '@lighty';
import { LoaderService, StorageService, TranslateService } from '@services';
import { ActivatedRoute } from '@angular/router';
import { DataHelper, HttpHelper } from '@helpers';
import { removeClass } from '@functions';
import { CommonToastService } from '@common2/services/toast.service';

const FILTER_DATE_OPTIONS = [{
    label: 'words.addition-date',
    value: '-date',
}, {
    label: 'A-Z',
    value: 'title'
}, {
    label: 'Z-A',
    value: '-title'
}];

@Component({
    selector: 'msc-organize-knowledge-watch-content-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.scss']
})
export class OrganizeKnowledgeWatchContentAddComponent implements OnInit {
    @Output() changeMode: EventEmitter<boolean> = new EventEmitter();
    private me: any;
    private company: Company;
    public lang: string = 'En';
    public skill: KnowledgeSkill;
    public contents: any;
    public pagination: Pagination;
    public query: string;
    public selectedContents: any = [];
    public availableTypes: any[];
    public availableLanguages: any[] = [];
    public availableProviders: any;
    public availableSkills: any;
    public dataFilters: any;
    public filtersModal: any;
    public filters: any = {};
    public isFilterActive: boolean = false;
    public orderDate: any = '-date';
    public selection: boolean = false;
    public loaders: any = {
        loaded: false,
        loadMore: false
    };
    public selectData: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private knowledgeProvider: KnowledgeProvider,
        private storageService: StorageService,
        private translateService: TranslateService,
        private loaderService: LoaderService,
        private toastService: CommonToastService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');

        this.skill = this.route.parent.snapshot.data.skill;

        this.selectData = this.getSelectData(FILTER_DATE_OPTIONS);

        this.getAvailableContents();
        this.getAvailableMeta();
    }

    private getAvailableContents(): void {
        this.loaders.loaded = true;

        this.knowledgeProvider.getAvailableContents(this.company.id, this.skill.id).subscribe((data) => {
            this.contents = data.contents;
            this.pagination = data.pagination;
            this.loaders.loaded = false;
        });
    }

    getProvidersOptions(providers) {
        const label = this.company.experience
                    ? this.company.experience.displayName
                    ? this.company.experience.displayName
                    : this.company.experience.name
                    : this.company.name;

        return providers.map(provider => ({
            label: provider.id === 'company' ? label : provider.name,
            value: provider.id,
            isChecked: false
        }));
    }

    getLanguagesOptions(languages) {
        const languageIds = this.me.learningLanguages.map(lang => lang.id);

        return languages.map((lang, idx) => ({
            id: idx,
            label: lang.id === 'undefined' ? this.translateService.instant('words.multilingual') : lang.name,
            value: lang.id,
            isSelected: languageIds.includes(lang.id)
        }));
    }

    getSubTypesOptions(data: any, key: string) {
        const subtypes = data[`${key}Types`];
        if (!subtypes) {
            return [];
        }

        return Object.keys(subtypes)
            .map((subtype, idx) => ({
                id: `${subtype}-${idx}`,
                label: this.translateService.instant(`words.course-type.${subtype}`),
                value: subtype,
                isSelected: false
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getTypesOptions(data: any) {
        return Object.keys(data.contentTypes)
            .map((type, index) => ({
                id: `${type}-${index}`,
                label: this.translateService.instant(`api.content-type.${type.split(/(?=[A-Z])/).join('_').toLowerCase()}`),
                value: type.split(/(?=[A-Z])/).join('_').toLowerCase(),
                isSelected: false,
                children: this.getSubTypesOptions(data, type)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    private getAvailableMeta(): void {
        this.knowledgeProvider.getAvailableMeta(this.company.id, this.skill.id).subscribe((meta) => {
            this.availableSkills = meta.contentSkills;
            this.availableTypes = this.getTypesOptions(meta);
            this.availableLanguages = this.getLanguagesOptions(meta.languages);
            this.availableProviders = this.getProvidersOptions(meta.providers);

            this.setFilters();
        });
    }

    getOrderDate(): string {
        switch (this.orderDate) {
            case '-date':
                return 'words.addition-date';
            case 'title':
                return 'A-Z';
            case '-title':
                return 'Z-A';
        }
    }

    getSelectData(data) {
        return data.map((d, index) => {
            const id = index;
            const label = d.label;
            const value = d.value;
            const isSelected = d.label === this.getOrderDate();
            return {id, label, value, isSelected};
        });
    }

    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });

        this.selectOrderDate(option.value);
    }

    private setFilters(): void {
        this.filtersModal = [
            {
                name: 'langs',
                type: 'multiselect',
                label: this.translateService.instant('words.languages'),
                displayValue: this.availableLanguages.filter(lang => lang.isSelected).map(lang => lang.label).join(', '),
                options: this.availableLanguages
            },
            {
                name: 'providers',
                type: 'checkbox',
                label: this.translateService.instant('words.providers'),
                options: this.availableProviders
            },
            {
                name: 'skill',
                type: 'skill',
                label: this.translateService.instant('words.skill'),
                selected: { skill: null, level: [] },
                query: null,
                displayLimit: 12,
                options: this.availableSkills
            }
        ]
    }

    showSelection(): void {
        this.selection = !this.selection;

        if (this.selection) {
            this.contents = Object.assign([], this.selectedContents);
        } else {
            this.search();
        }
    }

    getType(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'openclassroom':
                        case 'cefora':
                        case 'udemy':
                        case 'coursera':
                            return 'words.template';
                        case 'bookboon':
                            return 'words.ebook';
                        default:
                            return content.data.provider.name;
                    }
                } else {
                    return 'words.template';
                }
            case 'traject':
                return 'words.journey';
            default:
                return 'words.template';
        }
    }

    getColor(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'bookboon':
                            return 'orange';
                        default:
                            return 'purple';
                    }
                } else {
                    return 'purple';
                }
            case 'traject':
                return 'red';
        }
    }

    getLogo(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (!content.data.provider.logo) {
                    return 'https://static.myskillcamp.com/images/integrations/' + content.data.provider.key + '.svg';
                } else {
                    return content.data.provider.logo + '?size=128';
                }
            case 'external_video':
                return 'https://static.myskillcamp.com/images/integrations/youtube.svg';
        }
    }

    search(query?): void {
        this.query = query;
        this.loaderService.run();

        const params = HttpHelper.cleanParams({
            q: this.query,
            content_types: this.filters.contentTypes ? this.filters.contentTypes.map((item) => DataHelper.changeCase(item)).join() : null,
            course_types: this.filters.courseTypes ? this.filters.courseTypes.join() : null,
            external_content_types: this.filters.externalContentTypes ? this.filters.externalContentTypes.join() : null,
            external_resource_types: this.filters.externalResourceTypes ? this.filters.externalResourceTypes.join() : null,
            languages: this.dataFilters?.langs ? this.dataFilters.langs.join(',') : null,
            providers: this.dataFilters?.providers ? this.dataFilters.providers.join(',') : null,
            order: this.filters ? this.filters.order : null,
            'skills[]': this.dataFilters?.skill && this.dataFilters?.skill.id ? this.dataFilters?.skill.id : null,
            'skill_level[]': this.dataFilters?.skill && this.dataFilters.skill.level.length > 0 ? this.dataFilters.skill.level : null
        });

        this.knowledgeProvider.getAvailableContents(this.company.id, this.skill.id, params).subscribe((data) => {
            this.contents = data.contents;
            this.pagination = data.pagination;
        });
    }

    loadMore(): void {
        this.loaders.loadMore = true;

        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.currentPage + 1,
            content_types: this.filters.contentTypes ? this.filters.contentTypes.map((item) => DataHelper.changeCase(item)).join() : null,
            course_types: this.filters.courseTypes ? this.filters.courseTypes.join() : null,
            external_content_types: this.filters.externalContentTypes ? this.filters.externalContentTypes.join() : null,
            external_resource_types: this.filters.externalResourceTypes ? this.filters.externalResourceTypes.join() : null,
            languages: this.dataFilters?.langs ? this.dataFilters.langs.join(',') : null,
            providers: this.dataFilters?.providers ? this.dataFilters.providers.join(',') : null,
            order: this.filters ? this.filters.order : null,
            'skills[]': this.dataFilters?.skill && this.dataFilters?.skill.id ? this.dataFilters?.skill.id : null,
            'skill_level[]': this.dataFilters?.skill && this.dataFilters.skill.level.length > 0 ? this.dataFilters.skill.level : null
        });

        this.knowledgeProvider.getAvailableContents(this.company.id, this.skill.id, params).subscribe((data) => {
            this.contents = this.contents.concat(data.contents);
            this.pagination = data.pagination;
            this.loaders.loadMore = false;
        });
    }

    selectContent(content: any): void {
        const index = this.selectedContents.findIndex((cont) => {
            return cont.data.id === content.data.id;
        });

        if (index > -1) {
            this.selectedContents.splice(index, 1);
        } else {
            this.selectedContents.push(content);
        }
    }

    isSelected(content: any): boolean {
        return this.selectedContents.findIndex((cont) => {
            return cont.data.id === content.data.id;
        }) > -1;
    }

    cancel(): void {
        this.changeMode.emit(true);
    }

    validate(): void {
        const params = {
            contents: []
        };

        for (const content of this.selectedContents) {
            params.contents.push({ context: content.type, contextId: content.data.id });
        }

        this.knowledgeProvider.addContent(this.company.id, this.skill.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant(this.selectedContents.length > 1 ? 'toast.skill.contents.added' : 'toast.skill.content.added'));
            this.cancel();
        });
    }

    onFilterSave(data): void {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.search(this.query);
    }

    onFilterReset(): void {
        this.availableTypes = this.availableTypes.map(type => {
            type.isSelected = false
            type.children.map(subtype => {
                subtype.isSelected = false;
                return subtype;
            });
            return type;
        });

        this.dataFilters = {};
        this.filters = {};
        this.isFilterActive = false;
        this.search(this.query);
    }

    getSubTypesFilters(key: string) {
        const subTypes = this.availableTypes.find(t => t.value === key && t.isSelected);

        return subTypes ? subTypes.children.filter(t => t.isSelected).map(t => t.value) : [];
    }

    setTypeFilters(): void {
        this.filters = DataHelper.merge(this.filters, { contentTypes: this.availableTypes.filter(t => t.isSelected).map(t => t.value) });
        this.filters = DataHelper.merge(this.filters, { courseTypes: this.getSubTypesFilters('course') });
        this.filters = DataHelper.merge(this.filters, { externalContentTypes: this.getSubTypesFilters('external_content') });
        this.filters = DataHelper.merge(this.filters, { externalResourceTypes: this.getSubTypesFilters('external_resource') });
        this.isFilterActive = true;
        this.search();
    }

    getIterativeCheck(parent: any, selected: any) {
        if (parent.children && parent.children.length) {
            parent.children = parent.children.map((child: any) => {
                if (parent.id === selected.id) {
                    child.isSelected = parent.isSelected;
                }
                return child;
            });
            return parent;
        } else {
            return parent;
        }
    }

    onSelectType(option) {
        option.isSelected = !option.isSelected
        this.availableTypes = this.availableTypes.map(type => this.getIterativeCheck(type, option));
        this.setTypeFilters();
    }

    selectOrderDate(order: string): void {
        this.orderDate = order;
        this.filters = DataHelper.merge(this.filters, { order: this.orderDate });
        this.isFilterActive = true;

        this.search();
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }
}
