<div class="col-8 col-md-8 col-xxs-12 container__main animate presentation">
    <div class="container__desc">
        <div class="block-course">
            <div class="block-course__desc">
                <div>
                    <div class="flex between">
                        <h2 class="block-course__title">{{ template.title }}</h2>
                        <msc-tag [text]="'words.template' | translate"></msc-tag>
                    </div>

                    <p readMore #readMoreEl="readMore" class="inner-html"
                        [innerHTML]="template.description ? (template.description | safe:'html') : ('words.undescribed-content' | translate)">
                    </p>
                    <a *ngIf="readMoreEl.overflow" (click)="readMoreEl.toggleExtend()">{{ 'words.read-more.' +
                        readMoreEl.extended | translate }} <span><i class="icon icon-arrow-ios-down"
                                [class.icon-arrow-ios-up]="readMoreEl.extended"
                                [class.icon-arrow-ios-down]="!readMoreEl.extended"></i></span></a>
                </div>
            </div>

            <div *ngIf="template.onDemand" (click)="modalEnableOnDemand.onOpen()"
                class="block-course__start flex justify-end">
                <msc-button class="msc-button--primary" palette="secondary">
                    {{ 'content.on-demand.ask-session' | translate }}
                </msc-button>
            </div>
        </div>
    </div>

    <div class="container__list" *ngIf="noSession">
        <msc-empty-state [title]="'words.content'" [description]="'empty.full-sessions'" [hasPicture]="false"
            [source]="'icon-content'"></msc-empty-state>
    </div>

    <div class="container__list"
        *ngIf="(template.courseCamps && template.courseCamps.length === 0) || !template.courseCamps">
        <msc-empty-state [title]="'words.content'" [description]="'empty.content'" [hasPicture]="false"
            [source]="'icon-content'">></msc-empty-state>
    </div>

    <div class="container__list">
        <div class="container__item" *ngFor="let data of template?.courseCamps; let index=index">
            <div class="block-date-presentation" [ngClass]="{'active' : sessionsDropdown[index]}">
                <div class="block-date-presentation__subscription text-color-500" *ngIf="data.subscriptionDeadline">
                    <i class="icon icon-alert-triangle"></i>
                    {{ 'subscription.subscription-deadline' | translate }}
                    <span>{{ data.subscriptionDeadline | date:'dd MMM YYYY'}}</span>
                </div>

                <div class="block-date-presentation__header">
                    <div class="block-date-presentation__infos">
                        <msc-tag [type]="'date'" [day]="data.nextEvent | date:'dd'"
                            [month]="data.nextEvent | localizedDate:'MMM'" *ngIf="data.nextEvent">
                        </msc-tag>

                        <div class="block-date-presentation__desc">
                            <p class="block-date-presentation__title">
                                {{ (data.internalName | truncate:30) || ('words.untitled' | translate ) }}
                            </p>

                            <p class="block-date-presentation__camp">{{ data.templateTitle || ('words.untitled' |
                                translate) }}</p>
                            <p class="block-date-presentation__events" *ngIf="data.events">
                                <i class="icon icon-calendar"></i> <span class="text-primary-500">{{ data.events.length
                                    }}</span>
                                {{ data.events.length > 1 ? ('words.events' | translate) : ('words.event' | translate)
                                }}
                            </p>
                        </div>
                    </div>

                    <div class="block-date-presentation__stats">
                        <span><i class="icon icon-people"></i>{{ data.usedSeats }}</span>
                        <span><i class="icon icon-globe"></i>{{ data.language.name }}</span>
                    </div>

                    <div class="block-date-presentation__actions">
                        <msc-button class="msc-button--primary" palette="primary" *ngIf="data.state_button === 'start'"
                            (click)="goToPlayer(data)" [loading]="loader.registration">
                            {{ 'words.start' | translate }}
                        </msc-button>

                        <msc-button class="msc-button--primary" palette="primary" (click)="subscribeToContent(data)"
                            [disabled]="data.limit && data.usedSeats >= data.limit" [loading]="loader.registration"
                            *ngIf="!data.session && data.state_button !== 'start-course' && data.state_button !== 'subscribe-again' && (data.usedSeats < data.limit || data.limit <= 0)">
                            <ng-container *ngIf="data.limit && data.usedSeats >= data.limit">{{
                                'subscription.limit-reach' |
                                translate }}</ng-container>
                            <ng-container *ngIf="!data.limit || data.usedSeats < data.limit">{{
                                'words.request-registration' |
                                translate }}</ng-container>
                        </msc-button>

                        <msc-button class="msc-button--primary" color="primary" *ngIf="data.usedSeats >= data.limit && data.limit > 0 &&
                                        data.state_button !== 'start-course' && data.state_button !== 'start'"
                            [disabled]="data.usedSeats >= data.limit && data.limit > 0">
                            {{ 'subscription.limit-reach' | translate }}
                        </msc-button>

                        <msc-button class="msc-button--primary" color="primary"
                            [disabled]="(data.state_locked) || checkSubscriptionDeadline(data.subscriptionDeadline)"
                            [loading]="loader.registration"
                            *ngIf="data.state_button === 'start-course' && !data.session?.validationStatus"
                            (click)="subscribeToContent(data, true)">
                            {{ 'words.subscribe' | translate }}
                        </msc-button>

                        <div [tooltip]="'instance.locked-course' | translate"
                            *ngIf="data.state_button === 'locked' && data.session && data.session?.validationStatus === 1">
                            <i class="icon icon-lock color-gray"></i>
                        </div>

                        <div [tooltip]="'words.subscription-pending' | translate"
                            *ngIf="data.state_button === 'pending' && data.session && data.session?.validationStatus === null">
                            <i class="icon icon-clock-1 color-blue"></i>
                        </div>

                        <div [tooltip]="'words.subscription-refused' | translate"
                            *ngIf="data.state_button === 'subscribe-again' && data.session && data.session?.validationStatus === 0">
                            <i class="icon icon-close color-red"></i>
                        </div>

                        <msc-button class="msc-button--primary" palette="primary"
                            *ngIf="data.state_button === 'subscribe-again' && data.session && data.session?.validationStatus === 0"
                            [loading]="loader.registration"
                            (click)="subscribeToContent(data)">
                            <ng-container *ngIf="data.limit && data.usedSeats >= data.limit">{{
                                'subscription.limit-reach' |
                                translate }}</ng-container>
                            <ng-container *ngIf="!data.limit || data.usedSeats < data.limit">{{
                                'course.subscription.open.again'
                                | translate }}</ng-container>
                        </msc-button>

                        <div class="block-date-presentation__toggle" *ngIf="data.events && data.events.length > 0"
                            (click)="openSession(index)">
                            <i class="icon icon-arrow-ios-down-1"></i>
                        </div>
                    </div>
                </div>

                <div class="block-date-presentation__body" *ngIf="sessionsDropdown[index]">
                    <div class="block-date-presentation__icons">
                        <p class="session__activity" *ngIf="data.resume.media">
                            <i class="icon icon-play-circle"></i>
                            <span>{{ data.resume.media }}</span>{{ data.resume.media > 1 ? ('words.medias' | translate)
                            : ('words.media' | translate)}}
                        </p>

                        <p class="session__activity" *ngIf="data.resume.certificate">
                            <i class="icon icon-award"></i>
                            <span>{{ data.resume.certificate }}</span>{{ data.resume.certificate > 1 ?
                            ('words.certificates' | translate) : ('words.certificate' | translate)}}
                        </p>

                        <p class="session__activity" *ngIf="data.resume.exchange">
                            <i class="icon icon-message-circle"></i>
                            <span>{{ data.resume.exchange }}</span>{{ data.resume.exchange > 1 ? ('words.exchanges' |
                            translate) : ('words.exchange' | translate)}}
                        </p>

                        <p class="session__activity" *ngIf="data.resume.project">
                            <i class="icon icon-file-text"></i>
                            <span>{{ data.resume.project }}</span>{{ data.resume.project > 1 ? ('words.projects' |
                            translate) : ('words.project' | translate)}}
                        </p>

                        <p class="session__activity" *ngIf="data.resume.exercise">
                            <i class="icon icon-checkmark-square"></i>
                            <span>{{ data.resume.exercise }}</span>{{ 'words.exercise' | translate }}
                        </p>
                    </div>

                    <div class="block-date-presentation__list" *ngIf="data.events">
                        <div class="block-date-presentation__item" *ngFor="let event of data.events">
                            <div class="block-date-presentation">
                                <div class="block-date-presentation__header">
                                    <div class="block-date-presentation__header--event">
                                        <msc-tag [color]="'orange'" [type]="'date'" [day]="event.startAt | date:'dd'"
                                            [month]="event.startAt | localizedDate:'MMM'"></msc-tag>

                                        <div class="block-date-presentation__time">
                                            <p class="text-primary-500">{{ event.startAt | date:'HH:mm' }}</p>
                                            <p>{{ event.endAt | date:'HH:mm' }}</p>
                                        </div>

                                        <div class="block-date-presentation__desc">
                                            <p class="block-date-presentation__title">{{ event.title }}</p>
                                            <p class="block-date-presentation__localisation">
                                                <i class="icon icon-pin"></i>
                                                {{ event.address || ('words.empty-adress' | translate) }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="avatar-contact" *ngFor="let trainer of event.trainers">
                                        <msc-common-avatar [label]="trainer.name"
                                            [pictureURL]="trainer.picture ? (trainer.picture + '?size=64') : null">
                                        </msc-common-avatar>

                                        <div class="avatar__content">
                                            <p class="profile__name text-secondary-800">{{ trainer.name }}</p>
                                        </div>
                                    </div>

                                    <div class="block-date-presentation__add"
                                        (click)="downloadIcs(event.icsCalendar, data.session?.validationStatus)">
                                        <i class="icon icon-calendar"
                                            [ngClass]="{'color--grey': (!data.session || (data.session && data.session?.validationStatus !== 1))}"
                                            [ngStyle]="{'cursor': (data.session && data.session?.validationStatus === 1) ? '' : 'not-allowed'}"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container__item" *ngIf="template.requirement || template.target || template.purpose">
            <div class="block-course-data">
                <div *ngIf="template.purpose">
                    <p class="block-course-data__title">{{'studio.create.course.purpose' | translate}}</p>
                    <p class="block-course-data__description inner-html" [innerHTML]="template.purpose | safe:'html'">
                    </p>
                </div>
                <div *ngIf="template.target">
                    <p class="block-course-data__title">{{'studio.create.course.audience' | translate}}</p>
                    <p class="block-course-data__description inner-html" [innerHTML]="template.target | safe:'html'">
                    </p>
                </div>
                <div *ngIf="template.requirement">
                    <p class="block-course-data__title">{{'studio.create.course.requirement' | translate}}</p>
                    <p class="block-course-data__description inner-html"
                        [innerHTML]="template.requirement | safe:'html'"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<aside class="col-4 col-md-4 col-xxs-12 container__aside animate">
    <presentation-additional-details [content]="template" [backgroundImage]="template.picture ? template.picture + '?size=1024' :
    ('https://static.myskillcamp.com/lxp/images/logo-empty-state-' + template.id % 7 + '.jpg')">
    </presentation-additional-details>
</aside>

<msc-common-modal size="30vw" #modalEnableOnDemand [title]="'content.on-demand.ask-session' | translate">
    <ng-template>
        <msc-textarea [label]="'content.on-demand.modal-text' | translate" [(ngModel)]="message"
            id="commentDeleteCourse" cols="33" rows="5" [placeholder]="'placeholder.message' | translate">
        </msc-textarea>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalEnableOnDemand.onClose()">{{'common.cancel' |
                translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary"
                (click)="enableOnDemand(); modalEnableOnDemand.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>