import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Instance } from '@lighty';

@Component({
    selector: 'msc-learn-detail-instance-discussion',
    templateUrl: './discussion.component.html'
})
export class LearnDetailInstanceDiscussionComponent implements OnInit {
    public instance: Instance;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.instance = this.route.parent.snapshot.data.instance;
    }
}
