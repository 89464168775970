<div class="block-correction">
    <div class="block-correction__header" [ngClass]="{ 'active' : isOpen }">
        <div class="block-correction__title">
            <div class="tag tag--orange">
                <div class="tag__text">
                    {{ correction.tag | translate }}
                </div>
            </div>

            <p>{{ correction.title || ('words.untitled' | translate) }}</p>
            <p [innerHtml]="parse(correction.description) | safe: 'html'" class="block-correction__desc"></p>
        </div>

        <div class="block-correction__score">
            <p>{{ 'words.score' | translate }} : </p>
            <div class="block-correction__chart">
                <msc-chart-pie [value]="correction.ratio" [valueColor]="'#ED8371'" [backgroundColor]="'#FFCBC4'"></msc-chart-pie>
            </div>
        </div>

        <div class="block-correction__toggle" (click)="toggle()">
            <i class="icon icon-arrow-ios-up-1 text-secondary-500"></i>
        </div>
    </div>

    <div class="block-correction__body" *ngIf="isOpen">
        <div class="block-correction__answer">
            <h3>{{ 'correction.answer' | translate }}</h3>

            <p [innerHtml]="parse(correction.answer) | safe: 'html'" *ngIf="correction.answer"></p>

            <msc-media-manager [medias]="correction.answerMedias"></msc-media-manager>
        </div>

        <div class="block-correction__answer">
            <h3>{{ 'correction.corrector-answer' | translate }}</h3>

            <p [innerHtml]="correction.feedback"></p>
            <p *ngIf="!correction.feedback">{{ 'correction.trainer.no-feedbacks' | translate }}</p>

            <msc-media-manager [medias]="correction.correctionMedias"></msc-media-manager>
        </div>

        <div class="block-correction__feedback">
            <p>{{ 'correction.exchange-between' | translate }}</p>
            <msc-timeline [context]="correction.timelineContext" [contextId]="correction.id"></msc-timeline>
        </div>
    </div>
</div>
