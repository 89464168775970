import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Journey, Correction, CorrectionFactory } from '@lighty';

@Component({
    selector: 'msc-learn-detail-journey-correction',
    templateUrl: './correction.component.html'
})
export class LearnDetailJourneyCorrectionComponent implements OnInit {
    public journey: Journey;
    public corrections: Correction[];
    public contents: any;

    constructor(private route: ActivatedRoute, private factory: CorrectionFactory) {}

    ngOnInit(): void {
        this.journey = this.route.parent.snapshot.data.journey;
        this.contents = this.route.parent.snapshot.data.corrections;

        this.corrections = this.route.parent.snapshot.data.corrections
            .filter(content => content.corrections && content.corrections.length)
            .map(content => content.corrections)
            .map(corrections => {
                return corrections.map(correction => {
                    if (correction.corrections.length === 0) {
                        return null;
                    }
                    return this.factory.get([].concat(correction.corrections).shift(), correction.context, correction.title, correction.description);
                });
            })
            .map(corrections => {
                return corrections.filter(correction => correction);
            });
    }
}
