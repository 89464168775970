import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-organize-experience-watch-mail',
    templateUrl: './mail.component.html',
    styleUrls: ['./mail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchMailComponent {
}
