<msc-card-template *ngIf="content.type === 'course_template'"
                   [template]="content.data"
                   [saved]="content.saved ? content.saved : saved"
                   [params]="params"
                   (onEvent)="eventDispatcher($event)">
</msc-card-template>

<msc-card-instance *ngIf="content.type === 'course_instance'"
                   [instance]="content.data"
                   [saved]="content.saved ? content.saved : saved"
                   [params]="params"
                   [deleteEmitter]="deleteEmitter"
                   (onEvent)="eventDispatcher($event)">
</msc-card-instance>

<msc-card-journey *ngIf="content.type === 'traject'"
                  [journey]="content.data"
                  [saved]="content.saved ? content.saved : saved"
                  [params]="params"
                  [deleteEmitter]="deleteEmitter"
                  (onEvent)="eventDispatcher($event)">
</msc-card-journey>

<msc-card-external-content *ngIf="content.type === 'external_content' || content.type === 'external_resource' || content.type === 'external_video'"
                            [content]="content.data"
                            [type]="content.type"
                            [saved]="content.saved ? content.saved : saved"
                            [params]="params"
                            [deleteEmitter]="deleteEmitter"
                            (onEvent)="eventDispatcher($event)">
</msc-card-external-content>

<template #container></template>
