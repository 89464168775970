<section class="head">
    <h3>Wave</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Wave</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-wave></msc-common-wave>
            </div>
        </div>
    </div>
</section>
