import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { AdminGroupResourceProvider, Camp } from '@lighty';
import { StorageService, TranslateService } from '@services';

@Component({
    selector: 'msc-camp-aside-resources',
    templateUrl: './resources.component.html'
})
export class CampAsideResourcesComponent {
    @ViewChild('aside') aside;
    @Input() camp: Camp;
    public currentGroup: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    company;
    public data: any;
    public resourcesCollection: any;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public showErrorMsg: boolean = false;
    public loading: boolean = true;

    constructor(
        private toastService: CommonToastService,
        private groupResourceProvider: AdminGroupResourceProvider,
        private storageService: StorageService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.company = this.storageService.get('company');
        this.refreshListResources();
    }

    public onOpen(group?) {
        this.currentGroup = group;
        this.init();
        this.aside.onOpen();
    }

    refreshListResources(): void {
        this.data = {
            file: null,
            title: ''
        };

        this.groupResourceProvider.getResource(this.company.id, this.currentGroup.id).subscribe((data: any) => {
            this.loading = false;
            this.resourcesCollection = data.resources;
        });
    }

    // uploadFile(file: File): void  {
    //     if (file) {
    //         this.data.file = file;
    //     }
    //     this.uploadResource();
    // }

    uploadResource(media_id: number): void {
        this.loading = true;
        this.groupResourceProvider.storeResource(this.company.id, this.currentGroup.id, { media_id, title: this.data.title }).subscribe(
            () => {
                this.refreshListResources();
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            },
            () => {
                this.loading = false;
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            });
    }

    onUploadError(event): void {
        if (event.error && event.error.status === 415) {
            this.toastService.onError(this.translateService.instant('toast.error.file-type.not-supported'));
        }
    }

    deleteResource(resource): void {
        this.groupResourceProvider.deleteResource(this.company.id, this.currentGroup.id, resource.id).subscribe(() => {
            this.refreshListResources();
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    updateResource(resource): void {
        const params = {
            title: resource.title
        };

        this.groupResourceProvider.updateResource(this.currentGroup.id, resource.id, params).subscribe(() => {
            this.refreshListResources();
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    showError(): void {
        this.showErrorMsg = this.data.title === '';
    }

    close(): void {
        this.onClose.emit(true);
    }
}
