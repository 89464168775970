export const SHOWROOM_EXHIBIT_NAV_KEY_LIST: ReadonlyArray<string> = [
    'accordion',
    'aside',
    'avatar',
    'button',
    'chart',
    'checkbox',
    'empty',
    'error',
    'icons',
    'input',
    'inline-edit',
    'loader',
    'modal',
    'overlay',
    'radio',
    'search',
    'select',
    'slider',
    'spinner',
    'switch',
    'tab',
    'table',
    'tag',
    'theming',
    'textarea',
    'toast',
    'tooltip',
    'tree',
    'upload',
    'wave',
];
