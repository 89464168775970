<label *ngIf="label" class="flex text-sm mb-1 mr-2" [for]="name">{{label}}{{ formField?.hasValidator(requiredValidator) ? '*' :
    ''}}</label>
<div class="input-container flex flex-col relative" [ngClass]="palette">
    <i *ngIf="icon" class="icon--left icon {{icon}}"></i>
    <input #inputElement role="presentation" [attr.autocomplete]="autocomplete ? autocomplete : 'off'"
        [ngClass]="{'has-errors': (formField?.invalid && formField?.dirty) || hasErrors, 'has-success': hasSuccess, 'has-warning': hasWarning, 'pr-8': iconRight, 'pl-8': icon}" 
        [type]="type" [name]="name" [(ngModel)]="value" [placeholder]="placeholder" [disabled]="disabled" (input)="onChange(value)"
        (blur)="onTouched()">
    <i *ngIf="iconRight" class="icon--right icon {{iconRight}}"></i>
    <msc-button type="fab" icon="icon-close" *ngIf="value && clearBtn" (click)="value='';onChange(value)"></msc-button>
    <msc-button type="fab" [icon]="type === 'password' ? 'icon-eye-off' : 'icon-eye'" *ngIf="initialType === 'password'" (click)="toggleInputType()"></msc-button>
</div>
<div class="mt-1" *ngIf="formField?.invalid || hasErrors">
    <field-errors class="mt-1" [class.hidden]="formField?.valid" [formField]="formField"></field-errors>
</div>
<div class="hints mt-1" *ngIf="hints">
    <p class="text-black-400 text-xs leading-4" *ngFor="let hint of hints">
        <span><i class="icon icon-bulb text-sm"></i></span> <span [innerHtml]="hint"></span>
    </p>
</div>
<div class="helper-text mt-1" *ngIf="helperText">
    <p class="text-xs leading-4" [ngClass]="{'text-success-500': hasSuccess, 'text-error-500': hasErrors, 'text-danger-500': hasWarning}" [innerHtml]="helperText"></p>
</div>