import { Component, OnInit, Input } from '@angular/core';
import { Company, CompanyExperience, OverviewProvider } from '@lighty';

@Component({
    selector: 'msc-widget-resource',
    templateUrl: './resource.component.html'
})
export class WidgetResourceComponent implements OnInit {
    @Input() company: Company;
    @Input() experience: CompanyExperience;
    public resources: any[] = [];
    public loading: boolean = true;

    constructor(private overviewProvider: OverviewProvider) {}

    ngOnInit(): void {
        if (this.experience) {
            this.overviewProvider.getResources(this.company.id, this.experience.id).subscribe((data) => {
                this.resources = data.resources;
                this.loading = false;
            });
        }
    }
}
