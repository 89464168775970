<div class="container-integration__body col-xs-12 col-md-9 col-9 no-gutter">
    <div class="content-integration content-integration-exception box">
        <div class="content-integration-exception__header">
            <h3 class="content-integration-exception__title">
                {{ 'integration.links.title' | translate }}
            </h3>
        </div>

        <p class="content-integration-exception__description">
            {{ 'integration.links.description' | translate }}
        </p>

        <div class="content-integration-exception__create" *ngIf="emptyState && !loadingRules">
            <msc-button class="msc-button--primary msc-button--large" [disabled]="provider.companyProvider?.deploymentRunning || provider.companyProvider?.catalogueFetching"
                [routerLink]="['../../collections', collection?.collectionId, 'rules']">
                {{ 'integration.links.create-first' | translate }}
            </msc-button>
        </div>
        <msc-common-spinner *ngIf="loadingRules"></msc-common-spinner>


        <ng-container *ngIf="!emptyState">
            <div class="content-integration-exception__filters">
                <msc-common-search class="tiny mr-2" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

                <!-- TODO: add message when there are no skills available -->
                <ng-container *ngIf="selectData.contentSkills.length">
                    <msc-common-select class="mr-2" [data]="selectData.contentSkills" type="multi">
                        <ng-template #buttonTemplate let-data="data">
                            <button class="select-main">
                                <ng-container *ngIf="data?.length; else emptyTemplate">
                                    <span>{{ data[0].label }}</span>
                                </ng-container>
                                <ng-template #emptyTemplate>
                                    <span>{{ 'words.skills' | translate }}</span>
                                </ng-template>
                            </button>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <div class="option">
                                <msc-common2-checkbox [value]="datum.isSelected" [label]="datum.label" (changeEvent)="onSelectMultiOption(datum)"></msc-common2-checkbox>
                            </div>
                        </ng-template>
                    </msc-common-select>
                </ng-container>

                <msc-common2-checkbox [value]="filters.myRules" [label]="'integration.links.my-exceptions' | translate" (changeEvent)="search()"></msc-common2-checkbox>
            </div>

            <div class="content-integration-exception__table">
                <msc-empty-state *ngIf="!loading && contents.length === 0" [title]="'empty.available.data'"></msc-empty-state>
                <div class="dynamic-table">
                    <div class="dynamic-table__body">
                        <div class="dynamic-table__row row-radius" *ngFor="let content of contents">
                            <div class="dynamic-table__content">
                                <div class="dynamic-table__cell cell-main"
                                    [tooltip]="content.data.title?.length > 30 && content.data.skills.length > 0 ? content.data.title : ''">
                                    <span *ngIf="content.data.skills.length > 0">{{ content.data.title | truncate: 40}}</span>
                                    <span *ngIf="content.data.skills.length == 0">{{ content.data.title }}</span>
                                </div>

                                <div class="dynamic-table__cell cell-skills">
                                    <div class="skill skill--shadow-none skill--level"
                                        *ngFor="let skill of content.data.skills | slice:0:4; let i=index"
                                        [tooltip]="skill.displayName?.length > 10 ? skill.displayName : ''">
                                        <div class="skill__content">
                                            <p class="skill__text">
                                                {{skill.displayName | truncate: 10}}</p>
                                        </div>
                                    </div>
                                    <div class="skill skill--shadow-none skill--level"
                                        *ngIf="content.data.skills.length > 4"
                                        [tooltip]="content.remainingSkillsName">
                                        <div class="skill__content">
                                            <p class="skill__text">+{{content.data.skills.length - 4}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="dynamic-table__cell cell-small justify-end">
                                    <a class="cell-actions__edit" [tooltip]="'words.edit' | translate"
                                        (click)="setCurrentEdit(content);exceptionManageAside.onOpen(currentEdit)">
                                        <i class="icon icon-edit"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <msc-common-spinner *ngIf="loading"></msc-common-spinner>

            <div class="content-integration-exception__load">
                <msc-load-more [pagination]="pagination" [loading]="loading" (onLoad)="loadMore()"></msc-load-more>
            </div>
        </ng-container>
    </div>
</div>
<msc-organize-integration-aside-exception-manage #exceptionManageAside [companySlug]="company.slug" (onSave)="updateContent($event);">
</msc-organize-integration-aside-exception-manage>
