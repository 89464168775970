import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Company, Template } from '@lighty';
import { StorageService } from '@services';

const DEFAULT_PROVIDER_LIST_KEYS: ReadonlyArray<string> = [
    'appcues',
    'bookboon',
    'cefora',
    'cegos',
    'coursera',
    'edflex-blue',
    'email-apside',
    'freshchat',
    'goodhabitz',
    'gotowebinar',
    'learncube',
    'mymooc',
    'openclassroom',
    'trainingexpress',
    'udemy',
    'youtube',
    'linkedin',
    'aws',
    'learninghubz',
    'coursera-for-campus',
    'boclips',
    'netzun',
    'onementor',
    'coursera-career-academy',
    'xertify',
    'power_bi_dashboards',
    'platzi',
    'coursera-multi-programs'
];

@Component({
    selector: 'msc-card-template',
    templateUrl: './card-template.component.html',
    styleUrls: ['./card-template.component.scss']
})

export class CardTemplateComponent {
    @Input() template: Template;
    @Input() saved: boolean;
    @Input() params?: any;
    @Output() onEvent: EventEmitter<any> = new EventEmitter();
    public company: Company;

    constructor(private router: Router, private storageService: StorageService) { }

    get logo(): string { return this.getLogo(this.template); }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
    }

    navigateContent(): void {
        const url = `learn/${this.company.slug}/detail/template/${this.template.savableId ? this.template.savableId : this.template.id}`;
        this.router.navigateByUrl(url);
    }

    changeLearnLaterContent(): void {
        if (this.saved) {
            this.deleteSavedContent();
        } else {
            this.addToSavedContents();
        }
    }

    addToSavedContents(): void {
        this.saved = true;
        this.onEvent.emit('addSavedContent');
    }

    deleteSavedContent(): void {
        this.saved = false;
        this.onEvent.emit('deleteSavedContent');
    }

    getLogo(datum: any) {
        switch (true) {
            // If there is a customCampMedia , you need to display this media
            case !!datum && datum.hasOwnProperty('customCampMedia') && !!datum.customCampMedia:
                return `url(${datum?.customCampMedia?.pictureUrl}?size=256)`;
            // If there is NO customCampMedia , you need to check whether there is a provider.media
            case !!datum && datum.hasOwnProperty('provider') && !!datum.provider && datum.provider.hasOwnProperty('media') && !!datum?.provider?.media:
                // If company.id === provider.responsibleId && provider.responsibleType === 'company')  => display the company_logo and not the provider.media logo
                return this.company?.id === datum.provider.responsibleId && datum.provider.responsibleType === 'company' ?
                    `url(${this.company?.experience?.displayPicture}?size=256)` : `url(${datum.provider.media.pictureUrl}?size=256)`;
            // If there is no  provider.media  check if provider is Coursera, Goodhabitz, Bookboon etc...
            case !!datum && datum.hasOwnProperty('provider') && DEFAULT_PROVIDER_LIST_KEYS.includes(datum?.provider?.key):
                return `url(https://static.myskillcamp.com/images/integrations/${datum.provider.key}.svg)`;
            // If there is no  provider.media + NOT IN know providers , you need to display the company media image
            default:
                return `url(${this.company?.experience?.displayPicture}?size=256)`;
        }
    }
}
