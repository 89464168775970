import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, TrackByFunction } from '@angular/core';

import { Subject } from 'rxjs';

import { CommonFeedCommentDatum } from '@common/components/feed-comment/feed-comment.component';
import { COMMON_FEED_REACTIONS } from '@common/components/feed-reaction/feed-reaction.component';

export interface CommonFeedThreadDatum {
    comments?: CommonFeedCommentDatum[];
    config?: any;
    contentImage?: string;
    contentMessage: string;
    contentModified?: string | Date;
    contentReactions?: string[];
    contentTitle?: string;
    contentUrl?: string;
    contentVideo?: string;
    countComment: number;
    countReaction: number;
    dateCreation: string | Date;
    id: number;
    userAvatar: string;
    userName: string;
    userReactionId: number | undefined;
}

@Component({
    selector: 'msc-common-feed-thread',
    templateUrl: './feed-thread.component.html',
    styleUrls: ['./feed-thread.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonFeedThreadComponent {
    @Input() datum: CommonFeedThreadDatum;
    @Output() commentCreate?: EventEmitter<any> = new EventEmitter<any>();
    @Output() commentDelete?: EventEmitter<any> = new EventEmitter<any>();
    @Output() threadDelete?: EventEmitter<any> = new EventEmitter<any>();
    @Output() threadEdit?: EventEmitter<any> = new EventEmitter<any>();
    @Output() threadNotify?: EventEmitter<any> = new EventEmitter<any>();
    @Output() reactionCreate?: EventEmitter<any> = new EventEmitter<any>();
    @Output() reactionDelete?: EventEmitter<any> = new EventEmitter<any>();

    public isComment: boolean = false;
    public isComments: boolean = false;
    public isEdit: boolean = false;
    public fnOnTrackByIndex: TrackByFunction<CommonFeedCommentDatum> = (i: number) => i;

    private destroy$: Subject<void> = new Subject<void>();

    get reactionsUsed() { return this.getReactionsUsed(); }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Event handler for toggling comment
     */
    getReactionsUsed() {
        return this.datum.contentReactions.map((reaction) => {
            return `https://static.myskillcamp.com/images/animations/${reaction}.svg`
        });
    }

    /**
     * Event handler for updating reactions
     */
    onUpdateReaction(reactionId: number, isAdd: boolean = true): void {
        const reaction = COMMON_FEED_REACTIONS.find((r) => r.id === reactionId);
        if (!reaction) { return; }
        if (isAdd) { // TODO needs rework, has an shity corner case
            this.datum.contentReactions = [...this.datum.contentReactions, reaction.name];
        } else {
            this.datum.contentReactions = this.datum.contentReactions.filter((r) => r !== reaction.name);
        }
    }

    /**
     * Event handler for toggling comment
     */
    onToggleComment(): void {
        this.isComment = !this.isComment;
    }

    /**
     * Event handler for toggling comments
     */
    onToggleComments(): void {
        this.isComments = !this.isComments;
    }

    /**
     * Event handler for toggling edit
     */
    onToggleEdit(): void {
        this.isEdit = !this.isEdit;
    }

    /**
     * Event handler for comment create
     */
    onCommentCreate(params?: any): void {
        this.commentCreate && this.commentCreate.emit(params);
        this.isComment = false;
    }

    /**
     * Event handler for comment delete
     */
    onCommentDelete(params?: any): void {
        this.commentDelete && this.commentDelete.emit(params);
    }

    /**
     * Event handler for thread delete
     */
    onThreadDelete(params?: any): void {
        this.threadDelete && this.threadDelete.emit(params);
    }

    /**
     * Event handler for thread edit
     */
    onThreadEdit(params?: any): void {
        this.threadEdit && this.threadEdit.emit(params);
        this.isEdit = false;
    }

    /**
     * Event handler for thread notify
     */
    onThreadNotify(params?: any): void {
        this.threadNotify && this.threadNotify.emit(params);
    }

    /**
     * Event handler for reaction create
     */
    onReactionCreate(reactionId: number): void {
        this.reactionCreate && this.reactionCreate.emit(reactionId);
        this.onUpdateReaction(reactionId, true);
    }

    /**
     * Event handler for reaction create
     */
    onReactionDelete(reactionId: number): void {
        this.reactionDelete && this.reactionDelete.emit(reactionId);
        this.onUpdateReaction(reactionId, false);
    }
}
