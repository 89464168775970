<table class="grid text-sm text-left rtl:text-right overflow-x-auto min-w-full bg-white border border-gray-100 mb-3 shadow-sm rounded-lg">
    <thead class=" uppercase">
        <tr>
            <ng-container *ngFor="let th of thList">
                <th [style.width]="th?.width" [ngClass]="th.hideBreakpoint" scope="col"
                    class="text-gray-700  ng-star-inserted whitespace-normal py-2 px-3 lg:p-3 xl:p-3  2xl:p-3">
                    <ng-container *ngTemplateOutlet="th.template; context: { datum: th }"></ng-container>
                </th>
            </ng-container>
        </tr>
    </thead>

    <tbody cdkVirtualScrollingElement infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="0" (scrolled)="onScrollEnd.emit()" infiniteScrollTrigger [items]="rows">
        <cdk-virtual-scroll-viewport [itemSize]="70">
            <ng-container *cdkVirtualFor="let row of rows; trackBy: onTrackByIndex; let i=index">
                <tr class="row" [class.row--is-expanded]="row.isExpanded"
                    [class.clickable]="onRowClick.observers.length > 0" (click)="onRowClick.emit(row)">
                    <ng-container *ngFor="let td of tdList; let tdIndex=index">
                        <td [style.width]="td?.width" [ngClass]="thList[tdIndex].hideBreakpoint" scope="col"
                            class="text-gray-500 ng-star-inserted whitespace-normal py-2 px-3 lg:p-3 xl:p-3  2xl:p-3 ">
                            <ng-container *ngTemplateOutlet="td.template; context: { datum: row }"></ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr class="row row--expansion" *ngIf="row.isExpanded">
                    <ng-container *ngTemplateOutlet="extensionTemplate; context: { datum: row }"></ng-container>
                </tr>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </tbody>
</table>
