<div class="content-discussions__search" *ngIf="headerFilters">
    <msc-button icon="icon-options-2" (click)="timelineFiltersAside.onOpen()">
        {{ 'words.filters' | translate}}
    </msc-button>

    <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="resetTimeline()" *ngIf="timelineFiltered">
        {{ 'words.reset-filters' | translate }}
    </msc-button>
</div>

<div class="box-composer" *ngIf="!newPost && displayComposer && canDisplayComposer()">
    <msc-composer *ngIf="displayComposer"
                  [dynamic]="dynamic"
                  [autoResize]="true"
                  [me]="me"
                  [unlock]="unlock"
                  [camps]="camps"
                  [(message)]="message"
                  (messageSended)="send($event)"
                  (onSelectedCamp)="selectCamp($event)">
    </msc-composer>
</div>

<msc-empty-state *ngIf="ready && items.length === 0"
                 [title]="'words.timeline'"
                 [description]="'empty.timeline'"
                 [cypressHook]="'timeline'">
</msc-empty-state>

<msc-common-spinner *ngIf="!ready"></msc-common-spinner>

<div *ngIf="!newPost && ready">
    <msc-timeline-post *ngFor="let item of items"
                       [me]="me"
                       [post]="item"
                       [manage]="manage"
                       [timelineId]="timelineId"
                       [displayCamp]="displayCamp"
                       [context]="context"
                       [contextId]="contextId"
                       [small]="small"
                       [isAdmin]="isAdmin"
                       [customParams]="customParams"
                       (onDelete)="deletePost(item)">
    </msc-timeline-post>
</div>

<msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()" *ngIf="!newPost && ready"></msc-load-more>

<div class="new-post" [ngClass]="{'active': displayPost}" *ngIf="newPost && ready && items.length > 0">
    <div class="new-post__head">
        <div class="new-post__left">
            <msc-common-avatar [label]="items[0].account?.name" [pictureURL]="items[0].account?.picture ? (items[0].account?.picture + '?size=128') : null"></msc-common-avatar>

            <div class="new-post__message">
                <span>{{ items[0].account?.name }}</span> {{ 'timeline.new-post' | translate }} {{ items[0].createdAt | date }}
            </div>
        </div>

        <div class="new-post__right">
            <msc-tag [text]="'timeline.new-post.tag' | translate" [color]="'red'"></msc-tag>
        </div>
    </div>

    <div class="new-post__timeline" *ngIf="ready">
        <div class="box-composer" *ngIf="displayComposer && canDisplayComposer()">
            <msc-composer *ngIf="displayComposer"
                          [dynamic]="dynamic"
                          [autoResize]="true"
                          [me]="me"
                          [unlock]="unlock"
                          [camps]="camps"
                          [(message)]="message"
                          (messageSended)="send($event)"
                          (onSelectedCamp)="selectCamp($event)">
            </msc-composer>
        </div>

        <msc-timeline-post *ngFor="let item of items"
                           [me]="me"
                           [post]="item"
                           [manage]="manage"
                           [timelineId]="timelineId"
                           [displayCamp]="displayCamp"
                           [context]="context"
                           [contextId]="contextId"
                           [small]="small"
                           [isAdmin]="isAdmin"
                           (onDelete)="deletePost(item)">
        </msc-timeline-post>

        <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
    </div>

    <div class="new-post__actions">
        <i class="icon icon-arrow-ios-down" (click)="displayedPost()"></i>
    </div>
</div>

<msc-timeline-aside-filters #timelineFiltersAside
                            [meta]="meta"
                            [context]="context"
                            [loadingMeta]="loadingMeta"
                            (onSave)="filterTimeline($event)"
                            (onClose)="displays('filters')">
</msc-timeline-aside-filters>
