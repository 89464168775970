import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@services';

@Component({
    selector: 'msc-hub',
    templateUrl: './hub.component.html'
})
export class HubComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService) {}

    ngOnInit(): void {
        this.route.data.subscribe((data) => {
            if (data.type === 'checkOnboarding') {
                this.checkOnboarding();
            }
        });
    }

    private checkOnboarding(): void {
        if (!this.storageService.get('access')?.permissions?.companyAccessPlatform) {
            this.router.navigate(['../onboarding'], {relativeTo: this.route});
        } else {
            this.router.navigate(['../overview'], {relativeTo: this.route});
        }
    }
}
