import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@services';
import { ProvidersColor } from '@enums';

@Component({
    selector: 'msc-shared-stats-providers-manager',
    templateUrl: './stats-providers-manager.component.html'
})
export class SharedStatsProvidersManagerComponent implements OnInit, OnChanges {
    @Input() title: any;
    @Input() stats: any;
    @Input() providers: any;
    private active: boolean = false;
    public data: any;
    public options: any;
    public plugins: any;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        this.initDataset();
        this.initOptions();
        this.initPlugins();

        this.active = true;
    }

    ngOnChanges(): void {
        if (this.active) {
            this.initDataset();
            this.initOptions();
            this.initPlugins();
        }
    }

    private initDataset(): void {
        this.data = {
            labels: this.processData('providerName'),
            datasets: [{
                data: this.processData('count'),
                backgroundColor: this.stats.map((stat) => ProvidersColor[stat.providerName])
            }]
        };
    }

    private initOptions(): void {
        this.options = {
            legend: false,
            responsive: true,
            tooltips: {
                callbacks: {
                    title: (items) => {
                        return this.translateService.instant('words.' + items[0].label);
                    }
                },
                displayColors: false
            },
            hover: {
                mode: null
            },
            scales: {
                xAxes: [{
                    offset: true,
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: '#FFF'
                    }
                }],
                yAxes: [{
                    gridLines: {
                        borderDash: [5, 10],
                        borderDashOffset: 100,
                        drawTicks: false,
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: '#D3D5E4',
                        stepSize: 25,
                        min: 0,
                        max: 100
                    }
                }]
            }
        };
    }

    private initPlugins(): void {
        this.plugins = [{
            afterDraw: chart => {
                const ctx = chart.chart.ctx;
                const xAxis = chart.scales['x-axis-0'];
                const yAxis = chart.scales['y-axis-0'];

                xAxis.ticks.forEach((value, index) => {
                    const x = xAxis.getPixelForTick(index);
                    const image = new Image();
                    image.src = `https://static.myskillcamp.com/images/integrations/${this.processData('providerName')[index]}-picto.svg`;
                    ctx.drawImage(image, x - 12, yAxis.bottom + 5, 25, 25);
                });
            }
        }];
    }

    private processData(type: string): any {
        return this.stats.map((stat) => {
            return stat[type];
        });
    }
}
