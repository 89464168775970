import { Component, ChangeDetectionStrategy, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
    selector: 'msc-common-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonSliderComponent {
    @ViewChild("container", { static: true, read: ElementRef }) container: ElementRef;
    @HostListener("window:resize") windowResize() {
        const newCardsPerPage = this.getCardsPerPage();
        if (newCardsPerPage != this.cardsPerPage) {
            this.cardsPerPage = newCardsPerPage;
            this.initializeSlider();
            if (this.currentPage > this.totalPages) {
                this.currentPage = this.totalPages;
                this.populatePagePosition();
            }
        }
    }
    arr: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    totalCards: number = this.arr.length;
    currentPage = 1;
    pagePosition = "0%";
    cardsPerPage: number;
    totalPages: number;
    overflowWidth: string;
    cardWidth: string;
    containerWidth: number;

    ngOnInit() {
        this.cardsPerPage = this.getCardsPerPage();
        this.initializeSlider();
    }

    initializeSlider() {
        this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
        this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages *
            10}px)`;
        this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage *
            10}px) / ${this.cardsPerPage})`;
    }

    getCardsPerPage() {
        return Math.floor(this.container.nativeElement.offsetWidth / 300);
    }

    changePage(incrementor) {
        this.currentPage += incrementor;
        this.populatePagePosition();
    }

    populatePagePosition() {
        this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${10 *
            (this.currentPage - 1)}px)`;
    }
}
