<svg [attr.id]="id" width="466px" height="247px" viewBox="0 0 466 247" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Dynamic theme</title>

    <defs>
        <rect id="path-1" x="0" y="0.5" width="76" height="86" rx="4"></rect>
        <filter x="-2.6%" y="-1.2%" width="105.3%" height="104.7%" filterUnits="objectBoundingBox" id="filter-3">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.0392156863   0 0 0 0 0.0862745098   0 0 0 0 0.274509804  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>

        <rect id="path-4" x="0" y="0.5" width="76" height="86" rx="4"></rect>
        <filter x="-2.6%" y="-1.2%" width="105.3%" height="104.7%" filterUnits="objectBoundingBox" id="filter-6">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.0392156863   0 0 0 0 0.0862745098   0 0 0 0 0.274509804  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>

        <rect id="path-7" x="0" y="0.5" width="76" height="86" rx="4"></rect>
        <filter x="-2.6%" y="-1.2%" width="105.3%" height="104.7%" filterUnits="objectBoundingBox" id="filter-9">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.0392156863   0 0 0 0 0.0862745098   0 0 0 0 0.274509804  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>

        <rect id="path-10" x="0" y="0.5" width="76" height="86" rx="4"></rect>
        <filter x="-2.6%" y="-1.2%" width="105.3%" height="104.7%" filterUnits="objectBoundingBox" id="filter-12">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.0392156863   0 0 0 0 0.0862745098   0 0 0 0 0.274509804  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>

    <g id="customisation" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="group">
            <rect id="Rectangle-Copy-98" stroke="#979797" fill="#D8D8D8" x="38.5" y="98.5" width="31" height="3" rx="1.5"></rect>
            <rect id="Rectangle-Copy-99" fill="#F0F2FD" x="0" y="0" width="466" height="247" rx="6"></rect>
            <rect id="Rectangle-Copy-100" fill="#FFFFFF" x="11" y="11" width="72" height="225" rx="6"></rect>
            <rect id="Rectangle-Copy-101" fill="#FFFFFF" x="93" y="36" width="361" height="200" rx="6"></rect>
            <rect id="Rectangle-Copy-102" fill="#FFFFFF" x="93" y="11" width="361" height="18" rx="6"></rect>

            <path d="M99,36 L448,36 C451.313708,36 454,38.6862915 454,42 L454,99 L454,99 L93,99 L93,42 C93,38.6862915 95.6862915,36 99,36 Z" id="Rectangle-Copy-103" fill="#FEB88A" class="primary-color"></path>

            <g id="Group-65-Copy-23" transform="translate(106.000000, 61.000000)">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>

                <g id="layer">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-1"></use>
                    <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
                </g>

                <g id="Group-48-Copy-2" mask="url(#mask-2)">
                    <g transform="translate(4.000000, 4.500000)">
                        <g id="Group-47" stroke-width="1" fill="none" transform="translate(0.396480, 0.000000)">
                            <rect id="Rectangle" fill="#FFE3D0" x="0.60352" y="0.5" width="66" height="42" rx="3" class="primary-color--light"></rect>
                            <g id="image-2" opacity="0.548409598" transform="translate(25.603520, 14.500000)" fill="#FEB88A" class="primary-color">
                                <path d="M14.8055205,11.7612617 L10.8055592,4.33251237 C10.7562263,4.24108161 10.6545606,4.1787944 10.5378951,4.16822272 C10.4185629,4.15793676 10.306564,4.20022349 10.2362313,4.28108257 L6.43626802,8.623758 L4.03629119,7.08086391 C3.95695863,7.03000555 3.8559596,7.01200512 3.7596272,7.03114843 C3.6632948,7.05057747 3.58229558,7.10572165 3.53796267,7.18143775 L0.87132176,11.7529758 C0.819655592,11.8415494 0.825322204,11.9466947 0.885988285,12.0309825 C0.946654365,12.1152702 1.05398666,12.1667 1.16965221,12.1667 L14.5028568,12.1667 C14.6165223,12.1667 14.7225213,12.1169845 14.7835207,12.0349826 C14.8448535,11.9529806 14.8531867,11.8498353 14.8055205,11.7612617 Z" id="Path"></path>
                                <circle id="Oval" cx="5.8361856" cy="2.1667" r="2"></circle>
                            </g>
                        </g>

                        <rect id="Rectangle-Copy-25" fill="#9AA0C9" x="1" y="47.5" width="47.18112" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-30" fill="#D8DAEB" x="1" y="53.5" width="56.30016" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-31" fill="#D8DAEB" x="1" y="59.5" width="36.87264" height="3.2025" rx="1.60125"></rect>
                    </g>
                </g>
            </g>

            <g id="Group-65-Copy-24" transform="translate(192.000000, 61.000000)">
                <mask id="mask-5" fill="white">
                    <use xlink:href="#path-4"></use>
                </mask>

                <g id="Front-Layer">
                    <use fill="black" fill-opacity="1" filter="url(#filter-6)" xlink:href="#path-4"></use>
                    <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
                </g>

                <g id="Group-48-Copy-2" mask="url(#mask-5)">
                    <g transform="translate(4.000000, 4.500000)">
                        <g id="Group-47" stroke-width="1" fill="none" transform="translate(0.396480, 0.000000)">
                            <rect id="Rectangle" fill="#FFE3D0" x="0.60352" y="0.5" width="66" height="42" rx="3" class="primary-color--light"></rect>
                            <g id="image-2" opacity="0.548409598" transform="translate(25.603520, 14.500000)" fill="#FEB88A" class="primary-color">
                                <path d="M14.8055205,11.7612617 L10.8055592,4.33251237 C10.7562263,4.24108161 10.6545606,4.1787944 10.5378951,4.16822272 C10.4185629,4.15793676 10.306564,4.20022349 10.2362313,4.28108257 L6.43626802,8.623758 L4.03629119,7.08086391 C3.95695863,7.03000555 3.8559596,7.01200512 3.7596272,7.03114843 C3.6632948,7.05057747 3.58229558,7.10572165 3.53796267,7.18143775 L0.87132176,11.7529758 C0.819655592,11.8415494 0.825322204,11.9466947 0.885988285,12.0309825 C0.946654365,12.1152702 1.05398666,12.1667 1.16965221,12.1667 L14.5028568,12.1667 C14.6165223,12.1667 14.7225213,12.1169845 14.7835207,12.0349826 C14.8448535,11.9529806 14.8531867,11.8498353 14.8055205,11.7612617 Z" id="Path"></path>
                                <circle id="Oval" cx="5.8361856" cy="2.1667" r="2"></circle>
                            </g>
                        </g>
                        <rect id="Rectangle-Copy-25" fill="#9AA0C9" x="1" y="47.5" width="47.18112" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-30" fill="#D8DAEB" x="1" y="53.5" width="56.30016" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-31" fill="#D8DAEB" x="1" y="59.5" width="36.87264" height="3.2025" rx="1.60125"></rect>
                    </g>
                </g>
            </g>

            <g id="Group-65-Copy-25" transform="translate(278.000000, 61.000000)">
                <mask id="mask-8" fill="white">
                    <use xlink:href="#path-7"></use>
                </mask>

                <g id="🎨-Front-Layer">
                    <use fill="black" fill-opacity="1" filter="url(#filter-9)" xlink:href="#path-7"></use>
                    <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-7"></use>
                </g>

                <g id="Group-48-Copy-2" mask="url(#mask-8)">
                    <g transform="translate(4.000000, 4.500000)">
                        <g id="Group-47" stroke-width="1" fill="none" transform="translate(0.396480, 0.000000)">
                            <rect id="Rectangle" fill="#FFE3D0" x="0.60352" y="0.5" width="66" height="42" rx="3" class="primary-color--light"></rect>
                            <g id="image-2" opacity="0.548409598" transform="translate(25.603520, 14.500000)" fill="#FEB88A" class="primary-color">
                                <path d="M14.8055205,11.7612617 L10.8055592,4.33251237 C10.7562263,4.24108161 10.6545606,4.1787944 10.5378951,4.16822272 C10.4185629,4.15793676 10.306564,4.20022349 10.2362313,4.28108257 L6.43626802,8.623758 L4.03629119,7.08086391 C3.95695863,7.03000555 3.8559596,7.01200512 3.7596272,7.03114843 C3.6632948,7.05057747 3.58229558,7.10572165 3.53796267,7.18143775 L0.87132176,11.7529758 C0.819655592,11.8415494 0.825322204,11.9466947 0.885988285,12.0309825 C0.946654365,12.1152702 1.05398666,12.1667 1.16965221,12.1667 L14.5028568,12.1667 C14.6165223,12.1667 14.7225213,12.1169845 14.7835207,12.0349826 C14.8448535,11.9529806 14.8531867,11.8498353 14.8055205,11.7612617 Z" id="Path"></path>
                                <circle id="Oval" cx="5.8361856" cy="2.1667" r="2"></circle>
                            </g>
                        </g>
                        <rect id="Rectangle-Copy-25" fill="#9AA0C9" x="1" y="47.5" width="47.18112" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-30" fill="#D8DAEB" x="1" y="53.5" width="56.30016" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-31" fill="#D8DAEB" x="1" y="59.5" width="36.87264" height="3.2025" rx="1.60125"></rect>
                    </g>
                </g>
            </g>

            <g id="Group-65-Copy-26" transform="translate(364.000000, 61.000000)">
                <mask id="mask-11" fill="white">
                    <use xlink:href="#path-10"></use>
                </mask>

                <g id="🎨-Front-Layer">
                    <use fill="black" fill-opacity="1" filter="url(#filter-12)" xlink:href="#path-10"></use>
                    <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-10"></use>
                </g>

                <g id="Group-48-Copy-2" mask="url(#mask-11)">
                    <g transform="translate(4.000000, 4.500000)">
                        <g id="Group-47" stroke-width="1" fill="none" transform="translate(0.396480, 0.000000)">
                            <rect id="Rectangle" fill="#FFE3D0" x="0.60352" y="0.5" width="66" height="42" rx="3" class="primary-color--light"></rect>
                            <g id="image-2" opacity="0.548409598" transform="translate(25.603520, 14.500000)" fill="#FEB88A" class="primary-color">
                                <path d="M14.8055205,11.7612617 L10.8055592,4.33251237 C10.7562263,4.24108161 10.6545606,4.1787944 10.5378951,4.16822272 C10.4185629,4.15793676 10.306564,4.20022349 10.2362313,4.28108257 L6.43626802,8.623758 L4.03629119,7.08086391 C3.95695863,7.03000555 3.8559596,7.01200512 3.7596272,7.03114843 C3.6632948,7.05057747 3.58229558,7.10572165 3.53796267,7.18143775 L0.87132176,11.7529758 C0.819655592,11.8415494 0.825322204,11.9466947 0.885988285,12.0309825 C0.946654365,12.1152702 1.05398666,12.1667 1.16965221,12.1667 L14.5028568,12.1667 C14.6165223,12.1667 14.7225213,12.1169845 14.7835207,12.0349826 C14.8448535,11.9529806 14.8531867,11.8498353 14.8055205,11.7612617 Z" id="Path"></path>
                                <circle id="Oval" cx="5.8361856" cy="2.1667" r="2"></circle>
                            </g>
                        </g>

                        <rect id="Rectangle-Copy-25" fill="#9AA0C9" x="1" y="47.5" width="47.18112" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-30" fill="#D8DAEB" x="1" y="53.5" width="56.30016" height="3.2025" rx="1.60125"></rect>
                        <rect id="Rectangle-Copy-31" fill="#D8DAEB" x="1" y="59.5" width="36.87264" height="3.2025" rx="1.60125"></rect>
                    </g>
                </g>
            </g>

            <rect id="Rectangle-Copy-104" fill="#FEB88A" x="18" y="19" width="59" height="62" rx="4" class="primary-color"></rect>
            <path d="M18,66 L77,66 L77,77 C77,79.209139 75.209139,81 73,81 L22,81 C19.790861,81 18,79.209139 18,77 L18,66 L18,66 Z" id="Rectangle-Copy-105" fill="#F9AA81" class="primary-color--dark"></path>
            <circle id="Oval-Copy-42" fill="#FEB88A" cx="114.5" cy="138.5" r="3.5" class="primary-color"></circle>
            <circle id="Oval-Copy-43" fill="#FEB88A" cx="200.5" cy="138.5" r="3.5" class="primary-color"></circle>
            <circle id="Oval-Copy-44" fill="#FEB88A" cx="286.5" cy="138.5" r="3.5" class="primary-color"></circle>
            <circle id="Oval-Copy-45" fill="#FEB88A" cx="372.5" cy="138.5" r="3.5" class="primary-color"></circle>
            <circle id="Oval-Copy-46" fill="#5150C4" cx="409" cy="20" r="4" class="secondary-color"></circle>
            <circle id="Oval-Copy-47" fill="#D8DAEB" cx="125.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-48" fill="#D8DAEB" cx="211.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-49" fill="#D8DAEB" cx="297.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-50" fill="#D8DAEB" cx="383.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-51" fill="#D8DAEB" cx="136.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-52" fill="#D8DAEB" cx="222.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-53" fill="#D8DAEB" cx="308.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-54" fill="#D8DAEB" cx="394.5" cy="138.5" r="3.5"></circle>
            <circle id="Oval-Copy-55" fill="#FFFFFF" cx="47" cy="38" r="11"></circle>
            <rect id="Rectangle-Copy-106" fill="#D8DAEB" x="20" y="93" width="10" height="10" rx="2"></rect>

            <circle id="Oval-Copy-56" fill="#FFFFFF" cx="23.5" cy="58.5" r="1.5"></circle>
            <circle id="Oval-Copy-57" fill="#FFFFFF" cx="41.5" cy="58.5" r="1.5"></circle>
            <circle id="Oval-Copy-58" fill="#FFFFFF" cx="59.5" cy="58.5" r="1.5"></circle>

            <rect id="Rectangle-Copy-107" fill="#D8DAEB" x="20" y="113" width="10" height="10" rx="2"></rect>
            <rect id="Rectangle-Copy-108" fill="#FEB88A" x="20" y="133" width="10" height="10" rx="2" class="primary-color"></rect>
            <rect id="Rectangle-Copy-122" fill="#FFFFFF" x="106" y="45" width="22" height="8" rx="2"></rect>
            <rect id="Rectangle-Copy-124" fill="#FFFFFF" x="160" y="45" width="22" height="8" rx="2"></rect>
            <rect id="Rectangle-Copy-123" fill="#5150C4" x="133" y="45" width="22" height="8" rx="2" class="secondary-color"></rect>
            <rect id="Rectangle-Copy-109" fill="#D8DAEB" x="20" y="153" width="10" height="10" rx="2"></rect>
            <rect id="Rectangle-Copy-110" fill="#9AA0C9" x="36" y="97" width="28" height="3" rx="1.5"></rect>
            <rect id="Rectangle-Copy-111" fill="#ED8371" x="27" y="57" width="10" height="3" rx="1.5" class="primary-color--darker"></rect>
            <rect id="Rectangle-Copy-112" fill="#ED8371" x="45" y="57" width="10" height="3" rx="1.5" class="primary-color--darker"></rect>
            <rect id="Rectangle-Copy-113" fill="#ED8371" x="25" y="72" width="45" height="3" rx="1.5" class="primary-color--darker"></rect>
            <rect id="Rectangle-Copy-114" fill="#ED8371" x="63" y="57" width="10" height="3" rx="1.5" class="primary-color--darker"></rect>
            <rect id="Rectangle-Copy-115" fill="#9AA0C9" x="36" y="117" width="28" height="3" rx="1.5"></rect>
            <rect id="Rectangle-Copy-116" fill="#FEB88A" x="36" y="137" width="28" height="3" rx="1.5" class="primary-color"></rect>
            <rect id="Rectangle-Copy-117" fill="#9AA0C9" x="36" y="157" width="28" height="3" rx="1.5"></rect>
            <rect id="Rectangle-Copy-118" fill="#D8DAEB" x="42" y="166" width="28" height="3" rx="1.5"></rect>
            <rect id="Rectangle-Copy-119" fill="#D8DAEB" x="418" y="19" width="28" height="3" rx="1.5"></rect>
            <rect id="Rectangle-Copy-125" fill="#5150C4" x="425" y="47" width="15" height="8" rx="4" class="secondary-color"></rect>
            <rect id="Rectangle-Copy-126" fill="#5150C4" x="405" y="47" width="8" height="8" rx="4" class="secondary-color"></rect>
            <rect id="Rectangle-Copy-120" fill="#D8DAEB" x="42" y="174" width="28" height="3" rx="1.5"></rect>
            <circle id="Oval-Copy-59" fill="#FFFFFF" cx="435.414957" cy="50.9890648" r="2.65"></circle>
            <rect id="Rectangle-Copy-121" fill="#D8DAEB" x="42" y="182" width="28" height="3" rx="1.5"></rect>
            <line x1="419" y1="47" x2="419" y2="55" id="Line" stroke="#FFFFFF" stroke-width="0.3" stroke-linecap="square"></line>
        </g>
    </g>
</svg>
