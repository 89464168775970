import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectService, StorageService } from '@services';
import { Company } from '@lighty';

@Component({
    selector: 'msc-shared-event-calendar',
    templateUrl: './event-calendar.component.html'
})
export class SharedEventCalendarComponent implements OnInit {
    @Input() type: string;
    @Input() events: any;
    private company: Company;

    constructor(private router: Router, private storageService: StorageService, private redirectService: RedirectService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
    }

    redirect(item: any): void {
        if (item.isTraject) {
            this.router.navigate(['learn', this.company.slug, 'detail', 'journey', item.trajectId || item.id]);
        } else {
            if (this.type === 'content') {
                this.redirectService.navigate('player', ['course', item.id]);
            } else {
                this.router.navigate(['learn', this.company.slug, 'detail', 'template', item.templateId || item.id]);
            }
        }
    }
}
