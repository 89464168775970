import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ManageLearnerProvider, Pagination } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-manage-instance-learner-list-aside-available',
    templateUrl: './aside-available.component.html',
    styleUrls: ['./aside-available.component.scss'],
})
export class ManageInstanceLearnerListAsideAvailableComponent {
    @ViewChild('aside') aside;
    @Input() companyId: number;
    @Input() instance: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public data: any;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public availableLearners: any;
    public pagination: Pagination;
    public query: string;
    public loaders: any;

    constructor(private learnerProvider: ManageLearnerProvider) { }

    init(): void {
        this.data = {
            inviteAll: false,
            notify: false,
            learners: []
        };

        this.loaders = {
            ready: false,
            loadMore: false,
            save: false
        };

        this.getAvailableLearners();
    }

    onOpen() {
        this.init();
        this.aside.onOpen();
    }

    getAvailableLearners(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query,
            context: this.instance.type
        });

        this.learnerProvider.getAvailableLearners(this.companyId, this.instance.id, params).subscribe((data) => {
            this.availableLearners = data.available;
            this.pagination = data.pagination;
            this.loaders.ready = true;
        });
    }

    loadMore(): void {
        this.loaders.loadMore = true;

        const params = HttpHelper.cleanParams({
            q: this.query,
            context: this.instance.type,
            page: this.pagination.currentPage + 1
        });

        this.learnerProvider.getAvailableLearners(this.companyId, this.instance.id, params).subscribe((data) => {
            this.availableLearners = this.availableLearners.concat(data.available);
            this.pagination = data.pagination;
            this.loaders.loadMore = false;
        });
    }

    addLearner(user: any): void {
        const index = this.data.learners.findIndex((learner) => learner.accountId === user.accountId);

        if (index > -1) {
            this.data.learners.splice(index, 1);
        } else {
            this.data.learners.push(user);
        }
    }

    isLearnerSelected(user: any): boolean {
        if (this.data.inviteAll) {
            return true;
        }
        return this.data.learners.findIndex((learner) => learner.accountId === user.accountId) > -1;
    }

    inviteAll(): void {
        this.data.inviteAll = !this.data.inviteAll;

        if (this.data.inviteAll) {
            this.data.learners = [...this.availableLearners];
            return;
        }

        this.data.learners = [];
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (!this.loaders.save) {
            this.loaders.save = true;
            const params = {
                inviteAll: this.data.inviteAll,
                learners: this.data.inviteAll ? [] : this.data.learners.map((learner) => learner.accountId),
                notify: this.data.notify
            };
            this.aside.onClose();
            this.onSave.emit(params);
        }
    }
}
