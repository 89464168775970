<msc-common-aside #aside>
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4 slide-add-member-to-camp">
            <div class="slide__header">
                <div class="slide__ariane">
                    <span class="slide__ariane__step" (click)="breadcrumb('choice')"
                        [ngClass]="{ 'slide__ariane__step--current': (page === 'choice'), 'slide__ariane__step--success': page !== 'choice'}">
                        <div [tooltip]="'member.add-member' | translate" direction="bottom"></div>
                    </span>

                    <span class="slide__ariane__step" (click)="breadcrumb('rights')"
                        [ngClass]="{ 'disabled': isDisabled('rights'), 'slide__ariane__step--current': page === 'rights'}">
                        <div [tooltip]="'extension.header.title.edit' | translate" direction="bottom"></div>
                    </span>
                </div>

                <a class="slide__close" (click)="onCloseByUser.emit()">
                    <i class="icon icon-close"></i>
                </a>
            </div>

            <div class="slide__body">
                <msc-camp-aside-invite-members-choice #memberChoice [data]="data" [page]="page" *ngIf="page === 'choice'">
                </msc-camp-aside-invite-members-choice>
                <msc-camp-aside-invite-members-rights #memberRights [(data)]="data" [loading]="loading" *ngIf="page === 'rights'"></msc-camp-aside-invite-members-rights>
            </div>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div class="flex items-center justify-between grow col-gap-xs " *ngIf="page === 'choice'">
            <msc-common2-checkbox [value]="false" [label]="'group.selection-all-users' | translate" (changeEvent)="memberChoice.inviteAll()"></msc-common2-checkbox>
            <msc-button class="msc-button--primary" palette="secondary" (click)="goToRights()">
                {{ 'words.continue' | translate }}
            </msc-button>
        </div>
        <div class="flex justify-end grow" *ngIf="page === 'rights'">
            <msc-button class="msc-button--primary" palette="secondary" (click)="save(); aside.onClose();" [loading]="loading" [disabled]="!data.roles.camp_learner && !data.roles.camp_administrator">
                {{ 'words.save' | translate }}
            </msc-button>
        </div>
    </ng-template>
</msc-common-aside>
