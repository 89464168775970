import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { EmitterService } from '@services';


@Directive({
    selector: '[msc-dropzone]'
})
export class DropzoneDirective implements OnInit {
    @Output() onDrop: EventEmitter<any> = new EventEmitter();
    private el: ElementRef;
    private size: any = {
        w: 0,
        h: 0
    };
    private dragging: boolean = false;
    private isIn: boolean = false;

    constructor(el: ElementRef, private emitterService: EmitterService) {
        this.el = el;
    }

    ngOnInit(): void {
        this.size = {
            w: this.el.nativeElement.offsetWidth,
            h: this.el.nativeElement.offsetHeight
        };

        this.emitterService.get('drag.dragging').subscribe(event => {
            this.dragging = event.dragging;

            if (event.data && this.isIn) {
                this.onDrop.emit(event.data);
                this.el.nativeElement.classList.remove('dragged-in');
            }
        });
    }

    @HostListener('mouseenter')
    onMouseEnter(): void {
        this.isIn = true;
        if (this.dragging) {
            this.el.nativeElement.classList.add('dragged-in');
        }
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        this.isIn = false;
        if (this.dragging) {
            this.el.nativeElement.classList.remove('dragged-in');
        }
    }
}
