<section class="head">
    <h3>Chart</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Chart Donut</h4>
        <hr>
        <div class="partition cards-1">
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
        </div>

        <div class="partition cards-2">
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
            <div class="segment">
                <msc-common-chart-donut></msc-common-chart-donut>
            </div>
        </div>
    </div>
</section>
