import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class ManageFollowUpProvider {

    constructor(private http: HttpClient) { }

    getUsers(id: string | number, externalContentId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/external-contents/${externalContentId}/users`, { params }).pipe(
            map((data) => data)
        );
    }

    getLogs(id: string | number, externalContentId: number, sessionId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/external-contents/${externalContentId}/sessions/${sessionId}/logs`, { params }).pipe(
            map((data) => data)
        );
    }

    addLog(id: string | number, externalContentId: number, sessionId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${id}/manage/external-contents/${externalContentId}/sessions/${sessionId}/logs`, params).pipe(
            map((data) => data)
        );
    }

    updateLog(id: string | number, externalContentId: number, sessionId: number, logId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/external-contents/${externalContentId}/sessions/${sessionId}/logs/${logId}`, params).pipe(
            map((data) => data)
        );
    }

    deleteLog(id: string | number, externalContentId: number, sessionId: number, logId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/companies/${id}/manage/external-contents/${externalContentId}/sessions/${sessionId}/logs/${logId}`, { params }).pipe(
            map((data) => data)
        );
    }
}
