import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    ApplicationResolver,
    CompanyGuardCheck,
    CompanyRoleResolver,
    CompanyResolver,
    SidebarResolver,
    UserResolver,
} from '../../app.resolver';
import {
    OnboardingResolver,
    ContentResolver, ContentSavedResolver,
    CatalogueResolver, CatalogueSkillsResolver,
    InstanceCorrectionsResolver, InstanceResolver, InstanceSummaryResolver,
    TemplateResolver, TemplateSummaryResolver,
    JourneyResolver,
    CommunityResolver,
    EdflexResolver,
    ExternalContentResolver, ExternalContentApiCallCopyResolver, JourneyDetailsCorrectionsResolver, LearningGroupsResolver, LearningGroupResolver
} from './learn.resolver';

import { LearnComponent } from './learn.component';

import { HubComponent } from '../hub/hub.component';

import { LearnOverviewComponent } from './overview/overview.component';

import { LearnOnboardingComponent } from './onboarding/onboarding.component';

import { LearnContentComponent } from './content/content.component';
import { LearnContentListComponent } from './content/list/list.component';
import { LearnContentSavedComponent } from './content/saved/saved.component';

import { LearnCatalogueComponent } from './catalogue/catalogue.component';
import { LearnCatalogueListComponent } from './catalogue/list/list.component';
import { LearnCatalogueWatchComponent } from './catalogue/watch/watch.component';

import { LearnDetailComponent } from './detail/detail.component';
import { LearnDetailInstanceCorrectionComponent } from './detail/instance/correction/correction.component';
import { LearnDetailInstanceSummaryComponent } from './detail/instance/summary/summary.component';
import { LearnDetailInstancePresentationComponent } from './detail/instance/presentation/presentation.component';
import { LearnDetailInstanceDiscussionComponent } from './detail/instance/discussion/discussion.component';
import { LearnDetailJourneyComponent } from './detail/journey/journey.component';
import { LearnDetailJourneyCorrectionComponent } from './detail/journey/correction/correction.component';
import { LearnDetailJourneySummaryComponent } from './detail/journey/summary/summary.component';
import { LearnDetailJourneyPresentationComponent } from './detail/journey/presentation/presentation.component';
import { LearnDetailJourneyDiscussionComponent } from './detail/journey/discussion/discussion.component';
import { LearnDetailTemplateComponent } from './detail/template/template.component';
import { LearnDetailTemplateSummaryComponent } from './detail/template/summary/summary.component';
import { LearnDetailTemplatePresentationComponent } from './detail/template/presentation/presentation.component';
import { LearnDetailExternalContentPresentationComponent } from './detail/external-content/presentation/presentation.component';
import { LearnDetailExternalContentResultComponent } from './detail/external-content/result/result.component';
import { LearnDetailExternalContentComponent } from './detail/external-content/external-content.component';

import { LearnCommunityComponent } from './community/community.component';
import { LearnCommunityListComponent } from './community/list/list.component';
import { LearnCommunityWatchComponent } from './community/watch/watch.component';
import { LearnIntegrationComponent } from './integration/integration.component';
import { LearnBoclips } from './boclip/boclip.component';

import { OnementorComponent } from './content/onementor/onementor.component';

export const routes: Routes = [
    {
        path: ':slug',
        component: LearnComponent,
        data: { breadcrumb: { skip: true } },
        canActivate: [CompanyGuardCheck],
        resolve: {
            me: UserResolver,
            sidebar: SidebarResolver,
            company: CompanyResolver,
            access: CompanyRoleResolver,
            applications: ApplicationResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'hub',
                pathMatch: 'full'
            },
            {
                path: 'hub',
                component: HubComponent,
                data: {
                    type: 'checkOnboarding'
                }
            },
            {
                path: 'overview',
                component: LearnOverviewComponent,
                data: { breadcrumb: { skip: true } },
            },
            {
                path: 'onboarding',
                component: LearnOnboardingComponent,
                resolve: {
                    onboarding: OnboardingResolver
                },
                data: { breadcrumb: { skip: true } },
            },
            {
                path: 'integrations',
                component: LearnIntegrationComponent,
                data: { breadcrumb: { skip: true } },
            },
            {
                path: 'onementor',
                component: OnementorComponent,
                data: { breadcrumb: { skip: true } },
            },
            {
                path: 'contents',
                component: LearnContentComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        component: LearnContentListComponent,
                        resolve: {
                            contents: ContentResolver
                        }
                    },
                    {
                        path: 'saved',
                        component: LearnContentSavedComponent,
                        resolve: {
                            contents: ContentSavedResolver
                        }
                    }
                ]
            },
            {
                path: 'catalogue',
                component: LearnCatalogueComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        component: LearnCatalogueListComponent,
                        resolve: {
                            catalogue: CatalogueResolver
                        }
                    },
                    {
                        path: ':type',
                        component: LearnCatalogueWatchComponent,
                        resolve: {
                            explore: CatalogueSkillsResolver
                        }
                    },
                    {
                        path: ':type/thematic/:thematicId',
                        component: LearnCatalogueWatchComponent,
                        resolve: {
                            thematic: CatalogueSkillsResolver
                        }
                    },
                    {
                        path: ':type/thematic/:thematicId/level/:level',
                        component: LearnCatalogueWatchComponent,
                        resolve: {
                            level: CatalogueSkillsResolver
                        }
                    }
                ]
            },
            {
                path: 'communities',
                component: LearnCommunityComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        component: LearnCommunityListComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            groups: LearningGroupsResolver
                        }
                    },
                    {
                        path: ':groupId',
                        component: LearnCommunityWatchComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            group: LearningGroupResolver
                        }
                    }
                ]
            },
            {
                path: 'detail',
                component: LearnDetailComponent,
                children: [
                    {
                        path: 'instance/:instanceId',
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            instance: InstanceResolver,
                            corrections: InstanceCorrectionsResolver,
                            summary: InstanceSummaryResolver
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'presentation',
                                pathMatch: 'full'
                            },
                            {
                                path: 'presentation',
                                component: LearnDetailInstancePresentationComponent
                            },
                            {
                                path: 'corrections',
                                component: LearnDetailInstanceCorrectionComponent
                            },
                            {
                                path: 'summary',
                                component: LearnDetailInstanceSummaryComponent
                            },
                            {
                                path: 'discussion',
                                component: LearnDetailInstanceDiscussionComponent
                            },
                        ]
                    },
                    {
                        path: 'journey/:journeyId',
                        component: LearnDetailJourneyComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            journey: JourneyResolver,
                            corrections: JourneyDetailsCorrectionsResolver
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'presentation',
                                pathMatch: 'full'
                            },
                            {
                                path: 'presentation',
                                component: LearnDetailJourneyPresentationComponent
                            },
                            {
                                path: 'corrections',
                                component: LearnDetailJourneyCorrectionComponent
                            },
                            {
                                path: 'summary',
                                component: LearnDetailJourneySummaryComponent,
                            },
                            {
                                path: 'discussion',
                                component: LearnDetailJourneyDiscussionComponent
                            },
                        ]
                    },
                    {
                        path: 'template/:templateId',
                        component: LearnDetailTemplateComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            template: TemplateResolver,
                            summary: TemplateSummaryResolver
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'presentation',
                                pathMatch: 'full'
                            },
                            {
                                path: 'presentation',
                                component: LearnDetailTemplatePresentationComponent,
                                data: { breadcrumb: { skip: true } },
                            },
                            {
                                path: 'summary',
                                component: LearnDetailTemplateSummaryComponent,
                                data: { breadcrumb: { skip: true } },
                            }
                        ]
                    },
                    {
                        path: 'external-content/:externalContentContext/:externalContentId',
                        component: LearnDetailExternalContentComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            externalContent: ExternalContentResolver,
                            externalContentApiCallCopy: ExternalContentApiCallCopyResolver,
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'presentation',
                                pathMatch: 'full'
                            },
                            {
                                path: 'presentation',
                                component: LearnDetailExternalContentPresentationComponent
                            },
                            {
                                path: 'result',
                                component: LearnDetailExternalContentResultComponent
                            }
                        ]
                    }
                ]
            }, 
            {
                path: 'boclips',
                component: LearnBoclips,
                data: { breadcrumb: { skip: true } }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        UserResolver,
        SidebarResolver,
        ApplicationResolver,
        CompanyResolver,
        CompanyRoleResolver,
        OnboardingResolver,
        ContentResolver,
        ContentSavedResolver,
        CatalogueResolver,
        CatalogueSkillsResolver,
        InstanceResolver,
        InstanceCorrectionsResolver,
        InstanceSummaryResolver,
        TemplateResolver,
        TemplateSummaryResolver,
        JourneyResolver,
        JourneyDetailsCorrectionsResolver,
        CommunityResolver,
        EdflexResolver,
        ExternalContentResolver,
        ExternalContentApiCallCopyResolver,
        LearningGroupsResolver,
        LearningGroupResolver
    ]
})

export class LearnRoutingModule { }
