<div class="audio-system">
    <div class="audio-canvas" *ngIf="animate">
        <canvas #analyser></canvas>
    </div>

    <div class="audio-player bg-secondary-800">
        <div class="audio-player__timeline">
            <div class="audio-player__progress bg-primary-700"></div>
        </div>

        <div class="audio-player__controls">
            <div class="audio-player__play-container">
                <div class="audio-player__play">
                    <i class="icon icon-arrrow-left-1"></i>

                    <span></span>
                </div>
            </div>

            <div class="audio-player__time">
                <div class="audio-player__current">0:00</div>
                <div class="audio-player__divider">/</div>
                <div class="audio-player__length"></div>
            </div>

            <div class="audio-player__title">{{ title | truncate: 30 }}</div>

            <div class="audio-player__volume-container">
                <div class="audio-player__volume-button">
                    <div class="audio-player__volume active">
                        <i class="icon icon-volume-up-1 up"></i>

                        <i class="icon icon-volume-1 muted"></i>
                    </div>
                </div>

                <div class="audio-player__volume-slider">
                    <div class="audio-player__volume-percentage bg-primary-700"></div>
                </div>
            </div>
        </div>
    </div>
</div>
