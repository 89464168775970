<div class="bg-white w-full rounded-sm shadow-md p-4 md:p-8">
    <div class="mb-5">
        <h1 class="font-bold my-2" (click)="modalConfirm.onOpen()">{{ 'exports.title' | translate }}</h1>
        <p>{{ 'exports.subtitle' | translate: {company: camp.name} }}</p>
    </div>
    <div class="mb-5">
        <h2 class="font-bold my-2">{{ 'exports.learner_engagement' | translate }}</h2>
        <p>{{ 'exports.learner_engagement.description' | translate }}</p>
        <ul class="list-disc pl-8 mb-2">
            <li>{{ 'exports.learner_engagement.signups' | translate }}</li>
            <li>{{ 'exports.learner_engagement.progress_score' | translate }}</li>
            <li>{{ 'exports.learner_engagement.content_details' | translate }}</li>
        </ul>
        <div class="flex">
            <msc-button class="msc-button--primary" palette="secondary" icon="icon-download" (click)="modalExports.open({name: camp.name , format: 'csv', type: 'learner-engagement'})">CSV</msc-button>
            <msc-button class="msc-button--primary ml-2" palette="secondary" icon="icon-download" (click)="modalExports.open({name: camp.name , format: 'xlsx', type: 'learner-engagement'})">XLSX</msc-button>
        </div>
    </div>
    <div class="mb-5">
        <h2 class="font-bold my-2">{{ 'exports.content_engagement' | translate }}</h2>
        <p>{{ 'exports.content_engagement.description' | translate }}</p>
        <ul class="list-disc pl-8 mb-2">
            <li>{{ 'exports.content_engagement.signups' | translate }}</li>
            <li>{{ 'exports.content_engagement.progress_score' | translate }}</li>
            <li>{{ 'exports.content_engagement.content_details' | translate }}</li>
        </ul>
        <div class="flex">
            <msc-button class="msc-button--primary" palette="secondary" icon="icon-download" (click)="modalExports.open({name: camp.name , format: 'csv', type: 'content-engagement'})">CSV</msc-button>
            <msc-button class="msc-button--primary ml-2" palette="secondary" icon="icon-download" (click)="modalExports.open({name: camp.name , format: 'xlsx', type: 'content-engagement'})">XLSX</msc-button>
        </div>
    </div>
</div>
<msc-email-export-modal #modalExports (onSave)="download($event)"></msc-email-export-modal>

<msc-common-modal size="30vw" #modalConfirm [title]="'stats.email-export.send_export' | translate">
    <ng-template>
        <p [innerHTML]="'export.export_is_being_created' | translate"></p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="msc-button--primary" palette="primary" (click)="modalConfirm.onClose()">Ok</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
