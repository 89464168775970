import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';

@Component({
    selector: 'msc-common-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonAccordionComponent {
    @Input() icon?: string;
    @Input() isOpen: boolean = false;
    @Input() title: string;
    @Output() openEvent: EventEmitter<any> = new EventEmitter<EventEmitter<any>>();
    @ContentChild(TemplateRef) bodyTemplate: TemplateRef<any>;

    /**
     * Event handler for open
     */
    onOpen(): void {
        this.isOpen = !this.isOpen;
        if (!this.isOpen) { return; } // we only emit when we open
        this.openEvent.emit();
    }
}
