<div class="content-camps-subscription">
    <div class="content-camps__title">
        {{ 'words.request-subscriptions' | translate }}
    </div>

    <div class="menu-action">
        <div class="menu-action__filters">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
        </div>

        <div>
            <msc-common-select [data]="selectData">
                <ng-template #buttonTemplate let-data="data">
                    <button class="select-main">
                        <ng-container *ngIf="data?.length">
                            <span>{{ data[0].label | translate }}</span>
                        </ng-container>
                    </button>
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <button class="select-option" (click)="onSelectOption(datum)">
                        <span>{{ datum.label | translate }}</span>
                        <span class="option-value" *ngIf="filters && !datum.value">{{ filters['total'] }}</span>
                        <span class="option-value" *ngIf="filters && datum.value">{{ filters[datum.value] }}</span>
                    </button>
                </ng-template>
            </msc-common-select>
        </div>
    </div>

    <div class="content-camps__list camp-subscribe">
        <div class="dynamic-table">
            <div class="dynamic-table__head">
                <div class="dynamic-table__cell cell-title cell-title--small">
                    {{ 'words.group_member' | translate }}
                </div>

                <div class="dynamic-table__cell">
                    {{ 'words.registered' | translate }}
                </div>

                <div class="dynamic-table__cell">
                    {{ 'words.content' | translate }}
                </div>

                <div class="dynamic-table__cell">
                    {{ 'group.user.registration.date' | translate }}
                </div>

                <div class="dynamic-table__cell">
                    {{ 'subscription.participation' | translate }}
                </div>
            </div>

            <div class="dynamic-table__body">
                <div class="content-camps__subtitle" *ngIf="filters?.pending === 0 && currentFilter === 'pending'">
                    <p>{{ 'subscription.list.empty' | translate }}</p>
                </div>

                <div class="dynamic-table__row row-radius row-shadow" *ngFor="let subscription of subscriptions;let index=index">
                    <div class="dynamic-table__cell cell-title cell-title--small">
                        <div class="avatar-profile">
                            <msc-common-avatar class="mr-2" [label]="subscription?.name" [pictureURL]="subscription?.avatar ? (subscription?.avatar + '?size=128') : null"></msc-common-avatar>
                            <p>{{ subscription.name }}</p>
                        </div>
                    </div>

                    <div class="dynamic-table__cell">
                        <span *ngIf="subscription.usedSeats"> {{ subscription.usedSeats }} </span>
                        <span *ngIf="!subscription.limit || subscription.limit <= 0"><span class="separator">/</span>-</span>
                        <span *ngIf="subscription.limit > 0">
                            <span class="separator">/</span>{{ subscription.limit }}
                        </span>
                    </div>

                    <div class="dynamic-table__cell cell-multiline">
                        <div class="title title__instance">
                            {{ subscription.instanceTitle | truncate: 30 }}
                        </div>
                        <div class="title">
                            {{ subscription.templateTitle | truncate: 30 }}
                        </div>
                    </div>

                    <div class="dynamic-table__cell">
                        {{ subscription.subscriptionDate | date }}
                    </div>

                    <div class="dynamic-table__cell" *ngIf="subscription.isValidated !== null">
                        <span *ngIf="subscription.isValidated">{{ 'words.validated' | translate }}</span>
                        <span *ngIf="!subscription.isValidated">{{ 'words.refused' | translate }}</span>
                    </div>

                    <div class="dynamic-table__cell" *ngIf="subscription.isValidated === null">
                        <div class="dropdown-status" [ngClass]="{'active': displays.dropdowns[index]}" [(autoClose)]="displays.dropdowns[index]">
                            <div class="dropdown-status__selected" (click)="displayed('dropdowns',index)">
                                <div class="dropdown-status__item dropdown-status__item--purple">
                                    <a class="dropdown-status__link">
                                        {{ 'subscription.filter.pending' | translate }}
                                        <i class="icon icon-arrow-ios-down"></i>
                                    </a>
                                </div>
                            </div>

                            <ul class="dropdown-status__content">
                                <li class="dropdown-status__item dropdown-status__item--green" (click)="accept(subscription)">
                                    <a class="dropdown-status__link">{{ 'words.validated' | translate }}</a>
                                </li>

                                <li class="dropdown-status__item dropdown-status__item--orange" (click)="refuse(subscription)">
                                    <a class="dropdown-status__link">{{ 'words.refused' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>

<msc-camp-aside-refused-subscription #refuseSubscriptionAside (onSave)="confirm($event)"></msc-camp-aside-refused-subscription>
