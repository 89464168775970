import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'msc-audio-player',
    templateUrl: './audio-player.component.html'
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {
    @ViewChild('analyser', {static: true}) canvas: ElementRef;
    @Input() song: string;
    @Input() title: string;
    @Input() autoplay: boolean = false;
    @Input() animate: boolean = false;
    private player: any;
    private audio: any;
    private audioContext: any;
    private source: any;
    private analyser: any;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.player = document.querySelector('.audio-player');
        this.audio = new Audio(this.song);

        this.audio.addEventListener('loadeddata', () => {
            this.player.querySelector('.audio-player__length').textContent = this.getDuration(this.audio.duration);
            this.audio.volume = 1;
        }, false);

        const timeline = this.player.querySelector('.audio-player__timeline');
        timeline.addEventListener('click', (event: any) => {
            const timelineWidth = window.getComputedStyle(timeline).width;
            this.audio.currentTime = event.offsetX / parseInt(timelineWidth, 10) * this.audio.duration;
        }, false);

        const volumeSlider = this.player.querySelector('.audio-player__volume-slider');
        volumeSlider.addEventListener('click', (event: any) => {
            const sliderWidth = window.getComputedStyle(volumeSlider).width;
            const newVolume = event.offsetX / parseInt(sliderWidth, 10);
            this.audio.volume = newVolume;
            const volume: any = this.player.querySelector('.audio-player__volume-percentage');
            volume.style.width = newVolume * 100 + '%';
        }, false);

        setInterval(() => {
            const progressBar: any = this.player.querySelector('.audio-player__progress');
            progressBar.style.width = this.audio.currentTime / this.audio.duration * 100 + '%';
            this.player.querySelector('.audio-player__current').textContent = this.getDuration(this.audio.currentTime);
        }, 500);

        const playBtn = this.player.querySelector('.audio-player__play');
        playBtn.addEventListener('click', () => {
            if (this.audio.paused) {
                playBtn.classList.add('active');
                this.audio.play();
            } else {
                playBtn.classList.remove('active');
                this.audio.pause();
            }
        }, false);

        this.player.querySelector('.audio-player__volume-button').addEventListener('click', () => {
            const volumeEl = this.player.querySelector('.audio-player__volume');
            this.audio.muted = !this.audio.muted;
            if (this.audio.muted) {
                volumeEl.classList.remove('active');
            } else {
                volumeEl.classList.add('active');
            }
        });

        if (this.animate) {
            this.renderFrame();
        }

        if (this.autoplay) {
            this.audio.play();
        }
    }

    private getDuration(duration: number): string {
        const seconds = ('0' + Math.floor(duration % 3600 % 60)).slice(-2);
        const minutes = Math.floor(duration / 60) % 60;

        return `${minutes}:${seconds}`;
    }

    private renderFrame(): void {
        this.audioContext = new AudioContext();
        this.source = this.audioContext.createMediaElementSource(this.audio);
        this.analyser = this.audioContext.createAnalyser();

        this.canvas.nativeElement.width = 2000;
        this.canvas.nativeElement.height = window.innerHeight;

        this.source.connect(this.analyser);
        this.analyser.connect(this.audioContext.destination);
        this.analyser.fftSize = 256;

        const ctx = this.canvas.nativeElement.getContext('2d');
        const bufferLength = this.analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        const WIDTH = this.canvas.nativeElement.width;
        const HEIGHT = this.canvas.nativeElement.height;
        const barWidth = (WIDTH / bufferLength) * 2.5;
        let barHeight;

        const animate = () => {
            requestAnimationFrame(animate);

            let x = 0;

            this.analyser.getByteFrequencyData(dataArray);

            ctx.fillStyle = '#FFF';
            ctx.fillRect(0, 0, WIDTH, HEIGHT);

            for (let i = 0; i < bufferLength; i++) {
                barHeight = dataArray[i];

                const r = barHeight + (25 * (i / bufferLength));
                const g = 250 * (i / bufferLength);
                const b = 50;

                ctx.fillStyle = 'rgb(' + r + ',' + g + ',' + b + ')';
                ctx.fillRect(x, HEIGHT - barHeight, 25, barHeight);

                x += barWidth + 1;
            }
        };
        animate();
    }
}
