import { Directive, OnInit, ElementRef } from '@angular/core';

@Directive({
    selector: '[readMore]',
    exportAs: 'readMore'
})
export class ReadMoreDirective implements OnInit {
    public extended: boolean = false;
    public overflow: boolean = false;
    innerHtmlChanged: MutationObserver;
    initialMaxheight: number;

    constructor(private element: ElementRef) { }

    ngOnInit(): void {
        this.element.nativeElement.classList.add('read-more');

        this.overflow = this.isTextOverflow();
        this.initialMaxheight = this.element.nativeElement.offsetHeight;
        
        this.innerHtmlChanged = new MutationObserver((mutationsList, observer) => {
            this.overflow = this.isTextOverflow();
        });

        this.innerHtmlChanged.observe(this.element.nativeElement, { characterData: false, childList: true, attributes: false });
    }

    public isTextOverflow(): boolean {
        const elem = this.element.nativeElement;
        if (elem) {
            return (elem.offsetHeight < elem.scrollHeight);
        }
        else {
            return false;
        }
    }

    public toggleExtend(): void {
        this.extended = !this.extended;

        if (this.extended) {
            this.element.nativeElement.classList.add('extend');
            this.element.nativeElement.style.maxHeight = `${this.element.nativeElement.scrollHeight}px`;
            this.element.nativeElement.style.height = `${this.element.nativeElement.scrollHeight}px`;
        } else {
            this.element.nativeElement.classList.remove('extend');
            this.element.nativeElement.style.maxHeight = `${this.initialMaxheight}px`;
            this.element.nativeElement.style.height = `${this.initialMaxheight}px`;
        }
    }
}
