import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AdminTemplateMemberProvider {

    constructor(private http: HttpClient) {}

    getAllMembers(courseId: number, params?: any): Observable<any> {
        return forkJoin(
            this.getStudents(courseId, params),
            this.getAvailableMembers(courseId, params)
        );
    }

    getStudents(courseId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/course/${courseId}/subscriptions`, {params}).pipe(
            map(data => data)
        );
    }

    getAvailableMembers(courseId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/course/${courseId}/available-members`, {params}).pipe(
            map(data => data)
        );
    }

    subscribeStudent(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/course-subscriptions/import`, params).pipe(
            map(data => data)
        );
    }
}
