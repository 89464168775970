import { Correction } from './correction';

export class CorrectionProject extends Correction {

    constructor(data: any, title: string, description?: string) {
        super(data, title);
        this.tag = 'words.project';
        this.timelineContext = 'project-session';
        this.description = description;
        this.answer = data.answer;
        this.ratio = data.score;
        this.answerMedias = this.medias || [];
    }
}