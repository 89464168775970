import { Component, OnInit } from '@angular/core';
import { EmitterService, ExternalAppsService, RedirectService, StorageService, TranslateService } from '@services';
import { Company, CompanyExperience, InstanceProvider, JourneyProvider, ExternalContentProvider } from '@lighty';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadHelper, UrlHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { environment as env } from '@env/environment';
import { finalize, take, tap } from 'rxjs/operators';

@Component({
    selector: 'msc-learn-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class LearnOnboardingComponent implements OnInit {
    public me: any;
    public company: Company;
    public access: any;
    public sidebar: any;
    public experience: CompanyExperience;
    public onboarding: any;
    public content: any;
    public courses: any = {};
    public background: string = 'https://static.myskillcamp.com/lxp/images/hero-catalog.png';
    public displays: any;
    public journeyContentLoading = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private externalAppsService: ExternalAppsService,
        private journeyProvider: JourneyProvider,
        private instanceProvider: InstanceProvider,
        private externalContentProvider: ExternalContentProvider,
        private emitterService: EmitterService,
        private toastService: CommonToastService,
        private redirectService: RedirectService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.access = this.storageService.get('access');
        this.sidebar = this.storageService.get('sidebar');
        this.experience = this.company.experience;
        this.background = this.company.experience && this.company.experience.cataloguePicture ? this.company.experience.cataloguePicture + '?size=raw' : this.background;
        this.onboarding = this.route.snapshot.data.onboarding;

        this.displays = {
            profile: false,
            content: true,
            features: false,
            courses: [],
            sections: [],
            sectionContents: []
        };

        this.emitterService.get('refresh.content').subscribe((data) => {
            if (typeof data === 'boolean') {
                const params = {
                    context: this.onboarding.context === 'video' ? 'video' : 'content'
                };

                this.externalContentProvider.getExternalContentDetails(this.onboarding.contextId, params).subscribe((content) => {
                    this.content = content;
                });
            }
        });

        this.emitterService.get('finished.content').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.access.permissions.companyAccessPlatform = true;
                this.router.navigate(['../overview'], { queryParams: { actions: 'unlock-onboarding' }, relativeTo: this.route });
            }
        });

        if (this.access.permissions.companyAccessPlatform) {
            this.router.navigate(['../overview'], { queryParams: { actions: 'unlock-onboarding' }, relativeTo: this.route });
        }

        this.getContent();
    }

    private getContent(): void {
        switch (this.onboarding.context) {
            case 'traject':
                this.journeyProvider.get(this.company.id, this.onboarding.contextId).subscribe((data) => {
                    this.content = data;
                    this.fetchCourses();

                    if (data.contents.every((content) => content.session?.progress === 100)) {
                        this.access.permissions.companyAccessPlatform = true;
                        this.router.navigate(['../overview'], { queryParams: { actions: 'unlock-onboarding' }, relativeTo: this.route });
                    }
                });
                break;
            case 'course_instance':
                this.instanceProvider.getSummary(this.onboarding.contextId).subscribe((data) => {
                    this.content = data;
                });
                break;
            default:
                const params = {
                    context: this.onboarding.context === 'video' ? 'video' : 'content'
                };

                this.externalContentProvider.getExternalContentDetails(this.onboarding.contextId, params).subscribe((data) => {
                    this.content = data;
                });
        }
    }

    loadMoreContent() {
        this.journeyContentLoading = true;
        this.journeyProvider.get(this.company.id, this.content.id, { page: ++this.content.pagination.currentPage }).subscribe((journey) => {
            this.content.contents = [...this.content.contents, ...journey.contents];
            this.journeyContentLoading = false;
        });
    }

    //TO DO remove duplicate with card-jopurney.component.ts
    getLocalUrl(scope: string) {
        const localUrl = UrlHelper.getProtocol() + '//' + UrlHelper.getHost();
        switch (true) {
            case scope === 'app': return `${localUrl}/learn`;
            case scope === 'player': return `${localUrl}/` === env.envVar.APP_URL ? env.envVar.PLAYER_URL : `${localUrl}/player`;
            default: return localUrl;
        }
    }

    private getNewUrl() {
        return this.externalContentProvider
            .generateUrl({
                company_id: this.company.id,
                provider_id: this.content.provider.id,
                context_id: this.content.id,
                context_type: 'external_content',
            })
            .pipe(
                take(1),
                tap(({ url }) => {
                    this.content.url = url;
                    UrlHelper.navigate(url);
                }),
                finalize(() => {
                    this.emitterService.set('player-light.open', { type: 'external', content: this.content, more: this.content });
                })
            );
    }

    start(): void {
        switch (this.onboarding.context) {
            case 'traject':
                this.journeyProvider.resumeJourney(this.company.id, this.content.id).subscribe(({ scope, url }: any) => {
                    const localUrl = this.getLocalUrl(scope)
                    location.href = localUrl + '/' + url + '?onboarding=true';
                });
                break;
            case 'course_instance':
                this.redirectService.navigate('player', ['course', this.content.id], { onboarding: true });
                break;
            case 'bookboon':
            case 'audio':
                this.download();
                break;
            case 'video':
                this.emitterService.set('player-light.open', { type: 'video', content: this.content, more: this.content });
                break;
            default:
                if (this.content.requireUrlGeneration) {
                    this.getNewUrl().subscribe();
                } else {
                    UrlHelper.navigate(this.content.url);
                    this.emitterService.set('player-light.open', { type: 'external', content: this.content, more: this.content });
                }
        }
    }

    private download(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.downloading.soon'));
        this.externalContentProvider.download(this.content.id).subscribe((data) => {
            DownloadHelper.downloadBlob(data, this.content.title);
            this.toastService.onSuccess(this.translateService.instant('toast.bookboon.download-success'));
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        });
    }

    getTranslate(): string {
        return this.content.session?.duration > 0 ? 'words.continue' : 'words.start';
    }

    getType(): string {
        switch (this.onboarding.context) {
            case 'traject':
                return 'words.journey';
            case 'external_resource':
                return 'words.' + this.content.type;
            case 'bookboon':
            case 'audio':
                return 'words.ebook';
            default:
                return 'words.course';
        }
    }

    getColor(): string {
        switch (this.onboarding.context) {
            case 'traject':
                return 'red';
            case 'bookboon':
            case 'audio':
                return 'orange';
            case 'external_resource':
                switch (this.content.type) {
                    case 'course':
                        return 'purple';
                    case 'video':
                        return 'blue';
                    case 'article':
                        return 'orange';
                    case 'other':
                        return 'red';
                }
                break;
            default:
                return 'purple';
        }
    }

    getContentIcon(type: string): string {
        switch (type) {
            case 'project':
                return 'icon-clipboard';
            case 'exercise':
                return 'icon-checkmark-square';
            case 'media':
                return 'icon-play-circle';
            case 'certificate':
                return 'icon-award';
            case 'exchange':
                return 'icon-message-circle';
            case 'cheatsheet':
                return 'icon-file-text';
            case 'event':
                return 'icon-calendar';
        }
    }

    displayed(type: string, index: number = null): void {
        if (index !== null) {
            this.displays[type][index] = !this.displays[type][index];
        } else {
            this.displays[type] = !this.displays[type];
        }
    }

    conversationWith(user: any): void {
        if (user.conversationId) {
            this.router.navigate(['conversation', user.conversationId]);
        } else {
            this.storageService.set('conversationUsers', [user]);
            this.router.navigate(['conversation/create']);
        }
    }

    fetchCourses(): void {
        for (const item of this.content.contents) {
            if (item.context === 'course') {
                this.instanceProvider.getSummary(item.subscriptionCourseId).subscribe((data) => {
                    this.courses[item.id] = data;
                });
            }
        }
    }
}
