<div class="main">
    <div class="box">
        <msc-common-feed-submit (submit)="onPostThread($event)"></msc-common-feed-submit>
    </div>

    <div>
        <msc-common-list
            [data]="itemsThreads$ | async"
            [isLoading]="statusThreads$ | async"
            [emptyMessage]="'empty.available.data' | translate">
            <ng-template #item let-datum="datum">
                <msc-common-feed-thread
                    [datum]="datum"
                    (commentCreate)="onPostComment(datum, $event)"
                    (commentDelete)="onDeleteComment($event)"
                    (threadDelete)="onDeleteThread(datum)"
                    (threadEdit)="onEditThread(datum, $event)"
                    (threadNotify)="onNotifyOpenModal(modalNotify, datum)"
                    (reactionCreate)="onPostReaction(datum, $event)"
                    (reactionDelete)="onDeleteReaction(datum,  $event)">
                </msc-common-feed-thread>
            </ng-template>
        </msc-common-list>
    </div>
</div>

<div class="box">
    <div class="people">
        <msc-button class="msc-button--large" type="fab" icon="icon-add-people" (click)="onToggleAddUser()"></msc-button>
    </div>

    <h4 class="heading">{{ 'words.managers' | translate }}</h4>
    <msc-common-list
        [data]="itemsOwner$ | async"
        [isLoading]="statusOwner$ | async"
        [emptyMessage]="'empty.available.data' | translate"
        [hasLoadMore]="pageOwner$ | async"
        (loadMore)="onQueryUsers('owner')">
        <ng-template #item let-datum="datum">
            <msc-common-feed-user
                [datum]="datum"
                (chat)="onUserChat(datum)"
                (email)="mailUserAside.onOpen(datum.datum)">
            </msc-common-feed-user>
        </ng-template>
    </msc-common-list>

    <h4 class="heading">{{ 'words.members' | translate }}</h4>
    <msc-common-list
        [data]="itemsMember$ | async"
        [isLoading]="statusMember$ | async"
        [emptyMessage]="'empty.available.data' | translate"
        [hasLoadMore]="pageMember$ | async"
        (loadMore)="onQueryUsers('member')">
        <ng-template #item let-datum="datum">
            <msc-common-feed-user
                [datum]="datum"
                (chat)="onUserChat(datum)"
                (email)="mailUserAside.onOpen(datum.datum)">
            </msc-common-feed-user>
        </ng-template>
    </msc-common-list>
</div>

<msc-shared-aside-mail-user #mailUserAside></msc-shared-aside-mail-user>

<ng-container *ngIf="isToggleAddMember">
    <msc-manage-instance-conversation-modal-group [(members)]="itemsRawUsers" (onClose)="onToggleAddUser()"></msc-manage-instance-conversation-modal-group>
</ng-container>

<msc-common-modal size="30vw" #modalNotify [title]="'words.notify' | translate">
    <ng-template>
        <p>{{ 'timeline.notify-course-learner' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalNotify.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onNotifyThread(); modalNotify.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
