<div class="modal modal-player-light-iframe" [ngClass]="{'active': active}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <h1>{{ content.title }}</h1>

            <div class="modal__header__actions" *ngIf="options.actionEnable">
                <input type="number" min="0" max="100" class="input-number input-number--tiny" [(ngModel)]="data.duration">

                <div class="input form-select form-select--tiny">
                    <div class="select select--tiny">
                        <select name="select" [(ngModel)]="data.unit">
                            <option value="minutes">{{ 'words.minutes' | translate }}</option>
                            <option value="hours">{{ 'words.hours' | translate }}</option>
                            <option value="days">{{ 'words.days' | translate }}</option>
                        </select>
                    </div>
                </div>

                <msc-button class="msc-button--primary msc-button--tiny" palette="info" icon="icon-diagonal-arrow-right-up" (click)="progress()">
                    {{ 'words.i-have-progressed' | translate }}
                </msc-button>

                <msc-button class="msc-button--primary msc-button--tiny" palette="success" icon="icon-checkmark" (click)="finished()">
                    {{ 'words.i-finished' | translate }}
                </msc-button>

                <div class="actions__close" (click)="modalConfirmation.onOpen()">
                    <i class="icon icon-close"></i>
                </div>
            </div>

            <div class="modal__header__actions modal__header__actions--small" *ngIf="!options.actionEnable">
                <msc-button class="msc-button--primary msc-button--tiny" palette="primary" icon="icon-log-out" (click)="close()">
                    {{ 'words.quit-playing' | translate }}
                </msc-button>
            </div>
        </div>

        <div class="modal__body">
            <iframe *ngIf="content.url" [src]="getUrl()" allow="fullscreen" allowfullscreen width="100%"></iframe>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalConfirmation [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'playerlight.modal.confirmation' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalConfirmation.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="close(); modalConfirmation.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
