import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding, ElementRef } from '@angular/core';

@Component({
    selector: 'msc-common-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonBannerComponent {
    @Input() title?: string;
    @Input() description: string;
    @Input() type: string; // info, warning , error
    @Output() close?: EventEmitter<any> = new EventEmitter<any>();
    @HostBinding('class') get class() { return this.type; }

    constructor(
        private readonly host: ElementRef<HTMLElement>
    ) { }

    /**
     * Event handler for button close
     */
    onClose(): void {
        this.close && this.close.emit();
        this.host.nativeElement.remove();
    }
}
