<div class="composer" [ngClass]="{'composer--light': dynamic, 'active': active, 'composer--grey': grey}">
    <div class="composer__content">
        <div class="composer__profile">
            <msc-common-avatar [label]="me?.name" [pictureURL]="me?.picture ? (me?.picture + '?size=64') : null"></msc-common-avatar>
        </div>

        <div [attr.id]="composerId" class="composer__message">
            <textarea autocomplete="off" class="textarea composer__text text-secondary-800" name="message"
                      [(ngModel)]="message"
                      (keydown.enter)="send($event)"
                      (ngModelChange)="messageChange.emit(message)"
                      [autoSize]="autoResize"
                      [identifier]="'#' + composerId"
                      [forceRezize]="edit"
                      [debounce]="800"
                      [disabled]="loaders.sending"
                      (onDebounce)="messageDebounced.emit(true)"
                      [placeholder]="'placeholder.speak-out' | translate"
                      (focus)="onFocus()">
            </textarea>
        </div>
    </div>

    <div class="composer__footer">
        <div class="composer__actions">
            <div class="composer__quick">
                <a [tooltip]="'words.resource' | translate" (click)="importFile()">
                    <i class="icon icon-file-text"></i>
                </a>

                <a [tooltip]="'words.video' | translate" (click)="composerEmbedAside.onOpen()">
                    <i class="icon icon-play-circle"></i>
                </a>
            </div>

            <div class="composer__send">
                <div cdkOverlayOrigin #campsList="cdkOverlayOrigin">
                    <ng-container *ngIf="camps && camps.length > 1">
                        <div class="pr-2 border-r" [tooltip]="selectedCamp ? selectedCamp.name : camps[0].name">
                            <msc-button class="text-2xl" type="fab" icon="icon-logo-myskillcamp" (click)="selectCampOpen = true"></msc-button>
                        </div>
                    </ng-container>
                </div>

                <ng-container *ngIf="!loaders.sending">
                    <msc-button class="msc-button--primary msc-button--tiny" type="fab" palette="secondary" icon="icon-arrow-right-1" (click)="send()"></msc-button>
                </ng-container>

                <msc-common-spinner *ngIf="loaders.sending"></msc-common-spinner>
            </div>
        </div>

        <div class="composer__preview" *ngIf="preview.type === '' && media">
            <msc-button class="msc-button--primary msc-button--tiny preview__remove" type="fab" palette="error" icon="icon-close" *ngIf="!loaders.sending" (click)="removeMedia()"></msc-button>

            <div class="preview__picture" *ngIf="media.type === 'IMG'">
                <img [src]="media.pictureUrl + '?size=512'" alt="Picture">
            </div>

            <div class="preview__embed" *ngIf="media.type === 'EMBED'">
                <iframe [src]="media.url" width="100%" height="100%"></iframe>
            </div>

            <div class="preview__file" *ngIf="media.type === 'DOC' || media.type === 'PDF' || media.type === 'NONE' || media.type === 'SND'">
                <msc-shared-resource [media]="media"></msc-shared-resource>
            </div>

            <div class="preview__video" *ngIf="media.type === 'VID'">
                <video width="100%" controls>
                    <source [src]="media.url" [type]="media.type">
                </video>
            </div>
        </div>

        <div class="composer__preview" *ngIf="preview.type !== ''">
            <msc-button class="msc-button--primary msc-button--tiny preview__remove" type="fab" palette="error" icon="icon-close" *ngIf="!loaders.sending" (click)="removePreview()"></msc-button>

            <div class="preview__picture" *ngIf="preview.type === 'picture'">
                <img [src]="preview.url" alt="Picture">
            </div>

            <div class="preview__embed" *ngIf="preview.type === 'embed'">
                <iframe [src]="preview.url" width="100%" height="100%" *ngIf="preview.type === 'embed'"></iframe>
            </div>

            <div class="preview__file" *ngIf="preview.type === 'file'">
                <msc-shared-resource [file]="preview.data"></msc-shared-resource>
            </div>

            <div class="preview__video" *ngIf="preview.type === 'video'">
                <video width="100%" controls>
                    <source [src]="preview.url" [type]="preview.data.type">
                </video>
            </div>
        </div>
    </div>
</div>

<div class="display-none">
    <input #uploadedFile type="file" (change)="loadFile($event.target.files[0])">
</div>

<msc-composer-aside-embed #composerEmbedAside (onSave)="importEmbed($event)"></msc-composer-aside-embed>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayOrigin]="campsList"
    [cdkConnectedOverlayOpen]="selectCampOpen" (backdropClick)="selectCampOpen = false">
    <div class="camp-list">
        <ng-container *ngFor="let camp of camps">
            <div class="flex justify-between p-1 hover:bg-gray-100" (click)="selectCamp(camp); selectCampOpen = false">
                <div class="text-ellipsis overflow-hidden whitespace-nowrap">
                    <i class="icon icon-logo-myskillcamp text-lg text-primary-500"></i>
                    <span class="ml-2">{{ camp.name }}</span>
                </div>

                <ng-container *ngIf="isSelectedCamp(camp); else noSelectedCamp">
                    <i class="icon icon-checkmark-circle-3 text-xl text-secondary-500"></i>
                </ng-container>
                <ng-template #noSelectedCamp>
                    <i class="icon icon-radio-button-off-1 text-xl"></i>
                </ng-template>
            </div>
        </ng-container>
    </div>
</ng-template>
