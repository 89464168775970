import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChildren, QueryList, TemplateRef, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
//
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonTreeDatum } from '@common2/components/tree/tree.component';
import { first } from 'rxjs';
import { CommonOverlayComponent } from '../overlay/overlay.component';
//
export type CommonSelectType = 'single' | 'multi';
export interface CommonSelectDatum<T> extends CommonTreeDatum<T> { isSelected: boolean; }

@Component({
    selector: 'msc-common-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonSelectComponent<T> {
    @Input() data: Array<CommonSelectDatum<T>> = []; // data of select options
    @Input() hasSearch: boolean = false;
    @Input() type: CommonSelectType = 'single';
    @Input() isButton = false;
    @Input() infiniteLoad;
    @Input() disabled = false;
    @Output() selectEvent: EventEmitter<CommonSelectDatum<T>> = new EventEmitter<CommonSelectDatum<T>>();
    @ContentChildren('buttonTemplate') buttonTemplate: QueryList<TemplateRef<{ datum: CommonSelectDatum<T> }>>;
    @ContentChildren('optionTemplate') optionTemplate: QueryList<TemplateRef<{ datum: CommonSelectDatum<T> }>>;
    @ViewChild('overlay') overlayRef: CommonOverlayComponent;

    public dataButton: Array<CommonSelectDatum<T>>; // data for button template
    public dataOption: Array<CommonSelectDatum<T>> = []; // data for option template
    public isOpen = false;
    private pagination: any = {
        currentPage: 0
    };
    private query: string;
    public isLoading = false;
    public overlayRoot = [];

    constructor(
        private readonly elementRef: ElementRef,
        private cdRef: ChangeDetectorRef
    ) { }

    get templateButton(): TemplateRef<{ datum: CommonSelectDatum<T> }> { return (this.buttonTemplate?.toArray() || [])[0]; }
    get templateOption(): TemplateRef<{ datum: CommonSelectDatum<T> }> { return (this.optionTemplate?.toArray() || [])[0]; }
    get width(): number { return this.elementRef.nativeElement.getBoundingClientRect()?.width; }

    ngOnInit(): void {
        if (!this.infiniteLoad) {
            this.setDataOption();
        }
    }

    ngOnChanges(): void {
        if (!Array.isArray(this.data)) {
            return;
        }
        this.setDataButton();
        this.setDataOption();
    }

    /**
     * Set the data for button template
     */
    setDataButton(): void {
        if (!Array.isArray(this.data) || !this.data.length || this.infiniteLoad) { return; }

        this.dataButton = (this.data || []).filter((datum) => datum.isSelected);
        setTimeout(() => {
            this.cdRef.detectChanges();
        }, 100);
        if (this.type !== 'single') { return; }
        this.onClose();
    }

    /**
     * Set the data for option template
     */
    setDataOption(): void {
        this.dataOption = [...this.data];
    }

    /**
     * Event handler for close
     */
    onClose(): void {
        this.isOpen = false;
        this.overlayRef?.close();
        this.cdRef.detectChanges();
        this.setDataOption();
    }

    /**
     * Event handler for open
     */
    onOpen(origin): void {
        this.isOpen = !this.isOpen;
        this.overlayRef?.open(origin);
        if (this.infiniteLoad) {
            this.pagination.currentPage = 0;
            this.data = [];
            this.setDataOption();
            this.loadData();
        }

        this.cdRef.detectChanges();
    }

    loadData(query?) {
        if (this.pagination.currentPage === this.pagination.lastPage || this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.infiniteLoad(query, this.pagination).then(({ data, pagination }) => {
            this.pagination = pagination;
            this.data = [...this.data, ...data as Array<CommonSelectDatum<T>>];
            this.setDataOption();
            this.isLoading = false;
        })
    }

    /**
     * Event handler for search
     */
    onSearch({ data, value }: CommonSearchOutput<CommonSelectDatum<T>>): void {
        if (this.infiniteLoad) {
            this.data = [];
            this.setDataOption();
            this.pagination.currentPage = 0;
            this.query = value;
            this.loadData(value);
        } else {
            if (!Array.isArray(data)) { return; }
            this.dataOption = data as Array<CommonSelectDatum<T>>;
        }
    }

    /**
     * Event handler for select
     */
    onSelect(datum: CommonSelectDatum<T>): void {
        this.selectEvent.emit(datum);
        if (this.type !== 'single') { return; }
        this.onClose();
    }

    updateOverlayRoot(htmlElement) {
        this.overlayRoot.push(htmlElement.rootNodes[0]);
    }
}
