<msc-common-aside #aside [title]="'company.subscription.add-address.title' | translate" (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="flex flex-col gap-2">
        <msc-input [label]="'company.subscription.address.name' | translate" name="name" [(ngModel)]="address.name"></msc-input>        
        <msc-textarea [label]="'company.subscription.address.billing' | translate" rows="5" cols="33" id="address" [(ngModel)]="address.address"></msc-textarea>
        <msc-input [label]="'words.company' | translate" name="input" id="company" [(ngModel)]="address.company"></msc-input>
        <div>
            <label>{{ 'words.country' | translate }}</label>
            <div class="input input--experience">
                <div class="select" (click)="displayed()">
                    <div class="select__text">
                        {{ getCountry() }}
                    </div>

                    <div class="dropdown dropdown-experience" [ngClass]="{'active': dropdown}" [(autoClose)]="dropdown">
                        <div class="dropdown-experience__content">
                            <div class="dropdown-experience__item" *ngFor="let country of countries" (click)="changeCountry(country.code)">
                                <p>
                                    {{ country.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <msc-input [label]="'words.vat' | translate" name="input" id="vat" [(ngModel)]="address.vat"></msc-input>
        <msc-common2-checkbox [value]="address.no_vat" [label]="'company.subscription.address.vat' | translate" (changeEvent)="address.no_vat = !address.no_vat"></msc-common2-checkbox>
        </div>
    </ng-template>
</msc-common-aside>