<div class="score-progress">
    <div class="circle-progress">
        <div class="inner"></div>
        <div class="circle" [ngClass]="{ 'circle--disabled': score === false }">
            <div class="circle__bar left">
                <div class="progress"></div>
            </div>
            <div class="circle__bar right">
                <div class="progress"></div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="logo">
            <i class="icon icon-{{ score === false ? 'checkmark-1' : 'trending-up' }}"></i>
        </div>
    </div>
    <div *ngIf="score !== false" class="number">{{score}}%</div>
</div>