export class Subscription {
    id: number;
    instanceTitle: string;
    isValidated: number;
    limit: number;
    name: string;
    picture?: string;
    subscriptionDate: Date;
    templateTitle: string;
    usedSeats: number;
    validationComment?: string;

    constructor(subscription: object) {
        this.assign(subscription);
    }

    assign(subscription: object): void {
        Object.assign(this, subscription);
    }
}
