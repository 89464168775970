import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EmitterService, ScriptInjectorService } from '@services';
import { DataHelper } from '@helpers';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-window-embed',
    templateUrl: './window-embed.component.html',
})
export class WindowEmbedComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public active: boolean = false;
    public context: any;

    constructor(private router: Router, private emitterService: EmitterService, private scriptInjectorService: ScriptInjectorService) {}

    ngOnInit(): void {
        this.reset();

        this.subscriptionCollection.subscribe = this.router.events.subscribe((event) => {

            if (event instanceof NavigationEnd) {
                this.emitterService.set('global.window-embed', {});
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('global.window-embed').subscribe((data) => {
            if (!DataHelper.isEmpty(data)) {
                this.active = true;
                this.context = data;

                if (this.context.type === 'sale') {
                    const timeOut = setTimeout(() => {
                        this.scriptInjectorService.hubspot('#window-hubspot-injector');
                        clearTimeout(timeOut);
                    }, 200);
                }
            } else {
                this.reset();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    private reset(): void {
        this.active = false;

        this.context = {
            type: null,
            value: null
        };
    }
}
