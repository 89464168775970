import { Component, OnInit, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Conversation, ConversationMessage, ConversationProvider, Account, Media, Company, CompanyProvider } from '@lighty';
import { EmitterService, StorageService, SocketService, TranslateService } from '@services';
import SimpleBar from 'simplebar';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-conversation-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class ConversationMessageComponent implements OnInit, OnDestroy {
    @ViewChild('simpleBarElement', { static: true }) simpleBarElement: ElementRef;
    private company: Company;
    private socket: any;
    private isTyping: boolean = false;
    private embeds: SafeResourceUrl[] = [];
    private simpleBar: any;
    private newConversation: boolean;
    public me: any;
    public conversation: Conversation;
    public content: any;
    public members: Account[] = [];
    public query: string;
    public displaySearch: boolean = false;
    public searchMembersCollection: Account[];
    public writing: string;
    public unlock: EventEmitter<boolean> = new EventEmitter();
    public selectedPicture: string;

    constructor(
        private route: ActivatedRoute,
        private conversationProvider: ConversationProvider,
        private emitterService: EmitterService,
        private storageService: StorageService,
        private socketService: SocketService,
        private sanitizer: DomSanitizer,
        private translateService: TranslateService,
        private companyProvider: CompanyProvider,
        private toastService: CommonToastService,
    ) { }

    ngOnInit(): void {
       this.init();
    }

    get correspondents() { return this.othersCorrespondents(); }

    private socketListener(): void {
        this.socket.emit('roomStatus', {
            room: this.conversation.id
        });

        this.socket.on('message', (data) => {
            if (this.conversation.id === data.room) {
                const message = {
                    content: data.text,
                    media: data.medias,
                    account: data.author,
                    createdAt: data.created
                };

                if (data.author.id === this.me.id) {
                    this.unlock.emit(true);
                }

                this.conversation.messages.push(new ConversationMessage(message));
                this.scroller();
            }
        });

        this.socket.on('connectedUsers', (data) => {
            if (this.conversation.id === parseInt(data.room, 10)) {
                this.conversation.connected = data.users;
            }
        });

        this.socket.on('typingMessage', (data) => {
            if (this.conversation.id === data.room && this.me.id !== data.account.id) {
                if (data.isTyping) {
                    this.writing = data.account.firstname;
                } else {
                    this.writing = '';
                }
            }
        });
    }

    othersCorrespondents(): any {
        if (this.members.length > 1) {
            return this.members.filter((account, index) => {
                return index !== 0;
            });
        }
        return [];
    }

    send(media?: Media): void {
        if (media) {
            this.content.medias.push(media);
        }
        if (this.conversation.id) {
            this.sendMessage();
        } else {
            this.create();
        }

        this.content = {
            message: '',
            medias: []
        };
    }

    sendMessage(): void {
        if(!this.content?.message?.replace(/\n/g, '').trim().length && !this.content?.medias.length){
            this.unlock.emit(true);
            return;
        }
        this.socket.emit('sendMessage', {
            account: this.me,
            room: this.conversation.id,
            text: this.content.message,
            medias: this.content.medias
        });

        this.typing(false);

        const params = {
            message: this.content.message,
            medias: this.content.medias.length > 0 ? [this.content.medias[0].id] : [],
            connected: this.conversation.connected || [],
            companyId: this.company.id
        };

        this.conversationProvider
            .sendMessage(this.conversation.id, params)
            .subscribe(
                (data) => {
                    if (!this.socket.connected || this.newConversation) {
                        this.conversation.messages.push(data.message);
                    }
                    this.scroller();
                    this.unlock.emit(true);
                },
                () => {
                    this.unlock.emit(true);
                    this.toastService.onError(this.translateService.instant('toast.error.conversation-failed'));
                }
            );
    }

    create(): void {
        this.newConversation = true;
        if (!(this.members.length > 0)) {
            return;
        }

        const accounts = this.members.map((member: any) => member.id || member.accountId);

        const params = {
            companyId: this.company.id,
            message: this.content.message,
            medias: this.content.medias.length > 0 ? [this.content.medias[0].id] : [],
            accounts
        };

        this.conversationProvider
            .create(params)
            .subscribe((data) => {
            this.emitterService.set('conversation.created', data.data);
                this.unlock.emit(true);
        });

    }

    scroller(): void {
        const timeout = setTimeout(() => {
            const height = this.simpleBar.getScrollElement().scrollHeight;
            this.simpleBar.getScrollElement().scrollTo(0, height);
            clearTimeout(timeout);
        }, 200);
    }

    activeSearch(): void {
        this.displaySearch = !this.displaySearch;
        if (!this.displaySearch) {
            this.searchMembersCollection = [];
            this.query = '';
        }
    }

    search(query?): void {
        this.query = query;
        if (this.query) {
            const params = {
                q: this.query,
                type: 'conversation',
                conversationId: this.conversation.id

            };

            this.conversationProvider.searchMembers(params).subscribe((data) => {
                this.searchMembersCollection = data;
            });
        }
    }

    addMember(member: Account): void {
        if (this.conversation.id) {
            const params = {
                accounts: [member.id]
            };
            this.conversationProvider.addMembers(this.conversation.id, params).subscribe();
        }
        this.members.push(member);

        const index = this.searchMembersCollection.findIndex((searchMember) => {
            return searchMember.id === member.id;
        });

        if (index > -1) {
            this.searchMembersCollection.splice(index, 1);
        }
    }

    leave(): void {
        this.conversationProvider.leave(this.conversation.id).subscribe(() => {
            this.emitterService.set('conversation.left', this.conversation.id);
        });
    }

    back(): void {
        this.emitterService.set('conversation.created', null);
        this.emitterService.set('conversation.back', true);
    }

    isConnected(id: number): boolean {
        if (this.conversation.connected) {
            return this.conversation.connected.findIndex((connected) => {
                return connected === id;
            }) > -1;
        } else {
            return false;
        }
    }

    typing(active: boolean): void {
        if (!this.isTyping) {
            this.isTyping = active;
            this.socket.emit('typingMessage', {
                account: this.me,
                room: this.conversation.id,
                isTyping: true
            });
        }

        if (!active) {
            this.isTyping = active;
            this.socket.emit('typingMessage', {
                account: this.me,
                room: this.conversation.id,
                isTyping: false
            });
        }
    }

    openPicture(picture: any): void {
        this.selectedPicture = picture;
    }

    closePicture(): void {
        this.selectedPicture = null;
    }

    getEmbed(url: string, index: number): SafeResourceUrl {
        return this.embeds[index] ? this.embeds[index] : this.embeds[index] = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    init(){
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.socket = this.socketService.getSocket();

        this.route.data.subscribe((data) => {
            this.cleanUpListeners();
            this.writing = '';
            this.content = {
                message: '',
                medias: []
            };
            this.members = [];
            this.query = '';
            this.displaySearch = false;
            this.searchMembersCollection = [];

            this.conversation = data.conversation;

            if (this.conversation) {
                this.conversation.members.map((member: any) => {
                    if (member.account) {
                        this.members.push(member.account);
                    }
                });
            } else {
                this.conversation = new Conversation({ id: null });
                if (this.storageService.get('conversationUsers')) {
                    this.members = this.storageService.getFlash('conversationUsers');
                }

                this.route.queryParams.subscribe(params => {
                    if (params.account_ids) {
                        const param = {
                            account_ids: params.account_ids.split(',')
                        };

                        this.companyProvider.getAccounts(this.company.id, param).subscribe(accounts => {
                            this.members = accounts;
                        });

                        if (params.action === 'on-demand') {
                            this.content.message = this.translateService.instant('conversation.on-demand');
                        }
                    }
                });
            }

            this.socketListener();

            if (this.simpleBar) {
                this.simpleBar.unMount();
            }
            this.simpleBar = new SimpleBar(this.simpleBarElement.nativeElement);
            this.scroller();
            this.emitterService.set('conversation.back', false);
        });
    }

    cleanUpListeners(){
        this.socket.off('message');
        this.socket.off('connectedUsers');
        this.socket.off('typingMessage');
    }

    ngOnDestroy() {
        this.cleanUpListeners();
    }
}
