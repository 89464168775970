import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { LearnRoutingModule } from './learn-routing.module';

import { SharedModule } from '../../shared.module';

import { LearnComponent } from './learn.component';

// Pages Overview
import { LearnOverviewComponent } from './overview/overview.component';

// Widgets Overview
import { WidgetNextEventComponent } from './overview/widgets/next-event/next-event.component';
import { WidgetContentComponent } from './overview/widgets/content/content.component';
import { WidgetContactComponent } from './overview/widgets/contact/contact.component';
import { WidgetStatisticsComponent } from './overview/widgets/statistics/statistics.component';
import { WidgetResourceComponent } from './overview/widgets/resource/resource.component';

// Pages Onboarding
import { LearnOnboardingComponent } from './onboarding/onboarding.component';

// Pages Contents
import { LearnContentComponent} from './content/content.component';
import { LearnContentListComponent } from './content/list/list.component';
import { LearnContentSavedComponent } from './content/saved/saved.component';

// Pages Catalogue
import { LearnCatalogueComponent } from './catalogue/catalogue.component';
import { LearnCatalogueSliderComponent } from './catalogue/slider/slider.component';
import { LearnCatalogueListComponent } from './catalogue/list/list.component';
import { LearnCatalogueWatchComponent } from './catalogue/watch/watch.component';
import { LearnCatalogueWatchExploreComponent } from './catalogue/watch/details/explore/explore.component';
import { LearnCatalogueWatchThematicComponent } from './catalogue/watch/details/thematic/thematic.component';
import { LearnCatalogueWatchLevelComponent } from './catalogue/watch/details/level/level.component';

// Pages Community
import { LearnCommunityComponent } from './community/community.component';
import { LearnCommunityListComponent } from './community/list/list.component';
import { LearnCommunityWatchComponent } from './community/watch/watch.component';
import { LearnCommunityModalSubscribeComponent } from './community/list/modal/modal-subscribe.component';

// Pages Details
import { LearnDetailComponent } from './detail/detail.component';
import { LearnDetailInstanceComponent } from './detail/instance/instance.component';
import { LearnDetailInstanceCorrectionComponent } from './detail/instance/correction/correction.component';
import { LearnDetailInstanceSummaryComponent } from './detail/instance/summary/summary.component';
import { LearnDetailInstancePresentationComponent } from './detail/instance/presentation/presentation.component';
import { LearnDetailExternalContentResultComponent } from './detail/external-content/result/result.component';
import { LearnDetailInstanceDiscussionComponent } from './detail/instance/discussion/discussion.component';
import { LearnDetailJourneyComponent } from './detail/journey/journey.component';
import { LearnDetailJourneyCorrectionComponent } from './detail/journey/correction/correction.component';
import { LearnDetailJourneySummaryComponent } from './detail/journey/summary/summary.component';
import { LearnDetailJourneyPresentationComponent } from './detail/journey/presentation/presentation.component';
import { LearnDetailJourneyDiscussionComponent } from './detail/journey/discussion/discussion.component';
import { LearnDetailTemplateComponent } from './detail/template/template.component';
import { LearnDetailTemplateSummaryComponent } from './detail/template/summary/summary.component';
import { LearnDetailTemplatePresentationComponent } from './detail/template/presentation/presentation.component';
import { LearnDetailExternalContentComponent } from './detail/external-content/external-content.component';
import { LearnDetailExternalContentPresentationComponent } from './detail/external-content/presentation/presentation.component';
import { LearnIntegrationComponent } from './integration/integration.component';
import { AppCommon2Module } from '@common2/common.module';
import { PresentationAdditionalDetails } from './detail/components/additional-details/additional-details.component';
import { LearnBoclips } from './boclip/boclip.component';
import { OnementorComponent } from './content/onementor/onementor.component';

@NgModule({
    declarations: [
        LearnComponent,
        LearnOverviewComponent,
        LearnIntegrationComponent,
        WidgetNextEventComponent,
        WidgetContentComponent,
        WidgetContactComponent,
        WidgetStatisticsComponent,
        WidgetResourceComponent,
        LearnOnboardingComponent,
        LearnContentComponent,
        LearnContentListComponent,
        LearnContentSavedComponent,
        LearnCatalogueComponent,
        LearnCatalogueSliderComponent,
        LearnCatalogueListComponent,
        LearnCatalogueWatchComponent,
        LearnCatalogueWatchExploreComponent,
        LearnCatalogueWatchThematicComponent,
        LearnCatalogueWatchLevelComponent,
        LearnCommunityComponent,
        LearnCommunityListComponent,
        LearnCommunityWatchComponent,
        LearnCommunityModalSubscribeComponent,
        LearnDetailComponent,
        LearnDetailInstanceComponent,
        LearnDetailInstanceCorrectionComponent,
        LearnDetailInstanceSummaryComponent,
        LearnDetailInstancePresentationComponent,
        LearnDetailExternalContentResultComponent,
        LearnDetailInstanceDiscussionComponent,
        LearnDetailJourneyComponent,
        LearnDetailJourneyCorrectionComponent,
        LearnDetailJourneySummaryComponent,
        LearnDetailJourneyPresentationComponent,
        LearnDetailJourneyDiscussionComponent,
        LearnDetailTemplateComponent,
        LearnDetailTemplatePresentationComponent,
        LearnDetailTemplateSummaryComponent,
        LearnDetailExternalContentComponent,
        LearnDetailExternalContentPresentationComponent,
        PresentationAdditionalDetails,
        LearnBoclips,
        OnementorComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        SharedModule,
        LearnRoutingModule,
        AppCommon2Module,
    ]
})
export class LearnModule {}
