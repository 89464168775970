import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonToastService } from '@common2/services/toast.service';
import { DataHelper, DownloadHelper, HttpHelper, UrlHelper } from '@helpers';
import { Company, ContentProvider, ExternalContent, ExternalContentProvider, InstanceProvider, JourneyProvider } from '@lighty';
import { TranslateService, StorageService, ErrorStreamService, EmitterService, RedirectService } from '@services';
import { finalize, tap } from 'rxjs';
import { environment as env } from '@env/environment';
import { DEFAULT_PROVIDER_LIST_KEYS } from 'src/app/pages/organize/integration/providers/providers.component';
import { environment } from '@env/environment';
import Hls from 'hls.js';
@Component({
    selector: 'content-card',
    templateUrl: './content-card.component.html',
    styleUrls: ['./content-card.component.scss']
})
// WIP
export class ContentCardComponent {
    @Input() content;
    @Input() deleteEmitter: EventEmitter<any> = new EventEmitter();
    @Input() width;
    @Output() onEvent: EventEmitter<any> = new EventEmitter();
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
    @ViewChild('modalUnsubscribe') modalUnsubscribe;
    @ViewChild('videoPlayer') videoPlayer: ElementRef;
    @ViewChild('modalBoclip') modalBoclip;
    public company: Company;
    public me: any;
    public loading: any = {
        bookboon: false,
        trainingexpress: false
    };
    get logo(): string { return this.getLogo(this.content?.data); }
    get skillList(): string { return this.content.data?.skills.slice(1).map(skill => skill.name).join(', '); }

    constructor(
        private elementRef: ElementRef,
        private router: Router,
        private translateService: TranslateService,
        private instanceProvider: InstanceProvider,
        private storageService: StorageService,
        private errorStreamService: ErrorStreamService,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private redirectService: RedirectService,
        private externalContentProvider: ExternalContentProvider,
        private journeyProvider: JourneyProvider,
        private contentProvider: ContentProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        if(this.width){
            this.elementRef.nativeElement.style.width = `${this.width}px`;
        }
    }

    ngAfterViewInit(): void {
      }

    deleteCourse(refused: boolean = false): void {
        if (refused) {
            this.deleteContent({ message: '' });
        } else {
            this.modalUnsubscribe.type = this.content.type;
            this.modalUnsubscribe.open(this.content.data.title || this.translateService.instant('words.untitled'));

            this.modalUnsubscribe.onSave.subscribe((data) => {
                this.onDelete.emit(false);
                this.deleteContent(data);
                this.modalUnsubscribe.close();
            });
        }
    }

    deleteContent(data) {
        switch (this.content.type) {
            case 'external_content':
                this.unsubscribeExternalContent(data);
                return;
            case 'traject':
                this.unsubscribeJourney();
                return;
            default:
                this.unsubscribeInstance(data);
        }
    }

    navigateContent(): void {
        console.log(this.content.type)
        switch (this.content.type) {
            case 'traject':
                this.navigateContentJourney();
                break;
            case 'external_content':
            case 'external_video':
                this.navigateContentExternal();
                break;
            case 'course_instance':
                this.navigateContentCourseSubscribed();
                break;
            case 'course_template':
                this.navigateContentCourse();
                break;
            default:
        }
    }

    navigateContentCourseSubscribed(): void {
        const url = `learn/${this.company.slug}/detail/instance/${this.content.data.id}`;
        this.router.navigateByUrl(url);
    }

    navigateContentCourse(): void {
        const url = `learn/${this.company.slug}/detail/template/${this.content.data.savableId ? this.content.data.savableId : this.content.data.id}`;
        this.router.navigateByUrl(url);
    }

    navigateContentExternal(): void {
        const url = `learn/${this.company.slug}/detail/external-content/${this.content.type === 'external_content' ? this.content.data.provider ? this.content.data.provider.key : 'resource' : this.content.type}/${this.content.data.id}`;
        this.router.navigateByUrl(url);
    }

    navigateContentJourney(): void {
        this.storageService.set('card_redirect', 'summary');
        const url = `learn/${this.company.slug}/detail/journey/${this.content.data.id}`;
        this.router.navigateByUrl(url);
    }

    playContent(): void {
        switch (this.content.type) {
            case 'traject':
                this.playContentJourney();
                break;
            case 'external_content':
                if (this.content.data.provider) {
                    switch (this.content.data.provider.key) {
                        case 'trainingexpress':
                            this.loading.trainingexpress = true;
                            this.getNewUrl().pipe(tap(() => {
                                UrlHelper.navigate(this.content.data.url)
                                this.loading.trainingexpress = false;
                            })).subscribe();
                            break;
                        case 'netzun':
                            this.loading.trainingexpress = true;
                            this.getNewUrl().pipe(tap(() => {
                                UrlHelper.navigate(this.content.data.url)
                                this.loading.trainingexpress = false;
                            })).subscribe();
                            break;
                        default:
                            this.playContentExternal();
                    }
                } else {
                    this.playContentExternal();
                }
                break;
            case 'external_video':
                this.playContentVideo();
                break;
            case 'course_instance':
                this.playContentCourse();
                break;
            default:
        }
    }

    playContentExternal(): void {
        if (!this.content.data.subscribed) {
            this.externalContentProvider
                .subscribeContent(this.content.data.id)
                .subscribe(() => {
                    this.content.data.subscribed = true;
                    this.handleOpenExternalContentRedirection();

                    this.emitterService.set('player-light.open', { type: 'external', content: this.content.data, more: this.content.data, progressEnabled: this.content.data.provider ? this.content.data.provider.progressEnabled : true });
                });
        } else {
            this.handleOpenExternalContentRedirection();
            this.setExternalContentDataAgain(this.content.data);
            this.emitterService.set('player-light.open', { type: 'external', content: this.content.data, more: this.content.data, progressEnabled: this.content.data.provider ? this.content.data.provider.progressEnabled : true });
        }
    }

    handleOpenExternalContentRedirection():void{
        if(
            // to be optimized
            (this.content?.data?.url?.includes('udemy.com')
                || this.content?.data?.url?.includes('linkedin.com')
                || this.content?.data?.url?.includes('contentraven.com')
                || this.content?.data?.url?.includes('learninghubz.com')
                || this.content?.data?.url?.includes('coursera.org')
                || this.content?.data?.url?.includes('platzi.com')
            )
            && `${UrlHelper.getHost()}/` !== environment.envVar.APP_URL.replace('https://', '').replace('http://', '')){
            this.externalContentProvider.storeUserToken(this.content.data.url)
                .subscribe((token:string) => {
                    UrlHelper.navigate(`${environment.envVar.CLOUD_URL}/authenticated-redirect?token=${token}`);
                })
        } else{
            UrlHelper.navigate(this.content.data.url);
        }
    }

    getLocalUrl(scope: string) {
        const localUrl = UrlHelper.getProtocol() + '//' + UrlHelper.getHost();
        switch (true) {
            case scope === 'app': return `${localUrl}/learn`;
            case scope === 'player': return `${localUrl}/` === env.envVar.APP_URL ? env.envVar.PLAYER_URL : `${localUrl}/player`;
            default: return localUrl;
        }
    }

    playContentJourney(): void {
        this.journeyProvider
            .resumeJourney(this.company.id, this.content.data.id)
            .subscribe(({ scope, url }) => {
                const localUrl = this.getLocalUrl(scope)
                UrlHelper.navigate(localUrl + '/' + url, '_self');
            });
    }

    playContentVideo(): void {
        if (this.content.data.subscribed) {
            this.emitterService.set('player-light.open', { type: 'video', content: this.content.data, more: this.content.data });
        } else {
            this.externalContentProvider
                .subscribeVideo(this.content.data.id, this.content.data)
                .subscribe(() => {
                    this.emitterService.set('player-light.open', { type: 'video', content: this.content.data, more: this.content.data });
                });
        }
    }

    playContentCourse(): void {
        if (this.content.data.playerLight && !DataHelper.isEmpty(this.content.data.playerLight) && this.content.data.playerLight.context === 'internal') {
            this.emitterService.set('player-light.open', { type: this.content.data.playerLight.type, content: this.content.data.playerLight, more: this.content.data });
        } else if (!DataHelper.isEmpty(this.content.data.playerLight) && this.content.data.playerLight.context === 'external_link') {
            this.emitterService.set('player-light.open', { type: 'activity', subscriptionCourseId: this.content.data.id, more: this.content.data });
        } else {
            this.redirectService.navigate('player', ['course', this.content.data.id]);
        }
    }

    subscribeAndDownloadExternalContent(): void {
        this.errorStreamService.locked();
        this.loading.bookboon = true;
        this.toastService.onSuccess(this.translateService.instant('toast.downloading.soon'));

        if (this.content.data.progress === 100) {
            this.downloadExternalContent();
        } else {
            this.externalContentProvider
                .subscribeContent(this.content.data.id)
                .subscribe(() => {
                    this.downloadExternalContent();
                });
        }
    }

    downloadExternalContent(): void {
        this.externalContentProvider
            .download(this.content.data.id)
            .subscribe({
                next: (data) => {
                    this.errorStreamService.unlocked();
                    DownloadHelper.downloadBlob(data, this.content.data.title);
                    this.loading.bookboon = false;
                    this.toastService.onSuccess(this.translateService.instant('toast.bookboon.download-success'));
                    this.emitterService.set('player-light.open', { type: 'external', content: this.content.data, more: this.content.data, progressEnabled: this.content.data.provider ? this.content.data.provider.progressEnabled : true });
                },
                error: () => {
                    this.errorStreamService.unlocked();
                    this.loading.bookboon = false;
                    this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                }
            });
    }

    getNewUrlParams() {
        return {
            company_id: this.company.id,
            provider_id: this.content.data.provider.id,
            context_id: this.content.data.id,
            context_type: 'external_content',
        };
    }

    getNewUrl() {
        return this.externalContentProvider
            .generateUrl(this.getNewUrlParams())
            .pipe(
                tap(({ url }) => { this.content.data.url = url; })
            );
    }

    setExternalContentDataAgain(content: ExternalContent) {
        if (content && content.provider && content.provider.key === 'trainingexpress') {
            this.externalContentProvider
                .getExternalContentDetails(content.id, { context: 'content', company_id: this.company?.id })
                .pipe(
                    tap((content: ExternalContent) => this.onTapGetExternalContentDataAgain(content))
                )
                .subscribe();
        }
    }

    onTapGetExternalContentDataAgain(content: ExternalContent) {
        this.content.data.url = content.url;
    }

    changeLearnLaterContent(): void {
        if (this.content.saved) {
            this.deleteSavedContent();
        } else {
            this.addToSavedContents();
        }
    }

    addToSavedContents(): void {
        this.content.saved = true;
        const params = {
            context: this.content.type,
            context_id: this.content.data.id
        };

        this.contentProvider.addSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.saved'));
        });
    }

    deleteSavedContent(): void {
        this.content.saved = false;
        const params = {
            context: this.content.type,
            context_id: this.content.data.id
        };

        this.contentProvider.deleteSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.deleted'));
        });
    }

    unsubscribeExternalContent(data) {
        const params = HttpHelper.cleanParams({
            message: data.message
        });

        if (this.content.data.type === 'external_video') {
            const params = {
                provider: 'external_video'
            };

            this.externalContentProvider
                .unsubscribeVideo(this.content.data.id, params).pipe(finalize(() => {
                    this.onEvent.emit('completeDelete');
                }))
                .subscribe(() => {
                    this.toastService.onSuccess(this.translateService.instant('toast.unregister'));
                });
        } else {
            this.externalContentProvider
                .unsubscribe(this.content.data.id).pipe(finalize(() => {
                    this.onEvent.emit('completeDelete');
                }))
                .subscribe(() => {
                    this.toastService.onSuccess(this.translateService.instant('toast.unregister'));
                });
        }
    }

    unsubscribeInstance(data) {

        const params = HttpHelper.cleanParams({
            message: data.message
        });

        this.instanceProvider.unsubscribe(this.content.data.id, params).pipe(finalize(() => {
            this.onEvent.emit('completeDelete');
        })).subscribe(() => {
            this.errorStreamService.unlocked();
            this.toastService.onSuccess(this.translateService.instant('toast.unregister'));
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.unsubscribe.group.forbidden'));
            this.errorStreamService.unlocked();
        });
    }

    unsubscribeJourney() {
        this.journeyProvider.unsubscribe(this.content.data.id).pipe(finalize(() => {
            this.onEvent.emit('completeDelete');
        })).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.unregister'));
        });
    }

    getType(content: any): string {
        if(content.data.type == 'boclips'){
            //return content.data.format;
            const format = `boclips.${content.data.format}`.toLowerCase();
            return this.translateService.instant(format)
        } else 
        switch (content.type) {
            case 'external_content':
            case 'external_video':
                if (content.data.externalContentType) {
                    return `api.content-type.${content.data.externalContentType}`;
                } else {
                    return `api.content-type.${content.data.type}`;
                }
            case 'traject':
                return 'api.content-type.traject';
            default:
                return 'api.content-type.course';
        }
    }

    getLogo(datum: any) {
        if(datum.type === 'boclips')
        return `url(https://static.myskillcamp.com/images/integrations/boclips.svg)`;
        switch (true) {
            // If there is a customCampMedia , you need to display this media
            case !!datum && datum.hasOwnProperty('customCampMedia') && !!datum.customCampMedia:
                return `url(${datum?.customCampMedia?.pictureUrl}?size=256)`;
            // If there is NO customCampMedia , you need to check whether there is a provider.media
            case !!datum && datum.hasOwnProperty('provider') && !!datum.provider && datum.provider.hasOwnProperty('media') && !!datum?.provider?.media:
                // If company.id === provider.responsibleId && provider.responsibleType === 'company')  => display the company_logo and not the provider.media logo
                return this.company?.id === datum.provider.responsibleId && datum.provider.responsibleType === 'company' ?
                    `url(${this.company?.experience?.displayPicture}?size=256)` : `url(${datum.provider.media.pictureUrl}?size=256)`;
            // If there is no  provider.media  check if provider is Coursera, Goodhabitz, Bookboon etc...
            case !!datum && datum.hasOwnProperty('provider') && DEFAULT_PROVIDER_LIST_KEYS.includes(datum?.provider?.key):
                return `url(https://static.myskillcamp.com/images/integrations/${datum.provider.key}.svg)`;
            // If there is no  provider.media + NOT IN know providers , you need to display the company media image
            default:
                return `url(${this.company?.experience?.displayPicture}?size=256)`;
        }
    }

    editContent(): void {
        this.emitterService.toggleExtension.emit({ type: 'template', data: this.content.data });
    }

    public openVideo(videoUrl: string): void {
        this.modalBoclip.onOpen();
        setTimeout(() => {
                  if (Hls.isSupported()) {
            const video = this.videoPlayer.nativeElement;
            const hls = new Hls();
            
            hls.loadSource(videoUrl);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, function() {
              video.play();
              hls.subtitleDisplay = true; 
            });
          } else {
            console.error('HLS no es soportado en este navegador.');
          }  
        }, 1000);

    }
}
