import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-learn-detail-external-content',
    templateUrl: './external-content.component.html'
})
export class LearnDetailExternalContentComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
