import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ReportingProvider {

    constructor(private http: HttpClient) { }

    adoption(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.REPORTING_URL}/companies/${id}/meta/adoption`, params);
    }

    exportAdoption(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.REPORTING_URL}/companies/${id}/export/adoption`, params, { responseType: 'blob' });
    }

    providers(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.REPORTING_URL}/companies/${id}/meta/providers`, params);
    }

    exportProviders(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.REPORTING_URL}/companies/${id}/export/providers`, params, { responseType: 'blob' });
    }

    provider(id: number, providerId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.REPORTING_URL}/companies/${id}/meta/providers/${providerId}`, params);
    }

    exportProvider(id: number, providerId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.REPORTING_URL}/companies/${id}/export/providers/${providerId}`, params, { responseType: 'blob' });
    }

    getContentSubscriptionOverview(companyId, contentId, accountIds, contentType = "course"): Observable<any> {
        if (contentType === 'course_instance') {
            contentType = 'course';
        }
        const params = {
            context: contentType,
            account_ids: accountIds.join(',')
        };

        return this.http.get(`${environment.envVar.REPORTING_URL}/companies/${companyId}/contents/${contentId}/subscriptions`, { params });
    }

    getContentSubscriptions(companyId, contents, accountId): Observable<any> {
        return this.http.post(`${environment.envVar.REPORTING_URL}/companies/${companyId}/contents/users/${accountId}/subscriptions`, { contents });
    }

    getContentLogs(companyId, contentId, accountId, context): Observable<any> {
        const params = {
            context,
            account_id: accountId
        };

        return this.http.get(`${environment.envVar.REPORTING_URL}/companies/${companyId}/contents/${contentId}/logs`, { params });
    }

    getActivitySubscriptionDetails(companyId, contentId, accountId, sectionContentIds, context = 'course'): Observable<any> {
        const params = {
            context: context,
            content_ids: sectionContentIds.join(",")
        };

        return this.http.get(`${environment.envVar.REPORTING_URL}/companies/${companyId}/contents/${contentId}/subscriptions/${accountId}/activities`, { params });
    }

    getAccountOverview(companyId, accountId): Observable<any> {
        return this.http.get(`${environment.envVar.REPORTING_URL}/companies/${companyId}/users/${accountId}/aggs`);
    }
}
