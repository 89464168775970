<msc-common-overlay #overlay [direction]="direction">
    <ng-template>
        <ng-container *ngTemplateOutlet="templateMenu || defaultTemplateMenu"></ng-container>
    </ng-template>
</msc-common-overlay>

<ng-template #defaultTemplateMenu>
    <div class="menu">
        <ng-container *ngFor="let menuItem of menuItems">
            <div class="item" (click)="menuItem.callback && menuItem.callback();onSelection.emit(menuItem);close()" [routerLink]="menuItem.routerLink" [queryParams]="menuItem.queryParams">
                <div class="item-icon icon-left" *ngIf="menuItem.iconLeft">
                    <i class="icon {{menuItem.iconLeft}}"></i>
                </div>
                <span class="label">
                    {{menuItem.label}}
                </span>
                <div class="item-icon icon-right" *ngIf="menuItem.iconRight">
                    <i class="icon {{menuItem.iconRight}}"></i>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>