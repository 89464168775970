import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//
import { map } from 'rxjs/operators';
//
import { HttpUtils } from '@common2/utils/http.utils';
import { COMPANY_LIST_API_URL_ROOT } from '@core/tokens';
import { ApiLearningPathGetHistoryResponse } from '@modules/coach/coach.types';

@Injectable({ providedIn: 'root' })
export class CoachContentsAttendeesApiService {
    constructor(
        @Inject(COMPANY_LIST_API_URL_ROOT) private readonly companyListApi: string,
        private readonly http: HttpClient,
    ) { }

    /**
     * Get the content list
     */
    getLearningPathHistoryList(companyId: number, contentId: number, params: any = {}) {
        return this.http.get(`${this.companyListApi}/${companyId}/manage/contents/${contentId}/history?context=traject`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<ApiLearningPathGetHistoryResponse>) => response.body)
        );
    }
}
