import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmitterService, StorageService } from '@services';
import { ExtensionLink } from '@interfaces';

@Component({
    selector: 'msc-shared-aside-extension-form',
    templateUrl: './extension-form.component.html'
})
export class SharedExtensionFormComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    public access: any;
    public loading: boolean = false;
    public disabled: boolean = false;
    public wrongURL: boolean = false;
    public requiredURL: boolean = false;

    constructor(private emitterService: EmitterService, private storageService: StorageService) { }

    ngOnInit(): void {
        this.access = this.storageService.get('access');

        this.emitterService.loadingExternalResource.subscribe((data) => {
            this.loading = data.loading;
            this.wrongURL = data.error || false;
            this.disabled = data.loading || data.error;
        });
    }

    nextPanel(): void {
        if (!this.extensionLink.url) {
            this.requiredURL = true;
            return;
        }
        this.emitterService.extensionPage.emit('infos');
    }

    changeComma(): void {
        this.extensionLink.price = this.extensionLink.price.replace(',', '.');
    }
}
