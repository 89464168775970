import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Camp } from './camp';
import { environment } from '@env/environment';

@Injectable()
export class CampProvider {

    constructor(private http: HttpClient) {}

    getCamp(id: number, includes?: any): Observable<any> {
        const including = (includes) ? '?includes=' + includes : '';
        return this.http.get(`${environment.envVar.API_URL}/camps/${id}${including}`).pipe(
            map(data => new Camp(data))
        );
    }

    getCamps(companyId: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/camps`, {params}).pipe(
            map(data => data)
        );
    }

    getSettings(id: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camps/${id}/settings`).pipe(
            map(data => data)
        );
    }

    updateSettings(camp: Camp): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/camps/${camp.id}/settings`, camp).pipe(
            map(data => data)
        );
    }

    deleteCamp(id: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/camps/${id}`, {params}).pipe(
            map(data => data)
        );
    }
}
