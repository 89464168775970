import { Component } from '@angular/core';

@Component({
    selector: 'msc-camp-settings',
    templateUrl: './settings.component.html'
})
export class CampSettingsComponent {

    constructor() {}
}
