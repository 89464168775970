<div class="card card--progress card--hover-effect" (click)="redirectToJourney()">
    <div class="card__header">
        <!-- <div class="card__banner card__banner--container flex between" *ngIf="journey.provider?.logo || company.experience?.displayPicture"> -->
        <div class="card__banner card__banner--container flex between">
            <div class="card__banner--container__picture" [style.backgroundImage]="logo"></div>
            <msc-tag [color]="'red'" [text]="'words.journey' | translate"></msc-tag>
        </div>

        <div class="card__tags" [ngClass]="{'card__tags--banner': !journey.nextEvent}">
            <msc-tag *ngIf="journey.nextEvent"
                     [color]="'red'"
                     [type]="'date'"
                     [day]="journey.nextEvent | date: 'dd'"
                     [month]="journey.nextEvent | localizedDate: 'MMM'">
            </msc-tag>

            <!-- <msc-tag [color]="'red'" [text]="'words.journey' | translate" *ngIf="!journey.provider?.logo && !company.experience?.displayPicture"></msc-tag> -->
        </div>

        <div class="card__image">
            <div [ngStyle]="{'background-image': 'url(' + (journey.picture ? (journey.picture + '?size=512') : 'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + journey.id % 7 + '.jpg') + ')'}"></div>
        </div>
    </div>

    <div class="card__body">
        <div class="card__time" *ngIf="journey.totalDuration">
            <span class="card_time_text">{{ journey.totalDuration | duration }}</span>
        </div>

        <div class="card__states card__states--locked" *ngIf="journey.isLocked === true">
            <p>
                <i class="icon icon-lock"></i>
                {{ 'words.subscription-locked' | translate }}
            </p>
        </div>

        <div class="card__states card__states--registered" *ngIf="journey.subscribed && !journey.progress && journey.progress !== 0">
            <p>
                <i class="icon icon-checkmark-circle text-primary-500 text-lg"></i>
                {{ 'words.already-subscribed' | translate }}
            </p>
        </div>

        <div class="card__skills" *ngIf="journey.skills && journey.skills.length > 0">
            <div class="skill skill--level skill--no-hover" *ngFor="let skill of journey.skills | slice:0:1" [tooltip]="skill.name">
                <div class="skill__content">
                    <div class="skill__level" *ngIf="skill.level > 0">
                        <span [ngClass]="{'active': skill.level > 0}"></span>
                        <span [ngClass]="{'active': skill.level > 1}"></span>
                        <span [ngClass]="{'active': skill.level > 2}"></span>
                    </div>

                    <p class="skill__text">{{ skill.name | truncate: 10 }}</p>
                </div>
            </div>

            <div class="skill skill--more" *ngIf="journey.skills.length > 1">
                <div class="skill__content">
                    <div class="skill__text"></div>
                </div>
            </div>
        </div>

        <p class="card__title">
            <ng-container *ngIf="journey?.title?.length > 50; else titleTemplate">
                <span [tooltip]="journey?.title">{{ journey.title | truncate: 50 }}</span>
            </ng-container>
            <ng-template #titleTemplate>
                <span>{{ journey.title || ('words.untitled' | translate) }}</span>
            </ng-template>
        </p>

        <p class="card__desc leading-3" [innerHTML]="journey.description || ('words.undescribed-content' | translate) | truncate: 130"></p>
    </div>

    <div class="card__footer">
        <div class="card__actions">
            <div class="card__buttons flex space-x-2">
                <msc-button [tooltip]="'words.launch' | translate" class="msc-button--primary" type="fab" palette="primary" icon="icon-arrrow-left-1" *ngIf="journey.subscribed && journey.progress >= 0" (click)="goToPlayer(); stopPropagation($event);"></msc-button>

                <msc-button class="msc-button--primary" type="fab" palette="primary" icon="icon-search" [tooltip]="'words.details' | translate"></msc-button>
            </div>

            <div class="card__widgets">
                <a *ngIf="!journey.subscribed" (click)="changeLearnLaterContent(); stopPropagation($event)" [tooltip]="'words.learn-later' | translate" direction="left">
                    <i class="icon" [ngClass]="{'icon-bookmark-1 color--orange' : saved, 'icon-bookmark' : !saved}"></i>
                </a>

                <a [tooltip]="'words.unsubscribe' | translate" *ngIf="journey.subscribed && !journey.subscriptionOrigins?.isFromGroup" (click)="unsubscribeJourney(); stopPropagation($event);">
                    <i class="icon icon-trash-2"></i>
                </a>

                <span class="cursor-not-allowed" [tooltip]="'tooltip.delete.community.not-allowed' | translate" (click)="stopPropagation($event)" *ngIf="journey.subscriptionOrigins?.isFromGroup">
                    <i class="icon icon-trash-2"></i>
                </span>
            </div>
        </div>

        <div class="card__progress" *ngIf="journey.subscribed && journey.progress >= 0">
            <msc-progress-bar [progress]="journey.progress" [color]="journey.progress !== 100 ? 'blue': ''"></msc-progress-bar>
        </div>
    </div>
</div>
