<ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'success'">
        <ng-container *ngIf="rows.length || isInit; else emptyTemplate">
            <msc-common-table [columns]="columns" [rows]="rows">
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-checkbox [isPartial]="thCheckboxIsPartial" [value]="thCheckboxIsSelected" (changeEvent)="onModify($event)"></msc-common-th-checkbox>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span></span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span>{{ datum.label }}</span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span>{{ datum.label }}</span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span></span>
                </ng-template>

                <ng-template #tdTemplate let-datum="datum">
                    <msc-common2-checkbox type="tick" [value]="datum.isSelected" (changeEvent)="onChange($event, datum)"></msc-common2-checkbox>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <i class="icon icon-{{datum.icon}} icon-table"></i>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <span class="td-title" [tooltip]="datum.title">{{ datum.title }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <span>{{ datum.typeLabel }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <span>{{ datum.languageLabel }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <msc-common-td-categories [data]="datum.category"></msc-common-td-categories>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <a (click)="onRedirect(datum)"><i class="icon icon-eye"></i></a>
                </ng-template>
            </msc-common-table>
        </ng-container>

        <ng-template #emptyTemplate>
            <msc-common-empty [message]="'organize.marketplace.catalogue.table.empty.title' | translate">
                <ng-template>
                    <p>
                        <span>{{ 'organize.marketplace.catalogue.table.empty.description' | translate }}</span>&nbsp;
                        <a (click)="onGoToCreate()">{{ 'organize.marketplace.catalogue.table.empty.link' | translate }}</a>
                    </p>
                </ng-template>
            </msc-common-empty>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
        <msc-common-spinner *ngIf="true"></msc-common-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
        <!-- <msc-common-error>
            <ng-template></ng-template>
        </msc-common-error> -->
    </ng-container>
</ng-container>
