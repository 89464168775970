import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Company } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { UrlHelper, DataHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-user-modal-manage-invitation',
    templateUrl: './manage-invitation.component.html'
})
export class OrganizeUserModalManageInvitationComponent implements OnInit {
    @Input() user: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSend: EventEmitter<any> = new EventEmitter();
    public isActive: boolean = false;
    public invitationType: string = '';
    public data: any;
    public invitationLink: string;
    public company: Company;

    constructor(
        private toastService: CommonToastService,
        private storageService: StorageService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.isActive = true;

        this.data = {
            notification: 'auto',
            message: ''
        };

        this.company = this.storageService.get('company');

        this.invitationLink = this.user.experience.customUrl ? this.user.experience.customUrl : this.company.customUrl ? this.company.customUrl : UrlHelper.getUrl();
        this.invitationLink += '/signup?email=' + encodeURIComponent(this.user.account.email);
        this.invitationLink += this.user.account.firstname ? '&firstname=' + this.user.account.firstname : '';
        this.invitationLink += this.user.account.lastname ? '&lastname=' + this.user.account.lastname : '';
        this.invitationLink += '&company=' + this.company.slug;
        this.invitationLink += this.user.experienceSlug ? '&experience=' + this.user.experienceSlug : this.company.defaultExperienceSlug ? '&experience=' + this.company.defaultExperienceSlug : '';
        this.invitationLink += this.user.account.language ? '&lang=' + this.user.account.language.code : 'fr';
    }

    changeType(event: any, type?: string): void {
        this.stopPropagation(event);
        this.invitationType = type ? type : '';
    }

    changeNotification(type: string): void {
        this.data.notification = type;
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    clipboard(): void {
        DataHelper.clipboard(this.invitationLink);
        this.toastService.onSuccess(this.translateService.instant('toast.link-clipboard'));
    }

    close(): void {
        this.onClose.emit(true);
    }

    send(): void {
        const data = {
            type: 'normal',
            account_id: this.user.account.id,
            message: this.data.notification === 'custom' ? this.data.message : ''
        };
        this.onSend.emit(data);
    }

    sendReminder(): void {
        const data = {
            type: 'reminder',
            account_id: this.user.account.id
        };
        this.onSend.emit(data);
    }
}
