import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataHelper, DownloadHelper } from '@helpers';
import { Instance } from '@lighty';
import { EmitterService, RedirectService } from '@services';

@Component({
    selector: 'msc-learn-detail-instance-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss'],
})

export class LearnDetailInstancePresentationComponent implements OnInit {
    public instance: any;

    constructor(private route: ActivatedRoute,  private emitterService: EmitterService, private redirectService: RedirectService) {}

    ngOnInit(): void {
        this.instance = this.route.parent.snapshot.data.instance;
        this.instance = this.getInstanceRemapped(this.instance);
    }

    downloadIcs(ics: any): void {
        DownloadHelper.download(ics, '', false);
    }

    start(): void {
        if (!DataHelper.isEmpty(this.instance.playerLight) && this.instance.playerLight.context === 'internal') {
            this.emitterService.set('player-light.open', {type: this.instance.playerLight.type, content: this.instance.playerLight, more: this.instance});
        } else if (!DataHelper.isEmpty(this.instance.playerLight) && this.instance.playerLight.context === 'external_link') {
            this.emitterService.set('player-light.open', {type: 'activity', subscriptionCourseId: this.instance.id, more: this.instance});
        } else {
            this.redirectService.navigate('player', ['course', this.instance.id]);
        }
    }

    getInstanceRemapped(datum: any) {
        const mediaUrl = this.getMediaUrl(datum);
        return { mediaUrl, ...datum };
    }

    getMediaUrl(datum: any) {
        switch (true) {
            case datum.hasOwnProperty('provider') && !!datum.provider.media: return `url(${datum.provider.media.pictureUrl}?size=256)`;
            case datum.hasOwnProperty('provider') && !datum.provider.media: return `url('https://static.myskillcamp.com/images/integrations/${datum.provider.key}.svg')`;
            case datum.hasOwnProperty('picture') && !!datum.picture: return `url(${datum.picture}?size=256)`;
            default: return `url('https://static.myskillcamp.com/lxp/images/logo-empty-state-${datum.id % 7}.jpg')`;
        }
    }
}
