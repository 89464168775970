<msc-common-aside #aside [title]="'skill.create-title' | translate">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div>
                <p class="mb-2">{{ 'skill.your-skill' | translate }}</p>
                <div class="flex flex-col space-y-2">
                    <div class="slide__input" *ngFor="let language of languages">
                        <div class="input input--flag">
                            <img [attr.src]="'https://static.myskillcamp.com/images/icon/icon-camp/flag_' + language.code + '.svg'"
                                class="input__flag">

                            <input autocomplete="off" type="text" class="input__text"
                                [(ngModel)]="data.translations[language.code]"
                                [placeholder]="'skill.your-skill' | translate">
                            <span class="input__icon">
                                <i class="icon icon-edit-2 text-primary-700"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-between">
                <div class="flex space-x-2">
                    <i class="icon icon-grid text-primary-500"></i>
                    <p>{{ 'words.thematic' | translate }}</p>
                </div>
                <msc-common2-switch [value]="data.thematic" (changeEvent)="change('thematic')">
                </msc-common2-switch>
            </div>

            <div class="flex justify-between">
                <div class="flex space-x-2">
                    <i class="icon icon-all-done text-primary-500"></i>
                    <p>{{ 'words.approval' | translate }}</p>
                </div>
                <msc-common2-switch [value]="data.validated" (changeEvent)="change('validated')">
                </msc-common2-switch>
            </div>

            <div>
                <p class="mb-2">{{ 'company.management.usage' | translate }}</p>

                <div class="flex space-x-2">
                    <msc-common2-radio groupName="skillAllowed" [(ngModel)]="data.visible"
                        [valueName]="true" [label]="'words.allowed' | translate">
                    </msc-common2-radio>
                    <msc-common2-radio groupName="skillAllowed" [(ngModel)]="data.visible"
                        [valueName]="false" [label]="'words.forbidden' | translate">
                    </msc-common2-radio>
                </div>
            </div>

            <div>
                <p class="mb-2">{{ 'skill.suggest-skill' | translate }}</p>

                <div class="flex space-x-2">
                    <msc-common2-radio groupName="skillSuggest" [(ngModel)]="suggest"
                        [valueName]="true" [label]="'words.yes' | translate">
                    </msc-common2-radio>
                    <msc-common2-radio groupName="skillSuggest" [(ngModel)]="suggest"
                        [valueName]="false" [label]="'words.no' | translate">
                    </msc-common2-radio>
                </div>
            </div>

            <div *ngIf="suggest">
                <msc-common-search class="tiny" trigger="auto" [placeholder]="'placeholder.type-skill' | translate"
                    (searchEvent)="search($event.value)"></msc-common-search>

                <div class="flex flex-wrap p-2">
                    <div class="skills__item" *ngFor="let skill of skills">
                        <div class="skill skill--shadow-none skill--level skill--selected" (click)="selectSkill(skill)"
                            [ngClass]="{'active': isActive(skill)}">
                            <div class="skill__content">
                                <p class="skill__text">{{ skill.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div class="flex items-center justify-between grow col-gap-xs ">
            <msc-common2-checkbox [value]="createAnotherSkill" [label]="'skill.create-new-skill' | translate"
                (changeEvent)="changeCreateAnotherSkill()"></msc-common2-checkbox>

            <div class="flex space-x-2">
                <msc-button (click)="aside.onClose()">{{ 'words.cancel' | translate }}</msc-button>

                <msc-button class="msc-button--primary" palette="secondary" (click)="create()">{{ 'words.save' |
                    translate
                    }}</msc-button>
            </div>
        </div>
    </ng-template>
</msc-common-aside>
