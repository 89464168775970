import { Component, OnInit } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { CompanyProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { BehaviorSubject, take } from 'rxjs';

@Component({
  selector: 'app-on-demand',
  templateUrl: './on-demand.component.html',
  styleUrls: ['./on-demand.component.scss']
})
export class OnDemandComponent implements OnInit {

  public company: any;
  public menuOptions: Array<any> = [];
  public currentCompanyProvideId: any = undefined;
  public isLoadingProviders: boolean = false;
  public isLoadingTable: boolean = false;
  public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public columns: Array<any> = [];
  public availableMembers: any = undefined;
  public isLoadingAvailableMembers: boolean = false;
  public selectedMembers: Array<Number> = [];

  constructor(
    private companyProvider: CompanyProvider,
    private storageService: StorageService,
    private translateService: TranslateService,
    private toastService: CommonToastService,
  ) { }

  ngOnInit(): void {
    this.columns = this.getColumns();
    this.isLoadingProviders = true;
    this.company = this.storageService.get('company');
    this.companyProvider.GetServicesOnDemand(this.company.id).pipe(take(1)).subscribe((data) => {
      this.isLoadingProviders = false;
      this.menuOptions = data.serviceProviders;
    })
  }

  public loadLearners(companyUserId: number): void {
    this.isLoadingTable = true;
    const params = {
      company_id: this.company.id,
      company_provider_id: companyUserId
    }
    this.currentCompanyProvideId = companyUserId;

    this.companyProvider.GetAddedLearnersOnDemandService(params).pipe(take(1)).subscribe((data) => {
      this.rows$.next(data.companyUsers)
      this.getAvailableMembers();
      this.isLoadingTable = false;
    })
  }

  public getColumns(): any {
    return [
      {
        key: 'firstname',
        label: this.translateService.instant('words.name'),
        width: '20%',
      },
      {
        key: 'lastname',
        label: this.translateService.instant('words.lastname'),
        width: '20%',
      },
      {
        key: 'email',
        label: this.translateService.instant('words.email'),
        width: '20%',
      },
      {
        key: '',
        width: '5%',
      },
    ];
  }

  public getAvailableMembers(): void {
    this.isLoadingAvailableMembers = true;
    const params = {
      company_id: this.company.id,
      company_provider_id: this.currentCompanyProvideId
    }
    this.companyProvider.GetAvailabeLearnersOnDemandService(params).pipe(take(1)).subscribe((data) => {
      this.availableMembers = data;
      this.isLoadingAvailableMembers = false;
    })
  }

  public addMembers(): void {
    const params = {
      companyProviderId: this.currentCompanyProvideId,
      companyUsersId: this.selectedMembers
    }
    this.companyProvider.AddLearnerOnDemandService(params).pipe(take(1)).subscribe((data) => {
      this.toastService.onSuccess(this.translateService.instant('words.demand-admind-add-user'));
      this.refreshData();
    })
  }

  public selectMember(member: number): void {
    if(this.selectedMembers.includes(member)) {
      const index = this.selectedMembers.indexOf(member);
      this.selectedMembers.splice(index, 1);
    } else {
      this.selectedMembers.push(member);
    }
  }

  public deleteMember(member: number): void {
    const params = {
      companyProviderId: this.currentCompanyProvideId,
      companyUserId: member
    }

    this.companyProvider.RemoveLearnerOnDemandService(params).pipe(take(1)).subscribe((data) => {
      this.toastService.onSuccess(this.translateService.instant('words.demand-admind-delete-user'))
      this.refreshData();
    })
  }

  public refreshData(): void {
    this.loadLearners(this.currentCompanyProvideId);
    this.getAvailableMembers();
  }

  public onSearchTable(e: any): void {
    const params = {
      company_id: this.company.id,
      company_provider_id: this.currentCompanyProvideId,
      filter: e.value
    }
    this.companyProvider.GetAddedLearnersOnDemandService(params).pipe(take(1)).subscribe((data) => {
      this.rows$.next(data.companyUsers)
    })
  }

  public onSearchAside(e: any): void {
    this.isLoadingAvailableMembers = true;
    this.selectedMembers = [];
    const params = {
      company_id: this.company.id,
      company_provider_id: this.currentCompanyProvideId,
      filter: e.value
    }
    this.companyProvider.GetAvailabeLearnersOnDemandService(params).pipe(take(1)).subscribe((data) => {
      console.log(data);
      this.availableMembers = data;
      this.isLoadingAvailableMembers = false;
    })
  }
}
