<div class="stats-usage__item__empty" *ngIf="stats && stats.length > 0 && dates.type === 'custom'">
    <div class="stats-usage__item__empty__icon bg-secondary-200">
        <i class="icon icon-file-remove text-secondary-500"></i>
    </div>

    <div class="stats-usage__item__empty__title">
        {{ 'empty.available.data' | translate }}
    </div>

    <div class="stats-usage__item__empty__desc">
        {{ 'providers.warning.data' | translate }}
    </div>
</div>

<div class="stats-usage__item__chart" *ngIf="stats && stats.length > 0 && dates.type !== 'custom'">
    <msc-chart-line [data]="data" [options]="options"></msc-chart-line>
</div>

<div class="stats-usage__item__actions" *ngIf="stats && stats.length > 0" [ngClass]="{'justify-end': dates.type === 'custom'}">
    <div class="actions-legend" *ngIf="dates.type !== 'custom'">
        <div class="legend-color" [ngStyle]="{'background-color': color}"></div>

        <div class="legend-title">
            {{ (legend || 'words.' + unit) | translate }}
        </div>
    </div>

    <div class="actions-buttons">
        <div class="button-action" [tooltip]="'providers.quantity.data' | translate"  (click)="modalExpand.onOpen()">
            <i class="icon icon-expand"></i>
        </div>

        <div [tooltip]="'providers.warning.finish' | translate" *ngIf="warning && dates.type !== 'custom'">
            <i class="icon icon-alert-triangle color--orange"></i>
        </div>
    </div>
</div>

<div class="stats-usage__item__empty" *ngIf="stats && stats.length === 0">
    <div class="stats-usage__item__empty__icon bg-secondary-200">
        <i class="icon icon-file-remove text-secondary-500"></i>
    </div>

    <div class="stats-usage__item__empty__title">
        {{ 'empty.available.data' | translate }}
    </div>

    <div class="stats-usage__item__empty__desc">
        {{ 'empty.available.chart' | translate }}
    </div>
</div>

<msc-common-modal size="70vw" #modalExpand [title]="title">
    <ng-template>
        <div class="modal__granularity">
            <p>{{ 'words.granularity' | translate }}</p>

            <div class="select" [ngClass]="{'active': displays.granularity}" (click)="displayed('granularity')">
                <div class="select__content">
                    <p class="select__placeholder">
                        {{ 'words.' + internalGranularity | translate | lowercase }}
                        <i class="icon icon-arrow-ios-down" *ngIf="isEnable()"></i>
                    </p>

                    <div class="select__dropdown" *ngIf="isEnable()">
                        <div class="dropdown dropdown--simple active">
                            <ul>
                                <li (click)="changeGranularity('day')" *ngIf="dates.diff <= 60">
                                    <span>{{ 'words.day' | translate | lowercase }}</span>
                                </li>

                                <li (click)="changeGranularity('week')" *ngIf="dates.diff > 14 && dates.diff <= 60">
                                    <span>{{ 'words.week' | translate | lowercase }}</span>
                                </li>

                                <li (click)="changeGranularity('month')" *ngIf="dates.diff > 60">
                                    <span>{{ 'words.month' | translate | lowercase }}</span>
                                </li>

                                <li (click)="changeGranularity('year')" *ngIf="dates.diff > 365">
                                    <span>{{ 'words.year' | translate | lowercase }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal__canvas">
            <msc-chart-line [data]="dataQuant" [options]="optionsQuant"></msc-chart-line>
        </div>
    </ng-template>
</msc-common-modal>
