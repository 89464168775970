import { Component, OnInit } from '@angular/core';
import { StorageService, TranslateService } from '@services';
import { CampProvider, Camp, Company } from '@lighty';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-camp-settings-features',
    templateUrl: './settings-features.component.html',
    styleUrls: ['./settings-features.component.html']
})
export class CampSettingsFeaturesComponent implements OnInit {
    public camp: Camp;
    public displays: any;
    public company: Company;

    constructor(
        private storageService: StorageService,
        private campProvider: CampProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');
        this.company = this.storageService.get('company');

        this.displays = {
            asides: {
                catalogue: false,
                timeline: false
            }
        };
    }

    update(): void {
        this.campProvider.updateSettings(this.camp).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    displayed(key: string, type: string): void {
        this.displays[key][type] = !this.displays[key][type];
    }
}
