<msc-common-aside #aside [title]="'theme.custom-colors' | translate" (saveEvent)="save()">
    <ng-template #asideTemplate>
        <msc-input [label]="'theme.name' | translate" name="input" [(ngModel)]="data.name"></msc-input>
    
        <div class="mt-4">
            <span class="text-slate-400">{{ 'theme.primary-color' | translate }}</span>

            <div class="flex items-center border-2 mt-2 py-1 px-2 rounded-md w-[150px]" (click)="primaryColorInput.click()">
                <input #primaryColorInput type="color" class="invisible absolute" [(ngModel)]="data.primaryColor">
                <div class="w-4 h-4 rounded-full" [ngStyle]="{'background-color': getColors(data.primaryColor)}"></div>
                <div class="ml-3 text-slate-500">{{data.primaryColor}}</div>
            </div>
        </div>

        <div class="mt-4">
            <span class="text-slate-400">{{ 'theme.secondary-color' | translate }}</span>

            <div class="flex items-center border-2 mt-2 py-1 px-2 rounded-md w-[150px]" (click)="secondaryColorInput.click()">
                <input #secondaryColorInput type="color" class="invisible absolute" [(ngModel)]="data.secondaryColor">
                <div class="w-4 h-4 rounded-full" [ngStyle]="{'background-color': getColors(data.secondaryColor)}"></div>
                <div class="ml-3 text-slate-500">{{data.secondaryColor}}</div>
            </div>
        </div>

        <div class="mt-4">
            <msc-organize-experience-custom-svg [theme]="data" [autoCheck]="true"></msc-organize-experience-custom-svg>
        </div>
    </ng-template>
</msc-common-aside>