<div class="modal modal-player-light-iframe" [ngClass]="{'active': active}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <h1>{{ content.title }}</h1>

            <div class="modal__header__actions modal__header__actions--small">
                <msc-button class="msc-button--primary msc-button--tiny" palette="primary" icon="icon-log-out" (click)="close()">
                    {{ 'words.leave' | translate }}
                </msc-button>
            </div>
        </div>

        <div class="modal__body">
            <iframe name="embed-iframe"></iframe>

            <div id="embed-code"></div>
        </div>
    </div>
</div>
