import { Component } from '@angular/core';

@Component({
    selector: 'msc-camp-stats',
    templateUrl: './stats.component.html'
})
export class CampStatsComponent {

    constructor() {}
}
