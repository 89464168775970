import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserProvider, Company } from '@lighty';
import { StorageService, EmitterService } from '@services';
import { SubscriptionCollection } from '@classes';
import { DataHelper } from '@helpers';
import { environment } from '@env/environment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-account-access',
    templateUrl: './access.component.html'
})
export class AccountAccessComponent implements OnInit, OnDestroy {
    private company: Company;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public me: any;
    public user: any;
    public demo: boolean = environment.demo;

    constructor(private route: ActivatedRoute, private userProvider: UserProvider, private toastService: CommonToastService,
                private storageService: StorageService, private emitterService: EmitterService) {}

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.user = this.route.parent.snapshot.data.user;
        this.company = this.route.parent.snapshot.data.company;

        this.subscriptionCollection.subscribe = this.emitterService.get('update.access').subscribe(data => {
            if (!DataHelper.isEmpty(data)) {
                const access: any = {};
                if (Array.isArray(data)) {
                    for (const d of data) {
                        access[d.role] = d.enable;
                    }
                } else {
                    access[data.role] = data.enable;
                }

                this.updateAccess(access);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    updateAccess(access: any): void {
        const params = {
            account_id: this.user.id,
            companyRoles: access
        };

        this.userProvider.updateAccount(params, this.company.id).subscribe((data) => {
            this.user.companyRoles = data.companyRoles;

            if (!this.user.companyRoles.companyOwner) {
                this.emitterService.set('update.access.refresh', true);
            }
        });
    }
}
