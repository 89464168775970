import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CompanyUserProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { take } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class WatchSettingsComponent implements OnInit {
  @ViewChild('modalAdd') modalAdd: CommonModalComponent;

  public dashboardItemName: string = '';
  public dashboardItemDescription: string = '';
  public dashboardItemUrl: string = '';
  public modalTitle: string = '';
  public tableColumnns: any = ['name', 'description', 'status', 'edit', 'delete'];
  public tableContent: any;
  public isLoading: boolean = false;
  public currentDashboardId: string = '';
  public isEdit: boolean = false;
  constructor(
    private companyUserProvider: CompanyUserProvider,
    private storageService: StorageService,
    private toastService: CommonToastService,
    private translateService: TranslateService
  ) { 

  }

  ngOnInit(): void {
    this.getDashboards();
  }

  public openModalAdd(): void {
    this.resetFields();
    this.modalTitle = 'create';
    this.isEdit = false;
    this.modalAdd.onOpen();
  }


  public createDashboard(): void {
    const item = {
      name: this.dashboardItemName,
      description: this.dashboardItemDescription,
      url: this.dashboardItemUrl
    }
    this.companyUserProvider.CreateDashboard(this.storageService.get('currentExperience').id, item).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.created'));
      this.modalAdd.onClose();
      this.getDashboards();
    }, (error) => {
      this.toastService.onError(error.error);
    })
  }

  public getDashboards(): void {
    this.companyUserProvider.GetDashboard(this.storageService.get('currentExperience').id).pipe(take(1)).subscribe(data => {
      this.tableContent = data.dashboards;
      console.log(this.tableContent)
    })
  }

  public deleteDashboard(dashboardId: any): void {
    this.companyUserProvider.DeleteDashboard(this.storageService.get('currentExperience').id, dashboardId).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.deleted'));
      this.getDashboards();
      console.log(data)
    })
  }

  public changeStatus(dashboardId: any): void {
    this.companyUserProvider.StatusUpdate(this.storageService.get('currentExperience').id, dashboardId).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.status-updated'));
      console.log(data)
    })
  }

  public updateDashboard(): void {
    const item = {
      name: this.dashboardItemName,
      description: this.dashboardItemDescription,
      url: this.dashboardItemUrl
    }
    this.companyUserProvider.UpdateDashboard(this.storageService.get('currentExperience').id, this.currentDashboardId, item).pipe(take(1)).subscribe(data => {
      this.toastService.onSuccess(this.translateService.instant('dashboard.updated'));
      console.log(data)
      this.modalAdd.onClose();
      this.getDashboards();
    })

  }

  public resetFields(): void {
    this.dashboardItemName = '';
    this.dashboardItemDescription =  '';
    this.dashboardItemUrl = '';
  }

  public openEdit(data: any):void {
    this.resetFields();
    this.isEdit = true;
    this.modalTitle = 'update';
    this.currentDashboardId = data.uuid;
    this.dashboardItemName = data.name;
    this.dashboardItemDescription = data.description;
    this.dashboardItemUrl = data.url;
    this.modalAdd.onOpen();
  }

}
