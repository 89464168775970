import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class TimelineProvider {

    constructor(private http: HttpClient) {}

    getTimelineId(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/timeline`, {params}).pipe(
            map(data => data)
        );
    }

    getTimeline(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/common/timelines/${id}/items`, {params}).pipe(
            map(data => data)
        );
    }

    getMergedTimelines(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/timeline/merged`, {params}).pipe(
            map(data => data)
        );
    }

    send(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/timeline`, params).pipe(
            map(data => data)
        );
    }

    getPost(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/timeline-item/${id}`, {params}).pipe(
            map(data => data)
        );
    }

    editPost(timelineId: number, postId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/timelines/${timelineId}/items/${postId}`, params).pipe(
            map(data => data)
        );
    }

    deletePost(id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/timeline/${id}`).pipe(
            map(data => data)
        );
    }

    deleteItem(timelineId: number, itemId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/timelines/${timelineId}/items/${itemId}`).pipe(
            map(data => data)
        );
    }

    comment(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/timeline/${id}/content`, params).pipe(
            map(data => data)
        );
    }

    deleteComment(id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/timeline/comment/${id}`).pipe(
            map(data => data)
        );
    }

    getSpecificTimeline(type: string, id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/${type}/${id}/timeline`, {params}).pipe(
            map(data => data)
        );
    }

    getMeta(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/timeline/${id}/meta`, {params}).pipe(
            map(data => data)
        );
    }

    notifyUsers(postId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/timeline-item/${postId}/notify`, params).pipe(
            map(data => data)
        );
    }
}
