<div class="post">
    <div class="post-tag" *ngIf="post.scope && !post.scope.belongsToMainTimeline">
        <p class="post-tag__text">
            <span>{{ 'words.from' | translate }}</span>
            <i class="icon" [ngClass]="getActivityIcon(post.scope.context)"></i>
            <span *ngIf="context === 'traject' && post.scope.type === 'course'">{{ post.scope.sessionName | truncate:20 }} - </span>
            <span>{{ (post.scope.title || post.scope.context) | truncate:20 }}</span>
        </p>
    </div>

    <div class="post__header">
        <div class="profile__infos">
            <div class="avatar-profile">
                <div class="avatar-profile">
                    <msc-common-avatar [label]="post.account?.name" [pictureURL]="post.account?.picture ? (post.account?.picture + '?size=128') : null"></msc-common-avatar>
                </div>
            </div>

            <div>
                <p class="profile__name text-secondary-600">{{ post.account?.name }}</p>
                <div class="profile__job">
                    <p *ngIf="displayCamp" class="text-sm leading-none">
                        <i class="icon icon-camp-1 text-primary-600"></i>
                        {{ post.camp?.name }}
                    </p>

                    <span class="profile__time">{{ post.createdAt | timeAgo }}</span>
                </div>
            </div>
        </div>

        <div class="post__actions">
            <div class="post__action" [tooltip]="'words.edit' | translate" direction="left" (click)="displayed('edit')" *ngIf="me.id === post.account?.id">
                <i class="icon icon-edit-2"></i>
            </div>

            <div class="post__action" [tooltip]="'words.delete' | translate" direction="left" (click)="deletePost()" *ngIf="canDelete(post.account?.id)">
                <i class="icon icon-trash"></i>
            </div>

            <div *ngIf="isAdmin" class="post__action" [tooltip]="post.notifiedAt ? ('words.notify' | translate) + ' - ' + ('timeline.notified-at' | translate: {value: post.notifiedAt | date }) : ('words.notify' | translate)" direction="left" (click)="modalAlert.onOpen(); openModalAlert()">
                <i class="icon icon-megaphone"></i>
            </div>

            <div *ngIf="isAdmin && post.notifiedAt" class="post__notification hidden-md">
                <i class="icon icon-checkmark"></i>
            </div>
        </div>
    </div>

    <div class="post__body">
        <msc-composer *ngIf="displays.edit"
                      [edit]="true"
                      [dynamic]="true"
                      [autoResize]="true"
                      [me]="me"
                      [unlock]="unlock"
                      [media]="post.media"
                      [(message)]="post.content.message"
                      (messageSended)="editPost($event)">
        </msc-composer>

        <p class="post__text" [innerHTML]="post.content.message | nl2br | url | safe : 'html'" *ngIf="!displays.edit"></p>

        <msc-link-manager [content]="post.content.message" *ngIf="!displays.edit" [backgroundColor]="'grey'" [timeline]="small"></msc-link-manager>

        <div class="post__resources" *ngIf="post.media && !displays.edit">
            <img [src]="post.media.pictureUrl + '?size=512'" alt="" class="content__image" *ngIf="post.media.type === 'IMG'">

            <iframe [src]="getEmbed(post.media.url)" *ngIf="post.media.type === 'EMBED'" class="content__embed" allow="fullscreen" allowfullscreen></iframe>

            <msc-vimeo [media]="post.media" *ngIf="post.media.type === 'VID'"></msc-vimeo>
        </div>

        <div class="post__preview"
             *ngIf="post.media && (post.media.type === 'DOC' || post.media.type === 'PDF' || post.media.type === 'NONE' || post.media.type === 'SND') && !displays.edit"
             (click)="downloadFile(post.media)"
             [ngClass]="{'post__preview--processing': post.media.status !== 1}">
            <p>
                <i class="icon icon-file-text color--red"></i>
                <span>{{ post.media.title }}</span>
            </p>

            <p class="post__preview__status" *ngIf="post.media.status !== 1" [ngClass]="{'post__preview__status--failed': post.media.status === -1}">
                {{ (post.media.status === 0 ? 'timeline.file.processing' : 'timeline.file.failed') | translate }}
            </p>
        </div>

        <div class="post__edited" *ngIf="post.modified">
            {{ getEditMessage() }}
        </div>
    </div>

    <div class="post__footer">
        <div class="post__interaction">
            <div class="post__counter">
                <div class="counter__reaction flex align-center justify-start" (click)="displayed('reactions')" *ngIf="countReactions() > 0">
                    <div class="counter__animation flex align-center start" *ngFor="let reaction of reactions">
                        <img [src]="'https://static.myskillcamp.com/images/animations/' + reaction.name +  '.svg'" [alt]="reaction.name" *ngIf="reaction.count > 0">
                    </div>

                    <div class="counter__total">{{ countReactions() }}</div>
                </div>

                <div  *ngIf="!post.camp || post.camp.reply" class="counter__comment" (click)="displayed('comments')">
                    <span>{{ post.comments.length }}</span>
                    <p>{{ (post.comments.length > 1 ? 'words.comments' : 'words.comment') | translate | lowercase }}</p>
                </div>
            </div>

            <div class="post__save">
                <div class="save__download" *ngIf="post.media && post.media.type !== 'VID' && post.media.type !== 'EMBED' && post.media.status === 1" (click)="downloadFile(post.media)">
                    <i class="icon icon-download"></i>
                </div>
            </div>
        </div>

        <div class="post__action">
            <div class="link__action">
                <a class="btn-reaction">
                    <div class="btn-reaction__text" *ngIf="!checkIfAlreadyReacted()">
                        <i class="icon icon-like-social"></i>
                        {{ 'words.react' | translate }}
                    </div>

                    <div class="btn-reaction__text" *ngIf="checkIfAlreadyReacted()" [tooltip]="'timeline.reaction.delete' | translate" direction="right" (click)="removeReaction()">
                        <img [src]="getOwnReactionUrl()" [alt]="'Own reaction'">
                    </div>

                    <div class="btn-reaction__box flex align-center start" *ngIf="!checkIfAlreadyReacted()">
                        <div class="btn-reaction__icon" [attr.id]="'animation-' + post.id + '-' + reaction.name" *ngFor="let reaction of reactions;"
                             (mouseenter)="handlePlay(reaction.name)"
                             (mouseleave)="handlePlay(reaction.name, true)"
                             (click)="addReaction(reaction.id)">
                        </div>
                    </div>
                </a>

                <a *ngIf="!post.camp || post.camp.reply">
                    <i class="icon icon-message-circle"></i>
                    <span (click)="displayed('composer')">{{ 'words.comment-on' | translate }}</span>
                </a>
            </div>
        </div>
    </div>

    <div class="comments" *ngIf="displays.composer || displays.comments">
        <div class="box-composer" *ngIf="displays.composer">
            <msc-composer [dynamic]="true"
                          [autoResize]="true"
                          [me]="me"
                          [unlock]="unlock"
                          [(message)]="message"
                          (messageSended)="send($event)">
            </msc-composer>
        </div>

        <div class="comments__list">
            <msc-timeline-comment *ngFor="let comment of post.comments" [me]="me" [comment]="comment" [manage]="manage" (delete)="deleteComment(comment)"></msc-timeline-comment>
        </div>
    </div>
</div>

<msc-modal-reaction *ngIf="displays.reactions" [reactions]="reactions" [contextId]="post.id" (onClose)="displayed('reactions')"></msc-modal-reaction>

<msc-common-modal size="30vw" #modalAlert [title]="'words.notify' | translate">
    <ng-template>
        <p *ngIf="!displayCamp && context === 'company'">
            {{ 'timeline.notify-camp-members' | translate }}
        </p>

        <p *ngIf="!displayCamp && context === 'camp'">
            {{ 'timeline.notify-camp-members' | translate }}
        </p>

        <p *ngIf="!displayCamp && context === 'group'">
            {{ 'timeline.notify-community-members' | translate }}
        </p>

        <p *ngIf="!displayCamp && (context === 'traject' || context === 'course' || context === 'external_content' || context === 'external_video')">
            {{ 'timeline.notify-course-learner' | translate }}
        </p>

        <p *ngIf="displayCamp">{{ 'timeline.notify-camp' | translate: {value: post.camp?.name} }}</p>

        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalAlert.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="notifyUsers(); modalAlert.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
