import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmitterService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { IntegrationProvider } from '@lighty';
import { DataHelper, DownloadHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-organize-integration-watch-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})

export class OrganizeIntegrationWatchSettingsComponent {
    @ViewChild('modalActivated') modalActivated: CommonModalComponent;
    private company: any;
    private me: any;
    public provider: any;
    public activated: boolean = true;
    public fields: any[] = [];
    public data: any = {};

    constructor(
        private storageService: StorageService,
        private toastService: CommonToastService,
        private integrationProvider: IntegrationProvider,
        private emitterService: EmitterService,
        private route: ActivatedRoute,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.route.parent.data.subscribe((data: any) => {
            this.data = {};
            this.fields = [];
            this.provider = this.storageService.set('currentRuleProvider', data.integration);
            this.activated = this.provider.companyProvider?.status;
            this.createFields(this.provider.fields);
        });
    }

    get supportUrl(): string { return this.getFaqByLang(this.me.language.code); }

    get isLoading(): boolean {
        return this.provider?.settingsRequired === 1 && (this.provider.companyProvider?.deploymentRunning === 1 || this.provider.companyProvider?.catalogueFetching === 1);
    }

    private createFields(fields: any): void {
        this.fields = [];
        for (const key in fields) {
            this.data[key] = this.provider.companyProvider?.settings ? this.provider.companyProvider?.settings[key] : null;
            const field = {
                name: key,
                translate: fields[key].label,
                type: this.getType(fields[key].type),
                nullable: !!fields[key].type.indexOf('nullable'),
                htmlType: fields[key].htmlType,
                isCopyable: fields[key].isCopyable,
                dataKey: this.data[key],
                isPass: fields[key].isPass,
                isVisible: fields[key].isVisible,
                required: fields[key].required,
                disabled: fields[key].disabled
                        };
            this.fields.push(field);
        }
        console.log(this.fields)
    }

    private getType(field: string): string {
        const type = field.replace('nullable|', '');

        switch (type) {
            case 'integer':
                return 'number';
            default:
                return 'text';
        }
    }

    getFaqByLang(lang: string): string {
        switch (lang) {
            case 'en': return 'https://support.myskillcamp.com/hc/en-gb/sections/4859911483805-GoodHabitz';
            case 'nl': return 'https://support.myskillcamp.com/hc/nl/sections/4859911483805-GoodHabitz';
            case 'fr': return 'https://support.myskillcamp.com/hc/fr/sections/4859911483805-GoodHabitz';
            case 'de': return 'https://support.myskillcamp.com/hc/de/sections/4859911483805-GoodHabitz';
            case 'it': return 'https://support.myskillcamp.com/hc/it/sections/4859911483805-GoodHabitz';
            case 'es': return 'https://support.myskillcamp.com/hc/es/sections/4859911483805-GoodHabitz';
            default: return 'https://support.myskillcamp.com/hc/en-gb/sections/4859911483805-GoodHabitz';
        }
    }

    switchActivation(fromSave?): void {
        const params = {
            status: Number(fromSave ? fromSave : !this.activated)
        };

        this.integrationProvider.updateStatus(this.company.id, this.provider.id, params).subscribe(() => {
            if (params.status) {
                this.toastService.onSuccess(this.translateService.instant('toast.integrations.change-status-activated'));
                this.emitterService.set('integration.connected', { connected: true });
            } else {
                this.toastService.onSuccess(this.translateService.instant('toast.integrations.change-status-deactivated'));
                this.emitterService.set('integration.connected', { connected: false });
            }

            this.refresh();
        });

        if (!fromSave) {
            this.modalActivated.onClose();
        }
    }

    save(): void {
        this.integrationProvider.updateProvider(this.company.id, this.provider.id, this.data).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.validate();
            this.refresh();
        });
    }

    validate(): void {
        this.integrationProvider.testProvider(this.company.id, this.provider.id, this.data).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.integrations.test-success'));
            this.switchActivation(true);
        }, (err) => {
            this.toastService.onError(this.translateService.instant('toast.integrations.test-fail'));
        });

    }

    private refresh(): void {
        this.integrationProvider.getProvider(this.provider.id).subscribe((data) => {
            console.log(data)
            this.provider = this.storageService.set('currentRuleProvider', data);
            this.activated = this.provider.companyProvider?.status;
            this.createFields(this.provider.fields);
        });
    }

    downloadMeta(): void {
        this.integrationProvider.getMeta().subscribe((data) => {
            DownloadHelper.downloadBlob(data, "metadata.xml");
        })
    }

    public copyValue(value: string): void {
        DataHelper.clipboard(value);
        this.toastService.onInfo(this.translateService.instant('words.copied'));
    }

    public changeStatus(e: any, field: string): void {
        //e.stopPropagation();
        console.log(e)
        this.data[field] = e;
    }
}
