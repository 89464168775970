import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { environment } from '@env/environment';

@Injectable()
export class AdminContentProvider {

    constructor(private http: HttpClient) {}

    getContents(slug: string, campId: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            contents: []
        };

        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${slug}/camps/${campId}/contents`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.contents.map((content) => {
                    response.contents.push(content);
                });

                return response;
            })
        );
    }

    getTemplate(slug: string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/template/${slug}/course-camps`, {params}).pipe(
            map(data => data)
        );
    }

    search(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/search/courses`, {params}).pipe(
            map(data => data)
        );
    }

    getExport(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camps/${id}/courses/export`, {params}).pipe(
            map(data => data)
        );
    }

    getSurvey(courseCampId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/course-camp/${courseCampId}/export-eval`, {responseType: 'blob'}).pipe(
            map(data => data)
        );
    }

    getAttendanceList(courseCampId: number): Observable<any> {
        return this.http.get( `${environment.envVar.API_URL}/course-camp/${courseCampId}/export-attendancelist`, {responseType: 'blob'}).pipe(
            map((data: any) => data)
        );
    }

    updateSettings(campId: number, courseCampId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/camp/${campId}/admin/course-camp/${courseCampId}`, params).pipe(
            map(data => data)
        );
    }

    deleteContent(companyId: number, campId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${companyId}/camps/${campId}/contents`, {
            observe: 'body',
            params: params
        });
    }

    updateOnDemand(campId: number, templateId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/template/${templateId}/on-demand`, params).pipe(
            map(data => data)
        );
    }

    getMembersOnDemand(templateId: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            members: []
        };

        return this.http.get(`${environment.envVar.API_URL}/template/${templateId}/saved-contents/users`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.users.map((member) => {
                    response.members.push(member);
                });

                return response;
            })
        );
    }

    getFilters(companySlug: string, campId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companySlug}/camps/${campId}/contents/meta`,{params} ).pipe(
            map((data: any) => data.meta)
        );
    }
}
