<div class="content-access content-access__learn">
    <div class="content-access__learn__header">
        <h3>
            {{ 'access.learn.camps-list' | translate }}

            <div [tooltip]="'access.learn.info' | translate">
                <i class="icon icon-info"></i>
            </div>
        </h3>

        <div class="content-access__learn__enable">
            <msc-button class="msc-button--primary" palette="primary" (click)="modalAccess.onOpen()" [disabled]="!user.companyRoles.companyLearner">{{ 'words.revoke-access' | translate }}</msc-button>
        </div>
    </div>

    <div class="content-access__learn__actions">
        <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
    </div>

    <div class="content-access__learn__list">
        <div class="list-item col-4 col-md-6 col-xxs-12" *ngFor="let camp of camps">
            <div class="card-camp">
                <div class="card-camp__content">
                    <a class="content__action" (click)="removeCamp(camp)">
                        <i class="icon icon-minus"></i>
                    </a>

                    <div class="content__logo">
                        <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                    </div>

                    <div class="content__data">
                        <p *ngIf="camp.name.length >= 30" [tooltip]="camp.name">
                            {{ camp.name | truncate: 30 }}
                        </p>
                        <p *ngIf="camp.name.length < 30">
                            {{ camp.name }}
                        </p>

                        <div class="data__pictos">
                            <div class="data__members">
                                <i class="icon icon-people"></i>
                                <p>{{ camp.usersCount }}</p>
                            </div>

                            <div class="data__sessions">
                                <i class="icon icon-calendar"></i>
                                <p>{{ camp.instancesCount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-access__learn__available">
        <div class="list-item col-6 col-md-6 col-xxs-12" *ngFor="let camp of availableCamps">
            <div class="card-camp card-camp--flat">
                <div class="card-camp__content">
                    <a class="content__action content__action--blue bg-secondary-500" (click)="addCamp(camp)">
                        <i class="icon icon-plus"></i>
                    </a>

                    <div class="content__logo">
                        <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                    </div>

                    <div class="content__data">
                        <p *ngIf="camp.name.length > 30" [tooltip]="camp.name">
                            {{ camp.name | truncate: 30 }}
                        </p>
                        <p *ngIf="camp.name.length < 30">
                            {{ camp.name }}
                        </p>

                        <div class="data__pictos">
                            <div class="data__members">
                                <i class="icon icon-people"></i>
                                <p>{{ camp.usersCount }}</p>
                            </div>

                            <div class="data__sessions">
                                <i class="icon icon-calendar"></i>
                                <p>{{ camp.instancesCount }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalAccess [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'access.disabled-learn' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalAccess.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="disableAccess(); modalAccess.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
