<div class="container-catalog__session" *ngFor="let data of thematic.levels; let index=index">
    <h2 class="container-catalog__title" [ngClass]="{'container-catalog__title--white': index === 0}">
        <span>
            {{ thematic.name }} - {{ getTranslation(data.level) | translate }}
        </span>

        <a class="link link-showmore" [routerLink]="['level', data.level]" [ngClass]="{'link-showmore--light': index === 0}">
            {{ 'words.show-more' | translate }}
            <i class="icon icon-arrow-right-1 text-primary-600"></i>
        </a>
    </h2>

    <msc-learn-catalogue-slider [contents]="data.contents"></msc-learn-catalogue-slider>
</div>

<div class="container-catalog-internal__list row animate" *ngIf="thematic.contents">
    <div class="card-container">
        <content-card *ngFor="let content of thematic.contents" [content]="content"></content-card>
    </div>
</div>
