<h3 class="title">
    <span>{{ 'organize.experience.watch.mail.create.title' | translate }}</span>
</h3>

<div class="grouping">
    <form class="flex flex-col space-y-2 w-3/5" [formGroup]="form">
        <msc-input [label]="'organize.experience.watch.mail.create.sender-name.label' | translate" [placeholder]="'organize.experience.watch.mail.create.sender-name.label' | translate" formControlName="name"></msc-input>
        <msc-input [label]="'organize.experience.watch.mail.create.sender-email.label' | translate" [placeholder]="'organize.experience.watch.mail.create.sender-email.label' | translate" formControlName="email"></msc-input>
    </form>
</div>

<div class="grouping margin-top-40">
    <msc-button class="msc-button--primary" palette="primary" [disabled]="form.controls['email'].errors" (click)="onSubmit()">{{'organize.experience.watch.mail.create.btn' | translate}}</msc-button>
</div>
