import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { environment } from '@env/environment';

@Injectable()
export class AdminMemberProvider {

    constructor(private http: HttpClient) {}

    getMembers(campId: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            members: []
        };

        return this.http.get(`${environment.envVar.API_URL}/camps/${campId}/users`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.users.map((user) => {
                    response.members.push(user);
                });

                return response;
            })
        );
    }

    getAvailableMembers(campId: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            members: []
        };

        return this.http.get(`${environment.envVar.API_URL}/camps/${campId}/available-users`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.availableUsers.map((user) => {
                    response.members.push(user);
                });

                return response;
            })
        );
    }

    updateRoles(campId: number, userId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/camps/${campId}/users/${userId}`, params).pipe(
            map(data => data)
        );
    }

    delete(campId: number, userId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/camps/${campId}/users/${userId}`).pipe(
            map(data => data)
        );
    }

    inviteMembers(campId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/camps/${campId}/users`, params).pipe(
            map(data => data)
        );
    }
}
