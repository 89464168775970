<button type="button" [ngClass]="{ 'is-open': isOpen }" (click)="disabled ? null : onOpen(origin.elementRef)" element
    #origin="element">
    <ng-container *ngTemplateOutlet="templateButton || defaultTemplateButton; context: { data: dataButton }">
    </ng-container>
    <i class="icon icon-arrow-ios-down"></i>
</button>

<msc-common-overlay #overlay [root]="overlayRoot"  direction="bottom-start" (onClose)="isOpen=false">
    <ng-template>
        <div class="option-container" infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="0" (scrolled)="(!isLoading && infiniteLoad) ? loadData(query) : null" [ngStyle]="{'width': width + 'px'}">
            <ng-container *ngIf="hasSearch">
                <msc-common-search [data]="!infiniteLoad ? data : undefined" [key]="'label'"
                    [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
            </ng-container>
            <msc-common-tree [expandWithOverlay]="true" (treeUpdate)="updateOverlayRoot($event)" [data]="dataOption" [template]="templateOption || defaultTemplateOption"></msc-common-tree>
            <ng-container *ngIf="isLoading">
                <div class="loader-container">
                    <msc-common-spinner></msc-common-spinner>
                </div>
            </ng-container>
        </div>
    </ng-template>
</msc-common-overlay>

<ng-template #defaultTemplateButton let-data="data">
    <ng-container *ngIf="data?.length">
        <span>{{ data[0]?.label }}</span>
    </ng-container>
</ng-template>

<ng-template #defaultTemplateOption let-datum="datum">
    <span class="option" (click)="onSelect(datum)">{{ datum?.label }}</span>
</ng-template>
