import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, CourseProvider, Journey, JourneyProvider, ExternalContentProvider } from '@lighty';
import * as moment from 'moment';
import { DataHelper, DownloadHelper, UrlHelper } from '@helpers';
import { Subject } from 'rxjs';
import { EmitterService, ErrorStreamService, RedirectService, StorageService, ToastService, TranslateService } from '@services';
import { SubscriptionCollection } from '@classes';
import { tap, repeat, take, finalize } from 'rxjs/operators';
import { CommonToastService } from '@common2/services/toast.service';
import { environment } from '@env/environment';

@Component({
    selector: 'msc-learn-detail-journey-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class LearnDetailJourneySummaryComponent implements OnInit, OnDestroy {
    private company: Company;
    private loaders: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public journey: Journey;
    public summary: any;
    public dropdownExerciseContent: boolean[] = [];
    public skills: any[];
    public displays: any = [];
    public contentIdLoading = null;
    private repeat$: Subject<void> = new Subject<void>();
    private loadMoreSubscription;
    public journeyContentLoading = false;
    public contentSections;
    public loading: any = {
        bookboon: false,
        trainingexpress: false,
        hasCard: false,
        sections: false,
    };

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private emitterService: EmitterService,
        private courseProvider: CourseProvider,
        private toastService: CommonToastService,
        private journeyProvider: JourneyProvider,
        private externalContentProvider: ExternalContentProvider,
        private storageService: StorageService,
        private redirectService: RedirectService,
        private errorStreamService: ErrorStreamService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.journey = this.route.parent.snapshot.data.journey;
        console.log(this.journey)
        this.loaders = {
            player: [],
            finished: []
        };

        this.subscriptionCollection.subscribe = this.emitterService.get('player-light.loaded').subscribe((data) => {
            if (!DataHelper.isEmpty(data)) {
                const index = this.loaders.player.indexOf(data.id);

                if (index > -1) {
                    this.loaders.player.splice(index, 1);
                }
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('refresh.content').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.refresh();
                this.emitterService.set('refresh.content', {});

                const timeOut = setTimeout(() => {
                    this.refresh();
                    clearTimeout(timeOut);
                }, 3000);
            }
        });

        this.loadMoreSubscription = this.emitterService.loadMoreSection.subscribe(() => this.loadMoreContent());
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
        this.loadMoreSubscription.unsubscribe();
        this.repeat$.complete();
    }

    getTranslateButton(content: any): string {
        if (content.session && content.session.progress) {
            if (content.session.progress === 100) {
                return this.translateService.instant('words.resume');
            } else if (content.session.progress > 0 && content.session.progress < 100) {
                return this.translateService.instant('words.continue');
            } else {
                return this.translateService.instant('words.start');
            }
        } else {
            return this.translateService.instant('words.start');
        }
    }

    redirectActivityToPlayer(activity: any): void {
        if (activity.context === 'course') {
            this.redirectService.navigate('player', ['course', activity.subscriptionCourseId], { isFromLearningPath: true });
        } else if (activity.playerContext === 'media' || activity.playerContext === 'scormcloud' || activity.playerContext === 'cheatsheet') {
            this.openPlayerLight(activity);
        } else if (activity.playerContext === 'external_link') {
            this.openLti(activity);
        } else if (activity.playerContext === 'exercise') {
            this.redirectService.navigate('player', [
                'course', activity.courseCamp.course.id,
                'section', activity.playerSectionId, activity.playerContext, activity.playerContextId,
                'question', activity.data?.exercise?.questions[0]?.id
            ], { isFromLearningPath: true });
        } else {
            this.redirectService.navigate('player', ['course', activity.courseCamp.course.id, 'section', activity.playerSectionId, activity.playerContext, activity.playerContextId], { isFromLearningPath: true });
        }
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    addToCalendar(content: any): void {
        DownloadHelper.download(content.data.event.eventSessions[0].icsCalendar, '', false);
    }

    goToLink(content: any): void {
        if (content.data.event.type === 'virtual-class') {
            UrlHelper.navigate(content.data.event.url);
        } else {
            UrlHelper.navigate(content.data.event.link);
        }
    }

    isEventDisabled(date: string): boolean {
        return !moment(moment().format('MM-DD-YYYY')).isSame(moment(date).format('MM-DD-YYYY'));
    }

    openLti(activity: any): void {
        if (activity.data.externalLink.params.mode === 'embed') {
            this.loaders.player.push(activity.contextId);
        }
        this.emitterService.set('player-light.open', { type: 'activity', subscriptionCourseId: activity.subscriptionCourseId, more: activity });
    }

    openPlayerLight(activity: any): void {
        let params = {};
        switch (activity.playerContext) {
            case 'scormcloud':
                params = {
                    type: 'iframe',
                    content: {
                        sectionContentId: activity.playerContentId,
                        scormId: activity.playerContextId
                    },
                    more: {
                        id: activity.subscriptionCourseId,
                        title: activity.data.title
                    }
                };
                break;
            case 'media':
            case 'cheatsheet':
                params = {
                    type: 'activity',
                    subscriptionCourseId: activity.subscriptionCourseId,
                    more: activity
                };
                break;
        }

        this.loaders.player.push(activity.contextId);
        this.emitterService.set('player-light.open', params);
    }

    getIcon(type: string): string {
        switch (type) {
            case 'media':
                return 'icon icon-play-circle';
            case 'cheatsheet':
                return 'icon icon-file-text';
            case 'exchange':
                return 'icon icon-message-circle';
            default:
                return 'icon icon-checkmark-square';
        }
    }

    download(media): void {
        DownloadHelper.downloadMedia(media, media.title);
    }

    finished(content: any): void {
        this.loaders.finished.push(content.id);

        const params = {
            company_id: this.company.id,
            course_id: content.subscriptionCourseId,
            preview: 0,
            direct_play: 0
        };

        this.courseProvider.get(content.subscriptionCourseId, params).subscribe((course) => {
            const sectionContent = course.sections[0].sectionContents[0];
            this.courseProvider.createSubscription(params).subscribe((subscription) => {
                if (subscription.sectionContentSessions.length === 0) {
                    const parameters = {
                        sectionContentId: sectionContent.id,
                        courseSubscriptionSessionId: subscription.id
                    };

                    this.courseProvider.createSectionContentSession(parameters).subscribe((sectionContentSession) => {
                        this.finishedSession(sectionContentSession, content);
                    });
                } else {
                    this.finishedSession(subscription.sectionContentSessions[0], content);
                }
            });
        });
    }

    finishedSession(session: any, content: any): void {
        this.courseProvider.updateSectionContentSessions(session.id, session, { isDone: true }).subscribe(() => {
            content.session.progress = 100;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.refresh();
        });
    }

    isLoading(id: number, type: string): boolean {
        return this.loaders[type].indexOf(id) > -1;
    }

    refresh(): void {
        this.journeyProvider.get(this.company.id, this.journey.id).subscribe((journey) => {
            this.journey = this.storageService.set('journey', journey);
        });
    }

    getColor(content: any): string {
        switch (content.context) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'bookboon':
                            return 'orange';
                        default:
                            return 'purple';
                    }
                } else {
                    switch (content.data.type) {
                        case 'course':
                            return 'purple';
                        case 'video':
                            return 'blue';
                        case 'article':
                            return 'orange';
                        case 'other':
                            return 'red';
                    }
                }
                break;
            case 'external_video':
                return 'blue';
        }
    }

    getType(content: any): string {
        switch (content.context) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'openclassroom':
                        case 'cefora':
                        case 'udemy':
                        case 'coursera':
                            return 'words.template';
                        case 'bookboon':
                            return 'words.ebook';
                        default:
                            return content.data.provider.name;
                    }
                } else {
                    switch (content.data.type) {
                        case 'course':
                            return 'words.template';
                        default:
                            return 'words.' + content.data.type;
                    }
                }
            case 'external_video':
                return 'words.video';
        }
    }

    getLogo(content: any): string {
        switch (content.context) {
            case 'external_content':
                if (!content.data.provider.logo) {
                    return 'https://static.myskillcamp.com/images/integrations/' + content.data.provider.key + '.svg';
                } else {
                    return content.data.provider.logo + '?size=128';
                }
            case 'external_video':
                return 'https://static.myskillcamp.com/images/integrations/youtube.svg';
        }
    }

    getNewUrl({ data }: any) {
        return this.externalContentProvider
            .generateUrl({
                company_id: this.company?.id,
                provider_id: data.provider?.id,
                context_id: data.id,
                context_type: 'external_content',
            })
            .pipe(
                take(1),
                tap(({ url }) => {
                    data.url = url;
                    UrlHelper.navigate(url);
                }),
                finalize(() => {
                    this.contentIdLoading = null;
                })
            );
    }

    runExternalContent(content: any): void {
            if(content.data?.provider?.key == 'bookboon' && content.data?.type == 'ebook') {
                this.downloadBookBoon(content);
            } else if(content.data?.provider?.key == 'bookboon') {
                this.navigateContentExternal(content);
            }  else if(content.data?.provider?.key == 'external_video') {
                this.openExternalUrl(content, 'video');
            } else {
                if (content.data?.requireUrlGeneration) {
                    this.contentIdLoading = content.data.id;
                    this.getNewUrl(content).subscribe();
                }else if(
                    (content?.data?.url?.includes('udemy.com')
                        || content?.data?.url?.includes('linkedin.com')
                        || content?.data?.url?.includes('contentraven.com')
                        || content?.data?.url?.includes('learninghubz.com')
                        || content?.data?.url?.includes('coursera.org')
                        || content?.data?.url?.includes('platzi.com'))
                    && `${UrlHelper.getHost()}/` !== environment.envVar.APP_URL.replace('https://', '').replace('http://', ''))
                {
                    this.externalContentProvider.storeUserToken(content.data?.url)
                        .subscribe((token:string)=>{
                            UrlHelper.navigate(`${environment.envVar.CLOUD_URL}/authenticated-redirect?token=${token}`);
                            this.emitterService.set('player-light.open', { type: 'external', content: content.data, more: content.data, progressEnabled: content.data.provider ? content.data.provider.progressEnabled : true });
                        })
                } else {
                    UrlHelper.navigate(content.data.url);
                    this.emitterService.set('player-light.open', { type: 'external', content: content.data, more: content.data, progressEnabled: content.data.provider ? content.data.provider.progressEnabled : true });
                }
            }
    }

    loadMoreContent() {
        if (this.journey.pagination.currentPage < this.journey.pagination.lastPage && !this.journeyContentLoading) {
            this.journeyContentLoading = true;
            this.journeyProvider.get(this.company.id, this.journey.id, { page: ++this.journey.pagination.currentPage }).subscribe((journey) => {
                this.journey.contents = [...this.journey.contents, ...journey.contents];
                this.journeyContentLoading = false;
            })
        }
    }

    private getSections(content: any): void {
        this.loading.sections = true;
        this.externalContentProvider
            .getExternalContentSections(content.contextId)
            .subscribe(
                (data) => {
                    this.contentSections = data.sections;
                    this.loading.sections = false;
                },
                () => {
                    this.loading.sections = false;
                }
            );
    }

    private downloadBookBoon(content: any): void {
        const title = content.data.title;
        this.loading.bookboon = true;
        this.errorStreamService.locked();
        this.toastService.onSuccess(this.translateService.instant('toast.downloading.soon'));
        this.externalContentProvider
            .download(content.contextId)
            .subscribe(
                (data) => {
                    DownloadHelper.downloadBlob(
                        data,
                        title
                    );
                    this.toastService.onSuccess(this.translateService.instant('toast.bookboon.download-success'));
                    this.emitterService.set('player-light.open', {
                        type: 'external',
                        content: content,
                        more: content,
                        progressEnabled: content.provider
                            ? content.provider.progressEnabled
                            : true,
                    });
                },
                () => {
                    this.errorStreamService.unlocked();
                    this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                },
                () => {
                    this.loading.bookboon = false;
                }
            );
    }

    public openExternalUrl(content, type, url?) {
        if (content.requireUrlGeneration) {
            this.loading.trainingexpress = true;
            this.getNewUrl(content).subscribe();
        } else {

            UrlHelper.navigate(url || content.url);

            this.emitterService.set('player-light.open', {
                type: type,
                content: { ...content, type: type },
                more: { ...content, type: type },
                progressEnabled: content.provider
                    ? content.provider.progressEnabled
                    : true,
            });
        }
    }

    navigateContentExternal(content: any): void {
        const contentProviderKey = content.data.provider.key;
        const url = `learn/${this.company.slug}/detail/external-content/${contentProviderKey}/${content.data.id}`;
        this.router.navigateByUrl(url);
    }

}
