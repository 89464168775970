import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, EmitterService } from '@services';
import { AuthProvider, LanguageProvider, UserProvider } from '@lighty';
import { Language } from '@interfaces';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    private privacyUrls: any = {
        fr: 'https://myskillcamp.com/fr/platform-privacy-policy',
        en: 'https://myskillcamp.com/en/platform-privacy-policy',
        nl: 'https://myskillcamp.com/en/platform-privacy-policy',
        it: 'https://myskillcamp.com/en/platform-privacy-policy',
        de: 'https://myskillcamp.com/en/platform-privacy-policy',
        es: 'https://myskillcamp.com/en/platform-privacy-policy'
    };
    public display: any;
    public ready: boolean = false;
    public data: any;
    public selectedLanguage: Language;
    public languages: Language[];
    public displayDropdowns: any;
    public error: any = {
        short: false,
        characters: false,
        confirm: false,
        lowercase: false,
        uppercase: false,
        number: false
    };
    public valid: any = {
        short: false,
        characters: false,
        lowercase: false,
        uppercase: false,
        number: false
    };
    public selectData: any = {
        interfaceLanguages: []
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private authProvider: AuthProvider,
        private userProvider: UserProvider,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private languageProvider: LanguageProvider,
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.translateService.setLang(params.lang || 'fr');
            this.data = {
                email: params.email.replace(' ', '+'),
                firstname: params.firstname,
                lastname: params.lastname,
                languageId: null,
                password: '',
                password_confirmation: '',
                privacy: false
            };

            const param = {
                email: params.email,
                companySlug: params.company,
                experienceSlug: params.experienceSlug
            };

            this.userProvider.exists(param).subscribe((data) => {
                if (data.exists) {
                    this.toastService.onWarning(this.translateService.instant('toast.user-already-exists'));
                    if (data.companySlug) {
                        if (data.experienceSlug) {
                            this.router.navigate(['login'], { queryParams: { company: data.companySlug, experience: data.experienceSlug } });
                        } else {
                            this.router.navigate(['login'], { queryParams: { company: data.companySlug } });
                        }
                    } else {
                        this.router.navigate(['login']);
                    }
                } else {
                    this.ready = true;
                }
            });

            const code = params.lang || 'fr';

            this.languageProvider.get('interface').subscribe((langs) => {
                this.languages = langs.filter(lang => lang.name);
                this.findDefaultLanguage(code);
                this.selectData.interfaceLanguages = this.getSelectData(this.languages);
            });
        });

        this.display = this.route.snapshot.data.display;

        this.displayDropdowns = {
            flag: false
        };

        this.emitterService.set('theme.change', this.display && this.display.colorTemplate ? this.display.colorTemplate : {});
    }

    getSelectData(data) {
        return data.map(d => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: d.id === this.selectedLanguage?.id
        }));
    }

    onSelectOption(option, bool?: boolean) {
        this.selectData.interfaceLanguages = this.selectData.interfaceLanguages.map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });
        
        this.onSelect(option.value);

        if (bool) {
            this.translateService.setLang(option.value.code);
        }
    }

    displayed(key: string): void {
        this.displayDropdowns[key] = !this.displayDropdowns[key];
    }

    updateLang(lang: Language): void {
        this.selectedLanguage = lang;
        this.translateService.setLang(lang.code);
    }

    trimPassword(key: string): void {
        this.data[key] = this.data[key].trim();

        if (this.data.password.length > 0) {
            const regNumber = RegExp(/^.*(?=.*[\d\x]).*$/);
            const regUppercase = RegExp(/^.*(?=.*[A-Z]).*$/);
            const regLowercase = RegExp(/^.*(?=.*[a-z]).*$/);

            this.error.short = this.data.password.length > 0 && this.data.password.length < 8;
            this.error.number = !regNumber.test(this.data.password);
            this.error.uppercase = !regUppercase.test(this.data.password);
            this.error.lowercase = !regLowercase.test(this.data.password);

            this.valid.short = this.data.password.length >= 8;
            this.valid.number = regNumber.test(this.data.password) && this.data.password.length > 0;
            this.valid.uppercase = regUppercase.test(this.data.password) && this.data.password.length > 0;
            this.valid.lowercase = regLowercase.test(this.data.password) && this.data.password.length > 0;

            if (this.data.password_confirmation.length > 0) {
                this.error.confirm = this.data.password !== this.data.password_confirmation;
            }
        } else {
            this.error = {
                short: false,
                characters: false,
                confirm: false,
                number: false,
                uppercase: false,
                lowercase: false
            };
            this.valid = {
                short: false,
                characters: false,
                number: false,
                uppercase: false,
                lowercase: false
            };
        }
    }

    save(): void {
        if (this.data.firstname && this.data.lastname && this.data.password) {
            if (this.data.password.length >= 8) {
                if (this.data.password === this.data.password_confirmation) {
                    this.authProvider.signup(this.data).subscribe(() => {
                        this.toastService.onSuccess(this.translateService.instant('toast.signup'));
                        this.router.navigate(['login']);
                    }, (failure) => {
                        if (failure.error.message && failure.error.message.password[0] === 'num-maj-min') {
                            this.error.characters = true;
                        }
                    });
                } else {
                    this.error.confirm = true;
                }
            } else {
                this.error.short = true;
            }
        } else {
            this.toastService.onError(this.translateService.instant('toast.fields-required'));
        }
    }

    findDefaultLanguage(code) {
        this.selectedLanguage = this.languages.find(lang => lang.code === code);
        if (this.selectedLanguage) {
            this.data.languageId = this.selectedLanguage.id;
        }
    }

    onSelect(data: any) {
        this.selectedLanguage = data;
        this.data.languageId = data.id;
    }

    privacyPolicy(): void {
        window.open(this.privacyUrls[this.selectedLanguage.code], '_blank');
    }
}
