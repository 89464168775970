<div class="modal modal--center modal-invitation-reopen" [ngClass]="{'active': isActive}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <div class="modal__back text-primary-500" *ngIf="invitationType" (click)="changeType($event)">
                <i class="icon icon-arrow-ios-left"></i>
                {{ 'words.back' | translate }}
            </div>

            <div class="modal__close">
                <i class="icon icon-close" (click)="close()"></i>
            </div>

            <h2 class="modal__title" *ngIf="!invitationType">
                {{ 'user.pending-invitations' | translate }} ({{ counts?.invitationCount || 0 }})
            </h2>

            <h2 class="modal__title" *ngIf="invitationType === 'resend'">
                {{ 'user.send-invitation-mails' | translate }}
            </h2>

            <h2 class="modal__title" *ngIf="invitationType === 'reminder'">
                {{ 'user.reminder-invitation-mails' | translate }}
            </h2>
        </div>

        <div class="modal__body" *ngIf="!invitationType">
            <p> {{ 'words.question.send-mail' | translate }} </p>
        </div>

        <div *ngIf="invitationType === 'resend'">
            <div class="flex flex-col space-y-4 py-4 items-center">
                <div>
                    <msc-common2-radio groupName="invitationNotification" [(ngModel)]="data.notification"
                        [valueName]="'auto'" [label]="'words.notification.auto' | translate">
                    </msc-common2-radio>
                    <msc-common2-radio groupName="invitationNotification" [(ngModel)]="data.notification"
                        [valueName]="'custom'" [label]="'words.notification.custom' | translate">
                    </msc-common2-radio>
                </div>
                <msc-textarea class="w-full" *ngIf="data.notification === 'custom'" name="textarea" rows="5" cols="33"
                    [(ngModel)]="data.message"></msc-textarea>
            </div>
        </div>

        <div class="modal__body" *ngIf="invitationType === 'reminder'">
            <p>{{ 'user.reminder-mails-description' | translate }}</p>
        </div>

        <div class="flex justify-center space-x-2" *ngIf="invitationType === 'resend'">
            <msc-button class="msc-button--primary" palette="primary" (click)="send()">
                {{ 'words.send' | translate }}
            </msc-button>
        </div>

        <div class="flex items-center space-x-2 gap-4 flex-col" *ngIf="!invitationType">
            <msc-button class="msc-button--primary" palette="primary" (click)="changeType($event, 'resend')">
                {{ 'user.send-invitation-one-mail' | translate }}
            </msc-button>

            <msc-button class="msc-button--outline" palette="primary" (click)="changeType($event, 'reminder')">
                {{ 'user.reminder-one-invitation-mail' | translate }}
            </msc-button>
        </div>

        <div class="flex items-center space-x-2" *ngIf="invitationType === 'reminder'">
            <msc-button (click)="close()">
                {{ 'words.no' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="primary" (click)="sendReminder()">
                {{ 'words.yes' | translate }}
            </msc-button>
        </div>
    </div>
</div>
