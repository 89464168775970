import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { environment } from '@env/environment';

@Injectable()
export class AdminJourneyProvider {

    constructor(private http: HttpClient) {}

    getJourneys(id: number, params?: any): Observable<any> {
        const response = {
            journeys: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/camp/${id}/admin/trajects`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.journeys = data.trajects.map((journey) => {
                    return journey;
                });

                return response;
            })
        );
    }

    getAttendanceList(id: number, journeyId: number): Observable<any> {
        return this.http.get( `${environment.envVar.API_URL}/camp/${id}/admin/trajects/${journeyId}/exports/attendance-list`, {responseType: 'blob'}).pipe(
            map((data: any) => data)
        );
    }

    updateSettings(campId: number, journeyId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/camp/${campId}/admin/trajects/${journeyId}`, params).pipe(
            map(data => data)
        );
    }

    getTemplates(id: number, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${id}/admin/trajects/${journeyId}/instances`, {params}).pipe(
            map(data => data)
        );
    }

    subscribeUsers(id: number, journeyId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/camp/${id}/admin/trajects/${journeyId}/users`, params).pipe(
            map(data => data)
        );
    }
}
