<section class="head">
    <h3>{{ 'organize.marketplace.settings.title' | translate }}</h3>
    <p>{{ 'organize.marketplace.settings.description' | translate }}</p>
    <ng-container *ngIf="!isEdit">
        <msc-button class="msc-button--primary" palette="primary" icon="icon-edit-2" (click)="isEdit = true">
            {{ 'common.edit' | translate }}
        </msc-button>
    </ng-container>
</section>

<section class="body">
    <ng-container *ngIf="settings$ | async as settings">
        <ng-container *ngIf="isEdit; else staticTemplate">
            <msc-organize-form-marketplace-settings-component [patch]="settings" (submitEvent)="onEditSettings($event)"></msc-organize-form-marketplace-settings-component>
        </ng-container>
        <ng-template #staticTemplate>
            <div class="static-form">
                <div class="grouping">
                    <label>{{ 'organize.marketplace.settings.heading.organisation' | translate }}</label>
                    <span>{{ settings.name }}</span>
                </div>

                <div class="grouping">
                    <label>{{ 'organize.marketplace.settings.heading.information' | translate }}</label>
                    <div class="wrapper">
                        <span>https://www.myskillcamp.com/training-provider/</span>
                        <span class="underline">{{ settings.url }}</span>
                    </div>
                </div>

                <div class="grouping">
                    <label>{{ 'organize.marketplace.settings.heading.logo' | translate }}</label>
                    <div class="content">
                        <div class="segment">
                            <ng-container *ngIf="settings.mediaUrl">
                                <img src="{{ settings.mediaUrl }}?size=256">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</section>
