<div class="content-configuration__content">
    <h3>{{ 'words.personal-data' | translate }}</h3>
    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-4 col-sm-6 col-xs-12">
            <msc-input [label]="'words.email' | translate" name="input" [(ngModel)]="data.email"
                [hasErrors]="formErrors.email"></msc-input>
        </div>
    </div>

    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
            <msc-input [label]="'words.lastname' | translate" name="lastname" [(ngModel)]="data.lastname"
                [hasErrors]="formErrors.lastname"></msc-input>
        </div>
    </div>

    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
            <msc-input [label]="'words.firstname' | translate" name="firstname" [(ngModel)]="data.firstname"
                [hasErrors]="formErrors.firstname"></msc-input>
        </div>
    </div>

    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
            <msc-input [label]="'words.job' | translate" name="job" [(ngModel)]="data.headline"></msc-input>
        </div>
    </div>

    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
            <label class="flex text-sm mb-1">{{ 'words.manager' | translate }}</label>

            <msc-common-select class="max-w-full text-sm" [data]="_availableManagers" >
                <ng-template #buttonTemplate let-data="data">
                    <ng-container *ngIf="data?.length; else emptyTemplate">
                        <button class="flex items-center overflow-hidden">
                            <msc-common-avatar class="tiny" [label]="data[0].label"
                                [pictureURL]="data[0].value.picture ? (data[0].value.picture + '?size=64') : null">
                            </msc-common-avatar>
                            <span class="truncate ml-2">{{ data[0].label }}</span>
                        </button>
                    </ng-container>
                    <ng-template #emptyTemplate>
                        <span>{{'user.slow-invite.select-manager' | translate}}</span>
                    </ng-template>   
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <button class="flex items-center text-sm py-2 w-full overflow-hidden" (click)="onSelectUserManager(datum)">
                        <msc-common-avatar class="tiny" [label]="datum.label"
                            [pictureURL]="datum.value?.picture ? (datum.value?.picture + '?size=64') : null">
                        </msc-common-avatar>
                        <span class="truncate ml-2">{{ datum.label }}</span>
                    </button>
                </ng-template>
            </msc-common-select>
        </div>
    </div>
</div>