<msc-common-aside #aside [title]="'words.add-url-embed' | translate" (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="embed-item">
            <msc-input type="text" label="URL - Iframe/Embed" placeholder="https://..." [(ngModel)]="embed"
                [debounce]="800" (onDebounce)="process()"></msc-input>
        </div>

        <div class="embed-item" *ngIf="ready">
            <iframe [src]="getEmbed()" allow="fullscreen" allowfullscreen></iframe>
        </div>
    </ng-template>
</msc-common-aside>