import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { StorageService } from '@services';
import { ReportingProvider } from '../..';

@Injectable()
export class InstanceProvider {

    constructor(private http: HttpClient, private reportingProvider: ReportingProvider, private storageService: StorageService) { }

    unsubscribe(id: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/subscriptions/courses/${id}`, { params }).pipe(
            map((data) => data)
        );
    }

    get(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/courses/${id}`, { params }).pipe(
            map((data) => data)
        );
    }

    getCorrections(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/instance/${id}/corrections`, { params }).pipe(
            map((data) => data)
        );
    }

    getSummary(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/instance/${id}/summary`, { params }).pipe(
            concatMap((data) => this.reportingProvider.getContentSubscriptionOverview(this.storageService.get('company').id, id, [this.storageService.get('me').id]).pipe(
                map((res) => ({ ...data, session: { ...{ progress: 0, eventDuration: 0, duration: 0, score: 0 }, ...res[this.storageService.get('me').id]?.context } })),
                catchError(() => of(data)))
            ),
            concatMap((data: any) => this.reportingProvider.getActivitySubscriptionDetails(this.storageService.get('company').id, id, this.storageService.get('me').id, data.sections.flatMap(section => section.sectionContents?.map(section => section.id)).filter((e) => e)).pipe(
                map((res) => {
                    data.sections = data.sections.map((section) => {
                        if (section.sectionContents) {
                            section.sectionContents = section.sectionContents.map(item => ({ ...item, session: res[item.id]?.context }));
                        }

                        const finishedContents = section.sectionContents.filter(item => item.session.progress === 100).length;
                        section.progress = {
                            totalContents: section.sectionContents.length,
                            finishedContents,
                            finishedPercent: finishedContents / section.sectionContents.length * 100
                        }

                        return section;
                    })
                    return data;
                }),
                catchError(() => of(data)))
            )
        );
    }
}
