import { Component, EventEmitter } from '@angular/core';
import { Company, CompanyExperience, CompanyExperienceProvider, CompanyUrlProvider, LanguageProvider, Media } from '@lighty';
import { EmitterService, StorageService, TranslateService } from '@services';
import { DataHelper, UrlHelper } from '@helpers';
import { Language } from '@interfaces';
import { forkJoin } from 'rxjs';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-experience-watch-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class OrganizeExperienceWatchLoginComponent {
    private company: Company;
    public experience: CompanyExperience;
    public loadEmitter: EventEmitter<any> = new EventEmitter();
    public loading: boolean = false;
    public loginUrl: string;
    public selectedLang: string;
    public displays: any;
    public message: string;
    public urls: any;
    public selectData: any = {
        languages: [],
        urls: []
    };

    constructor(
        private companyExperienceProvider: CompanyExperienceProvider,
        private companyUrlProvider: CompanyUrlProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
        private emitterService: EmitterService,
        private languageProvider: LanguageProvider
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.experience = this.storageService.get('currentExperience');

        const url = this.company && this.company.experience && this.company.experience.customUrl ? this.company.experience.customUrl.slice(0, -1) : window.location.origin;

        this.loginUrl = url + '/login?company=' + this.company.slug + '&experience=' + this.experience.slug;
        this.selectedLang = this.translateService.getCurrentLang();
        this.getInterfaceLanguages();
        this.processMessage();
        this.getExperienceAndUrls();

        this.displays = {
            lang: false,
            url: false
        };
    }

    getInterfaceLanguages(): void {
        this.languageProvider
            .get('interface')
            .subscribe((data) => {
                this.selectData.languages = this.getSelectData(data, 'languages');
            });
    }

    getExperienceAndUrls() {
        const params = {
            excluded_experience_id: this.experience.id,
            status: 1
        };

        this.companyUrlProvider.get(this.company.id, params)
        forkJoin([
            this.companyExperienceProvider.get(this.company.id, this.experience.id),
            this.companyUrlProvider.get(this.company.id, params)
        ]).subscribe(resp => {
            this.experience = this.storageService.set('currentExperience', resp[0]);
            this.selectData.urls = this.getSelectData(resp[1], 'urls');
        })
    }

    processMessage(): void {
        this.message = this.experience.loginMessages.find((loginMessage) => {
            return loginMessage.lang === this.selectedLang;
        })?.message;
    }

    getSelectData(data, key) {
        switch (key) {
            case 'languages':
                return data.map((language, index) => ({
                    id: index,
                    label: language.name,
                    value: language.code,
                    isSelected: language.code === this.selectedLang
                }));
            case 'urls':
                const options = data.map(url => ({
                    id: url.id,
                    label: url.url,
                    value: url,
                    isSelected: false
                }));

                if (!this.experience.url) {
                    return options;
                }

                const defaultOption = {
                    id: this.experience.url.id,
                    label: this.experience.url.url,
                    value: this.experience.url,
                    isSelected: true
                }

                return [defaultOption, ...options];
        }
    }

    onSelectOption(option, key: string) {
        switch (key) {
            case 'languages':
                this.selectData[key] = this.selectData[key].map((selectDatum) => {
                    selectDatum.isSelected = selectDatum.id === option.id;
                    return selectDatum;
                });
                this.setLang(option.value);
                break;
            case 'urls':
                let param;
                this.selectData[key] = this.selectData[key].map((d) => {
                    if (d.id === option.id) {
                        d.isSelected = !d.isSelected;
                        param = d.isSelected ? d.value : undefined;
                    } else {
                        d.isSelected = false;
                    }
                    return d;
                });

                this.linkUrl(param);
                break;
        }
    }

    linkUrl(url?: any): void {
        const params = {
            url_id: url ? url.id : null
        };

        this.companyUrlProvider.updateExperienceUrl(this.company.id, this.experience.id, params).subscribe(() => {
            this.getExperienceAndUrls();
            this.displays.url = false;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    getMessage(lang: string): string {
        return this.experience.loginMessages.find((loginMessage) => {
            return loginMessage.lang === lang;
        })?.message;
    }

    hasMessages(): boolean {
        return this.experience.loginMessages.filter((loginMessage) => {
            return !!loginMessage.message;
        }).length > 0;
    }

    messageMissing(): boolean {
        return this.experience.loginMessages.filter((loginMessage) => {
            return !!loginMessage.message;
        }).length !== 6;
    }

    update(): void {
        this.companyExperienceProvider.update(this.company.slug, this.experience.id, this.experience).subscribe((data) => {
            this.experience = this.storageService.set('currentExperience', data);
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.loading = false;
        });
    }

    load(): void {
        this.loading = true;
    }

    loadPicture(): void {
        this.loadEmitter.emit(true);
    }

    updatePicture(media: Media): void {
        this.experience.loginMediaId = media.id;
        this.update();
    }

    deletePicture(): void {
        this.experience.loginMediaId = null;
        this.update();
    }

    redirectContact(): void {
        UrlHelper.mailTo('sales@myskillcamp.com');
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    setLang(lang: string): void {
        this.selectedLang = lang;
        this.processMessage();

        const timeOut = setTimeout(() => {
            this.emitterService.set('froala.refresh', true);
            clearTimeout(timeOut);
        }, 150);
    }

    updateMessage(): void {
        const index = this.experience.loginMessages.findIndex((loginMessage) => {
            return loginMessage.lang === this.selectedLang;
        });

        if (index > -1) {
            this.experience.loginMessages[index].message = this.message;
        } else {
            this.experience.loginMessages.push({ lang: this.selectedLang, message: this.message });
        }

        this.update();
    }

    clipboard(): void {
        DataHelper.clipboard(this.loginUrl);
        this.toastService.onSuccess(this.translateService.instant('toast.link-clipboard'));
    }
}
