import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-showroom-exhibit-tab-page',
    templateUrl: './showroom-exhibit-tab-page.component.html',
    styleUrls: ['./showroom-exhibit-tab-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitTabPageComponent {

}
