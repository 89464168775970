import { Component } from '@angular/core';

@Component({
    selector: 'msc-showroom-exhibit-button-showroom',
    templateUrl: './showroom-exhibit-button-page.component.html',
    styleUrls: ['./showroom-exhibit-button-page.component.scss'],
})

export class ShowroomExhibitButtonPageComponent {
    constructor() { }

    ngOnInit(): void { }
}
