<div class="flex justify-between border-b py-2 my-4 gap-2" *ngIf="!camp">
    <!--search-->
    <div class="w-full 2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2">
        <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)">
        </msc-common-search>
    </div>
    <msc-button class="msc-button--primary" palette="primary" icon="icon-logo-myskillcamp"
        *ngIf="access.roles.companyOwner" (click)="openModalCreateCamp(modalCreateCamp)">
        {{ 'words.create' | translate }}
    </msc-button>
</div>
<ng-container *ngIf="(camps$ | async)?.length || isSearching; else otherTemplate">
    <div>
        <div class="card-container mt-5">
            <ng-container *ngFor="let camp of camps$ | async">
                <div class="camp rounded" [routerLink]="[camp.id]">
                    <i class="icon icon-logo-myskillcamp"></i>

                    <p [tooltip]="camp.name.length > 35 ? camp.name : ''">
                        {{ camp.name | truncate: 35 }}
                    </p>

                    <div class="camp__meta">
                        <div>
                            <i class="icon icon-people"></i>
                            <p>{{ camp.countLearners ? camp.countLearners : '0' }}</p>
                        </div>

                        <div>
                            <i class="icon icon-catalogue"></i>
                            <p>{{ camp.countContent ? camp.countContent : '0' }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="hasLoadMore">
            <div class="flex justify-center mt-4">
                <msc-button class="msc-button--primary" palette="secondary" (click)="onLoad()">{{ 'words.load-more' | translate }}</msc-button>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-template #otherTemplate>
    <div class="container-camps">
        <ng-container *ngIf="camp">
            <h4 class="mb-5"><span [routerLink]="['/organize', company.slug, 'camp']" class="hover:text-primary-500 hover:cursor-pointer">{{'words.camps' | translate}}</span> >
                {{camp.name}}</h4>
            <div class="container-camps__main row">
                <aside class="container-camps__navigation col-xxs-12 col-md-3 col-3">
                    <div class="navigation-camps mt-1 mb-5">
                        <ul class="navigation-camps__content">
                            <li class="navigation-camps__item" [routerLink]="['contents']"
                                [routerLinkActive]="'active'">
                                <p>{{ 'words.contents' | translate }}</p>
                            </li>

                            <li class="navigation-camps__item" [routerLink]="['member']" [routerLinkActive]="'active'">
                                <p> {{ 'words.members' | translate }}</p>
                            </li>

                            <li class="navigation-camps__item"
                                [ngClass]="{'displayed': displays.stats, 'active': isActivatedRoute('stats')}"
                                (click)="displayed('stats')">
                                <p>{{ 'words.stats' | translate }} <span class="ml-1">
                                        <msc-tag color="orange" [text]="'words.new' | translate"></msc-tag>
                                    </span></p>

                                <a class="toggle toggle-campamentos">
                                    <i class="icon icon-arrow-ios-down text-secondary-500"></i>
                                </a>
                            </li>

                            <li class="navigations-camps__sub">
                                <ul>
                                    <li class="navigations-camps__sub__item" [routerLink]="['stats', 'content']"
                                        [routerLinkActive]="'active'">
                                        <p>{{ 'words.global-overview' | translate }}</p>
                                    </li>

                                    <li class="navigations-camps__sub__item" [routerLink]="['stats', 'adoption']"
                                        [routerLinkActive]="'active'">
                                        <p>{{ 'words.adoption' | translate }}</p>
                                    </li>

                                    <li class="navigations-camps__sub__item" [routerLink]="['stats', 'exports']"
                                        [routerLinkActive]="'active'">
                                        <p>{{ 'exports.title' | translate }} <span class="ml-1">
                                                <msc-tag color="orange" [text]="'words.new' | translate"></msc-tag>
                                            </span></p>
                                    </li>
                                </ul>
                            </li>

                            <li class="navigation-camps__item" [routerLink]="['timeline']"
                                [routerLinkActive]="'active'">
                                <p>{{ 'words.timeline' | translate }}</p>
                            </li>

                            <li class="navigation-camps__item" [routerLink]="['community']"
                                [routerLinkActive]="'active'" *ngIf="camp.settings.groups">
                                <p>{{ 'words.groups' | translate }}</p>
                            </li>

                            <li class="navigation-camps__item" [routerLink]="['subscription']"
                                [routerLinkActive]="'active'" *ngIf="camp.settings.catalogue">
                                <p>{{ 'words.request-subscriptions' | translate }}</p>
                            </li>

                            <li class="navigation-camps__item" [routerLink]="['correction']"
                                [routerLinkActive]="'active'">
                                <p>{{ 'words.corrections' | translate }}</p>
                            </li>

                            <li class="navigation-camps__item" *ngIf="camp.permissions.manageCampSettings"
                                [ngClass]="{'displayed': displays.settings, 'active': isActivatedRoute('settings')}"
                                (click)="displayed('settings')">
                                <p>{{ 'words.settings' | translate }}</p>

                                <a class="toggle toggle-campamentos">
                                    <i class="icon icon-arrow-ios-down"></i>
                                </a>
                            </li>

                            <li class="navigations-camps__sub" *ngIf="camp.permissions.manageCampSettings">
                                <ul>
                                    <li class="navigations-camps__sub__item" [routerLink]="['settings', 'general']"
                                        [routerLinkActive]="'active'">
                                        <p>
                                            <i class="icon icon-settings"></i> {{ 'words.general-settings' | translate
                                            }}
                                        </p>
                                    </li>

                                    <li class="navigations-camps__sub__item" [routerLink]="['settings', 'features']"
                                        [routerLinkActive]="'active'">
                                        <p>
                                            <i class="icon icon-toggle-left"></i> {{ 'words.features' | translate }}
                                        </p>
                                    </li>

                                    <li class="navigations-camps__sub__item"
                                        [routerLink]="['settings', 'notifications']" [routerLinkActive]="'active'">
                                        <p>
                                            <i class="icon icon-bell"></i> {{ 'words.notifications' | translate }}
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </aside>

                <div class="container-camps__content col-xxs-12 col-md-9 col-9">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<msc-common-modal size="30vw" #modalCreateCamp [title]="'words.new-camp' | translate">
    <ng-template>
        <msc-input [label]="'words.camp-name' | translate" name="input" [placeholder]="'words.name' | translate"
            id="camp-name" [(ngModel)]="campName"></msc-input>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalCreateCamp.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="createCamp(); modalCreateCamp.onClose()">
                {{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
