<msc-common-aside #aside>
    <ng-template #headerTemplate>
        <div class="flex flex-col space-y-4 relative">
            <msc-button class="absolute right-2 top-2" type="fab" icon="icon-close" (click)="aside.onClose()">
            </msc-button>
            <div class="flex flex-col">
                <div class="flex space-x-2 items-center">
                    <msc-button *ngIf="tab === 'community'" type="fab" icon="icon-arrow-ios-left"
                        (click)="changeTab('users')">
                    </msc-button>
                    <h3>
                        {{ 'words.add-new-participants' | translate }}
                    </h3>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4 slide-sessions-members-journey">
            <ng-container *ngIf="tab === 'users'">
                <msc-common-search [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)">
                </msc-common-search>
                <div class="dynamic-table">
                    <div class="dynamic-table__body">
                        <div class="dynamic-table__row row-radius row-shadow" *ngFor="let member of availableMembers">
                            <div class="dynamic-table__cell cell-title--small slide__checkbox flex">
                                <msc-common2-checkbox [value]="isUserSelected(member.id)"
                                    [isDisabled]="data.invite_all_accounts" (changeEvent)="selectMember(member.id)">
                                </msc-common2-checkbox>

                                <div class="avatar-profile">
                                    <msc-common-avatar [label]="member.name"
                                        [pictureURL]="member.picture ? (member.picture + '?size=128') : null">
                                    </msc-common-avatar>
                                    <p>
                                        {{ member.name }}
                                        <span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="tab === 'instances'">
                <div class="dynamic-table dynamic-table-toggle" *ngFor="let template of templates; let index=index">
                    <p class="text-error color--red" *ngIf="template.missingInstance">
                        <i class="icon icon-alert-triangle"></i>
                        {{ 'journey.missing-instance' | translate }}
                    </p>
                    <div class="dynamic-table__container" *ngIf="template.instances?.length > 0"
                        [ngClass]="{'active': displays[index]}">
                        <div class="dynamic-table__body">
                            <div class="dynamic-table__row row-radius row-shadow slide__checkbox"
                                (click)="displayed(index)">
                                <div class="dynamic-table__cell cell-title--small">
                                    {{ template.title }}
                                </div>

                                <div class="dynamic-table__cell cell-with-icon cell-large">
                                    <div class="cell-with-icon__container">
                                        <i class="icon icon-timer"></i>
                                        <div>{{ template.duration | duration }}</div>
                                    </div>
                                </div>

                                <i class="icon-displayer icon icon-arrow-ios-down"></i>
                            </div>

                            <div class="dynamic-table__content">
                                <div class="dynamic-table__body">
                                    <div class="dynamic-table__row row-radius row-shadow slide__checkbox"
                                        *ngFor="let instance of template.instances; let i=index" (click)="selectInstance(index,instance.id)">
                                        <msc-common2-radio [groupName]="instance.id" [ngModel]="instancesSelected[index]" [valueName]="instance.id"></msc-common2-radio>

                                        <div class="dynamic-table__cell cell-title--small">
                                            {{ instance.internalName }}
                                        </div>

                                        <div class="dynamic-table__cell cell-with-icon cell-large">
                                            <div class="cell-with-icon__container">
                                                <i class="icon icon-people"></i>
                                                <div>{{ instance.usersCount }}</div>
                                                <div *ngIf="instance.usersLimit">/{{ instance.usersLimit }}</div>
                                            </div>

                                            <div class="cell-with-icon__container">
                                                <i class="icon icon-message-circle"></i>
                                                <div>{{ instance.language }}</div>
                                            </div>
                                        </div>

                                        <div class="dynamic-table__cell cell-with-icon">
                                            <i class="cell-actions__icon icon icon-calendar"></i>
                                            <div class="color--orange">{{ instance.eventsCount }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <msc-common-spinner *ngIf="loaders.loading"></msc-common-spinner>

            <msc-load-more [loading]="loaders.loadmore" [pagination]="pagination" (onLoad)="loadMore()"
                *ngIf="tab === 'users'"></msc-load-more>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <p class="text-error color--red" *ngIf="missingInstance && tab === 'instances'">
            <i class="icon icon-alert-triangle"></i>
            {{ 'journey.template-instance-missing' | translate }}
        </p>

        <div class="flex justify-between grow items-center" *ngIf="tab === 'users'">
            <msc-common2-checkbox [value]="data.invite_all_accounts" [label]="'words.invite-all' | translate"
                (changeEvent)="inviteAll()"></msc-common2-checkbox>

            <msc-button (click)="changeTab('instances')" [disabled]="data.accountIds.length === 0"
                *ngIf="templates.length > 0 && tab === 'users'">
                {{ 'journey.choice-instances' | translate }}
            </msc-button>
        </div>

        <div class="flex justify-end grow items-center">
            <msc-button (click)="changeTab('users')" [disabled]="data.accountIds.length === 0"
                *ngIf="templates.length > 0 && tab === 'instances'">
                {{ 'journey.choice-members' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="secondary" (click)="save()"
                *ngIf="tab === 'instances' || templates.length === 0 && !loaders.templates"
                [disabled]="data.accountIds.length === 0 || missingInstance">
                {{ 'words.save' | translate }}
            </msc-button>
        </div>
    </ng-template>
</msc-common-aside>

<msc-common-modal size="30vw" #modalConfirm [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'journey.modal.subscribe.confirm' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalConfirm.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="subscribe(); modalConfirm.onClose()">
                {{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>