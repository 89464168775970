import {
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
export type MscButtonTypes = 'text' | 'fab' | 'custom';
export type MscHtmlButtonTypes = 'button' | 'submit' | 'reset';
export type MscPaletteTypes = 'slate' | 'primary' | 'secondary' | 'danger' | 'success' | 'error' | 'info';

@Component({
    selector: `msc-button`,
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class CommonButtonComponent {
    @Input() type: MscButtonTypes = 'text';
    @Input() htmlType: MscHtmlButtonTypes = 'button';
    @Input() icon: string;
    @Input() iconSecondary: string;
    @Input() palette: MscPaletteTypes = 'slate';
    @Input() disabled = false;
    @Input() loading = false;

    @ViewChild('buttonHandle') public buttonHandle: ElementRef;
    constructor() {}

    ngOnInit(): void {}
}