<div class="content-manage-follow-up">
    <div class="content-manage-follow-up__menu">
        <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
    </div>

    <div class="content-manage-follow-up__content">
        <div class="content-manage-follow-up__list">
            <div class="content-manage-follow-up__list__item box" [ngClass]="{'content-manage-follow-up__list__item--progress' : user.hasAttestations && user.pendingApprovals, 'content-manage-follow-up__list__item--success' : user.hasAttestations && !user.pendingApprovals, 'active' : user.id === selectedUser?.id}" *ngFor="let user of users" (click)="selectUser(user)">
                <div class="item-avatar">

                    <div class="avatar-user">
                        <msc-common-avatar class="tiny" [label]="user?.name"
                            [pictureURL]="user?.avatar ? (user?.avatar + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="avatar__content">
                            <p class="profile__name">{{ user.firstname }} {{ user.lastname }}</p>
                        </div>
                    </div>
                </div>

                <p class="item-login">{{ user.lastConnection | timeAgo | lowercase }}</p>

                <div class="item-arrow">
                    <i class="icon icon-arrow-ios-right"></i>
                </div>
            </div>

            <msc-load-more [loading]="loading.loadMore" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>

        <div class="content-manage-follow-up__body" *ngIf="selectedUser">
            <msc-common-spinner *ngIf="loading.logs"></msc-common-spinner>

            <div class="content-manage-follow-up__time" *ngIf="!loading.logs">
                <div class="flex justify-end" *ngIf="externalContentSession && !externalContentSession.doneAt">
                    <msc-button class="msc-button--primary" palette="secondary" icon="icon-checkmark" (click)="finished()">
                        {{ 'words.mark-finished' | translate }}
                    </msc-button>
                </div>

                <div class="time-block" [ngClass]="{'active': displays.time}">
                    <div class="time-header" (click)="displayed('time')">
                        <h2>{{ 'session-log.total-time-for' | translate }}</h2>

                        <span class="time-header__data" *ngIf="duration">
                            <i class="icon icon-clock text-primary-500" ></i>

                            {{ 'session-log.total-time' | translate }} : {{ getDuration('days') }} {{ getDuration('hours') }} {{ getDuration('minutes') }}
                        </span>

                        <span *ngIf="!duration">
                            {{ 'session-log.no-time' | translate }}
                        </span>

                        <i class="icon icon-arrow-ios-up open-action"></i>
                    </div>

                    <div class="time-content">
                        <div class="dynamic-table" *ngFor="let session of sessions; let index = index">
                            <div class="dynamic-table__body">
                                    <div class="dynamic-table__row">
                                        <div class="dynamic-table__cell">{{ session.date | date: 'dd MMMM YYYY' }}</div>

                                        <div class="dynamic-table__cell">{{ convertSessionDuration(session) || 'words.no-duration' | translate }}</div>
                                    </div>
                                </div>

                            <div class="time-add-modal" *ngIf="displays.modals.edit[index]">
                                    <div class="time-add-modal__inputs">
                                        <div>
                                            <div class="input input--date input--icon-right" [ngClass]="{'active': displays.datepickers[index]}">
                                                <input type="text" name="input" autocomplete="off" class="input__text" [ngModel]="data.updateDate | date" (click)="displayedDatepickers(index)">

                                                <span class="input__icon">
                                                    <i class="icon icon-calendar text-secondary-500"></i>
                                                </span>
                                            </div>

                                            <div class="dropdown-datepicker">
                                                <msc-datepicker [date]="data.updateDate" (onUpdate)="updateDateEdit($event)" (onClose)="displayedDatepickers(index)"></msc-datepicker>
                                            </div>
                                        </div>

                                        <div class="flex align-center">
                                            <input type="number" class="input-number" [(ngModel)]="data.value" min="0">

                                            <select class="select" [(ngModel)]="data.unit">
                                                <option value="minutes">{{ 'words.minutes' | translate }}</option>
                                                <option value="hours">{{ 'words.hours' | translate}}</option>
                                                <option value="days">{{ 'words.days' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="time-add-modal__actions">
                                        <msc-button icon="icon-close" (click)="displayedModals('edit', index, false)">
                                            {{ 'words.cancel' | translate }}
                                        </msc-button>

                                        <msc-button class="msc-button--primary" palette="secondary" icon="icon-checkmark" [loading]="loading.updateLog" *ngIf="data.value && data.updateDate" (click)="updateLog(session)">
                                            {{ 'words.validate' | translate }}
                                        </msc-button>
                                    </div>
                                </div>
                        </div>

                        <div class="time-add" (click)="displayed('addTime'); resetData()" *ngIf="!displays.addTime">
                            <i class="icon icon-plus-circle"></i>

                            <span>{{ 'session-log.add-time' | translate }}</span>
                        </div>

                        <div class="time-add-modal" *ngIf="displays.addTime">
                            <div class="time-add-modal__inputs">
                                <div>
                                    <div class="input input--date input--icon-right" [ngClass]="{'active': displays.datepicker}">
                                        <input type="text" name="input" autocomplete="off" class="input__text" [ngModel]="data.date | date" (click)="displayed('datepicker')">

                                        <span class="input__icon">
                                            <i class="icon icon-calendar"></i>
                                        </span>
                                    </div>

                                    <div class="dropdown-datepicker">
                                        <msc-datepicker [date]="data.date" (onUpdate)="updateDate($event)" (onClose)="displayed('datepicker')"></msc-datepicker>
                                    </div>
                                </div>

                                <div class="flex align-center">
                                    <input type="number" class="input-number" [(ngModel)]="data.value" min="0">

                                    <select class="select" [(ngModel)]="data.unit">
                                        <option value="minutes">{{ 'words.minutes' | translate }}</option>
                                        <option value="hours">{{ 'words.hours' | translate}}</option>
                                        <option value="days">{{ 'words.days' | translate }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="time-add-modal__actions">
                                <msc-button icon="icon-close" (click)="displayed('addTime')">
                                    {{ 'words.cancel' | translate }}
                                </msc-button>

                                <msc-button class="msc-button--primary" palette="secondary" *ngIf="data.value && data.date" icon="icon-checkmark" [loading]="loading.createLog" (click)="addLog()">
                                    {{ 'words.validate' | translate }}
                                </msc-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <msc-empty-state *ngIf="users.length === 0" [title]="'manage.follow-up'" [description]="'empty.nothing.members'"></msc-empty-state>
</div>

<msc-common-modal size="30vw" #modalDelete>
    <ng-template>
        <p>{{ 'session-log.delete.confirmation' | translate: {value: (selectedSession?.time | date : 'dd MMMM YYYY')} }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDelete.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteLog(); modalDelete.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>