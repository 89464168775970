<div class="modal modal-form-group" [ngClass]="{'active': isActive}">
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <div class="modal__close" (click)="close()">
                <i class="icon icon-close"></i>
            </div>

            <h2 class="modal__title">
                <i class="icon icon-people text-primary-700"></i>
                Créer un groupe de discussion
            </h2>
        </div>

        <div class="modal__body">
            <msc-common-search class="my-2" name="search" trigger="auto" [placeholder]="'common.search' | translate"
                (searchEvent)="search($event.value)"></msc-common-search>

            <ng-container *ngIf="membersList.learners.length > 0">
                <h4>{{
                    (membersList.managers.length > 1 ? 'words.trainers' : 'words.trainer') | translate }}</h4>
                <div class="p-2 my-2 border">
                    <div class="flex flex-col space-y-2 max-h-40 overflow-auto">
                        <div class="flex items-center space-x-2" *ngFor="let manager of membersList.learners">
                            <msc-common2-checkbox (changeEvent)="addMember(manager.id)"></msc-common2-checkbox>

                            <msc-common-avatar [label]="manager?.name"
                                [pictureURL]="manager?.picture ? (manager?.picture + '?size=128') : null">
                            </msc-common-avatar>

                            <p>
                                {{ manager.name }}
                                <span>{{ manager.headline }}</span>
                            </p>
                        </div>

                        <ng-container *ngIf="paginationCollection">
                            <msc-load-more [pagination]="paginationCollection.managers" [loading]="loaders.managers"
                                (onLoad)="loadMoreMembers('managers')"></msc-load-more>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="membersList.learners.length > 0">
                <h4>{{
                    (membersList.learners.length > 1 ? 'words.attendees' : 'words.attendee') | translate }}</h4>
                <div class="p-2 my-2 border">
                    <div class="flex flex-col space-y-2 max-h-40 overflow-auto">
                        <div class="flex items-center space-x-2"
                            *ngFor="let learner of membersList.learners; let index=index">
                            <msc-common2-checkbox (changeEvent)="addMember(learner.accountId)">
                            </msc-common2-checkbox>

                            <msc-common-avatar class="tiny" [label]="learner?.name"
                                [pictureURL]="learner?.picture ? (learner?.picture + '?size=128') : null">
                            </msc-common-avatar>

                            <p>
                                {{ learner.name }}
                                <span>{{ learner.headline }}</span>
                            </p>
                        </div>

                        <ng-container *ngIf="paginationCollection">
                            <msc-load-more [pagination]="paginationCollection.learners" [loading]="loaders.learners"
                                (onLoad)="loadMoreMembers('learners')"></msc-load-more>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="flex justify-end space-x-2">
            <msc-button (click)="close()">
                {{ 'words.cancel' | translate }}
            </msc-button>
            <msc-button class="msc-button--primary" palette="secondary" (click)="create()">
                {{ 'words.create' | translate }}
            </msc-button>
        </div>
    </div>
</div>