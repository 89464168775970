import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//
import { ComponentFormModel } from '@models/component-form.model';

@Component({
    selector: 'msc-organize-form-marketplace-customers-component',
    templateUrl: './organize-form-marketplace-customers.component.html',
    styleUrls: ['./organize-form-marketplace-customers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeFormMarketplaceCustomersComponent extends ComponentFormModel {
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        protected readonly fb: FormBuilder,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setFormGroup();
    }

    /**
     * Get the form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'comment': [''],
            'email': ['', [Validators.required, Validators.email]],
            'name': ['', [Validators.required, Validators.minLength(2)]],
        });
    }

    /**
     * Event handler for form submit
     */
    onSubmit(): void {
        this._onSubmit(this.form.value);
        this._onReset();
        this.onCloseModal();
    }

    /**
     * Event handler for close modal
     */
    onCloseModal(): void {
        this.closeModal.emit();
    }
}
