import { Injectable, EventEmitter } from '@angular/core';

/**
 * Error Stream Service
 */
@Injectable({providedIn: 'root'})
export class ErrorStreamService {
    /**
     * Lock
     */
    private lock: boolean = false;
    /**
     * Stream emitter
     */
    private stream: EventEmitter<any> = new EventEmitter();

    /**
     * Listen stream error
     * @return {EventEmitter<any>} Error Stream
     */
    listen(): EventEmitter<any> {
        return this.stream;
    }

    /**
     * Push error in stream
     * @param {string} message - Error message
     * @param {number} code - Error code
     */
    push(message: string, code?: number): void {
        if (!this.lock) {
            this.stream.emit({message, code});
        }
    }

    /**
     * Lock error
     */
    locked(): void {
        this.lock = true;
    }

    /**
     * Unlock error
     */
    unlocked(): void {
        this.lock = false;
    }

    /**
     * Checks if the stream is locked or not
     * @return {boolean} Lock
     */
    isLocked(): boolean {
        return this.lock;
    }
}
