import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LoaderService } from '@services';

@Component({
    selector: 'msc-navigation-progress',
    templateUrl: './navigation-progress.component.html',
    styleUrls: ['./navigation-progress.component.scss']
})
export class NavigationProgressComponent implements OnInit {
    private timeouts: any = {
        change: null,
        reset: null
    };
    public navigationType: string = null;

    constructor(private router: Router, private loaderService: LoaderService) {}

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loaderService.stop(true);
                this.navigationType = null;
                this.changeType('start');
            }

            if (event instanceof NavigationEnd) {
                const element: any = document.getElementById('main');
                const params: any = {
                    block: 'center',
                    inline: 'nearest',
                    behavior: 'smooth'
                };
                element.scrollIntoView(params);
                this.changeType('end');
            }
        });

        this.loaderService.status.subscribe((loading) => {
            if (loading) {
                this.navigationType = null;
                this.changeType('start');
            } else {
                this.changeType('end');
            }
        });
    }

    private changeType(type: string): void {
        const ms = (type === 'start') ? 10 : 200;
        clearTimeout(this.timeouts.change);

        this.timeouts.change = setTimeout(() => {
            this.navigationType = type;

            if (type === 'end') {
                this.reset();
            }
            clearTimeout(this.timeouts.change);
        }, ms);
    }

    private reset(): void {
        const ms = 250;
        clearTimeout(this.timeouts.reset);

        this.timeouts.reset = setTimeout(() => {
            this.navigationType = 'hidden';
            clearTimeout(this.timeouts.reset);
        }, ms);
    }
}
