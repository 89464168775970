import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-showroom-exhibit-tag-showroom',
    templateUrl: './showroom-exhibit-tag-page.component.html',
    styleUrls: ['./showroom-exhibit-tag-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitTagPageComponent {

}
