<div class="content-experience content-experience--catalogue">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title mb-3 flex justify-between">
                <h3>{{'organize.certifacates.nav.certificate' | translate}}</h3>
                <div class="flex flex-col">
                    <!--
                                <msc-button
                                class="msc-button--primary ml-auto mb-3"
                                icon="icon-plus"
                                palette="secondary"
                                (click)="openModalAdd()"
                            >
                                {{ "words.add" | translate }}
                            </msc-button>-->
            
                    <div class="flex w-4/5">
                        <div class="flex align-center">
                            <msc-button class="msc-button--primary mr-3" icon="icon-refresh" palette="secondary"
                                (click)="clearFilters()" [tooltip]="'words.refresh' | translate" [disabled]="isLoading">
                            </msc-button>
            
                            <msc-button class="msc-button--primary" icon="icon-trash" palette="secondary" (click)="clearFilters()"
                                [tooltip]="'words.reset-filters' | translate" *ngIf="currentSelectedFilter != undefined">
                            </msc-button>
                            <div class="flex align-center w-64 pl-6">
                                <p>{{ "words.filters" | translate }}</p>
                                <msc-common-select class="text-sm w-full" [data]="filters">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="select-main">
                                            <ng-container>
                                                <span>{{currentSelectedFilter?.key}}</span>
                                            </ng-container>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="select-option" (click)="onSelectType(datum, 'filter')">
                                            <span>{{ datum.key }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </div>
                            <div class="scale-50">
                                <msc-common-spinner *ngIf="isLoadingFilter"></msc-common-spinner>
                            </div>
                            <div class="w-52 pl-6"
                                *ngIf="currentSelectedFilter != undefined && !isLoadingFilter && currentSelectedFilter?.value != 'email'">
                                <msc-common-select class="text-sm w-full" [data]="filteredFilters">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="select-main">
                                            <ng-container>
                                                <span>{{currentSelectedFilteredFilters?.title | translate}}</span>
                                            </ng-container>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="select-option" (click)="onSelectType(datum, 'filterSelect')">
                                            <span>{{ datum.title | translate }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </div>
            
                            <msc-input [placeholder]="'words.email' | translate" class="w-2/4 pr-3" [(ngModel)]="inputFilter"
                                *ngIf="currentSelectedFilter?.value == 'email' && !isLoadingFilter"></msc-input>
            
                            <msc-button class="msc-button--primary ml-auto" icon="icon-search" palette="secondary"
                                (click)="filter()" *ngIf="currentSelectedFilteredFilters != undefined || inputFilter != ''">
                                {{ "placeholder.search" | translate }}
                            </msc-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-4 mt-10">
                <div class="flex flex-row box-experience__title">
                    <div class="w-1/6">
                        <span>{{'words.name' | translate}}</span>
                    </div>
                    <div class="w-1/6">
                        <span>{{'words.email' | translate}}</span>
                    </div> 
                    <div class="w-1/6">
                        <span>{{'words.experience-name' | translate}}</span>
                    </div>
                    <div class="w-2/6">
                        <span>{{'words.course-name' | translate}}</span>
                    </div>
                    <div class="w-1/6">
                        <span>{{'words.certificate-link' | translate}}</span>
                    </div>
                    <div class="w-1/6">
                        <span>{{'words.certificate-download' | translate}}</span>
                    </div>
                    <div class="w-1/6">
                        <span>{{'words.certificate-status' | translate}}</span>
                    </div>
                    <div class="w-1/12">
                        <span></span>
                    </div>
                </div>
                
                <msc-common-spinner *ngIf="isLoading"></msc-common-spinner>
                <div class="flex flex-col gap-4 mt-6" *ngIf="!isLoading">
                    <div
                        class="flex flex-row w-full box-experience__title"
                        *ngFor="let row of emitedCertificates"
                    >
                        <div class="w-1/6">
                            <span>{{ row.name }}</span>
                        </div>
                         <div class="w-1/6">
                            <span>{{ row.email }}</span>
                        </div> 
                        <div class="w-1/6">
                            <span>{{ row.experience }}</span>
                        </div>
                        <div class="w-2/6">
                            <span>{{ row.course }}</span>
                        </div>
                        <div class="w-1/6">
                            <ng-container *ngIf="row.status != 'words.status-revoked'">
                                <i 
                                class="icon icon-eye mr-5 cursor-pointer"
                                [tooltip]="row.link"
                                ></i>
                                <i 
                                class="icon icon-copy cursor-pointer"
                                (click)="copyLink(row.link)"
                                [tooltip]="'words.copy-clipboard' | translate"></i>
                            </ng-container>
                        </div>
                        <div class="w-1/6">
                            <i 
                            class="icon icon-download-1 mr-5 cursor-pointer"
                            [tooltip]="'words.download' | translate"
                            *ngIf="row.status != 'words.status-revoked'"
                            (click)="downloadCertificate(row)"
                            ></i>
                        </div>
                        <div class="w-1/6 flex align-center">
                            <div *ngIf="row.status == 'words.status-pending'" class="w-24 p-1 rounded-lg min-h-9 pending text-center">
                                <span>{{ row.status | translate }}</span>
                            </div>
                            <div *ngIf="row.status == 'words.status-sended'" class="w-24 p-1 rounded-lg min-h-9 sended text-center">
                                <span>{{ row.status | translate }}</span>
                            </div>
                            <div *ngIf="row.status == 'words.status-failed'" class="w-24 p-1 rounded-lg min-h-9 failed text-center">
                                <span>{{ row.status | translate }}</span>
                            </div>
                            <div *ngIf="row.status == 'words.status-revoked'" class="w-24 p-1 rounded-lg min-h-9 revoked text-center">
                                <span>{{ row.status | translate }}</span>
                            </div>
                            <div *ngIf="row.status == 'words.status-emitted'" class="w-24 p-1 rounded-lg min-h-9 emitted text-center">
                                <span>{{ row.status | translate }}</span>
                            </div>
                        </div>
                        <div class="flex gap-2 w-1/12">
                            <msc-button
                                *ngIf="row.status == 'words.status-emitted'"
                                class="msc-button--primary max-w-6"
                                palette="secondary"
                                icon="icon icon-file-remove"
                                (click)="openModalDelete(row)"
                                [tooltip]="'words.certificate-revoke' | translate"
                            >
                            </msc-button>

                            <msc-button
                            *ngIf="row.status == 'words.status-emitted'"
                            class="msc-button--primary"
                            icon="icon icon-email"
                            palette="primary"
                            (click)="sendCertificate(row.id)"
                            [tooltip]="'words.send-certificate-mail' | translate"
                            >
                        </msc-button>

                        </div>
                    </div>
                </div>
                <!--pagination-->
                <nav aria-label="Page navigation" [ngClass]="{'opacity-0 pointer-events-none': isLoading}" class="flex justify-center">
                    <ul class="flex items-center -space-x-px h-8 text-sm">
                    <li [tooltip]="'words.first' | translate" (click)="lastFirstPage('first')">
                        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <span class="sr-only">Previous</span>
                        <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
                        </svg>
                        </a>
                    </li>
                    <li *ngFor="let item of paginationItems; let i = index" (click)="setPaginator(i + 1, $event)">
                        <a class="paginationItem flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{i + 1}}</a>
                    </li>
                    <li [tooltip]="'words.last' | translate" (click)="lastFirstPage('latest')">
                        <a class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <span class="sr-only">Next</span>
                        <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                        </svg>
                        </a>
                    </li>
                    </ul>
                </nav> 
            </div>
        </div>
    </div>
</div>

<msc-common-modal
    size="20vw"
    #modalDelete
    [title]="'words.certificate.revoke-title' | translate"
>
    <ng-template>
        <span>{{'words.certificate.revoke-text' | translate}}</span>

        <div class="flex items-center mt-3 space-x-5 justify-start">
            <msc-button
            class="msc-button--primary mr-3"
            palette="primary"
            (click)="revokeCerficate()"
        >
                {{ "words.certificate-revoke" | translate }}
            </msc-button>

            <msc-button 
            palette="primary" 
            (click)="modalDelete.onClose()">
                {{ "common.cancel" | translate }}
            </msc-button>
        </div>
    </ng-template>
</msc-common-modal>
