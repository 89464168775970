import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Group, GroupProvider, Account } from '@lighty';
import { StorageService } from '@services';

@Component({
    selector: 'msc-learn-community-subscribe-modal',
    templateUrl: './modal-subscribe.component.html'
})
export class LearnCommunityModalSubscribeComponent implements OnInit {
    @Input() community: Group;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    private company: any;
    public isActive: boolean = false;
    public users: any;

    constructor(private route: ActivatedRoute, private router: Router, private groupProvider: GroupProvider,
                private storageService: StorageService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.isActive = true;
        this.getUsers();
    }

    private getUsers(): void {
        const params = {
            staff: 1
        };

        this.groupProvider.getUsers(this.company.id, this.community.id, params).subscribe((data) => {
            this.users = data.users;
        });
    }

    close(): void {
        this.onClose.emit();
    }

    subscribeToGroup(): void {
        this.groupProvider.subscribe(this.company.id, this.community.id).subscribe(() => {
            this.router.navigate([this.community.id], {relativeTo: this.route});
        });
    }

    startDiscussion(account: Account): void {
        this.storageService.set('conversationUsers', [account]);
        this.router.navigate(['conversation/create']);
    }
}
