import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CompanyUserProvider } from '@lighty';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StorageService } from '@services';
import { take } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('iframe') iframe!: ElementRef;

  public selectList: any;
  public isLoading: boolean = true;
  public urlSafe: SafeResourceUrl;
  public currentSelection: string = ''; 

  constructor(
    private companyUserProvider: CompanyUserProvider,
    private storageService: StorageService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.companyUserProvider.GetDashboardOnlyActive(this.storageService.get('company').experience.id)
      .pipe(take(1))
      .subscribe((data) => {
        this.selectList = data.dashboards;
        console.log(this.selectList)
        this.currentSelection = data.dashboards[0].name;
        this.loadDashboard(data.dashboards[0].uuid);
      });
  }

  loadDashboard(dashboardId: any, dashboardName?: string): void {
    this.companyUserProvider.ShowDashboard(this.storageService.get('company').experience.id, dashboardId)
      .pipe(take(1))
      .subscribe((data) => {
        if(dashboardName)
          this.currentSelection = dashboardName;
          const newUrl = data.url;
          this.updateIframeUrl(newUrl);
          this.isLoading = false;
      });
  }

  updateIframeUrl(newUrl: string): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(newUrl);
  }

  changeUrl() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://griky.1mentor.io/auth/login'
    );
  }
}
