<div class="info-tooltip">
    <i class="icon icon-info" (mouseenter)="show($event)" (mouseleave)="hide()"></i>
    <div class="info-tooltip__anchor" *ngIf="display" [ngStyle]="{'top': mouseY + 'px', 'left': mouseX + 'px'}">
        <div class="info-tooltip__container" [ngClass]="{ 'info-tooltip__container--flipped' : position === 'left' }">
            <div class="info-tooltip__header">
                <div class="info-tooltip__header__skills">
                    <div class="skill skill--shadow-none skill--level"
                        *ngFor="let skill of item?.skills; let index = index">
                        <div class="skill__content">
                            <div class="skill__level">
                                <span [ngClass]="{'active' : skill.level > 0 }"></span>
                                <span [ngClass]="{'active' : skill.level > 1 }"></span>
                                <span [ngClass]="{'active' : skill.level > 2 }"></span>
                            </div>

                            <p class="skill__text">{{ skill.names }}</p>
                        </div>
                    </div>
                </div>
                <div class="info-tooltip__header__details">
                    <div *ngIf="item.duration" class="info-tooltip__header__info">
                        <i class="icon icon-timer"></i>
                        <span>{{ item.duration | duration }}</span>
                    </div>
                    <div class="info-tooltip__header__info">
                        <i class="icon icon-message-circle"></i>
                        <span>
                            {{ item.language.name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="info-tooltip__content">
                {{ item.description || 'words.undescribed-content' | translate}}
            </div>
        </div>
    </div>
</div>