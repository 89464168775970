import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataHelper, ColorHelper } from '@helpers';
import { find } from '@functions';

@Component({
    selector: 'msc-funnel',
    templateUrl: './funnel.component.html'
})
export class FunnelComponent implements OnInit {
    @ViewChild('funnel', {static: true}) funnel: ElementRef;
    @Input() options: any;
    @Input() width: number = 650;
    @Input() height: number = 750;
    @Input() backgroundColor: string = '#FFF';
    private context: any;
    private size: any;
    private margin: number = 240;
    private space: number = 300;
    private maxWidth: number = 800;
    private minWidth: number = 280;
    public id: string;

    constructor() {}

    ngOnInit(): void {
        this.id = DataHelper.generateId('canvas-');

        this.context = this.funnel.nativeElement.getContext('2d');

        this.setCanvas();

        this.size = {
            width: this.funnel.nativeElement.width,
            height: this.funnel.nativeElement.height,
            first: {
                x: [],
                y: []
            },
            second: {
                x: [],
                y: []
            }
        };

        this.draw();
        this.draw(this.maxWidth, true);

        if (this.options.fill) {
            this.fill();
        }

        if (this.options.grid) {
            this.grid();
        }

        this.write();
    }

    private draw(baseX: number = 0, mirroring: boolean = false): void {
        let x = baseX;
        let previousX = null;
        let previousY = null;

        this.context.beginPath();
        this.context.moveTo(this.margin + baseX, 0);
        this.context.lineTo(this.margin + baseX, 0);

        this.context.moveTo(this.margin + baseX, 0);
        this.context.lineTo(this.margin + baseX, this.space);

        this.context.lineWidth = this.options.line && this.options.line.width ? this.options.line.width : 3;
        this.context.strokeStyle = this.options.line && this.options.line.color ? ColorHelper.hexToRGB(this.options.line.color, this.options.line.opacity) : 'black';
        this.context.closePath();
        this.context.stroke();

        for (let i = 0; i < this.options.data.length; i++) {
            if (mirroring) {
                x = this.margin + this.maxWidth - (1 - (this.options.data[i].value / this.options.total)) * (this.maxWidth - this.minWidth) / 2;
            } else {
                x = this.margin + (1 - (this.options.data[i].value / this.options.total)) * (this.maxWidth - this.minWidth) / 2;
            }
            const y = this.space * (i + 1);

            this.context.beginPath();
            this.context.moveTo(previousX !== null ? previousX : x, previousY !== null ? previousY : y);
            this.context.lineTo(x, y);

            previousX = x;
            previousY = y;

            if (!mirroring) {
                this.size.first.x.push(x);
                this.size.first.y.push(y);
            } else {
                this.size.second.x.push(x);
                this.size.second.y.push(y);
            }

            this.context.lineWidth = this.options.line && this.options.line.width ? this.options.line.width : 3;
            this.context.strokeStyle = this.options.line && this.options.line.color ? ColorHelper.hexToRGB(this.options.line.color, this.options.line.opacity) : 'black';
            this.context.closePath();
            this.context.stroke();
        }
    }

    private fill(): void {
        this.context.beginPath();

        this.context.moveTo(this.margin, 0);
        this.context.lineTo(this.margin, 0);
        this.context.lineTo(this.margin, this.space);
        this.context.lineTo(this.margin + this.maxWidth, this.space);
        this.context.lineTo(this.margin + this.maxWidth, 0);

        this.context.fillStyle = ColorHelper.hexToRGB(this.options.fill.color, this.options.fill.opacity) || 'black';
        this.context.closePath();
        this.context.fill();

        for (let i = 0; i < this.options.data.length; i++) {
            this.context.beginPath();

            this.context.moveTo(this.size.first.x[i], this.size.first.y[i]);
            this.context.lineTo(this.size.first.x[i], this.size.first.y[i]);
            this.context.lineTo(this.size.first.x[i + 1], this.size.first.y[i + 1]);
            this.context.lineTo(this.size.second.x[i + 1], this.size.second.y[i + 1]);
            this.context.lineTo(this.size.second.x[i], this.size.second.y[i]);

            this.context.fillStyle = ColorHelper.hexToRGB(this.options.fill.color, this.options.fill.opacity) || 'black';
            this.context.closePath();
            this.context.fill();
        }
    }

    private grid(): void {
        for (let i = 0; i < this.options.data.length; i++) {
            const y = this.space * (i + 1);

            this.context.beginPath();
            this.context.moveTo(0, y);
            this.context.lineTo(this.size.width, y);
            this.context.lineWidth = this.options.grid.width || 1;
            this.context.strokeStyle = ColorHelper.hexToRGB(this.options.grid.color, this.options.grid.opacity) || 'black';
            this.context.closePath();
            this.context.stroke();
        }
    }

    private write(): void {
        for (let i = 0; i < this.options.data.length; i++) {
            const y = this.space * i;

            if (this.options.label) {
                this.context.fillStyle = ColorHelper.hexToRGB(this.options.label.color, this.options.label.opacity) || 'black';
                this.context.textAlign = 'center';
                this.context.font = this.options.label.font || '28px Arial';
                this.context.fillText(this.options.data[i].label, this.margin * 2.7, y + 120);
            }

            if (this.options.value) {
                this.context.fillStyle = ColorHelper.hexToRGB(this.options.value.color, this.options.value.opacity) || 'black';
                this.context.textAlign = 'center';
                this.context.font = this.options.value.font || '42px Arial';
                this.context.fillText(this.options.data[i].value, this.margin * 2.7, y + 170);
            }

            if (this.options.percentage && i > 0) {
                const percentage = (this.options.data[i].value / this.options.total * 100).toPrecision(3);

                this.context.fillStyle = ColorHelper.hexToRGB(this.options.percentage.color, this.options.percentage.opacity) || 'black';
                this.context.textAlign = 'center';
                this.context.font = this.options.percentage.font || '24px Arial';
                this.context.fillText(percentage + '% ' + this.options.percentage.text, this.margin * 2.7, y + 210);
            }
        }
    }

    private setCanvas(): void {
        this.funnel.nativeElement.width = this.width * 2;
        this.funnel.nativeElement.height = this.height * 2;

        const timeOut = setTimeout(() => {
            const canvas = find('#' + this.id);

            canvas.style.width = this.width + 'px';
            canvas.style.height = this.height + 'px';
            canvas.style.backgroundColor = this.backgroundColor;

            clearTimeout(timeOut);
        }, 50);
    }
}
