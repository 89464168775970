<msc-common-aside #aside [title]="" (saveEvent)="updateGroupSettings()">
    <ng-template #headerTemplate>
        <h3>{{ 'settings.group.title' | translate }} : {{ currentGroup.title }}</h3>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4 slide-community-settings">
            <msc-input [label]="'group.title' | translate" [(ngModel)]="currentGroup.name"
                [placeholder]="currentGroup.name"></msc-input>
            <div class="slide__toggle">
                <h2>{{ 'words.visibility' | translate }}</h2>

                <div class="toggle-choice">
                    <msc-common2-radio groupName="timelineVisibility" [label]="'group.visibility-display' | translate" [(ngModel)]="currentGroup.timelineVisibility" [valueName]="true"></msc-common2-radio>
                    <msc-common2-radio groupName="timelineVisibility" [label]="'group.visibility-hidden' | translate" [(ngModel)]="currentGroup.timelineVisibility" [valueName]="false"></msc-common2-radio>
                </div>

                <h2>{{ 'group.group-type' | translate }}</h2>

                <div class="toggle-choice">
                    <msc-common2-radio groupName="subscriptionType" [label]="'group.free' | translate" [(ngModel)]="currentGroup.subscriptionType" [valueName]="'free'"></msc-common2-radio>
                    <msc-common2-radio groupName="subscriptionType" [label]="'group.admin' | translate" [(ngModel)]="currentGroup.subscriptionType" [valueName]="'admin'"></msc-common2-radio>
                </div>
            </div>

            <div class="image">
                <div class="slide__input">
                    <div class="input-upload-media media__avatar">
                        <div class="input-upload-media__label">{{'group.upload.upload-profil' | translate}}</div>
                        <div class="input-upload-media__desc">{{'group.upload.upload-profil.description' | translate}}
                        </div>
                        <div class="input-upload-media__container">
                            <div class="input-upload-media__image"
                                style="background-image: url('https://static.myskillcamp.com/lxp/images/catalog-item-2.png')"
                                *ngIf="!profileMedia && !loadProfile"></div>
                            <div class="input-upload-media__image"
                                [style.background-image]="'url(' + profileMedia + ')'"
                                *ngIf="profileMedia && !loadProfile">
                                <div class="input-image-loader">
                                    <msc-common-spinner *ngIf="loadProfile"></msc-common-spinner>
                                </div>
                            </div>

                            <input #fileUploadProfile class="display-none" type="file"
                                accept="image/png,image/jpeg,image/jpg,image/gif" fileUpload [fileUrl]="'media'"
                                (fileStatus)="updateProfileMedia($event)">
                            <div class="input-upload-media__buttons" (click)="fileUploadProfile.click()"><i
                                    class="icon icon-image"></i></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="image">
                <div class="slide__input">
                    <div class="input-upload-media">
                        <div class="input-upload-media__label">{{'group.upload.upload-cover' | translate}}</div>
                        <div class="input-upload-media__desc">{{'group.upload.upload-cover.description' | translate}}
                        </div>
                        <div class="input-upload-media__container">
                            <div class="input-upload-media__image"
                                style="background-image: url('https://static.myskillcamp.com/lxp/images/catalog-item-2.png')"
                                *ngIf="!backgroundMedia && !loadProfile"></div>
                            <div class="input-upload-media__image"
                                [style.background-image]="'url(' + backgroundMedia + ')'"
                                *ngIf="backgroundMedia && !loadProfile">
                                <div class="input-image-loader">
                                    <msc-common-spinner *ngIf="loadBackground"></msc-common-spinner>
                                </div>
                            </div>

                            <input #fileUploadBackground class="display-none" type="file"
                                accept="image/png,image/jpeg,image/jpg,image/gif" fileUpload [fileUrl]="'media'"
                                (fileStatus)="updateBackgroundMedia($event)">
                            <div class="input-upload-media__buttons" (click)="fileUploadBackground.click()"><i
                                    class="icon icon-image"></i></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="community-message">
                <msc-textarea [label]="'group.welcome.message' | translate" [maxlength]="100"
                    [(ngModel)]="currentGroup.info" [placeholder]="'group.welcome.message' | translate"></msc-textarea>
                <span class="textarea-count">Characters: {{ currentGroup.info?.length || 0 }}/100</span>
            </div>
        </div>
    </ng-template>
</msc-common-aside>