import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
//
import { StorageService } from '@services';
import { BillingProvider, Company } from '@lighty';
import { DownloadHelper } from '@helpers';

@Component({
    selector: 'msc-organize-company-invoice',
    templateUrl: './invoice.component.html',
    providers: [DatePipe],
})
export class OrganizeCompanyInvoiceComponent {
    private company: Company;
    public invoices: any[];

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private billingProvider: BillingProvider,
        private datePipe: DatePipe,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.invoices = this.route.snapshot.data.invoices;
    }

    getHour(date: any): string {
        return this.datePipe.transform(new Date(date), 'HH');
    }

    download(invoice: any): void {
        const params = {
            billable_id: this.company.slug,
            billable_type: 'company'
        };

        this.billingProvider.getInvoice(invoice.id, params).subscribe((data) => {
            DownloadHelper.download(data.url, 'invoice_' + invoice.id + '.pdf', false);
        });
    }
}
