<div class="stats-usage__item__chart" *ngIf="stats && stats.length > 0">
    <msc-chart-bar [data]="data" [options]="options" [plugins]="plugins"></msc-chart-bar>
</div>

<div class="stats-usage__item__empty" *ngIf="stats && stats.length === 0">
    <div class="stats-usage__item__empty__icon bg-secondary-200">
        <i class="icon icon-file-remove text-secondary-500"></i>
    </div>

    <div class="stats-usage__item__empty__title">
        {{ 'empty.available.data' | translate }}
    </div>

    <div class="stats-usage__item__empty__desc">
        {{ 'empty.available.chart' | translate }}
    </div>
</div>
