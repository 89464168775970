import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'msc-organize-experience-aside-create-template',
    templateUrl: './create-template.component.html'
})
export class OrganizeExperienceAsideCreateTemplateComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public isActive: boolean = false;
    public theme: any;
    public data: any;

    constructor() {}

    init(): void {
        this.isActive = true;

        this.data = {
            id: this.theme ? this.theme.id : null,
            name: this.theme ? this.theme.name : '',
            primaryColor: this.theme ? this.theme.primaryColor : '#FEB88A',
            secondaryColor: this.theme ? this.theme.secondaryColor : '#5150C4'
        };
    }

    onOpen(theme): void {
        this.theme = theme;
        this.init();
        this.aside.onOpen();
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (this.data.name && this.data.primaryColor && this.data.secondaryColor) {
            this.onSave.emit(this.data);
        }
    }

    getColors(hex: string): string {
        return hex && hex.length === 7 ? hex : '#FFFFFF';
    }
}
