import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'msc-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
    @Input() text: string = '';
    @Input() color: string = '';
    @Input() icon: string = '';
    @Input() type: string = '';
    @Input() class: string = '';
    @Input() day: string = '';
    @Input() month: string = '';

    constructor() {}

    ngOnInit(): void {}
}
