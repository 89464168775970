<div class="content-access content-access__organize">
    <div class="content-access__organize__header">
        <h3>
            <span>{{ 'words.company' | translate }}</span>
            <div [tooltip]="'access.organize.info' | translate">
                <i class="icon icon-info"></i>
            </div>
        </h3>

        <div class="content-access__organize__enable">
            <msc-button class="msc-button--primary" palette="primary" (click)="modalAccess.onOpen()" [disabled]="!user.companyRoles.companyAdministrator || accountId === me.id ">{{ 'words.revoke-access' | translate }}</msc-button>
        </div>
    </div>

    <div class="content-access__organize__data">
        <div class="data__logo">
            <msc-common-avatar [label]="company.name" [pictureURL]="company.picture ? (company.picture + '?size=256') : null"></msc-common-avatar>
        </div>

        <div class="flex center direction-column">
            <div class="data__form">
                <msc-common2-checkbox
                    [value]="user.companyRoles.companyOwner"
                    [isDisabled]="accountId === me.id || loading"
                    [label]="'access.organize.super-admin.description' | translate"
                    (changeEvent)="user.companyRoles.companyOwner = !user.companyRoles.companyOwner; updateAccess()">
                </msc-common2-checkbox>
            </div>
        </div>
    </div>

    <div class="content-access__organize__list" *ngIf="!user.companyRoles.companyOwner">
        <h3>{{ 'access.organize.camps' | translate }}</h3>

        <div class="content-access__organize__actions">
            <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
        </div>

        <div class="camps__list">
            <div class="list-item col-4 col-md-6 col-xxs-12" *ngFor="let camp of camps">
                <div class="card-camp">
                    <div class="card-camp__content">
                        <a class="content__action" (click)="removeCamp(camp)">
                            <i class="icon icon-minus"></i>
                        </a>

                        <div class="content__logo">
                            <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                        </div>

                        <div class="content__data">
                            <p *ngIf="camp.name.length > 30" [tooltip]="camp.name">
                                {{ camp.name | truncate: 30 }}
                            </p>
                            <p *ngIf="camp.name.length < 30">
                                {{ camp.name }}
                            </p>

                            <div class="data__pictos">
                                <div class="data__members">
                                    <i class="icon icon-people"></i>
                                    <p>{{ camp.usersCount }}</p>
                                </div>

                                <div class="data__sessions">
                                    <i class="icon icon-calendar"></i>
                                    <p>{{ camp.instancesCount }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-camp__check">
                        <msc-common2-checkbox
                            [value]="camp.permissions.manageCampSettings"
                            [label]="'access.organize.settings' | translate"
                            (changeEvent)="camp.permissions.manageCampSettings = !camp.permissions.manageCampSettings; updateAccessSettings(camp)">
                        </msc-common2-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-access__organize__available" *ngIf="!user.companyRoles.companyOwner">
        <h3>{{ 'access.organize.camps.available' | translate }}</h3>

        <div class="content-access__organize__actions">
            <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="searchAvailable($event.value)"></msc-common-search>
        </div>

        <div class="camps__list">
            <div class="list-item col-6 col-md-6 col-xxs-12" *ngFor="let camp of availableCamps">
                <div class="card-camp card-camp--flat">
                    <div class="card-camp__content">
                        <div class="content__logo">
                            <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                        </div>

                        <div class="content__data content__data__available">
                            <p *ngIf="camp.name.length > 30" [tooltip]="camp.name">
                                {{ camp.name | truncate: 30 }}
                            </p>
                            <p *ngIf="camp.name.length < 30">
                                {{ camp.name }}
                            </p>

                            <div class="data__pictos">
                                <div class="data__members">
                                    <i class="icon icon-people"></i>
                                    <p>{{ camp.usersCount }}</p>
                                </div>

                                <div class="data__sessions">
                                    <i class="icon icon-calendar"></i>
                                    <p>{{ camp.instancesCount }}</p>
                                </div>
                            </div>
                        </div>

                        <a class="content__action content__action--blue bg-secondary-500" (click)="addCamp(camp)">
                            <i class="icon icon-plus"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalAccess [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'access.disabled-organize' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalAccess.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="disableAccess(); modalAccess.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
