import { Component, Input } from '@angular/core';

@Component({
    selector: 'presentation-additional-details',
    templateUrl: './additional-details.component.html',
    styleUrls: ['additional-details.component.scss']
})
export class PresentationAdditionalDetails {
    @Input() content;
    @Input() backgroundImage;

    providerPictureNotLoaded = false;

    constructor() { }
}
