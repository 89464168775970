import { Component } from '@angular/core';
import { Company, ManageTeamProvider } from '@lighty';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService, TranslateService, LoaderService } from '@services';
import * as moment from 'moment';
import { GroupUserContentTypes, GroupUserContentState } from '@enums';
import { HttpHelper, DataHelper } from '@helpers';
import { first } from 'rxjs/operators';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-manage-member-details',
    templateUrl: './member-details.component.html',
    styleUrls: ['./member-details.component.scss']
})

export class ManageMemberDetailsComponent {
    company: Company;
    user;
    groupId;
    query: string;
    dates: any = {};
    availableProviders: any;
    availableTypes: any;
    isFilterActive: boolean = false;
    isTrainer: boolean = false;
    displays = {
        mail: false,
        period: false,
        types: false,
        subscribe: false,
        orderDate: false
    };

    contentTypes = GroupUserContentTypes;
    contentStates = GroupUserContentState;

    content = {
        [GroupUserContentTypes.SUBSCRIBED]: {
            pagination: null,
            data: null,
            loading: true
        },
        [GroupUserContentTypes.STARTED]: {
            pagination: null,
            data: null,
            loading: true
        },
        [GroupUserContentTypes.COMPLETED]: {
            pagination: null,
            data: null,
            loading: true,
            order: '-completion',
        }
    }

    refuseContentData: any = {};
    refuseContentReason: string

    teamMembers = {
        pagination: null,
        data: null,
        loading: true
    }

    public selectOrderOptions: any[] = [];
    public selectData: any[] = [];
    public tempContent: any;
    public modalConfirmValidateOnSave: () => void;

    me;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private teamProvider: ManageTeamProvider,
        private translateService: TranslateService,
        private loaderService: LoaderService,
    ) {

        this.dates = {
            start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        };
    }

    ngOnInit() {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.groupId = this.route.snapshot.params.id;

        this.teamMembers.data = this.route.snapshot.data.team.users;
        this.teamMembers.pagination = this.route.snapshot.data.team.pagination;
        this.teamMembers.loading = false;

        const currentIndex = this.teamMembers.data.findIndex((user) => user.account.id == this.route.snapshot.params.memberId);
        if (!this.teamMembers.data[currentIndex]) {
            // go back to team members list if user not found
            this.router.navigate(['..', '..'], { relativeTo: this.route });
        }
        this.user = {
            data: {
                ...this.teamMembers.data[currentIndex].account,
                lastLearningActivity: this.teamMembers.data[currentIndex].lastLearningActivity
            },
            index: currentIndex
        };

        this.selectOrderOptions = [
            {
                label: 'words.sort-completion-asc',
                value: 'completion',
            }, {
                label: 'words.sort-completion-desc',
                value: '-completion'
            }, {
                label: 'words.sort-score-asc',
                value: 'score'
            }, {
                label: 'words.sort-score-desc',
                value: '-score'
            }
        ];

        this.selectData = this.getSelectData(this.selectOrderOptions);

        this.initData();
    }

    initData() {
        this.router.navigate(['..', this.user.data.id], { relativeTo: this.route, queryParamsHandling: 'merge', queryParams: this.teamMembers.pagination });
        this.getMeta();

        Object.values(this.contentTypes).forEach(type => {
            this.content[type].data = null;
            this.content[type].pagination = null;
            this.getContents(type)();
        });
    }

    getSelectData(data) {
        return data.map((d, index) => {
            const id = index;
            const label = d.label;
            const value = d.value;
            const isSelected = d.label === this.getOrderDate();
            return { id, label, value, isSelected };
        });
    }

    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });

        this.selectOrderDate(option.value);
    }

    navigate(direction: 'next' | 'prev') {
        let nextIndex = direction === 'next' ? this.user.index + 1 : this.user.index - 1;
        if (nextIndex === this.teamMembers.data.length) {
            this.teamMembers.loading = true;

            const params = HttpHelper.cleanParams({
                ...this.teamMembers.pagination,
                page: this.teamMembers.pagination.currentPage + 1
            });

            this.teamProvider.getGroupUsers(this.company.slug, this.me.teamOwnerId, params).subscribe((data) => {
                this.teamMembers.data.push(...data.users);
                this.teamMembers.pagination = { ...data.pagination, q: params.q, headlines: params.headlines, sort: params.sort, sortBy: params.sortBy };
                this.teamMembers.loading = false;
                this.user = {
                    data: {
                        ...this.teamMembers.data[nextIndex].account,
                        lastLearningActivity: this.teamMembers.data[nextIndex].lastLearningActivity
                    },
                    index: nextIndex
                };
                this.initData();
            });
        } else if (nextIndex === -1 && this.teamMembers.pagination.currentPage > 1) {
            this.teamMembers.loading = true;

            const params = HttpHelper.cleanParams({
                ...this.teamMembers.pagination,
                page: this.teamMembers.pagination.currentPage - 1
            });

            this.teamProvider.getGroupUsers(this.company.slug, this.me.teamOwnerId, params).subscribe((data) => {
                this.teamMembers.data.unshift(...data.users);
                this.teamMembers.pagination = { ...data.pagination, q: params.q, headlines: params.headlines, sort: params.sort, sortBy: params.sortBy };
                this.teamMembers.loading = false;
                nextIndex = this.teamMembers.data.findIndex((user) => user.account.id === this.user.data.id) - 1;
                this.user = {
                    data: {
                        ...this.teamMembers.data[nextIndex].account,
                        lastLearningActivity: this.teamMembers.data[nextIndex].lastLearningActivity
                    },
                    index: nextIndex
                };
                this.initData();
            });
        } else {
            this.user = {
                data: {
                    ...this.teamMembers.data[nextIndex].account,
                    lastLearningActivity: this.teamMembers.data[nextIndex].lastLearningActivity
                },
                index: nextIndex
            };
            this.initData();
        }
    }


    search(type?) {
        if (type) {
            this.getContents(type)({ fromSearch: true, search: this.query });
        } else {
            Object.values(this.contentTypes).forEach(contentType => this.getContents(contentType)({ fromSearch: true, search: this.query }));
        }
    }

    getContents(type) {
        return ((opts?) => {
            let typeChild = null;
            const filteredTypes = this.availableTypes?.filter(type => {
                if (type.children) {
                    typeChild = { ...typeChild, [`${type.value}_types`]: type.children.filter(type => type.isSelected).map(type => type.value).join() }
                }
                if (type.isSelected) {
                    return true
                }

                return false;
            }) || [];

            const params = HttpHelper.cleanParams({
                q: opts?.search,
                type,
                order_by: type === GroupUserContentTypes.COMPLETED ? this.content[GroupUserContentTypes.COMPLETED].order.replace('-', '') : null,
                order_way: type === GroupUserContentTypes.COMPLETED ? this.content[GroupUserContentTypes.COMPLETED].order.indexOf('-') !== -1 ? 'desc' : 'asc' : null,
                page: opts?.loadMore ? this.content[type].pagination.currentPage + 1 : null,
                is_trainer: this.isTrainer ? 1 : null,
                content_types: filteredTypes.map(type => type.value).join(','),
                providers: this.availableProviders?.filter(provider => provider.isSelected).map(provider => provider.id).join(',') || null,
                ...typeChild
            });

            if (opts?.loadMore && params.page > this.content[type].pagination.lastPage)
                return;

            if (!opts?.loadMore && opts?.fromSearch) {
                this.content[type].data = null;
                this.content[type].pagination = null;
            }

            this.content[type].loading = true;

            this.teamProvider.getUserContents(this.company.id, this.groupId, this.user.data.id, params).subscribe((data) => {
                if (opts?.loadMore) {
                    this.content[type].data.push(...data.instances);
                } else {
                    this.content[type].data = data.instances;
                }
                this.content[type].pagination = data.pagination;
                this.content[type].loading = false;
            }, () => {
                this.content[type].loading = false;
            });
        });
    }

    loadMore(type) {
        if (this.content[type].loading)
            return;
        this.getContents(type)({ loadMore: true });
    }

    displayed(type: string, cancel?): void {
        this.displays[type] = !this.displays[type];

        if (type === 'types' && !this.displays[type] && cancel) {
            this.resetFiltersTypes();
        }

        if (type === 'providers' && !this.displays[type] && cancel) {
            this.resetFilterProviders();
        }
    }

    hasUnSelectedSubtypes(types: any[]): boolean {
        return types.length > 0 && types.length !== types.filter(type => type.selected).length && types.filter(type => type.selected).length !== 0;
    }

    getMeta() {
        this.teamProvider.getUserContentsMeta(this.company.id, this.groupId, this.route.snapshot.params.memberId).subscribe((data) => {
            this.availableProviders = this.getProvidersOptions(data.meta.providers);
            this.availableTypes = this.getTypesOptions(data.meta);
        })
    }

    getProvidersOptions(providers) {
        const label = this.company.experience
            ? this.company.experience.displayName
                ? this.company.experience.displayName
                : this.company.experience.name
            : this.company.name;

        return providers.map(provider => ({
            id: provider.id,
            label: provider.id === 'company' ? label : provider.name,
            value: provider.id,
            isSelected: false
        }));
    }

    getSubTypesOptions(data: any, key: string) {
        const subtypes = data[`${key}Types`];
        if (!subtypes) {
            return [];
        }

        return Object.keys(subtypes)
            .map((subtype, idx) => ({
                id: `${subtype}-${idx}`,
                label: this.translateService.instant(`words.course-type.${subtype}`),
                value: subtype,
                isSelected: false
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getTypesOptions(data: any) {
        return Object.keys(data.contentTypes)
            .map((type, index) => ({
                id: `${type}-${index}`,
                label: this.translateService.instant(`api.content-type.${type.split(/(?=[A-Z])/).join('_').toLowerCase()}`),
                value: type.split(/(?=[A-Z])/).join('_').toLowerCase(),
                isSelected: false,
                children: this.getSubTypesOptions(data, type)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    onFilterReset() {
        // this.query = null;
        this.isFilterActive = false;
        this.isTrainer = false;
        this.resetFiltersTypes();
        this.resetFilterProviders();
        this.search();
    }

    resetFiltersTypes(): void {
        this.availableTypes = this.availableTypes.map(type => {
            type.isSelected = false
            type.children.map(subtype => {
                subtype.isSelected = false;
                return subtype;
            });
            return type;
        });
    }

    resetFilterProviders(): void {
        this.availableProviders = this.availableProviders.map(provider => {
            provider.isSelected = false;
            return provider;
        })
    }

    sendMail(message) {
        const params = {
            account_ids: [this.user.data.id],
            content: message
        }

        this.teamProvider.sendMailToGroupUser(this.company.id, this.groupId, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.mail-successfully-sent'));
        });
    }

    subscribeMembersToContent(event) {
        const params = {
            context: event.context,
            sessionIds: event.sessionIds,
            accountIds: [this.user.data.id],
            groupId: this.groupId
        }
        this.loaderService.run();
        this.teamProvider.subscribeUsersToContent(this.company.id, event.contentId, params).subscribe(() => {
            this.loaderService.stop();
            this.getContents(GroupUserContentTypes.SUBSCRIBED)({ fromSearch: true });
            this.toastService.onSuccess(this.translateService.instant('toast.users-subscribed-content'));
        });
    }

    getOrderDate(): string {
        switch (this.content[GroupUserContentTypes.COMPLETED].order) {
            case 'completion':
                return 'words.sort-completion-asc';
            case '-completion':
                return 'words.sort-completion-desc';
            case 'score':
                return 'words.sort-score-asc';
            case '-score':
                return 'words.sort-score-desc';
        }
    }

    selectOrderDate(order: string): void {
        this.content[GroupUserContentTypes.COMPLETED].order = order;

        this.search(GroupUserContentTypes.COMPLETED);
    }

    getLogo(content: any): string {
        switch (content?.type) {
            case 'external_content':
                if (!content.data.provider?.logo) {
                    return 'https://static.myskillcamp.com/images/integrations/' + content.data.provider?.key + '.svg';
                } else {
                    return content.data.provider?.logo + '?size=128';
                }
            case 'external_video':
                return 'https://static.myskillcamp.com/images/integrations/youtube.svg';
        }
    }

    refuseContent(content) {
        this.refuseContentData = content?.data;
    }

    acceptContent(content) {
        this.tempContent = content;
        this.modalConfirmValidateOnSave = () => this.acceptContentConfirm(content);
    }

    get modalConfirmValidateTitle() { return this.translateService.instant('group.team.cards.validate.title', { title: this.tempContent?.data?.title }) }
    get modalConfirmValidateDescription() { return this.translateService.instant('group.team.cards.validate.description', { user: this.user?.data?.name, content: this.tempContent?.data?.title }); }


    acceptContentConfirm(content) {
        this.teamProvider.validateContentRequest(this.company.id, this.groupId, this.user.data.id, content.data.id).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-subscribed-content'));
        })
    }

    refuseContentConfirm() {
        const params = {
            comment: this.refuseContentReason
        };
        this.teamProvider.rejectContentRequest(this.company.id, this.groupId, this.user.data.id, this.refuseContentData.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.request.deleted'));
        })
    }

    getIterativeCheck(parent: any, selected: any) {
        if (parent.children && parent.children.length) {
            parent.children = parent.children.map((child: any) => {
                if (parent.id === selected.id) {
                    child.isSelected = parent.isSelected;
                }
                return child;
            });
            return parent;
        } else {
            return parent;
        }
    }

    onSelectType(option) {
        option.isSelected = !option.isSelected
        this.availableTypes = this.availableTypes.map(type => this.getIterativeCheck(type, option));
        this.isFilterActive = true;
        this.search();
    }

    onSelectProvider(option) {
        option.isSelected = !option.isSelected
        this.availableProviders = [...this.availableProviders];
        this.isFilterActive = true;
        this.search();
    }
}
