<div class="progress" [ngClass]="{'progress--blue' : color === 'blue', 'progress--orange': color === 'orange', 'progress--theme': color === 'theme'}">
    <div class="progress-content" [ngClass]="{'progress-content--label': label && progress < 100 || force}">
        <div class="progress-content__bar"></div>
        <div class="progress-content__full" [ngStyle]="{'width': progress ? progress + '%' : '0%'}"></div>

        <span class="progress-content__icon" *ngIf="icon && progress === 100">
            <i class="icon icon-checkmark-1"></i>
        </span>
    </div>

    <div class="progress__label" *ngIf="label && progress < 100  || force">
        {{ progress ? progress + '%' : '0%' }}
    </div>
</div>
