import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { catchError, mergeScan, map, tap, repeat, finalize, takeUntil, concatMap } from 'rxjs/operators';
//
import { StatisticsProvider2 } from '@lighty';
import { StorageService, TranslateService } from '@services';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { UserContentProvider } from 'src/app/lighty/models/user-content';
import * as _ from 'lodash';

@Component({
    selector: 'msc-stats-user',
    templateUrl: './stats-user.component.html',
    styleUrls: ['./stats-user.component.scss'],
})

export class CampStatsUserComponent {
    private company: any;
    private me: any;
    private courseId: number;
    private query: string;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public cards: Array<any>;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public rowsTemp$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public hasLoadMore: boolean = false;
    public content: any;
    public user: any;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly statisticsProvider2: StatisticsProvider2,
        private readonly userContentProvider: UserContentProvider,
    ) {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
            this.storageService.setCache('v2UserStats', this.router.getCurrentNavigation().extras.state.user, null, true);
        }
        this.content = this.storageService.getCache('v2ContentStats');
        this.user = this.storageService.getCache('v2UserStats');
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.courseId = this.route.parent.parent.snapshot.params['contextId'];
        this.setCards(this.user);
        this.setContent();
        this.onQueryContentUserStatList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     *
     */
    setCards(user: any): void {
        this.cards = [
            {
                label: this.translateService.instant('words.status'),
                value: this.translateService.instant(user.isSubscribed ? 'words.subscribed' : 'words.unsubscribed'),
                isSubscribed: user.isSubscribed,
                type: 'status',
            },
            {
                label: this.translateService.instant('stats.hour'),
                value: user.duration,
                type: 'duration',
            },
            {
                label: this.translateService.instant('words.progress-average'),
                value: user.chartProgress,
                type: 'chart',
            },
            {
                label: this.translateService.instant('words.score-average'),
                value: user.chartScore,
                type: 'chart',
            },
        ];
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        const baseColumns = [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '30%',
            },
            {
                key: 'time',
                label: this.translateService.instant('stats.hour'),
                width: this.content.context !== 'course' ? '15%' : '20%',
            },
            {
                key: 'progress',
                label: this.translateService.instant('words.progress'),
                width: '25%',
            },
            {
                key: 'result',
                label: this.translateService.instant('words.score'),
                width: '25%',
            },
        ];
        return this.content.context !== 'course' ? [
            ...baseColumns,
            { key: '', width: '5%' }
        ] : baseColumns;
    }

    /**
     *
     */
    getRemappedRowChartColor(datum: any, row: any, key: string) {
        if (!row.id) { return '#6C6BCD'; }
        if (!datum[row.id]?.context[key]) { return '#6C6BCD'; }
        if (!Number.isInteger(datum[row.id].context[key])) { return '#6C6BCD'; }
        return datum[row.id].context[key] !== 100 ? '#6C6BCD' : '#79E2BD';
    }

    /**
     *
     */
    getRemappedRowChartValue(datum: any, row: any, key: string) {
        if (!row.id) { return 0; }
        if (!datum[row.id]?.context[key]) { return 0; }
        if (!Number.isInteger(datum[row.id].context[key])) { return 0; }
        return datum[row.id].context[key];
    }

    /**
     *
     */
    getParamsOnQueryContentUserStatList(pageValue: number) {
        return Object.assign({
            page: pageValue,
        }, this.query ? { q: this.query } : {});
    }

    /**
     *
     */
    getContentApiCall(context, entityId, userId, params) {
        if (context === 'course') {
            return this.userContentProvider.getUserCourse(entityId, userId, params);
        } else if (context === 'traject') {
            return this.userContentProvider.getUserTraject(entityId, userId, params);
        }
    }

    /**
     *
     */
    onQueryContentUserStatList(isAppend = true) {
        this.getContentApiCall(this.content.context, this.courseId, this.user.id, this.getParamsOnQueryContentUserStatList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryContentUserStatList(response.pagination)),
                map((response) => this.onMapOnQueryContentUserStatList(response.contents || response.activities)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rowsTemp$.getValue() : []),
                tap((rows) => this.rowsTemp$.next(rows)),
                concatMap(() => this.onConcatMapOnQueryContentUserStatList()),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentUserStatList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryContentUserStatList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            id: datum.id,
            subscriptionCourseId: datum.subscriptionCourseId,
            label: this.translateService.instant(`stats.content.type-${datum.context}`),
            title: datum.title,
        }));
    }

    /**
     *
     */
    onConcatMapOnQueryContentUserStatList() {
        if (this.content.context === 'course') {
            const ids = this.rowsTemp$.getValue().map((row) => row.id);
            return this.statisticsProvider2
                .getContentSubscriptionActivityList(this.company.id, this.content.id, this.user.id, { context: this.content.context, content_ids: ids })
                .pipe(
                    catchError(() => of([])),
                    tap((datum) => this.onTapOnConcatMapOnQueryContentUserStatList(datum)),
                    finalize(() => this.status$.next(CommonSubjectStatus.SUCCESS)),
                    takeUntil(this.destroy$),
                );
        }

        const contents = this.rowsTemp$.getValue().filter((row) => row.subscriptionCourseId).map((row) => ({ context_id: row.subscriptionCourseId, context: 'course' }));
        // 'TODO Bogdan - check when data is available'
        return this.statisticsProvider2
            .getContentUsersSubscriptionList(this.company.id, this.me.id, { contents })
            .pipe(
                catchError(() => of([])),
                tap((datum) => this.onTapOnConcatMapOnQueryContentUserStatList(datum)),
                finalize(() => this.status$.next(CommonSubjectStatus.SUCCESS)),
                takeUntil(this.destroy$),
            );
    }

    /**
     *
     */
    onTapOnConcatMapOnQueryContentUserStatList(datum: any) {
        const rows = this.rowsTemp$.getValue().map((row) => {
            const subscriptionData = this.content.context === 'course' ? datum[row.id] : _.find(datum, { context: { contextId: row.subscriptionCourseId } });
            return {
                ...row,
                isChartProgressVisible: typeof subscriptionData?.context?.progress === 'number',
                isChartScoreVisible: typeof subscriptionData?.context?.score === 'number',
                chartProgress: subscriptionData?.context?.progress ?? 0,
                chartScore: subscriptionData?.context?.score ?? 0,
                duration: subscriptionData ? subscriptionData?.context?.duration ?? 0 : 0,
            };
        });
        this.rows$.next(rows);
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.query = value || null;
        this.page$.next(1);
        this.onQueryContentUserStatList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryContentUserStatList();
    }

    /**
     *
     */
    goBack(start?: boolean): void {
        const fragment = this.router.url.split('/');
        if (start) {
            fragment.splice(fragment.length - 5, 5);
            fragment.push('content');
        } else {
            fragment.splice(fragment.length - 2, 2);
        }

        this.router.navigate(fragment);
    }

    /**
     *
     */
    goToTemplateDetails(datum: any) {
        this.router.navigate(['template', datum.subscriptionCourseId], { relativeTo: this.route, state: { datum } });
    }
}
