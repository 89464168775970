<div class="container-integration__fieldset form-filter-fieldset col-xs-12 col-9">
    <h1 class="container-integration__fieldset__title">
        {{ 'words.access' | translate }}
    </h1>

    <p class="container-integration__fieldset__description">
        {{ 'integrations.rules.access-description' | translate }}
    </p>

    <ul class="form-filter-fieldset__list">
        <li>
            <div class="flex flex-col space-y-2">
                <msc-common2-radio groupName="accessibility" [(ngModel)]="rule.publicationType"
                    (ngModelChange)="checkNavigation()" [valueName]="'all'"
                    [label]="'integrations.rules.unlimited-access' | translate"></msc-common2-radio>
                <small>{{ 'integrations.rules.unlimited-access-details' | translate }}</small>
            </div>
        </li>

        <li>
            <div class="flex flex-col space-y-2">
                <msc-common2-radio groupName="accessibility" [(ngModel)]="rule.publicationType"
                    (ngModelChange)="checkNavigation()" [valueName]="'camps'"
                    [label]="'integrations.rules.limited-access' | translate"></msc-common2-radio>
            </div>

            <div class="form-filter-fieldset__content" [ngClass]="{ 'active' : rule.publicationType === 'camps' }">
                <div class="form-filter-fieldset__search">
                    <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate"
                        (searchEvent)="search($event.value)"></msc-common-search>
                </div>

                <ul class="form-filter-fieldset__camps flex align-center justify-start wrap">
                    <li class="container-camps__item col-3 col-md-3 col-sm-4 col-xxs-12"
                        *ngFor="let camp of campsCollection">
                        <div class="card-camp card-camp--min" [ngClass]="{'card-camp--checked': camp.checked}"
                            (click)="checkCamp(camp)">
                            <div class="card-camp__content">
                                <div class="content__logo">
                                    <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                                </div>

                                <div class="content__data">
                                    <p [tooltip]="camp.name">{{ camp.name | truncate }}</p>

                                    <div class="data__pictos">
                                        <div class="data__members">
                                            <i class="icon icon-people"></i>
                                            <p>{{ camp.usersCount }}</p>
                                        </div>

                                        <div class="data__sessions">
                                            <i class="icon icon-calendar"></i>
                                            <p>{{ camp.instancesCount }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-camp__radio" *ngIf="camp.checked"></div>
                        </div>
                    </li>
                </ul>

                <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
            </div>
        </li>

        <li>
            <div class="form-filter-fieldset__radio">
                <div class="flex flex-col space-y-2">
                    <msc-common2-radio groupName="accessibility" [(ngModel)]="rule.publicationType"
                        (ngModelChange)="checkNavigation()" [valueName]="'newCamp'"
                        [label]="'integrations.rules.create-camps' | translate"></msc-common2-radio>
                    <small><span>{{ 'integrations.rules.create-camps-details' | translate: {value: currentProviderName +
                            '
                            - ' + rule.name} }}.</span>
                        <span> {{ 'integrations.rules.create-camps-details-second' | translate }}</span></small>
                </div>

                <div class="form-filter-fieldset__radio-margin">
                    <ng-container *ngIf="rule.publicationType === 'newCamp'">
                        <msc-common2-checkbox [value]="rule.inviteAll" [label]="'words.invite-all' | translate"
                            (changeEvent)="rule.inviteAll = !rule.inviteAll"></msc-common2-checkbox>
                    </ng-container>


                    <p class="color--red text-error" *ngIf="rule.inviteAll && rule.publicationType === 'newCamp'">
                        <i class="icon icon-alert-triangle"></i>
                        {{ 'integrations.invite-users-delay' | translate }}
                    </p>
                </div>
            </div>
        </li>
    </ul>
</div>