import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import color from 'color';

/**
 * Theming Service
 */
@Injectable({ providedIn: 'root' })
export class ThemingService {
    // Default color scheme
    private colors = {
        primary: '#26314C',
        secondary: '#F52D55',
        success: '#6CD4A8',
        danger: '#EFC772',
        error: '#F44436',
        info: '#8290FD'
    };
    private palettes = [];

    constructor(@Inject(DOCUMENT) private document: Document) {}

    getColors() {
        return this.colors;
    }

    getPalettes() {
        return this.palettes;
    }

    apply(colors?) {
        this.colors = { ...this.colors, ...colors };
        this.palettes = [];

        // Aplica los colores originales como variables CSS antes de generar las variaciones
        this.setCssVariablesForOriginals(this.colors);

        // Genera paletas de variaciones de luminosidad para todos los colores
        for (const color in this.colors) {
            this.palettes.push(this.generatePalette(color, this.colors[color]));
        }

        // Aplica las paletas generadas como variables CSS
        this.setCssVariables(this.palettes);
    }

    private setCssVariables(palettes) {
        palettes.forEach((palette) => {
            for (const variation in palette.map) {
                this.document.documentElement.style.setProperty(
                    `--v2-color-${palette.name}-${variation}`,
                    palette.map[variation].c
                );
            }
        });
    }

    private setCssVariablesForOriginals(colors) {
        // Establece las variables CSS para los colores originales sin modificaciones
        for (const colorName in colors) {
            this.document.documentElement.style.setProperty(
                `--v2-color-${colorName}-500`,
                colors[colorName]
            );
        }
    }


    generatePalette(colorName, value) {
        const palette = {
            name: colorName,
            map: {}
        };

        const maxLightness = 98;
        const minDarkness = 8;
        const minDifference = 5; // Diferencia mínima entre niveles de luminosidad

        for (let i = 1; i <= 9; i++) {
            let c;
            try {
                c = color(value);

                if (c.hex().toUpperCase() === '#FFFFFF') {
                    throw new Error('White color detected');
                }
            } catch (e) {
                c = color('#949494');
            }

            c = c.hsl();
            let lightness = c.color[2];
            const isVeryDark = lightness < 5;

            if (colorName === 'primary') {
                if (i <= 5) {
                    if (isVeryDark) {
                        lightness = Math.max(minDarkness, Math.min(maxLightness, lightness + ((5 - i) * 20)));
                    } else {
                        lightness = Math.max(minDarkness, Math.min(maxLightness, lightness + ((5 - i) * 19)));
                    }
                } else {
                    if (isVeryDark) {
                        lightness = Math.max(minDarkness, Math.min(100, lightness - ((i - 5) * 3))); // Ajuste más pronunciado
                    } else {
                        lightness = Math.max(minDarkness, Math.min(100, lightness - ((i - 5) * 10))); // Ajuste más pronunciado
                    }
                }
            } else {
                if (i <= 5) {
                    if (isVeryDark) {
                        lightness = Math.max(minDarkness, Math.min(maxLightness, lightness + ((5 - i) * 20)));
                    } else {
                        lightness = Math.max(minDarkness, Math.min(maxLightness, lightness + ((5 - i) * 10.6)));
                    }
                } else {
                    if (isVeryDark) {
                        lightness = Math.max(minDarkness, Math.min(100, lightness - ((i - 5) * 3))); // Ajuste más pronunciado
                    } else {
                        lightness = Math.max(minDarkness, Math.min(100, lightness - ((i - 5) * 6))); // Ajuste más pronunciado
                    }
                }
            }

            // Comprobar y ajustar si la luminosidad es demasiado cercana al valor anterior
            if (i > 1) {
                const prevLightness = palette.map[(i - 1) * 100].c;
                const prevColor = color(prevLightness).hsl().color[2];
                if (Math.abs(lightness - prevColor) < minDifference) {
                    lightness = prevColor + minDifference * Math.sign(lightness - prevColor);
                }
            }

            // Asignar el valor de color al mapa de paleta con el nivel de variación correspondiente
            c.color[2] = lightness;
            palette.map[i * 100] = {
                c: c.hex()
            };
        }

        return palette;
    }


    getVariationFromColor(colorHex: string, variation: number): string {
        let c;
        try {
            c = color(colorHex);
        } catch (e) {
            c = color('#808080'); // fallback - gray hex
        }
        c = c.hsl();
        c.color[2] = Math.max(0, Math.min(100, c.color[2] + (variation - 500) / 10.5)); // Ajusta la luminosidad en pasos de 10%

        return c.hex();
    }
}





