import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AdminTemplateGroupProvider {

    constructor(private http: HttpClient) {}

    getGroups(campId: number, courseCampId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${campId}/admin/course-camp/${courseCampId}/group`, {params}).pipe(
            map(data => data)
        );
    }

    getAvailableGroups(campId: number, courseCampId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${campId}/admin/course-camp/${courseCampId}/available-groups`, {params}).pipe(
            map(data => data)
        );
    }

    addGroup(campId: number, courseCampId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/camp/${campId}/admin/course-camp/${courseCampId}/group`, params).pipe(
            map(data => data)
        );
    }

    deleteGroup(campId: number, courseCampId: number, groupId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/camp/${campId}/admin/course-camp/${courseCampId}/group/${groupId}`).pipe(
            map(data => data)
        );
    }
}
