<div class="container-integration">
    <div class="container-integration__main row">
        <div class="container-integration__back">
            <a class="link link-back" [routerLink]="'../'">
                <span>{{ "words.integration-list" | translate }}</span>

                <i class="icon icon-arrow-ios-up bg-secondary-600"></i>
            </a>
        </div>

        <header
            class="container-integration__header box col-12 no-gutter flex align-center between"
        >
            <div
                class="container-integration__title flex align-center justify-start col-gap-xs"
            >
                <ng-container
                    *ngIf="integration.mediaUrl; else defaultTemplate"
                >
                    <div
                        class="container-integration__picture"
                        [style.backgroundImage]="integration.mediaUrl"
                    ></div>
                </ng-container>
                <ng-template #defaultTemplate>
                    <div
                        class="container-integration__picture"
                        [style.backgroundImage]="
                            'url(https://static.myskillcamp.com/images/integrations/' +
                            integration.key +
                            '-picto.svg)'
                        "
                    ></div>
                </ng-template>

                <h2 class="container-integration__provider">
                    {{ integration.key }}
                </h2>
            </div>

            <div
                class="container-integration__actions flex align-center justify-end col-gap-xs"
                *ngIf="integration.companyProvider?.status"
            >
                <div
                    class="container-integration__deploy"
                    *ngIf="integration.companyProvider?.deploymentRunning"
                >
                    <div class="spinner-lines spinner-lines--orange">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <span>{{ "integrations.ongoing.update" | translate }}</span>
                </div>

                <div
                    class="flex items-center space-x-5 justify-end col-gap-xs"
                    *ngIf="
                        !integration.companyProvider?.catalogueFetching &&
                        !integration.companyProvider?.deploymentRunning
                    "
                >
                    <i
                        class="icon icon-checkmark"
                        *ngIf="integration.companyProvider?.deploymentDoneAt"
                    ></i>

                    <span *ngIf="integration.companyProvider?.deploymentDoneAt"
                        >{{ "integrations.last.update" | translate }} :
                        {{
                            integration.companyProvider?.deploymentDoneAt
                                | date : "dd/MM/YYYY HH:mm"
                        }}</span
                    >

                    <div
                        class="container-integration__deploy__alert"
                        [tooltip]="'integrations.catalogue.tooltip' | translate"
                        direction="bottom"
                        *ngIf="isSync()"
                    >
                        <i class="icon icon-alert-circle"></i>
                    </div>

                    <msc-button
                        class="msc-button--primary"
                        palette="primary"
                        (click)="modalUpdateCatalogue.onOpen()"
                        *ngIf="integration.companyProvider?.deploymentRequired"
                    >
                        {{ "integrations.update.catalogue" | translate }}
                    </msc-button>
                </div>

                <div
                    class="container-integration__deploy"
                    *ngIf="integration.companyProvider?.catalogueFetching"
                >
                    <span>
                        {{ "integrations.catalogue.fetching" | translate }}
                    </span>

                    <div
                        class="container-integration__deploy__alert"
                        [tooltip]="
                            'integrations.catalogue.fetching.tooltip'
                                | translate
                        "
                        direction="bottom"
                    >
                        <i class="icon icon-alert-circle"></i>
                    </div>
                </div>

                <div
                    class="container-integration__nav"
                    *ngIf="integration.companyProvider && allowNavigation"
                >
                    <a (click)="navigateThroughProvider(-1)">
                        <i class="icon icon-chevron-left"></i>
                    </a>

                    <a (click)="navigateThroughProvider(1)">
                        <i class="icon icon-chevron-right"></i>
                    </a>
                </div>
            </div>
        </header>

        <aside
            class="container-integration__aside col-xs-12 col-md-3 col-3"
            *ngIf="showMenu"
        >
            <div class="navigation-integration">
                <ul class="navigation-integration__content">
                    <li
                        class="navigation-integration__item"
                        [routerLink]="'settings'"
                        [routerLinkActive]="'active'"
                    >
                        <p>{{ "words.settings" | translate }}</p>
                    </li>

                    <ng-container
                        *ngIf="
                            integration.companyProvider?.status &&
                            !integration.companyProvider?.catalogueFetching &&
                            integration.key != 'xertify'
                        "
                    >
                        <li
                            class="navigation-integration__item"
                            [routerLink]="[
                                'collections',
                                collection?.collectionId,
                                'catalogue-export'
                            ]"
                            [routerLinkActive]="'active'"
                            *ngIf="
                                !integration.companyProvider?.catalogueFetching
                            "
                        >
                            <p>{{ "words.catalogue-overview" | translate }}</p>
                        </li>

                        <li
                            *ngIf="integration.companyProvider"
                            class="navigation-integration__item flex"
                            [routerLink]="[
                                'collections',
                                collection?.collectionId,
                                'rules'
                            ]"
                            [routerLinkActive]="'active'"
                        >
                            <div class="spinner" *ngIf="loader.collection">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <p>{{ "words.rules" | translate }}</p>
                        </li>

                        <li
                            *ngIf="integration.companyProvider"
                            class="navigation-integration__item"
                            [routerLink]="'skills'"
                            [routerLinkActive]="'active'"
                        >
                            <p>{{ "words.skills" | translate }}</p>
                        </li>

                        <li class="navigation-integration-sub">
                            <ul>
                                <li
                                    class="navigation-integration-sub__item"
                                    [routerLink]="'skills/mapping'"
                                    [routerLinkActive]="'active'"
                                >
                                    <p>
                                        {{
                                            "integrations.rules.matching"
                                                | translate
                                        }}
                                    </p>
                                </li>

                                <li
                                    class="navigation-integration-sub__item"
                                    [routerLink]="'skills/exception'"
                                    [routerLinkActive]="'active'"
                                >
                                    <p>
                                        {{
                                            "integrations.rules.exception.skills"
                                                | translate
                                        }}
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ng-container>

                    <!--                    <li *ngIf="integration.companyProvider" class="navigation-integration__item flex" [routerLink]="'collections/' + collection?.collectionId + '/stats'" [routerLinkActive]="'active'">-->
                    <!--                        <div class="spinner" *ngIf="loader.collection">-->
                    <!--                            <span></span>-->
                    <!--                            <span></span>-->
                    <!--                            <span></span>-->
                    <!--                            <span></span>-->
                    <!--                        </div>-->

                    <!--                        <p>{{ 'words.stats' | translate }}</p>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </aside>

        <router-outlet></router-outlet>
    </div>
</div>

<msc-common-modal
    size="30vw"
    #modalUpdateCatalogue
    [title]="'integrations.update.catalogue' | translate"
>
    <ng-template>
        <p>
            {{ "integrations.modal.description.update.catalogue" | translate }}
        </p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button
                class=""
                palette="primary"
                (click)="modalUpdateCatalogue.onClose()"
                >{{ "common.cancel" | translate }}</msc-button
            >
            <msc-button
                class="msc-button--primary"
                palette="primary"
                (click)="updateCatalogue(); modalUpdateCatalogue.onClose()"
                >{{ "common.save" | translate }}</msc-button
            >
        </div>
    </ng-template>
</msc-common-modal>
