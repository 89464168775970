<h3 class="title col-gap-xs">
    <span>{{ 'url.title' | translate }}</span>
    <ng-container *ngIf="indicator$ | async as indicator">
        <div class="indicator ">
            <span>{{ 'url.available' | translate }}</span>
            <span>&nbsp;&#58;&nbsp;</span>
            <div class="numbers col-gap-xs">
                <span>{{ indicator.label }}</span>
                <ng-container *ngIf="indicator.isLimitReached">
                    <msc-button type="fab" palette="danger" icon="icon-alert-triangle-1"></msc-button>
                    <msc-button class="msc-button--primary" palette="primary" (click)="modalNotify.onOpen()">{{'url.increase.request' | translate}}</msc-button>
                </ng-container>
            </div>
        </div>
    </ng-container>
</h3>

<div class="grouping">
    <p class="description">
        <span>{{ 'url.encryption.description' | translate }}</span>&nbsp;
        <a class="guide" href="https://letsencrypt.org/how-it-works/" target="_blank"><span>{{ 'words.see-more' | translate }}</span>&#46;</a>
    </p>
</div>

<ng-container *ngIf="(items$ | async).length; else emptyTemplate">
    <div class="grouping margin-top-20">
        <msc-common-list
            [data]="items$ | async"
            [isLoading]="status$ | async"
            [emptyMessage]="'empty.available.data' | translate">
            <ng-template #item let-datum="datum">
                <msc-organize-experience-watch-url-item
                    [datum]="datum"
                    (activate)="onActivate(datum)"
                    (edit)="goToDetails(datum.id)">
                </msc-organize-experience-watch-url-item>
            </ng-template>
        </msc-common-list>
    </div>
</ng-container>

<ng-template #emptyTemplate>
    <!-- TODO -->
</ng-template>

<ng-container *ngIf="!(indicator$ | async)?.isLimitReached">
    <div class="grouping margin-top-20">
        <msc-button class="msc-button--primary" palette="primary" (click)="goToCreate()">{{'url.create.url' | translate}}</msc-button>
    </div>
</ng-container>

<msc-common-modal size="30vw" #modalNotify [title]="'theme.send-request' | translate">
    <ng-template>
        <p>{{ 'url.modal.request.desc' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalNotify.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onNotify(modalNotify); modalNotify.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
