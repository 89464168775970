import { ConnectedPosition, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ChangeDetectionStrategy, ViewChild, Output, EventEmitter, ContentChild, TemplateRef, ViewContainerRef, ElementRef, Input, EmbeddedViewRef } from '@angular/core';
export type CommonOverlayDirection = 'top-start' | 'top-end' | 'right-start' | 'right-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end' | 'list';
//
export const CommonOverlayPositionMapping = new Map<CommonOverlayDirection, ConnectedPosition>([
    ['top-start', { originX: 'center', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -8 }],
    ['top-end', { originX: 'center', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetY: -8 }],
    ['right-start', { originX: 'end', originY: 'center', overlayX: 'end', overlayY: 'bottom', offsetX: 16 }],
    ['right-end', { originX: 'end', originY: 'center', overlayX: 'end', overlayY: 'top', offsetX: 16 }],
    ['bottom-start', { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: 8 }],
    ['bottom-end', { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetY: 8 }],
    ['left-start', { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'bottom', offsetX: -16 }],
    ['left-end', { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'top', offsetX: -16 }],
    ['list', { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top', offsetY: 8, offsetX: 12 }],
]);

@Component({
    selector: 'msc-common-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
})

export class CommonOverlayComponent {
    @Input() direction: CommonOverlayDirection = 'top-end';
    @Input() closeOnClickOutside = true;
    @Input() root = [];
    @ContentChild(TemplateRef) content;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('data') data;
    public isOpen = false;
    private overlayRef: OverlayRef;
    public templateRef: EmbeddedViewRef<any>;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly overlay: Overlay,
        private readonly overlayPositionBuilder: OverlayPositionBuilder,
        private _viewContainerRef: ViewContainerRef,
    ) { }

    /**
     * Set the overlay
     */
    setOverlay(origin: ElementRef): void {
        this.overlayRef = this.overlay.create(this.getConfig(origin));
    }

    /**
     * Get the config
     */
    getConfig(origin: ElementRef): OverlayConfig {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(origin)
            .setOrigin(origin)
            .withPositions([CommonOverlayPositionMapping.get(this.direction)])
            .withFlexibleDimensions(false)

        return {
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        };
    }

    ngOnDestroy(): void {
        this.overlayRef?.detach();
    }

    /**
     * Event handler for hide
     */
    close(): void {
        if (this.overlayRef?.hasAttached()) {
            this.overlayRef.detach();
            this.templateRef = undefined;
            this.isOpen = false;
            this.onClose.emit(true);
        }
    }

    /**
     * Event handler for show
     */
    open(origin: ElementRef): EmbeddedViewRef<any> {
        if (this.overlayRef?.hasAttached()) {
            return;
        }
        this.setOverlay(origin);
        this.templateRef = this.overlayRef.attach(new TemplatePortal(this.data, this._viewContainerRef));
        this.isOpen = true;
        return this.templateRef;
    }
}
