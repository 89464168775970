import { Component, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UrlHelper } from '@helpers';
import * as moment from 'moment';
import { StorageService } from '@services';

@Component({
    selector: 'msc-player-light-modal-iframe',
    templateUrl: './modal-iframe.component.html'
})

export class PlayerLightModalIframeComponent {
    private timerId;
    private internalTimer: number = 0;
    private preview: SafeResourceUrl;
    public onClose: EventEmitter<boolean> = new EventEmitter();
    public onSave: EventEmitter<any> = new EventEmitter();
    public active: boolean = false;
    public content: any;
    public data: any;
    public type: any;
    public options: any = {
        actionEnable: true
    };
    public progressEnabled: boolean = true;

    constructor(
        private sanitizer: DomSanitizer,
        private storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.active = true;

        this.data = {
            date: moment().format('YYYY-MM-DD'),
            unit: 'minutes',
            duration: 0,
            progress: 0,
            context: this.content.type === 'video' ? 'video' : 'content',
            company_id: this.storageService.get('company').id
        };

        this.timerId = setInterval(() => {
            this.internalTimer += 1;
            this.processTimer();
        }, 60000);

        if (this.content.progress === 100) {
            this.options.actionEnable = false;
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.timerId);
    }

    close(): void {
        this.active = false;
        this.onClose.emit(true);
    }

    progress(): void {
        this.transformDuration();
        this.onSave.emit({ type: 'progress', content: this.data });
    }

    finished(): void {
        this.transformDuration();
        this.data.progress = 100;

        this.onSave.emit({ type: 'finished', content: this.data });
    }

    getUrl(): SafeResourceUrl {
        return this.preview ? this.preview : this.preview = this.sanitizer.bypassSecurityTrustResourceUrl(UrlHelper.parse(this.content.url));
    }

    transformDuration(): void {
        if (this.data.duration) {
            let multiply = 60;
            if (this.data.unit === 'hours') {
                multiply *= 60;
            } else if (this.data.unit === 'day') {
                multiply *= 60 * 24;
            }

            this.data.duration *= multiply;
        }
    }

    processTimer(): any {
        if (this.internalTimer) {
            if (this.internalTimer > 60) {
                this.data.unit = 'hours';
                this.data.duration = 1;
            } else if (this.internalTimer > (60 * 60 * 24)) {
                this.data.unit = 'day';
                this.data.duration = 1;
            } else {
                this.data.duration += 1;
            }
        }
    }
}
