import { Injectable } from '@angular/core';
import { Correction, CorrectionExercise, CorrectionProject, CorrectionSelfAssessment } from './../../models/correction';

@Injectable()
export class CorrectionFactory {

    constructor() {}

    get(data: any, context: string, title: string, description: string): Correction {
        switch (context) {
            case 'exercise':
                return new CorrectionExercise(data, title);
            case 'project':
                return new CorrectionProject(data, title, description);
            case 'selfassessment':
                return new CorrectionSelfAssessment(data, title, description);
        }
    }
}
