import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService, StorageService } from '@services';
import { Pagination, CompanyProvider } from '@lighty';
import { HttpHelper } from '@helpers';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'msc-organize-integration-watch-rules-edit-accessibility-panel',
    templateUrl: './accessibility-panel.component.html'
})
export class OrganizeIntegrationWatchRulesEditAccessibilityPanelComponent implements OnInit {
    @Input() rule: any;
    @Output() allowNavigation: EventEmitter<any> = new EventEmitter<any>();
    public currentProviderName: string;
    public campsCollection: any[];
    public pagination: Pagination;
    public loading: boolean = false;
    public query: string;

    constructor(private route: ActivatedRoute, private companyProvider: CompanyProvider,
                private storageService: StorageService, private loaderService: LoaderService) {}

    ngOnInit(): void {
        this.currentProviderName = this.storageService.get('currentRuleProvider').name;
        this.allowNavigation.emit(true);

        if (!this.rule.publicationType) {
            this.rule.publicationType = 'all';
        }
        if (!this.rule.camps) {
            this.rule.camps = [];
        }

        this.rule.publicationType.length <= 0 ? this.rule.publicationType = 'all' : '';
        this.getCamps();
        this.checkNavigation();
    }

    private getCamps(): void {
        this.companyProvider.getCamps(this.storageService.get('company').id).subscribe((data) => {
            this.campsCollection = data.camps;
            for (const camp of this.campsCollection) {
                if (this.rule.camps.filter(ruleCamp => camp.id === ruleCamp.id).length > 0) {
                     camp.checked = true;
                }
            }
            this.pagination = data.pagination;
        });
    }

    loadMore(): void {
        this.loaderService.run();
        this.loading = true;
        const params = {
            page: this.pagination.currentPage + 1
        };

        this.companyProvider.getCamps(this.storageService.get('company').id, params).subscribe((data) => {
            this.campsCollection = this.campsCollection.concat(data.camps);
            this.processRules();
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.companyProvider.getCamps(this.storageService.get('company').id, params).subscribe((data) => {
            this.campsCollection = data.camps;
            this.pagination = data.pagination;
            this.processRules();
        });
    }

    private processRules(): void {
        for (const camp of this.campsCollection) {
            if (this.rule.camps.filter(ruleCamp => camp.id === ruleCamp.id).length > 0) {
                camp.checked = true;
            }
        }
    }

    checkCamp(camp: any): void {
        camp.checked = !camp.checked;
        if (camp.checked) {
            this.rule.camps.push(camp);
        } else {
            const index = this.rule.camps.indexOf(camp);
            this.rule.camps.splice(index, 1);
        }
        this.checkNavigation();
    }

    checkNavigation(): void {
        if (this.rule.publicationType === 'camps') {
            if (this.rule.camps.length > 0) {
                this.allowNavigation.emit(true);
            } else {
                this.allowNavigation.emit(false);
            }
        } else {
            this.allowNavigation.emit(true);
        }
    }
}
