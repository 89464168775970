import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Camp, Company, CompanyUserAccessProvider } from '@lighty';
import { EmitterService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-account-access-learn',
    templateUrl: './learn.component.html'
})

export class AccountAccessLearnComponent {
    private accountId: number;
    public company: Company;
    public camps: Camp[];
    public availableCamps: Camp[];
    public user: any;
    public query: string;

    constructor(
        private route: ActivatedRoute,
        private companyUserAccessProvider: CompanyUserAccessProvider,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.route.parent.parent.snapshot.data.company;
        this.camps = this.route.snapshot.data.access.camps;
        this.availableCamps = this.route.snapshot.data.access.availableCamps;
        this.user = this.route.parent.parent.snapshot.data.user;

        this.route.parent.parent.params.subscribe((params) => {
            this.accountId = parseInt(params.accountId, 10);
        });
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            q1: this.query,
            q2: this.query
        });

        this.companyUserAccessProvider.getLearn(this.company.id, params).subscribe((data) => {
            this.camps = data.camps;
            this.availableCamps = data.availableCamps;
        });
    }

    addCamp(camp: Camp): void {
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            camp_id: camp.id
        });

        this.companyUserAccessProvider.addCamps(this.company.id, params, 'learner').subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.camps.push(camp);

            const index = this.availableCamps.findIndex((data) => {
                return data.id === camp.id;
            });

            if (index !== -1) {
                this.availableCamps.splice(index, 1);
            }

            this.emitAccess();
        });
    }

    removeCamp(camp: Camp): void {
        const params = HttpHelper.cleanParams({
            account_id: this.accountId,
            camp_id: camp.id
        });

        this.companyUserAccessProvider.removeCamp(this.company.id, params, 'learner').subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.availableCamps.push(camp);

            const index = this.camps.findIndex((data) => {
                return data.id === camp.id;
            });

            if (index !== -1) {
                this.camps.splice(index, 1);
            }

            this.emitAccess();
        });
    }

    emitAccess(): void {
        if (this.camps.length === 0) {
            this.emitterService.set('update.access', { role: 'companyLearner', enable: false });
        } else if (this.camps.length === 1) {
            this.emitterService.set('update.access', { role: 'companyLearner', enable: true });
        }
    }

    disableAccess(): void {
        this.user.companyRoles.companyLearner = false;
        this.emitterService.set('update.access', { role: 'companyLearner', enable: this.user.companyRoles.companyLearner });
        this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        this.query = null;
        this.search();
    }
}
