import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { environment } from '@env/environment';

@Injectable()
export class AdminCorrectionProvider {

    constructor(private http: HttpClient) {}

    get(params: any): Observable<any> {
        const questions = {
            count: {},
            pagination: {},
            sessions: []
        };

        const projects = {
            count: {},
            pagination: {},
            sessions: []
        };

        const selfAssessments = {
            count: {},
            pagination: {},
            sessions: []
        };

        return forkJoin(
            this.http.get(`${environment.envVar.API_URL}/admin/corrections?type=question`, {params}).pipe(
                map((data: any) => {
                    questions.count = data.count;
                    questions.pagination = new Pagination(data.pagination);
                    questions.sessions = data.sessions;
                    return questions;
                })
            ),
            this.http.get(`${environment.envVar.API_URL}/admin/corrections?type=project`, {params}).pipe(
                map((data: any) => {
                    projects.count = data.count;
                    projects.pagination = new Pagination(data.pagination);
                    projects.sessions = data.sessions;

                    return projects;
                })
            ),
            this.http.get(`${environment.envVar.API_URL}/admin/corrections?type=selfassessment`, {params}).pipe(
                map((data: any) => {
                    selfAssessments.count = data.count;
                    selfAssessments.pagination = new Pagination(data.pagination);
                    selfAssessments.sessions = data.sessions;

                    return selfAssessments;
                })
            )
        );
    }

    getCorrections(params: any): Observable<any> {
        const response = {
            pagination: {},
            sessions: []
        };

        return this.http.get(`${environment.envVar.API_URL}/admin/corrections`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.sessions = data.sessions.map((session) => {
                    return session;
                });

                return response;
            })
        );
    }

    getAnswer(type: string, id: number, answerId: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/${type}/${id}/answers/${answerId}/correction`, {params}).pipe(
            map(data => data)
        );
    }

    update(type: string, id: number, answerId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/${type}/${id}/answers/${answerId}/correction`, params).pipe(
            map(data => data)
        );
    }
}
