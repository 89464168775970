<div class="showroom-container">
    <div class="demo">
        <div class="input-type">
            <div class="type-name">
                <h1>Basic tag</h1>
            </div>
            <div class="type-content">
                <msc-common-tag label="Basic tag" palette="primary"></msc-common-tag>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                <h1>Tag with icon</h1>
            </div>
            <div class="type-content">
                <msc-common-tag label="Basic tag" icon="icon-bookmark" palette="primary"></msc-common-tag>
                <msc-common-tag label="Basic tag" icon="icon-award" hasRemoveButton="true" palette="primary"></msc-common-tag>
                <msc-common-tag label="Basic tag" icon="icon-bulb" palette="primary"></msc-common-tag>
                <msc-common-tag label="Basic tag" icon="icon-plus-circle" hasRemoveButton="true" palette="primary"></msc-common-tag>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                <h1>Tag with image</h1>
            </div>
            <div class="type-content">
                <msc-common-tag label="Basic tag" pictureURL="https://yt3.ggpht.com/ytc/AKedOLTlGVLrXzgZDwItF-m8Tux0NF5II9C-TIa6HgIalg=s900-c-k-c0x00ffffff-no-rj"></msc-common-tag>
                <msc-common-tag label="Basic tag" pictureURL="https://yt3.ggpht.com/ytc/AKedOLTlGVLrXzgZDwItF-m8Tux0NF5II9C-TIa6HgIalg=s900-c-k-c0x00ffffff-no-rj" hasRemoveButton="true"></msc-common-tag>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                <h1>Tag outline</h1>
            </div>
            <div class="type-content">
                <msc-common-tag class="msc-tag--outline" label="Basic tag" icon="icon-heart" palette="primary"></msc-common-tag>
                <msc-common-tag class="msc-tag--outline" label="Basic tag" icon="icon-award" hasRemoveButton="true" palette="primary"></msc-common-tag>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                <h1>Tag custom</h1>
            </div>
            <div class="type-content">
                <msc-common-tag label="Basic tag" icon="icon-bulb" palette="slate"></msc-common-tag>
                <msc-common-tag label="Basic tag" icon="icon-heart" palette="success"></msc-common-tag>
                <msc-common-tag label="Basic tag" icon="icon-award" hasRemoveButton="true" palette="danger"></msc-common-tag>
                <msc-common-tag label="Basic tag" pictureURL="https://yt3.ggpht.com/ytc/AKedOLTlGVLrXzgZDwItF-m8Tux0NF5II9C-TIa6HgIalg=s900-c-k-c0x00ffffff-no-rj" hasRemoveButton="true" palette="error"></msc-common-tag>
                <msc-common-tag label="Basic tag" icon="icon-twitter" hasRemoveButton="true" palette="info"></msc-common-tag>
            </div>
        </div>
    </div>
</div>
