export class Integration {
    id?: number;
    key?: string;
    name: string;
    mediaId?: number;
    picture?: string;
    url?: string;
    type?: string;
    source?: string;
    lti?: any;
    isConnectable?: boolean;
    params?: any;
    permission?: any;
    companyProvider?: any;
    synchronizedAt?: any;

    constructor(integration: object) {
        this.assign(integration);
    }

    assign(integration: object): void {
        Object.assign(this, integration);
    }
}
