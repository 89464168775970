<div class="pictogram">
    <svg width="102" height="101" viewBox="0 0 102 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.3851 16.2867C25.3851 16.2867 40.6607 27.8785 63.8835 6.2038C84.5077 -13.0454 101.347 17.3255 101.465 31.8694C101.618 50.7099 80.8411 65.7844 90.924 78.1591C101.007 90.5338 70.9282 110.968 54.7172 93.2835C34.5514 71.2844 29.0881 89.1587 17.5938 89.1587C9.3441 89.1587 -7.59332 68.6604 3.84435 53.4102C13.469 40.5774 8.22005 36.3184 5.67756 31.8694C2.01108 25.453 10.7191 8.03707 25.3851 16.2867Z" [attr.fill]="colors[palette][200]" />
        <path d="M50.1016 42.6413V85.6575L16.7031 71.2317L16.8625 28.3711L50.1016 42.6413Z" [attr.fill]="colors[palette][600]" />
        <path d="M50.1016 42.6664V85.6825L83.3375 71.7551V28.5078L50.1016 42.6664Z" [attr.fill]="colors[palette][300]" />
        <path d="M50.1016 42.6421L83.5 28.6532L50.3638 13.9648L16.7031 28.3035L50.1016 42.6421Z" [attr.fill]="colors[palette][500]" />
        <path d="M29.2969 22.9416L62.5679 37.4232L62.8702 47.9765L72.179 44.1354L71.8975 33.5156L37.6422 19.3867L29.2969 22.9416Z" [attr.fill]="colors[palette][600]" />
        <path d="M60.6657 58.1026C61.1931 58.1026 61.6205 57.3224 61.6205 56.3599C61.6205 55.3974 61.1931 54.6172 60.6657 54.6172C60.1384 54.6172 59.7109 55.3974 59.7109 56.3599C59.7109 57.3224 60.1384 58.1026 60.6657 58.1026Z" [attr.fill]="colors.white" />
        <path d="M76.0485 51.8331C76.5759 51.8331 77.0033 51.0528 77.0033 50.0904C77.0033 49.1279 76.5759 48.3477 76.0485 48.3477C75.5212 48.3477 75.0938 49.1279 75.0938 50.0904C75.0938 51.0528 75.5212 51.8331 76.0485 51.8331Z" [attr.fill]="colors.white" />
        <path d="M66.2059 67.3033L65.5859 67.0625C66.9596 63.5259 68.6822 61.5776 70.706 61.2717C72.642 60.9791 74.2727 62.3129 74.8645 63.0589L74.3434 63.4721C73.9341 62.9561 72.5013 61.6731 70.8056 61.9294C69.0594 62.1932 67.4689 64.0515 66.2059 67.3033Z" [attr.fill]="colors.white" />
    </svg>
</div>

<ng-container *ngIf="message">
    <h4>{{ message }}</h4>
</ng-container>

<ng-container *ngIf="bodyTemplate">
    <div class="content">
        <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>
</ng-container>
