import { Correction } from './correction';

export class CorrectionSelfAssessment extends Correction {
    constructor(data: any, title: string, description: string) {
        super(data, title);
        this.tag = 'words.selfassessment';
        this.timelineContext = 'selfassessment-answer';
        this.description = description;
        this.answer = data.answer;
        this.answerMedias = this.medias || [];
        this.ratio = data.score;
    }
}