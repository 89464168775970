import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';

import { CompanySenderProvider, ApiCompanySenderItem } from '@lighty';
import { StorageService, ExternalAppsService, TranslateService } from '@services';

import { ComponentSmartFormModel } from '@models2/component-smart-form.model';

import { UiCompanySenderItem, getRemappedCompanySenderApiToUi } from '../mail-item/mail-item.component';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-experience-watch-mail-edit',
    templateUrl: './mail-edit.component.html',
    styleUrls: ['./mail-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchMailEditComponent extends ComponentSmartFormModel<any> {
    public sender$: Observable<UiCompanySenderItem>;

    constructor(
        protected readonly ref: ChangeDetectorRef,
        protected readonly fb: FormBuilder,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly companySenderProvider: CompanySenderProvider,
        protected readonly storageService: StorageService,
        protected readonly toastService: CommonToastService,
        protected readonly externalAppsService: ExternalAppsService,
        protected readonly translateService: TranslateService,
    ) {
        super();
    }

    /**
     * Sets the component observables
     */
    setObs(): void {
        this.sender$ = this.companySenderProvider
            .getSender(this.company.id, this.route.snapshot.params['senderId'])
            .pipe(
                map((sender: ApiCompanySenderItem) => this.onMapGetSender(sender)),
                tap((sender: UiCompanySenderItem) => this.onTapGetSender(sender)),
                takeUntil(this.destroy$),
            );
    }

    /**
     * Get a form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'name': ['', [Validators.required]],
        });
    }

    /**
     * Resolves map functionality on getSender
     */
    onMapGetSender(sender: ApiCompanySenderItem): UiCompanySenderItem {
        return getRemappedCompanySenderApiToUi(sender, this.companyCurrentExperience);
    }

    /**
     * Resolves tap functionality on getSender
     */
    onTapGetSender(sender: UiCompanySenderItem): void {
        this.setFormGroup({ name: sender.name });
    }

    /**
     * Query init data
     */
    onQuery(): void {
        // leave it empty
    }

    /**
     * Event handler for sender edit
     */
    onEdit(sender: ApiCompanySenderItem): void {
        this.companySenderProvider
            .editSender(this.company.id, sender.id, this.form.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onEditSuccess(),
                (error: HttpErrorResponse) => this.onEditError(error),
            )
    }

    /**
     * Handler for success on onEdit
     */
    onEditSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.edit.success'));
        this.goToList();
    }

    /**
     * Handler for error on onEdit
     */
    onEditError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.edit.error', error.message));
    }

    /**
     * Resolves navigation to list section
     */
    goToList(): void {
        this.router.navigate(['../..', 'list'], { relativeTo: this.route });
    }

    /**
     * Resolves navigation to details section
     */
    goToDetails(id: number): void {
        this.router.navigate(['../..', 'details', id], { relativeTo: this.route });
    }
}
