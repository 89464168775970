<div class="content-params content-params-functionalities row no-gutter">
    <h3 class="content-params__title">
        {{ 'features.list' | translate }}
    </h3>

    <div class="content-params__list">
        <div class="content-params__notifications box box-notification flex between align-center col-4 col-md-4 col-xs-12">
            <msc-common2-checkbox [value]="camp.settings.catalogue" [label]="'words.catalogue' | translate" (changeEvent)="camp.settings.catalogue = !camp.settings.catalogue; update()"></msc-common2-checkbox>
            <div class="box-notification__actions">
                <a [tooltip]="'words.settings' | translate" (click)="campCatalogueAside.onOpen()">
                    <i class="icon icon-settings-2"></i>
                </a>
            </div>
        </div>

        <div class="content-params__notifications box box-notification flex between align-center col-4 col-md-4 col-xs-12">
            <div class="box-notification__checkbox">
                <msc-common2-checkbox [value]="camp.settings.groups" [label]="'words.groups' | translate" (changeEvent)="camp.settings.groups = !camp.settings.groups; update()"></msc-common2-checkbox>
            </div>
        </div>

        <div class="content-params__notifications box box-notification flex between align-center col-4 col-md-4 col-xs-12">
            <div class="box-notification__checkbox">
                <msc-common2-checkbox [value]="camp.settings.discussionEnabled" [label]="'words.conversation' | translate" (changeEvent)="camp.settings.discussionEnabled = !camp.settings.discussionEnabled; update()"></msc-common2-checkbox>
            </div>
        </div>

        <div class="content-params__notifications box box-notification flex between align-center col-4 col-md-4 col-xs-12">
            <msc-common2-checkbox [value]="true" [label]="'words.timeline' | translate" [isDisabled]="true"></msc-common2-checkbox>
            <div class="box-notification__actions">
                <a [tooltip]="'words.settings' | translate" (click)="campTimelineAside.onOpen()">
                    <i class="icon icon-settings-2"></i>
                </a>
            </div>
        </div>
    </div>
</div>

<msc-camp-aside-catalogue #campCatalogueAside [camp]="camp"></msc-camp-aside-catalogue>

<msc-camp-aside-timeline #campTimelineAside [campId]="camp.id"></msc-camp-aside-timeline>
