import { Component, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Company, MediaProvider, Integration, UtilityProvider, IntegrationProvider, CompanyUserProvider, Camp } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-shared-aside-integration-link',
    templateUrl: './integration-link.component.html',
    styleUrls: ['./integration-link.component.scss']
})

export class SharedIntegrationLinkComponent {
    @ViewChild('aside') aside;
    @ViewChild('modalConfirm') modalConfirm: CommonModalComponent;
    @ViewChild('upload') upload: ElementRef;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    private company: Company;
    private decrypt: string = 'https://plus.google.com/_/favicon?domain_url=';
    private lock: boolean = false;
    public link: Integration;
    public file: File;
    public data: any;
    public favicon: string = '';
    public picture: string = '';
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public camps: Camp[];
    public availableCamps: Camp[];
    public query: string;
    public navigationIndex: number = 0;
    public modifyAnonymise: boolean = false;
    public loading: any = {
        media: false
    };
    public navigations: any[] = [
        {
            name: 'words.settings',
            unlocked: true
        },
        {
            name: 'words.access',
            unlocked: false
        }
    ];
    public type: string = 'url';

    constructor(
        private utilityProvider: UtilityProvider,
        private mediaProvider: MediaProvider,
        private renderer: Renderer2,
        private integrationProvider: IntegrationProvider,
        private storageService: StorageService,
        private companyUserProvider: CompanyUserProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.company = this.storageService.get('company');

        this.data = {
            id: this.link ? this.link.id : null,
            type: this.link ? this.link.type : this.type,
            name: this.link ? this.link.name : '',
            url: this.link ? this.link.url : '',
            media_id: this.link ? this.link.mediaId : null
        };

        if (this.type === 'lti') {
            this.data = {
                ...this.data,
                mode: 'embed',
                redirectUrl: null,
                allCamps: true,
                camps: null,
                key: null,
                secret: null,
                names: {
                    fr: null,
                    en: null,
                    nl: null,
                    it: null
                },
                anonymize: false
            };

            if (!this.link) {
                this.getAvailableCamps();
            }
        }

        if (this.link) {
            this.favicon = this.decrypt + this.data.url;
            this.picture = this.link.picture ? this.link.picture + '?size=256' : this.favicon;
        }

        if (this.link && this.type === 'lti') {
            this.getLink();
        }
    }

    public onOpen(linkType = 'url', isEdit?) {
        this.link = isEdit;
        this.type = linkType;
        this.init();
        this.aside.onOpen();
    }

    private getLink(): void {
        this.integrationProvider.getCompanyLink(this.company.id, this.link.id).subscribe((data: any) => {
            this.data.mode = data.params.mode;
            this.data.redirectUrl = data.params.redirectUrl;
            this.data.allCamps = data.permissions.allCamps;
            this.camps = data.permissions.camps;
            this.availableCamps = data.permissions.availableCamps;
            this.data.key = data.params.key;
            this.data.secret = data.params.secret;
            this.data.anonymize = data.params.anonymize;
        });
    }

    getFavicon(): void {
        this.favicon = this.picture = this.data.url ? this.decrypt + this.data.url : '';
        this.getTitle(this.data.url);
    }

    private getTitle(url: string): void {
        this.utilityProvider.getLinkPreview(url).subscribe((data) => {
            if (!this.lock) {
                this.data.name = data ? data.title : null;
            }
        });
    }

    private getAvailableCamps(): void {
        this.companyUserProvider.getCamps(this.company.id).subscribe((camps) => {
            this.availableCamps = camps;
        });
    }

    lockName(enable: boolean = true): void {
        this.lock = enable;
    }

    resetPicture(): void {
        this.picture = '';
        this.file = null;
        this.data.media_id = null;
    }

    uploadPicture(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.upload.nativeElement).dispatchEvent(event);
    }

    loadPicture(file: File): void {
        this.file = file;
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.picture = event.target.result;
        };
        reader.readAsDataURL(file);
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query,
        });

        this.integrationProvider.getProvider(this.company.id, params).subscribe((data) => {
            this.camps = data.permissions.camps;
            this.availableCamps = data.permissions.availableCamps;
        });
    }

    addCamp(camp: Camp): void {
        if (!this.camps) {
            this.camps = [];
        }
        this.camps.push(camp);

        const index = this.availableCamps.findIndex((data) => {
            return data.id === camp.id;
        });

        if (index !== -1) {
            this.availableCamps.splice(index, 1);
        }
    }

    removeCamp(camp: Camp): void {
        this.availableCamps.push(camp);

        const index = this.camps.findIndex((data) => {
            return data.id === camp.id;
        });

        if (index !== -1) {
            this.camps.splice(index, 1);
        }
    }

    onArianeClick(index: number): void {
        this.navigationIndex = index;
    }

    private getCampsId(): any {
        return this.camps.map((camp) => {
            return camp.id;
        });
    }

    save(): void {
        if (this.file) {
            this.loading.media = true;
            this.mediaProvider.uploadMedia(this.file).subscribe((data) => {
                this.data.media_id = data.id;
                this.aside.onSave();
                this.onSave.emit({link: this.data, isEdit: !!this.link});
            }, (error) => {
                this.loading.media = false;
                if (error.status === 400) {
                    this.toastService.onError(this.translateService.instant('toast.error.file-type.not-supported'));
                }
            }, () => {
                this.loading.media = false;
            });
        } else {
            this.onSave.emit({link: this.data, isEdit: !!this.link});
            this.aside.onSave();
        }
    }

    manageSave(): void {
        if (this.navigationIndex === 0) {
            this.navigationIndex = 1;
        } else {
            if (this.link) {
                this.modalConfirm.onOpen();
            } else {
                this.saveLTI();
            }
        }
    }

    saveLTI(): void {
        if (!this.data.allCamps) {
            this.data.camps = this.getCampsId();
        }
        this.save();
    }

    updateAnonymise(): void {
        this.data.anonymize = !this.data.anonymize;
        if (this.link) {
            this.modifyAnonymise = true;
        }
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }
}
