<section class="head">
    <h3>{{ 'organize.marketplace.catalogue.title' | translate }}</h3>
    <p>{{ 'organize.marketplace.catalogue.description' | translate }}</p>
</section>

<section class="body gap-4">
    <ng-container *ngIf="isCatalogueToolbarVisible">
        <msc-organize-toolbar-marketplace-catalogue-component
            [total]="catalogueTableTotal"
            [filters]="catalogueTableFilters$ | async"
            (deleteEvent)="onDeleteCatalogue()"
            (filterEvent)="onFilterCatalogue($event)"
            (searchEvent)="onSearchCatalogue($event)"
            (openModal)="onOpenModal(modal)">
        </msc-organize-toolbar-marketplace-catalogue-component>
    </ng-container>

    <msc-organize-table-marketplace-catalogue-component
        [rows]="catalogueTableRows$ | async"
        [status]="catalogueTableStatus$ | async"
        [filtersApplied]="hasFiltersApplied"
        (changeModeEvent)="onEditCourseMode($event)"
        (deleteEvent)="onDeleteCatalogue($event)"
        (sortEvent)="onSortCatalogue($event)"
        (selectAllEvent)="onSelectAllCatalogue($event)"
        (updateEvent)="onUpdateCatalogue($event)"
        (openModal)="onOpenModal(modal)">
    </msc-organize-table-marketplace-catalogue-component>

    <ng-container *ngIf="(catalogueTableRows$ | async).length && catalogueTableHasLoadMore$ | async">
        <div class="wrapper">
            <msc-button class="msc-button--primary" palette="primary" (click)="onLoadMoreCatalogue()">{{ 'words.load-more' | translate }}</msc-button>
        </div>
    </ng-container>
</section>

<msc-common-modal #modal [title]="'organize.marketplace.catalogue.modal.content.title' | translate" (closeEvent)="onCloseModal()">
    <ng-template>
        <msc-organize-toolbar-marketplace-content-component
            [ngClass]="{ 'is-hidden' : !isContentToolbarVisible }"
            [total]="contentTableTotal"
            [filters]="contentTableFilters$ | async"
            (addEvent)="onAddCatalogue(modal)"
            (filterEvent)="onFilterContent($event)"
            (searchEvent)="onSearchContent($event)">
        </msc-organize-toolbar-marketplace-content-component>

        <msc-organize-table-marketplace-content-component
            [rows]="contentTableRows$ | async"
            [status]="contentTableStatus$ | async"
            [isInit]="isInitContent"
            (sortEvent)="onSortContent($event)"
            (selectAllEvent)="onSelectAllContent($event)"
            (updateEvent)="onUpdateContent($event)">
        </msc-organize-table-marketplace-content-component>

        <ng-container *ngIf="contentTableHasLoadMore$ | async">
            <div class="wrapper">
                <msc-button class="msc-button--primary" palette="primary" (click)="onLoadMoreContent()">{{ 'words.load-more' | translate }}</msc-button>
            </div>
        </ng-container>

        <!-- <ng-container *ngIf="enableContentModal">
            <ng-container *ngIf="isContentToolbarVisible">

            </ng-container>
        </ng-container> -->
    </ng-template>
</msc-common-modal>
