import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from '@services';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageConversationProvider } from "@lighty";

@Component({
    selector: 'msc-manage-instance-conversation-modal-group',
    templateUrl: './group.component.html',
})
export class ManageInstanceConversationModalGroupComponent implements OnInit, OnChanges {
    @Input() members: any;
    @Input() paginationCollection: any;
    @Output() paginationCollectionChange: EventEmitter<any> = new EventEmitter();
    @Output() membersChange: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    private company: any;
    private instance: any;
    public isActive: boolean = false;
    public data: any;
    public query: string;
    public membersList: any;
    public modalMember: any = {};
    public loaders = {
        learners: false,
        managers: false
    }

    constructor(private storageService: StorageService, private router: Router, private conversationProvider: ManageConversationProvider, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.isActive = true;
        this.company = this.storageService.get('company');
        this.instance = this.route.parent.snapshot.data.instance;


        this.modalMember = {
            learners: [...this.members.learners],
            managers: [...this.members.managers],
        };

        this.data = {
            name: '',
            members: []
        };

        this.filterMembers();
    }

    ngOnChanges(): void {
        this.filterMembers();
    }

    filterMembers(): void {
        const me = this.storageService.get('me');

        if (this.modalMember.managers && this.modalMember.learners) {
            const memberId = this.modalMember.managers.map((manager) => manager.id);
            for (let i = 0; i < this.modalMember.learners.length; i++) {
                if (memberId.indexOf(this.modalMember.learners[i].accountId) > -1) {
                    this.modalMember.learners.splice(i, 1);
                }
            }
        }

        if (this.modalMember.managers) {
            for (let i = 0; i < this.modalMember.managers.length; i++) {
                if (this.modalMember.managers[i].id === me.id) {
                    this.modalMember.managers.splice(i, 1);
                }
            }
        }

        if (this.modalMember.learners) {
            for (let i = 0; i < this.modalMember.learners.length; i++) {
                if (this.modalMember.learners[i].id === me.id) {
                    this.modalMember.learners.splice(i, 1);
                }
            }
        }

        this.membersList = this.modalMember;
    }

    search(query?): void {
        this.query = query;
        this.filterMembers();
        this.membersList = {
            managers: this.modalMember.managers.filter((member) => (member.name.toLowerCase()).indexOf(this.query.toLowerCase() ) !== -1),
            learners: this.modalMember.learners.filter((member) => (member.name.toLowerCase()).indexOf(this.query.toLowerCase() ) !== -1)
        };
    }

    loadMoreMembers(type: string): void {
        const params = {
            context: this.instance.type,
            member_type: type,
            page: this.paginationCollection[type].currentPage + 1
        };
        this.loaders[type] = true;
        this.conversationProvider.getMembers(this.company.id, this.instance.id, params).subscribe((data) => {
            this.paginationCollection[type] = data.pagination;
            for (const account of data.accounts) {
                this.members[type].push(account);
                this.modalMember[type].push(account);
            }
            this.loaders[type] = false;
        });
    }


    addMember(id: number): void {
        const index = this.data.members.indexOf(id);
        if (index > -1) {
            this.data.members.splice(index, 1);
        } else {
            this.data.members.push(id);
        }
    }

    isMemberSelected(id: number): boolean {
        return this.data.members.indexOf(id) > -1;
    }

    startConversationWith(user: any): void {
        this.router.navigate( ['conversation/create'], {queryParams: {account_ids: user}});
    }

    create(): void {
        this.startConversationWith(
            [
                ...this.membersList.learners.map((learner) => learner.accountId).filter((id) => this.isMemberSelected(id)),
                ...this.membersList.managers.map((manager) => manager.id).filter((id) => this.isMemberSelected(id))
            ].toString()
        );
    }

    close(): void {
        this.onClose.emit(true);
    }
}
