import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProvider, Company, LanguageProvider, StatsProvider } from '@lighty';
import { EmitterService, StorageService, TranslateService, ExternalAppsService, ErrorStreamService } from '@services';
import { UrlHelper, DataHelper } from '@helpers';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-account-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class AccountProfileComponent implements OnInit {
    @ViewChild('modalConfirmation') modalConfirmation: CommonModalComponent;
    @ViewChild('upload') upload: ElementRef;
    private accountId: number;
    private privacyUrls: any = {
        fr: 'https://myskillcamp.com/fr/cookies-policy',
        en: 'https://myskillcamp.com/en/cookies-policy',
        nl: 'https://myskillcamp.com/en/cookies-policy',
        it: 'https://myskillcamp.com/en/cookies-policy',
        de: 'https://myskillcamp.com/en/cookies-policy'
    };
    public company: Company;
    public me: any;
    public profile: any;
    public profileEdit: any;
    public editMode: boolean = false;
    public editEnabled: boolean = false;
    public accountIdEdit: number;
    public isSynchronized: boolean;
    public canDoRGPDRequest: boolean;
    public isOpenedNotification: boolean = true;
    private savedProfile: any;
    private loadings: any = {
        avatar: false
    };
    public loadingDownload: boolean;
    public selectData: any = {
        interfaceLanguages: []
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userProvider: UserProvider,
        private toastService: CommonToastService,
        private renderer: Renderer2,
        private emitterService: EmitterService,
        private storageService: StorageService,
        private translateService: TranslateService,
        private externalAppsService: ExternalAppsService,
        private languageProvider: LanguageProvider,
        private errorStreamService: ErrorStreamService,
        private statsProvider: StatsProvider,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.profile = this.route.snapshot.data.profile || this.route.parent.snapshot.data.user;

        this.isSynchronized = this.profile.companyUser.synchronized;
        this.canDoRGPDRequest = !this.profile.companyRoles.companyAdministrator && !this.profile.companyRoles.companyOwner && this.company.experience.contactId !== null;

        this.savedProfile = DataHelper.clone(this.profile);

        if (this.profile.id === this.me.id) {
            this.editEnabled = true;
        } else {
            this.accountId = this.profile.id;
            if (this.storageService.get('access').roles.companyOwner) {
                this.editEnabled = true;
            }
        }

        if (this.storageService.get('editProfile')) {
            this.accountIdEdit = this.storageService.getFlash('editProfile');
            this.edit(true);
        }

        this.getLanguages();
    }

    getLanguages() {
        this.languageProvider.get('interface').subscribe(resp => {
            this.selectData.interfaceLanguages = this.getSelectData(resp);
        })
    }

    getSelectData(data) {
        return data.map(d => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: d.id === this.profile?.language.id
        }));
    }

    onSelectOption(option) {
        this.selectData.interfaceLanguages = this.selectData.interfaceLanguages.map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });

        this.onSelect(option.value);
    }

    edit(editable: boolean): void {
        if (editable) {
            this.profileEdit = DataHelper.clone(this.profile, {}, true);
        }
        this.editMode = editable;
    }

    deletePicture(): void {
        this.loadings.avatar = true;

        const bgColors = ['fdad92', 'FFD796', 'FC5752', 'fb97ef', 'cb96ff', '98FF96', '5FACFF', '36E0E8'];
        const textColors = ['f2615f', 'f38c59', 'A73936', 'e04acb', 'a35ff0', '3fd78c', '2264AB', '1E8388'];
        const colorIndex = this.profileEdit.id % 7;
        this.profileEdit.picture = `https://ui-avatars.com/api/?background=${bgColors[colorIndex]}&color=${textColors[colorIndex]}&font-size=0.6&length=1&name=${this.profileEdit.firstname}+${this.profileEdit.lastname}?size=64`;
        this.profileEdit.mediaId = null;
        this.loadings.avatar = false;
    }

    uploadPicture(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.upload.nativeElement).dispatchEvent(event);
    }

    updatePicture(event: any): void {
        this.loadings.avatar = true;

        this.upload.nativeElement.value = null;

        if (event.type === 'finished') {
            this.profileEdit.picture = event.value.pictureUrl;
            this.profileEdit.mediaId = event.value.id;
            this.loadings.avatar = false;
        }

        if (event.type === 'error') {
            this.loadings.avatar = false;
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    cancel(): void {
        this.profileEdit = this.savedProfile;
        this.userProvider.updateAccount(this.profileEdit, this.company.id, this.accountId).subscribe((data) => {
            this.profile = data;
            this.profileEdit = data;
            this.editMode = false;
            this.refreshMe();
        });
    }

    save(forceSave?): void {
        if (!forceSave && this.isActiveSSOChanged()) {
            this.modalConfirmation.onOpen();
            return;
        }

        this.userProvider.updateAccount(this.profileEdit, this.company.id, this.accountId).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.profile = data;
            this.savedProfile = data;
            this.editMode = false;
            this.refreshMe();
        });
    }

    isActiveSSOChanged(): boolean {
        return this.savedProfile.companyUser.hasSSO && this.profileEdit.companyUser.externalId !== this.savedProfile.companyUser.externalId
    }

    requestUpdate(contact: any): void {
        const slug = this.company.experience.contact ? this.company.experience.contact.conversationId : null;
        this.storageService.set('conversationUsers', [contact]);
        this.router.navigate(['conversation', slug || 'create']);
    }

    refreshMe(): void {
        this.emitterService.refreshMe.emit(true);
    }

    privacyPolicy(): void {
        UrlHelper.navigate(this.privacyUrls[this.translateService.getCurrentLang()]);
    }

    manageAccess(): void {
        this.router.navigate(['../access', 'learn'], { relativeTo: this.route });
    }

    getTranslateDate(): string {
        const today = moment().format('YYYY-MM-DD');
        const subscriptionDate = moment(this.profile.companyUser.subscriptionDate).format('YYYY-MM-DD');
        const startDate = moment(this.profile.companyUser.accessStatus.accessStartAt).format('YYYY-MM-DD');

        if (today >= subscriptionDate && (!this.profile.companyUser.accessStatus.accessStartAt || startDate <= today)) {
            return 'words.since-the';
        }
        if (this.profile.companyUser.accessStatus.accessStartAt && today < startDate) {
            return 'words.from-the';
        }

        return null;
    }

    onSelect(data: any) {
        this.profileEdit.languageId = data.id;
    }

    download(ev, accountId): void {
        this.loadingDownload = true;
        const params = {
            type: ev.type,
            format: ev.format,
            emails: [this.storageService.get('me').email],
            accountId
        };

        this.statsProvider.getExport(this.company.id, params).subscribe({
            next: (data) => {
                this.toastService.onSuccess(this.translateService.instant('toast.send-file-mail'));
                this.errorStreamService.unlocked();
                this.loadingDownload = false;
            },
            error: () => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                this.errorStreamService.unlocked();
                this.loadingDownload = false;
            }
        });
    }

    downloadHistoryWrapper() {
        this.download({ format: 'xlsx', type: 'learner-personal-history' }, this.me.id !== this.profile?.id ? this.profile.id : this.me.id);
    }
}
