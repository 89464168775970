<!--login signup passwords restablecer-->

<div class="login bg-color-primary login--signup animate  min-h-screen   h-auto bg-color-primary">
    <div class="p-5">
        <div class=" absolute translate-reset sm-translate-reset" >
            <ng-container *ngIf="selectData.interfaceLanguages.length">
                <msc-common-select [data]="selectData.interfaceLanguages" id="translate-signup">
                    <ng-template #buttonTemplate let-data="data">
                        <button class="select-main">
                            <ng-container *ngIf="data?.length">
                                <img src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                                <span>{{ data[0].label }}</span>
                            </ng-container>
                        </button>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <button class="select-option" (click)="onSelectOption(datum)">
                            <img src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                            <span>{{ datum.label }}</span>
                        </button>
                    </ng-template>
                </msc-common-select>
            </ng-container>
        </div>

    <div class="login__body box box--shadow" id="login-signup" *ngIf="data">
        <div class="login__header" >
           <!-- <a class="login__logo">
                <img *ngIf="!display.logo" src="https://webservices.griky.co/cdn/img/Griky-Logotipo.webp" alt="MySkillCamp">
                <img *ngIf="display.logo" [src]="display.logo + '?size=256'" [alt]="display.name">
            </a>-->

            <div class="login__logo">
                <div class="login__logo__container mx-auto h-15 w-3/5 " *ngIf="!display.logo"
                    [style.backgroundImage]="'url(https://webservices.griky.co/cdn/img/Griky-Logotipo.webp)'"></div>
                <div class="login__logo__container mx-auto h-15 w-3/5" *ngIf="display.logo"
                    [style.backgroundImage]="'url(' + display.logo + '?size=512)'"></div>
            </div>
        </div>

        <div class="signup__form">
            <div class="form__item">
                <msc-input [label]="'words.email' | translate" type="text" id="email" [ngModel]="data.email" disabled></msc-input>
            </div>

            <div class="form__item form__item--half">
                <div class="col-6">
                    <msc-input [label]="'words.firstname' | translate" type="text" id="firstname" [(ngModel)]="data.firstname"></msc-input>
                </div>

                <div class="col-6">
                    <msc-input [label]="'words.lastname' | translate" type="text" id="lastname" [(ngModel)]="data.lastname"></msc-input>
                </div>
            </div>

            <div class="form__item d-none">
                <label class="input__label" for="firstname"  style="display: none;">
                    {{ 'words.interface-language' | translate }}
                </label>

                <ng-container *ngIf="selectData.interfaceLanguages.length"  style="display: none;">
                    <msc-common-select [data]="selectData.interfaceLanguages" style="display: none;">
                        <ng-template #buttonTemplate let-data="data">
                            <button class="select-main">
                                <ng-container *ngIf="data?.length">
                                    <img src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                                    <span>{{ data[0].label }}</span>
                                </ng-container>
                            </button>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <button class="select-option" (click)="onSelectOption(datum)">
                                <img src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                                <span>{{ datum.label }}</span>
                            </button>
                        </ng-template>
                    </msc-common-select>
                </ng-container>
            </div>

            <div class="form__item">
                <msc-input [label]="'words.password' | translate" type="password" id="password" [(ngModel)]="data.password" [debounce]="500" (onDebounce)="trimPassword('password')"></msc-input>
            </div>

            <div class="form__item">
                <msc-input [label]="'words.confirm-password' | translate" type="password" id="confirm_password" [(ngModel)]="data.password_confirmation" [debounce]="500" (onDebounce)="trimPassword('password_confirmation')"></msc-input>
            </div>

            <p class="color--grey text-error" [ngClass]="{'active': error.short, 'valid': valid.short}">
                <i *ngIf="error.short" class="icon icon-close"></i>
                <i *ngIf="valid.short" class="icon icon-checkmark-1"></i>
                {{ "error.password.too-short" | translate }}
            </p>
            <p class="color--grey text-error" [ngClass]="{'active': error.number, 'valid': valid.number}">
                <i *ngIf="error.number" class="icon icon-close"></i>
                <i *ngIf="valid.number" class="icon icon-checkmark-1"></i>
                {{ "error.password.number" | translate }}
            </p>
            <p class="color--grey text-error" [ngClass]="{'active': error.uppercase, 'valid': valid.uppercase}">
                <i *ngIf="error.uppercase" class="icon icon-close"></i>
                <i *ngIf="valid.uppercase" class="icon icon-checkmark-1"></i>
                {{ "error.password.uppercase" | translate }}
            </p>
            <p class="color--grey text-error" [ngClass]="{'active': error.lowercase, 'valid': valid.lowercase}">
                <i *ngIf="error.lowercase" class="icon icon-close"></i>
                <i *ngIf="valid.lowercase" class="icon icon-checkmark-1"></i>
                {{ "error.password.lowercase" | translate }}
            </p>

            <p class="color--red text-error" *ngIf="error.confirm">
                <i class="icon icon-close"></i>
                {{ "error.password.no-match" | translate }}
            </p>

            <div class="form__item form__item--privacy mt-8">
                <msc-button class="msc-button--primary" palette="primary" (click)="save()" [disabled]="data.password.length === 0 || error.characters || error.number || error.lowercase || error.uppercase || error.short || error.confirm">
                    {{ 'words.save' | translate }}
                </msc-button>
            </div>
        </div>
    </div>
</div>



