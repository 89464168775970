<section class="head">
    <h3>Tooltip</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Tooltip</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <p tooltip="Tooltip top">Text with tooltip top</p>
                <p tooltip="Tooltip right" direction="right">Text with tooltip right</p>
                <p tooltip="Tooltip bottom" direction="bottom">Text with tooltip bottom</p>
                <p tooltip="Tooltip left" direction="left">Text with tooltip left</p>
                <p tooltip="Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                    mollit anim id est laborum." direction="right">
                    Huge text
                </p>
            </div>
        </div>
    </div>
</section>
