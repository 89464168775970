import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedirectService, TranslateService } from '@services';
import { Instance } from '@lighty';

@Component({
    selector: 'msc-learn-detail-instance-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
})

export class LearnDetailInstanceSummaryComponent {
    public instance: Instance;
    public summary: any;
    public openSections: boolean[] = [];

    constructor(private route: ActivatedRoute, private translateService: TranslateService, private redirectService: RedirectService) {}

    ngOnInit(): void {
        this.instance = this.route.parent.snapshot.data.instance;
        this.summary = this.route.parent.snapshot.data.summary;

        if (this.summary.sections) {
            this.openDropdownSections(0);
        }
    }

    openDropdownSections(index: number): void {
        this.openSections[index] = !this.openSections[index];
    }

    canProgressToSection(section: any): void {
        const sectionContents = section.sectionContents ? section.sectionContents.filter((content) => {
            if (!content.session || content.session.progress !== 100) {
                return content;
            }
        }) : [];
        this.redirectActivityToPlayer(section, sectionContents.length > 0 ? sectionContents[0] : null);
    }

    redirectActivityToPlayer(section: any, activity: any): void {
        if (activity) {
            if (activity.context === 'media') {
                this.redirectService.navigate('player', ['course', this.summary.id, 'section', section.id, activity.context, activity.contextId, 'content', activity.id]);
            } else if (activity.context === 'external_link') {
                this.redirectService.navigate('player', ['course', this.summary.id, 'section', section.id, 'external-link', activity.contextId]);
            } else {
                this.redirectService.navigate('player', ['course', this.summary.id, 'section', section.id, activity.context, activity.contextId]);
            }
        } else {
            this.redirectService.navigate('player', ['course', this.summary.id, 'section', section.id]);
        }
    }

    getTranslationButton(section: any): string {
        if (section.progress) {
            if (section.progress.finishedPercent > 0 && section.progress.finishedPercent < 100) {
                return this.translateService.instant('words.continue');
            } else if (section.progress.finishedPercent === 100) {
                return this.translateService.instant('words.resume');
            } else {
                return this.translateService.instant('words.start');
            }
        }
        return this.translateService.instant('words.start');
    }

    getTranslationButtonActivity(activity: any): string {
        if (activity.session) {
            if (activity.session.progress > 0 && activity.session.progress < 100) {
                return this.translateService.instant('words.continue');
            } else if (activity.session.progress === 100) {
                return this.translateService.instant('words.resume');
            } else {
                return this.translateService.instant('words.start');
            }
        }
        return this.translateService.instant('words.start');
    }

    getActivityIcon(context: string): string {
        switch (context) {
            case 'media':
                return 'icon-video';
            case 'cheatsheet':
                return 'icon-file-text';
            case 'exercise':
                return 'icon-checkmark-square';
            case 'event':
                return 'icon-calendar';
            case 'exchange':
                return 'icon-message-circle';
            case 'project':
                return 'icon-clipboard';
            default:
                return 'icon-checkmark-square';
        }
    }
}
