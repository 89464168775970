import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
} from '@angular/core';
import { ThemingService } from '@common2/services/theming.service';

const MSC_AVATAR_LETTER_COUNT = 1;

@Component({
    selector: `msc-common-avatar`,
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonAvatarComponent {
    @Input() pictureURL: string;
    @Input() label: string;

    public color = {
        background: '',
        text: ''
    };
    public letters: string;

    constructor(
        private themingService: ThemingService,
        private elementRef: ElementRef,
    ) { }

    ngOnInit(): void {
        if (this.label) {
            this.letters = this.label.split(' ', MSC_AVATAR_LETTER_COUNT).map(word => word[0]).join('').toUpperCase();
        }
        const baseColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        const c1 = this.themingService.getVariationFromColor(baseColor, 300);
        const c2 = this.themingService.getVariationFromColor(baseColor, 800);
        this.color = {
            background: c1,
            text: c2
        };

        this.elementRef.nativeElement.style.backgroundColor = this.color.background;
        this.elementRef.nativeElement.style.color = this.color.text;
    }
}