<div [attr.class]="'daterangepicker ' + class" [(autoClose)]="isActive" (autoCloseChange)="close()">
    <div class="calendar left">
        <div class="calendar-table">
            <div class="table-condensed">
                <div class="table-head">
                    <div class="month-row">
                        <div class="prev available" (click)="changeMonths('start','prev')">
                            <i class="icon icon-arrow-ios-left"></i>
                        </div>

                        <div class="month">
                            {{ 'month.' + (currentDates.start | date: 'MMMM') | translate }} {{ currentDates.start | date: 'YYYY' }}
                        </div>

                        <div class="next available" (click)="changeMonths('start','next')">
                            <i class="icon icon-arrow-ios-right"></i>
                        </div>
                    </div>

                    <div class="day-row">
                        <div *ngFor="let day of dayNames[lang]">{{ day }}</div>
                    </div>
                </div>

                <div class="table-body">
                    <div *ngFor="let week of weeksStart">
                        <div *ngFor="let day of week"
                             [ngClass]="{'today': day.today, 'active': isSelected(day), 'off' : !day.isSameMonth, 'in-range': isBetween(day), 'start': isStart(day), 'end': isEnd(day)}"
                             (click)="selectDay(day)">
                            <span>{{ day.moment.date() }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="calendar right">
        <div class="calendar-table">
            <div class="table-condensed">
                <div class="table-head">
                    <div class="month-row">
                        <div class="prev available" (click)="changeMonths('end','prev')">
                            <i class="icon icon-arrow-ios-left"></i>
                        </div>

                        <div class="month">
                            {{ 'month.' + (currentDates.end | date: 'MMMM') | translate }} {{ currentDates.end | date: 'YYYY' }}
                        </div>

                        <div class="prev available" (click)="changeMonths('end','next')">
                            <i class="icon icon-arrow-ios-right"></i>
                        </div>
                    </div>

                    <div class="day-row">
                        <div *ngFor="let day of dayNames[lang]">{{ day }}</div>
                    </div>
                </div>

                <div class="table-body">
                    <div *ngFor="let week of weeksEnd">
                        <div *ngFor="let day of week"
                             [ngClass]="{'today': day.today, 'active': isSelected(day), 'off' : !day.isSameMonth, 'in-range': isBetween(day), 'start': isStart(day), 'end': isEnd(day)}"
                             (click)="selectDay(day)">
                            <span>{{ day.moment.date() }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <div class="input form-select" *ngIf="range">
            <div class="select">
                <select name="select" [(ngModel)]="dateRange" (ngModelChange)="changeRange()">
                    <option value="last7days">{{ 'date.last7days' | translate }}</option>
                    <option value="last14days">{{ 'date.last14days' | translate }}</option>
                    <option value="last30days" selected>{{ 'date.last30days' | translate }}</option>
                    <option value="currentMonth">{{ 'date.current-month' | translate }}</option>
                    <option value="lastMonth">{{ 'date.last-month' | translate }}</option>
                    <option value="currentQuarter">{{ 'date.current-quarter' | translate }}</option>
                    <option value="lastQuarter">{{ 'date.last-quarter' | translate }}</option>
                    <option value="currentYear">{{ 'date.current-year' | translate }}</option>
                    <option value="lastYear">{{ 'date.last-year' | translate }}</option>
                    <option value="wholePeriod">{{ 'date.whole-period' | translate }}</option>
                </select>
            </div>
        </div>

        <div class="flex justify-end gap-2 w-full">
            <msc-button class="msc-button--tiny" (click)="close()">{{ 'words.cancel' | translate }}</msc-button>
            <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" (click)="save()">{{ 'words.save' | translate }}</msc-button>
        </div>
    </div>
</div>
