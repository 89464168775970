<div class="content-discussions">
    <div class="col-8 content-discussions__timeline">
        <msc-timeline [context]="getContext()" [contextId]="instance.id" [manage]="true" [headerFilters]="true"
            [show]="'all'" [options]="{'notifyLearners': true}"></msc-timeline>
    </div>

    <div class="col-4 content-discussions__contact">
        <div class="block-contact-discussions">
            <div class="block-participant">
                <div class="block-participant__header">
                    <h3 class="block-participant__headline">{{ 'words.all-contacts' | translate }}</h3>
                    <i class="icon icon-add-people" (click)="displayed('modals','group')"></i>
                </div>

                <div class="block-participant__body">
                    <h4 class="block-participant__title" [ngClass]="{'active': displays.all.managers}"
                        (click)="displayed('all', 'managers')">
                        {{ (members.managers.length > 1 ? 'words.trainers' : 'words.trainer') | translate }}
                        <i class="icon icon-arrow-ios-down"></i>
                    </h4>

                    <div class="block-participant__list" *ngIf="members.managers.length > 0">
                        <div class="block-participant__item" *ngFor="let manager of members.managers">
                            <div class="block-participant__infos">
                                <msc-common-avatar class="tiny mr-2" [label]="manager?.name"
                                    [pictureURL]="manager?.picture ? (manager?.picture + '?size=128') : null">
                                </msc-common-avatar>

                                <p>
                                    {{ manager.name }}
                                    <span></span>
                                </p>
                            </div>

                            <div class="block-participant__actions" *ngIf="manager.id !== me.id">
                                <a><i class="icon icon-message-circle" (click)="conversationWith(manager)"></i></a>
                                <a><i class="icon icon-email" (click)="mailUserAside.onOpen(manager)"></i></a>
                            </div>
                        </div>

                        <msc-load-more [loading]="loaders.managers" [pagination]="paginationCollection.managers"
                            (onLoad)="onLoadMoreMembers('learners')"></msc-load-more>
                    </div>
                </div>

                <div class="block-participant__body">
                    <h4 class="block-participant__title" [ngClass]="{'active': displays.all.learners}"
                        (click)="displayed('all', 'learners')">
                        {{ (members.learners.length > 1 ? 'words.attendees' : 'words.attendee') | translate }}
                        <i class="icon icon-arrow-ios-down"></i>
                    </h4>

                    <div class="block-participant__list" *ngIf="members.learners.length > 0">
                        <div class="block-participant__item" *ngFor="let learner of members.learners">
                            <div class="block-participant__infos">
                                <msc-common-avatar class="tiny mr-2" [label]="learner?.name"
                                    [pictureURL]="learner?.picture ? (learner?.picture + '?size=128') : null">
                                </msc-common-avatar>
                                <p>
                                    {{ learner.name }}
                                    <span></span>
                                </p>
                            </div>

                            <div class="block-participant__actions" *ngIf="learner.accountId !== me.id">
                                <a><i class="icon icon-message-circle" (click)="conversationWith(learner)"></i></a>
                                <a><i class="icon icon-email" (click)="mailUserAside.onOpen(learner)"></i></a>
                            </div>
                        </div>

                        <msc-load-more [loading]="loaders.learners" [pagination]="paginationCollection.learners"
                            (onLoad)="onLoadMoreMembers('learners')"></msc-load-more>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-manage-instance-conversation-modal-group *ngIf="displays.modals.group" [(members)]="members"
    [(paginationCollection)]="paginationCollection" (onClose)="displayed('modals', 'group')">
</msc-manage-instance-conversation-modal-group>

<msc-shared-aside-mail-user #mailUserAside></msc-shared-aside-mail-user>