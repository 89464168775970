import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
//
import { CommonTreeDatum } from '@common2/components/tree/tree.component';
//
export interface CommonNavDatum extends CommonTreeDatum<any> {
    icon?: string;
    isNew?: boolean;
    routerLink?: string | Array<string>;
}

@Component({
    selector: 'msc-common-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonNavComponent {
    @Input() data: Array<CommonNavDatum>;
}
