import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ManageTeamProvider, Pagination } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-manage-team-aside-add',
    templateUrl: './add-member.component.html'
})
export class ManageTeamAsideAddComponent {
    @ViewChild('aside') aside;
    @Input() companyId: number;
    @Input() groupId: number;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public pagination: Pagination;
    public availableUsers: any;
    public query: string;
    public sort: any;
    public data: any;
    public loaders: any;

    constructor(private teamProvider: ManageTeamProvider) { }

    init(): void {
        this.sort = {
            key: '',
            type: null
        };

        this.data = {
            inviteAll: false,
            notify: false,
            users: []
        };

        this.loaders = {
            ready: false,
            loadMore: false,
            save: false
        };

        this.getAvailableUsers();
    }

    public onOpen() {
        this.init();
        this.aside.onOpen();
    }

    getAvailableUsers(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query,
            sort: this.sort.type,
            sortBy: this.sort.key
        });

        this.teamProvider.getAvailableUsers(this.companyId, this.groupId, params).subscribe((data) => {
            this.availableUsers = data.users;
            this.pagination = data.pagination;

            this.loaders.ready = true;
        });
    }

    loadMore(): void {
        this.loaders.loadMore = true;

        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.currentPage + 1,
            sort: this.sort.type,
            sortBy: this.sort.key
        });

        this.teamProvider.getAvailableUsers(this.companyId, this.groupId, params).subscribe((data) => {
            this.availableUsers.push(...data.users);
            this.pagination = data.pagination;

            this.loaders.loadMore = false;
        });
    }

    sortAvailableUsers(key: string, type: string): void {
        this.sort.key = type ? key : null;
        this.sort.type = type;
        this.getAvailableUsers();
    }

    selectUser(user: any): void {
        const index = this.data.users.findIndex((item) => item.id === user.id);

        if (index > -1) {
            this.data.users.splice(index, 1);
        } else {
            this.data.users.push(user);
        }
    }

    isUserSelected(user: any): boolean {
        return this.data.users.findIndex((item) => item.id === user.id) > -1;
    }

    toggleSelectedUsers(): void {
        this.data.inviteAll = !this.data.inviteAll;

        this.availableUsers.forEach((user) => user.checked = this.data.inviteAll);

        if (this.data.inviteAll) {
            this.data.users = [...this.availableUsers];
        } else {
            this.data.users = [];
        }
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (!this.loaders.save) {
            this.loaders.save = true;
            const params = {
                inviteAll: this.data.inviteAll,
                accountIds: this.data.inviteAll ? null : this.data.users.map((user) => user.id),
                notify: this.data.notify
            };
            this.aside.onSave();
            this.onSave.emit(params);
        }
    }
}