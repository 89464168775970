import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//
import { map } from 'rxjs/operators';
//
import { HttpUtils } from '@common2/utils/http.utils';
import { ADMIN_COMPANY_LIST_API_URL_ROOT } from '@core/tokens';
import { ApiMarketplaceGetCustomerListResponse } from '@modules/organize/organize.types';

@Injectable({ providedIn: 'root' })
export class OrganizeMarketplaceCustomersApiService {
    constructor(
        @Inject(ADMIN_COMPANY_LIST_API_URL_ROOT) private readonly adminCompanyListApi: string,
        private readonly http: HttpClient,
    ) { }

    /**
     * Get the customer list
     */
    getCustomerList(companyId: number, params: any = {}) {
        return this.http.get(`${this.adminCompanyListApi}/${companyId}/provider/companies`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<ApiMarketplaceGetCustomerListResponse>) => response.body)
        );
    }

    /**
     * Post (add) a customer
     */
    postAddCustomer(companyId: number, params: any) {
        return this.http.post(`${this.adminCompanyListApi}/${companyId}/provider/companies/email`, params, {
            observe: 'body',
        });
    }
}
