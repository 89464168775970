import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class OnboardingProvider {

    constructor(private http: HttpClient) {}

    getOnboardingContent(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/onboarding/content`, {params}).pipe(
            map((data: any) => data)
        );
    }
}
