export class Account {
    id: number;
    type: string;
    firstname: string;
    lastname: string;
    name: string;
    email: string;
    picture: string;
    lastConnection: Date;
    headline?: string;
    lang: string;
    companyName?: string;
    departement?: string;
    conversationId?: string;

    constructor(account: object) {
        this.assign(account);
    }

    assign(account: object): void {
        Object.assign(this, account);
    }
}
