import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppCommonModule } from '@common/common.module';
import { AppCommon2Module } from '@common2/common.module';

import { ManageRoutingModule } from './manage-routing.module';

import { SharedModule } from '../../shared.module';

import { ManageComponent } from './manage.component';

// Pages Instances
import { ManageInstanceComponent } from './instance/instance.component';
import { ManageInstancesListComponent } from './instance/list/list.component';
import { ManageInstanceWatchComponent } from './instance/watch/watch.component';

// Pages Instance Dashboard
import { ManageInstanceDashboardComponent } from './instance/watch/dashboard/dashboard.component';

// Pages Instance Agenda
import { ManageInstanceAgendaComponent } from './instance/watch/agenda/agenda.component';
import { ManageInstanceAgendaAsideAttendanceComponent } from './instance/watch/agenda/aside/aside-attendance/aside-attendance.component';
import { ManageInstanceAgendaAsideEditComponent } from './instance/watch/agenda/aside/aside-edit/aside-edit.component';

// Paged Instance Learner
import { ManageInstanceLearnerComponent } from './instance/watch/learner/learner.component';
import { ManageInstanceLearnerListComponent } from './instance/watch/learner/list/list.component';
import { ManageInstanceLearnerListAsideAvailableComponent } from './instance/watch/learner/list/aside/aside-available/aside-available.component';
import { ManageInstanceLearnerStatsComponent } from './instance/watch/learner/stats/stats.component';

// Pages Instance Conversation
import { ManageInstanceConversationComponent } from './instance/watch/conversation/conversation.component';
import { ManageInstanceConversationModalGroupComponent } from './instance/watch/conversation/modal/group/group.component';

// Pages Instance Correction
import { ManageInstanceCorrectionListComponent } from './instance/watch/correction/list/correction-list.component';
import { ManageInstanceCorrectionComponent } from './instance/watch/correction/correction.component';
import { ManageInstanceCorrectionSessionComponent } from './instance/watch/correction/watch/session/correction-session.component';
import { ManageInstanceCorrectionActivityComponent } from './instance/watch/correction/watch/activity/correction-activity.component';
import { ManageInstanceCorrectionActivityAsideComponent } from './instance/watch/correction/watch/activity/aside/correction-activity-aside.component';

// Pages Instance Settings
import { ManageInstanceSettingsComponent } from './instance/watch/settings/settings.component';
import { ManageInstanceSettingsAsideTrainersComponent } from './instance/watch/settings/aside/aside-trainers/aside-trainers.component';

// Pages Instance Stats
import { ManageInstanceStatsComponent } from './instance/watch/stats/stats.component';
import { ManageInstanceStatsDispatcherComponent } from './instance/watch/stats/dispatcher/dispatcher.component';

// Pages Instance Follow Up
import { ManageInstanceFollowUpComponent } from './instance/watch/follow-up/follow-up.component';

// Pages Teams
import { ManageTeamComponent } from './team/team.component';
import { ManageTeamWatchComponent } from './team/watch/watch.component';

// Pages Team Members
import { ManageTeamListComponent } from './team/watch/list/list.component';
import { ManageTeamAsideAddComponent } from './team/watch/list/aside/add-member/add-member.component';
import { ManageTeamAsideReassignComponent } from './team/watch/list/aside/reassign-manager/reassign-manager.component';
import { ManageTeamModalSubscribeUsersComponent } from './team/watch/list/modal/subscribe-users.component';
import { ManageMemberDetailsComponent } from './team/watch/member-details/member-details.component';
import { ManageTeamMemberInfoTooltipComponent } from './team/watch/member-details/info-tooltip/info-tooltip.component';
import { ManageTeamMemberScoreProgressComponent } from './team/watch/member-details/score-progress/score-progress.component';
import { ManageTeamSettingsComponent } from './team/watch/settings/settings.component';
import { ManageTeamTimelineComponent } from './team/watch/timeline/timeline.component';
import { ManageInstanceLearnerStatsWrapperComponent } from './instance/watch/learner/stats-wrapper/stats-wrapper.component';
import { ManageInstanceLearnerStatsEntityComponent } from './instance/watch/learner/stats-wrapper/stats-entity/stats-entity.component';
import { ManageInstanceLearnerStatsExerciseComponent } from './instance/watch/learner/stats-wrapper/stats-entity/stats-exercise/stats-exercise.component';

@NgModule({
    declarations: [
        ManageComponent,
        ManageInstanceComponent,
        ManageInstancesListComponent,
        ManageInstanceWatchComponent,
        ManageInstanceDashboardComponent,
        ManageInstanceAgendaComponent,
        ManageInstanceAgendaAsideAttendanceComponent,
        ManageInstanceAgendaAsideEditComponent,
        ManageInstanceLearnerComponent,
        ManageInstanceLearnerListComponent,
        ManageInstanceLearnerListAsideAvailableComponent,
        ManageInstanceLearnerStatsComponent,
        ManageInstanceLearnerStatsWrapperComponent,
        ManageInstanceLearnerStatsEntityComponent,
        ManageInstanceLearnerStatsExerciseComponent,
        ManageInstanceStatsComponent,
        ManageInstanceStatsDispatcherComponent,
        ManageInstanceConversationComponent,
        ManageInstanceConversationModalGroupComponent,
        ManageInstanceCorrectionListComponent,
        ManageInstanceCorrectionComponent,
        ManageInstanceCorrectionSessionComponent,
        ManageInstanceCorrectionActivityComponent,
        ManageInstanceSettingsComponent,
        ManageInstanceSettingsAsideTrainersComponent,
        ManageInstanceCorrectionActivityAsideComponent,
        ManageInstanceFollowUpComponent,
        ManageTeamComponent,
        ManageTeamWatchComponent,
        ManageTeamListComponent,
        ManageTeamAsideAddComponent,
        ManageTeamAsideReassignComponent,
        ManageTeamModalSubscribeUsersComponent,
        ManageMemberDetailsComponent,
        ManageTeamMemberInfoTooltipComponent,
        ManageTeamMemberScoreProgressComponent,
        ManageTeamSettingsComponent,
        ManageTeamTimelineComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        ManageRoutingModule,
        AppCommonModule,
        AppCommon2Module,
    ],
    providers: [
        DatePipe
    ]
})
export class ManageModule { }
