import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, Camp, ReportingProvider, IntegrationProvider } from '@lighty';
import { ErrorStreamService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { HttpHelper, DownloadHelper } from '@helpers';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-stats-providers-list',
    templateUrl: './list.component.html'
})
export class CampStatsProvidersListComponent implements OnInit {
    private company: Company;
    private camp: Camp;
    public providersCollection: any;
    public selectedProviders: any;
    public providers: any;
    public query: string;
    public loaders: any;
    public dates: any;
    public displays: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private reportingProvider: ReportingProvider,
        private errorStreamService: ErrorStreamService,
        private toastService: CommonToastService,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
        private integrationProvider: IntegrationProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.camp = this.storageService.get('camp');

        this.providersCollection = this.route.snapshot.data.providers.providers;
        this.selectedProviders = [...this.providersCollection];

        this.dates = {
            start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            diff: 30
        };

        this.displays = {
            date: false,
            export: false,
            providers: false,
            set: []
        };

        this.loaders = {
            loading: true,
            download: false
        };

        this.getLicencesUsed();
        this.search();
    }

    getLicencesUsed(): void {
        const params = {
            filters: HttpHelper.cleanParams({
                calendar: [this.company.createdAt, this.dates.end],
                camp: [this.camp.id]
            })
        };

        this.reportingProvider.providers(this.company.id, params).subscribe((data: any) => {
            for (const item of data.learnerContentProviderSubscribed) {
                this.transform(item);
            }
        });
    }

    search(): void {
        this.loaders.loading = true;
        const params = {
            filters: HttpHelper.cleanParams({
                calendar: [this.dates.start, this.dates.end],
                camp: [this.camp.id],
                provider: this.selectedProviders ? this.selectedProviders.map((provider) => provider.id) : null
            })
        };

        this.reportingProvider.providers(this.company.id, params).subscribe((data) => {
            this.providers = data;
            this.loaders.loading = false;

            for (const key in this.providers) {
                if (this.providers.hasOwnProperty(key) && this.providers[key].length > 0) {
                    this.setProviders(key);
                }
            }
        });
    }

    download(options: any): void {
        this.loaders.download = true;
        this.errorStreamService.locked();
        this.displayed('export');

        const params = {
            filters: HttpHelper.cleanParams({
                calendar: [this.dates.start, this.dates.end],
                camp: [this.camp.id],
                provider: this.selectedProviders ? this.selectedProviders.map((provider) => provider.id) : null
            }),
            export: options
        };

        if (options.granularity !== 'days') {
            this.toastService.onInfo(this.translateService.instant('toast.downloading.soon'));
        }

        this.reportingProvider.exportProviders(this.company.id, params).subscribe((data) => {
            if (data) {
                DownloadHelper.downloadBlob(data, `export-${this.company.id}.${options.format}`);
            } else {
                this.toastService.onSuccess(this.translateService.instant('toast.send-file-mail'));
            }

            this.loaders.download = false;
            this.errorStreamService.unlocked();
        }, () => {
            this.loaders.download = false;
            this.errorStreamService.unlocked();
        });
    }

    displayed(type: string, force?: boolean): void {
        this.displays[type] = force ? false : !this.displays[type];
    }

    updateDates(): void {
        this.displayed('date');
        this.search();
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    selectProvider(provider: any): void {
        const index = this.selectedProviders.findIndex((selectedProvider) => {
            return selectedProvider.id === provider.id;
        });

        if (index > -1) {
            this.selectedProviders.splice(index, 1);
        } else {
            this.selectedProviders.push(provider);
        }

        this.search();
    }

    isProviderChecked(id: number): boolean {
        return this.selectedProviders.findIndex((provider) => {
            return provider.id === id;
        }) > -1;
    }

    setLicense(provider: any): void {
        const index = this.displays.set.findIndex((id) => {
            return id === provider.id;
        });

        if (index > -1) {
            this.displays.set.splice(index, 1);
        } else {
            this.displays.set.push(provider.id);
        }
    }

    isSet(provider: any): boolean {
        return this.displays.set.findIndex((id) => {
            return id === provider.id;
        }) > -1;
    }

    // TODO REMOVE
    updateLicense(provider: any): void {
        const params = {
            licenses_available: provider.licensesAvailable
        };

        this.integrationProvider.updateProvider(this.company.id, provider.id, params).subscribe((data) => {
            this.setLicense(provider);
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    getProgress(provider: any): string {
        if (provider.licensesUsed === null || provider.licensesAvailable === null) {
            return '0%';
        }

        if (provider.licensesUsed > provider.licensesAvailable) {
            return '100%';
        }

        return ((provider.licensesUsed / provider.licensesAvailable) * 100) + '%';
    }

    navigate(provider: any): void {
        this.router.navigate(['..', 'detail', provider.id], { relativeTo: this.route });
    }

    getColor(provider: any, progress?: boolean): string {
        switch (provider.name) {
            case 'bookboon':
                return progress ? 'progress--bookboon' : 'tag-provider--bookboon';
            case 'udemy':
                return progress ? 'progress--udemy' : 'tag-provider--udemy';
            case 'openclassroom':
                return progress ? 'progress--openclassroom' : 'tag-provider--openclassroom';
            case 'mymooc':
                return progress ? 'progress--edflex' : 'tag-provider--edflex';
            case 'cefora':
                return progress ? 'progress--cefora' : 'tag-provider--cefora';
            default:
                return progress ? 'progress--orange' : 'tag-provider--default';
        }
    }

    private transform(item: any): void {
        const index = this.providersCollection.findIndex((provider) => {
            return provider.id === item.providerId;
        });

        if (index > -1) {
            this.providersCollection[index].licensesUsed = item.count;
        }
    }

    private setProviders(key: string): void {
        for (const provider of this.selectedProviders) {
            const index = this.providers[key].findIndex((data) => {
                return data.providerId === provider.id;
            });

            if (index === -1) {
                this.providers[key].push({ count: 0, providerId: provider.id, providerName: provider.name });
            }
        }
    }
}
