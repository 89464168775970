<section class="head">
    <div class="msc-banner">
        <div class="msc-banner__gradient">
            <div
                [ngStyle]="{'background-image' : 'url(' + (group.background?.pictureUrl ? group.background?.pictureUrl + '?size=1024' : 'https://static.myskillcamp.com/images/images_random/empty-state-' + group.id % 7 + '.jpg') + ')'}">
            </div>
        </div>
    </div>
    <div class="block-header-communities">
        <div class="h-52">
            <div class="flex border-b border-white">
                <h1 class="text-white">{{ group.name }}</h1>
            </div>
        </div>

        <div class="block-header-communities__content rounded-lg">
            <div class="block-header-communities__infos">
                <div class="block-header-communities__curve">
                    <div class="block-header-communities__logo"
                        [ngStyle]="{'background': '#feb88a url(' + (group.type === 'community' ? (group.media?.pictureUrl ? (group.media?.pictureUrl + '?size=256') : 'https://static.myskillcamp.com/lxp/images/logo-camp-white.svg') : (group.media ? (group.media.pictureUrl + '?size=256') : 'https://static.myskillcamp.com/lxp/images/logo-camp-white.svg')) + ')' + ' no-repeat center'}">
                    </div>
                </div>

                <div class="block-header-communities__status">
                    <div class="tag" [ngClass]="{'tag--blue' : group.type === 'team'}">
                        <div class="tag__text">{{ ('group.type.' + group.type) | translate }}</div>
                    </div>

                    <ul class="avatar-group" *ngIf="group.usersPreview?.length > 0">
                        <li *ngFor="let user of group.usersPreview">
                            <a [tooltip]="user.name" direction="bottom">
                                <msc-common-avatar class="tiny" [label]="user?.name"
                                    [pictureURL]="user?.picture ? (user?.picture + '?size=64') : null">
                                </msc-common-avatar>
                            </a>
                        </li>

                        <li class="hidden-sm" *ngIf="group.membersCount && group.membersCount > 4">
                            <span class="avatar-group__text text-primary-500">+{{ group.membersCount - 4 }}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="nav-group">
                <ul class="nav-group__content">
                    <li [ngClass]="{'active': currentTab === 'timeline'}" class="nav-group__item"
                        (click)="changeTab('timeline')">
                        <a>
                            {{ 'words.timeline' | translate }}
                        </a>
                    </li>

                    <li *ngIf="group.type === 'community'" [ngClass]="{'active': currentTab === 'resources'}"
                        class="nav-group__item" (click)="changeTab('resources')">
                        <a>
                            {{ 'words.shared-files' | translate }} <span class="text-primary-500">({{
                                resources.length }})</span>
                        </a>
                    </li>

                    <li [ngClass]="{'active': currentTab === 'members'}" class="nav-group__item"
                        (click)="changeTab('members')">
                        <a>
                            {{ 'words.members' | translate }} <span class="text-primary-500">({{ members.length
                                }})</span>
                        </a>
                    </li>

                    <li *ngIf="group.type === 'community'" [ngClass]="{'active': currentTab === 'contents'}"
                        class="nav-group__item" (click)="changeTab('contents')">
                        <a>
                            {{ 'words.contents' | translate }} <span class="text-primary-500">({{ contents.length
                                }})</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="block-header-communities__menu" [ngClass]="{'displayed': displays.mobileMenu}">
            <div class="block-header-communities__menu__item" [ngClass]="{'active': currentTab === 'timeline'}"
                (click)="changeTab('timeline')">
                <a>
                    {{ 'words.timeline' | translate }}
                </a>
            </div>

            <div class="block-header-communities__menu__item" [ngClass]="{'active': currentTab === 'resources'}"
                (click)="changeTab('resources')">
                <a>
                    {{ 'words.shared-files' | translate }}
                </a>
            </div>

            <div class="block-header-communities__menu__item" [ngClass]="{'active': currentTab === 'members'}"
                (click)="changeTab('members')">
                <a>
                    {{ 'words.members' | translate }}
                </a>
            </div>

            <div class="block-header-communities__menu__item" [ngClass]="{'active': currentTab === 'contents'}"
                (click)="changeTab('contents')">
                <a>
                    {{ 'words.contents' | translate }}
                </a>
            </div>
        </div>
    </div>
</section>
<section class="body">
    <div class="container-community__content animate row" *ngIf="currentTab === 'timeline'">
        <div class="col-8 col-md-12 col-xxs-12" *ngIf="displays.timeline">
            <msc-timeline [context]="'group'" [contextId]="group.id"></msc-timeline>
        </div>
    </div>

    <div class="container-community__content animate row animate" *ngIf="currentTab === 'resources'">
        <div class="col-xxs-12 community-details__content">
            <div class="col-xl-6 col-xxs-12 col-md-6" *ngFor="let resource of resources">
                <msc-shared-resource [resource]="resource" class="block-resource-community" [downloadIcon]="true">
                </msc-shared-resource>
            </div>

            <msc-empty-state *ngIf="resources.length === 0" [title]="'words.shared-files'"
                [description]="'empty.shared-files'"></msc-empty-state>
        </div>
    </div>

    <div class="container-community__content row animate" *ngIf="currentTab === 'members'">
        <div class="container__content container__content--search">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate"
                (searchEvent)="search($event.value)"></msc-common-search>
        </div>

        <div class="container__content row animate">
            <div class="container__section col-xxs-12">
                <h3 class="container__title container__title--left">{{ 'words.management-team' | translate }}</h3>

                <div class="grid gap-4 grid-cols-3 md:grid-cols-4 sm:grid-cols-12" *ngIf="staffs.length > 0">
                    <div class="bg-white flex flex-col items-center space-y-6 p-4 rounded shadow-md"
                        *ngFor="let staff of staffs">
                        <h2>{{ 'words.a-question' | translate }}</h2>

                        <div class="relative">
                            <msc-common-avatar class="large" [label]="staff.account?.name"
                                [pictureURL]="staff.account?.picture ? (staff.account?.picture + '?size=64') : null">
                            </msc-common-avatar>
                            <div class="flex justify-center w-full absolute -bottom-3">
                                <msc-button class="m-auto" type="fab" icon="icon-message-square"
                                    (click)="startDiscussion(staff)"></msc-button>
                            </div>
                        </div>

                        <p>{{ staff.account.name }}</p>
                        <span *ngIf="staff.account && staff.account.headline">{{ staff.account.headline | truncate: 25
                            }}</span>
                        <msc-button class="msc-button--primary" palette="secondary" (click)="startDiscussion(staff)">{{
                            'words.contact-us' | translate }}</msc-button>
                    </div>
                    <msc-load-more class="col-xxs-12" [loading]="loading.user.staff"
                        [pagination]="pagination.user.staff" (onLoad)="loadMore(true)"></msc-load-more>
                </div>

                <msc-empty-state *ngIf="staffs.length === 0" [title]="'words.management-team'"
                    [description]="'empty.community.staff'">
                </msc-empty-state>
            </div>

            <div class="container__section col-xxs-12">
                <h3 class="container__title container__title--left">{{ 'words.members' | translate }}</h3>

                <div *ngIf="members.length > 0">
                    <div class="col-md-6 col-xxs-12 block-member" *ngFor="let member of members">
                        <div class="member">
                            <msc-common-avatar class="mr-2" [label]="member.account?.name"
                                [pictureURL]="member.account?.picture ? (member.account?.picture + '?size=64') : null">
                            </msc-common-avatar>

                            <div class="member__infos">
                                <div class="member__name text-secondary-800">{{ member.account.name }}</div>
                                <div class="member__job" *ngIf="member.account && member.account.headline">{{
                                    member.account.headline | truncate:25 }}</div>
                            </div>
                        </div>

                        <div class="block-member__discussion">
                            <i class="icon icon-message-square bg-secondary-500" (click)="startDiscussion(member)"></i>
                        </div>
                    </div>


                    <msc-load-more class="col-xxs-12" [loading]="loading.user.members"
                        [pagination]="pagination.user.members" (onLoad)="loadMore()"></msc-load-more>
                </div>

                <msc-empty-state *ngIf="members.length === 0" [title]="'words.members'"
                    [description]="'empty.community.members'">
                </msc-empty-state>
            </div>
        </div>
    </div>

    <div class="container-community__content row animate" *ngIf="currentTab === 'contents'">
        <div class="container__content container__content--search" *ngIf="contents.length > 0">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate"
                (searchEvent)="search($event.value)"></msc-common-search>
        </div>

        <div class="col-xxs-12">
            <div class="col-3 col-md-4 col-xxs-12" *ngFor="let content of contents">
                <msc-card-manager [content]="content"></msc-card-manager>
            </div>

            <msc-empty-state *ngIf="contents.length === 0" [title]="'words.contents'"
                [description]="'empty.community.content'">
            </msc-empty-state>
        </div>
    </div>
</section>

<msc-common-modal size="30vw" #modalPresentation [title]="'words.presentation' | translate">
    <ng-template>
        <p>{{ group.info }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalPresentation.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="modalPresentation.onClose()">{{'common.save'
                | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>