import { Pipe, PipeTransform } from '@angular/core';

/**
 * Truncate a value
 */
@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    /**
     * Transform
     * @param {string} value - Value
     * @param {number} limit - Limit
     * @param {string} replacer - Replaces truncated characters
     * @return {string} Truncated value
     */
    transform(value: string, limit: number = 10, replacer: string = '...'): string {
        if (value) {
            return value.length > limit ? value.substring(0, limit) + replacer : value;
        }
        return null;
    }
}
