<div class="flex flex-col space-y-4 items-center bg-white shadow-md p-6 rounded justify-center" style="height:400px;">

        <msc-empty-state *ngIf="!loading && resources.length === 0" [title]="'words.resources'"
            [description]="'empty.resources'" [cypressHook]="'resources'">
        </msc-empty-state>

    <msc-common-spinner *ngIf="loading"></msc-common-spinner>

    <div class="flex flex-col w-full justify-start items-start" *ngIf="!loading && resources.length > 0" style="max-height: 350px;">
        <h3 class="mx-2 mb-3">{{ 'words.resources' | translate }} </h3>
        <div class="overflow-y-scroll w-full">
            <msc-shared-resource class="border-b last-of-type:border-b-0"
                *ngFor="let resource of resources; let index=index" [size]="'mini'" [resource]="resource"
                [truncate]="20"></msc-shared-resource>
        </div>
    </div>
</div>
