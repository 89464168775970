import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CommonListDatum } from '@common2/components/list/list.component';
//
import { CommonSelectDatum } from '@common2/components/select/select.component';

@Component({
    selector: 'msc-showroom-exhibit-select-page',
    templateUrl: './showroom-exhibit-select-page.component.html',
    styleUrls: ['./showroom-exhibit-select-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitSelectPageComponent {
    public dataSimple: Array<CommonSelectDatum<string>>;
    public dataMulti: Array<CommonListDatum<string>>;
    public testModel;
    public testModelMulti;

    ngOnInit(): void {
        this.dataSimple = this.getDataSimple('coffee', 20);
        this.testModel = this.dataSimple[2];
        this.dataMulti = this.getList(2);
    }

    /**
     *
     */
    getDataSimple(label: string, size: number): Array<CommonSelectDatum<string>> {
        return new Array(size).fill(null).map((_, i) => ({
            id: Math.random().toString(36).replace(/[^a-z]+/g, ''),
            label: `${label}-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            value: `${label}-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            isSelected: i === 0,
        }));
    }
    
    /**
     *
     */
    getList(surface: number, depth: number = 0): Array<CommonListDatum<string>> {
        return new Array(5).fill(null).map(() => ({
            children: surface !== depth ? this.getList(surface, depth + 1) : undefined,
            id: Math.random().toString(36).replace(/[^a-z]+/g, ''),
            label: `Leaf-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            value: `Leaf-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            icon: 'icon-content',
            picture: 'https://picsum.photos/200'
        }));
    }

    /**
     *
     */
    onSelectDataSimple(datum: CommonSelectDatum<string>) {
        this.dataSimple = this.dataSimple.map((d) => {
            d.isSelected = d.id === datum.id;
            return d;
        });
    }
}
