<div class="container-integration__resume form-filter-resume" [ngClass]="{'col-xs-12 col-3': !large, 'col-9': large}">
    <div class="form-filter-resume__title">
        <i class="icon icon-content"></i>
        <span>{{ 'words.summary' | translate }}</span>
    </div>

    <div class="form-filter-resume__content">
        <ul class="form-filter-resume__list">
            <li class="form-filter-resume__item">
                {{ currentRule.name }}

                <ul>
                    <li>
                        <p *ngIf="navigationIndex > 0" class="flex align-center">
                            <i class="icon icon-checkmark"></i>
                            <span>{{ 'words.access' | translate }}</span>
                        </p>

                        <ul>
                            <li class="form-filter-resume__subitem" *ngIf="currentRule.publicationType === 'all'">
                                <p>{{ 'integrations.rules.unlimited-access' | translate }}</p>
                            </li>

                            <li class="form-filter-resume__subitem" *ngIf="currentRule.publicationType === 'camps'">
                                <p>{{ 'organize.integration.limited-to-camps' | translate }}</p>
                                <ul>
                                    <li *ngFor="let camp of currentRule.camps">{{ camp.name }}</li>
                                </ul>
                            </li>

                            <li class="form-filter-resume__subitem" *ngIf="currentRule.publicationType === 'newCamp'">
                                <p>{{ 'organize.integration.create-new-camp' | translate }}</p>
                                <ul>
                                    <li>{{ providerName + ' - ' + currentRule.name }}</li>
                                </ul>
                                <p *ngIf="currentRule.inviteAll">{{ 'words.invite-all' | translate }}</p>
                            </li>
                        </ul>
                    </li>

                    <li *ngIf="currentRule.selectionType">
                        <p class="flex align-center">
                            <i class="icon icon-checkmark"></i>
                            <span>{{ 'words.selection' | translate }}</span>
                        </p>

                        <ul>
                            <li class="form-filter-resume__subitem">
                                <p> {{ 'integrations.rules.import-type.' + currentRule.selectionType | translate }}</p>
                            </li>
                        </ul>
                    </li>

                    <li *ngIf="currentRule.selectedLanguages?.length > 0 && currentRule.selectionType === 'filters'">
                        <p class="flex align-center">
                            <i class="icon icon-checkmark"></i>
                            <span>{{ 'words.languages' | translate }}</span>
                        </p>

                        <ul>
                            <li class="form-filter-resume__subitem form-filter-resume__subitem--flag" *ngFor="let lang of currentRule.selectedLanguages">
                                <p class="flex align-center">
                                    {{ lang.name }}
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li *ngIf="currentRule.selectedCategories?.length > 0 && currentRule.selectionType === 'filters'">
                        <p class="flex align-center">
                            <i class="icon icon-checkmark"></i>
                            <span>{{ 'words.categories' | translate }}</span>
                        </p>

                        <ul>
                            <li *ngFor="let category of currentRule.selectedCategories" class="form-filter-resume__subitem">
                                {{ category.name }}
                                <ul>
                                    <li *ngFor="let subcategory of category.subcategories">
                                        <span *ngIf="subcategory.selected">{{ subcategory.name }}</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li *ngIf="(currentRule.contents?.length > 0 || currentRule.allContent) && currentRule.selectionType === 'manual'">
                        <p class="flex align-center">
                            <i class="icon icon-checkmark"></i>
                            <span>{{ 'words.contents' | translate }}</span>
                        </p>

                        <ul *ngIf="currentRule.allContent">
                            <li class="form-filter-resume__subitem">
                                {{ 'words.select-all' | translate }}
                            </li>
                        </ul>

                        <ul *ngIf="!currentRule.allContent">
                            <li *ngFor="let content of currentRule.contents" class="form-filter-resume__subitem">
                                {{ content.title }}
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
