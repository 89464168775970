import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { StorageService, EmitterService } from '@services';
import { ExtensionLink } from '@interfaces';

@Component({
    selector: 'msc-shared-aside-extension-summary',
    templateUrl: './extension-summary.component.html'
})
export class SharedExtensionSummaryComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();
    public me: any;
    public loading: boolean = false;
    public duration: number = 0;

    constructor(private emitterService: EmitterService, private storageService: StorageService) {}

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        if (this.extensionLink.durationUnit === 'minutes') {
            this.duration = parseInt(this.extensionLink.duration, 10) * 60;
        } else if (this.extensionLink.durationUnit === 'hours') {
            this.duration = parseInt(this.extensionLink.duration, 10) * 60 * 60;
        } else if (this.extensionLink.durationUnit === 'days') {
            this.duration = parseInt(this.extensionLink.duration, 10) * 60 * 60 * 24;
        }
    }

    getColor(): string {
        switch (this.extensionLink.type) {
            case 'module':
                return 'purple';
            case 'video':
                return 'blue';
            case 'Article':
                return 'orange';
            default:
                return 'gray';
        }
    }

    nextPanel(): void {
        this.loading = true;
        this.emitterService.extensionPage.emit('success');
    }
}
