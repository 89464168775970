import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ApiCompanySenderItem } from '@lighty';

export interface UiCompanySenderItem {
    email: string;
    id: number;
    isActive: boolean;
    isConfigured: boolean;
    isConfirmed: boolean;
    isValid: boolean;
    name: string;
}

export function getRemappedCompanySenderApiToUi(datum: ApiCompanySenderItem, companyCurrentExperience: any): UiCompanySenderItem {
    return {
        email: datum.email,
        id: datum.id,
        isActive: !!(datum.experiences || []).find((experience) => experience.id === companyCurrentExperience.id),
        isConfigured: datum.dkimStatus,
        isConfirmed: datum.isConfirmed,
        isValid: !!datum.status,
        name: datum.name,
    };
}

@Component({
    selector: 'msc-organize-experience-watch-mail-item',
    templateUrl: './mail-item.component.html',
    styleUrls: ['./mail-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchMailItemComponent {
    @Input() datum: UiCompanySenderItem;
    @Output() activate: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Event handler for activate
     */
    onActivate(): void {
        this.activate && this.activate.emit();
    }

    /**
     * Event handler for edit
     */
    onEdit(): void {
        this.edit && this.edit.emit();
    }
}
