import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthProvider } from '@lighty';
import { ErrorStreamService, AuthService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Validator } from '@classes';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-reset-password',
    templateUrl: './reset-password-form.component.html'
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {
    private isReseted: boolean = false;
    private isTouched: boolean = false;
    public credentials: any = {};
    public forceReset: boolean = false;
    public token: any;
    public loading: boolean = false;
    public errors: any = {
        email: '',
        password: '',
        password_confirmation: ''
    };
    public display: any = {
        password: false,
        passwordConfirm: false
    };
    public validator: Validator = new Validator();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private authProvider: AuthProvider,
        private authService: AuthService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.credentials = {
            email: null,
            password: null,
            password_confirmation: null
        };

        this.route.queryParams.subscribe((params) => {
            this.credentials.email = params.email;
            this.token = params.token;

            if (this.authService.isTemporarySession()) {
                this.forceReset = true;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.isReseted) {
            this.authService.removeTemporarySession();
        }
    }

    validateForm(): void {
        const validationData = {
            email: this.credentials.email,
            password: this.credentials.password,
            password_confirmation: this.credentials.password_confirmation
        };
        const validationRules = {
            email: 'required',
            password: 'password|required|min:8|equal:password_confirmation',
            password_confirmation: 'password|required|min:8|equal:password'
        };
        this.validator.create(validationData, validationRules);
        this.validator.validate();
    }

    onChange(): void {
        this.isTouched = true;
        this.validateForm();
    }

    checkPasswordError(errorType: string): string {
        if (this.isTouched) {
            if (this.validator.trace('password').errors.indexOf(errorType) > -1 || !this.credentials.password) {
                return 'error';
            } else {
                return 'valid';
            }
        }
        return '';
    }

    displayed(key: string): void {
        this.display[key] = !this.display[key];
    }

    submit(): void {
        this.errorStreamService.locked();

        const params: any = HttpHelper.cleanParams({
            password: this.credentials.password,
            email: this.credentials.email,
            token: this.token
        });

        if (this.forceReset) {
            delete params.email;
        }

        const action: Observable<any> = this.forceReset ? this.authProvider.resetPassword(params) : this.authProvider.resetPasswordToken(params);

        action.subscribe(
            () => {
                this.errorStreamService.unlocked();
                this.toastService.onSuccess(this.translateService.instant('toast.password-updated'));
                if (this.forceReset) {
                    this.isReseted = true;
                    this.authService.removeTemporarySession();
                }
                this.router.navigateByUrl(`password/success`);
            }, (err) => {
                this.errorStreamService.unlocked();
                if (err.error[0] === 'Account or reset token is invalid') {
                    this.toastService.onError(this.translateService.instant('toast.auth.account-token.invalid'));
                } else {
                    this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                }
            }
        );
    }
}
