import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'msc-organize-user-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.scss']
})
export class OrganizeUserWatchComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {}

    userList(): void {
        this.router.navigate(['../../users'], {relativeTo: this.route});
    }
}
