import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AdminTimelineProvider {

    constructor(private http: HttpClient) {}

    getSettings(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/timeline/settings`, {params}).pipe(
            map((data: any) => data.settings)
        );
    }

    update(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/timeline/settings/${id}`, params).pipe(
            map(data => data)
        );
    }
}
