import { Component, OnInit } from '@angular/core';
import { Template } from '@lighty';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'msc-learn-detail-instance-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class LearnDetailTemplateSummaryComponent implements OnInit {
    public template: Template;
    public summary: any;
    public openSections = [];

    constructor(private route: ActivatedRoute) {
        this.template = this.route.parent.snapshot.data.template;
        this.summary = this.route.parent.snapshot.data.summary;
    }

    ngOnInit(): void {
        if (this.summary.sections) {
            this.openDropdownSections(0);
        }
    }

    openDropdownSections(index): void {
        this.openSections[index] = !this.openSections[index];
    }

    getActivityIcon(context: string): string {
        switch (context) {
            case 'media':
                return 'icon-video';
            case 'cheatsheet':
                return 'icon-file-text';
            case 'exercise':
                return 'icon-checkmark-square';
            case 'event':
                return 'icon-calendar';
            case 'exchange':
                return 'icon-message-circle';
            case 'project':
                return 'icon-clipboard';
            default:
                return 'icon-checkmark-square';
        }
    }
}
