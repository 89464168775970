import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class SubscriptionProvider {

    constructor(private http: HttpClient) {}

    unsubscribe(type: string, id: number, params?: any, admin?: boolean): Observable<any> {
        const url = admin ? `${environment.envVar.API_URL}/admin/subscriptions/${type}/${id}` : `${environment.envVar.API_URL}/subscriptions/${type}/${id}`;
        return this.http.delete(url, {params}).pipe(
            map((data) => data)
        );
    }
}
