<section class="head">
    <h3>Error</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Error</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-error></msc-common-error>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Error with message</h4>
        <hr>
        <div class="partition cards">
            <div class="segment">
                <msc-common-error [message]="'Toto, Ive a feeling were not in Kansas anymore'"></msc-common-error>
            </div>
            <div class="segment">
                <msc-common-error [message]="'Toto, Ive a feeling were not in Kansas anymore'"></msc-common-error>
            </div>
            <div class="segment">
                <msc-common-error [message]="'Toto, Ive a feeling were not in Kansas anymore'"></msc-common-error>
            </div>
            <div class="segment">
                <msc-common-error [message]="'Toto, Ive a feeling were not in Kansas anymore'"></msc-common-error>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Error with message and body</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-error [message]="'Open the pod bay doors, Hal!'">
                    <ng-template>
                        <p>I’m sorry, Dave. I’m afraid I can’t do that</p>
                        <msc-button class="msc-button--primary" palette="primary" (click)="onEvent()">Try to open the door anyway!</msc-button>
                    </ng-template>
                </msc-common-error>
            </div>
        </div>
    </div>
</section>
