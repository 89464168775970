import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminContentProvider, Pagination } from '@lighty';

@Component({
    selector: 'msc-camp-aside-ondemand',
    templateUrl: './aside-ondemand.component.html'
})
export class CampAsideOnDemandComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public template: any;
    public onCloseByUser: EventEmitter<any> = new EventEmitter();
    public members: any = [];
    public pagination: Pagination;
    public query: string;
    public ready: boolean = false;
    public loading: boolean = false;

    constructor(private contentProvider: AdminContentProvider) {}

    init(): void {
        this.members = [];
        this.ready = false;
        this.contentProvider.getMembersOnDemand(this.template.id).subscribe((data) => {
            this.members = data.members;
            this.pagination = data.pagination;
            this.ready = true;
        });
    }

    public onOpen(template) {
        this.template = template;
        this.init();
        this.aside.onOpen();
    }

    search(query?): void {
        this.query = query;
        this.loading = true;
        const params: any = {
            q: this.query
        };

        this.contentProvider.getMembersOnDemand(this.template.id, params).subscribe((data) => {
            this.members = data.members;
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    loadMore(): void {
        this.loading = true;

        this.contentProvider.getMembersOnDemand(this.template.id).subscribe((data) => {
            this.members = this.members.concat(data.members);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    close(): void {
        this.onClose.emit(true);
    }
}
