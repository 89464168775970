import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Company, Camp, CompanyProvider, UserProvider } from '@lighty';
import { EmitterService, StorageService, ExternalAppsService, TranslateService, RedirectService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionCollection } from '@classes';
import { DataHelper, UrlHelper } from '@helpers';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { addClass, removeClass } from '@functions';
import { CommonToastService } from '@common2/services/toast.service';
const ROLES = (company, access) => ([
    {
        label: 'words.learn',
        routerLink: ['/learn', company.slug],
        role: 'company_learner',
        hidden: !access.roles['companyLearner'] || !access.permissions.companyAccessPlatform
    },
    {
        label: 'words.create',
        customNavParams: {
            app: 'studio',
            routerLink: ['company', company.slug, 'home']
        },
        role: 'company_author',
        hidden: !access.roles['companyAuthor'] || !access.permissions.companyAccessPlatform
    },
    {
        label: 'words.coach',
        routerLink: ['/manage', company.slug],
        role: 'company_manager',
        hidden: !access.roles['companyManager'] || !access.permissions.companyAccessPlatform
    },
    {
        label: 'words.organise',
        routerLink: ['/organize', company.slug],
        role: 'company_administrator',
        hidden: !access.roles['companyAdministrator'] || !access.permissions.companyAccessPlatform
    },
]);

const JOB_ROLE_MAP = {
    'learn': 'company_learner',
    'manage': 'company_manager',
    'organize': 'company_administrator'
}

const DOCS = {
    fr: {
        learn: 'https://support.myskillcamp.com/hc/fr/categories/4859917289885-J-apprends',
        organize: 'https://support.myskillcamp.com/hc/fr/categories/4859753911837-J-organise',
        manage: 'https://support.myskillcamp.com/hc/fr/categories/4859896516381-J-accompagne'
    },
    en: {
        learn: 'https://support.myskillcamp.com/hc/en-gb/categories/4859917289885-I-learn',
        organize: 'https://support.myskillcamp.com/hc/en-gb/categories/4859753911837-I-organise',
        manage: 'https://support.myskillcamp.com/hc/en-gb/categories/4859896516381-I-coach'
    },
    nl: {
        learn: 'https://support.myskillcamp.com/hc/nl/categories/4859917289885-Ik-leer',
        organize: 'https://support.myskillcamp.com/hc/nl/categories/4859753911837-Ik-organiseer',
        manage: 'https://support.myskillcamp.com/hc/nl/categories/4859896516381-Ik-begeleid'
    }
};

const SUPPORT_WEBFORMS = {
    EN: 'https://support.myskillcamp.com/hc/en-gb/requests/new',
    FR: 'https://support.myskillcamp.com/hc/fr/requests/new',
    NL: 'https://support.myskillcamp.com/hc/nl/requests/new'
}
@Component({
    selector: 'msc-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('addPinnedContentAside') addPinnedContentAside;
    @ViewChild('activeRole') activeRole;
    @Input() company: Company;
    @Input() access: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private docs = DOCS;
    public me: any;
    public currentJob: string = 'learn';
    public displays: any;
    public campActive: Camp;
    public demoBanner: boolean = false;
    public query: string;
    public displayedQuery: string;
    public contact: any;
    public theme: any = null;
    public roles;
    public availableCompanies = [];
    public companiesPickerOpen = false;
    public addPickerOpen = false;
    public profileOpen = false;
    public searchOpen = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private emitterService: EmitterService,
        private companyProvider: CompanyProvider,
        private userProvider: UserProvider,
        private storageService: StorageService,
        private externalAppsService: ExternalAppsService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
        private redirectService: RedirectService,
        private cookieService: CookieService,
    ) {
        this.displays = {
            search: false,
            actions: false,
            help: false,
            extension: false,
            submenu: false,
            appcues: false,
            theme: false,
            compiling: false
        };
        this.getCompanies();

    }

    get companyLogo() {
        return this.company.experience?.displayPicture + '?size=512' || 'https://webservices.griky.co/cdn/img/Griky-Logotipo.webp';
    }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.roles = ROLES(this.company, this.access);
        this.subscriptionCollection.subscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.storageService.set('referrer', this.router.url);
            }

            if (event instanceof NavigationEnd) {
                this.processJob();
                this.displays.search = false;
                this.query = null;

                if (this.externalAppsService.appcues && this.externalAppsService.appcues()) {
                    this.externalAppsService.appcues().page(); // MSC-3140
                }
            }
        });

        this.processJob();
        this.listeners();
        this.getContact();
    }

    ngOnDestroy(): void {
        this.emitterService.set('global.overlay', false);
        this.subscriptionCollection.unsubscribe();
    }

    private getContact(): void {
        this.contact = this.company.experience.contact;
    }

    displayed(type: string, force?: boolean): void {
        this.displays[type] = force || !this.displays[type];
    }

    addUser(): void {
        this.router.navigate(['/organize', this.company.slug, 'users', 'action', 'invitation']);
    }

    createCamp(): void {
        this.router.navigate(['/organize', this.company.slug, 'camp']);
    }

    createSkill(): void {
        this.router.navigate(['/organize', this.company.slug, 'knowledge']);
    }

    navigate(destination: string, searching?: boolean): void {
        switch (destination) {
            case 'catalogue':
            case 'contents':
            case 'communities':
                if (destination === 'communities') {
                    this.storageService.set('communitiesAvailable', true);
                }
                if (searching) {
                    this.emitterService.set('search.' + destination, this.query);
                }
                this.router.navigate(['/learn', this.company.slug, destination]);
                break;
            case 'conversation':
                this.router.navigate(['/conversation']);
                break;
        }
    }

    openSidebar(): void {
        this.emitterService.openSidebar.emit(true);
    }

    private listeners(): void {
        this.subscriptionCollection.subscribe = this.emitterService.campActive.subscribe((camp) => {
            this.campActive = camp;
        });

        this.subscriptionCollection.subscribe = this.emitterService.refreshExperience.subscribe(() => {
            const params = {
                includes: 'experience'
            };

            this.companyProvider.getCompany(this.company.slug, params).subscribe((company) => {
                this.company = this.storageService.get('company');
            });
        });

        this.subscriptionCollection.subscribe = this.emitterService.toggleExtension.subscribe(data => {
            if (data) {
                if (data.type === 'template') {
                    this.storageService.set('extensionLinkData', data.data);
                } else if (data.type === 'camp') {
                    this.storageService.set('extensionLinkCampId', data.id);
                }
            }
            this.addPinnedContentAside.onOpen();
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('delete.camp').subscribe((data) => {
            if (data === null) {
                this.campActive = data;
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('theme.preview.on').subscribe(data => {
            if (!DataHelper.isEmpty(data)) {
                this.theme = data;

                const timeOut = setTimeout(() => {
                    this.displays.theme = true;
                    if (this.theme.validate) {
                        addClass('.app-main', 'disable-overflow');
                        addClass('.overlay', 'active');
                    }
                    clearTimeout(timeOut);
                }, 500);
            } else {
                this.theme = null;
                this.displays.theme = false;
                removeClass('.app-main', 'disable-overflow');
                removeClass('.overlay', 'active');
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('theme.compiling').subscribe((data) => {
            if (!DataHelper.isEmpty(data)) {
                this.theme = data;
                this.displays.compiling = true;
                addClass('.app-main', 'disable-overflow');
                addClass('.overlay', 'active');
            } else {
                this.displays.compiling = false;
                removeClass('.app-main', 'disable-overflow');
                removeClass('.overlay', 'active');
            }
        });
    }

    private getSegment(url: any, index: number = 1): string {
        return url.split('/')[index];
    }

    private processJob(): void {
        const segment = this.getSegment(this.router.url);

        switch (segment) {
            case 'learn':
            case 'organize':
            case 'manage':
                this.currentJob = this.storageService.setCache('currentJob', segment, null, true);
                break;
            default:
                this.currentJob = this.storageService.getCache('currentJob') || 'learn';
        }
    }

    private isLocationActive(job: string, path: string): boolean {
        const currentLocation = this.router.url.split('/');

        return currentLocation.indexOf(job) !== -1 && currentLocation.indexOf(path) !== -1;
    }

    getTimeLastDemo(): number {
        return moment(moment(this.company.billing.expiredAt).format()).diff(moment(), 'days');
    }

    closeDemoBanner(): void {
        this.demoBanner = false;
        this.storageService.deleteLocal('demoMode');
        this.storageService.setLocal('demoMode', {
            show: false,
            companyName: this.company.name
        });
    }

    search(query?): void {
        this.query = query;
        this.displays.search = false;
        const destination = this.getSegment(this.router.url, 3);

        if (destination !== 'catalogue' && destination !== 'contents') {
            this.navigate('catalogue', true);
        } else {
            this.emitterService.set('search.' + destination, this.query);
        }
    }

    displayedSearch(query?): void {
        this.query = query;
        this.searchOpen = !!this.query;
        this.displayedQuery = this.query;
    }

    support(): void {
        if (this.currentJob === 'learn') {
            if (this.externalAppsService.isBooted('freshchat')) {
                this.externalAppsService.freshchat('open');
            } else {
                this.conversationWithManager();
            }
        } else {
            if (this.externalAppsService.isBooted('freshchat')) {
                this.externalAppsService.freshchat('open');
            }
           /* if (this.externalAppsService.isBooted('zendesk')) {
                let url;
                switch (this.me.language.code) {
                    case 'en':
                    case 'de':
                    case 'es':
                    case 'it':
                        url = SUPPORT_WEBFORMS.EN;
                        break;
                    case 'fr':
                        url = SUPPORT_WEBFORMS.FR;
                        break;
                    case 'nl':
                        url = SUPPORT_WEBFORMS.NL;
                        break;
                    default:
                        break;
                }

                window.open(url, '_blank');
            } */
        }
    }

    conversationWithManager(): void {
        if (this.contact && this.contact.conversationId) {
            this.router.navigate(['conversation', this.contact.conversationId]);
        } else {
            this.storageService.set('conversationUsers', [this.contact]);
            this.router.navigate(['conversation/create']);
        }
    }

    beamer(): void {
        this.externalAppsService.beamer().show();
    }

    booted(app: string): boolean {
        return this.externalAppsService.isBooted(app);
    }

    documentation(): void {
        UrlHelper.navigate(this.docs[this.me.language.code][this.currentJob]);
    }

    appointment(): void {
        const saleName: string = this.company.saleEmail.split('@')[0].replace('.', '-');
        this.emitterService.set('global.window-embed', { type: 'sale', value: saleName });
    }

    processTheme(validate?: boolean): void {
        if (validate) {
            this.emitterService.set('theme.apply', this.theme);
        } else {
            this.emitterService.set('theme.preview.off', true);
        }

        if (this.router.url.indexOf('experiences') === -1 || this.router.url.indexOf('theme') === -1) {
            this.toastService.onInfo(this.translateService.instant('toast.redirection.progress'));

            const url = `organize/${this.company.slug}/experiences/${this.theme.experienceId}/theme`;
            this.router.navigateByUrl(url);
        }

        removeClass('.app-main', 'disable-overflow');
        removeClass('.overlay', 'active');
    }

    processRequest(): void {
        this.emitterService.set('theme.request.sale', true);
        this.processTheme();
    }

    redirect(type: string, selectedCompany): void {
        this.company = selectedCompany;
        this.cookieService.set('current_company', selectedCompany.slug, 10, '/', UrlHelper.getDomain(), false, 'Lax');
        switch (type) {
            case 'create':
                this.redirectService.navigate('studio', ['company', selectedCompany.slug, 'home']);
                break;
            case 'learn':
            case 'manage':
            case 'organize':
                this.router.routeReuseStrategy.shouldReuseRoute = function(){
                    return false;
                 }
                this.router.navigate([type, selectedCompany.slug], {});
                break;
        }
    }

    onSelectCompany(selectedCompany) {
        if (selectedCompany.availableRoles.indexOf(JOB_ROLE_MAP[this.currentJob]) === -1) {
            this.redirect('learn', selectedCompany);
        } else {
            this.redirect(this.currentJob, selectedCompany);
        }
    }

    getCompanySubtitle(count = 0): string {
        const formatter = Intl.NumberFormat('en', { notation: 'compact' });
        const translation = count === 1 ? this.translateService.instant('words.member') : this.translateService.instant('words.members');

        return `${formatter.format(count)} ${translation.toLowerCase()}`;
    }

    getCompanies() {
        return this.userProvider.getCompanies({ includes: 'roles,experience' }).subscribe((data) => {
            this.availableCompanies = data.map((company) => ({
                logo: company.experience?.displayPicture,
                title: company.experience?.displayName || company.name,
                subtitle: this.getCompanySubtitle(company.usersCount),
                availableRoles: company.roles,
                ...company
            }));
        });
    }

    navigateTo(navParams: any): void {
        this.redirectService.navigate(navParams.app, navParams.routerLink);
    }

    logout(): void {
        this.emitterService.set('global.logout', true);
    }
}
