<section class="head">
    <h3>Select</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Select</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-select [data]="dataSimple" (selectEvent)="onSelectDataSimple($event)"></msc-common-select>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Select with search</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-select [data]="dataSimple" [hasSearch]="true" (selectEvent)="onSelectDataSimple($event)"></msc-common-select>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Select with search (v2)</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-dropdown [items]="dataSimple" [(model)]="testModel" [hasSearch]="true"></msc-common-dropdown>
            </div>
            <div class="segment">
                <span>{{testModel | json}}</span>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Multi Select with search (v2)</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-dropdown [items]="dataMulti" type="multi" [(model)]="testModelMulti" [hasSearch]="true"></msc-common-dropdown>
            </div>
            <div class="segment">
                <span>{{testModelMulti | json}}</span>
            </div>
        </div>
    </div>
</section>
