<div class="content-knowledge-content box">
    <div class="content-knowledge-content__header flex">
        <h3 class="content-knowledge-content__title">
            {{ 'words.contents' | translate }}
        </h3>

        <msc-button class="msc-button--primary" palette="secondary" icon="icon-download" (click)="addContent()">
            {{ 'skill.add.content' | translate }}
        </msc-button>
    </div>

    <div class="content-knowledge-content__content">
        <div class="content-knowledge-content__sub-header">
            <p>
                {{ 'skill.add.content.description' | translate }}
            </p>
        </div>

        <div class="flex space-x-2">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

            <ng-container *ngIf="availableTypes">
                <msc-common-select class="msc-select--small" [data]="availableTypes" [isButton]="true" type="multi">
                    <ng-template #buttonTemplate let-data="data">
                        <span class="text-slate-500 text-xs font-bold">{{ 'words.types' | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <label class="flex py-2 w-full">
                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectType(datum)"></msc-common2-checkbox>
                            <span class="ml-2">{{ datum.label }}</span>
                        </label>
                    </ng-template>
                </msc-common-select>
            </ng-container>

            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                {{ 'words.filters' | translate }}
            </msc-button>
        
            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>
        </div>

        <div class="content-knowledge-content__list dynamic-table__body">
            <div class="dynamic-table__row row-radius row-shadow" *ngFor="let content of contents">
                <div class="dynamic-table__cell cell-title-content">
                    <i class="icon icon-cube color--red" *ngIf="content.type === 'course_template'"></i>

                    <i class="icon icon-trending-up color--orange" *ngIf="content.type === 'traject'"></i>

                    <i class="icon icon-map color--orange" *ngIf="content.type === 'external_content' && !content.data.provider"></i>

                    <div class="cell-title-content__picture" [style.backgroundImage]="'url(' + getLogo(content) + ')'" *ngIf="content.type === 'external_content' && content.data.provider"></div>

                    <div class="cell-title-content__desc">
                        <msc-tag [text]="getType(content) | translate" [color]="getColor(content)"></msc-tag>

                        <div [tooltip]="content.data.title.length > 40 ? content.data.title : ''">
                            {{ content.data.title | truncate: 40 }}
                        </div>
                    </div>
                </div>

                <div class="dynamic-table__cell cell-skills">
                    <div class="skill skill--shadow-none skill--level" *ngFor="let skill of content.data.skills | slice: 0:3" [tooltip]="skill.name">
                        <div class="skill__content">
                            <p class="skill__text">{{ skill.name | truncate: 15 }}</p>
                        </div>
                    </div>

                    <div class="skill skill--shadow-none skill--level hidden-xs skill--show" *ngIf="content.data.skills.length > 3" [tooltip]="content.remainingSkillsName">
                        <div class="skill__content">
                            <div class="skill__text">+ {{ content.data.skills.length - 3 }}</div>
                        </div>
                    </div>
                </div>

                <div class="dynamic-table__cell cell-actions">
                    <a (click)="redirect(content)">
                        <i class="cell-actions__icon icon icon-eye"></i>
                    </a>

                    <a (click)="unlink(content)">
                        <i class="cell-actions__icon icon icon-unlink"></i>
                    </a>
                </div>
            </div>

            <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>
    </div>
</div>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>

<msc-common-modal size="30vw" #modalUnlinkSkill [title]="'words.delete' | translate">
    <ng-template>
        <p>{{ 'skill.unlink.content' | translate : {skill: contentTitle, content: skill.name} }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalUnlinkSkill.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="confirmUnlink(content)">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
