import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntegrationProvider, Company, Integration, YoutubePlaylist, IntegrationFactory } from '@lighty';
import { StorageService, TranslateService } from '@services';

@Component({
    selector: 'msc-learn-integration',
    templateUrl: './integration.component.html',
    styleUrls: ['./integration.component.scss']
})
export class LearnIntegrationComponent implements OnInit {
    private company: Company;
    public externalLinks: Integration[];
    public googleParse = 'https://plus.google.com/_/favicon?domain_url=';
    public loading = false;

    constructor(
        private storageService: StorageService,
        private integrationProvider: IntegrationProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.loadIntegrations();
    }

    loadIntegrations() {
        this.loading = true;
        this.integrationProvider.get(this.company.slug).subscribe({
            next: (data) => {
                this.externalLinks = data.externalLinks;
            },
            complete: () => {
                this.loading = false;
            }
        });
    }
}
