import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
//
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap, mergeScan, repeat, takeUntil } from 'rxjs/operators';
//
import { TranslateService, StorageService, ExternalAppsService } from '@services';
//
import { CommonSubjectStatus, CommonApiGetListOrderParams } from '@common2/common.types';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonSelectDatum } from '@common2/components/select/select.component';
import { CommonToastService } from '@common2/services/toast.service';
import { OrganizeTableMarketplaceCatalogueDatum } from '@modules/organize/components/table-marketplace-catalogue/organize-table-marketplace-catalogue.component';
import { OrganizeTableMarketplaceContentDatum } from '@modules/organize/components/table-marketplace-content/organize-table-marketplace-content.component';
import { OrganizeToolbarMarketplaceCatalogueComponent } from '@modules/organize/components/toolbar-marketplace-catalogue/organize-toolbar-marketplace-catalogue.component';
import { OrganizeToolbarMarketplaceContentComponent } from '@modules/organize/components/toolbar-marketplace-content/organize-toolbar-marketplace-content.component';
import { OrganizeMarketplaceCatalogueApiService } from '@modules/organize/services/api/organize-marketplace-catalogue-api.service';
import { OrganizeMarketplaceLibService } from '@modules/organize/services/lib/organize-marketplace-lib.service';
import { ApiMarketplaceGetCatalogueResponse } from '@modules/organize/organize.types';

@Component({
    selector: 'msc-organize-marketplace-catalogue-page',
    templateUrl: './organize-marketplace-catalogue-page.component.html',
    styleUrls: ['./organize-marketplace-catalogue-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeMarketplaceCataloguePageComponent {
    @ViewChild(OrganizeToolbarMarketplaceCatalogueComponent) toolbarCatalogue: OrganizeToolbarMarketplaceCatalogueComponent;
    @ViewChild(OrganizeToolbarMarketplaceContentComponent) toolbarContent: OrganizeToolbarMarketplaceContentComponent;

    private company: any;
    private destroy$: Subject<void> = new Subject<void>();
    private otherParamsCatalogue: any;
    private otherParamsContent: any;
    private exceptionIdsCatalogue: Array<number> = [];
    private exceptionIdsContent: Array<number> = [];
    // catalogue table related properties
    public catalogueTableFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public catalogueTableHasLoadMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public catalogueTablePage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public catalogueTableQuery$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public catalogueTableRepeat$: Subject<void> = new Subject<void>();
    public catalogueTableRows$: BehaviorSubject<Array<OrganizeTableMarketplaceCatalogueDatum>> = new BehaviorSubject<Array<OrganizeTableMarketplaceCatalogueDatum>>([]);
    public catalogueTableRowsDirty$: BehaviorSubject<Array<OrganizeTableMarketplaceCatalogueDatum>> = new BehaviorSubject<Array<OrganizeTableMarketplaceCatalogueDatum>>([]);
    public catalogueTableStatus$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public catalogueTableTotal: number;
    // content table related properties
    public contentTableFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public contentTableHasLoadMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public contentTablePage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public contentTableQuery$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public contentTableRepeat$: Subject<void> = new Subject<void>();
    public contentTableRows$: BehaviorSubject<Array<OrganizeTableMarketplaceContentDatum>> = new BehaviorSubject<Array<OrganizeTableMarketplaceContentDatum>>([]);
    public contentTableRowsDirty$: BehaviorSubject<Array<OrganizeTableMarketplaceContentDatum>> = new BehaviorSubject<Array<OrganizeTableMarketplaceContentDatum>>([]);
    public contentTableStatus$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public contentTableTotal: number;

    public enableContentModal: boolean = false;
    public isCatalogueToolbarVisible: boolean = false;
    public isContentToolbarVisible: boolean = false;
    public isInitContent: boolean = false;
    public totalContentAvailable: number;

    get hasFiltersApplied(): boolean {
        return Object.keys(this.getParamsOnQueryCatalogue()).length > 1;
    }

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly toastService: CommonToastService,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly marketplaceCatalogueApiService: OrganizeMarketplaceCatalogueApiService,
        private readonly marketplaceLibService: OrganizeMarketplaceLibService,
        private readonly externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.onQueryCatalogue(false);
        this.onQueryCatalogueMeta();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.catalogueTableRepeat$.complete();
        this.contentTableRepeat$.complete();
    }

    /**
     * Get the params for onQueryCatalogue
     */
    getParamsOnQueryCatalogue() {
        return {
            page: this.catalogueTablePage$.getValue(),
            ...this.catalogueTableQuery$.getValue(),
        };
    }

    /**
     * Get the params for onQueryContent
     */
    getParamsOnQueryContent() {
        return {
            page: this.contentTablePage$.getValue(),
            ...this.contentTableQuery$.getValue(),
        };
    }

    /**
     * Event handler for query catalogue
     */
    onQueryCatalogue(isAppend: boolean = true): void {
        this.marketplaceCatalogueApiService
            .getContentList(this.company.id, this.company.defaultCollection, this.getParamsOnQueryCatalogue())
            .pipe(
                catchError(() => this.onCatchErrorOnQueryCatalogue()),
                tap((response) => this.onTapGetPaginationOnQueryCatalogue(response?.pagination)),
                map((response) => this.marketplaceLibService.getTableCatalogue(response.data)),
                map((data) => {
                    return data.map((datum) => {
                        if (!isAppend) {
                            return datum;
                        }
                        datum.isSelected = !!this.otherParamsCatalogue?.select_all;
                        return datum;
                    })
                }),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.catalogueTableRows$.getValue() : []),
                tap((data) => this.onTapGetDataOnQueryCatalogue(data)),
                repeat({ delay: () => this.catalogueTableRepeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Event handler for catchError for onQueryCatalogue
     */
    onCatchErrorOnQueryCatalogue(): Observable<null> {
        this.toastService.onError(this.translateService.instant('toast.organize.marketplace.catalogue.get.error'));
        this.catalogueTableStatus$.next(CommonSubjectStatus.ERROR);
        return of(null);
    }

    /**
     * Event handler for tap pagination for onQueryCatalogue
     */
    onTapGetPaginationOnQueryCatalogue(pagination: ApiMarketplaceGetCatalogueResponse['pagination']): void {
        if (!pagination) { return; }
        this.catalogueTableHasLoadMore$.next(pagination.currentPage !== pagination.lastPage);
        this.catalogueTableTotal = pagination.total;
    }

    /**
     * Event handler for tap data for onQueryCatalogue
     */
    onTapGetDataOnQueryCatalogue(data: Array<OrganizeTableMarketplaceCatalogueDatum>): void {
        this.catalogueTableRows$.next(data);
        this.catalogueTableStatus$.next(CommonSubjectStatus.SUCCESS);
        this.isCatalogueToolbarVisible = !!this.catalogueTableRows$.getValue().length;
    }

    /**
     * Event handler for load more for onQueryCatalogue
     */
    onLoadMoreCatalogue(): void {
        this.catalogueTablePage$.next(this.catalogueTablePage$.getValue() + 1);
        this.onQueryCatalogue();
    }

    /**
     * Event handler for search for onQueryCatalogue
     */
    onSearchCatalogue(value: string): void {
        this.catalogueTablePage$.next(1);
        const { q, ...query } = this.catalogueTableQuery$.getValue();
        this.catalogueTableQuery$.next({ ...query, q: value });
        this.onQueryCatalogue(false);
    }

    /**
     * Event handler for filter for onQueryCatalogue
     */
    onFilterCatalogue(datum: any): void {
        this.catalogueTablePage$.next(1);
        const { languages, types, ...query } = this.catalogueTableQuery$.getValue();
        this.catalogueTableQuery$.next({ ...query, ...datum });
        this.onQueryCatalogue(false);
    }

    /**
     * Event handler for sort for onQueryCatalogue
     */
    onSortCatalogue(params: CommonApiGetListOrderParams): void {
        this.catalogueTablePage$.next(1);
        const { order, sort, ...query } = this.catalogueTableQuery$.getValue();
        const sortParams = params.order ? params : {};
        this.catalogueTableQuery$.next({ ...query, ...sortParams });
        this.onQueryCatalogue(false);
    }

    /**
     * Event handler for query catalogue meta
     */
    onQueryCatalogueMeta(): void {
        this.marketplaceCatalogueApiService
            .getContentListMeta(this.company.id, this.company.defaultCollection)
            .pipe(
                tap((response) => this.onTapOnQueryCatalogueMeta(response.meta)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryCatalogueMeta(datum: any): void {
        const languages: Array<CommonSelectDatum<any>> = (datum.languages || []).map((language: any) => ({
            isSelected: false,
            label: language.name,
            value: language.id,
            id: Math.random(),
        }) as CommonSelectDatum<any>);

        const types: Array<CommonSelectDatum<any>> = Object.keys(datum.contentTypes || {}).map((key) => ({
            isSelected: false,
            label: this.translateService.instant(`api.content-type.${key}`),
            value: key,
            id: Math.random(),
        }));

        this.catalogueTableFilters$.next({ languages, types })
    }

    /**
     *
     */
    onEditCourseMode({ status, id }): void {
        const params = {
            companyId: this.company.id,
            status: status,
        };
        this.marketplaceCatalogueApiService
            .editCourseMode(id, params)
            .pipe(
                tap((course: any) => {
                    const data = this.catalogueTableRows$.getValue().map((row) => {
                        if (row.courseId === course.id) {
                            row.isDraft = course.editMode === 'draft';
                        }
                        return row;
                    });
                    this.catalogueTableRows$.next(data);
                })
            )
            .subscribe();
    }

    /**
     * Event handler for query content
     */
    onQueryContent(isAppend: boolean = true): void {
        this.marketplaceCatalogueApiService
            .getContentListAvailable(this.company.id, this.company.defaultCollection, this.getParamsOnQueryContent())
            .pipe(
                catchError(() => this.onCatchErrorOnQueryContent()),
                tap((response) => this.onTapGetPaginationOnQueryContent(response?.pagination)),
                map((response) => this.marketplaceLibService.getTableContent(response.data)),
                map((data) => {
                    return data.map((datum) => {
                        if (!isAppend) {
                            return datum;
                        }
                        datum.isSelected = !!this.otherParamsContent?.select_all;
                        return datum;
                    })
                }),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.contentTableRows$.getValue() : []),
                tap((data) => this.onTapGetDataOnQueryContent(data)),
                repeat({ delay: () => this.contentTableRepeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Event handler for catchError for onQueryContent
     */
    onCatchErrorOnQueryContent(): Observable<null> {
        this.toastService.onError(this.translateService.instant('toast.organize.marketplace.catalogue.get.error'));
        this.contentTableStatus$.next(CommonSubjectStatus.ERROR);
        return of(null);
    }

    /**
     * Event handler for tap pagination for onQueryContent
     */
    onTapGetPaginationOnQueryContent(pagination: ApiMarketplaceGetCatalogueResponse['pagination']): void {
        if (!pagination) { return; }
        this.contentTableHasLoadMore$.next(pagination.currentPage !== pagination.lastPage);
        this.contentTableTotal = pagination.total;
        if (!this.totalContentAvailable) {
            this.totalContentAvailable = pagination.total;
        }
    }

    /**
     * Event handler for tap data for onQueryContent
     */
    onTapGetDataOnQueryContent(data: Array<OrganizeTableMarketplaceContentDatum>): void {
        this.contentTableRows$.next(data);
        this.contentTableStatus$.next(CommonSubjectStatus.SUCCESS);
        if (!this.isInitContent) {
            this.isContentToolbarVisible = !!this.contentTableRows$.getValue().length;
            this.isInitContent = true;
        }
        this.ref.detectChanges();
    }

    /**
     * Event handler for load more for onQueryContent
     */
    onLoadMoreContent(): void {
        this.contentTablePage$.next(this.contentTablePage$.getValue() + 1);
        this.onQueryContent();
    }

    /**
     * Event handler for search for onQueryContent
     */
    onSearchContent(value: string): void {
        this.contentTablePage$.next(1);
        const { q, ...query } = this.contentTableQuery$.getValue();
        this.contentTableQuery$.next({ ...query, q: value });
        this.onQueryContent(false);
    }

    /**
     * Event handler for filter for onQueryContent
     */
    onFilterContent(datum: any): void {
        this.contentTablePage$.next(1);
        const { languages, types, ...query } = this.contentTableQuery$.getValue();
        this.contentTableQuery$.next({ ...query, ...datum });
        this.onQueryContent(false);
    }

    /**
     * Event handler for sort for onQueryContent
     */
    onSortContent(params: CommonApiGetListOrderParams): void {
        this.contentTablePage$.next(1);
        const { order, sort, ...query } = this.contentTableQuery$.getValue();
        const sortParams = params.order ? params : {};
        this.contentTableQuery$.next({ ...query, ...sortParams });
        this.onQueryContent(false);
    }

    /**
     * Event handler for query content meta
     */
    onQueryContentMeta(): void {
        this.marketplaceCatalogueApiService
            .getContentListAvailableMeta(this.company.id, this.company.defaultCollection)
            .pipe(
                tap((response) => this.onTapOnQueryContentMeta(response.meta)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentMeta(datum: any): void {
        const languages: Array<CommonSelectDatum<any>> = (datum.languages || []).map((language: any) => ({
            isSelected: false,
            label: language.name,
            value: language.id,
            id: Math.random(),
        }) as CommonSelectDatum<any>);

        const types: Array<CommonSelectDatum<any>> = Object.keys(datum.contentTypes || {}).map((key) => ({
            isSelected: false,
            label: this.translateService.instant(`api.content-type.${key}`),
            value: key,
            id: Math.random(),
        }));

        this.contentTableFilters$.next({ languages, types })
    }

    /**
     * Event handler for delete catalogue
     */
    onDeleteCatalogue(id?: number): void {
        const catalogueTableRowsSelected = this.catalogueTableRows$.getValue().filter((row) => row.isSelected);
        const ids = !id ? catalogueTableRowsSelected.map((row) => row.id) : [id];
        // const params = { collection_content_ids: ids };
        if (this.otherParamsCatalogue) {
            const queryCatalogue = this.catalogueTableQuery$.getValue();
            if (this.exceptionIdsCatalogue.length) {
                this.otherParamsCatalogue = { ...this.otherParamsCatalogue, excludedContentIds: this.exceptionIdsCatalogue };
            }
            if (queryCatalogue && Object.keys(queryCatalogue).length) {
                this.otherParamsCatalogue = { ...this.otherParamsCatalogue, ...queryCatalogue };
            }
        }
        const params = this.otherParamsCatalogue ? this.otherParamsCatalogue : { collection_content_ids: ids };
        this.marketplaceCatalogueApiService
            .deleteContentList(this.company.id, this.company.defaultCollection, params)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onDeleteCatalogueSuccess(ids.length, ids),
                error: () => this.onDeleteCatalogueError(),
            });
    }

    /**
     * Event handler for success on onDeleteCatalogue
     */
    onDeleteCatalogueSuccess(count: number, ids: Array<number>): void {
        this.toastService.onSuccess(this.translateService.instant('toast.organize.marketplace.catalogue.delete.success', { count }));
        // this.toolbarCatalogue.onUpdate([]); // reset toolbar counter
        // this.catalogueTableQuery$.next({});
        // this.catalogueTablePage$.next(1);
        const deletedCount = this.catalogueTableRows$.getValue().filter((row) => ids.includes(row.id)).length;
        const rows: OrganizeTableMarketplaceCatalogueDatum[] = this.catalogueTableRows$.getValue().filter((row) => !ids.includes(row.id));
        this.catalogueTableRows$.next(rows);
        this.toolbarCatalogue.onUpdate(rows);
        if (this.otherParamsCatalogue?.select_all) {
            this.catalogueTableTotal = 0;
        } else {
            this.catalogueTableTotal -= deletedCount;
        }

        this.isCatalogueToolbarVisible = this.hasFiltersApplied || this.catalogueTableTotal > 0;
        this.otherParamsCatalogue = undefined;
    }

    /**
     * Event handler for error on onDeleteCatalogue
     */
    onDeleteCatalogueError(): void {
        this.toastService.onError(this.translateService.instant('toast.organize.marketplace.catalogue.delete.error'));
    }

    /**
     * Event handler for add catalogue
     */
    onAddCatalogue(modal: CommonModalComponent): void {
        const contentTableRowsSelected = this.contentTableRows$.getValue().filter((row) => row.isSelected);
        const contents = contentTableRowsSelected.map((row) => ({ content_id: row.id, type: row.type }));
        if (this.otherParamsContent) {
            const queryContent = this.contentTableQuery$.getValue();
            if (this.exceptionIdsContent.length) {
                this.otherParamsContent = { ...this.otherParamsContent, excludedContentIds: this.exceptionIdsContent };
            }
            if (queryContent && Object.keys(queryContent).length) {
                this.otherParamsContent = { ...this.otherParamsContent, ...queryContent };
            }
        }
        const params = this.otherParamsContent ? this.otherParamsContent : { contents };

        this.marketplaceCatalogueApiService
            .postAddContentList(this.company.id, this.company.defaultCollection, params)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onAddCatalogueSuccess(contents.length, modal, params),
                error: () => this.onAddCatalogueError(),
            });
    }

    /**
     * Event handler for success on onAddCatalogue
     */
    onAddCatalogueSuccess(count: number, modal: CommonModalComponent, params: any): void { // Bogdan TODO
        this.toastService.onSuccess(this.translateService.instant('toast.organize.marketplace.catalogue.add.success', { count }));
        const contentTableRowsSelected = this.contentTableRows$.getValue().filter((row) => row.isSelected);
        contentTableRowsSelected.forEach((row) => row.isSelected = false);
        this.onSetMockFilters(contentTableRowsSelected);
        this.catalogueTableRows$.next([...this.catalogueTableRows$.getValue(), ...contentTableRowsSelected as any[]]);
        modal.onClose();
        this.isCatalogueToolbarVisible = !!this.catalogueTableRows$.getValue().length;
        let contentItemsCounter = 0;
        if (params.hasOwnProperty('select_all') && !!params.select_all) {
            contentItemsCounter = this.totalContentAvailable;
            if (params.hasOwnProperty('exceptionIdsContent') && Array.isArray(params.exceptionIdsContent)) {
                contentItemsCounter = contentItemsCounter - params.exceptionIdsContent.length;
            }
        } else {
            contentItemsCounter = contentTableRowsSelected.length;
        }
        this.isInitContent = false;
        this.otherParamsContent = undefined;
    }

    /**
     *
     */
    onSetMockFilters(contentTableRowsSelected: any) {
        if (!contentTableRowsSelected || !contentTableRowsSelected.length) { return; }

        const mockLanguages = contentTableRowsSelected.map(({ languageId }) => languageId);
        const mockFiltersLanguage = Array.from(new Set(mockLanguages)).map((languageId) => {
            const d = contentTableRowsSelected.find((d: any) => d.languageId === languageId);
            return { languageId, languageLabel: d.languageLabel };
        });

        const mockTypes = contentTableRowsSelected.map(({ type }) => type);
        const mockFiltersTypes = Array.from(new Set(mockTypes)).map((type) => {
            const d = contentTableRowsSelected.find((d: any) => d.type === type);
            return { type, typeLabel: d.typeLabel };
        });

        const languagesExtra: Array<CommonSelectDatum<any>> = mockFiltersLanguage.map((language: any) => ({
            isSelected: false,
            label: language.languageLabel,
            value: language.languageId,
            id: Math.random(),
        }) as CommonSelectDatum<any>);

        const typesExtra: Array<CommonSelectDatum<any>> = mockFiltersTypes.map((type) => ({
            isSelected: false,
            label: type.typeLabel,
            value: type.type,
            id: Math.random(),
        }));


        const languagesOld = this.catalogueTableFilters$.getValue().languages || [];
        const typesOld = this.catalogueTableFilters$.getValue().types || [];

        const languageIds = [...languagesOld, ...languagesExtra].map((d) => d.value);
        const languages = Array.from(new Set(languageIds)).map((languageId) => {
            return [...languagesOld, ...languagesExtra].find((d) => d.value === languageId);
        }).filter((d) => d);

        const typeTypes = [...typesOld, ...typesExtra].map((d) => d.value);
        const types = Array.from(new Set(typeTypes)).map((typeType) => {
            return [...typesOld, ...typesExtra].find((d) => d.value === typeType);
        }).filter((d) => d);

        this.catalogueTableFilters$.next({ languages, types });
        this.ref.detectChanges();
    }

    /**
     * Event handler for error on onAddCatalogue
     */
    onAddCatalogueError(): void {
        this.toastService.onError(this.translateService.instant('toast.organize.marketplace.catalogue.add.error'));
    }

    /**
     *
     */
    onSelectAllCatalogue(value: boolean) {
        if (value) {
            this.otherParamsCatalogue = { select_all: true };
        } else {
            this.otherParamsCatalogue = undefined;
        }
    }

    /**
     *
     */
    onSelectAllContent(value: boolean) {
        if (value) {
            this.otherParamsContent = { select_all: true };
        } else {
            this.otherParamsContent = undefined;
        }
    }

    /**
     * Event handler for open modal
     */
    onOpenModal(modal: CommonModalComponent): void {
        this.enableContentModal = true;
        this.contentTableQuery$.next({});
        this.onQueryContent(false);
        this.onQueryContentMeta();
        modal.onOpen();
    }

    /**
     * Event handler for close modal
     */
    onCloseModal(): void {
        this.enableContentModal = false;
        this.contentTableQuery$.next({});
        this.contentTableRows$.next([]);
        this.contentTablePage$.next(1);
        this.contentTableStatus$.next(CommonSubjectStatus.LOADING);
    }

    /**
     * Event handler for
     */
    onUpdateCatalogue(rows: Array<OrganizeTableMarketplaceCatalogueDatum>): void {
        this.toolbarCatalogue.onUpdate(rows, this.otherParamsCatalogue);
        this.exceptionIdsCatalogue = rows.filter((row) => !row.isSelected).map((row) => row.id);
    }

    /**
     * Event handler for
     */
    onUpdateContent(rows: Array<OrganizeTableMarketplaceContentDatum>): void {
        this.toolbarContent.onUpdate(rows);
        this.exceptionIdsContent = rows.filter((row) => !row.isSelected).map((row) => row.id);
    }
}
