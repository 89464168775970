import { InstanceStats } from '../instance';

export class ContentStats {
    id: number;
    title: string;
    context: string;
    instances?: InstanceStats[];
    duration?: number;
    averageProgress?: number;
    averageScore?: number;

    constructor(content: object) {
        this.assign(content);
    }

    assign(content: object): void {
        Object.assign(this, content);

        if (this.instances && this.instances.length > 0) {
            this.instances = this.instances.map((instance) => new InstanceStats(instance));
        }
    }
}
