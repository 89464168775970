<div class="card-picture">
    <div class="card-picture__picture"
        [ngStyle]="{'background-image': content.data?.picture ? ('url(' + content.data?.picture + ((content.data?.provider?.key !== 'linkedin') ? '?size=512)' : ')')) : ('url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.data?.id % 7 + '.webp)')}" (click)="navigateContent()">
    </div>
    <div class="card-header">
        <div class="flex items-center space-x-2 h-full">
            <div class="card-header__logo" [style.backgroundImage]="logo"></div>
            <msc-common-avatar class="tiny"
                *ngIf="content.type === 'external_content' && !content.data?.provider && content.data?.account"
                [label]="content.data?.account.name"
                [pictureURL]="content.data?.account.picture ? (content.data?.account.picture + '?size=256') : null">
            </msc-common-avatar>
        </div>
        <msc-tag [text]="getType(content) | translate"></msc-tag>
    </div>
    <div class="card-picture__event" *ngIf="content.data?.nextEvent">
        <msc-tag [type]="'date'" [day]="content.data?.nextEvent | date: 'dd'"
            [month]="content.data?.nextEvent | localizedDate: 'MMM'" *ngIf="content.data?.nextEvent">
        </msc-tag>
    </div>
</div>
<div class="card-content">
    <div class="card-content__meta" *ngIf="content.data?.totalDuration">
        <span>{{ content.data?.totalDuration | duration }}</span>
    </div>
    <div class="card-content__state" *ngIf="content.data?.isLocked || content.data?.state || content.data?.archived">
        <ng-container *ngIf="!content.data.archived">
            <ng-container *ngIf="!content.data?.isLocked; else isLocked">
                <ng-container *ngIf="content.data?.state === 'refused'">
                    <i class="icon icon-slash"></i>
                    <span>{{ 'words.subscription-refused' | translate }}</span>
                    <span class="action" (click)="modalShowMessage.onOpen()">{{
                        'words.see-message'
                        | translate }} <i class="icon icon-arrow-right-1"></i></span>
                </ng-container>

                <ng-container *ngIf="content.data?.state === 'pending'">
                    <i class="icon icon-clock"></i>
                    <span>{{ 'words.subscription-pending' | translate }}</span>
                </ng-container>

                <ng-container
                    *ngIf="content.data?.subscribed && content.data?.state !== 'refused' && content.data?.state !== 'pending'">
                    <i class="icon icon-checkmark-circle"></i>
                    <span>{{ (!content.data.isDownloadable ? 'words.already-subscribed' :
                        'bookboon.already-downloaded') | translate }}</span>
                </ng-container>
            </ng-container>

            <ng-template #isLocked>
                <i class="icon icon-lock"></i>
                <span>{{ 'words.subscription-locked' | translate }}</span>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="content.data.archived">
            <i class="icon icon-slash"></i>
            <span>{{ 'words.content-archived' | translate }}</span>
        </ng-container>
    </div>
    <div class="card-content__tags space-x-2" *ngIf="content.data?.skills && content.data?.skills.length > 0">
        <div class="tag border" *ngFor="let skill of content.data?.skills | slice:0:2"
            [tooltip]="skill.name.length > 10 ? skill.name : ''">
            <i class="icon icon-bar-chart-2"></i>
            <span>{{ skill.name }}</span>
        </div>

        <div class="tag border" *ngIf="content.data?.skills.length > 2" [tooltip]="skillList">
            <span>...</span>
        </div>
    </div>
    <h3 class="card-content__title" (click)="navigateContent()">{{ content.data?.title }}</h3>
    <div class="card-content__description">
        <p [innerHtml]="content.data?.description"></p>
    </div>
</div>
<div class="card-footer">
    <div class="card-footer__actions">
        <div class="flex space-x-2">
            <ng-container *ngIf="!content.data?.archived && !content.data?.isLocked">
                <msc-button class="msc-button--primary" icon="icon-download" palette="primary" type="fab" [tooltip]="'words.download' | translate"
                    [loading]="loading.bookboon" (click)="subscribeAndDownloadExternalContent()"
                    *ngIf="content.type !== 'external_video' && (content.type === 'external_content' && content.data?.provider) && content.data?.isDownloadable">
                </msc-button>
                <ng-container *ngIf="content.data.externalContentType !== 'audio'">
                    <msc-button [tooltip]="'words.launch' | translate" class="msc-button--primary" palette="primary"
                    type="fab" icon="icon-arrow-right"
                    *ngIf="(content.data.subscribed || content.type === 'external_video') && content.data?.state !== 'pending' && content.data?.state !== 'refused' && !content.data?.isDownloadable"
                    [loading]="loading?.trainingexpress"
                    (click)="playContent()"></msc-button>
                </ng-container>
            </ng-container>
            <msc-button class="msc-button--primary" palette="primary" type="fab" [tooltip]="'words.modify' | translate"
                *ngIf="content.data?.account?.id === me.id && content.data?.type === 'external_resource'"
                icon="icon-edit-2" (click)="editContent();">
            </msc-button>
            <msc-button *ngIf="content.data?.type != 'boclips'" class="msc-button--primary" palette="primary" type="fab" icon="icon-search"
                [tooltip]="'words.details' | translate" (click)="navigateContent()"></msc-button>
        </div>

        <div class="flex space-x-2" *ngIf="content.data?.type == 'boclips'">
            <msc-button *ngFor="let video of content.data.video" class="msc-button--primary" type="text" icon="icon-arrow-right"
                [tooltip]="'words.launch' | translate"
                (click)="openVideo(video.url)">
                {{video.resolution}}
                <span *ngIf="content.data?.format != 'PODCAST'">p</span>
                <span *ngIf="content.data?.format == 'PODCAST'">Audio</span>
            </msc-button>
        </div>

        <div *ngIf="content.data?.type != 'boclips'">
            <msc-button *ngIf="content.data?.subscribed" type="fab" (click)="deleteCourse()" icon="icon-trash-2"
                [tooltip]="(content.data?.subscriptionOrigins?.isFromGroup ? 'tooltip.delete.community.not-allowed' : 'words.unsubscribe') | translate"
                [disabled]="content.data?.subscriptionOrigins?.isFromGroup"></msc-button>
            <msc-button *ngIf="!content.data.subscribed" type="fab" [palette]="content.saved ? 'primary' : 'slate'"
                (click)="changeLearnLaterContent()" [icon]="content.saved ? 'icon-bookmark-1' : 'icon-bookmark'"
                [tooltip]="'words.bookmark' | translate"></msc-button>
        </div>
    </div>

    <div class="card-footer__progress" *ngIf="content.data?.subscribed">
        <msc-progress-bar [progress]="content.data?.progress" [color]="content.data?.progress !== 100 ? 'blue': ''">
        </msc-progress-bar>
    </div>
</div>

<msc-common-modal size="30vw" #modalShowMessage [title]="'instance.modal.refused-message.title' | translate">
    <ng-template>
        <div class="avatar-contact modal__refused--manager">
            <msc-common-avatar [label]="content.data?.validation.name"
                [pictureURL]="content.data?.validation.picture ? (content.data?.validation.picture + '?size=64') : null">
            </msc-common-avatar>
            <div class="avatar__content">
                <p class="profile__name">{{ content.data?.validation.name }}</p>
            </div>
        </div>

        <p>{{ content.data?.validation.comment }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="deleteCourse(true); modalShowMessage.onClose()">
                {{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="modalShowMessage.onClose()">{{'common.save'
                | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalBoclip [title]="content.data.title">
    <ng-template>
        <div class="flex items-center">
        <video #videoPlayer controls playsinline width="640" height="360">
                Your browser does not support this player.
              </video>
        </div>
        <div>
            <h3>{{'extension.infos.description-label' | translate}}</h3>
            <br>
            <p>{{content.data.description}}</p>
        </div>

    </ng-template>
</msc-common-modal>
<msc-card-manager-modal-delete-subscription #modalUnsubscribe></msc-card-manager-modal-delete-subscription>
