import { Component, Input, OnInit } from '@angular/core';
import { ReportingProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import * as _ from 'lodash';

@Component({
    selector: 'msc-manage-instance-learner-stats-exercise',
    templateUrl: './stats-exercise.component.html',
    styleUrls: ['./stats-exercise.component.scss']
})
export class ManageInstanceLearnerStatsExerciseComponent implements OnInit {
    @Input() templateDetails;
    private company: any;
    public cards: Array<any>;
    public columns: Array<any>;
    public rows: Array<any>;
    public isLoading: boolean;

    constructor(
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly reportingProvider: ReportingProvider,
    ) {
        this.company = this.storageService.get('company');
        this.columns = this.getColumns();
    }

    ngOnInit() {
        this.rows = this.templateDetails.questions.map((question)=>({
            ...question,
            label: this.translateService.instant(`stats.content.type-${question.type.url}`),
        }))
        
        this.onConcatMapOnQueryContentUserStatList();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '30%',
            },
            {
                key: 'time',
                label: this.translateService.instant('stats.hour'),
                width: '20%',
            },
            {
                key: 'progress',
                label: this.translateService.instant('words.progress'),
                width: '25%',
            },
            {
                key: 'result',
                label: this.translateService.instant('words.score'),
                width: '25%',
            },
        ];
    }

    /**
    *
    */
    onConcatMapOnQueryContentUserStatList() {
        this.isLoading = true;
        const ids = this.templateDetails.questions.map((row) => row.id);
        return this.reportingProvider
            .getActivitySubscriptionDetails(this.company.id, this.templateDetails.contentId, this.templateDetails.userId, ids, 'question')
            .subscribe({
                next: (data) => {
                    this.rows = this.rows.map((row) => ({
                        ...row,
                        duration: data[row.id]?.context.duration,
                        progress: data[row.id]?.context.isDone ? 100 : 0,
                        score: Math.round(data[row.id]?.context.ratio * 100)
                    }));
                },
                complete: () => this.isLoading = false
            });
    }
}
