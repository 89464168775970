import { Component, OnInit, Input } from '@angular/core';
import { UrlHelper } from '@helpers';

@Component({
    selector: 'msc-link-manager',
    templateUrl: './link-manager.component.html'
})
export class LinkManagerComponent implements OnInit {
    @Input() content: string;
    @Input() backgroundColor: string;
    @Input() timeline: boolean = false;
    public links: string[] = [];

    constructor() {}

    ngOnInit(): void {
        if (this.content) {
            const splitter = this.content.split(/(\s|<br>)/gm);
            for (const item of splitter) {
                if (UrlHelper.test(item)) {
                    this.links.push(item);
                }
            }
        }
    }
}
