import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CommonListDatum } from '@common2/components/list/list.component';
//
import { CommonTreeDatum } from '@common2/components/tree/tree.component';

@Component({
    selector: 'msc-showroom-exhibit-tree-page',
    templateUrl: './showroom-exhibit-tree-page.component.html',
    styleUrls: ['./showroom-exhibit-tree-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitTreePageComponent {
    public tree1: Array<CommonTreeDatum<string>>;
    public tree2: Array<CommonTreeDatum<string>>;
    public tree3: Array<CommonTreeDatum<string>>;
    public list: Array<CommonListDatum<string>>;

    ngOnInit(): void {
        this.tree1 = this.getTree(1);
        this.tree2 = this.getTree(2);
        this.tree3 = this.getTree(3);
        this.list = this.getList(3);
    }

    /**
     *
     */
    getTree(surface: number, depth: number = 0): Array<CommonTreeDatum<string>> {
        return new Array(5).fill(null).map(() => ({
            children: surface !== depth ? this.getTree(surface, depth + 1) : undefined,
            id: Math.random().toString(36).replace(/[^a-z]+/g, ''),
            isSelected: false,
            label: `Leaf-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            value: `Leaf-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
        }));
    }

    /**
     *
     */
    getList(surface: number, depth: number = 0): Array<CommonListDatum<string>> {
        return new Array(5).fill(null).map(() => ({
            children: surface !== depth ? this.getList(surface, depth + 1) : undefined,
            id: Math.random().toString(36).replace(/[^a-z]+/g, ''),
            label: `Leaf-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            value: `Leaf-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            icon: 'icon-content',
            picture: 'https://picsum.photos/200'
        }));
    }

    logData(){
        console.log(this.list)
    }
}
