<msc-common-aside #aside [hasActionButtons]="false">
    <ng-template #headerTemplate>
        <div class="flex flex-col space-y-4 relative">
            <msc-button class="absolute right-2 top-2" type="fab" icon="icon-close" (click)="aside.onClose()">
            </msc-button>

            <h3>{{ 'group.resources.title' | translate }} : {{ currentGroup.title }}</h3>
        </div>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4 slide-community-resource">
            <h2>{{'group.resources.published-resources' | translate}}</h2>

            <div class="dynamic-table">
                <div class="dynamic-table__head">
                    <div class="dynamic-table__cell cell-title--small"></div>
                    <div class="dynamic-table__cell cell-actions"></div>
                </div>

                <div class="dynamic-table__body">
                    <div class="dynamic-table__row row-radius row-shadow row-hover"
                        *ngFor="let resource of resourcesCollection">
                        <msc-shared-resource [resource]="resource"></msc-shared-resource>

                        <div class="dynamic-table__cell cell-actions">
                            <a (click)="deleteResource(resource)">
                                <i class="cell-actions__icon icon icon-trash-2"></i>
                            </a>
                        </div>
                    </div>
                    <msc-empty-state *ngIf="(!resourcesCollection || resourcesCollection.length <= 0) && !loading"
                        [title]="'words.resources'" [description]="'empty.resources'">
                    </msc-empty-state>

                    <msc-common-spinner *ngIf="loading"></msc-common-spinner>
                </div>
            </div>

            <h2>{{ 'words.add-resources' | translate }}</h2>

            <div class="add-resource">
                <msc-input id="fileNameResource" name="resource-name" [(ngModel)]="data.title"
                    [placeholder]="'group.resources.resource-placeholder' | translate"></msc-input>
                <input #fileUploadResource class="display-none" type="file" fileUpload fileUrl="media"
                    [disabled]="data.title === ''"
                    (fileStatus)="$event.value?.id ? uploadResource($event.value.id) : onUploadError($event)">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-plus-circle"
                    [disabled]="data.title === ''" (click)="fileUploadResource.click(); showError()">
                    {{ 'words.add' | translate }}
                </msc-button>
            </div>
            <p class="message-caution" *ngIf="showErrorMsg">{{'group.resources.resource-placeholder' | translate}}</p>
        </div>
    </ng-template>
</msc-common-aside>