import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Company, ContentProvider, CompanyUserAccessManageProvider, ManageTeamProvider } from '@lighty';
import { EmitterService, StorageService, TranslateService } from '@services';
import { DataHelper, HttpHelper } from '@helpers';

const FILTER_DATE_OPTIONS = [{
        label: 'words.addition-date',
        value: '-date',
    }, {
        label: 'A-Z',
        value: 'title'
    }, {
        label: 'Z-A',
        value: '-title'
    }];

@Component({
    selector: 'msc-onboarding-topbar-filters',
    templateUrl: './onboarding-filters.component.html',
    styleUrls: ['./onboarding-filters.component.scss']
})
export class OnboardingTopbarFiltersComponent implements OnInit, AfterViewInit {
    @Input() isTeam = false;
    @Input() type: string;
    @Input() color: string = '';
    @Input() query: string;
    @Input() mode: string;
    @Input() filters: any;
    @Input() activeFilters: boolean = false;
    @Input() accountId: number;
    @Input() context;
    @Input() buildFilters: EventEmitter<any> = new EventEmitter();
    //
    @Output() queryChange: EventEmitter<any> = new EventEmitter();
    @Output() modeChange: EventEmitter<any> = new EventEmitter();
    @Output() filtersChange: EventEmitter<any> = new EventEmitter();
    @Output() activeFiltersChange: EventEmitter<any> = new EventEmitter();
    @Output() onlySelectionChange: EventEmitter<any> = new EventEmitter();
    //
    private me: any;
    private campIds: number[] = [];
    public company: Company;
    public onlySelection: boolean = false;
    public isFiltersVisible: boolean;
    public orderDate: string = '-date';
    public selectData: any[] = [];
    public selectFilterData: any;
    public displays: any;

    constructor(
        private contentProvider: ContentProvider,
        private companyUserAccessManageProvider: CompanyUserAccessManageProvider,
        private emitterService: EmitterService,
        private teamProvider: ManageTeamProvider,
        private storageService: StorageService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');

        if (this.storageService.getCache('context.campIds')) {
            this.campIds = this.storageService.getCache('context.campIds').map((camp) => camp.id);
        }

        this.displays = {
            search: false,
            types: false,
            providers: false,
            languages: false,
            orderDate: false
        };

        this.selectData = this.getSelectData(FILTER_DATE_OPTIONS);

        this.getMeta();

        this.buildFilters.subscribe((filters) => {
            this.activeFilters = true;
            this.activeFiltersChange.emit(this.activeFilters);
            DataHelper.merge(this.filters, filters);
            this.filtersChange.emit(this.filters);
        });
    }

    ngAfterViewInit(): void {
        const timeOut = setTimeout(() => {
            this.getStoredFilters();
            clearTimeout(timeOut);
        }, 150);
    }

    getOrderDate(): string {
        switch (this.orderDate) {
            case '-date':
                return 'words.addition-date';
            case 'title':
                return 'A-Z';
            case '-title':
                return 'Z-A';
        }
    }

    getSelectData(data) {
        return data.map((d, index) => {
            const id = index;
            const label = d.label;
            const value = d.value;
            const isSelected = d.label === this.getOrderDate();
            return { id, label, value, isSelected };
        });
    }

    getSubTypesOptions(data: any, key: string) {
        const subtypes = data[`${key}Types`];
        if (!subtypes) {
            return [];
        }

        return Object.keys(subtypes)
            .map((subtype, idx) => ({
                id: `${subtype}-${idx}`,
                label: this.translateService.instant(`words.course-type.${subtype}`),
                value: subtype,
                isSelected: false
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getTypesOptions(data: any) {
        return Object.keys(data.contentTypes)
            .map((type, index) => ({
                id: `${type}-${index}`,
                label: this.translateService.instant(`api.content-type.${type.split(/(?=[A-Z])/).join('_').toLowerCase()}`),
                value: type.split(/(?=[A-Z])/).join('_').toLowerCase(),
                isSelected: false,
                children: this.getSubTypesOptions(data, type)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getProvidersOptions(providers) {
        const label = this.company.experience
            ? this.company.experience.displayName
                ? this.company.experience.displayName
                : this.company.experience.name
            : this.company.name;

        return providers.map(provider => ({
            id: provider.id,
            label: provider.id === 'company' ? label : provider.name,
            value: provider.id,
            isSelected: false
        }));
    }

    getLanguagesOptions(languages) {
        return languages.map((lang, idx) => ({
            id: idx,
            label: lang.id === 'undefined' ? this.translateService.instant('words.multilingual') : lang.name,
            value: lang.id,
            isSelected: false
        }));
    }

    getSelectFilterData(data) {
        const types = this.getTypesOptions(data);
        const providers = this.getProvidersOptions(data.providers);
        const languages = this.getLanguagesOptions(data.languages)

        return { types, providers, languages };
    }

    private getMeta(): void {
        const params = HttpHelper.cleanParams({
            camp_ids: this.campIds.length > 0 ? this.campIds.join(',') : null
        });

        if (this.mode === 'coach') {
            this.companyUserAccessManageProvider.getAvailableForCoachMeta(this.company.id, params).subscribe((meta) => {
                this.selectFilterData = this.getSelectFilterData(meta);
            });
        } else if (this.context === 'subscribe') {
            this.teamProvider.getUserContentsMeta(this.company.id, this.me.teamOwnerId, this.accountId).subscribe((data) => {
                this.selectFilterData = this.getSelectFilterData(data.meta);
            })
        } else {
            if (this.isTeam) {
                this.contentProvider.getCoachOnboardingContentsMeta(this.company.id, this.accountId, params).subscribe((meta) => {
                    this.selectFilterData = this.getSelectFilterData(meta);
                });
            } else {
                this.contentProvider.getOnboardingContentsMeta(this.company.id, params).subscribe((meta) => {
                    this.selectFilterData = this.getSelectFilterData(meta);
                });
            }
        }
    }

    private getStoredFilters(): void {
        const filters = this.storageService.getAllCache().filter((record: any) => {
            return record.key.match(`onboarding.filter.${this.context}.`);
        });

        for (const filter of filters) {
            switch (filter.key) {
                case `onboarding.filter.${this.context}.order`:
                    this.orderDate = filter.data;
                    this.filters = DataHelper.merge(this.filters, { order: this.orderDate });
                    break;
                default:
                    this.filters = DataHelper.merge(this.filters, { [filter.key.replace(`onboarding.filter.${this.context}.`, '')]: filter.data });
            }
        }

        if (filters?.length > 0) {
            this.activeFilters = true;
            this.activeFiltersChange.emit(this.activeFilters);

            this.filtersChange.emit(this.filters);
        }
    }

    private storeFilters(): void {
        for (const key in this.filters) {
            if (this.filters.hasOwnProperty(key)) {
                if (!DataHelper.isEmpty(this.filters[key])) {
                    this.storageService.setCache(`onboarding.filter.${this.context}.${key}`, this.filters[key], 10);
                }
            }
        }
    }

    private removeStoredFilters(): void {
        const filters = this.storageService.getAllCache().filter((record: any) => {
            return record.key.match(`onboarding.filter.${this.context}.`);
        });

        for (const filter of filters) {
            this.storageService.deleteCache(filter.key);
        }
    }

    getIterativeCheck(parent: any, selected: any) {
        if (parent.children && parent.children.length) {
            parent.children = parent.children.map((child: any) => {
                if (parent.id === selected.id) {
                    child.isSelected = parent.isSelected;
                }
                return child;
            });
            return parent;
        } else {
            return parent;
        }
    }

    getSubTypesFilters(key: string) {
        const subTypes = this.selectFilterData.types.find(t => t.value === key && t.isSelected);

        return subTypes ? subTypes.children.filter(t => t.isSelected).map(t => t.value) : [];
    }

    setFilters(): void {
        this.filters = DataHelper.merge(this.filters, { langs: this.selectFilterData.languages.filter(l => l.isSelected).map(l => l.id) });
        this.filters = DataHelper.merge(this.filters, { providers: this.selectFilterData.providers.filter(p => p.isSelected).map(p => p.id) });
        this.filters = DataHelper.merge(this.filters, { contentTypes: this.selectFilterData.types.filter(t => t.isSelected).map(t => t.value) });
        this.filters = DataHelper.merge(this.filters, { courseTypes: this.getSubTypesFilters('course') });
        this.filters = DataHelper.merge(this.filters, { externalContentTypes: this.getSubTypesFilters('external_content') });
        this.filters = DataHelper.merge(this.filters, { externalResourceTypes: this.getSubTypesFilters('external_resource') });
        this.filters = DataHelper.merge(this.filters, { order: this.orderDate });

        this.activeFilters = true;
        this.activeFiltersChange.emit(this.activeFilters);
        this.filtersChange.emit(this.filters);

        this.storeFilters();
    }

    selectOrderDate(order: string): void {
        this.orderDate = order;

        this.activeFilters = true;
        this.activeFiltersChange.emit(this.activeFilters);
        this.filters = DataHelper.merge(this.filters, { order: this.orderDate });
        this.filtersChange.emit(this.filters);
        this.storeFilters();
    }

    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });

        this.selectOrderDate(option.value);
    } 

    onSelectFilterOption(option, key: string) {
        option.isSelected = !option.isSelected
        this.selectFilterData[key] = this.selectFilterData[key].map(data => this.getIterativeCheck(data, option));
    }

    onToggleFilters(force?: boolean | undefined) {
        this.isFiltersVisible = force ?? !this.isFiltersVisible;
    }

    onFilter() {
        this.onToggleFilters(false);
        this.setFilters();
    }

    resetFiltersOptions(): void {
        Object.keys(this.selectFilterData).forEach(key => {
            this.selectFilterData[key] = this.selectFilterData[key].map(d => {
                d.isSelected = false;
                if (d.children && d.children.length) {
                    d.chilren = d.children.map(child => {
                        child.isSelected = false;
                        return child;
                    })
                }
                return d;
            });
        });
    }

    onFiltersReset(): void {
        this.query = null;
        this.filters = {};
        this.orderDate = '-date';
        this.activeFilters = false;
        this.activeFiltersChange.emit(this.activeFilters);
        this.filtersChange.emit(this.filters);
        this.resetFiltersOptions();
        this.removeStoredFilters();
    }

    search(query?): void {
        this.query = query;
        this.queryChange.emit(this.query);
    }

    enableSearch(): void {
        this.displays.search = true;
    }

    showMySelection(): void {
        this.onlySelection = !this.onlySelection;
        this.onlySelectionChange.emit(this.onlySelection);
    }

    toggleExtension(): void {
        this.emitterService.toggleExtension.emit();
    }
}
