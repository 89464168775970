import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ApiCompanyUrlItem } from '@lighty';

export interface UiCompanyUrlItem {
    counter?: number;
    id: number;
    image?: string;
    isActive: boolean;
    isValid: boolean;
    label: string;
    type?: string;
}

export function getRemappedCompanyUrlApiToUi(datum: ApiCompanyUrlItem, companyCurrentExperience: any): UiCompanyUrlItem {
    return {
        counter: datum.experiencesCount,
        id: datum.id,
        image: datum.media && datum.media.pictureUrl + '?size=32' || undefined,
        isActive: !!(datum.experiences || []).find((experience) => experience.id === companyCurrentExperience.id),
        isValid: !!datum.status,
        label: datum.url,
        type: datum.type
    };
}

@Component({
    selector: 'msc-organize-experience-watch-url-item',
    templateUrl: './url-item.component.html',
    styleUrls: ['./url-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchUrlItemComponent {
    @Input() datum: UiCompanyUrlItem;
    @Output() activate: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Event handler for activate
     */
    onActivate(): void {
        this.activate && this.activate.emit();
    }

    /**
     * Event handler for edit
     */
    onEdit(): void {
        this.edit && this.edit.emit();
    }
}
