<h3>{{ 'stats.title' | translate }}</h3>

<div class="stats-cards">
    <ng-container *ngFor="let card of cards">
        <div class="stats-card">
            <i class="icon icon-{{ card.icon }} stats-icon-card"></i>
            <p>
                <span>{{ card.label }}</span>&nbsp;
                <span>{{ card.value }}</span>
            </p>
        </div>
    </ng-container>
</div>

<div class="toolbar">
    <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
</div>

<div class="content">
    <ng-container [ngSwitch]="status$ | async">
        <ng-container *ngSwitchCase="'success'">
            <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                <ng-container *ngFor="let row of rows$ | async">
                    <msc-common-accordion [title]="row.title" [icon]="row.icon" (openEvent)="onOpen(row)">
                        <ng-template>
                            <ng-container *ngIf="row?.hasDetails">
                                <div class="content-item-details" [style.height]="row.height">
                                    <msc-common-table [columns]="columns" [rows]="row.instances">
                                        <ng-template #thTemplate let-datum="datum">
                                            <span>{{ datum.label }}</span>
                                        </ng-template>
                                        <ng-template #thTemplate let-datum="datum">
                                            <span></span>
                                        </ng-template>
                                        <ng-template #thTemplate let-datum="datum">
                                            <span>{{ datum.label }}</span>
                                        </ng-template>
                                        <ng-template #thTemplate let-datum="datum">
                                            <span>{{ datum.label }}</span>
                                        </ng-template>
                                        <ng-template #thTemplate let-datum="datum">
                                            <span></span>
                                        </ng-template>

                                        <ng-template #tdTemplate let-datum="datum">
                                            <span>{{ datum.title }}</span>
                                        </ng-template>
                                        <ng-template #tdTemplate let-datum="datum">
                                            <span>
                                                <i class="icon icon-clock icon-table"></i>
                                                {{ datum.duration ? (datum.duration | duration) : 0 + 's' }}
                                            </span> &nbsp;&nbsp;
                                            <span>
                                                <i class="icon icon-people icon-table"></i>
                                                {{ datum.countLearner }}
                                            </span>
                                        </ng-template>
                                        <ng-template #tdTemplate let-datum="datum">
                                            <ng-container *ngIf="datum.isChartProgressVisible; else emptyChartProgressTemplate">
                                                <msc-chart-pie [size]="'small'" [value]="datum.chartProgress"></msc-chart-pie>
                                            </ng-container>
                                            <ng-template #emptyChartProgressTemplate>
                                                <span>{{ 'common.empty' | translate }}</span>
                                            </ng-template>

                                        </ng-template>
                                        <ng-template #tdTemplate let-datum="datum">
                                            <ng-container *ngIf="datum.isChartScoreVisible; else emptyChartScoreTemplate">
                                                <msc-chart-pie [size]="'small'" [value]="datum.chartScore"></msc-chart-pie>
                                            </ng-container>
                                            <ng-template #emptyChartScoreTemplate>
                                                <span>{{ 'common.empty' | translate }}</span>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template #tdTemplate let-datum="datum">
                                            <msc-button type="fab" icon="icon-arrow-ios-right" (click)="onGoToDetails(row, datum)"></msc-button>
                                        </ng-template>
                                    </msc-common-table>
                                </div>
                            </ng-container>
                        </ng-template>
                    </msc-common-accordion>
                </ng-container>
            </ng-container>

            <ng-template #emptyTemplate>
                <msc-common-empty [message]="'common.empty' | translate">
                    <ng-template></ng-template>
                </msc-common-empty>
            </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'loading'">
            <msc-common-spinner *ngIf="true"></msc-common-spinner>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
            <msc-common-error>
                <ng-template></ng-template>
            </msc-common-error>
        </ng-container>
    </ng-container>
</div>

<ng-container *ngIf="hasLoadMore">
    <div class="load-more">
        <msc-button class="msc-button--primary" palette="primary" (click)="onLoad()">{{ 'words.load-more' | translate }}</msc-button>
    </div>
</ng-container>
