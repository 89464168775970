import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@services';

@Component({
    selector: 'msc-necromancer',
    template: ''
})
export class NecromancerComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService) {}

    ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.routing(data.type);
        });
    }

    private routing(type: string): void {
        const params = this.route.snapshot.params;
        const queryParams = this.route.snapshot.queryParams;

        switch (type) {
            case 'login':
                this.storageService.set('lang', params.lang);
                this.router.navigate(['login'], {queryParams});
                break;
            case 'conversation':
                this.router.navigate(['conversation', params.slug]);
                break;
            case 'account':
                this.router.navigate(['account', this.transformAccountType(params.type)]);
                break;
            case 'component':
                if (params.misc) {
                    const contentType = this.transformContentType(params.type);
                    if (contentType) {
                        this.router.navigate(['learn', params.slug, 'detail', contentType, params.misc]);
                    } else {
                        this.router.navigate(['learn', params.slug, this.transformComponentType(params.type), params.misc]);
                    }
                } else {
                    this.router.navigate(['learn', params.slug, params.type]);
                }
                break;
        }
    }

    private transformAccountType(type: string): string {
        switch (type) {
            case 'password':
                return 'settings';
            default:
                return type;
        }
    }

    private transformContentType(type: string): string {
        switch (type) {
            case 'content':
                return 'template';
            case 'journey':
            case 'instance':
            case 'edflex':
                return type;
            default:
                return null;
        }
    }

    private transformComponentType(type: string): string {
        switch (type) {
            case 'group':
                return 'groups';
            default:
                return type;
        }
    }
}
