<msc-common-aside #aside [title]="'words.merge' | translate" (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div>
                <p class="mb-2">{{ 'skill.merge' | translate }}</p>

                <div class="flex flex-wrap p-2">
                    <div class="skills__item" *ngFor="let skill of selectedSkills; let index=index">
                        <div class="skill skill--shadow-none skill--level skill--selected">
                            <div class="skill__content">
                                <p class="skill__text">{{ skill.name }}</p>

                                <a class="skill__close" (click)="removeSkill(index)">
                                    <i class="icon icon-close"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col space-y-2">
                <p>{{ 'skill.destination' | translate }}</p>

                <msc-common-search class="tiny" trigger="auto" [placeholder]="'placeholder.type-skill' | translate"
                (searchEvent)="search($event.value)"></msc-common-search>

                <div class="flex flex-wrap p-2">
                    <div class="skills__item" *ngFor="let skill of skills">
                        <div class="skill skill--shadow-none skill--level skill--selected cursor-pointer"
                            (click)="selectSkill(skill.id)" [ngClass]="{'bg-secondary-200': activeSkill === skill.id}">
                            <div class="skill__content">
                                <p class="skill__text" [ngClass]="{'text-white': activeSkill === skill.id}">{{ skill.name }}</p>
                            </div>
                        </div>
                    </div>

                    <span *ngIf="skills && skills.length === 0 && !loading">
                        {{ 'empty.skill' | translate }}
                    </span>
                </div>

                <msc-common-spinner *ngIf="loading"></msc-common-spinner>
            </div>
        </div>
    </ng-template>
</msc-common-aside>