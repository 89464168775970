import { Component, ChangeDetectionStrategy, Input, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';

import { Subject } from 'rxjs';

@Component({
    selector: 'msc-common-input-radio',
    templateUrl: './input-radio.component.html',
    styleUrls: ['./input-radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ]
})

export class CommonInputRadioComponent {
    @Input() controlName: string;
    @Input() label?: string;
    @Input() value: any;

    public id: string = `id-input-radio-${Math.random()}`;

    private destroy$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
