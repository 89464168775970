import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Camp, AdminJourneyProvider, AdminJourneyMemberProvider, Pagination } from '@lighty';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-camp-aside-subscription',
    templateUrl: './aside-subscription.component.html'
})
export class CampAsideSubscriptionComponent {
    @ViewChild('aside') aside;
    @ViewChild('modalConfirm') modalConfirm: CommonModalComponent;
    @Input() camp: Camp;
    @Input() journey: any;
    @Input() externalContent: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public templates: any[] = [];
    public data: any;
    public instancesSelected: number[] = [];
    public availableMembers: any[] = [];
    public pagination: Pagination;
    public query: string;
    public onCloseByUser: EventEmitter<any> = new EventEmitter();
    public loaders: any;
    public tab: string = 'users';
    public displays: boolean[] = [];
    public missingInstance: boolean = false;

    constructor(
        private journeyProvider: AdminJourneyProvider,
        private journeyMemberProvider: AdminJourneyMemberProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.loaders = {
            loading: true,
            loadmore: false
        };

        if (this.journey) {
            this.loaders.templates = true;
            this.journeyProvider.getTemplates(this.camp.id, this.journey.id, { events_filter: 1 }).subscribe((data) => {
                this.templates = data;
                this.autoSelectedInstances();

                for (let i = 0; i < this.templates.length; i++) {
                    if (this.templates[i].instances?.length > 0) {
                        this.displayed(i);
                        break;
                    }
                }
                this.loaders.templates = false;
            });

            this.journeyMemberProvider.getAvailableMembers(this.camp.id, this.journey.id).subscribe((data) => {
                this.availableMembers = data.users;
                this.pagination = data.pagination;
                this.loaders.loading = false;
            });
        }

        this.data = {
            instanceIds: [],
            accountIds: [],
            invite_all_accounts: false
        };
    }

    public onOpen(item) {
        this.journey = item;
        this.init();
        this.aside.onOpen();
    }

    private autoSelectedInstances(): void {
        this.templates.forEach((template, index) => {
            if (template.instances?.length > 0) {
                this.instancesSelected[index] = template.instances[0].id;
            }
        });
    }

    isSelected(index: number, id: number): boolean {
        return this.instancesSelected[index] === id;
    }

    selectInstance(index: number, id: number): void {
        this.instancesSelected[index] = id;
    }

    inviteAll(): void {
        this.data.invite_all_accounts = !this.data.invite_all_accounts;

        for (const member of this.availableMembers) {
            const index = this.data.accountIds.findIndex((accountId) => {
                return member.id === accountId;
            });

            if (this.data.invite_all_accounts) {
                if (index === -1) {
                    this.data.accountIds.push(member.id);
                }
            } else {
                if (index > -1) {
                    this.data.accountIds.splice(index, 1);
                }
            }
        }
    }

    selectMember(id: number): void {
        const index = this.data.accountIds.findIndex((accountId) => {
            return id === accountId;
        });

        if (index > -1) {
            this.data.accountIds.splice(index, 1);
        } else {
            this.data.accountIds.push(id);
        }
    }

    isUserSelected(id: number): boolean {
        return this.data.accountIds.findIndex((accountId) => {
            return accountId === id;
        }) > -1;
    }

    search(query?): void {
        this.query = query;
        this.loaders.loading = true;

        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.journeyMemberProvider.getAvailableMembers(this.camp.id, this.journey.id, params).subscribe((data) => {
            this.availableMembers = data.users;
            this.pagination = data.pagination;
            this.loaders.loading = false;
        });
    }

    loadMore(): void {
        this.loaders.loadmore = true;

        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.currentPage + 1
        });

        this.journeyMemberProvider.getAvailableMembers(this.camp.id, this.journey.id, params).subscribe((data) => {
            this.availableMembers = this.availableMembers.concat(data.users);
            this.pagination = data.pagination;
            this.loaders.loadmore = false;
        });
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        let findLimit = false;

        for (const template of this.templates) {
            if (template.instances) {
                for (const instance of template.instances) {
                    const limit = instance.usersLimit;
                    const users = instance.usersCount;

                    if (limit !== 0 && limit < users + this.data.accountIds?.length) {
                        findLimit = true;
                    }
                }
            }
        }

        if (findLimit) {
            this.modalConfirm.onOpen();
        } else {
            this.subscribe();
        }
    }

    subscribe(): void {
        this.instancesSelected = HttpHelper.cleanParams(this.instancesSelected);
        this.data.instanceIds = this.instancesSelected;

        this.journeyProvider.subscribeUsers(this.camp.id, this.journey.id, this.data).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant(this.data.invite_all_accounts ? 'toast.users-added-undefined' : 'toast.users-subscribed-content'));
            this.onSave.emit(true);
            this.aside.onClose();
        });
    }

    changeTab(tab: string): void {
        this.tab = tab;

        for (const template of this.templates) {
            if (!template.instances) {
                this.missingInstance = true;
                template.missingInstance = true;
            }
        }
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }
}
