import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'msc-common-td-status',
    templateUrl: './td-status.component.html',
    styleUrls: ['./td-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonTdStatusComponent {
    @Input() datum: any;
}
