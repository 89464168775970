import { Correction } from './correction';

export class CorrectionExercise extends Correction {
    constructor(data: any, title: string) {
        super(data, title);
        this.tag = 'words.question.open-write-type';
        this.timelineContext = 'question-session';
        this.answer = data.answer;
        this.ratio = data.score;
        this.answerMedias = this.medias || [];
    }
}