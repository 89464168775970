<ng-container *ngIf="!id; else alreadyReactedTemplate">
    <msc-button icon="icon-like-social">{{'reaction.thumbs-up' | translate}}</msc-button>
</ng-container>

<div class="feed-reaction-container">
    <ng-container *ngFor="let reaction of reactions">
        <div class="feed-reaction-item"
            [id]="reaction.contentId"
            (mouseenter)="onMouseEnter(reaction)"
            (mouseleave)="onMouseLeave(reaction)"
            (click)="onSubmit(reaction)">
        </div>
    </ng-container>
</div>

<ng-template #alreadyReactedTemplate>
    <msc-button type="custom" (click)="onDelete()" [title]="'timeline.reaction.delete' | translate">
        <img [src]="url" alt="">
    </msc-button>
</ng-template>
