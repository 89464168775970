import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { add } from '@functions';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
    enableProdMode();
    window.console.log = () => { };
    window.console.table = () => { };
}

if (!(environment as any).local && Sentry) {
    Sentry.init({
        dsn: 'https://b9aa3dc464364078b9cdb514bc882fbb@o479680.ingest.sentry.io/5543726',
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['*.myskillcamp.com'],
                routingInstrumentation: Sentry.routingInstrumentation
            })
        ],
        tracesSampleRate: 1.0,
        environment: environment.production ? 'production' : 'development',
        ignoreErrors: [
            'Error: Cannot match any routes',
            'Handled unknown error',
            'Non-Error exception captured with keys',
            'ResizeObserver loop',
            'Http failure response',
            'InvalidStateError',
            '"status":40',
            'Not found',
            // new filters
            'HttpErrorResponse',
            'NG0',
            'Server returned code'
        ],
        denyUrls: [
            /extensions\//i,
            /^chrome:\/\//i
        ],
        release: '4.6.0'
    });
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
