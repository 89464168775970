<button type="button" [ngClass]="{ 'is-open': isOpen }" (click)="onOpen()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <i class="icon icon-more-horizontal"></i>
</button>

<ng-template cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (backdropClick)="isOpen = false">
    <div>
        <button class="option" type="button" (click)="onDelete()">
            <i class="icon icon-trash-2"></i>
            <span>{{ 'organize.marketplace.catalogue.table.td.menu.remove' | translate }}</span>
        </button>

        <ng-container *ngIf="isDraft; else isLiveTemplate">
            <button class="option" type="button" (click)="onChange('live')">
                <i class="icon icon-flash"></i>
                <span [innerHTML]="'organize.marketplace.catalogue.table.td.menu.live' | translate"></span>
            </button>
        </ng-container>

        <ng-template #isLiveTemplate>
            <button class="option" type="button" (click)="onChange('draft')">
                <i class="icon icon-flash"></i>
                <span [innerHTML]="'organize.marketplace.catalogue.table.td.menu.draft' | translate"></span>
            </button>
        </ng-template>
    </div>
</ng-template>
