import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//
import { ComponentFormModel } from '@models/component-form.model';
//
export interface OrganizeFormMarketplaceSettingsDatum {
    mediaId: number;
    mediaUrl: string;
    name: string;
    url: string;
}

@Component({
    selector: 'msc-organize-form-marketplace-settings-component',
    templateUrl: './organize-form-marketplace-settings.component.html',
    styleUrls: ['./organize-form-marketplace-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeFormMarketplaceSettingsComponent extends ComponentFormModel {
    @Input() patch: OrganizeFormMarketplaceSettingsDatum;
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        protected readonly fb: FormBuilder,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setFormGroup(this.patch);
    }

    /**
     * Get the form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'file': [null],
            'mediaId': [null],
            'name': ['', [Validators.required]],
            'url': ['', [Validators.pattern('^[a-zA-Z\-]+$')]],
        });
    }

    /**
     * Event handler for file upload
     */
    onUpload(file: File): void {
        this.form.patchValue({ file, mediaId: null });
    }

    /**
     * Event handler for form submit
     */
    onSubmit(): void {
        this._onSubmit(this.form.value);
    }
}
