<msc-common-aside #aside>
    <ng-template #headerTemplate>
        <h3>{{ 'correction.statement' | translate }} : {{ activity.title || ('words.untitled-activity' | translate) }}
        </h3>
    </ng-template>

    <ng-template #asideTemplate>
        <div class="flex flex-col">
            <p [innerHTML]="activity.statement | safe:'html'"></p>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div class="flex grow justify-end">
            <msc-button class="msc-button--primary" palette="secondary" (click)="aside.onClose()">{{ 'words.close' |
                translate }}
            </msc-button>
        </div>
    </ng-template>
</msc-common-aside>