<div class="content-knowledge-add-content">
    <div class="box">
        <div class="content-knowledge-add-content__header">
            <h3 class="content-knowledge-add-content__title">
                {{ 'skill.add.content' | translate }}
            </h3>
        </div>

        <div class="content-knowledge-add-content__content">
            <div class="content-knowledge-add-content__sub-header">
                <p>{{ 'skill.add.content.new' | translate }} "{{ skill.name }}"</p>
            </div>

            <div class="container-add-content">
                <div class="flex justify-between my-4">
                    <div class="flex space-x-2">
                        <ng-container *ngIf="!selection">
                            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
                        </ng-container>
                        
                        <div class="flex space-x-2 flex-nowrap items-center" *ngIf="selectedContents.length > 0">
                            <msc-common2-switch [value]="selection" (changeEvent)="showSelection()"></msc-common2-switch>
                            <span>{{ 'words.my-selection' | translate }}</span>
                        </div>

                        <ng-container *ngIf="!selection && availableTypes">
                            <msc-common-select [data]="availableTypes" [isButton]="true" type="multi">
                                <ng-template #buttonTemplate let-data="data">
                                    <span class="text-slate-500">{{ 'words.types' | translate }}</span>
                                </ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <label class="flex py-2 w-full">
                                        <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectType(datum)"></msc-common2-checkbox>
                                        <span class="ml-2">{{ datum.label }}</span>
                                    </label>
                                </ng-template>
                            </msc-common-select>
                        </ng-container>

                        <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                            {{ 'words.filters' | translate }}
                        </msc-button>
                    
                        <ng-container *ngIf="isFilterActive">
                            <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                                {{ 'words.reset-filters' | translate }}
                            </msc-button>
                        </ng-container>
                    </div>

                    <div class="separator hidden-sm" *ngIf="!selection">
                        <div class="separator__actions">
                            <msc-common-select class="w-[240px] text-sm" [data]="selectData">
                                <ng-template #buttonTemplate let-data="data">
                                    <button class="select-main">
                                        <ng-container *ngIf="data?.length">
                                            <span>{{ 'filter.sorted-by' | translate }}: {{ data[0].label | translate }}</span>
                                        </ng-container>
                                    </button>
                                </ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <button class="flex py-2 w-full" (click)="onSelectOption(datum)">
                                        <span>{{ datum.label | translate}}</span>
                                    </button>
                                </ng-template>
                            </msc-common-select>
                        </div>
                    </div>
                </div>

                <msc-common-spinner *ngIf="loaders.loaded"></msc-common-spinner>

                <ul class="container-add-content__cards flex justify-start wrap">
                    <li *ngFor="let content of contents" (click)="selectContent(content)">
                        <div class="card" [ngClass]="{'card--checked': isSelected(content)}">
                            <div class="card__header">
                                <div class="card__radio"></div>

                                <div class="card__picture" [style.backgroundImage]="'url(' + getLogo(content) + ')'" *ngIf="content.type === 'external_content' && content.data.provider"></div>

                                <div class="card__tags">
                                    <msc-tag [text]="getType(content) | translate" [color]="getColor(content)"></msc-tag>
                                </div>

                                <div class="card__image">
                                    <div [ngStyle]="{'background-image': 'url(' + (content.data.picture ? (content.data.picture + '?size=512') : 'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.data.id % 7 + '.jpg') + ')'}"></div>
                                </div>
                            </div>

                            <div class="card__body" [tooltip]="content.data.title" >
                                <div class="card__time" *ngIf="content.data.duration">
                                    <i class="icon icon-clock"></i>

                                    <span class="card_time_text">
                                        {{ content.data.duration | duration }}
                                    </span>
                                </div>

                                <p class="card__title">
                                    {{ content.data.title | truncate: 30 }}
                                </p>

                                <p class="card__desc leading-3">
                                    {{ content.data.description | truncate: 50 }}
                                </p>
                            </div>

                            <div class="card__footer" *ngIf="content.data.instanceCount">
                                <div class="card__widgets">
                                    <a>
                                        {{ content.data.instanceCount }}
                                        <i class="icon icon-content"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <msc-load-more [loading]="loaders.loadMore" [pagination]="pagination" (onLoad)="loadMore()" *ngIf="!selection"></msc-load-more>
            </div>
        </div>
    </div>

    <div class="actions flex between">
        <div class="flex align-center">
            <i class="icon icon-eye" *ngIf="selectedContents.length > 0"></i>

            <span *ngIf="selectedContents.length > 0">
                {{ selectedContents.length }} selected content
            </span>
        </div>

        <div class="actions__buttons">
            <msc-button (click)="cancel()">
                {{ 'words.cancel' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" icon="icon-checkmark" palette="secondary" (click)="validate()" [disabled]="selectedContents.length === 0">
                {{ 'words.validate' | translate }}
            </msc-button>
        </div>
    </div>
</div>

<msc-filters-modal #modalFilters [filters]="filtersModal" (saveEvent)="onFilterSave($event)"></msc-filters-modal>
