<div class="flex flex-col">
    <div class="slide-external-link__body grow">
        <div class="slide-external-link__selected-skills">
            <div class="slide-external-link__form__skills">
                <div class="input__label">{{ 'words.skills' | translate}}</div>

                <div class="skill skill--shadow-none skill--level  skill--hoverable" *ngFor="let skill of extensionLink.skills; let index = index">
                    <div class="skill__content">
                        <div class="skill__level">
                            <span [ngClass]="{'active' : skill.level > 0 }" (click)="setLevel(1, index)"></span>
                            <span [ngClass]="{'active' : skill.level > 1 }" (click)="setLevel(2, index)"></span>
                            <span [ngClass]="{'active' : skill.level > 2 }" (click)="setLevel(3, index)"></span>
                        </div>

                        <p class="skill__text" (click)="setLevel(0, index)">{{ skill.name }}</p>

                        <div class="skill__hover-actions">
                            <div class="skill__level">
                                <span [ngClass]="{'active' : skill.level > 0 }" (click)="setLevel(1, index)"></span>
                                <span [ngClass]="{'active' : skill.level > 1 }" (click)="setLevel(2, index)"></span>
                                <span [ngClass]="{'active' : skill.level > 2 }" (click)="setLevel(3, index)"></span>
                            </div>

                            <p (click)="setLevel(0, index)">{{ 'words.for-all' | translate }}</p>
                            <a class="skill__close" (click)="removeSkill(index)">
                                <i class="icon icon-close"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide-external-link__available-skills">
            <div class="input__label">{{ 'mentoring.add-skill' | translate }}</div>
            
            <msc-common-search [placeholder]="'common.search' | translate" (searchEvent)="searchSkill($event.value)"></msc-common-search>

            <ul class="slide-external-link__available-skills__list">
                <li *ngFor="let skill of availableSkills; let index = index">
                    <div class="slide-external-link__form__skills">
                        <div class="skill skill--shadow-none skill--level skill--hoverable">
                            <div class="skill__content">
                                <p class="skill__text">{{ skill.name }}</p>
                            </div>

                            <div class="skill__hover-actions">
                                <div class="skill__level">
                                    <span (click)="addSkill(index, 1)"></span>
                                    <span (click)="addSkill(index, 2)"></span>
                                    <span (click)="addSkill(index, 3)"></span>
                                </div>
                                <p (click)="addSkill(index, 0)">{{ 'words.for-all' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>
    </div>

    <div class="flex justify-end">
        <msc-button [disabled]="loading" [loading]="loading" class="msc-button--primary" palette="secondary" (click)="nextPanel()">{{ 'words.next' | translate }}</msc-button>
    </div>
</div>


