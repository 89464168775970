import { Component, ChangeDetectionStrategy, Renderer2, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable, of, forkJoin } from 'rxjs';
import { map, tap, concatMap, takeUntil, shareReplay, finalize } from 'rxjs/operators';

import { CompanyUrlProvider2, MediaProvider, ApiCompanyUrlItem } from '@lighty';
import { StorageService, ExternalAppsService, TranslateService } from '@services';

import { ComponentSmartFormModel } from '@models2/component-smart-form.model';
import { environment } from '@env/environment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-experience-watch-url-create',
    templateUrl: './url-create.component.html',
    styleUrls: ['./url-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchUrlCreateComponent extends ComponentSmartFormModel<any> {
    @ViewChild('file') file: ElementRef;
    @ViewChild('img') img: ElementRef;

    public source$: Observable<any>;
    public hashId$: Observable<number>;
    public hashString$: Observable<string>;
    public domain: string = environment.production ? environment.domain : 'sandbox-myskillcamp.com';
    public isLoadingSubmit: boolean = false;

    constructor(
        protected readonly fb: FormBuilder,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly ref: ChangeDetectorRef,
        protected readonly renderer: Renderer2,
        protected readonly companyUrlProvider: CompanyUrlProvider2,
        protected readonly mediaProvider: MediaProvider,
        protected readonly storageService: StorageService,
        protected readonly toastService: CommonToastService,
        protected readonly externalAppsService: ExternalAppsService,
        protected readonly translateService: TranslateService,
    ) {
        super();
    }

    get experience(): string { return this.companyCurrentExperience && this.companyCurrentExperience.name || ''; }
    get guide() { return this.getGuideLink(this.me.language.code); }

    /**
     * Sets the component observables
     */
    setObs(): void {
        this.source$ = this.companyUrlProvider
            .getHash(this.company.id)
            .pipe(
                concatMap(this.getConcatMapGetHash.bind(this)),
                shareReplay(1),
                takeUntil(this.destroy$),
            );

        this.hashId$ = this.source$.pipe(map((datum: any) => datum.id));
        this.hashString$ = this.source$.pipe(map((datum: any) => datum.hash));
    }

    /**
     * Get a form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'file': [null, [Validators.required]],
            'type': ['subdomain', [Validators.required]],
            'url': ['', [Validators.required]],
        });
    }

    /**
     * Resolver for concatMap of getHash
     */
    getConcatMapGetHash(datum: any): Observable<any> {
        if (datum) { return of(datum); }
        return this.companyUrlProvider.postHashCreate(this.company.id);
    }

    /**
     * Get a list of init observables (usually media related or nothing)
     */
    getForkPostUrl(): Array<Observable<any>> {
        switch (true) {
            case !!this.form.value.file: return [this.mediaProvider.uploadMedia(this.form.value.file)];
            default: return [of(null)];
        }
    }

    /**
     * Get a list of params for postUrl
     */
    getParamsPostUrl(hashId: number, media?: any) {
        const { file, url, type, ...datum } = this.form.value;
        return {
            experience_id: this.companyCurrentExperience.id,
            hash_id: hashId,
            media_id: media ? media.id : null,
            url: `${url}${type === 'subdomain' ? `.${this.domain}` : ''}`,
            type,
            ...datum,
        };
    }

    /**
     * Resolver for concatMap of onSubmit
     */
    getConcatMapOnSubmit(hashId: number, media: any) {
        const { type } = this.form.value;

        return this.companyUrlProvider
            .postUrlCreate(this.company.id, this.getParamsPostUrl(hashId, media))
            .pipe(
                (type === 'subdomain' ?
                    concatMap((url: ApiCompanyUrlItem) => this.getConcatMapOnPostUrlCreate(url, hashId)) :
                    tap((url: ApiCompanyUrlItem) => this.goToDetails(url.id)))
            )
    }

    /**
     * Resolver for concatMap of getConcatMapOnSubmit
     */
    getConcatMapOnPostUrlCreate(url: any, hashId: number) {
        return this.companyUrlProvider
            .postUrlValidate(this.company.id, url.id, { experience_id: this.companyCurrentExperience.id, hash_id: hashId });
    }

    /**
     *
     */
    getGuideLink(lang: string) {
        switch (lang) {
            case 'en': return 'https://support.myskillcamp.com/hc/en/articles/4922084506525-Custom-URL';
            case 'fr': return 'https://support.myskillcamp.com/hc/fr/articles/4922084506525-URL-personnalis%C3%A9e';
            case 'nl': return 'https://support.myskillcamp.com/hc/nl/articles/4922084506525-Aangepaste-URL';
            case 'de': return 'https://support.myskillcamp.com/hc/de/articles/4922084506525-Benutzerdefinierte-URL';
            case 'it': return 'https://support.myskillcamp.com/hc/it/articles/4922084506525-URL-personalizzato';
            case 'es': return 'https://support.myskillcamp.com/hc/es/articles/4922084506525-URL-personalizada';
        }
    }

    /**
     * Query init data
     */
    onQuery(): void {
        // leave it empty
    }

    /**
     * Event handler for adding an image
     */
    onImageAdd(): void {
        const element: HTMLElement = this.file.nativeElement as HTMLElement;
        element.click();
    }

    /**
     * Event handler for removing an image
     */
    onImageRemove(): void {
        this.renderer.removeAttribute(this.img.nativeElement, 'src');
        this.form.patchValue({ file: null });
    }

    /**
     * Event handler for change an image
     */
    onImageChange(files: FileList): void {
        const [file] = Array.from(files);
        this.img.nativeElement.src = URL.createObjectURL(file);
        this.form.patchValue({ file });
    }

    /**
     * Event handler for clipboarding
     */
    onCopy(element: any): void {
        element.select();
        document.execCommand('copy');
        element.setSelectionRange(0, 0);
    }

    /**
     * Event handler for submit of create of url
     */
    onSubmit(hashId: number): void {
        this.isLoadingSubmit = true;
        forkJoin(this.getForkPostUrl())
            .pipe(
                concatMap(([media]) => this.getConcatMapOnSubmit(hashId, media)),
                finalize(() => this.onSubmitFinally()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                () => this.onSubmitSuccess(),
                (error: HttpErrorResponse) => this.onSubmitError(error),
            );
    }

    /**
     * Handler for success on onSubmit
     */
    onSubmitSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.create.success'));
    }

    /**
     * Handler for error on onSubmit
     */
    onSubmitError(error: HttpErrorResponse): void {
        switch (error.status) {
            case 403: this.toastService.onError(this.translateService.instant('toast.custom-url.create.error.format', error.message)); break;
            default: this.toastService.onError(this.translateService.instant('toast.custom-url.create.error', error.message)); break;
        }
    }

    /**
     * Handler for finally on onSubmit
     */
    onSubmitFinally(): void {
        this.isLoadingSubmit = false;
        this.ref.detectChanges();
    }

    /**
     * Resolves navigation to details section
     */
    goToDetails(id: number): void {
        this.router.navigate(['..', 'details', id], { relativeTo: this.route });
    }
}
