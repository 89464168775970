import { Component, OnInit } from '@angular/core';
import { CommonNavDatum } from '@common2/components/nav/nav.component';
import { PageHubModel } from '@models/page-hub.model';
import { ORGANIZE_CERTIFICATES_NAV_KEY_LIST } from '@modules/organize/organize.constants';
import { TranslateService } from '@services';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent extends PageHubModel {

  constructor(
    private readonly translateService: TranslateService,
  ) { 
    super();
  }

  ngOnInit(): void {
    this.setNav();
  }

  getNav(): Array<CommonNavDatum> {
    return ORGANIZE_CERTIFICATES_NAV_KEY_LIST.map((key) => ({
        label: this.translateService.instant(`organize.certifacates.nav.${key}`),
        value: {
            routerLink:  `./${key}`,
        }
    }));
}

}
