import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
//
import { TranslateService } from '@services';
//
import { CommonSubjectStatus, CommonApiGetListOrderParams } from '@common2/common.types';
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';
import { UuidPipe } from '@common2/pipes/uuid.pipe';
import { ComponentTableModel } from '@models/component-table.model';
//
export interface OrganizeTableMarketplaceCustomersDatum {
    company: string;
    status: any;
}

@Component({
    selector: 'msc-organize-table-marketplace-customers-component',
    templateUrl: './organize-table-marketplace-customers.component.html',
    styleUrls: ['./organize-table-marketplace-customers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UuidPipe],
})

export class OrganizeTableMarketplaceCustomersComponent extends ComponentTableModel<OrganizeTableMarketplaceCustomersDatum> {
    @Input() rows: Array<OrganizeTableMarketplaceCustomersDatum>;
    @Input() status: CommonSubjectStatus;
    @Output() sortEvent: EventEmitter<CommonApiGetListOrderParams> = new EventEmitter<CommonApiGetListOrderParams>();

    public id: string = this.uuidPipe.transform();

    constructor(
        protected readonly translateService: TranslateService,
        protected readonly uuidPipe: UuidPipe,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setContent();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
    }

    /**
     * Get the column list
     * Note: Order of columns is important
     */
    getColumns() {
        return [
            {
                key: 'company',
                label: this.translateService.instant('organize.marketplace.customers.table.th.company', { count: '' }),
                width: '70%',
            },
            {
                key: 'deployment_done_at',
                label: this.translateService.instant('organize.marketplace.customers.table.th.date'),
                width: '30%',
            }
        ];
    }

    /**
     * Event handler for sort
     */
    onSort(key: keyof OrganizeTableMarketplaceCustomersDatum, type: CommonThSortType): void {
        this.sortEvent.emit({ order: type, sort: key });
    }
}
