import { Directive, Input, ElementRef, EventEmitter, Output, OnInit } from '@angular/core';

@Directive({ selector: '[element]', exportAs: 'element' })
export class CommonNgElementRef implements OnInit {
    @Output()
    public elementChange: EventEmitter<any> = new EventEmitter<any>();

    public elementRef: ElementRef;

    constructor(elementRef: ElementRef) {
        this.elementRef = elementRef;
        this.elementChange.next(undefined);
    }

    @Input()
    public get element(): any {
        return this.elementRef.nativeElement;
    }

    public set element(value: any) {

    }

    ngOnInit(): void {
        this.elementChange.next(this.elementRef.nativeElement);
    }
}