import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
//
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, takeUntil, filter } from 'rxjs/operators';
//
export type CommonSearchTrigger = 'auto' | 'touch';
export interface CommonSearchOutput<T> {
    data: Array<T | string>;
    value: string;
}
//
const COMMON_SEARCH_DEBOUNCE_TIME_MILISECONDS: number = 800;

@Component({
    selector: 'msc-common-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonSearchComponent<T> {
    @Input() data: Array<T | string>; // list of data items
    @Input() key?: string; // the key used for filtering if we have objects
    @Input() placeholder: string = ''; // placeholder search text
    @Input() trigger: CommonSearchTrigger = 'auto';
    @Output() searchEvent: EventEmitter<CommonSearchOutput<T>> = new EventEmitter<CommonSearchOutput<T>>();
    @ViewChild('input') input: ElementRef;

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly elementRef: ElementRef,
    ) { }

    ngOnInit(): void {
        this.setObs();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Set the component observables
     */
    setObs(): void {
        this.setObsInput();
        this.setObsKey();
    }

    /**
     * Set the component observable for input
     */
    setObsInput(): void {
        if (this.trigger !== 'auto') { return; }
        fromEvent(this.elementRef.nativeElement, 'input')
            .pipe(
                debounceTime(COMMON_SEARCH_DEBOUNCE_TIME_MILISECONDS),
                distinctUntilChanged(),
                tap(() => this.onSearch()),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Set the component observable for key
     */
    setObsKey(): void {
        if (this.trigger !== 'touch') { return; }
        fromEvent(this.elementRef.nativeElement, 'keyup')
            .pipe(
                debounceTime(COMMON_SEARCH_DEBOUNCE_TIME_MILISECONDS / 2),
                filter((event: KeyboardEvent) => event.key === 'Enter'),
                distinctUntilChanged(),
                tap(() => this.onSearch()),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Get the data after search
     */
    getDataSearch(value: string): Array<T | string> {
        if (!Array.isArray(this.data)) { return []; } // sanity check
        if (this.key) { return this.data.filter((d: T) => (d[this.key] as string).toLowerCase().includes(value)); }
        return this.data.filter((d: string) => d.toLowerCase().includes(value));
    }

    /**
     * Event handler for search
     */
    onSearch(): void {
        const value = this.input.nativeElement?.value.toLowerCase();
        const data = this.getDataSearch(value);
        this.searchEvent.emit({ data, value });
    }
}
