import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
    selector: 'msc-shared-aside-mail-multiple-users',
    templateUrl: './aside-mail-multiple-users.component.html'
})
export class SharedMailMultipleUsersComponent {
    @ViewChild('aside') aside;
    @Input() selectedUsers: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public message: string;

    constructor() { }

    public onOpen() {
        this.message = '';
        this.aside.onOpen();
    }

    close(): void {
        this.onClose.emit();
    }

    save(): any {
        const data = {
            message: this.message
        };
        this.onSave.emit(data);
    }
}
