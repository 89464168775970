import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AdminJourneyMemberProvider {

    constructor(private http: HttpClient) {}

    getAllMembers(id: number, journeyId: number): Observable<any> {
        return forkJoin(
            this.getStudents(id, journeyId, {include: 'contents'}),
            this.getAvailableMembers(id, journeyId)
        );
    }

    getStudents(id: number, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${id}/admin/trajects/${journeyId}/users`, {params}).pipe(
            map(data => data)
        );
    }

    getAvailableMembers(id: number, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${id}/admin/trajects/${journeyId}/users/available-members`, {params}).pipe(
            map(data => data)
        );
    }

    subscribeStudent(id: number, journeyId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/camp/${id}/admin/trajects/${journeyId}/users`, params).pipe(
            map(data => data)
        );
    }
}
