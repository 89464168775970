import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { LearnModule } from './pages/learn/learn.module';
import { ManageModule } from './pages/manage/manage.module';
import { OrganizeModule } from './pages/organize/organize.module';

// Lighty
import { LightyModule } from '@lighty';

// Guards
import { AuthGuard, LoginGuard, CompanyCookieGuard } from './guards';

// Interceptors
import { HttpInterceptorService, ErrorInterceptorService } from './interceptors';

// SharedModule
import { SharedModule } from './shared.module';

// Pages Main
import { AppComponent } from './app.component';
import { CorporateComponent } from './pages/common/corporate/corporate.component';
import { LoginComponent } from './pages/common/login/login.component';
import { MaintenanceComponent } from './pages/common/maintenance/maintenance.component';
import { SignupComponent } from './pages/common/signup/signup.component';

// Pages Account
import { AccountAccessComponent } from './pages/common/account/access/access.component';
import { AccountAccessCreateComponent } from './pages/common/account/access/create/create.component';
import { AccountAccessLearnComponent } from './pages/common/account/access/learn/learn.component';
import { AccountAccessManageComponent } from './pages/common/account/access/manage/manage.component';
import { AccountAccessManageInstanceComponent } from './pages/common/account/access/manage/instance/instance.component';
import { AccountAccessOrganizeComponent } from './pages/common/account/access/organize/organize.component';
import { AccountAccessSettingsComponent } from './pages/common/account/access/settings/settings.component';
import { AccountComponent } from './pages/common/account/account.component';
import { AccountProfileComponent } from './pages/common/account/profile/profile.component';
import { AccountSettingsComponent } from './pages/common/account/settings/settings.component';
import { AsideAccessManageInstanceComponent } from './pages/common/account/access/manage/instance/aside/aside-manage-instance.component';

// Pages Conversation
import { ConversationComponent } from './pages/common/conversation/conversation.component';
import { ConversationMessageComponent } from './pages/common/conversation/message/message.component';

// Pages Integrations
import { ExternalComponent } from './pages/external/external.component';

// Necromancer
import { NecromancerComponent } from './pages/necromancer/necromancer.component';

// Hub
import { ForgottenPasswordComponent } from './pages/common/reset-password/forgotten/forgotten-password.component';
import { HubComponent } from './pages/hub/hub.component';
import { ResetPasswordComponent } from './pages/common/reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './pages/common/reset-password/form/reset-password-form.component';
import { ResetPasswordSuccessComponent } from './pages/common/reset-password/success/reset-password-success.component';
/* Plugins */
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';

/* Languages */
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/nl.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/it.js';

// Common
import { AppCommon2Module } from './common2/common.module';
import { ThemingService } from '@common2/services/theming.service';

// Modules
import { Organize2Module } from '@modules/organize/organize.module';
import { ShowroomModule } from '@modules/showroom/showroom.module';
import { Coach2Module } from '@modules/coach/coach.module';
import { IdpLoginComponent } from './pages/common/idp/idp-login.component';
import { CompanyGuardCheck } from './app.resolver';

const disableAnimations =
    !('animate' in document.documentElement)
    || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));
@NgModule({
    declarations: [
        AccountAccessComponent,
        AccountAccessCreateComponent,
        AccountAccessLearnComponent,
        AccountAccessManageComponent,
        AccountAccessManageInstanceComponent,
        AccountAccessOrganizeComponent,
        AccountAccessSettingsComponent,
        AccountComponent,
        AccountProfileComponent,
        AccountSettingsComponent,
        AppComponent,
        AsideAccessManageInstanceComponent,
        ConversationComponent,
        ConversationMessageComponent,
        CorporateComponent,
        ExternalComponent,
        ForgottenPasswordComponent,
        HubComponent,
        LoginComponent,
        IdpLoginComponent,
        MaintenanceComponent,
        NecromancerComponent,
        ResetPasswordComponent,
        ResetPasswordFormComponent,
        ResetPasswordSuccessComponent,
        SignupComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule.withConfig({ disableAnimations }),
        FormsModule,
        HttpClientModule,
        AppCommon2Module,
        LearnModule,
        LightyModule,
        ManageModule,
        OrganizeModule,
        SharedModule,
        AppCommon2Module,
        Organize2Module,
        Coach2Module,
        ShowroomModule,
    ],
    providers: [
        AuthGuard,
        CompanyCookieGuard,
        CompanyGuardCheck,
        LoginGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private themingService: ThemingService) {
        this.themingService.apply();
    }
}
