<dl class="list">
    <ng-container *ngFor="let datum of data; let i=index; trackBy: onTrackByIndex">
        <dd class="item" [ngClass]="{'item--has-children': datum?.children && datum.children.length}">
            <div class="item-content" [ngClass]="{'item-content--selected': datum.expanded}">
                <div class="item-label" (click)="onSelect(selectionModel, datum, !isSelected(datum), true)">
                    <msc-common2-checkbox *ngIf="selectionActive && type === 'checkbox'" [type]="isIndeterminate(datum) ? 'half' : 'tick'"
                        [value]="isSelected(datum) || isIndeterminate(datum)" (changeEvent)="onSelect(selectionModel, datum, !isSelected(datum), true)">
                    </msc-common2-checkbox>
                    <div>
                        <i *ngIf="datum.icon" class="item-icon icon {{datum.icon}}"></i>
                        <msc-common-avatar *ngIf="datum.picture" class="tiny" [pictureURL]="datum.picture">
                        </msc-common-avatar>
                        <span class="truncate">{{datum.label}}</span>
                    </div>
                </div>
                <div class="flex items-center space-x-2">
                    <msc-common-overlay #overlay direction="list" [closeOnClickOutside]="false"
                        (onClose)="datum.expanded=false">
                        <ng-template>
                            <msc-common-list (treeUpdate)="treeUpdate.emit($event)" [itemsModel]="itemsModel" [selectionModel]="selectionModel?.get(datum.id)?.selectionModel" (selectionModelChange)="updateSelectionModel(datum, $event)" [selectionActive]="selectionActive" [expandWithOverlay]="expandWithOverlay"
                                [(data)]="datum.children"></msc-common-list>
                        </ng-template>
                    </msc-common-overlay>
                    <i class="item-selection-icon icon icon-checkmark"
                        *ngIf="type === 'checkmark' && isSelected(datum)"></i>
                    <i class="item-expansion-icon icon icon-arrow-ios-right" element #origin="element"
                        (click)="openTree(datum, overlay, origin.elementRef)"
                        [ngClass]="{'rotate-90': !expandWithOverlay && datum.expanded}"></i>
                </div>
            </div>
            <ng-container *ngIf="!expandWithOverlay">
                <div class="item-child" *ngIf="datum.expanded && datum?.children && datum.children.length">
                    <msc-common-list (treeUpdate)="treeUpdate.emit($event)" [itemsModel]="itemsModel" [selectionModel]="selectionModel?.get(datum.id)?.selectionModel" (selectionModelChange)="updateSelectionModel(datum, $event)" [selectionActive]="selectionActive" [expandWithOverlay]="expandWithOverlay"
                        [(data)]="datum.children"></msc-common-list>
                </div>
            </ng-container>
        </dd>
    </ng-container>
</dl>