import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class ReactionProvider {

    constructor(private http: HttpClient) {}

    create(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/reactionables`, params).pipe(
            map(data => data)
        );
    }

    delete(params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/reactionables`, {params}).pipe(
            map(data => data)
        );
    }

    getReactionFromPost(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/reactionables`, {params}).pipe(
            map(data => data)
        );
    }
}
