<section class="head">
    <h3>Tab</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Tab</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-tab>
                    <ng-template #tabControl>
                        <span>Coffee</span>
                    </ng-template>
                    <ng-template #tabPanel>
                        <p>Something about Coffee</p>
                    </ng-template>

                    <ng-template #tabControl>
                        <span>Soda</span>
                    </ng-template>
                    <ng-template #tabPanel>
                        <p>Something about Soda</p>
                    </ng-template>

                    <ng-template #tabControl>
                        <span>Water</span>
                    </ng-template>
                    <ng-template #tabPanel>
                        <p>Something about Water</p>
                    </ng-template>
                </msc-common-tab>
            </div>
        </div>
    </div>
</section>
