import { Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalAppsService, StorageService } from '@services';
import { Appcues } from '@classes';

@Component({
    selector: 'msc-appcues',
    templateUrl: './appcues.component.html',
    styleUrls: ['./appcues.component.scss']
})
export class AppcuesComponent implements OnInit {
    @Output() onBack: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Input() currentJob: string = 'learn';
    private appcues: Appcues = new Appcues();
    private company: any;
    public me: any;
    public guides: any[] = [];
    public screenWidth: number;

    constructor(private router: Router, private externalAppsService: ExternalAppsService, private storageService: StorageService) {}

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');

        this.getScreenSize();

        for (const guide of this.appcues.get(this.currentJob)) {
            if (guide.breakpoint === 'small' && this.screenWidth < 768) {
                this.guides.push(guide);
            } else if ((guide.breakpoint === 'large' || guide.breakpoint === undefined) && this.screenWidth > 768) {
                this.guides.push(guide);
            }
        }
    }

    back(): void {
        this.onBack.emit(true);
    }

    startGuide(guide: any): void {
        let url = '';

        if (guide.redirect === 'history') {
            url = `account/history`;
        } else if (guide.redirect === 'profile') {
            url = `account/profile`;
        } else if (guide.redirect === 'language') {
            url = `account/settings`;
        } else if (guide.redirect === 'application') {
            url = `account/integrations`;
        } else if (guide.redirect === 'conversation') {
            url = `conversation`;
        } else {
            url = `${this.currentJob}/${this.company.slug}/${guide.redirect}`;
        }
        this.router.navigateByUrl(url);

        const timeOut = setTimeout(() => {
            this.externalAppsService.appcues().show(guide[this.me.language.code].appcuesId);
            this.back();
            this.onClose.emit(true);
            clearTimeout(timeOut);
        }, 2000);
    }

    @HostListener('window:resize')
    getScreenSize(): void {
        this.screenWidth = window.innerWidth;
    }
}
