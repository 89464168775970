import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntegrationProvider } from '@lighty';
import { StorageService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-organize-integration-watch-mapping-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
})

export class OrganizeIntegrationWatchMappingEditComponent {
    @Input() provider: any;
    @Input() mapping: any;
    @Output() mappingChange: EventEmitter<any> = new EventEmitter();
    @Output() associationsChange: EventEmitter<any> = new EventEmitter();
    private company: any;
    public paginations: any = {
        associations: {},
        categories: {}
    };
    public filterByThemes: boolean = false;
    public displayCategories: any[] = [];
    public displayAssociations: any[] = [];
    public queryCategory: string = '';
    public queryAssociation: string = '';
    public selectedAssociation: any;
    public associations: any[] = [];
    public loadedAssociations: any[] = [];
    public categories: any[] = [];
    public loaders: any = {
        associations: false,
        categories: false,
        categoriesLoadMore: false
    };

    constructor(private route: ActivatedRoute, private storageService: StorageService, private integrationProvider: IntegrationProvider) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.route.data.subscribe(() => {
            this.paginations.associations.currentPage = 1;
            this.loaders.associations = true;
            this.fetchAssociations();
            this.paginations.categories.currentPage = 1;
            this.loaders.categories = true;
            this.fetchCategories();
        });
    }

    fetchAssociations(): void {
        const params = HttpHelper.cleanParams({
            page: this.paginations.associations.currentPage,
            q: this.queryAssociation,
            thematics: this.filterByThemes ? 1 : 0
        });
        this.integrationProvider.getMappingSkills(this.company.id, this.provider.id, params).subscribe((data) => {
            let tempArray = [];
            for (const skill of data.skills) { // if skill is already loaded, then use this one instead of the fetched one
                const index = this.loadedAssociations.findIndex(association => association.id === skill.id);
                if (index === -1) {
                    this.loadedAssociations.push(skill);
                    tempArray.push(Object.assign({}, skill));
                } else {
                    tempArray.push(Object.assign({}, this.loadedAssociations[index]));
                }
            }

            this.associations = tempArray;
            this.paginations.associations = data.pagination;
            this.loaders.associations = false;
            for (const association of this.associations) {
                if (!association.providerSkills) {
                    association.providerSkills = [];
                }
            }
        });
    }

    fetchCategories(): void {
        const params = HttpHelper.cleanParams({
            page: this.paginations.categories.currentPage,
            q: this.queryCategory
        });
        this.integrationProvider.getMappingCategories(this.company.id, this.provider.id, params).subscribe((data) => {
            this.categories = data.categories;
            for (const category of this.categories) {
                if (this.provider.hasSubcategories) {
                    for (const subcategory of category.subcategories) {
                        if (!subcategory.contentSkills) {
                            subcategory.contentSkills = [];
                        }
                    }
                } else {
                    if (!category.contentSkills) {
                        category.contentSkills = [];
                    }
                    category.isCategory = true;
                }
            }
            this.paginations.categories = data.pagination;
            this.loaders.categories = false;
        });
    }

    loadMoreCategories(): void {
        this.loaders.categoriesLoadMore = true;
        const params = HttpHelper.cleanParams({
            page: this.paginations.categories.currentPage + 1,
            q: this.queryCategory
        });
        this.integrationProvider.getMappingCategories(this.company.id, this.provider.id, params).subscribe((data) => {
            for (const category of data.categories) {
                if (this.provider.hasSubcategories) {
                    for (const subcategory of category.subcategories) {
                        if (!subcategory.contentSkills) {
                            subcategory.contentSkills = [];
                        }
                    }
                } else {
                    if (!category.contentSkills) {
                        category.contentSkills = [];
                    }
                    category.isCategory = true;
                }
                this.categories.push(category);
            }
            this.paginations.categories = data.pagination;
            this.loaders.categoriesLoadMore = false;
        });
    }

    searchCategories(query?): void {
        this.queryCategory = query;
        this.paginations.categories.currentPage = 1;
        this.fetchCategories();
    }

    searchAssociation(query?): void {
        this.queryAssociation = query;
        this.paginations.associations.currentPage = 1;
        this.fetchAssociations();
    }

    checkFilterByThemes(): void {
        this.paginations.associations.currentPage = 1;
        this.fetchAssociations();
    }

    onAssociationClick(association: any): void {
        this.selectedAssociation = association;
        for (const item of this.associations) {
            if (item.id !== association.id) {
                this.displayAssociations[item.id] = false;
            }
        }
        this.displayedAssociations(association.id);
        if (this.displayAssociations[association.id] === false) {
            this.selectedAssociation = null;
        }
        this.checkSkillsAssociated();
    }

    checkSkillsAssociated(): void {
        if (this.selectedAssociation) {
            for (const item of this.categories) {
                item.selected = this.selectedAssociation.providerSkills.includes(item);
            }
        } else {
            for (const item of this.categories) {
                item.selected = false;
            }
        }
    }

    onSkillRemove(skill: any, association: any): void {
        if (this.provider.hasSubcategories) {
            // find in association
            let skillIndex;
            if (skill.category) {
                skillIndex = association.providerSkills.findIndex((providerSkill) => providerSkill.category?.id === skill.category.id);
            }
            if (skill.subcategory) {
                skillIndex = association.providerSkills.findIndex((providerSkill) => providerSkill.subcategory.id === skill.subcategory.id);
            }

            // 2 subcategories cannot have the same ID, even accross categories
            for (const category of this.categories) {
                for (const subcategory of category.subcategories) {
                    if (skill.subcategory.id === subcategory.id) { // removes only in related subcategory
                        const subCategoryIndex = subcategory.contentSkills.findIndex(contentSkill => contentSkill.id === association.id);
                        if (subCategoryIndex > -1) {
                            subcategory.contentSkills.splice(subCategoryIndex, 1);
                        }
                    }
                }
            }

            // remove
            if (skillIndex > -1) {
                association.providerSkills.splice(skillIndex, 1);
            }
        } else {
            const providerSkillIndex = association.providerSkills.findIndex(providerSkill => providerSkill.category.id === skill.category.id);
            const categoryIndex = this.categories.findIndex(category => category.id === skill.category.id);
            if (providerSkillIndex > -1) {
                association.providerSkills.splice(providerSkillIndex, 1);
            }
            if (categoryIndex > -1) {
                const contentSkillIndex = this.categories[categoryIndex].contentSkills.findIndex(contentSkill => contentSkill.id === association.id);
                if (contentSkillIndex > -1) {
                    this.categories[categoryIndex].contentSkills.splice(contentSkillIndex, 1);
                }
            }
        }
        this.updateMappingProperty(skill, association);
    }

    displayedAssociations(index: any): void {
        this.displayAssociations[index] = !this.displayAssociations[index];
    }

    displayedCategories(index: any): void {
        this.displayCategories[index] = !this.displayCategories[index];
    }

    onSkillDrop(event: any, association: any): void {
        let eventIndex;
        if (event.isCategory) {
            eventIndex = association.providerSkills.findIndex((providerSkill) => providerSkill.category?.id === event.id);
        } else {
            eventIndex = association.providerSkills.findIndex((providerSkill) => providerSkill.subcategory?.id === event.id);
        }
        if (eventIndex === -1) {
            // check if category
            if (event.isCategory) {
                association.providerSkills.push({
                    category: event,
                    isCategory: true
                });
            } else if (!event.subcategories) {
                association.providerSkills.push({
                    subcategory: event,
                    isCategory: false
                });
            }
            // check if has contentSkill
            if (!event.contentSkills) {
                event.contentSkills = [];
            }
            event.contentSkills.push({
                name: association.name,
                id: association.id
            });
            // move to mapped if needed
            if (this.provider.hasSubcategories) {
                if (event.subcategories) {
                    for (const category of event.subcategories) {
                        this.onSkillDrop(category, association);
                    }
                }
            }
        }
        // store the modifications into the loaded associations
        const associationIndex = this.loadedAssociations.findIndex(assoc => assoc.id === association.id);
        this.loadedAssociations[associationIndex] = association;
        this.updateMappingProperty(event, association);
    }

    updateMappingProperty(category: any, association: any): void {
        const assocCategories = [];
        const assocSubcategories = [];

        for (const skill of association.providerSkills) {
            if (this.provider.hasSubcategories) {
                if (skill.isCategory) {
                    assocCategories.push(skill.category.id);
                } else {
                    assocSubcategories.push(skill.subcategory.id);
                }
            } else {
                assocCategories.push(skill.category.id);
            }
        }

        const mappingEntry = {
            skillId: association.id,
            categoryIds: assocCategories,
            subcategoryIds: assocSubcategories
        };

        const index = this.mapping.findIndex((entry) => entry.skillId === mappingEntry.skillId);

        if (index > -1) {
            this.mapping[index] = mappingEntry;
        } else {
            this.mapping.push(mappingEntry);
        }
    }

    getAssociationsNames(skill: any): string {
        const newArray = [];
        if (skill.contentSkills) {
            for (const contentSkill of skill.contentSkills) {
                newArray.push(contentSkill.name);
            }
            return newArray.join(', ');
        }

    }

    navigateThroughAssociations(page: number): void {
        this.loaders.associationsLoadMore = true;
        this.paginations.associations.currentPage = page;
        this.fetchAssociations();
    }

    onDraggableSimpleClick(event): void {
        if (this.selectedAssociation) {
            this.onSkillDrop(event, this.selectedAssociation);
        }
    }
}
