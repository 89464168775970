import { Component, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { mergeScan, map, tap, repeat, finalize, takeUntil, concatMap } from 'rxjs/operators';
//
import { Company, CompanyExperience, CompanyExperienceProvider, LanguageProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { Filter } from '@interfaces';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';
import { CommonMenuItem } from '@common2/components/overlay-menu/overlay-menu.component';

@Component({
    selector: 'msc-organize-experience-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})

export class OrganizeExperienceListComponent {
    @ViewChild('modalCreateExperience') modalCreateExperience: CommonModalComponent;
    @ViewChild('modalRenameExperience') modalRenameExperience: CommonModalComponent;
    @ViewChild('modalDeleteExperience') modalDeleteExperience: CommonModalComponent;

    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public company: Company;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public query$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore: boolean = false;
    // crap
    public experienceName: string;
    public selectedExperience: CompanyExperience;

    constructor(
        private readonly datePipe: DatePipe,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastService: CommonToastService,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
        private readonly companyExperienceProvider: CompanyExperienceProvider,
    ) { }

    ngOnInit(): void {
        this.setContent();
        this.onQueryExperienceList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    private getExperienceMenuList(datum): Array<CommonMenuItem> {
        return [
            {
                iconLeft: 'icon-edit-2',
                label: this.translateService.instant('words.rename'),
                callback: this.openModalRename.bind(this, datum)
            },
            {
                iconLeft: 'icon-edit',
                label: this.translateService.instant('words.edit'),
                callback: this.editExperience.bind(this, datum.id)
            },
            {
                iconLeft: 'icon-trash text-danger-500',
                label: this.translateService.instant('words.delete'),
                callback: this.openModalDeleteExperience.bind(this, datum)
            },
        ]
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.company = this.storageService.get('company');
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '20%',
            },
            {
                key: 'logo',
                label: this.translateService.instant('words.logo'),
                width: '20%',
            },
            {
                key: 'company',
                label: this.translateService.instant('words.company'),
                width: '20%',
            },
            {
                key: 'users',
                label: this.translateService.instant('words.users'),
                width: '20%',
            },
            {
                key: '',
                width: '20%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryExperienceList(pageValue: number) {
        return {
            page: pageValue,
            ...this.query$.getValue(),
        };
    }

    /**
     *
     */
    onQueryExperienceList(isAppend: boolean = true) {
        this.companyExperienceProvider
            .getExperiences(this.company.slug, this.getParamsOnQueryExperienceList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryExperienceList(response.pagination)),
                map((response) => this.onMapOnQueryExperienceList(response.experiences)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryExperienceList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryExperienceList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            ...datum,
            pictureUrl: `url(${datum.displayPicture}?size=256)`,
            dataSelect: this.getDataSelect(datum),
            experienceMenu: this.getExperienceMenuList(datum)
        }));
    }

    /**
     *
     */
    getDataSelect(datum: any) {
        return [
            {
                isSelected: false,
                value: {
                    icon: 'edit-2',
                    action: () => {
                        this.openModalRename(datum);
                    },
                },
                label: this.translateService.instant('words.rename'),
            },
            {
                isSelected: false,
                value: {
                    icon: 'edit',
                    action: () => this.editExperience(datum.id),
                },
                label: this.translateService.instant('words.edit'),
            },
            {
                isSelected: false,
                value: {
                    icon: 'trash',
                    action: () => this.openModalDeleteExperience(datum),
                },
                label: this.translateService.instant('words.delete'),
            },
        ];
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.page$.next(1);
        const { q, ...query } = this.query$.getValue();
        this.query$.next({ ...query, q: value });
        this.onQueryExperienceList(false);
    }

    /**
     *
     */
    onSort(key: any, type: CommonThSortType): void {
        this.page$.next(1);
        this.query$.next({ order_by: key, order_way: type })
        this.onQueryExperienceList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryExperienceList();
    }

    /// crap

    openModalRename(experience: CompanyExperience): void {
        this.selectedExperience = experience;
        this.experienceName = experience.name;
        this.modalRenameExperience.onOpen();
    }

    openModalCreateExperience(modal: CommonModalComponent): void {
        this.experienceName = '';
        modal.onOpen();
    }

    createExperience(): void {
        if (this.experienceName) {
            const params = {
                name: this.experienceName
            };

            this.companyExperienceProvider.create(this.company.slug, params).subscribe((data) => {
                this.query$.next({});
                this.onQueryExperienceList(false);
                this.editExperience(data.id);
            });
        }
    }

    editExperience(id: number): void {
        this.router.navigate([id], { relativeTo: this.route });
    }

    openModalDeleteExperience(experience: CompanyExperience): void {
        this.selectedExperience = experience;
        this.modalDeleteExperience.onOpen();
    }

    deleteExperience(): void {
        this.companyExperienceProvider.delete(this.company.slug, this.selectedExperience.id).subscribe(() => {
            this.query$.next({});
            this.onQueryExperienceList(false);
            this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
        });
    }

    renameExperience(): void {
        this.selectedExperience.name = this.experienceName;
        this.companyExperienceProvider.update(this.company.slug, this.selectedExperience.id, this.selectedExperience).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }
}
