<section class="head">
    <h3>{{ 'member.title' | translate }}</h3>
    <div class="toolbar">
        <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
        <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" (click)="inviteMembersAside.onOpen()">
            {{ 'words.invite-member' | translate }}
        </msc-button>
    </div>
</section>

<section class="body">
    <div class="content" style="overflow: hidden;">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async">
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <i class="icon icon-student" [tooltip]="datum.label"></i>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <i class="icon icon-calendar" [tooltip]="datum.label"></i>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <i class="icon icon-mentor" [tooltip]="datum.label"></i>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <i class="icon icon-clock-1" [tooltip]="datum.label"></i>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <i class="icon icon-activity" [tooltip]="datum.label"></i>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common-avatar class="mr-2" [label]="datum.account.name" [pictureURL]="datum.account.picture ? (datum.account.picture + '?size=128') : null"></msc-common-avatar>
                            <p>{{ datum.account.name }}</p>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common2-checkbox
                                [value]="datum.roles.campLearner"
                                [isDisabled]="datum.roles.campLearner && (datum.roles.companyOwner && !access.roles.companyOwner)"
                                (changeEvent)="updateRoles(modalDelete, datum, 'roles', 'campLearner')">
                            </msc-common2-checkbox>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common2-checkbox
                                [value]="datum.roles.campAdministrator"
                                [isDisabled]="datum.roles.campAdministrator && (datum.roles.companyOwner && !access.roles.companyOwner)"
                                (changeEvent)="updateRoles(modalDelete, datum, 'roles', 'campAdministrator')">
                            </msc-common2-checkbox>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common2-checkbox
                                [value]="datum.permissions.manageCampSettings"
                                [isDisabled]="!datum.roles.campAdministrator || datum.permissions.manageCampSettings && (datum.roles.companyOwner && !access.roles.companyOwner) || !camp.permissions.manageCampSettings"
                                (changeEvent)="updateRoles(modalDelete, datum, 'permissions', 'manageCampSettings')">
                            </msc-common2-checkbox>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span *ngIf="datum.account.lastConnection">{{ datum.account.lastConnection | timeAgo }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span *ngIf="datum.subscriptionDate">{{ datum.subscriptionDate | date: 'dd' }} {{ datum.subscriptionDate | date: 'MMMM' }} {{ datum.subscriptionDate | date: 'YYYY' }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <ng-container *ngIf="me.id !== datum.account.id">
                                <msc-button type="fab" palette="danger" icon="icon-trash-2" (click)="setAndDeleteMember(modalDelete, datum)" [tooltip]="'common.delete' | translate"></msc-button>
                            </ng-container>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'common.empty' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <msc-common-spinner *ngIf="true"></msc-common-spinner>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="hasLoadMore">
        <div class="load-more">
            <msc-button class="msc-button--primary" palette="primary" [loading]="(isLoadingMore$ | async) === 'loading'" (click)="onLoad()">{{ 'words.load-more' | translate }}</msc-button>
        </div>
    </ng-container>
</section>

<msc-camp-aside-invite-members
    #inviteMembersAside
    [camp]="camp"
    [loading]="loading.save"
    [availableMembers]="availableMembers?.members"
    [pagination]="availableMembers?.pagination"
    (onSave)="addMembers($event)">
</msc-camp-aside-invite-members>

<msc-common-modal size="30vw" #modalDelete [title]="'member.delete.title' | translate">
    <ng-template>
        <p>{{ 'member.delete.description' | translate }}</p>
        <p>{{ 'member.delete.confirmation' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDelete.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onDeleteMember(memberDelete); modalDelete.onClose()">{{'common.delete' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
