import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class ApplicationProvider {

    constructor(private http: HttpClient) {}

    get(id: number|string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/applications`, {params}).pipe(
            map(data => data)
        );
    }

    getApplication(id: number|string, applicationId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/applications/${applicationId}`, {params}).pipe(
            map(data => data)
        );
    }

    getFeaturesFromApplications(id: number|string, applicationId: number, featureId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/applications/${applicationId}/features/${featureId}`, {params}).pipe(
            map(data => data)
        );
    }

    updateFeaturesFromApplications(id: number|string, applicationId: number, featureId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${id}/applications/${applicationId}/features/${featureId}`, params).pipe(
            map(data => data)
        );
    }

    update(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/company/${id}/applications/messaging`, params).pipe(
            map(data => data)
        );
    }

    updateSettings(id: number, applicationId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/company/${id}/applications/${applicationId}/user-settings`, params).pipe(
            map(data => data)
        );
    }

    link(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/company/${id}/applications`, params).pipe(
            map(data => data)
        );
    }

    unlink(id: number, applicationId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/company/${id}/applications/${applicationId}`).pipe(
            map(data => data)
        );
    }
}
