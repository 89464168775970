import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild, TrackByFunction } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
    selector: 'msc-common-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonListComponent<T, U> {
    @Input() data: T[];
    @Input() emptyMessage?: string;
    @Input() isLoading?: boolean;
    @Input() hasLoadMore: boolean = false;
    @Output() loadMore: EventEmitter<any> = new EventEmitter<any>();
    @ContentChild('item', { static: false }) item: U;

    public fnOnTrackByIndex: TrackByFunction<any> = (i: number) => i;

    private destroy$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Event handler for load more
     */
    onLoadMore(): void {
        this.loadMore && this.loadMore.emit();
    }
}
