import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { StorageService } from '@services';

import { Subject } from 'rxjs';

@Component({
    selector: 'msc-common-feed-submit',
    templateUrl: './feed-submit.component.html',
    styleUrls: ['./feed-submit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonFeedSubmitComponent {
    @ViewChild('file') file: ElementRef;
    @ViewChild('iframe') iframe: ElementRef;
    @ViewChild('img') img: ElementRef;
    @Input() formPatch?: Record<string, unknown>;
    @Input() hasOptions: boolean = true;
    @Output() submit?: EventEmitter<any> = new EventEmitter<any>();

    public form: FormGroup;
    public resource: File;
    public isAside: boolean = false;

    private me: any;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly renderer: Renderer2,
        private readonly storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.form = this.fb.group({
            'contentMessage': ['', [Validators.required]],
            'file': [null, [Validators.required]],
            'url': ['', [Validators.required]],
        });
        this.form.patchValue(this.formPatch || {});
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get userAvatar(): string { return this.getUserAvatar(this.me); }
    get userName(): string { return this.getUserName(this.me); }
    get isPreviewIframe(): boolean { return this.iframe && this.iframe.nativeElement && this.iframe.nativeElement.src; }
    get isPreviewImage(): boolean { return this.img && this.img.nativeElement && this.img.nativeElement.src; }

    /**
     *
     */
     getUserName({ name }: any): string {
        return name || '';
    }

    /**
     *
     */
    getUserAvatar({ picture }: any): string {
        return picture || '';
    }

    /**
     * Event handler for clean up after iframe preview
     */
    getPreviewImage(file: File) {
        this.resource = file;

        switch (file.type) {
            case 'image/png':
            case 'image/jped':
            case 'image/gif':
            case 'image/bmp':
            case 'image/tiff':
            case 'image/svg+xml':
                return URL.createObjectURL(file);
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
                return 'https://static.myskillcamp.com/images/icon-doc.svg';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'text/csv':
                return 'https://static.myskillcamp.com/images/icon-xls.svg';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'https://static.myskillcamp.com/images/icon-ppt.svg';
            case 'application/pdf':
                return 'https://static.myskillcamp.com/images/icon-pdf.svg';
            default:
                return 'https://static.myskillcamp.com/images/icon-media.svg';
        }
    }

    /**
     *
     */
    onCleanPreviewIframe(): void {
        this.renderer.removeAttribute(this.iframe.nativeElement, 'src');
        this.form.patchValue({ url: null });
    }

    /**
     * Event handler for clean up after img preview
     */
    onCleanPreviewImg(): void {
        this.renderer.removeAttribute(this.img.nativeElement, 'src');
        this.form.patchValue({ file: null });
    }

    /**
     * Event handler for uploading the image
     */
    onUploadImg(): void {
        const element: HTMLElement = this.file.nativeElement as HTMLElement;
        element.click();
    }

    /**
     * Event handler for uploading the iframe
     */
    onUploadIframe({ data }: any): void {
        this.iframe.nativeElement.src = data;
        this.form.patchValue({ url: data });
        this.isAside = false;
    }

    /**
     * Event handler for native change on file input
     */
    onChange(files: FileList): void {
        const [file] = Array.from(files);
        this.img.nativeElement.src = this.getPreviewImage(file);
        this.form.patchValue({ file });
    }

    /**
     * Event handler for button click/submit
     */
    onSubmit(): void {
        this.submit && this.submit.emit(this.form.value);
        this.onCleanPreviewIframe();
        this.onCleanPreviewImg();
        this.form.patchValue({ contentMessage: '' });
    }
}
