import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Media, MediaProvider, UtilityProvider } from '@lighty';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MediaHelper } from '@helpers';
import { ErrorStreamService, WorkerService } from '@services';

@Component({
    selector: 'msc-vimeo',
    templateUrl: './vimeo.component.html'
})
export class VimeoComponent implements OnInit, OnDestroy {
    @Input() media: Media;
    private video: SafeResourceUrl;
    private interval: any;
    public ready: boolean = false;

    constructor(private sanitizer: DomSanitizer, private utilityProvider: UtilityProvider, private workerService: WorkerService,
                private mediaProvider: MediaProvider, private errorStreamService: ErrorStreamService) {}

    ngOnInit(): void {
        if (this.media.url) {
            this.process();
        } else {
            this.getStatus();
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    getVideo(url: string): SafeResourceUrl {
        return this.video ? this.video : this.video = this.sanitizer.bypassSecurityTrustResourceUrl(MediaHelper.getVideo(url));
    }

    private getStatus(timer: number = 500): void {
        this.interval = setInterval(() => {
            this.mediaProvider.getStatus(this.media.id).subscribe((data) => {
                if (data.status !== 0) {
                    if (data.status === 1) {
                        this.mediaProvider.getMedia(this.media.id).subscribe((media) => {
                            this.media = media;
                            this.process();
                        });
                    }
                } else {
                    this.getStatus(2500);
                }
            });
            clearInterval(this.interval);
        }, timer);
    }

    private process(): void {
        const id: number = parseInt(this.media.url.replace('//www.vimeo.com/', ''), 10);

        this.errorStreamService.locked();
        this.utilityProvider.getVimeoReady(id).subscribe(() => {
            this.ready = true;
            this.errorStreamService.unlocked();
        }, () => {
            this.errorStreamService.unlocked();
            this.worker(id);
        });
    }

    private worker(id: number): void {
        const func = function(event) {
            const interval = setInterval(() => {
                const req = new XMLHttpRequest();
                req.responseType = 'json';
                const self = this;
                req.onreadystatechange = function(e) {
                    if (this.readyState === XMLHttpRequest.DONE) {
                        if (this.status === 200) {
                            self.postMessage(this.response);
                            clearInterval(interval);
                        }
                    }
                };

                req.open('GET', 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + event.data[0] + '&version=' + new Date().getTime(), true);
                req.send();
            }, 10000);
        };

        this.workerService.run('vimeo', func, true);
        this.workerService.post('vimeo', [id]);
        this.workerService.listen('vimeo').subscribe((data) => {
            this.workerService.stop('vimeo');
            if (data !== 'error') {
                this.ready = true;
            }
        });
    }
}
