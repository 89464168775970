import { Countries } from '@classes';

export class DataHelper {
    public static capitalize(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    public static getCountries(lang: string): any[] {
        return new Countries().get(lang);
    }

    public static getCountry(lang: string, code: string, keyReturn: string = 'name'): string {
        if (code) {
            const countries = new Countries().get(lang);
            return countries.find((country) => country.code === code)[keyReturn];
        }
        return null;
    }

    public static booleanToString(bool: boolean): string {
        return bool ? '1' : '';
    }

    public static filtering(filters: any, key: string, attribute: string, defaultValue?: string): string {
        if (filters) {
            return this.booleanToString(filters[key][attribute]);
        }
        return defaultValue || '0';
    }

    public static filterCheckbox(object: any, splitter?: string): any {
        const collection = [];

        for (const key in object) {
            if (object[key] && object.hasOwnProperty(key)) {
                collection.push(key);
            }
        }

        if (splitter && collection.length > 0) {
            return collection.join(splitter);
        }
        return collection.length > 0 ? collection : null;
    }

    public static generateId(prefix?: string): string {
        const random = Math.random().toString(36).substr(2, 16);
        return prefix ? prefix + random : random;
    }

    public static merge(object: any, objectToMerge: any): any {
        for (const key in objectToMerge) {
            if (objectToMerge.hasOwnProperty(key)) {
                object[key] = objectToMerge[key];
            }
        }
        return object;
    }

    public static mergeGroupedCollection(collection: any, collectionToMerge: any, config: any = {key: 'title', value: 'items'}): any {
        const collectionCloned = this.cloneCollection(collection);
        const collectionToMergeCloned = this.cloneCollection(collectionToMerge);

        for (const item of collectionCloned) {
            const index = collectionToMergeCloned.findIndex((itemToMerge) => {
                return itemToMerge[config.key] === item[config.key];
            });

            if (index > -1) {
                item[config.value] = item[config.value].concat(collectionToMergeCloned[index][config.value]);
                delete collectionToMergeCloned[index];
            }
        }

        return this.merge(collectionCloned, collectionToMergeCloned);
    }

    public static split(content: string, splitter: string, last?: boolean): string {
        return last ? content.substring(0, content.indexOf(splitter)) : content.substring(content.indexOf(splitter));
    }

    public static splitBetween(content: string, first: string, last: string): string {
        return content.substring(content.indexOf(first), content.indexOf(last));
    }

    public static clone(assigner: any, base: any = {}, deep?: boolean): any {
        return deep ? JSON.parse(JSON.stringify(assigner)) : Object.assign(base, assigner);
    }

    public static cloneCollection(collection: any): any {
        return [...collection];
    }

    public static create(object: any): any {
        return Object.create(object);
    }

    public static isEmpty(content: any): boolean {
        if (content) {
            return Object.keys(content).length === 0;
        }
        return true;
    }

    public static toCollection(object: any, value?: boolean): any {
        if (object) {
            return value ? Object.values(object) : Object.keys(object);
        }
        return [];
    }

    public static filterBy(collection: any, filterKey: string): any {
        return collection.map((item: any) => item[filterKey]).filter((item: any, index: number, all: any) => all.indexOf(item) === index);
    }

    public static mergeBy(collection: any, filterKey: string, filterValue: string): any {
        return collection.filter(item => item[filterKey] === filterValue).map(item => item);
    }

    public static groupBy(collection: any, grouper: string, config: any = {key: 'title', value: 'items'}): any {
        return this.filterBy(collection, grouper).map((key: string) => {
            const grouped: any = {};
            grouped[config.key] = key;
            grouped[config.value] = this.mergeBy(collection, grouper, key);
            return grouped;
        });
    }

    public static urlFromIframe(iframe: string): string {
        return this.splitBetween(this.split(iframe, 'http'), 'http', '"');
    }

    public static clipboard(value: string): void {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.value = value;
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
    }

    public static changeCase(value: string, type: string = 'snake'): string {
        switch (type) {
            case 'camel':
                let splitedValue = value.split('_');
                splitedValue = splitedValue.map((splited, index) => {
                    if (index > 0 ) {
                        return splited.charAt(0).toUpperCase() + splited.slice(1);
                    }
                    return splited;
                });
                value = splitedValue.join('');
                break;
            case 'snake':
                value = value.split(/(?=[A-Z])/).join('_').toLowerCase();
                break;
        }

        return value;
    }

    public static jsonParse(value: string, key?: string): any {
        try {
            return key ? JSON.parse(value)[key] : JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    public static isJSON(value: any): boolean {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    }

    public static move(collection: any, currentIndex: number, newIndex: number): any {
        collection.splice(newIndex, 0, collection.splice(currentIndex, 1)[0]);
        return collection;
    }
}
