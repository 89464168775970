import { Component, Input, OnInit } from '@angular/core';
import { Correction } from '@lighty';

@Component({
    selector: 'msc-feat-correction-list',
    templateUrl: './list-correction.component.html'
})
export class ListCorrectionComponent implements OnInit {
    @Input() corrections: Correction[];
    @Input() title: string;
    @Input() index: number = 0;

    constructor() {}

    ngOnInit(): void {}

    hasCorrections(): boolean {
        return this.corrections.length > 0;
    }
}
