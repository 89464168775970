export class CompanyRole {
    companyLearner: boolean;
    companyManager: boolean;
    companyAdministrator: boolean;
    companyAuthor: boolean;
    companyOwner: boolean;

    constructor(role: object) {
        this.assign(role);
    }

    assign(role: object): void {
        Object.assign(this, role);
    }
}
