import { Injectable } from '@angular/core';
import { UrlHelper } from '@helpers';
import { environment } from '@env/environment';

/**
 * Redirect Service
 */
@Injectable({providedIn: 'root'})
export class RedirectService {

    constructor() {}

    navigate(applicationName: string, parts: any, params?: any, target: string = '_self'): void {
        if (environment.name.short === 'dev' || `${UrlHelper.getHostname()}/` === environment.envVar.APP_URL.replace('https://', '')) {
            UrlHelper.navigate({parts: [environment.envVar[applicationName.toUpperCase() + '_URL'], ...parts], params}, target);
        } else {
            switch (applicationName) {
                case 'studio':
                case 'player':
                    return UrlHelper.navigate({parts: [UrlHelper.getHostname() + '/' + applicationName, ...parts], params}, target);
                case 'app':
                    return UrlHelper.navigate({parts: [UrlHelper.getHostname(), ...parts], params}, target);
            }
        }
    }
}
