import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//
import { AppCommon2Module } from '@common2/common.module';
import { AppCoreModule } from '@core/core.module';
import { SharedModule } from '../../shared.module'; // TEMP
//
import { ShowroomRoutingModule } from './showroom-routing.module';
// Pages
import { ShowroomExhibitPageComponent } from './pages/exhibit/showroom-exhibit-page.component';
import { ShowroomExhibitAccordionPageComponent } from './pages/exhibit-accordion/showroom-exhibit-accordion-page.component';
import { ShowroomExhibitAsidePageComponent } from './pages/exhibit-aside/showroom-exhibit-aside-page.component';
import { ShowroomExhibitAvatarPageComponent } from './pages/exhibit-avatar/showroom-exhibit-avatar-page.component';
import { ShowroomExhibitButtonPageComponent } from './pages/exhibit-button/showroom-exhibit-button-page.component';
import { ShowroomExhibitChartPageComponent } from './pages/exhibit-chart/showroom-exhibit-chart-page.component';
import { ShowroomExhibitCheckboxPageComponent } from './pages/exhibit-checkbox/showroom-exhibit-checkbox-page.component';
import { ShowroomExhibitEmptyPageComponent } from './pages/exhibit-empty/showroom-exhibit-empty-page.component';
import { ShowroomExhibitErrorPageComponent } from './pages/exhibit-error/showroom-exhibit-error-page.component';
import { ShowroomExhibitIconsPageComponent } from './pages/exhibit-icons/showroom-exhibit-icons-page.component';
import { ShowroomExhibitInlineEditPageComponent } from './pages/exhibit-inline-edit/showroom-exhibit-inline-edit-page.component';
import { ShowroomExhibitInputPageComponent } from './pages/exhibit-input/showroom-exhibit-input-page.component';
import { ShowroomExhibitLoaderPageComponent } from './pages/exhibit-loader/showroom-exhibit-loader-page.component';
import { ShowroomExhibitModalPageComponent } from './pages/exhibit-modal/showroom-exhibit-modal-page.component';
import { ShowroomExhibitOverlayPageComponent } from './pages/exhibit-overlay/showroom-exhibit-overlay-page.component';
import { ShowroomExhibitRadioPageComponent } from './pages/exhibit-radio/showroom-exhibit-radio-page.component';
import { ShowroomExhibitSearchPageComponent } from './pages/exhibit-search/showroom-exhibit-search-page.component';
import { ShowroomExhibitSelectPageComponent } from './pages/exhibit-select/showroom-exhibit-select-page.component';
import { ShowroomExhibitSliderPageComponent } from './pages/exhibit-slider/showroom-exhibit-slider-page.component';
import { ShowroomExhibitSpinnerPageComponent } from './pages/exhibit-spinner/showroom-exhibit-spinner-page.component';
import { ShowroomExhibitSwitchPageComponent } from './pages/exhibit-switch/showroom-exhibit-switch-page.component';
import { ShowroomExhibitTabPageComponent } from './pages/exhibit-tab/showroom-exhibit-tab-page.component';
import { ShowroomExhibitTablePageComponent } from './pages/exhibit-table/showroom-exhibit-table-page.component';
import { ShowroomExhibitTagPageComponent } from './pages/exhibit-tag/showroom-exhibit-tag-page.component';
import { ShowroomExhibitThemingPageComponent } from './pages/exhibit-theming/showroom-exhibit-theming-page.component';
import { ShowroomExhibitTextareaPageComponent } from './pages/exhibit-textarea/showroom-exhibit-textarea-page.component';
import { ShowroomExhibitToastPageComponent } from './pages/exhibit-toast/showroom-exhibit-toast-page.component';
import { ShowroomExhibitTooltipPageComponent } from './pages/exhibit-tooltip/showroom-exhibit-tooltip-page.component';
import { ShowroomExhibitTreePageComponent } from './pages/exhibit-tree/showroom-exhibit-tree-page.component';
import { ShowroomExhibitUploadPageComponent } from './pages/exhibit-upload/showroom-exhibit-upload-page.component';
import { ShowroomExhibitWavePageComponent } from './pages/exhibit-wave/showroom-exhibit-wave-page.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppCommon2Module,
        SharedModule,
        AppCoreModule,
        ShowroomRoutingModule,
    ],
    declarations: [
        // Pages
        ShowroomExhibitPageComponent,
        ShowroomExhibitAccordionPageComponent,
        ShowroomExhibitAsidePageComponent,
        ShowroomExhibitAvatarPageComponent,
        ShowroomExhibitButtonPageComponent,
        ShowroomExhibitChartPageComponent,
        ShowroomExhibitCheckboxPageComponent,
        ShowroomExhibitEmptyPageComponent,
        ShowroomExhibitErrorPageComponent,
        ShowroomExhibitIconsPageComponent,
        ShowroomExhibitInlineEditPageComponent,
        ShowroomExhibitInputPageComponent,
        ShowroomExhibitLoaderPageComponent,
        ShowroomExhibitModalPageComponent,
        ShowroomExhibitOverlayPageComponent,
        ShowroomExhibitRadioPageComponent,
        ShowroomExhibitSearchPageComponent,
        ShowroomExhibitSelectPageComponent,
        ShowroomExhibitSliderPageComponent,
        ShowroomExhibitSpinnerPageComponent,
        ShowroomExhibitSwitchPageComponent,
        ShowroomExhibitTabPageComponent,
        ShowroomExhibitTablePageComponent,
        ShowroomExhibitTagPageComponent,
        ShowroomExhibitThemingPageComponent,
        ShowroomExhibitTextareaPageComponent,
        ShowroomExhibitToastPageComponent,
        ShowroomExhibitTooltipPageComponent,
        ShowroomExhibitTreePageComponent,
        ShowroomExhibitUploadPageComponent,
        ShowroomExhibitWavePageComponent,
    ]
})

export class ShowroomModule { }
