import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef } from '@angular/core';

@Component({
    selector: 'msc-common-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonLoaderComponent {
    @Input() message?: string;
    @ContentChild(TemplateRef) bodyTemplate: TemplateRef<any>;
}
