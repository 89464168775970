<msc-common-aside #aside (saveEvent)="save()">
    <ng-template #headerTemplate>
        <h3>{{ 'words.forbid' | translate }}</h3>
        <small>{{ 'skill.replace-skill' | translate }}</small>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div>
                <p class="mb-2">{{ 'skill.suggest-skill' | translate }}</p>

                <div>
                    <msc-common2-radio name="suggestSkill" [(ngModel)]="suggest" [valueName]="true"
                        [label]="'words.yes' | translate"></msc-common2-radio>
                    <msc-common2-radio name="suggestSkill" [(ngModel)]="suggest" [valueName]="false"
                    [label]="'words.no' | translate"></msc-common2-radio>
                </div>

                <div class="skills">
                    <div class="skill skill--shadow-none skill--forbidden" *ngIf="selectedSkill">
                        <div class="skill__content">
                            <div class="skill__icon">
                                <i class="icon icon-slash-1"></i>
                            </div>
    
                            <p class="skill__text">{{ selectedSkill?.name }}</p>
                        </div>
                    </div>
    
                    <div class="skills__arrow" *ngIf="activeSkill">
                        <i class="icon icon-arrow-ios-right"></i>
                    </div>
    
                    <div class="skill skill--shadow-none skill--alternate" *ngIf="activeSkill">
                        <div class="skill__content">
                            <p class="skill__text">{{ activeSkill.name }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col space-y-2">
                <div class="slide__input" *ngIf="suggest">
                    <msc-common-search [placeholder]="'placeholder.type-skill' | translate"
                        (searchEvent)="search($event.value)"></msc-common-search>
                </div>

                <div class="skills skills--search" *ngIf="suggest">
                    <div class="flex flex-wrap">
                        <div class="skills__item" *ngFor="let skill of skills">
                            <div class="skill skill--shadow-none skill--level skill--selected"
                                (click)="selectSkill(skill)" [ngClass]="{'active': isActive(skill)}">
                                <div class="skill__content">
                                    <p class="skill__text">{{ skill.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <msc-common-spinner *ngIf="loading"></msc-common-spinner>
                </div>
            </div>

            <div class="empty-skill" *ngIf="skills && skills.length === 0 && !loading">
                {{ 'empty.skill' | translate }}
            </div>

            <div class="slide__message" *ngIf="!suggest || !activeSkill">
                <span class="color--orange">{{ selectedSkill.usage.total }}</span> {{ (selectedSkill.usage.total > 1 ?
                'skill.resources-lost' : 'skill.resource-lost') | translate }}
            </div>

            <div class="slide__message" *ngIf="suggest && activeSkill">
                <span class="color--orange">{{ selectedSkill.usage.total }}</span> {{ (selectedSkill.usage.total > 1 ?
                'skill.resources-association' : 'skill.resource-association') | translate }}
            </div>
        </div>
    </ng-template>

</msc-common-aside>