import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmitterService, StorageService } from '@services';
import { HttpHelper } from '@helpers';
import { ExtensionLink } from '@interfaces';
import { CampProvider } from '@lighty';

@Component({
    selector: 'msc-shared-aside-extension-camps',
    templateUrl: './aside-extension-camps.component.html'
})
export class SharedExtensionCampsComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();
    private selectedCamps: any[] = [];
    public camps: any[] = [];
    public pagination: any;
    public campsloading: boolean = true;

    constructor(private emitterService: EmitterService, private storageService: StorageService, private campProvider: CampProvider) {}

    ngOnInit(): void {
        this.loadCamps();
    }

    loadMore(): void {
        this.pagination.currentPage += 1;
        this.loadCamps();
    }

    loadCamps(): void {
        const params = HttpHelper.cleanParams({
            page: this.pagination ? this.pagination.currentPage : 1
        });

        this.campProvider.getCamps(this.storageService.get('company').id, params).subscribe(data => {
            data.camps.forEach(camp => {
                this.camps.push(camp);
            });

            this.pagination = data.pagination;
            this.campsloading = false;
        });
    }

    select(camp: any): void {
        const index = this.extensionLink.campList.indexOf(camp.id);

        if (index > -1) {
            this.extensionLink.campList.splice(index, 1);
        } else {
            this.extensionLink.campList.push(camp.id);
        }

        this.selectCamp(camp);
    }

    isChecked(camp: any): boolean {
        return this.extensionLink.campList.indexOf(camp.id) > -1;
    }

    nextPanel(): void {
        this.storageService.set('selectedCamps', this.selectedCamps);
        this.emitterService.extensionPage.emit('form');
    }

    private selectCamp(camp: any): void {
        const index = this.selectedCamps.findIndex((selectedCamp) => {
            return selectedCamp.id === camp.id;
        });

        if (index > -1) {
            this.selectedCamps.splice(index, 1);
        } else {
            this.selectedCamps.push(camp);
        }
    }
}


