import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from '@services';

@Component({
    selector: 'msc-camp-aside-invite-members-rights',
    templateUrl: './aside-invite-members-rights.component.html',
    styleUrls: ['./aside-invite-members-rights.component.scss'],
})
export class CampAsideInviteMembersRightsComponent implements OnInit {
    @Input() data: any;
    @Input() loading: boolean;
    @Output() dataChange: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public camp: any;

    constructor(private storageService: StorageService) {}

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');
    }

    checkPermissions(): void {
        if (!this.data.roles.camp_administrator) {
            this.data.permissions.manage_camp_settings = false;
        }
    }

    public update(): void {
        this.dataChange.emit(this.data);
    }
}
