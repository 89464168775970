import { Component, ChangeDetectionStrategy } from '@angular/core';
//
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-showroom-exhibit-error-page',
    templateUrl: './showroom-exhibit-error-page.component.html',
    styleUrls: ['./showroom-exhibit-error-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitErrorPageComponent {
    constructor(
        private readonly toastService: CommonToastService,
    ) { }

    /**
     *
     */
    onEvent(): void {
        this.toastService.onInfo(`This mission is too important for me to allow you to jeopardize it. Good try anyway 😝`);
    }
}
