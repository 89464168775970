<div class="flex gap-4 h-full stats-block-flex ">
    <div
        class="stats-block stats-block--gradient bg-gradient-to-tr from-primary-500 to-primary-300">
        <msc-common-spinner *ngIf="loading"></msc-common-spinner>

        <ng-container *ngIf="!loading">
            <p>
                <i class="icon icon-clock-1"></i>
                {{ getDuration()[0] }}
            </p>
            <span>{{ getDuration()[1] | translate }}</span>
        </ng-container>
    </div>

    <div class="stats-block bg-white">
        <msc-common-spinner *ngIf="loading"></msc-common-spinner>

        <ng-container *ngIf="!loading">
            <p>
                <i class="icon icon-content-1"></i>
                {{ stats?.completed }}
            </p>
            <span>
                {{ (stats?.completed > 1 ? 'overview.stats.courses' : 'overview.stats.course') |
                translate }}
            </span>
        </ng-container>
    </div>
</div>
