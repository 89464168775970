import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { StorageService } from '@services';

@Injectable()
export class CompanyCookieGuard implements CanActivate {

    constructor(private router: Router, private storageService: StorageService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!this.storageService.get('company')) {
            this.router.navigate(['login', 'company']);
        }
        return true;
    }
}
