<div class="link-preview" *ngIf="preview" (click)="navigate()" [ngClass]="{'link-preview--grey': backgroundColor === 'grey', 'link-preview--timeline': timeline}">
    <img class="link-preview__cover" [src]="preview.cover" *ngIf="preview.cover">

    <div class="link-preview__content">
        <p class="link-preview__content__url">{{ link }}</p>

        <p class="link-preview__content__title text-primary-500">{{ preview.title }}</p>

        <p class="link-preview__content__desc">{{ preview.description }}</p>
    </div>
</div>
