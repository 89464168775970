<div class="modal modal-communities-description" [ngClass]="{'active': isActive}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content" [autoClose]="isActive" (autoCloseChange)="close()" [activeClass]="'modal__content'">
        <div class="modal__header">
            <div class="modal__close" (click)="close()">
                <i class="icon icon-close"></i>
            </div>
        </div>

        <div class="modal__body" data-simplebar>
            <div class="modal-communities-description__picture">
                <img [src]="'https://static.myskillcamp.com/lxp/images/logo-camp.svg'" *ngIf="!community.media">
                <img [src]="community.media?.pictureUrl + '?size=512'" *ngIf="community.media">

                <div class="modal-communities-description__title">
                    <msc-tag [text]="'group.type.' + community.type | translate" [color]="'green'"></msc-tag>

                    <div class="title">{{ community.title }}</div>
                </div>
            </div>

            <div class="modal-communities-description__desc">
                {{ community.info }}
            </div>

            <div class="modal-communities-description__action">
                <msc-button class="msc-button--primary" palette="secondary" (click)="subscribeToGroup()">
                    {{ 'words.subscribe-start' | translate }}
                </msc-button>
            </div>

            <div class="modal-communities-description__team" *ngIf="users && users.length > 0">
                <div class="block-member">
                    <div class="member">
                        <msc-common-avatar [label]="users[0].account?.name" [pictureURL]="users[0].account?.picture ? (users[0].account?.picture + '?size=128') : null"></msc-common-avatar>

                        <div class="member__infos ml-2">
                            <div class="member__name text-secondary-800">{{ users[0].account.name }}</div>
                            <div class="member__job">{{ users[0].account.headline }}</div>
                        </div>
                    </div>

                    <div class="block-member__discussion" (click)="startDiscussion(users[0].account)">
                        <i class="icon icon-message-square bg-secondary-500"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
