import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManageCorrectionProvider, Company } from '@lighty';
import { StorageService } from '@services';
import { Filter } from '@interfaces';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-manage-instance-list-correction',
    templateUrl: './correction-list.component.html'
})
export class ManageInstanceCorrectionListComponent implements OnInit {
    private company: Company;
    private journey: any;
    public query: string;
    public replica: Filter[];
    public activeFilters: boolean = false;
    public dataFilters: any;
    public displays: any[] = [];
    public corrections: any[] = [];
    public instances: any[] = [];
    public sectionContent: any[] = [];
    public loading: any = {};

    constructor(private route: ActivatedRoute, private correctionProvider: ManageCorrectionProvider, private storageService: StorageService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.journey = this.storageService.get('journey');

        this.loading = {
            global: false,
            section: []
        };

        this.getInstances();
    }

    getInstances(): void {
        this.loading.global = true;
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.correctionProvider.getTrajectContentList(this.company.id, this.journey.id, params).subscribe((data) => {
            this.instances = data.correctibles;
            this.loading.global = false;
        });
    }

    getActivityIcon(context: any): string {
        if (context.type === 'course') {
            return 'icon-cube';
        } else if (context.resume.course) {
            return 'icon-cube';
        } else if (context.resume.media) {
            return 'icon-video';
        } else if (context.resume.cheatsheet) {
            return 'icon-file-text';
        } else if (context.resume.exercise) {
            return 'icon-checkmark-square';
        } else if (context.resume.event) {
            return 'icon-calendar';
        } else if (context.resume.exchange) {
            return 'icon-message-circle';
        } else if (context.resume.project) {
            return 'icon-clipboard';
        } else if (context.resume.section) {
            return 'icon-height';
        } else {
            return 'icon-checkmark-square';
        }
    }

    search(query?): void {
        this.query = query;
        this.getInstances();
    }

    saveInstance(correctible: any): void {
        const instance = {
            id : correctible.courseInstanceId
        };
        this.storageService.set('instance', instance);
    }

    open(correctible: any, index: number): void {
        if (correctible.type !== 'course' || correctible.correctibleInstanceIds.length < 1) {
            return null;
        }
        this.displays[index] = !this.displays[index];

        if (this.displays[index] && !this.sectionContent[index]) {
            this.loading.section[index] = true;

            this.correctionProvider.getTrajectContent(this.company.id, this.journey.id, correctible.id).subscribe((data) => {
                this.sectionContent[index] = data;
                for (const correct of this.sectionContent[index].correctibles) {
                    if (Object.values(correctible.correctibleInstanceIds).indexOf(correct.courseInstanceId) === -1) {
                        this.sectionContent[index].correctibles.splice(index, 1);
                    }
                }
                this.loading.section[index] = false;
            });
        }
    }

    goToCorrectible(correctible: any, type: string): string[] {
        if (type === 'course') {
            return ['course' , correctible.courseInstanceId];
        } else if ( type === 'content' && (correctible.resume.project || correctible.resume.exercise || correctible.resume.selfassessment) ) {
            return ['course', correctible.resume.courseId, 'activity', correctible.typeId];
        } else {
            return [];
        }
    }
}
