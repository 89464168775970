import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ManageInstanceProvider, ManageCalendarProvider, ManageLearnerProvider, ManageFollowUpProvider, StatsProvider, AdminGroupUserProvider, ManageTeamProvider, ContentProvider } from '@lighty';
import { HttpHelper } from '@helpers';
import { TeamSortValues } from '@enums';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Injectable()
export class ManageInstancesResolver implements Resolve<any> {
    constructor(private manageInstanceProvider: ManageInstanceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            return this.manageInstanceProvider.list(route.parent.parent.paramMap.get('slug'));
        }
    }
}

@Injectable()
export class ManageInstanceResolver implements Resolve<any> {
    constructor(private manageInstanceProvider: ManageInstanceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            const params = {
                context: this.getContentType(route.paramMap.get('instanceType'))
            };

            return this.manageInstanceProvider.get(route.parent.parent.paramMap.get('slug'), parseInt(route.paramMap.get('instanceId'), 10), params);
        }
    }

    private getContentType(type: string): string {
        switch (type) {
            case 'template':
                return 'course_instance';
            case 'journey':
                return 'traject';
            default:
                return 'external_content';
        }
    }
}

@Injectable()
export class ManageCalendarResolver implements Resolve<any> {
    constructor(private manageCalendarProvider: ManageCalendarProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.parent.paramMap.get('slug')) {
            const params = {
                context: route.parent.data.instance.type
            };

            return this.manageCalendarProvider.get(route.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.paramMap.get('instanceId'), 10), params);
        }
    }
}

@Injectable()
export class ManageInstanceSettingsResolver implements Resolve<any> {
    constructor(private manageInstanceProvider: ManageInstanceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.parent.paramMap.get('slug')) {
            const params = {
                context: route.parent.data.instance.type
            };

            return this.manageInstanceProvider.getSettings(route.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.paramMap.get('instanceId'), 10), params);
        }
    }
}

@Injectable()
export class ManageInstanceManagersResolver implements Resolve<any> {
    constructor(private manageInstanceProvider: ManageInstanceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.parent.paramMap.get('slug')) {
            const params = {
                context: route.parent.data.instance.type
            };

            return this.manageInstanceProvider.getManagers(route.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.paramMap.get('instanceId'), 10), params);
        }
    }
}

@Injectable()
export class ManageInstanceLearnerResolver implements Resolve<any> {
    constructor(private manageLearnerProvider: ManageLearnerProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.parent.parent.paramMap.get('slug')) {
            const params = {
                context: this.getContentType(route.parent.parent.paramMap.get('instanceType'))
            };

            return this.manageLearnerProvider.get(route.parent.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.parent.paramMap.get('instanceId'), 10), params);
        }
    }

    private getContentType(type: string): string {
        switch (type) {
            case 'template':
                return 'course_instance';
            case 'journey':
                return 'traject';
            default:
                return 'external_content';
        }
    }
}

@Injectable()
export class ManageInstanceStatsResolver implements Resolve<any> {
    constructor(private statsProvider: StatsProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = HttpHelper.cleanParams({
            entity: route.paramMap.get('entity'),
            entityId: route.paramMap.get('entityId'),
            display: route.paramMap.get('display'),
            accountId: route.paramMap.get('learnerId')
        });

        return this.statsProvider.get(params);
    }
}

@Injectable()
export class ManageInstanceFollowUpResolver implements Resolve<any> {
    constructor(private manageFollowUpProvider: ManageFollowUpProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.parent.paramMap.get('slug')) {
            return this.manageFollowUpProvider.getUsers(route.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.paramMap.get('instanceId'), 10));
        }
    }
}

@Injectable()
export class ManageTeamListResolver implements Resolve<any> {
    public sortValues = TeamSortValues;

    constructor(private teamProvider: ManageTeamProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug') && route.paramMap.get('id')) {
            let params;
            if (!_.isEmpty(route.queryParams)) {
                params = HttpHelper.cleanParams({...route.queryParams, page: route.queryParams.currentPage});
            } else {
                params = {
                    sort: 'desc',
                    sortBy: this.sortValues.ACTIONS_TO_PROCESS
                }
            }
            return this.teamProvider.getGroupUsers(route.parent.parent.paramMap.get('slug'), +route.paramMap.get('id'), params).pipe(map((res) => ({ ...res, pagination: { ...res.pagination, sort: params.sort, sortBy: params.sortBy } })));
        }
    }
}

@Injectable()
export class ManageTeamSettingsResolver implements Resolve<any> {
    constructor(private teamProvider: ManageTeamProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.parent.paramMap.get('slug') && route.parent.paramMap.get('id')) {
            return this.teamProvider.getGroup(route.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.paramMap.get('id')));
        }
    }
}

@Injectable()
export class ManageTeamMemberResolver implements Resolve<any> {
    constructor(private groupUserProvider: AdminGroupUserProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        let groupId = 2;
        let userId = 2;
        let company_id = 3;
        if (groupId && userId) {
            // return this.groupUserProvider.getUser(company_id, groupId, userId);
            return of(true);
        }
    }
}


