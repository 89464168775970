import { Account } from '../../account';

export class CompanyExperience {
    announceMediaId?: number;
    announceMessage?: string;
    announcePicture?: string;
    announceUrl?: string;
    catalogueDisplay?: string;
    catalogueMediaId?: number;
    cataloguePicture?: string;
    colorTemplate?: any;
    contact: Account;
    contactId?: number;
    customUrl?: string;
    displayMediaId?: number;
    displayName: string;
    displayPicture?: string;
    id: number;
    loginMediaId?: number;
    loginMessages?: any;
    loginPicture?: string;
    loginUrl?: string;
    name: string;
    primaryColor?: string;
    quoteAsked?: 0 | 1;
    secondaryColor?: string;
    senderEmail?: string;
    senderName?: string;
    sendersCount?: number;
    sendersLimit?: number;
    slug: string;
    url?: any;
    usersCount?: number;

    constructor(experience: object) {
        this.assign(experience);
    }

    assign(experience: object): void {
        Object.assign(this, experience);
    }
}
