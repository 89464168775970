import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter a collection
 */
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    /**
     * Transform
     * @param {any[]} collection - Collection
     * @param {string} key - Filtered key
     * @param {string} search - Value to be searched
     * @return {any} Filtered collection
     */
    transform(collection: any[], key: string, search: string): any {
        if (search) {
            return collection.filter((item) => item[key].toLowerCase().match(search.toLowerCase()));
        }
        return collection;
    }
}
