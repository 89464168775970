import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MediaProvider, Media, Camp } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { DataHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-composer',
    templateUrl: './composer.component.html',
    styleUrls: ['./composer.component.scss']
})
export class ComposerComponent implements OnInit, OnChanges {
    @ViewChild('uploadedFile') uploadedFile: ElementRef;
    @Input() grey: boolean = false;
    @Input() edit: boolean = false;
    @Input() dynamic: boolean = false;
    @Input() autoResize: boolean = false;
    @Input() me: any;
    @Input() camps: Camp[];
    @Input() unlock: EventEmitter<boolean> = new EventEmitter();
    @Input() media: Media;
    @Input() message: string;
    @Output() messageChange: EventEmitter<string> = new EventEmitter();
    @Output() messageDebounced: EventEmitter<any> = new EventEmitter();
    @Output() messageSended: EventEmitter<any> = new EventEmitter();
    @Output() onSelectedCamp: EventEmitter<any> = new EventEmitter();
    private lock: boolean = false;
    public composerId: string;
    public active: boolean = false;
    public preview: any;
    public selectedCamp: Camp;
    public loaders: any = {
        sending: false
    };
    public selectCampOpen = false;

    constructor(
        private renderer: Renderer2,
        private mediaProvider: MediaProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.preview = {
            url: '',
            data: '',
            type: '',
            name: ''
        };

        this.selectedCamp = this.storageService.getCache('composerCamp');

        this.composerId = DataHelper.generateId('composer-');

        this.active = this.edit;

        this.unlock.subscribe((lock) => {
            this.lock = !lock;
        });
    }

    ngOnChanges(): void {
        if (this.camps && !this.selectedCamp) {
            this.selectCamp(this.camps[0], false);
        }
    }

    importFile(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.uploadedFile.nativeElement).dispatchEvent(event);
    }

    removePreview(): void {
        this.preview = {
            url: '',
            data: '',
            type: '',
            name: ''
        };
        this.uploadedFile.nativeElement.value = '';
    }

    removeMedia(): void {
        this.mediaProvider.deleteMedia(this.media.id).subscribe((resp) => {
            this.media = null;
        });
    }

    importEmbed(parsed: any): void {
        this.preview.url = parsed.url;
        this.preview.data = parsed.data;
        this.preview.type = 'embed';
    }

    load(file: File, type: string = 'picture'): void {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.preview.url = event.target.result;
            this.preview.data = file;
            this.preview.type = type;
        };
        reader.readAsDataURL(file);
    }

    loadFile(file: File): void {
        if (file.type && file.type.indexOf('image') > -1) {
            this.load(file);
        } else if (file.type && file.type.indexOf('video') > -1) {
            this.load(file, 'video');
        } else {
            this.preview.type = 'file';
            this.preview.data = file;
            this.preview.name = file.name;
        }
    }

    send(e): void {
        if(e) {
            e.preventDefault();
        }
        if ((this.message !== '' || this.preview) && !this.lock) {
            this.loaders.sending = true;
            this.lock = true;
            if (!this.preview.type) {
                this.emitSend(this.media);
            } else if (this.preview.type !== 'embed') {
                this.mediaProvider.uploadMedia(this.preview.data).subscribe((media) => {
                    this.emitSend(media);
                }, (error) => {
                    this.loaders.sending = false;
                    this.lock = false;
                    this.removePreview();
                    if (error.status === 400 || error.status === 415) {
                        this.toastService.onError(this.translateService.instant('toast.error.file-type.not-supported'));
                    }
                }, () => {
                    this.loaders.sending = false;
                    this.removePreview();
                    this.lock = false;
                });
            } else if (this.preview.type === 'embed') {
                this.mediaProvider.uploadEmbed({ url: this.preview.data }).subscribe((media) => {
                    this.emitSend(media);
                });
            }
        } else if (this.message && !this.preview) {
            this.toastService.onError(this.translateService.instant('toast.message-required'));
        }
    }

    private emitSend(media?: Media): void {
        this.loaders.sending = false;
        this.messageSended.emit(media);
        this.removePreview();
        this.quit();
    }

    onFocus(): void {
        this.active = true;
    }

    quit(): void {
        const timeOut = setTimeout(() => {
            this.active = false;
            clearTimeout(timeOut);
        }, 1000);
    }

    selectCamp(camp: Camp, store: boolean = true): void {
        if (store) {
            this.storageService.setCache('composerCamp', camp, null, true);
        }
        this.selectedCamp = camp;
        this.onSelectedCamp.emit(camp);
    }

    isSelectedCamp(camp: Camp): boolean {
        return this.selectedCamp && this.selectedCamp.id === camp.id;
    }
}
