import { Directive, ElementRef, Input, Output, EventEmitter, OnChanges, OnDestroy, Renderer2 } from '@angular/core';
import { addClass, removeClass } from '@functions';

@Directive({
    selector: '[autoClose]'
})
export class AutoCloseDirective implements OnChanges, OnDestroy {
    @Input('activeClass') activeClass: string = 'active';
    @Input('overlay') overlay: boolean = false;
    @Input('overlayLight') overlayLight: boolean = false;
    @Input('autoClose') isActive: boolean = false;
    @Output('autoCloseChange') isActiveChange: EventEmitter<boolean> = new EventEmitter();
    private listener: any;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngOnChanges(): void {
        if (this.isActive) {
            const timeOut = setTimeout(() => {
                this.listener = this.renderer.listen('document', 'click', (event) => {
                    this.onClickOut(event);
                });
                clearTimeout(timeOut);
            }, 10);

            this.toogleOverlay();
        } else {
            if (typeof this.listener === 'function') {
                const timeOut = setTimeout(() => {
                    this.listener();
                    clearTimeout(timeOut);
                }, 10);
            }
        }
    }

    ngOnDestroy(): void {
        if (typeof this.listener === 'function') {
            this.listener();
        }
        this.toogleOverlay(true);
    }

    private toogleOverlay(hide?: boolean): void {
        if (this.overlay) {
            if (!hide) {
                const timeOut = setTimeout(() => {
                    addClass('.overlay', 'active');
                    if (this.overlayLight) {
                        addClass('.overlay', 'active--light');
                    }
                    clearTimeout(timeOut);
                }, 10);
            } else {
                removeClass('.overlay', 'active');
                if (this.overlayLight) {
                    removeClass('.overlay', 'active--light');
                }
            }
        }
    }

    private onClickOut(event: any): void {
        const selector = '.' + this.element.nativeElement.className.split(' ')[0];
        if (!event.target.closest(selector)) {
            this.isActiveChange.emit(false);
            this.toogleOverlay(true);
        }
    }
}
