import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-learn-community',
    templateUrl: './community.component.html'
})
export class LearnCommunityComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
