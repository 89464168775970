import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'msc-common-th-checkbox',
    templateUrl: './th-checkbox.component.html',
    styleUrls: ['./th-checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonThCheckboxComponent {
    @Input() isPartial: boolean; // value to determine if we show the partial/intermitent checkbox
    @Input() value: boolean; // value of checked property
    @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Event handler for change
     */
    onChange(): void {
        this.value = !this.value;
        this.changeEvent.emit(this.value);
    }
}
