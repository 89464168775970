import { Component, OnInit, Input } from '@angular/core';
import { AdminMemberProvider } from '@lighty';
import { EmitterService, StorageService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-camp-aside-invite-members-choice',
    templateUrl: './aside-invite-members-choice.component.html',
    styleUrls: ['./aside-invite-members-choice.component.scss'],
})
export class CampAsideInviteMembersChoiceComponent implements OnInit {
    @Input() data: any;
    @Input() page: string;
    private camp: any;
    public loading: boolean = false;
    public query: string;
    public membersSelected: any[] = [];

    constructor(private memberProvider: AdminMemberProvider, private storageService: StorageService, private emitterService: EmitterService) {}

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.memberProvider.getAvailableMembers(this.camp.id, params).subscribe((data) => {
            this.data.availableMembers = data.members;
            this.data.pagination = data.pagination;
        });
    }

    inviteAll(): void {
        this.data.invite_all_accounts = !this.data.invite_all_accounts;

        for (const member of this.data.availableMembers) {
            if (this.data.invite_all_accounts) {
                this.addMember(member);
            } else {
                this.removeMember(member);
            }
        }
    }

    updateMember(member: any): void {
        if (!this.data.invite_all_accounts) {
            if (this.isMemberSelected(member)) {
                this.removeMember(member);
            } else {
                this.addMember(member);
            }
        }
    }

    private addMember(member: any): void {
        this.membersSelected.push(member);
        this.data.account_ids.push(member.id);
    }

    private removeMember(member: any): void {
        const index = this.membersSelected.findIndex((data) => {
            return data.id === member.id;
        });

        if (index !== -1) {
            this.membersSelected.splice(index, 1);
        }

        const indexData = this.data.account_ids.findIndex((data) => {
            return data === member.id;
        });

        if (indexData !== -1) {
            this.data.account_ids.splice(index, 1);
        }
    }

    isMemberSelected(member: any): boolean {
        if (this.data.invite_all_accounts) {
            return true;
        }
        return this.membersSelected.findIndex((data) => {
            return data.id === member.id;
        }) !== -1;
    }

    loadMore(): void {
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.data.pagination.currentPage + 1
        });

        this.memberProvider.getAvailableMembers(this.camp.id, params).subscribe((data) => {
            this.data.availableMembers = this.data.availableMembers.concat(data.members);
            this.data.pagination = data.pagination;
            this.loading = false;
        });
    }
}
