import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { catchError, mergeScan, map, tap, repeat, takeUntil, concatMap } from 'rxjs/operators';
//
import { SubscriptionCollection } from '@classes';
import { Company, Camp, CompanyProvider, StatisticsProvider2 } from '@lighty';
import { StorageService, EmitterService } from '@services';
//
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-camp',
    templateUrl: './camp.component.html',
    styleUrls: ['./camp.component.scss'],
})

export class CampComponent {
    @ViewChild('modalCreateCamp') modalCreateCamp: CommonModalComponent;

    private company: Company;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private currentRoute: string;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public access: any;
    public camp: Camp;
    public displays: any = {
        contents: true,
        mentoring: false,
        stats: false,
        settings: false
    };
    public campName: string;
    public query: string;
    public hasLoadMore: boolean = false;
    public camps$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public campsTemp$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public isSearching: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private companyProvider: CompanyProvider,
        private emitterService: EmitterService,
        private statisticsProvider2: StatisticsProvider2,
    ) { }

    ngOnInit(): void {
        this.company = this.route.parent.snapshot.data.company;
        this.access = this.route.parent.snapshot.data.access;

        if (this.route.snapshot.data.camp) {
            this.camp = this.storageService.set('camp', this.route.snapshot.data.camp);
            this.emitterService.campActive.emit(this.camp);
        } else {
            this.emitterService.campActive.emit(null);
            this.onQueryCampList();
        }

        this.subscriptionCollection.subscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setCurrentRoute();
            }
        });

        this.setCurrentRoute();
    }

    ngAfterViewInit(): void {
        this.emitterService.actionCreateCamp.subscribe(() => {
            this.openModalCreateCamp(this.modalCreateCamp);
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     *
     */
    getParamsOnQueryCampList(pageValue: number) {
        return Object.assign({
            page: pageValue,
        }, this.query ? { q: this.query } : {});
    }

    /**
     *
     */
    onQueryCampList(isAppend: boolean = true) {
        const company = this.company || this.storageService.get('company');

        this.companyProvider
            .getCamps(company.id, this.getParamsOnQueryCampList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryCampList(response.pagination)),
                map((response) => this.onMapOnQueryCampList(response.camps)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.campsTemp$.getValue() : []),
                tap((camps) => { this.campsTemp$.next(camps) }),
                concatMap(() => this.onConcatMapOnQueryCampList()),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryCampList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryCampList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            id: datum.id,
            name: datum.name,
            slug: datum.slug,
        }));
    }

    /**
     *
     */
    onConcatMapOnQueryCampList() {
        const company = this.company || this.storageService.get('company');
        const ids = this.campsTemp$.getValue().map((camp) => camp.id);

        if (!ids.length) {
            return of([]);
        }

        return this.statisticsProvider2
            .getCampList(company.id, { camp_ids: ids })
            .pipe(
                catchError(() => of([])),
                tap((datum) => this.onTapOnConcatMapOnQueryCampList(datum)),
                takeUntil(this.destroy$),
            );
    }

    /**
     *
     */
    onTapOnConcatMapOnQueryCampList(datum: any) {
        const camps = this.campsTemp$.getValue().map((camp) => ({
            ...camp,
            countContent: datum[camp.id] ? datum[camp.id].context.contentsCount : 0,
            countLearners: datum[camp.id] ? datum[camp.id].context.learnersCount : 0,
            countSession: datum[camp.id] ? datum[camp.id].context.sessionsCount : 0,
            duration: datum[camp.id] ? datum[camp.id].context.duration : 0,
        }));
        this.camps$.next(camps);
    }

    /**
     *
     */
    setCurrentRoute() {
        this.currentRoute = this.router.url.split('/')[5];
    }

    /**
     *
     */
    openModalCreateCamp(modal: CommonModalComponent) {
        this.campName = '';
        modal.onOpen();
    }

    /**
     *
     */
    createCamp(): void {
        if (!this.campName) { return; }
        const params = { name: this.campName };
        this.companyProvider
            .createCamp(this.company.id, params)
            .pipe(
                tap(() => { this.page$.next(1); this.onQueryCampList(false); })
            )
            .subscribe(() => {
                this.campName = null;
            });
    }

    /**
     *
     */
    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    /**
     *
     */
    isActivatedRoute(route: string): boolean {
        return this.currentRoute && route.indexOf(this.currentRoute) > -1;
    }

    /**
     *
     */
    search(): void {
        this.page$.next(1);
        this.onQueryCampList(false);
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.isSearching = true;
        this.query = value || null;
        this.page$.next(1);
        this.onQueryCampList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryCampList();
    }
}
