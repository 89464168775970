<div class="showroom-container">
    <div class="demo">
        <div class="input-type">
            <div class="type-name">
                <h1>Flavours</h1>
            </div>
            <div class="type-content">
                <form class="flex mb-2">
                    <msc-textarea class="w-52" type="text" label="Basic" placeholder="Placeholder" ></msc-textarea>
                    <msc-textarea class="w-52 msc-textarea--outline ml-3" type="text" label="Outline" placeholder="Placeholder"></msc-textarea>
                    <msc-textarea class="w-52 msc-textarea--no-border ml-3" type="text" label="Flat" placeholder="Placeholder"></msc-textarea>
                </form>
                <form class="flex">
                    <msc-textarea class="w-52" palette="primary" type="text" label="Primary" placeholder="Focus to see color"></msc-textarea>
                    <msc-textarea class="w-52 ml-3" type="text" label="Secondary" placeholder="Focus to see color"></msc-textarea>
                </form>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                <h1>States</h1>
            </div>
            <div class="type-content">
                <form class="flex" *ngIf="testForm" [formGroup]="testForm">
                    <msc-textarea class="w-52" type="text" label="Hints" placeholder="Placeholder" [hints]="hints"></msc-textarea>
                    <msc-textarea name="errorinput" class="w-52 ml-3" type="text" label="Error" placeholder="Placeholder" formControlName="errorinput"></msc-textarea>
                    <msc-textarea class="w-52 ml-3" type="text" label="Success" placeholder="Placeholder" [hasSuccess]="true"></msc-textarea>
                    <msc-textarea class="w-52 ml-3" type="text" label="Disabled" placeholder="Placeholder" [disabled]="true"></msc-textarea>
                </form>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                Textarea with icon
            </div>
            <div class="type-content">
                <form class="flex flex-col">
                    <msc-input class="w-52" type="text" label="Label" icon="icon-question-mark-circle-1" placeholder="Placeholder"></msc-input>
                </form>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                Textarea with autosize
            </div>
            <div class="type-content">
                <form class="example-form">
                    <form class="flex">
                        <msc-textarea class="w-52" label="Autoresize" placeholder="Type a looong text to increase height" [autosize]="true"></msc-textarea>
                    </form>
                </form>
            </div>
        </div>
    </div>
</div>
