import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class OverviewProvider {

    constructor(private http: HttpClient) {}

    getAnnounce(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/announce`, {params});
    }

    getNextEvent(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/closest-courses`, {params});
    }

    getResources(id: number, experienceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/experience/${experienceId}/resources`, {params});
    }
}
