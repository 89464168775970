import { Injectable } from '@angular/core';
//
import { Subject } from 'rxjs';
//
import { CommonTableColumn } from '@common2/components/table/table.component';

@Injectable()
export abstract class ComponentTableModel<T> {
    public columns: Array<CommonTableColumn>;
    public rows: Array<T>; // input data
    protected destroy$: Subject<void> = new Subject<void>();
    protected repeat$: Subject<void> = new Subject<void>();
    //
    protected abstract getColumns(): Array<CommonTableColumn>;

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }
}
