<ng-container *ngIf="formField?.dirty">
    <p class="text-error-500 text-xs leading-4" *ngIf="formField?.hasError( 'required' )">
        This is a required field.
    </p>

    <p class="text-error-500 text-xs leading-4" *ngIf="formField?.hasError( 'noEmptyString' )">
        Please enter a valid name.
    </p>

    <p class="text-error-500 text-xs leading-4" *ngIf="formField?.hasError( 'normalEmailRule' )">
        Email format is invalid.
    </p>

    <p class="text-error-500 text-xs leading-4" *ngIf="formField?.hasError( 'pattern' )">
        Invalid pattern.
    </p>

    <p class="text-error-500 text-xs leading-4" *ngIf="formField?.hasError( 'noWhitespaceRequired' )">
        No spaces allowed.
    </p>
</ng-container>