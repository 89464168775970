import { Component, EventEmitter, OnInit } from '@angular/core';
import { DataHelper } from '@helpers';
import { Integration } from '@lighty';

@Component({
    selector: 'msc-player-light-modal-embed',
    templateUrl: './modal-embed.component.html'
})
export class PlayerLightModalEmbedComponent implements OnInit {
    public onClose: EventEmitter<boolean> = new EventEmitter();
    public onSave: EventEmitter<any> = new EventEmitter();
    public active: boolean = false;
    public content: any;
    public type: any;

    constructor() {}

    ngOnInit(): void {
        this.active = true;

        const timeOut = setTimeout(() => {
            this.buildLTIForm(this.content);
            clearTimeout(timeOut);
        }, 500);
    }

    close(): void {
        this.active = false;
        this.onClose.emit(true);
    }

    private buildLTIForm(integration: Integration): void {
        const form = document.createElement('form');
        form.target = 'embed-iframe';
        form.method = 'POST';
        form.action = integration.url;
        for (const key in integration.lti.fields) {
            if (integration.lti.fields.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = DataHelper.changeCase(key);
                input.setAttribute('value', integration.lti.fields[key]);
                form.appendChild(input);
            }
        }

        document.querySelector('#embed-code').appendChild(form);
        form.submit();
    }
}
