<div class="head">
    <div class="breadcrumb-v2">
        <h3 class="breadcrumb-v2--back" [routerLink]="['../../knowledge']">{{ 'words.skills' | translate }}</h3>
        <i class="icon icon-arrow-ios-right"></i>
        <h3>{{ skill.name }}</h3>
    </div>
</div>

<div class="body">
    <msc-common-nav [data]="navData"></msc-common-nav>
    <section>
        <router-outlet></router-outlet>
    </section>
</div>