import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { StatisticsProvider2 } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { Subject, BehaviorSubject, tap, map, mergeScan, of, concatMap, repeat, takeUntil, catchError, finalize } from 'rxjs';
import { UserContentProvider } from 'src/app/lighty/models/user-content';
import * as _ from 'lodash';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'msc-manage-instance-learner-stats-entity',
    templateUrl: './stats-entity.component.html',
    styleUrls: ['./stats-entity.component.scss']
})
export class ManageInstanceLearnerStatsEntityComponent {
    private company: any;
    private me: any;
    private query: string;
    private loading = false;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public cards: Array<any>;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public hasLoadMore = false;
    public debounce = false;
    public content: any;
    public learnerId: any;
    public contextType: any;
    public contentId: any;
    public templateDetails: any;

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly statisticsProvider2: StatisticsProvider2,
        private readonly userContentProvider: UserContentProvider,
        private readonly breadcrumbService: BreadcrumbService,
    ) {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.columns = this.getColumns();

        this.activatedRoute.parent.params.subscribe(params => {
            this.learnerId = +params['learnerId'];
            this.contextType = params['entity'];
            this.contentId = params['entityId'];

            const lastCourseIndex = this.activatedRoute.snapshot.url.map(urlSegment => urlSegment.path).lastIndexOf('course');
            if (lastCourseIndex !== -1) {
                this.contextType = 'course';
                this.contentId = +this.activatedRoute.snapshot.url[lastCourseIndex + 1].path;
                this.breadcrumbService.set('@EntityDisplay', this.translateService.instant(`filter.content-type.${this.contextType}`));
            }

            this.onQueryContentUserStatList();
        });
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '30%',
            },
            {
                key: 'time',
                label: this.translateService.instant('stats.hour'),
                width: '20%',
            },
            {
                key: 'progress',
                label: this.translateService.instant('words.progress'),
                width: '25%',
            },
            {
                key: 'result',
                label: this.translateService.instant('words.score'),
                width: '25%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryContentUserStatList(pageValue: number) {
        return Object.assign({
            page: pageValue,
        }, this.query ? { q: this.query } : {});
    }

    /**
 *
 */
    getContentApiCall(entityId, userId, params) {
        if (this.contextType === 'course') {
            return this.userContentProvider.getUserCourse(entityId, userId, params);
        } else if (this.contextType === 'traject') {
            return this.userContentProvider.getUserTraject(entityId, userId, params);
        }
    }

    /**
     *
     */
    onQueryContentUserStatList(isAppend = true) {
        this.loading = true;
        this.getContentApiCall(this.contentId, this.learnerId, this.getParamsOnQueryContentUserStatList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryContentUserStatList(response.pagination)),
                map((response) => this.onMapOnQueryContentUserStatList(response.contents || response.activities)),
                concatMap((items) => this.onConcatMapOnQueryContentUserStatList(items)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => {
                    this.debounce = false;
                    this.loading = false;

                    return this.rows$.next(rows)
                }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentUserStatList(pagination: any) {
        if (!pagination) { return; }
        this.hasLoadMore = pagination.currentPage < pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryContentUserStatList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            id: datum.id,
            subscriptionCourseId: datum.subscriptionCourseId,
            label: this.translateService.instant(`stats.content.type-${datum.context}`),
            context: datum.context,
            title: datum.title,
            questions: datum.questions
        }));
    }

    /**
     *
     */
    onConcatMapOnQueryContentUserStatList(items) {
        if (this.contextType === 'course') {
            const ids = items.map((row) => row.id);
            return this.statisticsProvider2
                .getContentSubscriptionActivityList(this.company.id, this.contentId, this.learnerId, { context: this.contextType, content_ids: ids })
                .pipe(
                    catchError(() => of([])),
                    map((datum) => this.onMapOnConcatMapOnQueryContentUserStatList(datum, items)),
                    finalize(() => this.status$.next(CommonSubjectStatus.SUCCESS)),
                    takeUntil(this.destroy$),
                );
        }

        const contents = items.map((row) => ({ context_id: row.context === 'course' ? row.subscriptionCourseId : row.id, context: row.context === 'course' ? 'course' : 'content' }));
        return this.statisticsProvider2
            .getContentUsersSubscriptionList(this.company.id, this.learnerId, { contents })
            .pipe(
                catchError(() => of([])),
                map((datum) => this.onMapOnConcatMapOnQueryContentUserStatList(datum, items)),
                finalize(() => this.status$.next(CommonSubjectStatus.SUCCESS)),
                takeUntil(this.destroy$),
            );
    }

    /**
     *
     */
    onMapOnConcatMapOnQueryContentUserStatList(datum: any, rows) {
        const rowsWithStats = rows.map((row) => {
            const subscriptionData = this.contextType === 'course' ? datum[row.id] : _.find(datum, function(datum) {
                if(datum.type === 'section_content_session_updated') {
                    return datum.context.courseId === row.subscriptionCourseId;
                }
                return datum.context.contextId  === row.subscriptionCourseId || datum.context.contextId === row.id
            });

            return {
                ...row,
                isChartProgressVisible: typeof subscriptionData?.context?.progress === 'number',
                isChartScoreVisible: typeof subscriptionData?.context?.score === 'number',
                chartProgress: subscriptionData?.context?.progress ?? 0,
                chartScore: subscriptionData?.context?.score ?? 0,
                duration: subscriptionData ? subscriptionData?.context?.duration ?? 0 : 0,
            };
        });

        return rowsWithStats;
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.query = value || null;
        this.page$.next(1);
        this.onQueryContentUserStatList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.debounce = true;
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryContentUserStatList();
    }

    /**
    *
    */
    goToTemplateDetails(datum: any) {
        if (datum.questions) {
            this.templateDetails = {
                questions: datum.questions,
                userId: this.learnerId,
                contentId: datum.id
            }
        } else {
            this.router.navigate(['course', datum.subscriptionCourseId], { relativeTo: this.activatedRoute });
        }
    }

    back() {
        this.templateDetails = null;
    }
}
