<!-- <section class="head">

</section> -->

<section class="body">
    <div class="container-applications__section row col-xxs-12">
        <h2 class="container-applications__title">
            {{ 'integrations.company-links' | translate }}
        </h2>

        <!--vinculos a empresas-->
        <div class="container-applications__list">
            <div class="container-applications__item col-4 col-md-4 col-xxs-12" *ngFor="let link of externalLinks; let index=index">
                <ul class="block-provider block-provider--link">
                    <li class="block-provider__card">
                        <div class="block-provider__card__icon relative top-0 left-0 ">
                            <i class="icon icon-globe text-secondary-500" *ngIf="link.type === 'lti'"></i>

                            <i class="icon icon-link-2 text-secondary-500" *ngIf="link.type === 'url'"></i>
                        </div>

                        <div class="block-provider__card__actions">
                            <div class="dropdown-actions" [ngClass]="{'active': displayDropdowns.link[index]}" [(autoClose)]="displayDropdowns.link[index]">
                                <div class="dropdown-actions__selected" (click)="displayed('link', index)">
                                    <i class="icon icon-more-horizontal"></i>
                                </div>

                                <div class="dropdown-actions__content">
                                    <ul class="dropdown-actions__list">
                                        <li class="dropdown-actions__item" (click)="integrationLinkAside.onOpen(link.type, link)">
                                            <a>
                                                <i class="icon icon-edit"></i>
                                                {{ 'words.edit' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item dropdown-actions__item--trash" (click)="manageDeleteLink(link, index)">
                                            <a>
                                                <i class="icon icon-trash"></i>
                                                {{ 'words.delete' | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="block-provider__card__picture" [style.backgroundImage]="'url(' + (link.picture ? (link.picture + '?size=256') : (decrypt + link.url)) + ')'"></div>

                        <div class="block-provider__card__name">
                            {{ link.name | truncate: 30 }}
                        </div>
                    </li>
                </ul>
            </div>

            <div class="container-applications__item col-4 col-md-4 col-xxs-12">
                <ul class="block-provider block-provider--selectable block-provider--link" (click)="integrationLinkAside.onOpen('url')">
                    <li class="block-provider__card">
                        <div class="block-provider__card__logo">
                            <i class="icon icon-link-2 text-secondary-500"></i>
                        </div>

                        <div class="block-provider__card__name">
                            {{ 'integrations.add-link' | translate }}
                        </div>
                    </li>
                </ul>
            </div>

            <div class="container-applications__item col-4 col-md-4 col-xxs-12">
                <ul class="block-provider block-provider--selectable block-provider--link" (click)="integrationLinkAside.onOpen('lti')">
                    <li class="block-provider__card">
                        <div class="block-provider__card__logo">
                            <i class="icon icon-globe text-secondary-500"></i>
                        </div>

                        <div class="block-provider__card__name">
                            {{ 'integrations.lti-tools' | translate }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container-applications__section row col-xxs-12" *ngIf="isPlaylistEnabled()">
        <h2 class="container-applications__title">
            {{ 'integrations.playlist' | translate }}
        </h2>

        <div class="container-applications__list">
            <div class="container-applications__item col-4 col-md-4 col-xxs-12" *ngFor="let playlist of playlists; let index=index">
                <ul class="block-provider block-provider--playlist">
                    <li class="block-provider__card">
                        <div class="block-provider__card__icon" *ngIf="playlist.lastSync">
                            <i class="icon icon-refresh text-secondary-500"></i>

                            <span>{{ playlist.lastSync | date : 'dd/MM/YYYY' }}</span>
                        </div>

                        <div class="block-provider__card__actions">
                            <div class="dropdown-actions" [ngClass]="{'active': displayDropdowns.playlist[index]}" [(autoClose)]="displayDropdowns.playlist[index]">
                                <div class="dropdown-actions__selected" (click)="displayed('playlist', index)">
                                    <i class="icon icon-more-horizontal"></i>
                                </div>

                                <div class="dropdown-actions__content">
                                    <ul class="dropdown-actions__list">
                                        <li class="dropdown-actions__item" (click)="playlistAside.onOpen(playlist)">
                                            <a>
                                                <i class="icon icon-edit"></i>
                                                {{ 'words.edit' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="refreshPlaylist(playlist, index)">
                                            <a>
                                                <i class="icon icon-refresh"></i>
                                                {{ 'words.refresh' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item dropdown-actions__item--trash" (click)="deletePlaylist(playlist, index)">
                                            <a>
                                                <i class="icon icon-trash"></i>
                                                {{ 'words.delete' | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!playlist.status" class="block-provider__card__logo">
                            <i class="icon icon-clock text-secondary-500"></i>
                        </div>

                        <div *ngIf="playlist.mediaUrl && playlist.status" class="block-provider__card__picture" [style.backgroundImage]="'url(' + playlist.mediaUrl + ')'"></div>

                        <div class="block-provider__card__name">
                            {{ playlist.name | truncate: 30 }}
                        </div>
                    </li>
                </ul>
            </div>

            <div class="container-applications__item col-4 col-md-4 col-xxs-12">
                <ul class="block-provider block-provider--selectable block-provider--link" (click)="playlistAside.onOpen()">
                    <li class="block-provider__card">
                        <div class="block-provider__card__logo">
                            <i class="icon icon-list text-secondary-500"></i>
                        </div>

                        <div class="block-provider__card__name">
                            {{ 'integrations.add-playlist' | translate }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>


    <!--aplicaciones-->
    <div class="container-applications__section row col-xxs-12" *ngIf="connectedApplications.length > 0">
        <h2 class="container-applications__title">
            {{ 'integrations.app-connected' | translate }}
        </h2>

        <div class="container-applications__list">
            <div class="container-applications__item col-4 col-md-4 col-xxs-12" *ngFor="let application of connectedApplications">
                <ul class="block-provider" *ngFor="let feature of application.features" (click)="editFeature(application, feature); freshChatAside.onOpen()" [ngClass]="{'block-provider--editable': application.name === 'freshchat'}">
                    <li class="block-provider__card card-app-list justify-around">
                        <div class="block-provider__card__picture" [style.backgroundImage]="'url(https://static.myskillcamp.com/images/integrations/' + application.name + '.svg)'"></div>

                        <div class="block-provider__incoming" *ngIf="application.isConnectable">
                            <msc-button class="msc-button--primary" palette="secondary" (click)="unlinkApplication(application); stopPropagation($event)">
                                {{ 'integrations.unlink' | translate }}
                            </msc-button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container-applications__section row col-xxs-12" *ngIf="displayedAvailableApplications.length > 0">
        <h2 class="container-applications__title">
            {{ 'integrations.content-providers' | translate }}
        </h2>

        <div class="container-applications__list">
            <div class="container-applications__item col-4 col-md-4 col-xxs-12" *ngFor="let application of displayedAvailableApplications">
                <ul class="block-provider">
                    <li class="block-provider__card card-app-list justify-around">
                        <div class="block-provider__card__picture" [style.backgroundImage]="'url(https://static.myskillcamp.com/images/integrations/' + application.name + '.svg)'"></div>

                        <div class="block-provider__incoming">
                            <msc-button class="msc-button--primary" palette="primary" (click)="linkApplication(application)" *ngIf="application.isConnectable">
                                {{ 'integrations.link' | translate }}
                            </msc-button>

                            <msc-button class="msc-button--primary" palette="secondary" (click)="contact()" *ngIf="!application.isConnectable">
                                {{ 'words.contact-us' | translate }}
                            </msc-button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>


<msc-shared-aside-integration-link #integrationLinkAside
                                   (onSave)="manageLink($event)">
</msc-shared-aside-integration-link>

<msc-shared-aside-integration-playlist #playlistAside
                                       [manage]="true"
                                       [companyId]="company.id"
                                       (onSave)="managePlaylist($event)">
</msc-shared-aside-integration-playlist>

<msc-organize-integration-aside-freshchat #freshChatAside
                                          [freshchat]="selected.application"
                                          [companyId]="company.id"
                                          (onSave)="manageApplication($event)">
</msc-organize-integration-aside-freshchat>

<msc-common-modal size="30vw" #modalDeleteLink [title]="'words.confirm-action' | translate">
    <ng-template>
        <p *ngIf="selected?.link?.name">{{ 'lti.modal.delete' | translate: {name: selected?.link?.name} }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDeleteLink.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteLink(selected.link); modalDeleteLink.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
