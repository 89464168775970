import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'msc-organize-integration-watch-rules-recap',
    templateUrl: './recap.component.html'
})
export class OrganizeIntegrationWatchRulesRecapComponent implements OnInit {
    @Input() currentRule: any;
    @Input() navigations: any;
    @Input() navigationIndex: number;
    @Input() providerName: string;
    @Input() large: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
