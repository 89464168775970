import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, IntegrationProvider } from '@lighty';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@services';
import { HttpHelper } from '@helpers';
import * as _ from 'lodash';

@Component({
    selector: 'msc-organize-integration-watch-rules-edit-selection-panel-filtered',
    templateUrl: './selection-panel-filtered.component.html'
})
export class OrganizeIntegrationWatchRulesEditSelectionPanelFilteredComponent implements OnInit {
    @Output() allowNavigation: EventEmitter<any> = new EventEmitter<any>();
    @Input() rule: any;
    private company: Company;
    private provider: any;
    public collection: any;
    public displayAside: boolean = false;
    public loaders = {
        lang: true,
        categories: true
    };
    public categories: any[];
    public languages: any[];
    public displays: boolean[] = [];
    public selectedCategory: any;
    public emptyCategories: any[] = [];

    constructor(private integrationProvider: IntegrationProvider, private route: ActivatedRoute, private storageService: StorageService) { }

    ngOnInit(): void {
        // add potential missing properties
        if (!this.rule.selectedLanguages) {
            this.rule.selectedLanguages = [];
        }
        if (!this.rule.selectedCategories) {
            this.rule.selectedCategories = [];
        }
        if (!this.rule.selectedSubcategories) {
            this.rule.selectedSubcategories = [];
        }

        this.company = this.storageService.get('company');
        this.provider = this.storageService.get('currentRuleProvider');
        this.collection = this.route.snapshot.data.collection;

        this.integrationProvider.getCollectionCategories(this.company.id, this.provider.id, this.collection.collectionId).subscribe(data => {
            this.categories = data.categories;
            for (const category of this.categories) {
                if (this.rule.categories) {
                    const index = this.rule.categories.indexOf(category.id);
                    if (index !== -1) {
                        this.rule.selectedCategories.push(category);
                    }
                }

                for (const subcategory of category.subcategories) {
                    if (this.rule.selectedSubcategories) {
                        const index = this.rule.selectedSubcategories.findIndex(subcat => subcat.id === subcategory.id);
                        if (index > -1) {
                            subcategory.selected = true;
                        }
                    }
                }
            }
            this.loaders.categories = false;
            this.checkNavigation();
        });
        this.integrationProvider.getCollectionLanguages(this.company.id, this.provider.id, this.collection.collectionId).subscribe(data => {
            this.languages = data.languages;
            this.rule.selectedLanguages = _.cloneDeep(this.languages);
            this.loaders.lang = false;
            this.checkNavigation();
        });

        this.checkNavigation();
    }

    display(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    onCategoryChange(event: any, category: any): void {
        if (event.target.checked) {
            this.rule.selectedCategories.push(category);
            // will check if category is empty
            const params = {
                languageIds: this.rule.selectedLanguages.map(lang => lang.id),
                categoryIds: [category.id],
            }
            category.subcategories.map(subcategory => {
                const subcategoryIndex = this.rule.selectedSubcategories.map(sc => sc.id).indexOf(subcategory.id);
                if (subcategoryIndex === -1) {
                    this.rule.selectedSubcategories.push(subcategory);
                }
                subcategory.selected = true;
            });
        } else {
            const index = this.rule.selectedCategories.map(c => c.id).indexOf(category.id);
            this.rule.selectedCategories.splice(index, 1);
            category.subcategories.map(subcategory => {
                const subcategoryIndex = this.rule.selectedSubcategories.map(sc => sc.id).indexOf(subcategory.id);
                this.rule.selectedSubcategories.splice(subcategoryIndex, 1);
                subcategory.selected = false;
            });
        }
        this.checkNavigation();
    }

    onSubCategoryChange(event: any, category: any, subcategory: any): void {
        const isCategoryChecked = (this.rule.selectedCategories.map(cat => cat.id).indexOf(category.id) !== -1);
        if (event.target.checked) {
            subcategory.selected = true;
            this.rule.selectedSubcategories.push(subcategory);
            if (!isCategoryChecked) {
                this.rule.selectedCategories.push(category);
            }
        } else {
            subcategory.selected = false;
            const index = this.rule.selectedSubcategories.map(sc => sc.id).indexOf(subcategory.id);
            this.rule.selectedSubcategories.splice(index, 1);

            const categoryIndex = this.rule.selectedCategories.findIndex(cat => cat.id === category.id);
            const subcatIndex = this.rule.selectedCategories[categoryIndex].subcategories.findIndex(subcat => subcat.id === subcategory.id);
            this.rule.selectedCategories[categoryIndex].subcategories[subcatIndex].selected = false;

            if (isCategoryChecked) {
                let subcategoryEmpty = true;
                category.subcategories.map(sc => {
                    if (sc.selected) {
                        subcategoryEmpty = false;
                    }
                });
                if (subcategoryEmpty) {
                    const categoryIndex = this.rule.selectedCategories.map(c => c.id).indexOf(category.id);
                    this.rule.selectedCategories.splice(categoryIndex, 1);
                }
            }
        }
        this.checkNavigation();
    }

    onLanguageChange(value: any, lang?: any): void {
        if (!lang) {
            if (value) {
                this.rule.selectedLanguages = _.cloneDeep(this.languages);
            } else {
                this.rule.selectedLanguages = [];
            }
        } else {
            if (value) {
                this.rule.selectedLanguages.push(lang);
            } else {
                const index = this.rule.selectedLanguages.findIndex(selectedLang => selectedLang.id === lang.id);
                this.rule.selectedLanguages.splice(index, 1);
            }
        }

        const ids = this.rule.selectedLanguages.map(language => language.id);
        const params = HttpHelper.cleanParams({
            languageIds: ids.toString()
        });

        this.integrationProvider.getCollectionCategories(this.company.id, this.provider.id, this.collection.collectionId, params).subscribe(data => {
            const tempCategories = [];
            const tempSubcategories = [];
            for (const category of this.rule.selectedCategories) {
                const index = data.categories.findIndex(dataCategory => dataCategory.id === category.id);
                if (index !== -1) {
                    tempCategories.push(category);
                }
            }

            for (const subcategory of this.rule.selectedSubcategories) {
                for (const category of data.categories) {
                    const subcatIndex = category.subcategories.findIndex(subcat => subcat.id === subcategory.id);
                    if (subcatIndex !== -1) {
                        tempSubcategories.push(subcategory);
                    }
                }
            }

            this.rule.selectedCategories = tempCategories;
            this.rule.selectedSubcategories = tempSubcategories;
            this.categories = data.categories;
        });
        this.checkNavigation();
    }

    checkCategoryState(category: any): boolean {
        return this.rule.selectedCategories.map(c => c.id).indexOf(category.id) > -1;
    }

    checkCategoryIntermediate(category: any): boolean {
        for (const subcategory of category.subcategories) {
            if (!subcategory.selected) {
                return true;
            }
        }
        return false;
    }

    checkSubcategoryState(subcategory: any): boolean {
        return this.rule.selectedSubcategories.map(sc => sc.id).indexOf(subcategory.id) > -1;
    }

    checkLanguages(lang?: any): boolean {
        if (!lang) {
            return this.rule.selectedLanguages?.length === this.languages?.length;
        }
        return this.rule.selectedLanguages.map(l => l.id).indexOf(lang.id) > -1;
    }

    checkNavigation(): void {
        if ((this.rule.selectedCategories.length > 0 || this.rule.selectedLanguages.length > 0) && this.emptyCategories.length === 0) {
            this.allowNavigation.emit(true);
        } else {
            this.allowNavigation.emit(false);
        }
    }

    setSelectedCategory(category: any, subcategory: boolean = false) {
        this.selectedCategory = category;
        this.selectedCategory.isSub = subcategory;
    }
}
