<div class="content-corrections content-corrections-list content-corrections-session">
    <div class="content-corrections__filter">
        <div class="flex space-x-2">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                {{ 'words.filters' | translate }}
            </msc-button>
        
            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>
        </div>
    </div>

    <div class="dynamic-table box-session-correction-details" *ngFor="let section of sections; let index=index" [ngClass]="{ 'active': displays.sections[index]}" (click)="displayed('sections', index);">
        <div class="dynamic-table__head box-session-correction-details__header">
            <h3 class="box-session-correction-details__title dynamic-table__cell cell-title cell-title--large">
                <span class="box-session-correction-details__notification" *ngIf="section.notCorrectedAnswers.length > 0"></span>
                {{ (section.sectionName | truncate: 30) || ('words.untitled' | translate) }}
            </h3>

            <div class="box-session-correction-details__desc dynamic-table__cell cell-title cell-title--large">
                <div class="box-session-correction-details__infos">
                    <ul>
                        <li><i class="icon icon-clipboard"></i>{{ section.resume.project || '0' }}</li>
                        <li><i class="icon icon-checkmark-square"></i>{{ section.resume.exercise || '0' }}</li>
                        <li><i class="icon icon-content"></i>{{ section.resume.selfassessment || '0' }}</li>
                    </ul>
                </div>

                <div class="box-session-correction-details__progress">
                    <div class="progress-stats">
                        <span *ngIf="countQuestion(section)" class="progress-stats__bar progress-stats__bar--green tooltip tooltip--top" [ngStyle]="{ 'width': progressBarWidth(countQuestion(section), section.correctedAnswers) }"></span>
                        <span *ngIf="countQuestion(section)" class="progress-stats__bar progress-stats__bar--blue tooltip tooltip--top" [ngStyle]="{ 'width': progressBarWidth(countQuestion(section), section.correctedAnswers + section.notCorrectedAnswers) }"></span>
                    </div>
                </div>
            </div>

            <div class="box-session-correction-details__arrow dynamic-table__cell cell-arrow">
                <a><i class="icon icon-arrow-ios-down"></i></a>
            </div>
        </div>

        <div class="box-session-correction-details__body dynamic-table__body">
            <div class="box-session-correction-details__list dynamic-table__row row-hover" *ngFor="let activity of section.contents" (click)="saveSection(section); $event.stopPropagation()" [routerLink]="openActivityDetails(activity.sectionContentId)">
                <div class="box-session-correction-details__item box-session-correction-details__item dynamic-table__content" [ngClass]="activityProgressState(activity)">
                    <div class="box-session-correction-details__content">
                        <div class="box-session-correction-details__title dynamic-table__cell cell-large">
                            <i class="icon" [ngClass]="{ 'icon-checkmark-square': activity.sectionContentContext==='exercise', 'icon-clipboard': activity.sectionContentContext==='project', 'icon-content': activity.sectionContentContext==='selfassessment' }"></i>
                            <h3 class="box-session-correction__title">
                                <span class="box-session-correction__notification" *ngIf="activity.notification"></span>
                                {{ activity.sectionContentName || "words.untitled-activity" | translate }}
                            </h3>
                        </div>

                        <div class="box-session-correction-details__infos dynamic-table__cell">
                            <ul>
                                <li><i class="icon icon-checkmark-circle-2"></i>{{ activity.correctedAnswers }}</li>
                                <li><i class="icon icon-clock text-secondary-500"></i>{{ activity.notCorrectedAnswers }}</li>
                                <li><i class="icon icon-close"></i>{{ activity.notAnswered }}</li>
                            </ul>
                        </div>

                        <div class="box-session-correction-details__progress dynamic-table__cell">
                            <p>{{ activity.lastAnswerDate | timeAgo | lowercase }}</p>
                        </div>

                        <div class="box-session-correction-details__arrow dynamic-table__cell cell-actions">
                            <a><i class="icon icon-arrow-ios-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <msc-common-spinner *ngIf="loading"></msc-common-spinner>

    <msc-empty-state *ngIf="!loading && sections.length === 0"
                     [title]="'words.corrections'"
                     [description]="'empty.correction'">
    </msc-empty-state>
</div>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>
