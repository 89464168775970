import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { KnowledgeProvider, KnowledgeSkill, Company } from '@lighty';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-shared-aside-forbid-skill',
    templateUrl: './forbid-skill.component.html'
})
export class SharedForbidSkillComponent {
    @ViewChild('aside') aside;
    @Input() company: Company;
    @Input() selectedSkill: KnowledgeSkill;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public skills: KnowledgeSkill[];
    public query: string;
    public loading: boolean = false;
    public activeSkill: KnowledgeSkill;
    public suggest: boolean = false;
    public translations: any;

    constructor(
        private knowledgeProvider: KnowledgeProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    public onOpen() {
        this.aside.onOpen();
        if (this.selectedSkill.suggestion) {
            this.suggest = true;
            this.activeSkill = this.selectedSkill.suggestion;
        }
        this.translations = {
            fr: this.selectedSkill.translations.fr,
            en: this.selectedSkill.translations.en,
            nl: this.selectedSkill.translations.nl,
            de: this.selectedSkill.translations.de,
            it: this.selectedSkill.translations.it
        };
        this.search();
    }

    search(query?): void {
        this.query = query;
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            order_by: 'total_usage',
            order_way: 'desc'
        });

        this.knowledgeProvider.get(this.company.slug, params).subscribe((data) => {
            this.skills = this.filtering(data.skills);
            this.loading = false;
        });
    }

    private filtering(skills: KnowledgeSkill[]): KnowledgeSkill[] {
        return skills.filter((skill) => {
            return skill.visible && this.selectedSkill.id !== skill.id;
        });
    }

    selectSkill(skill: KnowledgeSkill): void {
        this.activeSkill = this.isActive(skill) ? null : skill;
    }

    isActive(skill: KnowledgeSkill): boolean {
        return this.activeSkill && this.activeSkill.id === skill.id;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        const params = {
            visible: false,
            company_id: this.company.id,
            suggestion_id: this.suggest && this.activeSkill ? this.activeSkill.id : null,
            translations: this.translations
        };

        this.knowledgeProvider.update(this.selectedSkill.id, params).subscribe((data) => {
            this.onSave.emit(data);
            this.closeByUser.emit();
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }
}
