<div class="flex">
    <msc-common-search *ngIf="!templateDetails" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)">
    </msc-common-search>
    <msc-button *ngIf="templateDetails" (click)="back()">{{'words.back' | translate}}</msc-button>
</div>

<div class="content grow" id="stats-entity">
    <ng-container [ngSwitch]="status$ | async" *ngIf="!templateDetails">
        <ng-container *ngSwitchCase="'success'">
            <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                <msc-common-table [columns]="columns" [rows]="rows$ | async" (onScrollEnd)="(hasLoadMore && !debounce) ? onLoad() : null">
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>


                    <ng-template #tdTemplate let-datum="datum">
                        <div class="flex flex-col cursor-pointer" [ngClass]="{'cursor-pointer': datum.questions?.length || datum.context === 'course'}" (click)="(datum.questions?.length || datum.context === 'course') && goToTemplateDetails(datum)">
                            <span [ngClass]="{'hover:underline': datum.questions?.length || datum.context === 'course'}">{{ datum.title || ('words.untitled' | translate) }}</span>
                            <div class="tag flex space-x-1 items-center w-fit">
                                <i class="icon icon-video text-xs text-white" *ngIf="datum.context === 'media'"></i>
                                <div class="tag__text">{{ datum.label }}</div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.duration | duration }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <ng-container *ngIf="datum.isChartProgressVisible; else emptyChartProgressTemplate">
                            <msc-chart-pie [size]="'small'" [value]="datum.chartProgress">
                            </msc-chart-pie>
                        </ng-container>
                        <ng-template #emptyChartProgressTemplate>
                            <span>-</span>
                        </ng-template>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <ng-container *ngIf="datum.isChartScoreVisible; else emptyChartScoreTemplate">
                            <msc-chart-pie [size]="'small'" [value]="datum.chartScore">
                            </msc-chart-pie>
                        </ng-container>
                        <ng-template #emptyChartScoreTemplate>
                            <span>-</span>
                        </ng-template>
                    </ng-template>
                </msc-common-table>
                <div class="flex ">
                    <msc-common-spinner class="mx-auto" *ngIf="loading"></msc-common-spinner>
                </div>
            </ng-container>

            <ng-template #emptyTemplate>
                <msc-common-empty [message]="'common.empty' | translate">
                    <ng-template></ng-template>
                </msc-common-empty>
            </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'loading'">
            <msc-common-spinner *ngIf="true"></msc-common-spinner>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
            <msc-common-error>
                <ng-template></ng-template>
            </msc-common-error>
        </ng-container>
    </ng-container>
    <msc-manage-instance-learner-stats-exercise *ngIf="templateDetails" [templateDetails]="templateDetails">
    </msc-manage-instance-learner-stats-exercise>
</div>
