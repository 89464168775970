<div class="content-experience content-experience--login">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title">
                <h3>{{ 'experience.login' | translate }}</h3>
            </div>

            <div class="box-experience__content">
                <div class="content__login">
                    <div class="content__login__picture">
                        <div class="content-title">
                            {{ 'words.background-picture' | translate }}
                        </div>

                        <div class="content-logo">
                            <div class="content-logo__container">
                                <msc-media [picture]="experience.loginPicture" [loadEmitter]="loadEmitter" (onUploaded)="updatePicture($event)" (onLoading)="load()"></msc-media>
                            </div>
                        </div>

                        <div class="content-description">
                            <p>{{ 'words.upload-info.256' | translate }}</p>
                        </div>

                        <div class="content-actions">
                            <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" icon="icon-download" (click)="loadPicture()">
                                {{ 'words.upload' | translate }}
                            </msc-button>

                            <msc-button class="msc-button--outline msc-button--tiny" palette="danger" icon="icon-trash-2" (click)="deletePicture()">
                                {{ 'words.delete' | translate }}
                            </msc-button>
                        </div>
                    </div>

                    <div class="content__login__link">
                        <div class="content-title">
                            {{ 'words.url' | translate }}
                        </div>

                        <div class="content-link">
                            <div class="content-url">
                                {{ loginUrl | truncate: 110 }}
                            </div>

                            <msc-button class="msc-button--outline msc-button--tiny" icon="icon-arrow-right-1" (click)="clipboard()">
                                {{ 'words.copy-clipboard' | translate }}
                            </msc-button>
                        </div>
                    </div>

                    <div class="content__login__custom-url">
                        <div class="content-title">
                            {{ 'url.applied.title' | translate }}
                        </div>

                        <div class="content-custom-item">
                            <ng-container *ngIf="selectData.urls.length">
                                <msc-common-select class="max-w-full" [data]="selectData.urls">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="flex align-center">
                                            <ng-container *ngIf="data?.length; else placeholderTemplate">
                                                <i class="icon icon-home-1" *ngIf="experience.url?.experienceId === experience.id"></i>
                                                <span>{{ data[0].label }}</span>
                                            </ng-container>
                                            <ng-template #placeholderTemplate>
                                                <span>{{'url.empty.url' | translate}}</span>
                                            </ng-template>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="flex py-1 w-full" (click)="onSelectOption(datum, 'urls')">
                                            <span>{{ datum.label }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="box-experience__content">
                    <p>{{ 'experience.custom-message.title' | translate }}</p>
                </div>

                <div class="box-experience__description">
                    <p>{{ 'experience.custom-message.description' | translate }}</p>
                </div>

                <div class="box-experience__language">
                    <div class="relative flex items-center gap-2">
                        <ng-container *ngIf="selectData.languages.length">
                            <msc-common-select class="w-[220px]" [data]="selectData.languages">
                                <ng-template #buttonTemplate let-data="data">
                                    <button class="flex items-center text-sm text-black-400 w-full">
                                        <ng-container *ngIf="data?.length">
                                            <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value}}.svg">
                                            <span>{{ data[0].label }}</span>
                                        </ng-container>
                                    </button>
                                </ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <button class="flex items-center justify-between text-sm py-2 w-full" (click)="onSelectOption(datum, 'languages')">
                                        <div class="flex items-center">
                                            <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value}}.svg">
                                            <span>{{ datum.label }}</span>
                                        </div>
                                        <ng-container *ngIf="!getMessage(datum.value) && hasMessages()">
                                            <i class="icon icon-alert-triangle self-end text-error-500" [tooltip]="'experience.custom-message.tooltip' | translate"></i>
                                        </ng-container>
                                    </button>
                                </ng-template>
                            </msc-common-select>
                        </ng-container>

                        <ng-container *ngIf="hasMessages() && messageMissing()">
                            <span class="message-warning">
                                <i class="icon icon-alert-triangle" [tooltip]="'experience.custom-message.missing.tooltip' | translate"></i>
                            </span>
                        </ng-container>
                    </div>
                </div>

                <div class="box-experience__content">
                    <msc-froala [plugins]="['charCounter', 'colors', 'lists', 'link']" [(content)]="message" (onUpdate)="updateMessage()"></msc-froala>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalCustom [title]="'experience.custom' | translate">
    <ng-template>
        <p>{{ 'experience.custom.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalCustom.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="redirectContact(); modalCustom.onClose()">{{'words.ask' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
