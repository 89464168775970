import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ReactionProvider } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-modal-reaction',
    templateUrl: './modal-reaction.component.html'
})
export class TimelineModalReactionComponent implements OnInit {
    @Input() reactions: any;
    @Input() contextId: number;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();
    public isActive: boolean = false;
    public tab: string = 'all';
    public users: any[] = [];
    public availableReactions: any[] = [];
    public loadUsers: boolean = false;

    constructor(private reactionProvider: ReactionProvider) {}

    ngOnInit(): void {
        this.isActive = true;
        this.loadUsers = true;

        const params = HttpHelper.cleanParams({
            context_id: this.contextId,
            context: 'timeline_item'
        });

        this.reactionsTreatment();

        this.reactionProvider.getReactionFromPost(params).subscribe((data) => {
            this.users = data.data;
            this.loadUsers = false;
        });
    }

    reactionsTreatment(): void {
        this.reactions.forEach((reaction) => {
            if (reaction.count > 0) {
                this.availableReactions.push(reaction);
            }
        });
    }

    changeTabs(reaction?: any): void {
        this.tab = reaction ? reaction.name : 'all';
        this.users = [];
        this.loadUsers = true;

        const params = HttpHelper.cleanParams({
            context_id: this.contextId,
            context: 'timeline_item',
            reaction_id: reaction ? reaction.id : null
        });

        this.reactionProvider.getReactionFromPost(params).subscribe((data) => {
            this.users = data.data;
            this.loadUsers = false;
        });
    }
}
