import { Component, ViewChild } from '@angular/core';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { mergeScan, map, tap, repeat, finalize, takeUntil, concatMap } from 'rxjs/operators';
//
import { Company, AdminGroupProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CampAsideContentComponent } from './aside/content/content.component';
import { CampAsideUsersComponent } from './aside/users/users.component';
import { CampAsideResourcesComponent } from './aside/resources/resources.component';
import { CampAsideSettingsComponent } from './aside/settings/settings.component';

@Component({
    selector: 'msc-camp-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss']
})

export class CampCommunityComponent {
    @ViewChild('modalDelete') modalDelete: CommonModalComponent;
    @ViewChild('campContentAside') campContentAside: CampAsideContentComponent;
    @ViewChild('campUsersAside') campUsersAside: CampAsideUsersComponent;
    @ViewChild('campResourcesAside') campResourcesAside: CampAsideResourcesComponent;
    @ViewChild('campSettingsAside') campSettingsAside: CampAsideSettingsComponent;
    private query: string;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public camp: any;
    public company: Company;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore: boolean = false;
    public data: any = {
        name: '',
        subscriptionType: 'free',
        timelineVisibility: true
    };
    public currentGroup: any;
    public displayDropdowns: any;
    public displayAsides: any;

    constructor(
        private toastService: CommonToastService,
        private storageService: StorageService,
        private groupProvider: AdminGroupProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.setContent();
        this.onQueryGroupList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.camp = this.storageService.get('camp');
        this.company = this.storageService.get('company');
        this.columns = this.getColumns();

        this.displayAsides = {
            contents: false,
            resources: false,
            users: false,
            settings: false
        };

        this.displayDropdowns = {
            visibility: [],
            type: [],
            edit: []
        };
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'title',
                label: this.translateService.instant('group.title'),
                width: '20%',
            },
            {
                key: 'visibility',
                label: this.translateService.instant('words.visibility'),
                width: '15%',
            },
            {
                key: 'type',
                label: this.translateService.instant('words.type'),
                width: '15%',
            },
            {
                key: 'users-number',
                label: this.translateService.instant('words.users-number'),
                width: '20%',
            },
            {
                key: 'contents-number',
                label: this.translateService.instant('group.contents-number'),
                width: '20%',
            },
            {
                key: '',
                width: '10%',
            },
        ];
    }

    /**
     *
     */
    getColumnVisibilityDropdown(datum: any) {
        return [
            {
                id: Math.random(),
                label: this.translateService.instant('group.visibility-display'),
                value: true,
                isSelected: datum.timelineVisibility === 1,
            },
            {
                id: Math.random(),
                label: this.translateService.instant('group.visibility-hidden'),
                value: false,
                isSelected: datum.timelineVisibility === 0,
            },
        ];
    }

    /**
     *
     */
    getColumnTypeDropdown(datum: any) {
        return [
            {
                id: Math.random(),
                label: this.translateService.instant('group.free'),
                value: 'free',
                isSelected: datum.subscriptionType === 'free',
            },
            {
                id: Math.random(),
                label: this.translateService.instant('group.admin'),
                value: 'admin',
                isSelected: datum.subscriptionType === 'admin',
            },
        ];
    }

    /**
     *
     */
    getActionsDropdownData(datum: any, index: number) {
        return [
            {
                id: Math.random(),
                label: this.translateService.instant('words.users'),
                value: {
                    icon: 'person',
                    action: () => this.campUsersAside.onOpen(datum),
                },
                isSelected: false,
            },
            {
                id: Math.random(),
                label: this.translateService.instant('words.contents'),
                value: {
                    icon: 'book',
                    action: () => this.campContentAside.onOpen(datum),
                },
                isSelected: false,
            },
            {
                id: Math.random(),
                label: this.translateService.instant('words.resources'),
                value: {
                    icon: 'file',
                    action: () => this.campResourcesAside.onOpen(datum),
                },
                isSelected: false,
            },
            {
                id: Math.random(),
                label: this.translateService.instant('words.settings'),
                value: {
                    icon: 'settings-2',
                    action: () => this.campSettingsAside.onOpen(datum),
                },
                isSelected: false,
            },
            {
                id: Math.random(),
                label: this.translateService.instant('words.delete'),
                value: {
                    icon: 'trash-2',
                    action: () => this.openDeleteCommunity(this.modalDelete, datum, index),
                },
                isSelected: false,
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryGroupList(pageValue: number) {
        return Object.assign({
            type: 'community',
            page: pageValue,
            camp_id: this.camp.id
        }, this.query ? { q: this.query } : {});
    }

    /**
     *
     */
    onQueryGroupList(isAppend: boolean = true) {
        this.groupProvider
            .getGroups(this.company.id, this.getParamsOnQueryGroupList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryGroupList(response.pagination)),
                map((response) => this.onMapOnQueryGroupList(response.groups)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryGroupList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryGroupList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum, index: number) => {
            const columnVisibilityDropdownData = this.getColumnVisibilityDropdown(datum);
            const columnTypeDropdownData = this.getColumnTypeDropdown(datum);
            const columnActionsDropdownData = this.getActionsDropdownData(datum, index);
            return {
                ...datum,
                columnVisibilityDropdownData,
                columnTypeDropdownData,
                columnActionsDropdownData,
            }
        });
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.query = value || null;
        this.page$.next(1);
        this.onQueryGroupList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryGroupList();
    }

    /**
     *
     */
    onCreateGroup(): void {
        this.groupProvider
            .createGroup(this.company.id, { ...this.data, type: 'community' })
            .pipe(
                concatMap((group) => this.groupProvider.updateGroupCamps(this.company.id, group.id, { camp_ids: [this.camp.id] }))
            )
            .subscribe({
                next: () => {
                    this.toastService.onSuccess(this.translateService.instant('toast.saved'));
                    this.onSearch({ value: '', data:[] });
                },
                error: () => this.toastService.onError(this.translateService.instant('toast.error-occurred'))
            });
    }

    /**
     *
     */
    onDeleteGroup(): void {
        this.groupProvider
            .deleteGroup(this.company.id, this.currentGroup.id)
            .pipe()
            .subscribe({
                next: () => this.toastService.onSuccess(this.translateService.instant('toast.saved')),
                error: () => this.toastService.onError(this.translateService.instant('toast.error-occurred')),
            });
    }

    /**
     *
     */
    closeAside(key: string, event?: any): void {
        this.displayAsides[key] = false;
    }

    /**
     *
     */
    displayed(key: string, index: number): void {
        this.displayDropdowns = {
            visibility: [],
            type: [],
            edit: []
        };
        this.displayDropdowns[key][index] = !this.displayDropdowns[key][index];
    }

    /**
     *
     */
    onSelectVisibilityOption(datum: any, option: any) {
        datum.columnVisibilityDropdownData = datum.columnVisibilityDropdownData.map((dropdownData: any) => {
            dropdownData.isSelected = dropdownData.id === option.id;
            return dropdownData;
        });
        this.updateVisibility(datum, option.value);
    }

    /**
     *
     */
    onSelectSubscriptionOption(datum: any, option: any) {
        datum.columnTypeDropdownData = datum.columnTypeDropdownData.map((dropdownData: any) => {
            dropdownData.isSelected = dropdownData.id === option.id;
            return dropdownData;
        });
        this.updateType(datum, option.value);
    }

    /**
     *
     */
    updateVisibility(group: any, visible: boolean): void {
        group.timelineVisibility = visible;

        this.groupProvider
            .updateGroup(this.company.id, group.id, this.getUpdateParams(group))
            .pipe()
            .subscribe({
                next: () => this.toastService.onSuccess(this.translateService.instant('toast.saved')),
                error: () => this.toastService.onError(this.translateService.instant('toast.error-occurred')),
            });
    }

    /**
     *
     */
    updateType(group: any, type: any): void {
        group.subscriptionType = type;
        const params = Object.assign({
            include: 'account,media'
        }, this.query ? { q: this.query } : {});

        this.groupProvider
            .updateGroup(this.company.id, group.id, this.getUpdateParams(group), params)
            .pipe()
            .subscribe({
                next: () => this.toastService.onSuccess(this.translateService.instant('toast.saved')),
                error: () => this.toastService.onError(this.translateService.instant('toast.error-occurred')),
            });
    }

    /**
     *
     */
    getUpdateParams(datum: any) {
        return Object.keys(datum).reduce((acc, key) => {
            if (key === 'media') {
                return { ...acc, mediaId: datum[key].id }
            }
            if (key === 'background') {
                return { ...acc, backgroundId: datum[key].id }
            }
            return { ...acc, [key]: datum[key] };
        }, {});
    }

    /**
     *
     */
    manage(type: string, group: any, index: number): void {
        this.currentGroup = group;
        this.displayAsides[type] = true;
        this.displayDropdowns.edit[index] = false;
    }

    /**
     *
     */
    openDeleteCommunity(modal: CommonModalComponent, group: any, index: number): void {
        this.currentGroup = group;
        this.displayDropdowns.edit[index] = false;
        modal.onOpen();
    }
}
