import { Account } from '../account';

export class Group {
    id: number;
    camp: string;
    backgroundPicture?: string;
    background: any;
    coursesCount: number;
    createdAt: number;
    type: string;
    info?: string;
    isAdmin: boolean;
    isSubscribed: boolean;
    membersCount: number;
    mediaId: number;
    resourcesCount: number;
    title?: string;
    name: string;
    updatedAt: number;
    usersLimit: number;
    usersPreview?: Account[];
    visibility: boolean;
    picture?: string;
    media: any;

    constructor(group: object) {
        this.assign(group);
    }

    assign(group: object): void {
        Object.assign(this, group);
        if (this.usersPreview && this.usersPreview.length > 0) {
            this.usersPreview = this.usersPreview.map((account) => new Account(account));
        }
    }
}
