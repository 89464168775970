import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    ApplicationResolver,
    CompanyGuardCheck,
    CompanyRoleResolver,
    CompanyResolver,
    SidebarResolver,
    UserResolver,
} from '../../app.resolver';

import {
    ManageInstancesResolver, ManageInstanceResolver, ManageInstanceSettingsResolver,
    ManageCalendarResolver, ManageInstanceManagersResolver, ManageInstanceLearnerResolver,
    ManageInstanceStatsResolver, ManageInstanceFollowUpResolver, ManageTeamListResolver, ManageTeamSettingsResolver
} from './manage.resolver';

import { HubComponent } from '../hub/hub.component';

import { ManageComponent } from './manage.component';

import { ManageInstanceComponent } from './instance/instance.component';
import { ManageInstancesListComponent } from './instance/list/list.component';
import { ManageInstanceWatchComponent } from './instance/watch/watch.component';
import { ManageInstanceDashboardComponent } from './instance/watch/dashboard/dashboard.component';
import { ManageInstanceSettingsComponent } from './instance/watch/settings/settings.component';
import { ManageInstanceAgendaComponent } from './instance/watch/agenda/agenda.component';
import { ManageInstanceLearnerComponent } from './instance/watch/learner/learner.component';
import { ManageInstanceConversationComponent } from './instance/watch/conversation/conversation.component';
import { ManageInstanceCorrectionListComponent } from './instance/watch/correction/list/correction-list.component';
import { ManageInstanceCorrectionActivityComponent } from './instance/watch/correction/watch/activity/correction-activity.component';
import { ManageInstanceCorrectionSessionComponent } from './instance/watch/correction/watch/session/correction-session.component';
import { ManageInstanceStatsComponent } from './instance/watch/stats/stats.component';
import { ManageInstanceStatsDispatcherComponent } from './instance/watch/stats/dispatcher/dispatcher.component';
import { ManageInstanceLearnerListComponent } from './instance/watch/learner/list/list.component';
import { ManageInstanceLearnerStatsComponent } from './instance/watch/learner/stats/stats.component';
import { ManageInstanceFollowUpComponent } from './instance/watch/follow-up/follow-up.component';
import { ManageTeamComponent } from './team/team.component';
import { ManageTeamWatchComponent } from './team/watch/watch.component';
import { ManageTeamListComponent } from './team/watch/list/list.component';
import { ManageMemberDetailsComponent } from './team/watch/member-details/member-details.component';
import { ManageTeamSettingsComponent } from './team/watch/settings/settings.component';
import { ManageTeamTimelineComponent } from './team/watch/timeline/timeline.component'
import { TeamGuard } from './team/team.guard';
import { ManageInstanceLearnerStatsWrapperComponent } from './instance/watch/learner/stats-wrapper/stats-wrapper.component';
import { ManageInstanceLearnerStatsEntityComponent } from './instance/watch/learner/stats-wrapper/stats-entity/stats-entity.component';

export const routes: Routes = [
    {
        path: ':slug',
        component: ManageComponent,
        data: { breadcrumb: { skip: true } },
        canActivate: [CompanyGuardCheck],
        resolve: {
            me: UserResolver,
            sidebar: SidebarResolver,
            company: CompanyResolver,
            access: CompanyRoleResolver,
            applications: ApplicationResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'contents',
                pathMatch: 'full'
            },
            {
                path: 'contents',
                component: ManageInstanceComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        component: ManageInstancesListComponent,
                    },
                    {
                        path: ':instanceType/:instanceId',
                        component: ManageInstanceWatchComponent,
                        resolve: {
                            instance: ManageInstanceResolver
                        },
                        data: { breadcrumb: { skip: true } },
                        children: [
                            {
                                path: '',
                                redirectTo: 'learners',
                                pathMatch: 'full',
                            },
                            {
                                path: 'dashboard',
                                component: ManageInstanceDashboardComponent
                            },
                            {
                                path: 'agenda',
                                component: ManageInstanceAgendaComponent,
                                resolve: {
                                    events: ManageCalendarResolver
                                }
                            },
                            {
                                path: 'learners',
                                component: ManageInstanceLearnerComponent,
                                data: { breadcrumb: { skip: true } },
                                children: [
                                    {
                                        path: '',
                                        component: ManageInstanceLearnerListComponent,
                                    },
                                    {
                                        path: ':learnerId/stats/:entity/:entityId',
                                        component: ManageInstanceLearnerStatsWrapperComponent,
                                        data: { breadcrumb: { alias: 'ContentDisplay' } },
                                        children: [
                                            {
                                                path: '',
                                                component: ManageInstanceLearnerStatsEntityComponent,
                                            },
                                            {
                                                path: ':entity/:entityId',
                                                component: ManageInstanceLearnerStatsEntityComponent,
                                                data: { breadcrumb: { alias: 'EntityDisplay' } },
                                            },
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'stats',
                                component: ManageInstanceStatsComponent,
                                children: [
                                    {
                                        path: ':entity/:entityId/:display',
                                        component: ManageInstanceStatsDispatcherComponent,
                                        resolve: {
                                            stats: ManageInstanceStatsResolver
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'conversations',
                                component: ManageInstanceConversationComponent
                            },
                            {
                                path: 'corrections',
                                component: HubComponent,
                                children: [
                                    {
                                        path: '',
                                        component: ManageInstanceCorrectionListComponent,
                                    },
                                    {
                                        path: 'course/:courseId',
                                        component: HubComponent,
                                        children: [
                                            {
                                                path: '',
                                                component: ManageInstanceCorrectionSessionComponent,
                                            },
                                            {
                                                path: 'activity/:activityId',
                                                component: ManageInstanceCorrectionActivityComponent
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'follow-up',
                                component: ManageInstanceFollowUpComponent,
                                resolve: {
                                    users: ManageInstanceFollowUpResolver
                                }
                            },
                            {
                                path: 'settings',
                                component: ManageInstanceSettingsComponent,
                                resolve: {
                                    settings: ManageInstanceSettingsResolver,
                                    managers: ManageInstanceManagersResolver
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'team',
                component: ManageTeamComponent,
                canActivate: [TeamGuard],
                children: [
                    {
                        path: '',
                        redirectTo: ':id',
                        pathMatch: 'full'
                    },
                    {
                        path: ':id',
                        component: ManageTeamWatchComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'list',
                                pathMatch: 'full'
                            },
                            {
                                path: 'list',
                                component: ManageTeamListComponent
                            },
                            {
                                path: 'settings',
                                component: ManageTeamSettingsComponent,
                                resolve: {
                                    team: ManageTeamSettingsResolver
                                }
                            },
                            {
                                path: 'timeline',
                                component: ManageTeamTimelineComponent,
                            }
                        ]
                    },
                    {
                        path: ':id/member/:memberId',
                        component: ManageMemberDetailsComponent,
                        resolve: {
                            team: ManageTeamListResolver
                        },
                    },
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        UserResolver,
        SidebarResolver,
        ApplicationResolver,
        CompanyResolver,
        CompanyRoleResolver,
        ManageInstancesResolver,
        ManageInstanceResolver,
        ManageCalendarResolver,
        ManageInstanceSettingsResolver,
        ManageInstanceManagersResolver,
        ManageInstanceLearnerResolver,
        ManageInstanceStatsResolver,
        ManageInstanceFollowUpResolver,
        ManageTeamListResolver,
        ManageTeamSettingsResolver,
        TeamGuard
    ]
})

export class ManageRoutingModule { }
