import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { TimelineProvider } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-timeline-aside-filters',
    templateUrl: './aside-filters.component.html'
})
export class TimelineAsideFiltersComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Input() meta: any;
    @Input() context: string;
    @Input() content: any;
    @Input() loadingMeta: boolean;
    public filteredSession: any;
    public metaSession: any;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public loading: boolean = false;
    public contextFiltered: string = '';
    public arrayFiltersActivity: any[] = [];
    public loadingMetaSession: boolean = false;

    constructor(private timelineProvider: TimelineProvider) { }

    onOpen() {
        if (this.context === 'course') {
            this.metaSession = this.meta;
        }
        this.aside.onOpen();
    }

    addToFilters(context: string, value: any): void {
        const index = this.arrayFiltersActivity.findIndex((activity) => {
            return value.name === activity.name;
        });

        if (index !== -1) {
            this.arrayFiltersActivity.splice(index, 1);
            this.contextFiltered = '';
        } else {
            this.arrayFiltersActivity = [];
            this.arrayFiltersActivity.push(value);
            this.contextFiltered = context;
        }
    }

    isInFilters(value: any): boolean {
        const index = this.arrayFiltersActivity.findIndex((activity) => {
            return value.name === activity.name;
        });

        return index !== -1;
    }

    getMetaFromSession(instance: any): void {
        this.contextFiltered = 'course';
        this.arrayFiltersActivity = [{ contextId: instance.id }];
        this.loadingMetaSession = true;
        this.metaSession = null;
        this.filteredSession = instance;
        this.timelineProvider.getMeta(instance.timelineId).subscribe((data) => {
            this.loadingMetaSession = false;
            this.metaSession = data.metas;
        });
    }

    isFilteredSession(instance: any): boolean {
        return this.filteredSession ? this.filteredSession.id === instance.id : false;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        const params = HttpHelper.cleanParams({
            context: this.contextFiltered === 'scormcloud' ? 'scorm' : this.contextFiltered,
            contextId: this.arrayFiltersActivity.length > -1 && this.arrayFiltersActivity[0] ? this.arrayFiltersActivity[0].contextId : null
        });

        this.onSave.emit(params);
    }
}
