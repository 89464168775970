import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'msc-organize-user-modal-massive-invitation',
    templateUrl: './massive-invitation.component.html'
})
export class OrganizeUserModalMassiveInvitationComponent implements OnInit {
    @Input() counts: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSend: EventEmitter<any> = new EventEmitter();
    public isActive: boolean = false;
    public invitationType: string = '';
    public data: any = {
        notification: 'auto',
        message: ''
    };

    constructor() { }

    ngOnInit(): void {
        this.isActive = true;
    }

    close(): void {
        this.onClose.emit();
    }

    changeType(event: any, type?: string): void {
        this.stopPropagation(event);
        this.invitationType = type ? type : '';
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    send(): void {
        const data = {
            type: 'normal',
            message: this.data.notification === 'custom' ? this.data.message : ''
        };
        this.onSend.emit(data);
    }

    sendReminder(): void {
        const data = {
            type: 'reminder',
        };
        this.onSend.emit(data);
    }
}
