import { Component, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { mergeScan, map, tap, repeat, finalize, takeUntil } from 'rxjs/operators';
//
import { Company, ManageTeamProvider } from '@lighty';
import { StorageService, EmitterService, TranslateService } from '@services';
import { TeamMember } from '@interfaces';
import { GroupPermissions } from '@enums';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';

@Component({
    selector: 'msc-manage-team-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [DatePipe],
})

export class ManageTeamListComponent {
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public company: Company;
    public me: any;
    public groupId: number;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public query$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore: boolean = false;
    public pagination;
    public selectedMembers: TeamMember[] = [];
    public excludedMembers: TeamMember[] = [];
    public headlinesList: any[];
    public filteredHeadlinesList: any[];
    public selectedHeadlines: any[] = [];
    public displayComponents: any;
    public allMembersSelected: boolean = false;
    public allHeadlinesSelected: boolean = false;
    public hasPermissionToAdd: boolean = false;
    public teamMembersList: TeamMember[];
    public selectAll: boolean = false;
    public selectData: any = {
        headlines: []
    }

    constructor(
        private readonly datePipe: DatePipe,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastService: CommonToastService,
        private readonly emitterService: EmitterService,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
        private readonly manageTeamProvider: ManageTeamProvider,
    ) { }

    get thCheckboxIsPartial(): boolean { return this.isThCheckboxPartial(); }
    get thCheckboxIsSelected(): boolean { return this.isThCheckboxSelected(); }

    ngOnInit(): void {
        // Init variables
        this.displayComponents = {
            aside: {
                add: false,
                export: false,
                mail: false,
                reassign: false
            },
            modal: {
                subscribe: false
            }
        };
        this.setContent();
        this.checkPermissionToAdd();
        this.initHeadlinesList();
        this.onQueryUserList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.groupId = this.route.parent.snapshot.params['id'];
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: '',
                width: '5%',
            },
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '15%',
            },
            {
                key: 'headline',
                label: this.translateService.instant('words.position'),
                width: '15%',
            },
            {
                key: 'last_activity',
                label: this.translateService.instant('group.team.last-activity'),
                width: '25%',
            },
            {
                key: 'learning_time',
                label: this.translateService.instant('group.team.total-time'),
                width: '15%',
            },
            {
                key: 'actions',
                label: this.translateService.instant('group.team.actions-todo'),
                width: '20%',
            },
            {
                key: '',
                width: '5%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryUserList(pageValue: number) {
        return {
            page: pageValue,
            ...this.query$.getValue(),
        };
    }

    /**
     *
     */
    onQueryUserList(isAppend: boolean = true) {
        this.manageTeamProvider
            .getGroupUsers(this.company.id, this.groupId, this.getParamsOnQueryUserList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryUserList(response.pagination)),
                map((response) => this.onMapOnQueryUserList(response.users)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryUserList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
        this.pagination = pagination;
    }

    /**
     *
     */
    onMapOnQueryUserList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            ...datum,
            isSelected: false,
            avatarLabel: datum?.account?.name,
            avatarUrl: `${datum?.account?.picture}?size=128`,
            headline: datum?.account?.headline || '-',
            dateLastActivity: this.datePipe.transform(datum.lastLearningActivity, 'dd MMM YYYY'),
            learningTime: datum.totalLearningTime
        }));
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.page$.next(1);
        const { q, ...query } = this.query$.getValue();
        this.query$.next({ ...query, q: value });
        this.onQueryUserList(false);
    }

    /**
     *
     */
    onSort(key: any, type: CommonThSortType): void {
        this.page$.next(1);

        const { sortBy, sort, ...query } = this.query$.getValue();
        if (type) {
            this.query$.next({ ...query, sortBy: key, sort: type });
        } else {
            this.query$.next({ ...query });
        }
        this.onQueryUserList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryUserList();
    }

    /**
     * Event handler for change
     */
    onChange(value: boolean, datum: any): void {
        datum.isSelected = value;
        this.selectTeamMember();
    }

    /**
     * Event handler for modify
     */
    onModify(value: boolean): void {
        const rows = this.rows$.getValue().map((row) => { row.isSelected = value; return row; });
        this.rows$.next(rows);
        this.selectTeamMember();
    }

    /**
     * Resolves the check if thead checkbox cell is partial or not
     */
    isThCheckboxPartial(): boolean {
        return !this.rows$.getValue().every((row, _i, rows) => rows[0].isSelected === row.isSelected);
    }

    /**
     * Resolves the check if thead checkbox is selected fully or not
     */
    isThCheckboxSelected(): boolean {
        return this.rows$.getValue().every((row) => row.isSelected);
    }

    /**
     *
     */
    goToMember(id: number) {
        this.router.navigate(['../member', id], { relativeTo: this.route, queryParamsHandling: 'merge', queryParams: {...this.pagination, ...this.query$.getValue()} });
    }

    /// crap below

    onSelectHeadline(option: any) {
        option.isSelected = !option.isSelected;

        if (option.id === 'all') {
            this.selectData.headlines = this.selectData.headlines.map(d => {
                d.isSelected = option.isSelected;
                return d;
            });
        } else {
            this.selectData.headlines = [...this.selectData.headlines];
        }
        this.selectedHeadlines = this.selectData.headlines.filter((h: any) => h.value && h.isSelected);

        this.page$.next(1);
        const { headlines, ...query } = this.query$.getValue();

        this.query$.next({ ...query, headlines: (this.selectedHeadlines || []).map(h => h.label).join(',') });
        this.onQueryUserList(false);
    }

    checkPermissionToAdd() {
        const team = this.me.teams.find(team => team.id === this.me.teamOwnerId);

        this.hasPermissionToAdd = team.permissions.find(p => p === GroupPermissions.MANAGE) ? true : false;
    }

    initHeadlinesList(): void {
        this.manageTeamProvider.getGroupUsersMeta(this.company.id, this.me.teamOwnerId).subscribe((data) => {
            // this.headlinesList = Object.entries(data).map(d => ({ name: d[0], value: d[1], checked: false }));
            this.headlinesList = this.getHeadlinesRemapped(Object.entries(data));
            this.filteredHeadlinesList = [...this.headlinesList];

            this.selectData.headlines = this.getSelectData(this.headlinesList);
        });
    }

    getHeadlinesRemapped(headlines) {
        return headlines.map(headline => {
            if (headline[0] === 'noHeadline') {
                return {
                    name: this.translateService.instant('group.selection.no-headline'),
                    value: headline[1]
                }
            }

            return {
                name: headline[0],
                value: headline[1]
            }
        })
    }

    getSelectData(data) {
        const selectAllOption = {
            id: 'all',
            label: this.translateService.instant('words.select-all'),
            value: undefined,
            isSelected: false
        };
        const options = data.map((d, index) => ({
            id: index,
            label: d.name,
            value: d.value,
            isSelected: false
        }));

        return [selectAllOption, ...options];
    }

    resetToDefaultState(): void {
        this.allHeadlinesSelected = false;
        this.headlinesList.forEach((h) => h.checked = false);

        this.selectedHeadlines = [];
        this.selectedMembers = [];

        this.emitterService.sort.emit('reset');
    }

    selectTeamMember(): void {
        this.selectedMembers = this.rows$.getValue().filter((row) => row.isSelected);
    }

    excludeTeamMember(member: TeamMember): void {
        const index = this.excludedMembers.findIndex(item => item.account.id === member.account.id);
        if (index !== -1) {
            this.excludedMembers.splice(index, 1);
        } else {
            this.excludedMembers.push(member);
        }
    }

    toggleSelectedMembers() {
        if (this.selectedMembers.length > 0) {
            this.selectedMembers = [];
            return;
        }
        this.excludedMembers = [];
        this.selectAll = !this.selectAll;
    }

    addTeamMembers(event: any): void {
        const params = {
            accountIds: event.accountIds,
            inviteAll: event.inviteAll,
            notify: event.notify
        };

        this.manageTeamProvider.addUserToGroup(this.company.slug, this.me.teamOwnerId, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-added-undefined'));
            this.closeComponent('aside', 'add');

            setTimeout(() => { // TODO (MSC-3171)
                this.resetToDefaultState();
            }, 1000);
        });
    }

    openConversationWithMembers(): void {
        const params = {
            accountIds: this.selectedMembers.map(member => member.account.id),
            excludedAccountIds: this.excludedMembers.map((user) => user.account.id),
            invite_all: this.selectAll
        };

        this.manageTeamProvider.createConversation(this.company.id, params).subscribe((data: any) => {
            this.router.navigate([`conversation/${data.slug}`]);
        });
    }

    reassignManager(event: any): void {
        const params = {
            accountIds: this.selectedMembers.map(member => member.account.id),
            excludedAccountIds: this.excludedMembers.map((user) => user.account.id),
            invite_all: this.selectAll,
            notify: event.notify
        };

        this.manageTeamProvider.addUserToGroup(this.company.slug, event.groupId, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-added-undefined'));
            this.closeComponent('aside', 'reassign');

            if (this.selectedMembers.length === this.teamMembersList.length) {
                this.router.navigate([`manage/${this.company.slug}/contents`]);
                return;
            }

            setTimeout(() => { // TODO (MSC-3171)
                this.resetToDefaultState();
            }, 1000);
        });
    }

    sendMailToMembers(event: any): void {
        const accountIds = this.selectedMembers.map(member => member.account.id);
        const excludedAccountIds = this.excludedMembers.map(member => member.account.id);

        const params = {
            content: event.message,
            accountIds,
            excludedAccountIds,
            invite_all: this.selectAll
        };

        this.manageTeamProvider.sendEmailToUsers(this.company.id, this.me.teamOwnerId, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.mail-successfully-sent'));
            this.closeComponent('aside', 'mail');
        });
    }

    subscribeMembersToContent(event): void {
        const params = {
            context: event.context,
            sessionIds: event.sessionIds,
            accountIds: this.selectedMembers.map(member => member.account.id),
            groupId: this.me.teamOwnerId
        };

        this.manageTeamProvider.subscribeUsersToContent(this.company.id, event.contentId, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-subscribed-content'));
        });
    }

    openComponent(type: string, key: string): void {
        this.displayComponents[type][key] = true;
    }

    closeComponent(type: string, key: string): void {
        this.displayComponents[type][key] = false;
    }
}
