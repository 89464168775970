<msc-common-aside #aside [hasActionButtons]="false">
    <ng-template #headerTemplate>
        <div class="flex flex-col space-y-4 relative">
            <msc-button class="absolute right-2 top-2" type="fab" icon="icon-close" (click)="aside.onClose()">
            </msc-button>

            <div class="flex flex-col">
                <div class="flex space-x-2 items-center">
                    <msc-button *ngIf="event.periodConfirmed && tab !== 'periods'" type="fab" icon="icon-arrow-ios-left"
                        (click)="backAsideState()">
                    </msc-button>
                    <h2>
                        {{ getStateTitleTranslation() }}
                    </h2>
                </div>
                <small class="mt-2 text-left" *ngIf="!event.periodConfirmed && tab === 'settings'">{{
                    'manage.instance.event.attendance.window.settings.v2.helper' | translate }}</small>
            </div>

            <div class="flex justify-between" *ngIf="tab !== 'settings'">
                <msc-common-search [ngClass]="{'w-full': tab === 'periods'}" name="search" trigger="auto"
                    [placeholder]="'common.search' | translate" (searchEvent)="searchAttendances($event.value)">
                </msc-common-search>

                <div class="flex space-x-2 items-center">
                    <msc-button class="msc-button--primary" palette="secondary" icon="icon-plus-circle"
                        *ngIf="tab === 'period'" (click)="changeTab('add-user')">
                        {{ 'words.add-attendee.v2' | translate }}
                    </msc-button>

                    <msc-button *ngIf="tab === 'period' && event.type !== 'virtual-class'"
                        (click)="changeTab('settings')" icon="icon-settings" palette="secondary" type="fab">
                    </msc-button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="grid h-full" style="grid-template-rows: 1fr max-content">
            <div class="overflow-y-auto">
                <div class="slide__body" *ngIf="tab === 'periods'">
                    <div *ngIf="attendancesList && attendancesList.length > 0">
                        <div class="box box-attendance" *ngFor="let period of attendancesList"
                            (click)="setSelectedPeriod(period.id)">
                            <div class="box-attendance__content">
                                <div class="box-attendance__date">
                                    <div class="tag tag--date tag--purple">
                                        <div class="tag__text">
                                            <span class="text__day">{{ period.date | date: 'dd' }}</span>
                                            <span class="text__month">{{ period.date | localizedDate:'MMM' }}</span>
                                        </div>
                                    </div>

                                    <div class="box-attendance__time">
                                        <p *ngIf="period.type === 'am'">
                                            <span>{{ 'manage.instance.event.attendance.window.morning' | translate
                                                }}</span>
                                        </p>

                                        <p *ngIf="period.type === 'pm'">
                                            <span>{{ 'manage.instance.event.attendance.window.afternoon' | translate
                                                }}</span>
                                        </p>

                                        <p *ngIf="period.type === 'day'">
                                            <span>{{ 'manage.instance.event.attendance.window.full-day.v2' | translate
                                                }}</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="box-attendance__infos">
                                    <ul>
                                        <li [tooltip]="'words.learners' | translate" direction="bottom">
                                            <i class="icon icon-people"></i>
                                            <span>{{ period.attendancesCount }}</span>
                                        </li>

                                        <li>
                                            <i class="icon icon-checkmark-circle-2"></i>
                                            <span>{{ period.confirmedCount }} / {{ period.attendancesCount }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="box-attendance__action">
                                <a><i class="icon icon-arrow-ios-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <msc-common-spinner *ngIf="loadingAttendancesList"></msc-common-spinner>
                </div>

                <div class="slide__body" *ngIf="tab === 'period'">
                    <div *ngIf="selectedPeriod.attendances && selectedPeriod.attendances.length > 0">
                        <div class="box box-attendance box-attendance-check-all">
                            <div class="box-attendance__content">
                                <div class="box-attendance__date">
                                    <div class="tag tag--date">
                                        <div class="tag__text">
                                            <span class="text__day">{{ selectedPeriod.date | date:'dd' }}</span>
                                            <span class="text__month">{{ selectedPeriod.date | localizedDate:'MMM' }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-attendance__infos ml-4">
                                    <ul>
                                        <li class="notification" *ngIf="selectedPeriod.unconfirmed">
                                            <a [tooltip]="'manage.instance.event.attendance.window.parameters.attendees_to_confirm' | translate"
                                                direction="bottom">
                                                <i class="icon icon-alert-triangle"></i>
                                            </a>
                                        </li>

                                        <li [tooltip]="'words.learners' | translate" direction="bottom">
                                            <i class="icon icon-people"></i>
                                            <span>{{ selectedPeriod.attendancesCount }}</span>
                                        </li>

                                        <li>
                                            <i class="icon icon-checkmark-circle-2"></i>
                                            <span>{{ selectedPeriod.confirmedCount }} / {{
                                                selectedPeriod.attendancesCount
                                                }}</span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="ml-auto flex item-center justify-end space-x-2">
                                    <span>{{ 'words.select-all' | translate }}</span>
                                    <msc-common2-checkbox [value]="missingAll" (changeEvent)="addToMissingAll()"></msc-common2-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="slide__select">
                            <div class="box box-attendance-user" *ngFor="let attendee of filteredAttendances">
                                <div class="box-attendance-user__content">
                                    <div class="box-attendance-user__avatar">
                                        <msc-common-avatar [label]="attendee.account.name"
                                            [pictureURL]="attendee.account.picture ? (attendee.account.picture + '?size=128') : null">
                                        </msc-common-avatar>

                                        <div class="box-attendance-user__infos">
                                            <p>{{ attendee.account.name }}</p>

                                            <small class="text-gray-500 bg-gray-200 rounded-xl py-1 px-2"
                                                [ngClass]="{'text-green-800 bg-green-200': attendee.confirmedAt}">
                                                {{ ( attendee.confirmedAt ? 'words.status-confirmed' : 'words.status-unconfirmed') |
                                                translate
                                                }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="box-attendance-user__checkbox">
                                        <msc-common2-checkbox [value]="!isInMissing(attendee)"
                                            (changeEvent)="addToMissing(attendee)"></msc-common2-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <msc-empty-state
                        *ngIf="(!selectedPeriod.attendances || selectedPeriod.attendances.length <= 0) && !loadingPeriod"
                        [title]="'manage.instance.event.attendance.window.periods'"
                        [description]="'manage.instance.event.attendance.window.periods.empty'">
                    </msc-empty-state>

                    <msc-common-spinner *ngIf="loadingPeriod"></msc-common-spinner>
                </div>

                <div class="slide__body" *ngIf="tab === 'settings'">
                    <div class="box-params" [ngClass]="{'box-params--active': event.periodType === 'single'}"
                        (click)="updatePeriodsSettingsModal.onOpen(); updatePeriodsSettings('single')">
                        <i class="icon icon-checkmark-circle-2"></i>
                        <div>
                            <p>{{ 'manage.instance.event.attendance.window.parameters.single.v2' | translate }}</p>
                            <small>{{ 'manage.instance.event.attendance.window.parameters.single.helper' | translate
                                }}</small>
                        </div>
                    </div>

                    <div class="box-params" [ngClass]="{'box-params--active': event.periodType === 'one_per_day'}"
                        (click)="updatePeriodsSettingsModal.onOpen(); updatePeriodsSettings('one_per_day')">
                        <i class="icon icon-checkmark-circle-2"></i>
                        <div>
                            <p>{{ 'manage.instance.event.attendance.window.parameters.one_per_day.v2' | translate }}</p>
                            <small>{{ 'manage.instance.event.attendance.window.parameters.one_per_day.helper' |
                                translate
                                }}</small>
                        </div>
                    </div>

                    <div class="box-params" [ngClass]="{'box-params--active': event.periodType === 'two_a_day'}"
                        (click)="updatePeriodsSettingsModal.onOpen(); updatePeriodsSettings('two_a_day')">
                        <i class="icon icon-checkmark-circle-2"></i>
                        <div>
                            <p>{{ 'manage.instance.event.attendance.window.parameters.two_a_day.v2' | translate }}</p>
                            <small>{{ 'manage.instance.event.attendance.window.parameters.two_a_day.helper' | translate
                                }}</small>
                        </div>
                    </div>
                </div>

                <div class="slide__body" *ngIf="tab === 'add-user'">
                    <div class="box box-attendance box-attendance-check-all">
                        <div class="box-attendance__content">
                            <div class="ml-auto flex item-center justify-end space-x-2">
                                <span>{{ 'words.select-all' | translate }}</span>
                                <msc-common2-checkbox [value]="learnerAll" (changeEvent)="addToLearnerAll()">
                                </msc-common2-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="" *ngIf="availableLearners && availableLearners.length === 0">
                        {{ 'empty.nothing.members' | translate }}
                    </div>

                    <div class="box box-attendance-user" *ngFor="let learner of availableLearners">
                        <div class="box-attendance-user__content">
                            <div class="box-attendance-user__avatar">
                                <msc-common-avatar [label]="learner.name"
                                    [pictureURL]="learner.picture ? (learner.picture + '?size=128') : null">
                                </msc-common-avatar>
                                <div class="box-attendance-user__infos">
                                    <p>{{ learner.name }}</p>
                                    <div class="profile__job">{{ learner.headline | truncate:30}}</div>
                                </div>
                            </div>

                            <div class="box-attendance-user__checkbox">
                                <msc-common2-checkbox [value]="isLearnerSelected(learner)"
                                    (changeEvent)="addToLearner(learner)">
                                </msc-common2-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 flex flex-col p-3">
                <div class="slide__summary" *ngIf="learnersSelected.length > 0 && tab === 'add-user'">
                    <ul class="avatar-group avatar-group--add">
                        <li *ngFor="let learner of learnersSelected | slice:0:3">
                            <a [attr.data-toolip]="learner.name" class="tooltip--triangle">
                                <msc-common-avatar [label]="learner.name"
                                    [pictureURL]="learner.picture ? (learner.picture + '?size=128') : null">
                                </msc-common-avatar>
                            </a>
                        </li>

                        <li *ngIf="learnersSelected.length > 3">
                            <span class="avatar-group__text text-primary-500">
                                + {{ learnersSelected.length - 3 }}
                            </span>
                        </li>
                    </ul>
                </div>

                <div class="flex justify-between">
                    <msc-common2-checkbox [value]="isUsersNotified" [label]="'words.notify-participants' | translate"
                        (changeEvent)="isUsersNotify()"></msc-common2-checkbox>

                    <msc-button *ngIf="tab !== 'settings'" class="msc-button--primary" palette="secondary"
                        (click)="save()">
                        {{ 'words.save' | translate }}
                    </msc-button>
                </div>
            </div>
        </div>
    </ng-template>
</msc-common-aside>

<msc-common-modal size="30vw" #updatePeriodsSettingsModal [title]="'words.update-periods-settings' | translate">
    <ng-template>
        <p>{{ 'words.update-periods-settings-description.v2' | translate : {choice:
            ('manage.instance.event.attendance.window.parameters.' + periodTypeSelected + '.v2' | translate)} }}</p>
        <p>{{ 'words.update-periods-settings-description.confirm' | translate }}</p>
        <p class="text-gray-400 mt-2">{{ 'words.update-periods-settings-description.helper' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="updatePeriodsSettingsModal.onClose()">{{'common.cancel' |
                translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary"
                (click)="confirmUpdatePeriodsSettings(); updatePeriodsSettingsModal.onClose()">{{'common.save' |
                translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
