<div class="content-configuration__content">
    <h3>{{ 'words.settings' | translate }}</h3>
    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-4 col-xs-6">
            <label class="flex text-sm mb-1">{{ 'words.interface-language' | translate }}</label>
            <ng-container *ngIf="selectData.interfaceLanguages.length">
                <msc-common-select [data]="selectData.interfaceLanguages">
                    <ng-template #buttonTemplate let-data="data">
                        <button class="flex items-center text-sm text-black-400 w-full">
                            <ng-container *ngIf="data?.length">
                                <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                                <span>{{ data[0].label }}</span>
                            </ng-container>
                        </button>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <button class="flex items-center text-sm py-2" (click)="onSelectOption(datum, 'interfaceLanguages')">
                            <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                            <span>{{ datum.label }}</span>
                        </button>
                    </ng-template>
                </msc-common-select>
            </ng-container>
        </div>
    </div>
    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
            <label class="flex text-sm mb-1">{{ 'words.learning-languages' | translate }}</label>
            <ng-container *ngIf="selectData.contentLanguages.length">
                <msc-common-select [data]="selectData.contentLanguages" type="multi" hasSearch="true">
                    <ng-template #buttonTemplate let-data="data">
                        <button class="flex text-sm text-black-400 w-full">
                            <ng-container *ngIf="data?.length; else emptyTemplate">
                                <span class="truncate">{{ contentLanguagesSelectedLabel }}</span>
                            </ng-container>
                            <ng-template #emptyTemplate>
                                <span>{{'words.languages' | translate}}</span>
                            </ng-template>
                        </button>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <label class="flex items-center justify-between text-sm py-2 w-full">
                            <span>{{ datum.label }}</span>
                            <msc-common2-checkbox [value]="datum.isSelected"
                                (changeEvent)="onSelectMultiOption(datum, 'contentLanguages')"></msc-common2-checkbox>
                        </label>
                    </ng-template>
                </msc-common-select>
            </ng-container>
        </div>
    </div>
    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-4 col-xs-6">
            <label class="flex text-sm mb-1">{{ 'words.experience' | translate }}</label>
            <ng-container *ngIf="selectData.experiences.length">
                <msc-common-select [data]="selectData.experiences">
                    <ng-template #buttonTemplate let-data="data">
                        <button class="flex text-sm text-black-400 w-full">
                            <ng-container *ngIf="data?.length">
                                <span class="truncate">{{ data[0].label }}</span>
                            </ng-container>
                        </button>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <button class="flex text-left text-sm py-1" (click)="onSelectOption(datum, 'experiences')">
                            <span>{{ datum.label }}</span>
                        </button>
                    </ng-template>
                </msc-common-select>
            </ng-container>
        </div>
    </div>
    <div class="content-configuration__dataset">
        <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
            <label class="flex text-sm mb-1">{{ 'words.skills' | translate}}</label>
            <msc-common-select #selectSkill [infiniteLoad]="loadMoreSkills()" type="multi" hasSearch="true">
                <ng-template #buttonTemplate>
                    <button class="flex items-center text-sm text-black-400 w-full">
                        <ng-container *ngIf="skillsSelectedLabel; else emptyTemplate">
                            <i class="icon icon-bulb"></i>
                            <span class="truncate ml-2">{{ skillsSelectedLabel }}</span>
                        </ng-container>
                        <ng-template #emptyTemplate>
                            <i class="icon icon-bulb"></i>
                            <span class="ml-2">{{'words.skills' | translate}}</span>
                        </ng-template>
                    </button>
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <label class="flex items-center justify-between text-sm py-2 w-full">
                        <div class="flex items-center mr-1">
                            <i class="icon icon-bulb icon-bulb-option"></i>
                            <span>{{ datum.label }}</span>
                        </div>
                        <msc-common2-checkbox [value]="datum.isSelected"
                            (changeEvent)="onSelectMultiOption(datum, 'skills');"></msc-common2-checkbox>
                    </label>
                </ng-template>
            </msc-common-select>
        </div>

        <div class="flex align-center wrap">
            <div class="skill skill--shadow-none skill--forbidden" *ngFor="let skill of skillsSelected">
                <div class="skill__content">
                    <span class="skill__text">{{ getSkillName(skill) }}</span>

                    <a class="skill__close" (click)="updateSkill(skill)">
                        <i class="icon icon-close"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="content-configuration__dataset">
        <div class="content-configuration__input">
            <label class="flex text-sm mb-2">{{ 'user.mail-notification' | translate }}*</label>
            <div>
                <msc-common2-radio class="text-sm mb-2" groupName="invitationNotification" [(ngModel)]="data.emailStatus" [valueName]="'auto'"
                    [label]="'words.notification.auto' | translate">
                </msc-common2-radio>
                <div class="content-configuration__dataset" *ngIf="data.emailStatus === 'auto'">
                    <div class="content-configuration__input col-md-6 col-sm-6 col-xs-12">
                        <label class="flex text-sm text-black-400">{{ 'words.message' | translate }}</label>
                        <div class="textarea" [innerHTML]="emailTemplate"></div>
                    </div>
                </div>

                <msc-common2-radio class="text-sm mb-2" groupName="invitationNotification" [(ngModel)]="data.emailStatus" [valueName]="'custom'"
                    [label]="'words.notification.custom' | translate">
                </msc-common2-radio>

                <div class="content-configuration__dataset" *ngIf="data.emailStatus === 'custom'">
                    <div class="content-configuration__input col-md-6 col-sm-6 col-xs-12">
                        <msc-textarea [label]="'words.custom-text' | translate" name="textarea"
                            [hasErrors]="formErrors.customMessage" rows="5" cols="33" 
                            [(ngModel)]="data.custom_message" [placeholder]="'placeholder.message' | translate">
                        </msc-textarea>
                    </div>
                </div>

                <msc-common2-radio class="text-sm" groupName="invitationNotification" [(ngModel)]="data.emailStatus" [valueName]="'none'"
                    [label]="'words.notification.none' | translate">
                </msc-common2-radio>
            </div>
        </div>
    </div>
</div>