import { Component, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { mergeScan, map, tap, repeat, finalize, takeUntil, take } from 'rxjs/operators';
//
import { Company, ManageLearnerProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';
import { SharedMailUserComponent } from '@components/shared/aside-mail-user/aside-mail-user.component';


@Component({
    selector: 'msc-manage-instance-learner-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [DatePipe],
})

export class ManageInstanceLearnerListComponent {
    @ViewChild('modalDeleteLearner') modalDeleteLearner: CommonModalComponent;
    @ViewChild('mailUserAside') mailUserAside: SharedMailUserComponent;

    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public instanceId: number;
    public instanceType: string;
    public company: Company;
    public me: any;
    public instance: any;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public query$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore: boolean = false;
    // crap
    public dropdowns: any[] = [];
    public displays: any;
    public currentLearner: any;
    public dataFilters: any;
    public filters: any;
    public isFilterActive: boolean = false;
    public activeSort: boolean = false;

    constructor(
        private readonly datePipe: DatePipe,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastService: CommonToastService,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
        private readonly manageLearnerProvider: ManageLearnerProvider,
    ) { }

    ngOnInit(): void {
        this.displays = {
            available: false,
            mail: false
        };
        this.setContent();
        this.setFilters();
        this.onQueryLearnerList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.instance = this.storageService.get('content');
        this.instanceId = this.route.parent.parent.snapshot.params['instanceId'];
        this.instanceType = this.getContentType(this.route.parent.parent.snapshot.params['instanceType']);
        this.columns = this.getColumns();
        console.log(this.route.parent.parent.snapshot)
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '20%',
            },
            {
                key: 'created_at',
                label: this.translateService.instant('words.subscription-date'),
                width: '20%',
            },
            {
                key: 'duration',
                label: this.translateService.instant('words.course-hours'),
                width: '15%',
            },
            {
                key: 'progress',
                label: this.translateService.instant('words.progress-average'),
                width: '15%',
            },
            {
                key: 'score',
                label: this.translateService.instant('words.score'),
                width: '15%',
            },
            {
                key: 'updated_at',
                label: this.translateService.instant('words.last-activity'),
                width: '10%',
            },
            {
                key: '',
                width: '5%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryLearnerList(pageValue: number) {
        return {
            context: this.instanceType,
            page: pageValue,
            ...this.query$.getValue(),
        };
    }

    /**
     *
     */
    onQueryLearnerList(isAppend: boolean = true) {
        this.manageLearnerProvider
            .get(this.company.id, this.instanceId, this.getParamsOnQueryLearnerList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryLearnerList(response.pagination)),
                map((response) => this.onMapOnQueryLearnerList(response.learners)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryLearnerList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryLearnerList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            ...datum,
            avatarLabel: datum?.name,
            avatarUrl: `${datum?.picture}?size=128`,
            dateActiveSince: this.datePipe.transform(datum.subscriptionDate, 'dd MMM YYYY'),
            dateLastActivity: this.datePipe.transform(datum.lastActivity, 'dd MMM'),
            dataSelect: this.getDataSelect(datum),
        }));
    }

    /**
     *
     */
    getDataSelect(datum: any) {
        return [
            {
                isSelected: false,
                value: {
                    icon: 'message-circle',
                    isVisible: datum.accountId !== this.me.id,
                    action: () => this.conversationWith(datum),
                },
                label: this.translateService.instant('words.discuss'),
            },
            {
                isSelected: false,
                value: {
                    icon: 'email',
                    isVisible: datum.accountId !== this.me.id,
                    action: () => this.mail(datum),
                },
                label: this.translateService.instant('words.notify-by-email'),
            },
            {
                isSelected: false,
                value: {
                    icon: 'pie-stats-1',
                    isVisible: this.instanceType !== 'external_content',
                    action: () => this.stats(datum.accountId),
                },
                label: this.translateService.instant('words.stats'),
            },
            {
                isSelected: false,
                value: {
                    icon: 'trash',
                    isVisible: datum.currentlySubscribed,
                    action: () => this.openModalDelete(datum),
                },
                label: this.translateService.instant('words.delete'),
            },
        ];
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.page$.next(1);
        const { q, ...query } = this.query$.getValue();
        this.query$.next({ ...query, q: value });
        this.onQueryLearnerList(false);
    }

    /**
     *
     */
    onSort(key: any, type: CommonThSortType): void {
        this.page$.next(1);
        const { order_by, order_way, ...query } = this.query$.getValue();
        if (type) {
            this.query$.next({ ...query, order_by: key, order_way: type });
        } else {
            this.query$.next({ ...query });
        }
        this.onQueryLearnerList(false);
    }

    /**
     *
     */
    onFilter(): void {
        this.page$.next(1);
        const { validation, status, score, ...query } = this.query$.getValue();
        this.query$.next({
            ...query,
            ...this.dataFilters.validation && this.dataFilters.validation.length ? { validation: this.dataFilters.validation.join(',') } : {},
            ...this.dataFilters.progress ? { status: this.dataFilters.progress } : {},
            ...this.dataFilters.score ? { score: [this.dataFilters.score.min, this.dataFilters.score.max].toString() } : {},
        });
        this.onQueryLearnerList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryLearnerList();
    }

    /**
     * 
     */
    onFilterSave(data): void {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.onFilter();
    }

    /**
     * 
     */
    onFilterReset() {
        this.dataFilters = {};
        this.isFilterActive = false;
        this.onFilter();
    }

    /// crap

    initSlider(): any {
        return {
            minValue: 0,
            maxValue: 100,
            values: {
                min: 0,
                max: 100
            },
            step: 5,
            stepBottom: true,
            displayStepValue: true,
        };
    }

    setFilters(): void {
        this.filters = [
            {
                name: 'validation',
                type: 'checkbox',
                label: this.translateService.instant('words.validation'),
                options: [{
                    label: this.translateService.instant('filter.accepted'),
                    value: 'accepted',
                    isChecked: false,
                }, {
                    label: this.translateService.instant('filter.refused'),
                    value: 'refused',
                    isChecked: false,
                }, {
                    label: this.translateService.instant('filter.pending'),
                    value: 'pending',
                    isChecked: false,
                }]
            },
            {
                name: 'progress',
                type: 'radio',
                label: this.translateService.instant('words.progress'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all'),
                    value: null
                }, {
                    label: this.translateService.instant('words.not-started'),
                    value: 'not_started'
                }, {
                    label: this.translateService.instant('manage.instance.status-ongoing'),
                    value: 'in_progress'
                }, {
                    label: this.translateService.instant('words.completed'),
                    value: 'completed'
                }]
            },
            {
                name: 'score',
                type: 'range',
                label: this.translateService.instant('words.score'),
                value: { min: 0, max: 100 },
                options: this.initSlider()
            }
        ];
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    addLearners(event: any): void {
        const params = {
            context: this.instanceType,
            account_ids: event.learners,
            invite_all: event.inviteAll,
            notify: event.notify
        };

        this.manageLearnerProvider.addLearners(this.company.id, this.instanceId, params).subscribe(() => {
            this.query$.next({});
            this.onQueryLearnerList(false);
        });
    }

    openModalDelete(learner: any): void {
        this.modalDeleteLearner.onOpen();
        this.currentLearner = learner;
    }

    closeModalDelete(): void {
        this.currentLearner = null;
    }

    deleteLearner(): void {
        const params = {
            context: this.instanceType,
            notify: 0
        };

        this.manageLearnerProvider.delete(this.company.id, this.instanceId, this.currentLearner.subscriptionId, params).subscribe(() => {
            const learner = this.rows$.getValue().find((data) => data.accountId === this.currentLearner.accountId);
            learner.currentlySubscribed = false;
            this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
            this.closeModalDelete();
        });
    }

    stats(id: number): void {
        const params = {
            id,
            entity: this.getEntity()
        };

        this.router.navigate([params.id, 'stats', params.entity, this.instanceId], { relativeTo: this.route });
    }

    conversationWith(user: any): void {
        this.storageService.set('conversationUsers', [user]);
        this.router.navigate(['conversation/create']);
    }

    mail(user: any): void {
        this.mailUserAside.onOpen(user);
    }

    getEntity(): string {
        switch (this.instanceType) {
            case 'course_instance':
                return 'course';
            case 'external_content':
                return 'content';
            default:
                return this.instanceType;
        }
    }

    getDisplay(): string {
        switch (this.instanceType) {
            case 'course_instance':
                return 'activities';
            case 'traject':
                return 'contents';
        }
    }

    getContentType(type: string): string {
        switch (type) {
            case 'template':
                return 'course_instance';
            case 'journey':
                return 'traject';
            default:
                return 'external_content';
        }
    }
    
    public generateReport(): void {
        console.log(this.instanceType)
        this.manageLearnerProvider.generateReports(this.company.id, this.instanceId).pipe(take(1)).subscribe(data => {
            this.toastService.onSuccess(this.translateService.instant('words.send-report'));
            console.log(data)
        })
    }
}
