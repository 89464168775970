<div class="pictogram">
    <div class="square">
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g transform="translate(0 -1028.4)">
                <path d="m4.2218 1031.2 2.1213 0.7 0.1548 0.1c0.3432-0.2 0.6978-0.4 1.0606-0.6l-0.0663-0.2 0.4199-2.2 1.9445-0.5
                1.4804 1.7 0.044 0.2c0.416-0.1 0.822-0.1 1.238 0l0.044-0.2 1.48-1.7 1.945 0.5 0.42 2.2-0.066 0.2c0.362 0.2 0.717
                0.4 1.06 0.6l0.155-0.1 2.121-0.7 1.414 1.4-0.707 2.1-0.154 0.2c0.226 0.3 0.438 0.7 0.618 1h0.199l2.188 0.4 0.53
                1.9-1.701 1.5h-0.177c0.026 0.5 0.026 0.9 0 1.3h0.177l1.701 1.5-0.53 2-2.188 0.4-0.199-0.1c-0.18 0.4-0.392 0.7-0.618
                1.1l0.154 0.1 0.707 2.1-1.414 1.5-2.121-0.8-0.155-0.1c-0.343 0.2-0.698 0.4-1.06 0.6l0.066 0.2-0.42 2.2-1.945
                0.5-1.48-1.7-0.044-0.1h-1.238l-0.044 0.1-1.4804 1.7-1.9446-0.5-0.4198-2.2 0.0663-0.2c-0.3628-0.2-0.7174-0.4-1.0607-0.6l-0.1547 0.1-2.1213
                0.8-1.4142-1.5 0.7071-2.1 0.1547-0.1c-0.2267-0.4-0.4389-0.7-0.6187-1.1l-0.1989 0.1-2.1876-0.4-0.53034-2 1.7015-1.5h0.1767c-0.0258-0.4-0.0258-0.8 0-1.3h-0.1767l-1.7015-1.5
                0.53033-1.9 2.1876-0.4h0.1989c0.1798-0.3 0.3921-0.7 0.6187-1l-0.1547-0.2-0.7071-2.1 1.4142-1.4zm2.8285 4.2c-2.7337 2.7-2.7337 7.2 0 9.9 2.7336 2.7
                7.1657 2.7 9.8997 0 2.733-2.7 2.733-7.2 0-9.9-2.734-2.7-7.1661-2.7-9.8997 0z" fill="#95a5a6" />
                <path d="m6.3431 1034.7c3.1242-3.1 8.1899-3.1 11.314 0 3.124 3.1 3.124 8.2 0 11.3s-8.1896 3.1-11.314 0c-3.1241-3.1-3.124-8.2
                0.0001-11.3zm1.4806 0.1 2.1655 2.1c0.8848-0.5 1.8958-0.7 2.8728-0.4l1.392-2.8c-2.142-0.7-4.5518-0.3-6.4303 1.1zm-1.4142
                1.4c-1.4064 1.9-1.766 4.3-1.0386 6.4l2.718-1.4c-0.2171-1-0.0357-2 0.4861-2.8l-2.1655-2.2zm4.1765 2.7c-0.7813 0.8-0.7813 2.1 0
                2.9 0.781 0.8 2.047 0.8 2.828 0s0.781-2.1 0-2.9c-0.781-0.7-2.047-0.7-2.828 0zm5.436-4.2-1.392 2.7c0.062 0 0.138 0.1 0.198 0.1
                0.69 0.7 1.068 1.6 1.149 2.5l3.006 0.5c0.022-1.9-0.644-3.7-2.033-5.1-0.288-0.3-0.607-0.5-0.928-0.7zm-9.723 9.7c0.2271 0.3
                0.4635 0.6 0.7513 0.9 1.3888 1.4 3.2177 2.1 5.0377 2l-0.442-3c-0.9 0-1.7854-0.4-2.4744-1.1-0.0602-0.1-0.0992-0.1-0.1547-0.2l-2.7179
                1.4zm9.369-2.4c-0.194 0.4-0.474 0.8-0.84 1.2-0.365 0.4-0.79 0.6-1.237 0.8l0.464 3.1c1.058-0.4 2.058-1 2.895-1.8s1.421-1.8 1.745-2.9l-3.027-0.4z" fill="#7f8c8d" />
            </g>
        </svg>
    </div>
    <div class="square">&nbsp;</div>
    <div class="square">&nbsp;</div>
    <div class="square">
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g transform="translate(0 -1028.4)">
                <path d="m4.2218 1031.2 2.1213 0.7 0.1548 0.1c0.3432-0.2 0.6978-0.4 1.0606-0.6l-0.0663-0.2 0.4199-2.2 1.9445-0.5
                1.4804 1.7 0.044 0.2c0.416-0.1 0.822-0.1 1.238 0l0.044-0.2 1.48-1.7 1.945 0.5 0.42 2.2-0.066 0.2c0.362 0.2 0.717
                0.4 1.06 0.6l0.155-0.1 2.121-0.7 1.414 1.4-0.707 2.1-0.154 0.2c0.226 0.3 0.438 0.7 0.618 1h0.199l2.188 0.4 0.53
                1.9-1.701 1.5h-0.177c0.026 0.5 0.026 0.9 0 1.3h0.177l1.701 1.5-0.53 2-2.188 0.4-0.199-0.1c-0.18 0.4-0.392 0.7-0.618
                1.1l0.154 0.1 0.707 2.1-1.414 1.5-2.121-0.8-0.155-0.1c-0.343 0.2-0.698 0.4-1.06 0.6l0.066 0.2-0.42 2.2-1.945
                0.5-1.48-1.7-0.044-0.1h-1.238l-0.044 0.1-1.4804 1.7-1.9446-0.5-0.4198-2.2 0.0663-0.2c-0.3628-0.2-0.7174-0.4-1.0607-0.6l-0.1547 0.1-2.1213
                0.8-1.4142-1.5 0.7071-2.1 0.1547-0.1c-0.2267-0.4-0.4389-0.7-0.6187-1.1l-0.1989 0.1-2.1876-0.4-0.53034-2 1.7015-1.5h0.1767c-0.0258-0.4-0.0258-0.8 0-1.3h-0.1767l-1.7015-1.5
                0.53033-1.9 2.1876-0.4h0.1989c0.1798-0.3 0.3921-0.7 0.6187-1l-0.1547-0.2-0.7071-2.1 1.4142-1.4zm2.8285 4.2c-2.7337 2.7-2.7337 7.2 0 9.9 2.7336 2.7
                7.1657 2.7 9.8997 0 2.733-2.7 2.733-7.2 0-9.9-2.734-2.7-7.1661-2.7-9.8997 0z" fill="#95a5a6" />
                <path d="m6.3431 1034.7c3.1242-3.1 8.1899-3.1 11.314 0 3.124 3.1 3.124 8.2 0 11.3s-8.1896 3.1-11.314 0c-3.1241-3.1-3.124-8.2
                0.0001-11.3zm1.4806 0.1 2.1655 2.1c0.8848-0.5 1.8958-0.7 2.8728-0.4l1.392-2.8c-2.142-0.7-4.5518-0.3-6.4303 1.1zm-1.4142
                1.4c-1.4064 1.9-1.766 4.3-1.0386 6.4l2.718-1.4c-0.2171-1-0.0357-2 0.4861-2.8l-2.1655-2.2zm4.1765 2.7c-0.7813 0.8-0.7813 2.1 0
                2.9 0.781 0.8 2.047 0.8 2.828 0s0.781-2.1 0-2.9c-0.781-0.7-2.047-0.7-2.828 0zm5.436-4.2-1.392 2.7c0.062 0 0.138 0.1 0.198 0.1
                0.69 0.7 1.068 1.6 1.149 2.5l3.006 0.5c0.022-1.9-0.644-3.7-2.033-5.1-0.288-0.3-0.607-0.5-0.928-0.7zm-9.723 9.7c0.2271 0.3
                0.4635 0.6 0.7513 0.9 1.3888 1.4 3.2177 2.1 5.0377 2l-0.442-3c-0.9 0-1.7854-0.4-2.4744-1.1-0.0602-0.1-0.0992-0.1-0.1547-0.2l-2.7179
                1.4zm9.369-2.4c-0.194 0.4-0.474 0.8-0.84 1.2-0.365 0.4-0.79 0.6-1.237 0.8l0.464 3.1c1.058-0.4 2.058-1 2.895-1.8s1.421-1.8 1.745-2.9l-3.027-0.4z" fill="#7f8c8d" />
            </g>
        </svg>
    </div>
</div>

<ng-container *ngIf="message">
    <p>{{ message }}</p>
</ng-container>

<ng-container *ngIf="bodyTemplate">
    <div class="content">
        <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>
</ng-container>
