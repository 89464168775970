<msc-common-aside #aside [title]="'words.add-trainer' | translate" (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate"
                (searchEvent)="search($event.value)"></msc-common-search>
            <div class="flex flex-col space-y-2">
                <div class="flex items-center space-x-4" *ngFor="let trainer of trainers"
                    (click)="addManager(trainer.accountId)">
                    <msc-common2-checkbox [value]="isManagerSelected(trainer.accountId)"
                        (changeEvent)="addManager(trainer.accountId)"></msc-common2-checkbox>

                    <div class="flex items-center space-x-2">
                        <msc-common-avatar [label]="trainer?.name"
                            [pictureURL]="trainer?.picture ? (trainer?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="flex flex-col">
                            <p>{{ trainer.name }}</p>
                            <small>{{ trainer.headline | truncate:30}}</small>
                        </div>
                    </div>
                </div>
            </div>

            <msc-load-more [loading]="!loaders.ready" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>
    </ng-template>
</msc-common-aside>