import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CompanyCertificatesProvider, CompanyExperience } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs';
import { CommonSelectDatum } from '@common2/components/select/select.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-certificates',
    templateUrl: './certificates.component.html',
    styleUrls: ['./certificates.component.scss'],
})
export class WatchCertificatesComponent implements OnInit {
    @ViewChild('modalAdd') modalAdd: CommonModalComponent;
    @ViewChild('modalDelete') modalDelete: CommonModalComponent;

    public experience: CompanyExperience;

    public emitedCertificates: any;
    public emitedTemplates: any;
    public emitedCreateTemplate: any;

    public templateItemDescription: string = '';
    public templateItemGroup: string = '';
    public templateItemTemplate: string = '';
    public templateItemCatalogue: string = '';
    public templateItemMinimumProgress: number;

    public availableTypes: any;
    public groups = [];
    public exportGroup: any;

    public checkInputs: boolean = false;

    public modalTitle: string = '';
    public newItemId: string = '';
    public newItemName: string = '';
    public newItemLabel: string = '';
    public selectedItemId: string = '';
    public newList: Array<any> = [];
    public newListDisplayed: Array<any> = [];
    public tableContent: any;
    public isLoading: boolean = true;
    public isLoadingTable: boolean = true;
    public hasError: boolean = false;
    public errorMsg: string = '';
    public currentDashboardId: string = '';
    public isEdit: boolean = false;
    public filters: any;
    public selectSelections: any = {
        template: {
            id: '',
            name: '',
        },
        group: {
            id: '',
            name: '',
        },
        catalog: [],
    };
    public selectedItem: Array<any> = [];
    public showDate: boolean = false;
    public initialCertificateDate: any = '';
    public currentEditTemplateId: any = -1;
    public currentDeleteTemplateId: number = -1;
    public currentExperienceId: string = '';
    public currentEditItem: any;

    constructor(
        private companyCertificatesProvider: CompanyCertificatesProvider,
        private storageService: StorageService,
        private cookieService: CookieService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.getTemplates();
    }

    public openModalAdd(): void {
        this.clearAllData();
        this.modalTitle = 'create';
        this.isEdit = false;
        console.log(this.selectSelections)
        this.modalAdd.onOpen();
        console.log(this.emitedTemplates.catalogs)
    }

    public getTemplates(): void {
        this.isLoadingTable = true;
        const params = {
            experienceId: this.getExperienceIdFromCurrentUrl()
        }
        this.companyCertificatesProvider
            .getTemplates(this.cookieService.get('current_company'), params)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    console.log(data)
                    this.emitedTemplates = data.data;
                    console.log(this.emitedTemplates);
                    this.isLoadingTable = false;
                    this.isLoading = false;
                },
                (err) => {
                    console.log(err);
                    this.hasError = true;
                    this.isLoadingTable = false;
                    this.isLoading = false;
                    this.errorMsg = err.error.error;
                }
            );
    }

    public resetFields(): void {
        this.newItemId = '';
        this.newItemName = '';
        this.newItemLabel = '';
        this.selectedItemId = '';
    }

    public openEdit(data: any): void {
        console.log(data)
        this.clearAllData();
        const templateItem = {
            name: data.xTemplateName,
            id: data.xTemplateId
        }
        const groupItem = {
            name: data.xGroupName,
            id: data.xGroupId
        }
        const catalogItem = JSON.parse(data.providerIds);
        this.isEdit = true; 
        this.isLoading = false;
        this.modalTitle = 'edit';
        console.log(data);
        this.templateItemDescription = data.description;
        this.selectSelections.group.id = data.xGroupId;
        this.selectSelections.template.id = data.xtemplateId;
        this.templateItemMinimumProgress = data.minScore;
        this.initialCertificateDate = data.startCertificateAt;
        this.currentEditTemplateId = data.id;
        this.currentExperienceId = data.experienceId;
        this.currentEditItem = data;

        this.onSelectType(templateItem, 'template');
        this.onSelectType(groupItem, 'group');
        data.dynamicData.forEach(element => {
            this.addNewItemToNewList(element);
        });
        catalogItem.forEach(catalogItem => {
            console.log(catalogItem)
            const item = {
                id: catalogItem,
                name: this.emitedTemplates.catalogs[this.emitedTemplates.catalogs.findIndex(item => item.id === catalogItem)].name,
                isSelect: false,
                isSelected: true,
            };
            this.emitedTemplates.catalogs.splice(this.emitedTemplates.catalogs.findIndex(item => item.id === catalogItem), 1);
            this.emitedTemplates.catalogs.push(item);
            this.onSet(true, item);
        });

        this.modalAdd.onOpen();
    }

    formatData(dateFormat: Date): String {
        const date = new Date(dateFormat);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    public createTemplate(): void {
        const data = {
            description: this.templateItemDescription,
            x_group_id: this.selectSelections.group.id,
            x_template_id: this.selectSelections.template.id,
            experience_id: this.getExperienceIdFromCurrentUrl(),
            provider_ids: this.selectedItem.map((item) => item.id),
            dynamic_data: this.newList,
            min_score: Number(this.templateItemMinimumProgress),
            start_certificate_at: this.initialCertificateDate,
        };

        this.companyCertificatesProvider
            .setTemplates(data)
            .pipe(take(1))
            .subscribe((data) => {
                this.toastService.onSuccess(
                    this.translateService.instant(data.message)
                );
                this.clearAllData();
                this.modalAdd.onClose();
                this.getTemplates();
            },(error) => {
                console.log(error.error.message)
                this.toastService.onError(
                    this.translateService.instant(error.error.message)
                )
            });
    }

    public onInputChange(): void {
        if (
            this.templateItemDescription != '' &&
            this.selectSelections.group.id != '' &&
            this.selectSelections.template.id != '' &&
            this.newList.length != 0 &&
            Number(this.templateItemMinimumProgress) > -1 &&
            Number(this.templateItemMinimumProgress) < 101 &&
            this.selectedItem.length > 0 &&
            this.initialCertificateDate != ''
        ) {
            this.checkInputs = true;
        } else {
            this.checkInputs = false;
        }
    }

    getIterativeCheck(parent: any, selected: any) {
        if (parent.children && parent.children.length) {
            parent.children = parent.children.map((child: any) => {
                if (parent.id === selected.id) {
                    child.isSelected = parent.isSelected;
                }
                return child;
            });
            return parent;
        } else {
            return parent;
        }
    }

    onSelectType(option, origin) {
        if (origin != 'localField') {
            this.selectSelections[origin] = option;
        } else {
            this.newItemId = option.value;
            this.newItemLabel = this.translateService.instant(option.name);
            this.selectedItemId = this.translateService.instant(option.name);
        }

        this.onInputChange();
    }

    onSelectGropu(datum: CommonSelectDatum<string>) {
        this.groups = this.groups.map((d) => {
            d.isSelected = d.id === datum.id;
            return d;
        });
        this.exportGroup = datum.value;
    }

    public getSelectedDisplayValues(filterKey: string): any {
        const filter = this.filters[filterKey];
        if (filterKey === 'languages') {
            if (!filter || !Array.isArray(filter)) {
                return [];
            }

            return filter
                .filter((item: any) => item.isSelected)
                .map((item: any) => item.value);
        } else {
            if (!filter || !Array.isArray(filter)) {
                return '';
            }

            const selectedValues = filter
                .filter((item: any) => item.isSelected)
                .map((item: any) => item.value);

            return selectedValues.join(', ');
        }
    }

    public addNewItemToNewList(editItem?: any): void {
        const item = {
            x_field: editItem ? editItem.xField : this.newItemName,
            local_field: editItem ? editItem.localField : this.newItemId,
            local_value: editItem ? editItem.localValue : this.newItemLabel,
            id: editItem ? editItem.id : '',
        };
        const displayItem = {
            xertifyField: editItem ? editItem.xField : this.newItemName,
            localField: editItem ? this.translateService.instant((`words.${editItem.localField}`).replace(/_/g, '-')) : this.selectedItemId,
            valueField: this.translateService.instant((`words.${editItem?.localValue}`).replace(/_/g, '-')) ? this.translateService.instant((`words.${editItem?.localValue}`).replace(/_/g, '-')) : editItem?.localValue,
        }
        this.newListDisplayed.push(displayItem);
        this.newList.push(item);
        this.resetFields();
        this.onInputChange();
    }

    public removeItemFromNewList(index: number): void {
        this.newList.splice(index, 1);
        this.newListDisplayed.splice(index, 1);
        this.onInputChange();
    }

    public verifyNewInputs(): boolean {
        if (this.newItemId != '' && this.newItemName != '') {
            return false;
        } else {
            return true;
        }
    }

    public onSet(value: any, datum: any): void {
        const item = {
            id: datum.id,
            name: datum.name,
        };
        if (value) {
            if (!this.selectedItem.some(existingItem => existingItem.id === item.id)) {
                this.selectedItem.push(item);
            }
        } else {
            const index = this.selectedItem.findIndex(existingItem => existingItem.id === item.id);
            if (index !== -1) {
                this.selectedItem.splice(index, 1);
            }
        }
        console.log(this.emitedTemplates.catalogs)
        datum.isSelected = value;
        this.onInputChange();
    }
        

    public validateScale(e: any) {
        const value = parseInt(e.target.value);
        if (value < 0) {
            e.target.value = 0;
            this.onInputChange();
        } else if (value > 100) {
            e.target.value = 100;
            this.onInputChange();
        }
    }

    public updateTemplate(): void {
        const data = {
            description: this.templateItemDescription,
            x_group_id: this.selectSelections.group.id,
            x_template_id: this.selectSelections.template.id,
            experience_id: this.currentExperienceId,
            provider_ids: this.selectedItem.map((item) => item.id),
            dynamic_data: this.newList,
            min_score: Number(this.templateItemMinimumProgress),
            start_certificate_at: this.initialCertificateDate,
        };
        console.log(data)

        this.companyCertificatesProvider
            .updateTemplate(data, this.currentEditTemplateId)
            .pipe(take(1))
            .subscribe((data) => {
                this.emitedCreateTemplate = data.data;
                this.toastService.onSuccess(
                    this.translateService.instant('toast.saved')
                );
                this.clearAllData();
                this.modalAdd.onClose();
                this.getTemplates();
                console.log(this.emitedCreateTemplate);
            },(error) => {
                console.log(error.error.message)
                this.toastService.onError(
                    this.translateService.instant(error.error.message)
                )
            });

    }

    public clearAllData(): void {
        this.newList = [];
        this.newListDisplayed = [];
        this.selectedItemId = '';
        this.templateItemDescription = '';
        this.selectSelections.group.id = '';
        this.selectSelections.template.id = '';
        this.selectSelections.group.name = '';
        this.selectSelections.template.name = '';
        this.selectSelections.catalog = [];
        this.templateItemMinimumProgress = undefined;
        this.initialCertificateDate = '';
        this.selectedItem = [];
        this.currentEditTemplateId = -1;
        this.emitedTemplates?.catalogs.forEach(element => {
            if('isSelected' in element) {
                element.isSelected = false;
            }
        });
        this.resetFields();
    }

    public deleteTemplate(): void {
        this.companyCertificatesProvider
        .deleteTemplate(this.currentDeleteTemplateId)
        .pipe(take(1))
        .subscribe((data) => {
            this.toastService.onSuccess(
                this.translateService.instant(data.message)
            );
            this.currentDeleteTemplateId = -1;
            this.modalDelete.onClose();
            this.getTemplates();
        },(error) => {
            console.log(error.error.message)
            this.toastService.onError(
                this.translateService.instant(error.error.message)
            )
        });
    }

    public openModalDelete(templateId: number): void {
        console.log(templateId);
        this.currentDeleteTemplateId = templateId;
        this.modalDelete.onOpen();
    } 

    public toggleTemplateStatus(id: number): void {
        this.companyCertificatesProvider
        .toggleTemplateStatus(id)
        .pipe(take(1))
        .subscribe((data) => {
            console.log(data)
            this.getTemplates();
            this.toastService.onSuccess(
                this.translateService.instant(data.message)
            );
        },(error) => {
            console.log(error.error.message)
            this.toastService.onError(
                this.translateService.instant(error.error.message)
            )
        });
    }

    public getExperienceIdFromCurrentUrl(): string {
        const url = window.location.href;
        const regex = /\/experiences\/(\d+)\//;
        const match = url.match(regex);

        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }
}
