<div class="slider" #container>
    <msc-button *ngIf="totalPages > 1 && currentPage !== 1" class="left-0" palette="secondary" type="fab"
        icon="icon-arrow-ios-left" (click)="currentPage != 1 && changePage(-1)"></msc-button>
    <div class="slider__list" [ngClass]="{'w-full': totalPages === 1}">
        <div class="slider__view" [ngStyle]="{'width': overflowWidth, 'left': pagePosition}">
            <div class="slider__element" *ngFor="let i of arr; let index = index" [ngStyle]="{'width': cardWidth}">
                <span>{{i}}</span>
            </div>
        </div>
    </div>
    <msc-button *ngIf="totalPages > 1 && currentPage !== totalPages" class="right-0" palette="secondary" type="fab"
        icon="icon-arrow-ios-right" (click)="currentPage != totalPages && changePage(+1)"></msc-button>
</div>
