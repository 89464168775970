<div class="flex flex-col">
    <div class="slide-external-link__body grow">
        <p class="slide-external-link__paragraph">{{ 'extension.summary.intro' | translate }}</p>

        <div class="slide-external-link__card">
            <div class="card card--shadow card-catalogue-extension">
                <div class="card__header">
                    <div class="card__banner card__banner--between">
                        <div class="card__pinned">
                            <a><i class="icon icon-pin-2"></i></a>

                            <div class="card__avatar">
                                <msc-common-avatar class="tiny" [label]="me?.name" [pictureURL]="me?.picture ? (me?.picture + '?size=64') : null"></msc-common-avatar>
							</div>

							<div class="card__tags card__tags--banner">
								<msc-tag [color]="getColor()" [text]="extensionLink.type ? extensionLink.type : 'words.other' | translate"></msc-tag>
							</div>
						</div>
					</div>

                    <div class="card__image">
                        <div [style.backgroundImage]="'url(' + extensionLink.image + '?size=256)'"></div>
                    </div>
                </div>

                <div class="card__body">
                     <div class="card__time" *ngIf="duration">
                        <span class="card_time_text">{{ duration  | duration }}</span>
                    </div>

                    <p class="card__title">{{ extensionLink.title | truncate: 120 }}</p>
                </div>

                <div class="card__footer">
                    <div class="card__actions">
                        <div class="flex align-center gap-2">
                            <msc-button class="msc-button--primary" palette="danger" icon="icon-external-link" type="fab"></msc-button>
                            <msc-button class="msc-button--outline msc-button--tiny" icon="icon-checkmark-circle-2">{{ 'words.i-finished' | translate }}</msc-button>
                        </div>

                        <div class="card__widgets">
                            <a><i class="icon icon-edit"></i></a>
                            <a><i class="icon icon-trash"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-auto flex justify-end">
        <msc-button class="msc-button--primary" palette="secondary" [loading]="loading" (click)="nextPanel()" [disabled]="loading">
            {{ 'words.next' | translate }}
        </msc-button>
    </div>
</div>
