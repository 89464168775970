<div class="showroom-container">
    <div class="demo">
        <div class="btn-type">
            <div class="type-name">
                Primary button
            </div>
            <div class="type-content">
                <div class="content-row">
                    <p>Display</p>
                    <msc-button class="msc-button--primary" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--primary" iconSecondary="icon-bookmark" palette="secondary" loading="true">Button</msc-button>
                    <msc-button class="msc-button--primary" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--primary" iconSecondary="icon-bookmark" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--primary" iconSecondary="icon-bookmark" palette="secondary"></msc-button>
                    <msc-button class="msc-button--primary" type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Disabled</p>
                    <msc-button class="msc-button--primary" palette="secondary" disabled="true">Button</msc-button>
                    <msc-button class="msc-button--primary" iconSecondary="icon-bookmark" palette="secondary" disabled="true">Button</msc-button>
                </div>
            </div>
        </div>
        <div class="btn-type">
            <div class="type-name">
                Secondary button
            </div>
            <div class="type-content">
                <div class="content-row">
                    <p>Display</p>
                    <msc-button class="msc-button--secondary" palette="success">Button</msc-button>
                    <msc-button class="msc-button--secondary" iconSecondary="icon-bookmark" palette="secondary" loading="true">Button</msc-button>
                    <msc-button class="msc-button--secondary" icon="icon-bookmark" palette="error">Button</msc-button>
                    <msc-button class="msc-button--secondary" iconSecondary="icon-bookmark" icon="icon-bookmark" palette="danger">Button</msc-button>
                    <msc-button class="msc-button--secondary" iconSecondary="icon-bookmark" palette="secondary"></msc-button>
                    <msc-button class="msc-button--secondary" type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Disabled</p>
                    <msc-button class="msc-button--secondary" palette="secondary" disabled="true">Button</msc-button>
                    <msc-button class="msc-button--secondary" iconSecondary="icon-bookmark" palette="secondary" disabled="true">Button</msc-button>
                </div>
            </div>
        </div>
        <div class="btn-type">
            <div class="type-name">
                Outline button
            </div>
            <div class="type-content">
                <div class="content-row">
                    <p>Display</p>
                    <msc-button class="msc-button--outline" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline" iconSecondary="icon-bookmark" palette="secondary" loading="true">Button</msc-button>
                    <msc-button class="msc-button--outline" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline" iconSecondary="icon-bookmark" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline" iconSecondary="icon-bookmark" palette="secondary"></msc-button>
                    <msc-button class="msc-button--outline" type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Disabled</p>
                    <msc-button class="msc-button--outline" palette="secondary" disabled="true">Button</msc-button>
                    <msc-button class="msc-button--outline" iconSecondary="icon-bookmark" palette="secondary" disabled="true">Button</msc-button>
                </div>
            </div>
        </div>
        <div class="btn-type">
            <div class="type-name">
                Neutral button
            </div>
            <div class="type-content">
                <div class="content-row">
                    <p>Display</p>
                    <msc-button palette="secondary">Button</msc-button>
                    <msc-button iconSecondary="icon-bookmark" palette="secondary" loading="true">Button</msc-button>
                    <msc-button icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button iconSecondary="icon-bookmark" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button iconSecondary="icon-bookmark" palette="secondary"></msc-button>
                    <msc-button type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Disabled</p>
                    <msc-button palette="secondary" disabled="true">Button</msc-button>
                    <msc-button iconSecondary="icon-bookmark" palette="secondary" disabled="true">Button</msc-button>
                </div>
            </div>
        </div>

        
      
        
      
        <!-- <div class="btn-type">
            <div class="type-name">
                Flat button (Ripple)
            </div>
            <div class="type-content">
                <div class="content-row">
                    <p>Display</p>
                    <msc-button class="msc-button--primary msc-button--ripple">Basic</msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" icon="icon-bookmark" palette="primary">Primary</msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" icon="icon-bookmark" palette="secondary">Secondary
                    </msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" icon="icon-bookmark" palette="primary"></msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Disabled</p>
                    <msc-button class="msc-button--primary msc-button--ripple" disabled="true">Basic</msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" icon="icon-bookmark" palette="primary" disabled="true">Primary</msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" icon="icon-bookmark" palette="secondary" disabled="true">
                        Secondary</msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" icon="icon-bookmark" palette="primary" disabled="true"></msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" type="fab" icon="icon-bookmark" palette="secondary" disabled="true"></msc-button>
                </div>
            </div>
        </div> -->
        <!-- <div class="btn-type">
            <div class="type-name">
                Fab button (Ripple)
            </div>
            <div class="type-content">
                <div class="content-row">
                    <p>Display</p>
                    <msc-button class="msc-button msc-button--ripple" type="fab" icon="icon-trash-1" palette="error"></msc-button>
                    <msc-button class="msc-button--primary msc-button--ripple" type="fab" icon="icon-bell" palette="primary"></msc-button>
                    <msc-button class="msc-button--raised msc-button--ripple" type="fab" icon="icon-heart-1" palette="secondary"></msc-button>
                    <msc-button class="msc-button--outline msc-button--ripple" type="fab" icon="icon-bookmark" palette="primary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Disabled</p>
                    <msc-button disabled="true" class="msc-button msc-button--ripple" type="fab" icon="icon-trash-1" palette="error"></msc-button>
                    <msc-button disabled="true" class="msc-button--primary msc-button--ripple" type="fab" icon="icon-bell" palette="primary"></msc-button>
                    <msc-button disabled="true" class="msc-button--raised msc-button--ripple" type="fab" icon="icon-heart-1" palette="secondary"></msc-button>
                    <msc-button disabled="true" class="msc-button--outline msc-button--ripple" type="fab" icon="icon-bookmark" palette="primary"></msc-button>
                </div>
            </div>
        </div> -->
        <div class="w-full"></div>
        <div class="btn-type">
            <div class="type-name">
                Sizing
            </div>
            <div class="type-content">
                <div class="content-row">
                    <p>Small</p>
                    <msc-button class="msc-button--small">Button</msc-button>
                    <msc-button class="msc-button--primary msc-button--small" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--secondary msc-button--small" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline msc-button--small" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline msc-button--small" icon="icon-bookmark" palette="secondary"></msc-button>
                    <msc-button class="msc-button--primary msc-button--small" type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Medium</p>
                    <msc-button class="msc-button--medium">Button</msc-button>
                    <msc-button class="msc-button--primary msc-button--medium" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--secondary msc-button--medium" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline msc-button--medium" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline msc-button--medium" icon="icon-bookmark" palette="secondary"></msc-button>
                    <msc-button class="msc-button--primary msc-button--medium" type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
                <div class="content-row">
                    <p>Normal</p>
                    <msc-button>Button</msc-button>
                    <msc-button class="msc-button--primary" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--secondary" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline" icon="icon-bookmark" palette="secondary">Button</msc-button>
                    <msc-button class="msc-button--outline" icon="icon-bookmark" palette="secondary"></msc-button>
                    <msc-button class="msc-button--primary" type="fab" icon="icon-bookmark" palette="secondary"></msc-button>
                </div>
            </div>
        </div>
    </div>
</div>