<ng-container *ngIf="data && data.length; else noneTemplate">
    <div>
        <ng-container *ngFor="let datum of data | slice:0:1">
            <span class="text">{{ datum }}</span>
        </ng-container>
        <ng-container *ngIf="data.length > 1">
            <div class="counter" [tooltip]="tooltipMessage">
                <span>&#43;</span>
                <span>{{ data.length - 1 }}</span>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #noneTemplate>
    <span>N/A</span>
</ng-template>
