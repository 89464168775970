<div class="aside__form">
    <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

    <ul class="select__user">
        <li *ngFor="let member of data.availableMembers">
            <div class="wrapper">
                <msc-common-avatar [label]="member.name" [pictureURL]="member.picture ? (member.picture + '?size=128') : null"></msc-common-avatar>
                <p>{{ member.name }}</p>
            </div>
            <msc-common2-checkbox [value]="isMemberSelected(member)" [isDisabled]="data.invite_all_accounts" (changeEvent)="updateMember(member)"></msc-common2-checkbox>
        </li>
    </ul>

    <msc-load-more [loading]="loading" [pagination]="data.pagination" (onLoad)="loadMore()"></msc-load-more>
</div>
