import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, tap, takeUntil, repeatWhen, finalize } from 'rxjs/operators';

import { CompanyProvider, CompanySenderProvider, ApiCompanySenderItem } from '@lighty';
import { StorageService, TranslateService, ExternalAppsService } from '@services';

import { ComponentSmartSimpleModel } from '@models2/component-smart-simple.model';

import { UiCompanySenderItem, getRemappedCompanySenderApiToUi } from '../mail-item/mail-item.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

const CUSTOM_SENDER_DETAILS_UI_KEYS: string[] = ['email', 'dkim'];

@Component({
    selector: 'msc-organize-experience-watch-mail-details',
    templateUrl: './mail-details.component.html',
    styleUrls: ['./mail-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchMailDetailsComponent extends ComponentSmartSimpleModel<any> {
    public sender$: Observable<any>;
    public isLoadingValidate: boolean = false;
    public isContinued: boolean = false;

    constructor(
        protected readonly ref: ChangeDetectorRef,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly renderer: Renderer2,
        protected readonly companyProvider: CompanyProvider,
        protected readonly companySenderProvider: CompanySenderProvider,
        protected readonly storageService: StorageService,
        protected readonly toastService: CommonToastService,
        protected readonly translateService: TranslateService,
        protected readonly externalAppsService: ExternalAppsService,
    ) {
        super();
    }

    /**
     * Sets the component observables
     */
    setObs(): void {
        this.sender$ = this.companySenderProvider
            .getSender(this.company.id, this.route.snapshot.params['senderId'])
            .pipe(
                repeatWhen(() => this.repeat$),
                map((d: any) => this.onMapGetSender(d)),
                tap((sender: any) => this.onTapGetSender(sender)),
                takeUntil(this.destroy$),
            );
    }

    /**
     *
     */
    getSenderUi(sender: any) {
        return CUSTOM_SENDER_DETAILS_UI_KEYS.reduce((acc, key) => ({
            ...acc,
            ...this.getSenderUiObj(key, sender)
        }), {});
    }

    /**
     *
     */
    getSenderUiObj(key: string, sender: any) {
        const isSet = this.getSenderUiIsSet(key, sender);
        const label = this.translateService.instant(`organize.experience.watch.mail.details.${key}.confirmation.${isSet ? 'yes' : 'no'}`);

        return { [key]: { isSet, label } };
    }

    /**
     *
     */
    getSenderUiIsSet(key: string, sender: any) {
        switch (key) {
            case 'email': return sender.isConfirmed;
            case 'dkim': return sender.dkimStatus;
        }
    }

    /**
     *
     */
    getSenderModal(sender: any) {
        return {
            title: this.translateService.instant('organize.experience.watch.mail.details.modal.title', { email: sender.email }),
            description: sender.experiences ?
                `<p>${this.translateService.instant('organize.experience.watch.mail.details.modal.description')}</p>
                <br>
                <ul>${(sender.experiences || []).map((exp: any) => `<li>&#10625;&nbsp;${exp.name}</li>`)}</ul>
            ` : null
        };
    }

    /**
     * Query init data
     */
    onQuery(): void {
        // leave it empty
    }

    /**
     * Resolves map functionality on getUrl
     */
    onMapGetSender(sender: ApiCompanySenderItem) {
        return {
            ...getRemappedCompanySenderApiToUi(sender, this.companyCurrentExperience),
            dkimHost: sender.dkimHost,
            dkimValue: sender.dkimValue,
            modal: this.getSenderModal(sender),
            ui: this.getSenderUi(sender),
        };
    }

    /**
     * Resolves tap functionality on getUrl
     */
    onTapGetSender(sender: any) {
        if (sender.isConfirmed && !sender.isConfigured) { return; }
        this.isContinued = sender.isConfirmed;
    }

    /**
     * Event handler for clipboarding
     */
    onCopy(element: any): void {
        element.select();
        document.execCommand('copy');
        element.setSelectionRange(0, 0);
    }

    /**
     * Event handler for step continue
     */
    onContinue(): void {
        this.isContinued = true;
    }

    /**
     * Event handler for sender delete
     */
    onDelete(modal: CommonModalComponent, sender: any): void {
        this.companySenderProvider
            .deleteSender(this.company.id, sender.id)
            .pipe(
                finalize(() => this.onDeleteFinally(modal)),
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: () => this.onDeleteSuccess(),
                error: (error: HttpErrorResponse) => this.onDeleteError(error),
            });
    }

    /**
     * Handler for success on onDelete
     */
    onDeleteSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.delete.success'));
        this.goToList();
    }

    /**
     * Handler for error on onDelete
     */
    onDeleteError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.delete.error', error.message));
    }

    /**
     * Handler for finally on onDelete
     */
    onDeleteFinally(modal: CommonModalComponent): void {
        modal.onClose();
        this.ref.detectChanges();
    }

    /**
     * Event handler for sender confirmation
     */
    onConfirm(sender: any): void {
        this.companySenderProvider
            .postSenderConfirm(this.company.id, sender.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onConfirmSuccess(),
                error: () => this.onConfirmError(),
            });
    }

    /**
     * Handler for success on onConfirm
     */
    onConfirmSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.mail-successfully-sent'));
    }

    /**
     * Handler for error on onConfirm
     */
    onConfirmError(): void {
        this.toastService.onError(this.translateService.instant('toast.error-occurred'));
    }

    /**
     * Event handler for sender validation
     */
    onValidate(sender: UiCompanySenderItem, type: string): void {
        this.isLoadingValidate = true;
        this.companySenderProvider
            .postSenderValidate(this.company.id, sender.id)
            .pipe(
                finalize(() => this.onValidateFinally()),
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: (response: ApiCompanySenderItem) => this.onValidateSuccess(response, type),
                error: (error: HttpErrorResponse) => this.onValidateError(error),
            });
    }

    /**
     * Handler for success on onValidate
     */
    onValidateSuccess(response: ApiCompanySenderItem, type: string): void {
        switch (true) { // TODO: maybe refactor this next iteration?
            case !response.isConfirmed && type === 'email': this.toastService.onWarning(this.translateService.instant('toast.custom-sender.validate.pending.email')); break;
            case !response.dkimStatus && type === 'dkim': this.toastService.onWarning(this.translateService.instant('toast.custom-sender.validate.pending.dkim')); break;
            default: this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.validate.success'));
        }
        this.repeat$.next();
    }

    /**
     * Handler for error on onValidate
     */
    onValidateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.validate.error', error.message));
    }

    /**
     * Handler for finally on onValidate
     */
    onValidateFinally(): void {
        this.isLoadingValidate = false;
        this.ref.detectChanges();
    }

    /**
     * Event handler for activate of url
     */
    onActivate(sender: UiCompanySenderItem): void {
        this.companySenderProvider
            .editExperience(this.company.id, this.companyCurrentExperience.id, { sender_id: sender.id })
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onActivateSuccess(sender),
                error: (error: HttpErrorResponse) => this.onActivateError(error),
            });
    }

    /**
     * Event handler for success on onActivate
     */
    onActivateSuccess(sender: UiCompanySenderItem): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.activate.success'));
        this.repeat$.next();
    }

    /**
     * Event handler for error on onActivate
     */
    onActivateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.activate.error', error.message));
    }

    /**
     * Event handler for sender deactivation / set inactive
     */
    onDeactivate(): void {
        this.companySenderProvider
            .editExperience(this.company.id, this.companyCurrentExperience.id, { sender_id: null })
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onDeactivateSuccess(),
                error: (error: HttpErrorResponse) => this.onDeactivateError(error),
            });
    }

    /**
     * Handler for success on onDeactivate
     */
    onDeactivateSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.deactivate.success'));
        this.repeat$.next();
    }

    /**
     * Handler for error on onDeactivate
     */
    onDeactivateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.deactivate.error', error.message));
    }

    /**
     * Resolves navigation to edit section
     */
    goToEdit(id: number): void {
        this.router.navigate(['../..', 'edit', id], { relativeTo: this.route });
    }

    /**
     * Resolves navigation to list section
     */
    goToList(): void {
        this.router.navigate(['../..', 'list'], { relativeTo: this.route });
    }
}
