import { Injectable } from '@angular/core';

import { Company, CompanyExperience } from '@lighty';
import { StorageService } from '@services';

import { Subject } from 'rxjs';

// DEPRECATED TODO: REPLACE
@Injectable()
export abstract class ComponentSmartSimpleModel<T> {
    protected storageService: StorageService;

    protected company: Company;
    protected companyCurrentExperience: CompanyExperience | any; // TODO
    protected me: any;
    protected repeat$: Subject<void> = new Subject<void>();
    protected destroy$: Subject<void> = new Subject<void>();

    protected abstract setObs(): void;
    protected abstract onQuery(): void;

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.companyCurrentExperience = this.storageService.get('currentExperience');
        this.me = this.storageService.get('me');
        this.setObs();
        this.onQuery();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }
}
