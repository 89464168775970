import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
//
import { LanguageProvider, ManageAttendanceProvider, ManageCalendarProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { DataHelper, HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'msc-manage-instance-agenda-aside-edit',
    templateUrl: './aside-edit.component.html',
    styleUrls: ['./aside-edit.component.scss'],
    providers: [DatePipe],
})

export class ManageInstanceAgendaAsideEditComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();

    private company: any;
    private instance: any;

    public event: any;
    public readOnly = false;
    public availableTrainers: any[] = [];
    public clonedEvent: any;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public dateRangePicker: boolean = false;
    public isSettingsVisible: boolean = false;
    public dates: any;
    public endDate: string;
    public endHour: string;
    public notify: boolean = false;
    public preview: any;
    public query: string = '';
    public readonly: boolean;
    public startDate: string;
    public startHour: string;
    public selectData: any = {
        languages: []
    }

    constructor(
        private route: ActivatedRoute,
        private attendanceProvider: ManageAttendanceProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private manageCalendarProvider: ManageCalendarProvider,
        private languageProvider: LanguageProvider,
        private datePipe: DatePipe,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.company = this.storageService.get('company');
        this.instance = this.storageService.get('content');
        this.clonedEvent = DataHelper.clone(this.event);

        this.startDate = this.datePipe.transform(new Date(this.clonedEvent.startAt), 'YYYY/MM/dd');
        this.endDate = this.datePipe.transform(new Date(this.clonedEvent.endAt), 'YYYY/MM/dd');
        this.startHour = this.datePipe.transform(new Date(this.clonedEvent.startAt), 'HH:mm');
        this.endHour = this.datePipe.transform(new Date(this.clonedEvent.endAt), 'HH:mm');

        this.readonly = this.company.permissions.companyLockSession && new Date().getTime() > new Date(this.clonedEvent.startAt).getTime();

        this.getLanguages();
    }

    onOpen(event, isReadOnly?) {
        this.readOnly = isReadOnly;
        this.event = event;
        this.init();
        this.aside.onOpen();
    }

    getLanguages() {
        this.languageProvider.get('content').subscribe(resp => {
            this.selectData.languages = this.getSelectData(resp);
        })
    }

    getSelectData(data) {
        return data.map(d => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: d.id === false
        }));
    }

    onSelectOption(option) {
        this.selectData.languages = this.selectData.languages.map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });

        this.onSelect(option.value);
    }

    ngOnDestroy(): void {
        //
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        this.formatingDates();
        let params: any = {
            context: this.instance.type,
            notify: this.notify
        };

        params = DataHelper.merge(params, this.clonedEvent);
        this.manageCalendarProvider.update(this.company.id, this.instance.id, this.event.id, params).subscribe((_) => {
            this.onSave.emit(this.clonedEvent);
        });
    }

    formatingDates(): void {
        const startAt = new Date(this.startDate);
        startAt.setHours(parseInt(this.startHour.split(':')[0]));
        startAt.setMinutes(parseInt(this.startHour.split(':')[1]));

        const endAt = new Date(this.endDate);
        endAt.setHours(parseInt(this.endHour.split(':')[0]));
        endAt.setMinutes(parseInt(this.endHour.split(':')[1]));
        // endAt.setHours(parseInt(this.endHour));

        this.clonedEvent.startAt = this.datePipe.transform(startAt, DATE_DEFAULT_FORMAT);
        this.clonedEvent.endAt = this.datePipe.transform(endAt, DATE_DEFAULT_FORMAT);
    }

    saveDates(): void {
        this.startDate = this.datePipe.transform(new Date(this.dates.start), DATE_DEFAULT_FORMAT);
        this.endDate = this.datePipe.transform(new Date(this.dates.end), DATE_DEFAULT_FORMAT);
    }

    onSelect(lang: any): void {
        this.clonedEvent.languageId = lang.id;
    }

    toggleSettings() {
        this.isSettingsVisible = !this.isSettingsVisible;
    }

    openDateRangePicker(): void {
        this.dateRangePicker = !this.dateRangePicker;
    }

    setEventLanguage(language: string): void {
        this.clonedEvent.lang = language;
    }

    searchTrainers(query?): void {
        this.query = query;
        const params: any = HttpHelper.cleanParams({
            type: 'trainers',
            q: this.query,
            event_id: this.clonedEvent.id
        });

        this.manageCalendarProvider.searchTrainers(params).subscribe((data) => {
            this.availableTrainers = data?.accounts || [];
        });
    }

    addTrainer(trainer: any): void {
        const params: any = {
            account_id: trainer.id,
            name: trainer.name
        };

        this.manageCalendarProvider.addTrainer(this.clonedEvent.id, params).subscribe((data) => {
            this.clonedEvent.trainers.push(data);
        });
    }

    deleteTrainer(trainer: any): void {
        const params: any = {
            name: trainer.account.name,
            account_id: trainer.account.id ? trainer.account.id : null
        };

        this.manageCalendarProvider.deleteTrainer(this.clonedEvent.id, params).subscribe(() => {
            const index = this.clonedEvent.trainers.findIndex((findedTrainer) => {
                return findedTrainer.account.id === trainer.account.id;
            });

            if (index !== -1) {
                this.clonedEvent.trainers.splice(index, 1);
            }
        });
    }

    loadPicture(file: File): void {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.preview = event.target.result;
        };
        reader.readAsDataURL(file);
    }

    load(event: any, type: string): void {
        if (event.type === 'finished') {
            if (type === 'resource') {
                event.value.companyId = this.company.id;
                this.manageCalendarProvider.uploadResource(this.event.id, this.event.eventSessionId, event.value.id, event.value).subscribe(() => {
                    this.toastService.onSuccess(this.translateService.instant('toast.saved'));
                });
            } else {
                this.clonedEvent.media = event.value;
            }
        }

        if (event.type === 'error') {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }
}
