import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class AdminGroupContentProvider {

    constructor(private http: HttpClient) { }

    getContents(group_id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/groups/${group_id}/contents`, { params });
    }

    getAvailableContents(group_id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/groups/${group_id}/contents/available`, { params });
    }

    addToGroup(group_id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/groups/${group_id}/contents`, params);
    }

    deleteFromGroup(group_id: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/groups/${group_id}/contents`, { params });
    }
}
