<ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'success'">
        <ng-container *ngIf="rows.length; else emptyTemplate">
            <msc-common-table [columns]="columns" [rows]="rows">
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-checkbox [isPartial]="thCheckboxIsPartial" [value]="thCheckboxIsSelected"
                        (changeEvent)="onModify($event)"></msc-common-th-checkbox>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span></span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span></span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)">
                    </msc-common-th-sort>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)">
                    </msc-common-th-sort>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span>{{ datum.label }}</span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span>{{ datum.label }}</span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)">
                    </msc-common-th-sort>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <span></span>
                </ng-template>

                <ng-template #tdTemplate let-datum="datum">
                    <msc-common2-checkbox type="tick" [value]="datum.isSelected"
                        (changeEvent)="onChange($event, datum)"></msc-common2-checkbox>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <i class="icon icon-{{datum.icon}} icon-table"></i>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <ng-container *ngIf="datum.isDraft; else isLiveTemplate">
                        <span class="td-status"
                            [tooltip]="'organize.marketplace.catalogue.table.td.status.draft' | translate"></span>
                    </ng-container>
                    <ng-template #isLiveTemplate>
                        <span class="td-status is-live"
                            [tooltip]="'organize.marketplace.catalogue.table.td.status.live' | translate"></span>
                    </ng-template>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <span class="td-title" [tooltip]="datum.title">{{ datum.title }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <span>{{ datum.typeLabel }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <span>{{ datum.languageLabel }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <msc-common-td-categories [data]="datum.category"></msc-common-td-categories>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <span>{{ datum.date }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <msc-common-td-menu [type]="datum.type" [isDraft]="datum.isDraft" (deleteEvent)="onDelete(datum.id)"
                        (changeEvent)="onChangeMode($event, datum.courseId)"></msc-common-td-menu>
                </ng-template>
            </msc-common-table>
        </ng-container>

        <ng-template #emptyTemplate>
            <ng-container *ngIf="!filtersApplied; else noFilterData">
                <msc-common-empty [message]="'organize.marketplace.catalogue.table.empty.title' | translate">
                    <ng-template>
                        <div class="flex flex-col items-center gap-4">
                            <msc-button class="msc-button--primary" palette="primary" icon="icon-plus-1"
                                (click)="onOpenModal()">
                                {{ 'organize.marketplace.catalogue.table.empty.btn.add' | translate }}
                            </msc-button>
                            <p>
                                <span>{{ 'organize.marketplace.catalogue.table.empty.description' | translate
                                    }}</span>&nbsp;
                                <a (click)="onGoToCreate()">{{ 'organize.marketplace.catalogue.table.empty.link' |
                                    translate }}</a>
                            </p>
                        </div>
                    </ng-template>
                </msc-common-empty>
            </ng-container>
            <ng-template #noFilterData>
                <msc-common-empty [message]="'organize.marketplace.catalogue.table.empty-filter.title' | translate">
                </msc-common-empty>
            </ng-template>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
        <msc-common-spinner></msc-common-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
        <!-- <msc-common-error>
            <ng-template></ng-template>
        </msc-common-error> -->
    </ng-container>
</ng-container>