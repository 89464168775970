<div class="card card--progress card--hover-effect" (click)="redirectSession()">
    <div class="card__header">
        <div class="card__banner card__banner--container flex between">
            <div class="card__banner--container__picture" [style.backgroundImage]="logo"></div>
            <msc-tag [text]="'words.template' | translate"></msc-tag>
        </div>

        <div class="card__tags" [ngClass]="{'card__tags--banner': !instance.nextEvent}">
            <msc-tag [type]="'date'"
                     [day]="instance.nextEvent | date: 'dd'"
                     [month]="instance.nextEvent | localizedDate: 'MMM'"
                     *ngIf="instance.nextEvent">
            </msc-tag>

            <!-- <msc-tag [text]="'words.template' | translate" *ngIf="!instance.provider?.logo && !company.experience?.displayPicture"></msc-tag> -->
        </div>

        <div class="card__image">
            <div *ngIf="instance.picture" [ngStyle]="{'background-image': 'url(' + instance.picture + '?size=512)'}"></div>
            <div *ngIf="!instance.picture" [ngStyle]="{'background-image': 'url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + instance.id % 7 + '.jpg)'}"></div>
        </div>
    </div>

    <div class="card__body">
        <div class="card__time" *ngIf="instance.totalDuration">
            <span class="card_time_text">{{ instance.totalDuration | duration }}</span>
        </div>

        <div class="card__states card__states--refused" *ngIf="!instance.isLocked && instance.state === 'refused'">
            <p>
                <i class="icon icon-slash"></i>
                {{ 'words.subscription-refused' | translate }}
                <span (click)="modalShowMessage.onOpen(); stopPropagation($event)">{{ 'words.see-message' | translate }} <i class="icon icon-arrow-right-1"></i></span>

            </p>
        </div>

        <div class="card__states card__states--pending bg-secondary-100" *ngIf="!instance.isLocked && instance.state === 'pending'">
            <p>
                <i class="icon icon-clock text-secondary-500"></i>
                {{ 'words.subscription-pending' | translate }}
            </p>
        </div>

        <div class="card__states card__states--pending" *ngIf="instance.isLocked === true">
            <p>
                <i class="icon icon-lock"></i>
                {{ 'instance.locked-course' | translate }}
            </p>
        </div>

        <div class="card__skills" *ngIf="instance.skills && instance.skills.length > 0">
            <div class="skill skill--level skill--no-hover" *ngFor="let skill of instance.skills | slice:0:1" [tooltip]="skill.name || skill.names">
                <div class="skill__content">
                    <div class="skill__level" *ngIf="skill.level > 0">
                        <span [ngClass]="{'active': skill.level > 0}"></span>
                        <span [ngClass]="{'active': skill.level > 1}"></span>
                        <span [ngClass]="{'active': skill.level > 2}"></span>
                    </div>

                    <p class="skill__text">{{ skill.name || skill.names | truncate: 10 }}</p>
                </div>
            </div>

            <div class="skill skill--more" *ngIf="instance.skills.length > 1">
                <div class="skill__content">
                    <div class="skill__text"></div>
                </div>
            </div>
        </div>

        <p class="card__title" [tooltip]="instance.title">
            <ng-container *ngIf="instance?.title?.length > 50; else titleTemplate">
                <span [tooltip]="instance?.title">{{ instance.title | truncate: 50 }}</span>
            </ng-container>
            <ng-template #titleTemplate>
                <span>{{ instance.title || ('words.untitled' | translate) }}</span>
            </ng-template>
        </p>

        <p class="card__desc leading-3" [innerHTML]="instance.description || ('words.undescribed-content' | translate) | truncate: 130"></p>
    </div>

    <div class="card__footer">
        <div class="card__actions">
            <div class="card__buttons flex space-x-2">
                <msc-button [tooltip]="'words.launch' | translate" class="msc-button--primary" palette="primary" type="fab" icon="icon-arrrow-left-1" *ngIf="!instance.isLocked && instance.state !== 'pending' && instance.state !== 'refused'" (click)="redirectPlayer(); stopPropagation($event);"></msc-button>

                <msc-button class="msc-button--primary" palette="primary" type="fab" icon="icon-search" [tooltip]="'words.details' | translate" (click)="goToSession(); stopPropagation($event);"></msc-button>
            </div>

            <div class="card__widgets" *ngIf="params?.isDeletable">
                <a [tooltip]="'words.unsubscribe' | translate" (click)="deleteCourse(); stopPropagation($event);" *ngIf="!instance.subscriptionOrigins?.isFromGroup">
                    <i class="icon icon-trash-2"></i>
                </a>

                <span class="cursor-not-allowed" [tooltip]="'tooltip.delete.community.not-allowed' | translate" (click)="stopPropagation($event)" *ngIf="instance.subscriptionOrigins?.isFromGroup">
                    <i class="icon icon-trash-2"></i>
                </span>
            </div>
        </div>

        <div class="card__progress">
            <msc-progress-bar [progress]="instance.progress" [color]="instance.progress !== 100 ? 'blue': ''"></msc-progress-bar>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalShowMessage [title]="'instance.modal.refused-message.title' | translate">
    <ng-template>
        <div class="avatar-contact modal__refused--manager">
            <msc-common-avatar [label]="instance.validation.name" [pictureURL]="instance.validation.picture ? (instance.validation.picture + '?size=64') : null"></msc-common-avatar>
            <div class="avatar__content">
                <p class="profile__name text-secondary-800">{{ instance.validation.name }}</p>
            </div>
        </div>

        <p>{{ instance.validation.comment }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="deleteCourse(true); modalShowMessage.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="modalShowMessage.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
