import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth } from './auth';
import { environment } from '@env/environment';
@Injectable()
export class AuthProvider {
    constructor(private http: HttpClient) { }
    login(credentials: any): Observable<Auth> {
        return this.http.post(`${environment.envVar.AUTH_URL}/api/idp/login`, credentials, { observe: 'response' }).pipe(
            map(response => {
                if (response.status == 204) {
                    throw new HttpErrorResponse({
                        error: 'No Content',
                        status: 400,
                        statusText: 'No Content'
                    });
                }
                return new Auth(response.body);
            }))
    }
    logout(): Observable<any> {
        return this.http.post(`${environment.envVar.AUTH_URL}/api/idp/logout`, {});
    }
    refresh(refreshToken: string): Observable<Auth> {
        const params = {
            refresh_token: refreshToken
        };
        return this.http.post(`${environment.envVar.AUTH_URL}/api/idp/token`, params).pipe(
            map(data => new Auth(data))
        );
    }
    signup(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/auth/invitation`, params);
    }
    authenticateMethod(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.CLOUD_URL}/auth/method`, params);
    }
    getSso(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/enable-providers-by-company-uuid/${params}`);
    }
    validateSsoAccount(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/validate-account`, params);
    }
    /*
     * params require email
     */
    sendForgottenPasswordRequest(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/common/account/password/reset-link`, params);
    }
    /*
     * params require password
     */
    resetPassword(params: any): Observable<any> {
        return this.http.put(`${environment.envVar.AUTH_URL}/api/idp/passwords`, params);
    }
    /*
     */
    resetPasswordAdmin(params: any): Observable<any> {
        return this.http.put(`${environment.envVar.AUTH_URL}/api/idp/passwords/admin`, params);
    }
    /*
     * params require token, email, password
     */
    resetPasswordToken(params: any): Observable<any> {
        return this.http.put(`${environment.envVar.AUTH_URL}/api/idp/passwords/token`, params);
    }
    getSAML(params): Observable<any> {
        return this.http.get(`${environment.envVar.AUTH_URL}/api/idp/response?SAMLRequest=${encodeURIComponent(params.SAMLRequest)}${params.RelayState ? `&RelayState=${encodeURIComponent(params.RelayState)}` : ''}`, params);
    }
    public validateCustomUrlSso(params): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/enable-providers-check-url`, params);
    }
}
