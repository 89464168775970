<div class="slide__choices">
    <msc-common2-checkbox
        [value]="data.roles.camp_learner"
        [label]="'words.learn' | translate"
        (changeEvent)="data.roles.camp_learner = !data.roles.camp_learner; update()">
    </msc-common2-checkbox>

    <msc-common2-checkbox
        [value]="data.roles.camp_administrator"
        [label]="'words.organise' | translate"
        (changeEvent)="data.roles.camp_administrator = !data.roles.camp_administrator; update()">
    </msc-common2-checkbox>

    <ng-container *ngIf="data.roles.camp_administrator && camp.permissions.manageCampSettings">
        <msc-common2-checkbox
            [value]="data.permissions.manage_camp_settings"
            [label]="'words.job-settings-camp' | translate"
            (changeEvent)="data.permissions.manage_camp_settings = !data.permissions.manage_camp_settings; update()">
        </msc-common2-checkbox>
    </ng-container>

    <div class="slide__choices__email">
        <msc-common2-checkbox
            [value]="data.send_mail"
            [label]="'user.mail-notification' | translate"
            (changeEvent)="data.send_mail = !data.send_mail; update()">
        </msc-common2-checkbox>
    </div>
</div>
