import { Pipe, PipeTransform } from '@angular/core';

/**
 * Replace a empty value by a character
 */
@Pipe({
    name: 'empty'
})
export class EmptyPipe implements PipeTransform {

    transform(value: string|number, replace: string = '-'): any {
        return value || replace;
    }
}
