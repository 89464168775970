import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { CommonSelectDatum } from '@common2/components/select/select.component';
import { addClass, removeClass } from '@functions';
import { ExternalAppsService, StorageService } from '@services';
@Component({
    selector: 'msc-email-export-modal',
    templateUrl: './email-export-modal.component.html',
    providers: [TitleCasePipe]
})
export class EmailExportModal {
    @Input() title: string;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @ViewChild('emailsContainer') emailsContainer;
    @ViewChild('modal') modal;
    settings = { year: false };
    public active = false;
    public options: any = {};
    public exportName = '';
    private emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    data = {
        emails: []
    }
    invalid = false;
    exportYear;
    years = [];

    displays = {
        year: false
    }

    constructor(private storageService: StorageService, private externalAppsService: ExternalAppsService, private titleCasePipe: TitleCasePipe) {
        this.resetFields();
    }

    resetFields() {
        this.data.emails = [
            {
                value: this.storageService.get('me').email,
                isValid: true,
                readonly: true
            },
            {
                value: '',
                isValid: true,
            },
            {
                value: '',
                isValid: true,
            }
        ];
    }

    open(options?: any): void {
        this.modal.onOpen();
        this.options = options;
        this.settings.year = this.options.type === 'learner-engagement';
        if (this.settings.year) {
            const [start, stop] = [new Date(this.storageService.get('company').createdAt).getFullYear(), new Date().getFullYear()];
            this.years = Array.from({ length: (stop - start) + 1 }, (_, i) => start + i).reverse().map((year, index) => ({
                id: year,
                label: year,
                value: year,
                isSelected: index === 0,
            }));
            this.exportYear = this.years[0]?.value;
        }

        const date = new Date();
        this.exportName = `${date.toISOString().slice(0, 10)}${this.options.name ? ('-' + this.options.name.replaceAll(' ', '-')) : ''}-${this.options.type}`;
    }

    onSelectYear(datum: CommonSelectDatum<string>) {
        this.years = this.years.map((d) => {
            d.isSelected = d.id === datum.id;
            return d;
        });
        this.exportYear = datum.value;
    }

    close(): void {
        this.resetFields();
        this.modal.onClose();
        this.onClose.emit(true);
    }

    isValidEmail(emailField) {
        emailField.isValid = !emailField.value || this.emailRegex.test(emailField.value);
        this.data.emails.filter(field => !field.isValid).map(field => {
            if (!field.value) {
                field.isValid = true;
            }
        })
        this.invalid = !this.canSubmit();
    }

    canSubmit(): boolean {
        return !this.data.emails.some((email) => !email.isValid);
    }

    addEmailField() {
        let emptyFields;
        this.data.emails.map(email => {
            if (!email.value) {
                email.isValid = false;
                emptyFields = true;
            }
        });

        if (emptyFields) {
            return;
        }

        this.data.emails.push({
            value: '',
            isValid: true
        })

        setTimeout(() => {
            this.emailsContainer.nativeElement.scrollTop = this.emailsContainer.nativeElement.scrollHeight;
        }, 200);
    }

    removeEmailField(index) {
        this.data.emails.splice(index, 1);
    }

    save() {
        this.onSave.emit({ emails: this.data.emails.filter(emailField => emailField.value).map(emailField => emailField.value), year: this.exportYear, ...this.options });
        this.close();
    }
}
