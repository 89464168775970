import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pagination, ContentProvider, Company } from '@lighty';
import { EmitterService, LoaderService, StorageService, ParamsService } from '@services';
import { SubscriptionCollection } from '@classes';
import { DataHelper } from '@helpers';
import * as moment from 'moment';

@Component({
    selector: 'msc-learn-content-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})

export class LearnContentListComponent {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private me: any;
    private company: Company;
    public contents: any;
    public events: any;
    public contentsCollection: any;
    public pagination: Pagination;
    public paginationEvent: Pagination;
    public searching: boolean = false;
    public loading: boolean = false;
    public loaders: any = {
        calendar: false
    };
    public mode: string = 'list';
    public query: string;
    public filters: any = {};
    public cardParams: any;

    constructor(
        private route: ActivatedRoute,
        private contentProvider: ContentProvider,
        private storageService: StorageService,
        private loaderService: LoaderService,
        private emitterService: EmitterService,
        private paramsService: ParamsService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.contents = this.route.snapshot.data.contents.contents;
        this.pagination = this.route.snapshot.data.contents.pagination;

        this.cardParams = {
            isDeletable: true
        };

        this.getEvents();

        this.subscriptionCollection.subscribe = this.emitterService.get('search.contents').subscribe((query) => {
            if (typeof query === 'string') {
                this.contents = null;
                this.search(query);
                this.emitterService.set('search.contents', {});
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.newResourceAdded.subscribe(() => {
            this.search();
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    private getEvents(): void {
        this.contentProvider.getEventsSubscribed().subscribe((data) => {
            this.paginationEvent = data.pagination;
            this.contentsCollection = data.contents;

            this.groupEvents(data.contents);
        });
    }

    private groupEvents(contents: any): void {
        for (const content of contents) {
            content.eventDate = moment(content.event.startAt).format('YYYY-MM-DD');
        }
        this.events = DataHelper.groupBy(this.contentsCollection, 'eventDate', { key: 'date', value: 'items' });
        this.loaders.calendar = false;
    }

    search(query?: string): void {
        this.searching = true;
        this.pagination = null;
        this.loaderService.run();

        const params = this.paramsService.getCatalogue(this.filters, this.company.id, this.query || query, null, { mode: this.mode }, this.me);

        this.contentProvider.getContents(params).subscribe((data) => {
            this.pagination = data.pagination;
            this.contents = data.contents;
            this.searching = false;
        });
    }

    loadMore(): void {
        this.loading = true;
        this.loaderService.run();

        const params = this.paramsService.getCatalogue(this.filters, this.company.id, null, this.pagination, { mode: this.mode }, this.me);

        this.contentProvider.getContents(params).subscribe((data) => {
            this.pagination = data.pagination;
            this.contents = this.contents.concat(data.contents);
            this.loading = false;
        });
    }

    loadMoreEvents(): void {
        const params = {
            page: this.paginationEvent.currentPage + 1
        };
        this.loaders.calendar = true;

        this.contentProvider.getEventsSubscribed(params).subscribe((data) => {
            this.paginationEvent = data.pagination;
            this.contentsCollection = this.contentsCollection.concat(data.contents);

            this.groupEvents(data);
        });
    }

    deleteContent(index: number): void {
        this.contents.splice(index, 1);
        this.getEvents();
    }
}
