import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output } from "@angular/core";
import { fromEvent, filter, Subscription } from "rxjs";

@Directive({
    selector: '[clickOutside]'
})
export class CommonClickOutsideDirective implements AfterViewInit, OnDestroy {
    rootTreeArray = [];
    @Input('clickOutside') set state(rootTreeArray: ElementRef[]) {
        this.rootTreeArray = rootTreeArray;
    }
    @Output() onClickOutside = new EventEmitter<void>();

    documentClickSubscription: Subscription | undefined;

    constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.documentClickSubscription = fromEvent(this.document, 'click').pipe(filter((event) => {
                return !this.isInside(event.target as HTMLElement);
            })).subscribe(() => {
                this.onClickOutside.emit();
            })
        }, 100);
    }

    ngOnDestroy(): void {
        this.documentClickSubscription?.unsubscribe();
    }

    isInside(elementToCheck: HTMLElement) {
        const containersToCheckInto: Array<any> = [this.elementRef.nativeElement, ...this.rootTreeArray];
        return containersToCheckInto.some((element) => (elementToCheck === element || element.contains(elementToCheck)))
    }
}