import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class BillingProvider {

    constructor(private http: HttpClient) {}

    getProducts(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/billing/products`, {params}).pipe(
            map((data: any) => data.products)
        );
    }

    updateSubscription(slug: string, type: string, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/billing/subscription?billable_id=${slug}&billable_type=${type}`, params).pipe(
            map(data => data)
        );
    }

    getAddresses(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/billing/addresses`, {params}).pipe(
            map(data => data)
        );
    }

    createAddress(slug: string, type: string, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/billing/addresses?billable_id=${slug}&billable_type=${type}`, params).pipe(
            map(data => data)
        );
    }

    updateAddress(addressId: string, slug: string, type: string): Observable<any>  {
        return this.http.put(`${environment.envVar.API_URL}/billing/addresses/${addressId}?billable_id=${slug}&billable_type=${type}`, {}).pipe(
            map(data => data)
        );
    }

    deleteAddress(addressId: string, slug: string, type: string): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/billing/addresses/${addressId}?billable_id=${slug}&billable_type=${type}`, {}).pipe(
            map(data => data)
        );
    }

    getCards(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/billing/cards`, {params}).pipe(
            map(data => data)
        );
    }

    createCard(slug: string, type: string, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/billing/cards?billable_id=${slug}&billable_type=${type}`, params).pipe(
            map(data => data)
        );
    }

    updateCard(cardId: string, slug: string, type: string): Observable<any>  {
        return this.http.put(`${environment.envVar.API_URL}/billing/cards/${cardId}?billable_id=${slug}&billable_type=${type}`, {}).pipe(
            map(data => data)
        );
    }

    deleteCard(cardId: string, slug: string, type: string): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/billing/cards/${cardId}?billable_id=${slug}&billable_type=${type}`).pipe(
            map(data => data)
        );
    }

    getInvoices(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/billing/invoices`, {params}).pipe(
            map(data => data)
        );
    }

    getInvoice(invoiceId: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/billing/invoices/${invoiceId}`, {params}).pipe(
            map((data: any) => data)
        );
    }
}
