import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@services';
import { environment } from '@env/environment';
import { CompanyExperience } from '@lighty';

@Component({
    selector: 'msc-organize-experience-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.scss'],
})
export class OrganizeExperienceWatchComponent implements OnInit {
    public experience: CompanyExperience;
    public demo: boolean = environment.demo;
    public me: any;
    public company: any;
    public showCertificates: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService
    ) {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.experience = this.storageService.set(
            'currentExperience',
            this.route.snapshot.data.experience
        );
    }

    ngOnInit(): void {
        console.log(this.company)
    }

    experienceList(): void {
        this.router.navigate(['../../experiences'], { relativeTo: this.route });
    }
    
    displayCertificatesSection(): void {
        this.showCertificates = !this.showCertificates;
    }
}
