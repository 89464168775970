<div class="container-user__row row">
    <div class="container-user__back">
        <a class="link link-back link-back--transparent" (click)="userList()">
            <span>{{ 'words.users' | translate }}</span>

            <i class="icon icon-arrow-ios-up"></i>
        </a>
    </div>

    <div class="container-user__main row">
        <router-outlet></router-outlet>
    </div>
</div>
