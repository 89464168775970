import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UuidPipe } from '@common2/pipes/uuid.pipe';
import { SwiperComponent } from "swiper/angular";
import SwiperCore , {
    Navigation,
    Pagination,
    SwiperOptions,
} from 'swiper';

// install Swiper modules
SwiperCore.use([
    Navigation,
    Pagination,
]);

@Component({
    selector: 'msc-learn-catalogue-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [UuidPipe],
})
export class LearnCatalogueSliderComponent implements OnInit {
    @Input() contents: any[] = [];
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

    public id: string = this.uuidPipe.transform();
    public config: SwiperOptions;

    constructor(
        private readonly uuidPipe: UuidPipe,
    ) {}

    ngOnInit(): void {
        this.config = {
            spaceBetween: 24,
            slidesPerView: 1,
            navigation: {
                prevEl: `#prev-${this.id}`,
                nextEl: `#next-${this.id}`,
            },
            pagination: {
                clickable: true,
            },
            breakpoints: {
                '360': {
                    slidesPerView: 1,
                },
                '598': {
                    slidesPerView: 2,
                },
                '1022': {
                    slidesPerView: 3,
                },
                '1440': {
                    slidesPerView: 'auto',
                },
                '1700': {
                    slidesPerView: 'auto',
                }
            }
        }
    }
}
