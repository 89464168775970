<div class="modal modal-delete-subscription" [ngClass]="{'active': isActive}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content min-h-min" [autoClose]="isActive" (autoCloseChange)="close()" [activeClass]="'modal__content'">
        <div class="flex justify-between">
            <h3>
                <ng-container *ngIf="title; else noTitle">
                    {{ 'content.delete.subscription_new.title' | translate : {title: title} }}
                </ng-container>
                <ng-template #noTitle>
                    {{ 'content.delete.subscription.title' | translate }}
                </ng-template>
            </h3>
            <msc-button type="fab" icon="icon-close" (click)="close()"></msc-button>
        </div>

        <div>
            <p>{{ 'content.delete.subscription_new.description' | translate }}</p>
            <ng-container *ngIf="type === 'course_instance' || type === 'traject'">
                <p>{{ 'content.delete.subscription_new.message' | translate }}</p>
                <msc-textarea [(ngModel)]="data.message" id="commentDeleteCourse" cols="33" rows="5" [placeholder]="'placeholder.message' | translate"></msc-textarea>
            </ng-container>
        </div>

        <div class="flex justify-end space-x-2 mt-2">
            <msc-button (click)="close()">
                {{ 'words.cancel' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="secondary" (click)="save()">
                {{ 'words.unsubscribe' | translate }}
            </msc-button>
        </div>
    </div>
</div>
