export const environment = {
    production: true,
    demo: false,
    maintenance: false,
    name: {
        short: 'prod',
        long: 'prod'
    },
    integrations: {
        keySecret: 'SrkDJFI0xkGe1b5z',
        webinarKey: 'bn43aCfMeaDHOwy1FYXxwmYtqZT4s9BN',
        beamer: 'UYnzpRBS27242'
    },
    payments: {
        stripeKey: 'pk_live_EMBJgo9e9DzIq423CGZY86p6',
        weavr: '0Y5WSuJh+woBdvYm0JsACQ=='
    },
    analytics: {
        google: 'UA-112169318-2',
        mixpanel: '2de0b492c5dfab39cb2a95d19a249b43',
        fullstory: '4HEW5'
    },
    domain: 'myskillcamp.com',
    tokenName: 'token_msc',
    tokenSessionName: 'token_session',
    refreshTokenName: 'refresh_token',
    envVar: {
        AUTH_URL: "https://auth.myskillcamp.com",
        API_URL: "https://api.myskillcamp.com/api",
        REPORTING_URL: "https://api-reporting.myskillcamp.com/api/v2",
        STUDIO_URL: "https://studio.myskillcamp.com/",
        PLAYER_URL: "https://player.myskillcamp.com/",
        APP_URL: "https://app.myskillcamp.com/",
        CHAT_URL: "https://chat.myskillcamp.com",
        CLOUD_URL: "https://cloud.myskillcamp.com",
        CSS_URL: "https://cdn.myskillcamp.com"
    }
};
