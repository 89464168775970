
    <section class="head">
        <h2>
            {{ 'boclips.video-library' | translate }}
        </h2>
    </section>

    <div class="container-contents__main row flex ">
        <div class="col-12 col-xxs-12">
            <div class="container-contents__list row animate">
                <div class="card-container">
                    <content-card *ngFor="let content of contents" [content]="content" [saved]="true"></content-card>
                </div>
             </div>
             <div class="col-12 col-xxs-12 flex content-center justify-center mt-8">
             <msc-button class="mt-8" palette="primary" (click)="selectLoadMethod();" *ngIf="!loading && contents.length != 0" [disabled]="disabled">
                {{'words.load-more' | translate}}</msc-button>
             </div>
            <div class="container-contents__load-more col-12 col-xxs-12 flex content-center justify-center mt-12">
                <msc-load-more [loading]="loading"></msc-load-more>
                <msc-common-spinner *ngIf="loading"></msc-common-spinner>
            </div>
            <msc-empty-state *ngIf="!loading && contents.length == 0" [title]="'boclips.no-found' | translate"></msc-empty-state>
        </div>
        <div class="filter-boclips mt-3 mb-8 col-2 col-xxs-2">
            <h3>{{'words.filters' | translate}}
            </h3>
            <div>
                <msc-input type="text" [(ngModel)]="search" (ngModelChange)="verifyFilters()" [placeholder]="'common.search' | translate" [disabled]="loading"></msc-input>
            </div>
            <div>
                <div class="modal-select-segment">
                    <h6>{{ 'words.language' | translate }}</h6>
                    <msc-common-select type="multi" [data]="filters.languages" [disabled]="loading" style="overflow: hidden;">
                        <ng-template #buttonTemplate let-data="data">
                            <ng-container *ngIf="data.length; else emptyTemplate">
                                <span class="text-ellipsis overflow-hidden whitespace-nowrap">{{
                                    filter.displayValue }}</span>
                                <div class="selections-content text-ellipsis overflow-hidden whitespace-nowrap">
                                    <ng-container *ngFor="let datum of data">
                                        <span class="selections">{{ datum.label }}</span>&nbsp;
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #emptyTemplate>&nbsp;</ng-template>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <div class="option">
                                <msc-common2-checkbox type="tick" [value]="datum.isSelected" [label]="datum.label" (changeEvent)="onSet($event, datum, 'languages')"></msc-common2-checkbox>
                            </div>
                        </ng-template>
                    </msc-common-select>
                </div>
            </div>
            <div>
                <div class="modal-select-segment">
                    <h6>{{ 'boclips.subject' | translate }}</h6>
                    <msc-common-select type="multi" [data]="filters.subjects" [disabled]="loading" style="overflow: hidden;">
                        <ng-template #buttonTemplate let-data="data">
                            <ng-container *ngIf="data.length; else emptyTemplate">
                                <span class="text-ellipsis overflow-hidden whitespace-nowrap">{{
                                    filter.displayValue }}</span>
                                <div class="selections-content text-ellipsis overflow-hidden whitespace-nowrap">
                                    <ng-container *ngFor="let datum of data">
                                        <span class="selections">{{ datum.label }}</span>&nbsp;
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #emptyTemplate>&nbsp;</ng-template>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <div class="option">
                                <msc-common2-checkbox type="tick" [value]="datum.isSelected" [label]="datum.label" (changeEvent)="onSet($event, datum, 'subjects')"></msc-common2-checkbox>
                            </div>
                        </ng-template>
                    </msc-common-select>
                </div>
            </div>
            <div>
                <div class="modal-select-segment">
                    <h6>{{ 'boclips.type' | translate }}</h6>
                    <msc-common-select type="multi" [data]="filters.type" [disabled]="loading" style="overflow: hidden;">
                        <ng-template #buttonTemplate let-data="data">
                            <ng-container *ngIf="data.length; else emptyTemplate">
                                <span class="text-ellipsis overflow-hidden whitespace-nowrap">{{
                                    filter.displayValue }}</span>
                                <div class="selections-content text-ellipsis overflow-hidden whitespace-nowrap">
                                    <ng-container *ngFor="let datum of data">
                                        <span class="selections">{{ datum.label }}</span>&nbsp;
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #emptyTemplate>&nbsp;</ng-template>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <div class="option">
                                <msc-common2-checkbox type="tick" [value]="datum.isSelected" [label]="datum.label" (changeEvent)="onSet($event, datum, 'type')"></msc-common2-checkbox>
                            </div>
                        </ng-template>
                    </msc-common-select>
                </div>
            </div>
            <div class="w-full ">
                <msc-button palette="primary" (click)="filter(true)" [disabled]="loading" >
                    {{'common.filter' | translate}}</msc-button>
            </div>
        </div>
    </div>

