<div class="container-experience__row row">
    <div class="container-experience__back">
        <a
            class="link link-back link-back--transparent"
            (click)="experienceList()"
        >
            <span>{{ "words.experiences" | translate }}</span>
            <i class="icon icon-arrow-ios-up"></i>
        </a>
    </div>

    <header class="container-experience__header box">
        <div class="container-experience__header__title">
            <h2 class="container-experience__name">{{ experience.name }}</h2>
        </div>

        <div class="container-experience__header__actions">
            <div class="container-experience__counts">
                <p>
                    <i class="icon icon-person"></i>
                    {{ experience.usersCount || 0 }}
                </p>
            </div>
        </div>
    </header>

    <div class="container-experience__main row">
        <aside class="container-experience__aside col-xxs-12 col-md-3 col-3">
            <div class="navigations-aside">
                <ul class="navigations-aside-content">
                    <li
                        class="navigations-aside-content__item"
                        [routerLink]="['dashboard']"
                        [routerLinkActive]="'active'"
                    >
                        <p>
                            <i class="icon icon-dashboard"></i>
                            {{ "words.dashboard" | translate }}
                        </p>
                    </li>

                    <li
                        class="navigations-aside-content__item"
                        [routerLink]="['catalogue']"
                        [routerLinkActive]="'active'"
                    >
                        <p>
                            <i class="icon icon-catalogue"></i>
                            {{ "words.catalogue" | translate }}
                        </p>
                    </li>

                    <li
                        class="navigations-aside-content__item"
                        [routerLink]="['login']"
                        [routerLinkActive]="'active'"
                    >
                        <p>
                            <i class="icon icon-log-in"></i>
                            {{ "words.login" | translate }}
                        </p>
                    </li>

                    <li
                        class="navigations-aside-content__item"
                        [routerLink]="['mail']"
                        [routerLinkActive]="'active'"
                    >
                        <p>
                            <i class="icon icon-email"></i>
                            {{
                                "organize.experience.watch.mail.list.title"
                                    | translate
                            }}
                        </p>
                    </li>

                    <li
                        class="navigations-aside-content__item"
                        [routerLink]="['theme']"
                        [routerLinkActive]="'active'"
                    >
                        <p>
                            <i class="icon icon-image"></i>
                            {{ "theme.title" | translate }}
                        </p>
                    </li>

                    <li
                        class="navigations-aside-content__item"
                        [routerLink]="['url']"
                        [routerLinkActive]="'active'"
                    >
                        <p>
                            <i class="icon icon-link"></i>
                            {{ "url.title" | translate }}
                        </p>
                    </li>

                    <li
                        *ngIf="
                            me.email.includes('@griky.co') &&
                            company.permissions.companyProviderPowerBiDashboards
                        "
                        class="navigations-aside-content__item"
                        [routerLink]="['settings']"
                        [routerLinkActive]="'active'"
                    >
                        <p>
                            <i class="icon icon-settings-2"></i>
                            {{ "words.dashboard-settings" | translate }}
                        </p>
                    </li>

                    <li
                    *ngIf="
                            me.email.includes('@griky.co') &&
                            company.permissions.companyProviderXertify
                        "
                        class="navigations-aside-content__item"
                        [routerLink]="['certificates']"
                        [routerLinkActive]="'active'"
                        (click)="displayCertificatesSection()"
                    >
                        <p>
                            <i class="icon icon-star"></i>
                            {{ 'words.cerficates-title' | translate }}
                        </p>
                    </li>


                </ul>
            </div>
        </aside>

        <div
            class="container-experience__body col-xxs-12 col-md-9 col-9 no-gutter"
        >
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
