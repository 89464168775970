import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
//
import { TranslateService } from '@services';
//
import { CommonApiContentTypeIconMapping } from '@common2/common.constants';
import { OrganizeFormMarketplaceSettingsDatum } from '@modules/organize/components/form-marketplace-settings/organize-form-marketplace-settings.component';
import { OrganizeTableMarketplaceCatalogueDatum } from '@modules/organize/components/table-marketplace-catalogue/organize-table-marketplace-catalogue.component';
import { OrganizeTableMarketplaceContentDatum } from '@modules/organize/components/table-marketplace-content/organize-table-marketplace-content.component';
import { OrganizeTableMarketplaceCustomersDatum } from '@modules/organize/components/table-marketplace-customers/organize-table-marketplace-customers.component';
import { ApiMarketplaceGetCatalogueItem, ApiMarketplaceGetCustomerItem, ApiMarketplaceGetSettings } from '@modules/organize/organize.types';

@Injectable({ providedIn: 'root' })
export class OrganizeMarketplaceLibService {
    constructor(
        private readonly datePipe: DatePipe,
        private readonly translateService: TranslateService,
    ) { }

    /**
     * Get the ui data for table catalogue
     */
    getTableCatalogue(data: Array<ApiMarketplaceGetCatalogueItem>): Array<OrganizeTableMarketplaceCatalogueDatum> {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            category: datum.categories,
            courseId: datum.id,
            date: this.datePipe.transform(datum.updatedAt, 'dd MMM YY'),
            icon: CommonApiContentTypeIconMapping.get(datum.type),
            id: datum.collectionContentId,
            isDraft: datum.isDraft,
            isSelected: false,
            languageId: datum?.language?.id,
            languageLabel: datum?.language?.name,
            title: datum.title,
            type: datum.type,
            typeLabel: this.translateService.instant(`api.content-type.${datum.type}`),
        }));
    }

    /**
     * Get the ui data for table content
     */
    getTableContent(data: Array<ApiMarketplaceGetCatalogueItem>): Array<OrganizeTableMarketplaceContentDatum> {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            category: datum.categories,
            date: this.datePipe.transform(datum.updatedAt, 'dd MMM YY'),
            icon: CommonApiContentTypeIconMapping.get(datum.type),
            id: datum.id,
            isDraft: datum.isDraft,
            isSelected: false,
            languageId: datum?.language?.id,
            languageLabel: datum?.language?.name,
            title: datum.title,
            type: datum.type,
            typeLabel: this.translateService.instant(`api.content-type.${datum.type}`),
        }));
    }

    /**
     * Get the ui data for table customers
     */
    getTableCustomers(data: Array<ApiMarketplaceGetCustomerItem>): Array<OrganizeTableMarketplaceCustomersDatum> {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            company: datum.name,
            status: this.getTableCustomersStatus(datum),
        }));
    }

    /**
     * Get the status data for table customers
     */
    getTableCustomersStatus({ deploymentDoneAt, status }: ApiMarketplaceGetCustomerItem) {
        switch (true) {
            case status === 0:
                return {
                    color: '#F44436',
                    label: this.translateService.instant('organize.marketplace.customers.table.td.date.disabled'),
                    tooltip: this.translateService.instant('organize.marketplace.customers.tooltip.disabled'),
                };
            case status === 1 && !deploymentDoneAt:
                return {
                    color: '#D39718',
                    label: this.translateService.instant('organize.marketplace.customers.table.td.date.not-configured'),
                    tooltip: this.translateService.instant('organize.marketplace.customers.tooltip.not-configured'),
                };
            default:
                return {
                    color: '',
                    label: this.datePipe.transform(deploymentDoneAt, 'dd MMM YY')
                };
        }
    }

    /**
     * Get the ui data for form settings
     */
    getFormSettings(datum: ApiMarketplaceGetSettings): OrganizeFormMarketplaceSettingsDatum {
        return {
            mediaId: datum?.media?.id,
            mediaUrl: datum?.media?.pictureUrl,
            name: datum.key,
            url: datum.url,
        };
    }
}
