<div>
    <msc-common-tab [index]="currentTabIndex">
        <ng-template #tabControl>
            <span class="w-max">{{ 'group.your-groups' | translate }}</span>
        </ng-template>
        <ng-template #tabPanel>
            <ng-container [ngTemplateOutlet]="cardViewTemplate" [ngTemplateOutletContext]="{tab:'communities'}">
            </ng-container>
        </ng-template>

        <ng-template #tabControl>
            <span class="w-max">{{ 'group.your-free-groups' | translate }}</span>
        </ng-template>
        <ng-template #tabPanel>
            <ng-container [ngTemplateOutlet]="cardViewTemplate"
                [ngTemplateOutletContext]="{tab:'communities-available'}">
            </ng-container>
        </ng-template>
    </msc-common-tab>
</div>

<ng-template #cardViewTemplate let-tab="tab">
    <div class="card-container">
        <div class="group-card" *ngFor="let group of getCurrentData(tab)">
            <div class="card-picture" (click)="navigate(group)">
                <div class="card-picture__picture"
                    [ngStyle]="{'background-image': 'url(' + (group.background ? group.background?.pictureUrl + '?size=1024' : 'https://static.myskillcamp.com/images/images_random/empty-state-' + group.id % 7 + '.jpg') + ')'}">
                </div>
                <div class="card-header">
                    <msc-tag [color]="group.type === 'team' ? 'blue' : ''"
                        [text]="('group.type.' + group.type) | translate"></msc-tag>
                </div>
            </div>
            <div class="card-content" (click)="navigate(group)">
                <p class="card-content__title" *ngIf="group.name">
                    {{ group.name | truncate: 45 }}
                </p>

                <div class="card-content__avatar" *ngIf="group.usersPreview?.length > 0">
                    <ul class="avatar-group">
                        <li *ngFor="let user of group.usersPreview">
                            <a [tooltip]="user.name">
                                <msc-common-avatar class="tiny" [label]="user?.name"
                                    [pictureURL]="user?.picture ? (user?.picture + '?size=64') : null">
                                </msc-common-avatar>
                            </a>
                        </li>

                        <li *ngIf="group.membersCount && group.membersCount > 4">
                            <span class="avatar-group__text text-primary-500">+ {{ group.membersCount - 4
                                }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-footer">
                <div class="card-footer__widgets">
                    <div class="widget">
                        <span>{{ group.resourcesCount > 0 ? group.resourcesCount : 0 }}</span>
                        <i class="icon icon-attach-1"></i>
                    </div>
                    <div class="widget">
                        <span>{{ group.coursesCount > 0 ? group.coursesCount : 0 }}</span>
                        <i class="icon icon-content"></i>
                    </div>
                </div>
                <div class="card-footer__actions">
                    <msc-button class="msc-button--primary" palette="secondary" *ngIf="tab === 'communities'"
                        (click)="navigate(group)">
                        {{ 'words.enter' | translate }}
                    </msc-button>

                    <msc-button class="msc-button--primary" palette="secondary" *ngIf="tab === 'communities-available'"
                        (click)="subscribeToGroup(group)">
                        {{ 'words.subscribe' | translate }}
                    </msc-button>

                    <msc-button *ngIf="group.subscriptionType === 'free' && tab === 'communities'" type="fab"
                        (click)="openModalUnsubscribe(modalUnsubscribe, group)" icon="icon-trash-2"
                        [tooltip]="'words.unsubscribe' | translate"></msc-button>
                </div>
            </div>
        </div>
    </div>

    <msc-load-more [pagination]="pagination" [loading]="loading" (onLoad)="loadMore(tab)">
    </msc-load-more>
</ng-template>

<msc-learn-community-subscribe-modal *ngIf="displays.modals.subscribeCommunity" [community]="selectedCommunity"
    (onClose)="displayed('modals','subscribeCommunity')">
</msc-learn-community-subscribe-modal>

<msc-common-modal size="30vw" #modalUnsubscribe [title]="'group.leave-community' | translate">
    <ng-template>
        <p>{{ 'group.leave-community.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalUnsubscribe.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary"
                (click)="unsubscribeCommunity(); modalUnsubscribe.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
