import { Component, Input, OnInit, AfterViewInit, OnDestroy, HostBinding, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EmitterService, StorageService, TranslateService, ExternalAppsService, RedirectService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { UserProvider, Integration, IntegrationFactory, GroupProvider, Pagination, ExternalContentProvider, CompanyProvider } from '@lighty';
import { DataHelper, UrlHelper } from '@helpers';
import { environment } from '@env/environment';
import { SubscriptionCollection } from '@classes';
import { take } from 'rxjs';
import { ContentProvider } from './../../lighty/models/content/content.provider';


@Component({
    selector: 'msc-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() me: any;
    @Input() company: any;
    @Input() access: any;
    @Input() sidebar: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private decrypt: string = 'https://plus.google.com/_/favicon?domain_url=';
    private static: string = 'https://static.myskillcamp.com/images/integrations/';
    public integrations: Integration[];
    public currentJob: string = 'learn';
    public displays: any;
    public countRoles: number = 0;
    public demo: boolean = environment.demo;
    public animate: boolean = true;
    public icons: any = {
        learn: 'icon-student',
        create: 'icon-color-palette',
        manage: 'icon-mentor',
        organize: 'icon-calendar'
    };
    public extensionReady: boolean = false;
    public learningGroups: any[];
    public learningGroupsPagination: Pagination;
    public availableLearningGroups: any[];
    public availableLearningGroupsPagination: Pagination;
    public isMobileView: boolean = false;
    public hasProvidersEnabled: boolean = true;
    public hasOneMentorEnabled: boolean;
    public MENU: any;

    // new
    @HostBinding('class.collapsed') isCollapsed = false;
    public menu;
    public isOnboarding = false;
    public isScreenLessThan768: boolean;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.detectScreenSize();
    }

    constructor(
        private router: Router, private userProvider: UserProvider, private emitterService: EmitterService,
        private storageService: StorageService, private cookieService: CookieService,
        private translateService: TranslateService, private externalAppsService: ExternalAppsService, private integrationFactory: IntegrationFactory,
        private redirectService: RedirectService, public groupProvider: GroupProvider,
        private externalContentProvider: ExternalContentProvider,
        private contentProvider: ContentProvider,
        private companyProvider: CompanyProvider) {
            this.me = this.storageService.get('me');
            this.company = this.storageService.get('company');
            this.buildMenu();
         }

    ngOnInit(): void {
        this.subscriptionCollection.subscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.processJob();
            }
        });

        this.listener();
        this.processJob();
        this.getCountRoles();
        this.getIntegrations();
        this.getGroups();
        this.detectScreenSize();
        this.checkOneMentorAccess();
    }

    ngAfterViewInit(): void {
        const timeOut = setTimeout(() => {
            this.extensionReady = true;
            clearTimeout(timeOut);
        }, 1500);
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    getGroups(): void {
        const params = {
            byPage: 3
        };
        this.groupProvider.getLearningGroups(this.company.id, params).subscribe(data => {
            this.learningGroups = data.groups;
            this.learningGroupsPagination = data.pagination;
        });
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    selectJob(job: string): void {
        this.currentJob = job;
        this.displays.dropdown = false;
        this.redirect();
    }

    private redirect(): void {
        this.event(this.currentJob);
        if (this.currentJob === 'learn' || this.currentJob === 'organize' || this.currentJob === 'manage') {
            this.router.navigate([this.currentJob, this.company.slug]);
        } else {
            this.redirectService.navigate('studio', ['company', this.company.slug, 'home']);
        }
    }

    private event(type: string): void {
        let role = '';
        switch (type) {
            case 'learn':
                role = 'learner';
                break;
            case 'create':
                role = 'author';
                break;
            case 'manage':
                role = 'manager';
                break;
            case 'organize':
                role = 'administrator';
                break;
        }
    }

    private listener(): void {
        this.subscriptionCollection.subscribe = this.emitterService.refreshMe.subscribe(() => {
            this.userProvider.me().subscribe(() => {
                this.me = this.storageService.get('me');
            });
        });

        this.subscriptionCollection.subscribe = this.emitterService.openSidebar.subscribe(() => {
            this.displayed('sidebar');
        });
    }

    private getSegment(url: any): string {
        return url.split('/')[1];
    }

    private  processJob(): void {
        const segment = this.getSegment(this.router.url);
        switch (segment) {
            case 'learn':
            case 'manage':
            case 'organize':
                this.currentJob = this.storageService.setCache('currentJob', segment, null, true);
                break;
            default:
                this.currentJob = this.storageService.getCache('currentJob') || 'learn';
        }

        this.menu = this.MENU(this.me, this.company, this.access, this.sidebar);
        this.isOnboarding = this.menu.learn.every((menuItem) => menuItem.hidden);
    }

    private getCountRoles(): void {
        for (const role in this.access.roles) {
            if (this.access.roles[role] && role !== 'assign' && role !== 'companyOwner') {
                this.countRoles++;
            }
        }
    }

    private getIntegrations(): void {
        const integrations = this.sidebar.integrations.filter((integration) => {
            if (integration.url || (integration.type && integration.type.indexOf('learncube') > -1)) {
                return integration;
            }
        });

        const lti = integrations.filter((integration) => {
            return integration.type === 'lti';
        });
        const others = integrations.filter((integration) => {
            return integration.type !== 'lti' || !integration.type;
        });

        this.integrations = [...lti, ...others];
    }

    getLogo(integration: Integration): string {
        if (integration.picture) {
            return integration.picture + '?size=256';
        } else if (integration.url) {
            return this.decrypt + integration.url;
        }
        return this.static + integration.name + '-picto.svg';
    }

    link(integration: Integration): void {
        if (integration.type && integration.type === 'lti') {
            if (integration.lti.mode === 'blank') {
                this.buildLTIForm(integration);
            } else {
                this.emitterService.set('player-light.open', integration);
            }
        } else {
            UrlHelper.navigate(integration.url);
        }
    }

    private buildLTIForm(integration: Integration): void {
        const form = document.createElement('form');
        form.target = '_blank';
        form.method = 'POST';
        form.action = integration.url;
        for (const key in integration.lti.fields) {
            if (integration.lti.fields.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = DataHelper.changeCase(key);
                input.setAttribute('value', integration.lti.fields[key]);
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }

    onboarding(): void {
        this.router.navigate(['learn', this.company.slug, 'onboarding']);
    }

    // new
    toggle(el) {
        el.buttonHandle.nativeElement.blur();
        this.isCollapsed = !this.isCollapsed;
    }

    /*
    //check the screen size on resize to apply hidde to some menu options
    //using ng if in menu html
    */
   public detectScreenSize(): void {
        const screenWidth = window.innerWidth;
        const wasScreenLessThan768 = this.isScreenLessThan768;

        if (screenWidth < 768) {
            this.isScreenLessThan768 = true;
            this.isCollapsed = true; // Asegurarse de que esté colapsado en pantallas pequeñas
        } else {
            this.isScreenLessThan768 = false;
            this.isCollapsed = false; // Puede estar expandido en pantallas más grandes
        }

        if (wasScreenLessThan768 !== this.isScreenLessThan768) {
          if (this.isScreenLessThan768) {
            this.isMobileView = true;
          } else {
            this.isMobileView = false;
          }
        }
      }
    public openCoronaSiemens(): void {
        const url = 'https://siacademy4r.com/auth/saml2/login.php';
        this.externalContentProvider.storeUserToken(url)
        .subscribe((token:string)=>{
            UrlHelper.navigate(`${environment.envVar.CLOUD_URL}/authenticated-redirect?token=${token}`);
        })
    }

    public checkOneMentorAccess(): void {
        if(this.company.permissions.companyProviderOnementor){
            const params = {
                company_id: this.company.id
            }
            this.companyProvider.GetServicesOnDemand(params).pipe(take(1)).subscribe((onDemand) => {
                console.log(onDemand)
                if(onDemand.serviceProviders.length != 0){
                    this.companyProvider.CompanyUserMe(this.company.id).pipe(take(1)).subscribe((me) => {
                        console.log(me)
                        const paramsMe = {
                            company_id : this.company.id,
                            company_user_id: me.companyUser
                        }
                        this.companyProvider.GetServiceForLearnerOnDemandService(paramsMe).pipe(take(1)).subscribe((access) => {
                            console.log(access)
                            this.hasOneMentorEnabled = access[0].haveAccess;
                            this.buildMenu();
                            this.processJob();
                        });
                    })
                } else {
                    this.hasOneMentorEnabled = true;
                    this.buildMenu();
                    this.processJob();
                }
            })
        } else {
            this.hasOneMentorEnabled = false;
            this.buildMenu();
            this.processJob();
        }
    }

    public buildMenu(): void {
        this.MENU = (me, company, access, sidebar) => ({
            learn: [
                {
                    label: 'words.dashboard',
                    routerLink: ['/learn', company.slug, 'overview'],
                    icon: 'icon-dashboard',
                    hidden: !access.permissions.companyAccessPlatform,
                },
                {
                    label: 'words.my-training-courses',
                    routerLink: ['/learn', company.slug, 'contents'],
                    routerOptions: { exact: true },
                    icon: 'icon-content',
                    hidden: !access.permissions.companyAccessPlatform
                },
                {
                    label: 'words.catalogue',
                    routerLink: company.experience?.catalogueDisplay === 'skills' ? ['/learn', company.slug, 'catalogue', 'all'] : ['/learn', company.slug, 'catalogue'],
                    icon: 'icon-catalogue',
                    hidden: !(sidebar.settings.catalogue && access.permissions.companyAccessPlatform)
                },
                {
                    label: 'words.groups',
                    routerLink: ['/learn', company.slug, 'communities'],
                    icon: 'icon-people',
                    hidden: !(sidebar.settings.group && access.permissions.companyAccessPlatform)
                },
                {
                    label: 'words.bookmarks',
                    routerLink: ['/learn', company.slug, 'contents', 'saved'],
                    routerOptions: { exact: true },
                    icon: 'icon-bookmark',
                    hidden: !access.permissions.companyAccessPlatform
                },
                {
                    label: 'words.my-applications',
                    routerLink: ['/learn', company.slug, 'integrations'],
                    icon: 'icon-layers-1',
                    hidden: !access.permissions.companyAccessPlatform
                },
                {
                    label: 'Boclips',
                    routerLink: ['/learn', company.slug, 'boclips'],
                    icon: 'icon-layers-1',
                    hidden: !company.permissions.companyProviderBoclips
                },
                {
                    label: 'words.boots-your-profile',
                    routerLink: ['/learn', company.slug, 'onementor'],
                    icon: 'icon-link-1',
                    hidden: !this.hasOneMentorEnabled,
                    id: 'onementor'
                },
            ],
            manage: [
                {
                    label: 'words.my-team',
                    routerLink: ['/manage', company.slug, 'team', me.teamOwnerId],
                    icon: 'icon-people',
                    hidden: !me.teamOwnerId
                },
                {
                    label: 'words.contents',
                    routerLink: ['/manage', company.slug, 'contents'],
                    icon: 'icon-calendar'
                },
            ],
            organize: [
                {
                    label: 'words.camps',
                    routerLink: ['/organize', company.slug, 'camp'],
                    icon: 'icon-camp-1',
                    isMobile: true
                },
                {
                    label: 'words.users',
                    routerLink: ['/organize', company.slug, 'users'],
                    icon: 'icon-people',
                    hidden: !access.roles['companyOwner'],
                    isMobile: true
                },
                {
                    label: 'words.experiences',
                    routerLink: ['/organize', company.slug, 'experiences'],
                    icon: 'icon-image',
                    hidden: !access.roles['companyOwner'],
                    isMobile: false
                },
                {
                    label: 'words.integrations',
                    routerLink: ['/organize', company.slug, 'integrations'],
                    icon: 'icon-share',
                    hidden: !access.roles['companyOwner'],
                    isMobile: false
                },
                {
                    label: 'words.skills',
                    routerLink: ['/organize', company.slug, 'knowledge'],
                    icon: 'icon-bulb',
                    hidden: !access.roles['companyOwner'],
                    isMobile: false
                },
                {
                    label: 'words.stats',
                    routerLink: ['/organize', company.slug, 'statistics'],
                    icon: 'icon-pie-stats-1',
                    hidden: !access.roles['companyOwner'],
                    isMobile: true
                },
                {
                    label: 'words.company',
                    routerLink: ['/organize', company.slug, 'company'],
                    icon: 'icon-credit-card',
                    hidden: !access.roles['companyOwner'],
                    isMobile: false
                },
                {
                    label: 'organize.marketplace.title',
                    routerLink: ['/organize', company.slug, 'marketplace'],
                    icon: 'icon-shopping-cart',
                    hidden: !company.isCompanyProvider,
                    isNew: true,
                    isMobile: false
                },
                {
                    label: 'words.demand-services',
                    routerLink: ['/organize', company.slug, 'ondemand'],
                    icon: 'icon-cube',
                    hidden: !company.hasOnDemandServices,
                    isNew: true,
                    isMobile: false
                },
                {
                    label: 'words.attestations',
                    routerLink: ['/organize', company.slug, 'certificates'],
                    icon: 'icon-star',
                    hidden: !(me.scopes.isGrikyAccount && company.permissions.companyProviderXertify),
                    isNew: true,
                    isMobile: false
                },
            ]
        })
    }
}
