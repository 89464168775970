<section class="head">
    <h3>{{ 'words.groups' | translate }}</h3>
    <div class="toolbar">
        <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
        <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" (click)="modalCreate.onOpen()">
            {{ 'group.create.lets-create-a-group' | translate }}
        </msc-button>
    </div>
</section>

<section class="body">
    <div class="content">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async">
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.name | truncate: 30 }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common-select [data]="datum.columnVisibilityDropdownData" (selectEvent)="onSelectVisibilityOption(datum, $event)"></msc-common-select>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common-select [data]="datum.columnTypeDropdownData" (selectEvent)="onSelectSubscriptionOption(datum, $event)"></msc-common-select>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.usersCount }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.contentsCount }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common-select [isButton]="true" #select [data]="datum.columnActionsDropdownData" (selectEvent)="$event.preventDefault();">
                                <ng-template #buttonTemplate let-data="data">
                                    <span></span>
                                </ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <button class="wrapper option" (click)="datum.value.action(); select.onClose()">
                                        <i class="icon icon-{{ datum.value.icon }}"></i>
                                        <span>{{ datum.label }}</span>
                                    </button>
                                </ng-template>
                            </msc-common-select>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'common.empty' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <msc-common-spinner *ngIf="true"></msc-common-spinner>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="hasLoadMore">
        <div class="load-more">
            <msc-button class="msc-button--primary" palette="primary" [loading]="(isLoadingMore$ | async) === 'loading'" (click)="onLoad()">{{ 'words.load-more' | translate }}</msc-button>
        </div>
    </ng-container>

</section>

<msc-camp-aside-content
    #campContentAside
    [camp]="camp">
</msc-camp-aside-content>

<msc-camp-aside-resources
    #campResourcesAside
    [camp]="camp">
</msc-camp-aside-resources>

<msc-camp-aside-users
    #campUsersAside
    [camp]="camp">
</msc-camp-aside-users>

<msc-camp-aside-settings
    #campSettingsAside
    [camp]="camp">
</msc-camp-aside-settings>

<msc-common-modal size="30vw" #modalCreate [title]="'group.create.lets-create-a-group' | translate">
    <ng-template>
        <msc-input name="input" [placeholder]="'group.title' | translate" id="name" [(ngModel)]="data.name"></msc-input>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalCreate.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onCreateGroup(); modalCreate.onClose()">{{'words.create' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalDelete [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'group.delete.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalDelete.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onDeleteGroup(); modalDelete.onClose()">{{'words.delete' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
