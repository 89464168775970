import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    CompanyGuardCheck,
    CompanyResolver,
    CompanyRoleResolver,
    CompanyUserAccessManageResolver,
    CompanyUserAccessResolver,
    ConversationResolver,
    DisplayResolver,
    IntegrationResolver,
    SidebarResolver,
    UserCompaniesResolver,
    UserProfileResolver,
    UserResolver
} from './app.resolver';

import { AuthGuard, LoginGuard } from './guards';

import { AccountAccessComponent } from './pages/common/account/access/access.component';
import { AccountAccessCreateComponent } from './pages/common/account/access/create/create.component';
import { AccountAccessLearnComponent } from './pages/common/account/access/learn/learn.component';
import { AccountAccessManageComponent } from './pages/common/account/access/manage/manage.component';
import { AccountAccessManageInstanceComponent } from './pages/common/account/access/manage/instance/instance.component';
import { AccountAccessOrganizeComponent } from './pages/common/account/access/organize/organize.component';
import { AccountAccessSettingsComponent } from './pages/common/account/access/settings/settings.component';
import { AccountComponent } from './pages/common/account/account.component';
import { AccountProfileComponent } from './pages/common/account/profile/profile.component';
import { AccountSettingsComponent } from './pages/common/account/settings/settings.component';
import { ConversationComponent } from './pages/common/conversation/conversation.component';
import { ConversationMessageComponent } from './pages/common/conversation/message/message.component';
import { CorporateComponent } from './pages/common/corporate/corporate.component';
import { ExternalComponent } from './pages/external/external.component';
import { ForgottenPasswordComponent } from './pages/common/reset-password/forgotten/forgotten-password.component';
import { LoginComponent } from './pages/common/login/login.component';
import { MaintenanceComponent } from './pages/common/maintenance/maintenance.component';
import { NecromancerComponent } from './pages/necromancer/necromancer.component';
import { ResetPasswordComponent } from './pages/common/reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './pages/common/reset-password/form/reset-password-form.component';
import { ResetPasswordSuccessComponent } from './pages/common/reset-password/success/reset-password-success.component';
import { SignupComponent } from './pages/common/signup/signup.component';
import { IdpLoginComponent } from './pages/common/idp/idp-login.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard],
        data: { breadcrumb: { skip: true } },
        resolve: {
            display: DisplayResolver
        }
    },
    {
        path: 'sso/login/:id',
        component: LoginComponent,
        canActivate: [LoginGuard],
        data: { breadcrumb: { skip: true } },
        resolve: {
            display: DisplayResolver
        }
    },
    {
        path: 'idp/login',
        component: IdpLoginComponent,
        data: { breadcrumb: { skip: true } },
    },
    {
        path: 'password',
        data: { breadcrumb: { skip: true } },
        component: ResetPasswordComponent,
        resolve: {
            display: DisplayResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'reset',
                pathMatch: 'full'
            },
            {
                path: 'reset',
                component: ResetPasswordFormComponent
            },
            {
                path: 'success',
                component: ResetPasswordSuccessComponent
            },
            {
                path: 'forgotten',
                component: ForgottenPasswordComponent
            }
        ]
    },
    {
        path: 'login/:type',
        component: LoginComponent,
        data: { breadcrumb: { skip: true } },
        canActivate: [AuthGuard],
        resolve: {
            me: UserResolver,
            companies: UserCompaniesResolver,
            display: DisplayResolver
        }
    },
    {
        path: 'signup',
        component: SignupComponent,
        data: { breadcrumb: { skip: true } },
        resolve: {
            display: DisplayResolver
        }
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        data: { breadcrumb: { skip: true } },
    },
    {
        path: 'corporates/kyc',
        component: CorporateComponent,
        data: { breadcrumb: { skip: true } },
        resolve: {
            display: DisplayResolver
        }
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard, CompanyGuardCheck],
        data: { breadcrumb: { skip: true } },
        resolve: {
            me: UserResolver,
            sidebar: SidebarResolver,
            company: CompanyResolver,
            access: CompanyRoleResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'profile',
                pathMatch: 'full'
            },
            {
                path: 'profile',
                component: AccountProfileComponent,
                resolve: {
                    profile: UserProfileResolver
                }
            },
            {
                path: 'settings',
                component: AccountSettingsComponent,
                resolve: {
                    profile: UserProfileResolver
                }
            },
        ]
    },
    {
        path: 'account/:accountId',
        component: AccountComponent,
        canActivate: [AuthGuard, CompanyGuardCheck],
        data: { breadcrumb: { skip: true } },
        resolve: {
            me: UserResolver,
            sidebar: SidebarResolver,
            company: CompanyResolver,
            access: CompanyRoleResolver,
            user: UserProfileResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'profile',
                pathMatch: 'full'
            },
            {
                path: 'profile',
                component: AccountProfileComponent
            },
            {
                path: 'settings',
                component: AccountSettingsComponent,
                resolve: {
                    profile: UserProfileResolver
                }
            },
            {
                path: 'access',
                component: AccountAccessComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'learn',
                        pathMatch: 'full'
                    },
                    {
                        path: 'learn',
                        component: AccountAccessLearnComponent,
                        resolve: {
                            access: CompanyUserAccessResolver
                        },
                        data: {
                            type: 'learn'
                        }
                    },
                    {
                        path: 'manage',
                        component: AccountAccessManageComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'instances',
                                pathMatch: 'full'
                            },
                            {
                                path: 'instances',
                                component: AccountAccessManageInstanceComponent,
                                resolve: {
                                    access: CompanyUserAccessManageResolver
                                }
                            }
                        ]
                    },
                    {
                        path: 'organize',
                        component: AccountAccessOrganizeComponent,
                        resolve: {
                            access: CompanyUserAccessResolver
                        },
                        data: {
                            type: 'organize'
                        }
                    },
                    {
                        path: 'create',
                        component: AccountAccessCreateComponent,
                        resolve: {
                            access: CompanyUserAccessResolver
                        },
                        data: {
                            type: 'create'
                        }
                    },
                    {
                        path: 'settings',
                        component: AccountAccessSettingsComponent
                    }
                ]
            }
        ]
    },
    {
        path: 'conversation',
        component: ConversationComponent,
        canActivate: [AuthGuard, CompanyGuardCheck],
        data: { breadcrumb: { skip: true } },
        resolve: {
            me: UserResolver,
            sidebar: SidebarResolver,
            company: CompanyResolver,
            conversations: ConversationResolver,
            access: CompanyRoleResolver
        },
        children: [
            {
                path: ':conversationSlug',
                component: ConversationMessageComponent,
                resolve: {
                    conversation: ConversationResolver
                }
            }
        ]
    },
    {
        path: 'learn',
        resolve: {
            display: DisplayResolver
        },
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./pages/learn/learn.module').then(m => m.LearnModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'manage',
        resolve: {
            display: DisplayResolver
        },
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./pages/manage/manage.module').then(m => m.ManageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'organize',
        resolve: {
            display: DisplayResolver
        },
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./pages/organize/organize.module').then(m => m.OrganizeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'showroom',
        data: { breadcrumb: { skip: true } },
        loadChildren: () => import('./modules/showroom/showroom.module').then(m => m.ShowroomModule),
    },
    {
        path: 'oauth/:type/callback',
        data: { breadcrumb: { skip: true } },
        component: ExternalComponent,
        canActivate: [AuthGuard],

    },
    /** Route necromancer */
    {
        path: ':lang/login',
        component: NecromancerComponent,
        data: {
            type: 'login'
        }
    },
    {
        path: ':lang/conversation/:slug',
        component: NecromancerComponent,
        data: {
            type: 'conversation'
        }
    },
    {
        path: ':lang/user/:type',
        component: NecromancerComponent,
        data: {
            type: 'account'
        }
    },
    {
        path: ':lang/company/:slug/:type',
        data: {
            type: 'component'
        },
        children: [
            {
                path: '',
                component: NecromancerComponent
            },
            {
                path: ':misc',
                component: NecromancerComponent
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        CompanyResolver,
        CompanyRoleResolver,
        CompanyUserAccessManageResolver,
        CompanyUserAccessResolver,
        ConversationResolver,
        DisplayResolver,
        IntegrationResolver,
        SidebarResolver,
        UserCompaniesResolver,
        UserProfileResolver,
        UserResolver,
    ]
})

export class AppRoutingModule { }
