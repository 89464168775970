<ng-container *ngIf="sender$ | async as sender; else errorTemplate">
    <h3 class="title">
        <span>{{ 'organize.experience.watch.mail.edit.title' | translate }}</span>
    </h3>

    <div class="grouping">
        <form class="flex flex-col space-y-2 w-3/5" [formGroup]="form">
            <msc-input [label]="'organize.experience.watch.mail.create.sender-name.label' | translate" formControlName="name"></msc-input>
            <msc-input [label]="'organize.experience.watch.mail.create.sender-email.label' | translate" [placeholder]="sender.email" [disabled]="true"></msc-input>
            <p class="description margin-top-20">{{ 'organize.experience.watch.mail.edit.description' | translate }}</p>
        </form>
    </div>

    <div class="grouping margin-top-20">
        <div class="flex space-x-2">
            <msc-button class="msc-button--primary" (click)="goToList()" palette="secondary">{{'words.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" (click)="onEdit(sender)" palette="primary">{{'words.save.real' | translate}}</msc-button>
        </div>
    </div>
</ng-container>

<ng-template #errorTemplate>
    <!-- TODO -->
</ng-template>
