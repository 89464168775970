import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DatePipe } from '@angular/common';
//
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';
import { UuidPipe } from '@common2/pipes/uuid.pipe';

@Component({
    selector: 'msc-showroom-exhibit-table-page',
    templateUrl: './showroom-exhibit-table-page.component.html',
    styleUrls: ['./showroom-exhibit-table-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UuidPipe],
})

export class ShowroomExhibitTablePageComponent {
    public id: string = this.uuidPipe.transform();
    public columns = [];
    public rows = [];

    constructor(
        private readonly datePipe: DatePipe,
        private readonly uuidPipe: UuidPipe,
    ) { }

    get thCheckboxIsPartial(): boolean { return this.isThCheckboxPartial(); }
    get thCheckboxIsSelected(): boolean { return this.isThCheckboxSelected(); }

    ngOnInit(): void {
        this.setContent();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
        this.rows = this.getRows();
    }

    /**
     * Get the column list
     * Note: Order of columns is important
     */
    getColumns() {
        return [
            {
                key: 'isSelected',
                label: '',
                width: '10%',
            },
            {
                key: 'title',
                label: 'Title',
                width: '25%',
            },
            {
                key: 'category',
                label: 'Category',
                width: '25%',
            },
            {
                key: 'type',
                label: 'Type',
                width: '20%',
            },
            {
                key: 'date',
                label: 'Date',
                width: '20%',
            },
        ];
    }

    /**
     *
     */
    getRows() {
        return new Array(5000).fill(null).map((_) => ({
            isSelected: false,
            title: `Title-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            category: `Category-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            type: `Type-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            date: this.datePipe.transform(new Date(), 'dd MMM YY'),
        }));
    }

    /**
     * Resolves the check if thead checkbox cell is partial or not
     */
    isThCheckboxPartial(): boolean {
        return !this.rows.every((row, _i, rows) => rows[0].isSelected === row.isSelected);
    }

    /**
     * Resolves the check if thead checkbox is selected fully or not
     */
    isThCheckboxSelected(): boolean {
        return this.rows.every((row) => row.isSelected);
    }

    /**
     * Event handler for modify
     */
    onModify(value: boolean): void {
        this.rows.forEach((row) => row.isSelected = value);
    }

    /**
     * Event handler for change
     */
    onChange(value: boolean, datum: any): void {
        datum.isSelected = value;
    }

    /**
     * Event handler for sort
     */
    onSort(key: any, type: CommonThSortType): void {
        console.log(key, type);
    }
}
