import { Component } from '@angular/core';
import { ICON_LIST } from 'src/styles/typography/icon-list';

@Component({
    selector: 'msc-showroom-exhibit-icons-showroom',
    templateUrl: './showroom-exhibit-icons-page.component.html',
    styleUrls: ['./showroom-exhibit-icons-page.component.scss'],
})

export class ShowroomExhibitIconsPageComponent {
    iconList;
    filter;
    constructor() {
        this.iconList = ICON_LIST.map(icon => ({ key: icon }));
    }
}
