import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, ContentProvider, Pagination } from '@lighty';
import { EmitterService, LoaderService, ParamsService, StorageService } from '@services';
import { SubscriptionCollection } from '@classes';
import { DataHelper } from '@helpers';
import * as moment from 'moment';

@Component({
    selector: 'msc-learn-catalogue-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.scss']
})
export class LearnCatalogueWatchComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private me: any;
    public company: Company;
    public background: string = 'https://static.myskillcamp.com/lxp/images/hero-catalog.png';
    public contents: any;
    public pagination: Pagination;
    public events: any;
    public explore: any;
    public thematic: any;
    public level: any;
    public mode: string = 'list';
    public query: string;
    public activeFilters: boolean = false;
    public filters: any = {};
    public params: any;
    public searching: boolean = false;
    public isSearching: boolean = false;
    public loading: boolean = false;

    constructor(private route: ActivatedRoute, private router: Router, private storageService: StorageService,
                private emitterService: EmitterService, private contentProvider: ContentProvider, private loaderService: LoaderService,
                private paramsService: ParamsService) {}

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.background = this.company.experience && this.company.experience.cataloguePicture ? this.company.experience.cataloguePicture + '?size=raw' : this.background;

        if (this.route.snapshot.data.explore) {
            this.explore = this.route.snapshot.data.explore.catalogue;
            this.pagination = this.route.snapshot.data.explore.pagination;
        } else if (this.route.snapshot.data.thematic) {
            this.thematic = this.route.snapshot.data.thematic;
            this.pagination = this.route.snapshot.data.thematic.pagination;
        } else {
            this.level = this.route.snapshot.data.level;
            this.pagination = this.route.snapshot.data.level.pagination;
        }

        this.route.params.subscribe((params) => {
            this.params = params;
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('search.catalogue').subscribe((query) => {
            if (typeof query === 'string') {
                const timeOut = setTimeout(() => {
                    this.isSearching = true;
                    clearTimeout(timeOut);
                }, 200);
                this.search(query);
                this.emitterService.set('search.catalogue', {});
            }
        });

        this.getEvents();
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    private getEvents(): void {
        const params = {
            include: 'attendees',
            user_course: 0
        };

        this.contentProvider.getEvents(this.company.id, params).subscribe((data) => {
            this.events = this.groupEvents(data.closest, data.events);
        });
    }

    private groupEvents(closest: any, events: any): any {
        for (const item of closest) {
            item.eventDate = moment(item.start).format('YYYY-MM-DD');
        }
        const groupedData = DataHelper.groupBy(closest, 'eventDate', {key: 'date', value: 'events'});
        const mergedData = DataHelper.mergeGroupedCollection(groupedData, events, {key: 'date', value: 'events'});

        for (const event of mergedData) {
            event.items = event.events;
            delete event.events;
        }

        return mergedData;
    }

    search(query?: string): void {
        this.searching = true;
        this.loaderService.run();

        const params = this.paramsService.getCatalogue(this.filters, this.company.id, this.query || query, null, {browse: this.params.type}, this.me);

        if (this.params.level) {
            this.contentProvider.getCatalogueSkillLevel(this.params.thematicId, this.params.level, params).subscribe((data) => {
                this.level = data;
                this.pagination = data.pagination;

                this.searching = false;
            });
        } else if (this.params.thematicId) {
            this.contentProvider.getCatalogueSkill(this.params.thematicId, params).subscribe((data) => {
                this.thematic = data;
                this.pagination = data.pagination;

                this.searching = false;
            });
        } else {
            if (this.query || query) {
                this.contentProvider.getCatalogue(params).subscribe((data) => {
                    this.contents = data.catalogue;
                    this.pagination = data.pagination;

                    this.isSearching = (!!this.query || !!query) || this.activeFilters;
                    this.searching = false;
                });
            } else {
                this.contentProvider.getCatalogueSkills(params).subscribe((data) => {
                    this.explore = data.catalogue;
                    this.pagination = data.pagination;

                    this.isSearching = false;
                    this.searching = false;
                });
            }
        }
    }

    loadMore(): void {
        this.loading = true;
        this.loaderService.run();
        const scrubbedMe = {...this.me};
        delete scrubbedMe.learningLanguages;

        const params = this.paramsService.getCatalogue(this.filters, this.company.id, this.query, this.pagination, {browse: this.params.type}, scrubbedMe);

        if (this.params.level) {
            this.contentProvider.getCatalogueSkillLevel(this.params.thematicId, this.params.level, params).subscribe((data) => {
                this.level.contents = this.level.contents.concat(data.contents);
                this.pagination = data.pagination;

                this.loading = false;
            });
        } else if (this.params.thematicId) {
            this.contentProvider.getCatalogueSkill(this.params.thematicId, params).subscribe((data) => {
                this.thematic.contents = this.thematic.contents.concat(data.contents);
                this.pagination = data.pagination;

                this.loading = false;
            });
        } else {
            if (this.isSearching) {
                this.contentProvider.getCatalogue(params).subscribe((data) => {
                    this.contents = this.contents.concat(data.catalogue);
                    this.pagination = data.pagination;
                    this.loading = false;
                });
            } else {
                this.contentProvider.getCatalogueSkills(params).subscribe((data) => {
                    if (this.explore.length === 1 && this.explore[0].name === 'temp_name_for_empty_thematic') {
                        this.explore[0].contents = this.explore[0].contents.concat(data.catalogue[0].contents);
                    } else {
                        this.explore = this.explore.concat(data.catalogue);
                    }
                    this.pagination = data.pagination;
                    this.loading = false;
                });
            }
        }
    }

    navigate(type: string, enable: boolean): void {
        if (enable) {
            switch (type) {
                case 'catalogue':
                    this.router.navigate(['learn', this.company.slug, 'catalogue']);
                    break;
                case 'thematic':
                    this.router.navigate(['learn', this.company.slug, 'catalogue', this.params.type, 'thematic', this.params.thematicId]);
                    break;
                default:
                    this.router.navigate(['learn', this.company.slug, 'catalogue', type]);
            }
        }
    }

    getLevel(): string {
        switch (this.params.level) {
            case '0':
                return 'words.appropriate-for-all';
            case '1':
                return 'words.introductive';
            case '2':
                return 'words.intermediate';
            case '3':
                return 'words.confirmed';
        }
    }
}
