<div class="content-corrections content-corrections-details">
    <div class="container-sessions__back" (click)="back()">
        <a class="link link-back link-back--transparent" *ngIf="breadcrumbs && !breadcrumbs.isTrajectActivity">
            <span>{{ 'words.back' | translate }}</span>

            <i class="icon icon-arrow-ios-up"></i>
        </a>
    </div>

    <div class="content-corrections__header">
        <div class="box-session-correction">
            <div class="box-session-correction__header" *ngIf="breadcrumbs && !breadcrumbs.isTrajectActivity">
                <div class="box-session-correction__breadcrumb">
                    <span>
                        <i class="icon icon-cube color--orange"></i>
                    </span>

                    <p (click)="back()">{{ breadcrumbs?.courseReference  || ('words.untitled' | translate) }}</p>
                </div>

                <div class="box-session-correction__breadcrumb">
                    <span><i class="icon icon-arrow-ios-right"></i></span>
                    <p (click)="back()">{{ breadcrumbs?.sectionName  || ('words.untitled' | translate) }}</p>
                </div>

                <div class="box-session-correction__breadcrumb">
                    <span><i class="icon icon-arrow-ios-right"></i></span>
                    <p class="activity">{{ selectedCorrection?.title || ('words.untitled-activity' | translate) }}</p>
                </div>
            </div>

            <div class="box-session-correction__header" *ngIf="breadcrumbs && breadcrumbs.isTrajectActivity">
                <div class="box-session-correction__breadcrumb">
                    <span><i class="icon icon-cube"></i></span>v
                    <p (click)="back()">{{ breadcrumbs?.courseName  || ('words.untitled' | translate) }}</p>
                </div>

                <div class="box-session-correction__breadcrumb">
                    <span><i class="icon icon-arrow-ios-right"></i></span>
                    <p (click)="back()">{{ breadcrumbs?.sectionName  || ('words.untitled' | translate) }} - {{ selectedCorrection?.context  || ('words.untitled' | translate) }}</p>
                </div>
            </div>
        </div>

        <div class="content-corrections__filter">
            <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

            <msc-button class="msc-button--outline msc-button--tiny" [ngClass]="{'active' : unCorrectedOnly}" (click)="searchUncorrected()">{{ 'correction.pending' | translate }}</msc-button>
        </div>
    </div>

    <div class="content-corrections__content flex">
        <msc-empty-state class="empty" *ngIf="!loading && corrections.length === 0"
                         [title]="'words.corrections'"
                         [description]="'empty.correction'">
        </msc-empty-state>

        <div class="content-corrections__aside content-corrections__aside--simplebar" data-simplebar *ngIf="corrections.length > 0">
            <div class="box box-correction-user flex align-center" [ngClass]="{'box-correction-user--progress' : !correction.isCorrected, 'box-correction-user--success' : correction.isCorrected, 'active' : correction === selectedCorrection }" *ngFor="let correction of corrections" (click)="selectCorrection(correction)">
                <div class="box-correction-user__avatar">
                    <div class="box-correction-user__notification"></div>

                    <div class="avatar-user">
                        <msc-common-avatar class="tiny" [label]="correction.account?.name"
                            [pictureURL]="correction.account?.picture ? (correction.account?.picture + '?size=64') : null">
                        </msc-common-avatar>

                        <div class="avatar__content">
                            <p class="profile__name"> {{ correction.account.name }} </p>
                        </div>
                    </div>
                </div>

                <p class="box-correction-user__login">{{ correction.lastCorrectionDate | timeAgo | lowercase }}</p>

                <div class="box-correction-user__arrow">
                    <i class="icon icon-arrow-ios-right"></i>
                </div>
            </div>

            <msc-common-spinner *ngIf="loading" ></msc-common-spinner>

            <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>

        <div class="content-corrections__main" *ngIf="selectedCorrection">
            <div class="content-corrections__nav">
                <msc-button class="msc-button--outline msc-button--tiny" icon="icon-eye" (click)="correctionActivityAside.onOpen()" *ngIf="selectedCorrection.statement">
                    {{ 'correction.see-statement' | translate }}
                </msc-button>
            </div>

            <div class="content-corrections__details" [ngClass]="{'active': displays?.answer }">
                <div class="content-corrections__title flex between" (click)="displayed('answer')">
                    <h3>
                        <i class="icon icon-content"></i>
                        {{ 'correction.response' | translate }}
                    </h3>

                    <div class="content-corrections__toggle text-secondary-500">
                        <i class="icon icon-arrow-ios-down"></i>
                    </div>
                </div>

                <div class="content-corrections__section">
                    <div class="content-corrections__answer">
                        <div *ngIf="answer" [innerHTML]="answer | safe: 'html'"></div>
                        <msc-media-manager [medias]="selectedCorrection.media" [options]="{ 'canUploadMedia': false, 'canDownloadMedia': true, 'canDeleteMedia': false }"></msc-media-manager>
                    </div>
                </div>
            </div>

            <div class="content-corrections__details" [ngClass]="{'active': displays.correction}">
                <div class="content-corrections__title">
                    <h3>
                        <i class="icon icon-checkmark-circle-2"></i>
                        {{ 'words.corrections' | translate }}
                    </h3>

                    <msc-button class="msc-button--outline msc-button--tiny" icon="icon-edit" (click)="editCorrecting()">
                        {{ 'words.edit' | translate }}
                    </msc-button>

                    <div class="content-corrections__toggle text-secondary-500">
                        <i class="icon icon-arrow-ios-down" (click)="displayed('correction')"></i>
                    </div>
                </div>

                <div class="content-corrections__section">
                    <div class="content-corrections__input">
                        <div class="textarea__content">
                            <msc-textarea [(ngModel)]="correction.feedback" [placeholder]="('correction.corrector-answer' | translate)" [debounce]="800" *ngIf="allowCorrecting"></msc-textarea>

                            <div class="content-correction__feedback">
                                <h4 class="feedback__title" *ngIf="!allowCorrecting">{{ 'correction.corrector-answer' | translate }} :</h4>
                                <p class="feedback__content" *ngIf="!allowCorrecting">{{ correction.feedback || 'empty.edit' | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <msc-media-manager #mediaManagerTrainer [medias]="correction.correctionMedia" [options]="{ 'canUploadMedia': false, 'canDownloadMedia': true, 'canDeleteMedia': false }" (newMediaEvent)="addMedia($event)" (deleteMediaEvent)="deleteMedia($event)" class="list-correction__medias"></msc-media-manager>

                    <msc-common-spinner *ngIf="!allowCorrecting && loadings.updateCorrection"></msc-common-spinner>

                    <div class="content-corrections__evaluation">
                        <h4>
                            {{ 'words.rating' | translate }}
                            <span>{{ 'correction.rating.desc' | translate }}</span>
                        </h4>
                    </div>

                    <div class="content-corrections__input validate">
                        <input class="input-number" type="number" min="0" max="100" [(ngModel)]="correction.score" *ngIf="allowCorrecting" (ngModelChange)="spy()">
                        <msc-chart-pie [size]="'small'" [value]="correction.score" [valueColor]="'#8290FD'" *ngIf="!allowCorrecting"></msc-chart-pie>
                        <msc-button class="msc-button--primary" palette="secondary" (click)="modalConfirm.onOpen()" *ngIf="allowCorrecting" [disabled]="correction.score > 100 || correction.score < 0">
                            {{ 'correction.correct' | translate }}
                        </msc-button>
                    </div>
                </div>
            </div>

            <div class="content-corrections__details" [ngClass]="{'active': displays.comments}">
                <div class="content-corrections__title">
                    <h3>
                        <i class="icon icon-message-circle"></i>
                        {{ 'words.comments' | translate }}
                    </h3>

                    <div class="content-corrections__toggle text-secondary-500">
                        <i class="icon icon-arrow-ios-down" (click)="displayed('comments')"></i>
                    </div>
                </div>

                <div class="content-corrections__section">
                    <msc-timeline #correctionTimeline [context]="getContext(selectedCorrection)" [contextId]="selectedCorrection.activitySessionId" [instanceId]="instanceId" [me]="me" [customParams]="getCustomParams"></msc-timeline>
                </div>

            </div>
        </div>
    </div>

    <msc-correction-activity-aside #correctionActivityAside
                                    [activity]="selectedCorrection">
    </msc-correction-activity-aside>
</div>

<msc-common-modal size="30vw" #modalConfirm [title]="'words.save' | translate">
    <ng-template>
        <p>{{ 'correction.validate' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalConfirm.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="save(); modalConfirm.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
