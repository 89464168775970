<section class="head">
    <h3>Aside</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Aside</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-button class="msc-button--primary" palette="primary" (click)="aside.onOpen()">The Riddle of Steel</msc-button>
            </div>
        </div>
    </div>
</section>

<msc-common-aside #aside [title]="'Thulsa Doom'">
    <ng-template #asideTemplate>
        <p>
            That is strength, boy! That is <b [tooltip]="'POWAH!'">power</b>! What is steel compared to the hand that wields it?
            Look at the strength in your body, the desire in your heart, I gave you this! Such a waste.
            Contemplate this on the tree of woe.
        </p>
    </ng-template>
</msc-common-aside>
