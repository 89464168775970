import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
    selector: 'msc-camp-aside-refused-subscription',
    templateUrl: './aside-refused.component.html'
})
export class CampAsideRefusedSubscriptionComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public message: string;

    constructor() { }

    onOpen() {
        this.aside.onOpen();
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        this.onSave.emit(this.message);
        this.close();
    }
}
