<div class="media-manager__container">
    <input #uploadedFile class="display-none" type="file" *ngIf="options.canUploadMedia">

    <label class="input file-upload" *ngIf="options.canUploadMedia">
        <div class="file-upload__ctn">
            <input type="file" name="input" class="input__text" (change)="onMediaUpload($event)">
            <p class="file-upload__sentence">{{ 'words.add-media' | translate }}</p>
        </div>

        <div class="file-upload__action bg-secondary-800">
            <i class="icon icon-upload" *ngIf=!loading></i>
            <div class="spinner" *ngIf="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </label>

    <div class="file-upload--advertising" *ngIf="options.canUploadMedia">
        <p class="file-upload--advertising--sentence"  *ngIf="advertises.extensions.show">
            <span><i class="icon icon-info"></i> {{ 'words.accepted-file-extensions' | translate }}</span>:  {{ advertises.extensions.value }}
        </p>

        <p class="file-upload--advertising--sentence" *ngIf="advertises.size.show">
            <span><i class="icon icon-info"></i> {{ 'words.max-file-size' | translate }}</span>: {{ options.canUploadFileSize | fileSize }}
        </p>
    </div>

    <div class="medias--container">
        <div class="media--item" (click)="!options.canDeleteMedia && onDownloadMedia(media)" [ngClass]="{'media--item__downloadable': !options.canDeleteMedia}" *ngFor="let media of medias">
            <img [src]="getIcon(media)" alt="Resource icon type" class="media--file">

            <div class="media--description">
                <p class="resource__name">{{ media.title | truncate }}</p>
                <p class="resource__size">{{ media.size | fileSize }}</p>
            </div>

            <div class="media--actions">
                <i class="icon icon-download" (click)="onDownloadMedia(media)" *ngIf="options.canDownloadMedia"></i>
                <i class="icon icon-close" (click)="onDeleteMedia(media)" *ngIf="options.canDeleteMedia"></i>
            </div>
        </div>
    </div>
</div>
