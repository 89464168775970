import { Component, Input, OnInit } from '@angular/core';
import { OrganizeUserStepBaseComponent } from '../configure.component.base';

@Component({
    selector: 'msc-organize-user-configure-recap',
    templateUrl: './recap.component.html'
})
export class OrganizeUserConfigureRecapComponent implements OnInit {
    @Input() data;
    displays: any = {};
    parsedData = [];

    constructor() { }

    ngOnInit(): void {
        Object.entries(this.data).forEach(([key, obj]: [string, OrganizeUserStepBaseComponent]) => {
            if (obj) {
                const data = { key, obj: obj.toReadableData ? obj.toReadableData() : null };
                if (data.obj) {
                    this.parsedData.push(data);
                    this.displays[key] = true;
                }
            }
        });
    }

    displayed(key: string): void {
        this.displays[key] = !this.displays[key];
    }
}
