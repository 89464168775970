import { Directive, OnInit, OnDestroy, ElementRef, Input } from "@angular/core";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { ReplaySubject, Subscription, filter, delay } from "rxjs";

@Directive({
    selector: '[infiniteScrollTrigger]'
})
export class CommonInfiniteScrollTriggerDirective implements OnInit, OnDestroy {
    @Input() set items(items: any[]) {
        this.items$.next(items);
    }
    items$ = new ReplaySubject<any[]>(1);
    itemSub: Subscription;

    constructor(
        private elementRef: ElementRef,
        private infiniteScroll: InfiniteScrollDirective
    ) { }

    ngOnInit() {
        this.itemSub = this.items$.asObservable().pipe(
            filter(items => !!items && items.length > 0),
            delay(1)
        ).subscribe(() => this.doOverflowCheck())
    }

    private resolveContainerElement(
        selector: string | any,
        scrollWindow,
        defaultElement,
        fromRoot: boolean
    ): any {
        const hasWindow = window && !!window.document && window.document.documentElement;
        let container = hasWindow && scrollWindow ? window : defaultElement;
        if (selector) {
            const containerIsString =
                selector && hasWindow && typeof selector === 'string';
            container = containerIsString
                ? this.findElement(selector, defaultElement.nativeElement, fromRoot)
                : selector;
            if (!container) {
                throw new Error('ngx-infinite-scroll {resolveContainerElement()}: selector for');
            }
        }
        return container;
    }
    private findElement(
        selector: string | any,
        customRoot: ElementRef | any,
        fromRoot: boolean
    ) {
        const rootEl = fromRoot ? window.document : customRoot;
        return rootEl.querySelector(selector);
    }

    containerDoesNotOverflow(): boolean {
        const container = this.resolveContainerElement(
            this.infiniteScroll.infiniteScrollContainer,
            this.infiniteScroll.scrollWindow,
            this.elementRef.nativeElement,
            this.infiniteScroll.scrollWindow
        );

        if (!container) {
            return false;
        }
        if (this.infiniteScroll.horizontal) {
            return container.clientWidth === container.scrollWidth;
        }
        return container.clientHeight === container.scrollHeight;
    }

    doOverflowCheck(): void {
        if (this.containerDoesNotOverflow()) {
            this.infiniteScroll.scrolled.emit();
        }
    }


    ngOnDestroy() {
        this.itemSub.unsubscribe();
    }
}