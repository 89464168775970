import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { DataHelper } from '@helpers';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-aside-company-subscription-address',
    templateUrl: './address.component.html'
})
export class OrganizeCompanyAsideAddressComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    private isActive: boolean = false;
    public countries: any[];
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public address: any;
    public dropdown: boolean = false;

    constructor(private translateService: TranslateService) {}

    init(): void {
        this.isActive = true;

        this.address = {
            name: '',
            address: '',
            company: '',
            country: 'BE',
            vat: '',
            no_vat: false
        };

        this.countries = DataHelper.getCountries(this.translateService.getCurrentLang());
    }

    onOpen(): void {
        this.init();
        this.aside.onOpen();
    }

    displayed(): void {
        this.dropdown = !this.dropdown;
    }

    changeCountry(code: string): void {
        this.address.country = code;
    }

    getCountry(): string {
        return DataHelper.getCountry(this.translateService.getCurrentLang(), this.address.country);
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        this.onSave.emit(this.address);
    }
}
