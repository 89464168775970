import { Component, OnInit, Input } from '@angular/core';
import { DownloadHelper } from '@helpers';

@Component({
    selector: 'msc-shared-resource',
    templateUrl: './resource.component.html'
})
export class SharedResourceComponent implements OnInit {
    @Input() resource: any;
    @Input() file: File;
    @Input() media: any;
    @Input() truncate: number = 20;
    @Input() size: string = 'default';
    @Input() downloadIcon: boolean = false;
    public icon: string;

    constructor() {}

    ngOnInit(): void {
        if (this.media) {
            this.transform();
        }
        if (this.resource) {
            this.icon = this.getIcon();
        } else if (this.file) {
            this.icon = this.getFileIcon();
        }
    }

    download(): void {
        DownloadHelper.downloadMedia(this.resource.media, this.resource.title);
    }

    private transform(): void {
        this.resource = {
            media: this.media,
            title: this.media.title
        };
    }

    private getIcon(): string {
        if (this.resource.media.type === 'DOC') {
            const extension = this.resource.media.originalUrl ? this.resource.media.originalUrl.split('.')[1] : 'doc';
            switch (extension) {
                case 'ods':
                case 'xls':
                case 'xlsx':
                    return 'https://static.myskillcamp.com/images/icon-xls.svg';
                case 'doc':
                case 'docx':
                case 'odt':
                    return 'https://static.myskillcamp.com/images/icon-doc.svg';
                case 'ppt':
                case 'pptx':
                case 'odp':
                    return 'https://static.myskillcamp.com/images/icon-ppt.svg';
            }
        } else if (this.resource.media.type === 'NONE') {
            return 'https://static.myskillcamp.com/images/icon-doc.svg';
        } else if (this.resource.media.type === 'VID') {
            return 'https://static.myskillcamp.com/images/icon-media.svg';
        } else if (this.resource.media.type === 'SND') {
            return 'https://static.myskillcamp.com/images/icon-sound.svg';
        } else {
            return 'https://static.myskillcamp.com/images/icon-' + this.resource.media.type.toLowerCase() + '.svg';
        }
    }

    private getFileIcon(): string {
        switch (this.file.type) {
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
                return 'https://static.myskillcamp.com/images/icon-doc.svg';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'text/csv':
                return 'https://static.myskillcamp.com/images/icon-xls.svg';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'https://static.myskillcamp.com/images/icon-ppt.svg';
            case 'application/pdf':
                return 'https://static.myskillcamp.com/images/icon-pdf.svg';
            default:
                return 'https://static.myskillcamp.com/images/icon-media.svg';
        }
    }
}
