import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';

/**
 * Job Service
 */
@Injectable({providedIn: 'root'})
export class JobService {
    /**
     * CurrentJob in the application
     */
    private currentJob: string = 'learn';

    /**
     * Init
     * @constructor
     */
    constructor(private router: Router, private storageService: StorageService) {}

    /**
     * get current Job
     * @return {string} currentJob
     */
    getJob(): string {
        const segment = this.router.url.split('/')[1];

        switch (segment) {
            case 'learn':
            case 'organize':
            case 'manage':
                this.currentJob = segment;
                break;
            default:
                this.currentJob = this.storageService.getCache('currentJob') || 'learn';
        }
        return this.currentJob;
    }

    /**
     * Return feature from URL
     * @return {string} feature
     */
    getFeature(): string {
        if (this.getJob() === 'learn') {
            if (this.router.url.split('/')[3]) {
                return this.router.url.split('/')[3];
            } else {
                return this.router.url.split('/')[1];
            }
        } else {
            if (this.router.url.split('/')[3] === 'camp' && !this.router.url.split('/')[4]) {
                return this.router.url.split('/')[3];
            } else if (this.router.url.split('/')[5]) {
                return this.router.url.split('/')[5];
            } else {
                return this.router.url.split('/')[3];
            }
        }
    }
}
