<msc-common-aside #aside [title]="'words.add-new-participants' | translate">

    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div class="flex items-center justify-between my-2 gap--1rm-xs">
                <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate"
                    (searchEvent)="getAvailableLearners($event.value)"></msc-common-search>

                <msc-button class="msc-button--primary" palette="secondary" icon="icon-checkmark-square-2"
                    (click)="inviteAll()">
                    {{ 'words.invite-all' | translate }}
                </msc-button>
            </div>
            <div class="flex items-center space-x-4" [ngClass]="{'box--disabled': data.inviteAll}"
                *ngFor="let learner of availableLearners" (click)="addLearner(learner)">
                <msc-common2-checkbox [value]="isLearnerSelected(learner)" (changeEvent)="addLearner(learner)">
                </msc-common2-checkbox>

                <div class="flex items-center space-x-2">
                    <msc-common-avatar [label]="learner?.name"
                        [pictureURL]="learner?.picture ? (learner?.picture + '?size=128') : null">
                    </msc-common-avatar>

                    <div class="flex flex-col">
                        <p>{{ learner.name }}</p>
                        <small>{{ learner.headline | truncate:30}}</small>
                    </div>
                </div>
            </div>

            <div class="box flex center" *ngIf="availableLearners && availableLearners.length === 0">
                {{ 'empty.nothing.members' | translate }}
            </div>

            <msc-common-spinner *ngIf="!loaders.ready"></msc-common-spinner>

            <msc-load-more [loading]="loaders.loadMore" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div class="flex flex-col grow space-y-4">
            <div class="slide__summary" *ngIf="data.learners.length > 0">
                <ul class="avatar-group avatar-group--add">
                    <li *ngFor="let learner of data.learners | slice:0:3">
                        <a [attr.data-toolip]="learner.name" class="tooltip--triangle">
                            <msc-common-avatar [label]="learner?.name"
                                [pictureURL]="learner?.picture ? (learner?.picture + '?size=128') : null">
                            </msc-common-avatar>
                        </a>
                    </li>

                    <li *ngIf="data.learners.length > 3">
                        <span class="avatar-group__text text-primary-500">
                            + {{ data.inviteAll ? (pagination.total - 3) : (data.learners.length - 3) }}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="flex justify-between col-gap-xs ">
                <msc-common2-checkbox [value]="data.notify" [label]="'words.notify-participants' | translate"
                    (changeEvent)="data.notify = !data.notify"></msc-common2-checkbox>

                <div class="flex space-x-2">
                    <msc-button (click)="aside.onClose()">
                        {{ 'words.cancel' | translate }}
                    </msc-button>
                    <msc-button class="msc-button--primary" palette="secondary" [loading]="loaders.save" (click)="save()">
                        {{ 'words.save' | translate }}
                    </msc-button>
                </div>
            </div>
        </div>
    </ng-template>
</msc-common-aside>
