<section class="head">
    <h3>{{ 'user.manage.title' | translate }}</h3>

<!--search-->
<div class="w-full 2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2">
    <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
            (searchEvent)="onSearch($event)"></msc-common-search>
</div>

    <div class="flex items-center justify-between gap-2">

        <div class="flex items-center space-x-2">
            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                <span class="hidden sm:inline-block">
                    {{ 'words.filters' | translate }}
                </span>
            </msc-button>

            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh"
                    (click)="onFilterReset(); modalFilters.onReset()">
                    <span class="hidden sm:inline-block">
                        {{ 'words.reset-filters' | translate }}
                    </span>
                </msc-button>
            </ng-container>

            <ng-container *ngIf="selectGroupsOptions.length">
                <div class="hidden 2xl:block xl:block lg:block">
                    <msc-common-select class="w-[240px] msc-select--small" [data]="selectGroupsOptions" type="multi"
                        hasSearch="true">
                        <ng-template #buttonTemplate let-data="data">
                            <button class="">
                                <ng-container *ngIf="!allGroupsSelected; else allSelectedTemplate">
                                    <span class="text-xs font-bold text-gray-600">{{ 'words.managers' | translate
                                        }}</span>
                                    <ng-container *ngIf="data?.length">
                                        <msc-tag [text]="data.length" [color]="'secondary-default'"></msc-tag>
                                    </ng-container>
                                </ng-container>
                                <ng-template #allSelectedTemplate>
                                    <span>{{ 'words.all-managers' | translate }}</span>
                                </ng-template>
                            </button>
                        </ng-template>
                        <ng-template #optionTemplate let-datum="datum">
                            <label class="flex justify-between text-sm py-2 w-full"
                                [ngClass]="{'select-all-option': datum.id === 'all'}">
                                <div class="flex items-center overflow-hidden">
                                    <ng-container *ngIf="datum.value">
                                        <msc-common-avatar class="tiny mr-1" [label]="datum.label"
                                            [pictureURL]="datum.value.picture ? (datum.value.picture + '?size=128') : null">
                                        </msc-common-avatar>
                                    </ng-container>
                                    <span class="truncate">{{ datum.label }}</span>
                                </div>
                                <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectManager(datum)">
                                </msc-common2-checkbox>
                            </label>
                        </ng-template>
                    </msc-common-select>
                </div>
            </ng-container>

            <div class="flex space-x-4">
                <msc-button class="msc-button--primary" palette="primary" icon="icon-person-add"
                    [routerLink]="['/organize', company.slug, 'users', 'action', 'invitation']">
                    <span class="hidden sm:inline-block">{{ 'user.fast-invite.title' | translate }}</span>
                </msc-button>
                <div *ngIf="addUserMenu.length" #invitationOrigin>
                    <msc-button class="msc-button--outline msc-button--square" palette="primary" type="fab"
                        icon="icon-more-horizontal" (click)="overlayInvitation.open(invitationOrigin)"></msc-button>
                    <msc-common-overlay-menu #overlayInvitation direction="bottom-end" [menuItems]="addUserMenu">
                    </msc-common-overlay-menu>
                </div>
            </div>
        </div>


    </div>


</section>
<section class="body">
    <div class="content">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async"
                        (onScrollEnd)="hasLoadMore ? onLoad() : null">
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common2-checkbox
                                [type]="(!allUsersSelected && (selectedUsers.length > 0 || excludedUsers.length > 0)) ? 'half' : 'tick'"
                                [value]="selectedUsers.length > 0 || excludedUsers.length > 0 || selectAll || allUsersSelected"
                                (changeEvent)="toggleSelectedUsers()"></msc-common2-checkbox>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <div class="flex space-x-1 items-center">
                                <span>{{ 'user.account-status' | translate }}</span>

                                <div class="flex items-center justify-center"
                                    [tooltip]="'tooltip.access-date.status' | translate">
                                    <i class="icon icon-question-mark-circle"></i>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate>
                        </ng-template>
                        <ng-template #thTemplate>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common2-checkbox [value]="selectAll ? !isExcluded(datum) : isSelected(datum)"
                                (changeEvent)="selectAll ? excludeUser(datum) : selectUser(datum)">
                            </msc-common2-checkbox>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="flex space-x-2 items-center overflow-hidden cursor-pointer hover:underline"
                                (click)="editProfile(datum.account.id)">
                                <div class="relative" [tooltip]="'user.manage-invitation' | translate"
                                    *ngIf="datum.account.type === 'invitation'" (click)="manageInvitations(datum)">
                                    <msc-common-avatar [label]="datum.account?.name"
                                        [pictureURL]="datum.account?.picture ? (datum.account?.picture + '?size=128') : null">
                                    </msc-common-avatar>
                                    <i
                                        class="icon icon-clock text-primary-500 absolute top-0 right-0 w-3.5 h-3.5 bg-white flex items-center justify-center rounded-full"></i>
                                </div>

                                <msc-common-avatar *ngIf="datum.account.type !== 'invitation'"
                                    [label]="datum.account?.name"
                                    [pictureURL]="datum.account?.picture ? (datum.account?.picture + '?size=128') : null">
                                </msc-common-avatar>

                                <div class="flex flex-col space-y-1 overflow-x-hidden">
                                    <span class="truncate">{{ datum.account.name }}</span>

                                    <small class="truncate">{{ datum.externalId }}</small>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="flex flex-col space-y-1">
                                <div class="flex space-x-1">
                                    <msc-tag [text]="'words.' + datum.accessStatus.licenseStatus | translate"
                                        [color]="getStatusColor(datum.accessStatus.licenseStatus)"></msc-tag>
                                    <msc-tag [text]="'words.guest' | translate" *ngIf="datum.account.type === 'guest'"
                                        color="gray"></msc-tag>
                                </div>

                                <small *ngIf="getTranslateDate(datum)">{{ getTranslateDate(datum) | translate }}
                                    <small *ngIf="datum.accessStatus.accessStartAt">
                                        {{formatCustomDate(datum.accessStatus.accessStartAt)}}
                                    </small>
                                    <small *ngIf="datum.subscriptionDate && !datum.accessStatus.accessStartAt">
                                        {{formatCustomDate(datum.subscriptionDate)}}
                                    </small>
                                </small>
                                <small *ngIf="datum.accessStatus.accessEndAt"> -
                                    {{formatCustomDate(datum.accessStatus.accessEndAt)}}
                                </small>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="flex space-x-2">
                                <i class="icon icon-student"
                                    [ngClass]="{'text-primary-500': datum.roles.companyLearner}"></i>
                                <i class="icon icon-color-palette"
                                    [ngClass]="{'text-primary-500': datum.roles.companyAuthor}"></i>
                                <i class="icon icon-mentor"
                                    [ngClass]="{'text-primary-500': datum.roles.companyManager}"></i>
                                <i class="icon icon-calendar"
                                    [ngClass]="{'text-primary-500': datum.roles.companyAdministrator}"></i>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="flex space-x-2">
                                <i class="icon icon-camp text-primary-500"></i>
                                <div>{{ datum.campsCount }}</div>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-tableDatum="datum">
                            <msc-common-select style="width: 180px"
                                [infiniteLoad]="loadMoreAvailableManagers(tableDatum)" hasSearch="true" #selectManager>
                                <ng-template #buttonTemplate>
                                    <ng-container *ngIf="tableDatum.manager; else emptyTemplate">
                                        <button class="flex items-center space-x-2 overflow-hidden">
                                            <msc-common-avatar class="tiny" [label]="tableDatum.manager?.name"
                                                [pictureURL]="tableDatum.manager?.picture ? (tableDatum.manager?.picture + '?size=64') : null">
                                            </msc-common-avatar>
                                            <small class="truncate hidden lg:inline-block">{{ tableDatum.manager?.name
                                                }}</small>
                                        </button>
                                    </ng-container>
                                    <ng-template #emptyTemplate>
                                        <button class="flex items-center space-x-2 overflow-hidden">
                                            <msc-common-avatar class="tiny">
                                            </msc-common-avatar>
                                            <small class="truncate hidden lg:inline-block">{{'words.no-manager' |
                                                translate}}</small>
                                        </button>
                                    </ng-template>
                                </ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <button class="flex items-center space-x-2 py-1 overflow-hidden"
                                        (click)="onSelectUserManager(tableDatum, datum); selectManager.onClose();">
                                        <msc-common-avatar class="tiny" [label]="datum.label"
                                            [pictureURL]="datum.value?.picture ? (datum.value?.picture + '?size=64') : null">
                                        </msc-common-avatar>
                                        <small class="truncate">{{ datum.label }}</small>
                                    </button>
                                </ng-template>
                            </msc-common-select>
                        </ng-template>
                        <ng-template #tdTemplate let-tableDatum="datum">
                            <ng-container *ngIf="tableDatum.selectExperienceData?.length">
                                <msc-common-select class="w-[150px]" [data]="tableDatum.selectExperienceData">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="flex overflow-hidden">
                                            <i class="block lg:hidden" icon="icon icon-image"></i>
                                            <ng-container *ngIf="data?.length; else placeholderTemplate">
                                                <small class="truncate hidden lg:inline-block">{{
                                                    data[0].label}}</small>
                                            </ng-container>
                                            <ng-template #placeholderTemplate>
                                                <small class="truncate hidden lg:inline-block">{{'words.no-experience' |
                                                    translate}}</small>
                                            </ng-template>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="text-left leading-none py-2 w-full"
                                            (click)="onSelectExperience(tableDatum, datum)">
                                            <small>{{ datum.label }}</small>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </ng-container>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div #origin>
                                <msc-button type="fab" icon="icon-more-horizontal" (click)="userOptions.open(origin)">
                                </msc-button>

                                <msc-common-overlay-menu #userOptions [menuItems]="datum.userMenu">
                                </msc-common-overlay-menu>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <a (click)="datum.isExpanded=!datum.isExpanded" class="transition-all"
                                [ngClass]="{'rotate-180': datum.isExpanded}">
                                <i class="icon icon-arrow-ios-down"></i>
                            </a>
                        </ng-template>
                        <ng-template #tdExtensionTemplate let-datum="datum">
                            <ng-container *ngIf="datum.account">
                                <div class="grow flex justify-between">
                                    <div class="flex flex-col space-y-4">
                                        <div class="flex flex-col space-y-2">
                                            <p>{{ 'words.email' | translate }}</p>

                                            <small>{{ datum.account.email | empty }}</small>
                                        </div>

                                        <div class="flex flex-col space-y-2">
                                            <p>{{ 'words.phone' | translate }}</p>

                                            <small>{{ datum.account.phoneNumber | empty }}</small>
                                        </div>
                                    </div>

                                    <div class="hidden sm:flex flex-col space-y-4">
                                        <p>{{ 'words.role' | translate }}</p>

                                        <div class="flex flex-col space-y-2">
                                            <div class="flex items-center space-x-2"
                                                [ngClass]="{'text-primary-500': datum.roles.companyLearner}">
                                                <i class="icon icon-student"></i>
                                                <small>{{ 'words.learn' | translate }}</small>
                                            </div>

                                            <div class="flex items-center space-x-2"
                                                [ngClass]="{'text-primary-500': datum.roles.companyAuthor}">
                                                <i class="icon icon-color-palette"></i>
                                                <small>{{ 'words.create' | translate }}</small>
                                            </div>

                                            <div class="flex items-center space-x-2"
                                                [ngClass]="{'text-primary-500': datum.roles.companyManager}">
                                                <i class="icon icon-mentor"></i>
                                                <small>{{ 'words.coach' | translate }}</small>
                                            </div>

                                            <div class="flex items-center space-x-2"
                                                [ngClass]="{'text-primary-500': datum.roles.companyAdministrator}">
                                                <i class="icon icon-calendar"></i>
                                                <small>{{ 'words.organise' | translate }}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="hidden sm:flex flex-col space-y-4">
                                        <div class="flex flex-col space-y-2">
                                            <p>{{ 'words.department' | translate }}</p>

                                            <small>{{ datum.account.department | empty }}</small>
                                        </div>

                                        <div class="flex flex-col space-y-2">
                                            <p>{{ 'words.last-connection' | translate }}</p>

                                            <small>{{ datum.account.lastConnection | date: 'dd MMM YYYY HH:mm:ss' |
                                                empty
                                                }}</small>
                                        </div>
                                    </div>

                                    <div class="hidden sm:flex flex-col space-y-4" *ngIf="datum.subscriptionDate">
                                        <div class="flex flex-col space-y-2">
                                            <p>{{ 'group.user.registration.date' | translate }}</p>

                                            <small>{{ datum.subscriptionDate | date: 'dd MMM YYYY' }}</small>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'common.empty' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <div class="flex justify-center">
                    <msc-common-spinner></msc-common-spinner>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="(isLoadingMore$ | async) === 'loading'">
        <div class="flex justify-center">
            <msc-common-spinner></msc-common-spinner>
        </div>
    </ng-container>

    <footer class="container-users__footer" *ngIf="selectedUsers.length || selectAll">
        <div class="container-users__footer__info">
            <i class="icon icon-minus-square"></i>
            <p><span>{{selectAll ? (pagination.total - excludedUsers.length) : selectedUsers.length}}</span>
                participants selectionnes</p>
        </div>

        <div class="container-users__footer__icons">
            <div [tooltip]="'Discuss'" (click)="openConversationWithUsers()">
                <i class="icon icon-message-circle"></i>
            </div>

            <div [tooltip]="'Notify by email'" (click)="mailMultipleUsersAside.onOpen()">
                <i class="icon icon-email"></i>
            </div>

            <div [tooltip]="'Re attribuer un manager'" (click)="reassignManagerAside.onOpen()">
                <i class="icon icon-shuffle-1"></i>
            </div>

        </div>

        <div></div>
    </footer>
</section>

<msc-organize-user-modal-massive-invitation *ngIf="displays.modals.massiveInvitation" [counts]="counts"
    (onClose)="closeModal('massiveInvitation')" (onSend)="sendMail($event, true)">
</msc-organize-user-modal-massive-invitation>

<msc-organize-user-modal-manage-invitation *ngIf="displays.modals.manageInvitation" [user]="selectedUser"
    (onClose)="closeModal('manageInvitation')" (onSend)="sendMail($event)">
</msc-organize-user-modal-manage-invitation>

<msc-organize-user-modal-reset #modalResetPassword>
</msc-organize-user-modal-reset>

<msc-shared-aside-mail-multiple-users #mailMultipleUsersAside [selectedUsers]="selectedUsers"
    (onSave)="sendMailToUsers($event)">
</msc-shared-aside-mail-multiple-users>

<msc-organize-users-aside-reassign #reassignManagerAside [companyId]="company.id" [selectedUsers]="selectedUsers"
    (onSave)="reassignManager($event)">
</msc-organize-users-aside-reassign>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>

<msc-common-modal size="30vw" #modalDeleteUser [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'user.delete.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDeleteUser.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteUser(modalDeleteUser)">
                {{'common.delete' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
