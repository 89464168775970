import { Account } from '../../account';

export class CompanySettings {
    name: string;
    country: string;
    vat: string;
    usersCount: number;
    usersLimit: number;
    mediaId: number;
    picture?: string;
    contactId: number;
    contact?: Account;
    billingType: string;

    constructor(settings: object) {
        this.assign(settings);
    }

    assign(settings: object): void {
        Object.assign(this, settings);
    }
}
