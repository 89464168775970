import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class ManageCalendarProvider {

    constructor(private http: HttpClient) {}

    get(id: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events`, {params}).pipe(
            map((data) => data)
        );
    }

    delete(id: string|number, instanceId: number, eventId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/${eventId}`, {params}).pipe(
            map((data) => data)
        );
    }

    update(id: string|number, instanceId: number, eventId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/${eventId}`, params).pipe(
            map((data) => data)
        );
    }

    exportAttendances(id: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/attendances-export`, params, {responseType: 'blob'}).pipe(
            map((data) => data)
        );
    }

    getAttendanceListPaper(id: string|number, instanceId: number, eventId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/${eventId}/export`, params, {responseType: 'blob'}).pipe(
            map((data) => data)
        );
    }

    searchTrainers(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/search/accounts`, {params}).pipe(
            map((data) => data)
        );
    }

    addTrainer(eventId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/events/${eventId}/trainers`, params).pipe(
            map((data) => data)
        );
    }

    deleteTrainer(eventId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/events/${eventId}/trainers`, {params}).pipe(
            map((data) => data)
        );
    }

    uploadResource(eventId: number, sessionId: number, mediaId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/events/${eventId}/session/${sessionId}/media/${mediaId}`, params);
    }
}
