import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '@env/environment';

export interface ApiCompanySenderItemExperience {
    catalogueDisplay: string;
    displayName: string;
    displayPicture: string;
    id: number;
    name: string;
    slug: string;
    usersCount: number;
}

export interface ApiCompanySenderItem {
    company: any;
    dkimHost: string;
    dkimStatus: boolean;
    dkimValue: string;
    email: string;
    experiences?: ApiCompanySenderItemExperience[];
    externalId: number;
    id: number;
    isConfirmed: boolean;
    name: string;
    status: boolean;
}

export enum ApiCompanySenderSubjectStatus {
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

export interface ApiCompanySenderSubject {
    items: ApiCompanySenderItem[];
    status: ApiCompanySenderSubjectStatus;
}

@Injectable({ providedIn: 'root' })
export class CompanySenderProvider {
    private readonly root = `${environment.envVar.API_URL}/admin/companies`;
    private readonly subject: BehaviorSubject<any> = new BehaviorSubject<any>({
        items: [],
        status: ApiCompanySenderSubjectStatus.LOADING,
    });

    constructor(
        private http: HttpClient,
    ) { }

    /**
     * Set subject of Company Senders
     */
    setSubjectCompanySender(datum: Partial<ApiCompanySenderSubject>, isAppend: boolean = false) {
        const aux = this.subject.getValue();
        Object.keys(datum).forEach((key) => {
            aux[key] = isAppend && Array.isArray(datum[key]) ? [...aux[key], ...datum[key]] : datum[key];
        });
        this.subject.next(aux);
    }

    /**
     * Get subject of Company Senders
     */
    getSubjectCompanySender() {
        return this.subject.asObservable();
    }

    /**
     * Get a list of senders
     */
    getSenders(companyId: number): Observable<ApiCompanySenderItem[]> {
        return this.http.get<{ senders: ApiCompanySenderItem[] }>(`${this.root}/${companyId}/senders`, {
            observe: 'response',
        }).pipe(
            map((r: HttpResponse<any>) => this.onMapGetSenders(r.body)),
            tap((d: any) => this.onTapGetSenders(d))
        );
    }

    /**
     * Get a specific sender
     */
    getSender(companyId: number, senderId: number): Observable<ApiCompanySenderItem> {
        return this.http.get<any>(`${this.root}/${companyId}/senders/${senderId}`, {
            observe: 'response',
        }).pipe(
            map((r: HttpResponse<any>) => r.body)
        );
    }

    /**
     *
     */
    editSender(companyId: number, senderId: number, params: Partial<any>) {
        return this.http.put(`${this.root}/${companyId}/senders/${senderId}`, params, {
            observe: 'body'
        }).pipe(
            //
        );
    }

    /**
     *
     */
    editExperience(companyId: number, experienceId: number, params: { sender_id: number }) {
        return this.http.put(`${this.root}/${companyId}/experiences/${experienceId}/sender`, params, {
            observe: 'body'
        }).pipe(
            //
        );
    }

    /**
     *
     */
    editQuote(companyId: number, experienceId: number, params: any = {}) {
        return this.http.put(`${this.root}/${companyId}/experiences/${experienceId}/quote`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    deleteSender(companyId: number, senderId: number) {
        return this.http.delete(`${this.root}/${companyId}/senders/${senderId}`, {
            observe: 'body'
        }).pipe(
            tap(() => this.onTapDeleteSender(senderId))
        );
    }

    /**
     *
     */
    postSenderCreate(companyId: number, params: any) {
        return this.http.post(`${this.root}/${companyId}/senders`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    postSenderConfirm(companyId: number, senderId: number) {
        return this.http.post(`${this.root}/${companyId}/senders/${senderId}/send`, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    postSenderValidate(companyId: number, senderId: number) {
        return this.http.post(`${this.root}/${companyId}/senders/${senderId}/validate`, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    postNotify(companyId: number) {
        return this.http.post(`${this.root}/${companyId}/senders/notify-sales`, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     * Resolver for map of getSenders
     */
    onMapGetSenders(response: { senders: ApiCompanySenderItem[] }) {
        const items = response.senders;
        const status = ApiCompanySenderSubjectStatus.SUCCESS;
        return { items, status };
    }

    /**
     * Resolver for tap of getSenders
     */
    onTapGetSenders(d: ApiCompanySenderSubject) {
        this.setSubjectCompanySender(d);
    }

    /**
     * Resolver for tap of deleteSender
     */
    onTapDeleteSender(senderId: number) {
        const { items } = this.subject.getValue();
        const itemsAfterDelete = items.filter((item: any) => item.id !== senderId);
        this.setSubjectCompanySender({ items: itemsAfterDelete });
    }
}
