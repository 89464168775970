<main id="main">
    <div class="overlay"></div>
    <router-outlet></router-outlet>
    <msc-navigation-progress></msc-navigation-progress>
    <msc-player-light></msc-player-light>
    <msc-window-embed></msc-window-embed>
    <msc-common-toast></msc-common-toast>
</main>

<msc-common-modal size="30vw" #modalLogout [title]="'words.logout' | translate">
    <ng-template>
        <p>{{ 'words.logout-description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalLogout.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--flat" palette="primary" (click)="logout(); modalLogout.onClose()">{{'common.confirm' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
