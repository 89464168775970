import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, StorageService } from '@services';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService, private storageService: StorageService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.isLogged()) {
            return true;
        } else {
            this.storageService.set('redirect', state.url);
            this.router.navigate(['/login'], {queryParams: route.queryParams, queryParamsHandling: 'merge'});
            return false;
        }
    }
}
