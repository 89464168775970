import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '../company';
import { Pagination } from '../pagination';
import { environment } from '@env/environment';
import { GroupTypes, GroupRoles } from '@enums';
import { StorageService } from '@services';

@Injectable()
export class UserProvider {

    constructor(private http: HttpClient, private storageService: StorageService) { }

    updatePassword(params: any): Observable<any> {
        return this.http.put(`${environment.envVar.AUTH_URL}/api/idp/passwords`, params).pipe(
            map(data => data)
        );
    }

    me(): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/me`).pipe(
            map((data: any) => (
                {
                    ...data.account,
                    teams: (data.groups || []).filter((group) => group.type === GroupTypes.TEAM).map((team) => (
                        {
                            ...team,
                            roles: team.users[0] ? team.users[0].roles.map((role) => role.name) : [],
                            permissions: team.users[0] ? team.users[0].permissions.map((permission) => permission.name) : [],
                        }
                    )),
                    communities: (data.groups || []).filter((group) => group.type === GroupTypes.COMMUNITY).map((community) => (
                        {
                            ...community,
                            roles: community.users[0] ? community.users[0].roles.map((role) => role.name) : [],
                            permissions: community.users[0] ? community.users[0].permissions.map((permission) => permission.name) : [],
                        }
                    ))
                }
            )), map((data) => {
                const temp = {
                    ...data,
                    teamOwnerId: data.teams.filter(team => team.roles.indexOf(GroupRoles.OWNER) !== -1)[0]?.id || null,
                    teamManagerId: data.teams.filter(team => team.roles.indexOf(GroupRoles.MANAGER) !== -1)[0]?.id || null,
                    teamMemberId: data.teams.filter(team => team.roles.indexOf(GroupRoles.MEMBER) !== -1)[0]?.id || null,
                    // remove users array
                    teams: data.teams.map(({ users, ...team }) => team),
                    communities: data.communities.map(({ users, ...community }) => community)
                }
                this.storageService.set('me', temp);
                return temp;
            })
        );
    }

    getCompanies(params?: any): Observable<Company[]> {
        return this.http.get(`${environment.envVar.API_URL}/me/companies`, { params }).pipe(
            map((companies: any) => {
                return companies.map((company) => {
                    return new Company(company);
                });
            })
        );
    }

    getAccount(companyId: number, accountId?: number): Observable<any> {
        let params;
        if (accountId) {
            params = {
                company_id: String(companyId),
                account_id: accountId
            };
        } else {
            params = {
                company_id: String(companyId),
            };
        }

        return this.http.get(`${environment.envVar.API_URL}/account`, { params }).pipe(
            map(data => data)
        );
    }

    updateAccount(params: any, companyId: number, accountId?: number): Observable<any> {
        const include = (accountId) ? '&account_id=' + accountId : '';
        return this.http.put(`${environment.envVar.API_URL}/account?company_id=${companyId}${include}`, params).pipe(
            map(data => data)
        );
    }

    getHistories(params?: any): Observable<any> {
        const response = {
            pagination: {},
            histories: []
        };
        const url = (params && params.company_id) ? `${environment.envVar.API_URL}/admin/companies/${params.company_id}/users/${params.account_id}/history` : `${environment.envVar.API_URL}/learning/contents/history`;

        return this.http.get(url, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.contents.map((content) => {
                    response.histories.push(content);
                });
                return response;
            })
        );
    }

    downloadHistory(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/account/history/download`, { params }).pipe(
            map(data => data)
        );
    }

    exists(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/account/check`, params).pipe(
            map(data => data)
        );
    }

    notifyPassword(companyId, accountId, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/${accountId}/password/notify `, params).pipe(
            map(data => data)
        );
    }
}
