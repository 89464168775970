export class Validator {
    private data: any;
    private rules: any;
    private validationTrace: any = [];
    private regex: any = {
        alpha: /^[a-zA-Z_ ]*$/,
        num: /^[0-9]*$/,
        alphanum: /^[a-zA-Z0-9_ ]*$/,
        mail: /[a-z0-9.-_]+@[a-z.]+/,
        phoneprefix: /^\+(?:[\d]{2,4})$/,
        password: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\\x]).*$/
    };
    private customTranslations?: any;

    constructor(data?: any, rules?: any, customTranslations?: any) {
        this.data = data;
        this.rules = rules;
        this.customTranslations = customTranslations;
    }

    create(data: any, rules: any, customTranslates?: any): any {
        this.data = data;
        this.rules = rules;
        this.customTranslations = customTranslates;
    }

    isValid(): boolean {
        return !this.validationTrace.filter((trace) => {
            return !trace.valid;
        }).length;
    }

    trace(key?: string): any {
        if (key) {
            return this.validationTrace.find((trace) => {
                return trace.key === key;
            });
        }
        return this.validationTrace;
    }

    validate(): any {
        this.validationTrace = [];

        for (const key in this.data) {
            if (this.rules[key] && this.data.hasOwnProperty(key)) {
                const rules = this.rules[key].split('|');
                const errors = [];
                const customTranslations = this.customTranslations ? this.customTranslations[key] : '';
                let locked = false;

                if (rules.indexOf('required') > -1 && !this.data[key]) {
                    errors.push(customTranslations && customTranslations.required ? customTranslations.required : 'required');
                }

                if (this.data[key] && rules.indexOf('alphanum') > -1) {
                    locked = true;

                    if (!this.regex.alphanum.test(this.data[key])) {
                        errors.push(customTranslations && customTranslations.alphanum ? customTranslations.alphanum : 'alphanum');
                    }
                }

                if (!locked && this.data[key] && rules.indexOf('alpha') > -1 && !this.regex.alpha.test(this.data[key])) {
                    errors.push(customTranslations && customTranslations.alpha ? customTranslations.alpha : 'alpha');
                }

                if (!locked && this.data[key] && rules.indexOf('num') > -1 && !this.regex.num.test(this.data[key])) {
                    errors.push(customTranslations && customTranslations.num ? customTranslations.num : 'num');
                }

                if (this.data[key] && rules.indexOf('mail') > -1 && !this.regex.mail.test(this.data[key])) {
                    errors.push(customTranslations && customTranslations.mail ? customTranslations.mail : 'mail');
                }

                if (this.data[key] && rules.indexOf('phoneprefix') > -1 && !this.regex.phoneprefix.test(this.data[key])) {
                    errors.push(customTranslations && customTranslations.phoneprefix ? customTranslations.phoneprefix : 'phoneprefix');
                }

                if (this.data[key] && rules.indexOf('password') > -1 && !this.regex.password.test(this.data[key])) {
                    errors.push(customTranslations && customTranslations.password ? customTranslations.password : 'password');
                }

                let index = this.findIndex(rules, 'min');
                if (this.data[key] && index > -1) {
                    const min = rules[index].replace('min:', '');

                    if (this.data[key].length < min) {
                        errors.push(customTranslations && customTranslations.min ? customTranslations.min : 'min');
                    }
                }

                index = this.findIndex(rules, 'max');
                if (this.data[key] && index > -1) {
                    const max = rules[index].replace('max:', '');

                    if (this.data[key].length > max) {
                        errors.push(customTranslations && customTranslations.max ? customTranslations.max : 'max');
                    }
                }

                index = this.findIndex(rules, 'equal');
                if (this.data[key] && index > -1) {
                    const equalKey = rules[index].replace('equal:', '');
                    if (this.data[key] !== this.data[equalKey]) {
                        errors.push(customTranslations && customTranslations.equal ? customTranslations.equal : 'equal');
                    }
                }

                index = this.findIndex(rules, 'custom');
                if (this.data[key] && index > -1) {
                    const custom = new RegExp(rules[index].replace('custom:', ''));

                    if (!custom.test(this.data[key])) {
                        errors.push(customTranslations && customTranslations.custom ? customTranslations.custom : 'custom');
                    }
                }

                this.validationTrace.push({key, valid: !errors.length, errors});
            }
        }
    }

    private findIndex(rules: any[], compare: string): number {
        return rules.findIndex((rule) => {
            return rule.indexOf(compare) > -1;
        });
    }
}
