import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { catchError, mergeScan, map, tap, repeat, finalize, concatMap, takeUntil } from 'rxjs/operators';
//
import { StatisticsProvider2, StatsProvider } from '@lighty';
import { ErrorStreamService, ExternalAppsService, StorageService, TranslateService } from '@services';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { TimeUtils } from '@common/utils/time.utils';
import moment from 'moment';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-stats-detail',
    templateUrl: './stats-detail.component.html',
    styleUrls: ['./stats-detail.component.scss'],
})

export class CampStatsDetailComponent {
    private company: any;
    private campId: number;
    private query: string;
    private sort: any;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public cards: Array<any>;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public rowsTemp$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public hasLoadMore: boolean = false;
    public isLoading: boolean = false;
    public isSubscribed: boolean = true;
    public displayChangelog: boolean = false;
    public content: any;
    public lastChange;
    public tableHeight: string;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly statisticsProvider2: StatisticsProvider2,
        private readonly toastService: CommonToastService,
        private readonly errorStreamService: ErrorStreamService,
        private readonly externalAppsService: ExternalAppsService,
        private readonly statsProvider: StatsProvider
    ) {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
            this.storageService.setCache('v2ContentStats', this.router.getCurrentNavigation().extras.state.content, null, true);
        }
        this.content = this.storageService.getCache('v2ContentStats');
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.campId = this.route.parent.parent.parent.snapshot.params['campId'];
        this.setCards(this.content);
        this.setContent();
        this.onQueryContentUserList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     *
     */
    setCards(content: any): void {
        this.cards = [
            {
                label: this.translateService.instant('words.progress-average'),
                value: content.chartProgress,
                type: 'chart',
            },
            {
                label: this.translateService.instant('words.score-average'),
                value: content.chartScore,
                type: 'chart',
            },
        ];
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '20%',
            },
            {
                key: 'subscribed',
                label: this.translateService.instant('words.status'),
                width: '20%',
            },
            {
                key: 'time',
                label: this.translateService.instant('stats.hour'),
                width: '15%',
            },
            {
                key: 'progress',
                label: this.translateService.instant('words.progress'),
                width: '20%',
            },
            {
                key: 'result',
                label: this.translateService.instant('words.score'),
                width: '20%',
            },
            {
                key: '',
                width: '5%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryContentUserList(pageValue: number) {
        return Object.assign(
            {
                context: this.content.context,
                page: pageValue,
                subscribed: this.isSubscribed ? 1 : 0,
            },
            this.query ? { q: this.query } : {},
            this.sort ? { ...this.sort } : {},
        );
    }

    /**
     *
     */
    onQueryContentUserList(isAppend: boolean = true): void {
        this.isLoading = true;
        this.statisticsProvider2
            .getContentUserList(this.company.id, this.campId, this.content.id, this.getParamsOnQueryContentUserList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryContentUserList(response)),
                map((response) => this.onMapOnQueryContentUserList(response.users)),
                tap((response) => this.rowsTemp$.next(response)),
                concatMap(() => this.onConcatMapOnQueryContentUserList(this.content)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((datum) => this.rows$.next(datum)),
                tap(() => this.rowsTemp$.next([])),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentUserList(response: any) {
        this.hasLoadMore = response.pagination.currentPage !== response.pagination.lastPage;
        if (response.global.lastLogCreatedAt && this.content.context === 'traject') {
            this.content = { ...this.content, ...response.global };
            const lastEdit = moment(response.global.lastLogCreatedAt);
            this.lastChange = TimeUtils.isOlderThanYesterday(lastEdit) ? lastEdit.format('DD.MM.YYYY') : lastEdit.calendar(TimeUtils.TODAY);
        }
    }

    /**
     *
     */
    onMapOnQueryContentUserList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            avatar: datum.picture,
            id: datum.id,
            isSubscribed: datum.subscribe,
            name: datum.name,
        }));
    }

    /**
     *
     */
    onConcatMapOnQueryContentUserList(content: any) {
        const ids = this.rowsTemp$.getValue().map((row) => row.id);
        return this.statisticsProvider2
            .getContentSubscriptionList(this.company.id, content.id, { context: content.context, account_ids: ids }) // TODO search issue 7287 ||
            .pipe(
                catchError(() => of([])),
                map((datum) => this.onMapOnConcatMapOnQueryContentUserList(datum)),
                finalize(() => {
                    this.status$.next(CommonSubjectStatus.SUCCESS);
                    this.isLoading = false;
                }),
                takeUntil(this.destroy$),
            );
    }

    /**
     *
     */
    onMapOnConcatMapOnQueryContentUserList(datum: any) {
        const rows = this.rowsTemp$.getValue().map((row) => ({
            ...row,
            isChartProgressVisible: typeof datum[row.id]?.context?.progress === 'number',
            isChartScoreVisible: typeof datum[row.id]?.context?.score === 'number',
            chartProgress: datum[row.id]?.context?.progress ?? 0,
            chartScore: datum[row.id]?.context?.score ?? 0,
            duration: datum[row.id] ? datum[row.id]?.context?.duration ?? 0 : 0,
        }));
        this.tableHeight = `${Math.min(rows.length, 6) * 70 + 120}px`
        return rows;
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.query = value || null;
        this.page$.next(1);
        this.onQueryContentUserList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryContentUserList();
    }

    /**
     *
     */
    onSwitch(value: boolean): void {
        this.isSubscribed = value;
        this.page$.next(1);
        this.onQueryContentUserList(false);
    }

    /**
     *
     */
    onSort(key: string, value: any): void {
        if (value) {
            this.sort = { orderBy: key, orderType: value };
        } else {
            this.sort = {};
        }
        this.page$.next(1);
        this.onQueryContentUserList(false);
    }

    /**
     *
     */
    goBack(): void {
        const fragment = this.router.url.split('/');
        fragment.splice(fragment.length - 3, 3);
        fragment.push('content');
        this.router.navigate(fragment);
    }

    /**
     *
     */
    goToUserDetails(user: any) {
        this.router.navigate(['user', user.id], { relativeTo: this.route, state: { user } });
    }

    download(modal: CommonModalComponent, ev: any): void {
        const params = {
            type: ev.type,
            format: ev.format,
            emails: ev.emails,
            year: ev.year || undefined,
            traject_id: this.content.id,
            camp_id: this.campId
        };

        this.statsProvider.getExport(this.company.id, params).subscribe({
            next: (data) => {
                this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail'));
                modal.onOpen();
                this.errorStreamService.unlocked();
            },
            error: () => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                this.errorStreamService.unlocked();
            }
        });
    }
}
