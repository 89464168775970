<ng-container *ngIf="data?.length; then contentTemplate; else emptyTemplate"></ng-container>

<ng-template #contentTemplate>
    <ng-container *ngFor="let datum of data; trackBy: fnOnTrackByIndex">
        <ng-container *ngTemplateOutlet="item; context: { datum: datum }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #emptyTemplate>
    <ng-container *ngIf="!isLoading">
        <msc-empty-state [title]="emptyMessage"></msc-empty-state>
    </ng-container>
</ng-template>

<ng-container *ngIf="isLoading">
    <msc-common-spinner *ngIf="true"></msc-common-spinner>
</ng-container>

<ng-container *ngIf="hasLoadMore">
    <msc-button class="msc-button--primary" palette="secondary" (click)="onLoadMore()">{{ 'words.load-more' | translate }}</msc-button>
</ng-container>
