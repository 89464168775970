import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContentProvider } from './../../../lighty/models/content/content.provider'
import { take } from 'rxjs';
import { Company } from '@lighty';
import { StorageService, TranslateService } from '@services';
@Component({
  selector: 'app-boclip',
  templateUrl: './boclip.component.html',
  styleUrls: ['./boclip.component.scss']
})
export class LearnBoclips implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  public contents: any = [];
  public auxContents: any = [];
  public loading: boolean = true;
  public company: Company;
  public totalPages: number | null = null;
  public pages: Array<number> = [];
  public firstPass: boolean = false;
  public disabled: boolean = false;
  public search: string = '';
  public filters: any;
  public isFilterActive: boolean = false;
  public pageCount: number = 1;


  constructor(
    private contentProvider: ContentProvider,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) {
    this.filters = {
      languages: [
        {
          label: this.translateService.instant('language.es'),
          value: 'spa',
          isSelected: false,
          displayValue: this.translateService.instant('language.es')
        },
        {
          label: this.translateService.instant('language.en'),
          value: 'eng',
          isSelected: false,
          displayValue: this.translateService.instant('language.en')
        },
        {
          label: this.translateService.instant('language.fr'),
          value: 'fra',
          isSelected: false,
          displayValue: this.translateService.instant('language.fr')
        },
        {
          label: this.translateService.instant('language.it'),
          value: 'ita',
          isSelected: false,
          displayValue: this.translateService.instant('language.it')
        },
        {
          label: this.translateService.instant('language.po'),
          value: 'por',
          isSelected: false,
          displayValue: this.translateService.instant('language.po')
        },
        {
          label: this.translateService.instant('language.de'),
          value: 'deu',
          isSelected: false,
          displayValue: this.translateService.instant('language.de')
        },
        {
          label: this.translateService.instant('language.nl'),
          value: 'nld',
          isSelected: false,
          displayValue: this.translateService.instant('language.nl')
        },
      ],
      subjects: this.generateSubjects()
      ,
      type: [
        {
          label: this.translateService.instant('boclips.podcast'),
          value: 'PODCAST',
          isSelected: false,
          displayValue: this.translateService.instant('boclips.podcast')
        },
        {
          label: this.translateService.instant('boclips.news'),
          value: 'NEWS',
          isSelected: false,
          displayValue: this.translateService.instant('boclips.news')
        },
        {
          label: this.translateService.instant('boclips.instructional'),
          value: 'INSTRUCTIONAL',
          isSelected: false,
          displayValue: this.translateService.instant('boclips.instructional')
        },
        {
          label: this.translateService.instant('boclips.stock'),
          value: 'STOCK',
          isSelected: false,
          displayValue: this.translateService.instant('boclips.stock')
        }
      ]
    };
  }

  ngOnInit(): void {
    this.company = this.storageService.get('company');
    this.loadMore();
  }

  public loadMore(): void {
    this.disabled = true;
    this.loading = true;
    const params = {
      company_id: this.company.id,
      pages: this.pages,
      total_pages: this.totalPages
    }
    //update content after first pass
    if (this.firstPass === true) {
      this.auxContents.forEach(element => {
        this.contents.push(element);
      });
      this.auxContents = [];
    }
    //first call
    this.contentProvider.getBoclip(params).pipe(take(1)).subscribe(data => {
      if (this.firstPass === false) {
        this.contents = data.data;
      };
      this.totalPages = data.totalPages;
      this.pages.push(data.page);
      this.loading = false;
      this.firstPass = true;
      //second call
      this.contentProvider.getBoclip(params).pipe(take(1)).subscribe(auxData => {
        this.disabled = false;
        this.totalPages = auxData.totalPages;
        this.pages.push(auxData.page);
        auxData.data.forEach(element => {
          this.auxContents.push(element);
        });
      })
    });
  }

  public onSet(value: any, datum: any, key: string): void {
    datum.isSelected = value;
    this.filters[key] = [...this.filters[key]];
    this.verifyFilters();
  }

  public selectLoadMethod(): void {
    if(this.isFilterActive) {
      this.filter();
    } else {
      this.loadMore();
    }
  }

  public filter(newFilter?: boolean): void {
    this.disabled = true;
    this.loading = true;

    if(newFilter)
    this.verifyFilters(true);

    if(!this.isFilterActive) {
      this.contents = [];
      this.auxContents = [];
    }

    let filterData = {
      company_id: this.company.id,
      search: {
        q: this.search,
        lang: this.getSelectedDisplayValues('languages'),
        type: this.getSelectedDisplayValues('type'),
        subject: this.getSelectedDisplayValues('subjects')
      },
      page: this.pageCount
    }

    //fill new values
    this.auxContents.forEach(element => {
      this.contents.push(element)
    });
    //check for filters
    if (
      filterData.search.q.length >= 1 ||
      filterData.search.lang.length >= 1 ||
      filterData.search.type.length >= 1 ||
      filterData.search.subject.length >= 1
    ) {
      //first filter call
      this.contentProvider.filterBoclip(filterData).pipe(take(1)).subscribe(data => {
        if(!this.isFilterActive){
          this.contents = data.data;
        }
        this.loading = false;
        //second filter call
        filterData.page ++;
        this.contentProvider.filterBoclip(filterData).pipe(take(1)).subscribe(auxData => {
          this.disabled = false;
          this.isFilterActive = true;
          auxData.data.forEach(element => {
            this.auxContents.push(element);
          });
        })
      })
    } 

    //restone normal behavior on no filters
      else {
      this.verifyFilters();
    }
  }

  public getSelectedDisplayValues(filterKey: string): any {
    const filter = this.filters[filterKey];
    if (filterKey === 'languages') {
      if (!filter || !Array.isArray(filter)) {
        return [];
      }

      return filter
        .filter((item: any) => item.isSelected)
        .map((item: any) => item.value);
    } else {
      if (!filter || !Array.isArray(filter)) {
        return '';
      }

      const selectedValues = filter
        .filter((item: any) => item.isSelected)
        .map((item: any) => item.value);

      return selectedValues.join(', ');
    }
  }

  public generateSubjects(): any {
    const json = {
      "_embedded": {
        "subjects": [
          {
            "id": "5cb1f6ba5c9cb675c59df378",
            "name": "Elementary Literacy",
            "categories": ["YNF", "YNL", "YB", "YD", "YNB", "YF", "YPC"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df378",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df37a",
            "name": "Performing Arts",
            "categories": ["AT"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df37a",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df37d",
            "name": "Politics and Government",
            "categories": ["JP"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df37d",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df37e",
            "name": "Education",
            "categories": ["JN"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df37e",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df37f",
            "name": "General Mathematics",
            "categories": ["PB"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df37f",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df380",
            "name": "Computing and Information Technology",
            "categories": ["U"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df380",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df381",
            "name": "Literature and Fiction",
            "categories": ["D", "F", "X"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df381",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df383",
            "name": "Religion",
            "categories": ["QR"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df383",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6ba5c9cb675c59df385",
            "name": "General Biology",
            "categories": ["PS"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6ba5c9cb675c59df385",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce6c",
            "name": "Psychology",
            "categories": ["JM"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce6c",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce6d",
            "name": "Chemistry",
            "categories": ["PN"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce6d",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce6f",
            "name": "History",
            "categories": ["NH"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce6f",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce71",
            "name": "The Arts",
            "categories": ["AK", "AB", "AF", "AG", "AJ", "YPA", "AM"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce71",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce72",
            "name": "Economics",
            "categories": ["KC"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce72",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce73",
            "name": "Sociology",
            "categories": ["JW", "JB", "JH"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce73",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce74",
            "name": "Geography and Earth Science",
            "categories": ["RB", "RG"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce74",
                "templated": false
              }
            }
          },
          {
            "id": "5cb1f6bad9cecb5460d5ce76",
            "name": "Physics",
            "categories": ["PH"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cb1f6bad9cecb5460d5ce76",
                "templated": false
              }
            }
          },
          {
            "id": "5cdd6fff0ea063146ed67fc2",
            "name": "Industry, Architecture and Engineering",
            "categories": ["AM", "KN", "T", "U", "WG"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cdd6fff0ea063146ed67fc2",
                "templated": false
              }
            }
          },
          {
            "id": "5cdd7000c0a01f670d4c6f41",
            "name": "Agriculture and Farming",
            "categories": ["TV"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cdd7000c0a01f670d4c6f41",
                "templated": false
              }
            }
          },
          {
            "id": "5cdd7000c0a01f670d4c6f42",
            "name": "Family and Health",
            "categories": ["VF"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cdd7000c0a01f670d4c6f42",
                "templated": false
              }
            }
          },
          {
            "id": "5cdd70010ea063146ed67fc4",
            "name": "Law",
            "categories": ["L"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cdd70010ea063146ed67fc4",
                "templated": false
              }
            }
          },
          {
            "id": "5cdd70020ea063146ed67fc6",
            "name": "Elementary Health Education",
            "categories": ["YNW", "YX"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cdd70020ea063146ed67fc6",
                "templated": false
              }
            }
          },
          {
            "id": "5cdd7002c0a01f670d4c6f44",
            "name": "Sports and Recreation",
            "categories": ["S"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cdd7002c0a01f670d4c6f44",
                "templated": false
              }
            }
          },
          {
            "id": "5cdd7003c0a01f670d4c6f45",
            "name": "Music",
            "categories": ["AV"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5cdd7003c0a01f670d4c6f45",
                "templated": false
              }
            }
          },
          {
            "id": "5e73824e8b2b0d7c559680a1",
            "name": "Language and Linguistics",
            "categories": ["C"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5e73824e8b2b0d7c559680a1",
                "templated": false
              }
            }
          },
          {
            "id": "5e73824e8b2b0d7c559680a3",
            "name": "Communication and Media Studies",
            "categories": ["G", "JBCT"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5e73824e8b2b0d7c559680a3",
                "templated": false
              }
            }
          },
          {
            "id": "5e7382528b92e353df0e5a1b",
            "name": "Anthropology",
            "categories": ["JHM"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5e7382528b92e353df0e5a1b",
                "templated": false
              }
            }
          },
          {
            "id": "5e7382558b92e353df0e5a1d",
            "name": "Statistics",
            "categories": ["PBT"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5e7382558b92e353df0e5a1d",
                "templated": false
              }
            }
          },
          {
            "id": "5e7382568b92e353df0e5a1e",
            "name": "Finance",
            "categories": ["KFF"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/5e7382568b92e353df0e5a1e",
                "templated": false
              }
            }
          },
          {
            "id": "61938b507fe0fc7ea7adb65f",
            "name": "Archaeology",
            "categories": ["NK"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b507fe0fc7ea7adb65f",
                "templated": false
              }
            }
          },
          {
            "id": "61938b507fe0fc7ea7adb67f",
            "name": "Cultural Studies",
            "categories": ["GT", "JBCC"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b507fe0fc7ea7adb67f",
                "templated": false
              }
            }
          },
          {
            "id": "61938b507fe0fc7ea7adb68b",
            "name": "Urban Planning",
            "categories": ["RP"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b507fe0fc7ea7adb68b",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb697",
            "name": "Criminology",
            "categories": ["JK"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb697",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb6a2",
            "name": "Astronomy",
            "categories": ["PG"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb6a2",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb6af",
            "name": "Science: General Issues",
            "categories": ["PD"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb6af",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb6be",
            "name": "Biochemistry",
            "categories": ["PSB"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb6be",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb6d4",
            "name": "Human Biology",
            "categories": ["PSX"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb6d4",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb6e1",
            "name": "Microbiology",
            "categories": ["PSG"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb6e1",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb6ef",
            "name": "Zoology and Animal Sciences",
            "categories": ["PSV"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb6ef",
                "templated": false
              }
            }
          },
          {
            "id": "61938b517fe0fc7ea7adb6fb",
            "name": "Ecology and Environmental Studies",
            "categories": ["RN"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b517fe0fc7ea7adb6fb",
                "templated": false
              }
            }
          },
          {
            "id": "61938b527fe0fc7ea7adb709",
            "name": "Accounting",
            "categories": ["KFC"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b527fe0fc7ea7adb709",
                "templated": false
              }
            }
          },
          {
            "id": "61938b527fe0fc7ea7adb714",
            "name": "Management",
            "categories": ["KJM"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b527fe0fc7ea7adb714",
                "templated": false
              }
            }
          },
          {
            "id": "61938b527fe0fc7ea7adb720",
            "name": "Industry and Industrial Studies",
            "categories": ["KN"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b527fe0fc7ea7adb720",
                "templated": false
              }
            }
          },
          {
            "id": "61938b527fe0fc7ea7adb72d",
            "name": "Lifestyle",
            "categories": ["W"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b527fe0fc7ea7adb72d",
                "templated": false
              }
            }
          },
          {
            "id": "61938b527fe0fc7ea7adb740",
            "name": "Personal Development",
            "categories": ["VX", "VS"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b527fe0fc7ea7adb740",
                "templated": false
              }
            }
          },
          {
            "id": "61938b527fe0fc7ea7adb75a",
            "name": "Clinical Pre-clinical and Internal Medicine",
            "categories": ["MF", "MJ"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b527fe0fc7ea7adb75a",
                "templated": false
              }
            }
          },
          {
            "id": "61938b537fe0fc7ea7adb771",
            "name": "Medicine: General Issues",
            "categories": ["MX", "MB", "MR"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b537fe0fc7ea7adb771",
                "templated": false
              }
            }
          },
          {
            "id": "61938b537fe0fc7ea7adb77d",
            "name": "Medicine: Specialty Issues",
            "categories": ["MN", "MK"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b537fe0fc7ea7adb77d",
                "templated": false
              }
            }
          },
          {
            "id": "61938b537fe0fc7ea7adb788",
            "name": "Veterinary Medicine",
            "categories": ["MZ"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938b537fe0fc7ea7adb788",
                "templated": false
              }
            }
          },
          {
            "id": "61938f877fe0fc7ea7ae5df4",
            "name": "Algebra",
            "categories": ["PBF"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938f877fe0fc7ea7ae5df4",
                "templated": false
              }
            }
          },
          {
            "id": "61938f877fe0fc7ea7ae5e02",
            "name": "Calculus",
            "categories": ["PBK"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938f877fe0fc7ea7ae5e02",
                "templated": false
              }
            }
          },
          {
            "id": "61938f877fe0fc7ea7ae5e16",
            "name": "Geometry",
            "categories": ["PBM"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938f877fe0fc7ea7ae5e16",
                "templated": false
              }
            }
          },
          {
            "id": "61938f877fe0fc7ea7ae5e21",
            "name": "Applied Mathematics",
            "categories": ["PBW"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61938f877fe0fc7ea7ae5e21",
                "templated": false
              }
            }
          },
          {
            "id": "619391677fe0fc7ea7aeb090",
            "name": "Business Studies",
            "categories": ["KJ"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619391677fe0fc7ea7aeb090",
                "templated": false
              }
            }
          },
          {
            "id": "619392257fe0fc7ea7aeca2c",
            "name": "Environmental Engineering",
            "categories": ["TQ"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619392257fe0fc7ea7aeca2c",
                "templated": false
              }
            }
          },
          {
            "id": "619392257fe0fc7ea7aeca41",
            "name": "Technology General Issues",
            "categories": ["TB", "TT"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619392257fe0fc7ea7aeca41",
                "templated": false
              }
            }
          },
          {
            "id": "619392257fe0fc7ea7aeca4c",
            "name": "Mechanical Engineering",
            "categories": ["TG"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619392257fe0fc7ea7aeca4c",
                "templated": false
              }
            }
          },
          {
            "id": "619392267fe0fc7ea7aeca59",
            "name": "Civil Engineering",
            "categories": ["TR", "TN"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619392267fe0fc7ea7aeca59",
                "templated": false
              }
            }
          },
          {
            "id": "619392267fe0fc7ea7aeca64",
            "name": "Electrical Engineering",
            "categories": ["TJ"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619392267fe0fc7ea7aeca64",
                "templated": false
              }
            }
          },
          {
            "id": "619392267fe0fc7ea7aeca72",
            "name": "Industrial Engineering",
            "categories": ["TH", "TD"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619392267fe0fc7ea7aeca72",
                "templated": false
              }
            }
          },
          {
            "id": "619392267fe0fc7ea7aeca81",
            "name": "Biochemical Engineering",
            "categories": ["TC"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619392267fe0fc7ea7aeca81",
                "templated": false
              }
            }
          },
          {
            "id": "619cd74a7fe0fc7ea7a62233",
            "name": "General Humanities",
            "categories": ["D", "C", "A", "F", "Q", "X"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619cd74a7fe0fc7ea7a62233",
                "templated": false
              }
            }
          },
          {
            "id": "619cd74a7fe0fc7ea7a62256",
            "name": "General Social Sciences",
            "categories": ["G", "L", "J", "Q", "N"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619cd74a7fe0fc7ea7a62256",
                "templated": false
              }
            }
          },
          {
            "id": "619cd74b7fe0fc7ea7a62270",
            "name": "Anatomy and Physiology",
            "categories": ["MFC"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619cd74b7fe0fc7ea7a62270",
                "templated": false
              }
            }
          },
          {
            "id": "619cef1b7fe0fc7ea7aeb020",
            "name": "Nursing",
            "categories": ["MQ"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/619cef1b7fe0fc7ea7aeb020",
                "templated": false
              }
            }
          },
          {
            "id": "61a0c1337fe0fc7ea7a2fa3b",
            "name": "Medicine and Nursing",
            "categories": ["M"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61a0c1337fe0fc7ea7a2fa3b",
                "templated": false
              }
            }
          },
          {
            "id": "61a0c28c7fe0fc7ea7a329d7",
            "name": "General Business and Economics",
            "categories": ["K"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61a0c28c7fe0fc7ea7a329d7",
                "templated": false
              }
            }
          },
          {
            "id": "61a0c2d07fe0fc7ea7a3e00f",
            "name": "Life Sciences",
            "categories": ["RN", "PS"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61a0c2d07fe0fc7ea7a3e00f",
                "templated": false
              }
            }
          },
          {
            "id": "61a0c3137fe0fc7ea7a5011a",
            "name": "Physical Sciences",
            "categories": ["RB", "PH", "PD", "PN", "PG", "RG"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61a0c3137fe0fc7ea7a5011a",
                "templated": false
              }
            }
          },
          {
            "id": "61a0c4287fe0fc7ea7a93852",
            "name": "Philosophy and Religion",
            "categories": ["Q"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61a0c4287fe0fc7ea7a93852",
                "templated": false
              }
            }
          },
          {
            "id": "61a0e3ba7fe0fc7ea7b2c2cb",
            "name": "Health, Relationships and General Education",
            "categories": ["V"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61a0e3ba7fe0fc7ea7b2c2cb",
                "templated": false
              }
            }
          },
          {
            "id": "61f7f4a32429f56e7d6a80a9",
            "name": "Elementary Technology Education",
            "categories": ["YPMT", "YNT"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61f7f4a32429f56e7d6a80a9",
                "templated": false
              }
            }
          },
          {
            "id": "61f7f4ca2429f56e7d6a8d47",
            "name": "Elementary Social Studies",
            "categories": ["YNH", "YPJ", "YNJ", "YNK", "YNM", "YR", "YNR"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61f7f4ca2429f56e7d6a8d47",
                "templated": false
              }
            }
          },
          {
            "id": "61f7f4e32429f56e7d6a9518",
            "name": "Physical Sciences",
            "categories": ["YPMP3", "YPMP5"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61f7f4e32429f56e7d6a9518",
                "templated": false
              }
            }
          },
          {
            "id": "61f7f5e72429f56e7d6b1700",
            "name": "Life Sciences",
            "categories": ["YPMP6", "YNN", "YPMP1"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61f7f5e72429f56e7d6b1700",
                "templated": false
              }
            }
          },
          {
            "id": "61f7f5e82429f56e7d6b1715",
            "name": "Elementary Mathematics",
            "categories": ["YPMF"],
            "_links": {
              "self": {
                "href": "https://api.boclips.com/v1/subjects/61f7f5e82429f56e7d6b1715",
                "templated": false
              }
            }
          }
        ]
      },
      "_links": {
        "self": {
          "href": "https://api.boclips.com/v1/subjects",
          "templated": false
        }
      }
    }
      ;

    const resultArray = [];

    json._embedded.subjects.forEach(subject => {
      const subjectName = subject.name.toLowerCase().replace(/\s+/g, '-').replace(',', '').replace(':', '');
      const translate = `boclips.${subjectName}`;

      const newObj = {
        label: this.translateService.instant(translate),
        value: subject.id,
        isSelected: false,
        displayValue: this.translateService.instant(translate)
      };
      resultArray.push(newObj);
    });
    return resultArray;
  }

  public verifyFilters(force?: boolean): void {
    if(force) {
      this.pages = [];
      this.totalPages = null;
      this.contents = [];
      this.auxContents = [];
      this.isFilterActive = false;
      this.pageCount = 1;
      this.firstPass = false;
    }
    if (
      this.getSelectedDisplayValues('languages').length == 0 &&
      this.getSelectedDisplayValues('type').length == 0 &&
      this.getSelectedDisplayValues('subjects').length == 0 &&
      this.search === ''
    ) {
      this.pages = [];
      this.totalPages = null;
      this.contents = [];
      this.auxContents = [];
      this.isFilterActive = false;
      this.pageCount = 1;
      this.firstPass = false;
      this.loadMore();
    }
  }
}
