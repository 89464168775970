<div class="content-configuration__content">
    <h3>{{'words.role' | translate}}</h3>

    <div class="content-configuration__dataset">
        <div class="content-configuration__input">
            <div class="content-configuration__group__checkbox">
                <div class="content-configuration__item__checkbox flex-col">
                    <div class="flex items-center text-black-400 gap-2">
                        <msc-common2-checkbox [value]="roles.learn.status" (changeEvent)="roles.learn.status = !roles.learn.status"></msc-common2-checkbox>
                        <i class="icon icon-student"></i>
                        <span>{{ 'words.learn' | translate }}</span>
                    </div>

                    <div class="checkbox__container" *ngIf="roles.learn.status">
                        <div class="content-configuration__dataset">
                            <div class="content-configuration__input col-md-3 col-sm-4 col-xs-6">
                                <msc-common-select [data]="selectData.campsLearn" type="multi" hasSearch="true">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="flex items-center text-sm text-black-400 w-full">
                                            <ng-container *ngIf="data?.length; else emptyTemplate">
                                                <i class="icon icon-camp"></i>
                                                <span class="truncate ml-2">{{ roles.learn.campsSelectedLabel }}</span>
                                            </ng-container>
                                            <ng-template #emptyTemplate>
                                                <i class="icon icon-camp"></i>
                                                <span class="ml-2">{{ 'words.select.camp' | translate }}</span>
                                            </ng-template>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <label class="flex items-center justify-between text-sm py-2 w-full">
                                            <div class="flex items-center mr-1">
                                                <i class="icon icon-camp icon-camp-option"></i>
                                                <span>{{ datum.label }}</span>
                                            </div>
                                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectOption(datum, roles.learn, 'campsLearn')"></msc-common2-checkbox>
                                        </label>
                                    </ng-template>
                                </msc-common-select>
                            </div>

                            <div class="flex align-center wrap">
                                <div class="content-configuration__tag" *ngFor="let camp of roles.learn.campsSelected">
                                    <p>{{ camp.name }}</p>

                                    <span (click)="updateCamp(camp, roles.learn, 'campsLearn')">
                                        <i class="icon icon-close"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content-configuration__item__checkbox flex-col">
                    <div class="flex items-center gap-2 text-black-400">
                        <msc-common2-checkbox [value]="roles.organise.status" (changeEvent)="roles.organise.status = !roles.organise.status"></msc-common2-checkbox>
                        <i class="icon icon-calendar"></i>
                        <span>{{ 'words.organise' | translate }}</span>
                    </div>

                    <div class="checkbox__container" *ngIf="roles.organise.status">
                        <div class="content-configuration__dataset content-configuration__dataset--no-margin">
                            <div class="content-configuration__input">
                                <label class="content-configuration__label">{{ 'words.super-admin' | translate }}</label>
                                <p class="text-sm text-black-400">{{ 'user.slow-invite.super-admin.description' | translate }}</p>
                                <div class="flex align-center gap-2 my-2">
                                    <msc-common2-switch [value]="roles.organise.super_admin" (changeEvent)="roles.organise.super_admin = !roles.organise.super_admin"></msc-common2-switch>
                                    <p class="text-black-400" *ngIf="!roles.organise.super_admin">{{ 'words.no' | translate  }}</p>
                                    <p class="text-black-400" *ngIf="roles.organise.super_admin">{{ 'words.yes' | translate  }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="content-configuration__dataset" *ngIf="!roles.organise.super_admin">
                            <div class="content-configuration__input col-md-3 col-sm-4 col-xs-6">
                                <msc-common-select [data]="selectData.campsOrganise" type="multi" hasSearch="true">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="flex items-center text-sm text-black-400 w-full">
                                            <ng-container *ngIf="data?.length; else emptyTemplate">
                                                <i class="icon icon-camp"></i>
                                                <span class="truncate ml-2">{{ roles.organise.campsSelectedLabel }}</span>
                                            </ng-container>
                                            <ng-template #emptyTemplate>
                                                <i class="icon icon-camp"></i>
                                                <span class="ml-2">{{ 'words.select.camp' | translate }}</span>
                                            </ng-template>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <label class="flex items-center justify-between text-sm py-2 w-full">
                                            <div class="flex items-center mr-1">
                                                <i class="icon icon-camp icon-camp-option"></i>
                                                <span>{{ datum.label }}</span>
                                            </div>
                                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectOption(datum, roles.organise, 'campsOrganise')"></msc-common2-checkbox>
                                        </label>
                                    </ng-template>
                                </msc-common-select>
                            </div>
                            <div class="content-configuration__configurable-tag">
                                <div class="flex between" *ngFor="let camp of roles.organise.campsSelected">
                                    <div class="content-configuration__tag">
                                        <p>{{camp.name}}</p>

                                        <span (click)="updateCamp(camp, roles.organise, 'campsOrganise')">
                                            <i class="icon icon-close"></i>
                                        </span>
                                    </div>

                                    <div class="content-configuration__item__checkbox">
                                        <msc-common2-checkbox [value]="camp.configure" [label]="'words.job-settings-camp' | translate" (changeEvent)="camp.configure = !camp.configure"></msc-common2-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content-configuration__item__checkbox flex-col">
                    <div class="flex items-center gap-2 text-black-400">
                        <msc-common2-checkbox [value]="roles.create.status" (changeEvent)="roles.create.status = !roles.create.status"></msc-common2-checkbox>
                        <i class="icon icon-color-palette"></i>
                        <span>{{ 'words.create' | translate }}</span>
                    </div>

                    <div class="checkbox__container" *ngIf="roles.create.status">
                        <div class="content-configuration__dataset">
                            <msc-common2-radio class="text-sm text-black-400 mb-2" groupName="userAccess" [(ngModel)]="roles.create.option" [valueName]="false"
                                [label]="'access.create.private' | translate">
                            </msc-common2-radio>
                            <msc-common2-radio class="text-sm text-black-400" groupName="userAccess" [(ngModel)]="roles.create.option" [valueName]="true"
                                [label]="'access.create.company' | translate">
                            </msc-common2-radio>
                        </div>
                    </div>
                </div>

                <div class="content-configuration__item__checkbox flex-col">
                    <div class="flex items-center gap-2 text-black-400">
                        <msc-common2-checkbox [value]="roles.coach.status" (changeEvent)="roles.coach.status = !roles.coach.status"></msc-common2-checkbox>
                        <i class="icon icon-mentor"></i>
                        <span>{{ 'words.coach' | translate }}</span>
                    </div>
                    <div class="checkbox__container" *ngIf="roles.coach.status">
                        <p class="text-sm text-black-400">{{ 'user.slow-invite.coach.description' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h3>{{ 'words.access' | translate }}</h3>
    <div class="content-configuration__dataset">
        <div class="flex">
            <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
                <label class="content-configuration__label">{{ 'words.access-start.date' | translate }}</label>
                <div class="input input--date input--icon-right" [ngClass]="{'active': displayDropdowns.startDate}"
                    [(autoClose)]="displayDropdowns.startDate">
                    <input type="text" readonly="true" name="input" autocomplete="off" class="input__text"
                        (focus)="displayed('startDate'); displayed('endDate', null, false)"
                        [ngModel]="data.accessStartAt | date">

                    <span class="input__icon input__icon--trash" *ngIf="data.accessStartAt"
                        (click)="resetDateInput('accessStartAt')">
                        <i class="icon icon-trash-2"></i>
                    </span>

                    <span class="input__icon">
                        <i class="icon icon-calendar text-secondary-500"></i>
                    </span>

                    <div class="dropdown-datepicker dropdown-datepicker--top">
                        <msc-datepicker [date]="data.accessStartAt" [minDate]="dates.tomorrow"
                            [limit]="{min: dates.now, max: data.accessEndAt}" [actions]="true"
                            (onUpdate)="updateDate('accessStartAt', $event)" (onClose)="displayed('startDate')">
                        </msc-datepicker>
                    </div>
                </div>
            </div>

            <div class="content-configuration__input col-md-3 col-sm-6 col-xs-12">
                <label class="content-configuration__label">{{ 'words.access-end.date' | translate }}</label>
                <div class="input input--date input--icon-right" [ngClass]="{'active': displayDropdowns.endDate}"
                    [(autoClose)]="displayDropdowns.endDate">
                    <input type="text" readonly="true" name="input" autocomplete="off" class="input__text"
                        (focus)="displayed('endDate'); displayed('startDate', null, false)"
                        [ngModel]="data.accessEndAt | date">

                    <span class="input__icon input__icon--trash" *ngIf="data.accessEndAt"
                        (click)="resetDateInput('accessEndAt')">
                        <i class="icon icon-trash-2"></i>
                    </span>

                    <span class="input__icon">
                        <i class="icon icon-calendar text-secondary-500"></i>
                    </span>

                    <div class="dropdown-datepicker dropdown-datepicker--top">
                        <msc-datepicker [date]="data.accessEndAt" [minDate]="data.accessStartAt || dates.tomorrow"
                            [limit]="{min: data.accessStartAt || dates.now}" [actions]="true"
                            (onUpdate)="updateDate('accessEndAt', $event)" (onClose)="displayed('endDate')">
                        </msc-datepicker>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
