import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { ContentProvider, Pagination, Camp } from '@lighty';
import { ParamsService, StorageService } from '@services';
import { HttpHelper } from '@helpers';
import { OrganizeUserStepBaseComponent } from '../configure.component.base';
import { FORMSTEPS } from '../configure.enum';

@Component({
    selector: 'msc-organize-user-configure-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    providers: [{ provide: OrganizeUserStepBaseComponent, useExisting: OrganizeUserConfigureOnboardingComponent }]
})
export class OrganizeUserConfigureOnboardingComponent extends OrganizeUserStepBaseComponent implements OnInit, OnChanges {
    @Input() selectedCamps: Camp[] = [];
    @Input() label;
    @Input() context = 'default';
    @Output() onValidate: EventEmitter<any> = new EventEmitter();

    private me: any;
    private company: any;

    phase = 0;

    public filters: any = {};
    public activeFilters: boolean = false;
    public query: string;
    public buildFilters: EventEmitter<any> = new EventEmitter();
    pagination: any = new Pagination({
        perPage: 10,
    });
    catalogue: any[] = [];
    loading: boolean;
    selectedContent: any;
    onboardingSessionList: any;
    onboardingSessionSelection = {
        course: null
    };
    public hasNavigation = true;
    lastSelected;

    public data = {
        context: '',
        id: null,
        session_ids: []
    };


    validOnboarding = false;

    constructor(private contentProvider: ContentProvider, private paramsService: ParamsService, private storageService: StorageService) {
        super();

        this.formId = FORMSTEPS.ONBOARDING_CONTENT;
    }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.lastSelected = this.storageService.getCache('onboarding.last_choice');
        this.loadMore();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedCamps && this.company?.id) {
            this.search();
        }
    }

    search(query?: string): void {
        this.loading = true;
        const params = this.paramsService.getOnboarding(this.filters, this.query || query, this.selectedCamps.map(camp => camp.id));
        this.contentProvider.getOnboardingContents(this.company.id, params).subscribe((data) => {
            this.pagination = data.pagination;
            this.catalogue = data.catalogue.filter(content => content.data.id !== this.lastSelected?.data.id);
            this.loading = false;
        });
    }

    loadMore(): void {
        this.loading = true;

        const params = this.paramsService.getOnboarding(this.filters, null, this.selectedCamps.map(camp => camp.id), this.pagination);

        this.contentProvider.getOnboardingContents(this.company.id, params).subscribe((data) => {
            this.catalogue = this.catalogue.concat(data.catalogue).filter(content => content.data.id !== this.lastSelected?.data.id);

            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    filter(provider: any): void {
        const params = {
            company: false
        };
        params[provider.id] = true;

        const filter = {
            providers: params
        };

        this.buildFilters.emit(filter);
    }

    selectContent(content?: any): void {
        this.validOnboarding = true;
        this.onboardingSessionList = null;

        if (!content) {
            this.selectedContent = this.selectedContent === 'skip' ? null : 'skip';
            this.isValid();
            return;
        }

        if (content.data.id === this.selectedContent?.data?.id) {
            this.selectedContent = null;
        } else {
            this.selectedContent = content;
            this.data.context = content.type;
            this.data.id = content.data.id;
            if (content.type !== 'external_content') {
                const params = HttpHelper.cleanParams({
                    context: content.type,
                    camp_ids: this.selectedCamps.length > 0 ? this.selectedCamps.map(camp => camp.id).join(',') : null
                });
                this.contentProvider.getOnboardingContentSessions(this.company.id, content.data.id, params).subscribe((data) => {
                    this.onboardingSessionList = data;
                    this.phase = 1;
                    this.checkIfOneAndSelect();
                });
            }
        }
    }

    private storeSelectedContent(): void {
        this.storageService.setCache('onboarding.last_choice', this.selectedContent, 10);
    }

    selectInstance(session: any, ctx?: any): void {
        if (!ctx) {
            this.onboardingSessionSelection['course'] = session;
            this.validOnboarding = true;
        } else {
            this.onboardingSessionSelection[ctx] = session;
            this.validOnboarding = this.checkIfAtleastOneSelection();
        }
    }

    checkIfAtleastOneSelection(): boolean {
        return Object.entries(this.onboardingSessionSelection).every(([key, val]) => key !== 'course' ? val : true);
    }

    checkIfOneAndSelect(): void {
        if (this.data.context === 'course_template') {
            if (this.onboardingSessionList.length === 1) {
                this.onboardingSessionList.forEach(element => {
                    element.selected = false;
                });
                this.selectInstance(this.onboardingSessionList[0]);
            }
        } else {
            this.onboardingSessionList.forEach(mod => {
                if (mod.instances?.length === 1) {
                    this.selectInstance(mod.instances[0], mod.id);
                } else if (mod.instances?.length > 1) {
                    mod.display = true;
                }
            });
        }
    }

    back(): void {
        this.onboardingSessionSelection = {
            course: null
        };
        this.validOnboarding = false;
        this.phase = 0;
    }

    isValid(): boolean {
        if (this.selectedContent === 'skip') {
            this.onValidate.emit(null);
            return;
        }

        this.storeSelectedContent();

        if (this.data.context === 'external_content') {
            this.onValidate.emit(this.data);
            return;
        } else if (this.data.context === 'course_template') {
            this.data.session_ids = this.onboardingSessionSelection['course'] ? [this.onboardingSessionSelection['course'].id] : [];
        } else {
            this.data.session_ids = Object.values(this.onboardingSessionSelection).filter(sel => sel).map(sel => sel.id);
        }

        this.onValidate.emit(this.data);
    }

    public toApiData() {
        return this.selectedContent !== 'skip' ? {
            onboarding: {
                context: this.data.context,
                id: this.data.id,
                session_ids: this.data.session_ids
            }
        } : null
    }

    public toReadableData() {
        return {
            label: 'words.onboarding',
            data: (this.selectedContent && this.selectedContent !== 'skip') ? [
                this.selectedContent.type === 'external_content' ? this.selectedContent.data.title :
                    {
                        label: this.selectedContent.data.title || 'words.untitled-content',
                        data: Object.values(this.onboardingSessionSelection).filter(sel => sel).map(sel => sel.internalName || sel.title || 'words.instance-without-title')
                    },
            ] : ['onboarding.skip']
        }
    }
}
