<msc-common-aside #aside>
    <ng-template #headerTemplate>
        <h3 class="flex">
            <a (click)="changeTab('list')">
                <i *ngIf="tab === 'users' || selectedTrainer" class="icon icon-arrow-ios-left"></i>
            </a>
            {{ (tab === 'list' ? 'instance.members-list' : 'instance.add-member') | translate }}
        </h3>

        <div>{{ externalContent.title }}</div>
    </ng-template>

    <ng-template #asideTemplate>
        <div class="flex between mb-4">
            <ng-container *ngIf="tab === 'list' && !selectedTrainer">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
            </ng-container>
            
            <ng-container *ngIf="tab === 'users'">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="searchAvailable($event.value)"></msc-common-search>
            </ng-container>

            <ng-container *ngIf="tab === 'list' && externalContent">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-plus-circle" (click)="changeTab('users')">
                    {{ 'words.add' | translate }}
                </msc-button>
            </ng-container>
        </div>

        <div class="dynamic-table" *ngIf="tab === 'list' && !selectedTrainer">
            <div class="dynamic-table__head">
                <div class="dynamic-table__cell cell-title--small">
                    <div>{{ 'words.name' | translate }}</div>
                </div>

                <div class="dynamic-table__cell">
                    {{ 'words.last-connection' | translate }}
                </div>

                <div class="dynamic-table__cell cell-actions"></div>
            </div>

            <div class="dynamic-table__body">
                <div class="dynamic-table__row row-radius row-shadow" *ngFor="let trainer of members.trainers">
                    <div class="dynamic-table__cell cell-title--small">
                        <div class="avatar-profile">
                            <msc-common-avatar [label]="trainer.name"
                                [pictureURL]="trainer.picture ? (trainer.picture + '?size=128') : null">
                            </msc-common-avatar>
                            <span></span>
                            <p class="ml-2">{{ trainer.name }}</p>
                        </div>
                    </div>

                    <div class="dynamic-table__cell" *ngIf="trainer.lastConnection">
                        {{ trainer.lastConnection | timeAgo }}
                    </div>

                    <div class="dynamic-table__cell" *ngIf="!trainer.lastConnection">
                        {{ 'words.no-connection' | translate }}
                    </div>

                    <div class="dynamic-table__cell cell-actions">
                        <a (click)="removeTrainer(trainer)">
                            <i class="cell-actions__icon icon icon-trash"></i>
                        </a>
                    </div>
                </div>

                <msc-load-more [loading]="!ready" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
            </div>
        </div>

        <div *ngIf="tab === 'users' && externalContent">
            <div class="box box-add-participant flex align-center" *ngFor="let account of members.availableTrainers">
                <msc-common2-checkbox [value]="isChecked(account.id)" (changeEvent)="checkMember(account.id)"></msc-common2-checkbox>

                <div class="avatar-profile ml-2">
                    <msc-common-avatar class="mr-2" [label]="account.name"
                        [pictureURL]="account.picture ? (account.picture + '?size=128') : null">
                    </msc-common-avatar>
                    <p>
                        {{ account.name }} <span></span>
                    </p>
                </div>
            </div>

            <msc-load-more [loading]="!ready" [pagination]="userPagination" (onLoad)="loadMoreAvailableUsers()"></msc-load-more>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <ng-container *ngIf="tab === 'users' && externalContent">
            <msc-button class="msc-button--primary" palette="primary" (click)="addTrainer()">{{ 'words.confirm' | translate }}</msc-button>
        </ng-container>
    </ng-template>
</msc-common-aside>