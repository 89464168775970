import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, ManageConversationProvider } from '@lighty';
import { StorageService } from '@services';

@Component({
    selector: 'msc-manage-instance-conversation',
    templateUrl: './conversation.component.html',
    styleUrls: ['./conversation.component.scss']
})
export class ManageInstanceConversationComponent implements OnInit {
    private company: Company;
    public me: any;
    public instance: any;
    public displays: any;
    public members: any;
    public paginationCollection: any;
    public selectedUser: any;
    public loaders = {
        learners: false,
        managers: false
    };

    constructor(private route: ActivatedRoute, private router: Router, private conversationProvider: ManageConversationProvider,
                private storageService: StorageService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.instance = this.route.parent.snapshot.data.instance;

        this.displays = {
            online: {
                managers: false,
                learners: false
            },
            all: {
                managers: false,
                learners: true
            },
            modals: {
                group: false
            },
            asides: {
                mail: false
            }
        };

        this.members = {
            managers: [],
            learners: []
        };

        this.paginationCollection = {
            managers: {},
            learners: {}
        };

        this.getMembers('managers');
        this.getMembers('learners');
    }

    getMembers(type: string): void {
        const params = {
            context: this.instance.type,
            member_type: type
        };

        this.conversationProvider.getMembers(this.company.id, this.instance.id, params).subscribe((data) => {
            this.paginationCollection[type] = data.pagination;
            this.members[type] = data.accounts;
        });
    }

    onLoadMoreMembers(type: string): void {
        const params = {
            context: this.instance.type,
            member_type: type,
            page: this.paginationCollection[type].currentPage + 1
        };
        this.loaders[type] = true;
        this.conversationProvider.getMembers(this.company.id, this.instance.id, params).subscribe((data) => {
            this.paginationCollection[type] = data.pagination;
            for (const account of data.accounts) {
                this.members[type].push(account);
            }
            this.loaders[type] = false;
        });
    }

    conversationWith(user: any): void {
        this.storageService.set('conversationUsers', [user]);
        this.router.navigate(['conversation/create']);
    }

    displayed(key: string, type: string): void {
        this.displays[key][type] = !this.displays[key][type];
    }

    openMailAside(user: any): void {
        this.selectedUser = user;
        this.displayed('asides', 'mail');
    }

    getContext(): string {
        switch (this.instance.type) {
            case 'course_instance':
                return 'course';
            default:
                return this.instance.type;
        }
    }
}
