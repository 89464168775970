<msc-common-aside #aside (saveEvent)="save()" [title]="'words.settings' | translate">
    <ng-template #asideTemplate>
        <div class="slide__form">
            <div class="mt-4">
                <p class="mb-2">{{ 'words.learn' | translate }}</p>

                <msc-common2-radio groupName="applicationLearn" [(ngModel)]="application.learn.enable" [valueName]="false"
                    [label]="'words.standard.support' | translate"></msc-common2-radio>

                <msc-common2-radio groupName="applicationLearn" [(ngModel)]="application.learn.enable" [valueName]="true"
                    [label]="'words.freshchat.code' | translate"></msc-common2-radio>

                <msc-textarea *ngIf="application.learn.enable" rows="5" cols="33" [(ngModel)]="settings.learn.connectionString"></msc-textarea>
            </div>

            <div class="mt-4">
                <p class="mb-2">{{ 'words.create' | translate }}</p>

                <msc-common2-radio groupName="applicationCreate" [(ngModel)]="application.create.enable" [valueName]="false"
                    [label]="'words.standard.support' | translate"></msc-common2-radio>

                <msc-common2-radio groupName="applicationCreate" [(ngModel)]="application.create.enable" [valueName]="true"
                    [label]="'words.freshchat.code' | translate"></msc-common2-radio>

                <msc-textarea *ngIf="application.create.enable" rows="5" cols="33" [(ngModel)]="settings.create.connectionString"></msc-textarea>
            </div>

            <div class="mt-4">
                <p class="mb-2">{{ 'words.coach' | translate }}</p>

                <msc-common2-radio groupName="applicationManage" [(ngModel)]="application.manage.enable" [valueName]="false"
                    [label]="'words.standard.support' | translate"></msc-common2-radio>

                <msc-common2-radio groupName="applicationManage" [(ngModel)]="application.manage.enable" [valueName]="true"
                    [label]="'words.freshchat.code' | translate"></msc-common2-radio>

                <msc-textarea *ngIf="application.manage.enable" rows="5" cols="33" [(ngModel)]="settings.manage.connectionString"></msc-textarea>
            </div>

            <div class="mt-4">
                <p class="mb-2">{{ 'words.organise' | translate }}</p>

                <msc-common2-radio groupName="applicationOrganize" [(ngModel)]="application.organize.enable" [valueName]="false"
                    [label]="'words.standard.support' | translate"></msc-common2-radio>

                <msc-common2-radio groupName="applicationOrganize" [(ngModel)]="application.organize.enable" [valueName]="true"
                    [label]="'words.freshchat.code' | translate"></msc-common2-radio>

                <msc-textarea *ngIf="application.organize.enable" rows="5" cols="33" [(ngModel)]="settings.organize.connectionString"></msc-textarea>
            </div>
        </div>
    </ng-template>
</msc-common-aside>