import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import { DataHelper } from '@helpers';

/**
 * Storage Service
 */
@Injectable({providedIn: 'root'})
export class StorageService {
    /**
     * Storage collection
     */
    private storage: any[] = [];

    /**
     * Import CacheService to persist the data
     * @constructor
     * @param cacheService
     */
    constructor(private cacheService: CacheService) {}

    /**
     * Get data from storage
     * @param {string} key - Key where data is stored
     * @return {any} Stored data
     */
    get(key: string): any {
        return this.storage[key];
    }

    /**
     * Set data in storage
     * @param {string} key - Key where to store the data
     * @param {any} data - Any data you need
     * @return {any} Stored data
     */
    set(key: string, data: any): any {
        this.storage[key] = data;
        return this.get(key);
    }

    /**
     * Get data only once. This data will be deleted
     * @param {string} key - Key where data is stored
     * @return {any} Stored data
     */
    getFlash(key: string): any {
        const storage = this.storage[key];
        this.delete(key);
        return storage;
    }

    /**
     * Delete data
     * @param {string} key - Key where data is stored
     */
    delete(key?: string): void {
        if (key) {
            delete this.storage[key];
        } else {
            this.storage = [];
        }
    }

    /**
     * Get all data from cache
     * @return {any} Stored data
     */
    getAllCache(): any {
        return this.cacheService.getAll();
    }

    /**
     * Get data from cache
     * @param {string} key - Key where data is stored
     * @return {any} Stored data
     */
    getCache(key: string): any {
        return this.cacheService.get(key);
    }

    /**
     * Set data in cache storage
     * @param {string} key - Key where to store the data
     * @param {any} data - Any data you need
     * @param {number} expire - Expiration time
     * @param {boolean} persistence - Persists the data
     * @return {any} Stored data
     */
    setCache(key: string, data: any, expire?: number, persistence?: boolean): any {
        this.cacheService.set(key, data, expire, persistence);
        return this.getCache(key);
    }

    /**
     * Delete data from cache
     * @param {string} key - Key where data is stored
     */
    deleteCache(key: string): void {
        this.cacheService.remove(key);
    }

    /**
     * Get data from local storage
     * @param {string} key - Key where data is stored
     * @return {any} Stored data
     */
    getLocal(key: string): any {
        const value = localStorage.getItem(key);

        if (DataHelper.isJSON(value)) {
            return JSON.parse(value);
        } else {
            return value;
        }
    }

    /**
     * Set data in local storage
     * @param {string} key - Key where to store the data
     * @param {any} data - Any data you need
     * @return {any} Stored data
     */
    setLocal(key: string, data: string|any): any {
        localStorage.setItem(key, typeof data === 'object' ? JSON.stringify(data) : data);

        return this.getLocal(key);
    }

    /**
     * Delete data from local storage
     * @param {string} key - Key where data is stored
     */
    deleteLocal(key: any): void {
        localStorage.removeItem(key);
    }
}
