import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService, TranslateService } from '@services';
import { DataHelper, UrlHelper } from '@helpers';
import { CompanySettingsProvider, CompanySettings, Company, Account } from '@lighty';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-company-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class OrganizeCompanySettingsComponent implements OnInit {
    @ViewChild('upload') upload: ElementRef;
    private me: any;
    public company: Company;
    public settings: CompanySettings;
    public countries: any[];
    public displayDropdowns: any;
    public availableContacts: any[];
    public loading: boolean = false;
    public selectData: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private companySettingsProvider: CompanySettingsProvider,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private renderer: Renderer2,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.settings = this.storageService.get('companySettings') || this.route.parent.parent.snapshot.data.settings;
        this.displayDropdowns = {
            countries: false,
            contacts: false
        };

        this.countries = DataHelper.getCountries(this.translateService.getCurrentLang());
        this.selectData = this.getSelectData(this.countries);
        this.getAvailableContacts();
    }

    private getAvailableContacts(): void {
        this.companySettingsProvider.getAvailableContacts(this.company.slug).subscribe((data) => {
            this.availableContacts = data.map((account) => ({
                label: account.name,
                value: account
            }));

            if (this.settings.contact) {
                this.availableContacts.push({ label: this.settings.contact.name, value: this.settings.contact, isSelected: true });
            }
        });
    }

    getSelectData(countries) {
        return countries.map((country, index) => {
            const id = index;
            const label = country.name;
            const value = country.code;
            const isSelected = country.name === this.getCountry();
            return { id, label, value, isSelected };
        });
    }

    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });

        this.changeCountry(option.value);
    }

    displayed(type: string): void {
        this.displayDropdowns[type] = !this.displayDropdowns[type];
    }

    changeCountry(code: string): void {
        this.settings.country = code;
        this.update();
    }

    update(): void {
        this.companySettingsProvider.update(this.company.slug, this.settings).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    uploadPicture(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.upload.nativeElement).dispatchEvent(event);
    }

    updatePicture(event: any): void {
        this.loading = true;
        if (event.type === 'finished') {
            this.settings.mediaId = event.value.id;
            this.settings.picture = event.value.pictureUrl;
            this.loading = false;
            this.update();
        }

        if (event.type === 'error') {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    deletePicture(): void {
        this.settings.mediaId = null;
        this.update();
    }

    selectContact(contact: Account): void {
        this.availableContacts = this.availableContacts.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.value.id === contact.id;
            return selectDatum;
        });
        this.settings.contactId = contact.id;
        this.settings.contact = contact;
        this.update();
    }

    getCountry(): string {
        return DataHelper.getCountry(this.translateService.getCurrentLang(), this.settings.country);
    }

    redirect(): void {
        if (this.me.language.code === 'fr') {
            UrlHelper.navigate('https://myskillcamp.com/fr/rendez-vous');
        } else {
            UrlHelper.navigate('https://myskillcamp.com/en/appointment');
        }
    }
}
