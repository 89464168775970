<section class="head">
    <h3>Search</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Search</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-search [data]="rows" [key]="'fruit'" [placeholder]="'Search a fruit'" (searchEvent)="onSearch($event)"></msc-common-search>
            </div>
        </div>
        <div class="partition full">
            <div class="segment">
                <msc-common-table [columns]="columns" [rows]="rowsRender">
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-sort [label]="datum.label"></msc-common-th-sort>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <msc-common-th-sort [label]="datum.label"></msc-common-th-sort>
                    </ng-template>

                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.fruit }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.size }}</span>
                    </ng-template>
                </msc-common-table>
            </div>
        </div>
    </div>
</section>
