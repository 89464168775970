import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmitterService, TranslateService } from '@services';
import { SubscriptionCollection } from '@classes';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-account-access-manage',
    templateUrl: './manage.component.html'
})

export class AccountAccessManageComponent {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public user: any;
    public query: string;
    public instancesEmpty: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private emitterService: EmitterService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.user = this.route.parent.parent.snapshot.data.user;

        this.subscriptionCollection.subscribe = this.emitterService.get('instances.empty').subscribe((empty) => {
            if (typeof empty === 'boolean') {
                this.instancesEmpty = empty;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    search(query?): void {
        this.query = query;
        this.emitterService.set('access.manage.search', this.query);
    }

    disableAccess(): void {
        if (!this.user.companyRoles.companyOwner) {
            this.user.companyRoles.companyManager = false;
        }
        const enable: boolean = false;
        this.emitterService.set('update.access', { role: 'companyManager', enable });
        this.toastService.onSuccess(this.translateService.instant('toast.saved'));
    }
}
