<div class="container-integration__form form-filter">
    <div class="container-integration__selection form-selection col-xs-12 col-9">
        <h1 class="container-integration__fieldset__title">{{ 'integrations.rules.categories-title' | translate }}</h1>
        <p class="container-integration__fieldset__description">{{ 'integrations.rules.categories-description' | translate }}</p>

        <ng-container *ngIf="collection.hasSeveralLanguages">
            <h3 class="form-selection__title">{{ 'words.languages' | translate }}</h3>

            <ng-container *ngIf="languages?.length >= 5">
                <div class="form-selection__language flex">
                    <msc-common2-checkbox [value]="checkLanguages()" [label]="'words.select-all' | translate" (changeEvent)="onLanguageChange($event)"></msc-common2-checkbox>
                </div>
            </ng-container>

            <div class="form-selection__language flex">
                <div class="form-selection__checkbox flex" *ngFor="let lang of languages">
                    <msc-common2-checkbox [value]="checkLanguages(lang)" [label]="lang.name" (changeEvent)="onLanguageChange($event, lang)"></msc-common2-checkbox>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="collection.hasSeveralCategories">
            <h3 class="form-selection__title">{{ 'words.category' | translate }}</h3>

            <div class="form-selection__cat">
                <msc-common-spinner *ngIf="loaders.categories"></msc-common-spinner>
                <msc-empty-state [title]="'empty.available.data' | translate" *ngIf="categories?.length === 0"></msc-empty-state>
                <ul>
                    <li *ngFor="let category of categories; let index = index">
                        <div class="form-selection__checkbox flex" [ngClass]="{'active': displays[index]}">
                            <label class="checkbox" [ngClass]="{ 'checkbox--intermediate' : checkCategoryIntermediate(category) }">
                                <input class="checkbox__input" type="checkbox" [checked]="checkCategoryState(category)"  [name]="'category-' + index" [value]="category.id" (change)="onCategoryChange($event, category)">
                                <span class="checkbox__checkmark"></span>
                            </label>
                            <div class="form-selection__label flex between">
                                {{ category.name }}
                                <i class="icon icon-arrow-ios-down" *ngIf="category.subcategories.length > 0" (click)="display(index)"></i>
                                <span tooltip="View" [direction]="index === 0 ? 'left' : 'top'" *ngIf="category.subcategories.length <= 0">
                                    <i class="icon icon-eye" (click)="setSelectedCategory(category); selectionPanelFilteredAside.onOpen(selectedCategory)"></i>
                                </span>
                            </div>
                        </div>

                        <ul class="form-selection__sublist">
                            <li class="form-selection__checkbox flex" *ngFor="let subcategory of category.subcategories; let subindex = index">
                                <label class="checkbox">
                                    <input class="checkbox__input" type="checkbox" [checked]="checkSubcategoryState(subcategory)" [name]="'subcategory- ' + subindex" (change)="onSubCategoryChange($event, category, subcategory)">
                                    <span class="checkbox__checkmark"></span>
                                </label>
                                <div class="form-selection__label flex between">
                                    {{ subcategory.name }}
                                    <span class="tooltip tooltip--top" [attr.data-tooltip]="'words.preview' | translate">
                                        <i class="icon icon-eye" (click)="displaysAside(subcategory, true)"></i>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-container>

        <div class="footer-warning" *ngIf="emptyCategories.length > 0">
            <div class="color--red form-selection__warning">
                <i class="icon icon-alert-triangle"></i>
                {{ 'integrations.empty-category-content' | translate }}
                <div *ngFor="let category of emptyCategories">- {{ category.name }}</div>
            </div>
        </div>
    </div>
</div>

<msc-aside-selection-panel-filtered #selectionPanelFilteredAside [languages]="rule.selectedLanguages"><msc-aside-selection-panel-filtered>
