import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpUtils } from '@common2/utils/http.utils';

import { environment } from '@env/environment';

export interface ApiCompanyUrlItemExperience {
    catalogueDisplay: string;
    displayName: string;
    displayPicture: string;
    id: number;
    name: string;
    slug: string;
    usersCount: number;
}

export interface ApiCompanyUrlItem {
    createdAt: string;
    experienceId: number;
    experiencesCount: 0;
    experiences?: ApiCompanyUrlItemExperience[];
    id: number;
    media?: any;
    status: number;
    type: string;
    url: string;
}

export enum ApiCompanyUrlSubjectStatus {
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

export interface ApiCompanyUrlSubject {
    items: ApiCompanyUrlItem[];
    status: ApiCompanyUrlSubjectStatus;
}

@Injectable({ providedIn: 'root' })
export class CompanyUrlProvider2 {
    private readonly root = `${environment.envVar.API_URL}/admin`;
    private readonly subject: BehaviorSubject<ApiCompanyUrlSubject> = new BehaviorSubject<ApiCompanyUrlSubject>({
        items: [],
        status: ApiCompanyUrlSubjectStatus.LOADING,
    });

    constructor(
        private http: HttpClient,
    ) { }

    /**
     * Set subject of Company Urls
     */
    setSubjectCompanyUrl(datum: Partial<ApiCompanyUrlSubject>, isAppend: boolean = false) {
        const aux = this.subject.getValue();
        Object.keys(datum).forEach((key) => {
            aux[key] = isAppend && Array.isArray(datum[key]) ? [...aux[key], ...datum[key]] : datum[key];
        });
        this.subject.next(aux);
    }

    /**
     * Get subject of Company Urls
     */
    getSubjectCompanyUrl() {
        return this.subject.asObservable();
    }

    /**
     * Gets a list of urls
     */
    getUrls(companyId: number, params: any = {}): Observable<ApiCompanyUrlSubject> {
        return this.http.get<any>(`${this.root}/companies/${companyId}/urls`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((r: HttpResponse<any>) => this.onMapGetUrls(r.body)),
            tap((d: ApiCompanyUrlSubject) => this.onTapGetUrls(d))
        );
    }

    /**
     * Get a specific url
     */
    getUrl(companyId: number, urlId: number, params: any = {}): Observable<any> {
        return this.http.get<any>(`${this.root}/companies/${companyId}/urls/${urlId}`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((r: HttpResponse<any>) => r.body),
        );
    }

    /**
     * Get an available hash
     */
    getHash(companyId: number, params: any = {}): Observable<any> {
        return this.http.get<any>(`${this.root}/companies/${companyId}/urls/hashes`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((r: HttpResponse<any>) => r.body),
        );
    }

    /**
     *
     */
    editUrl(companyId: number, urlId: number, params: any = {}) {
        return this.http.put(`${this.root}/companies/${companyId}/urls/${urlId}`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    editExperience(companyId: number, experienceId: number, params: { url_id: number }) {
        return this.http.put(`${this.root}/companies/${companyId}/experiences/${experienceId}/url`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    deleteUrl(companyId: number, urlId: number) {
        return this.http.delete(`${this.root}/companies/${companyId}/urls/${urlId}`, {
            observe: 'body'
        }).pipe(
            //
        );
    }

    /**
     *
     */
    postUrlCreate(companyId: number, params: any = {}) {
        return this.http.post<any>(`${this.root}/companies/${companyId}/urls`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    postUrlValidate(companyId: number, urlId: number, params: any = {}) {
        return this.http.post<any>(`${this.root}/companies/${companyId}/urls/${urlId}/validate`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    postHashCreate(companyId: number, params: any = {}) {
        return this.http.post<any>(`${this.root}/companies/${companyId}/urls/hashes`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     *
     */
    postNotify(companyId: number, params: any = {}) {
        return this.http.post<any>(`${this.root}/companies/${companyId}/urls/notify-sales`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     * Resolver for map of getUrls
     */
    onMapGetUrls(response: { urls: ApiCompanyUrlItem[] }): ApiCompanyUrlSubject {
        const items = response.urls;
        const status = ApiCompanyUrlSubjectStatus.SUCCESS;
        return { items, status };
    }

    /**
     * Resolver for map of getUrls
     */
    onTapGetUrls(response: ApiCompanyUrlSubject) {
        this.setSubjectCompanyUrl(response);
    }
}
