<section class="head">
    <h3>Toast</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Toast</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-button class="msc-button--primary" palette="primary" (click)="onError()">Error</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onInfo()">Info</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onSuccess()">Success</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onWarning()">Warning</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onActionBlock()">Notification with action (block)</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onActionInline()">Notification with action (inline)</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onButtonBlock()">Notification with button (block)</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onButtonInline()">Notification with button (inline)</msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onInline()">Simple notification (inline)</msc-button>
            </div>
        </div>
    </div>
</section>
