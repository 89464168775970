import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, CompanyUserAccessProvider } from '@lighty';
import { EmitterService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-account-access-create',
    templateUrl: './create.component.html'
})
export class AccountAccessCreateComponent {
    private accountId: number;
    public company: Company;
    public access: any;
    public user: any;

    constructor(
        private route: ActivatedRoute,
        private companyUserAccessProvider: CompanyUserAccessProvider,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.route.parent.parent.snapshot.data.company;
        this.access = this.route.snapshot.data.access;
        this.user = this.route.parent.parent.snapshot.data.user;

        this.route.parent.parent.params.subscribe((params) => {
            this.accountId = parseInt(params.accountId, 10);
        });
    }

    setAccess(enable: boolean): void {
        this.access.accessCompanyCourses = enable;
        this.update();
        this.enableAccess();
    }

    update(): void {
        const params = HttpHelper.cleanParams({
            access_company_courses: this.access.accessCompanyCourses,
            account_id: this.accountId
        });

        this.companyUserAccessProvider.update(this.company.id, params, 'author').subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    enableAccess(enable: boolean = true): void {
        if (!enable) {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        }
        this.user.companyRoles.companyAuthor = enable;
        this.emitterService.set('update.access', { role: 'companyAuthor', enable: this.user.companyRoles.companyAuthor });
    }

    disableAccess(): void {
        this.user.companyRoles.companyAuthor = false;
        this.emitterService.set('update.access', { role: 'companyAuthor', enable: this.user.companyRoles.companyAuthor });
        this.toastService.onSuccess(this.translateService.instant('toast.saved'));
    }
}
