import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { StorageService } from '@services';
import { ReportingProvider } from '../..';

@Injectable()
export class JourneyProvider {

    constructor(private http: HttpClient, private reportingProvider: ReportingProvider, private storageService: StorageService) { }

    get(companyId: number | string, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${companyId}/traject/${journeyId}`, { params }).pipe(
            concatMap((data) => {
                if (params?.page > 1) {
                    return of(data);
                } else {
                    return this.reportingProvider.getContentSubscriptionOverview(this.storageService.get('company').id, journeyId, [this.storageService.get('me').id], 'traject').pipe(
                        map((res) => ({ ...data, session: res[this.storageService.get('me').id]?.context })),
                        catchError(() => of(data)));
                }
            }
            ),
            concatMap((data: any) => this.reportingProvider.getContentSubscriptions(this.storageService.get('company').id, data.contents.map(content => ({ context: content.context, context_id: content.context === 'course' ? content.subscriptionCourseId : content.contextId })), this.storageService.get('me').id).pipe(
                map((res) => {
                    data.contents = data.contents.map((content) => {
                        const stats = res.filter(item => ((content.context === 'course' || content.context === 'external_content') ? item.context.contextId : item.context.sectionContentId) === (content.context === 'course' ? content.subscriptionCourseId : content.contextId))[0];
                        content.session = { ...content.session, ...{ progress: 0, eventDuration: 0, duration: 0, score: 0 }, ...stats?.context }

                        return content;
                    })
                    return data;
                }),
                catchError(() => of(data)))
            )
        );
    }

    getCorrections(companyId: number | string, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${companyId}/traject/${journeyId}/corrections`, { params }).pipe(
            map((data) => data)
        );
    }

    unsubscribe(id: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/subscriptions/trajects/${id}`, { params }).pipe(
            map((data) => data)
        );
    }

    resumeJourney(companyId: number, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${companyId}/traject/${journeyId}/continue`, { params }).pipe(
            map((data) => data)
        );
    }

    getTemplates(companyId: number, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${companyId}/traject/${journeyId}/instances`, { params }).pipe(
            map((data) => data)
        );
    }

    getEvents(companyId: number, journeyId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${companyId}/traject/${journeyId}/events`, { params }).pipe(
            map((data) => data)
        );
    }

    subscribe(companyId: number, journeyId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/company/${companyId}/traject/${journeyId}/users`, params).pipe(
            map((data) => data)
        );
    }
}
