<div class="msc-banner">
    <div class="msc-banner__gradient">
        <div [ngStyle]="{'background-image': 'url(' + background + ')'}"></div>
    </div>
</div>

<div class="container-onboarding__main col-xxs-12 animate">
    <div class="container-onboarding__title">
        <h1>
            <span>{{ 'words.hello' | translate }} {{ me.firstname }} !</span> {{ 'onboarding.welcome' |
            translate:{companyName: company.name} }}
        </h1>
    </div>

    <div class="container-onboarding__content" *ngIf="content">
        <div class="container-onboarding__content__left col-8 col-sm-8 col-xxs-12">
            <div class="box">
                <div class="content__title">
                    <h2>{{ 'onboarding.start' | translate }}</h2>

                    <msc-button class="msc-button--primary" icon="icon-play-circle" palette="secondary"
                        (click)="start()">
                        {{ getTranslate() | translate }}
                    </msc-button>
                </div>

                <div class="content__list">
                    <div class="content__list__item" [ngClass]="{'active': displays.profile}">
                        <div class="item-title" (click)="displayed('profile')">
                            <span class="item-title__title">
                                {{ 'onboarding.profile' | translate }}
                            </span>

                            <div class="item-action">
                                <i class="icon icon-arrow-ios-down"></i>
                            </div>
                        </div>

                        <div class="item-content item-content--shadow">
                            <div class="item-content__title">
                                <div class="flex">
                                    <i class="icon icon-checkmark color--green"></i>

                                    <span class="normal">{{ 'words.interface-language' | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="item-content item-content--shadow">
                            <div class="item-content__title">
                                <div class="flex">
                                    <i class="icon"
                                        [ngClass]="{'icon-close color--red': me.learningLanguages.length === 0, 'icon-checkmark color--green': me.learningLanguages.length > 0}"></i>

                                    <span class="normal">{{ 'words.learning-languages' | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="item-content item-content--shadow">
                            <div class="item-content__title">
                                <div class="flex">
                                    <i class="icon"
                                        [ngClass]="{'icon-close color--red': !me.headline || !me.companyName, 'icon-checkmark color--green': me.headline && me.companyName}"></i>

                                    <span class="normal">{{ 'words.personal.information' | translate }} ({{ 'words.job'
                                        | translate }} & {{ 'words.company-name' | translate }})</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content__list__item" [ngClass]="{'active': displays.content}">
                        <div class="item-title" (click)="displayed('content')">
                            <span class="item-title__title item-title__title--limit">
                                {{ content.title }}
                            </span>

                            <div class="item-data">
                                <i class="icon icon-timer"></i>

                                <span>{{ content.duration || 0 | duration }}</span>

                                <msc-tag [text]="getType() | translate" [color]="getColor()"></msc-tag>
                            </div>

                            <div class="item-action">
                                <i class="icon icon-arrow-ios-down"
                                    *ngIf="onboarding.context === 'traject' || onboarding.context === 'course_instance'"></i>
                            </div>
                        </div>

                        <div *ngFor="let section of content.sections; let index=index">
                            <div class="item-content item-content--shadow"
                                [ngClass]="{'active': displays.sections[index]}">
                                <div class="item-content__title item-content__title--selectable"
                                    (click)="displayed('sections', index)">
                                    <span>{{ section.title }}</span>

                                    <div class="item-content__data">
                                        <i class="icon icon-trending-up"></i>

                                        <div class="data-progress">
                                            <msc-progress-bar [progress]="section.progress?.finishedPercent"
                                                [label]="true" [icon]="false" [force]="true"></msc-progress-bar>
                                        </div>
                                    </div>

                                    <div class="item-content__action">
                                        <i class="icon icon-arrow-ios-down"></i>
                                    </div>
                                </div>

                                <div class="item-content__item" *ngFor="let sectionContent of section.sectionContents">
                                    <span>
                                        <i class="icon" [attr.class]="getContentIcon(sectionContent.context)"></i>

                                        {{ sectionContent.title }}
                                    </span>

                                    <div class="item-content__item__data">
                                        <i class="icon icon-trending-up"></i>

                                        <div class="data-progress">
                                            <msc-progress-bar [progress]="sectionContent.session?.progress"
                                                [label]="true" [icon]="false" [force]="true"></msc-progress-bar>
                                        </div>
                                    </div>

                                    <div class="item-content__empty"></div>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let item of content.contents; let index=index">
                            <div class="item-content item-content--shadow" *ngIf="item.context === 'content'">
                                <div class="item-content__title">
                                    <span>
                                        <i class="icon text-secondary-500"
                                            [attr.class]="getContentIcon(item.playerContext)"></i>

                                        {{ item.data.title || ('words.untitled' | translate) }}
                                    </span>

                                    <div class="item-content__data">
                                        <i class="icon icon-trending-up"></i>

                                        <div class="data-progress">
                                            <msc-progress-bar [progress]="item.session?.progress" [label]="true"
                                                [icon]="false" [force]="true"></msc-progress-bar>
                                        </div>
                                    </div>

                                    <div class="item-content__empty"></div>
                                </div>
                            </div>

                            <div class="item-content-path item-content-path--shadow" *ngIf="item.context === 'course'"
                                [ngClass]="{'active': displays.courses[index]}">
                                <div class="item-content-path__title item-content-path__title--selectable"
                                    (click)="displayed('courses', index)">
                                    <div class="item-path-title">
                                        <div class="item-path-cover"
                                            [ngStyle]="{'background-image': 'url(' + (item.data.picture ? item.data.picture + '?size=512' : 'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + item.data.id % 7 + '.jpg') + ')'}">
                                        </div>

                                        <span>{{ item.data.title || ('words.untitled' | translate) }}</span>
                                    </div>

                                    <div class="item-content-path__data">
                                        <i class="icon icon-trending-up"></i>

                                        <div class="data-progress">
                                            <msc-progress-bar [progress]="item.session?.progress" [label]="true"
                                                [icon]="false" [force]="true"></msc-progress-bar>
                                        </div>
                                    </div>

                                    <div class="item-content-path__action">
                                        <i class="icon icon-arrow-ios-down"></i>
                                    </div>
                                </div>

                                <div *ngIf="courses[item.id]">
                                    <div class="item-content"
                                        *ngFor="let section of courses[item.id].sections; let index=index"
                                        [ngClass]="{'active': displays.sectionContents[item.id + index]}">
                                        <div class="item-content__title item-content__title--selectable"
                                            (click)="displayed('sectionContents', item.id + index)">
                                            <span>{{ section.title || ('words.untitled' | translate) }}</span>

                                            <div class="item-content__data">
                                                <i class="icon icon-trending-up"></i>

                                                <div class="data-progress">
                                                    <msc-progress-bar [progress]="section.progress?.finishedPercent"
                                                        [label]="true" [icon]="false" [force]="true"></msc-progress-bar>
                                                </div>
                                            </div>

                                            <div class="item-content__action">
                                                <i class="icon icon-arrow-ios-down"></i>
                                            </div>
                                        </div>

                                        <div class="item-content__item"
                                            *ngFor="let sectionContent of section.sectionContents">
                                            <span>
                                                <i class="icon text-secondary-500"
                                                    [attr.class]="getContentIcon(sectionContent.context)"></i>

                                                {{ sectionContent.title || ('words.untitled' | translate)}}
                                            </span>

                                            <div class="item-content__item__data">
                                                <i class="icon icon-trending-up"></i>

                                                <div class="data-progress">
                                                    <msc-progress-bar [progress]="sectionContent.session?.progress"
                                                        [label]="true" [icon]="false" [force]="true"></msc-progress-bar>
                                                </div>
                                            </div>

                                            <div class="item-content__item__empty"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="load-more justify-center" *ngIf="content.pagination">
                            <msc-button class="msc-button--secondary" (click)="loadMoreContent()"
                                *ngIf="content.pagination.currentPage < content.pagination.lastPage && !journeyContentLoading">
                                {{ 'words.load-more' | translate }}</msc-button>
                            <msc-common-spinner *ngIf="journeyContentLoading"></msc-common-spinner>
                        </div>
                    </div>

                    <div class="content__list__item" [ngClass]="{'active': displays.features}">
                        <div class="item-title" (click)="displayed('features')">
                            <span class="item-title__title">
                                <i class="icon icon-gift text-secondary-500"></i>

                                {{ 'onboarding.unlock' | translate }}
                            </span>

                            <div class="item-action">
                                <i class="icon icon-arrow-ios-down"></i>
                            </div>
                        </div>

                        <div class="item-content item-content--shadow">
                            <div class="item-content__title">
                                <div class="flex">
                                    <i class="icon"
                                        [ngClass]="{'icon-close color--red': !access.permissions.companyAccessPlatform, 'icon-checkmark color--green': access.permissions.companyAccessPlatform}"></i>

                                    <span>{{ 'words.dashboard' | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="item-content item-content--shadow">
                            <div class="item-content__title">
                                <div class="flex">
                                    <i class="icon"
                                        [ngClass]="{'icon-close color--red': !access.permissions.companyAccessPlatform, 'icon-checkmark color--green': access.permissions.companyAccessPlatform}"></i>

                                    <span>{{ 'words.my-training-courses' | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="item-content item-content--shadow" *ngIf="sidebar.settings.catalogue">
                            <div class="item-content__title">
                                <div class="flex">
                                    <i class="icon"
                                        [ngClass]="{'icon-close color--red': !access.permissions.companyAccessPlatform, 'icon-checkmark color--green': access.permissions.companyAccessPlatform}"></i>

                                    <span>{{ 'words.catalogue' | translate }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="item-content item-content--shadow" *ngIf="sidebar.settings.group">
                            <div class="item-content__title">
                                <div class="flex">
                                    <i class="icon"
                                        [ngClass]="{'icon-close color--red': !access.permissions.companyAccessPlatform, 'icon-checkmark color--green': access.permissions.companyAccessPlatform}"></i>

                                    <span>{{ 'words.communities' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-onboarding__content__right col-4 col-sm-4 col-xxs-12">
            <div class="box box-contact" *ngIf="experience && experience.contact">
                <div class="box-contact__question">
                    <div>{{ 'words.a-question' | translate }}</div>

                    <msc-button class="msc-button--primary" palette="secondary"
                        (click)="conversationWith(experience.contact)">
                        {{ 'words.contact-us' | translate }}
                    </msc-button>
                </div>

                <div class="box-contact__avatar">
                    <div class="avatar-contact">
                        <msc-common-avatar [label]="experience.contact?.name"
                            [pictureURL]="experience.contact?.picture ? (experience.contact?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="avatar__content">
                            <p class="profile__name text-secondary-800">{{ experience.contact.name }}</p>
                            <div class="profile__job" *ngIf="experience.contact.headline">
                                {{ experience.contact.headline }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <msc-timeline [context]="'company'" [contextId]="company.id" [isDashboard]="true" [displayCamp]="true"
                [small]="true" [newPost]="true"></msc-timeline>
        </div>
    </div>
</div>