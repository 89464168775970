import { Component, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of, Observable } from 'rxjs';
import { mergeScan, map, tap, repeat, finalize, takeUntil } from 'rxjs/operators';
//
import { Company, ManageInstanceProvider, CompanyRole } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper, DataHelper } from '@helpers';
//
import { CommonApiContentTypeIconMapping } from '@common2/common.constants';
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSelectDatum } from '@common2/components/select/select.component';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';

@Component({
    selector: 'msc-manage-instances-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [DatePipe],
})

export class ManageInstancesListComponent {
    @Output() onSave: EventEmitter<any> = new EventEmitter();

    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public me: any;
    public roles: CompanyRole;
    public company: Company;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public query$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore: boolean = false;
    public total: number;
    public availableProviders: Array<CommonSelectDatum<any>>;
    public availableLanguages: Array<CommonSelectDatum<any>>;
    public availableTypes: any;
    // crap
    public dropdowns: any;
    public favoriteInstances: any[] = [];
    public defaultInstances: any[] = [];
    public dataFilters: any = {};
    public filters: any;
    public typeFilters: any = {};
    public isFilterActive: boolean = false;
    public isTrainer: boolean = false;
    public selectedInstance: any;
    public isArchived: boolean;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastService: CommonToastService,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
        private readonly manageInstanceProvider: ManageInstanceProvider,
    ) { }

    ngOnInit(): void {
        this.setContent();
        this.resetDropdowns();
        this.getMeta();
        this.onQueryContentList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.roles = this.storageService.get('access').roles;
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: '',
                width: '5%',
            },
            {
                key: '',
                width: '5%',
            },
            {
                key: 'title',
                label: this.translateService.instant('words.name'),
                width: '25%',
            },
            {
                key: 'next_event',
                label: this.translateService.instant('words.next-event'),
                width: '15%',
            },
            {
                key: 'students_count',
                label: this.translateService.instant('words.attendees'),
                width: '15%',
            },
            {
                key: 'status',
                label: this.translateService.instant('words.status'),
                width: '15%',
            },
            {
                key: 'hide',
                label: this.translateService.instant('words.hide'),
                width: '15%',
            },
            {
                key: '',
                width: '5%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryContentList(pageValue: number) {
        return {
            page: pageValue,
            ...this.query$.getValue(),
        };
    }

    /**
     *
     */
    onQueryContentList(isAppend: boolean = true) {
        this.manageInstanceProvider
            .list(this.company.slug, this.getParamsOnQueryContentList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryContentList(response.pagination)),
                map((response) => this.onMapOnQueryContentList(response.instances)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows.sort((x, y) => Number(y.data.favorite) - Number(x.data.favorite)))),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
        this.total = pagination.total;
    }

    /**
     *
     */
    onMapOnQueryContentList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            ...datum,
            icon: CommonApiContentTypeIconMapping.get(datum.type),
            isFavorite: datum.data.favorite,
            dataSelect: this.getDataSelect(datum.data.status),
        }));
    }

    getDataSelect(status: string) {
        return [
            {
                isSelected: status === 'preparation',
                value: 'preparation',
                label: this.translateService.instant('manage.instance.status-preparation'),
            },
            {
                isSelected: status === 'ongoing',
                value: 'ongoing',
                label: this.translateService.instant('manage.instance.status-ongoing'),
            },
            {
                isSelected: status === 'done',
                value: 'done',
                label: this.translateService.instant('manage.instance.status-done'),
            },
        ];
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.page$.next(1);
        const { q, ...query } = this.query$.getValue();
        this.query$.next({ ...query, q: value });
        this.onQueryContentList(false);
    }

    /**
     *
     */
    onSort(key: any, type: CommonThSortType): void {
        this.page$.next(1);
        const { order_by, order_way, ...query } = this.query$.getValue();
        if (type) {
            this.query$.next({ ...query, order_by: key, order_way: type });
        } else {
            this.query$.next({ ...query });
        }
        this.onQueryContentList(false);
    }

    /**
     *
     */
    onLoad(): void {
        if (this.isLoadingMore$.getValue() === CommonSubjectStatus.LOADING)
            return;
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryContentList();
    }

    /**
     *
     */
    goToContent(datum: any) {
        this.router.navigate([this.getContentType(datum.type), datum.data.id], { relativeTo: this.route });
    }

    /**
     *
     */
    onChangeDropdown(value: boolean, datum: CommonSelectDatum<any>) {
        datum.isSelected = value;
    }

    /**
     *
     */
    getContentType(type: string): string {
        switch (type) {
            case 'course_instance':
                return 'template';
            case 'traject':
                return 'journey';
            default:
                return 'external';
        }
    }

    /// crap

    addToFavorite(instance: any): void {
        instance.data.favorite = !instance.data.favorite;
        this.updateInstance(instance)
            .subscribe(() => {
                instance.isFavorite = instance.data.favorite;
            });
    }

    addToArchived(instance: any): void {
        this.archiveInstance(instance);
    }

    archiveInstance(instance: any): void {
        instance.data.hidden = !instance.data.hidden;
        const params = {
            context: instance.type,
            hidden: instance.data.hidden
        };

        this.manageInstanceProvider.archive(this.company.id, instance.data.id, params).subscribe((data) => {
            if (params.hidden) {
                this.toastService.onSuccess(this.translateService.instant('toast.content.hidden'));
            } else {
                this.toastService.onSuccess(this.translateService.instant('toast.content.shown'));
            }
            if (instance.data.favorite) {
                const indexFavorite = this.favoriteInstances.findIndex((instanceFindIndex) => {
                    return instanceFindIndex.data.id === instance.data.id;
                });

                if (indexFavorite !== -1) {
                    this.favoriteInstances.splice(indexFavorite, 1);
                }
            } else {
                const indexNonFavorite = this.defaultInstances.findIndex((instanceFindIndex) => {
                    return instanceFindIndex.data.id === instance.data.id;
                });

                if (indexNonFavorite !== -1) {
                    this.defaultInstances.splice(indexNonFavorite, 1);
                }
            }
        });
    }

    resetDropdowns(): void {
        this.dropdowns = {
            favorite: [],
            default: []
        };
    }

    getMeta(): void {
        this.manageInstanceProvider.getMeta(this.company.id).subscribe((meta) => {
            this.availableTypes = this.getTypesOptions(meta);
            this.availableLanguages = this.getLanguagesOptions(meta.languages);
            this.availableProviders = this.getProvidersOptions(meta.providers);

            this.setFilters();
        });
    }

    getSubTypesOptions(data: any, key: string) {
        const subtypes = data[`${key}Types`];
        if (!subtypes) {
            return [];
        }

        return Object.keys(subtypes)
            .map((subtype, idx) => ({
                id: `${subtype}-${idx}`,
                label: this.translateService.instant(`words.course-type.${subtype}`),
                value: subtype,
                isSelected: false
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getTypesOptions(data: any) {
        return Object.keys(data.contentTypes)
            .map((type, index) => ({
                id: `${type}-${index}`,
                label: this.translateService.instant(`api.content-type.${type.split(/(?=[A-Z])/).join('_').toLowerCase()}`),
                value: type.split(/(?=[A-Z])/).join('_').toLowerCase(),
                isSelected: false,
                children: this.getSubTypesOptions(data, type)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getProvidersOptions(providers) {
        const label = this.company.experience
            ? this.company.experience.displayName
                ? this.company.experience.displayName
                : this.company.experience.name
            : this.company.name;

        return providers.map(provider => ({
            label: provider.id === 'company' ? label : provider.name,
            value: provider.id,
            isChecked: false
        }));
    }

    getLanguagesOptions(languages) {
        const languageIds = this.me.learningLanguages.map(lang => lang.id);

        return languages.map((lang, idx) => ({
            id: idx,
            label: lang.id === 'undefined' ? this.translateService.instant('words.multilingual') : lang.name,
            value: lang.id,
            isSelected: languageIds.includes(lang.id)
        }));
    }

    onFilter() {
        this.page$.next(1);
        const { is_trainer,
            content_types, course_types, external_content_types, external_resource_types,
            status, languages, providers, hidden, favorite,
            ...query } = this.query$.getValue();
        this.query$.next({
            ...query,
            ...this.isTrainer ? { is_trainer: 1 } : {},
            ...this.typeFilters.contentTypes && this.typeFilters.contentTypes.length ? { content_types: this.typeFilters.contentTypes.join(',') } : {},
            ...this.typeFilters.courseTypes && this.typeFilters.courseTypes.length ? { course_types: this.typeFilters.courseTypes.join(',') } : {},
            ...this.typeFilters.externalContentTypes && this.typeFilters.externalContentTypes.length ? { external_content_types: this.typeFilters.externalContentTypes.join(',') } : {},
            ...this.typeFilters.externalResourceTypes && this.typeFilters.externalResourceTypes.length ? { external_resource_types: this.typeFilters.externalResourceTypes.join(',') } : {},
            ...(this.dataFilters.status && this.dataFilters.status.length) ? { status: this.dataFilters.status.join(',') } : {},
            ...(this.dataFilters.langs && this.dataFilters.langs.length) ? { languages: this.dataFilters.langs.join(',') } : {},
            ...(this.dataFilters.providers && this.dataFilters.providers.length) ? { providers: this.dataFilters.providers.join(',') } : {},
            ...this.dataFilters.archived ? { hidden: this.dataFilters.archived } : {},
            ...this.dataFilters.favorite ? { favorite: this.dataFilters.favorite } : {},
        });
        this.onQueryContentList(false);
    }

    resetSort(): void {
        this.query$.next({});
        this.onQueryContentList(false);
    }

    onFilterSave(data) {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.onFilter();
    }

    onFilterReset() {
        this.availableTypes = this.availableTypes.map(type => {
            type.isSelected = false
            type.children.map(subtype => {
                subtype.isSelected = false;
                return subtype;
            });
            return type;
        });

        this.dataFilters = {};
        this.typeFilters = {};
        this.isTrainer = false;
        this.isFilterActive = false;
        this.onFilter();
    }

    setFilters(): void {
        this.filters = [
            {
                name: 'status',
                type: 'checkbox',
                label: this.translateService.instant('words.status'),
                options: [{
                    label: this.translateService.instant('manage.instance.status-preparation'),
                    value: 'preparation',
                    isChecked: false,
                }, {
                    label: this.translateService.instant('manage.instance.status-ongoing'),
                    value: 'ongoing',
                    isChecked: false,
                }, {
                    label: this.translateService.instant('manage.instance.status-done'),
                    value: 'done',
                    isChecked: false,
                }]
            },
            {
                name: 'langs',
                type: 'multiselect',
                label: this.translateService.instant('words.languages'),
                displayValue: this.availableLanguages.filter(lang => lang.isSelected).map(lang => lang.label).join(', '),
                options: this.availableLanguages
            },
            {
                name: 'providers',
                type: 'checkbox',
                label: this.translateService.instant('words.providers'),
                options: this.availableProviders
            },
            {
                name: 'archived',
                type: 'radio',
                label: this.translateService.instant('words.hidden'),
                value: null,
                options: [{
                    label: this.translateService.instant('words.no'),
                    value: 0,
                }, {
                    label: this.translateService.instant('words.yes'),
                    value: 1,
                }]
            },
            {
                name: 'favorite',
                type: 'radio',
                label: this.translateService.instant('words.favorite'),
                value: null,
                options: [{
                    label: this.translateService.instant('words.no'),
                    value: 0,
                }, {
                    label: this.translateService.instant('words.yes'),
                    value: 1,
                }]
            },
        ];
    }

    updateStatus(instance: any, { value }: any): void {
        this.dropdowns.favorite = [];
        this.dropdowns.default = [];
        instance.data.status = value;
        instance.dataSelect = this.getDataSelect(value);
        this.updateInstance(instance).subscribe()
    }

    updateInstance(instance: any): Observable<any> {
        const params = {
            context: instance.type,
            hidden: instance.data.hidden,
            favorite: instance.data.favorite,
            status: instance.data.status
        };

        return this.manageInstanceProvider.update(this.company.id, instance.data.id, params)
            .pipe(
                finalize(() => this.toastService.onSuccess(this.translateService.instant('toast.saved')))
            );
    }

    getIterativeCheck(parent: any, selected: any) {
        if (parent.children && parent.children.length) {
            parent.children = parent.children.map((child: any) => {
                if (parent.id === selected.id) {
                    child.isSelected = parent.isSelected;
                }
                return child;
            });
            return parent;
        } else {
            return parent;
        }
    }

    getSubTypesFilters(key: string) {
        const subTypes = this.availableTypes.find(t => t.value === key && t.isSelected);

        return subTypes ? subTypes.children.filter(t => t.isSelected).map(t => t.value) : [];
    }

    setTypeFilters(): void {
        this.typeFilters = DataHelper.merge(this.typeFilters, { contentTypes: this.availableTypes.filter(t => t.isSelected).map(t => t.value) });
        this.typeFilters = DataHelper.merge(this.typeFilters, { courseTypes: this.getSubTypesFilters('course') });
        this.typeFilters = DataHelper.merge(this.typeFilters, { externalContentTypes: this.getSubTypesFilters('external_content') });
        this.typeFilters = DataHelper.merge(this.typeFilters, { externalResourceTypes: this.getSubTypesFilters('external_resource') });
        this.isFilterActive = true;
        this.onFilter();
    }

    onSelectType(option) {
        option.isSelected = !option.isSelected
        this.availableTypes = this.availableTypes.map(type => this.getIterativeCheck(type, option));
        this.setTypeFilters();
    }
}
