import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from './company';
import { Camp } from '../camp';
import { CompanyRole } from './role';
import { CompanyPermission } from './permission';
import { Integration } from '../integration';
import { Account } from '../account';
import { environment } from '@env/environment';

@Injectable()
export class CompanyProvider {

    constructor(private http: HttpClient) { }

    getCompany(slug: string, params?: any): Observable<Company> {
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}`, { params }).pipe(
            map(data => {
                return new Company(data);
            })
        );
    }

    getCamps(id: number, params?: any): Observable<any> {
        const response = {
            camps: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${id}/camps`, { params }).pipe(
            map((data: any) => {
                response.pagination = data.pagination;
                response.camps = data.camps.map((camp) => {
                    return new Camp(camp);
                });

                return response;
            })
        );
    }

    getRoles(slug: string | number): Observable<any> {
        const response = {
            roles: {},
            permissions: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/roles`).pipe(
            map((data: any) => {
                response.roles = new CompanyRole(data.roles);
                response.permissions = new CompanyPermission(data.permissions);

                return response;
            })
        );
    }

    createCamp(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/company/${id}/camps`, params).pipe(
            map(data => data)
        );
    }

    getNotificationsSettings(id: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${id}/admin/notification-settings`).pipe(
            map(data => data)
        );
    }

    updateNotificationsSettings(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/camp/${id}/admin/notification-settings`, params).pipe(
            map(data => data)
        );
    }

    getSidebar(slug: string, params?: any): Observable<any> {
        const response = {
            integrations: [],
            groups: [],
            settings: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/sidebar`, { params }).pipe(
            map((data: any) => {
                response.integrations = data.integrations.map((integration) => {
                    return new Integration(integration);
                });
                response.groups = data.groups;
                response.settings = data.settings;

                return response;
            })
        );
    }

    getAccounts(id: number, params?: any): Observable<Account[]> {
        return this.http.post(`${environment.envVar.API_URL}/company/${id}/accounts`, params).pipe(
            map((data: any) => {
                return data.accounts.map((account) => {
                    return new Account(account);
                });
            })
        );
    }
    
    CompanyUserMe(company_id): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/me/${company_id}`).pipe(
            map(data => data)
        );
    }

    getSendersLimit(slug: string | number) {
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}?includes=experience`, {
            observe: 'response',
        }).pipe(
            map((r: HttpResponse<any>) => r.body.sendersLimit)
        );
    }

    GetServicesOnDemand( params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/services/ondemand`, {params}).pipe(
            map(data => data)
        );
    }

    GetAvailabeLearnersOnDemandService( params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/services/ondemand/available/learners`, {params}).pipe(
            map(data => data)
        );
    }

    GetAddedLearnersOnDemandService( params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/services/ondemand/added/learners`, {params}).pipe(
            map(data => data)
        );
    }

    AddLearnerOnDemandService( params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/services/ondemand/add/learner`, params).pipe(
            map(data => data)
        );
    }

    RemoveLearnerOnDemandService( params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/services/ondemand/remove/learner`, {params}).pipe(
            map(data => data)
        );
    }

    GetServiceForLearnerOnDemandService( params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/services/ondemand/available`, {params}).pipe(
            map(data => data)
        );
    }
}
