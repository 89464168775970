<section class="head">
    <h3>Loader</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Loader</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-loader></msc-common-loader>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Loader with message</h4>
        <hr>
        <div class="partition cards">
            <div class="segment">
                <msc-common-loader [message]="'Row, row, row your boat'"></msc-common-loader>
            </div>
            <div class="segment">
                <msc-common-loader [message]="'Gently down the stream'"></msc-common-loader>
            </div>
            <div class="segment">
                <msc-common-loader [message]="'Merrily merrily, merrily, merrily'"></msc-common-loader>
            </div>
            <div class="segment">
                <msc-common-loader [message]="'Life is but a dream'"></msc-common-loader>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Loader with message and body</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-loader [message]="'Wait!'">
                    <ng-template>
                        <p>Wait! Wait! Wait! Wait!</p>
                        <msc-button class="msc-button--primary" palette="primary" (click)="onEvent()">Fire!</msc-button>
                    </ng-template>
                </msc-common-loader>
            </div>
        </div>
    </div>
</section>
