import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';

@Component({
    selector: 'msc-team-member-info-tooltip',
    templateUrl: './info-tooltip.component.html'
})
export class ManageTeamMemberInfoTooltipComponent implements OnInit {
    @Input() item;
    @Input() position: 'left' | 'right' = 'right';
    display = false;
    mouseY;
    mouseX;

    constructor(private elRef: ElementRef) { }

    ngOnInit() {
        window.addEventListener('scroll', this.hide, true);
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.hide, true);
    }

    hide = () => {
        this.display = false;
    }

    show(ev) {
        if (this.display)
            return;
        this.display = true;
        let boundings = this.elRef.nativeElement.getBoundingClientRect();
        this.mouseX = (boundings.left + boundings.right) / 2;
        this.mouseY = (boundings.top + boundings.bottom) / 2;
    }
}
