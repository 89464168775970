<msc-common-aside #aside>
    <ng-template #headerTemplate>
        <h3 class="flex">
            <a (click)="changeTab('list')">
                <i *ngIf="tab === 'users' || selectedStudent" class="icon icon-arrow-ios-left"></i>
            </a>
            {{ (tab === 'list' ? 'instance.members-list' : 'instance.add-member') | translate }}
        </h3>

        <div>
            {{ courseCamp ? courseCamp.title : journey ? journey.title : externalContent.title }} -
            <span *ngIf="courseCamp && courseCamp.usersLimit">
                {{ 'instance.register' | translate }} : {{ courseCamp.usersCount || courseCamp.usedSeats }}
                / {{ courseCamp.usersLimit }}
            </span>

            <span *ngIf="courseCamp && !courseCamp.usersLimit">
                {{ 'instance.unlimited-registrations' | translate }}
            </span>

            <span *ngIf="journey && journey.limit">
                {{ 'instance.register' | translate }} : {{ journey.usersCount || journey.usedSeats }}
                / {{ journey.limit }}
            </span>

            <span *ngIf="journey && !journey.limit">
                {{ 'instance.unlimited-registrations' | translate }}
            </span>
        </div>
    </ng-template>

    <ng-template #asideTemplate>
        <div class="flex between mb-4">
            <ng-container *ngIf="tab === 'list' && !selectedStudent">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
            </ng-container>

            <ng-container *ngIf="tab === 'users'">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="searchAvailable($event.value)"></msc-common-search>
            </ng-container>
            

            <ng-container *ngIf="tab === 'list'">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-plus-circle" (click)="changeTab('users')">
                    {{ 'words.add' | translate }}
                </msc-button>
            </ng-container>
        </div>

        <div class="dynamic-table" *ngIf="tab === 'list' && !selectedStudent">
            <div class="dynamic-table__head">
                <div class="dynamic-table__cell cell-title--small">
                    <div>{{ 'words.name' | translate }}</div>
                </div>

                <div class="dynamic-table__cell">
                    {{ 'words.last-connection' | translate }}
                </div>

                <div class="dynamic-table__cell cell-actions"></div>
                <div class="dynamic-table__cell" *ngIf="journey"></div>
            </div>

            <div class="dynamic-table__body">
                <div class="dynamic-table__row row-radius row-shadow" *ngFor="let student of members.students">
                    <div class="dynamic-table__cell cell-title--small">
                        <div class="flex items-center space-x-2">
                            <msc-common-avatar [label]="student.account.name"
                                [pictureURL]="student.account.picture ? (student.account.picture + '?size=128') : null">
                            </msc-common-avatar>
                            <p>{{ student.account.name }}</p>
                        </div>
                    </div>

                    <div class="dynamic-table__cell" *ngIf="student.account.lastConnection">
                        {{ student.account.lastConnection | timeAgo }}
                    </div>

                    <div class="dynamic-table__cell" *ngIf="!student.account.lastConnection">
                        {{ 'words.no-connection' | translate }}
                    </div>

                    <div class="dynamic-table__cell cell-actions" *ngIf="!journey">
                        <a (click)="removeMember(student)">
                            <i class="cell-actions__icon icon icon-trash"></i>
                        </a>
                    </div>

                    <div class="dynamic-table__cell cell-with-icon" *ngIf="journey">
                        <a (click)="removeMember(student)">
                            <i class="cell-actions__icon icon icon-trash"></i>
                        </a>
                    </div>

                    <div class="dynamic-table__cell cell-actions" *ngIf="journey">
                        <a (click)="getPreview(student); stopPropagation($event)">
                            <i class="cell-actions__icon icon icon-arrow-ios-right"></i>
                        </a>
                    </div>
                </div>

                <msc-load-more [loading]="!ready" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
            </div>
        </div>

        <div class="slide__body" *ngIf="selectedStudent">
            <div class="dynamic-table dynamic-table-toggle"
                *ngFor="let content of selectedStudent.contents; let index = index">
                <div class="dynamic-table-toggle__container" [ngClass]="{'active': displays[index]}">
                    <div class="dynamic-table__body" (click)="displayed(index)">
                        <div class="dynamic-table__row row-radius row-shadow slide__checkbox">

                            <div class="dynamic-table__cell cell-title--small">
                                {{ content.title }}
                            </div>

                            <div class="dynamic-table__cell cell-with-icon cell-large">
                                <div class="cell-with-icon__container">
                                    <i class="icon icon-timer"></i>
                                    <div>{{ content.duration }}</div>
                                </div>
                            </div>

                            <i class="icon-displayer icon icon-arrow-ios-down"></i>
                        </div>
                    </div>

                    <div class="dynamic-table-toggle__content">
                        <div class="dynamic-table__body">
                            <div class="dynamic-table__row row-radius row-shadow slide__checkbox"
                                *ngFor="let instance of content.instances">

                                <div class="dynamic-table__cell cell-title--small">
                                    {{ instance.internalName }}
                                </div>

                                <div class="dynamic-table__cell cell-with-icon cell-large">
                                    <div class="cell-with-icon__container">
                                        <i class="icon icon-people"></i>
                                        <div>{{ instance.usersCount }}</div>
                                    </div>

                                    <div class="cell-with-icon__container">
                                        <i class="icon icon-message-circle"></i>
                                        <div>{{ instance.language }}</div>
                                    </div>
                                </div>

                                <div class="dynamic-table__cell cell-with-icon">
                                    <i class="cell-actions__icon icon icon-calendar"></i>
                                    <div class="color--orange">{{ instance.eventsCount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <msc-load-more [loading]="!ready" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>

        <div *ngIf="tab === 'users'">
            <div class="box box-add-participant flex align-center" *ngFor="let account of members.availableMembers">
                <msc-common2-checkbox [value]="isChecked(account.id)" [isDisabled]="data.all_campus_users" (changeEvent)="checkMember(account.id)"></msc-common2-checkbox>

                <div class="avatar-profile">
                    <msc-common-avatar class="mr-2" [label]="account.name" [pictureURL]="account.picture ? (account.picture + '?size=128') : null"></msc-common-avatar>
                    <span></span>
                    <p>{{ account.name }}</p>
                </div>
            </div>

            <msc-load-more [loading]="!ready" [pagination]="userPagination" (onLoad)="loadMoreAvailableUsers()"></msc-load-more>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <ng-container *ngIf="tab === 'users' && (courseCamp || externalContent)">
            <div class="flex justify-between items-center w-full">
                <div>
                    <msc-common2-checkbox [value]="false" [label]="'words.invite-all' | translate" (changeEvent)="inviteAll()"></msc-common2-checkbox>
                    <msc-common2-checkbox [value]="data.notify" [label]="'instance.notify-user' | translate"(changeEvent)="data.notify = !data.notify"></msc-common2-checkbox>
                </div>
    
                <msc-button class="msc-button--primary" palette="primary" (click)="addMember(courseCamp ? 'courseCamp' : 'externalContent')"
                    [disabled]="data.accounts.length === 0 && !this.data.all_campus_users">
                    {{ 'words.confirm' | translate }}
                </msc-button>
            </div>
        </ng-container>
    </ng-template>
</msc-common-aside>