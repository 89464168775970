import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService, TranslateService } from '@services';
import { Pagination, IntegrationProvider } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-aside-selection-panel-filtered',
    templateUrl: './aside-selection-panel-filtered.component.html',
    styleUrls: ['./aside-selection-panel-filtered.component.scss']
})
export class OrganizeIntegrationWatchRulesEditAsideSelectionPanelFilteredComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Input() languages: any[];
    public category: any;
    private languagesArray: number[] = [];
    public query: string;
    public loaders: any = {
        contents: true,
        loadMore: false
    };
    public pagination: Pagination;
    public contents: any[] = [];

    constructor(
        private readonly router: Router,
        private readonly integrationProvider: IntegrationProvider,
        private readonly route: ActivatedRoute,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
    ) { }

    init() {
        const companyId = this.storageService.get('company').id;
        const providerId = this.router.url.indexOf('rules/edit') > -1 ? this.route.snapshot.parent.parent.parent.parent.parent.params.providerId : this.route.snapshot.parent.parent.parent.parent.params.providerId;
        const collectionId = this.route.snapshot.data.collection.collectionId;

        for (const language of this.languages) {
            this.languagesArray.push(language.id);
        }

        const params = HttpHelper.cleanParams({
            subcategoryIds: this.category.isSub ? [this.category.id] : null,
            categoryIds: !this.category.isSub ? [this.category.id] : null,
            languageIds: this.languagesArray
        });
        this.loaders.contents = true;
        this.integrationProvider.getCollectionContents(companyId, providerId, collectionId, params).subscribe(data => {
            this.loaders.contents = false;
            this.contents = this.getContentsRemapped(data.contents);
            this.pagination = data.pagination;
        });
    }

    onOpen(category) {
        this.category = category;
        this.aside.onOpen();
        this.init();
    }

    getContentsRemapped(data: Array<any>) {
        return data.map((datum) => {
            const mediaUrl = this.getMediaUrl(datum);
            const uiTypeLabel = this.getUiTypeLabel(datum);
            datum.categories = datum.categories.sort((a: any, _: any) => {
                if (a.name === this.category.name) { return -1; }
                return 1;
            });
            return { ...datum, mediaUrl, uiTypeLabel };
        });
    }

    getMediaUrl(datum: any) {
        switch (true) {
            case datum.hasOwnProperty('media') && !!datum.media: return `url(${datum.media.pictureUrl}?size=256)`;
            case datum.hasOwnProperty('picture') && !!datum.picture: return `url(${datum.picture}?size=256)`;
            default: return `url('https://static.myskillcamp.com/lxp/images/logo-empty-state-${datum.id % 7}.jpg')`;
        }
    }

    getUiTypeLabel(datum: any) {
        switch (true) {
            case datum.hasOwnProperty('isTraject') && datum.isTraject: return this.translateService.instant('api.content-type.traject');
            case !datum.hasOwnProperty('isTraject') && datum.hasOwnProperty('type') && !!datum.type: return this.translateService.instant(`api.content-type.${datum.type}`);
            default: return this.translateService.instant('api.content-type.course');
        }
    }

    close(): void {
        const timeOut = setTimeout(() => {
            this.onClose.emit();
            clearTimeout(timeOut);
        }, 300);
    }


    loadMore(): void {
        const companyId = this.storageService.get('company').id;
        const providerId = this.router.url.indexOf('rules/edit') > -1 ? this.route.snapshot.parent.parent.parent.parent.parent.params.providerId : this.route.snapshot.parent.parent.parent.parent.params.providerId;
        const collectionId = this.route.snapshot.data.collection.collectionId;
        this.loaders.contents = true;
        const params = HttpHelper.cleanParams({
            subcategoryIds: this.category.isSub ? [this.category.id] : null,
            categoryIds: !this.category.isSub ? [this.category.id] : null,
            languageIds: this.languagesArray,
            page: this.pagination.currentPage + 1,
            q: this.query
        });
        this.integrationProvider.getCollectionContents(companyId, providerId, collectionId, params).subscribe(data => {
            this.loaders.contents = false;
            data.contents.map(content => {
                this.contents.push(content);
            });
            this.pagination = data.pagination;
        });
    }

    search(query?): void {
        this.query = query;
        const companyId = this.storageService.get('company').id;
        const providerId = this.router.url.indexOf('rules/edit') > -1 ? this.route.snapshot.parent.parent.parent.parent.parent.params.providerId : this.route.snapshot.parent.parent.parent.parent.params.providerId;
        const collectionId = this.route.snapshot.data.collection.collectionId;
        this.loaders.contents = true;

        const params = HttpHelper.cleanParams({
            subcategoryIds: this.category.isSub ? [this.category.id] : null,
            categoryIds: !this.category.isSub ? [this.category.id] : null,
            languageIds: this.languagesArray,
            q: this.query
        });
        this.integrationProvider.getCollectionContents(companyId, providerId, collectionId, params).subscribe(data => {
            this.loaders.contents = false;
            this.contents = data.contents;
            this.pagination = data.pagination;
        });
    }

    getCategoriesName(content: any) {
        const tooltipCategories = [...content.categories];
        tooltipCategories.shift();
        return tooltipCategories.map((category: any) => category.name).join(', ').toString();
    }
}
