<div class="content-params content-params-notification row">
    <div class="content-params__header flex align-center between">
        <h3 class="content-params__title">
            {{ 'notifications.list' | translate }}
        </h3>

        <div class="content-params__all flex align-center justify-end" *ngIf="ready">
            <p>{{ 'notifications.enable-all' | translate }}</p>

            <msc-common2-switch [value]="meta.emailEnabled" (changeEvent)="disableAll()"></msc-common2-switch>
        </div>
    </div>

    <div class="content-params__list" *ngIf="meta?.emailEnabled">
        <div class="tabs row">
            <div class="tabs__aside col-5 col-md-4 col-xxs-12">
                <div class="tabs__header box box-notification box-notification--hover flex between align-center" *ngFor="let notification of transformedNotifications; let index=index" [ngClass]="{'active': currentNotification.title === notification.title}" (click)="setCurrent(index)">
                    <div class="box-notification__content">
                        <h4 class="box-notification__title">
                            {{ 'notifications.emails.categories.' + notification.title | translate }}
                        </h4>

                        <p class="box-notification__infos">
                            <i class="icon icon-email"></i>
                            {{ count(notification.values) }}
                        </p>
                    </div>

                    <div class="box-notification__arrow">
                        <i class="icon icon-arrow-ios-right"></i>
                    </div>
                </div>
            </div>

            <div class="tabs__body col-7 col-md-8 col-xxs-12">
                <div class="tabs__title flex space-between">
                    <h3>{{ 'notifications.emails.categories.' + currentNotification.title | translate }}</h3>

                    <a [tooltip]="'notifications.enable-all' | translate" (click)="enableAll()">
                        <i class="icon icon-email"></i>
                    </a>
                </div>

                <div class="tabs__content">
                    <div class="box box-border box-notification-settings flex between" *ngFor="let category of currentNotification.values | keyvalue; let index=index">
                        <div class="box-notification-settings__content">
                            <h4 class="box-notification-settings__title">{{ 'notifications.emails.' + category.key | translate }}</h4>

                            <p class="box-notification-settings__desc"> {{ 'notifications.emails.description.' + category.key | translate }}</p>
                        </div>

                        <msc-common2-switch [value]="category.value" (changeEvent)="change($event, currentNotification.title, category.key)"></msc-common2-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
