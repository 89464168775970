import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
    selector: 'msc-common-td-menu',
    templateUrl: './td-menu.component.html',
    styleUrls: ['./td-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonTdMenuComponent {
    @Input() type: string;
    @Input() isDraft: boolean = false;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();

    public isOpen: boolean = false;

    constructor(
        private readonly elementRef: ElementRef,
    ) { }

    get width(): number { return this.elementRef.nativeElement.getBoundingClientRect()?.width; }

    /**
     * Event handler for open
     */
    onOpen(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * Event handler for change
     */
    onChange(status: string): void {
        this.changeEvent.emit(status);
        this.isOpen = false;
    }

    /**
     * Event handler for delete
     */
    onDelete(): void {
        this.deleteEvent.emit();
        this.isOpen = false;
    }
}
