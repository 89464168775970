import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';

import { CompanySenderProvider, ApiCompanySenderItem } from '@lighty';
import { StorageService, ExternalAppsService, TranslateService } from '@services';

import { ComponentSmartFormModel } from '@models2/component-smart-form.model';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-experience-watch-mail-create',
    templateUrl: './mail-create.component.html',
    styleUrls: ['./mail-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchMailCreateComponent extends ComponentSmartFormModel<any> {
    constructor(
        protected readonly fb: FormBuilder,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly companySenderProvider: CompanySenderProvider,
        protected readonly storageService: StorageService,
        protected readonly toastService: CommonToastService,
        protected readonly externalAppsService: ExternalAppsService,
        protected readonly translateService: TranslateService,
    ) {
        super();
    }

    /**
     * Sets the component observables
     */
    setObs(): void {
        // leave it empty
    }

    /**
     * Get a form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'name': ['', [Validators.required]],
            'email': ['', [Validators.required, Validators.email]]
        });
    }

    /**
     * Query init data
     */
    onQuery(): void {
        // leave it empty
    }

    /**
     * Event handler for submit of create of mail url
     */
    onSubmit(): void {
        this.companySenderProvider
            .postSenderCreate(this.company.id, this.form.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (sender: ApiCompanySenderItem) => this.onSubmitSuccess(sender),
                () => this.onSubmitError(),
            );
    }

    /**
     * Handler for success on onSubmit
     */
    onSubmitSuccess(sender: ApiCompanySenderItem): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.create.success'));
        this.goToDetails(sender.id);
    }

    /**
     * Handler for error on onSubmit
     */
    onSubmitError(): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.create.error'));
    }

    /**
     * Resolves navigation to list section
     */
    goToList(isBannerVisible: boolean = false): void {
        this.router.navigate(['..', 'list'], { relativeTo: this.route, state: { isBannerVisible } });
    }

    /**
     * Resolves navigation to details section
     */
    goToDetails(id: number): void {
        this.router.navigate(['..', 'details', id], { relativeTo: this.route });
    }
}
