<div class="modal modal-player-light-iframe" [ngClass]="{'active': active}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <h1>{{ content.title }}</h1>

            <div class="modal__header__actions">
                <div class="actions__time">
                    <i class="icon icon-clock"></i>
                    {{ internalTimer | duration: true }}
                </div>

                <msc-button class="msc-button--primary msc-button--tiny" palette="success" icon="icon-checkmark" (click)="finished()" *ngIf="!session.doneAt">
                    {{ 'words.i-finished' | translate }}
                </msc-button>

                <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" icon="icon-download" (click)="download()" *ngIf="content.context === 'media' && content.media.type !== 'EMBED'">
                    {{ 'words.download' | translate }}
                </msc-button>

                <msc-button class="msc-button--primary msc-button--tiny" palette="primary" icon="icon-log-out" (click)="close()">
                    {{ 'words.quit-playing' | translate }}
                </msc-button>
            </div>
        </div>

        <div class="modal__body">
            <ng-container *ngIf="(content.context === 'cheatsheet') && content.cheatsheet.content">
                <div class="modal__wrapper" data-simplebar [innerHTML]="content.cheatsheet.content | safe: 'html'"></div>
            </ng-container>

            <div class="modal__wrapper" *ngIf="content.context === 'external_link'">
                <iframe name="embed-iframe"></iframe>
                <div id="embed-code"></div>
            </div>

            <div *ngIf="content.context === 'media'" [ngClass]="{'modal__wrapper': content.media.type === 'IMG'}">
                <div class="modal__body__picture" *ngIf="content.media.type === 'IMG'">
                    <img [src]="content.media.storage === 'azure' ? content.media.pictureUrl + '?size=512' : content.media.url">
                </div>

                <div class="" *ngIf="content.media.type === 'VID'">
                    <iframe [src]="getUrl(content.media.url, 'video')" allow="fullscreen" allowfullscreen width="100%"></iframe>
                </div>

                <div class="" *ngIf="content.media.type === 'DOC' || content.media.type === 'PDF'">
                    <iframe [src]="getUrl(content.media.pictureUrl)" allow="fullscreen" allowfullscreen width="100%"></iframe>
                </div>

                <div class="" *ngIf="content.media.type === 'EMBED'">
                    <iframe [src]="getUrl(content.media.url)" allow="fullscreen" allowfullscreen width="100%"></iframe>
                </div>

                <div class="modal__body__audio" *ngIf="content.media.type === 'SND'">
                    <msc-audio-player [song]="content.media.pictureUrl" [title]="content.media.title"></msc-audio-player>
                </div>
            </div>

            <div class="modal__body__description" *ngIf="content.description">
                <div [innerHTML]="content.description | safe: 'html'"></div>
            </div>
        </div>
    </div>
</div>
