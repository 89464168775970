<div class="modal modal-player-light" [ngClass]="{'active': active}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <div class="modal__close" (click)="close()">
                <i class="icon icon-close"></i>
            </div>
        </div>

        <div class="modal__body">
            <i *ngIf="!content.provider" class="icon icon-external-link head-icon"></i>
            <img *ngIf="content.provider" class="modal__body__logo" [attr.src]="'https://static.myskillcamp.com/images/integrations/' + content.provider.key + '.svg'" [attr.alt]="content.provider.name">

            <div class="modal__body__content" *ngIf="progressEnabled">
                <div class="modal__body__content__label">
                    {{ 'words.i-have-progressed' | translate }}
                </div>

                <div class="modal__body__content__input" *ngIf="content.context !== 'external_link'">
                    <input type="number" min="0" max="100" class="input-number input-number--tiny" [(ngModel)]="data.duration">

                    <div class="input form-select form-select--tiny">
                        <div class="select select--tiny">
                            <select name="select" [(ngModel)]="data.unit">
                                <option value="minutes">{{ 'words.minutes' | translate }}</option>
                                <option value="hours">{{ 'words.hours' | translate }}</option>
                                <option value="days">{{ 'words.days' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal__body__title">
                <span>{{ content.title | truncate: 90 }}</span>
            </div>

            <div class="modal__body__actions" *ngIf="progressEnabled">
                <label class="checkbox" *ngIf="session?.progress !== 100" for="finished">
                    <msc-common2-checkbox [value]="data.finished" [label]="'words.i-finished' | translate" (changeEvent)="data.finished = !data.finished"></msc-common2-checkbox>
                </label>

                <msc-button class="msc-button--primary" palette="success" icon="icon-diagonal-arrow-right-up" (click)="save()">
                    {{ 'words.save' | translate }}
                </msc-button>
            </div>
        </div>

        <div class="modal__footer" *ngIf="content?.type !== 'ebook'">
            <div class="modal__buttons">
                <div class="modal__buttons__label">
                    {{ 'words.module-new-tab' | translate }}
                </div>

                <msc-button class="msc-button--outline msc-button--tiny" icon="icon-external-link" (click)="open()">
                    {{ 'words.open-module' | translate }}
                </msc-button>
            </div>
        </div>
    </div>
</div>
