import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class CompanyColorProvider {

    constructor(private http: HttpClient) {}

    getThemes(id: number|string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates`, {params}).pipe(
            map(data => data)
        );
    }

    getTheme(id: number, templateId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates/${templateId}`, params).pipe(
            map(data => data)
        );
    }

    create(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates`, params).pipe(
            map(data => data)
        );
    }

    update(id: number, templateId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates/${templateId}`, params).pipe(
            map(data => data)
        );
    }

    delete(id: number, templateId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates/${templateId}`, {params}).pipe(
            map(data => data)
        );
    }

    enable(id: number, templateId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates/${templateId}/enable`, params).pipe(
            map(data => data)
        );
    }

    notify(id: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates/notify-sales`, params).pipe(
            map(data => data)
        );
    }

    generate(id: number, templateId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/color-templates/${templateId}/generate`, params).pipe(
            map(data => data)
        );
    }
}
