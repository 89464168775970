<div>
    <div class="msc-banner">
        <div class="msc-banner__gradient">
            <div
                [ngStyle]="{ 'background-image': 'url(' + background + ')' }"
            ></div>
        </div>
    </div>

    <header>
        <div class="flex justify-between mb-8 md--flex-row">
            <div class="breadcrumb-v2 overflow-hidden pl-4">
                <h3
                    class="breadcrumb-v2--back whitespace-nowrap"
                    (click)="navigate()"
                >
                    {{
                        (isFromSubscriptions
                            ? "words.my-training-courses"
                            : "words.catalogue"
                        ) | translate
                    }}
                </h3>
                <i class="icon icon-arrow-ios-right"></i>
                <h3 class="truncate">{{ title }}</h3>
            </div>

            <div class="flex gap-4 md--flex-row">
                <msc-button
                    class="msc-button--secondary msc-button--tiny"
                    icon="icon-link-1"
                    (click)="clipboard()"
                >
                    {{ "words.share-link" | translate }}
                </msc-button>
                <msc-button
                    class="msc-button--secondary msc-button--tiny"
                    palette="primary"
                    [icon]="saved ? 'icon-bookmark-1' : 'icon-bookmark'"
                    (click)="changeLearnLaterContent()"
                >
                    {{ "words.bookmark" | translate }}
                </msc-button>
                <!-- <msc-button *ngIf="context !== 'external-content' && account"
                    class="msc-button--primary msc-button--tiny" palette="secondary" (click)="openConversation()">
                    {{ 'words.contact-creator' | translate }}
                </msc-button> -->
                <msc-button
                    class="msc-button--primary msc-button--tiny"
                    palette="danger"
                    *ngIf="isVisibleSubscriptionMenu"
                    (click)="modalDelete.open(title)"
                >
                    {{ "words.unsubscribe" | translate }}
                </msc-button>
            </div>
        </div>
        <msc-common-tab [navigation]="navigation"> </msc-common-tab>
    </header>

    <router-outlet></router-outlet>
</div>

<msc-card-manager-modal-delete-subscription
    #modalDelete
    [type]="context"
    (onSave)="deleteContent($event)"
>
</msc-card-manager-modal-delete-subscription>
