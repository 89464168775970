<div class="feed-thread-head">
    <div class="wrapper">
        <msc-common-avatar [label]="datum.userName" [pictureURL]="datum.userAvatar"></msc-common-avatar>
        <div class="grouping">
            <span class="name">{{ datum.userName }}</span>
            <span class="date">{{ datum.dateCreation | timeAgo }}</span>
        </div>
    </div>

    <div class="wrapper">
        <msc-button class="msc-button--tiny" *ngIf="datum.config.hasEdit" icon="icon-edit-1" (click)="onToggleEdit()">{{'words.edit' | translate}}</msc-button>
        <msc-button class="msc-button--tiny" *ngIf="datum.config.hasDelete" icon="icon-trash" (click)="onThreadDelete()">{{'words.delete' | translate}}</msc-button>
        <msc-button class="msc-button--tiny" *ngIf="datum.config.hasNotify" icon="icon-megaphone" (click)="onThreadNotify()">{{'words.notify' | translate}}</msc-button>
    </div>
</div>

<div class="feed-thread-body">
    <ng-container *ngIf="isEdit">
        <msc-common-feed-submit [formPatch]="datum" (submit)="onThreadEdit($event)"></msc-common-feed-submit>
    </ng-container>

    <p class="content-message">{{ datum.contentMessage }}</p>
    <p class="content-modified" *ngIf="datum.contentModified">{{ datum.contentModified }}</p>
    <ng-container *ngIf="datum.contentUrl; else imageTemplate">
        <div class="wrapper">
            <i class="icon icon-file-text color--red"></i>
            <a download class="content-title" [href]="datum.contentUrl" target="_blank">{{ datum.contentTitle }}</a>
        </div>
    </ng-container>
    <ng-template #imageTemplate>
        <img class="content-image" *ngIf="datum.contentImage" [src]="datum.contentImage">
    </ng-template>
    <iframe class="content-video" *ngIf="datum.contentVideo" [src]="datum.contentVideo | safe : 'resourceUrl'"></iframe>
    <div class="content-social">
        <ng-container *ngIf="reactionsUsed?.length">
            <msc-button type="custom">
                <span class="counter">{{ datum.countReaction }}</span>
                <div class="wrapper">
                    <ng-container *ngFor="let reaction of reactionsUsed">
                        <img [src]="reaction" alt="">
                    </ng-container>
                </div>
            </msc-button>
        </ng-container>
        <msc-button type="custom" (click)="onToggleComments()">
            <span class="counter">{{ datum.countComment }}</span>
            <ng-container *ngIf="datum.countComment < 2; else pluralTemplate">
                <span>{{ 'words.comment' | translate }}</span>
            </ng-container>
            <ng-template #pluralTemplate>
                <span><span>{{ 'words.comments' | translate }}</span></span>
            </ng-template>
        </msc-button>
    </div>
</div>

<div class="feed-thread-footer">
    <div class="wrapper">
        <msc-common-feed-reaction [id]="datum.userReactionId" (delete)="onReactionDelete($event)" (submit)="onReactionCreate($event)"></msc-common-feed-reaction>
        <msc-button class="msc-button--tiny" icon="icon-message-circle" (click)="onToggleComment()">{{'words.comment' | translate}}</msc-button>
    </div>

    <ng-container *ngIf="isComment">
        <msc-common-feed-submit [hasOptions]="false" (submit)="onCommentCreate($event)"></msc-common-feed-submit>
    </ng-container>
</div>

<ng-container *ngIf="isComments && datum?.comments?.length">
    <ng-container *ngFor="let comment of datum.comments; trackBy: fnOnTrackByIndex">
        <msc-common-feed-comment [datum]="comment" (delete)="onCommentDelete($event)"></msc-common-feed-comment>
    </ng-container>
</ng-container>
