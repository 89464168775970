<msc-common-aside #aside [title]="'words.timeline' | translate" (saveEvent)="update()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <msc-common-spinner *ngIf="!ready"></msc-common-spinner>

            <ng-container *ngIf="ready">
                <div class="flex flex-col space-y-2">
                    <span>{{ 'words.posts' | translate }}</span>
                    <msc-common2-radio groupName="timelinePosts" [label]="'timeline.free-post' | translate" [(ngModel)]="timeline.settings.student.post" [valueName]="'free'"></msc-common2-radio>
                    <msc-common2-radio groupName="timelinePosts" [label]="'timeline.moderated-post' | translate" [(ngModel)]="timeline.settings.student.post" [valueName]="'moderated'"></msc-common2-radio>
                    <msc-common2-radio groupName="timelinePosts" [label]="'timeline.disabled-post' | translate" [(ngModel)]="timeline.settings.student.post" [valueName]="'disabled'"></msc-common2-radio>
                </div>

                <div class="flex flex-col space-y-2">
                    <span>{{ 'words.comments' | translate }}</span>
                    <msc-common2-radio groupName="timelineComments" [label]="'timeline.free-reply' | translate" [(ngModel)]="timeline.settings.student.reply" [valueName]="'free'"></msc-common2-radio>
                    <msc-common2-radio groupName="timelineComments" [label]="'timeline.disabled-reply' | translate" [(ngModel)]="timeline.settings.student.reply" [valueName]="'disabled'"></msc-common2-radio>
                </div>
            </ng-container>
        </div>
    </ng-template>
</msc-common-aside>