import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@services';
import * as moment from 'moment';

/**
 * Add time ago to a value (like 2 days ago)
 */
@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
    private singleUnits: any = {
        fr: {
            y: 'il y a 1 an',
            m: 'il y a 1 mois',
            d: 'il y a 1 jour',
            h: 'il y a 1 heure',
            min: 'il y a 1 minute',
            s: 'il y a quelques secondes'
        },
        en: {
            y: '1 year ago',
            m: '1 month ago',
            d: '1 day ago',
            h: '1 hour ago',
            min: '1 minute ago',
            s: 'a few seconds ago'
        },
        es: {
            y: 'hace 1 año',
            m: 'hace 1 mes',
            d: 'hace 1 día',
            h: 'hace 1 hora',
            min: 'hace 1 minuto',
            s: 'hace unos segundos'
        },
        nl: {
            y: '1 jaar geleden',
            m: '1 maand geleden',
            d: '1 dag geleden',
            h: '1 uur geleden',
            min: '1 minute geleden',
            s: 'enkele seconden geleden'
        },
        it: {
            y: '1 anno fa',
            m: '1 mese fa',
            d: '1 giorno fa',
            h: '1 ora fa',
            min: '1 minuto fa',
            s: 'pochi secondi fa'
        },
        de: {
            y: 'vor 1 Jahr',
            m: 'vor 1 Monat',
            d: 'vor 1 Tag',
            h: 'vor 1 Stunde',
            min: 'vor 1 Minute',
            s: 'vor ein paar Sekunden'
        }
    };
    private pluralUnits: any = {
        fr: {
            y: 'il y a {time} ans',
            m: 'il y a {time} mois',
            d: 'il y a {time} jours',
            h: 'il y a {time} heures',
            min: 'il y a {time} minutes'
        },
        en: {
            y: '{time} years ago',
            m: '{time} months ago',
            d: '{time} days ago',
            h: '{time} hours ago',
            min: '{time} minutes ago'
        },
        es: {
            y: 'hace {time} años',
            m: 'hace {time} meses',
            d: 'hace {time} días',
            h: 'hace {time} horas',
            min: 'hace {time} minutos'
        },
        nl: {
            y: '{time} jaar geleden',
            m: '{time} maanden geleden',
            d: '{time} dagen geleden',
            h: '{time} uren geleden',
            min: '{time} minuten geleden'
        },
        it: {
            y: '{time} anni fa',
            m: '{time} mesi fa',
            d: '{time} giorni fa',
            h: '{time} ore fa',
            min: '{time} minuti fa'
        },
        de: {
            y: 'vor {time} Jahre',
            m: 'vor {time} Monaten',
            d: 'vor {time} Tagen',
            h: '{time} Stunden zurück',
            min: '{time} Minuten zurück'
        }
    };

    constructor(private translateService: TranslateService) {}

    transform(date: Date): any {
        return this.process(date, this.translateService.getCurrentLang());
    }

    private process(date: Date, lang: string): string {
        if (!date) {
            return '';
        }

        let sec = true;
        let unit = 's';
        let time = moment().diff(moment(date), 'seconds');

        if (time > 60) {
            sec = false;
            unit = 'min';
            time = moment().diff(moment(date), 'minutes');

            if (time > 60) {
                unit = 'h';
                time = moment().diff(moment(date), 'hours');

                if (time > 24) {
                    unit = 'd';
                    time = moment().diff(moment(date), 'days');

                    if (time > 30) {
                        unit = 'm';
                        time = moment().diff(moment(date), 'months');

                        if(time > 12) {
                            unit = 'y';
                            time = moment().diff(moment(date), 'years');
                        }
                    }
                }
            }
        }

        if (sec) {
            return this.singleUnits[lang].s;
        } else {
            return time > 1 ? this.pluralUnits[lang][unit].replace('{time}', time) : this.singleUnits[lang][unit];
        }
    }
}
