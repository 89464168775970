<div class="flex flex-col">
    <div class="slide-external-link__body scrollable grow">
        <msc-common-spinner *ngIf="campsloading"></msc-common-spinner>
    
        <div class="slide-external-link__form" *ngIf="!campsloading">
            <div class="slide-external-link__camp-block" *ngFor="let camp of camps">
                <p class="slide-external-link__camp-block__name">
                    {{ camp.name }}
                </p>
    
                <div class="slide-external-link__camp-block__infos">
                    <i class="icon icon-people"></i>
                    <span>{{ camp.usersCount }}</span>
    
                    <i class="icon icon-calendar"></i>
                    <span>{{ camp.instancesCount }}</span>
                </div>
    
                <msc-common2-checkbox [value]="isChecked(camp)" (changeEvent)="select(camp)"></msc-common2-checkbox>
    
            </div>
    
            <msc-load-more [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>
    </div>
    
    <div class="flex justify-end">
        <msc-button class="msc-button--primary" palette="secondary" (click)="nextPanel()" [disabled]="extensionLink.campList.length <= 0">
            {{ 'words.next' | translate }}
        </msc-button>
    </div>
</div>