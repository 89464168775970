<section class="head">
    <h3>{{ 'organize.marketplace.customers.title' | translate }}</h3>
    <p>{{ 'organize.marketplace.customers.description' | translate }}</p>
    <msc-button class="msc-button--secondary" palette="primary" icon="icon-plus-1" (click)="onOpenModal(modal)">
        {{ 'organize.marketplace.customers.btn.add' | translate }}
    </msc-button>
</section>

<section class="body">
    <msc-organize-table-marketplace-customers-component
        [rows]="customerListTableRows$ | async"
        [status]="customerListTableStatus$ | async"
        (sortEvent)="onSortCustomerList($event)">
    </msc-organize-table-marketplace-customers-component>

    <ng-container *ngIf="customerListTableHasLoadMore$ | async">
        <div class="wrapper">
            <msc-button class="msc-button--primary" palette="primary" (click)="onLoadMoreCustomerList()">{{ 'words.load-more' | translate }}</msc-button>
        </div>
    </ng-container>
</section>

<msc-common-modal #modal size="30vw" [title]="'organize.marketplace.customers.modal.form.title' | translate">
    <ng-template>
        <msc-organize-form-marketplace-customers-component
            (submitEvent)="onAddCustomer($event)"
            (closeModal)="onCloseModal(modal)">
        </msc-organize-form-marketplace-customers-component>
    </ng-template>
</msc-common-modal>
