import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'msc-common-td-categories',
    templateUrl: './td-categories.component.html',
    styleUrls: ['./td-categories.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonTdCategoriesComponent {
    @Input() data: any;

    public tooltipMessage: string;

    ngOnInit(): void {
        this.tooltipMessage = this.getTooltipMessage();
    }

    getTooltipMessage() {
        const d = [...this.data];
        d.shift();
        return d.join(', ');
    }
}
