export class YoutubePlaylist {
    id?: number;
    name: string;
    language: string;
    originalName: string;
    description: string;
    originalDescription: string;
    url: string;
    status: boolean;
    privacyStatus: boolean;
    externalId: string;
    mediaUrl: string;
    lastSync?: Date;

    constructor(youtube: object) {
        this.assign(youtube);
    }

    assign(youtube: object): void {
        Object.assign(this, youtube);
    }
}
