<msc-header [company]="company" [access]="access"></msc-header>
<msc-sidebar [me]="me" [company]="company" [access]="access" [sidebar]="sidebar"></msc-sidebar>

<section>
    <div class="container-account__row row">
        <div class="container-account__back" *ngIf="!myAccount">
            <a class="link link-back link-back--transparent" [routerLink]="['/organize', company.slug, 'users']">
                <span>{{ 'user.manage.title' | translate }}</span>

                <i class="icon icon-arrow-ios-up"></i>
            </a>
        </div>

        <header class="container-account__header box flex justify-start align-center" *ngIf="!myAccount">
            <div class="container-account__avatar">
                <msc-common-avatar [label]="user.name"
                    [pictureURL]="user.picture ? (user.picture + '?size=256') : null"></msc-common-avatar>
            </div>

            <h2 class="container-account__name">
                {{ user.name }}
            </h2>
        </header>

        <div class="container-account__main row">
            <aside class="container-account__aside col-xxs-12 col-md-3 ">
                <div class="navigations-access">
                    <ul class="navigations-content">
                        <li class="navigations-content__item" [routerLink]="['profile']" [routerLinkActive]="'active'">
                            <p>{{ (myAccount ? 'words.my-profile' : 'words.profile') | translate }}</p>
                        </li>

                        <li class="navigations-content__item" [routerLink]="['settings']" [routerLinkActive]="'active'"
                            *ngIf="myAccount">
                            <p>{{ 'words.my-settings' | translate }}</p>
                        </li>

                        <li *ngIf="user" class="navigations-content__item" (click)="displayed('access')"
                            [ngClass]="{'displayed': displays.access || isActivatedRoute('access'), 'active': isActivatedRoute('access')}">
                            <p>{{ 'words.access' | translate }}</p>

                            <a class="toggle">
                                <i class="icon icon-arrow-ios-down text-secondary-500"></i>
                            </a>
                        </li>

                        <li class="navigations-sub" *ngIf="user">
                            <ul>
                                <li class="navigations-sub__item" [routerLink]="['access', 'learn']"
                                    [routerLinkActive]="'active'">
                                    <p>
                                        <i class="icon icon-student"></i>
                                        {{ 'words.learn' | translate }}
                                    </p>

                                    <i class="icon-job icon"
                                        [ngClass]="{'icon-checkmark-1': user.companyRoles.companyLearner, 'icon-close': !user.companyRoles.companyLearner}"></i>
                                </li>

                                <li class="navigations-sub__item" [routerLink]="['access', 'create']"
                                    [routerLinkActive]="'active'">
                                    <p>
                                        <i class="icon icon-color-palette"></i>
                                        {{ 'words.create' | translate }}
                                    </p>

                                    <i class="icon-job icon"
                                        [ngClass]="{'icon-checkmark-1': user.companyRoles.companyAuthor, 'icon-close': !user.companyRoles.companyAuthor}"></i>
                                </li>

                                <li class="navigations-sub__item" [routerLink]="['access', 'manage']"
                                    [routerLinkActive]="'active'">
                                    <p>
                                        <i class="icon icon-mentor"></i>
                                        {{ 'words.coach' | translate }}
                                    </p>

                                    <i class="icon-job icon"
                                        [ngClass]="{'icon-checkmark-1': user.companyRoles.companyManager, 'icon-close': !user.companyRoles.companyManager}"></i>
                                </li>

                                <li class="navigations-sub__item" [routerLink]="['access', 'organize']"
                                    [routerLinkActive]="'active'">
                                    <p>
                                        <i class="icon icon-calendar"></i>
                                        {{ 'words.organise' | translate }}
                                    </p>

                                    <i class="icon-job icon"
                                        [ngClass]="{'icon-checkmark-1': user.companyRoles.companyAdministrator, 'icon-close': !user.companyRoles.companyAdministrator}"></i>
                                </li>

                                <ng-container *ngIf="user.id !== me.id">
                                    <li class="navigations-sub__item" [routerLink]="['access', 'settings']"
                                        [routerLinkActive]="'active'">
                                        <p>
                                            <i class="icon icon-settings-2"></i>
                                            {{ 'words.access-period' | translate }}
                                        </p>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>
                </div>
            </aside>

            <div class="container-account__body col-xxs-12 col-md-9 col-9 no-gutter">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>
