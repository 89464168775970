import { Component } from '@angular/core';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-organize-integration',
    templateUrl: './integration.component.html',
    styleUrls: ['./integration.component.scss'],
})

export class OrganizeIntegrationComponent {
    public navData: Array<any>;

    constructor(
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.navData = this.getNav();
    }

    /**
     * Get the nav data of the page
     */
    getNav(): Array<any> {
        return [
            {
                label: this.translateService.instant(`words.tools`),
                value: {
                    routerLink: 'tools'
                }
            },
            {
                label: this.translateService.instant(`words.providers`),
                value: {
                    routerLink: 'providers',
                }
            },
        ]
    }
}
