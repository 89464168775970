import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
//
import { Subject, BehaviorSubject, Observable, iif, of } from 'rxjs';
import { catchError, map, tap, repeat, takeUntil, concatMap } from 'rxjs/operators';
//
import { TranslateService, StorageService, ExternalAppsService } from '@services';
//
import { CommonToastService } from '@common2/services/toast.service';
import { OrganizeFormMarketplaceSettingsDatum } from '@modules/organize/components/form-marketplace-settings/organize-form-marketplace-settings.component';
import { OrganizeMarketplaceSettingsApiService } from '@modules/organize/services/api/organize-marketplace-settings-api.service';
import { OrganizeMarketplaceLibService } from '@modules/organize/services/lib/organize-marketplace-lib.service';

@Component({
    selector: 'msc-organize-marketplace-settings-page',
    templateUrl: './organize-marketplace-settings-page.component.html',
    styleUrls: ['./organize-marketplace-settings-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeMarketplaceSettingsPageComponent {
    private company: any;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public settings$: BehaviorSubject<OrganizeFormMarketplaceSettingsDatum> = new BehaviorSubject<OrganizeFormMarketplaceSettingsDatum>(null);

    public isEdit: boolean = false;

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly toastService: CommonToastService,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly marketplaceSettingsApiService: OrganizeMarketplaceSettingsApiService,
        private readonly marketplaceLibService: OrganizeMarketplaceLibService,
        private readonly externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.onQuerySettings();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Event handler for query settings
     */
    onQuerySettings(): void {
        this.marketplaceSettingsApiService
            .getSettings(this.company.id)
            .pipe(
                catchError(() => this.onCatchErrorOnQuerySettings()),
                map((response) => this.marketplaceLibService.getFormSettings(response)),
                tap((datum) => this.settings$.next(datum)),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: () => {
                    this.isEdit = false;
                    this.ref.detectChanges();
                }
            });
    }

    /**
     * Event handler for catchError for onQuerySettings
     */
    onCatchErrorOnQuerySettings(): Observable<null> {
        this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        return of(null);
    }

    /**
     * Event handler for edit settings
     */
    onEditSettings(formData: any): void {
        const { file, mediaId, ...params } = formData; // we remove file and mediaId
        iif(() => !!file, this.marketplaceSettingsApiService.postUploadMedia(file), of(null))
            .pipe(
                concatMap((media: any) => this.onConcatMapOnEditSettings(params, media)),
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: () => this.onEditSettingsSuccess(),
                error: () => this.onEditSettingsError(),
            });
    }

    /**
     * Event handler for concatMap for onEditSettings
     */
    onConcatMapOnEditSettings(params: any, media: any): Observable<any> {
        const media_id = media?.id || null;
        return this.marketplaceSettingsApiService
            .editSettings(this.company.id, { ...params, media_id })
            .pipe(
                takeUntil(this.destroy$),
            );
    }

    /**
     * Event handler for success on onEditSettings
     */
    onEditSettingsSuccess(): void { // Bogdan TODO
        this.toastService.onSuccess(this.translateService.instant('toast.organize.marketplace.settings.save.success'));
        this.onQuerySettings();
    }

    /**
     * Event handler for error on onEditSettings
     */
    onEditSettingsError(): void {
        this.toastService.onError(this.translateService.instant('toast.organize.marketplace.settings.save.error'));
    }
}
