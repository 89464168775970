import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { mergeScan, map, tap, repeat, finalize, takeUntil } from 'rxjs/operators';
//
import { Company, KnowledgeSkill, KnowledgeProvider, LanguageProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';

@Component({
    selector: 'msc-organize-knowledge-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [DatePipe],
})

export class OrganizeKnowledgeListComponent {
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public company: Company;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public query$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore: boolean = false;
    // crap
    public dataFilters: any = {};
    public filters: any;
    public isFilterActive: boolean = false;
    public languageOptions: any;
    public displayComponents: any;
    public selectedSkills: KnowledgeSkill[] = [];
    public selectAll: boolean = false;
    public displayBottom: boolean = false;

    constructor(
        private readonly datePipe: DatePipe,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastService: CommonToastService,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
        private readonly knowledgeProvider: KnowledgeProvider,
        private readonly languageProvider: LanguageProvider,
    ) { }

    get thCheckboxIsPartial(): boolean { return this.isThCheckboxPartial(); }
    get thCheckboxIsSelected(): boolean { return this.isThCheckboxSelected(); }

    ngOnInit(): void {
        this.languageProvider.get('interface').subscribe((data) => {
            this.languageOptions = data.map(lang => ({
                label: lang.name,
                value: lang.id
            }));

            this.languageOptions.unshift({
                label: this.translateService.instant('filter.all.f'),
                value: null
            });

            this.setFilters();
        });

        this.displayComponents = {
            aside: {
                create: false,
                merge: false,
                forbid: false
            }
        };
        this.setContent();
        this.onQuerySkillList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     *
     */
    setFilters(): void {
        this.filters = [
            {
                name: 'used',
                type: 'radio',
                label: this.translateService.instant('words.usage'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all.f'),
                    value: null,
                }, {
                    label: this.translateService.instant('filter.used'),
                    value: '1',
                }, {
                    label: this.translateService.instant('filter.not-used'),
                    value: '0',
                }]
            },
            {
                name: 'visible',
                type: 'radio',
                label: this.translateService.instant('company.management.usage'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all.f'),
                    value: null,
                }, {
                    label: this.translateService.instant('words.allowed'),
                    value: '1',
                }, {
                    label: this.translateService.instant('words.forbidden'),
                    value: '0',
                }]
            },
            {
                name: 'validated',
                type: 'radio',
                label: this.translateService.instant('words.validated'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all.f'),
                    value: null,
                }, {
                    label: this.translateService.instant('words.yes'),
                    value: '1',
                }, {
                    label: this.translateService.instant('words.no'),
                    value: '0',
                }]
            },
            {
                name: 'missingTranslations',
                type: 'radio',
                label: this.translateService.instant('words.missing-translations'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all.f'),
                    value: null,
                }, {
                    label: this.translateService.instant('words.yes'),
                    value: '1',
                }, {
                    label: this.translateService.instant('words.no'),
                    value: '0',
                }]
            },
            {
                name: 'lang',
                type: 'radio',
                label: this.translateService.instant('words.language'),
                value: null,
                options: this.languageOptions
            },
            {
                name: 'thematic',
                type: 'radio',
                label: this.translateService.instant('words.classification'),
                value: null,
                options: [{
                    label: this.translateService.instant('filter.all.f'),
                    value: null,
                }, {
                    label: this.translateService.instant('words.thematic'),
                    value: '1',
                }, {
                    label: this.translateService.instant('words.skill'),
                    value: '0',
                }]
            }
        ]
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.company = this.storageService.get('company');
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: '',
                width: '1%',
            },
            {
                key: '',
                width: '4%',
            },
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '25%',
            },
            {
                key: 'total_usage',
                label: this.translateService.instant('words.resources'),
                width: '20%',
            },
            {
                key: 'use',
                label: this.translateService.instant('company.management.usage'),
                width: '15%',
            },
            {
                key: 'validated',
                label: this.translateService.instant('words.validated'),
                width: '15%',
            },
            {
                key: 'created_at',
                label: this.translateService.instant('words.date'),
                width: '20%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQuerySkillList(pageValue: number) {
        return {
            page: pageValue,
            ...this.query$.getValue(),
        };
    }

    /**
     *
     */
    onQuerySkillList(isAppend: boolean = true) {
        this.knowledgeProvider
            .get(this.company.slug, this.getParamsOnQuerySkillList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQuerySkillList(response.pagination)),
                map((response) => this.onMapOnQuerySkillList(response.skills)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQuerySkillList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQuerySkillList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            ...datum,
            isSelected: false,
            name: datum.name,
            use: datum.visible ? this.translateService.instant('words.allowed') : this.translateService.instant('words.forbidden'),
            validated: datum.validated ? this.translateService.instant('words.yes') : this.translateService.instant('words.no'),
            date: this.datePipe.transform(datum.createdAt, 'MMM dd, YYYY'),
        }));
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.page$.next(1);
        const { q, ...query } = this.query$.getValue();
        this.query$.next({ ...query, q: value });
        this.onQuerySkillList(false);
    }

    /**
     *
     */
    onSort(key: any, type: CommonThSortType): void {
        this.page$.next(1);
        const { order_by, order_way, ...query } = this.query$.getValue();
        if (type) {
            this.query$.next({ ...query, order_by: key, order_way: type });
        } else {
            this.query$.next({ ...query });
        }
        this.onQuerySkillList(false);
    }

    /**
     *
     */
    onFilter(): void {
        this.page$.next(1);
        const { used, visible, missing, missing_lang, validated, thematic, ...query } = this.query$.getValue();
        this.query$.next({
            ...query,
            ...this.dataFilters.used ? { used: this.dataFilters.used } : {},
            ...this.dataFilters.visible ? { visible: this.dataFilters.visible } : {},
            ...this.dataFilters.missingTranslations ? { missing: this.dataFilters.missingTranslations } : {},
            ...this.dataFilters.lang ? { missing_lang: this.dataFilters.lang } : {},
            ...this.dataFilters.validated ? { validated: this.dataFilters.validated } : {},
            ...this.dataFilters.thematic ? { thematic: this.dataFilters.thematic } : {},
        });
        this.onQuerySkillList(false);
    }

    /**
     * 
     */
    onFilterSave(data): void {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.onFilter();
    }

    /**
     * 
     */
     onFilterReset() {
        this.dataFilters = {};
        this.isFilterActive = false;
        this.onFilter();
    }

    /**
     *
     */
    onRefresh(): void {
        this.onFilter();
        this.selectedSkills = [];
        this.displayBottom = false;
    }

    /**
     *
     */
    onLoad(): void {
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQuerySkillList();
    }

    /**
     * Event handler for change
     */
    onChange(value: boolean, datum: any): void {
        datum.isSelected = value;
        this.selectSkill(datum);
    }

    /**
     * Event handler for modify
     */
    onModify(value: boolean): void {
        const rows = this.rows$.getValue().map((row) => { row.isSelected = value; return row; });
        this.rows$.next(rows);
        this.selectedAll();
    }

    /**
     * Resolves the check if thead checkbox cell is partial or not
     */
    isThCheckboxPartial(): boolean {
        return !this.rows$.getValue().every((row, _i, rows) => rows[0].isSelected === row.isSelected);
    }

    /**
     * Resolves the check if thead checkbox is selected fully or not
     */
    isThCheckboxSelected(): boolean {
        return this.rows$.getValue().every((row) => row.isSelected);
    }

    /**
     *
     */
    goToDetails(id: number): void {
        this.router.navigate([id], { relativeTo: this.route });
    }

    /**
     *
     */
    openComponent(type: string, key: string): void {
        this.displayComponents[type][key] = true;
    }

    /**
     *
     */
    closeComponent(type: string, key: string): void {
        this.displayComponents[type][key] = false;
    }

    /**
     *
     */
    validation(enable: boolean): void {
        const params = {
            company_id: this.company.id,
            validated: enable,
            skill_ids: this.selectedSkills.map((skill) => skill.id)
        };

        this.knowledgeProvider.validation(params).subscribe(() => {
            this.onRefresh();
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    /**
     *
     */
    allow(): void {
        const params = {
            company_id: this.company.id,
            visible: true,
            skill_ids: this.selectedSkills.map((skill) => skill.id)
        };

        this.knowledgeProvider.visibility(params).subscribe(() => {
            this.onRefresh();
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    /**
     *
     */
    selectedAll(): void {
        this.selectAll = !this.selectAll;

        if (this.selectAll) {
            this.rows$.getValue().forEach((skill) => {
                this.selectedSkills.push(skill);
            });
            this.displayBottom = true;
        } else {
            this.selectedSkills = [];
            this.displayBottom = false;
        }
    }

    /**
     *
     */
    selectSkill(skill: any): void {
        const index = this.selectedSkills.findIndex((data) => {
            return data.id === skill.id;
        });

        if (index !== -1) {
            this.selectedSkills.splice(index, 1);
        } else {
            this.selectedSkills.push(skill);
            this.displayBottom = true;
        }

        if (this.selectedSkills.length === 0) {
            this.displayBottom = false;
        }
    }
}
