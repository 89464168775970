<div class="col-8 col-md-8  container__main">
    <div class="container__desc">
        <div class="block-course rounded">
            <div class="block-course__desc">
                <div>
                    <div class="flex between title-tag">
                        <h2 class="block-course__title">{{ externalContent.title }}</h2>
                        <msc-tag [text]="('filter.content-type.' + externalContent.type) | translate"
                            [color]="colorType()" *ngIf="externalContent.type"></msc-tag>
                    </div>

                    <p readMore #readMoreEl="readMore" class="inner-html"
                        [innerHTML]="externalContent.description ? (externalContent.description | safe:'html') : ('words.undescribed-content' | translate)">
                    </p>
                    <a *ngIf="readMoreEl.overflow" (click)="readMoreEl.toggleExtend()">{{ 'words.read-more.' +
                        readMoreEl.extended | translate }} <span><i class="icon icon-arrow-ios-down"
                                [class.icon-arrow-ios-up]="readMoreEl.extended"
                                [class.icon-arrow-ios-down]="!readMoreEl.extended"></i></span></a>

                    <msc-button (click)="subscribeOrAction()"
                        *ngIf="!(externalContentType === 'bookboon' && externalContent.type === 'audio' && externalContent.subscribed)"
                        class="btn-subs" palette="primary" [loading]="isButtonLoading"
                        [ngClass]="{'pointer-events-none': isButtonLoading}">
                        {{ callToAction }}</msc-button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white w-full rounded-sm shadow-md p-4 md:p-8"
        *ngIf="externalContentType === 'bookboon' && externalContent.type === 'audio' && externalContent.subscribed">
        <h2 class="mb-2">{{ 'words.content' | translate }}</h2>
        <msc-common-spinner *ngIf="loading.sections"></msc-common-spinner>
        <div class="flex flex-col" *ngIf="!loading.sections">
            <a class="text-indigo-600 cursor-pointer underline" *ngFor="let section of contentSections"
                (click)="openExternalUrl('external', section.url)">{{section.title}}</a>
        </div>
    </div>
</div>

<aside class="container__aside col-4 col-md-4  order-first " >
    <presentation-additional-details [content]="externalContent" [backgroundImage]="externalContentType === 'cefora' ? 'https://static.myskillcamp.com/images/covers/cefora.png' : (externalContent.picture ? externalContent.picture +
    '?size=1024' : ('https://static.myskillcamp.com/lxp/images/logo-empty-state-' + externalContent.id % 7 + '.jpg'))">
    </presentation-additional-details>
</aside>
