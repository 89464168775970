<h3 class=" text-event text-center justify-center mb-3">{{ (title || 'words.untitled') | translate }}</h3>
<div class="empty__state rounded">
    <div class="state__icon relative">
        <img src="https://static.myskillcamp.com/images/empty-states/shape.svg" alt="empty" *ngIf="!hasPicture">
        <img [src]="source" alt="empty" *ngIf="hasPicture">
        <i [class]="'absolute top-1/4 left-1/4 icon ' + source" *ngIf="!hasPicture"></i>
    </div>

    <p>{{ (title || 'words.untitled') | translate }}</p>
    <span>{{ description | translate }}</span>
</div>
