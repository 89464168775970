import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminCorrectionProvider, Camp, Company } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { MediaManagerComponent } from '@components/media-manager/media-manager.component';
import { TimelineComponent } from '@components/timeline/timeline.component';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-camp-project-correction',
    templateUrl: './project.component.html'
})
export class CampProjectCorrectionComponent implements OnInit, OnChanges {
    @ViewChild('mediaManagerLearner') mediaManagerLearner: MediaManagerComponent;
    @ViewChild('mediaManagerTrainer') mediaManagerTrainer: MediaManagerComponent;
    @ViewChild('correctionTimeline') correctionTimeline: TimelineComponent;
    @Input() session: any;
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();
    private camp: Camp;
    private company: Company;
    public loading: boolean = false;
    public answerMedias: any[];

    constructor(
        private correctionProvider: AdminCorrectionProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    )  {}

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');
        this.company = this.storageService.get('company');
    }

    ngOnChanges(): void {
        if (this.correctionTimeline && this.correctionTimeline.ready) {
            this.correctionTimeline.contextId = this.session.id;
            this.correctionTimeline.getTimeline();
        }
        this.answerMedias = this.session.projectAnswerMedia && Array.isArray(this.session.projectAnswerMedia) ? this.session.projectAnswerMedia.map(media => media.media) : [];
    }

    addMedia(media: any): void {
        if (media) {
            this.session.correctionMedia.push(media);
        }
    }

    deleteMedia(media: any): void {
        const index = this.session.correctionMedia.indexOf(media);
        if (index !== -1) {
            this.session.correctionMedia.splice(index, 1);
        }
    }

    update(): void {

        this.loading = true;
        const params = HttpHelper.cleanParams({
            camp_id: this.camp.id,
            company_id: this.company.id,
            ratio: this.session.ratio,
            feedback: this.session.feedback,
            correctionMediaIds: this.session.correctionMedia.map(media => media.id)
        });

        this.correctionProvider.update('projects', this.session.project.id, this.session.id, params).subscribe((data) => {
            this.session = data.session;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.loading = false;
            this.onUpdate.emit('project');
        });
    }

    spyProject(): void {
        this.session.ratio = this.session.ratio > 100 ? 100 : this.session.ratio;
    }

}
