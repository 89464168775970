import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';

import { MscPaletteTypes } from '../button/button.component';

@Component({
    selector: `msc-common-tag`,
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonTagComponent {
    @Input() label: string;
    @Input() icon: string;
    @Input() pictureURL: string;
    @Input() hasRemoveButton: boolean;
    @Input() palette: MscPaletteTypes = 'secondary';
    @Output() removeEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void { }

    onRemove(): void {
        this.removeEvent.emit();
    }
}