<div class="modal modal--center modal--large modal-invitation-manage" [ngClass]="{'active': isActive}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <div class="modal__back text-primary-500" *ngIf="invitationType" (click)="changeType($event)">
                <i class="icon icon-arrow-ios-left"></i>
                {{ 'words.back' | translate }}
            </div>

            <div class="modal__close">
                <i class="icon icon-close" (click)="close()"></i>
            </div>

            <h2 class="modal__title" *ngIf="!invitationType">
                {{ 'user.pending-invitation' | translate }}
                <span>{{ user.account.name }}</span>
            </h2>

            <h2 class="modal__title" *ngIf="invitationType === 'resend'">
                {{ 'user.send-invitation-mail' | translate }}
            </h2>

            <h2 class="modal__title" *ngIf="invitationType === 'link'">
                {{ 'user.link-invitation' | translate }}
            </h2>
        </div>

        <div class="modal__body" *ngIf="!invitationType">
            <p>{{ 'words.question.want-do' | translate }}</p>
        </div>

        <div *ngIf="invitationType === 'resend'">
            <div class="flex flex-col space-y-4 py-4 items-center">
                <div>
                    <msc-common2-radio groupName="invitationNotification" [(ngModel)]="data.notification"
                        [valueName]="'auto'" [label]="'words.notification.auto' | translate">
                    </msc-common2-radio>
                    <msc-common2-radio groupName="invitationNotification" [(ngModel)]="data.notification"
                        [valueName]="'custom'" [label]="'words.notification.custom' | translate">
                    </msc-common2-radio>
                </div>
                <msc-textarea class="w-full" *ngIf="data.notification === 'custom'" name="textarea" rows="5" cols="33"
                    [(ngModel)]="data.message"></msc-textarea>
            </div>
        </div>

        <div class="modal__body" *ngIf="invitationType === 'reminder'">
            <p>{{ 'user.reminder-invitation-mail' | translate }}</p>
        </div>

        <div class="modal__body" *ngIf="invitationType === 'link'">
            <p>{{ 'user.resend-description' | translate }}</p>

            <div class="modal__form">
                <div class="modal__input">
                    <msc-input type="text" [ngModel]="invitationLink"></msc-input>
                </div>

                <div class="modal__buttons" (click)="clipboard()">
                    <msc-button class="msc-button--primary" palette="primary">{{ 'words.copy-clipboard' | translate }}</msc-button>
                </div>
            </div>
        </div>

        <div class="flex justify-center space-x-2" *ngIf="!invitationType">
            <div class="flex flex-col items-center space-y-2 mt-4">
                <msc-button class="msc-button--primary" palette="primary" (click)="changeType($event, 'resend')">
                    {{ 'user.send-invitation-mail' | translate }}
                </msc-button>

                <msc-button class="msc-button--outline" palette="primary" (click)="changeType($event, 'reminder')">
                    {{ 'user.reminder-invitation-mail' | translate }}
                </msc-button>

                <msc-button class="msc-button--outline" palette="primary" (click)="changeType($event, 'link')">
                    {{ 'user.get-link-invitation' | translate }}
                </msc-button>
            </div>
        </div>

        <div class="flex justify-center space-x-2" *ngIf="invitationType === 'resend'">
            <msc-button class="msc-button--primary" palette="primary" (click)="send()">
                {{ 'words.send' | translate }}
            </msc-button>
        </div>

        <div class="flex items-center space-x-2" *ngIf="invitationType === 'reminder'">
            <msc-button (click)="close()">
                {{ 'words.no' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="primary" (click)="sendReminder()">
                {{ 'words.yes' | translate }}
            </msc-button>
        </div>
    </div>
</div>

