<div class="container__main container__main--full" *ngIf="hasCorrections()">
    <div class="col-xxs-12 content-correction__section animate">
        <h2 *ngIf="title" class="content-correction__title" [ngClass]="{'color--white': index === 0}">
            {{ title }}
        </h2>

        <div class="content-correction__correction" *ngFor="let correction of corrections">
            <msc-feat-correction-detail [correction]="correction"></msc-feat-correction-detail>
        </div>
    </div>
</div>
