<div class="maintenance">
    <div class="maintenance__content bg-primary-600">
        <div class="box box--shadow animate">
            <div class="box__flag">
                <div class="dropdown-flag" [ngClass]="{'active': displays.flag}" [(autoClose)]="displays.flag" (click)="displayed('flag')">
                    <a class="dropdown-flag__selected">
                        <img [src]="'https://static.myskillcamp.com/images/icon/icon-camp/flag_' + this.currentLang + '.svg'">
                        <i class="icon icon-arrow-ios-down"></i>
                    </a>

                    <ul class="dropdown-flag__content">
                        <li class="dropdown-flag__item" (click)="setLang('fr')" *ngIf="currentLang !== 'fr'">
                            <a class="dropdown-flag__link">
                                <img alt="Fr flag" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_fr.svg">
                            </a>
                        </li>

                        <li class="dropdown-flag__item" (click)="setLang('en')" *ngIf="currentLang !== 'en'">
                            <a class="dropdown-flag__link">
                                <img alt="En flag" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_en.svg">
                            </a>
                        </li>

                        <li class="dropdown-flag__item" (click)="setLang('nl')" *ngIf="currentLang !== 'nl'">
                            <a class="dropdown-flag__link">
                                <img alt="Nl flag" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_nl.svg">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <img class="box__icon" src="https://static.myskillcamp.com/maintenance/warning.svg" alt="Warning icon">

            <div class="box__message">
                <h1 class="text-primary-600">{{ status.message }}</h1>

                <h2>{{ 'maintenance.message' | translate : {endDate: status.endDate | date:'dd MMM hh:mm'} }}</h2>
            </div>
        </div>
    </div>

    <div class="maintenance__illus">
        <img src="https://static.myskillcamp.com/maintenance/illustration.svg" alt="Illustration maintenance">
    </div>
</div>
