import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScormProvider } from '../../models/activity/scorm';

@Injectable()
export class ActivityFactory {

    constructor(private http: HttpClient) {}

    get(type: string): any {
        switch (type) {
            case 'scorm': {
                return new ScormProvider(this.http);
            }
        }
    }
}
