import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
    selector: 'msc-score-progress',
    templateUrl: './score-progress.component.html',
})
export class ManageTeamMemberScoreProgressComponent implements OnInit {
    @Input() score = false;

    constructor(private elRef: ElementRef) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.elRef.nativeElement.querySelector('.circle-progress').classList.add(`circle-progress--${this.score === false ? 0 : this.score}`);
        }, 100);
    }
}
