import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'msc-corporate',
    templateUrl: './corporate.component.html'
})

export class CorporateComponent {
    private options: any;
    public display: any;
    public loading: boolean = true;

    constructor(
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.display = this.route.snapshot.data.display;

        this.options = {
            companyId: null,
            token: null,
            applicant: null
        };

        this.route.queryParams.subscribe((params) => {
            this.options.companyId = parseInt(params.companyId || params.company_id, 10);
            this.options.token = params.token;
            this.options.applicant = params.applicant;
        });
    }
}
