import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Company, Pagination, ExternalContentProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-camp-aside-trainers',
    templateUrl: './aside-trainers.component.html',
    styleUrls: ['./aside-trainers.component.scss']
})
export class CampAsideTrainersComponent {
    @ViewChild('aside') aside;
    @Input() camp: any;
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    private company: Company;
    public externalContent: any;
    public members: any = {
        trainers: [],
        availableTrainers: []
    };
    public pagination: Pagination;
    public userPagination: Pagination;
    public loading: boolean = false;
    public data: any = {
        accounts: [],
        excludedMembers: []
    };
    public querys: any;
    public ready: boolean = false;
    public selectedTrainer: any;
    public tab: string = 'list';
    public displays: boolean[] = [];

    constructor(
        private externalContentProvider: ExternalContentProvider,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.company = this.storageService.get('company');

        const params = {
            company_id: this.company.id
        };

        this.externalContentProvider.getManagers(this.externalContent.id, params).subscribe((data) => {
            this.members.trainers = data.accounts;
            this.pagination = data.pagination;
            this.ready = true;
        });

        this.externalContentProvider.getAvailableManagers(this.externalContent.id, params).subscribe((data) => {
            this.members.availableTrainers = data.accounts;
            this.userPagination = data.pagination;
        });

        this.displayed(0);

        this.querys = {
            general: '',
            available: ''
        };
    }

    onOpen(content): void {
        this.externalContent = content;
        this.init();
        this.aside.onOpen();
    }

    search(value): void {
        this.querys.general = value;
        const params = HttpHelper.cleanParams({
            q: this.querys.general,
            company_id: this.company.id
        });

        this.externalContentProvider.getManagers(this.externalContent.id, params).subscribe((data) => {
            this.members.trainers = data.accounts;
            this.pagination = data.pagination;
            this.ready = true;
        });
    }

    searchAvailable(value): void {
        this.querys.available = value;
        const params = HttpHelper.cleanParams({
            q: this.querys.available,
            company_id: this.company.id
        });

        this.externalContentProvider.getAvailableManagers(this.externalContent.id, params).subscribe((data) => {
            this.members.availableTrainers = data.accounts;
            this.userPagination = data.pagination;
        });
    }

    checkMember(account: any): void {
        const index = this.data.accounts.indexOf(account);

        if (index > -1) {
            this.data.accounts.splice(index, 1);
        } else {
            this.data.accounts.push(account);
        }
    }

    isChecked(account: any): boolean {
        return this.data.accounts.indexOf(account) > -1;
    }

    addTrainer(): void {
        const params = {
            account_ids: this.data.accounts
        };

        this.externalContentProvider.addManager(this.externalContent.id, params).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-subscribed-content'));
            this.onSave.emit(true);
            this.aside.onClose();
        });
    }

    removeTrainer(trainer: any): void {
        this.externalContentProvider.deleteManager(this.externalContent.id, trainer.id).subscribe((data) => {
            const index = this.members.trainers.findIndex((t) => {
                return t.id === trainer.id;
            });
            this.members.trainers.splice(index, 1);
            this.members.availableTrainers.push(trainer);
            this.toastService.onSuccess(this.translateService.instant('toast.user-deleted'));
        });
    }

    loadMore(): void {
        this.loading = true;

        const params = {
            company_id: this.company.id,
            page: this.pagination.currentPage + 1
        };

        this.externalContentProvider.getManagers(this.externalContent.id, params).subscribe((data) => {
            this.members.trainers = this.members.trainers.concat(data.accounts);
            this.pagination = data.pagination;
            this.ready = true;
            this.loading = false;
        });
    }

    loadMoreAvailableUsers(): void {
        this.loading = true;

        const params = {
            company_id: this.company.id,
            page: this.userPagination.currentPage + 1
        };

        this.externalContentProvider.getAvailableManagers(this.externalContent.id, params).subscribe((data) => {
            this.members.availableTrainers = this.members.availableTrainers.concat(data.accounts);
            this.userPagination = data.pagination;
            this.loading = false;
        });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    back(): void {
        this.selectedTrainer = null;
    }

    changeTab(tab: string): void {
        this.tab = tab;
        this.selectedTrainer = null;
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }
}
