<div class="wrapper">
    <msc-common-led [isOn]="datum.isValid"></msc-common-led>
    <div class="wrapper">
        <span class="label">{{ datum.label }}</span>
        <ng-container *ngIf="datum.counter">
            <span class="counter">{{ datum.counter }}</span>
        </ng-container>
    </div>
</div>

<div class="wrapper">
    <ng-container *ngIf="!datum.isActive; else isActiveTemplate">
        <msc-button class="msc-button--primary" palette="primary" [disabled]="!datum.isValid" (click)="onActivate()">{{'organize.experience.watch.mail.list.btn.activate' | translate}}</msc-button>
    </ng-container>
    <ng-template #isActiveTemplate>
        <msc-button class="msc-button--tiny" icon='icon-checkmark'>{{'words.active' | translate}}</msc-button>
    </ng-template>
    <msc-button class="msc-button--tiny" type="fab" icon='icon-arrow-ios-right' (click)="onEdit()"></msc-button>
</div>
