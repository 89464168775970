import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';

export interface CommonFeedCommentDatum {
    contentMessage: string;
    dateCreation: string | Date;
    id: number;
    userAvatar: string;
    userName: string;
}

@Component({
    selector: 'msc-common-feed-comment',
    templateUrl: './feed-comment.component.html',
    styleUrls: ['./feed-comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonFeedCommentComponent {
    @Input() datum: CommonFeedCommentDatum;
    @Output() delete?: EventEmitter<any> = new EventEmitter<any>();

    private destroy$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Event handler for delete
     */
    onDelete(): void {
        this.delete && this.delete.emit(this.datum);
    }
}
