<msc-common-aside #aside [title]="'words.catalogue' | translate" (saveEvent)="update()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div class="flex flex-col space-y-2">
                <span>{{ 'catalogue.display.camp' | translate }}</span>

                <msc-common2-radio groupName="displayCamp" [label]="'catalogue.display.camp.internal' | translate" [(ngModel)]="camp.settings.catalogueType" [valueName]="'internals'"></msc-common2-radio>
                <msc-common2-radio groupName="displayCamp" [label]="'catalogue.display.camp.external' | translate" [(ngModel)]="camp.settings.catalogueType" [valueName]="'externals'"></msc-common2-radio>
            </div>

            <div class="flex flex-col space-y-2">
                <span>{{ 'catalogue.picture.cards' | translate }}</span>

                <div class="input-image">
                    <div class="input-image__content" [ngClass]="{'input-image__content--loaded': picture}"
                        [style.backgroundImage]="picture ? 'url(' + picture + '?size=512)' : ''">
                        <p>{{ 'catalogue.picture.add' | translate }}</p>

                        <a (click)="loadPicture()">
                            <i class="icon icon-download-1 bg-secondary-500"></i>
                        </a>

                        <a class="close" (click)="updatePicture()">
                            <i class="icon icon-close"></i>
                        </a>
                    </div>
                </div>

                <input #upload class="display-none" type="file" accept="image/png,image/jpeg,image/jpg,image/gif"
                    [fileUpload]="'file'" (fileStatus)="uploadPicture($event)">
            </div>
        </div>
    </ng-template>
</msc-common-aside>