import { Component, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorStreamService, EmitterService, AuthService, StorageService, ExternalAppsService, TranslateService } from '@services';
import { DataHelper } from '@helpers';
import { addClass, removeClass } from '@functions';
import { environment } from '@env/environment';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent {
    @ViewChild('modalLogout') modalLogout: CommonModalComponent;

    private favicon: HTMLLinkElement;

    public loading: boolean = false;
    public modal: boolean = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private emitterService: EmitterService,
        private errorStreamService: ErrorStreamService,
        private externalAppService: ExternalAppsService,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) {
        (window as any).addEventListener('CookiebotOnConsentReady', () => {
            if ((window as any).Cookiebot?.changed) {
                (location as any).reload();
            }
        });
    }

    ngOnInit(): void {
        // temporary hide the favicon
        this.favicon = document.querySelector('#favicon');
        this.favicon.href = '';

        this.errorStreamService.listen().subscribe(() => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        });

        this.emitterService.get('global.logout').subscribe((data) => {
            if (typeof data === 'boolean' || typeof data === 'object' && !DataHelper.isEmpty(data)) {
                this.modalLogout.onOpen();
            }
        });

        this.emitterService.get('global.overlay').subscribe((enable) => {
            if (typeof enable === 'boolean') {
                this.storageService.set('overlayStatus', enable);

                if (enable) {
                    addClass('.app-main', 'disable-overflow');
                    addClass('.overlay', 'force-active');
                } else {
                    removeClass('.app-main', 'disable-overflow');
                    removeClass('.overlay', 'force-active');
                }
            }
        });

        this.emitterService.get('favicon.change').subscribe((data) => {
            // reinject favicon so that the MSC logo does not appear before loading the theme
            if (!DataHelper.isEmpty(data)) {
                this.favicon.href = data + '?size=64';
            } else {
                this.favicon.href = 'https://static.myskillcamp.com/lxp/favicon.ico';
            }
        });

        this.checkMaintenanceMode();
    }

    checkMaintenanceMode(): void {
        if (environment.maintenance && location.href.indexOf('maintenance') === -1 && !this.storageService.getLocal('force_access')) {
            this.router.navigate(['maintenance']);
        }
    }

    logout(): void {
        this.modalLogout.onClose();
        this.authService.logout();
    }
}
