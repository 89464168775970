import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@services';
import * as moment from 'moment';

@Component({
    selector: 'msc-shared-stats-provider-manager',
    templateUrl: './stats-provider-manager.component.html',
    styleUrls: ['./stats-provider-manager.component.scss']
})
export class SharedStatsProviderManagerComponent implements OnInit, OnChanges {
    @Input() title: any;
    @Input() stats: any;
    @Input() dates: any;
    @Input() granularity: string = 'day';
    @Input() duration: boolean = false;
    @Input() color: string = '#6cd4a8';
    @Input() legend: string = '';
    @Input() warning: boolean = false;
    private internalStats: any[] = [];
    private internalGlobalStats: any[] = [];
    private active: boolean = false;
    public internalGranularity: string = 'day';
    public data: any;
    public dataQuant: any;
    public unit: string = 'minute';
    public options: any;
    public optionsQuant: any;
    public displays: any;

    constructor(private translateService: TranslateService) { }

    ngOnInit(): void {
        this.setGranularity();
        this.initDataset();
        this.initOptions();

        this.active = true;

        this.displays = {
            granularity: false
        };
    }

    ngOnChanges(): void {
        if (this.active) {
            this.initDataset();
            this.initOptions();
        }
    }

    private setGranularity(): void {
        if (this.dates.diff > 365) {
            this.internalGranularity = 'year';
        } else if (this.dates.diffQual > 60) {
            this.internalGranularity = 'month';
        } else {
            this.internalGranularity = 'day';
        }
    }

    private initDataset(): void {
        this.datasetQualitative();
        this.datasetQuantitative();
    }

    private datasetQualitative(): void {
        this.internalStats = [];
        for (let i = 0; i <= this.dates.diffQual; i++) {
            this.internalStats.push({ count: 0, date: moment(this.dates.startQual).add(i, 'days').format('YYYY-MM-DD') });
        }

        for (const internalStat of this.internalStats) {
            for (const stat of this.stats) {
                if (stat.date === internalStat.date) {
                    internalStat.count = stat.count;
                }
            }
        }

        this.data = {
            datasets: [{
                data: this.processData()
            }]
        };
    }

    private datasetQuantitative(): void {
        this.internalStats = [];
        for (let i = 0; i <= this.dates.diff; i++) {
            this.internalStats.push({ count: 0, date: moment(this.dates.start).add(i, 'days').format('YYYY-MM-DD') });
        }

        for (const internalStat of this.internalStats) {
            for (const stat of this.stats) {
                if (stat.date === internalStat.date) {
                    internalStat.count = stat.count;
                }
            }
        }

        this.dataQuant = {
            datasets: [{
                data: this.processData(true)
            }]
        };
    }

    private initOptions(): void {
        this.setOptionsQualitative();
        this.setOptionsQuantitative();
    }

    private setOptionsQualitative(): void {
        this.options = {
            legend: false,
            responsive: true,
            tooltips: {
                callbacks: {
                    title: (items) => {
                        const format = (this.granularity === 'year') ? 'YYYY' : this.granularity === 'month' ? 'MMM YYYY' : 'DD MMM YYYY';
                        return `${this.translateService.instant('words.date')} : ${moment(items[0].label).locale(this.translateService.getCurrentLang()).format(format)}`;
                    },
                    label: (item) => {
                        if (this.duration) {
                            const index = this.internalGlobalStats.findIndex(stat => {
                                return stat.count === item.value;
                            });

                            if (index > -1) {
                                const seconds = this.internalGlobalStats[index].seconds;
                                return this.getDuration('days', seconds) + ' ' + this.getDuration('hours', seconds) + ' ' + this.getDuration('minutes', seconds);
                            }
                        }
                        return item.value;
                    }
                },
                displayColors: false
            },
            hover: {
                mode: null
            },
            elements: {
                line: {
                    fill: false,
                },
                point: {
                    radius: 5,
                    backgroundColor: this.color,
                    borderColor: this.color
                }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: this.processTime(),
                    offset: true,
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: '#D3D5E4'
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: this.duration,
                        labelString: this.translateService.instant('words.' + this.unit)
                    },
                    gridLines: {
                        borderDash: [5, 10],
                        borderDashOffset: 100,
                        drawTicks: false,
                        drawBorder: false
                    },
                    ticks: {
                        stepSize: this.processStep(),
                        suggestedMax: this.suggestedMax(),
                        fontColor: '#D3D5E4'
                    }
                }]
            }
        };
    }

    private setOptionsQuantitative(): void {
        this.optionsQuant = {
            legend: false,
            responsive: true,
            tooltips: {
                callbacks: {
                    title: (items) => {
                        const format = (this.granularity === 'year') ? 'YYYY' : this.granularity === 'month' ? 'MMM YYYY' : 'DD MMM YYYY';
                        return `${this.translateService.instant('words.date')} : ${moment(items[0].label).locale(this.translateService.getCurrentLang()).format(format)}`;
                    },
                    label: (item) => {
                        if (this.duration) {
                            const index = this.internalStats.findIndex(stat => {
                                return stat.count === item.value;
                            });

                            if (index > -1) {
                                const seconds = this.internalStats[index].seconds;
                                return this.getDuration('days', seconds) + ' ' + this.getDuration('hours', seconds) + ' ' + this.getDuration('minutes', seconds);
                            }
                        }
                        return item.value;
                    }
                },
                displayColors: false
            },
            hover: {
                mode: null
            },
            elements: {
                line: {
                    fill: false,
                },
                point: {
                    radius: 5,
                    backgroundColor: this.color,
                    borderColor: this.color
                }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: this.processTime(true),
                    offset: true,
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontColor: '#D3D5E4'
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: this.duration,
                        labelString: this.translateService.instant('words.' + this.unit)
                    },
                    gridLines: {
                        borderDash: [5, 10],
                        borderDashOffset: 100,
                        drawTicks: false,
                        drawBorder: false
                    },
                    ticks: {
                        stepSize: this.processStep(),
                        suggestedMax: this.suggestedMax(),
                        fontColor: '#D3D5E4'
                    }
                }]
            }
        };
    }

    private processData(quantitative?: boolean): any {
        const startDate = quantitative ? this.dates.start : this.dates.startQual;
        const granularity = quantitative ? this.internalGranularity : this.granularity;

        switch (granularity) {
            case 'week':
                const firstDay = moment(startDate).startOf('week');
                const numberOfWeeks = moment(this.dates.end).diff(startDate, 'weeks');
                const weeks = [{ count: 0, date: moment(firstDay).format('YYYY-MM-DD') }];

                for (let i = 1; i <= numberOfWeeks; i++) {
                    weeks.push({ count: 0, date: moment(firstDay).add(i, 'weeks').format('YYYY-MM-DD') });
                }

                for (const week of weeks) {
                    for (const internalStat of this.internalStats) {
                        if (moment(week.date).isSame(internalStat.date, 'week')) {
                            const index = weeks.findIndex(data => data.date === week.date);
                            weeks[index].count += internalStat.count;
                        }
                    }
                }
                this.internalStats = weeks;
                break;
            case 'month':
            case 'year':
                const format = this.granularity === 'month' ? 'YYYY-MM' : 'YYYY';
                let compare = moment(startDate).format(format);
                const merge = [{ count: 0, date: compare }];
                for (const internalStat of this.internalStats) {
                    if (compare === moment(internalStat.date).format(format)) {
                        const index = merge.findIndex(data => data.date === compare);
                        merge[index].count += internalStat.count;
                    } else {
                        compare = moment(internalStat.date).format(format);
                        merge.push({ count: internalStat.count, date: compare });
                    }
                }
                this.internalStats = merge;
                break;
        }

        if (this.duration) {
            const max = this.getMax();

            let divider = 1;
            if (max > 86400) {
                divider = 86400;
                this.unit = 'day';
            } else if (max > 3600) {
                divider = 3600;
                this.unit = 'hour';
            } else if (max > 60) {
                divider = 60;
                this.unit = 'minute';
            }

            this.internalStats = this.internalStats.map((stat) => {
                return {
                    count: parseFloat((stat.count / divider).toString()).toFixed(2),
                    seconds: stat.count,
                    date: stat.date
                };
            });

            if (!quantitative) {
                this.internalGlobalStats = this.internalStats;
            }
        }

        return this.internalStats.map((stat) => {
            return {
                x: stat.date,
                y: stat.count
            };
        });
    }

    private getMax(): number {
        const countCollection = this.internalStats.map((stat) => {
            return stat.count;
        });

        return Math.max(...countCollection);
    }

    private processStep(): number {
        return this.getMax() > 0 ? Math.ceil(this.getMax() / 5) : 1;
    }

    private suggestedMax(): number {
        const step = Math.ceil(this.getMax() / 5);

        return this.getMax() > 0 ? this.getMax() + step : 1;
    }

    private processTime(quantitative?: boolean): any {
        const granularity = quantitative ? this.internalGranularity : this.granularity;

        if (granularity === 'year') {
            return {
                unit: 'year',
                displayFormats: {
                    year: 'YYYY'
                }
            };
        } else if (granularity === 'month') {
            return {
                unit: 'month',
                displayFormats: {
                    month: 'MMM'
                }
            };
        } else if (granularity === 'week') {
            return {
                unit: 'week',
                displayFormats: {
                    week: 'DD MMM'
                }
            };
        } else {
            return {
                unit: 'day',
                displayFormats: {
                    day: 'DD MMM'
                }
            };
        }
    }

    private getDuration(type: string, seconds: number): string {
        let time = Math.floor(seconds / 60) % 60;
        let unit = this.translateService.instant('unit.minute');

        switch (type) {
            case 'hours':
                time = Math.floor(seconds / 3600) % 24;
                unit = this.translateService.instant('unit.hour');
                break;
            case 'days':
                time = Math.floor(seconds / 86400);
                unit = this.translateService.instant('unit.day');
                break;
        }

        return `${time > 0 ? time + unit.toLocaleLowerCase() : ''}`;
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    changeGranularity(granularity: string): void {
        this.internalGranularity = granularity;

        this.datasetQuantitative();
        this.setOptionsQuantitative();
    }

    isEnable(): boolean {
        if (this.dates.diff > 365 || this.dates.diff > 14 && this.dates.diff <= 60) {
            return true;
        }
        return false;
    }
}
