import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Camp } from '../../../camp';
import { environment } from '@env/environment';

@Injectable()
export class CompanyUserAccessProvider {

    constructor(private http: HttpClient) {}

    getLearn(id: number, params: any): Observable<any> {
        const response = {
            camps: [],
            availableCamps: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${id}/permissions/learner`, {params}).pipe(
            map((data: any) => {
                data.camps.map((camp) => {
                    response.camps.push(new Camp(camp));
                });
                data.availableCamps.map((camp) => {
                    response.availableCamps.push(new Camp(camp));
                });

                return response;
            })
        );
    }

    getOrganize(id: number, params: any): Observable<any> {
        const response = {
            meta: {},
            camps: [],
            availableCamps: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${id}/permissions/administrator`, {params}).pipe(
            map((data: any) => {
                response.meta = data.meta;
                data.camps.map((camp) => {
                    response.camps.push(new Camp(camp));
                });
                data.availableCamps.map((camp) => {
                    response.availableCamps.push(new Camp(camp));
                });

                return response;
            })
        );
    }

    getCreate(id: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/permissions/author`, {params}).pipe(
            map(data => data)
        );
    }

    addCamps(id: number, params: any, type: string): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/company/${id}/permissions/${type}`, params).pipe(
            map(data => data)
        );
    }

    removeCamp(id: number, params: any, type: string): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/company/${id}/permissions/${type}`, {params}).pipe(
            map(data => data)
        );
    }

    update(id: number, params: any, type: string): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/company/${id}/permissions/${type}`, params).pipe(
            map(data => data)
        );
    }
}
