import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//
import { HttpUtils } from '@common2/utils/http.utils';
import { environment } from '@env/environment';
//
import { Group } from './group';
import { Pagination } from '../pagination';

@Injectable()
export class GroupProvider {

    constructor(private http: HttpClient) { }

    get(id: string | number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            groups: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${id}/groups`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.groups = data.groups.map((group) => {
                    return new Group(group);
                });
                return response;
            })
        );
    }

    getLearningGroups(companyId: string | number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups`, { params });
    }

    getAvailableLearningGroups(companyId: string | number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups/available`, { params });
    }

    getLearningGroup(companyId: string | number, groupId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups/${groupId}`, { params });
    }

    getLearningGroupContent(companyId: string | number, groupId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups/${groupId}/contents`, { params });
    }

    getLearningGroupMembers(companyId: string | number, groupId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups/${groupId}/users`, { params });
    }

    getGroup(id: string | number, groupId: number, params?: any): Observable<Group> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/groups/${groupId}`, { params }).pipe(
            map((data: any) => new Group(data.group))
        );
    }

    getAvailableGroups(id: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            groups: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${id}/groups/available`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.groups = data.groups.map((group: any) => {
                    return new Group(group);
                });
                return response;
            })
        );
    }

    getUsers(companyId: number, id: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            users: [],
        };

        return this.http.get(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups/${id}/users`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.users = data.users.map((user: any) => {
                    return user;
                });
                return response;
            })
        );
    }

    subscribe(companyId: number, id: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups/${id}/users`, params);
    }

    unsubscribe(id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/group/${id}/unsubscribe`);
    }

    getContents(id: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            contents: [],
        };

        return this.http.get(`${environment.envVar.API_URL}/learning/groups/${id}/contents`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.contents = data.contents.map((content: any) => {
                    return content;
                });
                return response;
            })
        );
    }

    getResources(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/group/${id}/resource`, { params });
    }

    getResourcesV2(companyId: number, groupId: number, params: any = {}): Observable<any> {
        return this.http.get<any>(`${environment.envVar.API_URL}/learning/companies/${companyId}/groups/${groupId}/resources`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((r: HttpResponse<any>) => r.body),
        );
    }
}
