import { Directive, ElementRef, HostListener, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmitterService } from '@services';

@Directive({
    selector: '[msc-draggable]'
})
export class DraggableDirective implements OnInit {
    @Input() dragData: any;
    @Output() onSimpleClick: EventEmitter<any> = new EventEmitter();
    private el: ElementRef;
    private draggedElement: ElementRef;
    private isDragged: boolean = false;
    private hasMoved: boolean = false;
    private size: any = {
        w: 0,
        h: 0
    };

    constructor(el: ElementRef, private emitterService: EmitterService) {
        this.el = el;
    }

    ngOnInit(): void {
        this.size = {
            w: this.el.nativeElement.offsetWidth,
            h: this.el.nativeElement.offsetHeight
        };

        this.emitterService.get('drag.dragging').subscribe((data) => {
            if (data.dragging) {
                this.el.nativeElement.classList.add('pointer-events-none');
            } else {
                this.el.nativeElement.classList.remove('pointer-events-none');
            }
        })
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(e): void {
        e.preventDefault();
        this.draggedElement = Object.assign({}, this.el);
        this.draggedElement.nativeElement = this.el.nativeElement.cloneNode(true);
        this.el.nativeElement.parentElement.appendChild(this.draggedElement.nativeElement);
        this.el.nativeElement.classList.add('msc-draggable__base-element');
        this.draggedElement.nativeElement.classList.add('msc-draggable__dragged');
        this.draggedElement.nativeElement.style.top = (e.clientY - (this.size.h / 2)) + 'px';
        this.draggedElement.nativeElement.style.left = (e.clientX - (this.size.x / 2)) + 'px';
        this.isDragged = true;
        this.emitterService.set('drag.dragging', {dragging: true, data: null});
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e): void {
        if (this.isDragged) {
            this.hasMoved = true;
            this.draggedElement.nativeElement.style.top = (e.clientY - (this.size.h / 2)) + 'px';
            this.draggedElement.nativeElement.style.left = (e.clientX - (this.size.w / 2)) + 'px';
        }
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(e): void {
        e.preventDefault();
        if (this.isDragged) {
            this.el.nativeElement.parentElement.removeChild(this.el.nativeElement.parentElement.childNodes[this.el.nativeElement.parentElement.childNodes.length - 1]);
            this.el.nativeElement.classList.remove('msc-draggable__base-element');
            this.draggedElement = null;
            if (!this.hasMoved) {
                this.onSimpleClick.emit(this.dragData);
            }
            this.emitterService.set('drag.dragging', {dragging: false, data: this.dragData});
        }
        this.el.nativeElement.classList.remove('pointer-events-none');
        this.hasMoved = false;
        this.isDragged = false;
    }
}
