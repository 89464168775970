<msc-common-aside #aside [hasActionButtons]="tab === 'contents'">
    <ng-template #headerTemplate>
        <div class="flex flex-col space-y-4 relative">
            <msc-button class="absolute right-2 top-2" type="fab" icon="icon-close" (click)="aside.onClose()">
            </msc-button>
            <div class="flex flex-col">
                <div class="flex space-x-2 items-center">
                    <msc-button type="fab" icon="icon-arrow-ios-left" (click)="back()" *ngIf="tab === 'contents'">
                    </msc-button>
                    <h2>
                        {{ (tab === 'list') ? ('group.content.manage' | translate) : 'words.add-content' | translate }}
                        : {{
                        currentGroup.title }}
                    </h2>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="slide__content">
            <div class="flex justify-between">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
                    (searchEvent)="search($event.value)"></msc-common-search>

                <msc-button class="msc-button--primary" palette="secondary" icon="icon-plus-circle" *ngIf="tab === 'list'"
                    (click)="changeTab('contents')">
                    {{ 'words.add' | translate }}
                </msc-button>
            </div>

            <div class="dynamic-table" *ngIf="tab === 'list'">
                <div class="dynamic-table__head">
                    <div class="dynamic-table__cell cell-title">
                        <span>{{ 'group.content.name' | translate }}</span>
                    </div>

                    <div class="dynamic-table__cell cell-large">
                        {{ 'group.course.subscribers' | translate }}
                    </div>

                    <div class="dynamic-table__cell cell-actions"></div>
                </div>

                <div class="dynamic-table__body">
                    <div class="dynamic-table__row row-radius row-shadow" *ngFor="let content of contents">
                        <div class="dynamic-table__cell cell-title">
                            <div *ngIf="content.data.title.length > 50" [tooltip]="content.data.title">{{
                                content.data.title | truncate: 50 }}</div>
                            <div *ngIf="content.data.title.length < 50">{{ content.data.title | truncate: 50 }}</div>

                        </div>

                        <div class="dynamic-table__cell cell-large">
                            <span *ngIf="content.data.usedSeats">{{ content.data.usedSeats }}</span>
                            <span *ngIf="content.data.limit <= 0"><span class="separator">/</span>-</span>
                            <span *ngIf="content.data.limit > 0"><span class="separator">/</span>{{ content.data.limit
                                }}</span>
                        </div>

                        <div class="dynamic-table__cell cell-actions">
                            <a (click)="deleteFromGroup(content)">
                                <i class="cell-actions__icon icon icon-trash"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col space-y-2" *ngIf="tab === 'contents'">
                <div class="flex space-x-4 p-2 items-center overflow-hidden" *ngFor="let content of availableContents">
                    <msc-common2-checkbox [value]="isContentSelected(content)" (changeEvent)="addContent(content)">
                    </msc-common2-checkbox>
                    <div class="flex flex-col">
                        <p class="truncate">{{ content.data.title || 'untitled' }}</p>
                        <msc-tag [color]="getColorType(content)" [text]="('words.' + getType(content) | translate)">
                        </msc-tag>
                    </div>
                </div>
            </div>

            <msc-load-more [loading]="loading"
                [pagination]="(tab === 'list') ? pagination.content : pagination.addContent" (onLoad)="loadMore(tab)">
            </msc-load-more>

            <div *ngIf="ready && tab === 'list' && contents.length === 0">
                <msc-empty-state [title]="'words.contents'" [description]="'empty.content.search.title'">
                </msc-empty-state>
            </div>

            <msc-common-spinner *ngIf="!ready"></msc-common-spinner>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div class="flex items-center justify-between grow col-gap-xs " *ngIf="tab === 'contents'">
            <msc-common2-checkbox [value]="isAllSelected" [label]="'words.select-all' | translate"
                (changeEvent)="selectAll()"></msc-common2-checkbox>

            <div class="flex space-x-2">
                <msc-button (click)="changeTab('list')">
                    {{ 'words.cancel'| translate }}
                </msc-button>

                <msc-button class="msc-button--primary" palette="secondary" (click)="save()">
                    {{ 'words.save'| translate }}
                </msc-button>
            </div>
        </div>
    </ng-template>
</msc-common-aside>
