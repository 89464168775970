<div class="content-details">
    <h3 class="col-12 content-details__title">{{ 'words.general-settings' | translate }}</h3>

    <div class="col-6 settings content-details__block">
        <div class="settings__list">
            <div class="settings__item">
                <p>
                    <i class="icon icon-activity text-primary-500"></i>
                    {{ 'words.state' | translate }}
                </p>

                <msc-common-select class="rounded-[14px] text-xs h-7"
                    [ngClass]="'status-' + instance.status" [data]="statusOptions" [isButton]="true">
                    <ng-template #buttonTemplate let-data="data">
                        <span>{{ ('manage.instance.status-' + instance.status) | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <ng-container *ngIf="instance.status !== datum.value">
                            <span class="rounded-[14px] py-2 px-2 my-2 text-xs" [ngClass]="'status-' + datum.value"
                                (click)="changeInstanceStatus(datum.value)">{{ datum.label }}</span>
                        </ng-container>
                    </ng-template>
                </msc-common-select>
            </div>

            <div class="settings__item" *ngIf="instanceType !== 'external'">
                <p>
                    <i class="icon-folder text-primary-500"></i>
                    {{ 'words.hiding' | translate }}
                </p>

                <msc-common2-switch [value]="instance.archived" (changeEvent)="instance.archived = !instance.archived; updateInstance()"></msc-common2-switch>
            </div>

            <div class="settings__item">
                <p>
                    <i class="icon icon-star text-primary-500"></i>
                    {{ 'words.favorites' | translate }}
                </p>
                <msc-common2-switch [value]="instance.favorite" (changeEvent)="instance.favorite = !instance.favorite; updateInstance()"></msc-common2-switch>
            </div>

            <div class="settings__item" *ngIf="instanceType !== 'external'">
                <div class="settings__form">
                    <p>
                        <i class="icon icon-calendar text-primary-500"></i>
                        {{ 'subscription.subscription-deadline' | translate }}
                    </p>

                    <div class="input input--date input--icon-right" [ngClass]="{'active': displays.subscriptionDate}" [(autoClose)]="displays.subscriptionDate">
                        <input [attr.type]="'text'" readonly="true" name="input" autocomplete="off" class="input__text" (focus)="displayed('subscriptionDate')" [ngModel]="settings.subscriptionDeadline | date" [debounce]="800" (onDebounce)="updateInstanceSettings()">

                        <span class="input__icon input__icon--trash" *ngIf="settings.subscriptionDeadline" (click)="resetDateInput('subscriptionDeadline')">
                            <i class="icon icon-trash-2"></i>
                        </span>

                        <span class="input__icon">
                            <i class="icon icon-calendar text-secondary-500"></i>
                        </span>

                        <div class="dropdown-datepicker">
                            <msc-datepicker [date]="settings.subscriptionDeadline" [actions]="true" (onUpdate)="updateDate('subscriptionDeadline', $event)" (onClose)="displayed('subscriptionDate')"></msc-datepicker>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings__item" *ngIf="instanceType !== 'external'">
                <div class="settings__form">
                    <p>
                        <i class="icon icon-lock text-primary-500"></i>
                        {{ 'words.access-to.course' | translate }}
                    </p>

                    <div class="settings__inputs">
                        <p class="settings__label">{{ 'words.organize-settings.lock' | translate }}</p>

                        <div class="flex space-x-4 mb-2">
                            <msc-common2-radio class="text-sm" groupName="settingsLock" [label]="'words.yes' | translate" [(ngModel)]="settings.locked" (ngModelChange)="updateInstanceSettings()" [valueName]="true"></msc-common2-radio>
                            <msc-common2-radio class="text-sm" groupName="settingsLock" [label]="'words.no' | translate" [(ngModel)]="settings.locked" (ngModelChange)="updateInstanceSettings()" [valueName]="false"></msc-common2-radio>
                        </div>

                        <div class="settings__conditional-form" *ngIf="settings.locked">
                            <p class="settings__label">{{ 'words.organize-settings.date' | translate }}</p>

                            <div class="input input--date input--icon-right" [ngClass]="{'active': displays.unlockDate}" [(autoClose)]="displays.unlockDate">
                                <input [attr.type]="'text'" readonly="true" name="input" autocomplete="off" class="input__text" (focus)="displayed('unlockDate')" [ngModel]="settings.unlockDate | date">

                                <span class="input__icon input__icon--trash" *ngIf="settings.unlockDate" (click)="resetDateInput('unlockDate')">
                                    <i class="icon icon-trash-2"></i>
                                </span>

                                <span class="input__icon">
                                    <i class="icon icon-calendar text-secondary-500"></i>
                                </span>

                                <div class="dropdown-datepicker">
                                    <msc-datepicker [date]="settings.unlockDate" [actions]="true" (onUpdate)="updateDate('unlockDate', $event)" (onClose)="displayed('unlockDate')"></msc-datepicker>
                                </div>
                            </div>

                            <p class="settings__label">{{ 'words.organize-settings.notify-users' | translate }}</p>

                            <div class="flex space-x-4">
                                <msc-common2-radio class="text-sm" groupName="unlockNotification" [label]="'words.yes' | translate" [(ngModel)]="settings.unlockNotification" (ngModelChange)="updateInstanceSettings()" [valueName]="true"></msc-common2-radio>
                                <msc-common2-radio class="text-sm" groupName="unlockNotification" [label]="'words.no' | translate" [(ngModel)]="settings.unlockNotification" (ngModelChange)="updateInstanceSettings()" [valueName]="false"></msc-common2-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 trainers content-details__block">
        <div class="trainers__header">
            <p>
                <i class="icon icon-people text-primary-500"></i>
                {{ 'words.trainers' | translate }}
            </p>

            <a class="link link-add" (click)="trainersAside.onOpen()">
                <i class="icon icon-plus-1 bg-secondary-600"></i>

                <span>{{ 'words.add' | translate }}</span>
            </a>
        </div>

        <div class="trainers__list">
            <div class="trainers__item" *ngFor="let manager of managers">
                <div class="avatar-contact">
                    <msc-common-avatar class="mr-2" [label]="manager?.name"
                        [pictureURL]="manager?.picture ? (manager?.picture + '?size=128') : null">
                    </msc-common-avatar>

                    <div class="avatar__content">
                        <p class="profile__name text-secondary-800">{{ manager.name }}</p>
                        <div class="profile__job">{{ manager.headline | truncate:30}}</div>
                    </div>
                </div>

                <div class="trainers__action">
                    <a *ngIf="manager.id !== me.id" (click)="conversationWith(manager)">
                        <i class="icon icon-message-circle text-secondary-500"></i>
                    </a>

                    <a (click)="modalDeleteManager.onOpen(); openModalDelete(manager)">
                        <i class="icon icon-trash-2"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-manage-instance-settings-aside-trainers #trainersAside
                                             [companyId]="company.id"
                                             [instanceId]="instance.id"
                                             [managers]="managers"
                                             (onSave)="addManagers($event)">
</msc-manage-instance-settings-aside-trainers>

<msc-common-modal size="30vw" #modalDeleteManager [title]="'manage.instance.settings.modal.delete-trainer.title' | translate">
    <ng-template>
        <p *ngIf="currentManager?.id !== me.id">{{ 'manage.instance.settings.modal.delete-trainer.description' | translate }}</p>
        <p *ngIf="currentManager?.id === me.id && managers.length > 1">{{ 'manage.instance.settings.modal.delete-me.description' | translate }}</p>
        <p *ngIf="currentManager?.id === me.id && managers.length === 1">{{ 'manage.instance.settings.modal.delete-me.nothing.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="closeModalDelete(); modalDeleteManager.onClose()">{{'words.no' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteManager(); modalDeleteManager.onClose()">{{'words.yes' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
