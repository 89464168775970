<section class="head">
    <h3>Radio</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Radio with Form</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <form [formGroup]="form">
                    <msc-common2-radio [valueName]="'coffee'" [label]="'Coffee'" formControlName="beverage"></msc-common2-radio>
                    <msc-common2-radio [valueName]="'soda'" [label]="'Soda'" formControlName="beverage"></msc-common2-radio>
                    <msc-common2-radio [valueName]="'water'" [label]="'Water'" formControlName="beverage" [disabled]="true"></msc-common2-radio>
                </form>
            </div>

            <div class="segment">
                <code>{{ form.value | json }}</code>
            </div>
        </div>
    </div>
    <div class="segment">
        <h4>Radio with NgModel</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common2-radio groupName="ngmodelRadio" [(ngModel)]="model" [valueName]="'coffee'" [label]="'Coffee'"></msc-common2-radio>
                <msc-common2-radio groupName="ngmodelRadio" [(ngModel)]="model" [valueName]="'soda'" [label]="'Soda'"></msc-common2-radio>
                <msc-common2-radio groupName="ngmodelRadio" [(ngModel)]="model" [valueName]="'water'" [label]="'Water'" [disabled]="true"></msc-common2-radio>
            </div>

            <div class="segment">
                <code>Beverage: {{ model }}</code>
            </div>
        </div>
    </div>
    <div class="segment">
        <h4>Radio with NgModel</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common2-radio groupName="ngmodelRadio2" [(ngModel)]="model2" [valueName]="'beer'" [label]="'Beer'"></msc-common2-radio>
                <msc-common2-radio groupName="ngmodelRadio2" [(ngModel)]="model2" [valueName]="'wine'" [label]="'Wine'"></msc-common2-radio>
                <msc-common2-radio groupName="ngmodelRadio2" [(ngModel)]="model2" [valueName]="'tequile'" [label]="'Tequila'" [disabled]="true"></msc-common2-radio>
            </div>

            <div class="segment">
                <code>Nice Beverage: {{ model2 }}</code>
            </div>
        </div>
    </div>
    <div class="segment">
        <h4>Radio with checked</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common2-radio groupName="checkedRadio" [ngModel]="'a'" [valueName]="'a'" [label]="'Coffee'"></msc-common2-radio>
                <msc-common2-radio groupName="checkedRadio" [ngModel]="'b'" [valueName]="false" [label]="'Soda'"></msc-common2-radio>
                <msc-common2-radio groupName="checkedRadio" [ngModel]="'c'" [valueName]="false" [label]="'Water'" [disabled]="true"></msc-common2-radio>
            </div>
        </div>
    </div>
</section>
