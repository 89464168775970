import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmitterService, StorageService } from '@services';
import { Pagination } from '@lighty';
import { ExtensionLink } from '@interfaces';
import { GroupProvider } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-shared-aside-extension-community',
    templateUrl: './aside-extension-community.component.html'
})
export class SharedExtensionCommunityComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();
    private company: any;
    public communities: any[] = [];
    public pagination: Pagination;
    public ready: boolean = false;

    constructor(private emitterService: EmitterService, private storageService: StorageService, private groupProvider: GroupProvider) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.getCommunities();
    }

    private getCommunities(): void {
        const params = HttpHelper.cleanParams({
            include: 'account,subscription,users_preview',
            status: 'allGroups'
        });

        this.groupProvider.get(this.company.id, params).subscribe((data) => {
            this.communities = data.groups;
            this.pagination = data.pagination;
            this.ready = true;
        });
    }

    loadMore(): void {
        const params = HttpHelper.cleanParams({
            include: 'account,subscription,users_preview',
            status: 'allGroups',
            page: this.pagination.currentPage + 1
        });

        this.groupProvider.get(this.company.id, params).subscribe((data) => {
            this.communities = this.communities.concat(data.groups);
            this.pagination = data.pagination;
        });
    }

    onChange(event: any): void {
        const id = parseInt(event.target.value, 10);
        const index = this.extensionLink.communityList.indexOf(id);

        if (index !== -1) {
            this.extensionLink.communityList.splice(index, 1);
        } else {
            this.extensionLink.communityList.push(id);
        }
    }

    isChecked(camp: any): boolean {
        return this.extensionLink.communityList.indexOf(camp.id) > -1;
    }

    nextPanel(): void {
        this.emitterService.extensionPage.emit('form');
    }
}
