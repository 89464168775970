<div class="team-member">
    <div class="team-member__header">
        <a class="link link-back link-back--transparent link-back--horizontal" [routerLink]="['../../']">
            <span>{{ 'words.members' | translate }}</span>
            <i class="icon icon-arrow-ios-up"></i>
        </a>

        <div class="flex">
            <msc-button class="msc-button--outline" icon="icon-email" (click)="mailUserAside.onOpen(user.data)">
                {{ 'group.team.send-email' | translate }}
            </msc-button>
            <msc-button class="msc-button--outline" icon="icon-plus-circle" (click)="displayed('subscribe')">
                {{ 'group.team.join-training' | translate }}
            </msc-button>
        </div>
    </div>
    <div class="team-member__navigation">
        <div class="team-member__navigation__container">
            <div class="navigation">
                <a (click)="navigate('prev')"
                    *ngIf="!teamMembers.loading && (user?.index > 0 || teamMembers.pagination.currentPage > 1)"><i
                        class="icon icon-arrow-ios-left"></i></a>
                <msc-common-spinner *ngIf="teamMembers.loading"></msc-common-spinner>
            </div>
            <div class="current-user">
                <div class="current-user__icon mr-2">
                    <msc-common-avatar [label]="user?.data.name"
                        [pictureURL]="user?.data.picture ? (user?.data.picture + '?size=128') : null">
                    </msc-common-avatar>
                </div>
                <div class="current-user__details">
                    <p>{{ user?.data.name }}</p>
                    <div *ngIf="user?.data.lastLearningActivity">{{ 'words.active' | translate }} {{ user?.data.lastLearningActivity | timeAgo }}</div>
                </div>
                <div class="current-user__job">
                    <span>{{ user?.data.headline }}</span>
                </div>
            </div>
            <div class="navigation">
                <a (click)="navigate('next')"
                    *ngIf="!teamMembers.loading && (user?.index + 1 < teamMembers.pagination.total)"><i
                        class="icon icon-arrow-ios-right"></i></a>
                <msc-common-spinner *ngIf="teamMembers.loading"></msc-common-spinner>
            </div>
        </div>
    </div>
    <div class="team-member__filters navigation-filter">
        <div class="flex space-x-2">
            <msc-common-search [placeholder]="'placeholder.search.by-content' | translate" (searchEvent)="query=$event?.value;search()"></msc-common-search>

            <ng-container *ngIf="availableProviders">
                <msc-common-select class="msc-select--small hidden-xs" [data]="availableProviders" [isButton]="true" type="multi">
                    <ng-template #buttonTemplate let-data="data">
                        <span class="text-slate-500">{{ 'words.providers' | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <label class="flex py-2 w-full">
                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectProvider(datum)"></msc-common2-checkbox>
                            <span class="ml-2">{{ datum.label }}</span>
                        </label>
                    </ng-template>
                </msc-common-select>
            </ng-container>

            <ng-container *ngIf="availableTypes">
                <msc-common-select class="msc-select--small hidden-xs" [data]="availableTypes" [isButton]="true" type="multi">
                    <ng-template #buttonTemplate let-data="data">
                        <span class="text-slate-500">{{ 'words.types' | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <label class="flex py-2 w-full">
                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectType(datum)"></msc-common2-checkbox>
                            <span class="ml-2">{{ datum.label }}</span>
                        </label>
                    </ng-template>
                </msc-common-select>
            </ng-container>

            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>
        </div>
    </div>
    <div class="team-member__board">
        <div class="board__row">
            <div class="row__header">
                <h3>{{ 'group.team.not-started' | translate }}</h3>
                <msc-tag [text]="content[contentTypes.SUBSCRIBED].pagination?.total || '-'" [color]="'graylight'">
                </msc-tag>
            </div>
            <div class="row__container" infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="0" (scrolled)="loadMore(contentTypes.SUBSCRIBED)">
                <div class="row__item" *ngFor="let item of content[contentTypes.SUBSCRIBED].data">
                    <div class="item__header">
                        <div class="item__header__icon">
                            <i class="icon"
                                [ngClass]="{'icon-cube': item.type === 'course_instance' || (item.type === 'external_content' && item.data.provider), 'icon-trending-up': item.type === 'traject', 'icon-pin-2': item.type === 'external_content' && !item.data.provider }"></i>
                        </div>
                        <div class="item__header__details">
                            <p>{{ item.data.title }}</p>
                            <div>{{ item.data.internalName }}</div>
                        </div>
                        <div class="item__header__help">
                            <a *ngIf="item.data.isLocked"
                                [tooltip]="'words.content-locked' | translate" direction="left">
                                <i class="icon icon-lock"></i>
                            </a>
                            <msc-team-member-info-tooltip [item]="item.data"></msc-team-member-info-tooltip>
                        </div>
                    </div>
                    <div class="item__content">
                        <div class="item__content__icon">
                            <i *ngIf="item.data.state === contentStates.PENDING_VALIDATION"
                                class="icon icon-hourglass"></i>
                        </div>
                        <div class="item__content__description">
                            <p *ngIf="item.data.state === contentStates.PENDING_VALIDATION">{{'group.team.cards.pending' | translate}}
                                <span>{{ item.data.validationRequestedAt | timeAgo }}</span></p>
                            <p *ngIf="item.data.state === contentStates.REFUSED">{{'words.refused' | translate}}</p>
                            <p *ngIf="item.data.subscription && item.data.state !== contentStates.PENDING_VALIDATION && item.data.state !== contentStates.REFUSED">{{'group.team.cards.enrolled' | translate}}
                                <span>{{ item.data.subscription.sessionStartedAt || item.data.subscription.sessionCreatedAt | timeAgo }}</span></p>
                        </div>
                        <div class="item__content__actions">
                            <img *ngIf="item.type === 'external_content' && item.data.provider" [src]="getLogo(item)" width="70" class="logo-external"/>
                            <div *ngIf="item.data.state === contentStates.PENDING_VALIDATION">
                                <msc-button class="msc-button--outline" palette="danger" (click)="modalConfirmRefuse.onOpen(); refuseContent(item)">{{'words.refuse' | translate}}</msc-button>
                                <msc-button class="msc-button--primary" palette="primary" (click)="modalConfirmValidate.onOpen(); acceptContent(item)">{{'words.validate' | translate}}</msc-button>
                            </div>
                        </div>
                    </div>
                </div>
                <msc-common-spinner *ngIf="content[contentTypes.SUBSCRIBED].loading"></msc-common-spinner>
                <!-- <div class="row__item__separator"></div> -->
            </div>
            <msc-empty-state *ngIf="!content[contentTypes.SUBSCRIBED].loading && content[contentTypes.SUBSCRIBED].data?.length === 0" [title]="'empty.available.data'"></msc-empty-state>
        </div>
        <div class="board__row">
            <div class="row__header">
                <h3>{{ 'group.team.in-progress' | translate }}</h3>
                <msc-tag [text]="content[contentTypes.STARTED].pagination?.total || '-'" [color]="'graylight'">
                </msc-tag>
            </div>
            <div class="row__container" infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="0" (scrolled)="loadMore(contentTypes.STARTED)">
                <div class="row__item" *ngFor="let item of content[contentTypes.STARTED].data">
                    <div class="item__header">
                        <div class="item__header__icon">
                            <i class="icon"
                                [ngClass]="{'icon-cube': item.type === 'course_instance' || (item.type === 'external_content' && item.data.provider), 'icon-trending-up': item.type === 'traject', 'icon-pin-2': item.type === 'external_content' && !item.data.provider }"></i>
                        </div>
                        <div class="item__header__details">
                            <p>{{ item.data.title }}</p>
                            <div>{{ item.data.internalName }}</div>
                        </div>
                        <div class="item__header__help">
                            <a *ngIf="item.data.isLocked"
                            [tooltip]="'words.content-locked' | translate" direction="left">
                                <i class="icon icon-lock"></i>
                            </a>
                            <msc-team-member-info-tooltip [item]="item.data"></msc-team-member-info-tooltip>
                        </div>
                    </div>
                    <div class="item__content">
                        <div class="item__content__icon">

                        </div>
                        <div class="item__content__description">
                            <p>{{'words.last-activity' | translate}} <span>{{ item.data.subscription.lastActivity | timeAgo }}</span></p>
                        </div>
                        <div class="item__content__actions" *ngIf="item.type === 'external_content'  && item.data.provider">
                            <img [src]="getLogo()" width="70" class="logo-external"/>
                        </div>
                    </div>
                    <div class="item__progress">
                        <div class="item__progress__status"
                            [ngStyle]="{ 'width': item.data.subscription.progress + '%' }"></div>
                    </div>
                </div>
                <msc-common-spinner *ngIf="content[contentTypes.STARTED].loading"></msc-common-spinner>
            </div>
            <msc-empty-state *ngIf="!content[contentTypes.STARTED].loading && content[contentTypes.STARTED].data?.length === 0" [title]="'empty.available.data'"></msc-empty-state>
        </div>
        <div class="board__row">
            <div class="row__header gap-2">
                <h3>{{ 'manage.instance.status-done' | translate }}</h3>
                <msc-tag [text]="content[contentTypes.COMPLETED].pagination?.total || '-'" [color]="'graylight'">
                </msc-tag>
                <msc-common-select class="w-[240px] msc-select--small ml-auto" [data]="selectData">
                    <ng-template #buttonTemplate let-data="data">
                        <span class="truncate" *ngIf="data?.length">{{ 'filter.sorted-by' | translate }} : {{ data[0].label | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <button class="flex py-2 w-full" (click)="onSelectOption(datum)">
                            <span>{{ datum.label | translate}}</span>
                        </button>
                    </ng-template>
                </msc-common-select>
            </div>
            <div class="row__container" infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="0" (scrolled)="loadMore(contentTypes.COMPLETED)">
                <div class="row__item" *ngFor="let item of content[contentTypes.COMPLETED].data">
                    <div class="item__header">
                        <div class="item__header__icon">
                            <i class="icon"
                                [ngClass]="{'icon-cube': item.type === 'course_instance' || (item.type === 'external_content' && item.data.provider), 'icon-trending-up': item.type === 'traject', 'icon-pin-2': item.type === 'external_content' && !item.data.provider }"></i>
                        </div>
                        <div class="item__header__details">
                            <p>{{ item.data.title }}</p>
                            <div>{{ item.data.internalName }}</div>
                        </div>
                        <div class="item__header__help">
                            <a *ngIf="item.data.isLocked"
                            [tooltip]="'words.content-locked' | translate" direction="left">
                                <i class="icon icon-lock"></i>
                            </a>
                            <msc-team-member-info-tooltip position="left" [item]="item.data">
                            </msc-team-member-info-tooltip>
                        </div>
                    </div>
                    <div class="item__content">
                        <div class="item__content__progress">
                            <msc-score-progress
                                [score]="item.data.subscription?.score === null ? false : item.data.subscription?.score">
                            </msc-score-progress>
                        </div>
                        <div class="item__content__description">
                            <p *ngIf="item.data.subscription.sessionDoneAt">{{'group.team.cards.score' | translate}}
                                <span>{{ item.data.subscription.sessionDoneAt | date: 'dd MMMM'}}</span></p>
                            <p *ngIf="!item.data.subscription.sessionDoneAt && item.data.subscription.lastActivity">{{'words.last-activity' | translate}} <span>{{ item.data.subscription.lastActivity | date: 'dd MMMM'}}</span></p>
                        </div>
                        <div class="item__content__actions" *ngIf="item.type === 'external_content'  && item.data.provider">
                            <img [src]="getLogo(item)" width="70" class="logo-external"/>
                        </div>
                    </div>
                </div>
                <msc-common-spinner *ngIf="content[contentTypes.COMPLETED].loading"></msc-common-spinner>
            </div>
            <msc-empty-state *ngIf="!content[contentTypes.COMPLETED].loading && content[contentTypes.COMPLETED].data?.length === 0" [title]="'empty.available.data'"></msc-empty-state>
        </div>
    </div>
</div>

<msc-shared-aside-mail-user #mailUserAside [customHandler]="true"
    (onClose)="$event ? sendMail($event) : null">
</msc-shared-aside-mail-user>

<msc-manage-team-modal-subscribe-users *ngIf="displays.subscribe" [companyId]="company.id" [accountId]="user.data.id"
    (onClose)="displayed('subscribe')" (onSave)="subscribeMembersToContent($event)">
</msc-manage-team-modal-subscribe-users>

<msc-common-modal size="30vw" #modalConfirmValidate [title]="modalConfirmValidateTitle">
    <ng-template>
        <p>{{ modalConfirmValidateDescription }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalConfirmValidate.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="modalConfirmValidateOnSave && modalConfirmValidateOnSave(); modalConfirmValidate.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalConfirmRefuse [title]="'group.team.cards.refuse.title' | translate">
    <ng-template>
        <msc-textarea [label]="'group.team.cards.refuse.description' | translate: {user: user.data.name, content: refuseContentData.title}" [(ngModel)]="refuseContentReason" [placeholder]="'placeholder.description' | translate"></msc-textarea>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalConfirmRefuse.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="refuseContentConfirm(); modalConfirmRefuse.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
