import { Component } from '@angular/core';

@Component({
    selector: 'msc-organize-user',
    templateUrl: './user.component.html'
})
export class OrganizeUserComponent {

    constructor() {}
}
