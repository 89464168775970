<div class="content-configuration">
    <div class="content-configuration__header">
        <h3>{{ 'user.slow-invite.title' | translate }}</h3>

        <div class="form-filter row">
            <div class="form-filter-step">
                <ng-container *ngFor="let step of formSteps | keyvalue">
                    <div class="form-filter-step__item" *ngIf="step.value.enabled"
                        [ngClass]="{'form-filter-step__item--success': step.value.complete && step.key != FORMSTEPS.REVIEW, 'form-filter-step__item--current': currentForm == step.key}"
                        (click)="goTo(step.key, false, true)">
                        <span class="label">{{ step.value.label | translate }}</span>
                        <span class="anchor"></span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <p class="content-configuration__subheader"
        *ngIf="currentForm != FORMSTEPS.ONBOARDING_CONTENT && currentForm != FORMSTEPS.COACH">{{
        'user.slow-invite.description' | translate }}</p>

    <!-- Personal data -->
    <msc-organize-user-configure-personal-data #formStep [hidden]="currentForm != FORMSTEPS.PERSONAL_INFO" [availableManagers]="availableManagers">
    </msc-organize-user-configure-personal-data>

    <!-- Accesses -->
    <msc-organize-user-configure-accesses #formStep [hidden]="currentForm != FORMSTEPS.ACCESSES"
        *ngIf="formSteps[FORMSTEPS.PERSONAL_INFO].complete" [availableCamps]="availableCamps">
    </msc-organize-user-configure-accesses>

    <!-- I COACH -->
    <msc-organize-user-configure-coach #formStep [hidden]="currentForm != FORMSTEPS.COACH"
        [role]="steps[FORMSTEPS.ACCESSES].data.roles?.coach"
        (onSkip)="goTo(FORMSTEPS.ACCESSES, false, true); formSteps[FORMSTEPS.COACH].enabled = false"
        *ngIf="steps[FORMSTEPS.ACCESSES]?.data?.roles?.coach?.status"
        (onValidate)="goTo(formSteps[currentForm].next, true)">
    </msc-organize-user-configure-coach>

    <!-- Settings -->
    <msc-organize-user-configure-settings #formStep [hidden]="currentForm != FORMSTEPS.SETTINGS"
        *ngIf="formSteps[FORMSTEPS.ACCESSES].complete" [availableExperiences]="availableExperiences"
        [company]="company">
    </msc-organize-user-configure-settings>

    <!-- Onboarding -->
    <msc-organize-user-configure-onboarding #formStep [hidden]="currentForm != FORMSTEPS.ONBOARDING_CONTENT"
        *ngIf="formSteps[FORMSTEPS.ONBOARDING_CONTENT].enabled && steps[FORMSTEPS.ACCESSES] && steps[FORMSTEPS.ACCESSES].data.roles?.learn?.campsSelected.length"
        [selectedCamps]="steps[FORMSTEPS.ACCESSES].data.roles?.learn?.campsSelected || []"
        [label]="'onboarding.content.configure'" (onValidate)="goTo(formSteps[currentForm].next, true)">
    </msc-organize-user-configure-onboarding>

    <!-- Recapitulatif -->
    <msc-organize-user-configure-recap *ngIf="currentForm == FORMSTEPS.REVIEW" [data]="steps">
    </msc-organize-user-configure-recap>

    <div class="flex content-configuration__actions">
        <msc-button class="msc-button--primary" palette="secondary" (click)="goTo(formSteps[currentForm].next, false)"
            *ngIf="steps[currentForm] && !steps[currentForm]?.hasNavigation">
            {{ 'words.continue' | translate }}
        </msc-button>

        <ng-container *ngIf="currentForm == FORMSTEPS.REVIEW">
            <div class="flex space-x-2">
                <msc-button class="msc-button--primary" palette="secondary" (click)="invite()">{{ 'words.invite' |
                    translate }}</msc-button>

                <msc-common2-checkbox [value]="keepSettings" [label]="'user.slow-invite.keep-settings' | translate"
                (changeEvent)="keepSettings = !keepSettings"></msc-common2-checkbox>
            </div>
        </ng-container>
    </div>
</div>