import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Inject,
    Injector,
    Input,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export type MscPaletteTypes = 'slate' | 'primary' | 'secondary';

/**
 * Msc design button.
 */
@Component({
    selector: `msc-textarea`,
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CommonTextareaComponent),
            multi: true
        }
    ]
})
export class CommonTextareaComponent implements ControlValueAccessor {
    @Input() name: string;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() maxlength: number;
    @Input() cols = 10;
    @Input() rows = 3;
    @Input() icon: string;
    @Input() hints: Array<string>;
    @Input() hasErrors: boolean;
    @Input() hasSuccess: boolean;
    @Input() autosize = false;
    @Input() palette: MscPaletteTypes = 'secondary';

    @Input() disabled = false;
    touched = false;

    value: string;
    requiredValidator = Validators.required;

    onChange: any = () => { };
    onTouched: any = () => { };

    constructor(
        @Inject(Injector) private injector: Injector,
    ) { }

    get formField(): FormControl {
        try {
            return (this.injector.get(NgControl)).control as FormControl;
        } catch (e) {
            return null;
        }
    }

    writeValue(value: string): void {
        this.value = value;
        this.updateChanges();
    }

    updateChanges() {
        this.onChange(this.value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}