import { Component } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { StatisticsProvider2, UserProvider } from '@lighty';
import { EmitterService, StorageService, TranslateService } from '@services';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'msc-manage-instance-learner-stats-wrapper',
    templateUrl: './stats-wrapper.component.html',
    styleUrls: ['./stats-wrapper.component.scss']
})
export class ManageInstanceLearnerStatsWrapperComponent {
    learner;
    context;
    company;
    overviewStats;
    me;
    sections = [];

    constructor(private storageService: StorageService, private router: Router, private activatedRoute: ActivatedRoute, private userProvider: UserProvider, private statisticsProvider2: StatisticsProvider2, private breadcrumbService: BreadcrumbService, private translateService: TranslateService) {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');

        this.activatedRoute.params.subscribe(params => {
            this.userProvider.getAccount(this.company.id, +params['learnerId']).subscribe((user) => {
                this.learner = user;
            })

            this.statisticsProvider2
                .getContentSubscriptionList(this.company.id, params['entityId'], { context: params['entity'], account_ids: +params['learnerId'] }).subscribe(data => {
                    this.overviewStats = data[params['learnerId']]?.context;
                })

            this.breadcrumbService.set('@ContentDisplay', this.translateService.instant(`filter.content-type.${params['entity']}`));
        });
    }

    navigateToRoute(route) {
        this.router.navigate([], { relativeTo: route });
    }

    conversation(): void {
        this.storageService.set('conversationUsers', [this.learner]);
        this.router.navigate(['conversation/create']);
    }
}
