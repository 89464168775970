<div class="container-integration__body col-xs-12 col-md-9 col-9 no-gutter">
    <div class="content-integration content-integration--rules box">
        <div class="content-integration__header">
            <h3 class="content-integration__title">
                {{ 'organize.integration.publication-rules' | translate }}
            </h3>
        </div>

        <div class="content-integration__table">
            <div class="dynamic-table">
                <div class="dynamic-table__header hidden-xs">
                    <div class="dynamic-table__row">
                        <div class="dynamic-table__content">
                            <div class="dynamic-table__cell cell-important cell-medium">
                                {{ 'organize.integration.rule-name' | translate }}
                            </div>

                            <div class="dynamic-table__cell cell-small">
                                {{ 'words.contents' | translate }}
                            </div>

                            <div class="dynamic-table__cell cell-small">
                                {{ 'words.selection' | translate }}
                            </div>

                            <div class="dynamic-table__cell cell-small">
                                {{ 'words.access' | translate }}
                            </div>

                            <div class="dynamic-table__cell cell-actions cell-medium"></div>
                        </div>
                    </div>
                </div>

                <div class="dynamic-table__body">
                    <div class="dynamic-table__row row-radius" *ngFor="let rule of rules" [ngClass]="{'dynamic-table__row--disable': currentProvider.companyProvider.deploymentRunning}">
                        <div class="dynamic-table__content">
                            <div class="dynamic-table__cell dynamic-table__cell--main cell-medium cell-important">
                                <span class="container-integration__rule-title">{{ rule.name }}</span>
                            </div>

                            <div class="dynamic-table__cell cell-small">{{ rule.contentsCount }}</div>

                            <div class="dynamic-table__cell cell-small">{{ 'integrations.rules.import-type.' + rule.selectionType | translate }}</div>

                            <div class="dynamic-table__cell cell-small cell-camp">
                                <span>
                                    <i class="icon-camp text-primary-500" *ngIf="rule.publicationType === 'camps'" [tooltip]="rule.displayedCampsNames ? rule.displayedCampsNames.join(', ') : company.name"></i>
                                    <i class="icon icon-company-building text-primary-500" *ngIf="rule.publicationType === 'all'" [tooltip]="company.experience.name"></i>
                                </span>

                                <span *ngIf="rule.publicationType === 'camps'">
                                    {{ rule.campsCount }}
                                </span>
                            </div>

                            <div class="dynamic-table__cell cell-actions cell-medium">
                                <a class="cell-actions__edit" [tooltip]="'words.edit' | translate" [routerLink]="'edit/' + rule.id">
                                    <i class="icon icon-edit"></i>
                                </a>

                                <a class="cell-actions__delete" [tooltip]="'words.delete' | translate" (click)="modalDeleteRule.onOpen(); openDeleteModal(rule)">
                                    <i class="icon icon-trash-2"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>

                    <div class="dynamic-table__row dynamic-table__row--add row-radius" *ngIf="!currentProvider.companyProvider.deploymentRunning" [routerLink]="'create'">
                        <div class="dynamic-table__content">
                            <div class="dynamic-table__cell cell-important">
                                <i class="icon icon-plus-circle text-secondary-500"></i>

                                <span>{{ 'organize.integration.add-rule' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalDeleteRule [title]="'integrations.rules.delete-title' | translate">
    <ng-template>
        <p>{{ 'integrations.rules.delete-description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDeleteRule.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteRule(); modalDeleteRule.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
