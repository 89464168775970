<div class="team-settings col-8">
    <div class="setting flex">
        <msc-input class="w-3/4" #nameInput autocomplete="off" [label]="'group.team.title' | translate" [disabled]="!editMode"
            name="input" [placeholder]="'words.name' | translate" [(ngModel)]="team.name"></msc-input>
        <span class="self-end mb-2 ml-2 text-2xl cursor-pointer">
            <i class="icon icon-edit" *ngIf="!editMode" (click)="enableEdit()"></i>
            <i class="icon icon-checkmark" *ngIf="editMode && team.name" (click)="editMode = false; confirmSave()"></i>
            <i class="icon icon-close" *ngIf="editMode && !team.name"></i>
        </span>
    </div>

    <div class="separator"></div>

    <div class="setting flex">
        <div class="setting__icon">
            <i class="icon icon-message-circle"></i>
        </div>
        <div class="setting__label">
            <p>{{ 'words.conversations' | translate }}</p>
            <span>{{ 'group.team.discussion.label' | translate }}</span>
        </div>
        <div class="setting__action">
            <msc-common2-switch [value]="team.timelineVisibility" (changeEvent)="team.timelineVisibility = !team.timelineVisibility; save()"></msc-common2-switch>
        </div>
    </div>

    <div class="separator"></div>


    <div class="setting input-upload-media media__avatar">
        <div class="input-upload-media__label">{{'group.upload.upload-profil' | translate}}</div>
        <div class="input-upload-media__desc">{{'group.upload.upload-profil.description' | translate}}</div>
        <div class="input-upload-media__container">
            <div class="input-upload-media__image"
                style="background-image: url('https://static.myskillcamp.com/lxp/images/catalog-item-2.png')"
                *ngIf="!profileMedia && !loadProfile"></div>
            <div class="input-upload-media__image" [style.background-image]="'url(' + profileMedia + ')'"
                *ngIf="profileMedia && !loadProfile">
                <div class="input-image-loader">
                    <msc-common-spinner *ngIf="loadProfile"></msc-common-spinner>
                </div>
            </div>

            <input #fileUploadProfile class="display-none" type="file" accept="image/png,image/jpeg,image/jpg,image/gif"
                fileUpload [fileUrl]="'media'" (fileStatus)="updateProfileMedia($event)">
            <div class="input-upload-media__buttons" (click)="fileUploadProfile.click()"><i class="icon icon-image"></i>
            </div>
        </div>
    </div>

    <div class="separator"></div>

    <div class="setting input-upload-media">
        <div class="input-upload-media__label">{{'group.upload.upload-cover' | translate}}</div>
        <div class="input-upload-media__desc">{{'group.upload.upload-cover.description' | translate}}</div>
        <div class="input-upload-media__container">
            <div class="input-upload-media__image"
                style="background-image: url('https://static.myskillcamp.com/lxp/images/catalog-item-2.png')"
                *ngIf="!backgroundMedia && !loadProfile"></div>
            <div class="input-upload-media__image" [style.background-image]="'url(' + backgroundMedia + ')'"
                *ngIf="backgroundMedia && !loadProfile">
                <div class="input-image-loader">
                    <msc-common-spinner *ngIf="loadBackground"></msc-common-spinner>
                </div>
            </div>

            <input #fileUploadBackground class="display-none" type="file"
                accept="image/png,image/jpeg,image/jpg,image/gif" fileUpload [fileUrl]="'media'"
                (fileStatus)="updateBackgroundMedia($event)">
            <div class="input-upload-media__buttons" (click)="fileUploadBackground.click()"><i
                    class="icon icon-image"></i></div>
        </div>
    </div>
</div>


<msc-common-modal size="30vw" #modalConfirm [title]="'group.team.discussion.modal.title' | translate">
    <ng-template>
        <p>{{ 'group.team.discussion.modal.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="team.timelineVisibility = true; modalConfirm.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="confirmSave(); modalConfirm.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
