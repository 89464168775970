import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReportingProvider, Camp, Company, StatsProvider } from '@lighty';
import { ErrorStreamService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-stats-adoption',
    templateUrl: './stats-adoption.component.html',
    styleUrls: ['./stats-adoption.component.scss']
})
export class CampStatsAdoptionComponent implements OnInit {
    @ViewChild('modalConfirm') modalConfirm: CommonModalComponent;
    private company: Company;
    public camp: Camp;
    public adoption: any;
    public adoptionPercentage: string;
    public loaders: any;
    public options: any;
    public dates: any;
    public displays: any;
    public today: any;

    constructor(
        private reportingProvider: ReportingProvider,
        private storageService: StorageService,
        private translateService: TranslateService,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private router: Router,
        private externalAppsService: ExternalAppsService,
        private statsProvider: StatsProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.camp = this.storageService.get('camp');

        this.today = moment();

        this.dates = {
            from: moment(this.company.createdAt).format('YYYY-MM-DD'),
            upto: moment().format('YYYY-MM-DD')
        };

        this.displays = {
            date: false,
            export: false
        };

        this.loaders = {
            loading: true,
            download: false
        };

        const params = {
            filters: {
                date: {
                    from: this.dates.from,
                    to: this.dates.upto
                },
                camp: [this.camp.id]
            }
        };

        this.reportingProvider.adoption(this.company.id, params).subscribe((data) => {
            this.adoption = data;
            this.adoptionPercentage = this.adoption.licenceAssigned > 0 ? (this.adoption.learnerContentDone * 100 / this.adoption.licenceAssigned).toFixed(2) : '0';
            this.initOptions();
            this.loaders.loading = false;
        });
    }

    private initOptions(): void {
        this.options = {
            total: this.adoption.licenceAssigned,
            data: [
                // {value: this.adoption.licenceAvailable, label: this.translateService.instant('funnel.adoption.available-licenses')},
                { value: this.adoption.licenceAssigned, label: this.translateService.instant('funnel.adoption.assigned-licenses') },
                { value: this.adoption.licenceActivated, label: this.translateService.instant('funnel.adoption.activated-licenses') },
                { value: this.adoption.learnerContentStarted, label: this.translateService.instant('funnel.adoption.started') },
                { value: this.adoption.learnerContentDone, label: this.translateService.instant('funnel.adoption.finished') }
            ],
            other: [
                // {label: this.translateService.instant('funnel.adoption.other.not-assigned'), cta: this.translateService.instant('words.invite'), url: `organize/${this.company.slug}/camp/${this.camp.id}/member?actions=invitation`},
                { label: this.translateService.instant('funnel.adoption.other.not-activated'), cta: this.translateService.instant('words.resend'), url: `organize/${this.company.slug}/users?actions=massive_invitation` },
                { label: this.translateService.instant('funnel.adoption.other.not-started') },
                { label: this.translateService.instant('funnel.adoption.other.not-completed') }
            ],
            line: {
                width: 12,
                color: '#ED8371'
            },
            grid: {
                width: 1,
                color: '#D3D5E4',
                opacity: 0.3
            },
            fill: {
                color: '#FFF1E8'
            },
            label: {
                font: '600 28px Circular Std Book',
                color: '#535A8B',
                opacity: 0.7
            },
            value: {
                font: '800 42px Circular Std Book',
                color: '#ED8371',
            },
            percentage: {
                text: this.translateService.instant('funnel.adoption.total-users'),
                font: '400 24px Circular Std Book',
                color: '#535A8B',
                opacity: 0.4
            }
        };
    }

    search(): void {
        this.loaders.loading = true;
        const params = {
            filters: HttpHelper.cleanParams({
                date: {
                    from: this.dates.from,
                    to: this.dates.upto
                },
                camp_id: this.camp.id
            })
        };

        this.reportingProvider.adoption(this.company.id, params).subscribe((data) => {
            this.adoption = data;
            this.adoptionPercentage = this.adoption.licenceAssigned > 0 ? (this.adoption.learnerContentDone * 100 / this.adoption.licenceAssigned).toFixed(2) : '0';
            this.initOptions();
            this.loaders.loading = false;
        });
    }

    download(options: any): void {
        this.loaders.download = true;
        this.errorStreamService.locked();
        this.displayed('export');

        const params = {
            filters: HttpHelper.cleanParams({
                date: {
                    from: this.dates.from,
                    to: this.dates.upto
                },
                camp_id: this.camp.id
            }),
            ...options
        };

        this.statsProvider.getAdoptionExport(this.company.id, params).subscribe((data) => {
            this.modalConfirm.onOpen();

            this.loaders.download = false;
            this.errorStreamService.unlocked();
        }, () => {
            this.loaders.download = false;
            this.errorStreamService.unlocked();
        });
    }

    displayed(type: string, force?: boolean): void {
        this.displays[type] = force ? false : !this.displays[type];
    }

    updateDate(date: string): void {
        this.dates.upto = moment(date).format('YYYY-MM-DD');
        this.displayed('date');
        this.search();
    }

    sum(firstValue: number, secondValue: number): number {
        return firstValue - secondValue;
    }

    dropOff(firstValue: number, secondValue: number): string {
        const sum = this.sum(firstValue, secondValue);
        return ((sum / this.options.total) * 100).toPrecision(3) + '%';
    }

    navigate(url: string): void {
        this.router.navigateByUrl(url);
    }
}
