<div class="box">
    <div class="list-correction__type text-secondary-600">
        {{ 'words.question' | translate }}
    </div>

    <div class="list-correction__desc">
        {{ session.project.title }}
    </div>

    <div class="list-correction__type text-secondary-600">
        {{ 'correction.learner-answer' | translate }}
    </div>

    <div class="list-correction__desc" *ngIf="session.project.type === 'cheatsheet'" [innerHTML]="session.content | safe: 'html'"></div>

    <msc-media-manager #mediaManagerLearner [medias]="answerMedias" [options]="{ 'canUploadMedia': false, 'canDownloadMedia': true, 'canDeleteMedia': false }" class="list-correction__medias"  *ngIf="session.project.type === 'document'"></msc-media-manager>

    <div class="list-correction__feedback">
        <msc-textarea [label]="'words.correction' | translate" rows="5" cols="33" [(ngModel)]="session.feedback"></msc-textarea>
    </div>

    <msc-media-manager #mediaManagerTrainer [medias]="session.correctionMedia" [options]="{ 'canUploadMedia': true, 'canDownloadMedia': true, 'canDeleteMedia': true }" (newMediaEvent)="addMedia($event)" (deleteMediaEvent)="deleteMedia($event)"></msc-media-manager>
    
    <div class="list-correction__note text-secondary-600">
        {{ 'words.rating' | translate }}
        <div class="note-label">{{ 'correction.rating.desc' | translate }}</div>
        <div class="note-input">
            <input type="number" class="input-number" min="0" max="100" [(ngModel)]="session.ratio" (ngModelChange)="spyProject()">
        </div>
    </div>

    <msc-button class="msc-button--primary" palette="secondary" (click)="update()" [disabled]="loading || mediaManagerTrainer.loading || !session.ratio || session.ratio > 100 || session.ratio < 0">
        {{ 'words.save' | translate }}
    </msc-button>

    <div class="list-correction__timeline">
        <msc-timeline #correctionTimeline [context]="'project-session'" [contextId]="session.id"></msc-timeline>
    </div>
</div>