import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'msc-showroom-exhibit-input-showroom',
    templateUrl: './showroom-exhibit-input-page.component.html',
    styleUrls: ['./showroom-exhibit-input-page.component.scss'],
})

export class ShowroomExhibitInputPageComponent {
    public testForm: FormGroup;
    public hints = [
        "Enter your favorite hobbies",
        "Don't lie :P"
    ]
    constructor() { }

    ngOnInit(): void {
        this.testForm =
            new FormGroup({
                errorinput: new FormControl(
                    '',
                    {
                        validators: [
                            Validators.required,
                        ],
                    }
                ),
            });
        this.testForm.get('errorinput').markAsDirty();
    }
}
