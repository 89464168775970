import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import * as _ from 'lodash';

@Component({
    selector: 'msc-filters-modal',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
    @Input() filters: any[];
    @Output() saveEvent: EventEmitter<any> = new EventEmitter();
    //
    @ViewChild('modalFilters') modalFilters: CommonModalComponent;
    //
    private rawData: any;
    public hasFilters;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filters'].currentValue?.length) {
            this.rawData = _.cloneDeep(changes['filters'].currentValue);
        }
        this.hasFilters = this.filters?.filter(filter => !filter.options || filter.options.length).length > 0;
    }

    /**
     * 
     */
    getSelectedFilters(filter) {
        switch (filter.type) {
            case 'select': return filter.options.find(option => option.isSelected)?.value;
            case 'multiselect': return filter.options.filter(option => option.isSelected).map(option => option.value);
            case 'checkbox': return filter.options.filter(option => option.isChecked).map(option => option.value);
            case 'radio': return filter.value;
            case 'range': return filter.value;
            case 'skill': return filter.selected.skill && (({ skill, level }) => ({ id: skill.id, level }))(filter.selected);
        }
    }

    /**
     * 
     */
    onOpen(): void {
        this.modalFilters.onOpen();
    }

    /**
     * 
     */
    onMultiselectOption(option, idx): void {
        this.filters[idx].options = this.filters[idx].options.map((d) => {
            if (d.id === option.id) {
                d.isSelected = !d.isSelected;
            }
            return d;
        });

        this.filters[idx].displayValue = this.filters[idx].options.filter(opt => opt.isSelected).map(opt => opt.label).join(', ');
    }

    /**
     * 
     */
    onSelectOption(option, idx): void {
        this.filters[idx].options = this.filters[idx].options.map((d) => {
            d.isSelected = d.id === option.id;
            return d;
        });
    }

    /**
     * 
     */
    onChange(value, item): void {
        item.isChecked = value;
    }

    /**
     * 
     */
    onUpdateRange(value: any, filter): void {
        filter.value = value;
    }

    /**
     * 
     */
    onSearchSkill(value, filter): void {
        filter.query = value;
    }

    /**
     * 
     */
    onLoadMoreSkills(filter): void {
        if (filter.displayLimit < filter.options.length) {
            filter.displayLimit += 4;
        }
    }

    /**
     * 
     */
    onSelectSkill(selected, skill = null): void {
        selected.skill = skill;
        selected.level = [];
    }

    /**
     * 
     */
    onSelectLevel(selected, level: number): void {
        const idx = selected.level.findIndex(l => l === level);
        if (idx > -1) {
            selected.level.splice(idx, 1);
            return;
        }

        selected.level.push(level);
    }

    /**
     * 
     */
    onReset(): void {
        this.filters = _.cloneDeep(this.rawData);
    }

    /**
     * 
     */
    onSave(): void {
        const selectedFilters = this.filters.reduce((acc, key) => ({ ...acc, [key.name]: this.getSelectedFilters(key) }), {});
        this.saveEvent.emit(selectedFilters);
    }
}
