import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyExperienceResource } from './company-resource';
import { environment } from '@env/environment';

@Injectable()
export class CompanyExperienceResourceProvider {

    constructor(private http: HttpClient) { }

    getResources(slug: string, experienceId: number, params?: any): Observable<any> {
        if (typeof experienceId !== 'number') { return; }
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/experience/${experienceId}/resources`, { params }).pipe(
            map((data: any) => {
                return data.resources.map((resource) => {
                    return new CompanyExperienceResource(resource);
                });
            })
        );
    }

    create(slug: string, experienceId: number, params: any): Observable<CompanyExperienceResource> {
        return this.http.post(`${environment.envVar.API_URL}/company/${slug}/experience/${experienceId}/resources`, params).pipe(
            map(data => new CompanyExperienceResource(data))
        );
    }

    delete(slug: string, experienceId: number, resourceId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/company/${slug}/experience/${experienceId}/resources/${resourceId}`).pipe(
            map(data => data)
        );
    }
}
