<div class="flex flex-col">
    <div class="slide-external-link__body scrollable grow" *ngIf="ready">
        <div class="slide-external-link__form">
            <div class="slide-external-link__camp-block" *ngFor="let community of communities">
                <p class="slide-external-link__camp-block__name">{{ community.title }}</p>
                <msc-common2-checkbox [value]="isChecked(community)" [label]="community.id" (changeEvent)="onChange($event)"></msc-common2-checkbox>
            </div>
    
            <msc-load-more [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
        </div>
    
        <msc-empty-state *ngIf="communities?.length === 0"
                         [title]="'words.communities'"
                         [description]="'empty.communities'">
        </msc-empty-state>
    </div>
    
    <div class="flex justify-end">
        <msc-button class="msc-button--primary" palette="secondary" (click)="nextPanel()" [disabled]="extensionLink.communityList.length <= 0">{{ 'words.next' | translate }}</msc-button>
    </div>    
</div>