import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
    UserProvider, CompanyProvider, ConversationProvider, CompanyUserAccessProvider,
    CompanyUserAccessManageProvider, IntegrationProvider, ApplicationProvider, CompanyExperienceProvider
} from '@lighty';
import { StorageService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ThemingService } from '@common2/services/theming.service';
import { environment } from '@env/environment';

@Injectable()
export class CompanyGuardCheck implements CanActivate {
    constructor(private companyProvider: CompanyProvider, private cookieService: CookieService, private storageService: StorageService, private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot): Observable<any> {
        const slug = this.cookieService.get('current_company') || route.paramMap.get('slug');
        if (slug) {
            return this.companyProvider.getCompany(slug, { includes: 'experience' }).pipe(map(data => {
                this.storageService.set('company', data);
                return data;
            }));
        }

        this.router.navigate(['login', 'company']);
    }
}
@Injectable()
export class DisplayResolver implements Resolve<any> {
    constructor(private companyExperienceProvider: CompanyExperienceProvider, private titleService: Title, private themingService: ThemingService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        let storedTheme = JSON.parse(localStorage.getItem('theme'));
        if (route.queryParams.company) {
            return this.companyExperienceProvider.display(route.queryParams).pipe(map((data) => {
                this.titleService.setTitle(data.name ?? 'Myskillcamp');
                this.themingService.apply({ ...(data?.colorTemplate?.primaryColor && { primary: data.colorTemplate.primaryColor }), ...(data?.colorTemplate?.secondaryColor && { secondary: data.colorTemplate.secondaryColor }) });
                if(data && Object.keys(data).length != 0) {
                    localStorage.setItem('theme', JSON.stringify(data));
                }
                return data;
            }),catchError(() => {
                return of([]);
            }));
        } else if (location.origin !== environment.envVar.APP_URL) {
            // Remove MSC Title on load
            this.titleService.setTitle('');
            const params = {
                host: location.origin
            };
            return this.companyExperienceProvider.resolveExperienceFromUrl(params).pipe(map((data) => {
                this.titleService.setTitle(data.name ?? 'Myskillcamp');
                this.themingService.apply({ ...(data?.colorTemplate?.primaryColor && { primary: data.colorTemplate.primaryColor }), ...(data?.colorTemplate?.secondaryColor && { secondary: data.colorTemplate.secondaryColor }) });
                if(data && Object.keys(data).length != 0) {
                    localStorage.setItem('theme', JSON.stringify(data));
                }
                return data;
            }), catchError(() => {
                this.titleService.setTitle('Myskillcamp');
                return of([]);
            }))
        }
        return of([]);
    }
}

@Injectable()
export class CompanyResolver implements Resolve<any> {
    constructor(private companyProvider: CompanyProvider, private cookieService: CookieService, private themingService: ThemingService, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('slug')) {
            return this.companyProvider.getCompany(route.paramMap.get('slug'), { includes: 'experience' }).pipe(map((data) => {
                this.storageService.set('company', data);
                this.themingService.apply({ ...(data.experience?.colorTemplate?.primaryColor && { primary: data.experience.colorTemplate.primaryColor }), ...(data.experience?.colorTemplate?.secondaryColor && { secondary: data.experience.colorTemplate.secondaryColor }) });
                return data;
            }));
        } else {
            return this.companyProvider.getCompany(this.storageService.get('company')?.slug || this.cookieService.get('current_company'), { includes: 'experience' }).pipe(map(data => {
                this.storageService.set('company', data);
                return data;
            }));
        }
    }
}

@Injectable()
export class CompanyRoleResolver implements Resolve<any> {
    constructor(private companyProvider: CompanyProvider, private cookieService: CookieService, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('slug')) {
            return this.companyProvider.getRoles(route.paramMap.get('slug'));
        } else {
            return this.companyProvider.getRoles(this.storageService.get('company')?.slug || this.cookieService.get('current_company'));
        }
    }
}

@Injectable()
export class SidebarResolver implements Resolve<any> {
    constructor(private companyProvider: CompanyProvider, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('slug')) {
            return this.companyProvider.getSidebar(route.paramMap.get('slug'));
        } else {
            return this.companyProvider.getSidebar(this.storageService.get('company').slug);
        }
    }
}

@Injectable()
export class ApplicationResolver implements Resolve<any> {
    constructor(private applicationProvider: ApplicationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('slug')) {
            return this.applicationProvider.get(route.paramMap.get('slug'));
        }
    }
}

@Injectable()
export class IntegrationResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.data['type'] === 'perso') {
            return this.integrationProvider.get();
        } else {
            return this.integrationProvider.get(route.parent.parent.paramMap.get('slug'));
        }
    }
}

@Injectable()
export class ConversationResolver implements Resolve<any> {
    constructor(private conversationProvider: ConversationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('conversationSlug')) {
            if (route.paramMap.get('conversationSlug') !== 'create') {
                return this.conversationProvider.get(route.paramMap.get('conversationSlug'));
            }
        } else {
            return this.conversationProvider.getAll();
        }
    }
}

@Injectable()
export class UserResolver implements Resolve<any> {
    constructor(private userProvider: UserProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.userProvider.me();
    }
}

@Injectable()
export class UserCompaniesResolver implements Resolve<any> {
    constructor(private userProvider: UserProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.userProvider.getCompanies({ includes: 'roles,experience' });
    }
}

@Injectable()
export class UserProfileResolver implements Resolve<any> {
    constructor(private userProvider: UserProvider, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('accountId')) {
            return this.userProvider.getAccount(this.storageService.get('company').id, parseInt(route.paramMap.get('accountId'), 10));
        } else {
            return this.userProvider.getAccount(route.parent.data.company.id);
        }
    }
}

@Injectable()
export class CompanyUserAccessResolver implements Resolve<any> {
    constructor(private companyUserAccessProvider: CompanyUserAccessProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const type = route.data['type'];
        if (route.parent.parent.paramMap.get('accountId')) {
            if (type === 'learn') {
                return this.companyUserAccessProvider.getLearn(route.parent.parent.data.company.id, { account_id: route.parent.parent.paramMap.get('accountId') });
            }

            if (type === 'organize') {
                return this.companyUserAccessProvider.getOrganize(route.parent.parent.data.company.id, { account_id: route.parent.parent.paramMap.get('accountId') });
            }

            if (type === 'create') {
                return this.companyUserAccessProvider.getCreate(route.parent.parent.data.company.id, { account_id: route.parent.parent.paramMap.get('accountId') });
            }
        }
    }
}

@Injectable()
export class CompanyUserAccessManageResolver implements Resolve<any> {
    constructor(private companyUserAccessManageProvider: CompanyUserAccessManageProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.parent.paramMap.get('accountId')) {
            const params = {
                role: 'manager'
            };

            return this.companyUserAccessManageProvider.get(parseInt(route.parent.parent.parent.data.company.id, 10), parseInt(route.parent.parent.parent.paramMap.get('accountId'), 10), params);
        }
    }
}

@Injectable()
export class ProvidersResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider, private storageService: StorageService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.integrationProvider.getConnectedProviders(this.storageService.get('company').id);
    }
}

@Injectable()
export class ProviderResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('providerId')) {
            return this.integrationProvider.getProvider(parseInt(route.paramMap.get('providerId'), 10));
        }
    }
}
