import { Component } from '@angular/core';
import { Company, ContentProvider, TemplateProvider } from '@lighty';
import { ActivatedRoute, Router } from '@angular/router';
import { DataHelper, DownloadHelper, HttpHelper } from '@helpers';
import { StorageService, EmitterService, RedirectService, TranslateService } from '@services';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-learn-detail-instance-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})

export class LearnDetailTemplatePresentationComponent {
    public template: any;
    public company: Company;
    public sessionsDropdown: boolean[] = [];
    public message: string = '';
    public display: boolean = true;
    public noSession: boolean = false;
    public loader: any = {
        registration: false
    };
    changeLearnLaterContent: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private templateProvider: TemplateProvider,
        private contentProvider: ContentProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private redirectService: RedirectService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.template = this.getTemplateRemapped(this.route.parent.snapshot.data.template);

        if (this.template.courseCamps) {
            if (this.template.courseCamps.length === 0) {
                this.noSession = true;
            }

            if (this.template.courseCamps.length > 0) {
                this.fillData();
            }
        }

        this.changeLearnLaterContent = this.emitterService.get('changeLearnLaterContent').pipe(skip(1)).subscribe((saved) => {
            if (saved) {
                this.deleteSavedContent();
            } else {
                this.addToSavedContents();
            }
        })
    }

    getTemplateRemapped(datum: any) {
        const mediaUrl = this.getMediaUrl(datum);
        return { mediaUrl, ...datum };
    }

    getMediaUrl(datum: any) {
        switch (true) {
            case datum.hasOwnProperty('provider') && !!datum.provider.media: return `url(${datum.provider.media.pictureUrl}?size=256)`;
            case datum.hasOwnProperty('provider') && !datum.provider.media: return `url('https://static.myskillcamp.com/images/integrations/${datum.provider.key}.svg')`;
            case datum.hasOwnProperty('picture') && !!datum.picture: return `url(${datum.picture}?size=256)`;
            default: return `url('https://static.myskillcamp.com/lxp/images/logo-empty-state-${datum.id % 7}.jpg')`;
        }
    }

    fillData(): void {
        this.template.courseCamps.forEach((item) => {
            if (item.isLocked) {
                item.state_locked = true;
            }
            if (item.usedSeats >= item.limit && item.limit > 0 &&
                !item.session) {
                // No places left
                item.state_card = 'full';
            } else if (item.session && item.session.validationStatus === 1) {
                if (item.isLocked) {
                    item.state_card = 'locked';
                } else if (item.session.length > 0 &&
                    item.session.progress === 100) {
                    // Course finished
                    item.state_card = 'finish';
                } else {
                    // Course available to start
                    item.state_card = 'start';
                }
            } else if (item.needValidation && (!item.session ||
                item.session.validationStatus !== 1)) {
                if (!item.session && item.hasPrevious) {
                    item.state_card = 'subscribe-again';
                } else if (!item.session && !item.hasPrevious) {
                    // Can subscribe
                    item.state_card = 'subscribe';
                } else if (item.session.validationStatus === null) {
                    // Waiting for validation
                    item.state_card = 'pending';
                } else if (item.session.validationStatus === 0) {
                    // Has already subscribe
                    item.state_card = 'subscribe-again';
                }
            } else {
                if (item.isLocked) {
                    item.state_card = 'locked';
                } else {
                    // No need validation
                    item.state_card = 'start-course';
                }
            }
            item.state_button = item.state_card;
        });
    }

    subscribeToContent(content: any, player?: boolean): void {
        const params = HttpHelper.cleanParams({
            course_camp_id: content.id
        });

        this.loader.registration = true;
        this.templateProvider.subscribe(params).subscribe(() => {
            this.loader.registration = false;
            this.toastService.onSuccess(this.translateService.instant('toast.demand-sent'));

            if (player) {
                this.goToPlayer(content);
                content.state_button = 'start';
                content.session = true;
            } else {
                const paramsTemplate = HttpHelper.cleanParams({
                    include: 'course_camps,course_camps.courseCampSessions,course_camps.course.events,course_camps.course.events.event_sessions,course_camps.seats.users',
                    company_id: this.company.slug
                });

                this.templateProvider.get(this.template.id, paramsTemplate).subscribe((template) => {
                    this.template = template;
                    this.fillData();
                });
            }
        }, (failure) => {
            if (failure.status === 403) {
                this.toastService.onSuccess(this.translateService.instant(`toast.${failure.error.translationKey}`));
            }
        });
    }

    openSession(index): void {
        this.sessionsDropdown[index] = !this.sessionsDropdown[index];
    }

    checkEvents(): boolean {
        let hasEvent = false;

        if (this.template.courseCamps) {
            this.template.courseCamps.forEach((courseCamp) => {
                if (courseCamp.events && courseCamp.events.length > 0) {
                    hasEvent = true;
                }
            });
        }

        return hasEvent;
    }

    downloadIcs(ics: string, subscribed: boolean): void {
        if (subscribed) {
            DownloadHelper.download(ics, '', false);
        }
    }

    goToPlayer(content: any): void {
        if (!DataHelper.isEmpty(content.playerLight) && content.playerLight.context === 'internal') {
            const more = {
                id: content.courseId
            };
            this.emitterService.set('player-light.open', { type: content.playerLight.type, content: content.playerLight, more, progressEnabled: content.provider ? content.provider.progressEnabled : true });
        } else {
            this.loader.registration = true;
            this.redirectService.navigate('player', ['course', content.courseId]);
        }
    }

    enableOnDemand(): void {
        const params = HttpHelper.cleanParams({
            company_id: this.company.id,
            message: this.message
        });

        this.templateProvider.requestOnDemand(this.template.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.demand-sent'));
        });
    }

    addToSavedContents(): void {
        this.template.saved = true;

        const params = {
            context: 'course_template',
            context_id: this.template.id
        };

        this.contentProvider.addSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.saved'));
        });
    }

    deleteSavedContent(): void {
        this.template.saved = false;

        const params = {
            context: 'course_template',
            context_id: this.template.id
        };

        this.contentProvider.deleteSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.deleted'));
        });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    checkSubscriptionDeadline(date: any): boolean {
        if (date) {
            const subscriptionDeadline = new Date(date);
            const now = new Date();
            return subscriptionDeadline.getTime() < now.getTime();
        }

        return false;
    }

    ngOnDestroy(): void {
        this.changeLearnLaterContent.unsubscribe();
    }
}
