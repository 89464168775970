import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class CompanyUserAccessManageProvider {

    constructor(private http: HttpClient) { }

    get(id: number, accountId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/users/${accountId}/instances`, { params }).pipe(
            map(data => data)
        );
    }

    getAvailable(id: number, accountId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/users/${accountId}/available-instances`, { params }).pipe(
            map(data => data)
        );
    }

    getAvailableForCoach(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/contents/available`, {params}).pipe(
            map(data => data)
        );
    }

    getAvailableForCoachMeta(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/contents/available/meta`, {params}).pipe(
            map((data: any) => data.meta)
        );
    }

    addInstance(id: number, accountId: number, instanceId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/users/${accountId}/instances/${instanceId}/manager-access`, params).pipe(
            map(data => data)
        );
    }

    deleteInstance(id: number, accountId: number, instanceId: number, params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${id}/users/${accountId}/instances/${instanceId}/manager-access`, { params }).pipe(
            map(data => data)
        );
    }

    create(id: number, params: any, type: string): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/company/${id}/permissions/manager/${type}`, params).pipe(
            map(data => data)
        );
    }

    delete(id: number, params: any, type: string): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/company/${id}/permissions/manager/${type}`, { params }).pipe(
            map(data => data)
        );
    }

    getInstances(id: number, templateId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/permissions/manager/courses/${templateId}/instances`, { params }).pipe(
            map(data => data)
        );
    }
}
