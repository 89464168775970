import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from '@lighty';

@Component({
    selector: 'msc-load-more',
    templateUrl: './load-more.component.html'
})
export class LoadMoreComponent {
    @Input() loading: boolean = false;
    @Input() pagination: Pagination;
    @Output() onLoad: EventEmitter<any> = new EventEmitter();

    constructor() {}

    loadMore(event): void {
        event.stopPropagation();
        this.onLoad.emit(true);
    }
}
