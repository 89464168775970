import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Pagination, AdminSubscriptionProvider, Camp } from '@lighty';
import { StorageService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-camp-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class CampSubscriptionComponent implements OnInit {
    @ViewChild('refuseSubscriptionAside') refuseSubscriptionAside;
    private camp: Camp;
    public subscriptions: Subscription[];
    public currentSubscription: Subscription;
    public pagination: Pagination;
    public filters: any;
    public currentFilter: string = 'pending';
    public loading: boolean = false;
    public query: string;
    public filterDropdown: boolean = false;
    public displays: any;
    public selectData: any[] = [];

    constructor(private route: ActivatedRoute, private subscriptionProvider: AdminSubscriptionProvider, private storageService: StorageService) {}

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');
        this.subscriptions = this.route.snapshot.data.subscriptions.subscriptions;
        this.pagination = this.route.snapshot.data.subscriptions.pagination;
        this.filters = this.route.snapshot.data.subscriptions.meta;

        this.selectData = [{
            id: 0,
            label: 'subscription.filter.all',
            value: undefined,
            isSelected: false
        }, {
            id: 1,
            label: 'subscription.filter.pending',
            value: 'pending',
            isSelected: true
        }, {
            id: 2,
            label: this.filters?.validated > 1 ? 'subscription.filter.validated' : 'subscription.filter.validated.unique',
            value: 'validated',
            isSelected: false
        }, {
            id: 3,
            label: this.filters?.refused > 1 ? 'subscription.filter.refused' : 'subscription.filter.refused.unique',
            value: 'refused',
            isSelected: false
        }];

        this.displays = {
            dropdowns: [],
            asides: {
                refused: false
            }
        };
    }

    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });

        this.changeFilter(option.value);
    }

    changeFilter(filter?: string): void {
        this.currentFilter = filter || '';
        // this.filterDropdown = false;
        this.refresh();
    }

    private refresh(): void {
        const params = {
            camp_id: this.camp.id,
            status: this.currentFilter
        };

        this.subscriptionProvider.getSubscriptions(params).subscribe((data) => {
            this.subscriptions = data.subscriptions;
            this.pagination = data.pagination;
            this.filters = data.meta;
        });
    }

    // displayFilterDropdown(): void {
    //     this.filterDropdown = !this.filterDropdown;
    // }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            camp_id: this.camp.id,
            status: this.currentFilter,
            q: this.query
        });

        this.subscriptionProvider.getSubscriptions(params).subscribe((data) => {
            this.subscriptions = data.subscriptions;
            this.pagination = data.pagination;
        });
    }

    accept(subscription: Subscription): void {
        this.subscriptionProvider.update(subscription.id, {is_validated: 1}).subscribe(() => {
            subscription.isValidated = 1;
            this.refresh();
        });
    }

    refuse(subscription: Subscription): void {
        this.currentSubscription = subscription;
        this.refuseSubscriptionAside.onOpen();
    }

    confirm(message: string): void {
        this.subscriptionProvider.update(this.currentSubscription.id, {is_validated: 0, validation_comment: message}).subscribe(() => {
            this.currentSubscription.isValidated = 0;
            this.refresh();
        });
    }

    displayed(type: string, index: number): void {
        const display = !this.displays[type][index];
        this.displays[type] = [];
        this.displays[type][index] = display;
    }

    loadMore(): void {
        this.loading = true;

        const params = HttpHelper.cleanParams({
            camp_id: this.camp.id,
            status: this.currentFilter,
            page: this.pagination.currentPage + 1,
            q: this.query
        });

        this.subscriptionProvider.getSubscriptions(params).subscribe((data) => {
            data.subscriptions.map((subscription) => {
                this.subscriptions.push(subscription);
            });
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    close(key: string, type: string): void {
        this.displays[key][type] = false;
    }
}
