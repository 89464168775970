import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
    selector: 'msc-showroom-exhibit-radio-page',
    templateUrl: './showroom-exhibit-radio-page.component.html',
    styleUrls: ['./showroom-exhibit-radio-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitRadioPageComponent {
    public form: FormGroup;
    public model = null;
    public model2 = null;

    constructor(
        private readonly fb: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.form = this.getFormGroup();
    }

    /**
     * Get the form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'beverage': new FormControl('soda'),
        });
    }
}
