<button #buttonHandle [type]="htmlType" class="{{type === 'fab' ? 'fab' : ''}} {{loading ? 'loading' : ''}}" [ngClass]="palette" [disabled]="disabled" [ngSwitch]="type">
    <span class="btn__content">
        <ng-container *ngSwitchCase="'custom'">
            <ng-content></ng-content>
        </ng-container>
        <ng-container *ngSwitchCase="'fab'">
            <i class="icon {{icon}}"></i>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <i *ngIf="iconSecondary" class="icon {{ iconSecondary }}"></i>
            <span class="btn__text">
                <ng-content></ng-content>
            </span>
            <i *ngIf="icon" class="icon {{ icon }}"></i>
        </ng-container>
    </span>
</button>