<msc-common-aside #aside [title]="('instance.interested-list' | translate) + ':' + template?.title">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4 slide-aside-on-demand aside-on-demand__body">
            <div class="slide__actions">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
                    (searchEvent)="search($event.value)"></msc-common-search>
            </div>

            <div class="dynamic-table" *ngIf="members.length > 0">
                <div class="dynamic-table__head">
                    <div class="dynamic-table__cell cell-title">
                        {{ 'words.name' | translate }}
                    </div>

                    <div class="dynamic-table__cell cell-important">
                        {{ 'words.last-connection' | translate }}
                    </div>
                </div>

                <div class="dynamic-table__body">
                    <div class="dynamic-table__row row-radius row-shadow" *ngFor="let member of members">
                        <div class="dynamic-table__cell cell-title">
                            <div class="avatar-profile">
                                <msc-common-avatar [label]="member.name"
                                    [pictureURL]="member.picture ? (member.picture + '?size=128') : null">
                                </msc-common-avatar>

                                <p>{{ member.name }}<span></span></p>
                            </div>
                        </div>

                        <div class="dynamic-table__cell cell-important">
                            <p *ngIf="member.lastConnection">{{ member.lastConnection | timeAgo }}</p>
                            <p *ngIf="!member.lastConnection">{{ 'words.no-connection' | translate }}</p>
                        </div>
                    </div>

                    <div class="dynamic-table__body__more" [ngClass]="{'animate': loading}">
                        <msc-button class="msc-button--primary" palette="secondary" [disabled]="loading"
                            *ngIf="pagination?.currentPage < pagination?.lastPage" (click)="loadMore()"
                            [loading]="loading">
                            {{ 'words.load-more' | translate }}
                        </msc-button>
                    </div>
                </div>
            </div>

            <msc-common-spinner *ngIf="!ready"></msc-common-spinner>

            <div class="aside-on-demand__text" *ngIf="members.length === 0 && ready">
                {{ 'empty.template.members' | translate }}
            </div>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div class="flex justify-end grow">
            <msc-button (click)="aside.onClose()">{{ 'words.cancel' | translate }}</msc-button>
        </div>
    </ng-template>
</msc-common-aside>