<div class=" rounded  p-6 max-w-lg m-auto">
    <div class="flex flex-col gap-6">
        <div class="text-center">
            <h1>{{ 'auth.new-password' | translate }}</h1>
            <p>{{ 'auth.enter-your-new-password' | translate }}</p>
        </div>

        <form class="flex flex-col space-y-4" (ngSubmit)="submit()">
            <input type="hidden" name="token" [ngModel]="token">

            <msc-input [label]="'auth.identifier' | translate" id="email" type="text" [(ngModel)]="credentials.email"
                [debounce]="500" (onDebounce)="onChange()" disabled name="email" required></msc-input>

            <input type="hidden" name="email" *ngIf="forceReset" [(ngModel)]="credentials.email">

            <msc-input [label]="'auth.password' | translate" id="password" type="password"
                [(ngModel)]="credentials.password" [debounce]="500" (onDebounce)="onChange()" name="password" required>
            </msc-input>

            <msc-input [label]="'auth.confirm-password' | translate" id="password-confirm" type="password"
                [(ngModel)]="credentials.password_confirmation" [debounce]="500" (onDebounce)="onChange()"
                name="password_confirmation" required></msc-input>

            <div class="flex flex-col space-y-1" role="alert">
                <span class="flex space-x-2 items-center"
                    [ngClass]="{'text-danger-500' : checkPasswordError('min') === 'error', 'text-success-500' : checkPasswordError('min') === 'valid'}">
                    <small *ngIf="checkPasswordError('min') === 'valid'">
                        <i class="icon icon-checkmark-1"></i>
                    </small>

                    <small *ngIf="checkPasswordError('min') === 'error'">
                        <i class="icon icon-close"></i>
                    </small>

                    <small>{{ 'auth.too-short' | translate }}</small>
                </span>

                <span class="flex space-x-2 items-center"
                    [ngClass]="{'text-danger-500' : checkPasswordError('password') === 'error', 'text-success-500' : checkPasswordError('password') === 'valid'}">
                    <small *ngIf="checkPasswordError('password') === 'valid'">
                        <i class="icon icon-checkmark-1"></i>
                    </small>

                    <small *ngIf="checkPasswordError('password') === 'error'">
                        <i class="icon icon-close"></i>
                    </small>

                    <small>{{ 'auth.num-maj-min' | translate }}</small>
                </span>

                <span class="flex space-x-2 items-center"
                    [ngClass]="{'text-danger-500' : checkPasswordError('equal') === 'error', 'text-success-500' : checkPasswordError('equal') === 'valid'}">
                    <small *ngIf="checkPasswordError('equal') === 'valid'">
                        <i class="icon icon-checkmark-1"></i>
                    </small>

                    <small *ngIf="checkPasswordError('equal') === 'error'">
                        <i class="icon icon-close"></i>
                    </small>

                    <small>{{ 'auth.no-match-rule' | translate }}</small>
                </span>
            </div>

            <div class="flex justify-end">
                <msc-button class="msc-button--primary" htmlType="submit" palette="primary"
                    [disabled]="!this.validator.isValid()">
                    {{ 'auth.reset-password' | translate }}
                </msc-button>
            </div>
        </form>
    </div>
</div>
