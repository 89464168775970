import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { Account } from '../../account';
import { environment } from '@env/environment';

@Injectable()
export class ManageConversationProvider {

    constructor(private http: HttpClient) {}

    getMembers(id: string|number, instanceId: number, params?: any): Observable<any> {
        const response = {
            accounts: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/members`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.accounts = data.accounts.map((account) => new Account(account));

                return response;
            })
        );
    }
}
