import { Component, OnInit } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { CompanyProvider, Camp } from '@lighty';
import { StorageService, TranslateService } from '@services';

@Component({
    selector: 'msc-camp-settings-notifications',
    templateUrl: './settings-notifications.component.html',
    styleUrls: ['./settings-notifications.component.scss']
})
export class CampSettingsNotificationsComponent implements OnInit {
    private camp: Camp;
    private notifications: any;
    public ready: boolean = false;
    public meta: any;
    public transformedNotifications: any[] = [];
    public displays: boolean[] = [];
    public currentNotification: any;

    constructor(
        private storageService: StorageService,
        private companyProvider: CompanyProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');

        this.companyProvider.getNotificationsSettings(this.camp.id).subscribe((data) => {
            this.meta = data.meta;
            this.notifications = data.notifications;
            this.transformNotifications(data.notifications);
            this.setCurrent(0);
            this.ready = true;
        });
    }

    setCurrent(index: number): void {
        this.currentNotification = this.transformedNotifications[index];
    }

    private transformNotifications(notifications: any): void {
        for (const key in notifications) {
            if (notifications[key] && notifications.hasOwnProperty(key)) {
                this.transformedNotifications.push({ title: key, values: notifications[key] });
            }
        }
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }

    count(notification: any): number {
        return (Object.values(notification).reduce((total: number, value: number) => {
            return total + value;
        }, 0)) as any;
    }

    disableAll(): void {
        this.meta.emailEnabled = !this.meta.emailEnabled;
        this.update({ meta: this.meta });
    }

    enableAll(): void {
        for (const key in this.currentNotification.values) {
            if (this.currentNotification.values.hasOwnProperty(key)) {
                this.notifications[this.currentNotification.title][key] = 1;
            }
        }
        this.update({ notifications: this.notifications });
    }

    change(value: boolean, category: string, key: string): void {
        this.notifications[category][key] = !this.notifications[category][key];
        this.update({ notifications: this.notifications });
    }

    private update(params: any): void {
        this.companyProvider.updateNotificationsSettings(this.camp.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }
}
