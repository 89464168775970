import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, Integration, IntegrationProvider } from '@lighty';
import { EmitterService, ExternalAppsService, StorageService, TranslateService } from '@services';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-integration-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.scss']
})

export class OrganizeIntegrationWatchComponent {
    private company: Company;
    public showMenu: boolean = true;
    public integration: Integration | any;
    public collection: any;
    public rules: any;
    public allowNavigation: boolean = false;
    public loader: any = {
        collection: false
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private integrationProvider: IntegrationProvider,
        private storageService: StorageService,
        private emitterService: EmitterService,
        private toastService: CommonToastService,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.route.data.subscribe((data: any) => {
            this.collection = data.collections.collections[0];
            this.integration = this.storageService.set('currentRuleProvider', data.integration);
            this.integration = this.getIntegrationRemapped(this.integration);
        });

        this.allowNavigation = this.route.snapshot.parent.data.providers.providers.length > 1;

        this.showMenu = (this.router.url.indexOf('rules/edit') === -1 && this.router.url.indexOf('rules/create') === -1);
        this.router.events.subscribe(() => {
            this.showMenu = (this.router.url.indexOf('rules/edit') === -1 && this.router.url.indexOf('rules/create') === -1);
        });

        this.emitterService.get('integration.connected').subscribe(data => {
            this.loader.collection = true;
            const companyId = this.company.id;
            const providerId = this.integration.id;

            this.integrationProvider.getProvider(providerId).subscribe((integration) => {
                this.integration = this.storageService.set('currentRuleProvider', integration);
                this.integration = this.getIntegrationRemapped(this.integration);
            });

            this.integrationProvider.getCollections(companyId, providerId).subscribe((dt) => {
                this.collection = dt.collections[0];
                this.loader.collection = false;
            });
        });

        this.emitterService.get('mapping-edit').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.showMenu = !data;
                this.emitterService.set('mapping-edit', {});
            }
        });

        this.emitterService.get('refresh.integration.deploy').subscribe(data => {
            if (typeof data === 'boolean') {
                this.refresh();
                this.emitterService.set('refresh.integration.deploy', {});
            }
        });
    }

    refresh(): void {
        this.integrationProvider.getProvider(this.integration.id).subscribe((integration) => {
            this.integration = this.storageService.set('currentRuleProvider', integration);
            this.integration = this.getIntegrationRemapped(this.integration);
        });
    }

    getIntegrationRemapped(datum: any) {
        const mediaUrl = datum.media ? `url(${datum.media.pictureUrl}?size=256)` : null;
        return { ...datum, mediaUrl };
    }

    navigateThroughProvider(direction: number): void {
        this.integrationProvider.getConnectedProviders(this.company.id).subscribe(dt => {
            const integrations = dt.providers;

            const positionInArray = integrations.findIndex((int) => this.integration.id === int.provider.id);
            let newIndex = positionInArray + direction;
            if (newIndex < 0) {
                newIndex = integrations.length - 1;
            }
            if (newIndex >= integrations.length) {
                newIndex = 0;
            }
            this.integrationProvider.getCollections(this.company.id, integrations[newIndex].provider.id).subscribe(data => {
                const collectionId = data.collections[0].collectionId;
                const currentRoute = this.router.url;
                let url;

                if (currentRoute.includes('/rules')) {
                    url = `organize/${this.company.slug}/integrations/providers/${integrations[newIndex].provider.id}/collections/${collectionId}/rules`;
                } else if (currentRoute.includes('/stats')) {
                    url = `organize/${this.company.slug}/integrations/providers/${integrations[newIndex].provider.id}/stats`;
                } else if (currentRoute.includes('/settings')) {
                    url = `organize/${this.company.slug}/integrations/providers/${integrations[newIndex].provider.id}/settings`;
                } else if (currentRoute.includes('/catalogue-export')) {
                    url = `organize/${this.company.slug}/integrations/providers/${integrations[newIndex].provider.id}/collections/${collectionId}/catalogue-export`;
                } else if (currentRoute.includes('/mapping')) {
                    url = `organize/${this.company.slug}/integrations/providers/${integrations[newIndex].provider.id}/skills/mapping`;
                } else if (currentRoute.includes('/exception')) {
                    url = `organize/${this.company.slug}/integrations/providers/${integrations[newIndex].provider.id}/skills/exception`;
                }
                this.router.navigateByUrl(url);
            });
        });
    }

    updateCatalogue(): void {
        this.integrationProvider.deploy(this.company.id, this.integration.id).subscribe(() => {
            this.integration.companyProvider.deploymentRunning = 1;
            this.toastService.onSuccess(this.translateService.instant('toast.integration.update.catalogue'));
        });
    }

    isSync(): boolean {
        return moment(this.integration.synchronizedAt).isAfter(this.integration.companyProvider?.deploymentDoneAt);
    }
}
