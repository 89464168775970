<div class="content-experience content-experience--catalogue">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title mb-3">
                <h3>{{ 'experience.catalogue.welcome' | translate }}</h3>
            </div>

            <div class="flex flex-col space-y-2">
                <msc-common2-radio groupName="catalogueDisplay" [(ngModel)]="experience.catalogueDisplay" (ngModelChange)="update()" [valueName]="'overview'"
                [label]="'experience.catalogue.next-contents' | translate"></msc-common2-radio>
                <msc-common2-radio groupName="catalogueDisplay" [(ngModel)]="experience.catalogueDisplay" (ngModelChange)="update()" [valueName]="'skills'"
                [label]="'experience.catalogue.thematic-contents' | translate"></msc-common2-radio>
            </div>
        </div>

        <div class="box box-experience box-experience--spacing">
            <div class="box-experience__title">
                <h3>{{ 'experience.background' | translate }}</h3>
            </div>

            <div class="box-experience__content">
                <p>{{ 'words.background-picture' | translate }}</p>

                <div class="content__upload">
                    <div class="content__picture" [ngClass]="{'content__picture--empty': !loading && !experience.cataloguePicture, 'content__picture--load': loading && !experience.cataloguePicture}">
                        <div class="picture__delete bg-primary-700" *ngIf="experience.cataloguePicture" (click)="deletePicture()">
                            <i class="icon icon-close"></i>
                        </div>

                        <msc-media [picture]="experience.cataloguePicture" [size]="'512'" [loadEmitter]="loadEmitter" (onUploaded)="updatePicture($event)" (onLoading)="load()"></msc-media>
                    </div>

                    <div class="content__action">
                        <msc-button class="msc-button--primary" palette="secondary" (click)="loadPicture()">
                            {{ 'words.upload' | translate }}
                        </msc-button>

                        <p>{{ 'words.upload-info.256' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
