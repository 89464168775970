<div class="wrapper">
    <msc-common-avatar [label]="datum.userName" [pictureURL]="datum.userAvatar"></msc-common-avatar>
    <div class="grouping">
        <span class="name">{{ datum.userName }}</span>
        <span class="role">{{ datum.userRole }}</span>
    </div>
</div>

<div class="wrapper">
    <msc-button type="fab" icon="icon-message-circle" (click)="onChat()"></msc-button>
    <msc-button type="fab" icon="icon-email" (click)="onEmail()"></msc-button>
</div>
