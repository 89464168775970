import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@services';
import * as moment from 'moment';

/**
 * Add a duration to a value (3 months)
 */
@Pipe({
    name: 'timeElapsed'
})
export class TimeElapsedPipe implements PipeTransform {
    private singleUnits: any = {
        fr: {
            m: 'mois',
            d: 'jour',
            h: 'heure',
            min: 'minute',
            s: 'seconde'
        },
        en: {
            m: 'month',
            d: 'day',
            h: 'hour',
            min: 'minute',
            s: 'second'
        },
        es: {
            m: 'mes',
            d: 'día',
            h: 'hora',
            min: 'minuto',
            s: 'segunda'
        },
        nl: {
            m: 'maand',
            d: 'dag',
            h: 'uur',
            min: 'minute',
            s: 'seconde'
        },
        it: {
            m: 'mese',
            d: 'giorno',
            h: 'ora',
            min: 'minuto',
            s: 'secondo'
        },
        de: {
            m: 'Monat',
            d: 'Tag',
            h: 'Stunde',
            min: 'Minute',
            s: 'Sekunde'
        }
    };
    private pluralUnits: any = {
        fr: {
            m: 'mois',
            d: 'jours',
            h: 'heures',
            min: 'minutes',
            s: 'secondes'
        },
        en: {
            m: 'months',
            d: 'days',
            h: 'hours',
            min: 'minutes',
            s: 'secondes'
        },
        es: {
            m: 'meses',
            d: 'días',
            h: 'horas',
            min: 'minutos',
            s: 'segundos'
        },
        nl: {
            m: 'maanden',
            d: 'dagen',
            h: 'uren',
            min: 'minuten',
            s: 'seconden'
        },
        it: {
            m: 'mesi',
            d: 'giorni',
            h: 'ore',
            min: 'minuti',
            s: 'secondi'
        },
        de: {
            m: 'Monate',
            d: 'Tage',
            h: 'Stunden',
            min: 'Minuten',
            s: 'Sekunden'
        }
    };

    constructor(private translateService: TranslateService) {}

    transform(date: Date|number): any {
        return this.process(date, this.translateService.getCurrentLang());
    }

    private process(date: Date|number, lang: string): string {
        if (!date) {
            return '';
        }

        let unit = 's';
        let time = moment().diff(moment.utc(date), 'seconds');

        if (time > 60) {
            unit = 'min';
            time = moment().diff(moment.utc(date), 'minutes');

            if (time > 60) {
                unit = 'h';
                time = moment().diff(moment.utc(date), 'hours');

                if (time > 24) {
                    unit = 'd';
                    time = moment().diff(moment.utc(date), 'days');

                    if (time > 30) {
                        unit = 'm';
                        time = moment().diff(moment.utc(date), 'months');
                    }
                }
            }
        }

        return time > 1 ? time + ' ' + this.pluralUnits[lang][unit] : time + ' ' + this.singleUnits[lang][unit];
    }
}
