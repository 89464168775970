import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { TranslateService } from '@services';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(key: string, params?: any): string {
        return this.translateService.getTranslate(key, params) || key;
    }
}
