import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DataHelper, UrlHelper } from '@helpers';
import { StorageService } from '@services';
import * as moment from 'moment';
import { CourseProvider } from '@lighty';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-player-light-modal-progress',
    templateUrl: './modal-progress.component.html',
    styleUrls: ['./modal-progress.component.scss']
})
export class PlayerLightModalProgressComponent implements OnInit, OnDestroy {
    private timers: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public internalTimer: number = 0;
    public onClose: EventEmitter<boolean> = new EventEmitter();
    public onSave: EventEmitter<any> = new EventEmitter();
    public active: boolean = false;
    public content: any;
    public data: any;
    public options: any;
    public type: any;
    public session: any;
    public progressEnabled: boolean = true;

    constructor(private storageService: StorageService, private courseProvider: CourseProvider) {}

    ngOnInit(): void {
        this.timers = {
            duration: null,
            internal: null
        };

        if (this.content.context === 'external_link') {
            this.session = this.content;
            this.content = this.type;
            this.internalTimer = this.session.duration || 0;
            this.buildLTIForm(this.content.externalLink);

            if (this.progressEnabled) {
                this.timers.duration = setInterval(() => {
                    this.session.duration += 5;
                    this.updateSectionContent();
                }, 5000);
    
                this.timers.internal = setInterval(() => {
                    this.internalTimer++;
                    this.processTimer();
                }, 1000);
            }
        }

        this.active = true;
        this.data = {
            finished: true,
            date: moment().format('YYYY-MM-DD'),
            unit: 'minutes',
            duration: 0,
            progress: 0,
            context: this.type === 'external' ? 'content' : 'video',
            company_id: this.storageService.get('company').id
        };

        if (!this.session) {
            this.session = {
                progress: this.content.progress
            };
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.timers.duration);
        clearInterval(this.timers.internal);
        this.subscriptionCollection.unsubscribe();
    }

    open(): void {
        this.content.context === 'external_link' ? this.buildLTIForm(this.content.externalLink) : UrlHelper.navigate(this.content.url);
    }

    close(update: boolean = true): void {
        if (this.content.context === 'external_link' && update) {
            this.session.duration = this.internalTimer;
            const params = {
                isDone: !!this.session.doneAt
            };

            this.courseProvider.updateSectionContentSessions(this.session.id, this.session, params).subscribe((data) => {
                this.session = data;
            });
        }
        this.active = false;
        this.onClose.emit(true);
    }

    progress(): void {
        this.transformDuration();
        this.onSave.emit({type: 'progress', content: this.data});
    }

    finished(): void {
        if (this.content.context === 'external_link') {
            const params = {
                isDone: true
            };

            this.courseProvider.updateSectionContentSessions(this.session.id, this.session, params).subscribe((data) => {
                this.session = data;
            });
            this.close(false);
        } else {
            this.transformDuration();
            this.data.progress = 100;

            this.onSave.emit({type: 'finished', content: this.data});
        }
    }

    save(): void {
        if (this.data.finished) {
            this.finished();
        } else {
            this.progress();
        }
    }

    private transformDuration(): void {
        if (this.data.duration) {
            let multiply = 60;
            if (this.data.unit === 'hours') {
                multiply *= 60;
            } else if (this.data.unit === 'days') {
                multiply *= 60 * 24;
            }

            this.data.duration *= multiply;
        }
    }

    private processTimer(): any {
        if (this.internalTimer) {
            if (this.internalTimer > 60) {
                this.data.unit = 'hours';
                this.data.duration = 1;
            } else if (this.internalTimer > (60 * 60 * 24)) {
                this.data.unit = 'days';
                this.data.duration = 1;
            } else {
                this.data.duration += 1;
            }
        }
    }

    private buildLTIForm(integration: any): void {
        const form = document.createElement('form');
        form.target = '_blank';
        form.method = 'POST';
        form.action = integration.url;
        for (const key in integration.fields) {
            if (integration.fields.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = DataHelper.changeCase(key);
                input.setAttribute('value', integration.fields[key]);
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }

    private updateSectionContent(): void {
        const params = {
            isDone: !!this.session.doneAt
        };

        this.subscriptionCollection.subscribe = this.courseProvider.updateSectionContentSessions(this.session.id, this.session, params).subscribe((data) => {
            this.session = data;
        });
    }
}
