import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmitterService } from '@services';
import { ExtensionLink } from '@interfaces';

@Component({
    selector: 'msc-shared-aside-extension-choice',
    templateUrl: './aside-extension-choice.component.html',
    styleUrls:['./aside-extension-choice.component.scss']
})
export class SharedExtensionChoiceComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();

    constructor(private emitterService: EmitterService) {}

    ngOnInit(): void {}

    disableClick(): boolean {
        return !(this.extensionLink.savein.camps === true || this.extensionLink.savein.content === true || this.extensionLink.savein.community === true);

    }

    checkTheBox(saveIn: string): void {
        switch (saveIn) {
            case 'content' :
                this.extensionLink.savein.content = !this.extensionLink.savein.content;
                break;
            case 'camps':
                this.extensionLink.savein.camps = !this.extensionLink.savein.camps;
                break;
            case 'community':
                this.extensionLink.savein.community = !this.extensionLink.savein.community;
                break;
        }
    }

    nextPanel(): void {
        if (this.extensionLink.savein.camps === true) {
            this.emitterService.extensionPage.emit('camps');
        } else if (this.extensionLink.savein.community === true) {
            this.emitterService.extensionPage.emit('community');
        } else {
            this.emitterService.extensionPage.emit('form');
        }
    }
}
