export const ICON_LIST: Array<string> = [
    'icon-activity-1',
    'icon-add-message',
    'icon-alert-circle',
    'icon-alert-triangle',
    'icon-all-done',
    'icon-archive',
    'icon-arrow-circle-down',
    'icon-arrow-circle-left',
    'icon-arrow-circle-right',
    'icon-arrow-circle-up',
    'icon-arrow-down',
    'icon-arrow-ios-down',
    'icon-arrow-ios-left',
    'icon-arrow-ios-right',
    'icon-arrow-ios-up',
    'icon-arrow-left',
    'icon-arrow-right',
    'icon-arrow-up',
    'icon-arrowhead-down',
    'icon-arrowhead-left',
    'icon-arrowhead-right',
    'icon-arrowhead-up',
    'icon-arrrow-left',
    'icon-at',
    'icon-attach-2',
    'icon-attach',
    'icon-award',
    'icon-backspace',
    'icon-bar-chart-2',
    'icon-bar-chart',
    'icon-battery',
    'icon-batttery-charging',
    'icon-behance',
    'icon-bell-off',
    'icon-bell',
    'icon-bluetooth',
    'icon-book-open',
    'icon-book',
    'icon-bookmark',
    'icon-briefcase',
    'icon-browser',
    'icon-brush',
    'icon-bulb',
    'icon-bullet-point',
    'icon-calendar-add',
    'icon-calendar',
    'icon-camp',
    'icon-camera',
    'icon-car',
    'icon-cast',
    'icon-catalogue',
    'icon-checkmark-circle-2',
    'icon-checkmark-circle',
    'icon-checkmark-square-2',
    'icon-checkmark-square',
    'icon-checkmark',
    'icon-chevron-down',
    'icon-chevron-left',
    'icon-chevron-right',
    'icon-chevron-up',
    'icon-clipboard',
    'icon-clock',
    'icon-close-circle',
    'icon-close',
    'icon-cloud-download',
    'icon-cloud-upload',
    'icon-code-download',
    'icon-code',
    'icon-collapse',
    'icon-color-palette',
    'icon-color-picker',
    'icon-compass',
    'icon-content',
    'icon-copy',
    'icon-corner-down-left',
    'icon-corner-down-right',
    'icon-corner-left-down',
    'icon-corner-left-up',
    'icon-corner-right-down',
    'icon-corner-right-up',
    'icon-corner-up-left',
    'icon-corner-up-right',
    'icon-credit-card',
    'icon-crop',
    'icon-cube',
    'icon-dashboard',
    'icon-diagonal-arrow-left-down',
    'icon-diagonal-arrow-left-up',
    'icon-diagonal-arrow-right-down',
    'icon-diagonal-arrow-right-up',
    'icon-download',
    'icon-droplet-off',
    'icon-droplet',
    'icon-edit-2',
    'icon-edit',
    'icon-email',
    'icon-external-link',
    'icon-expand',
    'icon-eye-off-2',
    'icon-eye-off',
    'icon-eye',
    'icon-facebook',
    'icon-file-add',
    'icon-file-remove',
    'icon-file-text',
    'icon-file',
    'icon-film',
    'icon-fire',
    'icon-flag',
    'icon-flash-off',
    'icon-flash',
    'icon-flip-2',
    'icon-flip',
    'icon-folder-add',
    'icon-folder-remove',
    'icon-folder',
    'icon-funnel',
    'icon-gift',
    'icon-github',
    'icon-globe-2',
    'icon-globe',
    'icon-google',
    'icon-grid',
    'icon-hard-drive',
    'icon-hash',
    'icon-headphones',
    'icon-heart',
    'icon-height',
    'icon-home',
    'icon-image',
    'icon-inbox',
    'icon-info',
    'icon-keypad',
    'icon-layers',
    'icon-layout',
    'icon-like-social',
    'icon-link-2',
    'icon-link',
    'icon-linkedin',
    'icon-list',
    'icon-loader',
    'icon-lock',
    'icon-log-in',
    'icon-log-out',
    'icon-logo-myskillcamp',
    'icon-map',
    'icon-maximize',
    'icon-mentee',
    'icon-mentor',
    'icon-menu-2',
    'icon-menu-arrow',
    'icon-menu',
    'icon-message-circle',
    'icon-message-square',
    'icon-mic-off',
    'icon-mic',
    'icon-minimize',
    'icon-minus-circle',
    'icon-minus-square',
    'icon-minus',
    'icon-monitor',
    'icon-moon',
    'icon-more-horizontal',
    'icon-more-vertical',
    'icon-move',
    'icon-music',
    'icon-navigation-2',
    'icon-navigation',
    'icon-npm',
    'icon-options-2',
    'icon-options',
    'icon-pantone',
    'icon-paper-plane',
    'icon-pause-circle',
    'icon-people',
    'icon-percent',
    'icon-person-add',
    'icon-person-delete',
    'icon-person-done',
    'icon-person-remove',
    'icon-person',
    'icon-phone-call',
    'icon-phone-missed',
    'icon-phone-off',
    'icon-phone',
    'icon-pie-chart',
    'icon-pin',
    'icon-play-circle',
    'icon-plus-circle',
    'icon-plus-square',
    'icon-plus',
    'icon-power',
    'icon-pricetag',
    'icon-printer',
    'icon-question-mark-circle',
    'icon-question-mark',
    'icon-radio-button-off',
    'icon-radio-button-on',
    'icon-radio',
    'icon-recording',
    'icon-refresh',
    'icon-repeat',
    'icon-rewind-left',
    'icon-rewind-right',
    'icon-save',
    'icon-scissors',
    'icon-search',
    'icon-settings-2',
    'icon-settings',
    'icon-shake',
    'icon-share',
    'icon-shield-off',
    'icon-shield',
    'icon-shopping-bag',
    'icon-shopping-cart',
    'icon-shuffle-2',
    'icon-shuffle',
    'icon-skip-back',
    'icon-skip-forward',
    'icon-slash',
    'icon-smartphone',
    'icon-speaker',
    'icon-square',
    'icon-star',
    'icon-stop-circle',
    'icon-sun',
    'icon-swap',
    'icon-sync',
    'icon-text',
    'icon-thermometer-minus',
    'icon-thermometer-plus',
    'icon-thermometer',
    'icon-timer',
    'icon-toggle-left',
    'icon-toggle-right',
    'icon-trash-2',
    'icon-trash',
    'icon-trending-down',
    'icon-trending-up',
    'icon-tv',
    'icon-twitter',
    'icon-umbrella',
    'icon-undo',
    'icon-unlock',
    'icon-upload',
    'icon-video-off',
    'icon-video',
    'icon-volume-down',
    'icon-volume-off',
    'icon-volume-up',
    'icon-volume',
    'icon-weight',
    'icon-wifi-off',
    'icon-wifi',
    'icon-activity',
    'icon-alert-circle-1',
    'icon-alert-triangle-1',
    'icon-all-done-1',
    'icon-archive-1',
    'icon-arrow-circle-down-1',
    'icon-arrow-circle-left-1',
    'icon-arrow-circle-right-1',
    'icon-arrow-circle-up-1',
    'icon-arrow-down-1',
    'icon-arrow-ios-down-1',
    'icon-arrow-ios-left-1',
    'icon-arrow-ios-right-1',
    'icon-arrow-ios-up-1',
    'icon-arrow-left-1',
    'icon-arrow-right-1',
    'icon-arrow-up-1',
    'icon-arrowhead-down-1',
    'icon-arrowhead-left-1',
    'icon-arrowhead-right-1',
    'icon-arrowhead-up-1',
    'icon-arrrow-left-1',
    'icon-at-1',
    'icon-attach-1',
    'icon-attach-3',
    'icon-award-1',
    'icon-backspace-1',
    'icon-bar-chart-1',
    'icon-bar-chart-3',
    'icon-battery-1',
    'icon-batttery-charging-1',
    'icon-behance-1',
    'icon-bell-off-1',
    'icon-bell-1',
    'icon-bluetooth-1',
    'icon-bold',
    'icon-book-open-1',
    'icon-book-1',
    'icon-bookmark-1',
    'icon-briefcase-1',
    'icon-browser-1',
    'icon-brush-1',
    'icon-bulb-1',
    'icon-student',
    'icon-student-1',
    'icon-calendar-add-1',
    'icon-calendar-1',
    'icon-camera-1',
    'icon-camp-1',
    'icon-cast-1',
    'icon-car-1',
    'icon-catalogue-1',
    'icon-checkmark-circle-1',
    'icon-checkmark-circle-3',
    'icon-checkmark-square-copy',
    'icon-checkmark-square-1',
    'icon-checkmark-1',
    'icon-chevron-left-1',
    'icon-chevron-down-1',
    'icon-chevron-right-1',
    'icon-chevron-up-1',
    'icon-clipboard-1',
    'icon-clock-1',
    'icon-close-circle-1',
    'icon-close-1',
    'icon-cloud-download-1',
    'icon-cloud-upload-1',
    'icon-code-download-1',
    'icon-code-1',
    'icon-color-palette-1',
    'icon-collapse-1',
    'icon-compass-1',
    'icon-content-1',
    'icon-color-picker-1',
    'icon-copy-1',
    'icon-corner-down-left-1',
    'icon-corner-down-right-1',
    'icon-corner-left-down-1',
    'icon-corner-left-up-1',
    'icon-corner-right-down-1',
    'icon-corner-right-up-1',
    'icon-corner-up-left-1',
    'icon-corner-up-right-1',
    'icon-credit-card-1',
    'icon-crop-1',
    'icon-cube-1',
    'icon-dashboard-1',
    'icon-diagonal-arrow-left-down-1',
    'icon-diagonal-arrow-left-up-1',
    'icon-diagonal-arrow-right-down-1',
    'icon-diagonal-arrow-right-up-1',
    'icon-download-1',
    'icon-droplet-off-1',
    'icon-droplet-1',
    'icon-edit-1',
    'icon-edit-3',
    'icon-expand-1',
    'icon-email-1',
    'icon-external-link-1',
    'icon-eye-off-1',
    'icon-eye-off-3',
    'icon-eye-1',
    'icon-facebook-1',
    'icon-file-add-1',
    'icon-file-remove-1',
    'icon-file-text-1',
    'icon-file-1',
    'icon-film-1',
    'icon-fire-1',
    'icon-flag-1',
    'icon-flash-off-1',
    'icon-flash-1',
    'icon-flip-1',
    'icon-flip-3',
    'icon-folder-add-1',
    'icon-folder-remove-1',
    'icon-folder-1',
    'icon-funnel-1',
    'icon-gift-1',
    'icon-github-1',
    'icon-globe-1',
    'icon-globe-3',
    'icon-google-1',
    'icon-grid-1',
    'icon-hard-drive-1',
    'icon-hash-1',
    'icon-headphones-1',
    'icon-heart-1',
    'icon-height-1',
    'icon-home-1',
    'icon-image-1',
    'icon-inbox-1',
    'icon-info-1',
    'icon-keypad-1',
    'icon-layers-1',
    'icon-layout-1',
    'icon-link-1',
    'icon-link-3',
    'icon-linkedin-1',
    'icon-list-1',
    'icon-loader-1',
    'icon-lock-1',
    'icon-log-in-1',
    'icon-log-out-1',
    'icon-pie-stats',
    'icon-map-1',
    'icon-maximize-1',
    'icon-menu-1',
    'icon-menu-arrow-1',
    'icon-menu-3',
    'icon-message-circle-1',
    'icon-message-square-1',
    'icon-mic-off-1',
    'icon-mic-1',
    'icon-minimize-1',
    'icon-minus-circle-1',
    'icon-minus-square-1',
    'icon-minus-1',
    'icon-monitor-1',
    'icon-moon-1',
    'icon-more-horizontal-1',
    'icon-more-vertical-1',
    'icon-move-1',
    'icon-music-1',
    'icon-navigation-1',
    'icon-navigation-3',
    'icon-npm-1',
    'icon-options-1',
    'icon-options-3',
    'icon-pantone-1',
    'icon-paper-plane-1',
    'icon-pause-circle-1',
    'icon-people-1',
    'icon-percent-1',
    'icon-person-add-1',
    'icon-person-delete-1',
    'icon-person-done-1',
    'icon-person-remove-1',
    'icon-person-1',
    'icon-phone-call-1',
    'icon-phone-missed-1',
    'icon-phone-off-1',
    'icon-phone-1',
    'icon-pie-chart-1',
    'icon-pin-1',
    'icon-play-circle-1',
    'icon-plus-circle-1',
    'icon-plus-square-1',
    'icon-plus-1',
    'icon-power-1',
    'icon-pricetag-1',
    'icon-printer-1',
    'icon-question-mark-circle-1',
    'icon-question-mark-1',
    'icon-radio-button-off-1',
    'icon-radio-button-on-1',
    'icon-radio-1',
    'icon-recording-1',
    'icon-refresh-1',
    'icon-repeat-1',
    'icon-rewind-left-1',
    'icon-rewind-right-1',
    'icon-save-1',
    'icon-scissors-1',
    'icon-search-1',
    'icon-settings-1',
    'icon-settings-3',
    'icon-shake-1',
    'icon-share-1',
    'icon-shield-off-1',
    'icon-shield-1',
    'icon-shopping-bag-1',
    'icon-shopping-cart-1',
    'icon-shuffle-1',
    'icon-shuffle-3',
    'icon-skip-back-1',
    'icon-skip-forward-1',
    'icon-slash-1',
    'icon-smartphone-1',
    'icon-speaker-1',
    'icon-square-1',
    'icon-star-1',
    'icon-stop-circle-1',
    'icon-sun-1',
    'icon-swap-1',
    'icon-sync-1',
    'icon-text-1',
    'icon-thermometer-minus-1',
    'icon-thermometer-plus-1',
    'icon-thermometer-1',
    'icon-timer-1',
    'icon-toggle-left-1',
    'icon-toggle-right-1',
    'icon-trash-1',
    'icon-trash-3',
    'icon-trending-down-1',
    'icon-trending-up-1',
    'icon-tv-1',
    'icon-twitter-1',
    'icon-umbrella-1',
    'icon-undo-1',
    'icon-unlock-1',
    'icon-upload-1',
    'icon-video-off-1',
    'icon-video-1',
    'icon-volume-down-1',
    'icon-volume-off-1',
    'icon-volume-up-1',
    'icon-volume-1',
    'icon-weight-1',
    'icon-wifi-off-1',
    'icon-wifi-1',
    'icon-pie-stats-1',
    'icon-add-people',
    'icon-library',
    'icon-megaphone',
    'icon-pin-2',
    'icon-rocket',
    'icon-evaluation',
    'icon-company-building',
    'icon-hourglass',
    'icon-hourglass-1',
    'icon-unlink',
]