<div class="head" >
    <div class="wrapper">
        <h3>{{ 'words.my-team' | translate }}</h3>
    </div>
</div>

<div class="body" >
    <msc-common-nav [data]="navData"></msc-common-nav>
    <section>
        <router-outlet></router-outlet>
    </section>
</div>
