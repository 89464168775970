<section class="head">
    <h3>Accordion</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Accordion</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-accordion [title]="'Coffee'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
                <msc-common-accordion [title]="'Soda'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
                <msc-common-accordion [title]="'Water'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Accordion with icons</h4>
        <hr>
        <div class="partition cards">
            <div class="segment">
                <msc-common-accordion icon="cube" [title]="'Coffee'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
                <msc-common-accordion icon="cube" [title]="'Soda'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
            </div>

            <div class="segment">
                <msc-common-accordion icon="pin-2" [title]="'Coffee'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
                <msc-common-accordion icon="pin-2" [title]="'Soda'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
            </div>

            <div class="segment">
                <msc-common-accordion icon="trending-up" [title]="'Coffee'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
                <msc-common-accordion icon="trending-up" [title]="'Soda'">
                    <ng-template>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
                    </ng-template>
                </msc-common-accordion>
            </div>
        </div>
    </div>
</section>
