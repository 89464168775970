<section class="head">
    <h3>Slider</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Slider</h4>
        <hr>
        <div class="partition">
            <msc-common-slider></msc-common-slider>
        </div>
    </div>  
</section>
