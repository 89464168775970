import { NgModule, LOCALE_ID } from '@angular/core';
//
import { environment } from '@env/environment';
//
import { registerLocales } from './locales';
import {
    ADMIN_COMPANY_LIST_API_URL_ROOT,
    COMPANY_LIST_API_URL_ROOT,
    CREATE_API_URL_ROOT,
    MEDIA_API_URL_ROOT,
} from './tokens';

registerLocales();

const API_ROOT = environment.envVar;
const URL_ROOT = {
    adminCompanyList: 'admin/companies',
    companyList: 'companies',
    create: 'create',
    media: 'media',
};
const ROOT: any = function() {
    return Object.keys(API_ROOT).reduce((apiAcc: object, apiKey: string) => ({
        ...apiAcc,
        [apiKey]: Object.keys(URL_ROOT).reduce((urlAcc: object, urlKey: string) => ({
            ...urlAcc,
            [urlKey]: `${API_ROOT[apiKey]}/${URL_ROOT[urlKey]}`
        }), {})
    }), {});
}();

@NgModule({
    providers: [
        // Api
        { provide: ADMIN_COMPANY_LIST_API_URL_ROOT, useValue: ROOT.API_URL.adminCompanyList },
        { provide: COMPANY_LIST_API_URL_ROOT, useValue: ROOT.API_URL.companyList },
        { provide: CREATE_API_URL_ROOT, useValue: ROOT.API_URL.create },
        { provide: MEDIA_API_URL_ROOT, useValue: ROOT.API_URL.media },
        // Internationalization i18n
        { provide: LOCALE_ID, useValue: 'en-US' },
    ]
})

export class AppCoreModule { }
