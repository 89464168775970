export enum CommonSubjectStatus {
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

export interface CommonApiGetListOrderParams {
    order: 'asc' | 'desc';
    sort: string;
}

export interface CommonApiGetListResponse<T> {
    data: Array<T>;
    pagination: {
        currentPage: number;
        lastPage: number;
        perPage: number;
        total: number;
    };
}
