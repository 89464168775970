<msc-common-aside #aside (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div class="slide__mail-avatar">
                <ul class="avatar-group avatar-group--add">
                    <li *ngFor="let member of selectedUsers | slice:0:4">
                        <a [tooltip]="member.account.name">
                            <msc-common-avatar [label]="member.account.name"
                                [pictureURL]="member.account.picture ? (member.account.picture + '?size=128') : null">
                            </msc-common-avatar>
                        </a>
                    </li>

                    <li *ngIf="selectedUsers.length > 4">
                        <span class="avatar-group__text text-primary-500">+ {{ selectedUsers.length - 4 }}</span>
                    </li>
                </ul>
            </div>

            <div class="slide__mail-tab">
                <msc-textarea [label]="'shared.mail.title' | translate"
                    [placeholder]="'shared.mail.placeholder' | translate" [(ngModel)]="message"></msc-textarea>

                <small>{{ 'shared.mail.description' | translate }}</small>
            </div>
        </div>
    </ng-template>
</msc-common-aside>