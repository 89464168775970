import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild, TemplateRef, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';

@Component({
    selector: 'msc-common-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonUploadComponent {
    @Input() imageUrl?: string;
    @Output() uploadEvent: EventEmitter<File> = new EventEmitter<File>();
    @ContentChild(TemplateRef) bodyTemplate: TemplateRef<any>;
    @ViewChild('file') file: ElementRef;
    @ViewChild('img') img: ElementRef;

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly renderer: Renderer2,
    ) { }

    ngAfterViewInit(): void {
        this.setContent();
    }

    /**
     * Set the component content
     */
    setContent(): void {
        if (!this.imageUrl) { return; }
        this.img.nativeElement.src = `${this.imageUrl}?size=256`;
        this.ref.detectChanges();
    }

    /**
     * Event handler for trigger
     */
    onTrigger(): void {
        this.file.nativeElement.click();
    }

    /**
     * Event handler for drop
     */
    onDrop(file: File): void {
        this.img.nativeElement.src = URL.createObjectURL(file);
        this.uploadEvent.emit(file);
    }

    /**
     * Event handler for upload
     */
    onUpload(files: FileList): void {
        const [file] = Array.from(files);
        this.onDrop(file);
    }

    /**
     * Event handler for remove
     */
    onRemove(): void {
        this.renderer.removeAttribute(this.img.nativeElement, 'src');
        this.uploadEvent.emit(null);
    }
}
