import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { CompanyUserAccessManageProvider, Pagination } from '@lighty';
import { ParamsService, StorageService, ToastService } from '@services';
import { OrganizeUserStepBaseComponent } from '../configure.component.base';
import { FORMSTEPS } from '../configure.enum';

@Component({
    selector: 'msc-organize-user-configure-coach',
    templateUrl: './coach.component.html',
    providers: [{ provide: OrganizeUserStepBaseComponent, useExisting: OrganizeUserConfigureCoachComponent }]
})
export class OrganizeUserConfigureCoachComponent implements OnInit {
    @Input() role;
    @Output() onSkip: EventEmitter<any> = new EventEmitter();
    @Output() onValidate: EventEmitter<any> = new EventEmitter();
    private me: any;
    private company: any;
    public filters: any = {};
    public activeFilters: boolean = false;
    public query: string;
    public buildFilters: EventEmitter<any> = new EventEmitter();
    public formId;
    pagination: any = new Pagination({
        perPage: 5
    });
    public hasNavigation = true;
    catalogue: any[] = [];
    cachedCatalogue: any[] = [];
    loading: boolean;

    constructor(private companyUserAccessManageProvider: CompanyUserAccessManageProvider,
        private paramsService: ParamsService, private storageService: StorageService) {

        this.formId = FORMSTEPS.COACH;
    }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.loadMore();
    }

    displayed(key: string, context?: any, force: boolean = null): void {
        if (!context) {
            context = this;
        }

        context.displayDropdowns[key] = force !== null ? force : !context.displayDropdowns[key];
    }

    search(query?: string): void {
        this.loading = true;

        const params = this.paramsService.getOnboarding(this.filters, this.query || query, null);

        this.companyUserAccessManageProvider.getAvailableForCoach(this.company.id, params).subscribe((data) => {
            this.pagination = data.pagination;
            this.catalogue = data.contents;
            this.loading = false;
        });
    }

    loadMore(): void {
        this.loading = true;

        const params = this.paramsService.getOnboarding(this.filters, null, null, this.pagination);

        this.companyUserAccessManageProvider.getAvailableForCoach(this.company.id, params).subscribe((data) => {
            this.catalogue = this.catalogue.concat(data.contents);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    filter(provider: any): void {
        const params = {
            company: false
        };
        params[provider.id] = true;

        const filter = {
            providers: params
        };

        this.buildFilters.emit(filter);
    }

    isSessionSelected(session: any): boolean {
        return this.role.selectedSessions.findIndex((selection) => selection.data.id === session.data.id) !== -1;
    }

    updateSession(session?: any): void {
        if (!session) {
            this.role.status = false;
            this.onSkip.emit(true);
            return;
        }
        if (this.isSessionSelected(session)) {
            this.removeSession(session);
        } else {
            this.addSession(session);
        }
    }

    toggleSelection(selection) {
        if (selection) {
            this.cachedCatalogue = this.catalogue;
            this.catalogue = this.role.selectedSessions;
        } else {
            this.catalogue = this.cachedCatalogue;
        }
    }

    private addSession(session: any): void {
        this.role.selectedSessions.push(session);
    }

    public removeSession(session: any): void {
        const index = this.role.selectedSessions.findIndex((selection) => {
            return selection.data.id === session.data.id;
        });

        if (index !== -1) {
            this.role.selectedSessions.splice(index, 1);
        }
    }

    public isValid() {
        if (this.role.selectedSessions.length > 0) {
            this.onValidate.emit();
            return true;
        } else {
            return false;
        }
    }
}
