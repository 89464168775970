import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef } from '@angular/core';
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from './../../../../../tailwind.config.js';
export type MscPaletteTypes = 'slate' | 'primary' | 'secondary' | 'danger' | 'success' | 'error' | 'info';

@Component({
    selector: 'msc-common-empty',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonEmptyComponent {
    @Input() message?: string;
    @Input() palette: MscPaletteTypes = 'secondary';
    @ContentChild(TemplateRef) bodyTemplate: TemplateRef<any>;

    colors;

    constructor() {
        this.colors = resolveConfig(tailwindConfig).theme.colors;
    }
}
