<div class="dropdown dropdown-help dropdown-help--appcues active">
    <ul class="header__appcues" (click)="back()">
        <li>
            <i class="icon icon-arrow-left text-secondary-500"></i>
            {{ 'words.back' | translate }}
        </li>
    </ul>

    <ul *ngIf="guides && guides.length > 0">
        <li *ngFor="let guide of guides" (click)="startGuide(guide)">
            <i class="icon icon-arrow-right text-secondary-500"></i>

            <div class="dropdown-help__content">
                <div class="dropdown-help__title">
                    {{ guide[me.language.code] ? guide[me.language?.code].name : '-' }}
                </div>
            </div>
        </li>
    </ul>
</div>
