import { Component, ViewChild } from '@angular/core';
import { Company, CompanyUserProvider } from '@lighty';
import { ErrorStreamService, StorageService, TranslateService } from '@services';
import { ActivatedRoute } from '@angular/router';
import { HttpHelper } from '@helpers';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-account-access-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class AccountAccessSettingsComponent {
    @ViewChild('modalNotify') modalNotify: CommonModalComponent;
    private company: Company;
    public me: any;
    public user: any;
    public settings: any;
    public displays: any;
    public dates: any;
    public data: any = {
        email_status: 'auto',
        custom_message: ''
    };
    public savedDate: string;
    public dateUpdated: boolean;

    constructor(
        private route: ActivatedRoute,
        private companyUserProvider: CompanyUserProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.user = this.route.parent.parent.snapshot.data.user;
        this.settings = this.user.companyUser;
        this.savedDate = this.settings.accessStatus?.accessStartAt;

        this.data.email_status = this.user.invitation.emailStatus;
        this.data.custom_message = this.user.invitation.customMessage;

        this.dates = {
            now: moment(),
            tomorrow: moment().add(1, 'day')
        };

        this.displays = {
            startDate: false,
            endDate: false,
            notification: false
        };
    }

    getStatusColor(status: string): string {
        switch (status) {
            case 'inactive':
                return 'red';
            case 'reserved':
                return 'orange';
            default:
                return 'green';
        }
    }

    displayed(type: string, force: boolean = null): void {
        if (this.user.id !== this.me.id) {
            this.displays[type] = force !== null ? force : !this.displays[type];
        }
    }

    changed(key: string, value: string): void {
        this.data[key] = value;
    }

    updateDate(type: string, date: any): void {
        this.settings.accessStatus[type] = date.split('T')[0];
        this.dateUpdated = true;
    }

    resetDateInput(input: string): void {
        this.settings.accessStatus[input] = null;
        this.dateUpdated = true;
    }

    checkModal(): void {
        const startAtDate = this.settings.accessStatus?.accessStartAt;

        if (!startAtDate || startAtDate !== this.savedDate) {
            this.modalNotify.onOpen();
        } else {
            this.update();
        }
    }

    update(): void {
        this.errorStreamService.locked();
        const params = HttpHelper.cleanParams({
            access_start_at: this.settings.accessStatus.accessStartAt ? moment(this.settings.accessStatus.accessStartAt).format('YYYY-MM-DD') : null,
            access_end_at: this.settings.accessStatus.accessEndAt ? moment(this.settings.accessStatus.accessEndAt).format('YYYY-MM-DD') : null,
            email_status: this.data.email_status,
            custom_message: this.data.custom_message
        });

        this.companyUserProvider.update(this.company.id, this.user.id, params).subscribe((data) => {
            this.settings.accessStatus = data.accessStatus;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.errorStreamService.unlocked();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            this.errorStreamService.unlocked();
        });
    }

    getMostRecentDate(dateA, dateB){
        if(!dateA)
            return dateB;
        if(!dateB)
            return dateA;
        return moment(dateA).isAfter(dateB) ? dateA : dateB;
    }
}
