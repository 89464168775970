import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Pages

const routes: Routes = [];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class Coach2RoutingModule { }
