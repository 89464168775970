import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Company, DiscussionProvider, LineupProvider, MediaProvider, ReactionProvider, ApiDiscussionThread, ApiLineupSubject, ApiLineupSubjectKey, ApiLineupSubjectStatus, ApiDiscussionThreadReaction, ApiDiscussionSubject, ApiDiscussionSubjectStatus } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { TeamSortValues } from '@enums';

import { Subject, Observable, of, forkJoin, EMPTY } from 'rxjs';
import { map, concatMap, takeUntil } from 'rxjs/operators';

import { CommonFeedCommentDatum } from '@common/components/feed-comment/feed-comment.component';
import { COMMON_FEED_REACTIONS } from '@common/components/feed-reaction/feed-reaction.component';
import { CommonFeedThreadDatum } from '@common/components/feed-thread/feed-thread.component';
import { CommonFeedUserDatum } from '@common/components/feed-user/feed-user.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-manage-team-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ManageTeamTimelineComponent {
    public sourceThreads$: Observable<any>;
    public itemsThreads$: Observable<CommonFeedThreadDatum[]>;
    public statusThreads$: Observable<boolean>;
    public sourceUsers$: Observable<any>;
    public itemsOwner$: Observable<CommonFeedUserDatum[]>;
    public statusOwner$: Observable<boolean>;
    public pageOwner$: Observable<number>
    public itemsMember$: Observable<CommonFeedUserDatum[]>;
    public statusMember$: Observable<boolean>;
    public pageMember$: Observable<number>
    public selectedUser: any;
    public selectedThread: any;
    public isToggleAddMember: boolean = false;
    public itemsRawUsers: any;

    private company: Company;
    private me: any;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly datePipe: DatePipe,
        private readonly router: Router,
        private readonly discussionProvider: DiscussionProvider,
        private readonly lineupProvider: LineupProvider,
        private readonly mediaProvider: MediaProvider,
        private readonly reactionProvider: ReactionProvider,
        private readonly toastService: CommonToastService,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.sourceThreads$ = this.discussionProvider
            .getSubjectDiscussion()
            .pipe(takeUntil(this.destroy$));
        this.itemsThreads$ = this.sourceThreads$.pipe(map((r: ApiDiscussionSubject) => this.getThreadsRemapped(r.items)));
        this.statusThreads$ = this.sourceThreads$.pipe(map((r: ApiDiscussionSubject) => r.status !== ApiDiscussionSubjectStatus.SUCCESS));

        this.sourceUsers$ = this.lineupProvider
            .getSubjectLineup()
            .pipe(takeUntil(this.destroy$));
        this.itemsOwner$ = this.sourceUsers$.pipe(map((r: ApiLineupSubject) => this.getUsersRemapped(r[ApiLineupSubjectKey.OWNER].items)));
        this.statusOwner$ = this.sourceUsers$.pipe(map((r: ApiLineupSubject) => r[ApiLineupSubjectKey.OWNER].status !== ApiLineupSubjectStatus.SUCCESS));
        this.pageOwner$ = this.sourceUsers$.pipe(map((r: ApiLineupSubject) => r[ApiLineupSubjectKey.OWNER].page));

        this.itemsMember$ = this.sourceUsers$.pipe(map((r: ApiLineupSubject) => this.getUsersRemapped(r[ApiLineupSubjectKey.MEMBER].items)));
        this.statusMember$ = this.sourceUsers$.pipe(map((r: ApiLineupSubject) => r[ApiLineupSubjectKey.MEMBER].status !== ApiLineupSubjectStatus.SUCCESS));
        this.pageMember$ = this.sourceUsers$.pipe(map((r: ApiLineupSubject) => r[ApiLineupSubjectKey.MEMBER].page));

        this.lineupProvider.setSubjectLineup({ items: [], status: ApiLineupSubjectStatus.LOADING, page: 1 }, ApiLineupSubjectKey.OWNER);
        this.lineupProvider.setSubjectLineup({ items: [], status: ApiLineupSubjectStatus.LOADING, page: 1 }, ApiLineupSubjectKey.MEMBER);
        this.onQueryThreads();
        this.onQueryUsers();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Gets a new list of threads remapped to ui data
     */
    getThreadsRemapped(data: ApiDiscussionThread[]): CommonFeedThreadDatum[] {
        return data.map((datum) => ({
            comments: datum.comments && datum.comments.length && this.getCommentsRemapped(datum.comments),
            config: {
                hasDelete: this.getThreadHasDelete(datum),
                hasEdit: this.getThreadHasEdit(datum),
                hasNotify: this.getThreadHasNotify(datum),
            },
            contentImage: (datum.media && !datum.media.video) ? `${datum!.media!.pictureUrl}?size=512` : '',
            contentMessage: datum.content && datum.content.message,
            contentModified: this.getThreadContentModified(datum),
            contentReactions: this.getContentReactions(datum.reactions),
            contentTitle: datum.media && datum.media.title,
            contentUrl: this.getThreadContentUrl(datum),
            contentVideo: (datum.media && datum.media.video) ? `${datum!.media!.url}` : '',
            countComment: (datum.comments || []).length,
            countReaction: datum.reactions.reduce((acc, reaction) => acc + reaction.count, 0),
            dateCreation: datum.createdAt,
            id: datum.id,
            userAvatar: datum.account && datum.account.picture,
            userName: datum.account && datum.account.name,
            userReactionId: this.getUserReactionId(datum.reactions),
        }));
    }

    /**
     *
     */
    getThreadHasDelete(_datum: ApiDiscussionThread): boolean {
        return true;
    }

    /**
     *
     */
    getThreadHasEdit(datum: ApiDiscussionThread): boolean {
        if (this.me.id === datum.account.id) {
            return true;
        }
        return false;
    }

    /**
     *
     */
    getThreadHasNotify(_datum: ApiDiscussionThread): boolean {
        return true;
    }

    /**
     *
     */
    getThreadContentModified(datum: ApiDiscussionThread) {
        if (!datum.modified) { return; }
        return `${this.translateService.instant('words.updated-at')} : ${this.datePipe.transform(datum.modified, 'd/MM/YYYY HH:mm')}`;
    }

    /**
     * Gets a new list of comments remapped to ui data
     */
    getCommentsRemapped(data: Partial<ApiDiscussionThread>[]): CommonFeedCommentDatum[] {
        return data.map((datum) => ({
            contentMessage: datum.content && datum.content.message,
            dateCreation: datum.createdAt,
            id: datum.id,
            userAvatar: datum.account && datum.account.picture,
            userName: datum.account && datum.account.name,
        }));
    }

    /**
     * Gets the reaction Id of current user, if applicable
     */
    getUserReactionId(reactions: ApiDiscussionThreadReaction[]): number | null {
        const reactionApi = reactions.find((r) => r.accounts.find((account: any) => account.id = this.me.id));
        if (!reactionApi) { return; }
        const reactionUi = COMMON_FEED_REACTIONS.find((r) => r.type === reactionApi.type);
        if (!reactionUi) { return; }
        return reactionUi.id;
    }

    /**
     *
     */
    getThreadContentUrl(datum: ApiDiscussionThread) {
        switch (true) {
            case datum.media && datum.media.type === 'DOC':
            case datum.media && datum.media.type === 'PDF':
            case datum.media && datum.media.type === 'NONE':
            case datum.media && datum.media.type === 'SND':
                return datum.media.pictureUrl;
            default: return '';
        }
    }

    /**
     * Gets the reaction Id of current user, if applicable
     */
    getContentReactions(reactions: ApiDiscussionThreadReaction[]): string[] {
        const reactionTypes = reactions.reduce((acc, reaction) => {
            if (reaction.count) { acc.push(reaction.type); }
            return acc;
        }, []);
        return reactionTypes.map((type) => COMMON_FEED_REACTIONS.find((fr) => fr.type === type).name);
    }

    /**
     * Gets a list of users remapped to ui data
     */
    getUsersRemapped(data: any[]): CommonFeedUserDatum[] {
        this.itemsRawUsers = {
            managers: data.map((d) => d.account),
            learners: []
        };

        return data.map((datum) => ({
            datum,
            userAvatar: datum.account && datum.account.picture,
            userName: datum.account && datum.account.name,
            userRole: datum.account && datum.account.headline,
        }));
    }

    /**
     * Gets the params for getThreads api call
     */
    getParamsGetThreads() {
        return {
            context: 'group',
            context_id: this.me.teamOwnerId,
            company_id: this.company.id,
            group_id: this.me.teamOwnerId,
        };
    }

    /**
     * Gets the params for getUsers api call
     */
    getParamsGetUsers(role: ApiLineupSubjectKey) {
        return {
            page: 1,
            sort: 'asc',
            sortBy: TeamSortValues.NAME,
            role: role,
        };
    }

    /**
     * Get the params for postThread api call
     */
    getParamsPostThread(params: Partial<CommonFeedThreadDatum> & { media: any }) {
        return {
            company_id: this.company.id,
            content: {
                message: params.contentMessage,
                media_id: params.media ? params.media.id : null,
            },
            context: 'group',
            context_id: this.me.teamOwnerId,
            group_id: this.me.teamOwnerId,
            timeline_id: this.discussionProvider.getSubjectDiscussionId(),
            type: 'mixed',
        };
    }

    /**
     * Get the params for editThread api call
     */
    getParamsEditThread(params: Partial<CommonFeedThreadDatum> & { media: any }) {
        return this.getParamsPostThread(params);
    }

    /**
     * Get the params for postReaction api call
     */
    getParamsPostReaction(params: Partial<CommonFeedThreadDatum>, reactionId: number) {
        return {
            context: 'timeline_item',
            context_id: params.id,
            reaction_id: reactionId,
        };
    }

    /**
     * Get the params for deleteReaction api call
     */
    getParamsDeleteReaction(params: Partial<CommonFeedThreadDatum>, reactionId: number) {
        return this.getParamsPostReaction(params, reactionId);
    }

    /**
     * Get the params for postComment api call
     */
    getParamsPostComment(params: Partial<CommonFeedThreadDatum> & { media: any }) {
        return this.getParamsPostThread(params);
    }

    /**
     * Get an array as a prerequisite observable to onPostThread
     */
    getForkPostThread(params: any): Observable<any>[] {
        switch (true) {
            case !!params.file: return [this.mediaProvider.uploadMedia(params.file)];
            case !!params.url: return [this.mediaProvider.uploadEmbed({ url: params.url })];
            default: return [of(null)];
        }
    }

    /**
     *
     */
    getOnQueryUsersCall(role?: ApiLineupSubjectKey | string) {
        switch (role) {
            case ApiLineupSubjectKey.MEMBER:
                return [
                    this.lineupProvider.getUsers(this.company.id, this.me.teamOwnerId, this.getParamsGetUsers(ApiLineupSubjectKey.MEMBER), ApiLineupSubjectKey.MEMBER),
                ];
            case ApiLineupSubjectKey.OWNER:
                return [
                    this.lineupProvider.getUsers(this.company.id, this.me.teamOwnerId, this.getParamsGetUsers(ApiLineupSubjectKey.OWNER), ApiLineupSubjectKey.OWNER),
                ];
            default: return [
                this.lineupProvider.getUsers(this.company.id, this.me.teamOwnerId, this.getParamsGetUsers(ApiLineupSubjectKey.OWNER), ApiLineupSubjectKey.OWNER),
                this.lineupProvider.getUsers(this.company.id, this.me.teamOwnerId, this.getParamsGetUsers(ApiLineupSubjectKey.MEMBER), ApiLineupSubjectKey.MEMBER),
            ];
        }
    }

    /**
     * Resolves a list of threads
     */
    onQueryThreads(): void {
        this.discussionProvider
            .getThreads(this.getParamsGetThreads())
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    /**
     * Resolves a list of users
     */
    onQueryUsers(role?: ApiLineupSubjectKey | string): void {
        forkJoin(this.getOnQueryUsersCall(role))
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    /**
     *
     */
    onPostThread(params: any): void {
        forkJoin(this.getForkPostThread(params))
            .pipe(
                concatMap(([media]) => this.discussionProvider.postThread(this.getParamsPostThread({ ...params, media }))),
                takeUntil(this.destroy$)
            )
            .subscribe(
                () => this.onPostThreadSuccess(),
                (error: HttpErrorResponse) => this.onPostThreadError(error),
            );
    }

    /**
     * Event handler for onPostThread success
     */
    onPostThreadSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.timeline.thread.add'));
    }

    /**
     * Event handler for onPostThread error
     */
    onPostThreadError(error: HttpErrorResponse): void {
        this.toastService.onSuccess(this.translateService.instant('toast.error-occurred', error.message));
    }

    /**
     *
     */
    onDeleteThread(datum: CommonFeedThreadDatum): void {
        this.discussionProvider
            .deleteThread(datum.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onDeleteThreadSuccess(),
                (error: HttpErrorResponse) => this.onDeleteThreadError(error),
            );
    }

    /**
     * Event handler for onDeleteThread success
     */
    onDeleteThreadSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.timeline.thread.delete'));
    }

    /**
     * Event handler for onDeleteThread error
     */
    onDeleteThreadError(error: HttpErrorResponse): void {
        this.toastService.onSuccess(this.translateService.instant('toast.error-occurred', error.message));
    }

    /**
     *
     */
    onEditThread(datum: CommonFeedThreadDatum, params: Partial<CommonFeedThreadDatum> & { media: any }): void {
        this.discussionProvider
            .editThread(datum.id, this.getParamsEditThread(params))
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onEditThreadSuccess(),
                (error: HttpErrorResponse) => this.onEditThreadError(error),
            );
    }

    /**
     * Event handler for onEditThread success
     */
    onEditThreadSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.timeline.thread.edit'));
    }

    /**
     * Event handler for onEditThread error
     */
    onEditThreadError(error: HttpErrorResponse): void {
        this.toastService.onSuccess(this.translateService.instant('toast.error-occurred', error.message));
    }

    /**
     *
     */
    onNotifyThread(): void {
        this.discussionProvider
            .notifyThread(this.selectedThread.id, { company_id: this.company.id, group_id: this.me.teamOwnerId })
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.selectedThread = null);
    }

    /**
     *
     */
    onNotifyOpenModal(modal: CommonModalComponent, datum: CommonFeedThreadDatum): void {
        modal.onOpen();
        this.selectedThread = datum;
    }

    /**
     *
     */
    onPostReaction(datum: CommonFeedCommentDatum, reactionId: number): void {
        this.reactionProvider
            .create(this.getParamsPostReaction(datum, reactionId))
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.discussionProvider.onUpdateReaction(datum, reactionId, true);
                this.onQueryThreads();
            });
    }

    /**
     *
     */
    onDeleteReaction(datum: CommonFeedCommentDatum, reactionId: number): void {
        this.reactionProvider
            .delete(this.getParamsDeleteReaction(datum, reactionId))
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.discussionProvider.onUpdateReaction(datum, reactionId, false);
                this.onQueryThreads();
            });
    }

    /**
     *
     */
    onPostComment(datum: CommonFeedCommentDatum, params: any): void {
        this.discussionProvider
            .postComment(datum.id, this.getParamsPostComment(params))
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onPostCommentSuccess(),
                (error: HttpErrorResponse) => this.onPostCommentError(error),
            );
    }

    /**
     * Event handler for onPostComment success
     */
    onPostCommentSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.timeline.comment.add'));
    }

    /**
     * Event handler for onPostComment error
     */
    onPostCommentError(error: HttpErrorResponse): void {
        this.toastService.onSuccess(this.translateService.instant('toast.error-occurred', error.message));
    }

    /**
     *
     */
    onDeleteComment(comment: CommonFeedCommentDatum): void {
        this.discussionProvider
            .deleteComment(comment.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onDeleteCommentSuccess(),
                (error: HttpErrorResponse) => this.onDeleteCommentError(error),
            );
    }

    /**
     * Event handler for onDeleteComment success
     */
    onDeleteCommentSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.timeline.comment.delete'));
    }

    /**
     * Event handler for onDeleteComment error
     */
    onDeleteCommentError(error: HttpErrorResponse): void {
        this.toastService.onSuccess(this.translateService.instant('toast.error-occurred', error.message));
    }

    /**
     *
     */
    onUserChat(user: CommonFeedUserDatum): void {
        this.storageService.set('conversationUsers', [user.datum.account]);
        this.router.navigate(['conversation/create']);
    }

    /**
     *
     */
    onUserEmail(user: CommonFeedUserDatum): void {
        this.selectedUser = user && user.datum && user.datum.account || null;
    }

    /**
     *
     */
    onToggleAddUser(): void {
        this.isToggleAddMember = !this.isToggleAddMember;
    }
}
