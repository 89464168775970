import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminCorrectionProvider, Pagination, Camp, Company } from '@lighty';
import { StorageService } from '@services';

@Component({
    selector: 'msc-camp-correction',
    templateUrl: './correction.component.html',
    styleUrls: ['./correction.component.scss']
})
export class CampCorrectionComponent implements OnInit {
    private currentTab: string = 'question';
    private camp: Camp;
    private company: Company;
    public counter: any;
    public questions: any[];
    public projects: any[];
    public selfassessments: any[];
    public currentQuestion: any;
    public currentProject: any;
    public currentSelfAssessment: any;
    public questionsPagination: Pagination;
    public projectsPagination: Pagination;
    public selfassessmentsPagination: Pagination;
    public loading: boolean = false;

    constructor(private route: ActivatedRoute, private correctionProvider: AdminCorrectionProvider, private storageService: StorageService) {}

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');
        this.company = this.storageService.get('company');

        this.counter = this.route.snapshot.data.corrections[0].count;
        this.questions = this.route.snapshot.data.corrections[0].sessions;
        this.questionsPagination = this.route.snapshot.data.corrections[0].pagination;
        this.projects = this.route.snapshot.data.corrections[1].sessions;
        this.projectsPagination = this.route.snapshot.data.corrections[1].pagination;
        this.selfassessments = this.route.snapshot.data.corrections[2].sessions;
        this.selfassessmentsPagination = this.route.snapshot.data.corrections[2].pagination;
    }

    setTab(tab: string): void {
        this.currentTab = tab;
        this.currentQuestion = this.currentProject = this.currentSelfAssessment =  null;
    }

    isTab(tab: string): boolean {
        return this.currentTab === tab;
    }

    selectItem(type: string, item: any): void {
        (type === 'question') ? this.currentQuestion = item : type === 'project' ? this.currentProject = item : this.currentSelfAssessment = item;
    }

    loadMore(): void {
        this.loading = true;
        let params: any;

        if (this.isTab('question')) {
            params = {
                camp_id: this.camp.id,
                company_id: this.company.id,
                type: 'question',
                page: this.questionsPagination.currentPage + 1
            };
        } else if (this.isTab('project')) {
            params = {
                camp_id: this.camp.id,
                company_id: this.company.id,
                type: 'project',
                page: this.projectsPagination.currentPage + 1
            };
        } else {
            params = {
                camp_id: this.camp.id,
                company_id: this.company.id,
                type: 'selfassessment',
                page: this.selfassessmentsPagination.currentPage + 1
            };
        }

        this.correctionProvider.getCorrections(params).subscribe((data) => {
            if (this.isTab('question')) {
                this.questionsPagination = data.pagination;
                this.questions = this.questions.concat(data.sessions);
            } else if (this.isTab('project')) {
                this.projectsPagination = data.pagination;
                this.projects = this.projects.concat(data.sessions);
            } else {
                this.selfassessmentsPagination = data.pagination;
                this.selfassessments = this.selfassessments.concat(data.sessions);
            }
            this.loading = false;
        });
    }

    getCurrentPagination(): Pagination {
        if (this.currentTab === 'question') {
            return this.questionsPagination;
        } else if (this.currentTab === 'project') {
            return this.projectsPagination;
        } else {
            return this.selfassessmentsPagination;
        }
    }

    refresh(type: string): void {
        const params = {
            camp_id: this.camp.id,
            company_id: this.company.id,
            type
        };

        this.correctionProvider.getCorrections(params).subscribe((data) => {
            if (type === 'question') {
                this.questions = data.sessions;
            } else if (type === 'project') {
                this.projects = data.sessions;
            } else {
                this.selfassessments = data.sessions;
            }
        });
    }
}
