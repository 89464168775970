import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination, IntegrationProvider } from '@lighty';
import { ActivatedRoute } from '@angular/router';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-organize-integration-watch-rules-edit-selection-panel-manual',
    templateUrl: './selection-panel-manual.component.html',
    styleUrls: ['./selection-panel-manual.component.scss']
})
export class OrganizeIntegrationWatchRulesEditSelectionPanelManualComponent implements OnInit {
    @Output() allowNavigation: EventEmitter<any> = new EventEmitter<any>();
    @Input() rule: any;
    @Input() excludeMode: boolean = false;
    private companyId: number;
    private providerId: number;
    private collectionId: number;
    private categoryIds: number[] = [];
    private subcategoryIds: number[] = [];
    private languageIds: number[] = [];
    public query: string;
    public loading: boolean = false;
    public pagination: Pagination;
    public loaders: any = {
        contents: false
    };
    public contents: any[];
    public onlySelected: boolean = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly integrationProvider: IntegrationProvider,
        private readonly storageService: StorageService,
        private readonly translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        if (!this.rule.contents) {
            this.rule.contents = [];
        }
        this.companyId = this.storageService.get('company').id;
        this.providerId = this.storageService.get('currentRuleProvider').id;
        this.collectionId = this.route.snapshot.data.collection.collectionId;
        this.loaders.contents = true;

        if (this.excludeMode) {
            for (const category of this.rule.selectedCategories) {
                this.categoryIds.push(category.id);
            }
            for (const subcategory of this.rule.selectedSubcategories) {
                this.subcategoryIds.push(subcategory.id);
            }
            for (const language of this.rule.selectedLanguages) {
                this.languageIds.push(language.id);
            }
        }

        const params = HttpHelper.cleanParams({
            categoryIds: this.categoryIds,
            subcategoryIds: this.subcategoryIds,
            languageIds: this.languageIds
        });

        this.integrationProvider.getCollectionContents(this.companyId, this.providerId, this.collectionId, params).subscribe(data => {
            this.pagination = data.pagination;
            this.contents = this.getContentsRemapped(data.contents);
            this.rule.allContent = false;
            this.loaders.contents = false;

            for (const content of this.contents) {
                if (this.excludeMode) {
                    const index = this.rule.excludedContents.findIndex(ruleContent => ruleContent.id === content.id);
                    if (index !== -1) {
                        content.excluded = true;
                        const index = this.contents.findIndex(ctn => ctn.id === content.id);
                        this.contents.unshift(content);
                        this.contents.splice(index + 1, 1);
                    }
                } else {
                    this.rule.excludedContents = [];
                    const index = this.rule.contents.findIndex(ruleContent => ruleContent.id === content.id);
                    if (index !== -1) {
                        content.checked = true;
                    }
                }
            }
        });

        this.checkNavigation();
    }

    getContentsRemapped(data: Array<any>) {
        return data.map((datum) => {
            const mediaUrl = this.getMediaUrl(datum);
            const uiTypeLabel = this.getUiTypeLabel(datum);
            return { ...datum, uiTypeLabel, mediaUrl };
        });
    }

    getMediaUrl(datum: any) {
        switch (true) {
            case datum.hasOwnProperty('media') && !!datum.media: return `url(${datum.media.pictureUrl}?size=256)`;
            case datum.hasOwnProperty('picture') && !!datum.picture: return `url(${datum.picture}?size=256)`;
            default: return `url('https://static.myskillcamp.com/lxp/images/logo-empty-state-${datum.id % 7}.jpg')`;
        }
    }

    getUiTypeLabel(datum: any) {
        switch (true) {
            case datum.hasOwnProperty('isTraject') && datum.isTraject: return this.translateService.instant('api.content-type.traject');
            case !datum.hasOwnProperty('isTraject') && datum.hasOwnProperty('type') && !!datum.type: return this.translateService.instant(`api.content-type.${datum.type}`);
            default: return this.translateService.instant('api.content-type.course');
        }
    }

    search(query?): void {
        this.query = query;
        if (this.onlySelected) {
            if (this.excludeMode) {
                this.contents = this.rule.contents.filter(content => content.title.toLowerCase().includes(this.query.toLowerCase()));
                for (const content of this.contents) {
                    content.checked = true;
                }
            } else {
                this.contents = this.rule.excludedContents.filter(content => content.title.toLowerCase().includes(this.query.toLowerCase()));
                for (const content of this.contents) {
                    content.excluded = true;
                    const index = this.contents.findIndex(ctn => ctn.id === content.id);
                    this.contents.unshift(content);
                    this.contents.splice(index + 1, 1);
                }
            }
        } else {
            const params = HttpHelper.cleanParams({
                q: this.query,
                categoryIds: this.excludeMode ? this.categoryIds : null,
                subcategoryIds: this.excludeMode ? this.subcategoryIds : null,
                languageIds: this.excludeMode ? this.languageIds : null,
            });
            this.integrationProvider.getCollectionContents(this.companyId, this.providerId, this.collectionId, params).subscribe(data => {
                this.pagination = data.pagination;
                this.contents = this.getContentsRemapped(data.contents);
                this.rule.allContent = false;
                this.loaders.contents = false;

                for (const content of this.contents) {
                    if (this.excludeMode) {
                        const index = this.rule.excludedContents.findIndex(ruleContent => ruleContent.id === content.id);
                        if (index !== -1) {
                            content.excluded = true;
                        }
                    } else {
                        const index = this.rule.contents.findIndex(ruleContent => ruleContent.id === content.id);
                        if (index !== -1) {
                            content.checked = true;
                        }
                    }
                }
            });
        }
    }

    loadMore(): void {
        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.currentPage + 1,
            categoryIds: this.excludeMode ? this.categoryIds : null,
            subcategoryIds: this.excludeMode ? this.subcategoryIds : null,
            languageIds: this.excludeMode ? this.languageIds : null,
        });
        this.integrationProvider.getCollectionContents(this.companyId, this.providerId, this.collectionId, params).subscribe(data => {
            this.pagination = data.pagination;
            for (const content of this.getContentsRemapped(data.contents)) {
                this.contents.push(content);
                if (this.rule.allContent) {
                    if (this.excludeMode) {
                        content.excluded = true;
                        const index = this.contents.findIndex(ctn => ctn.id === content.id);
                        this.contents.unshift(content);
                        this.contents.splice(index + 1, 1);
                    } else {
                        content.checked = true;
                    }
                }
            }
            this.loaders.contents = false;

            for (const content of this.contents) {
                if (this.excludeMode) {
                    const index = this.rule.excludedContents.findIndex(excludedContent => content.id === excludedContent.id);
                    if (index !== -1) {
                        content.excluded = true;
                    }
                } else {
                    const index = this.rule.contents.findIndex(ruleContent => ruleContent.id === content.id);
                    if (index !== -1) {
                        content.checked = true;
                    }
                }
            }
        });
    }

    showMySelection(): void {
        if (!this.excludeMode) {
            this.onlySelected = !this.onlySelected;
            this.loaders.contents = true;
            if (((this.rule.contents.length === 0 && !this.excludeMode) || (this.rule.excludedContents.length === 0 && this.excludeMode)) && this.onlySelected) {
                this.contents = [];
                this.pagination = null;
            }
            else {
                if (this.onlySelected) {
                    if (this.excludeMode) {
                        this.contents = this.rule.excludedContents.filter(content => content.title.toLowerCase().includes(this.query?.toLowerCase() || ''));
                        for (const content of this.contents) {
                            content.excluded = true;
                        }
                    } else {
                        this.contents = this.rule.contents.filter(content => content.title.toLowerCase().includes(this.query?.toLowerCase() || ''));
                        for (const content of this.contents) {
                            content.checked = true;
                        }
                    }
                    this.pagination = null;
                } else {
                    const params = HttpHelper.cleanParams({
                        q: this.query,
                        categoryIds: this.excludeMode ? this.categoryIds : null,
                        subcategoryIds: this.excludeMode ? this.subcategoryIds : null,
                        languageIds: this.excludeMode ? this.languageIds : null,
                    });
                    this.integrationProvider.getCollectionContents(this.companyId, this.providerId, this.collectionId, params).subscribe(data => {
                        this.loaders.contents = false;
                        this.contents = data.contents;
                        this.pagination = data.pagination;

                        for (const content of this.contents) {
                            if (this.excludeMode) {
                                const index = this.rule.excludedContents.findIndex(ruleContent => ruleContent.id === content.id);
                                if (index !== -1) {
                                    content.excluded = true;
                                }
                            } else {
                                const index = this.rule.contents.findIndex(ruleContent => ruleContent.id === content.id);
                                if (index !== -1 || this.rule.allContent) {
                                    content.checked = true;
                                }
                            }
                        }
                    });
                }
            }
        }
    }

    checkContent(content: any): void {
        if (this.excludeMode) {
            content.excluded = !content.excluded;
            if (content.excluded) {
                this.rule.excludedContents.push(content);
                const index = this.contents.findIndex(ctn => ctn.id === content.id);
                const lastExcluded = this.contents.filter(content => content.excluded).length;

                this.contents.splice(lastExcluded - 1, 0, content)
                this.contents.splice(index + 1, 1);
            } else {
                const index = this.rule.excludedContents.findIndex(excludedContent => excludedContent.id === content.id);
                this.rule.excludedContents.splice(index, 1);

                const currIndex = this.contents.findIndex(ctn => ctn.id === content.id);
                this.contents.splice(currIndex, 1);
                const lastExcluded = this.contents.filter(content => content.excluded).length;
                this.contents.splice(lastExcluded + 1, 0, content);
            }

            return;
        }

        content.checked = !content.checked;
        if (this.rule.allContent) {
            if (!content.checked) {
                this.rule.excludedContents.push(content.id);
            } else {
                const index = this.rule.excludedContents.findIndex(id => id === content.id);
                this.rule.excludedContents.splice(index, 1);
            }
        } else {
            if (content.checked) {
                this.rule.contents.push(content);
            } else {
                const index = this.rule.contents.findIndex(ruleContent => ruleContent.id === content.id);
                this.rule.contents.splice(index, 1);
            }
        }

        this.checkNavigation();
    }

    checkNavigation(): void {
        if (this.excludeMode) {
            this.allowNavigation.emit(true);
            return;
        }
        if (this.rule.contents.length > 0 || this.rule.allContent) {
            this.allowNavigation.emit(true);
            return;
        }

        this.allowNavigation.emit(false);
    }

    onSelectAll(): void {
        this.rule.contents = [];
        if (this.rule.allContent) {
            for (const content of this.contents) {
                content.checked = true;
            }
        } else {
            for (const content of this.contents) {
                content.checked = false;
            }
        }

        this.checkNavigation();
    }

    getCategoriesName(content: any) {
        const tooltipCategories = [...content.categories];
        tooltipCategories.shift();
        return tooltipCategories.map((category: any) => category.name).join(', ').toString();
    }
}
