import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChildren, QueryList, TemplateRef } from '@angular/core';

export interface NavigationObject {
    label: string,
    isHidden: boolean,
    routerLink: Array<any>,
    iconLeft?: string,
    iconRight?: string,
    callback?: CallableFunction
}
@Component({
    selector: 'msc-common-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonTabComponent {
    @Input() index: number = 0;
    @Input() navigation: NavigationObject;
    @Output() selectEvent: EventEmitter<any> = new EventEmitter<any>();
    @ContentChildren('tabControl') tabControl: QueryList<TemplateRef<any>>;
    @ContentChildren('tabPanel') tabPanel: QueryList<TemplateRef<any>>;

    get templateListTabControl() { return this.tabControl?.toArray() || []; }
    get templateListTabPanel() { return this.tabPanel?.toArray() || []; }

    /**
     * Event handler for select
     */
    onSelect(idx: number): void {
        this.index = idx;
        this.selectEvent.emit();
    }
}
