<div class="feed-comment-head">
    <div class="wrapper">
        <msc-common-avatar [label]="datum.userName" [pictureURL]="datum.userAvatar"></msc-common-avatar>
        <div class="grouping">
            <span class="name">{{ datum.userName }}</span>
            <span class="date">{{ datum.dateCreation | timeAgo }}</span>
        </div>
    </div>

    <div class="wrapper">
        <msc-button type="fab" palette="danger" icon="icon-trash" (click)="onDelete()"></msc-button>
    </div>
</div>

<div class="feed-comment-body">
    <p class="content-message">{{ datum.contentMessage }}</p>
</div>

<div class="feed-comment-footer">
    <!-- // NOTE future use? -->
</div>
