import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'msc-correction-activity-aside',
    templateUrl: './correction-activity-aside.component.html'
})
export class ManageInstanceCorrectionActivityAsideComponent implements OnInit {
    @ViewChild('aside') aside;
    @Input() activity: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit(): void { }

    onOpen() {
        this.aside.onOpen();
    }

    close(): void {
        this.onClose.emit();
    }
}
