<msc-common-aside #aside
    [title]="(link ? (type === 'url' ? 'integrations.add-link' : 'lti.settings') : (type === 'url' ? 'integrations.add-link' : 'lti.add')) | translate">
    <ng-template #asideTemplate>
        <div class="slide__form">
            <div class="slide__ariane" *ngIf="type === 'lti'">
                <span class="slide__ariane__step" *ngFor="let navigation of navigations; let index=index"
                    (click)="onArianeClick(index)"
                    [ngClass]="{ 'disabled': !navigation.unlocked, 'slide__ariane__step--current': index === navigationIndex, 'slide__ariane__step--success': index < navigationIndex}">
                    <div [tooltip]="navigation.name | translate" direction="bottom"></div>
                </span>
            </div>

            <div *ngIf="navigationIndex === 0">
                <div class="slide__input">
                    <div class="flex space-x-2 items-end">
                        <span class="input__favicon" *ngIf="favicon">
                            <img [src]="favicon" alt="Favicon">
                        </span>

                        <msc-input class="grow" type="text"
                            [label]="(type === 'url' ? 'integrations.url' : 'lti.url') | translate"
                            [(ngModel)]="data.url" [debounce]="800" (onDebounce)="getFavicon()"></msc-input>
                    </div>
                </div>

                <div class="slide__input">
                    <msc-input type="text"
                        [label]="(type === 'url' ? 'integrations.link-name' : 'lti.title') | translate"
                        [(ngModel)]="data.name" (focus)="lockName()" (blur)="lockName(false)"></msc-input>
                </div>

                <div class="slide__input slide__input-media">
                    <label class="slide__label">{{ 'integrations.link-picture' | translate }}</label>

                    <div class="upload-media">
                        <div class="upload-media__container">
                            <a class="upload-media__delete" *ngIf="picture"
                                (click)="resetPicture(); stopPropagation($event)">
                                <i class="icon icon-close"></i>
                            </a>

                            <div *ngIf="picture" class="upload-media__image"
                                [style.backgroundImage]="'url('+picture+')'"
                                [ngClass]="{'upload-media__image--full': file}"></div>
                        </div>

                        <div class="flex flex-col space-y-4">
                            <msc-button class="msc-button--outline" palette="secondary" icon="icon-image"
                                (click)="uploadPicture()">
                                {{ 'words.upload' | translate }}
                            </msc-button>

                            <input #upload class="hidden" type="file" accept="image/png,image/jpeg,image/jpg,image/gif"
                                (change)="loadPicture($event.target.files[0])">
                            <small> {{ 'words.upload-info.256' | translate }} </small>
                        </div>
                    </div>
                </div>

                <div class="slide__input" *ngIf="type === 'lti'">
                    <msc-input [label]="'lti.redirect' | translate" type="text" [(ngModel)]="data.redirectUrl">
                    </msc-input>
                </div>

                <div class="slide__input" *ngIf="type === 'lti'">
                    <div class="input form-select">
                        <label class="input__label">{{ 'lti.mode' | translate }}</label>

                        <div class="select">
                            <select name="select" id="mode" [(ngModel)]="data.mode">
                                <option value="embed">{{ 'lti.mode-embed' | translate }}</option>
                                <option value="blank">{{ 'lti.mode-window' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="slide__input" *ngIf="type === 'lti'">
                    <msc-input [label]="'lti.private-key' | translate" type="text" [(ngModel)]="data.key">
                    </msc-input>
                </div>

                <div class="slide__input" *ngIf="type === 'lti'">
                    <msc-input [label]="'lti.secret-key' | translate" type="text" [(ngModel)]="data.secret">
                    </msc-input>
                </div>
            </div>

            <div class="flex flex-col py-4 space-y-4" *ngIf="navigationIndex === 1">
                <div class="flex space-x-2">
                    <msc-common2-checkbox [value]="data.anonymize" (changeEvent)="updateAnonymise()">
                    </msc-common2-checkbox>
                    <span>{{ (modifyAnonymise ? 'lti.anonymise-change' : 'lti.anonymise') | translate }}</span>
                </div>

                <div class="flex flex-col space-y-2 my-2">
                    <msc-common2-radio groupName="allCampsAccess" [label]="'integrations.playlist.all' | translate"
                        [(ngModel)]="data.allCamps" [valueName]="true"></msc-common2-radio>
                    <msc-common2-radio groupName="allCampsAccess" [label]="'integrations.playlist.camp' | translate"
                        [(ngModel)]="data.allCamps" [valueName]="false"></msc-common2-radio>
                </div>

                <div class="flex flex-col space-y-2" *ngIf="!data.allCamps">
                    <msc-common-search [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)">
                    </msc-common-search>

                    <span>{{ 'access.learn.camps-list' | translate }}</span>
                    <div class="overflow-y-auto">
                        <div class="list-camp__list" *ngIf="camps?.length > 0">
                            <div class="card-camp card-camp--flat card-camp--no-effect" *ngFor="let camp of camps">
                                <div class="card-camp__content">
                                    <a class="content__action" (click)="removeCamp(camp); stopPropagation($event)">
                                        <i class="icon icon-minus"></i>
                                    </a>

                                    <div class="content__logo">
                                        <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                                    </div>

                                    <div class="content__data">
                                        <p>{{ camp.name | truncate: 30 }}</p>

                                        <div class="data__pictos">
                                            <div class="data__members">
                                                <i class="icon icon-people"></i>
                                                <p>{{ camp.usersCount }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list-camp__list">
                            <div class="card-camp card-camp--flat card-camp--no-effect"
                                *ngFor="let camp of availableCamps">
                                <div class="card-camp__content">
                                    <a class="content__action content__action--blue bg-secondary-500"
                                        (click)="addCamp(camp); stopPropagation($event)">
                                        <i class="icon icon-plus"></i>
                                    </a>

                                    <div class="content__logo">
                                        <i class="icon icon-logo-myskillcamp bg-primary-500"></i>
                                    </div>

                                    <div class="content__data">
                                        <p>{{ camp.name | truncate: 30 }}</p>

                                        <div class="data__pictos">
                                            <div class="data__members">
                                                <i class="icon icon-people"></i>
                                                <p>{{ camp.usersCount }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <msc-button (click)="aside.onClose()">{{'common.cancel' | translate}}
        </msc-button>
        <msc-button class="msc-button--primary" palette="secondary" (click)="save()" *ngIf="type === 'url'">
            {{ (link ? 'words.edit' : 'words.add') | translate }}
        </msc-button>

        <msc-button class="msc-button--primary" palette="secondary" (click)="manageSave()" *ngIf="type === 'lti'">
            {{ (navigationIndex === 0 ? 'words.continue' : 'words.save') | translate }}
        </msc-button>
    </ng-template>
</msc-common-aside>
<msc-common-modal size="30vw" #modalConfirm [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'lti.modal.confirm' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button (click)="modalConfirm.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="saveLTI(); modalConfirm.onClose()">
                {{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>