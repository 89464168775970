<div class="conversation__chat">
    <div class="chat__back">
        <a (click)="back()">
            <i class="icon icon-arrow-ios-left"></i>
            {{ 'words.back' | translate }}
        </a>
    </div>

    <div class="chat__content-header">
        <div class="chat__member">
            <div class="chat__profile" *ngIf="members?.length > 0" >
                <div class="flex items-center space-x-2">
                    <msc-common-avatar [label]="members[0]?.name"
                        [pictureURL]="members[0]?.picture ? (members[0]?.picture + '?size=128') : null">
                    </msc-common-avatar>

                    <p class="ml-2">{{ members[0]?.name }}</p>
                </div>
            </div>

            <div class="chat__add-member">
                <ul class="avatar-group">
                    <li *ngFor="let correspondent of correspondents | slice:0:4">
                        <a [tooltip]="correspondent?.name">
                            <msc-common-avatar class="tiny" [label]="correspondent?.name"
                                [pictureURL]="correspondent?.picture ? (correspondent?.picture + '?size=64') : null">
                            </msc-common-avatar>
                        </a>
                    </li>

                    <li *ngIf="correspondents.length > 4">
                        <span class="avatar-group__text text-primary-500">+ {{ correspondents.length - 4 }}</span>
                    </li>
                </ul>

                <div class="dropdown-search-member" [ngClass]="{'active': displaySearch}" [(autoClose)]="displaySearch">
                    <div class="dropdown-search-member__selected">
                        <div class="dropdown-search-member__text">
                            <a class="link link__add-member" (click)="activeSearch()">
                                <i class="icon icon-plus-1"></i>
                                <span>{{ 'conversation.add-member' | translate }}</span>
                            </a>
                        </div>
                    </div>

                    <div class="dropdown-search-member__content">
                        <div class="dropdown-search-member__form">
                            <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
                        </div>

                        <ul class="dropdown-search-member__list" *ngIf="searchMembersCollection">
                            <li class="dropdown-search-member__item" *ngFor="let member of searchMembersCollection">
                                <div class="avatar-profile">
                                    <msc-common-avatar class="tiny mr-2" [label]="member?.name"
                                        [pictureURL]="member?.picture ? (member?.picture + '?size=64') : null">
                                    </msc-common-avatar>
                                    <p>{{ member.name }}</p>
                                </div>

                                <a class="bg-primary-500" (click)="addMember(member); $event.stopPropagation()">
                                    <i class="icon icon-plus"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="chat__action" (click)="leave()" *ngIf="conversation.id">
            <a class="link link__leave-chat">
                <i class="icon icon-trash-2"></i>

                <span>{{ 'conversation.delete' | translate }}</span>
            </a>
        </div>
    </div>

    <div class="chat__discussion" #simpleBarElement data-simplebar>
        <div class="content__chat" *ngFor="let message of conversation.messages; let index=index" [ngClass]="{'content__chat--sender': message.account.id === me.id}">
            <div class="message__author">
                <msc-common-avatar [label]="message.account?.name"
                    [pictureURL]="message.account?.picture ? (message.account?.picture + '?size=128') : null">
                </msc-common-avatar>

                <div class="author__details">
                    <p class="detail__name">{{ message.account.name }}</p>
                    <p class="detail__date">{{ message.createdAt | timeAgo }}</p>
                    <div *ngIf="isConnected(message.account.id)" [ngClass]="{'online': isConnected(message.account.id)}"></div>
                </div>
            </div>

            <div class="message" *ngIf="message.content | nl2br | url">
                <div class="message__content">
                    <p [innerHtml]="message.content | nl2br | url"></p>
                </div>
            </div>

            <div class="resources" *ngIf="message.media.length > 0">
                <div class="resources__preview resources__preview--file resources__preview--hover" *ngIf="message.media[0].type === 'DOC' || message.media[0].type === 'PDF' || message.media[0].type === 'SND'">
                    <msc-shared-resource [media]="message.media[0]"></msc-shared-resource>
                </div>

                <div class="resources__preview resources__preview--embed" [ngClass]="{'resources__preview--hover': message.media[0].type !== 'VID'}" *ngIf="message.media[0].type === 'EMBED' || message.media[0].type === 'VID' || message.media[0].type === 'IMG'">
                    <iframe [src]="getEmbed(message.media[0].url, index)" *ngIf="message.media[0].type === 'EMBED'" allow="fullscreen" allowfullscreen></iframe>

                    <msc-vimeo [media]="message.media[0]" *ngIf="message.media[0].type === 'VID'"></msc-vimeo>

                    <div class="picture" [style.backgroundImage]="'url(' + message.media[0].pictureUrl + '?size=512)'" *ngIf="message.media[0].type === 'IMG'" (click)="modalPicture.onOpen(); openPicture(message.media[0])"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="chat__writing" *ngIf="writing">
        <p> {{ writing }} {{ 'conversation.is-writing' | translate }} </p>
    </div>

    <div class="chat__composer">
        <msc-composer [grey]="true"
                      [me]="me"
                      [unlock]="unlock"
                      [(message)]="content.message"
                      (messageChange)="typing(true)"
                      (messageDebounced)="typing(false)"
                      (messageSended)="send($event)">
        </msc-composer>
    </div>
</div>

<msc-common-modal size="70vw" #modalPicture>
    <ng-template>
        <div class="flex justify-center">
            <img [src]="selectedPicture.pictureUrl + '?size=512'">
        </div>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalPicture.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="closePicture(); modalPicture.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
