import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
//
import { TimelineProvider, Media, ReactionProvider, MediaProvider } from '@lighty';
import { EmitterService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { DataHelper, DownloadHelper, HttpHelper } from '@helpers';
//
import Lottie from 'lottie-web';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-timeline-post',
    templateUrl: './timeline-post.component.html',
    styleUrls: ['./timeline-post.component.scss'],
    providers: [DatePipe],
})

export class TimelinePostComponent {
    @Input() context: string;
    @Input() contextId: number;
    @Input() customParams: any;
    @Input() displayCamp: boolean = false;
    @Input() isAdmin: boolean;
    @Input() manage: boolean = false;
    @Input() me: any;
    @Input() post: any;
    @Input() small: boolean = false;
    @Input() timelineId: number;
    @Output() onDelete: EventEmitter<any> = new EventEmitter();

    private animationCollection: any[] = [];
    private companyId: number;
    private embed: SafeResourceUrl;
    public canReply: boolean = false;
    public displays: any;
    public isDelete: boolean = false;
    public message: string;
    public reactions: any[];
    public unlock: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private timelineProvider: TimelineProvider,
        private toastService: CommonToastService,
        private sanitizer: DomSanitizer,
        private reactionProvider: ReactionProvider,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private storageService: StorageService,
        private externalAppsService: ExternalAppsService,
        private emitterService: EmitterService,
        private mediaProvider: MediaProvider,
        private datePipe: DatePipe,
    ) { }

    ngOnInit(): void {
        this.displays = {
            composer: false,
            comments: false,
            edit: false,
            reactions: false
        };

        this.companyId = this.storageService.get('company').id;

        this.canReply = this.context === 'company' && this.post.camp ? this.post.camp.reply : true;

        this.initReactions();

        const timeOut = setTimeout(() => {
            this.loadAnimations();
            clearTimeout(timeOut);
        }, 100);

        if (this.post.media?.status === 0) {
            this.getMedia();
        }
    }

    initReactions(): void {
        this.reactions = [
            {
                id: 1,
                name: 'thumbs-up',
                file: 'thumbs-up',
                type: 'like',
                count: this.post.reactions[0].count
            },
            {
                id: 3,
                name: 'excited',
                file: 'excited',
                type: 'love',
                count: this.post.reactions[2].count
            },
            {
                id: 4,
                name: 'mind-blown',
                file: 'mind-blown',
                type: 'useful',
                count: this.post.reactions[3].count
            },
            {
                id: 2,
                name: 'skeptical',
                file: 'skeptical',
                type: 'not_understood',
                count: this.post.reactions[1].count
            }
        ];
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];

        if (type === 'composer' && !this.displays.composer) {
            this.displays.comments = false;
        }
    }

    loadAnimations(): void {
        this.animationCollection = [];

        for (const reaction of this.reactions) {
            const animation = Lottie.loadAnimation({
                name: reaction.name,
                container: document.getElementById('animation-' + this.post.id + '-' + reaction.name),
                renderer: 'svg',
                loop: false,
                autoplay: false,
                path: 'assets/animations/' + reaction.file + '.json'
            });

            this.animationCollection.push(animation);
        }
    }

    handlePlay(name: string, stop?: boolean): void {
        const animation = this.animationCollection.find((anim) => {
            return anim.name === name;
        });

        if (stop) {
            animation.stop();
        } else {
            animation.play();
        }
    }

    addReaction(reactionId: number): void {
        const params = HttpHelper.cleanParams({
            context: 'timeline_item',
            context_id: this.post.id,
            reaction_id: reactionId
        });

        this.reactionProvider.create(params).subscribe(() => {
            const index = this.reactions.findIndex((reaction) => {
                return reaction.id === reactionId;
            });

            this.refreshReactions();

            this.toastService.onSuccess(this.translateService.instant('toast.reaction-added'));
        });
    }

    send(media?: Media): void {
        if (this.message || media) {
            const params = {
                content: {
                    message: this.message,
                    media_id: media ? media.id : null
                },
                postId: this.post.id,
                companyId: this.companyId,
                type: 'mixed',
                contextId: this.contextId,
                context: this.context
            };

            switch (this.context) {
                case 'group':
                    params['group_id'] = this.contextId;
                    break;
                case 'community':
                    params['camp_id'] = this.post.camp.id;
                    break;
                case 'traject':
                    params['traject_id'] = this.contextId;
                    break;
                case 'course':
                    params['instance_id'] = this.contextId;
                    break;
                case 'external_content':
                    params['external_content_id'] = this.contextId;
                    break;
            }

            this.timelineProvider.comment(this.post.id, params).subscribe((data) => {
                this.post.comments.unshift(data);
                this.message = '';
                this.unlock.emit(true);
            });
        } else {
            this.toastService.onWarning(this.translateService.instant('toast.message-required'));
            this.unlock.emit(true);
        }
    }

    editPost(media?: Media): void {
        if (this.post.content.message || media || this.post.mediaId) {
            const params = {
                timeline_id: this.timelineId,
                content: {
                    message: this.post.content.message,
                    media_id: media ? media.id : this.post.mediaId
                },
                type: 'mixed'
            };

            this.timelineProvider.editPost(this.timelineId, this.post.id, params).subscribe(() => {
                this.displays.edit = false;
                this.post.media = media;
                this.post.modified = this.datePipe.transform(new Date(), 'YYYY/MM/dd HH:mm')
            });
        } else {
            this.toastService.onWarning(this.translateService.instant('toast.message-required'));
            this.unlock.emit(true);
        }
    }

    getEmbed(url: string): SafeResourceUrl {
        return this.embed ? this.embed : this.embed = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    downloadFile(media: any): void {
        if (media.status === 1) {
            let url = media.originalUrl;
            if (media.type === 'IMG') {
                url = media.pictureUrl + '?size=raw';
            }
            DownloadHelper.download(url, media.title);
        }
    }

    deletePost(): void {
        this.isDelete = true;
        this.onDelete.emit(true);
    }

    deleteComment(comment: any): void {
        this.timelineProvider.deleteComment(comment.id).subscribe(() => {
            const index = this.post.comments.findIndex((data) => {
                return data.id === comment.id;
            });

            if (index !== -1) {
                this.post.comments.splice(index, 1);
                this.toastService.onSuccess(this.translateService.instant('toast.comment-deleted'));
            }
        });
    }

    canDelete(id: number): boolean {
        // if (this.isAdmin || this.manage) {
        if (this.isAdmin) {
            return true;
        } else {
            return this.me.id === id;
        }
    }

    getActivityIcon(context: string): string {
        switch (context) {
            case 'course':
                return 'icon-cube';
            case 'media':
                return 'icon-video';
            case 'cheatsheet':
                return 'icon-file-text';
            case 'exercise':
                return 'icon-checkmark-square';
            case 'event':
                return 'icon-calendar';
            case 'exchange':
                return 'icon-message-circle';
            case 'project':
                return 'icon-clipboard';
            case 'section':
                return 'icon-height';
            default:
                return 'icon-checkmark-square';
        }
    }

    getEditMessage(): string {
        return this.translateService.instant('words.updated-at') + ' : ' + this.datePipe.transform(new Date(this.post.modified), 'YYYY/MM/dd HH:mm:ss')
    }

    notifyUsers(): void {
        this.emitterService.set('timeline.modal', false);

        const params = this.notifyParams();

        this.timelineProvider.notifyUsers(this.post.id, params).subscribe(() => {
            this.post.notifiedAt = new Date();
        });
    }

    notifyParams(): any {
        const params = {
            company_id: this.companyId
        };

        if (this.post.camp) {
            params['camp_id'] = this.post.camp.id;
        }

        if (this.customParams) {
            DataHelper.merge(params, this.customParams);
        }

        switch (this.context) {
            case 'camp':
                params['camp_id'] = this.contextId;
                break;
            case 'group':
                params['group_id'] = this.contextId;
                break;
            case 'course':
                params['instance_id'] = this.contextId;
                break;
            case 'traject':
                params['traject_id'] = this.contextId;
                break;
            case 'external_content':
                params['external_content_id'] = this.contextId;
                break;
        }

        return HttpHelper.cleanParams(params);
    }

    openModalAlert(): void {
        this.emitterService.set('timeline.modal', true);
    }

    countReactions(): number {
        return this.post.reactions.reduce((total, reaction) => {
            return total + reaction.count;
        }, 0);
    }

    checkIfAlreadyReacted(): boolean {
        let alreadySubscribed = false;

        this.post.reactions.forEach((reaction) => {
            reaction.accounts.forEach((account) => {
                if (account.id === this.me.id) {
                    alreadySubscribed = true;
                }
            });
        });

        return alreadySubscribed;
    }

    getOwnReactionUrl(): string {
        return 'https://static.myskillcamp.com/images/animations/' + this.getReactionName(this.getOwnReactionData('type')) + '.svg';
    }

    getReactionName(name: string): string {
        switch (name) {
            case 'like':
                return 'thumbs-up';
            case 'not_understood':
                return 'skeptical';
            case 'love':
                return 'excited';
            case 'useful':
                return 'mind-blown';
        }
    }

    getOwnReactionData(data: any): any {
        let reaction: any = {};

        this.post.reactions.forEach((react) => {
            react.accounts.forEach((account) => {
                if (account.id === this.me.id) {
                    reaction = react;
                }
            });
        });

        return reaction[data];
    }

    removeReaction(): void {
        const index = this.reactions.findIndex((reaction) => {
            return reaction.type === this.getOwnReactionData('type');
        });

        const params = HttpHelper.cleanParams({
            context: 'timeline_item',
            context_id: this.post.id,
            reaction_id: this.reactions[index].id
        });

        this.reactionProvider.delete(params).subscribe(() => {
            this.refreshReactions();
            this.toastService.onSuccess(this.translateService.instant('toast.reaction-added'));
        });
    }

    refreshReactions(): void {
        this.timelineProvider.getPost(this.post.id).subscribe((data) => {
            this.post.reactions = data.item.reactions;
            this.initReactions();

            const timeOut = setTimeout(() => {
                this.loadAnimations();
                clearTimeout(timeOut);
            }, 500);
        });
    }

    closeModalAlert(): void {
        this.emitterService.set('timeline.modal', false);
    }

    getMedia(): void {
        this.mediaProvider.getStatus(this.post.media.id).subscribe((data) => {
            if (data.status === 0) {
                const timeOut = setTimeout(() => {
                    this.getMedia();
                    clearTimeout(timeOut);
                }, 15000);
            } else {
                this.post.media.status = data.status;
            }
        });
    }
}
