<section class="head">
    <h3>Upload</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Upload</h4>
        <hr>
        <div class="partition cards">
            <div class="segment">
                <msc-common-upload></msc-common-upload>
            </div>
            <div class="segment">
                <msc-common-upload></msc-common-upload>
            </div>
            <div class="segment">
                <msc-common-upload></msc-common-upload>
            </div>
            <div class="segment">
                <msc-common-upload></msc-common-upload>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Upload with message and body</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-upload>
                    <ng-template>
                        <i class="icon icon-cloud-upload"></i>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                    </ng-template>
                </msc-common-upload>
            </div>
        </div>
    </div>
</section>
