<div class=" rounded px-6 py-4 max-w-lg m-auto">
    <div class="flex flex-col gap-6">
        <ng-container *ngIf="sent; else forgottenPasswordForm">
            <div class="text-center">
                <h1><i class="icon icon-checkmark-circle-3 text-success-500"></i></h1>
                <h2 class="block-login__title text-xl my-2 font-bold">{{ 'auth.success' | translate }}</h2>
                <p class="mb-2">{{ 'auth.email-sent' | translate }}</p>
            </div>
            <msc-button class="msc-button--primary self-center duration-500 transform hover:scale-105" palette="primary" (click)="navigate()">
                {{ 'words.continue' | translate }}
            </msc-button>
        </ng-container>

        <ng-template #forgottenPasswordForm>
            <div class="text-center">
                <h2 class="block-login__title text-xl my-2 font-bold">{{ 'auth.forgot-your-password' | translate }}</h2>
                <p class="mb-2">{{ 'auth.enter-your-email-for-password-recovery' | translate }}</p>
            </div>

            <form class="flex flex-col space-y-4" (ngSubmit)="submit()">
                <div class="slide__input">
                    <msc-input [label]="'auth.identifier' | translate" id="email" type="text" [(ngModel)]="email"
                        name="email" placeholder="example@email.com" required [hasErrors]="errors.email"></msc-input>
                    <span class="invalid-feedback" role="alert" *ngIf="errors.email">
                        <strong>{{ 'auth.email-format' | translate }}</strong>
                    </span>
                </div>
                <!--btn submit-->
                <msc-button class="w-full msc-button--primary self-center duration-500 transform hover:scale-105" style="width:50% !important;" htmlType="submit" palette="primary" [loading]="loading"
                        [disabled]="errors.email">
                        {{ 'words.send' | translate }}
                </msc-button>
                <!--
                <div class="btn-submit flex flex-row" style="padding-bottom: 0.5rem !important;">
                    <msc-button class="w-1/2 duration-500 transform hover:scale-105" htmlType="back">
                       <p>🡨</p>
                    </msc-button>
                    <msc-button class=" msc-button--primary self-center duration-500 transform hover:scale-105" style="width:50% !important;" htmlType="submit" palette="primary" [loading]="loading"
                        [disabled]="errors.email">
                        {{ 'words.send' | translate }}
                    </msc-button>
                </div> -->
            </form>
        </ng-template>
    </div>
</div>
