import { Component, OnInit, Input } from '@angular/core';
import { UtilityProvider } from '@lighty';
import { UrlHelper } from '@helpers';
import { ErrorStreamService } from '@services';

@Component({
    selector: 'msc-link-preview',
    templateUrl: './link-preview.component.html'
})
export class LinkPreviewComponent implements OnInit {
    @Input() link: string;
    @Input() backgroundColor: string;
    @Input() timeline: boolean = false;
    public preview: any;

    constructor(private utilityProvider: UtilityProvider, private errorStreamService: ErrorStreamService) {}

    ngOnInit(): void {
        this.errorStreamService.locked();
        this.utilityProvider.getLinkPreview(this.link).subscribe((data) => {
            this.errorStreamService.unlocked();
            this.preview = data;
        });
    }

    navigate(): void {
        UrlHelper.navigate(this.link);
    }
}
