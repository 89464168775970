import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ContentStats } from './content';
import { UserStats } from './user';
import { Pagination } from '../pagination';
import { environment } from '@env/environment';
import { ReportingProvider } from '..';
import { StorageService } from '@services';

@Injectable()
export class StatsProvider {

    constructor(private http: HttpClient, private reportingProvider: ReportingProvider, private storageService: StorageService) { }

    getAdoptionExport(companyId, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/adoption/export`, params);
    }

    getExport(companyId, params?: any): Observable<any> {
        console.log(params)
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/export`, params).pipe(
            map(data => data)
        );
    }

    downloadStats(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camp/${id}/admin/history/download`, { params }).pipe(
            map(data => data)
        );
    }

    downloadHistory(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/camps/${id}/stats`, { params }).pipe(
            map(data => data)
        );
    }

    getDetailStats(companyId, campId, contentId, params?: any): Observable<any> {
        const response = {
            pagination: {},
            instance: {},
            users: []
        };

        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/camps/${campId}/contents/${contentId}/users/history`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.instance = data.global;
                data.users.map((user) => {
                    response.users.push(new UserStats(user));
                });

                return response;
            })
        );
    }

    getUserContentsStats(context: string, contextId: number, userId: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            user: {},
            contents: []
        };

        return this.http.get(`${environment.envVar.API_URL}/stats/${context}/${contextId}/user/${userId}`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.user = data.global;
                data.users.map((content) => {
                    response.contents.push(new ContentStats(content));
                });

                return response;
            })
        );
    }

    get(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/statistics`, { params }).pipe(
            concatMap(
                (data: any) => {
                    if (params.entity === 'activity') {
                        return this.reportingProvider.getActivitySubscriptionDetails(this.storageService.get('company').id, data.meta.course.id, [params.accountId], [params.entityId]).pipe(map(res => {
                            data.header = { ...data.header, ...{ eventDuration: 0, duration: 0, progress: 0, score: 0 }, ...res[params.entityId]?.context };
                            return data;
                        }), catchError(() => of(data)));
                    } else {
                        return this.reportingProvider.getContentSubscriptionOverview(this.storageService.get('company').id, params.entityId, [params.accountId], params.entity).pipe(map(res => {
                            data.header = { ...data.header, ...{ eventDuration: 0, duration: 0, progress: 0, score: 0 }, ...res[params.accountId]?.context };
                            return data;
                        }), catchError(() => of(data)))
                    }
                }
            ),
            concatMap(
                (data: any) => {
                    if (params.entity === 'traject') {
                        return this.reportingProvider.getContentSubscriptions(this.storageService.get('company').id, data.items.map(item => ({ context: item.context, context_id: item.context === 'course' ? item.instanceId : item.contextId })), params.accountId).pipe(
                            map((res) => {
                                data.items = data.items.map((item) => {
                                    const stats = res.filter(resItem => (item.context === 'content' ? resItem.context.sectionContentId : resItem.context.contextId) === (item.context === 'course' ? item.instanceId : item.contextId))[0];
                                    item = { ...item, ...{ eventDuration: 0, duration: 0, progress: 0, score: 0 }, ...stats?.context }
                                    return item;
                                });
                                return data;
                            }),
                            catchError(() => of(data)))
                    } else {
                        return this.reportingProvider.getActivitySubscriptionDetails(this.storageService.get('company').id, params.entityId, [params.accountId], data.items.map(item => item.id), params.display === 'questions' ? 'question' : undefined).pipe(map(res => {
                            data.items = data.items.map(item => ({
                                ...item, ...{
                                    eventDuration: 0,
                                    progress: res[item.id]?.context.isDone ? 100 : 0,
                                    score: (res[item.id]?.context.ratio || 0) * 100,
                                    duration: res[item.id]?.context.duration || 0
                                }, ...res[item.id]?.context
                            }))
                            return data;
                        }), catchError(() => of(data)));
                    }
                }
            )
        );
    }

    public exportByRange(company_id: any, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${company_id}/export-by-range`, params).pipe(
            map(data => data)
        );
    };

    public downloadByRange(company_id: any, id: any, name: any): Observable<any> {
        console.log(name)
        const fileName = name.replace('exports//', '');
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${company_id}/downloadreport/${id}`, { responseType: 'blob' }).pipe(
            map(data => {
       
        const blob = new Blob([data], { type: 'text/csv' });
     
        const url = window.URL.createObjectURL(blob);

  
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; 


        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
            } )
        );
    };

    public getReports(company_id: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${company_id}/generated-reports`).pipe(
            map(data => data)
        );
    }; 

    public requestTrajectReport(company_id: any, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${company_id}/manage/alltrajects/learning-path-report`, params).pipe(
            map(data => data)
        );
    }; 
}
