<div class="flex flex-col gap-4">
    <div class="flex items-center space-x-1">
        <span>{{learner?.name}}</span>
        <i class="icon icon-arrow-ios-right"></i>
        <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
        <ng-template #iconTemplate>
            <i class="icon icon-arrow-ios-right"></i>
        </ng-template>
    </div>

    <div class="flex justify-around items-center p-6 bg-white gap-4">
        <p class="text-secondary-400">{{ (overviewStats ? 'words.subscribed' : 'words.unsubscribed') | translate }}
        </p>

        <p>
            {{ 'words.course-hours' | translate }} :
            <span class="text-secondary-400">{{ overviewStats?.duration | duration }}</span>
        </p>

        <div class="flex space-x-4 items-center">
            <p>{{ 'words.progress' | translate }}</p>
            <msc-chart-pie [value]="overviewStats?.progress" [size]="'small'" [valueColor]="'#8290FD'">
            </msc-chart-pie>
        </div>

        <div class="flex space-x-4 items-center">
            <p>{{ 'words.score' | translate }}</p>
            <msc-chart-pie [value]="overviewStats?.score" [size]="'small'" [valueColor]="'#8290FD'">
            </msc-chart-pie>
        </div>

        <msc-button *ngIf="me?.id !== learner?.id" class="msc-button--outline" icon="icon-message-circle"
            (click)="conversation()">
            {{ 'words.discuss' | translate }}
        </msc-button>
    </div>
</div>

<div class="my-4 grow">
    <router-outlet></router-outlet>
</div>
