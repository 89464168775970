import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Pagination } from '../pagination';
import { environment } from '@env/environment';
import { ReportingProvider } from '..';
import { StorageService } from '@services';

export const ApiCourseContextMapping = new Map<string, string>([
    ['course_instance', 'course'],
    ['traject', 'traject'],
    ['external_content', 'external_content'],
    ['external_video', 'external_video'],
]);

@Injectable()
export class ContentProvider {

    constructor(private http: HttpClient, private reportingProvider: ReportingProvider, private storageService: StorageService) { }

    getContents(params: any): Observable<any> {
        const response = {
            pagination: {},
            contents: []
        };

        return this.http.get(`${environment.envVar.API_URL}/course-subscriptions`, { params }).pipe(
            concatMap((data: any) => this.reportingProvider.getContentSubscriptions(this.storageService.get('company').id, data.data.map(content => ({ context: ApiCourseContextMapping.get(content.type), context_id: content.data.id })), this.storageService.get('me').id)
                .pipe(map(res => {
                    data.data = data.data.map(content => {
                        const stats = res.filter(item => item.context.contextId === content.data.id)[0];
                        content.data = { ...content.data, ...{ totalDuration: content.data.duration }, ...{ progress: 0, eventDuration: 0, duration: 0, score: 0 }, ...stats?.context };
                        return content;
                    });
                    return data;
                }),
                    catchError(() => of(data)))),
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.contents = data.data;

                return response;
            })
        );
    }

    getEvents(id: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            closest: [],
            events: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${id}/events`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.closest = data.closest;
                response.events = data.dates;

                return response;
            })
        );
    }

    getEventsCatalogue(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/events/catalogue`, { params }).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getEventsSubscribed(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/events/contents/subscribed`, { params }).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getSavedContents(params: any): Observable<any> {
        const response = {
            pagination: {},
            contents: []
        };

        return this.http.get(`${environment.envVar.API_URL}/saved-contents`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.contents = data.savedContents;

                return response;
            })
        );
    }

    getOverview(params: any): Observable<any> {
        const response = {
            contents: [],
            externals: [],
            featured: []
        };

        return this.http.get(`${environment.envVar.API_URL}/catalogue/overview`, { params }).pipe(
            map((data: any) => {
                response.contents = data.courses;
                response.externals = data.externals;
                response.featured = data.featured;

                return response;
            })
        );
    }

    getThematics(id: number): Observable<any> {
        return forkJoin(
            this.http.get(`${environment.envVar.API_URL}/catalogue/skills/meta`, { params: { company_id: id, browse: 'internal' } as any }).pipe(
                map(data => data)
            ),
            this.http.get(`${environment.envVar.API_URL}/catalogue/skills/meta`, { params: { company_id: id, browse: 'external' } as any }).pipe(
                map(data => data)
            )
        );
    }

    getCatalogue(params: any): Observable<any> {
        const response = {
            catalogue: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/catalogue`, { params }).pipe(
            map((data: any) => {
                response.catalogue = data.catalogue;
                response.pagination = new Pagination(data.pagination);

                return response;
            })
        );
    }

    getRemainingLanguages(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/catalogue/remaining-languages`, { params }).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getCatalogueSkills(params: any): Observable<any> {
        const response = {
            catalogue: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/catalogue/skills`, { params }).pipe(
            map((data: any) => {
                response.catalogue = data.catalogue;
                response.pagination = new Pagination(data.pagination);

                return response;
            })
        );
    }

    getCatalogueSkill(id: number, params?: any): Observable<any> {
        const response = {
            contents: [],
            levels: [],
            name: '',
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/catalogue/skill/${id}`, { params }).pipe(
            map((data: any) => {
                response.contents = data.contents;
                response.levels = data.levels;
                response.name = data.name;
                response.pagination = data.pagination;

                return response;
            })
        );
    }

    getCatalogueSkillLevel(id: number, level: number, params?: any): Observable<any> {
        const response = {
            contents: [],
            name: '',
            level: 0,
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/catalogue/skill/${id}/level/${level}`, { params }).pipe(
            map((data: any) => {
                response.contents = data.contents;
                response.name = data.name;
                response.level = data.level;
                response.pagination = new Pagination(data.pagination);

                return response;
            })
        );
    }

    getOnboardingContents(companyId: any, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/contents`, { params }).pipe(
            map((data: any) => data)
        );
    }

    getOnboardingContentsMeta(companyId: any, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/contents/meta`, { params }).pipe(
            map((data: any) => data.meta)
        );
    }

    getOnboardingContentSessions(companyId: any, contentId: any, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/contents/${contentId}`, { params }).pipe(
            map((data: any) => data)
        );
    }

    getCoachOnboardingContents(companyId: any, userId: any, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/users/${userId}/contents`, { params }).pipe(
            map((data: any) => data)
        );
    }

    getCoachOnboardingContentsMeta(companyId: any, userId: any, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/users/${userId}/contents/meta`, { params }).pipe(
            map((data: any) => data.meta)
        );
    }

    getCoachOnboardingContentSessions(companyId: any, contentId: any, userId: any, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/users/${userId}/contents/${contentId}`, { params }).pipe(
            map((data: any) => data)
        );
    }

    getMeta(type: string, params?: any): Observable<any> {
        let url = null;
        switch (type) {
            case 'content':
                url = `${environment.envVar.API_URL}/course-camp/count`;
                break;
            case 'catalogue':
                url = `${environment.envVar.API_URL}/catalogue/meta`;
                break;
            case 'saved-content':
                url = `${environment.envVar.API_URL}/saved-contents/count`;
                break;
        }
        return this.http.get(url, { params }).pipe(
            map((data: any) => data.meta)
        );
    }

    addSavedContent(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/saved-contents`, params).pipe(
            map((data) => data)
        );
    }

    deleteSavedContent(params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/learning/saved-contents`, { params }).pipe(
            map((data) => data)
        );
    }

    getTokenOneMentor(companyId: any, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/common/companies/${companyId}/onementor/token`, params).pipe(
            map((data) => data) 
        );
    }

    getBoclip(params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/catalogue/boclips`,  params ).pipe(
            map((data: any) => data)
        );
    }

    filterBoclip(params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/catalogue/boclips/search`,  params ).pipe(
            map((data: any) => data)
        );
    }
}
