import { Injectable } from '@angular/core';
//
import { Subject, Observable } from 'rxjs';
//
import { CommonToastDatum, CommonToastType } from '@common2/components/toast/toast.component';
import { MscPaletteTypes } from '@common2/components/button/button.component';

interface CommonToastAction {
    type: 'action' | 'button';
    label: string;
    callback?: CallableFunction;
    href?: string;
    buttonClassType?: 'primary' | 'secondary' | 'outline';
    palette?: MscPaletteTypes;
}
export interface CommonToastOptions {
    display?: 'inline' | 'block';
    icon?: string;
    actions?: Array<CommonToastAction>;
}

@Injectable({ providedIn: 'root' })
export class CommonToastService {
    public toasts$: Subject<CommonToastDatum> = new Subject<CommonToastDatum>();

    /**
     * Get the toast list subject/observable
     */
    getSubject(): Observable<CommonToastDatum> {
        return this.toasts$.asObservable();
    }

    /**
     * Get a toast item
     */
    getToast(message: string, type: CommonToastType, opts?: CommonToastOptions): CommonToastDatum {
        return { id: Math.random(), message, type, ...opts };
    }

    /**
     * Event handler for toast error
     */
    onError(message: string, opts?: CommonToastOptions): void {
        const toast = this.getToast(message, 'error', opts);
        this.toasts$.next(toast);
    }

    /**
     * Event handler for toast info
     */
    onInfo(message: string, opts?: CommonToastOptions): void {
        const toast = this.getToast(message, 'info', opts);
        this.toasts$.next(toast);
    }

    /**
     * Event handler for toast success
     */
    onSuccess(message: string, opts?: CommonToastOptions): void {
        const toast = this.getToast(message, 'success', opts);
        this.toasts$.next(toast);
    }

    /**
     * Event handler for toast warning
     */
    onWarning(message: string, opts?: CommonToastOptions): void {
        const toast = this.getToast(message, 'warning', opts);
        this.toasts$.next(toast);
    }
}
