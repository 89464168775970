<msc-common-modal #modalFilters size="50vw" [title]="'words.filters' | translate">
    <ng-template>
        <ng-container *ngIf="hasFilters; else emptyState">
            <div class="filters-container">
                <ng-container *ngFor="let filter of filters; let idx = index">
                    <!-- SELECT -->
                    <ng-container *ngIf="filter.type === 'select' && filter.options.length">
                        <section>
                            <h3 class="text-lg font-bold mb-2">{{ filter.label }}</h3>

                            <msc-common-select class="max-w-[50%]" [data]="filter.options">
                                <ng-template #buttonTemplate let-data="data">
                                    <ng-container *ngIf="data?.length; else emptyTemplate">
                                        <span>{{ data[0].label }}</span>
                                    </ng-container>
                                    <ng-template #emptyTemplate>
                                        <span>{{ filter.label }}</span>
                                    </ng-template>
                                </ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <span class="my-1 w-full" (click)="onSelectOption(datum, idx)">{{ datum.label
                                        }}</span>
                                </ng-template>
                            </msc-common-select>
                        </section>
                    </ng-container>

                    <!-- MULTISELECT -->
                    <ng-container *ngIf="filter.type === 'multiselect' && filter.options.length">
                        <section>
                            <h3 class="text-lg font-bold mb-2">{{ filter.label }}</h3>

                            <msc-common-select class="max-w-[50%]" [data]="filter.options" type="multi">
                                <ng-template #buttonTemplate let-data="data">
                                    <ng-container *ngIf="data?.length; else emptyTemplate">
                                        <span class="text-ellipsis overflow-hidden whitespace-nowrap">{{
                                            filter.displayValue }}</span>
                                    </ng-container>
                                    <ng-template #emptyTemplate>
                                        <span>{{ filter.label }}</span>
                                    </ng-template>
                                </ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <label class="flex justify-between my-1 w-full">
                                        <span>{{ datum.label }}</span>
                                        <msc-common2-checkbox [value]="datum.isSelected"
                                            (changeEvent)="onMultiselectOption(datum, idx)"></msc-common2-checkbox>
                                    </label>
                                </ng-template>
                            </msc-common-select>
                        </section>
                    </ng-container>

                    <!-- CHECKBOX -->
                    <ng-container *ngIf="filter.type === 'checkbox' && filter.options.length">
                        <section>
                            <h3 class="text-lg font-bold mb-2">{{ filter.label }}</h3>

                            <div class="flex flex-wrap justify-between gap-y-3 checkbox-filter">
                                <ng-container *ngFor="let option of filter.options">
                                    <msc-common2-checkbox class="w-1/2" [value]="option.isChecked"
                                        [label]="option.label" (changeEvent)="onChange($event, option)">
                                    </msc-common2-checkbox>
                                </ng-container>
                            </div>
                        </section>
                    </ng-container>

                    <!-- RADIO -->
                    <ng-container *ngIf="filter.type === 'radio'">
                        <section>
                            <h3 class="text-lg font-bold mb-2">{{ filter.label }}</h3>

                            <div class="flex flex-wrap gap-y-3 radio-filter">
                                <ng-container *ngFor="let option of filter.options">
                                    <msc-common2-radio class="w-1/4 comp-radio-filter" [groupName]="filter.name"
                                        [(ngModel)]="filter.value" [valueName]="option.value" [label]="option.label">
                                    </msc-common2-radio>
                                </ng-container>
                            </div>
                        </section>
                    </ng-container>

                    <!-- RANGE -->
                    <ng-container *ngIf="filter.type === 'range'">
                        <section>
                            <h3 class="text-lg font-bold mb-2">{{ filter.label }}</h3>

                            <div class="w-[95%] mt-4 min-h-[35px]">
                                <msc-range-slider [config]="filter.options" (onChange)="onUpdateRange($event, filter)">
                                </msc-range-slider>
                            </div>
                        </section>
                    </ng-container>

                    <!-- SKILL -->
                    <ng-container *ngIf="filter.type === 'skill' && filter.options.length">
                        <section>
                            <h3 class="text-lg font-bold mb-2">{{ filter.label }}</h3>

                            <msc-common-search class="w-1/2" [placeholder]="'common.search' | translate"
                                (searchEvent)="onSearchSkill($event.value, filter)"></msc-common-search>

                            <div class="flex flex-wrap gap-2 mt-5">
                                <ng-container *ngIf="!filter.selected.skill; else selectedTemplate">
                                    <ng-container
                                        *ngFor="let option of filter.options | filter: 'name': filter.query | slice: 0: filter.displayLimit ">
                                        <span class="skill-item bg-black-100/30 hover:bg-black-100/80"
                                            (click)="onSelectSkill(filter.selected, option)">{{ option.name }}</span>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!filter.query && (filter.displayLimit < filter.options.length)">
                                        <span class="skill-item bg-black-100/30 hover:bg-black-100/80"
                                            (click)="onLoadMoreSkills(filter)">{{ 'words.load-more' | translate
                                            }}</span>
                                    </ng-container>
                                </ng-container>

                                <ng-template #selectedTemplate>
                                    <span class="skill-item bg-black-100/30 hover:bg-black-100/80">
                                        {{ filter.selected.skill.name }}
                                        <i class="icon icon-close mx-2" (click)="onSelectSkill(filter.selected)"></i>
                                    </span>

                                    <div class="flex flex-wrap gap-2 mt-4 w-full">
                                        <span class="skill-item bg-black-100/30 hover:bg-black-100/80"
                                            [ngClass]="{'active': filter.selected.level.includes(0)}"
                                            (click)="onSelectLevel(filter.selected, 0)">
                                            <div class="flex items-baseline mr-1">
                                                <span class="skill-level"></span>
                                                <span class="skill-level"></span>
                                                <span class="skill-level"></span>
                                            </div>
                                            <span>{{ 'words.appropriate-for-all' | translate }}</span>
                                        </span>

                                        <span class="skill-item bg-black-100/30 hover:bg-black-100/80"
                                            [ngClass]="{'active': filter.selected.level.includes(1)}"
                                            (click)="onSelectLevel(filter.selected, 1)">
                                            <div class="flex items-baseline mr-1">
                                                <span class="skill-level active"></span>
                                                <span class="skill-level"></span>
                                                <span class="skill-level"></span>
                                            </div>
                                            <span>{{ 'words.introductive' | translate }}</span>
                                        </span>

                                        <span class="skill-item bg-black-100/30 hover:bg-black-100/80"
                                            [ngClass]="{'active': filter.selected.level.includes(2)}"
                                            (click)="onSelectLevel(filter.selected, 2)">
                                            <div class="flex items-baseline mr-1">
                                                <span class="skill-level active"></span>
                                                <span class="skill-level active"></span>
                                                <span class="skill-level"></span>
                                            </div>
                                            <span>{{ 'words.intermediate' | translate }}</span>
                                        </span>

                                        <span class="skill-item bg-black-100/30 hover:bg-black-100/80"
                                            [ngClass]="{'active': filter.selected.level.includes(3)}"
                                            (click)="onSelectLevel(filter.selected, 3)">
                                            <div class="flex items-baseline mr-1">
                                                <span class="skill-level active"></span>
                                                <span class="skill-level active"></span>
                                                <span class="skill-level active"></span>
                                            </div>
                                            <span> {{ 'words.confirmed' | translate }}</span>
                                        </span>
                                    </div>
                                </ng-template>
                            </div>
                        </section>
                    </ng-container>
                </ng-container>
            </div>

            <div class="flex justify-end items-center space-x-5">
                <msc-button class="" palette="primary" (click)="modalFilters.onClose()">{{'common.cancel' | translate}}
                </msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="onSave(); modalFilters.onClose()">
                    {{'common.save' | translate}}</msc-button>
            </div>
        </ng-container>
        <ng-template #emptyState>
            <msc-common-empty class="m-0" [message]="'words.no-available-filters' | translate"></msc-common-empty>
        </ng-template>
    </ng-template>
</msc-common-modal>
