<div class="col-12 content-invoice">
    <div class="dynamic-table animate">
        <div class="dynamic-table__head">
            <div class="dynamic-table__cell cell-important">
                {{ 'company.invoice.issue-date' | translate }}
            </div>

            <div class="dynamic-table__cell">
                {{ 'words.hour' | translate }}
            </div>

            <div class="dynamic-table__cell">
                {{ 'words.amount' | translate }}
            </div>

            <div class="dynamic-table__cell cell-actions"></div>
        </div>

        <div class="dynamic-table__body">
            <div class="dynamic-table__row row-radius row-shadow"  *ngFor="let invoice of invoices">
                <div class="dynamic-table__cell cell-important">
                    {{ invoice.date | date }}
                </div>

                <div class="dynamic-table__cell">
                    {{ getHour(invoice.date) }}
                </div>

                <div class="dynamic-table__cell">
                    {{ invoice.total }}
                </div>

                <div class="dynamic-table__cell cell-actions" (click)="download(invoice)">
                    <a>
                        <i class="icon icon-file"></i>
                        {{ 'company.invoice.download' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
