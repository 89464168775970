import { Component, OnInit, Input } from '@angular/core';
import { Company, OverviewProvider } from '@lighty';
import { RedirectService } from '@services';

@Component({
    selector: 'msc-widget-next-event',
    templateUrl: './next-event.component.html'
})
export class WidgetNextEventComponent implements OnInit {
    @Input() company: Company;
    public events: any[] = [];
    public loading: boolean = true;

    constructor(private overviewProvider: OverviewProvider, private redirectService: RedirectService) {}

    ngOnInit(): void {
        this.overviewProvider.getNextEvent(this.company.id).subscribe((data) => {
            this.events = data;
            this.loading = false;
        });
    }

    redirectPlayer(event: any): void {
        if (!event.isLocked) {
            this.redirectService.navigate('player', ['course', event.courseId, 'section', event.sectionId, 'event', event.eventId]);
        }
    }
}
