<div class="card card--progress card--hover-effect" (click)="externalAction()"
    [ngClass]="{'pointer-events-none': loading.trainingexpress}">
    <div *ngIf="loading.trainingexpress"
        class="absolute left-0 top-0 h-full w-full flex flex-wrap items-center justify-center z-30 bg-gray-600 bg-opacity-60">
        <div>
            <span class="w-full inline-block text-white text-center text-xl mb-3">{{ 'words.course-loading' | translate
                }}&#46;&#46;&#46;</span>
            <svg role="status" class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-purple-500"
                viewBox="0 0 100 101" fill="none">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
        </div>
    </div>
    <div class="card__header">
        <div class="card__banner card__banner--external">
            <div class="card__banner--external__picture" [style.backgroundImage]="logo"
                *ngIf="type === 'external_video' || (type === 'external_content' && content.provider)"></div>
            <i class="icon--card icon icon-pin-2" *ngIf="type === 'external_content' && !content.provider"></i>
            <msc-common-avatar class="tiny" *ngIf="type === 'external_content' && !content.provider && content.account"
                [label]="content.account.name" 
                [pictureURL]="content.account.picture ? (content.account.picture + '?size=256') : null"></msc-common-avatar>
        </div>

        <div class="card__tags card__tags--banner">
            <msc-tag [color]="getColor()" [text]="getType() | translate"></msc-tag>
        </div>

        <div class="card__image">
            <div *ngIf="!content.archived && content.provider?.key !== 'cefora'"
                [ngStyle]="{'background-image': 'url(' + (content.picture ? (content.picture + '?size=512') : 'https://static.myskillcamp.com/lxp/images/catalog-item.png') + ')'}">
            </div>
            <div *ngIf="!content.archived && content.provider?.key === 'cefora'" class="card__image--cefora"
                [ngStyle]="{'background-image': 'url(https://static.myskillcamp.com/images/covers/cefora.png'}"></div>
            <div *ngIf="content.archived"
                [ngStyle]="{'background-image': 'url(' + 'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.id % 7 + '.jpg)'}">
            </div>
        </div>
    </div>

    <div class="card__body">
        <div class="card__time" *ngIf="content.totalDuration">
            <span class="card_time_text">{{ content.totalDuration | duration }}</span>
        </div>

        <div class="card__states card__states--refused" *ngIf="content.archived">
            <p>
                <i class="icon icon-slash"></i> {{ 'words.content-archived' | translate }}
            </p>
        </div>

        <div class="card__states card__states--registered"
            *ngIf="content.subscribed && (!content.progress && content.progress !== 0)">
            <p><i class="icon icon-checkmark-circle text-primary-500 text-lg"></i> {{ (!content.isDownloadable ? 'words.already-subscribed' :
                'bookboon.already-downloaded') | translate }}</p>
        </div>

        <div class="card__skills" *ngIf="content.skills && content.skills.length > 0">
            <div class="skill skill--level skill--no-hover" *ngFor="let skill of content.skills | slice:0:1" [tooltip]="skill.name">
                <div class="skill__content">
                    <div class="skill__level" *ngIf="skill.level > 0">
                        <span [ngClass]="{'active': skill.level > 0}"></span>
                        <span [ngClass]="{'active': skill.level > 1}"></span>
                        <span [ngClass]="{'active': skill.level > 2}"></span>
                    </div>

                    <p class="skill__text">{{ skill.name | truncate: 10 }}</p>
                </div>
            </div>

            <div class="skill skill--more" *ngIf="content.skills.length > 1">
                <div class="skill__content">
                    <div class="skill__text"></div>
                </div>
            </div>
        </div>

        <p class="card__title">
            <ng-container *ngIf="content?.title?.length > 50; else titleTemplate">
                <span [tooltip]="content?.title">{{ content.title | truncate: 50 }}</span>
            </ng-container>
            <ng-template #titleTemplate>
                <span>{{ content.title || ('words.untitled' | translate) }}</span>
            </ng-template>
        </p>
        <p class="card__desc leading-3"
            [innerHTML]="content.description || ('words.undescribed-content' | translate) | truncate: 130"></p>
    </div>

    <div class="card__footer">
        <div class="card__actions">
            <div class="card__buttons flex space-x-2">
                <msc-button class="msc-button--primary"
                    [icon]="content.provider?.key === 'bookboon' ? 'icon-download' : 'icon-arrow-ios-left-1'"
                    palette="primary" type="fab" [loading]="loading.bookboon"
                    *ngIf="type !== 'external_video' && (type === 'external_content' && content.provider) && !content.archived && content.isDownloadable">
                </msc-button>

                <msc-button class="msc-button--primary" type="fab" palette="primary" icon="icon-external-link"
                    *ngIf="type === 'external_content' && !content.provider">
                </msc-button>

                <msc-button [tooltip]="'words.launch' | translate" class="msc-button--primary" type="fab" palette="primary" icon="icon-arrrow-left-1"
                    (click)="launchVideo(); stopPropagation($event);" *ngIf="type === 'external_video'">
                </msc-button>

                <msc-button class="msc-button--primary" palette="primary" type="fab"
                    [tooltip]="'words.details' | translate" *ngIf="!content.archived" icon="icon-search"
                    (click)="redirect(); stopPropagation($event);">
                </msc-button>

                <msc-button class="msc-button--primary" palette="primary" type="fab"
                    [tooltip]="'words.modify' | translate"
                    *ngIf="content.account?.id === me.id && content.type === 'external_resource'" icon="icon-edit-2"
                    (click)="editContent(); stopPropagation($event);">
                </msc-button>
            </div>

            <div class="card__widgets">
                <a *ngIf="!content.subscribed" (click)="changeLearnLaterContent(); stopPropagation($event)"
                    [tooltip]="'words.learn-later' | translate" direction="left">
                    <i class="icon" [ngClass]="{'icon-bookmark-1 color--orange' : saved, 'icon-bookmark' : !saved}"></i>
                </a>

                <a [tooltip]="'words.unsubscribe' | translate" *ngIf="content.subscribed && content.progress >= 0 && !content.subscriptionOrigins?.isFromGroup"
                    (click)="unsubscribe(); stopPropagation($event);">
                    <i class="icon icon-trash-2"></i>
                </a>

                <span class="cursor-not-allowed" [tooltip]="'tooltip.delete.community.not-allowed' | translate" (click)="stopPropagation($event)" *ngIf="content.subscriptionOrigins?.isFromGroup">
                    <i class="icon icon-trash-2"></i>
                </span>
            </div>
        </div>

        <div class="card__progress" *ngIf="content.subscribed && content.progress > 0">
            <msc-progress-bar [progress]="content.progress" [color]="content.progress !== 100 ? 'blue': ''">
            </msc-progress-bar>
        </div>
    </div>
</div>