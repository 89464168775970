import { Directive, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UploadService, ErrorStreamService } from '@services';

@Directive({
    selector: '[fileUpload]'
})
export class FileUploadDirective implements OnInit {
    @Input('fileUpload') attributeFilename: string = 'file';
    @Input('fileUrl') url: string = 'media';
    @Output('fileStatus') status: EventEmitter<any> = new EventEmitter();
    private uploadService: UploadService;

    constructor(private http: HttpClient, private errorStreamService: ErrorStreamService) {
        this.uploadService = new UploadService(this.http, this.errorStreamService);
    }

    ngOnInit(): void {
        this.uploadService.getStatus().subscribe((status) => {
            this.status.emit(status);
        });
    }

    private upload(file: File): void {
        this.uploadService.upload(file, this.attributeFilename || 'file', this.url);
    }

    @HostListener('change', ['$event.target'])
    onChange(event: any): void {
        if (event.files[0]) {
            this.upload(event.files[0]);
        }
    }
}
