import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//
import { map } from 'rxjs/operators';
//
import { HttpUtils } from '@common2/utils/http.utils';
import { ADMIN_COMPANY_LIST_API_URL_ROOT, MEDIA_API_URL_ROOT } from '@core/tokens';
import { ApiMarketplaceGetSettings } from '@modules/organize/organize.types';

@Injectable({ providedIn: 'root' })
export class OrganizeMarketplaceSettingsApiService {
    constructor(
        @Inject(ADMIN_COMPANY_LIST_API_URL_ROOT) private readonly adminCompanyListApi: string,
        @Inject(MEDIA_API_URL_ROOT) private readonly mediaApi: string,
        private readonly http: HttpClient,
    ) { }

    /**
     * Get the settings
     */
    getSettings(companyId: number, params: any = {}) {
        return this.http.get(`${this.adminCompanyListApi}/${companyId}/provider`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<ApiMarketplaceGetSettings>) => response.body)
        );
    }

    /**
     * Edit the settings
     */
    editSettings(companyId: number, params: any = {}) {
        return this.http.put(`${this.adminCompanyListApi}/${companyId}/provider`, params, {
            observe: 'body',
        });
    }

    /**
     * Post (upload) the media
     */
    postUploadMedia(file: File) { // TODO Bogdan: should move to a new media service
        return this.http.post(`${this.mediaApi}`, HttpUtils.getFormDataParams({ file }), {
            observe: 'body',
        });
    }
}
