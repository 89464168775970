import { KeyValue } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CoachContentsAttendeesApiService } from '@modules/coach/services/api/coach-contents-attendees-api.service';
import { StorageService } from '@services';
import moment from 'moment';

@Component({
    selector: 'msc-learning-path-changelog',
    templateUrl: './learning-path-changelog-aside.component.html',
    styleUrls: ['./learning-path-changelog-aside.component.scss']
})
export class MscLearningPathChangelog implements OnInit {
    @ViewChild('aside') aside;
    @Input() learningPath;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public onCloseByUser: EventEmitter<any> = new EventEmitter();
    company;
    data = {};
    loading;
    pagination = {
        currentPage: 0,
        lastPage: 1
    };

    constructor(private storageService: StorageService, private coachContentsAttendeesApiService: CoachContentsAttendeesApiService) { }

    onOpen() {
        this.aside.onOpen();
        this.getLogs();
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
    }

    getLogs(loadMore?) {
        if (loadMore && this.pagination.currentPage === this.pagination.lastPage) {
            return;
        }
        this.loading = true;
        this.coachContentsAttendeesApiService.getLearningPathHistoryList(this.company.id, this.learningPath.id, { page: this.pagination.currentPage += 1, perPage: 30 }).subscribe((rsp) => {
            this.pagination = rsp.pagination;
            this.data = rsp.data.reduce((acc, a: any) => {
                const floorDate = moment(a.date).format('YYYY-MM-DD');
                acc[floorDate] = acc[floorDate] || [];
                acc[floorDate].push(a);
                return acc;
            }, this.data);
            this.loading = false;
        })
    }

    close(): void {
        this.onClose.emit(true);
    }

    // Order by descending property key
    keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
    }
}
