import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
//
import { TranslateService, StorageService, RedirectService } from '@services';
//
import { CommonSubjectStatus, CommonApiGetListOrderParams } from '@common2/common.types';
import { CommonThSortType } from '@common2/components/table-th/th-sort/th-sort.component';
import { UuidPipe } from '@common2/pipes/uuid.pipe';
import { ComponentTableModel } from '@models/component-table.model';
//
export interface OrganizeTableMarketplaceContentDatum {
    category: Array<string>;
    icon: string;
    id: number;
    isDraft: boolean;
    isSelected: boolean;
    languageId: number;
    languageLabel: string;
    title: string;
    type: string;
    typeLabel: string;
}

@Component({
    selector: 'msc-organize-table-marketplace-content-component',
    templateUrl: './organize-table-marketplace-content.component.html',
    styleUrls: ['./organize-table-marketplace-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UuidPipe],
})

export class OrganizeTableMarketplaceContentComponent extends ComponentTableModel<OrganizeTableMarketplaceContentDatum> {
    @Input() rows: Array<OrganizeTableMarketplaceContentDatum>;
    @Input() status: CommonSubjectStatus;
    @Input() isInit: boolean = false;
    @Output() sortEvent: EventEmitter<CommonApiGetListOrderParams> = new EventEmitter<CommonApiGetListOrderParams>();
    @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectAllEvent: EventEmitter<any> = new EventEmitter<any>();

    private company: any;
    public id: string = this.uuidPipe.transform();

    constructor(
        protected readonly storageService: StorageService,
        protected readonly translateService: TranslateService,
        protected readonly redirectService: RedirectService,
        protected readonly uuidPipe: UuidPipe,
    ) {
        super();
    }

    get thCheckboxIsPartial(): boolean { return this.isThCheckboxPartial(); }
    get thCheckboxIsSelected(): boolean { return this.isThCheckboxSelected(); }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.setContent();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
    }

    /**
     * Get the column list
     * Note: Order of columns is important
     */
    getColumns() {
        return [
            {
                key: 'isSelected',
                width: '5%',
            },
            {
                key: 'icon',
                width: '5%',
            },
            {
                key: 'title',
                label: this.translateService.instant('organize.marketplace.catalogue.table.th.title'),
                width: '30%',
            },
            {
                key: 'type',
                label: this.translateService.instant('organize.marketplace.catalogue.table.th.type'),
                width: '15%',
            },
            {
                key: 'language',
                label: this.translateService.instant('words.language'),
                width: '15%',
            },
            {
                key: 'category',
                label: this.translateService.instant('organize.marketplace.catalogue.table.th.category'),
                width: '20%',
            },
            {
                key: '',
                width: '10%',
            },
        ];
    }

    /**
     * Resolves the check if thead checkbox cell is partial or not
     */
    isThCheckboxPartial(): boolean {
        return !this.rows.every((row, _i, rows) => rows[0].isSelected === row.isSelected);
    }

    /**
     * Resolves the check if thead checkbox is selected fully or not
     */
    isThCheckboxSelected(): boolean {
        return this.rows.every((row) => row.isSelected);
    }

    /**
     * Event handler for change
     */
    onChange(value: boolean, datum: OrganizeTableMarketplaceContentDatum): void {
        datum.isSelected = value;
        this.updateEvent.emit(this.rows);
    }

    /**
     * Event handler for modify
     */
    onModify(value: boolean): void {
        this.rows.forEach((row) => row.isSelected = value);
        this.updateEvent.emit(this.rows);
        this.selectAllEvent.emit(value);
    }

    /**
     * Event handler for sort
     */
    onSort(key: keyof OrganizeTableMarketplaceContentDatum, type: CommonThSortType): void {
        this.sortEvent.emit({ order: type, sort: key });
    }

    /**
     * Event handler for sort
     */
    onRedirect(datum: any) {
        if (datum.type === 'traject') {
            this.redirectService.navigate('studio', ['company', this.company.slug, 'journey/preview', datum.id], {}, '_blank');
        } else {
            this.redirectService.navigate('studio', ['company', this.company.slug, 'course', datum.id], {}, '_blank');
        }
    }

    /**
     * Event handler for redirect to create
     */
    onGoToCreate() {
        this.redirectService.navigate('studio', ['company', this.company.slug], {}, '_blank');
    }
}
