import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
    selector: '[ngModel][debounce]',
})
export class DebounceDirective implements OnInit {
    @Input('debounce') debounceTime: number = 800;
    @Output() onDebounce: EventEmitter<any> = new EventEmitter();
    private first: boolean = true;

    constructor(public model: NgControl) {}

    ngOnInit(): void {
        this.model.valueChanges.pipe(debounceTime(this.debounceTime), distinctUntilChanged()).subscribe((value) => {
            if (this.first) {
                this.first = false;
            } else {
                this.onDebounce.emit(value);
            }
        });
    }
}
