<div class="content-configuration__content">
    <h3>{{ 'words.summary' | translate }}</h3>

    <div class="content-configuration__resume form-filter-resume col-xs-12 col-6">
        <div class="form-filter-resume__content">
            <ul class="form-filter-resume__list">
                <li class="form-filter-resume__item">
                    <ul>
                        <li *ngFor="let form of parsedData" class="form-filter-resume__sublist" [ngClass]="{'active': displays[form.key]}">
                            <p class="flex between">
                                <span>
                                    <i class="icon icon-checkmark"></i>
                                    {{ form.obj.label | translate }}
                                </span>

                                <a (click)="displayed(form.key)">
                                    <i class="icon icon-arrow-ios-down"></i>
                                </a>
                            </p>

                            <ul>
                                <ng-container *ngFor="let subForm of form.obj.data">
                                    <li class="form-filter-resume__subitem" *ngIf="subForm">
                                        <p *ngIf="!subForm.data">{{ subForm | translate }}</p>

                                        <ng-container *ngIf="subForm.data">
                                            <p>{{ subForm.label | translate }}</p>

                                            <ul>
                                                <ng-container *ngFor="let data of subForm.data">
                                                    <li *ngIf="data">
                                                        <p *ngIf="!data.data">{{ data | translate }}</p>

                                                        <ng-container *ngIf="data.data">
                                                            <p>{{ data.label | translate }}</p>

                                                            <ul>
                                                                <li *ngFor="let childData of subForm.data">
                                                                    {{ childData | translate }}
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </ng-container>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
