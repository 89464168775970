import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from '@interfaces';
import { AuthProvider, LanguageProvider } from '@lighty';
import { AuthService, TranslateService } from '@services';
import { tap } from 'rxjs';
import { environment } from '@env/environment';
import { CommonToastService } from '@common2/services/toast.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'idp-login',
    templateUrl: './idp-login.component.html',
    styleUrls: ['./idp-login.component.scss']
})
export class IdpLoginComponent implements OnInit {

    public loading: boolean = false;
    public selectData: any[] = [];
    public currentLang: Language;
    public credentials: any = {
        email: '',
        password: ''
    };
    public loggedIn;
    public samlData;

    constructor(
        private translateService: TranslateService,
        private languageProvider: LanguageProvider,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cookieService: CookieService,
        private authProvider: AuthProvider,
        private authService: AuthService,
        private toastService: CommonToastService,
    ) {
        this.setLanguages('en');
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.SAMLRequest) {
                this.samlData = {
                    SAMLRequest: params.SAMLRequest,
                    RelayState: params.RelayState
                }
                const accessToken = this.cookieService.get(environment.tokenName);
                if (accessToken) {
                    this.loggedIn = true;
                    this.authProvider.getSAML(this.samlData).subscribe({
                        next: (data) => {
                            this.samlForm({
                                SAMLResponse: data.SAMLResponse,
                                RelayState: data.RelayState
                            }, data.url);
                        },
                        error: (error) => {
                            this.loggedIn = false;
                            this.cookieService.delete(environment.tokenName);
                        }
                    });
                }
            } else {
                //redirect to login
                this.router.navigate(['login']);
            }
        });
    }

    login() {
        this.loading = true;
        this.authService.login({ ...this.credentials, ...this.samlData }).subscribe({
            next: (auth: any) => {
                this.loggedIn = true;
                setTimeout(() => {
                    this.samlForm({
                        SAMLResponse: auth.sso?.SAMLResponse,
                        RelayState: auth.sso?.RelayState
                    }, auth.sso?.url);
                }, 500);
            },
            error: (failure) => {
                if (typeof failure.error === 'string' && failure.error.indexOf('Company prefix missing') > -1) {
                    this.toastService.onError(this.translateService.instant('toast.company.prefix.missing'));
                } else if (typeof failure.error === 'object' && failure.error.message[this.credentials.email]) {
                    this.toastService.onError(this.translateService.instant('toast.login.many.attempts'));
                } else {
                    this.toastService.onError(this.translateService.instant('toast.invalid-credentials'));
                }
            },
            complete: () => {
                this.loading = false;
            }
        });
    }

    getSelectData(languages: Language[], code) {
        return languages.map((language, index) => {
            const id = index;
            const label = language.name;
            const value = language;
            const isSelected = language.code === code;
            return { id, label, value, isSelected };
        });
    }

    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });
        this.onSelect(option.value);
    }

    setLanguages(code = 'en') {
        this.languageProvider
            .get('interface')
            .pipe(tap((response) => this.onTapSetLanguages(response, code)))
            .subscribe();
    }

    onTapSetLanguages(languages: Language[], code: string) {
        if (!Array.isArray(languages)) { return; }
        this.currentLang = languages.find((language) => language.code === code) || languages[0];
        this.translateService.setLang(this.currentLang.code);

        this.selectData = this.getSelectData(languages, code);
    }

    onSelect(language: Language): void {
        this.currentLang = language;
        this.translateService.setLang(this.currentLang.code);
    }

    passwordForgotten(): void {
        this.router.navigate(['password', 'forgotten'], { queryParams: { email: this.credentials.email } });
    }

    trimPassword(): void {
        this.credentials.password = this.credentials.password.trim();
    }

    samlForm(params: any, url: string): void {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.setAttribute('value', params[key]);
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }
}
