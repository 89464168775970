<section class="head">
    <h3>Avatar</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Avatar</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-avatar label="Bucan Dragos"></msc-common-avatar>
                <msc-common-avatar label="Carbune Bogdan"></msc-common-avatar>
                <msc-common-avatar label="Prisecaru Bogdan"></msc-common-avatar>
                <msc-common-avatar pictureURL="https://yt3.ggpht.com/ytc/AKedOLTlGVLrXzgZDwItF-m8Tux0NF5II9C-TIa6HgIalg=s900-c-k-c0x00ffffff-no-rj"></msc-common-avatar>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Avatar tiny</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-avatar class="tiny" label="Bucan Dragos"></msc-common-avatar>
                <msc-common-avatar class="tiny" label="Carbune Bogdan"></msc-common-avatar>
                <msc-common-avatar class="tiny" label="Prisecaru Bogdan"></msc-common-avatar>
                <msc-common-avatar class="tiny" pictureURL="https://yt3.ggpht.com/ytc/AKedOLTlGVLrXzgZDwItF-m8Tux0NF5II9C-TIa6HgIalg=s900-c-k-c0x00ffffff-no-rj"></msc-common-avatar>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Avatar large</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-avatar class="large" label="Bucan Dragos"></msc-common-avatar>
                <msc-common-avatar class="large" label="Carbune Bogdan"></msc-common-avatar>
                <msc-common-avatar class="large" label="Prisecaru Bogdan"></msc-common-avatar>
                <msc-common-avatar class="large" pictureURL="https://yt3.ggpht.com/ytc/AKedOLTlGVLrXzgZDwItF-m8Tux0NF5II9C-TIa6HgIalg=s900-c-k-c0x00ffffff-no-rj"></msc-common-avatar>
            </div>
        </div>
    </div>
</section>
