import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@services';
import { Company, CompanyExperience, OverviewProvider } from '@lighty';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-learn-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class LearnOverviewComponent {
    @ViewChild('modalOnboarding') modalOnboarding: CommonModalComponent;
    public me: any;
    public company: Company;
    public sidebar: any;
    public experience: CompanyExperience;
    public announce: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private overviewProvider: OverviewProvider,
        private storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.sidebar = this.storageService.get('sidebar');
        this.experience = this.company.experience;
        if (!this.experience) {
            this.overviewProvider.getAnnounce(this.company.id).subscribe((data) => {
                this.announce = data;
            });
        }
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe((params) => {
            if (params.actions && params.actions === 'unlock-onboarding') {
                this.modalOnboarding.onOpen();
            }
        });
    }

    getAnnouncePicture(): string {
        if (this.experience && this.experience.announcePicture) {
            return 'url(' + this.experience.announcePicture + '?size=512' + ')';
        } else if (this.announce && this.announce.dashboardMedia) {
            return 'url(' + this.announce.dashboardMedia + '?size=512' + ')';
        }
    }

    catalogue(): void {
        this.router.navigate(['../catalogue'], { relativeTo: this.route });
    }
}
