import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-reset-password-success',
    templateUrl: './reset-password-success.component.html'
})
export class ResetPasswordSuccessComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
