import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, takeUntil, shareReplay, repeatWhen, finalize } from 'rxjs/operators';

import { CompanyUrlProvider2, CompanySettingsProvider, ApiCompanyUrlSubject, ApiCompanyUrlSubjectStatus, ApiCompanyUrlItem } from '@lighty';
import { StorageService, ExternalAppsService, TranslateService } from '@services';

import { ComponentSmartSimpleModel } from '@models2/component-smart-simple.model';

import { UiCompanyUrlItem, getRemappedCompanyUrlApiToUi } from '../url-item/url-item.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-organize-experience-watch-url-list',
    templateUrl: './url-list.component.html',
    styleUrls: ['./url-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchUrlListComponent extends ComponentSmartSimpleModel<any> {
    public source$: Observable<ApiCompanyUrlSubject>;
    public items$: Observable<any[]>;
    public status$: Observable<boolean>;
    public indicator$: Observable<any>;

    constructor(
        protected readonly ref: ChangeDetectorRef,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly companyUrlProvider: CompanyUrlProvider2,
        protected readonly companySettingsProvider: CompanySettingsProvider,
        protected readonly storageService: StorageService,
        protected readonly toastService: CommonToastService,
        protected readonly externalAppsService: ExternalAppsService,
        protected readonly translateService: TranslateService,
    ) {
        super();
    }

    /**
     * Sets the component observables
     */
    setObs(): void {
        this.source$ = this.companyUrlProvider
            .getSubjectCompanyUrl()
            .pipe(takeUntil(this.destroy$));

        this.items$ = this.source$.pipe(map((r: ApiCompanyUrlSubject) => this.getUrlsRemapped(r.items)));
        this.status$ = this.source$.pipe(map((r: ApiCompanyUrlSubject) => r.status !== ApiCompanyUrlSubjectStatus.SUCCESS));

        this.indicator$ = this.companySettingsProvider
            .get(this.company.slug)
            .pipe(
                repeatWhen(() => this.repeat$),
                map((d) => this.onMapGetIndicator(d)),
                shareReplay(1),
                takeUntil(this.destroy$)
            );
    }

    /**
     * Query init data
     */
    onQuery(): void {
        this.onQueryUrls();
    }

    /**
     * Query data for urls
     */
    onQueryUrls(): void {
        this.companyUrlProvider
            .getUrls(this.company.id)
            .pipe(
                repeatWhen(() => this.repeat$),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    /**
     * Get a list of remapped urls
     */
    getUrlsRemapped(data: ApiCompanyUrlItem[]): UiCompanyUrlItem[] {
        return data.map((datum: any) => getRemappedCompanyUrlApiToUi(datum, this.companyCurrentExperience));
    }

    /**
     * Resolver for map of get indicator
     */
    onMapGetIndicator(datum: any) {
        const isLimitReached = datum.urlsCount === datum.urlsLimit;
        const label = `${datum.urlsCount} / ${datum.urlsLimit}`;
        return { isLimitReached, label };
    }

    /**
     * Event handler for activate of url
     */
    onActivate(url: UiCompanyUrlItem): void {
        this.companyUrlProvider
            .editExperience(this.company.id, this.companyCurrentExperience.id, { url_id: url.id })
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onActivateSuccess(url),
                error: (error: HttpErrorResponse) => this.onActivateError(error),
            });
    }

    /**
     * Event handler for success on onActivate
     */
    onActivateSuccess(url: UiCompanyUrlItem): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.activate.success'));
        this.repeat$.next();
    }

    /**
     * Event handler for error on onActivate
     */
    onActivateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-url.activate.error', error.message));
    }

    /**
     * Event handler for notify (of sales team)
     */
    onNotify(modal: CommonModalComponent): void {
        this.companyUrlProvider
            .postNotify(this.company.id)
            .pipe(
                finalize(() => this.onNotifyFinally(modal)),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: () => this.onNotifySuccess(),
                error: (error: HttpErrorResponse) => this.onNotifyError(error),
            });
    }

    /**
     * Event handler for success on onNotify
     */
    onNotifySuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.notify.success'));
    }

    /**
     * Event handler for error on onNotify
     */
    onNotifyError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-url.notify.error', error.message));
    }

    /**
     * Event handler for finally on onNotify
     */
    onNotifyFinally(modal: CommonModalComponent): void {
        modal.onClose();
        this.ref.detectChanges();
    }

    /**
     * Resolves navigation to create section
     */
    goToCreate(): void {
        this.router.navigate(['..', 'create'], { relativeTo: this.route });
    }

    /**
     * Resolves navigation to details section
     */
    goToDetails(id: number): void {
        this.router.navigate(['..', 'details', id], { relativeTo: this.route });
    }
}
