import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ReportingProvider } from '../..';
import { StorageService } from '@services';

interface UrlParams {
    company_id: number;
    provider_id: number;
    context_id: number;
    context_type: string;
}

@Injectable()
export class ExternalContentProvider {
    public baseUrl: string;
    constructor(private http: HttpClient) {
        this.baseUrl = localStorage.getItem('baseUrl');
     }

    subscribeContent(id: number, params?: any): Observable<any> {
        if(params){ 
            params['base_url'] = this.baseUrl; 
        }
        else {
            params = {
               base_url: this.baseUrl
            } 
        }
        return this.http.post(`${environment.envVar.API_URL}/learning/external-contents/${id}/subscribe`, params);
    }

     storeUserToken(url:string): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/common/account/store-token`,{url});
    }

    generateUrl(params: UrlParams): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/content/custom-url`, params);
    }

    subscribeVideo(id: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/external-videos/${id}/subscription`, params);
    }

    unsubscribe(id: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/external-contents/${id}/unsubscribe`, { params });
    }

    unsubscribeVideo(id: number, params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/subscriptions/externals/${id}`, { params, });
    }

    getExternalContentSections(externalContentId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/external-contents/${externalContentId}/sections`);
    }

    getExternalContentDetails(externalContentId: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/external-content/${externalContentId}`, { params });
    }

    getEdflex(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/mymooc/${id}`, { params });
    }

    download(id: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/external-contents/${id}/download`, params, { responseType: 'blob' });
    }

    getAvailableMembersForSubscription(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/external-contents/${id}/available-accounts`, { params });
    }

    getSubscribedMembersForResource(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/external-contents/${id}/sessions`, { params });
    }

    subscribeMemberToResource(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/external-contents/${id}/subscribe`, params);
    }

    unsubscribeMemberToResource(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/external-contents/${id}/unsubscribe`, params);
    }

    addSkillToExternalResource(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/learning/external-contents/${id}/skills`, params);
    }

    removeSkillFromExternalResource(id: number, skillId: number, params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/external-contents/${id}/skills/${skillId}`, { params });
    }

    updateSkillLevelOfExternalResource(id: number, skillId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/external-contents/${id}/skills/${skillId}`, params);
    }

    addExternalResource(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/external-resources`, params);
    }

    addExternalResourceToCamp(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/external-contents/${id}/camps`, params);
    }

    updateExternalContent(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/external-contents/${id}`, params);
    }

    getExternalContentsInfos(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/external-contents/${id}`, { params, });
    }

    getManagers(id: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/external-contents/${id}/managers`, { params });
    }

    getAvailableManagers(id: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/external-contents/${id}/available-managers`, { params });
    }

    addManager(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/external-contents/${id}/managers`, params);
    }

    deleteManager(id: number, accountId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/external-contents/${id}/managers/${accountId}`, { params });
    }

    getPreviewUrl(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/external-resources/preview`, { params, });
    }

    getSession(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/external-contents/${id}/session`, { params });
    }

    updateSession(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/learning/external-contents/${id}/session`, params);
    }

    createSessionLog(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/external-contents/${id}/session/logs`, params);
    }

    updateSessionLog(id: number, logId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/learning/external-contents/${id}/session/logs/${logId}`, params);
    }

    deleteSessionLog(id: number, logId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/learning/external-contents/${id}/session/logs/${logId}`, { params });
    }

    getSessionLogsVideo(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/external-videos/${id}/session/logs`, { params });
    }

    createSessionLogVideo(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/learning/external-videos/${id}/session/logs`, params);
    }

    updateSessionLogVideo(id: number, logId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/learning/external-videos/${id}/session/logs/${logId}`, params);
    }

    deleteSessionLogVideo(id: number, logId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/learning/external-videos/${id}/session/logs/${logId}`, { params });
    }

    getSessionFromManage(id: number, externalContentId: number, sessionId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/external-contents/${externalContentId}/sessions/${sessionId}`, { params });
    }

    updateSessionFromManage(id: number, externalContentId: number, sessionId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/external-contents/${externalContentId}/sessions/${sessionId}`, params);
    }

    getSettings(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/external-contents/${id}/settings`, { params });
    }

    updateSettings(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/external-contents/${id}/settings`, params);
    }

    public openOneMentor(token: any): Observable<any> {
        return this.http.get(`${environment.envVar.CLOUD_URL}/authenticated-redirect?token=${token}`);
    }
}
