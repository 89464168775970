<div class="showroom-container">
    <div class="demo">
        <div class="input-type">
            <div class="type-name">
                <h1>Flavours</h1>
            </div>
            <div class="type-content">
                <form class="flex mb-2">
                    <msc-input class="w-52" type="text" label="Basic" placeholder="Placeholder" ></msc-input>
                    <msc-input class="w-52 msc-input--outline ml-3" type="text" label="Outline" placeholder="Placeholder"></msc-input>
                    <msc-input class="w-52 msc-input--no-border ml-3" type="text" label="Flat" placeholder="Placeholder"></msc-input>
                </form>
                <form class="flex">
                    <msc-input class="w-52" palette="primary" type="text" label="Primary" placeholder="Focus to see color"></msc-input>
                    <msc-input class="w-52 ml-3" type="text" label="Secondary" placeholder="Focus to see color"></msc-input>
                </form>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                <h1>States</h1>
            </div>
            <div class="type-content">
                <form class="flex mb-2">
                    <msc-input class="w-52" type="text" label="Hints" placeholder="Placeholder" [hints]="hints"></msc-input>
                    <msc-input class="w-52 ml-3" type="text" label="Disabled" placeholder="Placeholder" [disabled]="true"></msc-input>
                </form>
                <form class="flex mb-2" *ngIf="testForm" [formGroup]="testForm">
                    <msc-input name="errorinput" class="w-52" type="text" label="Error" placeholder="Placeholder" formControlName="errorinput"></msc-input>
                    <msc-input class="w-52 ml-3" type="text" label="Success" placeholder="Placeholder" [hasSuccess]="true" [helperText]="'Helper text'"></msc-input>
                    <msc-input class="w-52 ml-3" type="text" label="Warning" placeholder="Placeholder" [hasWarning]="true" [helperText]="'Helper text'"></msc-input>
                </form>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                Inputs with icon
            </div>
            <div class="type-content">
                <form class="flex flex-col">
                    <msc-input class="w-52 msc-input--no-border" type="text" label="Label" icon="icon-question-mark-circle-1" placeholder="Placeholder"></msc-input>
                </form>
                <form class="flex flex-col">
                    <msc-input class="w-52 msc-input--no-border" type="text" label="Label" iconRight="icon-question-mark-circle-1" placeholder="icon to the right ->"></msc-input>
                </form>
                <form class="flex flex-col">
                    <msc-input class="w-52 msc-input--no-border" type="text" label="Label" icon="icon-question-mark-circle" iconRight="icon-question-mark-circle-1" placeholder="<- icons everywhere ->"></msc-input>
                </form>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                Input with a clear button
            </div>
            <div class="type-content">
                <form class="flex flex-col">
                    <msc-input class="w-52" type="text" label="Label" placeholder="Placeholder" clearBtn="true"></msc-input>
                </form>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                Input with a password toggle
            </div>
            <div class="type-content">
                <form class="flex flex-col">
                    <msc-input class="w-52" type="password" label="Label" placeholder="Placeholder"></msc-input>
                </form>
            </div>
        </div>
    </div>
</div>
