import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'msc-empty-state',
    templateUrl: './empty-state.component.html'
})
export class EmptyStateComponent implements OnInit {
    @Input() classes: string = 'empty__state';
    @Input() source: string = 'https://static.myskillcamp.com/images/no-results.svg';
    @Input() title: string;
    @Input() description: string;
    @Input() hasPicture: boolean = true;
    @Input() cypressHook: string = '';

    constructor() {}

    ngOnInit(): void {}
}
