import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef, ViewChild } from '@angular/core';
//
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonListDatum, CommonListSelection } from '../list/list.component';
import { CommonOverlayComponent } from '../overlay/overlay.component';
//
export type CommonDropdownType = 'single' | 'multi';
export interface CommonDropdownDatum<T> extends CommonListDatum<T> { isSelected: boolean; }

@Component({
    selector: 'msc-common-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonDropdownComponent<T> {
    @Input() items: Array<CommonDropdownDatum<T>> = []; // data of select options
    @Input() model: any;
    @Output() modelChange: EventEmitter<CommonDropdownDatum<T>> = new EventEmitter<CommonDropdownDatum<T>>()
    @Input() hasSearch = false;
    @Input() type: CommonDropdownType = 'single';
    @Input() isButton = false;
    @Input() infiniteLoad;
    @Input() disabled = false;
    @Input() placeholder = 'Select';
    @ViewChild(CommonOverlayComponent) dropdown;
    public isOpen = false;
    private pagination: any = {
        currentPage: 0
    };
    private query: string;
    public isLoading = false;
    public itemsClone;
    private selectionArray = [];
    private selectionModel = new Map<string, CommonListSelection<T>>();
    public overlayRoot = [];

    constructor(
        private readonly elementRef: ElementRef,
        private cdRef: ChangeDetectorRef
    ) { }

    get width(): number { return this.elementRef.nativeElement.getBoundingClientRect()?.width; }

    ngOnInit(): void {
        if (!this.infiniteLoad) {
            this.setDataOption();
        }
    }

    ngOnChanges(): void {
        if (!Array.isArray(this.items)) {
            return;
        }
        this.setDataButton();
    }
    /**
     * Set the data for option template
     */
    setDataOption(): void {
        this.itemsClone = [...this.items];
    }

    /**
     * Set the data for button template
     */
    setDataButton(): void {
        if (!Array.isArray(this.items) || !this.items.length || this.infiniteLoad) { return; }

        setTimeout(() => {
            this.cdRef.detectChanges();
        }, 100);
        if (this.type !== 'single') { return; }
        this.onClose();
    }

    /**
     * Event handler for close
     */
    onClose(): void {
        if (!this.isOpen)
            return;
        this.isOpen = false;
        this.dropdown.close();
        this.cdRef.detectChanges();

        this.setDataOption();
    }

    /**
     * Event handler for open
     */
    onOpen(): void {
        this.isOpen = !this.isOpen;
        if (this.infiniteLoad) {
            this.pagination.currentPage = 0;
            this.items = [];
            this.setDataOption();
            this.loadData();
        }
    }

    loadData(query?) {
        if (this.pagination.currentPage === this.pagination.lastPage || this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.infiniteLoad(query, this.pagination).then(({ data, pagination }) => {
            this.pagination = pagination;
            this.items = [...this.items, ...data as Array<CommonDropdownDatum<T>>];
            this.setDataOption();
            this.isLoading = false;
        })
    }

    /**
     * Event handler for search
     */
    onSearch({ data, value }: CommonSearchOutput<CommonDropdownDatum<T>>): void {
        if (this.infiniteLoad) {
            this.items = [];
            this.setDataOption();
            this.pagination.currentPage = 0;
            this.query = value;
            this.loadData(value);
        } else {
            if (!Array.isArray(data)) { return; }
            this.itemsClone = data as Array<CommonDropdownDatum<T>>;
        }
    }

    updateOverlayRoot(htmlElement) {
        this.overlayRoot.push(htmlElement.rootNodes[0]);
    }

    getLeafs(model, acc) {
        if (model) {
            model.forEach((value, key) => {
                if (!value.selectionModel) {
                    acc.push(value);
                } else {
                    this.getLeafs(value.selectionModel, acc);
                }
            })
        }

        return acc;
    }

    handleSelection(data: Map<string, CommonListSelection<T>>) {
        if (this.type === 'single') {
            this.model = data.values().next().value;
            this.modelChange.emit(this.model);
            this.onClose();
        } else {
            console.log(data);
            
            this.model = this.getLeafs(data, []);
        }
        // debugger
    }
}
