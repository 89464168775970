import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';
import { DataHelper } from '@helpers';
import { CompanyCertificatesProvider } from '@lighty';
import { TranslateService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs';

@Component({
    selector: 'app-generated-certificates',
    templateUrl: './generated-certificates.component.html',
    styleUrls: ['./generated-certificates.component.scss'],
})
export class GeneratedCertificatesComponent implements OnInit {
    @ViewChild('modalDelete') modalDelete: CommonModalComponent;
    public emitedCertificates: any;
    public dateFormat: String;
    public isLoading: boolean = false;
    public isLoadingFilter: boolean = false;
    public filters: Array<any> = [];
    public filteredFilters: Array<any> = [];
    public currentSelectedFilter: any = undefined;
    public currentSelectedFilteredFilters: any = undefined;
    public inputFilter: string = '';
    public paginator: any;
    public paginationItems: any;
    public currentDeleteItem: any;

    constructor(
        private companyCertificatesProvider: CompanyCertificatesProvider,
        private cookieService: CookieService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.getCertificates();
        this.filters = [
          {
              key: this.translateService.instant('words.email'),
              value: 'email'
          },
          {
              key: this.translateService.instant('common.status'),
              value: 'status'
          },
          {
              key: this.translateService.instant('words.experience-name'),
              value: 'experience'
          },
      ]
    }

    public getCertificates(): void {
        this.isLoading = true;
        this.companyCertificatesProvider
            .getCertificates(this.cookieService.get('current_company'))
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    this.isLoading = false;
                    this.emitedCertificates = data.data;
                    this.buildPaginator(data.pagination);
                    this.paginator = data.pagination;
                    console.log(this.emitedCertificates);
                },
                error: (err) => {
                    this.isLoading = false;
                    console.log(err);
                }
            })
    }

    formatData(dateFormat: Date): String {
        const date = new Date(dateFormat);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    public openModalDelete(row: any): void {
      this.currentDeleteItem = row;
      this.modalDelete.onOpen();
    }

    public copyLink(id: string): void {
        DataHelper.clipboard(id);
        this.toastService.onInfo(this.translateService.instant('words.copied'));
    }

    public clearFilters(): void {
    this.currentSelectedFilter = undefined;
    this.currentSelectedFilteredFilters = undefined;
    this.inputFilter = '';
    this.getCertificates();
    }

    public buildPaginator(paginationInfo: any): void {
      console.log('buildpaginator')
      this.paginator = undefined;
      this.paginationItems = [];
      this.paginationItems = Array(paginationInfo.lastPage).fill(0).map((x, i) => i);
      setTimeout(() => {
        const paginationNumbers = document.querySelectorAll('.paginationItem');
        paginationNumbers[0].className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white';
      }, 100);
    }
    
    public setPaginator(index: number, e: any): void {
      console.log('setpaginator')
      const element = e.target as HTMLElement;
      const paginationNumbers = document.querySelectorAll('.paginationItem');
      paginationNumbers.forEach(item => {
        (item as HTMLElement).className = 'paginationItem flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
      });
      element.className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white';
      this.applyPagination(index);
    }
  
    public lastFirstPage(origin: string): void {
      console.log('lastfirstpage ', origin)
      console.log(this.paginator)
      const paginationNumbers = document.querySelectorAll('.paginationItem');
      paginationNumbers.forEach(item => {
        (item as HTMLElement).className = 'paginationItem flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
      });
      if(origin == 'first') {
        paginationNumbers[0].className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
        this.applyPagination(1);
      } else {
        paginationNumbers[this.paginator.lastPage -1].className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
        this.applyPagination(this.paginator.lastPage);
      }
    }
    
    public applyPagination(pageIndex: number): void {
      console.log('applyPagination')
      this.isLoading = true;
      let params;
      if(this.currentSelectedFilter && (this.currentSelectedFilteredFilters || this.inputFilter)) {
         params = {
          page: pageIndex,
          filter: this.currentSelectedFilter != undefined ? this.currentSelectedFilter?.value : '',
          value: this.inputFilter == '' ? this.currentSelectedFilteredFilters?.value : this.inputFilter
        }
      } else {
        params = {
          page: pageIndex,
        }
      }
      
      this.companyCertificatesProvider
      .getCertificatesPaginated(this.cookieService.get('current_company'), params)
      .pipe(take(1))
      .subscribe(
        (data) => {
            console.log(data)
            this.isLoading = false;
          if(data){
            this.paginator = data?.pagination;
            this.emitedCertificates = data?.data;
           // this.buildPaginator(this.paginator);
          } else {
            this.paginator = undefined;
            this.emitedCertificates = [];
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
    }
    
    public filter(): void {
      this.paginator = undefined;
      this.paginationItems = [];
      this.isLoading = true;
      const params = {
        filter: this.currentSelectedFilter.value,
        value: this.inputFilter == '' ? this.currentSelectedFilteredFilters.value : this.inputFilter
      }
      this.companyCertificatesProvider
      .getCertificatesFiltered(this.cookieService.get('current_company'), params)
      .pipe(take(1))
      .subscribe(
        (data) => {
            console.log(data)
            this.isLoading = false;
          if(data){
            this.emitedCertificates = data?.data;
            this.buildPaginator(data?.pagination);
            this.paginator = data?.pagination;
            console.log(this.paginator)
          } else {
            this.paginator = undefined;
            this.emitedCertificates = [];
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
    }

      public onSelectType(filter: any, origin: any): void {
        if(origin == 'filter') {
          this.currentSelectedFilter = filter;
          this.currentSelectedFilteredFilters = undefined;
          if(filter.value != 'email') {
            this.inputFilter = '';
            this.isLoadingFilter = true;
            const params = {
              filter: this.currentSelectedFilter.value
            };
            this.companyCertificatesProvider.getFilterCertificates(params).pipe(take(1)).subscribe({
              next: (data) => {       
                this.isLoadingFilter = false;
                console.log(data);
                this.filteredFilters = data.data;
              },
              error: (err) => {
                this.isLoading = false;
                this.isLoadingFilter = false;
                console.log(err);
              }
            })
          }
        } else {
          this.currentSelectedFilteredFilters = filter;
        }
      }

      public revokeCerficate(): void {
        const params = {
          xertify_transaction: this.currentDeleteItem.id
        }
        this.companyCertificatesProvider.revokeCertificate(params).pipe(take(1)).subscribe({
          next: (data) => {
            this.getCertificates();
            this.modalDelete.onClose();
            this.toastService.onSuccess(this.translateService.instant(data.message));
            console.log(data)
          },
          error: (err) => {
            console.log(err)
            this.toastService.onSuccess(this.translateService.instant(err.error.error));
          }
        })
      }

      public downloadCertificate(certificate: any): void {
        const params = {
          transaction_id: certificate.id
        }
        this.companyCertificatesProvider.downloadCertificate(params, certificate.course).pipe(take(1)).subscribe({
          next: (data) => {
            console.log(data)
          },
          error: (err) => {
            console.log(err)
          }
        })
      }

      public sendCertificate(certificateId: any): void {
        this.companyCertificatesProvider.sendCertificate(certificateId).pipe(take(1)).subscribe({
          next: (data) => {
            console.log(data)
            this.toastService.onSuccess(this.translateService.instant(data.message));
          },
          error: (err) => {
            console.log(err)
          }
        })
      }
}
