<div class="breadcrumb">
    <p class="breadcrumb-item" (click)="goBack(true)"><span>{{ 'stats.title' | translate }}</span></p>
    <p class="breadcrumb-item" (click)="goBack()">
        <ng-container *ngIf="content">
            <i class="icon icon-{{ content.icon }}"></i>
            <span>{{ content.title }}</span>
        </ng-container>
    </p>
    <p class="breadcrumb-item">
        <ng-container *ngIf="user">
            <span>{{ user.name }}</span>
        </ng-container>
    </p>
</div>

<div class="stats-cards">
    <ng-container *ngFor="let card of cards">
        <div class="stats-card">
            <h6>{{ card.label }}</h6>
            <ng-container [ngSwitch]="card.type">
                <ng-container *ngSwitchCase="'status'">
                    <div class="stats-card-status" [ngClass]="{ 'is-subscribed' : card?.isSubscribed }">
                        <span>{{ card.value }}</span>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'duration'">
                    <div class="stats-card-duration">{{ card.value ? (card.value | duration) : 0 + 's' }}</div>
                </ng-container>

                <ng-container *ngSwitchCase="'chart'">
                    <msc-chart-pie [size]="'medium'" [value]="card.value"></msc-chart-pie>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>

<div class="toolbar">
    <div class="wrapper">
        <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
    </div>
</div>

<div class="content">
    <ng-container [ngSwitch]="status$ | async">
        <ng-container *ngSwitchCase="'success'">
            <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                <msc-common-table [columns]="columns" [rows]="rows$ | async">
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span></span>
                    </ng-template>


                    <ng-template #tdTemplate let-datum="datum">
                        <div class="grouping">
                            <span class="grouping-title">{{ datum.title }}</span>
                            <div class="tag">
                                <i class="icon icon-video" *ngIf="content.type === 'media'"></i>
                                <div class="tag__text">{{ datum.label }}</div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <span>{{ datum.duration | duration }}</span>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <ng-container *ngIf="datum.isChartProgressVisible; else emptyChartProgressTemplate">
                            <msc-chart-pie [size]="'small'" [value]="datum.chartProgress" [valueColor]="'#8290fd'"></msc-chart-pie>
                        </ng-container>
                        <ng-template #emptyChartProgressTemplate>
                            <span>{{ 'common.empty' | translate }}</span>
                        </ng-template>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <ng-container *ngIf="datum.isChartScoreVisible; else emptyChartScoreTemplate">
                            <msc-chart-pie [size]="'small'" [value]="datum.chartScore" [valueColor]="'#8290fd'"></msc-chart-pie>
                        </ng-container>
                        <ng-template #emptyChartScoreTemplate>
                            <span class="text-center w-[112px]">&#x2212;</span>
                        </ng-template>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <msc-button icon="icon-arrow-ios-right" type="fab" (click)="goToTemplateDetails(datum)"></msc-button>
                    </ng-template>
                </msc-common-table>
            </ng-container>

            <ng-template #emptyTemplate>
                <msc-common-empty [message]="'common.empty' | translate">
                    <ng-template></ng-template>
                </msc-common-empty>
            </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'loading'">
            <msc-common-spinner *ngIf="true"></msc-common-spinner>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
            <msc-common-error>
                <ng-template></ng-template>
            </msc-common-error>
        </ng-container>
    </ng-container>
</div>

<ng-container *ngIf="hasLoadMore">
    <div class="load-more">
        <msc-button class="msc-button--primary" palette="primary" (click)="onLoad()">{{ 'words.load-more' | translate }}</msc-button>
    </div>
</ng-container>
