import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonToastService } from '@common2/services/toast.service';
import { DownloadHelper, HttpHelper } from '@helpers';
import { ContentProvider, Template, JourneyProvider, Company, ConversationProvider } from '@lighty';
import { EmitterService, StorageService, TranslateService } from '@services';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
    selector: 'msc-learn-detail-journey-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss'],
})

export class LearnDetailJourneyPresentationComponent {
    private company: Company;
    public journey: any;
    public loaderSessions: boolean = false;
    public templates: any[] = [];
    public checkboxDefault: any[] = [];
    public dropdownSession: number[] = [];
    public instancesList: any[] = [];
    public events: any[] = [];
    public sendingMessage: boolean = false;
    public journeyCollection: any[] = [];
    public courseSessionFull: boolean = false;
    public journeyIsFull: boolean = false;
    public detailsFooter: any = {
        numberSessions: 0,
        numberEvents: 0
    };
    changeLearnLaterContent: Subscription;

    constructor(
        private route: ActivatedRoute,
        private toastService: CommonToastService,
        private contentProvider: ContentProvider,
        private journeyProvider: JourneyProvider,
        private storageService: StorageService,
        private router: Router,
        private emitterService: EmitterService,
        private conversationProvider: ConversationProvider,
        private translateService: TranslateService,
    ) { }

    get logo(): string { return `url(${this.getLogo(this.journey.provider)})`; }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.journey = this.storageService.get('journey') || this.route.parent.snapshot.data.journey;
        this.journeyIsFull = (this.journey.usedSeats >= this.journey.usersLimit && this.journey.usersLimit !== 0);

        if (this.storageService.getFlash('card_redirect') && this.journey.subscribed && !this.journey.hasToSubscribe) {
            const url = `learn/${this.company.slug}/detail/journey/${this.journey.id}/summary`;
            this.router.navigateByUrl(url);
        } else {
            this.checkSubscribeToJourney();
        }

        this.changeLearnLaterContent = this.emitterService
            .get('changeLearnLaterContent')
            .pipe(skip(1))
            .subscribe((saved) => {
                if (saved) {
                    this.deleteSavedContent();
                } else {
                    this.addToSavedContents();
                }
            })
    }

    notifyAdmin(): void {
        this.sendingMessage = true;
        let message;

        if (this.journeyIsFull) {
            message = this.translateService.instant('journey.learning-path-full.message', { title: this.journey.title });
        } else {
            message = this.translateService.instant('journey.learning-path-content-session-full.message', { title: this.journey.title });
        }
        const params = {
            companyId: this.company.id,
            accounts: this.journey.contactIds,
            medias: [],
            message
        };

        this.conversationProvider
            .create(params)
            .subscribe({
                next: (conv) => {
                    this.router.navigateByUrl(`/conversation/${conv.data}`);
                },
                error: () => {
                    this.sendingMessage = false;
                }
            });
    }

    checkSubscribeToJourney(): void {
        this.loaderSessions = true;

        if (!this.journey.subscribed || this.journey.hasToSubscribe) {
            this.journeyProvider.getTemplates(this.company.id, this.journey.id).subscribe((data) => {
                this.loaderSessions = false;
                this.templates = data || [];

                for (const template of this.templates) {
                    for (const instance of template.instances) {
                        if (instance.isSubscribed) {
                            this.detailsFooter.numberSessions += 1;

                            if (instance.events?.length > 0) {
                                this.detailsFooter.numberEvents += 1;
                            }
                        }
                    }
                }
                this.mergeArrays();
                this.autoCheckedInstances(this.templates);
            });

            const contents = this.journey.contents.filter((content) => content.context === 'content');

            for (const content of contents) {
                if (content.playerContext === 'event') {
                    this.detailsFooter.numberEvents += 1;
                }
            }
        } else {
            const params = HttpHelper.cleanParams({
                include: 'course_settings'
            });

            this.journeyProvider.getEvents(this.company.id, this.journey.id, params).subscribe((data) => {
                this.loaderSessions = false;
                this.events = data || [];
            });
        }
    }

    mergeArrays(): void {
        for (const content of this.journey.contents) {
            const index = this.templates.findIndex(template => template.id === content.contextId);
            if (index === -1) {
                this.journeyCollection.push(content);
            } else {
                const session = [];
                this.journeyCollection.push(this.templates[index]);
                for (const instance of this.templates[index].instances) {
                    if (instance.usersCount >= instance.usersLimit && instance.isSubscribed === false && instance.usersLimit !== 0) {
                        session.push(instance);
                    }
                }
                if (session?.length === this.templates[index].instances?.length) {
                    this.templates[index].isFull = true;
                    this.courseSessionFull = true;
                }
            }
        }
    }

    autoCheckedInstances(templates: Template[]): void {
        templates.forEach((template, index) => {
            this.checkboxDefault[index] = [];
            if ((template.instances?.length > 1 || (template.instances[0]?.events && template.instances[0]?.events?.length > 0)) && !template.instances[0]?.isSubscribed) {
                this.addInstance(index, 0, template.instances[0]);
            }
            // } else if (template.instances?.length === 1 && (!template.instances[0].events || template.instances[0].events?.length <= 0)) {
            //     this.detailsFooter.numberSessions++;
            // }
        });
    }

    checkInstancesToDisplay(template): string {
        if (template.schema === 'template') {
            if (template.instances?.length === 0) {
                return 'nosession';
            } else if (template.instances?.length === 1 && !template.instances[0].events?.length) {
                return 'onesessiondigital';
            } else if (template.isFull) {
                return 'sessionsfull';
            } else {
                let result = 'display';

                template.instances.forEach(instance => {
                    if (instance.isSubscribed) {
                        result = 'alreadysubscribed';
                    }
                });

                return result;
            }
        }
    }

    addInstance(index: number, instanceIndex: number, instance: any): void {
        // if (!this.instancesList[index]) {
        //     this.detailsFooter.numberSessions++;

        //     if (instance.events?.length > 0) {
        //         this.detailsFooter.numberEvents += 1;
        //     }
        // }
        this.instancesList[index] = instance;

        this.checkboxDefault[index] = [];
        this.checkboxDefault[index][instanceIndex] = true;
        this.countEvents();
    }

    countEvents(): void {
        this.detailsFooter.numberEvents = 0;

        this.instancesList.forEach((instance) => {
            this.detailsFooter.numberEvents += instance.eventsCount;
        });
    }

    openDropdownSession(templateIndex, instanceIndex): void {
        if (this.dropdownSession[templateIndex] === undefined) {
            this.dropdownSession[templateIndex] = null;
        }
        if (this.dropdownSession[templateIndex] !== instanceIndex) {
            this.dropdownSession[templateIndex] = instanceIndex;
        } else {
            this.dropdownSession[templateIndex] = null;
        }
    }

    getAllSessionsToSubscribe(collection: any): any[] {
        const transformedArray = [];

        collection.forEach((template) => {
            if (template.instances?.length === 1) {
                transformedArray.push(template.instances[0].id);
            } else {
                template.instances.forEach(instance => {
                    if (instance.isSubscribed) {
                        transformedArray.push(instance.id);
                    }
                });
            }
        });
        return transformedArray;
    }

    checkSubscription(): void {
        let instancesToSubscribe = [];

        this.instancesList.forEach((instance) => {
            instancesToSubscribe.push(instance.id);
        });

        instancesToSubscribe = instancesToSubscribe.concat(this.getAllSessionsToSubscribe(this.templates));

        const filteredInstances = instancesToSubscribe.filter(element => {
            return element !== undefined;
        });

        const params = HttpHelper.cleanParams({
            instances: filteredInstances
        });

        this.journeyProvider
            .subscribe(this.company.id, this.journey.id, params)
            .subscribe(() => {
                this.emitterService.set('refresh.content', true);
                const url = `learn/${this.company.slug}/detail/journey/${this.journey.id}/summary`;
                this.router.navigateByUrl(url);
            });
    }

    addToSavedContents(): void {
        this.journey.saved = true;

        const params = {
            context: 'traject',
            context_id: this.journey.id
        };

        this.contentProvider.addSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.saved'));
        });
    }

    deleteSavedContent(): void {
        this.journey.saved = false;

        const params = {
            context: 'traject',
            context_id: this.journey.id
        };

        this.contentProvider.deleteSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.deleted'));
        });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    addToCalendar(content: any): void {
        DownloadHelper.download(content.data.event.eventSessions[0].icsCalendar, '', false);
    }

    isChecked(templateIndex: number, instanceIndex: number): boolean {
        if (this.checkboxDefault[templateIndex]) {
            return this.checkboxDefault[templateIndex][instanceIndex];
        }

        return false;
    }

    ngOnDestroy(): void {
        this.changeLearnLaterContent.unsubscribe();
    }

    getLogo(provider: any) {
        switch (true) {
            case provider && provider.hasOwnProperty('media') && !!provider.media: return `${provider.media.pictureUrl}?size=256`;
            case provider && !provider.hasOwnProperty('media'): return `https://static.myskillcamp.com/images/integrations/${provider.key}.svg`;
            default: return null;
        }
    }
}
