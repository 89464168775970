import * as moment from 'moment';

export class TimeUtils {
    static REFERENCE = moment();
    static TODAY = TimeUtils.REFERENCE.clone().startOf('day');
    static YESTERDAY = TimeUtils.REFERENCE.clone().subtract(1, 'days').startOf('day');
    static A_WEEK_OLD = TimeUtils.REFERENCE.clone().subtract(7, 'days').startOf('day');

    static isToday(momentDate) {
        return momentDate.isSame(TimeUtils.TODAY, 'd');
    }

    static isYesterday(momentDate) {
        return momentDate.isSame(TimeUtils.YESTERDAY, 'd');
    }

    static isOlderThanYesterday(momentDate) {
        return !momentDate.isAfter(TimeUtils.YESTERDAY);
    }

    static isWithinAWeek(momentDate) {
        return momentDate.isAfter(TimeUtils.A_WEEK_OLD);
    }

    static isTwoWeeksOrMore(momentDate) {
        return !TimeUtils.isWithinAWeek(momentDate);
    }
}