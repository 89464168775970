import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '@services';

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (this.authService.isLogged()) {
            this.router.navigate(['login', 'company'], route.queryParams && route.queryParams.redirect ? { queryParams: route.queryParams, queryParamsHandling: 'merge' } : undefined);
        }
        return true;
    }
}
