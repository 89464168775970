import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { mergeScan, map, tap, repeat, finalize, takeUntil } from 'rxjs/operators';
//
import { AdminMemberProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-camp-member',
    templateUrl: './member.component.html',
    styleUrls: ['./member.component.scss'],
})

export class CampMemberComponent {
    private query: string;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public me: any;
    public access: any;
    public camp: any;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public isLoadingMore$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.SUCCESS);
    public hasLoadMore: boolean = false;
    public displays: any = {
        modals: {
            members: false
        },
        dropdowns: []
    };
    public loading: any = {
        members: false,
        save: false
    };
    public availableMembers: any;
    public memberDelete: any;

    constructor(
        private route: ActivatedRoute,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private memberProvider: AdminMemberProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.setContent();
        this.onQueryMemberList();
        this.onQueryAvailableMemberList();

        this.route.queryParams.subscribe((params) => {
            switch (params.actions) {
                case 'invitation':
                    const timeOut = setTimeout(() => {
                        this.displayed('modals', 'members');
                        clearTimeout(timeOut);
                    }, 1000);
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.me = this.storageService.get('me');
        this.camp = this.storageService.get('camp');
        this.access = this.storageService.get('access');
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '30%',
            },
            {
                key: 'job-learn',
                label: this.translateService.instant('words.learn'),
                width: '10%',
            },
            {
                key: 'job-organize',
                label: this.translateService.instant('words.organise'),
                width: '10%',
            },
            {
                key: 'job-settings-camp',
                label: this.translateService.instant('words.job-settings-camp'),
                width: '10%',
            },
            {
                key: 'last-connection',
                label: this.translateService.instant('words.last-connection'),
                width: '15%',
            },
            {
                key: 'active-since',
                label: this.translateService.instant('words.active-since'),
                width: '20%',
            },
            {
                key: '',
                width: '5%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryMemberList(pageValue: number) {
        return Object.assign({
            include: 'camp',
            page: pageValue,
        }, this.query ? { q: this.query } : {});
    }

    /**
     *
     */
    onQueryMemberList(isAppend: boolean = true) {
        this.memberProvider
            .getMembers(this.camp.id, this.getParamsOnQueryMemberList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryMemberList(response.pagination)),
                map((response) => this.onMapOnQueryMemberList(response.members)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rows$.getValue() : []),
                tap((rows) => this.rows$.next(rows)),
                finalize(() => { this.status$.next(CommonSubjectStatus.SUCCESS); this.isLoadingMore$.next(CommonSubjectStatus.SUCCESS) }),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryMemberList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryMemberList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data;
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.query = value || null;
        this.page$.next(1);
        this.onQueryMemberList(false);
    }

    /**
     *
     */
    onLoad(): void {
        this.isLoadingMore$.next(CommonSubjectStatus.LOADING);
        this.page$.next(this.page$.getValue() + 1);
        this.onQueryMemberList();
    }

    /**
     *
     */
    onQueryAvailableMemberList(): void {
        this.memberProvider.getAvailableMembers(this.camp.id).subscribe((data) => {
            this.availableMembers = data;
        });
    }

    /**
     *
     */
    displayed(key: string, type: string, index?: number): void {
        if (index) {
            this.displays[key][index] = !this.displays[key][index];
        } else {
            this.displays[key][type] = !this.displays[key][type];
        }
    }

    /**
     *
     */
    addMembers(event: any): void {
        this.loading.save = true;

        if (event.invite_all_accounts) {
            delete event.account_ids;
        }
        this.memberProvider.inviteMembers(this.camp.id, HttpHelper.cleanParams(event)).subscribe(() => {
            if (event.invite_all_accounts) {
                this.toastService.onSuccess(this.translateService.instant('toast.users-added-undefined'));
            } else {
                this.toastService.onSuccess(this.translateService.instant(event.account_ids.length > 1 ? 'toast.users-added' : 'toast.user-added', { value: event.account_ids.length }));
            }
            this.loading.save = false;
            this.displays.modals.members = false;
            this.onQueryMemberList(false);
            this.onQueryAvailableMemberList();
        });
    }

    /**
     *
     */
    onDeleteMember(member: any): void {
        if (!member) { return; }
        this.memberProvider
            .delete(this.camp.id, member.id)
            .pipe(
                tap(() => {
                    const members = this.rows$.getValue();
                    const membersAfterDelete = members.filter((m) => m.id !== member.id);
                    this.rows$.next(membersAfterDelete);
                })
            )
            .subscribe();
    }

    updateRoles(modal: CommonModalComponent, member: any, type: string, key: string): void {
        member[type][key] = !member[type][key];

        if (this.countRoles(member.roles) === 0) {
            member[type][key] = !member[type][key];
            this.memberDelete = member;

            modal.onOpen();
            return;
        }

        if (!member.roles.campAdministrator) {
            member.permissions.manageCampSettings = false;
        }
        const params = {
            roles: member.roles,
            permissions: member.permissions
        };

        this.memberProvider.updateRoles(this.camp.id, member.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    /**
     *
     */
    setAndDeleteMember(modal: CommonModalComponent, member: any): void {
        this.memberDelete = member;
        modal.onOpen();
    }

    /**
     *
     */
    countRoles(roles: any): number {
        let counter = 0;

        for (const key in roles) {
            if (roles[key] && key !== 'companyOwner') {
                counter++;
            }
        }
        return counter;
    }
}
