import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Pagination, CompanyUserAccessManageProvider } from '@lighty';
import { EmitterService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-aside-access-manage-instance',
    templateUrl: './aside-manage-instance.component.html'
})
export class AsideAccessManageInstanceComponent {
    @ViewChild('aside') aside;
    @Input() accountId: number;
    @Input() company: any;
    @Input() instances: any[];
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public onCloseByUser: EventEmitter<any> = new EventEmitter();
    public query: string;
    public pagination: Pagination;
    public loading = {
        load: false,
        more: false
    };
    public availableInstances: any = [];

    constructor(
        private companyUserAccessManageProvider: CompanyUserAccessManageProvider,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.loading.load = true;
        this.getAvailableInstances();
    }

    onOpen() {
        this.init();
        this.aside.onOpen();
    }

    close(): void {
        this.onClose.emit(true);
    }

    getAvailableInstances(): void {
        this.companyUserAccessManageProvider.getAvailable(this.company.id, this.accountId).subscribe((data) => {
            this.availableInstances = data.instances;
            this.pagination = data.pagination;
            this.loading.load = false;
        });
    }

    addInstance(instance: any): void {
        const index = this.availableInstances.findIndex((result) => {
            return result.data.id === instance.data.id;
        });

        if (index !== -1) {
            this.availableInstances.splice(index, 1);
        }

        const params = HttpHelper.cleanParams({
            context: instance.type
        });

        this.companyUserAccessManageProvider.addInstance(this.company.id, this.accountId, instance.data.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.instances.push(instance);

            this.emitAccess();
        });
    }

    emitAccess(): void {
        if (this.instances.length === 1) {
            this.emitterService.set('update.access', { role: 'companyManager', enable: true });
        }
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query,
        });

        this.companyUserAccessManageProvider.getAvailable(this.company.id, this.accountId, params).subscribe((data) => {
            this.availableInstances = data.instances;
            this.pagination = data.pagination;
        });
    }

    loadMore(): void {
        this.loading.more = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.currentPage + 1,
        });

        this.companyUserAccessManageProvider.getAvailable(this.company.id, this.accountId, params).subscribe((data) => {
            this.availableInstances = this.availableInstances.concat(data.instances);
            this.pagination = data.pagination;
            this.loading.more = false;
        });
    }
}
