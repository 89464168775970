import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ContentProvider, Pagination } from '@lighty';
import { ParamsService, StorageService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-manage-team-modal-subscribe-users',
    templateUrl: './subscribe-users.component.html',
    styleUrls: ['./subscribe-users.component.scss']
})
export class ManageTeamModalSubscribeUsersComponent implements OnInit {
    @Input() companyId: number;
    @Input() accountId: number;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public buildFilters: EventEmitter<any> = new EventEmitter();
    public pagination: Pagination;
    public catalogue: any[] = [];
    public selectedContent: any;
    public lastSelected: any;
    public sessionList: any;
    public sessionSelection = { course: null };
    public contex: string = 'default';
    public data: any;
    public phase: number = 0;
    public query: string;
    public filters: any = {};
    public activeFilters: boolean = false;
    public isContentSelected = false;
    public loading: boolean = false;
    public isActive: boolean = false;

    constructor(private contentProvider: ContentProvider, private paramsService: ParamsService, private storageService: StorageService) { }

    ngOnInit(): void {
        this.isActive = true;
        this.lastSelected = this.storageService.getCache('subscribe.last_selected');

        this.data = {
            context: '',
            contentId: null,
            sessionIds: []
        };

        this.search();
    }

    private storeSelectedContent(): void {
        this.storageService.setCache('subscribe.last_selected', this.selectedContent, 10);
    }

    search(query?: string): void {
        this.loading = true;
        const params = this.paramsService.getOnboarding(this.filters, this.query || query, null);

        this.contentProvider.getCoachOnboardingContents(this.companyId, this.accountId, params).subscribe((data) => {
            this.catalogue = data.catalogue.filter(content => content.data.id !== this.lastSelected?.data.id);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    loadMore(): void {
        this.loading = true;
        const params = this.paramsService.getOnboarding(this.filters, this.query, null, this.pagination);

        this.contentProvider.getCoachOnboardingContents(this.companyId, this.accountId, params).subscribe((data) => {
            this.catalogue = this.catalogue.concat(data.catalogue).filter(content => content.data.id !== this.lastSelected?.data.id);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    selectContent(content?: any): void {
        this.isContentSelected = true;
        this.sessionList = null;

        if (!content) {
            this.selectedContent = null;
            this.save()
            return;
        }

        if (content.data.id === this.selectedContent?.data?.id) {
            this.selectedContent = null;
        } else {
            this.selectedContent = content;
            this.data.context = content.type;
            this.data.contentId = content.data.id;

            if (content.type !== 'external_content') {
                const params = HttpHelper.cleanParams({
                    context: content.type
                });
                
                this.contentProvider.getCoachOnboardingContentSessions(this.companyId, content.data.id, this.accountId, params).subscribe((data) => {
                    this.sessionList = data;
                    this.phase = 1;
                    this.checkIfOneAndSelect();
                });
            }
        }
    }

    selectInstance(session: any, ctx?: any): void {
        if (!ctx) {
            this.sessionSelection['course'] = session;
            this.isContentSelected = true;
        } else {
            this.sessionSelection[ctx] = session;
            this.isContentSelected = this.checkIfAtleastOneSelection();
        }
    }

    checkIfOneAndSelect(): void {
        if (this.data.context === 'course_template') {
            if (this.sessionList.length === 1) {
                this.sessionList.forEach(element => {
                    element.selected = false;
                });
                this.selectInstance(this.sessionList[0]);
            }
        } else {
            this.sessionList.forEach(mod => {
                if (mod.instances?.length === 1) {
                    this.selectInstance(mod.instances[0], mod.id);
                } else if (mod.instances?.length > 1) {
                    mod.display = true;
                }
            });
        }
    }

    checkIfAtleastOneSelection(): boolean {
        return Object.entries(this.sessionSelection).every(([key, val]) => key !== 'course' ? val : true);
    }

    back(): void {
        this.sessionSelection = {
            course: null
        };
        this.isContentSelected = false;
        this.phase = 0;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        this.storeSelectedContent();

        if (this.data.context === 'course_template') {
            this.data.sessionIds = this.sessionSelection['course'] ? [this.sessionSelection['course'].id] : [];
        } else {
            this.data.sessionIds = Object.values(this.sessionSelection).filter(sel => sel).map(sel => sel.id);
        }

        this.onSave.emit(this.data);
        this.close();
    }
}
