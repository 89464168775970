import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-shared-aside-export-reporting',
    templateUrl: './aside-export-reporting.component.html'
})
export class SharedExportReportingComponent implements OnInit {
    @ViewChild('aside') aside;
    @Input() granularity: string = 'day';
    @Input() diff: number = 366;
    @Input() options: any = {};
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public data: any;

    constructor() {}

    ngOnInit(): void {
        this.data = {
            format: 'xlsx',
            granularity: this.granularity,
            chart: this.getChart()
        };
    }

    public onOpen() {
        this.aside.onOpen();
    }

    private getChart(): string {
        if (this.options) {
            if (this.options.usage) {
                return 'learner_content_subscribed';
            }
            if (this.options.providers) {
                return 'learner_subscriptions';
            }
        }
        return null;
    }

    change(key: string, value: string): void {
        this.data[key] = value;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        this.onSave.emit(HttpHelper.cleanParams(this.data));
    }
}
