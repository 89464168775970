import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination, Company, StatsProvider } from '@lighty';
import { HttpHelper } from '@helpers';
import { StorageService } from '@services';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-manage-instance-stats-dispatcher',
    templateUrl: './dispatcher.component.html'
})
export class ManageInstanceStatsDispatcherComponent implements OnInit, OnDestroy {
    private company: Company;
    private instance: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public meta: any;
    public header: any;
    public items: any;
    public pagination: Pagination;
    public query: string;
    public loading: boolean = false;
    public parameters: any = {
        entity: null,
        entityId: null,
        display: null,
        courseId: null
    };

    constructor(private route: ActivatedRoute, private router: Router, private statsProvider: StatsProvider,
                private storageService: StorageService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.instance = this.storageService.get('instance');

        this.subscriptionCollection.subscribe = this.route.data.subscribe((data) => {
            this.meta = data.stats.meta;
            this.header = data.stats.header;
            this.items = data.stats.items;
            this.pagination = data.stats.pagination;
        });

        this.subscriptionCollection.subscribe = this.route.params.subscribe((params) => {
            const courseId = this.instance.id;
            this.parameters = {...params, courseId};
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    search(): void {
        const params = HttpHelper.cleanParams({
            entity: this.parameters.entity,
            entityId: this.parameters.entityId,
            display: this.parameters.display,
            q: this.query
        });

        this.statsProvider.get(params).subscribe((data) => {
            this.items = data.items;
            this.pagination = data.pagination;
        });
    }

    loadMore(): void {
        this.loading = true;

        const params = HttpHelper.cleanParams({
            entity: this.parameters.entity,
            entityId: this.parameters.entityId,
            display: this.parameters.display,
            q: this.query,
            page: this.pagination.currentPage + 1
        });

        this.statsProvider.get(params).subscribe((data) => {
            this.items = this.items.concat(data.items);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    navigate(entity: string, entityId: number, display: string, queryParams: any = {}, enable: boolean = true): void {
        if (enable) {
            this.router.navigate(['manage', this.company.slug, 'instances', this.instance.id, 'stats', entity, entityId, display], {queryParams});
        }
    }

    contentNavigate(context: string, contextId: number): void {
        switch (context) {
            case 'course':
                this.navigate('course', contextId, 'activities');
                break;
            case 'exercise':
                this.navigate('activity', contextId, 'users');
                break;
        }
    }

    storeCache(key: string, data: any): void {
        this.storageService.setCache(key, data, null, true);
    }
}
