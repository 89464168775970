import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageInstanceProvider, Company } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-manage-instance-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class ManageInstanceSettingsComponent {
    public me: any;
    public company: Company;
    public instance: any;
    public instanceType: string;
    public settings: any;
    public managers: any;
    public displays: any;
    public currentManager: any;
    public statusOptions = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private instanceProvider: ManageInstanceProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.instanceType = this.storageService.get('instanceType');
        this.instance = this.route.parent.snapshot.data.instance;
        this.settings = this.route.snapshot.data.settings;
        this.managers = this.route.snapshot.data.managers.managers;
        this.statusOptions = this.getStatusOptions(this.instance.status);

        this.displays = {
            subscriptionDate: false,
            unlockDate: false
        };
    }

    updateInstance(): void {
        const params = {
            context: this.instance.type,
            archived: this.instance.archived,
            favorite: this.instance.favorite,
            status: this.instance.status
        };

        this.instanceProvider.update(this.company.id, this.instance.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    getStatusOptions(status: string) {
        return [
            {
                label: this.translateService.instant('manage.instance.status-preparation'),
                value: 'preparation',
                isSelected: status === 'preparation',
            },
            {
                label: this.translateService.instant('manage.instance.status-ongoing'),
                value: 'ongoing',
                isSelected: status === 'ongoing',
            },
            {
                label: this.translateService.instant('manage.instance.status-done'),
                value: 'done',
                isSelected: status === 'done',
            },
        ];
    }

    updateDate(type: string, date: any): void {
        this.settings[type] = date.split('T')[0];

        if (type === 'unlockDate') {
            this.settings.unlockNotification = true;
        }
        this.updateInstanceSettings();
    }

    updateInstanceSettings(): void {
        this.instanceProvider.updateSettings(this.company.id, this.instance.id, this.instance.type, this.settings).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    changeInstanceStatus(status: string): void {
        this.instance.status = status;
        this.statusOptions = this.getStatusOptions(status);
        this.updateInstance();
    }

    setSettings(type: string, value: boolean): void {
        this.settings[type] = value;
        this.updateInstanceSettings();
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    addManagers(managers: number[]): void {
        const params = {
            context: this.instance.type,
            account_ids: managers
        };

        this.instanceProvider.addManagers(this.company.id, this.instance.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));

            this.instanceProvider.getManagers(this.company.id, this.instance.id, { context: this.instance.type }).subscribe((data) => {
                this.managers = data.managers;
            });
        });
    }

    deleteManager(): void {
        const params = {
            context: this.instance.type
        };

        this.instanceProvider.deleteManager(this.company.id, this.instance.id, this.currentManager.id, params).subscribe(() => {
            const index = this.managers.findIndex((manager) => {
                return manager.id === this.currentManager.id;
            });

            if (index > -1) {
                this.managers.splice(index, 1);
            }

            if (this.currentManager.id === this.me.id) {
                this.router.navigate(['manage', this.company.slug, 'instances']);
            } else {
                this.closeModalDelete();
                this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
            }
        });
    }

    conversationWith(manager: any): void {
        this.router.navigate(['conversation/create'], { queryParams: { account_ids: manager.id } });
    }

    openModalDelete(manager: any): void {
        this.currentManager = manager;
    }

    closeModalDelete(): void {
        this.currentManager = null;
    }

    resetDateInput(input: string): void {
        this.settings[input] = null;
    }
}
