import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'msc-card-manager-modal-delete-subscription',
    templateUrl: './delete-subscription.component.html'
})
export class CardManagerModalDeleteSubscriptionComponent implements OnInit {
    @Input() type: string;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public isActive: boolean = false;
    public data: any;
    public title: any;

    constructor() {}

    ngOnInit(): void {
        this.data = {
            message: null
        };
    }

    close(): void {
        this.isActive = false;
        this.onClose.emit(true);
    }

    open(title?: string): void {
        this.isActive = true;
        this.title = title;
    }

    save(): void {
        this.isActive = false;
        this.onSave.emit(this.data);
    }
}
