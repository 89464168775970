import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntegrationProvider } from '@lighty';
import { StorageService } from '@services';
import { DownloadHelper } from '@helpers';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'msc-organize-integration-watch-catalogue-export',
    templateUrl: './catalogue-export.component.html',
    styleUrls: ['./catalogue-export.component.scss'],
})

export class OrganizeIntegrationWatchCatalogueExportComponent {
    private company: any;
    public provider: any;
    public loaders: any = {
        export: false
    };

    constructor(
        private route: ActivatedRoute,
        private integrationProvider: IntegrationProvider,
        private storageService: StorageService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.provider = this.route.snapshot.parent.parent.parent.data.integration;

        this.route.data.subscribe(() => {
            this.provider = this.route.snapshot.parent.parent.parent.data.integration;
        });
    }

    exportCatalogue(): void {
        this.loaders.export = true;

        this.integrationProvider
            .exportCatalogue(this.company.id, this.provider.id)
            .pipe(
                finalize(() => this.loaders.export = false)
            )
            .subscribe((data) => {
                DownloadHelper.downloadBlob(data, this.provider.name + '-catalogue.xlsx');
            });
    }
}
