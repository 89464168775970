import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-organize-integration-watch-stats',
    templateUrl: './stats.component.html'
})
export class OrganizeIntegrationWatchStatsComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
