import { Component, ChangeDetectionStrategy, SkipSelf, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlContainer } from '@angular/forms';
//
import { UuidPipe } from '@common2/pipes/uuid.pipe';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

//
export type CommonCheckboxType = 'half' | 'tick';

@Component({
    selector: 'msc-common2-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        UuidPipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Common2CheckboxComponent),
            multi: true
          }
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ],
})

export class Common2CheckboxComponent {
    @Input() controlName?: string; // form control name
    @Input() isDisabled: boolean = false; // non-form property
    @Input() label?: string;
    @Input() type: CommonCheckboxType = 'tick';
    @Input() value: boolean; // non-form property
    @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();

    public id: string = this.uuidPipe.transform();

    constructor(
        private readonly uuidPipe: UuidPipe,
    ) { }

    /**
     * Event handler for change
     */
    onChange(event: Event): void {
        event.stopPropagation();
        this.changeEvent.emit((event.target as HTMLInputElement).checked);
    }

    private onChangeCallback: (value: any) => void = () => {};
    private onTouchedCallback: () => void = () => {};

    writeValue(value: boolean): void {
        this.value = value;
      }
    
      registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
      }
    
      registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
      }
    
      setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
      }
}
