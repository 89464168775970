import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { CompanyExperience } from './company-experience';
import { environment } from '@env/environment';

@Injectable()
export class CompanyExperienceProvider {

    constructor(private http: HttpClient) {}

    getExperiences(slug: string, params?: any): Observable<any> {
        const response = {
            pagination: {},
            experiences: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/experiences`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.experiences.map((experience) => {
                    response.experiences.push(new CompanyExperience(experience));
                });

                return response;
            })
        );
    }

    get(slug: string|number, experienceId: number, params?: any): Observable<CompanyExperience> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${slug}/experiences/${experienceId}`, {params}).pipe(
            map(data => new CompanyExperience(data))
        );
    }

    create(slug: string, params: any): Observable<CompanyExperience> {
        return this.http.post(`${environment.envVar.API_URL}/company/${slug}/experiences`, params).pipe(
            map(data => new CompanyExperience(data))
        );
    }

    update(slug: string, experienceId: number, params: any): Observable<CompanyExperience> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${slug}/experiences/${experienceId}`, params).pipe(
            map(data => new CompanyExperience(data))
        );
    }

    delete(slug: string, experienceId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/company/${slug}/experiences/${experienceId}`).pipe(
            map(data => new CompanyExperience(data))
        );
    }

    resolveExperienceFromUrl(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/common/experience/resolve`, params).pipe(
            map(data => new CompanyExperience(data))
        );
    }

    display(params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/common/experience/display`, {params}).pipe(
            map(data => data)
        );
    }
}
