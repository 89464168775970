import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { YoutubePlaylist } from './youtube-playlist';
import { environment } from '@env/environment';

@Injectable()
export class YoutubeIntegrationProvider {

    constructor(private http: HttpClient) {}

    get(params?: any): Observable<any> {
        const response = {
            pagination: {},
            playlists: []
        };

        return this.http.get(`${environment.envVar.API_URL}/learning/playlists`, {params}).pipe(
            map((data: any) => {
                response.pagination = data.pagination;
                response.playlists = data.playlists.map((playlist) => {
                    return new YoutubePlaylist(playlist);
                });

                return response;
            })
        );
    }

    create(params: any): Observable<YoutubePlaylist> {
        return this.http.post(`${environment.envVar.API_URL}/learning/playlists`, params).pipe(
            map(data => new YoutubePlaylist(data))
        );
    }

    update(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/learning/playlists/${id}`, params).pipe(
            map(data => new YoutubePlaylist(data))
        );
    }


    delete(id: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/learning/playlists/${id}`, {params}).pipe(
            map(data => data)
        );
    }

    refresh(id: number, params: any): Observable<YoutubePlaylist> {
        return this.http.post(`${environment.envVar.API_URL}/playlists/${id}/sync`, params).pipe(
            map(data => new YoutubePlaylist(data))
        );
    }

    getVideos(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/playlists/${id}/videos`, {params}).pipe(
            map(data => data)
        );
    }
}
