import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class SkillProvider {

    constructor(private http: HttpClient) {}

    attach(skillId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/skills/${skillId}/attach`, params).pipe(
            map((data) => data)
        );
    }

    detach(skillId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/skills/${skillId}/detach`, {params}).pipe(
            map((data) => data)
        );
    }
}
