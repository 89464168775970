import { Component, ChangeDetectionStrategy } from '@angular/core';
//
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap, mergeScan, repeat, takeUntil } from 'rxjs/operators';
//
import { TranslateService, StorageService, ExternalAppsService } from '@services';
//
import { CommonSubjectStatus, CommonApiGetListOrderParams } from '@common2/common.types';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';
import { OrganizeTableMarketplaceCustomersDatum } from '@modules/organize/components/table-marketplace-customers/organize-table-marketplace-customers.component';
import { OrganizeMarketplaceCustomersApiService } from '@modules/organize/services/api/organize-marketplace-customers-api.service';
import { OrganizeMarketplaceLibService } from '@modules/organize/services/lib/organize-marketplace-lib.service';
import { ApiMarketplaceGetCustomerListResponse } from '@modules/organize/organize.types';

@Component({
    selector: 'msc-organize-marketplace-customers-page',
    templateUrl: './organize-marketplace-customers-page.component.html',
    styleUrls: ['./organize-marketplace-customers-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeMarketplaceCustomersPageComponent {
    private company: any;
    private destroy$: Subject<void> = new Subject<void>();
    public customerListTableHasLoadMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public customerListTablePage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public customerListQuery$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public customerListTableRepeat$: Subject<void> = new Subject<void>();
    public customerListTableRows$: BehaviorSubject<Array<OrganizeTableMarketplaceCustomersDatum>> = new BehaviorSubject<Array<OrganizeTableMarketplaceCustomersDatum>>([]);
    public customerListTableStatus$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);

    constructor(
        private readonly toastService: CommonToastService,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly marketplaceCustomersApiService: OrganizeMarketplaceCustomersApiService,
        private readonly marketplaceLibService: OrganizeMarketplaceLibService,
        private readonly externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.onQueryCustomerList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.customerListTableRepeat$.complete();
    }

    /**
     * Get the params for onQueryCustomerList
     */
    getParamsOnQueryCustomerList() {
        return {
            page: this.customerListTablePage$.getValue(),
            ...this.customerListQuery$.getValue(),
        };
    }

    /**
     * Event handler for query customers
     */
    onQueryCustomerList(isAppend: boolean = true): void {
        this.marketplaceCustomersApiService
            .getCustomerList(this.company.id, this.getParamsOnQueryCustomerList())
            .pipe(
                catchError(() => this.onCatchErrorOnQueryCustomerList()),
                tap((response) => this.onTapGetPaginationOnQueryCustomerList(response.pagination)),
                map((response) => this.marketplaceLibService.getTableCustomers(response.data)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.customerListTableRows$.getValue() : []),
                tap((data) => this.onTapGetDataOnQueryCustomerList(data)),
                repeat({ delay: () => this.customerListTableRepeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Event handler for catchError for onQueryCustomerList
     */
    onCatchErrorOnQueryCustomerList(): Observable<null> {
        this.toastService.onError(this.translateService.instant('toast.organize.marketplace.customers.get.error'));
        this.customerListTableStatus$.next(CommonSubjectStatus.ERROR);
        return of(null);
    }

    /**
     * Event handler for tap pagination for onQueryCustomerList
     */
    onTapGetPaginationOnQueryCustomerList(pagination: ApiMarketplaceGetCustomerListResponse['pagination']): void {
        this.customerListTableHasLoadMore$.next(pagination.currentPage !== pagination.lastPage);
    }

    /**
     * Event handler for tap data for onQueryCustomerList
     */
    onTapGetDataOnQueryCustomerList(data: Array<OrganizeTableMarketplaceCustomersDatum>): void {
        this.customerListTableRows$.next(data);
        this.customerListTableStatus$.next(CommonSubjectStatus.SUCCESS);
    }

    /**
     * Event handler for load more for onQueryCustomerList
     */
    onLoadMoreCustomerList(): void {
        this.customerListTablePage$.next(this.customerListTablePage$.getValue() + 1);
        this.onQueryCustomerList();
    }

    /**
     * Event handler for sort for onQueryCustomerList
     */
    onSortCustomerList(params: CommonApiGetListOrderParams): void {
        this.customerListTablePage$.next(1);
        const { order, sort, ...query } = this.customerListQuery$.getValue();
        const sortParams = params.order ? params : {};
        this.customerListQuery$.next({ ...query, ...sortParams });
        this.onQueryCustomerList(false);
    }

    /**
     * Event handler for add customer
     */
    onAddCustomer(params: any): void {
        this.marketplaceCustomersApiService
            .postAddCustomer(this.company.id, params)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onAddCustomerSuccess(params),
                error: () => this.onAddCustomerError(),
            });
    }

    /**
     * Event handler for success on onAddCustomer
     */
    onAddCustomerSuccess(params: any): void {
        this.toastService.onSuccess(this.translateService.instant('toast.organize.marketplace.customers.add.success'));
    }

    /**
     * Event handler for error on onAddCustomer
     */
    onAddCustomerError(): void {
        this.toastService.onError(this.translateService.instant('toast.organize.marketplace.customers.add.error'));
    }

    /**
     * Event handler for close modal
     */
    onCloseModal(modal: CommonModalComponent): void {
        modal.onClose();
    }

    /**
     * Event handler for open modal
     */
    onOpenModal(modal: CommonModalComponent): void {
        modal.onOpen();
    }
}
