<msc-common-aside #aside (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div class="avatar-lang flex space-x-2">
                <msc-common-avatar [label]="user.name" [pictureURL]="user.picture ? (user.picture + '?size=128') : null">
                </msc-common-avatar>
                <div class="avatar__content">
                    <p class="profile__name">{{ user.name }}</p>
    
                    <div class="profile__lang">
                        <img class="profile__img"
                            [src]="'https://static.myskillcamp.com/images/icon/icon-camp/flag_' + this.lang + '.svg'">
                        <p class="profile__lang-desc"> {{ 'words.'+ lang | translate }} </p>
                    </div>
                </div>
            </div>
    
            <div class="slide__mail-tab">
                <msc-textarea [label]="'shared.mail.title' | translate"
                    [placeholder]="'shared.mail.placeholder' | translate" [(ngModel)]="message"></msc-textarea>
    
                <small>{{ 'shared.mail.description' | translate }}</small>
            </div>
        </div>
    </ng-template>
</msc-common-aside>