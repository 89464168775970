import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@services';
import { CalendarDate } from '@interfaces';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'msc-datepicker',
    templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements OnInit, OnChanges {
    @Input() date: any;
    @Input() minDate: any;
    @Input() limit: any;
    @Input() actions: boolean = false;
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    public currentDate: any;
    public dayNames: any = {
        en: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        fr: ['L', 'M', 'Me', 'J', 'V', 'S', 'D'],
        nl: ['M', 'D', 'W', 'D', 'V', 'Z', 'Z']
    };
    public weeks: CalendarDate[][] = [];
    public lang: string;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        this.lang = this.translateService.getCurrentLang();
        this.currentDate = (this.date) ? moment(this.date).format() : moment().format();

        if (this.minDate && !this.date) {
            this.currentDate = moment(this.minDate).format();
        }

        this.generateCalendar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date && changes.date.currentValue && changes.date.currentValue.length  > 1) {
            this.currentDate = moment(changes.date.currentValue).format();
            this.generateCalendar();
        }

        if (changes.minDate && changes.minDate.currentValue && changes.minDate.currentValue.length  > 1 && !this.date) {
            this.currentDate = moment(changes.minDate.currentValue).format();
            this.generateCalendar();
        }
    }

    selectDay(day: CalendarDate): void {
        if (day.isSameMonth && !this.isLimit(day)) {
            this.currentDate = day.moment;
            if (!this.actions) {
                this.onUpdate.emit(moment(this.currentDate).format('YYYY-MM-DD'));
                this.close();
            }
        }
    }

    isSelected(date: CalendarDate): boolean {
        if (this.currentDate && date.isSameMonth) {
            return moment(this.currentDate).isSame(date.moment, 'day');
        }
        return false;
    }

    changeMonth(direction: string): void {
        if (direction === 'prev') {
            this.currentDate = moment(this.currentDate).subtract(1, 'months');
        } else {
            this.currentDate = moment(this.currentDate).add(1, 'months');
        }

        if (this.minDate && moment(this.minDate).isAfter(this.currentDate)) {
            this.currentDate = moment(this.minDate).format();
        }
        this.generateCalendar();
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        this.onUpdate.emit(moment(this.currentDate).format('YYYY-MM-DD'));
        this.close();
    }

    isLimit(compareDate: CalendarDate): boolean {
        if (this.limit?.min && this.limit?.max) {
            return !moment(compareDate.moment).isBetween(this.limit.min, this.limit.max, null, '[]');
        } else if (this.limit?.max) {
            return moment(compareDate.moment).isAfter(this.limit.max);
        } else if (this.limit?.min) {
            return moment(compareDate.moment).isBefore(this.limit.min);
        }
        return false;
    }

    private generateCalendar(): void {
        const dates = this.fillDates(this.currentDate);
        const weeks: CalendarDate[][] = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeks = weeks;
    }

    private fillDates(currentDate: moment.Moment): CalendarDate[] {
        const firstOfMonth = moment(currentDate).startOf('month').day();
        const firstDayOfGrid = firstOfMonth === 0 ? moment(currentDate).startOf('month').subtract(6, 'days') : moment(currentDate).startOf('month').subtract(firstOfMonth - 1, 'days');
        const start = firstDayOfGrid.date();
        return _.range(start, start + 42)
            .map((date: number): CalendarDate => {
                const d = moment(firstDayOfGrid).date(date);
                return {
                    today: this.isSame(d),
                    isSameMonth: this.isSame(d, 'month', currentDate),
                    moment: d
                };
            });
    }

    private isSame(date: moment.Moment, granularity: any = 'day', compareDate?: moment.Moment): boolean {
        if (compareDate) {
            return moment(compareDate).isSame(moment(date), granularity);
        }
        return moment().isSame(moment(date), granularity);
    }

    public getYearFromDate(dateString: string): number {
        const date = new Date(dateString);
        return date.getFullYear();
      }
}
