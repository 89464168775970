<msc-common-aside #aside [title]="'words.export' | translate" (saveEvent)="save()">
    <div class="slide__content">
        <div class="slide__form">
            <div class="slide__access">
                <div class="flex flex-col space-y-2">
                    <p>{{ 'words.format' | translate }}</p>

                    <div>
                        <msc-common2-radio groupName="exportFormat" [label]="'XLSX'" [(ngModel)]="data.format"
                            [valueName]="'xlsx'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportFormat" [label]="'CSV'" [(ngModel)]="data.format"
                            [valueName]="'csv'"></msc-common2-radio>
                    </div>
                </div>

                <div class="flex flex-col space-y-2">
                    <p>{{ 'words.granularity' | translate }}</p>

                    <div>
                        <msc-common2-radio groupName="exportGranularity" [label]="'words.day' | translate"
                            [(ngModel)]="data.granularity" [valueName]="'day'"></msc-common2-radio>
                        <msc-common2-radio *ngIf="diff > 14" groupName="exportGranularity"
                            [label]="'words.week' | translate" [(ngModel)]="data.granularity" [valueName]="'week'">
                        </msc-common2-radio>
                        <msc-common2-radio *ngIf="diff > 60" groupName="exportGranularity"
                            [label]="'words.month' | translate" [(ngModel)]="data.granularity" [valueName]="'month'">
                        </msc-common2-radio>
                        <msc-common2-radio *ngIf="diff > 365" groupName="exportGranularity"
                            [label]="'words.year' | translate" [(ngModel)]="data.granularity" [valueName]="'year'">
                        </msc-common2-radio>
                    </div>
                </div>

                <div class="flex flex-col space-y-2" *ngIf="options.usage">
                    <p>{{ 'words.charts' | translate }}</p>

                    <div>
                        <msc-common2-radio groupName="exportChart" [label]="'usage.learner-subscribed' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learner_content_subscribed'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportChart" [label]="'usage.total-subscriptions' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learner_subscriptions'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportChart" [label]="'usage.learner-content-done' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learner_content_done'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportChart" [label]="'usage.content-done' | translate"
                            [(ngModel)]="data.chart" [valueName]="'content_done'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportChart" [label]="'usage.learning-duration' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learning_duration'"></msc-common2-radio>
                    </div>
                </div>

                <div class="flex flex-col space-y-2" *ngIf="options.providers">
                    <p>{{ 'words.charts' | translate }}</p>

                    <div>
                        <msc-common2-radio groupName="exportProvidersChart" [label]="'providers.total-subscriptions' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learner_subscriptions'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportProvidersChart" [label]="'providers.learners-subscribed' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learner_content_subscribed'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportProvidersChart" [label]="'providers.learners-content-done' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learner_content_done'"></msc-common2-radio>
                        <msc-common2-radio groupName="exportProvidersChart" [label]="'providers.contents-done' | translate"
                            [(ngModel)]="data.chart" [valueName]="'content_done'"></msc-common2-radio>
                        <msc-common2-radio *ngIf="options.extend" groupName="exportChart" [label]="'providers.learning-duration' | translate"
                            [(ngModel)]="data.chart" [valueName]="'learning_duration'"></msc-common2-radio>
                    </div>
                </div>
            </div>
        </div>
    </div>
</msc-common-aside>