import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { EmitterService } from '@services';
import { ExtensionLink } from '@interfaces';
import { LanguageProvider, Media } from '@lighty';

@Component({
    selector: 'msc-shared-aside-extension-infos',
    templateUrl: './extension-infos.component.html',
    styleUrls: ['./extension-infos.component.scss']
})
export class SharedExtensionInfosComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();
    @ViewChild('resourceUpload') resourceUpload: ElementRef;
    public resources: any[];
    public loadDisplayEmitter: EventEmitter<any> = new EventEmitter();
    public loadAnnounceEmitter: EventEmitter<any> = new EventEmitter();
    public selectData: any = {
        languages: []
    }

    constructor(private emitterService: EmitterService, private languageProvider: LanguageProvider) { }

    ngOnInit(): void {
        this.getLanguages();
    }

    getLanguages() {
        this.languageProvider.get('content').subscribe(resp => {
            this.selectData.languages = this.getSelectData(resp);
        })
    }

    getSelectData(data) {
        return data.map(d => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: d.id === this.extensionLink.language.id
        }));
    }

    onSelectOption(option) {
        this.selectData.languages = this.selectData.languages.map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });

        this.onSelect(option.value);
    }

    nextPanel(): void {
        this.emitterService.extensionPage.emit('skills');
    }

    updatePicture(key: string, media?: Media): void {
        this.extensionLink.image = media ? media.pictureUrl : '';
    }

    loadPicture(key: string): void {
        if (key === 'display') {
            this.loadDisplayEmitter.emit(true);
        } else {
            this.loadAnnounceEmitter.emit(true);
        }
    }

    onSelect(lang): void {
        this.extensionLink.languageId = lang.id;
    }
}
