<div class="feed-submit-head">
    <!-- NOTE: in case we need more shit here -->
</div>

<div class="feed-submit-body">
    <form [formGroup]="form">
        <div class="grouping">
            <div class="wrapper-textarea">
                <msc-common-avatar [label]="userName" [pictureURL]="userAvatar"></msc-common-avatar>
                <msc-textarea class="msc-textarea--no-border msc-textarea--outline"
                    rows="1"
                    formControlName="contentMessage"
                    [autosize]="true"
                    [placeholder]="'placeholder.speak-out' | translate"
                    (keydown.enter)="onSubmit()">
                </msc-textarea>
            </div>
        </div>

        <div class="preview-iframe" [hidden]="!isPreviewIframe">
            <msc-button class="msc-button--primary" palette="secondary" icon="icon-close" type="fab" (click)="onCleanPreviewIframe()"></msc-button>
            <iframe #iframe></iframe>
        </div>

        <div class="preview-img" [hidden]="!isPreviewImage">
            <msc-button class="msc-button--primary" palette="secondary" icon="icon-close" type="fab" (click)="onCleanPreviewImg()"></msc-button>
            <div class="grouping-img">
                <img #img>
                <ng-container *ngIf="resource">
                    <div class="grouping-doc">
                        <p class="preview-name">{{ resource.name }}</p>
                        <p class="preview-size">{{ resource.size | fileSize }}</p>
                    </div>
                </ng-container>
            </div>
        </div>

        <input type="text" formControlName="url" [hidden]="true">
        <input type="file" #file (change)="onChange($event.target.files)">
    </form>
</div>

<div class="feed-submit-footer">
    <div class="wrapper">
        <ng-container *ngIf="hasOptions">
            <msc-button icon="icon-file-text" type="fab" [tooltip]="'words.resource' | translate" (click)="onUploadImg()"></msc-button>
            <msc-button icon="icon-play-circle" type="fab" [tooltip]="'words.video' | translate" (click)="composerEmbedAside.onOpen()"></msc-button>
        </ng-container>
    </div>

    <msc-button class="msc-button--primary msc-button--tiny" type="fab" palette="secondary" icon="icon-arrow-right-1" (click)="onSubmit()"></msc-button>
</div>

<msc-composer-aside-embed #composerEmbedAside (onSave)="onUploadIframe($event)"></msc-composer-aside-embed>
