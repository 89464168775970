import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class ManageAttendanceProvider {

    constructor(private http: HttpClient) {}

    getPeriods(id: string|number, instanceId: number, eventId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/${eventId}/periods`, {params}).pipe(
            map((data) => data)
        );
    }

    getPeriod(id: string|number, instanceId: number, eventId: number, periodId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/${eventId}/periods/${periodId}`, {params}).pipe(
            map((data) => data)
        );
    }

    getAvailableLearner(id: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/learners`, {params}).pipe(
            map((data) => data)
        );
    }

    updatePeriodsSettings(id: string|number, instanceId: number, eventId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/${eventId}/periods`, params).pipe(
            map((data) => data)
        );
    }

    updatePeriod(id: string|number, instanceId: number, eventId: number, periodId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/events/${eventId}/periods/${periodId}`, params).pipe(
            map((data) => data)
        );
    }
}
