import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Camp, Company, Pagination, AdminJourneyProvider, AdminContentProvider } from '@lighty';
import { EmitterService, LoaderService, StorageService, ErrorStreamService, TranslateService, RedirectService, ExternalAppsService } from '@services';
import { SubscriptionCollection } from '@classes';
import { DownloadHelper, HttpHelper, DataHelper, UrlHelper } from '@helpers';
import { removeClass } from '@functions';
import { CommonToastService } from '@common2/services/toast.service';
import { concatMap, delay, retry, tap } from 'rxjs/operators';

export enum ContentStatus {
    PUBLISHED = 'published',
    ONGOING = 'ongoing',
    ARCHIVED = 'archived',
    DRAFT = 'draft'
}

export enum ContentType {
    EXTERNAL_CONTENT = 'external_content',
    EXTERNAL_RESOURCE = 'external_resource',
    COURSE = 'course',
    JOURNEY = 'traject'
}

@Component({
    selector: 'msc-camp-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss']
})

export class CampTemplateComponent {
    @ViewChild('campGroupsAside') campGroupsAside;
    @ViewChild('campPublicationAside') campPublicationAside;
    @ViewChild('campSubscriptionAside') campSubscriptionAside;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private me: any;
    public company: Company;
    public camp: Camp;
    public currentCourseCamp: any;
    public currentTemplate: any;
    public currentExternalContent: any;
    public templates: any;
    public templatesCollection: any[] = [];
    public pagination: Pagination;
    public currentJourney: any;
    public loading: boolean = false;
    public queryCourse: string;
    public query: string;
    public breadcrumb: boolean = false;
    public displayAsides: any;
    public displaySlider: boolean = false;
    public dataFilters: any;
    public filters: any;
    public isFilterActive: boolean = false;
    public slider: any;
    public displayDropdowns: any;
    public currentContent: any;
    public displays: any;
    public availableTypes: any;
    public availableLanguages: any[] = [];
    public availableProviders: any;
    public activeSort: boolean = false;
    public sort: any;
    public isNotify: boolean;
    public content: any;
    public typeFilters: any = {
        unlimitedCourses: true
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private journeyProvider: AdminJourneyProvider,
        private contentProvider: AdminContentProvider,
        private loaderService: LoaderService,
        private emitterService: EmitterService,
        private errorStreamService: ErrorStreamService,
        private translateService: TranslateService,
        private redirectService: RedirectService,
        private externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.camp = this.storageService.get('camp');
        this.me = this.storageService.get('me');

        this.route.params.subscribe((params) => {
            if (params.slug) {
                this.templates = [this.route.snapshot.data.templates];
                this.breadcrumb = true;
            } else {
                this.pagination = this.route.snapshot.data.contents.pagination;
                this.templates = this.getContentsRemapped(this.route.snapshot.data.contents.contents);
            }
        });

        this.displayAsides = {
            members: false,
            groups: false,
            publication: false,
            subscription: false,
            onDemand: false,
            // deleteCourse: false,
            trainers: false
        };

        this.displayDropdowns = {
            contents: [],
            preview: [],
            people: [],
            attach: [],
            responsive: []
        };

        this.getMeta();
        this.resetSort(false);

        this.route.queryParams.subscribe(params => {
            if (params.action && params.instance) {
                if (params.action === 'members') {
                    this.openManageMembers(this.getCoursecamp(params.instance));
                } else if (params.action === 'groups') {
                    this.openManageGroups(this.getCoursecamp(params.instance));
                }
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('refresh.extension').subscribe(data => {
            if (typeof data === 'boolean') {
                this.refresh();
                this.emitterService.set('refresh.extension', {});
            }
        });

        this.displays = {
            dropdowns: {
                contents: [],
                people: []
            },
            asides: {
                members: false,
                trainers: false,
                communities: false
            }
        };
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    private getCoursecamp(instanceId: string): any {
        return this.templates[0].courseCamps.find((courseCamp) => {
            return courseCamp.course.id === parseInt(instanceId, 10);
        });
    }

    getSubTypesOptions(data: any, key: string) {
        const subtypes = data[`${key}Types`];
        if (!subtypes) {
            return [];
        }

        return Object.keys(subtypes)
            .map((subtype, idx) => ({
                id: `${subtype}-${idx}`,
                label: this.translateService.instant(`words.course-type.${subtype}`),
                value: subtype,
                isSelected: false
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getTypesOptions(data: any) {
        return Object.keys(data.contentTypes)
            .map((type, index) => ({
                id: `${type}-${index}`,
                label: this.translateService.instant(`api.content-type.${type.split(/(?=[A-Z])/).join('_').toLowerCase()}`),
                value: type.split(/(?=[A-Z])/).join('_').toLowerCase(),
                isSelected: false,
                children: this.getSubTypesOptions(data, type)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getProvidersOptions(providers) {
        const label = this.company.experience
                    ? this.company.experience.displayName
                    ? this.company.experience.displayName
                    : this.company.experience.name
                    : this.company.name;

        return providers.map(provider => ({
            label: provider.id === 'company' ? label : provider.name,
            value: provider.id,
            isChecked: false
        }));
    }

    getLanguagesOptions(languages) {
        const languageIds = this.me.learningLanguages.map(lang => lang.id);

        return languages.map((lang, idx) => ({
            id: idx,
            label: lang.id === 'undefined' ? this.translateService.instant('words.multilingual') : lang.name,
            value: lang.id,
            isSelected: languageIds.includes(lang.id)
        }));
    }

    private getMeta(): void {
        this.contentProvider.getFilters(this.company.slug, this.camp.id).subscribe((meta) => {
            this.availableTypes = this.getTypesOptions(meta);
            this.availableLanguages = this.getLanguagesOptions(meta.languages);
            this.availableProviders = this.getProvidersOptions(meta.providers);

            this.setFilters();
        });
    }

    private setFilters(): void {
        this.filters = [
            {
                name: 'langs',
                type: 'multiselect',
                label: this.translateService.instant('words.languages'),
                displayValue: this.availableLanguages.filter(lang => lang.isSelected).map(lang => lang.label).join(', '),
                options: this.availableLanguages
            },
            {
                name: 'providers',
                type: 'checkbox',
                label: this.translateService.instant('words.providers'),
                options: this.availableProviders
            }
        ];
    }

    refresh(): void {
        this.search();
    }

    goToTemplate(slug?: string): void {
        this.resetDropdowns();
        if (!this.breadcrumb) {
            this.router.navigate(['../template', slug], { relativeTo: this.route });
        } else {
            this.router.navigate(['../../template'], { relativeTo: this.route });
        }
    }

    downloadExport(): void {
        // DOWNLOAD-EXPORT -> CAMPS-CONTENT
        this.errorStreamService.locked();

        const params = {
            content_type: 'internal'
        };

        this.contentProvider.getExport(this.camp.id, params).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail'));
            this.errorStreamService.unlocked();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            this.errorStreamService.unlocked();
        });
    }

    onFilterSave(data): void {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.search(this.query);
    }

    onFilterReset() {
        this.availableTypes = this.availableTypes.map(type => {
            type.isSelected = false
            type.children.map(subtype => {
                subtype.isSelected = false;
                return subtype;
            });
            return type;
        });

        this.dataFilters = {};
        this.typeFilters = {};
        this.isFilterActive = false;
        this.search(this.query);
    }

    resetSort(search: boolean = true): void {
        this.sort = {
            name: '',
            type: null
        };
        this.activeSort = false;
        if (search) {
            this.search();
        }
    }

    search(query?): void {
        this.query = query;
        this.loaderService.run();
        const params = HttpHelper.cleanParams({
            q: this.query,
            content_types: this.typeFilters.contentTypes ? this.typeFilters.contentTypes.join() : null,
            course_types: this.typeFilters.courseTypes ? this.typeFilters.courseTypes.join() : null,
            external_content_types: this.typeFilters.externalContentTypes ? this.typeFilters.externalContentTypes.join() : null,
            external_resource_types: this.typeFilters.externalResourceTypes ? this.typeFilters.externalResourceTypes.join() : null,
            languages: this.dataFilters?.langs ? this.dataFilters.langs.join(',') : null,
            providers: this.dataFilters?.providers ? this.dataFilters.providers.join(',') : null
        });

        if (this.breadcrumb) {
            this.contentProvider.getTemplate(this.templates[0].slug, params).subscribe((data) => {
                this.templates = [data];
            });
        } else {
            this.contentProvider.getContents(this.company.slug, this.camp.id, params).subscribe((data) => {
                this.templates = this.getContentsRemapped(data.contents);
                this.pagination = data.pagination;
            });
        }
    }

    downloadSurvey(courseCamp: any): void {
        this.contentProvider.getSurvey(courseCamp.id).subscribe((data) => {
            DownloadHelper.downloadBlob(data, courseCamp.title + '.xlsx');
        });
    }

    downloadAttendance(courseCamp: any): void {
        this.contentProvider.getAttendanceList(courseCamp.id).subscribe((data) => {
            DownloadHelper.downloadBlob(data, courseCamp.title + '-list.pdf');
        });
    }

    setCurrentContent(content, type: string = 'courseCamp'): void {
        if (type === 'courseCamp') {
            this.currentCourseCamp = content;
        } else if (type === 'externalResource') {
            this.currentExternalContent = content;
        } else {
            this.currentJourney = content;
        }
    }

    openManageMembers(item: any, type: string = 'courseCamp'): void {
        if (type === 'courseCamp') {
            this.currentCourseCamp = item;
        } else if (type === 'externalResource') {
            this.currentExternalContent = item;
        } else {
            this.currentJourney = item;
        }
    }

    getLogo(content: any): string {
        switch (content.type) {
            case 'external_content':
            case 'course_template':
                if (!content.data.provider?.logo && content.data.provider?.key) {
                    return 'https://static.myskillcamp.com/images/integrations/' + content.data.provider.key + '-picto.svg';
                } else {
                    return content.data.provider?.logo;
                }
            case 'external_video':
                return 'https://static.myskillcamp.com/images/integrations/youtube-picto.svg';
        }
    }

    navigateAttestation(content: any): void {
        this.router.navigate(['manage', this.company.slug, 'contents', 'external', content.id, 'follow-up']);
    }

    setCurrentExternalContent(content) {
        this.currentExternalContent = content;
    }

    openManageCommunities(content: any): void {
        this.currentContent = content;
        this.displayed('asides', 'communities');
    }

    openManageGroups(item: any, type: string = 'courseCamp'): void {
        this.campGroupsAside.onOpen(item, type);
    }

    openAsidePublication(item: any, settings: any = [], type: string = 'courseCamp'): void {
        if (settings) {
            item = { ...item, settings: settings };
        }
        this.campPublicationAside.onOpen(item, type);
    }

    openAsideSubscription(item: any, settings: any = []): void {
        item = { ...item, settings: settings };
        this.campSubscriptionAside.onOpen(item);
    }

    openAsideOnDemand(template: any): void {
        this.currentTemplate = template;
        this.displayAsides.onDemand = true;
    }

    loadMore(): void {
        this.loaderService.run();
        this.loading = true;

        const params = HttpHelper.cleanParams({
            content_types: this.typeFilters.contentTypes ? this.typeFilters.contentTypes.map((item) => DataHelper.changeCase(item)).join() : null,
            course_types: this.typeFilters.courseTypes ? this.typeFilters.courseTypes.join() : null,
            external_content_types: this.typeFilters.externalContentTypes ? this.typeFilters.externalContentTypes.join() : null,
            external_resource_types: this.typeFilters.externalResourceTypes ? this.typeFilters.externalResourceTypes.join() : null,
            languages: this.dataFilters?.langs ? this.dataFilters.langs.join(',') : null,
            providers: this.dataFilters?.providers ? this.dataFilters.providers.join(',') : null,
            q: this.query,
            page: this.pagination.currentPage + 1
        });

        this.contentProvider.getContents(this.company.slug, this.camp.id, params).subscribe((data) => {
            this.templates = this.getContentsRemapped(this.templates.concat(data.contents));
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    closeAside(key: string): void {
        this.currentCourseCamp = this.currentJourney = this.currentExternalContent = null;
        this.displayAsides[key] = false;
    }

    downloadAttendanceJourney(journey: any): void {
        this.journeyProvider.getAttendanceList(this.camp.id, journey.id).subscribe((data) => {
            DownloadHelper.downloadBlob(data, journey.title + '-list.pdf');
        });
    }

    openDeleteExternalContent(externalContent: any): void {
        this.currentExternalContent = externalContent;
    }

    copyLink(template: any): void {
        DataHelper.clipboard(UrlHelper.getUrl('player') + '/course/' + (template.courseId || template.course.id) + '?direct_play=true');
        this.toastService.onSuccess(this.translateService.instant('toast.link-clipboard'));
    }

    displayed(type: string, index: number | string): void {
        this.displayDropdowns.preview = [];
        this.displayDropdowns.people = [];
        this.displayDropdowns.attach = [];
        this.displayDropdowns.responsive = [];

        this.displayDropdowns[type][index] = !this.displayDropdowns[type][index];
    }

    displayedExternal(key: string, type: string, index?: number): void {
        if (index !== undefined) {
            this.displays[key][type][index] = !this.displays[key][type][index];
        } else {
            this.displays[key][type] = !this.displays[key][type];
        }
    }

    resetDropdowns(): void {
        this.displayDropdowns = {
            contents: [],
            preview: [],
            people: [],
            attach: [],
            responsive: []
        };

        this.displays = {
            dropdowns: {
                contents: [],
                people: []
            },
            asides: {
                members: false,
                trainers: false,
                communities: false
            }
        };
    }

    openExternalResourceAside(): void {
        this.emitterService.toggleExtension.emit({ type: 'camp', id: this.camp.id });
    }

    openEditExternalResourceAside(data: any): void {
        this.emitterService.toggleExtension.emit({ type: 'template', data });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    closeOverlay(): void {
        removeClass('.overlay', 'active');
        removeClass('.overlay', 'active--light');
    }

    navigatePlayer(courseId: number): void {
        this.redirectService.navigate('player', ['course', courseId], { preview: true });
    }

    getUnpublishState(template, instance?) {
        const content = instance ? instance : template;
        switch (true) {
            case content.state === ContentStatus.ONGOING:
            case template.isAttachedToACollection:
            case template.type === ContentType.EXTERNAL_CONTENT:
                return false;
            default: return true;
        }
    }

    getUnpublishTooltipMessage(template, instance?) {
        const content = instance ? instance : template;
        switch (true) {
            case content.state === ContentStatus.ONGOING: return this.translateService.instant('template.session-ongoing');
            case template.isAttachedToACollection: return this.translateService.instant('tooltip.content-unpublish-rules');
            case template.type === ContentType.EXTERNAL_CONTENT: return this.translateService.instant('tooltip.content-unpublish-rules');
            default: return this.translateService.instant('words.unpublish');
        }
    }

    getTagClass(template) {
        switch (true) {
            case template.state === ContentStatus.ONGOING: return 'info';
            case template.state === ContentStatus.PUBLISHED: return 'success';
            default: return '';
        }
    }

    getContentsRemapped(contents) {
        return contents.map(content => {
            if (content.data.type === ContentType.COURSE) {
                content.courseCamps.map(instance => {
                    instance.tagClass = this.getTagClass(instance);
                    instance.tooltipUnpublish = this.getUnpublishTooltipMessage(content.data, instance);
                    instance.isUnpublishable = this.getUnpublishState(content.data, instance);
                    return instance;
                });
                return content;
            } else {
                content.tagClass = this.getTagClass(content.data);
                content.tooltipUnpublish = this.getUnpublishTooltipMessage(content.data);
                content.isUnpublishable = this.getUnpublishState(content.data);
                return content;
            }
        })
    }

    getParamsUnpublishContent() {
        return HttpHelper.cleanParams({
            context: this.content.type,
            context_id: this.content.id,
            notify: this.isNotify
        });
    }

    getIterativeCheck(parent: any, selected: any) {
        if (parent.children && parent.children.length) {
            parent.children = parent.children.map((child: any) => {
                if (parent.id === selected.id) {
                    child.isSelected = parent.isSelected;
                }
                return child;
            });
            return parent;
        } else {
            return parent;
        }
    }

    getSubTypesFilters(key: string) {
        const subTypes = this.availableTypes.find(t => t.value === key && t.isSelected);

        return subTypes ? subTypes.children.filter(t => t.isSelected).map(t => t.value) : [];
    }

    setTypeFilters(): void {
        this.typeFilters = DataHelper.merge(this.typeFilters, { contentTypes: this.availableTypes.filter(t => t.isSelected).map(t => t.value) });
        this.typeFilters = DataHelper.merge(this.typeFilters, { courseTypes: this.getSubTypesFilters('course') });
        this.typeFilters = DataHelper.merge(this.typeFilters, { externalContentTypes: this.getSubTypesFilters('external_content') });
        this.typeFilters = DataHelper.merge(this.typeFilters, { externalResourceTypes: this.getSubTypesFilters('external_resource') });
        this.isFilterActive = true;
        this.search();
    }

    onSelectType(option) {
        option.isSelected = !option.isSelected
        this.availableTypes = this.availableTypes.map(type => this.getIterativeCheck(type, option));
        this.setTypeFilters();
    }

    onOpenModalUnpublish(modal, template: any, instance?: any) {
        const content = instance ? instance : template;
        if (!content.isUnpublishable) {
            return;
        }
        this.content = {
            type: template.data ? template.data.type : undefined,
            id: instance ? instance.courseId : template.data?.id
        };
        modal.onOpen();
    }

    onToggleNotifyUsers(value) {
        this.isNotify = value;
    }

    onConcatMapOnUnpublishContent() {
        const params = {
            instances_per_page: 3
        };

        return this.contentProvider
            .getContents(this.company.slug, this.camp.id, params)
            .pipe(
                retry({ count: 2, delay: 1000 }),
                tap((resp) => {
                    this.templates = this.getContentsRemapped(resp.contents);
                    this.pagination = resp.pagination;
                })
            )
    }

    onUnpublishContent(modal) {
        this.contentProvider
            .deleteContent(this.company.id, this.camp.id, this.getParamsUnpublishContent())
            .pipe(
                tap(() => { // TODO: use finalize operator instead of tap
                    modal.onClose();
                    this.isFilterActive = false;
                }),
                delay(1000),
                concatMap(() => this.onConcatMapOnUnpublishContent()),
            )
            .subscribe({
                next: () => {
                    this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
                },
                error: () => {
                    this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                }
            });
    }
}
