import { Component, ChangeDetectionStrategy, Input, ContentChildren, QueryList, TemplateRef, TrackByFunction, Output, EventEmitter } from '@angular/core';
//
export interface CommonTableCell {
    template: TemplateRef<unknown>;
    width: string;
}

export interface CommonTableColumn {
    key: string;
    label?: string;
    width: string;
    hideBreakpoint?: string;
}

@Component({
    selector: 'msc-common-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonTableComponent<T> {
    @Input() columns: Array<CommonTableColumn> = []; // table columns
    @Input() rows: Array<T> = []; // table rows;
    @Output() onScrollEnd: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
    @ContentChildren('tdTemplate') tdTemplate: QueryList<TemplateRef<{ datum: T }>>; // template for table body cell
    @ContentChildren('tdExtensionTemplate') tdExtensionTemplate: QueryList<TemplateRef<{ datum: T }>>;
    @ContentChildren('thTemplate') thTemplate: QueryList<TemplateRef<{ datum: CommonTableColumn }>>; // template for table head cell

    public onTrackByIndex: TrackByFunction<T> = (i: number) => i;
    public tdList: Array<CommonTableCell>;
    public thList: Array<CommonTableCell>;

    get extensionTemplate(): TemplateRef<any> { return (this.tdExtensionTemplate?.toArray() || [])[0]; }

    ngAfterContentInit(): void {
        this.tdList = this.getTdList(this.columns);
        this.thList = this.getThList(this.columns);
    }

    /**
     * Get the table <td> cells
     */
    getTdList(columns: Array<CommonTableColumn>): Array<CommonTableCell> {
        return columns.map((column, i) => {
            const template = (this.tdTemplate?.toArray() || [])[i];
            return { ...column, template };
        });
    }

    /**
     * Get the table <th> cells
     */
    getThList(columns: Array<CommonTableColumn>): Array<CommonTableCell> {
        return columns.map((column, i) => {
            const template = (this.thTemplate?.toArray() || [])[i];
            return { ...column, ...(column.hideBreakpoint && {hideBreakpoint: `hidden ${column.hideBreakpoint}:flex`}), template };
        });
    }
}
