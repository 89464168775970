import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { UrlHelper } from '@helpers';
import { Company } from '@lighty';
import { StorageService, TranslateService, ExternalAppsService, EmitterService } from '@services';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'msc-manage',
    templateUrl: './manage.component.html',
    styleUrls: ['./manage.component.scss'],
})

export class ManageComponent {
    public me: any;
    public company: Company;
    public access: any;
    public sidebar: any;
    public applications: any;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private translateService: TranslateService,
        private cookieService: CookieService,
        private externalAppsService: ExternalAppsService,
        private emitterService: EmitterService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.access = this.storageService.set('access', this.route.snapshot.data.access);
        this.sidebar = this.storageService.set('sidebar', this.route.snapshot.data.sidebar);
        this.applications = this.storageService.set('applications', this.route.snapshot.data.applications);

        this.translateService.setLang(this.me.language.code);
        this.externalAppsService.boot(this.me, this.company, this.access.roles, 'manage', this.applications);

        this.emitterService.set('theme.change', this.company.experience.colorTemplate);

        this.processFavicon();
    }

    private processFavicon(): void {
        if (this.company.experience.url?.media) {
            this.emitterService.set('favicon.change', this.company.experience.url.media.pictureUrl);
        }
    }
}
