<div class="content-stats-adoption py-5">
    <div class="content-stats-adoption__header">
        <div class=" w-100 content-stats-adoption__filter  flex align-center between ">
            <!--DATE-->
            <div class=" w-full content-stats-adoption-date content-stats-adoption__search flex align-center gap-4 ">
                <div class="flex space-x-2">
                    <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                        {{ 'words.filters' | translate }}
                    </msc-button>

                    <ng-container *ngIf="isFilterActive">
                        <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh"
                            (click)="onFilterReset(); modalFilters.onReset()">
                            {{ 'words.reset-filters' | translate }}
                        </msc-button>
                    </ng-container>
                </div>

                <div class="date-init">
                    <p>
                        {{ 'words.from' | translate }}
                        <span>{{ dates.from | date: 'dd MMM YYYY' }}</span>
                    </p>
                </div>

                <div class="date-till flex align-center justify-start">
                    <p>{{ 'words.up-to' | translate }}</p>

                    <div class="select" [ngClass]="{'active': displays.date}" (click)="displayed('date')">
                        <div class="select__content">
                            <p class="select__placeholder">
                                {{ dates.upto | date: 'dd MMM YYYY' }}
                                <i class="icon icon-arrow-ios-down"></i>
                            </p>

                            <div class="select__dropdown" (click)="$event.stopPropagation()">
                                <msc-datepicker [date]="dates.upto" [limit]="{min: dates.from, max: today}"
                                    [actions]="true" (onUpdate)="updateDate($event)"
                                    (onClose)="displayed('date', true)"></msc-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--CANVAS-->
            <div class="w-1/2 content-stats-adoption__action hidden-xs flex justify-end md:justify-star">
                <msc-button class="msc-button--primary" type="fab" palette="primary" icon="icon-download-1"
                    (click)="modalExports.open({name: company.name})" [disabled]="loaders.download"
                    [loading]="loaders.download"></msc-button>
            </div>
        </div>
    </div>

    <msc-common-spinner *ngIf="loaders.loading"></msc-common-spinner>

    <div class="content-stats-adoption__body" *ngIf="!loaders.loading">
        <div class="w-3/5 box-stats-adoption-canvas gap-4 box-stats-adoption box flex align-center ">
            <p class="box-stats-adoption__title flex align-center">
                <i class="icon icon-options-2 text-primary-700"></i>
                {{ 'words.adoption-rate' | translate }}
            </p>

            <p class="box-stats-adoption__number text-primary-700">{{ adoptionPercentage }}%</p>
        </div>

        <div class="content-stats-adoption__canvas box">
            <msc-funnel [options]="options"></msc-funnel>

            <div class="content-stats-adoption__sidebar">
                <div class="box box-adoption-details" *ngFor="let other of options.other; let index=index">
                    <p class="box-adoption-details__status flex">
                        <i class="icon icon-arrow-ios-down text-primary-700"></i>
                        {{ other.label }}
                    </p>

                    <p class="box-adoption-details__stats">{{ sum(options.data[index].value, options.data[index +
                        1].value) }}</p>
                    <p class="box-adoption-details__rank text-primary-700">{{ dropOff(options.data[index].value,
                        options.data[index + 1].value) }} {{ 'funnel.adoption.other.drop-off' | translate }}</p>

                    <msc-button class="msc-button--primary" palette="primary" *ngIf="other.cta"
                        (click)="navigate(other.url)">
                        {{ other.cta }}
                    </msc-button>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-stats-export-modal #modalExports (onSave)="download(modalConfirm, $event)"></msc-stats-export-modal>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>

<msc-common-modal size="30vw" #modalConfirm>
    <ng-template>
        <p>The export is <strong>being created</strong>, it may take a <strong>few minutes</strong> before landing in
            your inbox.</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalConfirm.onClose()">{{'common.cancel' |
                translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="modalConfirm.onClose()">{{'common.save' |
                translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
