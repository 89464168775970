import { Component, Input } from '@angular/core';
import { DataHelper, DownloadHelper } from '@helpers';
import { Correction } from '@lighty';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-feat-correction-detail',
    templateUrl: './detail-correction.component.html'
})

export class DetailCorrectionComponent {
    @Input() correction: Correction;
    @Input() contextId: number;
    public isOpen: boolean = false;

    constructor(
        private translateService: TranslateService,
    ) { }

    parse(answer: string): string {
        return answer ? DataHelper.jsonParse(answer, 'text') : this.translateService.instant('words.notification.none');
    }

    download(resource: any): void {
        DownloadHelper.downloadMedia(resource, resource.title);
    }

    toggle(): void {
        this.isOpen = !this.isOpen;
    }
}
