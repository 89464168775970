<form [formGroup]="form">
    <div class="grouping">
        <label>{{ 'organize.marketplace.settings.heading.organisation' | translate }}<span class="asterix">&#42;</span></label>
        <input type="text" formControlName="name" [ngClass]="{'error' : form.controls['name'].errors }">
    </div>

    <div class="grouping">
        <label>{{ 'organize.marketplace.settings.heading.information' | translate }}</label>
        <p [innerHTML]="'organize.marketplace.settings.description.information' | translate : { href: 'https://www.myskillcamp.com/training-provider' }"></p>
        <div class="wrapper">
            <span>https://www.myskillcamp.com/training-provider/</span>&nbsp;
            <div class="wrapper-input">
                <input type="text" formControlName="url" [ngClass]="{'error' : form.controls['url'].errors }">
                <ng-container *ngIf="form.controls['url'].errors">
                    <i class="icon icon-info-1 icon-err" [tooltip]="'organize.marketplace.settings.form.input.url.error' | translate"></i>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="grouping">
        <label>{{ 'organize.marketplace.settings.heading.logo' | translate }}</label>
        <p>{{ 'organize.marketplace.settings.description.logo' | translate }}</p>
        <msc-common-upload [imageUrl]="patch.mediaUrl" (uploadEvent)="onUpload($event)"></msc-common-upload>
    </div>
</form>

<div class="grouping">
    <msc-button class="msc-button--primary" palette="primary" [disabled]="form.status === 'INVALID'" (click)="onSubmit()">{{ 'common.save' | translate }}</msc-button>
</div>
