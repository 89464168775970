import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmitterService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { AdminMemberProvider, Company, CompanyProvider, IntegrationProvider } from '@lighty';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-integration-watch-rules-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})

export class OrganizeIntegrationWatchRulesEditComponent {
    private company: Company;
    private collection: any;
    private excludeMode: boolean;
    public provider: any;
    public comparedRule: any;
    public currentRule: any;
    public navigations: any[] = [
        {
            name: 'words.name',
            unlocked: true
        },
        {
            name: 'words.access',
            unlocked: false
        },
        {
            name: 'words.selection',
            unlocked: false,
            subNav: [{
                name: 'automatic-filtered',
                condition: true
            }, {
                name: 'exclude',
                condition: false
            }]
        },
        {
            name: 'words.summary',
            unlocked: false
        }
    ];
    public navigationIndex: number = 0;
    public subNavigationIndex: number = -1;
    public allowNextNavigation: boolean = false;
    public isEditing: boolean = false;
    public isLoading: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private integrationProvider: IntegrationProvider,
        private companyProvider: CompanyProvider,
        private memberProvider: AdminMemberProvider,
        private translateService: TranslateService,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.provider = this.storageService.get('currentRuleProvider');
        this.collection = this.route.snapshot.data.collection;

        this.isEditing = this.router.url.indexOf('rules/edit') > -1;

        this.currentRule = this.route.snapshot.data.rule || {
            name: '',
            selectionType: '',
            publicationType: '',
            camps: [],
            selectedCategories: [],
            selectedSubcategories: [],
            selectedLanguages: [],
            contents: [],
            excludedContents: [],
            allContent: false
        };
        if (!this.currentRule.excludedContents) {
            this.currentRule.excludedContents = [];
        }
        if (this.currentRule.languages) {
            this.currentRule.selectedLanguages = this.currentRule.languages;
        }
        if (this.currentRule.categories) {
            this.currentRule.selectedCategories = this.currentRule.categories;
        }
        if (this.currentRule.subcategories) {
            this.currentRule.selectedSubcategories = this.currentRule.subcategories;
            for (const category of this.currentRule.selectedCategories) {
                for (const subcategory of category.subcategories) {
                    const index = this.currentRule.selectedSubcategories.findIndex(subcat => subcat.id === subcategory.id);
                    if (index !== -1) {
                        subcategory.selected = true;
                    }
                }
            }
        }

        this.comparedRule = Object.assign({}, this.currentRule);
    }

    getRouterBack(): string {
        if (this.router.url.indexOf('rules/edit') > -1) {
            return '../../';
        } else {
            return '../';
        }
    }

    onArianeClick(index: number): void {
        this.navigationIndex = index;
    }

    checkNavigation(value: boolean): void {
        this.allowNextNavigation = value;
    }

    navigateThroughPanelsUp(): void {
        this.allowNextNavigation = false;
        this.navigations[2].subNav[1].condition = this.currentRule.selectionType === 'filters' ? this.excludeMode : false;
        if (this.navigations[this.navigationIndex].subNav && this.currentRule.selectionType !== 'all') {
            if (this.subNavigationIndex < this.navigations[this.navigationIndex].subNav.length && this.navigations[this.navigationIndex].subNav[this.subNavigationIndex].condition) {
                this.subNavigationIndex += 1;
            } else {
                this.subNavigationIndex = 0;
                this.navigations[this.navigationIndex].unlocked = true;
                this.navigationIndex += 1;
            }
        } else {
            if (this.navigations[this.navigationIndex + 1]) {
                this.subNavigationIndex = 0;
                this.navigations[this.navigationIndex].unlocked = true;
                this.navigationIndex += 1;
            }
        }
    }

    navigateThroughPanelsDown(): void {
        this.allowNextNavigation = false;
        this.navigations[2].subNav[1].condition = this.currentRule.selectionType === 'filters' ? this.excludeMode : false;
        if (this.navigations[this.navigationIndex].subNav && this.currentRule.selectionType !== 'all') {
            if (this.navigationIndex > 0 && this.navigations[this.navigationIndex].subNav[this.subNavigationIndex - 1]?.condition) {
                this.subNavigationIndex -= 1;
            } else {
                if (this.navigations[this.navigationIndex - 1].subNav) {
                    let index = 0;
                    for (let i = this.navigations[this.navigationIndex - 1].subNav.length - 1; i >= 0; i--) {
                        if (this.navigations[this.navigationIndex - 1].subNav[i].condition) {
                            index = i;
                            break;
                        }
                    }
                    this.subNavigationIndex = index;
                }
                this.navigationIndex -= 1;
            }
        } else {
            if (this.navigations[this.navigationIndex - 1].subNav && this.currentRule.selectionType !== 'all') {
                let index = 0;
                for (let i = this.navigations[this.navigationIndex - 1].subNav.length - 1; i >= 0; i--) {
                    if (this.navigations[this.navigationIndex - 1].subNav[i].condition) {
                        index = i + 1;
                        break;
                    }
                }
                this.subNavigationIndex = index;
            }
            this.navigationIndex -= 1;
        }
    }

    onExcludeModalSave(): void {
        this.navigations[2].subNav[1].condition = true;
        this.excludeMode = true;
        this.navigateThroughPanelsUp();
    }

    onExcludeModalCancel(): void {
        this.navigations[2].subNav[1].condition = false;
        this.excludeMode = false;
        this.navigateThroughPanelsUp();
    }

    renameRule(): void {
        const params = {
            name: this.currentRule.name
        };

        this.integrationProvider.renameRule(this.company.id, this.provider.id, this.collection.collectionId, this.currentRule.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    saveRule(): void {
        this.isLoading = true;
        const params = HttpHelper.cleanParams({
            name: this.currentRule.name,
            selectionType: this.currentRule.selectionType,
            publicationType: this.currentRule.publicationType,
            camps: this.currentRule.camps ? this.currentRule.camps.map(camp => camp.id) : null,
            categories: this.currentRule.selectedCategories ? this.currentRule.selectedCategories.map(category => category.id) : null,
            subcategories: this.currentRule.selectedSubcategories ? this.currentRule.selectedSubcategories.map(subCategory => subCategory.id) : null,
            languages: this.currentRule.selectedLanguages ? this.currentRule.selectedLanguages.map(lang => lang.id) : null,
            contents: this.currentRule.contents ? this.currentRule.contents.map(content => content.id) : null,
            allContent: !!this.currentRule.allContent,
            excludedContents: this.currentRule.excludedContents ? this.currentRule.excludedContents : null,
        });

        if (this.currentRule.publicationType === 'newCamp') {
            const campParam = {
                name: this.storageService.get('currentRuleProvider').name + ' - ' + this.currentRule.name
            };
            this.companyProvider
                .createCamp(this.company.id, campParam)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(camp => {
                    params.publicationType = 'camps';
                    params.camps.push(camp.id);
                    if (this.currentRule.inviteAll) {
                        const inviteParam = {
                            invite_all_accounts: true,
                            roles: {
                                camp_learner: true
                            }
                        };
                        this.memberProvider.inviteMembers(camp.id, inviteParam).subscribe(() => {
                            this.handleRule(params);
                        });
                    } else {
                        this.handleRule(params);
                    }
                }, () => {
                    this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                });
        } else {
            this.handleRule(params);
        }
    }

    handleRule(params: any): void {
        if (this.router.url.indexOf('rules/edit') > -1) {
            this.integrationProvider.updateCollectionRule(this.company.id, this.provider.id, this.collection.collectionId, this.currentRule.id, params).subscribe(() => {
                this.emitterService.set('refresh.integration.deploy', true);
                this.toastService.onSuccess(this.translateService.instant('toast.integration.rule-updated'));
                this.router.navigateByUrl(`organize/${this.company.slug}/integrations/providers/${this.provider.id}/collections/${this.collection.collectionId}/rules`);
            }, () => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            });
        }

        if (this.router.url.indexOf('rules/create') > -1) {
            this.integrationProvider.createCollectionRule(this.company.id, this.provider.id, this.collection.collectionId, params).subscribe(() => {
                this.emitterService.set('refresh.integration.deploy', true);
                this.toastService.onSuccess(this.translateService.instant('toast.integration.rule-created'));
                this.router.navigateByUrl(`organize/${this.company.slug}/integrations/providers/${this.provider.id}/collections/${this.collection.collectionId}/rules`);
            }, () => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            });
        }
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (JSON.stringify(this.currentRule) !== JSON.stringify(this.comparedRule) && this.navigationIndex < this.navigations.length - 1) {
            const result = window.confirm(this.translateService.getTranslate('integrations.rules.leave-unsaved'));
            return of(result);
        }
        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    leavePage($event: any): void {
        if (this.currentRule !== this.comparedRule) {
            $event.returnValue = 'Your data will be lost!';
        }
    }
}
