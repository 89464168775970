<div class="modal modal--center modal--full-width" [ngClass]="{'active': isActive}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__close">
            <i class="icon icon-close" (click)="close()"></i>
        </div>

        <div class="block-onboarding">
            <ng-container *ngIf="phase === 0">
                <div class="block-onboarding__header">
                    <h3>Course selection</h3>
                </div>
    
                <msc-onboarding-topbar-filters [accountId]="accountId" [isTeam]="true" [type]="'catalogue'" [color]="'white'" [buildFilters]="buildFilters"
                    [(query)]="query" [(filters)]="filters" [(activeFilters)]="activeFilters" (queryChange)="search()"
                    (filtersChange)="search()" [context]="'subscribe'" [accountId]="accountId"></msc-onboarding-topbar-filters>
    
                <div class="text-center">
                    <p *ngIf="catalogue.length === 0">
                        {{ 'empty.available.data' | translate }}
                    </p>
                </div>
    
                <div class="block-onboarding__cards flex justify-start wrap">
                    <div class="col-xxs-12" *ngIf="loading">
                        <msc-common-spinner *ngIf="loading"></msc-common-spinner>
                    </div>
    
                    <div class="cards-container row">
                        <div class="card card--onboarding col-2 col-xxs-12 col-card" *ngIf="lastSelected"
                            [ngClass]="{'card--checked': selectedContent?.data?.id === lastSelected.data.id}" (click)="selectContent(lastSelected)">
                            <div class="card__header">
                                <div class="card__banner card__banner--container flex between" *ngIf="lastSelected.type === 'external_content'">
                                    <div class="card__radio"></div>
                                    <div class="card__last-selection" *ngIf="selectedContent?.data?.id !== lastSelected.data.id">
                                        <p>{{ 'onboarding.content.last-selection' | translate }}</p>
                                    </div>
    
                                    <div class="card__banner--container__picture"
                                        [style.backgroundImage]="'url(' + (lastSelected.data.provider?.logo ? (lastSelected.data.provider.logo + '?size=256') : ('https://static.myskillcamp.com/images/integrations/' + lastSelected.data.provider?.key + '.svg')) + ')'">
                                    </div>
    
                                    <msc-tag [color]="lastSelected.data.type === 'traject' ? 'red' : ''"
                                        [text]="'filter.content-type.' + lastSelected.data.type | translate">
                                    </msc-tag>
                                </div>
    
                                <ng-container *ngIf="lastSelected.type !== 'external_content'">
                                    <div class="card__radio"></div>
    
                                    <div class="card__last-selection" *ngIf="selectedContent?.data?.id !== lastSelected.data.id">
                                        <p>{{ 'onboarding.content.last-selection' | translate }}</p>
                                    </div>
    
                                    <div class="card__tags">
                                        <msc-tag [color]="lastSelected.data.type === 'traject' ? 'red' : ''"
                                            [text]="'filter.content-type.' + lastSelected.data.type | translate">
                                        </msc-tag>
                                    </div>
                                </ng-container>
    
                                <div class="card__image">
                                    <div *ngIf="lastSelected.data.picture"
                                        [ngStyle]="{'background-image': 'url(' + lastSelected.data.picture + '?size=512)'}">
                                    </div>
    
                                    <div *ngIf="!lastSelected.data.picture"
                                        [ngStyle]="{'background-image': 'url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + lastSelected.data.id % 7 + '.jpg)'}">
                                    </div>
                                </div>
                            </div>
    
                            <div class="card__body">
                                <div class="card__time" *ngIf="lastSelected.data.duration">
                                    <i class="icon icon-clock"></i>
                                    <span class="card_time_text">{{ lastSelected.data.duration | duration }}</span>
                                </div>
    
                                <p class="card__title" [tooltip]="lastSelected.data.title?.length > 30 ? lastSelected.data.title : ''">
                                    {{ lastSelected.data.title | truncate: 30 }}
                                </p>
    
                                <p class="card__desc leading-3"
                                    [tooltip]="lastSelected.data.description?.length > 50 ? lastSelected.data.description: ''"
                                    [innerHTML]="lastSelected.data.description || ('words.undescribed-content' | translate) | truncate: 130">
                                </p>
                            </div>
    
                            <div class="card__footer">
                                <div class="card__widgets">
                                    <a *ngIf="lastSelected.data.instanceCount">{{lastSelected.data.instanceCount}}
                                        <i class="icon icon-content"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
    
                        <div class="card card--onboarding col-2 col-xxs-12 col-card" *ngFor="let content of catalogue" [ngClass]="{'card--checked': selectedContent?.data?.id === content.data.id}" (click)="selectContent(content)">
                            <div class="card__header">
                                <div class="card__banner card__banner--container flex between" *ngIf="content.type === 'external_content'">
                                    <div class="card__radio"></div>
                                    <div class="card__banner--container__picture"
                                        [style.backgroundImage]="'url(' + (content.data.provider?.logo ? (content.data.provider.logo + '?size=256') : ('https://static.myskillcamp.com/images/integrations/' + content.data.provider?.key + '.svg')) + ')'">
                                    </div>
                                    <msc-tag [color]="content.data.type === 'traject' ? 'red' : ''"
                                        [text]="'filter.content-type.' + content.data.type | translate">
                                    </msc-tag>
                                </div>
    
                                <ng-container *ngIf="content.type !== 'external_content'">
                                    <div class="card__radio"></div>
                                    <div class="card__tags">
                                        <msc-tag [color]="content.data.type === 'traject' ? 'red' : ''"
                                            [text]="'filter.content-type.' + content.data.type | translate">
                                        </msc-tag>
                                    </div>
                                </ng-container>
    
                                <div class="card__image">
                                    <div *ngIf="content.data.picture"
                                        [ngStyle]="{'background-image': 'url(' + content.data.picture + '?size=512)'}">
                                    </div>
    
                                    <div *ngIf="!content.data.picture"
                                        [ngStyle]="{'background-image': 'url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.data.id % 7 + '.jpg)'}">
                                    </div>
                                </div>
                            </div>
    
                            <div class="card__body">
                                <div class="card__time" *ngIf="content.data.duration">
                                    <i class="icon icon-clock"></i>
                                    <span class="card_time_text">{{content.data.duration | duration}}</span>
                                </div>
    
                                <p class="card__title"
                                    [tooltip]="content.data.title?.length > 30 ? content.data.title : ''">
                                    {{ content.data.title | truncate: 30 }}</p>
                                <p class="card__desc leading-3"
                                    [tooltip]="content.data.description?.length > 80 ? content.data.description : ''"
                                    [innerHTML]="content.data.description || ('words.undescribed-content' | translate) | truncate: 80">
                                </p>
                            </div>
    
                            <div class="card__footer">
                                <div class="card__widgets">
                                    <a *ngIf="content.data.instanceCount">{{content.data.instanceCount}}
                                        <i class="icon icon-content"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-xxs-12">
                        <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
                    </div>
                </div>
    
                <div class="actions">
                    <msc-button class="msc-button--primary" palette="secondary" *ngIf="selectedContent === 'skip' || selectedContent?.type === 'external_content'" (click)="save()">
                        {{ 'words.validate' | translate }}
                    </msc-button>
                </div>
            </ng-container>
    
            <ng-container *ngIf="phase === 1">
                <div class="block-onboarding__header">
                    <h3>Sessions selection</h3>
                </div>
    
                <div class="block-onboarding__list col-md-5 col-xs-12">
                    <h3 *ngIf="selectedContent.data.title">{{ selectedContent.data.title }}</h3>
                    <h3 *ngIf="!selectedContent.data.title">{{ 'instance.untitled' | translate }}</h3>
                    <ng-container *ngFor="let contentSession of sessionList">
                        <div *ngIf="contentSession.schema !== 'template'" class="box box-content flex center between box--shadow"
                            [ngClass]="{'selected': contentSession.selected}">
                            <div class="box-content__infos flex align-center justify-start">
                                <label class="radio cursor-pointer">
                                    <input type="radio" [checked]="sessionSelection['course']?.id === contentSession.id" (click)="selectInstance(contentSession)">
                                    <span class="radio__checkmark"></span>
                                </label>
                                <p>
                                    <span *ngIf="!contentSession.internalName">
                                        {{ contentSession.title | truncate: 30 }}
                                    </span>
    
                                    <span *ngIf="contentSession.internalName">
                                        {{ contentSession.internalName | truncate: 30 }}
                                    </span>
                                </p>
                            </div>
    
                            <div class="justify-end flex">
                                <div class="box-content__participant flex align-center">
                                    <i class="icon icon-people"></i>
                                    <p>{{ contentSession.usersCount }}</p>
                                </div>
    
                                <div class="box-content__participant flex align-center">
                                    <i class="icon icon-calendar"></i>
                                    <p>{{ contentSession.eventsCount }}</p>
                                </div>
    
                                <div class="box-content__participant flex align-center">
                                    <i class="icon icon-message-circle"></i>
                                    <p>{{ contentSession.language.name }}</p>
                                </div>
                            </div>
                        </div>
    
                        <ng-container *ngIf="contentSession.schema === 'template'">
                            <div class="dynamic-table__container" [ngClass]="{'active': contentSession.display}">
                                <div class="dynamic-table__body">
                                    <div class="dynamic-table__row row-radius row-shadow slide__checkbox" (click)="contentSession.display = !contentSession.display">
                                        <div class="dynamic-table__cell cell-title--small">
                                            <ng-container *ngIf="contentSession.title">
                                                {{ contentSession.title }}
                                            </ng-container>
    
                                            <ng-container *ngIf="!contentSession.title">
                                                {{ 'words.untitled' | translate }}
                                            </ng-container>
                                        </div>
    
                                        <div class="dynamic-table__cell cell-with-icon cell-large">
                                            <div class="cell-with-icon__container" *ngIf="contentSession.instances">
                                                <i class="icon icon-timer"></i>
                                                <div>{{ contentSession.duration | duration }}</div>
                                            </div>
    
                                            <p class="text-error color--red" *ngIf="!contentSession.instances">
                                                <i class="icon icon-alert-triangle"></i>
                                                {{ 'journey.missing-instance' | translate }}
                                            </p>
                                        </div>
    
                                        <i *ngIf="contentSession.instances.length > 1" class="icon-displayer icon icon-arrow-ios-down"></i>
                                    </div>
    
                                    <div class="dynamic-table__content" *ngIf="contentSession.instances.length > 1">
                                        <div class="dynamic-table__body">
                                            <div class="dynamic-table__row row-radius row-shadow slide__checkbox"
                                                *ngFor="let instance of contentSession.instances; let i=index">
    
                                                <label class="radio">
                                                    <input type="radio" [attr.name]="instance.id"
                                                        [checked]="sessionSelection[contentSession.id]?.id === instance.id"
                                                        (click)="selectInstance(instance, contentSession.id)">
                                                    <span class="radio__checkmark"></span>
                                                </label>
    
                                                <div class="dynamic-table__cell cell-title--small">
                                                    {{ instance.internalName }}
                                                </div>
    
                                                <div class="dynamic-table__cell cell-with-icon cell-large">
                                                    <div class="cell-with-icon__container">
                                                        <i class="icon icon-people"></i>
                                                        <div>{{ instance.usersCount }}</div>
                                                        <div *ngIf="instance.usersLimit">/{{ instance.usersLimit }}</div>
                                                    </div>
    
                                                    <div class="cell-with-icon__container">
                                                        <i class="icon icon-message-circle"></i>
                                                        <div>{{ instance.language.name }}</div>
                                                    </div>
                                                </div>
    
                                                <div class="dynamic-table__cell cell-with-icon">
                                                    <i class="cell-actions__icon icon icon-calendar"></i>
                                                    <div class="color--orange">{{ instance.eventsCount }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="flex space-x-2">
                    <msc-button (click)="back()">
                        {{ 'words.back' | translate }}
                    </msc-button>

                    <msc-button class="msc-button--primary" palette="secondary" [disabled]="!isContentSelected" (click)="save()">
                        {{ 'words.validate' | translate }}
                    </msc-button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
