export class Camp {
    id: number;
    companyId: number;
    name: string;
    slug: string;
    welcomeText: string;
    settings?: any;
    picture?: string;
    headline: string;
    permissions?: any;
    usersCount?: number;
    instancesCount?: number;
    provider?: boolean;

    constructor(camp: object) {
        this.assign(camp);
    }

    assign(camp: object): void {
        Object.assign(this, camp);
    }
}
