import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { DataHelper } from '@helpers';
import { EmitterService, TranslateService } from '@services';
import FroalaEditor from 'froala-editor';


@Component({
    selector: 'msc-froala',
    templateUrl: './froala.component.html'
})
export class FroalaComponent implements OnInit, AfterViewInit {
    @Input() plugins: any;
    @Input() content: string;
    @Output() contentChange: EventEmitter<string> = new EventEmitter();
    @Output() onUpdate: EventEmitter<string> = new EventEmitter();
    private readonly froalaKey: string = 'hWA2C-7G2C5A4C1B1D1vd1EAJLQCVLUVBa1NXNRSSATEXD-13C3B2E2D2E3B1A2C7E2E2==';
    private editor: any;
    public id: string = DataHelper.generateId('froala-');

    constructor(private translateService: TranslateService, private emitterService: EmitterService) {}

    ngOnInit(): void {
        this.emitterService.get('froala.refresh').subscribe((data) => {
            if (typeof data === 'boolean' && data) {
                this.editor.html.set(this.content);
                this.emitterService.set('froala.refresh', false);
            }
        });
    }

    ngAfterViewInit(): void {
        this.editor = new FroalaEditor(`#${this.id}`, {
            key: this.froalaKey,
            attribution: false,
            typingTimer: 800,
            language: this.translateService.getCurrentLang(),
            pluginsEnabled: this.getPlugins(),
            toolbarButtons: {
                moreText: {
                    buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                },
                moreParagraph: {
                    buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                    buttonsVisible: 2
                },
                moreRich: {
                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
                },
                moreMisc: {
                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                    align: 'right',
                    buttonsVisible: 2
                }
            },
            events: {
                initialized: () => {
                    this.editor.selection.save();
                    this.editor.html.set(this.content);
                },
                contentChanged: () => {
                    this.onChange(this.editor.html.get(true));
                }
            }
        });
    }

    private getPlugins(): any {
        if (this.plugins) {
            if (typeof this.plugins === 'object') {
                return this.plugins;
            }

            if (this.plugins === 'normal') {
                return [
                    'colors', 'align', 'fontSize',
                    'fontFamily', 'link', 'paragraphFormat',
                    'table', 'quote', 'lists',
                    'image', 'video', 'codeView'
                ];
            }

            if (this.plugins === 'mini') {
                return [
                    'colors', 'fontSize', 'fontFamily',
                    'link', 'paragraphFormat', 'lists'
                ];
            }
        }

        return [
            'align', 'charCounter', 'codeBeautifier', 'codeView',
            'colors', 'draggable', 'embedly', 'entities',
            'fontFamily', 'fontSize', 'image',
            'lineBreaker', 'link', 'lists', 'paragraphFormat',
            'quickInsert', 'quote', 'table', 'url',
            'video', 'wordPaste'
        ];
    }

    private onChange(content: string): void {
        this.content = content;
        this.contentChange.emit(this.content);
        this.onUpdate.emit(content);
    }
}
