<div class="content-summary__main content-template-instance-summary row">
    <div class="summary__main col-8 col-md-8 col-xxs-12" *ngIf="summary.sections && summary.sections.length > 0">
        <div class="dropdown-module" *ngFor="let section of summary.sections; let index = index"
             [ngClass]="{'active' : openSections[index]}">
            <div class="block-section">
                <div class="block-section__wrapper">
                    <div class="block-section__header">
                        <div class="block-section__title">{{ section.title || ('words.untitled' | translate) }}</div>

                        <div class="block-section__widgets">
                            <p class="block-section__results" *ngIf="section.progress && section.progress.score">
                                {{ 'words.score' | translate }} : <span>{{ section.progress.score }}</span>
                            </p>

                            <div class="block-section__link" *ngIf="!instance.isLocked">
                                <a class="link link-play text-primary-700" (click)="canProgressToSection(section)" *ngIf="section.progress && section.progress.finishedPercent < 100">
                                    <span>{{ getTranslationButton(section) }}</span>
                                    <i class="icon icon-arrow-right bg-primary-700"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="block-section__footer" *ngIf="section.progress">
                        <msc-progress-bar [color]="section.progress.finishedPercent !== 100 ? 'blue': ''" [progress]="section.progress.finishedPercent | number: '1.0-0'"></msc-progress-bar>
                    </div>

                    <div class="block-section__toggle" (click)="openDropdownSections(index)" [ngClass]="{'block-section__toggle-active': !section.sectionContents}">
                        <i class="icon icon-arrow-ios-down text-secondary-500" *ngIf="section.sectionContents"></i>
                    </div>
                </div>
            </div>

            <div class="content-summary__content" *ngIf="openSections[index] && section.sectionContents">
                <div class="block-task" *ngFor="let activity of section.sectionContents">
                    <div class="block-task__wrapper">
                        <div class="block-task__header">
                            <div class="block-task__title">
                                <i class="icon" [ngClass]="getActivityIcon(activity.context)"></i>

                                {{ activity.title || ('words.untitled' | translate) }}
                            </div>

                            <div class="block-task__widgets">
                                <p class="task__results" *ngIf="activity.session?.score">{{ 'words.score' | translate }} : <span>{{ activity.session.score }}</span></p>

                                <a class="link link-play text-primary-700" (click)="redirectActivityToPlayer(section, activity)" *ngIf="!instance.isLocked">
                                    <span>{{ getTranslationButtonActivity(activity) }}</span>
                                    <i class="icon icon-arrow-right bg-primary-700"></i>
                                </a>
                            </div>
                        </div>

                        <div class="block-task__footer">
                            <msc-progress-bar [color]="activity.session ? activity.session.progress !== 100 ? 'blue' : '' : ''" [progress]="activity.session ? (activity.session.progress | number: '1.0-0') : '0'"></msc-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <aside class="col-4 col-md-4 col-xxs-12 summary__aside">
        <div class="block-stats">
            <div class="stats">
                <div class="stats__size">
                    <msc-chart-pie [value]="summary.session?.progress" [valueColor]="'#ED8371'" [backgroundColor]="'#FFCBC4'"></msc-chart-pie>
                </div>

                <div class="stats__text">
                    <p>{{ 'words.progress' | translate }}</p>
                </div>
            </div>

            <div class="stats">
                <div class="stats__size">
                    <msc-chart-pie [value]="summary.session?.score"></msc-chart-pie>
                </div>

                <div class="stats__text">
                    <p>{{ 'words.score' | translate }}</p>
                </div>
            </div>
        </div>
    </aside>
</div>
