import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { StorageService, RedirectService } from '@services';
import { UserProvider } from '@lighty';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class TeamGuard implements CanActivate {

    constructor(private storageService: StorageService, private userProvider: UserProvider,
        private redirectService: RedirectService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const data = await firstValueFrom(this.userProvider.me());
        this.storageService.set('me', data);
        if (data['teamOwnerId']) {
            return true;
        } else {
            this.redirect(route);
            return false;
        }
    }

    private redirect(route): void {
        const company: any = this.storageService.get('company');
        const slug = company?.slug || route.parent.paramMap.get('slug');
        this.redirectService.navigate('app', ['manage', slug, 'contents']);
    }
}
