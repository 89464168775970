import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminCorrectionProvider, Camp, Company, TimelineProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { MediaManagerComponent } from '@components/media-manager/media-manager.component';
import { TimelineComponent } from '@components/timeline/timeline.component';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-camp-self-assessment-correction',
    templateUrl: './self-assessment.component.html'
})

export class CampSelfAssessmentCorrectionComponent {
    @ViewChild('mediaManagerTrainer') mediaManagerTrainer: MediaManagerComponent;
    @ViewChild('correctionTimeline') correctionTimeline: TimelineComponent;
    @Input() session: any;
    @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();
    private camp: Camp;
    private company: Company;
    public loading: boolean = false;

    constructor(
        private correctionProvider: AdminCorrectionProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private timelineProvider: TimelineProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.camp = this.storageService.get('camp');
        this.company = this.storageService.get('company');
    }

    ngOnChanges(): void {
        if (this.correctionTimeline && this.correctionTimeline.ready) {
            this.correctionTimeline.contextId = this.session.id;
            this.correctionTimeline.getTimeline();
        }
    }

    addMedia(media: any): void {
        if (media) {
            this.session.correctionMedia.push(media);
        }
    }

    deleteMedia(media: any): void {
        const index = this.session.correctionMedia.indexOf(media);
        if (index !== -1) {
            this.session.correctionMedia.splice(index, 1);
        }
    }

    update(): void {
        this.loading = true;
        const params = HttpHelper.cleanParams({
            camp_id: this.camp.id,
            company_id: this.company.id,
            ratio: this.session.ratio,
            feedback: this.session.feedback,
            correctionMediaIds: this.session.correctionMedia.map(media => media.id)
        });

        this.correctionProvider.update('self-assessments', this.session.selfassessmentId, this.session.id, params).subscribe((data) => {
            this.session = data;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.loading = false;

            this.onUpdate.emit('selfassessment');

            const parameters = HttpHelper.cleanParams({
                company_id: this.company.id,
                timeline_id: this.correctionTimeline.timelineId,
                content: {
                    message: this.translateService.instant('self-assessment.timeline.correction.manager'),
                    media_id: null
                },
                type: 'mixed'
            });

            this.timelineProvider.send(parameters).subscribe((item) => {
                this.correctionTimeline.getTimeline();
            });
        });
    }

    spySelfAssessment(): void {
        this.session.ratio = this.session.ratio > 100 ? 100 : this.session.ratio;
    }
}
