import { Injectable, NgZone } from '@angular/core';
import { DataHelper } from '@helpers';
import { add, find } from '@functions';

type Me = {
    id: string,
    language: { code: string },
    email?: string,
    firstname?: string,
    lastname?: string
}

function makeConfigScript(productId: number, me: Me, companyId, filters: string): string {
    const safe = (x?: string): string => {
        if (x === null) {
            return '';
        } else {
            return x.replace('\'', '');
        }
    };
    return `var beamer_config = {
        product_id: '${productId}',
        language: '${me.language.code.toUpperCase()}',
        user_email: '${safe(me?.email)}',
        user_firstname : '${safe(me?.firstname)}',
        user_lastname : '${safe(me?.lastname)}',
        user_id: '${me.id}',
        company_id: '${companyId}',
        filter: '${filters}',
        alert: true,
        selector: '#beamerHook'
    }`;
}

/**
 * Script injector
 */
@Injectable({ providedIn: 'root' })
export class ScriptInjectorService {
    embedly = false;
    /**
     * Init
     * @constructor
     */
    constructor(private _ngZone: NgZone) { }

    /**
     * Inject freshchat inside HTML
     * @param {string} content - Script content
     * @param {any} params - parameters
     */
    freshchat(content: string, params: any): void {
        if (content.indexOf('initFreshChat') > -1) {
            const script = content.replace('<script>', '')
                .replace('</script>', '')
                .replace('<#externalId>', params.email)
                .replace('<#restoreId>', params.restoreId)
                .trim();

            add('#custom-app', 'script', null, { type: 'text', value: script });
            add('#custom-app', 'script', null, { type: 'text', value: 'initiateCall()' });
        } else {
            const html = DataHelper.split(content, '<script', true);
            const url = DataHelper.splitBetween(content, '<script src="https', '"></script>').replace('<script src="', '');
            const script = content.replace(html, '')
                .replace(`<script src="${url}"></script>`, '')
                .replace('<script>', '')
                .replace('</script>', '')
                .replace('<#externalId>', params.email)
                .replace('<#restoreId>', params.restoreId)
                .trim();

            const timeOut = `setTimeout(() => {${script}}, 2500);`;

            find('#custom-app').innerHTML += html;
            add('#custom-app', 'script', { type: 'src', value: url });
            add('#custom-app', 'script', null, { type: 'text', value: timeOut });
        }
    }

    /**
     * Inject appcues inside HTML
     */
    appcues(): void {
        const url = '//fast.appcues.com/52146.js';
        add('#custom-app', 'script', { type: 'src', value: url });
    }

    /**
     * Inject hubspot inside HTML
     */
    hubspot(hook: string): void {
        const url = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        add(hook, 'script', { type: 'src', value: url });
    }


    /**
     * Inject beamer inside HTML
     * https://www.getbeamer.com/docs/#parameters
     * @param {number} productId - Product identification number
     * @param {any} me - Current user
     * @param {string} filters - Current filters
     */
    beamer(productId: number, me: Me, companyId, filters: string): void {
        const script = makeConfigScript(productId, me, companyId, filters);
        add('#custom-app', 'script', null, { type: 'text', value: script });
        add('#custom-app', 'script', { type: 'src', value: 'https://app.getbeamer.com/js/beamer-embed.js' });
    }

    /**
     * Inject ??
     */
    platformEmbedly(): void {
        if (this.embedly) {
            return;
        }

        this._ngZone.runOutsideAngular(() => {
            add('body', 'script', [{ type: 'defer' }, { type: 'src', value: "https://cdn.embedly.com/widgets/platform.js" }]);
        })
    }
}
