import { Component, OnInit, ViewChildren, HostListener } from '@angular/core';
import { FORMSTEPS } from './configure.enum';
import { Company, Camp, CompanyExperience, CompanyUserProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { OrganizeUserStepBaseComponent } from './configure.component.base';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-user-configure',
    templateUrl: './configure.component.html',
    styleUrls: ['./configure.component.scss']
})
export class OrganizeUserConfigureComponent implements OnInit {
    public FORMSTEPS = FORMSTEPS;
    formSteps = {
        [FORMSTEPS.PERSONAL_INFO]: {
            label: 'words.personal-data',
            inprog: true,
            complete: false,
            enabled: true,
            next: FORMSTEPS.ACCESSES
        },
        [FORMSTEPS.ACCESSES]: {
            label: 'words.roles-access',
            inprog: false,
            complete: false,
            enabled: true,
            next: this.checkICoach
        },
        [FORMSTEPS.COACH]: {
            label: 'words.coach',
            inprog: false,
            complete: false,
            enabled: false,
            next: FORMSTEPS.SETTINGS
        },
        [FORMSTEPS.SETTINGS]: {
            label: 'words.settings',
            inprog: false,
            complete: false,
            enabled: true,
            next: this.checkIfOnboarding
        },
        [FORMSTEPS.ONBOARDING_CONTENT]: {
            label: 'words.onboarding',
            inprog: false,
            complete: false,
            enabled: false,
            next: FORMSTEPS.REVIEW
        },
        [FORMSTEPS.REVIEW]: {
            label: 'words.summary',
            inprog: false,
            complete: false,
            enabled: true,
            next: undefined
        }
    };
    currentForm = FORMSTEPS.PERSONAL_INFO;
    public company: Company;
    public availableCamps: Camp[];
    public availableExperiences: CompanyExperience[];
    public availableManagers: any[];
    public displayDropdowns: any = {};
    steps: any = {};
    saved: boolean = false;

    keepSettings;

    @ViewChildren(OrganizeUserStepBaseComponent) set setSteps(steps) {
        const timeOut = setTimeout(() => {
            this.steps = {};
            steps.forEach((step: OrganizeUserStepBaseComponent) => {
                this.steps[step.formId] = step;
            });
            clearTimeout(timeOut);
        }, 500);
    }
    me: any;

    constructor(
        private companyUserProvider: CompanyUserProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.getAvailableCamps();
        this.getAvailableExperiences();
        this.getAvailableManagers();
    }

    private getAvailableCamps(): void {
        this.companyUserProvider.getCamps(this.company.id).subscribe((camps) => {
            this.availableCamps = camps;
        });
    }

    private getAvailableExperiences(): void {
        this.companyUserProvider.getExperiences(this.company.slug).subscribe((data) => {
            this.availableExperiences = data;
        });
    }

    private getAvailableManagers(): void {
        this.companyUserProvider.getManagers(this.company.id).subscribe((data) => {
            this.availableManagers = data.managers;
        });
    }

    goTo(form: any, validated?, fromNav?): void {
        if (!validated && this.formSteps[this.currentForm].complete && !this.steps[this.currentForm].isValid()) {
            return;
        }

        if (typeof form === 'function') {
            form = form.apply(this);
        } else if (form == FORMSTEPS.ACCESSES) {
            this.formSteps[FORMSTEPS.SETTINGS].next = this.checkIfOnboarding;
            this.formSteps[FORMSTEPS.ACCESSES].next = this.checkICoach;
        }

        if (form > this.currentForm && !validated && !this.steps[this.currentForm].isValid()) {
            return;
        }

        if (fromNav && form > this.currentForm && !this.formSteps[form].inprog) {
            return;
        } else if (this.formSteps[form].inprog) {
            this.formSteps[this.currentForm].complete = form > this.currentForm;
            this.currentForm = form;
            this.formSteps[this.currentForm].inprog = true;
            if (form === FORMSTEPS.SETTINGS) {
                this.calcNextStep();
            }
            return;
        }

        if ((typeof this.formSteps[this.currentForm].next !== 'function' && this.formSteps[this.currentForm].next !== form) && !this.formSteps[form].inprog) {
            return;
        }

        if (this.currentForm !== FORMSTEPS.REVIEW && !validated && (this.steps[this.currentForm] && !this.steps[this.currentForm].isValid())) {
            return;
        }

        this.formSteps[this.currentForm].complete = form > this.currentForm;
        this.currentForm = form;
        this.formSteps[this.currentForm].inprog = true;
        this.calcNextStep();
        window.scrollTo(0, 0);
    }

    checkIfOnboarding(): any {
        this.formSteps[FORMSTEPS.ONBOARDING_CONTENT].enabled = this.steps[FORMSTEPS.ACCESSES].data.roles.learn.status && this.steps[FORMSTEPS.ACCESSES].data.roles.learn.campsSelected.length > 0;
        return this.formSteps[FORMSTEPS.ONBOARDING_CONTENT].enabled ? FORMSTEPS.ONBOARDING_CONTENT : FORMSTEPS.REVIEW;
    }

    calcNextStep(): void {
        if (typeof this.formSteps[this.currentForm].next === 'function') {
            this.formSteps[this.currentForm].next = this.formSteps[this.currentForm].next.apply(this);
        }
    }

    checkICoach(): any {
        if (this.steps[FORMSTEPS.ACCESSES]) {
            this.formSteps[FORMSTEPS.COACH].enabled = this.steps[FORMSTEPS.ACCESSES].roles.coach.status;
            return this.formSteps[FORMSTEPS.COACH].enabled ? FORMSTEPS.COACH : FORMSTEPS.SETTINGS;
        }
        return this.checkICoach;
    }

    invite(): void {
        let user = {};

        Object.values(this.steps).forEach((form: OrganizeUserStepBaseComponent) => user = { ...user, ...form.toApiData ? form.toApiData() : {} });

        this.companyUserProvider.configureMember(this.company.id, user).subscribe((data) => {
            this.processInviteResponse(data);

            if (this.keepSettings) {
                this.steps[FORMSTEPS.PERSONAL_INFO].data = {
                    ...this.steps[FORMSTEPS.PERSONAL_INFO].data, ...{
                        email: null,
                        firstname: null,
                        lastname: null
                    }
                };
                this.currentForm = FORMSTEPS.PERSONAL_INFO;
                this.keepSettings = false;
            } else {
                this.saved = true;
                this.router.navigate(['../../../users'], { relativeTo: this.route });
            }
        });
    }

    private processInviteResponse(data: any): void {

        if (data?.noPlaceLeft?.length > 0) {
            this.toastService.onWarning(this.translateService.instant('toast.limit-users-reached'));
            return;
        }

        if (data?.undefinedError?.length > 0) {
            this.toastService.onError(this.translateService.instant('toast.invitations-user-error'));
            return;
        }

        this.toastService.onSuccess(this.translateService.instant('toast.user-added'));
    }

    canDeactivate(): Observable<boolean> | boolean {
        let user = {};
        Object.values(this.steps).forEach((form: OrganizeUserStepBaseComponent) => user = { ...user, ...form.toApiData ? form.toApiData() : {} });

        if (!this.saved && (this.currentForm !== FORMSTEPS.PERSONAL_INFO || Object.values(user).filter(value => value).length > 0)) {
            const result = window.confirm(this.translateService.getTranslate('integrations.rules.leave-unsaved'));
            return of(result);
        }

        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    leavePage($event: any): void {
        let user = {};
        Object.values(this.steps).forEach((form: OrganizeUserStepBaseComponent) => user = { ...user, ...form.toApiData ? form.toApiData() : {} });

        if (!this.saved && (this.currentForm !== FORMSTEPS.PERSONAL_INFO || Object.values(user).filter(value => value).length > 0)) {
            $event.returnValue = 'Your data will be lost!';
        }
    }
}
