import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared.module';

// Components
import { CommonBannerComponent } from './components/banner/banner.component';
import { CommonFeedCommentComponent } from './components/feed-comment/feed-comment.component';
import { CommonFeedReactionComponent } from './components/feed-reaction/feed-reaction.component';
import { CommonFeedSubmitComponent } from './components/feed-submit/feed-submit.component';
import { CommonFeedThreadComponent } from './components/feed-thread/feed-thread.component';
import { CommonFeedUserComponent } from './components/feed-user/feed-user.component';
import { CommonInputRadioComponent } from './components/input-radio/input-radio.component';
import { CommonLedComponent } from './components/led/led.component';
import { CommonListComponent } from './components/list/list.component';

// Modals
import { StatsExportModal } from './modals/stats-export/stats-export-modal.component';
import { EmailExportModal } from './modals/email-export/email-export-modal.component';

// Directives

// Pipes
import { SafePipe } from './pipes/safe.pipe';
import { MscLearningPathChangelog } from './components/learning-path-changelog-aside/learning-path-changelog-aside.component';
import { AppCommon2Module } from '@common2/common.module';

// Services

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule, // meh
        AppCommon2Module
    ],
    declarations: [
        // components
        CommonBannerComponent,
        CommonFeedCommentComponent,
        CommonFeedReactionComponent,
        CommonFeedSubmitComponent,
        CommonFeedThreadComponent,
        CommonFeedUserComponent,
        CommonInputRadioComponent,
        CommonLedComponent,
        CommonListComponent,
        // Asides
        MscLearningPathChangelog,
        // Modals
        StatsExportModal,
        EmailExportModal,
        // Pipes
        SafePipe,
    ],
    exports: [
        // components
        CommonBannerComponent,
        CommonFeedCommentComponent,
        CommonFeedReactionComponent,
        CommonFeedSubmitComponent,
        CommonFeedThreadComponent,
        CommonFeedUserComponent,
        CommonInputRadioComponent,
        CommonLedComponent,
        CommonListComponent,
        // Asides
        MscLearningPathChangelog,
        // Modals
        StatsExportModal,
        EmailExportModal,
        // Pipes
        SafePipe,
    ],
    providers: [],
})

export class AppCommonModule { }
