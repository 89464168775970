import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safe'
})

export class SafePipe implements PipeTransform {

    constructor(
        private readonly sanitizer: DomSanitizer,
    ) { }

    transform(value: any, type: string): SafeHtml | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                return this.sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}
