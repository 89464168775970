<div class="content-experience content-experience--dashboard">
    <div class="content-experience__body">
        <div class="content-experience__item">
            <div class="box box-experience">
                <div class="box-experience__title">
                    <h3>{{ "words.space-course" | translate }}</h3>
                </div>

                <div class="box-experience__content">
                    <p>{{ "words.logo" | translate }}</p>

                    <div class="content__upload">
                        <div
                            class="content__picture"
                            [ngClass]="{
                                'content__picture--empty':
                                    !loaders.display &&
                                    !experience.displayPicture,
                                'content__picture--load':
                                    loaders.display &&
                                    !experience.displayPicture
                            }"
                        >
                            <div
                                class="picture__delete bg-primary-700"
                                *ngIf="experience.displayPicture"
                                (click)="updatePicture('display')"
                            >
                                <i class="icon icon-close"></i>
                            </div>

                            <msc-media
                                [picture]="experience.displayPicture"
                                [loadEmitter]="loadDisplayEmitter"
                                (onUploaded)="updatePicture('display', $event)"
                                (onLoading)="load('display')"
                            ></msc-media>
                        </div>

                        <div class="content__action">
                            <msc-button
                                class="msc-button--primary"
                                palette="secondary"
                                icon="icon-image"
                                (click)="loadPicture('display')"
                            >
                                {{ "words.upload" | translate }}
                            </msc-button>

                            <p>{{ "words.upload-info.256" | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="box-experience__content">
                    <msc-input
                        [label]="'words.space-name' | translate"
                        name="input"
                        [(ngModel)]="data.displayName"
                        [debounce]="800"
                        (onDebounce)="update()"
                    ></msc-input>
                </div>
            </div>
        </div>

        <div class="content-experience__item">
            <div class="box box-experience box-experience--spacing">
                <div class="box-experience__title">
                    <h3>{{ "experience.announce" | translate }}</h3>
                </div>

                <div class="box-experience__content">
                    <p>{{ "words.background-picture" | translate }}</p>

                    <div class="content__upload">
                        <div
                            class="content__picture"
                            [ngClass]="{
                                'content__picture--empty':
                                    !loaders.announce &&
                                    !experience.announcePicture,
                                'content__picture--load':
                                    loaders.announce &&
                                    !experience.announcePicture
                            }"
                        >
                            <div
                                class="picture__delete bg-primary-700"
                                *ngIf="experience.announcePicture"
                                (click)="updatePicture('announce')"
                            >
                                <i class="icon icon-close"></i>
                            </div>

                            <msc-media
                                [picture]="experience.announcePicture"
                                [loadEmitter]="loadAnnounceEmitter"
                                (onUploaded)="updatePicture('announce', $event)"
                                (onLoading)="load('announce')"
                            ></msc-media>
                        </div>

                        <div class="content__action">
                            <msc-button
                                class="msc-button--primary"
                                palette="secondary"
                                icon="icon-image"
                                (click)="loadPicture('announce')"
                            >
                                {{ "words.upload" | translate }}
                            </msc-button>

                            <p>{{ "words.upload-info.256" | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="box-experience__content">
                    <msc-input
                        [label]="'words.message' | translate"
                        name="input"
                        [(ngModel)]="data.announceMessage"
                        [debounce]="800"
                        (onDebounce)="update()"
                        maxlength="100"
                    ></msc-input>
                </div>

                <div class="box-experience__content">
                    <msc-input
                        [label]="'words.url' | translate"
                        name="input"
                        [(ngModel)]="data.announceUrl"
                        [debounce]="800"
                        (onDebounce)="update()"
                    ></msc-input>
                </div>
            </div>
        </div>

        <div class="content-experience__item content-experience__item--grouped">
            <!-- <div class="col-md-6">
                <div class="box box-experience box-experience--spacing">
                    <div class="box-experience__title">
                        <h3>{{ 'experience.contact' | translate }}</h3>
                    </div>

                    <div class="box-experience__content box-experience__content--contact">
                        <msc-common-select class="text-sm"
                            [infiniteLoad]="loadMoreContactUsers()" hasSearch="true" #selectContactUser>
                            <ng-template #buttonTemplate>
                                <ng-container *ngIf="experience.contact; else emptyTemplate">
                                    <button class="flex items-center overflow-hidden">
                                        <msc-common-avatar class="tiny" [label]="experience.contact.name"
                                            [pictureURL]="experience.contact.picture ? (experience.contact.picture + '?size=64') : null">
                                        </msc-common-avatar>
                                        <span class="truncate ml-2">{{ experience.contact.name }}</span>
                                    </button>
                                </ng-container>
                                <ng-template #emptyTemplate>
                                    <span>{{'words.no-contact' | translate}}</span>
                                </ng-template>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <button class="flex items-center text-sm py-2 overflow-hidden"
                                    (click)="onSelectContactUser(datum.value); selectContactUser.onClose()">
                                    <msc-common-avatar class="tiny" [label]="datum.label"
                                        [pictureURL]="datum.value?.picture ? (datum.value?.picture + '?size=64') : null">
                                    </msc-common-avatar>
                                    <span class="truncate ml-2">{{ datum.label }}</span>
                                </button>
                            </ng-template>
                        </msc-common-select>
                    </div>
                </div>
            </div> -->

            <div class="col-md-6">
                <div class="box box-experience box-experience--spacing">
                    <div class="box-experience__title">
                        <h3>{{ "experience.resource" | translate }}</h3>
                    </div>

                    <div class="box-experience__content">
                        <div class="content__resources">
                            <div
                                class="resource__empty"
                                *ngIf="resources.length === 0"
                            >
                                <i
                                    class="icon icon-file text-color-500 text-lg"
                                ></i>
                                {{ "experience.no-resources" | translate }}
                            </div>

                            <div
                                class="resource__item"
                                *ngFor="let resource of resources"
                            >
                                <div class="item__title">
                                    <i
                                        class="icon icon-file-text text-color-500 text-lg"
                                    ></i>
                                    {{ resource.title }}
                                </div>

                                <div
                                    class="item__delete"
                                    (click)="deleteResource(resource.id)"
                                >
                                    <i class="icon icon-trash-2"></i>
                                </div>
                            </div>

                            <div
                                class="resource__loading"
                                *ngIf="resourceFile.progress"
                            >
                                <div class="loading__data">
                                    <div class="loading__title">
                                        <i
                                            class="icon icon-file text-color-500 text-lg"
                                        ></i>
                                        {{ "words.loading" | translate }}
                                    </div>

                                    <div class="loading__percentage">
                                        {{ resourceFile.progress }}%
                                    </div>
                                </div>

                                <div class="loading__progress">
                                    <div
                                        class="progress-bar"
                                        [ngStyle]="{
                                            width: resourceFile.progress + '%'
                                        }"
                                    ></div>
                                </div>
                            </div>

                            <div class="content__action">
                                <msc-button
                                    class="msc-button--primary"
                                    palette="secondary"
                                    icon="icon-download-1"
                                    (click)="modalUploadResource.onOpen()"
                                >
                                    {{ "words.upload" | translate }}
                                </msc-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-common-modal
    size="30vw"
    #modalUploadResource
    [title]="'experience.upload-resource' | translate"
>
    <ng-template>
        <msc-input
            [label]="'words.title' | translate"
            name="input"
            id="title"
            [(ngModel)]="resourceFile.title"
        ></msc-input>
        <div
            class="flex-grouped align-center"
            [ngClass]="{
                'justify-end': !resourceFile.file,
                between: resourceFile.file
            }"
        >
            <p *ngIf="resourceFile.file">{{ resourceFile.file.name }}</p>
            <msc-button
                class="msc-button--primary"
                palette="primary"
                (click)="focusResource()"
            >
                {{ "words.upload" | translate }}
            </msc-button>
        </div>
        <input
            #resourceUpload
            class="display-none"
            type="file"
            (change)="loadResource($event.target.files[0])"
        />

        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button
                class=""
                palette="primary"
                (click)="modalUploadResource.onClose()"
                >{{ "common.cancel" | translate }}</msc-button
            >
            <msc-button
                class="msc-button--primary"
                palette="primary"
                (click)="uploadResource(); modalUploadResource.onClose()"
                >{{ "common.save" | translate }}</msc-button
            >
        </div>
    </ng-template>
</msc-common-modal>
