import { Component, ChangeDetectionStrategy, Input, TemplateRef, ContentChild, TrackByFunction, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { CommonOverlayComponent } from '../overlay/overlay.component';
//
export interface CommonTreeDatum<T> {
    children?: Array<CommonTreeDatum<T>>;
    id?: number | string;
    label: string;
    value: T;
}

@Component({
    selector: 'msc-common-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonTreeComponent<T> {
    @Input() data: Array<CommonTreeDatum<T>> = []; // data items for tree
    @Input() template?: TemplateRef<{ datum: CommonTreeDatum<T> }>; // template for item via input
    @Input() expandWithOverlay = false;
    @Input() shouldOpen = false;
    @Output() treeUpdate: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren(CommonOverlayComponent) overlayList: QueryList<CommonOverlayComponent>;
    @ContentChild(TemplateRef) leafTemplate: TemplateRef<{ datum: CommonTreeDatum<T> }>; // template for leaf

    public onTrackByIndex: TrackByFunction<CommonTreeDatum<T>> = (i: number) => i;

    openTree(datum: CommonTreeDatum<T>, overlay: CommonOverlayComponent, origin) {
        if (this.expandWithOverlay && !overlay?.isOpen) {
            this.overlayList.forEach((overlay) => overlay.close());
            this.treeUpdate.emit(overlay.open(origin));
        }
    }

    closeTree(overlay: CommonOverlayComponent){
        this.treeUpdate.emit(overlay.close());
    }
}
