import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[dragDrop]'
})

export class CommonDragDropDirective {
    @Output() dropEvent: EventEmitter<File> = new EventEmitter<File>();

    /**
     * Event handler for drag leave
     */
    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent): void {
        event.preventDefault();
    }

    /**
     * Event handler for drag over
     */
    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent): void {
        event.preventDefault();
    }

    /**
     * Event handler for drop
     */
    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        event.preventDefault();
        const [file] = Array.from(event.dataTransfer.files);
        this.dropEvent.emit(file);
    }
}
