import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-organize-statistic-providers',
    templateUrl: './providers.component.html'
})
export class OrganizeStatisticProvidersComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
