<div class="col-8 col-md-8 col-xxs-12 container__main animate">
    <div class="container__desc">
        <div class="block-course">
            <div class="block-course__desc">
                <div>
                    <div class="flex between">
                        <h2 class="block-course__title">{{ instance.title }}</h2>
                        <msc-tag [text]="'words.template' | translate"></msc-tag>
                    </div>

                    <p readMore #readMoreEl="readMore" class="inner-html"
                        [innerHTML]="instance.description ? (instance.description | safe:'html') : ('words.undescribed-content' | translate)">
                    </p>

                    <a *ngIf="readMoreEl.overflow" (click)="readMoreEl.toggleExtend()">{{ 'words.read-more.' +
                        readMoreEl.extended | translate }} <span><i class="icon icon-arrow-ios-down"
                                [class.icon-arrow-ios-up]="readMoreEl.extended"
                                [class.icon-arrow-ios-down]="!readMoreEl.extended"></i></span></a>

                    <div class="block-course__start">
                        <msc-button class="msc-button--primary" palette="primary" (click)="start()"
                            [disabled]="instance.subscriptionState == 'pending' || instance.subscriptionState == 'refused'">
                            {{ 'words.start' | translate }}</msc-button>

                        <div *ngIf="instance.subscriptionState === 'pending'"
                            [tooltip]="'words.subscription-pending' | translate">
                            <i class="icon icon-clock-1 color-blue"></i>
                        </div>

                        <div *ngIf="instance.subscriptionState === 'refused'"
                            [tooltip]="'words.subscription-refused' | translate">
                            <i class="icon icon-slash"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container__list" *ngIf="instance?.events?.length > 0">
        <div class="container__item" *ngFor="let event of instance.events">
            <div class="block-date-presentation">
                <div class="block-date-presentation__header">
                    <div class="block-date-presentation__header--event">
                        <msc-tag [type]="'date'" [color]="'orange'" [day]="event.eventSessions[0].startAt | date:'dd'"
                            [month]="event.eventSessions[0].startAt | localizedDate:'MMM'"
                            *ngIf="event.eventSessions[0].startAt">
                        </msc-tag>

                        <div class="block-date-presentation__time">
                            <p class="text-primary-500">{{ event.eventSessions[0].startAt | date:'HH:mm' }}</p>
                            <p>{{ event.eventSessions[0].endAt | date:'HH:mm' }}</p>
                        </div>

                        <div class="block-date-presentation__desc">
                            <p class="block-date-presentation__title">{{ event.title || ('words.untitled' | translate)
                                }}</p>
                            <p class="block-date-presentation__localisation">
                                <i class="icon icon-pin"></i>

                                <span *ngIf="!event.eventSessions[0].address">{{ 'words.empty-adress' | translate
                                    }}</span>
                                <a [href]="event.eventSessions[0].address" target="_blank"
                                    *ngIf="event.eventSessions[0].address">{{ event.eventSessions[0].address }}</a>
                            </p>
                        </div>
                    </div>

                    <div class="block-date-presentation__add">
                        <i class="icon icon-calendar" (click)="downloadIcs(event.eventSessions[0].icsCalendar)"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="container__item" *ngIf="instance.requirement || instance.target || instance.purpose">
            <div class="block-course-data">
                <div *ngIf="instance.purpose">
                    <p class="block-course-data__title">{{'studio.create.course.purpose' | translate}}</p>
                    <p class="block-course-data__description inner-html" [innerHTML]="instance.purpose | safe:'html'">
                    </p>
                </div>
                <div *ngIf="instance.target">
                    <p class="block-course-data__title">{{'studio.create.course.audience' | translate}}</p>
                    <p class="block-course-data__description inner-html" [innerHTML]="instance.target | safe:'html'">
                    </p>
                </div>
                <div *ngIf="instance.requirement">
                    <p class="block-course-data__title">{{'studio.create.course.requirement' | translate}}</p>
                    <p class="block-course-data__description inner-html"
                        [innerHTML]="instance.requirement | safe:'html'"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<aside class="col-4 col-md-4 col-xxs-12 container__aside">
    <presentation-additional-details [content]="instance" [backgroundImage]="instance.picture ? instance.picture + '?size=1024' :
    ('https://static.myskillcamp.com/lxp/images/logo-empty-state-' + instance.id % 7 + '.jpg')">
    </presentation-additional-details>
</aside>
