import { Component, Input, OnInit } from '@angular/core';
import { CompanyExperience, KnowledgeProvider, CompanyUserProvider, LanguageProvider } from '@lighty';
import { HttpHelper } from '@helpers';
import { OrganizeUserStepBaseComponent } from '../configure.component.base';
import { StorageService, TranslateService } from '@services';
import { FORMSTEPS } from '../configure.enum';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonSelectDatum } from 'src/app/common2/components/select/select.component';
import { firstValueFrom, forkJoin } from 'rxjs';

@Component({
    selector: 'msc-organize-user-configure-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    providers: [{ provide: OrganizeUserStepBaseComponent, useExisting: OrganizeUserConfigureSettingsComponent }]
})
export class OrganizeUserConfigureSettingsComponent extends OrganizeUserStepBaseComponent implements OnInit {
    @Input() company;
    @Input() set availableExperiences(value) {
        this._availableExperiences = value;
        this.selectExperience(this._availableExperiences[0]);

        if (value) {
            this.selectData.experiences = this.getSelectData(value, 'experiences');
        }
    }

    loadingSkills: boolean;
    public skillsSelected: any[] = [];
    public skillsSelectedLabel: string = '';

    public _availableExperiences: CompanyExperience[];
    public availableSkills: any[];
    public experienceSelected: CompanyExperience;
    public query = {
        skill: '',
    };

    public data = {
        languageId: null,
        learningLanguagesIds: [],
        experience_id: null,
        skills: [],
        emailStatus: 'auto',
        custom_message: null
    };

    public languageModel = {
        interfaceLanguage: null,
        contentLanguages: []
    }

    formErrors: any = {};
    emailTemplate: string;
    emailTemplateRaw: string;
    public me;
    public contentLanguagesSelectedLabel: string;
    public selectedLanguages: any[] = [];
    public selectData: any = {
        interfaceLanguages: [],
        contentLanguages: [],
        experiences: [],
        skills: []
    }

    constructor(
        private companyUserProvider: CompanyUserProvider,
        private toastService: CommonToastService,
        private knowledgeProvider: KnowledgeProvider,
        private storageService: StorageService,
        private translateService: TranslateService,
        private languageProvider: LanguageProvider,
    ) {
        super();

        this.formId = FORMSTEPS.SETTINGS;
    }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.languageModel.interfaceLanguage = this.storageService.get('me')?.language;
        this.data.languageId = this.storageService.get('me')?.language.id;
        this.getEmailTemplate();
        this.getLanguages();
    }

    getEmailTemplate() {
        this.companyUserProvider.getInvitationEmailTemplate(this.company.id, { language_id: this.me.language.id }).subscribe((data: any) => {
            this.emailTemplateRaw = data.paragraph;
            this.updateEmailTemplate();
        })
    }

    getLanguages() {
        const langsObservable = forkJoin({
            interface: this.languageProvider.get('interface'),
            content: this.languageProvider.get('content')
        });

        langsObservable.subscribe(resp => {
            this.selectData.interfaceLanguages = this.getSelectData(resp.interface, 'interfaceLanguages');
            this.selectData.contentLanguages = this.getSelectData(resp.content);
        })
    }

    getSelectData(data, key?: string) {
        switch (key) {
            case 'interfaceLanguages':
                return data.map(d => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: d.id === this.data.languageId
                }));
            case 'experiences':
                return data.map((d, index) => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: index === 0
                }));
            case 'skills':
                return data.map((d) => ({
                    id: d.id,
                    label: this.getSkillName(d),
                    value: d,
                    isSelected: false
                }));
            default:
                return data.map(d => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: false
                }));
        }
    }

    getSkillName(skill){
        return skill.translations[this.me.language?.code] || this.getFallbackTranslation(skill.translations) || skill.name
    }

    getFallbackTranslation(obj) {
        return Object.values(obj).find(el => el !== "");
    }

    onSelectOption(option: CommonSelectDatum<any>, key: string) {
        this.selectData[key] = this.selectData[key].map((d: CommonSelectDatum<any>) => {
            d.isSelected = d.id === option.id;
            return d;
        });

        switch (key) {
            case 'interfaceLanguages':
                this.onSelect(option.value);
                break;
            case 'experiences':
                this.selectExperience(option.value);
                break;
        }
    }

    onSelectMultiOption(option: CommonSelectDatum<any>, key: string) {
        this.selectData[key] = this.selectData[key].map((d) => {
            if (d.id === option.id) {
                d.isSelected = !d.isSelected;
            }
            return d;
        });

        switch (key) {
            case 'contentLanguages':
                this.selectContentLanguage(option);
                break;
            case 'skills':
                this.updateSkill(option.value);
        }
    }

    selectContentLanguage(option) {
        if (option.isSelected) {
            this.selectedLanguages.push(option.value);
        } else {
            this.selectedLanguages = this.selectedLanguages.filter(lang => lang.id !== option.id);
        }

        this.contentLanguagesSelectedLabel = this.selectedLanguages.map(lang => lang.name).join(', ');
        this.onSelect(this.selectedLanguages);
    }

    updateEmailTemplate() {
        this.emailTemplate = this.emailTemplateRaw?.replace(':company_name', this.company.name).replace(':experience_name', this.experienceSelected.name);
    }

    selectExperience(experience: CompanyExperience): void {
        this.experienceSelected = experience;
    }

    changed(key: string, value: string): void {
        this.data[key] = value;
    }

    loadMoreSkills(): CallableFunction {
        return (async (query, pagination) => {
            const params = {
                ...(query && { q: query }),
                page: pagination.currentPage + 1
            };

            const result = await firstValueFrom(this.knowledgeProvider.get(this.company.slug, params));

            return {
                data: this.getSelectData(result.skills, 'skills'),
                pagination: result.pagination
            };
        })
    }

    updateSkill(skill): void {
        if (this.isSkillSelected(skill)) {
            this.removeSkill(skill);
        } else {
            this.addSkill(skill);
        }
        this.skillsSelectedLabel = this.getSelectedSkills(3);
    }

    private addSkill(skill): void {
        this.skillsSelected.push(skill);
    }

    public removeSkill(skill): void {
        const index = this.skillsSelected.findIndex((data) => {
            return data.id === skill.id;
        });

        if (index !== -1) {
            this.skillsSelected.splice(index, 1);
            this.selectData.skills = this.selectData.skills.map(d => {
                if (d.id === skill.id) { d.isSelected = false }
                return d;
            });

        }
    }

    isSkillSelected(skill): boolean {
        return this.skillsSelected.findIndex((data) => data.id === skill.id) !== -1;
    }

    getSelectedSkills(limit: number): string {
        return this.skillsSelected.slice(0, limit).map((skill) => {
            return this.getSkillName(skill);
        }).join(', ');
    }

    onSelect(data: any) {
        if (Array.isArray(data)) {
            this.data.learningLanguagesIds = data.map(d => d.id);
            this.languageModel.contentLanguages = data;
        } else {
            this.data.languageId = data.id;
            this.languageModel.interfaceLanguage = data;
        }
    }

    public isValid(): boolean {
        this.formErrors = {};

        if (!this.data.custom_message && this.data.emailStatus === 'custom') {
            this.formErrors.customMessage = true;
        }

        this.data.experience_id = this.experienceSelected.id;
        this.data.skills = this.skillsSelected.map(skill => skill.id);

        if (!this.data.languageId) {
            this.formErrors.language = true;
            this.toastService.onError(this.translateService.instant('toast.invite.language.required'));
        }

        return !Object.values(this.formErrors).some(error => error);
    }

    public toApiData() {
        return HttpHelper.cleanParams({
            languageId: this.data.languageId,
            learningLanguagesIds: this.data.learningLanguagesIds,
            skills: this.data.skills,
            experience_id: this.data.experience_id,
            emailStatus: this.data.emailStatus,
            custom_message: this.data.custom_message
        })
    }

    public toReadableData() {
        return {
            label: 'words.settings',
            data: [
                {
                    label: 'words.interface-language',
                    data: [this.languageModel.interfaceLanguage.name]
                },
                this.languageModel.contentLanguages.length > 0 ? {
                    label: 'words.learning-languages',
                    data: this.languageModel.contentLanguages.length ? this.languageModel.contentLanguages.map(lang => lang.name) : null
                } : null,
                {
                    label: 'words.experience',
                    data: [this.experienceSelected.name]
                },
                this.skillsSelected.length > 0 ? {
                    label: 'words.skills',
                    data: [this.skillsSelected.map(skill => skill.name).join(', ')]
                } : null,
                {
                    label: 'words.email-notification',
                    data: [
                        `words.notification.${this.data.emailStatus}`
                    ]
                },
            ]
        }
    }
}
