import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupProvider, Group, Pagination, Company } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-learn-community-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})

export class LearnCommunityListComponent {
    private company: Company;
    public groups: Group[];
    public communitiesAvailable: Group[];
    public selectedCommunity: Group;
    public pagination: Pagination;
    public paginationAvailable: Pagination;
    public currentTabIndex: number = 0;
    public displays: any;
    public loading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private groupProvider: GroupProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.groups = this.route.snapshot.data.groups.groups;
        this.pagination = this.route.snapshot.data.groups.pagination;

        this.displays = {
            modals: {
                subscribeCommunity: false
            }
        };

        this.getAvailableGroups();

        if (this.storageService.getFlash('communitiesAvailable')) {
            this.currentTabIndex = 1;
        }
    }

    private getAvailableGroups(): void {
        this.groupProvider.getAvailableLearningGroups(this.company.id).subscribe((data) => {
            this.communitiesAvailable = data.groups;
            this.paginationAvailable = data.pagination;
        });
    }

    loadMore(tab): void {
        this.loading = true;
        const params = {
            page: tab === 'communities' ? this.pagination.currentPage + 1 : this.paginationAvailable.currentPage + 1
        };

        if (tab === 'communities') {
            this.groupProvider.getLearningGroups(this.company.id, params).subscribe((data) => {
                this.groups = this.groups.concat(data.groups);
                this.pagination = data.pagination;
                this.loading = false;
            });
        } else {
            this.groupProvider.getAvailableLearningGroups(this.company.id, params).subscribe((data) => {
                this.communitiesAvailable = this.communitiesAvailable.concat(data.groups);
                this.paginationAvailable = data.pagination;
                this.loading = false;
            });
        }
    }

    getCurrentData(tab): Group[] | Pagination {
        return tab === 'communities' ? this.groups : this.communitiesAvailable;
    }

    getPagination(tab) {
        return tab === 'communities' ? this.pagination : this.paginationAvailable
    }

    openModalUnsubscribe(modal: CommonModalComponent, community: Group): void {
        this.selectedCommunity = community;
        modal.onOpen();
    }

    unsubscribeCommunity(): void {
        this.groupProvider.unsubscribe(this.selectedCommunity.id).subscribe(() => {
            const index = this.groups.findIndex((group) => {
                return group.id === this.selectedCommunity.id;
            });

            if (index !== -1) {
                this.getAvailableGroups();
                this.groups.splice(index, 1);
                this.toastService.onSuccess(this.translateService.instant('toast.unsubscribe'));
            }
        });
    }

    displayed(key: string, type: string): void {
        this.displays[key][type] = !this.displays[key][type];
    }

    navigate(community): void {
        this.router.navigate([community.id], { relativeTo: this.route });
    }

    subscribeToGroup(group) {
        this.selectedCommunity = group;
        this.displayed('modals', 'subscribeCommunity');
    }
}
