<!--mercado = marketplace-->
<div class="body grid-autofill grid items-start gap-0 h-full">
    <msc-common-nav [data]="nav"></msc-common-nav>
    <section>
        <router-outlet></router-outlet>
    </section>
</div>



