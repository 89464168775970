import { Component, ElementRef, OnInit, OnChanges, Renderer2, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { MediaProvider } from '@lighty';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-media',
    templateUrl: './media.component.html'
})
export class MediaComponent implements OnInit, OnChanges {
    @Input() new: string;
    @Input() class: string;
    @Input() picture: string;
    @Input() size: string = '256';
    @Input() enableClick: boolean = false;
    @Input() loadEmitter: EventEmitter<any> = new EventEmitter();
    @Output() onLoading: EventEmitter<any> = new EventEmitter();
    @Output() onUploaded: EventEmitter<any> = new EventEmitter();
    @ViewChild('upload') upload: ElementRef;
    public loading: boolean = false;
    public preview: string;

    constructor(
        private renderer: Renderer2, 
        private mediaProvider: MediaProvider, 
        private toastService: CommonToastService,
        private translateService: TranslateService,
        ) {}

    ngOnInit(): void {
        this.loadEmitter.subscribe(() => {
            this.loadPicture();
        });
    }

    ngOnChanges(): void {
        if (this.picture) {
            this.preview = null;
        }
    }

    load(): void {
        if (this.enableClick) {
            this.loadPicture();
        }
    }

    processPreview(file: File): void {
        this.picture = null;
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.preview = event.target.result;
        };
        reader.readAsDataURL(file);
    }

    loadPicture(): void {
        const event = new MouseEvent('click', {bubbles: true});
        this.renderer.selectRootElement(this.upload.nativeElement).dispatchEvent(event);
    }

    uploadPicture(file: File): void {
        if (file) {
            this.onLoading.emit(true);
            this.processPreview(file);
            this.loading = true;
            this.mediaProvider.uploadMedia(file).subscribe((data) => {
                this.onUploaded.emit(data);
            }, (error) => {
                this.loading = false;
                if (error.status === 400) {
                    this.toastService.onError(this.translateService.instant('toast.error.file-type.not-supported'));
                }
            }, () => {
                this.loading = false;
            });
        }
    }
}
