<div class="content-calendar">
    <div class="calendar">
        <div class="calendar__actions">
            <a class="link link-add" (click)="addEvent()" *ngIf="instance && instance.isEditable && this.instance.status !== 'done' && this.instance.state !== 'ongoing' && this.instance.type !== 'external_content'">
                <i class="icon icon-plus-1 bg-secondary-600"></i>

                <span>{{ 'words.add-event' | translate }}</span>
            </a>

            <a class="calendar__more text-secondary-500">
                <div class="dropdown-actions" [ngClass]="{'active': dropdowns.exports[0]}" [(autoClose)]="dropdowns.exports[0]">
                    <div class="dropdown-actions__selected" (click)="displayedDropdowns('exports', 0)">
                        <i class="icon icon-more-horizontal"></i>
                    </div>

                    <div class="dropdown-actions__content">
                        <ul class="dropdown-actions__list">
                            <li class="dropdown-actions__item">
                                <a (click)="exportAttendances()">
                                    <i class="icon icon-external-link"></i>
                                    {{ 'words.download.xls' | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </a>
        </div>

        <div class="calendar__list">
            <div class="calendar__item" *ngFor="let event of events; let index = index" [ngClass]="{'calendar__item--done' : checkIfPast(event) === true, 'calendar__item--in-progress': checkNextEvent(event.startAt), ' calendar__item--come-up': !checkIfPast(event) && !checkNextEvent(event.startAt)}">
                <div class="tag tag--date bg-[#A2A2D5]" [ngClass]="{'bg-[#D2D5E4]': checkIfPast(event) === true, 'bg-[#6968BE]': checkNextEvent(event.startAt)}">
                    <div class="tag__text">
                        <span class="text__day">{{ event.startAt | date: 'dd' }}</span>
                        <span class="text__month">{{ event.startAt | localizedDate:'MMM' }}</span>
                    </div>
                </div>

                <div class="calendar__progress"></div>

                <div class="calendar__sessions">
                    <div class="box box-sessions" [ngClass]="{'active': displays[index]}">
                        <div class="box-sessions__header col-gap-xs">
                            <div class="box-sessions__title col-gap-xs">
                                <div class="box-sessions__time">
                                    <p>
                                        <span>{{ event.startAt | date: 'HH:mm' }}</span>
                                        {{ event.endAt | date: 'HH:mm' }}
                                    </p>
                                </div>

                                <div class="box-sessions__title">
                                    <div class="tag tag--red" *ngIf="event.pendingAttendances">
                                        <div class="tag__text">
                                            <i class="icon icon-alert-triangle"></i>
                                        </div>
                                    </div>

                                    <p>{{ event.title || ('words.untitled' | translate) }}</p>
                                </div>
                            </div>

                            <div class="box-sessions__localisation" *ngIf="event.address">
                                <i class="icon icon-pin"></i>

                                {{ event.address }}
                            </div>

                            <div class="box-sessions__link" *ngIf="event.link && event.type === 'visio'">
                                <i class="icon icon-video relative -top-1"></i>

                                <a [href]="event.link" target="_blank" class="color--gray">
                                    {{ event.link | truncate: 30 }}
                                </a>
                            </div>

                            <div class="box-sessions__action">
                                <msc-button icon="icon-calendar-add" class="msc-button--primary" palette="secondary" type="fab" (click)="downloadIcs(event)"></msc-button>
                                <div [tooltip]="(event.isAttendanceMandatory ? 'manage.instance.event.attendance.title' : 'words.attendance-edit-disabled.tooltip') | translate">
                                    <msc-button icon="icon-checkmark-circle-2" class="msc-button--primary" palette="secondary" type="fab" (click)="attendanceAside.onOpen(event)" [disabled]="!event.isAttendanceMandatory"></msc-button>
                                </div>

                                <a class="box-sessions__more">
                                    <div class="dropdown-actions" [ngClass]="{'active': dropdowns.parameters[index]}" [(autoClose)]="dropdowns.parameters[index]">
                                        <div class="dropdown-actions__selected" (click)="displayedDropdowns('parameters', index)">
                                            <i class="icon icon-more-horizontal"></i>
                                        </div>

                                        <div class="dropdown-actions__content">
                                            <ul class="dropdown-actions__list">
                                                <li class="dropdown-actions__item">
                                                    <a (click)="instanceAgendaAside.onOpen(event, checkIfPast(event))">
                                                        <i class="icon icon-edit"></i>
                                                        {{ 'words.edit' | translate }}
                                                    </a>
                                                </li>

                                                <li class="dropdown-actions__item">
                                                    <a (click)="getAttendanceListPaper(event)">
                                                        <i class="icon icon-download-1"></i>
                                                        {{ 'words.download.attendance' | translate }}
                                                    </a>
                                                </li>

                                                <li class="dropdown-actions__item dropdown-actions__item--trash" *ngIf="!checkIfPast(event) === true">
                                                    <a (click)="modalDeleteEvent.onOpen(); openModalDeleteEvent(event)">
                                                        <i class="icon icon-trash"></i>
                                                        {{ 'words.delete' | translate }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <a class="box-sessions__toggle" (click)="displayed(index)">
                                <i class="icon icon-arrow-ios-down text-secondary-500"></i>
                            </a>
                        </div>

                        <div class="box-sessions__body">
                            <div class="box-sessions__infos">
                                <div class="box-sessions__infos-item" *ngIf="event.trainers.length > 0">
                                    <h4>{{ 'words.trainers' | translate }}</h4>

                                    <p *ngFor="let trainer of event.trainers">
                                        <msc-common-avatar *ngIf="trainer.account" class="tiny" [label]="trainer.account?.name" [pictureURL]="trainer.account?.picture ? (trainer.account?.picture + '?size=64') : null"></msc-common-avatar>
                                        {{ trainer.name }}
                                    </p>
                                </div>

                                <div class="box-sessions__infos-item" *ngIf="instance.language?.name">
                                    <h4>{{ 'words.language' | translate }}</h4>

                                    <p>
                                        {{ instance.language?.name }}
                                    </p>
                                </div>

                                <div class="box-sessions__infos-item" *ngIf="event.info">
                                    <h4>{{ 'words.additional-information' | translate }}</h4>
                                    <p [innerHTML]="event.info"></p>
                                </div>
                            </div>

                            <div class="box-sessions__infos">
                                <div class="box-sessions__infos-item" *ngIf="event.description">
                                    <h4>{{ 'words.description' | translate }}</h4>

                                    <p [innerHTML]="event.description"></p>
                                </div>

                                <div class="box-sessions__infos-item" *ngIf="event.resources.length > 0">
                                    <h4>{{ 'words.resources' | translate }}</h4>

                                    <msc-shared-resource *ngFor="let resource of event.resources"
                                                         [size]="'mini'"
                                                         [resource]="resource"
                                                         [truncate]="20">
                                    </msc-shared-resource>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <msc-empty-state *ngIf="events?.length === 0"
                             [source]="'icon-calendar'"
                             [title]="'words.agenda'"
                             [description]="'empty.event'"
                             [hasPicture]="false">
            </msc-empty-state>
        </div>
    </div>
    <ng-container *ngIf="hasLoadMore">
        <div class="load-more">
            <msc-button class="msc-button--primary" palette="primary" [loading]="(isLoadingMore | async) === 'loading'" (click)="updateDataPagination()">{{ 'words.load-more' | translate }}</msc-button>
        </div>
    </ng-container>
</div>

<msc-manage-instance-agenda-aside-attendance #attendanceAside>
</msc-manage-instance-agenda-aside-attendance>

<msc-manage-instance-agenda-aside-edit #instanceAgendaAside
                                       (onSave)="updateEvent($event)">
</msc-manage-instance-agenda-aside-edit>

<msc-common-modal size="30vw" #modalDeleteEvent [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'manage.instance.event.modal.delete.description' | translate }}</p>
        <p>{{ 'manage.instance.event.modal.delete.description-2' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDeleteEvent.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteEvent(); modalDeleteEvent.onClose()">{{'common.delete' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
