export const CommonApiContentTypeIconMapping = new Map<string, string>([
    ['article', ''],
    ['audio', ''],
    ['course', 'cube'],
    ['course_instance', 'cube'],
    ['course_template', 'cube'],
    ['ebook', ''],
    ['external_content', 'pin-2'],
    ['external_resource', ''],
    ['external_video', ''],
    ['other', ''],
    ['traject', 'trending-up'],
    ['video', ''],
]);
