import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { DataHelper } from '@helpers';

@Component({
    selector: 'msc-range-slider',
    templateUrl: './range-slider.component.html',
    styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
    @Input() config: any;
    @Output() configChange: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    private timeOut: any;
    private rangeMin: any;
    private rangeMax: any;
    private positions: any;
    private pixels: number;
    private classes: any;
    public values: any;
    public typeMove: string;
    public uniqueId: string;

    constructor() {}

    ngOnInit(): void {
        this.uniqueId = DataHelper.generateId();

        this.classes = {
            range: '.range-line-' + this.uniqueId,
            between: '.range-line__between-' + this.uniqueId,
            step: '.step-' + this.uniqueId,
            rangeMin: '.range-min-' + this.uniqueId,
            rangeMax: '.range-max-' + this.uniqueId
        };

        const timeOut = setTimeout(() => {
            if (this.config.step) {
                const rangeLine: any = document.querySelector(this.classes.range);
                this.pixels = rangeLine.offsetWidth / (this.config.step - 1);
            }

            this.values = {
                min: this.config.minValue,
                max: this.config.maxValue
            };

            this.positions = {
                min: 0,
                max: 0,
                limit: 0
            };

            this.rangeMin = document.querySelector(this.classes.rangeMin);
            this.rangeMin.addEventListener('mousedown', (event) => {
                this.positions.min = this.rangeMin.offsetLeft - event.clientX;
                this.typeMove = 'min';
            });

            this.rangeMax = document.querySelector(this.classes.rangeMax);
            this.positions.limit = this.rangeMax.offsetLeft;
            this.rangeMax.addEventListener('mousedown', (event) => {
                this.positions.max = this.rangeMax.offsetLeft - event.clientX;
                this.typeMove = 'max';
            });

            if (this.config.step) {
                this.getPositionFromStep(this.config.values.min, this.config.values.max).subscribe((positions: any) => {
                    this.rangeMin.style.left = positions.min;
                    this.rangeMax.style.left = positions.max;

                    const between: any = document.querySelector(this.classes.between);
                    between.style.marginLeft = positions.min;
                    const calc = `${positions.max} - ${positions.min}`;
                    between.style.width = `calc(${calc} + 10px)`;
                });
                this.stepListener();
            } else {
                this.defaultListener();
            }

            clearTimeout(timeOut);
        }, 100);
    }

    private defaultListener(): void {
        document.addEventListener('mousemove', (event) => {
            event.preventDefault();
            if (this.typeMove) {
                const element: any = this.typeMove === 'min' ? this.rangeMin : this.rangeMax;
                let position = event.clientX + this.positions[this.typeMove];
                if ((this.typeMove === 'min' && position + 30 < this.rangeMax.offsetLeft)
                    || (this.typeMove === 'max' && position > this.rangeMin.offsetLeft + 30)) {
                    if (position <= 0) {
                        position = 0;
                    } else if (position > this.positions.limit) {
                        position = this.positions.limit;
                    }
                    element.style.left = position + 'px';
                    this.calcBetween(this.typeMove, position, this.typeMove === 'min' ? this.rangeMax : this.rangeMin);
                }
            }
        });

        document.addEventListener('mouseup', () => {
            this.typeMove = null;
        });

        const rangeLine = document.querySelector(this.classes.range);
        rangeLine.addEventListener('click', (event: any) => {
            const position = event.layerX;
            const between = (this.rangeMax.offsetLeft - this.rangeMin.offsetLeft) / 2;

            if (event.offsetX < between) {
                this.rangeMin.style.left = position + 'px';
                this.calcBetween('min', position, this.rangeMax);
            } else {
                this.rangeMax.style.left = position + 'px';
                this.calcBetween('max', position, this.rangeMin);
            }
        });
    }

    private stepListener(): void {
        this.deferStepListener();
    }

    private calcBetween(type: string, position: number, element: any): void {
        const between: any = document.querySelector(this.classes.between);
        between.style.marginLeft = type === 'min' ? position + 'px' : element.offsetLeft + 'px';
        const calc = type === 'min' ? `${element.offsetLeft}px - ${position}px` : `${position}px - ${element.offsetLeft}px`;
        between.style.width = `calc(${calc} + 10px)`;

        this.config.values[type] = Math.round((position / this.positions.limit) * this.config.values.max) > this.config.values.max ? this.config.values.max : Math.round((position / this.positions.limit) * this.config.values.max);
        this.deferChange();
    }

    private deferChange(): void {
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
            this.configChange.emit(this.config);
            this.onChange.emit(this.config.values);
            clearTimeout(this.timeOut);
        }, 800);
    }

    translateX(index: number): string {
        return `translateX(${this.getPosition(index)}px)`;
    }

    getPosition(index: number): number {
        return index * this.pixels;
    }

    getValue(index: number): number {
        if (this.config.customValues) {
            return this.config.customValues[index];
        }
        return Math.round(index * (100 / (this.config.step - 1)));
    }

    getTranslate(index: number): string {
        if (this.config.translate) {
            return this.config.translate(this.config.displayedValues ? this.config.displayedValues[this.getValue(index)] : this.getValue(index));
        } else if (this.config.displayedValues) {
            return this.config.displayedValues[this.getValue(index)];
        }
        return this.getValue(index).toString();
    }

    isStepActive(index: number): boolean {
        const value = this.getValue(index);

        return value > this.config.values.min && value < this.config.values.max;
    }

    private deferStepListener(): void {
        const timeOut = setTimeout(() => {
            const stepCollection: any = document.querySelectorAll(this.classes.step);
            for (const step of stepCollection) {
                step.addEventListener('click', () => {
                    if (this.typeMove) {
                        if (!this.canMove(step.dataset.value)) {
                            return;
                        }
                        if (this.typeMove === 'min') {
                            this.rangeMin.style.left = step.dataset.position + 'px';
                        } else if (this.typeMove === 'max') {
                            this.rangeMax.style.left = step.dataset.position + 'px';
                        }
                        this.calcBetweenStep(this.typeMove, step.dataset.position, this.typeMove === 'min' ? this.rangeMax : this.rangeMin, step.dataset.value);
                        this.typeMove = null;
                    }
                });
            }
            clearTimeout(timeOut);
        }, 500);
    }

    private calcBetweenStep(type: string, position: number, element: any, value: string): void {
        const between: any = document.querySelector(this.classes.between);
        between.style.marginLeft = type === 'min' ? position + 'px' : element.offsetLeft + 'px';
        const calc = type === 'min' ? `${element.offsetLeft}px - ${position}px` : `${position}px - ${element.offsetLeft}px`;
        between.style.width = `calc(${calc} + 10px)`;

        this.config.values[type] = parseInt(value, 10);
        this.deferChange();
    }

    private canMove(value: number): boolean {
        if (this.typeMove === 'min' && value < this.config.values.max) {
            return true;
        } else if (this.typeMove === 'max' && value > this.config.values.min) {
            return true;
        }
        return false;
    }

    private getPositionFromStep(min: number, max: number): Observable<string>  {
        return new Observable(subscriber => {
            const timeOut = setTimeout(() => {
                const stepCollection: any = document.querySelectorAll(this.classes.step);
                const positions: any = {
                    min: '',
                    max: ''
                };

                for (const step of stepCollection) {
                    if (parseInt(step.dataset.value, 10) === min) {
                        positions.min = step.dataset.position + 'px';
                    }

                    if (parseInt(step.dataset.value, 10) === max) {
                        positions.max = step.dataset.position + 'px';
                    }
                }
                subscriber.next(positions);
                clearTimeout(timeOut);
            }, 200);
        });
    }
}
