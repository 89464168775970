import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpHelper } from '@helpers';
import { CompanyUserProvider, Pagination } from '@lighty';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-organize-users-aside-reassign',
    templateUrl: './reassign-manager.component.html'
})
export class OrganizeUsersAsideReassignComponent implements OnInit {
    @ViewChild('aside') aside;
    @Input() companyId: number;
    @Input() selectedUsers: any[];
    @Input() pagination: Pagination;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public availableUsers: any[];
    public managers: any = [];
    public selectedManager: any;
    public selectedUserIds: number[];
    public query: string;
    public data: any;
    public loaders: any;

    constructor(
        private companyUserProvider: CompanyUserProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void { 
        this.data = {
            notify: false,
            userId: null,
        };

        this.loaders = {
            ready: false,
            loadMore: false,
            save: false
        };
    }

    public onOpen() {
        this.aside.onOpen();

        this.loaders.save = false;
        this.selectedUserIds = this.selectedUsers.map(user => user.account.id);
        this.managers = this.getUniqueList(this.getManagersRemapped(this.selectedUsers));

        this.getAvailableUsers();
    }

    private getAvailableUsers() {
        const params = {
            accountIds: this.selectedUserIds.join(',')
        };

        const noManagerOption = {
            account: {
                id: 'none',
                name: this.translateService.instant('words.no-manager'),
            }
        };

        this.companyUserProvider.getUsers(this.companyId, params).subscribe((data) => {
            this.availableUsers = data.users;
            this.availableUsers.unshift(noManagerOption);
            this.pagination = data.pagination;

            this.loaders.ready = true;
        });
    }

    private getUniqueList(arr) {
        const uniq = {}
        return arr.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
    }

    private getManagersRemapped(data) {
        return data.map((datum) => ({
            id: datum.manager && datum.manager.id,
            name: datum.manager && datum.manager.name,
            picture: datum.manager && datum.manager.picture
        })).filter(datum => datum.id);
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query,
            accountIds: this.selectedUserIds.join(',')
        });

        this.companyUserProvider.getUsers(this.companyId, params).subscribe((data) => {
            this.availableUsers = data.users;
            this.pagination = data.pagination;
        });
    }

    loadMore(): void {
        this.loaders.loadMore = true;

        const params = HttpHelper.cleanParams({
            q: this.query,
            accountIds: this.selectedUserIds.join(','),
            page: this.pagination.currentPage + 1,
        });

        this.companyUserProvider.getUsers(this.companyId, params).subscribe((data) => {
            this.availableUsers.push(...data.users);
            this.pagination = data.pagination;

            this.loaders.loadMore = false;
        });
    }

    selectAvailableUser(user: any): void {
        this.data.userId = user.account.id;
        this.selectedManager = user.account;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (!this.loaders.save) {
            this.loaders.save = true;
            const params = {
                userId: this.data.userId,
                notify: this.data.notify
            };

            this.onSave.emit(params);
        }
    }
}