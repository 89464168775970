<ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'success'">
        <ng-container *ngIf="rows.length; else emptyTemplate">
            <msc-common-table [columns]="columns" [rows]="rows">
                <ng-template #thTemplate let-datum="datum">
                    <span>{{ rows.length }}</span>&nbsp;
                    <span>{{ datum.label }}</span>
                </ng-template>
                <ng-template #thTemplate let-datum="datum">
                    <msc-common-th-sort [label]="datum.label" [name]="id" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                </ng-template>

                <ng-template #tdTemplate let-datum="datum">
                    <i class="icon icon-company-building icon-table"></i>
                    <span>{{ datum.company }}</span>
                </ng-template>
                <ng-template #tdTemplate let-datum="datum">
                    <msc-common-td-status [datum]="datum.status"></msc-common-td-status>
                </ng-template>
            </msc-common-table>
        </ng-container>

        <ng-template #emptyTemplate>
            <msc-common-empty [message]="'organize.marketplace.customers.table.empty.title' | translate">
                <ng-template>
                    <p>{{ 'organize.marketplace.customers.table.empty.description' | translate }}</p>
                </ng-template>
            </msc-common-empty>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
        <!-- <msc-common-loader [message]="'common.loading' | translate">
            <ng-template></ng-template>
        </msc-common-loader> -->
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
        <!-- <msc-common-error>
            <ng-template></ng-template>
        </msc-common-error> -->
    </ng-container>
</ng-container>
