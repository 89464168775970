export class UserStats {
    id: number;
    name: string;
    picture?: string;
    duration: number;
    subscribe: boolean;
    averageProgress: number;
    averageScore: number;

    constructor(user: object) {
        this.assign(user);
    }

    assign(user: object): void {
        Object.assign(this, user);
    }
}
