import { Injectable, EventEmitter } from '@angular/core';

/**
 * Loader Service
 */
@Injectable({providedIn: 'root'})
export class LoaderService {
    /**
     * Loading
     */
    private loading: boolean = false;
    /**
     * Status emitter
     */
    public status: EventEmitter<any> = new EventEmitter();

    /**
     * Run loader
     */
    public run(): void {
        this.loading = true;
        this.status.emit(this.loading);
    }

    /**
     * Stop loader
     * @param {boolean} force - Force the loader to stop
     */
    public stop(force?: boolean): void {
        if (force) {
            this.loading = false;
        }

        if (this.loading) {
            this.loading = false;
            this.status.emit(false);
        }
    }
}
