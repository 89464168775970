import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class ManageCorrectionProvider {

    constructor(private http: HttpClient) {}

    get(id: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/corrections`, {params}).pipe(
            map((data) => data)
        );
    }

    getInstances(id: string|number, trajectId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/traject/${trajectId}/instances`, {params}).pipe(
            map((data) => data)
        );
    }

    getInstancesCorrectibles(id: string|number, templateId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/template/${templateId}/instances`, {params}).pipe(
            map((data) => data)
        );
    }

    getTrajectContentList(id: string|number, trajectId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/trajects/${trajectId}/correctibles`, {params}).pipe(
            map((data) => data)
        );
    }

    getTrajectContent(id: string|number, trajectId: number, trajectContentId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/trajects/${trajectId}/correctibles/${trajectContentId}`, {params}).pipe(
            map((data) => data)
        );
    }

    getSectionsCorrectible(companyId: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/instances/${instanceId}/corrections`, {params}).pipe(
            map((data) => data)
        );
    }

    getActivityDetail(companyId: string|number, instanceId: number, sectionContentId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/instances/${instanceId}/section-contents/${sectionContentId}/corrections`, {params}).pipe(
            map((data) => data)
        );
    }

    getActivityParent(companyId: string|number, instanceId: number, activityId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/instances/${instanceId}/section-contents/${activityId}/parent-data`, {params}).pipe(
            map((data) => data)
        );
    }

    updateCorrectible(companyId: number|string, instanceId: number, sectionContentId: number, accountId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${companyId}/manage/instances/${instanceId}/section-contents/${sectionContentId}/corrections/${accountId}`, params).pipe(
            map((data) => data)
        );
    }
}
