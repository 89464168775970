<ng-container *ngIf="isBannerVisible">
    <msc-common-banner [title]="'organize.experience.watch.mail.list.banner.title' | translate" [description]="'organize.experience.watch.mail.list.banner.description' | translate" [type]="'warning'"></msc-common-banner>
</ng-container>

<h3 class="title col-gap-xs">
    <span>{{ 'organize.experience.watch.mail.list.title' | translate }}</span>
    <div class="wrapper col-gap-xs">
        <msc-button class="msc-button--primary" (click)="modalQuota.onOpen()" palette="secondary">{{'organize.experience.watch.mail.list.btn.quota' | translate}}</msc-button>
        <ng-container *ngIf="count">
            <msc-button class="msc-button--primary" palette="primary" (click)="goToCreate()">{{'organize.experience.watch.mail.list.btn.address' | translate}}</msc-button>
        </ng-container>
    </div>
</h3>

<div class="grouping">
    <p class="description">
        <span>{{ 'organize.experience.watch.mail.list.description' | translate }}</span>&nbsp;
        <span>{{ 'url.custom.info.more' | translate }}</span>&#44;&nbsp;
        <a class="guide" [href]="lang" target="_blank">{{ 'url.custom.info.guide' | translate }}</a>&#46;
    </p>
    <p class="description margin-top-20" [innerHTML]="'organize.experience.watch.mail.list.info' | translate: {email: email, name: name, count: count}"></p>
</div>

<ng-container *ngIf="(items$ | async).length; else emptyTemplate">
    <div class="grouping margin-top-20">
        <msc-common-list
            [data]="items$ | async"
            [isLoading]="status$ | async"
            [emptyMessage]="'empty.available.data' | translate">
            <ng-template #item let-datum="datum">
                <msc-organize-experience-watch-mail-item
                    [datum]="datum"
                    (activate)="onActivate(datum)"
                    (edit)="goToDetails(datum.id)">
                </msc-organize-experience-watch-mail-item>
            </ng-template>
        </msc-common-list>
    </div>
</ng-container>

<ng-template #emptyTemplate>
    <!-- TODO -->
</ng-template>

<ng-container *ngIf="count">
    <div class="grouping margin-top-20">
        <msc-button class="msc-button--primary" palette="primary" (click)="goToCreate()">{{'organize.experience.watch.mail.list.btn.address' | translate}}</msc-button>
    </div>
</ng-container>

<msc-common-modal size="30vw" #modalQuota [title]="'organize.experience.watch.mail.list.modal.title' | translate">
    <ng-template>
        <p>{{ 'organize.experience.watch.mail.list.modal.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalQuota.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onQuota(modalQuota); modalQuota.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
