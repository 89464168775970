import { Injectable } from '@angular/core';
import { Resolve, Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    AdminCorrectionProvider,
    AdminGroupProvider,
    AdminMemberProvider,
    AdminSubscriptionProvider,
    BillingProvider,
    CampProvider,
    CompanyExperienceProvider,
    CompanyExperienceResourceProvider,
    CompanySettingsProvider,
    CompanyUserProvider,
    StatsProvider,
    AdminContentProvider,
    KnowledgeProvider,
    CompanyColorProvider,
    CompanyUrlProvider,
    IntegrationProvider, ApplicationProvider
} from '@lighty';


@Injectable()
export class ProviderAllowGuard implements CanActivate {
    constructor(
        private router: Router,
        private integrationProvider: IntegrationProvider,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const providerId = state && state.url.match(/(providers\/)(.*\n?)(?=\/collections)/)[2];
        const slug = route.parent?.parent?.parent?.parent?.parent?.parent.paramMap.get('slug');

        if (!providerId || !slug) { return true; }
        return this.integrationProvider
            .getProvider(parseInt(providerId, 10))
            .pipe(
                map((response: any) => {
                    if (response.companyProvider.catalogueFetching === 1) {
                        this.router.navigate(['organize', slug, 'integrations', 'providers', providerId, 'settings']);
                    }
                    return response.companyProvider.catalogueFetching === 0;
                })
            );
    }
}

@Injectable()
export class CampResolver implements Resolve<any> {
    constructor(private campProvider: CampProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('campId')) {
            return this.campProvider.getCamp(parseInt(route.paramMap.get('campId'), 10));
        }
    }
}

@Injectable()
export class CompanyUserResolver implements Resolve<any> {
    constructor(private companyUserProvider: CompanyUserProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.companyUserProvider.getUsers(route.parent.parent.paramMap.get('slug'));
    }
}

@Injectable()
export class CompanyExperienceResolver implements Resolve<any> {
    constructor(private companyExperienceProvider: CompanyExperienceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('experienceId')) {
            return this.companyExperienceProvider.get(route.parent.parent.paramMap.get('slug'), parseInt(route.paramMap.get('experienceId'), 10));
        } else {
            return this.companyExperienceProvider.getExperiences(route.parent.parent.paramMap.get('slug'));
        }
    }
}

@Injectable()
export class CompanyExperienceResourcesResolver implements Resolve<any> {
    constructor(private companyExperienceResourceProvider: CompanyExperienceResourceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.paramMap.get('experienceId')) {
            return this.companyExperienceResourceProvider.getResources(route.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.paramMap.get('experienceId'), 10));
        }
    }
}

@Injectable()
export class CompanySettingsResolver implements Resolve<any> {
    constructor(private companySettingsProvider: CompanySettingsProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.companySettingsProvider.get(route.parent.paramMap.get('slug') || route.parent.parent.parent.paramMap.get('slug'));
    }
}

@Injectable()
export class CompanyColorResolver implements Resolve<any> {
    constructor(private companyColorProvider: CompanyColorProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.companyColorProvider.getThemes(route.parent.parent.parent.paramMap.get('slug'));
    }
}

@Injectable()
export class CompanyUrlResolver implements Resolve<any> {
    constructor(private companyUrlProvider: CompanyUrlProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            excluded_experience_id: route.parent.paramMap.get('experienceId')
        };

        return this.companyUrlProvider.get(route.parent.parent.parent.paramMap.get('slug'), params);
    }
}

@Injectable()
export class KnowledgeResolver implements Resolve<any> {
    constructor(private knowledgeProvider: KnowledgeProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.knowledgeProvider.get(route.parent.parent.data.company.id);
    }
}

@Injectable()
export class KnowledgeSummaryResolver implements Resolve<any> {
    constructor(private knowledgeProvider: KnowledgeProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.knowledgeProvider.getSkill(route.paramMap.get('skillId'));
    }
}

@Injectable()
export class KnowledgeContentsResolver implements Resolve<any> {
    constructor(private knowledgeProvider: KnowledgeProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.knowledgeProvider.getContents(route.parent.paramMap.get('skillId'));
    }
}

@Injectable()
export class BillingProductResolver implements Resolve<any> {
    constructor(private billingProvider: BillingProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            billable_id: route.parent.parent.paramMap.get('slug'),
            billable_type: 'company'
        };
        return this.billingProvider.getProducts(params);
    }
}

@Injectable()
export class BillingInvoiceResolver implements Resolve<any> {
    constructor(private billingProvider: BillingProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            billable_id: route.parent.parent.paramMap.get('slug'),
            billable_type: 'company'
        };
        return this.billingProvider.getInvoices(params);
    }
}

@Injectable()
export class TemplateResolver implements Resolve<any> {
    constructor(private contentProvider: AdminContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            camp_id: route.parent.parent.paramMap.get('campId'),
            include: 'course_camps'
        };
        return this.contentProvider.getTemplate(route.paramMap.get('slug'), params);
    }
}

@Injectable()
export class ContentResolver implements Resolve<any> {
    constructor(private contentProvider: AdminContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            instances_per_page: 3
        };
        return this.contentProvider.getContents(route.parent.parent.parent.paramMap.get('slug'), parseInt(route.parent.parent.paramMap.get('campId'), 10), params);
    }
}

@Injectable()
export class CorrectionResolver implements Resolve<any> {
    constructor(private correctionProvider: AdminCorrectionProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            camp_id: route.parent.paramMap.get('campId'),
            company_id: route.parent.parent.paramMap.get('slug')
        };

        return this.correctionProvider.get(params);
    }
}

@Injectable()
export class SubscriptionResolver implements Resolve<any> {
    constructor(private subscriptionProvider: AdminSubscriptionProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const params = {
            camp_id: route.parent.paramMap.get('campId'),
            status: 'pending'
        };
        return this.subscriptionProvider.getSubscriptions(params);
    }
}

@Injectable()
export class MemberResolver implements Resolve<any> {
    constructor(private memberProvider: AdminMemberProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.memberProvider.getMembers(parseInt(route.parent.paramMap.get('campId'), 10));
    }
}

@Injectable()
export class GroupResolver implements Resolve<any> {
    constructor(private groupProvider: AdminGroupProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.groupProvider.getGroups(route.parent.parent.paramMap.get('slug'), {
            type: 'community',
            camp_id: route.parent.paramMap.get('campId'),
        });
    }
}

@Injectable()
export class DetailStatsResolver implements Resolve<any> {
    constructor(private statsProvider: StatsProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.statsProvider.getDetailStats(route.parent.parent.parent.data.company.id, route.parent.parent.data.camp.id, parseInt(route.paramMap.get('contextId'), 10), { context: route.paramMap.get('context'), subscribed: (!!sessionStorage.getItem('statsDetailsOnlyRegistered')) ? 1 : 0 });
    }
}

@Injectable()
export class UserContentsStatsResolver implements Resolve<any> {
    constructor(private statsProvider: StatsProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.paramMap.get('userId')) {
            return this.statsProvider.getUserContentsStats(
                route.parent.paramMap.get('context'),
                parseInt(route.parent.paramMap.get('contextId'), 10),
                parseInt(route.paramMap.get('userId'), 10)
            );
        } else {
            const params = {
                traject_id: route.parent.parent.paramMap.get('contextId')
            };

            return this.statsProvider.getUserContentsStats(
                'course',
                parseInt(route.paramMap.get('templateId'), 10),
                parseInt(route.parent.paramMap.get('userId'), 10),
                params
            );
        }
    }
}

/* Integration resolvers */
@Injectable()
export class IntegrationProviderResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.integrationProvider.getProvider(route.params.providerId);
    }
}

@Injectable()
export class IntegrationProvidersResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.data.company.id;
        return this.integrationProvider.getConnectedProviders(companyId);
    }
}

@Injectable()
export class IntegrationAvailableProvidersResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.integrationProvider.getAvailableProviders();
    }
}

@Injectable()
export class IntegrationProviderRulesResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.parent.parent.parent.parent.data.company.id;
        const providerId = route.parent.parent.parent.params.providerId;
        const collectionId = route.params.collectionId;

        return this.integrationProvider.getCollectionRules(companyId, providerId, collectionId);
    }
}

@Injectable()
export class IntegrationProviderRuleResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.parent.parent.parent.parent.parent.parent.data.company.id;
        const providerId = route.parent.parent.parent.parent.parent.params.providerId;
        const collectionId = route.params.collectionId;
        const ruleId = route.params.ruleId;
        return this.integrationProvider.getCollectionRule(companyId, providerId, collectionId, ruleId);
    }
}

@Injectable()
export class IntegrationProviderCollectionsResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.parent.data.company.id;
        const providerId = route.params.providerId;
        return this.integrationProvider.getCollections(companyId, providerId);
    }
}

@Injectable()
export class IntegrationProviderCollectionResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.parent.parent.parent.data.company.id;
        const providerId = route.parent.parent.params.providerId;
        const collectionId = route.params.collectionId;

        return this.integrationProvider.getCollection(companyId, providerId, collectionId);
    }
}

@Injectable()
export class IntegrationProviderMappingCategoriesResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.parent.parent.parent.data.company.id;
        const providerId = route.parent.parent.params.providerId;

        return this.integrationProvider.getMappingCategories(companyId, providerId);
    }
}

@Injectable()
export class IntegrationProviderExceptionResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.parent.parent.parent.data.company.id;
        const providerId = route.parent.parent.params.providerId;
        const params = {
            perPage: 5
        };

        return this.integrationProvider.getProviderContents(companyId, providerId, params);
    }
}

@Injectable()
export class IntegrationProviderMappingSkillsResolver implements Resolve<any> {
    constructor(private integrationProvider: IntegrationProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const companyId = route.parent.parent.parent.parent.data.company.id;
        const providerId = route.parent.params.providerId;

        return this.integrationProvider.getMappingSkills(companyId, providerId);
    }
}

@Injectable()
export class ApplicationFeatureResolver implements Resolve<any> {
    constructor(private applicationProvider: ApplicationProvider) { }

    resolve(route: ActivatedRouteSnapshot): any {
        const companyId = route.parent.parent.data.company.id;
        const applicationId = route.params.applicationId;

        return this.applicationProvider.getApplication(companyId, applicationId);
    }
}
