import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService, StorageService, ErrorStreamService } from '@services';
import { SubscriptionCollection } from '@classes';
import { ExtensionLink } from '@interfaces';
import { Company, ExternalContentProvider, TimelineProvider } from '@lighty';
import { DataHelper } from '@helpers';

@Component({
    selector: 'msc-shared-aside-extension',
    templateUrl: './extension.component.html',
    styleUrls: ['./extension.component.scss']
})
export class SharedExtensionComponent {
    @ViewChild('aside') aside;
    private company: Company;
    private isEditing: boolean = false;
    private visitedPages: string[] = [];
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public page: string = 'choice';
    public extensionLink: ExtensionLink;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public displays: any;

    constructor(
        private externalContentProvider: ExternalContentProvider,
        private emitterService: EmitterService,
        private storageService: StorageService,
        private errorStreamService: ErrorStreamService,
        private timelineProvider: TimelineProvider,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.subscriptionCollection.subscribe = this.emitterService.extensionPage.subscribe(direction => {
            if (direction === 'success') {
                this.addNewResource(direction);
            } else {
                this.page = direction;
            }
            this.visitedPages.push(direction);
        });
    }

    init() {
        this.company = this.storageService.get('company');
        this.visitedPages.push(this.page);

        const extensionData = this.storageService.getFlash('extensionLinkData');
        const extensionCampId = this.storageService.getFlash('extensionLinkCampId');
        this.extensionLink = {
            id: extensionData ? extensionData.id : null,
            title: extensionData ? extensionData.title : '',
            type: extensionData ? extensionData.type : 'other',
            duration: extensionData ? extensionData.duration : '',
            durationUnit: extensionData ? extensionData.durationUnit : 'minutes',
            price: extensionData ? extensionData.price : null,
            description: extensionData ? extensionData.description : '',
            image: (extensionData && extensionData.additionalData) ? extensionData.additionalData.image : '',
            url: extensionData ? extensionData.url : '',
            companyId: this.storageService.get('company').id,
            language: extensionData ? extensionData.language : this.storageService.get('me').language,
            languageId: extensionData ? extensionData.language?.id : this.storageService.get('me').language.id,
            savein: extensionCampId ? { camps: true } : {
                content: false,
                camps: false,
                community: false,
            },
            skills: [],
            removedSkills: [],
            modifiedSkills: [],
            campList: extensionCampId ? [extensionCampId] : [],
            communityList: [],
            roles: this.storageService.get('access').roles,
            exists: !!extensionData
        };

        if (extensionData) {
            this.page = 'edit';
            this.isEditing = true;

            if (this.extensionLink.durationUnit === 'minutes') {
                this.extensionLink.duration = '' + parseInt(this.extensionLink.duration, 10) / 60;
            } else if (this.extensionLink.durationUnit === 'hours') {
                this.extensionLink.duration = '' + parseInt(this.extensionLink.duration, 10) / 60 / 60;
            } else if (this.extensionLink.durationUnit === 'days') {
                this.extensionLink.duration = '' + parseInt(this.extensionLink.duration, 10) / 60 / 60 / 24;
            }
        }

        if (extensionCampId) {
            this.page = 'form';
        }

        this.displays = {
            alert: false
        };
    }

    public onOpen() {
        this.init();
        this.aside.onOpen();

    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
        this.storageService.delete('selectedCamps');
    }

    onChangeURL(): void {
        if (this.extensionLink.url.length > 0) {
            this.errorStreamService.locked();
            this.emitterService.loadingExternalResource.emit({ loading: true });

            const params = {
                url: this.extensionLink.url
            };

            this.subscriptionCollection.subscribe = this.externalContentProvider.getPreviewUrl(params).subscribe(data => {
                if (this.storageService.get('selectedCamps') && data.exists) {
                    this.displays.alert = true;
                }
                this.bindExtensionLink(data);

                this.emitterService.loadingExternalResource.emit({ loading: false });
                this.errorStreamService.unlocked();
            }, () => {
                this.extensionLink.exists = false;
                this.emitterService.loadingExternalResource.emit({ loading: false, error: true });
                this.errorStreamService.unlocked();
            });
        } else {
            this.emitterService.loadingExternalResource.emit({ loading: false });
        }
    }

    addNewResource(direction: string): any {
        const send = DataHelper.clone(this.extensionLink);

        if (send.durationUnit === 'minutes') {
            send.duration *= 60;
        } else if (send.durationUnit === 'hours') {
            send.duration *= 60 * 60;
        } else if (send.durationUnit === 'days') {
            send.duration *= 60 * 60 * 24;
        }

        send['forceCreate'] = !this.isEditing;
        send.subscribe = this.extensionLink.savein.content || false;
        this.subscriptionCollection.subscribe = this.externalContentProvider.addExternalResource(send).subscribe(data => {
            this.extensionLink.id = data.id;

            this.postSkills(data.id);
            this.postCamps(data.id, direction);
            this.postCommunity();
        });
    }

    onArianeClick(destination: string): void {
        const validated = this.visitedPages.indexOf(destination);

        if (validated !== -1) {
            this.page = destination;
        }
    }

    isDisabled(name: string): boolean {
        return !(this.visitedPages.indexOf(name) > -1);
    }

    prevPanel(): void {
        switch (this.page) {
            case 'camps':
                this.page = 'choice';
                break;
            case 'community':
                if (this.extensionLink.savein.camps) {
                    this.page = 'camps';
                } else {
                    this.page = 'choice';
                }
                break;
            case 'form':
                if (this.extensionLink.savein.community) {
                    this.page = 'community';
                } else {
                    this.page = 'choice';
                }
                break;
            case 'infos':
                this.page = 'form';
                break;
            case 'skills':
                this.page = 'infos';
                break;
            case 'summary':
                if (this.isEditing) {
                    this.page = 'edit';
                } else {
                    this.page = 'skills';
                }
                break;
        }
    }

    private bindExtensionLink(extensionLink: any): void {
        this.extensionLink.id = extensionLink.id ? extensionLink.id : null;
        this.extensionLink.title = extensionLink.title;
        this.extensionLink.type = extensionLink.type || this.extensionLink.type;
        this.extensionLink.duration = extensionLink.exists ? (extensionLink.duration / 60).toFixed() : extensionLink.duration;
        this.extensionLink.description = extensionLink.description;
        this.extensionLink.image = extensionLink.image;
        this.extensionLink.language = extensionLink.language ? extensionLink.language : this.storageService.get('me').language;
        this.extensionLink.exists = extensionLink.exists;
        this.extensionLink.removedSkills = [];
        this.extensionLink.modifiedSkills = [];
        this.extensionLink.skills = extensionLink.skills ? extensionLink.skills : [];
    }

    private postSkills(id: number): void {
        const params = {
            companyId: this.storageService.get('company').id,
            skills: []
        };

        this.extensionLink.skills.forEach(skill => {
            params.skills.push({
                skill_id: skill.id,
                level: skill.level
            });
        });

        this.externalContentProvider.addSkillToExternalResource(id, params).subscribe();
    }

    private postCamps(id: number, direction: string): void {
        if (this.extensionLink.savein.camps && this.extensionLink.campList.length > 0) {
            const params = {
                camp_ids: this.extensionLink.campList
            };

            this.externalContentProvider.addExternalResourceToCamp(id, params).subscribe(() => {
                this.emitterService.newResourceAdded.emit();

                if (this.isEditing) {
                    this.aside.onClose();
                    this.emitterService.set('refresh.extension', true);
                } else {
                    this.page = direction;
                }
            });
        } else {
            this.emitterService.newResourceAdded.emit();

            if (this.isEditing) {
                this.aside.onClose();
                this.emitterService.set('refresh.extension', true);
            } else {
                this.page = direction;
            }
        }
    }

    private postCommunity(): void {
        this.extensionLink.communityList.forEach(community => {
            const params = {
                context: 'group',
                contextId: community
            };

            this.timelineProvider.getTimelineId(params).subscribe((data) => {
                const skills = [];
                this.extensionLink.skills.forEach(skill => {
                    skills.push('<div class="skill skill--level"><div class="skill__content flex between">' + skill.name + '</div></div>');
                });

                const lang = this.extensionLink.language.name;

                const imgBlock = `<div class="post__text__external-image" style="background-image: url('${this.extensionLink.image}?size=raw')"></div>`;

                const message =
                    `<a target="_blank" href="${this.extensionLink.url}"><div data-options="no-url">
                            ${(this.extensionLink.title ? '<h2>' + this.extensionLink.title + '</h2>' : '')}
                            ${(this.extensionLink.description ? '<p>' + this.extensionLink.description + '</p>' : '')}
                            <div class="flex wrap">
                            <div class="skill skill--level">
                            <div class="skill__content flex between">
                            <img class="flag" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_${this.extensionLink.language.code}.svg"/>${lang}
                            </div>
                            </div>
                            <div class="skill skill--level">
                            <div class="skill__content flex between">
                            <i class="icon icon-clock"></i>${this.extensionLink.duration || 0} ${this.extensionLink.durationUnit}
                            </div>
                            </div>
                            ${skills.join('')}
                            </div>${imgBlock}</div></a>`;

                const sendParams = {
                    content: {
                        message,
                        media_id: null
                    },
                    timeline_id: data.id,
                    type: 'mixed',
                    companyId: this.company.id,
                    context: 'group',
                    group_id: community
                };

                this.timelineProvider.send(sendParams).subscribe();
            });
        });
    }

    requestEdit(edit: boolean = true): void {
        if (edit) {
            this.emitterService.set('search.catalogue', this.extensionLink.url);
            this.router.navigate(['/learn', this.company.slug, 'catalogue']);
            this.aside.onClose();
        } else {
            this.displays.alert = false;
            this.extensionLink.exists = false;
        }
    }
}
