import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DownloadHelper } from '@helpers';
import { MediaProvider } from '@lighty';

@Component({
    selector: 'msc-timeline-comment',
    templateUrl: './timeline-comment.component.html'
})
export class TimelineCommentComponent implements OnInit {
    @Input() me: any;
    @Input() comment: any;
    @Input() manage: boolean = false;
    @Input() displayCamp: boolean = false;
    @Output() delete: EventEmitter<any> = new EventEmitter();
    private embed: SafeResourceUrl;
    public isDelete: boolean = false;

    constructor(private sanitizer: DomSanitizer, private mediaProvider: MediaProvider) {}

    ngOnInit(): void {
        if (this.comment.media?.status === 0) {
            this.getMedia();
        }
    }

    getEmbed(url: string): SafeResourceUrl {
        return this.embed ? this.embed : this.embed = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    downloadFile(media): void {
        if (media.status === 1) {
            let url = media.originalUrl;
            if (media.type === 'IMG') {
                url += '?size=raw';
            }
            DownloadHelper.download(url, media.title);
        }
    }

    deleteComment(): void {
        this.isDelete = true;
        this.delete.emit(true);
    }

    canDelete(id: number): boolean {
        if (this.manage) {
            return true;
        } else {
            return this.me.id === id;
        }
    }

    private getMedia(): void {
        this.mediaProvider.getStatus(this.comment.media.id).subscribe((data) => {
            if (data.status === 0) {
                const timeOut = setTimeout(() => {
                    this.getMedia();
                    clearTimeout(timeOut);
                }, 15000);
            } else {
                this.mediaProvider.getMedia(this.comment.media.id).subscribe((media) => {
                    this.comment.media = media;
                });
            }
        });
    }
}
