<!--CUSTOM V2 LOGIN AND SSO-->
<div id="bg-login" class="min-h-screen flex items-center justify-center login  animacion-trans h-auto "
    *ngIf="loginType === 'normal'">
    <!--contenedor - columnas -->
    <div class="custom-xl main-login  content-img content-img-sm  w-9-12  md-w-10-12 mx-auto my-2 flex p-5 flex md-w-100 sm-w-auto "
        *ngIf="loginType === 'normal'"
        [style.backgroundImage]="'url(' + (display?.background ? display.background + '?size=1024' : 'https://conocimiento.griky.co/hubfs/Imagenes%20de%20Blog%20%281%29.png')+')'">

        <!--btn translate-->
        <div class=" absolute  translate-y-10 sm-translate  " style="--tw-translate-x: 9.5rem;">
            <msc-common-select [data]="selectData">
                <ng-template #buttonTemplate let-data="data">
                    <button class="flex align-center gap-1">
                        <ng-container *ngIf="data?.length">
                            <img class="mr-1 w-[24px]"
                                src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                            <span>{{ data[0].label }}</span>
                        </ng-container>
                    </button>
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <button class="flex align-center py-1 gap-1" (click)="onSelectOption(datum)">
                        <img class="w-[24px] mr-1"
                            src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                        <span>{{ datum.label }}</span>
                    </button>
                </ng-template>
            </msc-common-select>
        </div>


        <!--col-1 bg-img -->
        <div class="w-1/2 lg:block md:block hidden ">

        </div>
        <!--col-2 login -->
        <div class="md:w-1/2 px-5 content-login flex flex-col justify-center animate">

            <div class="login__header m-0 max-w-full" *ngIf='!isValidating'>
                <div class="login__logo">
                    <div class="login__logo__container mx-auto h-15 w-3/5" *ngIf="!display.logo"
                        [style.backgroundImage]="'url(https://webservices.griky.co/cdn/img/Griky-Logotipo.webp)'"></div>
                    <div class="login__logo__container mx-auto h-15 w-3/5" *ngIf="display.logo"
                        [style.backgroundImage]="'url(' + display.logo + '?size=512)'"></div>
                </div>
            </div>


            <div *ngIf='!isValidating'>
                <div class="login__custom login__custom-message" *ngIf="message">
                    <div class="login__custom__content" [ngStyle]="{'max-height': getMaxHeight()}">
                        <div class="froala-view" [innerHTML]="message | safe: 'html'"></div>
                    </div>

                    <div class="login__custom__more" (click)="displayedMessage()" [ngClass]="{'active': displayMessage}"
                        *ngIf="hasDifferentHeight()">
                        <i class="icon icon-arrow-ios-down"></i>
                    </div>
                </div>
            </div>

            <div class="flex login__body" style="flex-direction: column-reverse;"
                [ngClass]="{'login__body__providers': id}" *ngIf='!isValidating'>
                <div class="block-login-custom m-0 px-8 py-5" style="min-width:0;">

                    <h2 class="block-login__title mb-6">
                        {{ 'login.connect' | translate }}
                    </h2>

                    <form class="block-login__form" (ngSubmit)="login()">
                        <div>
                            <div class="block-login__input">
                                <msc-input class="text-base w-full" name="email" id="login" autocomplete="on"
                                    [label]="'words.login-id' | translate" type="text"
                                    [(ngModel)]="credentials.email"></msc-input>
                            </div>

                            <div class="block-login__input" *ngIf="writePassword">
                                <msc-input class="w-full" name="password" id="password"
                                    [label]="'words.password' | translate" type="password"
                                    [(ngModel)]="credentials.password" [debounce]="500" (onDebounce)="trimPassword()">
                                </msc-input>

                                <a class="link" *ngIf="writePassword" (click)="passwordForgotten()">
                                    {{ 'words.password-forgotten' | translate }}
                                </a>
                            </div>

                            <div class="block-login__submit w-full">
                                <msc-button htmlType="submit"
                                    class="w-full hover:text-white transition duration-500 transform hover:scale-105 msc-button--primary"
                                    [loading]="loading" palette="primary"
                                    [disabled]="(!writePassword && !credentials.email) || (writePassword && (!credentials.email || !credentials.password))">
                                    {{ writePassword ? ('words.login' | translate) : ('words.next' | translate)
                                    }}</msc-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


            <hr class="my-4" *ngIf='!isValidating' />


            <!--sso options-->
            <div *ngIf="ssoList.length != 0" class="provider-options provider-options-custom h-auto m-0">
                <div class="contenedor-sso" *ngIf='!isValidating'>
                    <div class="provider-title my-2 text-center">
                        <span>{{('login.sso' | translate)}}</span>
                    </div>
                    <div class="provider-options-container overflow-hidden align-center ">
                        <ng-container *ngIf="!loader">
                            <a [href]="baseUrl + '/api/loginprovider/' + provider.uuid + '/' + id"
                                class="provider-item h-auto " *ngFor="let provider of ssoList">
                                <img *ngIf="provider.showIcon"
                                    [src]="baseUrl + '/logo/' + provider.defaultIcon + '.svg'" width="30" height="30"
                                    alt="Icon">
                                <span style="font-size: 1rem;">{{provider.buttonInfo}}</span>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="loader">
                            <msc-common-spinner></msc-common-spinner>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!--validating sso-->
            <div class="login__body validate" style="min-height: 435px; height: 435px;"
                [ngClass]="{'login__body__providers': id}" *ngIf='isValidating'>
                <h2 class="block-login__title">
                    {{ 'login.validating' | translate }}
                </h2>
                <msc-common-spinner></msc-common-spinner>
            </div>
        </div>
    </div>

</div>




<!--CUSTOM V2 COMPANY-->

<div class="login login-company animate" *ngIf="loginType === 'company'">
    <div class="flex flex-col gap-8 align-center text-center my-5">
        <h1>{{ 'words.choose-space' | translate }}</h1>


        <msc-button (click)="logout()" class="absolute right-8 msc-button--primary" type="fab" palette="primary"
            icon="icon-power-1">
        </msc-button>

        <div *ngIf="storedCompanies.length > 4">
            <msc-common-search trigger="auto" [placeholder]="'common.search' | translate"
                (searchEvent)="search($event.value)"></msc-common-search>
        </div>

        <msc-empty-state *ngIf="companies.length === 0" [title]="'empty.company'"
            [description]="'empty.company.description'"></msc-empty-state>

        <div *ngIf="companies.length > 0" class="relative flex justify-center w-full xs:w-full xs:mx-8 px-12">
            <swiper class="swiper-main" #swiper [config]="slideConfig">
                <ng-container *ngFor="let company of companies; let index=index">
                    <ng-template swiperSlide>

                        <div class=" card-slider card-company duration-500  hover:shadow-md bg-white  h-64 p-4 border-2 rounded flex flex-col gap-4 hover:border-primary-500 hover:cursor-pointer overflow-hidden justify-evenly"
                            [ngClass]="{'border-primary-500': company.slug === companySelected.slug}"
                            (click)="selectCompany(company)">
                            <div class="content__logo">
                                <div class="content__logo__container"
                                    *ngIf="company.experience && company.experience.displayPicture"
                                    [style.backgroundImage]="'url(' + company.experience.displayPicture + '?size=256)'">
                                </div>
                                <div class="content__logo__container" *ngIf="!company.experience"
                                    [style.backgroundImage]="'url(' + company.picture + '?size=256)'"></div>
                            </div>

                            <div class="flex flex-col gap-2 text-center items-center">
                                <!--titulos card-->
                                <h3 class="font-normal text-center overflow-hidden text-ellipsis font-normal"
                                    style="width:200px;">
                                    <ng-container *ngIf="company.experience; else noExperienceTemplate">
                                        {{ (company.experience.displayName !== company.name &&
                                        company.experience.displayName) ? company.experience.displayName :
                                        company.name
                                        }}
                                    </ng-container>
                                    <ng-template #noExperienceTemplate>
                                        {{ company.name }}
                                    </ng-template>
                                </h3>
                                <p>
                                    <span
                                        *ngIf="company.experience && company.experience.displayName !== company.name && company.experience.displayName">{{
                                        'words.powered-by' | translate }}</span> {{ (company.experience &&
                                    company.experience.displayName
                                    !== company.name && company.experience.displayName) ? company.name : '' }}
                                </p>
                            </div>

                            <msc-common2-checkbox class="absolute top-3 right-3"
                                [value]="company.slug === companySelected.slug"></msc-common2-checkbox>
                        </div>

                    </ng-template>
                </ng-container>
            </swiper>
            <div class="swiper-controls px-2">
                <msc-button class="msc-button--large prev-btn rounded-full shadow-md active:translate-y-0.5" type="fab"
                    icon="icon-arrow-left-1" palette="secondary"></msc-button>
                <msc-button class="msc-button--large next-btn rounded-full shadow-md active:translate-y-0.5" type="fab"
                    icon="icon-arrow-right-1" palette="secondary"></msc-button>
            </div>
        </div>
    </div>

    <!--cards-2 usser-->

    <div class="job text-center" *ngIf="companySelected && companies.length > 0">
        <h1 *ngIf="companySelected.roles.length > 0">{{ 'words.choose-role' | translate
            }}</h1>

        <msc-empty-state *ngIf="companySelected.roles.length === 0" [title]="'empty.roles'"
            [description]="'empty.roles.description'" [cypressHook]="'roles'"></msc-empty-state>

        <div class="w-full job__list" *ngIf="companySelected.roles.length > 0">
            <section class="mx-auto px-4 sm:px-6 lg:px-4 py-8">
                <div class=" flex md:flex-row justify-center items-center gap-4 flex-wrap">

                    <div class="job__item m-0 bg-white shadow-md rounded-md duration-500 hover:scale-105 hover:shadow-xl"
                        *ngIf="isRole('company_learner')" (click)="redirect('learn')">
                        <div class="cards-job">
                            <div class="cards-job__icon">
                                <i class="icon icon-student"></i>
                            </div>

                            <div class="cards-job__content">
                                <h3 class="cards-job__title font-normal">{{ 'words.learn' | translate }}</h3>

                                <p class="cards-job__desc leading-3">
                                    {{ 'words.job-learn.description' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="job__item m-0 bg-white shadow-md rounded-md duration-500 hover:scale-105 hover:shadow-xl"
                        *ngIf="isRole('company_author')" (click)="redirect('create')">
                        <div class="cards-job">
                            <div class="cards-job__icon">
                                <i class="icon icon-color-palette"></i>
                            </div>

                            <div class="cards-job__content">
                                <h3 class="cards-job__title font-normal">{{ 'words.create' | translate }}</h3>

                                <p class="cards-job__desc leading-3">
                                    {{ 'words.job-create.description' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="job__item m-0 bg-white shadow-md rounded-md duration-500 hover:scale-105 hover:shadow-xl"
                        *ngIf="isRole('company_manager')" (click)="redirect('manage')">
                        <div class="cards-job">
                            <div class="cards-job__icon">
                                <i class="icon icon-mentor"></i>
                            </div>

                            <div class="cards-job__content">
                                <h3 class="cards-job__title font-normal">{{ 'words.coach' | translate }}</h3>

                                <p class="cards-job__desc leading-3">
                                    {{ 'words.job-manage.description' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="job__item m-0 bg-white shadow-md rounded-md duration-500 hover:scale-105 hover:shadow-xl"
                        *ngIf="isRole('company_administrator')" (click)="redirect('organize')">
                        <div class="cards-job">
                            <div class="cards-job__icon">
                                <i class="icon icon-calendar"></i>
                            </div>

                            <div class="cards-job__content">
                                <h3 class="cards-job__title font-normal">{{ 'words.organise' | translate }}</h3>

                                <p class="cards-job__desc leading-3">
                                    {{ 'words.job-organize.description' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
