import { Component } from '@angular/core';

@Component({
    selector: 'msc-learn-catalogue',
    templateUrl: './catalogue.component.html',
    styleUrls: ['./catalogue.component.scss']
})

export class LearnCatalogueComponent {

}
