<section class="head">
    <h3>Tree</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Tree lvl 1</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-tree [data]="tree1"></msc-common-tree>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Tree lvl 2</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-tree [data]="tree2"></msc-common-tree>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Tree lvl 3</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-tree [data]="tree3"></msc-common-tree>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Tree lvl 3 (overlay)</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-tree [data]="tree3" [expandWithOverlay]="true"></msc-common-tree>
            </div>
        </div>
    </div>
    <div class="segment">
        <h4>Tree lvl 4</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-list2 [selectionActive]="true" [(data)]="list"></msc-common-list2>
            </div>
        </div>
    </div>
</section>
