import { Injectable} from '@angular/core';

/**
 * Cache Service
 */
@Injectable({providedIn: 'root'})
export class CacheService {
    /**
     * Cache name
     */
    private readonly cacheName: string = 'msc_cache';
    /**
     * Expire time
     */
    private readonly expire: number = 10;

    /**
     * Init
     * @constructor
     */
    constructor() {}

    /**
     * Set data in cache storage
     * @param {string} key - Key where to store the data
     * @param {any} data - Any data you need
     * @param {number} expire - Expiration time
     * @param {boolean} persistence - Persists the data
     */
    set(key: string, data: any, expire?: number, persistence?: boolean): void {
        const cacheData = {
            key,
            data,
            expire: this.setExpire(expire || this.expire),
            persistence: !!persistence
        };

        if (!sessionStorage.getItem(this.cacheName)) {
            const cacheStorage = [cacheData];
            sessionStorage.setItem(this.cacheName, JSON.stringify(cacheStorage));
        } else {
            const cacheStorage = JSON.parse(sessionStorage.getItem(this.cacheName));
            let find = false;
            for (const cache of cacheStorage) {
                if (cache.key === cacheData.key) {
                    find = true;
                    cache.data = cacheData.data;
                    cache.expire = cacheData.expire;
                    cache.persistence = cacheData.persistence;
                }
            }
            if (!find) {
                cacheStorage.push(cacheData);
            }
            sessionStorage.removeItem(this.cacheName);
            sessionStorage.setItem(this.cacheName, JSON.stringify(cacheStorage));
        }
    }

    /**
     * Get all data from cache
     * @return {any} Stored data
     */
    getAll(): any {
        if (sessionStorage.getItem(this.cacheName)) {
            this.clean();

            return JSON.parse(sessionStorage.getItem(this.cacheName));
        }
        return null;
    }

    /**
     * Get data from cache
     * @param {string} key - Key where data is stored
     * @return {any} Stored data
     */
    get(key: string): any {
        if (sessionStorage.getItem(this.cacheName)) {
            const cache = JSON.parse(sessionStorage.getItem(this.cacheName)).find((data) => data.key === key);
            if (cache && (!this.isExpired(cache.expire) || cache.persistence)) {
                return cache.data;
            } else {
                this.clean(key);
            }
        }
        return null;
    }

    /**
     * Clean data from cache storage
     * @param {string} key - Key where data is stored
     */
    remove(key: string): void {
        this.clean(key);
    }

    /**
     * Checks if expired or not
     * @param {number} expire - Expiration time
     * @return {boolean}
     */
    private isExpired(expire: number): boolean {
        const date = new Date(expire);
        const now = new Date();

        return date < now;
    }

    /**
     * Set expiration time
     * @param {number} expire - Expiration time
     * @return {number} Expiration time
     */
    private setExpire(expire: number): number {
        const date = new Date();
        date.setMinutes(date.getMinutes() + expire);

        return date.valueOf();
    }

    /**
     * Clean data if is expired
     * @param {string} key - Key where data is stored
     */
    private clean(key?: string): void {
        if (sessionStorage.getItem(this.cacheName)) {
            const cacheStorage = JSON.parse(sessionStorage.getItem(this.cacheName));
            const newCacheStorage = [];

            for (const cache of cacheStorage) {
                if (!key && (!this.isExpired(cache.expire) || cache.persistence)) {
                    newCacheStorage.push(cache);
                }
                if (key && key !== cache.key) {
                    newCacheStorage.push(cache);
                }
            }

            sessionStorage.removeItem(this.cacheName);
            if (newCacheStorage.length > 0) {
                sessionStorage.setItem(this.cacheName, JSON.stringify(newCacheStorage));
            }
        }
    }
}
