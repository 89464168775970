import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, Pagination, KnowledgeProvider, KnowledgeSkill } from '@lighty';
import { StorageService, LoaderService, TranslateService } from '@services';
import { HttpHelper, DataHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-organize-knowledge-watch-content-list',
    templateUrl: './list.component.html'
})

export class OrganizeKnowledgeWatchContentListComponent {
    @ViewChild('modalUnlinkSkill') modalUnlinkSkill: CommonModalComponent;
    @Output() changeMode: EventEmitter<boolean> = new EventEmitter();
    private me: any;
    private company: Company;
    public skill: KnowledgeSkill;
    public contents: any;
    public pagination: Pagination;
    public loading: boolean = false;
    public query: string;
    public displayAside: boolean = false;
    public dataFilters: any;
    public filters: any;
    public typeFilters: any = {};
    public isFilterActive: boolean = false;
    public availableTypes: any[];
    public availableLanguages: any[] = [];
    public availableProviders: any;
    public availableSkills: any;
    public contentTitle: string = '';
    public contentToDelele: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private knowledgeProvider: KnowledgeProvider,
        private storageService: StorageService,
        private loaderService: LoaderService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.skill = this.route.parent.snapshot.data.skill;
        this.contents = this.route.snapshot.data.contents.contents.map(this.processContent);
        this.pagination = this.route.snapshot.data.contents.pagination;

        this.sortContentSkills();
        this.getMeta();
    }

    private sortContentSkills(): void {
        this.contents.forEach(content => {
            let skill = content.data.skills[content.data.skills.findIndex(skill => skill.id === this.skill.id)];
            content.data.skills = content.data.skills.filter(skill => skill.id !== this.skill.id);
            content.data.skills.unshift(skill);
        });
    }

    getSubTypesOptions(data: any, key: string) {
        const subtypes = data[`${key}Types`];
        if (!subtypes) {
            return [];
        }

        return Object.keys(subtypes)
            .map((subtype, idx) => ({
                id: `${subtype}-${idx}`,
                label: this.translateService.instant(`words.course-type.${subtype}`),
                value: subtype,
                isSelected: false
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getTypesOptions(data: any) {
        return Object.keys(data.contentTypes)
            .map((type, index) => ({
                id: `${type}-${index}`,
                label: this.translateService.instant(`api.content-type.${type.split(/(?=[A-Z])/).join('_').toLowerCase()}`),
                value: type.split(/(?=[A-Z])/).join('_').toLowerCase(),
                isSelected: false,
                children: this.getSubTypesOptions(data, type)
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    getProvidersOptions(providers) {
        const label = this.company.experience
                    ? this.company.experience.displayName
                    ? this.company.experience.displayName
                    : this.company.experience.name
                    : this.company.name;

        return providers.map(provider => ({
            label: provider.id === 'company' ? label : provider.name,
            value: provider.id,
            isChecked: false
        }));
    }

    getLanguagesOptions(languages) {
        const languageIds = this.me.learningLanguages.map(lang => lang.id);

        return languages.map((lang, idx) => ({
            id: idx,
            label: lang.id === 'undefined' ? this.translateService.instant('words.multilingual') : lang.name,
            value: lang.id,
            isSelected: languageIds.includes(lang.id)
        }));
    }

    private getMeta(): void {
        this.knowledgeProvider.getMeta(this.company.id, this.skill.id).subscribe((meta) => {
            this.availableSkills = meta.contentSkills;
            this.availableTypes = this.getTypesOptions(meta);
            this.availableLanguages = this.getLanguagesOptions(meta.languages);
            this.availableProviders = this.getProvidersOptions(meta.providers);

            this.setFilters();
        });
    }

    private setFilters(): void {
        this.filters = [
            {
                name: 'langs',
                type: 'multiselect',
                label: this.translateService.instant('words.languages'),
                displayValue: this.availableLanguages.filter(lang => lang.isSelected).map(lang => lang.label).join(', '),
                options: this.availableLanguages
            },
            {
                name: 'providers',
                type: 'checkbox',
                label: this.translateService.instant('words.providers'),
                options: this.availableProviders
            },
            {
                name: 'skill',
                type: 'skill',
                label: this.translateService.instant('words.skill'),
                selected: { skill: null, level: [] },
                query: null,
                displayLimit: 12,
                options: this.availableSkills
            }
        ];
    }

    search(query?): void {
        this.query = query;
        this.loaderService.run();
        const params = HttpHelper.cleanParams({
            q: this.query,
            content_types: this.typeFilters.contentTypes ? this.typeFilters.contentTypes.join() : null,
            course_types: this.typeFilters.courseTypes ? this.typeFilters.courseTypes.join() : null,
            external_content_types: this.typeFilters.externalContentTypes ? this.typeFilters.externalContentTypes.join() : null,
            external_resource_types: this.typeFilters.externalResourceTypes ? this.typeFilters.externalResourceTypes.join() : null,
            languages: this.dataFilters?.langs ? this.dataFilters.langs.join(',') : null,
            providers: this.dataFilters?.providers ? this.dataFilters.providers.join(',') : null,
            'skills[]': this.dataFilters?.skill && this.dataFilters?.skill.id ? this.dataFilters?.skill.id : null,
            'skill_level[]': this.dataFilters?.skill && this.dataFilters.skill.level.length > 0 ? this.dataFilters.skill.level : null
        });

        this.knowledgeProvider.getContents(this.skill.id, params).subscribe((data) => {
            this.contents = data.contents.map(this.processContent);
            this.pagination = data.pagination;
            this.sortContentSkills();
        });
    }

    loadMore(): void {
        this.loaderService.run();
        this.loading = true;

        const params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination.currentPage + 1,
            content_types: this.typeFilters.contentTypes ? this.typeFilters.contentTypes.map((item) => DataHelper.changeCase(item)).join() : null,
            course_types: this.typeFilters.courseTypes ? this.typeFilters.courseTypes.join() : null,
            external_content_types: this.typeFilters.externalContentTypes ? this.typeFilters.externalContentTypes.join() : null,
            external_resource_types: this.typeFilters.externalResourceTypes ? this.typeFilters.externalResourceTypes.join() : null,
            languages: this.dataFilters?.langs ? this.dataFilters.langs.join(',') : null,
            providers: this.dataFilters?.providers ? this.dataFilters.providers.join(',') : null,
            'skills[]': this.dataFilters?.skill && this.dataFilters?.skill.id ? this.dataFilters?.skill.id : null,
            'skill_level[]': this.dataFilters?.skill && this.dataFilters.skill.level.length > 0 ? this.dataFilters.skill.level : null
        });

        this.knowledgeProvider.getContents(this.skill.id, params).subscribe((data) => {
            this.contents = this.contents.concat(data.contents.map(this.processContent));
            this.pagination = data.pagination;
            this.loading = false;
            this.sortContentSkills();
        });
    }

    onFilterSave(data): void {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.search(this.query);
    }

    onFilterReset(): void {
        this.availableTypes = this.availableTypes.map(type => {
            type.isSelected = false
            type.children.map(subtype => {
                subtype.isSelected = false;
                return subtype;
            });
            return type;
        });

        this.dataFilters = {};
        this.typeFilters = {};
        this.isFilterActive = false;
        this.search(this.query);
    }

    getType(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'openclassroom':
                        case 'cefora':
                        case 'udemy':
                        case 'coursera':
                            return 'filter.content-type.external_content';
                        case 'bookboon':
                            return 'words.ebook';
                        default:
                            return content.data.provider?.name;
                    }
                } else {
                    return 'filter.content-type.external_content';
                }
            case 'traject':
                return 'words.journey';
            default:
                return 'words.template';
        }
    }

    getColor(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (content.data.provider) {
                    switch (content.data.provider.key) {
                        case 'bookboon':
                            return 'orange';
                        default:
                            return 'purple';
                    }
                } else {
                    return 'purple';
                }
            case 'traject':
                return 'red';
        }
    }

    getLogo(content: any): string {
        switch (content.type) {
            case 'external_content':
                if (!content.data.provider.logo) {
                    return 'https://static.myskillcamp.com/images/integrations/' + content.data.provider.key + '-picto.svg';
                } else {
                    return content.data.provider.logo + '?size=128';
                }
            case 'external_video':
                return 'https://static.myskillcamp.com/images/integrations/youtube-picto.svg';
        }
    }

    redirect(content: any): void {
        let url;
        switch (content.type) {
            case 'external_content':
                url = `learn/${this.company.slug}/detail/external-content/${content.data.provider.key}/${content.data.id}`;
                break;
            case 'traject':
                url = `learn/${this.company.slug}/detail/journey/${content.data.id}`;
                break;
            default:
                url = `learn/${this.company.slug}/detail/template/${content.data.id}`;
        }

        this.router.navigateByUrl(url);
    }

    unlink(content: any): void {
        this.contentTitle = content.data.title;
        this.contentToDelele = content;
        this.modalUnlinkSkill.onOpen();
    }

    confirmUnlink(): void {
        const params = {
            context: this.contentToDelele.type
        };
        this.knowledgeProvider.unlink(this.company.id, this.skill.id, this.contentToDelele.data.id, params).subscribe(() => {
            const index = this.contents.findIndex((cont) => {
                return cont.data.id === this.contentToDelele.data.id;
            });

            if (index > -1) {
                this.contents.splice(index, 1);
            }

            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
        this.modalUnlinkSkill.onClose();
    }

    addContent(): void {
        this.changeMode.emit(true);
    }

    getSubTypesFilters(key: string) {
        const subTypes = this.availableTypes.find(t => t.value === key && t.isSelected);

        return subTypes ? subTypes.children.filter(t => t.isSelected).map(t => t.value) : [];
    }

    setTypeFilters(): void {
        this.typeFilters = DataHelper.merge(this.typeFilters, { contentTypes: this.availableTypes.filter(t => t.isSelected).map(t => t.value) });
        this.typeFilters = DataHelper.merge(this.typeFilters, { courseTypes: this.getSubTypesFilters('course') });
        this.typeFilters = DataHelper.merge(this.typeFilters, { externalContentTypes: this.getSubTypesFilters('external_content') });
        this.typeFilters = DataHelper.merge(this.typeFilters, { externalResourceTypes: this.getSubTypesFilters('external_resource') });
        this.isFilterActive = true;
        this.search();
    }

    getIterativeCheck(parent: any, selected: any) {
        if (parent.children && parent.children.length) {
            parent.children = parent.children.map((child: any) => {
                if (parent.id === selected.id) {
                    child.isSelected = parent.isSelected;
                }
                return child;
            });
            return parent;
        } else {
            return parent;
        }
    }

    onSelectType(option) {
        option.isSelected = !option.isSelected
        this.availableTypes = this.availableTypes.map(type => this.getIterativeCheck(type, option));
        this.setTypeFilters();
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    processContent = (content) => {
        return {
            ...content,
            remainingSkillsName: content.data.skills.length > 3 ? content.data.skills.slice(3).map(skill => skill.name || skill.names).join(', ') : ''
        }
    }
}
