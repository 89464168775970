declare var require: any;

/**
 * Appcues class
 */
export class Appcues {
    /**
     * List of guides
     */
    private appcuesGuide: any[] = [];

    /**
     * Insert each guide
     * @constructor
     */
    constructor() {
        this.appcuesGuide['learn'] = require('../../assets/appcues/learn.json');
        this.appcuesGuide['manage'] = require('../../assets/appcues/manage.json');
        this.appcuesGuide['organize'] = require('../../assets/appcues/organize.json');
    }

    /**
     * Get guide with a job
     * @param {string} job - Job
     * @return {any} - collection
     */
    get(job: string): any {
        return this.appcuesGuide[job].guide;
    }
}
