export abstract class Correction {
    public id: number;
    public sectionContentId: number;
    public sectionContentSessionId: number;
    public isCorrected: boolean;
    public medias: any[];
    public feedback: any;
    
     /* --- Reviewable implementation */

     public timelineContext: string;
     public tag: string;
     public title: string;
     public description: string;
     public ratio: number;
     public answer: string;
     public answerMedias: any[];
     public comment: string;
     public correctionMedias: any[];
 
     /* --- End Reviewable implementation */

    constructor(data: any, title: string) {
        this.id = data.activitySessionId || data.contextId;
        this.title = title;
        this.sectionContentId = data.sectionContentId;
        this.sectionContentSessionId = data.sectionContentSessionId;
        this.isCorrected = !!data.isCorrected;
        this.medias = data.media || [];
        this.feedback = data.feedback || null;
        this.correctionMedias = data.correctionMedia || [];
    }
}