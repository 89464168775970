
<div class="content-experience content-experience--catalogue">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title mb-3">
                <h3>{{ 'words.dashboard-settings' | translate }}</h3>
            </div>
            <msc-button class="msc-button--primary" icon="icon-plus" palette="secondary" (click)="openModalAdd()">
                {{ 'words.add' | translate }}
            </msc-button>

            <div class="flex flex-col gap-4 mt-10">
                <div class="flex flex-row box-experience__title">
                    <div class="w-1/3">
                        <span>{{'common.name' | translate}}</span>
                    </div>
                    <div class="w-1/3">
                        <span>{{'common.description' | translate}}</span>
                    </div>
                    <div class="w-1/12">
                        <span>{{'common.status' | translate}}</span>
                    </div>
                    <div class="w-1/12 mr-6">
                        <span>{{'common.edit' | translate}}</span>
                    </div>
                    <div class="w-1/12">
                        <span>{{'common.delete' | translate}}</span>
                    </div>
                </div>

                <div class="flex flex-col gap-4 mt-6">
                    <div class="flex flex-row w-full box-experience__title" *ngFor="let row of tableContent">
                        <div class="w-1/3">
                            <span>{{row.name}}</span>
                        </div>
                        <div class="w-1/3">
                            <span>{{row.description}}</span>
                        </div>
                        <div class="w-1/12">
                            <msc-common2-switch [value]="row.status" (changeEvent)="changeStatus(row.uuid)"></msc-common2-switch>
                        </div>
                        <div class="w-1/12 mr-6">
                            <msc-button class="msc-button--primary" icon="icon-edit-2" palette="secondary" [tooltip]="'common.edit' | translate"
                            (click)="openEdit(row)">
                                {{'common.edit' | translate}}
                            </msc-button>
                        </div>
                        <div class="w-1/12">
                            <msc-button class="msc-button--primary" icon="icon-trash-2" palette="secondary" [tooltip]="'common.delete' | translate"
                            (click)="deleteDashboard(row.uuid)">
                                {{'common.delete' | translate}}
                            </msc-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<msc-common-modal size="30vw" #modalAdd [title]="'words.dashboard-settings-' + modalTitle | translate">
    <ng-template>
        <msc-input [label]="'words.name' | translate"
        [placeholder]="'words.name' | translate"
        [(ngModel)]="dashboardItemName"
        ></msc-input>
        <msc-input [label]="'words.description' | translate"
        [placeholder]="'words.description' | translate"
        [(ngModel)]="dashboardItemDescription"
        ></msc-input>
        <msc-input [label]="'words.url' | translate"
        [placeholder]="'words.url' | translate"
        [(ngModel)]="dashboardItemUrl"
        ></msc-input>

        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalAdd.onClose()">
                {{'common.cancel' | translate}}
            </msc-button>

            <msc-button *ngIf="!isEdit" class="msc-button--primary" palette="primary" (click)="createDashboard()">
                {{'common.save' | translate}}
            </msc-button>

            <msc-button *ngIf="isEdit" class="msc-button--primary" palette="primary" (click)="updateDashboard()">
                {{'common.edit' | translate}}
            </msc-button>
        </div>
    </ng-template>
</msc-common-modal>
