<msc-common-aside #aside [title]="'group.team.add-member' | translate">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div class="flex justify-between items-center">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
                    (searchEvent)="getAvailableUsers($event.value)"></msc-common-search>

                <msc-button class="msc-button-outline" icon="icon-checkmark-square-2" (click)="toggleSelectedUsers()">
                    {{ 'words.all-participants' | translate }}
                </msc-button>
            </div>

            <div class="grid grid-cols-2 gap-4">
                <msc-sort [label]="'group.team.learner-name' | translate" (onSort)="sortAvailableUsers('name', $event)">
                </msc-sort>

                <msc-sort [label]="'group.team.associate-manager' | translate"
                    (onSort)="sortAvailableUsers('manager', $event)"></msc-sort>
                <ng-container *ngFor="let user of availableUsers">

                    <div class="flex items-center space-x-2 overflow-hidden cursor-pointer" (click)="selectUser(user)">
                        <msc-common2-checkbox [value]="isUserSelected(user)">
                        </msc-common2-checkbox>
                        <msc-common-avatar [label]="user?.name"
                            [pictureURL]="user?.picture ? (user?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <p class="truncate">{{ user.name }}</p>
                    </div>

                    <div class="flex items-center space-x-2 overflow-hidden cursor-pointer" (click)="selectUser(user)">
                        <msc-common-avatar [label]="user.team?.teamManager?.account?.name"
                            [pictureURL]="user.team?.teamManager?.account?.picture ? (user.team?.teamManager?.account?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <p class="truncate">{{ user.team?.teamManager?.account.name }}</p>
                    </div>
                </ng-container>


            </div>

            <div class="flex justify-center">
                <msc-load-more [loading]="loaders.loadMore" [pagination]="pagination" (onLoad)="loadMore()">
                </msc-load-more>
                <span *ngIf="availableUsers && availableUsers.length === 0">
                    {{ 'empty.nothing.members' | translate }}
                </span>
                <msc-common-spinner *ngIf="!loaders.ready"></msc-common-spinner>
            </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div class="flex flex-col space-y-4 grow">
            <div class="slide__summary" *ngIf="data.users.length > 0">
                <ul class="avatar-group avatar-group--add">
                    <li *ngFor="let user of data.users | slice:0:3">
                        <a [attr.data-toolip]="user.name" class="tooltip--triangle">
                            <msc-common-avatar [label]="user?.name"
                                [pictureURL]="user?.picture ? (user?.picture + '?size=128') : null">
                            </msc-common-avatar>
                        </a>
                    </li>

                    <li *ngIf="data.users.length > 3">
                        <span class="avatar-group__text text-primary-500">
                            + {{ data.users.length - 3 }}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="flex items-center justify-between">
                <msc-common2-checkbox [value]="data.notify" [label]="'words.notify-participants' | translate"
                    (changeEvent)="data.notify = !data.notify"></msc-common2-checkbox>

                <div class="flex space-x-2">
                    <msc-button (click)="aside.onClose()">
                        {{ 'words.cancel' | translate }}
                    </msc-button>
                    <msc-button class="msc-button--primary" palette="secondary" [loading]="loaders.save" (click)="save()">
                        {{ 'words.save' | translate }}
                    </msc-button>
                </div>
            </div>
        </div>
    </ng-template>
</msc-common-aside>