import { Component, ChangeDetectionStrategy } from '@angular/core';
//
import { CommonSearchOutput } from '@common2/components/search/search.component';
//
const FRUIT_LIST: ReadonlyArray<string> = ['apple', 'orange', 'grapes', 'nuts', 'strawberry', 'banana', 'lemon'];

@Component({
    selector: 'msc-showroom-exhibit-search-page',
    templateUrl: './showroom-exhibit-search-page.component.html',
    styleUrls: ['./showroom-exhibit-search-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitSearchPageComponent {
    public columns = [];
    public rows = [];
    public rowsRender = [];

    ngOnInit(): void {
        this.setContent();
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
        this.rows = this.getRows();
        this.rowsRender = [...this.rows];
    }

    /**
     * Get the column list
     * Note: Order of columns is important
     */
    getColumns() {
        return [
            {
                key: 'fruit',
                label: 'Fruit',
                width: '50%',
            },
            {
                key: 'size',
                label: 'Size',
                width: '50%',
            },
        ];
    }

    /**
     *
     */
    getRows() {
        return new Array(200).fill(null).map(() => ({
            fruit: `${FRUIT_LIST[Math.floor(Math.random() * FRUIT_LIST.length)]}-${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
            size: `${Math.floor(Math.random() * 100)}kg`
        }));
    }

    /**
     *
     */
    onSearch({ data }: CommonSearchOutput<any>): void {
        this.rowsRender = [...data];
    }
}
