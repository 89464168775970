<msc-common-aside #aside [title]="'words.filters' | translate" (saveEvent)="save()">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div class="group__radio" *ngIf="context === 'traject'">
                <div *ngFor="let filter of meta" style="margin-bottom: 2rem; border-bottom: 1px solid #e5e8ed">
                    <p style="margin-bottom: 1rem">{{ filter.name }}</p>
    
                    <div style="padding-left: 20px">
                        <div *ngFor="let value of filter.instances">
                            <label class="radio">
                                {{ value.name || ('words.untitled' | translate) }}
                                <input type="radio" (click)="getMetaFromSession(value)"
                                    [checked]="isFilteredSession(value)">
                                <span class="radio__checkmark"></span>
                            </label>
    
                            <msc-common-spinner *ngIf="loadingMetaSession && isFilteredSession(value)"></msc-common-spinner>
    
                            <div class="group__radio"
                                *ngIf="context === 'traject' && metaSession && isFilteredSession(value)"
                                style="padding: 20px">
                                <div *ngFor="let filter of metaSession | keyvalue" style="padding-bottom: 10px">
                                    <p style="margin-bottom: 1rem">{{ ('stats.content.type-' + filter.key) | translate
                                        }}</p>
    
                                    <div style="padding-left: 20px">
                                        <label class="radio" *ngFor="let value of filter.value">
                                            {{ value.name || ('words.untitled' | translate) }}
                                            <input type="radio" (click)="addToFilters(filter.key, value)"
                                                [checked]="isInFilters(value)">
                                            <span class="radio__checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="group__radio" *ngIf="context === 'course' && metaSession">
                <div *ngFor="let filter of metaSession | keyvalue" style="padding-bottom: 10px">
                    <p style="margin-bottom: 1rem">{{ ('stats.content.type-' + filter.key) | translate }}</p>
    
                    <div style="padding-left: 20px">
                        <label class="radio" *ngFor="let value of filter.value">
                            {{ value.name || ('words.untitled' | translate) }}
                            <input type="radio" (click)="addToFilters(filter.key, value)" [checked]="isInFilters(value)">
                            <span class="radio__checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
    
            <msc-common-spinner *ngIf="loadingMeta"></msc-common-spinner>
        </div>
    </ng-template>
</msc-common-aside>