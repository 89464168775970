import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { environment } from '@env/environment';

@Injectable()
export class ManageTeamProvider {
    constructor(private http: HttpClient) { }

    /**
     * @param companyId 
     * @param groupId
     */
    getGroup(companyId: string | number, groupId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}`);
    }


    sendMailToGroupUser(companyId, group_id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${group_id}/users/email`, params);
    }

    /**
     * @param companyId 
     * @param groupId 
     * @param data 
     */
    updateGroup(companyId: string | number, groupId: number, data: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}`, data);
    }

    /**
     * @param companyId 
     * @param type
     */
    getAvailableGroups(companyId: string | number, params): Observable<any> {
        const response = {
            groups: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups`, { params }).pipe(
            map((data: any) => {
                response.groups = data.groups;
                response.pagination = new Pagination(data.pagination);

                return response;
            })
        );
    }

    /**
     * @param companyId 
     * @param groupId
     * @param params
     */
    getGroupUsers(companyId: string | number, groupId: number, params?: any): Observable<any> {
        const response = {
            users: [],
            pagination: {}
        };
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users`, { params: { role: 'member', ...params } }).pipe(
            map((data: any) => {
                response.users = data.users;
                response.pagination = new Pagination(data.pagination);

                return response;
            })
        );
    }

    /**
     * 
     * @param companyId 
     * @param groupId 
     * @param params 
     */
    getGroupUsersMeta(companyId: string | number, groupId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users/meta`);
    }

    /**
     * @param companyId 
     * @param groupId
     * @param accountIds
     */
    addUserToGroup(companyId: string|number, groupId: number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users`, data);
    }

    /**
     * @param companyId 
     * @param groupId
     * @param params
     */
    getAvailableUsers(companyId: string | number, groupId: number, params?: any): Observable<any> {
        const response = {
            users: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users/available`, { params }).pipe(
            map((data: any) => {
                response.users = data.accounts;
                response.pagination = new Pagination(data.pagination);

                return response;
            })
        );
    }

    /**
     * @param companyId 
     * @param data
     */
    createConversation(companyId: string|number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${companyId}/manage/conversations`, data);
    }

    /**
     * @param companyId 
     * @param groupId 
     * @param userId 
     * @param params 
     */
    getUserContents(companyId: string | number, groupId: number, userId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users/${userId}/contents`, { params }).pipe(
            map((data: any) => ({ ...data, pagination: new Pagination(data.pagination) }))
        );
    }

    /**
     * @param companyId 
     * @param groupId
     * @param userId
     */
    getUserContentsMeta(companyId: string | number, groupId: number, userId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users/${userId}/contents/meta`);
    }

    /**
     * @param companyId 
     * @param contentId
     * @param data
     */
    subscribeUsersToContent(companyId: string | number, contentId: number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${companyId}/manage/contents/${contentId}/users`, data);
    }

    /**
     * @param companyId 
     * @param groupId
     * @param userId
     * @param contentId
     */
    validateContentRequest(companyId: string | number, groupId: number, userId: number, contentId: number): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users/${userId}/contents/${contentId}/validate`, null);
    }

    /**
     * @param companyId 
     * @param groupId
     * @param userId
     * @param contentId
     */
    rejectContentRequest(companyId: string | number, groupId: number, userId: number, contentId: number, data?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users/${userId}/contents/${contentId}/reject`, data);
    }

    /**
     * @param companyId 
     * @param groupId
     * @param data
     */
    sendEmailToUsers(companyId: string | number, groupId: number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${companyId}/manage/groups/${groupId}/users/email`, data);
    }

    getAvailableManagers(id: string | number, instanceId: number, params?: any): Observable<any> {
        const response = {
            available: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/available-learners`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.available = data.available;

                return response;
            })
        );
    }
}
