import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
    Company, AdminTemplateMemberProvider, AdminJourneyMemberProvider,
    Pagination, SubscriptionProvider, ExternalContentProvider
} from '@lighty';
import { StorageService, ErrorStreamService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-camp-aside-members',
    templateUrl: './aside-members.component.html',
    styleUrls: ['./aside-members.component.scss'],
})
export class CampAsideMembersComponent {
    @ViewChild('aside') aside;
    @Input() camp: any;
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    private company: Company;
    public externalContent: any;
    public journey: any;
    public courseCamp: any;
    public members: any = {
        students: [],
        availableMembers: []
    };
    public pagination: Pagination;
    public userPagination: Pagination;
    public loading: boolean = false;
    public data: any = {
        accounts: [],
        excludedMembers: [],
        notify: true,
        all_campus_users: false
    };
    public querys: any;
    public ready: boolean = false;
    public selectedStudent: any;
    public tab: string = 'list';
    public displays: boolean[] = [];

    constructor(
        private templateMemberProvider: AdminTemplateMemberProvider,
        private journeyMemberProvider: AdminJourneyMemberProvider,
        private toastService: CommonToastService,
        private subscriptionProvider: SubscriptionProvider,
        private storageService: StorageService,
        private externalContentProvider: ExternalContentProvider,
        private errorStreamService: ErrorStreamService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.company = this.storageService.get('company');
        this.data = {
            accounts: [],
            excludedMembers: [],
            notify: true,
            all_campus_users: false
        };
        if (this.courseCamp) {
            const params = {
                camp_id: this.camp.id
            };

            this.templateMemberProvider.getAllMembers(this.courseCamp.courseId || this.courseCamp.course.id, params).subscribe((data) => {
                this.members.students = data[0].students;
                this.pagination = data[0].pagination;
                this.userPagination = data[1].pagination;
                this.members.availableMembers = data[1].availableMembers;
                this.ready = true;
            });
        } else if (this.journey) {
            this.journeyMemberProvider.getAllMembers(this.camp.id, this.journey.id).subscribe((data) => {
                this.members.students = data[0].users;
                this.pagination = data[0].pagination;
                this.members.availableMembers = data[1].users;
                this.userPagination = data[1].pagination;
                this.ready = true;
            });
        } else if (this.externalContent) {
            const params = {
                company_id: this.company.id
            };

            this.externalContentProvider.getSubscribedMembersForResource(this.externalContent.id, params).subscribe((data) => {
                this.members.students = data.sessions;
                this.pagination = data.pagination;
                this.ready = true;
            });

            this.externalContentProvider.getAvailableMembersForSubscription(this.externalContent.id, params).subscribe((data) => {
                this.members.availableMembers = data.accounts;
                this.userPagination = data.pagination;
            });
        }

        this.displayed(0);

        this.querys = {
            general: '',
            available: ''
        };
    }

    onOpen(content, type: string = 'courseCamp'): void {
        this.resetContents();

        if (type === 'courseCamp') {
            this.courseCamp = content;
        } else if (type === 'externalResource') {
            this.externalContent = content;
        } else {
            this.journey = content;
        }

        this.init();
        this.aside.onOpen();
    }

    resetContents() {
        this.courseCamp = undefined;
        this.externalContent = undefined;
        this.journey = undefined;
    }

    search(value): void {
        this.querys.general =value
        const params = HttpHelper.cleanParams({
            q: this.querys.general
        });

        if (this.courseCamp) {
            this.templateMemberProvider.getStudents(this.courseCamp.courseId || this.courseCamp.course.id, params).subscribe((data) => {
                this.members.students = data.students;
                this.pagination = data.pagination;
            });
        } else if (this.journey) {
            this.journeyMemberProvider.getStudents(this.camp.id, this.journey.id, params).subscribe((data) => {
                this.members.students = data.users;
                this.pagination = data.pagination;
            });
        } else {
            params['company_id'] = this.company.id;
            this.externalContentProvider.getSubscribedMembersForResource(this.externalContent.id, params).subscribe((data) => {
                this.members.students = data.sessions;
                this.pagination = data.pagination;
                this.ready = true;
            });
        }
    }

    searchAvailable(value): void {
        this.querys.available = value;
        const params = HttpHelper.cleanParams({
            q: this.querys.available,
        });

        if (this.courseCamp) {
            this.templateMemberProvider.getAvailableMembers(this.courseCamp.courseId || this.courseCamp.course.id, params).subscribe((data) => {
                this.members.availableMembers = data.availableMembers;
                this.userPagination = data.pagination;
            });
        } else if (this.journey) {
            this.journeyMemberProvider.getAvailableMembers(this.camp.id, this.journey.id, params).subscribe((data) => {
                this.members.availableMembers = data.users;
                this.userPagination = data.pagination;
            });
        } else {
            params['company_id'] = this.company.id;
            this.externalContentProvider.getAvailableMembersForSubscription(this.externalContent.id, params).subscribe((data) => {
                this.members.availableMembers = data.accounts;
                this.userPagination = data.pagination;
            });
        }
    }

    inviteAll(): void {
        this.data.all_campus_users = !this.data.all_campus_users;
    }

    checkMember(account: any): void {
        const index = {
            selected: null,
            excluded: null
        };

        index.selected = this.data.accounts.indexOf(account);

        if (index.selected > -1) {
            this.data.accounts.splice(index.selected, 1);

            if (this.data.isAllSelected) {
                index.excluded = this.data.excludedMembers.indexOf(account);
                if (index.excluded > -1) {
                    this.data.excludedMembers.splice(index.excluded, 1);
                } else {
                    this.data.excludedMembers.push(account);
                }
            }
        } else {
            this.data.accounts.push(account);
        }
    }

    isChecked(account: any): boolean {
        if (this.data.all_campus_users) {
            return true;
        }
        return this.data.accounts.indexOf(account) > -1;
    }

    addMember(type: string): void {
        if (type === 'courseCamp') {
            this.processMemberCourseCamp();
        } else if (type === 'externalContent') {
            this.processMemberExternalContent();
        }
    }

    processMemberCourseCamp(): void {
        this.data.accounts.forEach((id) => {
            const index = this.members.availableMembers.findIndex((member) => {
                return member.id === id;
            });
            this.members.availableMembers.splice(index, 1);
        });

        const params = {
            course_camp_id: this.courseCamp.id,
            accounts: this.data.accounts,
            notify: this.data.notify,
            all_campus_users: this.data.all_campus_users
        };

        this.errorStreamService.locked();
        this.templateMemberProvider.subscribeStudent(params).subscribe(() => {
            this.data = {
                accounts: [],
                notify: true,
                all_campus_users: false
            };
            const parameters = {
                camp_id: this.camp.id
            };

            this.templateMemberProvider.getStudents(this.courseCamp.courseId || this.courseCamp.course.id, parameters).subscribe((data) => {
                this.members.students = data.students;
                this.pagination = data.pagination;
            });
            this.toastService.onSuccess(this.translateService.instant(params.all_campus_users ? 'toast.users-added-undefined' : 'toast.users-subscribed-content'));
            this.aside.onClose();
            this.errorStreamService.unlocked();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.invitation.limit-reached'));
            this.aside.onClose();
            this.errorStreamService.unlocked();
        });
    }

    processMemberExternalContent(): void {
        const params = {
            account_ids: this.data.accounts,
            invite_all: this.data.all_campus_users,
            notify: this.data.notify,
            company_id: this.company.id
        };

        this.externalContentProvider.subscribeMemberToResource(this.externalContent.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-subscribed-content'));
            this.onSave.emit(true);
            this.aside.onClose();
        });
    }

    removeMember(member: any): void {
        const params = {
            account_id: member.account.id,
            company_id: this.company.id
        };

        if (this.courseCamp) {
            this.subscriptionProvider.unsubscribe('courses', this.courseCamp.courseId || this.courseCamp.course.id, params, true).subscribe(() => {
                const index = this.members.students.findIndex((student) => {
                    return student.accountId === member.accountId;
                });

                this.members.students.splice(index, 1);
                this.members.availableMembers.push(member.account);
                this.toastService.onSuccess(this.translateService.instant('toast.user-deleted'));
            });
        } else if (this.journey) {
            this.subscriptionProvider.unsubscribe('trajects', this.journey.id, params, true).subscribe(() => {
                const index = this.members.students.findIndex((student) => {
                    return student.trajectUserId === member.trajectUserId;
                });

                this.members.students.splice(index, 1);
                this.members.availableMembers.push(member.account);
                this.toastService.onSuccess(this.translateService.instant('toast.user-deleted'));
            });
        } else if (this.externalContent) {
            const externalParams = {
                account_ids: [member.account.id]
            };
            this.externalContentProvider.unsubscribeMemberToResource(this.externalContent.id, externalParams).subscribe((data) => {
                const index = this.members.students.findIndex((student) => {
                    return student.trajectUserId === member.trajectUserId;
                });
                this.members.students.splice(index, 1);
                this.members.availableMembers.push(member.account);
                this.toastService.onSuccess(this.translateService.instant('toast.user-deleted'));
            });
        }
    }

    loadMore(): void {
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.querys.general,
            page: this.pagination.currentPage + 1
        });

        if (this.courseCamp) {
            this.templateMemberProvider.getStudents(this.courseCamp.courseId || this.courseCamp.course.id, params).subscribe((data) => {
                this.members.students = this.members.students.concat(data.students);
                this.pagination = data.pagination;
                this.loading = false;
            });
        } else if (this.journey) {
            this.journeyMemberProvider.getStudents(this.camp.id, this.journey.id, params).subscribe((data) => {
                this.members.students = this.members.students.concat(data.users);
                this.pagination = data.pagination;
                this.loading = false;
            });
        } else if (this.externalContent) {
            params['company_id'] = this.company.id;
            this.externalContentProvider.getSubscribedMembersForResource(this.externalContent.id, params).subscribe((data) => {
                this.members.students = this.members.students.concat(data.sessions);
                this.pagination = data.pagination;
                this.ready = true;
                this.loading = false;
            });
        }
    }

    loadMoreAvailableUsers(): void {
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.querys.available,
            page: this.userPagination.currentPage + 1
        });

        if (this.courseCamp) {
            this.templateMemberProvider.getAvailableMembers(this.courseCamp.courseId || this.courseCamp.course.id, params).subscribe((data) => {
                this.members.availableMembers = this.members.availableMembers.concat(data.availableMembers);
                this.userPagination = data.pagination;
                this.loading = false;
            });
        } else if (this.journey) {
            this.journeyMemberProvider.getAvailableMembers(this.camp.id, this.journey.id, params).subscribe((data) => {
                this.members.availableMembers = this.members.availableMembers.concat(data.users);
                this.userPagination = data.pagination;
                this.loading = false;
            });
        } else if (this.externalContent) {
            params['company_id'] = this.company.id;
            this.externalContentProvider.getAvailableMembersForSubscription(this.externalContent.id, params).subscribe((data) => {
                this.members.availableMembers = this.members.availableMembers.concat(data.accounts);
                this.userPagination = data.pagination;
                this.loading = false;
            });
        }
    }

    getPreview(student: any): void {
        if (this.journey) {
            this.selectedStudent = student;
        }
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    back(): void {
        this.selectedStudent = null;
    }

    changeTab(tab: string): void {
        this.tab = tab;
        this.selectedStudent = null;
    }

    displayed(index: number): void {
        this.displays[index] = !this.displays[index];
    }
}
