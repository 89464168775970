import { Component, OnInit, Input } from '@angular/core';
import { Company, ReportingProvider } from '@lighty';
import { StorageService } from '@services';

@Component({
    selector: 'msc-widget-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class WidgetStatisticsComponent implements OnInit {
    @Input() company: Company;
    public stats: any;
    public loading: boolean = true;

    constructor(private reportingProvider: ReportingProvider, private storageService: StorageService) {}

    ngOnInit(): void {
        const params = {
            company_id: this.company.id
        };

        this.reportingProvider.getAccountOverview(this.storageService.get('company').id, this.storageService.get('me').id).subscribe((data) => {
            this.stats = data;
            this.loading = false;
        });
    }

    getDuration(): any {
        let time: number = 0;
        let translate: string = 'overview.stats.time.minute';

        if (this.stats) {
            if (this.stats.duration > 0 && this.stats.duration < 3600) {
                time = Math.round(this.stats.duration / 60);
                translate = 'overview.stats.time.minutes';
            } else if (this.stats.duration >= 3600 ) {
                time = Math.round(this.stats.duration / 3600);
                translate = (time > 1) ? 'overview.stats.time.hours' : 'overview.stats.time.hour';
            }
        }

        return [time, translate];
    }
}
