import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { environment } from '@env/environment';

/**
 * Socket Service
 */
@Injectable({providedIn: 'root'})
export class SocketService {
    /**
     * Socket using socket.io
     */
    private socket: any = null;

    /**
     * Get current socket. If there is no socket, we create a new one
     * @return {any} Current socket
     */
    getSocket(): any {
        if (!this.socket) {
            return this.socket = io(environment.envVar.CHAT_URL, {reconnection: false});
        }
        return this.socket;
    }

    /**
     * Connect to server
     */
    connect(): void {
        this.socket.open();
    }

    /**
     * Disconnect from server
     */
    disconnect(): void {
        this.socket.emit('disconnect');
        this.socket.close();
    }
}
