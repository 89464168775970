<div class="content-profile">
    <div class="animate">
        <div class="block-profile">
            <div class="block-notification block-notification--info"
                *ngIf="editMode && isOpenedNotification && isSynchronized">
                <i class="block-notification__icon icon icon-info"></i>

                <span class="block-notification__sentence">
                    {{ 'words.account.sync-data-advertising' | translate }}
                </span>

                <msc-button class="block-notification__cta msc-button--primary msc-button--tiny" palette="secondary"
                    icon="icon-message-circle" *ngIf="canDoRGPDRequest"
                    (click)="requestUpdate(company.experience.contact)">
                    {{ 'words.account.rgpd-request' | translate }}
                </msc-button>
            </div>

            <div class="block-profile__header flex flex-col items-start"
                [ngClass]="{ 'block-profile__header--editing' : editMode }">
                <div class="flex justify-end w-full space-x-2 flex--colum mb-5">
                    <ng-container *ngIf="!editMode">
                        <msc-button class="msc-button--outline" [loading]="loadingDownload"
                            icon="icon-download-1" (click)="downloadHistoryWrapper()">
                            {{ 'words.download-history' | translate }}
                        </msc-button>
                        <msc-button *ngIf="editEnabled" class="msc-button--primary" palette="secondary"
                            icon="icon-edit-2" (click)="edit(true)">
                            {{ 'words.edit' | translate }}
                        </msc-button>
                    </ng-container>
                    <msc-button *ngIf="editMode && editEnabled" icon="icon-close" (click)="edit(false)">
                        {{ 'words.close' | translate }}
                    </msc-button>
                </div>
                <div class="block-profile__infos" [ngClass]="{'edit': editMode}">
                    <div class="block-profile__avatar">
                        <msc-common-avatar *ngIf="!editMode" [label]="profile?.name"
                            [pictureURL]="profile?.picture ? (profile?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="avatar-editable" *ngIf="editMode">
                            <msc-common-avatar *ngIf="!loadings.avatar" [label]="profileEdit?.name"
                                [pictureURL]="profileEdit?.picture ? (profileEdit?.picture + '?size=128') : null">
                            </msc-common-avatar>

                            <a *ngIf="(profileEdit?.mediaId || profile.mediaId) && !loadings.avatar"
                                (click)="deletePicture()">
                                <i class="icon icon-close-1"></i>
                            </a>

                            <msc-common-spinner *ngIf="loadings.avatar"></msc-common-spinner>
                        </div>

                        <div class="block-profile__add-avatar" *ngIf="editMode" (click)="uploadPicture()">
                            <i class="hidden icon icon-image"></i>

                            <p>
                                {{ 'words.upload' | translate }}
                                <span>{{ 'words.upload-info.type' | translate }}</span>
                                <span>{{ 'words.upload-info.size' | translate }}</span>
                            </p>
                        </div>

                        <input #upload class="display-none" type="file"
                            accept="image/png,image/jpeg,image/jpg,image/gif" fileUpload [fileUrl]="'media'"
                            (fileStatus)="updatePicture($event)">
                    </div>

                    <div class="block-profile__name" *ngIf="!editMode">
                        <h4>{{ profile.name }}</h4>
                        <p class="m-0 p-0">{{ profile.email }}</p>
                        <p class="m-0 p-0" *ngIf="profile.headline">{{ profile.headline }}</p>
                        <small *ngIf="!editMode && profile.lastConnection">
                            {{ 'words.last-connection' | translate }} : {{ profile.lastConnection | timeAgo | lowercase
                            }}
                        </small>
                    </div>

                    <div class="block-profile__dataset" *ngIf="editMode">
                        <div class="block-profile__data">
                            <msc-input [label]="'words.firstname' | translate" type="text"
                                [(ngModel)]="profileEdit.firstname" [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data">
                            <msc-input [label]="'words.lastname' | translate" type="text" [(ngModel)]="profileEdit.lastname"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data">
                            <msc-input [label]="'words.job' | translate" type="text" [(ngModel)]="profileEdit.headline"
                                [disabled]="isSynchronized"></msc-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-profile__content">
                <div class="block-profile__infos">
                    <div class="block-profile__dataset">
                        <div class="block-profile__data">
                            <h4>{{ 'words.id' | translate }}
                                <ng-container *ngIf="editMode && profileEdit.companyUser.hasSSO">
                                    <span [tooltip]="'profile.external-identifier.tooltip.hasSSO' | translate">
                                        <i class="icon icon-alert-circle"></i>
                                    </span>
                                </ng-container>
                            </h4>

                            <p *ngIf="!editMode">{{ profile.companyUser.externalId | empty }}</p>
                            <msc-input *ngIf="editMode" type="text" [(ngModel)]="profileEdit.companyUser.externalId"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data" *ngIf="false">
                            <h4>{{ 'words.email' | translate }}</h4>

                            <p *ngIf="!editMode">{{ profile.email }}</p>
                            <msc-input *ngIf="editMode" type="text" [ngModel]="profile.email" [disabled]="true">
                            </msc-input>
                        </div>

                        <div class="block-profile__data">
                            <h4>{{ 'words.phone' | translate }}</h4>

                            <p *ngIf="!editMode">{{ profile.phoneNumber | empty }}</p>
                            <msc-input *ngIf="editMode" type="text" [(ngModel)]="profileEdit.phoneNumber"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data">
                            <h4>{{ 'words.city' | translate }}</h4>

                            <p *ngIf="!editMode">{{ profile.city | empty }}</p>
                            <msc-input *ngIf="editMode" type="text" [(ngModel)]="profileEdit.city"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data">
                            <h4>{{ 'words.company-name' | translate }}</h4>

                            <p *ngIf="!editMode">{{ profile.companyName | empty }}</p>
                            <msc-input *ngIf="editMode" type="text" [(ngModel)]="profileEdit.companyName"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data">
                            <h4>{{ 'words.department' | translate }}</h4>

                            <p *ngIf="!editMode">{{ profile.department | empty }}</p>
                            <msc-input *ngIf="editMode" type="text" [(ngModel)]="profileEdit.department"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data">
                            <h4>{{ 'words.organization-id' | translate }}</h4>

                            <p *ngIf="!editMode">{{ profile.organizationId | empty }}</p>
                            <msc-input *ngIf="editMode" type="text" [(ngModel)]="profileEdit.organizationId"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data">
                            <h4>{{ 'words.document-id' | translate }}</h4>

                            <p *ngIf="!editMode">{{ profile.documentId | empty }}</p>
                            <msc-input *ngIf="editMode" type="text" [(ngModel)]="profileEdit.documentId"
                                [disabled]="isSynchronized"></msc-input>
                        </div>

                        <div class="block-profile__data" *ngIf="profile.id !== me.id">
                            <h4>{{ 'words.interface-language' | translate }}</h4>
                            <p *ngIf="!editMode">{{ profile.language.name }}</p>

                            <!-- <msc-language-dropdown *ngIf="editMode" [type]="'interface'" [hasLabel]="false" [multiSelect]="false"
                                [currentLanguage]="profileEdit.language" (select)="onSelect($event)"></msc-language-dropdown> -->

                            <ng-container *ngIf="editMode && selectData.interfaceLanguages.length">
                                <msc-common-select [data]="selectData.interfaceLanguages">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="select-main">
                                            <ng-container *ngIf="data?.length">
                                                <img
                                                    src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                                                <span>{{ data[0].label }}</span>
                                            </ng-container>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="select-option" (click)="onSelectOption(datum)">
                                            <img
                                                src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                                            <span>{{ datum.label }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </ng-container>
                        </div>

                        <div class="block-profile__data block-profile__data--block">
                            <h4>{{ (editMode && me.id !== profile.id ? 'words.role-validity-period' : 'words.role') |
                                translate }}</h4>

                            <div class="block-profile__roles" *ngIf="!editMode || me.id === profile.id">
                                <i class="icon icon-student"
                                    [ngClass]="{'active': profile.companyRoles.companyLearner}"></i>
                                <i class="icon icon-color-palette"
                                    [ngClass]="{'active': profile.companyRoles.companyAuthor}"></i>
                                <i class="icon icon-mentor"
                                    [ngClass]="{'active': profile.companyRoles.companyManager}"></i>
                                <i class="icon icon-calendar"
                                    [ngClass]="{'active': profile.companyRoles.companyAdministrator}"></i>
                            </div>

                            <msc-button class="msc-button--outline" *ngIf="editMode && me.id !== profile.id"
                                (click)="manageAccess()">
                                {{ 'words.manage-roles' | translate }}
                            </msc-button>
                        </div>

                        <div class="block-profile__data block-profile__data--block" *ngIf="!editMode">
                            <h4>{{ 'words.validity-period' | translate }}</h4>

                            <p>
                                <span *ngIf="getTranslateDate()">{{ getTranslateDate() | translate }} {{
                                    profile.companyUser.accessStatus.accessStartAt ||
                                    profile.companyUser.subscriptionDate | date: 'd MMM YY' }}</span>
                                <span *ngIf="profile.companyUser.accessStatus.accessEndAt"> - {{
                                    profile.companyUser.accessStatus.accessEndAt | date: 'd MMM YY' }}</span>
                            </p>
                        </div>
                    </div>

                    <div class="block-profile__bio">
                        <h4>{{ 'words.biography' | translate }}</h4>

                        <div class="block-profile__bio__content" *ngIf="!editMode">
                            {{ profile.biography | empty }}
                        </div>

                        <msc-textarea rows="5" cols="33" *ngIf="editMode" [(ngModel)]="profileEdit.biography">
                        </msc-textarea>
                    </div>
                </div>
            </div>

            <div class="block-profile__cta" *ngIf="editMode">
                <msc-button (click)="cancel()">
                    {{ 'words.cancel' | translate }}
                </msc-button>

                <msc-button class="msc-button--primary" palette="primary" (click)="save()">
                    {{ 'words.save' | translate }}
                </msc-button>
            </div>
        </div>
    </div>

    <div class="footer-privacy animate flex align-center" *ngIf="!editMode">
        <small *ngIf="me.id === profile.id" class="mr-2">{{ 'profile.info' | translate }}</small>
        <a class="link-privacy" (click)="privacyPolicy()">
            {{ 'words.privacy-policy' | translate }}
        </a>
    </div>
</div>

<msc-common-modal size="30vw" #modalConfirmation [title]="'words.confirm-action' | translate">
    <ng-template>
        <p>{{ 'profile.modal.sso-integration-warning' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalConfirmation.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="save(true); modalConfirmation.onClose()">
                {{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
