<ng-template #factoryTemplate>
    <div class="base" [style.width]="size">
        <div class="head">
            <div class="flex gap-2 items-center">
                <h2>{{ title }}</h2>
                <i *ngIf="tooltip" class="{{tooltip.icon}} hover:text-secondary-700" [tooltip]="tooltip.text"></i>
            </div>
            <msc-button type="fab" icon="icon-close" (click)="onClose()"></msc-button>
        </div>

        <div class="body">
            <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
        </div>
    </div>
</ng-template>