<div class="modal modal-reaction" [ngClass]="{'active': isActive}" modal>
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <div class="modal__close" (click)="onClose.emit()">
                <i class="icon icon-close"></i>
            </div>
        </div>

        <div class="modal__body">
            <div class="modal-reaction__tabs">
                <ul class="modal-reaction__tabs-controls flex">
                    <li class="modal-reaction__tabs-control active" (click)="changeTabs()" [ngClass]="{'active': tab === 'all'}">
                        <a>
                            <span>{{ 'filter.all' | translate }}</span>
                        </a>
                    </li>

                    <li class="modal-reaction__tabs-control" (click)="changeTabs(reaction)" [ngClass]="{'active': tab === reaction.name}" *ngFor="let reaction of availableReactions">
                        <a>
                            <img [src]="'https://static.myskillcamp.com/images/animations/' + reaction.name + '.svg'" [alt]="reaction.name">
                            <span>{{ reaction.count }}</span>
                        </a>
                    </li>
                </ul>

                <div class="modal-reaction__tabs-panels" *ngIf="users.length > 0">
                    <div class="modal-reaction__tabs-panel" data-simplebar>
                        <ul class="modal-reaction__user">
                            <li *ngFor="let user of users">
                                <div class="avatar-user">
                                    <msc-common-avatar class="tiny" [label]="user.account?.name" [pictureURL]="user.account?.picture ? (user.account?.picture + '?size=64') : null"></msc-common-avatar>

                                    <div class="avatar__content">
                                        <p class="profile__name">{{ user.account.name }}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <msc-common-spinner *ngIf="loadUsers"></msc-common-spinner>
            </div>
        </div>
    </div>
</div>
