<div class="content-camps content-camps-stats">
    <div class="content-camps-stats__header">
        <div class="content-camps-stats__filter flex align-center between">
            <div class="content-camps-stats__search flex align-center">
                <div class="date-init">
                    <p>
                        {{ 'words.from' | translate }}
                        <span>{{ dates.from | date: 'dd MMM YYYY' }}</span>
                    </p>
                </div>

                <div class="date-till flex align-center justify-start">
                    <p>{{ 'words.up-to' | translate }}</p>

                    <div class="select" [ngClass]="{'active': displays.date}" (click)="displayed('date')">
                        <div class="select__content">
                            <p class="select__placeholder">
                                {{ dates.upto | date: 'dd MMM YYYY' }}
                                <i class="icon icon-arrow-ios-down"></i>
                            </p>

                            <div class="select__dropdown" (click)="$event.stopPropagation()">
                                <msc-datepicker [date]="dates.upto" [limit]="{min: dates.from, max: today}" [actions]="true" (onUpdate)="updateDate($event)" (onClose)="displayed('date', true)"></msc-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-stats-adoption__action hidden-xs">
                <msc-button class="msc-button--primary" palette="primary" type="fab" icon="icon-download-1" (click)="modalExports.open({name: camp.name})" [disabled]="loaders.download" [loading]="loaders.download"></msc-button>
            </div>
        </div>
    </div>

    <msc-common-spinner *ngIf="loaders.loading"></msc-common-spinner>

    <div class="content-camps-stats__body" *ngIf="!loaders.loading">
        <div class="box-stats-adoption box flex align-center">
            <p class="box-stats-adoption__title flex align-center">
                <i class="icon icon-options-2 text-primary-700"></i>
                {{ 'words.adoption-rate' | translate }}
            </p>

            <p class="box-stats-adoption__number text-primary-700">{{ adoptionPercentage }}%</p>
        </div>

        <div class="content-camps-stats__canvas box">
            <msc-funnel [options]="options"></msc-funnel>

            <div class="content-camps-stats__sidebar">
                <div class="box box-adoption-details" *ngFor="let other of options.other; let index=index">
                    <p class="box-adoption-details__status flex">
                        <i class="icon icon-arrow-ios-down text-primary-700"></i>
                        {{ other.label }}
                    </p>

                    <p class="box-adoption-details__stats">{{ sum(options.data[index].value, options.data[index + 1].value) }}</p>
                    <p class="box-adoption-details__rank text-primary-700">{{ dropOff(options.data[index].value, options.data[index + 1].value) }} {{ 'funnel.adoption.other.drop-off' | translate }}</p>

                    <msc-button class="msc-button--primary" palette="primary" *ngIf="other.cta" (click)="navigate(other.url)">
                        {{ other.cta }}
                    </msc-button>
                </div>
            </div>
        </div>
    </div>
</div>

<msc-stats-export-modal #modalExports (onSave)="download($event)"></msc-stats-export-modal>

<msc-common-modal size="30vw" #modalConfirm [title]="'stats.email-export.send_export' | translate">
    <ng-template>
        <p [innerHTML]="'export.export_is_being_created' | translate"></p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalConfirm.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="modalConfirm.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
