import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PlayerLightModalIframeComponent } from '@components/player-light/modals/modal-iframe/modal-iframe.component';
import { PlayerLightModalProgressComponent } from '@components/player-light/modals/modal-progress/modal-progress.component';
import { PlayerLightModalActivityComponent } from '@components/player-light/modals/modal-activity/modal-activity.component';
import { PlayerLightModalEmbedComponent } from '@components/player-light/modals/modal-embed/modal-embed.component';
import { EmitterService, StorageService, TranslateService } from '@services';
import { DataHelper, HttpHelper } from '@helpers';
import { Company, ActivityFactory, CourseProvider, ExternalContentProvider, StatisticsProvider } from '@lighty';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-player-light',
    templateUrl: './player-light.component.html'
})
export class PlayerLightComponent implements OnInit {
    @ViewChild('playerContainer', { read: ViewContainerRef }) playerContainer: ViewContainerRef;
    private modal: ComponentRef<PlayerLightModalIframeComponent | PlayerLightModalProgressComponent>;
    private activity: ComponentRef<PlayerLightModalActivityComponent>;
    private embed: ComponentRef<PlayerLightModalEmbedComponent>;
    private company: Company;
    private me: any;

    constructor(
        private externalContentProvider: ExternalContentProvider, 
        private factory: ComponentFactoryResolver, 
        private emitterService: EmitterService,
        private toastService: CommonToastService, 
        private activityFactory: ActivityFactory, 
        private courseProvider: CourseProvider,
        private storageService: StorageService, 
        private statisticsProvider: StatisticsProvider,
        private translateService: TranslateService,
        ) { }

    ngOnInit(): void {
        this.emitterService.get('player-light.open').subscribe((data) => {
            this.company = this.storageService.get('company');
            this.me = this.storageService.get('me');
            if (!DataHelper.isEmpty(data)) {
                if (data.type === 'lti') {
                    this.statisticsProvider.launchActivity(data.id).subscribe();
                    this.createEmbed(data.type, data);
                } else if (data.type === 'iframe') {
                    const params = {
                        course_id: data.more.id,
                        preview: 0,
                        direct_play: 0
                    };

                    this.courseProvider.get(data.more.id, params).subscribe((course) => {
                        const content = course.sections[0].sectionContents[0];
                        this.courseProvider.createSubscription(params).subscribe((subscription) => {
                            if (subscription.sectionContentSessions.length === 0) {
                                const parameters = {
                                    sectionContentId: content.id,
                                    courseSubscriptionSessionId: subscription.id
                                };

                                this.courseProvider.createSectionContentSession(parameters).subscribe(() => {
                                    this.createSCORM(data);
                                });
                            } else {
                                this.createSCORM(data);
                            }
                        });
                    });
                } else if (data.type === 'activity') {
                    const params = {
                        company_id: this.company.id,
                        course_id: data.subscriptionCourseId,
                        preview: 0,
                        direct_play: 0
                    };

                    this.courseProvider.get(data.subscriptionCourseId, params).subscribe((course) => {
                        const content = course.sections[0].sectionContents[0];
                        this.courseProvider.createSubscription(params).subscribe((subscription) => {
                            this.statisticsProvider.launchActivity(content.id).subscribe();
                            const progressEnabled = data.more.provider ? data.more.provider.progressEnabled : undefined;
                            if (subscription.sectionContentSessions.length === 0) {
                                const parameters = {
                                    sectionContentId: content.id,
                                    courseSubscriptionSessionId: subscription.id
                                };

                                this.courseProvider.createSectionContentSession(parameters).subscribe((sectionContentSession) => {
                                    this.statisticsProvider.launchActivity(content.id).subscribe();
                                    content.externalLink && content.externalLink.mode === 'blank' ? this.createModal(content, sectionContentSession, data.more, progressEnabled) : this.createActivity(content, sectionContentSession, data.more);
                                });
                            } else {
                                content.externalLink && content.externalLink.mode === 'blank' ? this.createModal(content, content, data.more, progressEnabled) : this.createActivity(content, subscription.sectionContentSessions[0], data.more);
                            }
                        });
                    });
                } else {
                    if (['external_content', 'course'].indexOf(data.more.type) !== -1) {
                        this.statisticsProvider.launchContent(data.more.id, {
                            context: data.more.type,
                        }).subscribe();
                    }
                    this.createModal(data.type, data.content, data.more, data.progressEnabled);
                }
            }
        });
    }

    private createModal(type: string, content: any, more: any, progressEnabled: boolean = true): void {
        this.emitterService.set('player-light.loaded', content);
        this.playerContainer.clear();

        let factory;

        if (type === 'video' || type === 'iframe') {
            factory = this.factory.resolveComponentFactory(PlayerLightModalIframeComponent);
        } else {
            factory = this.factory.resolveComponentFactory(PlayerLightModalProgressComponent);
        }

        this.modal = this.playerContainer.createComponent(factory);
        this.modal.instance.content = content;
        this.modal.instance.type = type;
        this.modal.instance.progressEnabled = !!progressEnabled;

        if (type === 'iframe') {
            this.modal.instance.options = { actionEnable: content.actionEnable !== undefined ? content.actionEnable : true };
        }

        this.modal.instance.onClose.subscribe(() => {
            this.emitterService.set('refresh.content', true);
            this.modal.destroy();
        });

        this.modal.instance.onSave.subscribe(data => {
            this.modal.destroy();

            if (data.content.duration === 0) {
                data.content.duration = null;
            }
            this.externalContentProvider.updateSession(content.id, HttpHelper.cleanParams(data.content)).subscribe(() => {
                if (data.type === 'finished') {
                    more.progress = 100;
                    this.emitterService.set('finished.content', true);
                }
                this.emitterService.set('refresh.content', true);
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            });
        });
    }

    private createActivity(content: any, session: any, more: any): void {
        this.emitterService.set('player-light.loaded', content);
        this.playerContainer.clear();
        this.activity = this.playerContainer.createComponent(this.factory.resolveComponentFactory(PlayerLightModalActivityComponent));
        this.activity.instance.content = content;
        this.activity.instance.session = session;

        this.activity.instance.onClose.subscribe(() => {
            this.emitterService.set('refresh.content', true);
            this.activity.destroy();
        });

        this.activity.instance.onSave.subscribe((data) => {
            this.activity.destroy();

            if (more.session) {
                more.session.progress = 100;
            }
            this.courseProvider.updateSectionContentSessions(data.id, data, { isDone: true }).subscribe(() => {
                this.emitterService.set('refresh.content', true);
                this.emitterService.set('finished.content', true);
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            });
        });
    }

    private createEmbed(type: string, content: any): void {
        this.playerContainer.clear();

        const factory = this.factory.resolveComponentFactory(PlayerLightModalEmbedComponent);

        this.embed = this.playerContainer.createComponent(factory);
        this.embed.instance.content = content;
        this.embed.instance.type = type;
    }

    private createSCORM(data: any): void {
        const params = {
            preview: false,
            sectionContentId: data.content.sectionContentId
        };

        this.activityFactory.get('scorm').launch(data.content.scormId, params).subscribe(scorm => {
            const content = {
                id: data.content.sectionContentId,
                title: data.more.title,
                url: scorm.url,
                scormId: data.content.scormId,
                sectionContentId: data.content.sectionContentId,
                actionEnable: false
            };

            this.statisticsProvider.launchActivity(data.content.sectionContentId).subscribe();

            this.createModal(data.type, content, data.more);
        });
    }
}
