<div class="container__aside__preview">
    <div class="preview__image rounded" [ngStyle]="{'background-image': content?.picture ? ('url(' + content?.picture + ((content?.provider?.key !== 'linkedin') ? '?size=512)' : ')')) : ('url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content?.id % 7 + '.webp)')}">
    </div>
</div>

<div class="bg-white shadow-md rounded p-4 my-4 flex flex-col space-y-4">
    <h2>{{ 'words.additional-information' | translate }}</h2>

    <div class="flex flex-col space-y-2">
        <span *ngIf="content.duration">
            <i class="icon icon-clock"></i>
            {{ content.duration | duration:true }} {{'words.to-complete' | translate}}
        </span>

        <span *ngIf="content.language">
            <i class="icon icon-globe"></i>
            {{ content.language.name }}
        </span>

        <span>
            <i class="icon icon-student"></i>
            {{ 'words.presentation' | translate }}
        </span>

        <span *ngIf="content.subscribersCount >= 0">
            <i class="icon icon-people"></i>
            <ng-container *ngIf="!content.subscribersCount">
                0 {{'words.learners-enrolled' | translate}}
            </ng-container>
            <ng-container *ngIf="content.subscribersCount == 1">
                1 {{'words.learner-enrolled' | translate}}
            </ng-container>
            <ng-container *ngIf="content.subscribersCount > 1">
                {{ content.subscribersCount }} {{'words.learners-enrolled' | translate}}
            </ng-container>
        </span>
    </div>

    <div class="flex flex-wrap gap-2" *ngIf="content.skills && content.skills.length > 0">
        <div class="skill skill--level" *ngFor="let skill of content.skills">
            <div class="skill__content">
                <div class="skill__level">
                    <span [ngClass]="{'active': skill.level > 0}"></span>
                    <span [ngClass]="{'active': skill.level > 1}"></span>
                    <span [ngClass]="{'active': skill.level > 2}"></span>
                </div>

                <p class="skill__text">{{ skill.name }}</p>
            </div>
        </div>
    </div>
</div>

<div class="bg-white shadow-md rounded p-4 mb-8" *ngIf="content.provider">
    <h2>{{'words.provided-by' | translate}}</h2>
    <img *ngIf="!providerPictureNotLoaded" class="block-course__header__tags__provider-picture"
        (error)="providerPictureNotLoaded=true"
        [src]="'https://static.myskillcamp.com/images/integrations/' + content.provider.key + '.svg'">
    <h3 class="flex items-center justify-center gap-2 m-4" *ngIf="providerPictureNotLoaded">
        <i class="icon icon-share text-primary-500 text-2xl"></i>
        <span>{{content.provider.name}}</span>
    </h3>
</div>
