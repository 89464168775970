import { Component, ElementRef, Host, OnInit, ViewChild } from '@angular/core';
import { ContentProvider } from './../../../../lighty/models/content/content.provider';
import { take } from 'rxjs';
import { StorageService } from '@services';
@Component({
  selector: 'app-onementor',
  templateUrl: './onementor.component.html',
  styleUrls: ['./onementor.component.scss']
})
export class OnementorComponent implements OnInit {
  @ViewChild('iframe') iframe: any;
  public company: any;
  public isLoading: boolean = true;
  constructor(
    private contentProvider: ContentProvider,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.company = this.storageService.get('company');
    const params = {
      baseUrl: localStorage.getItem('baseUrl')
  };
  console.log(this.company.id)
  this.contentProvider.getTokenOneMentor(this.company.id, params).pipe(take(1)).subscribe(data => {
        window.addEventListener('message', (event) => {
          if(event.origin == 'https://griky.1mentor.io'){
            this.sendMessage(data.token);
            console.log("Mensaje recibido", event);
          }
        })
  })
  }

  public sendMessage(token: any): void {
      this.iframe.nativeElement.contentWindow.postMessage({
        tokenGriky: token
      }, "*");
      this.isLoading = false;
      console.log('enviado')
  }

}
