export class InstanceStats {
    id: number;
    title: string;
    total: number;
    subscriptionsCompleted: number;
    subscriptionsNotStarted: number;
    subscriptionsInProgress: number;
    averageDuration: number;
    averageScore: number;
    averageProgress: number;
    status: string;

    constructor(template: object) {
        this.assign(template);
    }

    assign(template: object): void {
        Object.assign(this, template);
    }
}
