import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AdminContentProvider, AdminJourneyProvider } from '@lighty';
import { DataHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-camp-aside-publication',
    templateUrl: './aside-publication.component.html'
})
export class CampAsidePublicationComponent {
    @ViewChild('aside') aside;
    @Input() camp: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public courseCamp: any;
    public journey: any;
    public isActive: boolean = false;
    public onCloseByUser: EventEmitter<any> = new EventEmitter();
    public displays: any;
    public courseCampCopy: any;
    public journeyCopy: any;

    constructor(
        private contentProvider: AdminContentProvider,
        private journeyProvider: AdminJourneyProvider,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.isActive = true;

        this.displays = {
            accessibility: [],
            monitoring: [],
        };

        this.courseCampCopy = DataHelper.clone(this.courseCamp);
        this.journeyCopy = DataHelper.clone(this.journey);
    }

    onOpen(item, type){
        if (type === 'courseCamp') {
            this.courseCamp = item;
        } else {
            this.journey = item;
        }
        this.init();
        this.aside.onOpen();
    }

    changeSwitch(type: string): void {
        if (this.courseCamp) {
            this.courseCampCopy[type] = !this.courseCampCopy[type];
        } else if (this.journey) {
            this.journeyCopy.settings[type] = !this.journeyCopy.settings[type];
        }
    }

    update(): void {
        if (this.courseCamp) {
            this.contentProvider.updateSettings(this.camp.id, this.courseCamp.id, this.courseCampCopy).subscribe(() => {
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
                this.onSave.emit();
                this.save();
            });
        } else if (this.journey) {
            this.journeyProvider.updateSettings(this.camp.id, this.journey.id, this.journeyCopy.settings).subscribe(() => {
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
                this.onSave.emit();
                this.save();
            });
        }
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        this.onSave.emit(true);
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }
}
