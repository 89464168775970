import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppCommon2Module } from '@common2/common.module';

// Interceptors
import { HttpInterceptorService, ErrorInterceptorService } from './interceptors';

// Components
import { AppcuesComponent } from '@components/header/appcues/appcues.component';
import { AudioPlayerComponent } from '@components/audio-player/audio-player.component';
import { AvatarComponent } from '@components/avatar/avatar.component';
import { CardExternalContentComponent } from '@components/card/card-type/card-external-content/card-external-content.component';
import { CardInstanceComponent } from '@components/card/card-type/card-instance/card-instance.component';
import { CardJourneyComponent } from '@components/card/card-type/card-journey/card-journey.component';
import { CardManagerComponent } from '@components/card/card-manager/card-manager.component';
import { CardManagerModalDeleteSubscriptionComponent } from '@components/card/card-manager/modal/delete-subscription/delete-subscription.component';
import { CardTemplateComponent } from '@components/card/card-type/card-template/card-template.component';
import { ChartBarComponent } from '@components/chart/chart-bar/chart-bar.component';
import { ChartLineComponent } from '@components/chart/chart-line/chart-line.component';
import { ChartPieComponent } from '@components/chart/chart-pie/chart-pie.component';
import { ComposerAsideEmbedComponent } from '@components/composer/aside/embed/embed.component';
import { ComposerComponent } from '@components/composer/composer.component';
import { ContentCardComponent } from '@components/card/content-card.component';
import { DatePickerComponent } from '@components/date-picker/date-picker.component';
import { DateRangePickerComponent } from '@components/daterange-picker/daterange-picker.component';
import { DetailCorrectionComponent } from '@components/correction/detail/detail-correction.component';
import { EmptyStateComponent } from '@components/empty-state/empty-state.component';
import { FiltersComponent } from '@components/filters/filters.component';
import { FroalaComponent } from '@components/froala/froala.component';
import { FunnelComponent } from '@components/funnel/funnel.component';
import { HeaderComponent } from '@components/header/header.component';
import { LinkManagerComponent } from '@components/link-preview/link-manager/link-manager.component';
import { LinkPreviewComponent } from '@components/link-preview/link-preview.component';
import { ListCorrectionComponent } from '@components/correction/list/list-correction.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { LoadMoreComponent } from '@components/load-more/load-more.component';
import { MediaComponent } from '@components/media/media.component';
import { MediaManagerComponent } from '@components/media-manager/media-manager.component';
import { NavigationProgressComponent } from '@components/navigation-progress/navigation-progress.component';
import { OnboardingTopbarFiltersComponent } from '@components/onboarding-filters/onboarding-filters.component';
import { PlayerLightComponent } from '@components/player-light/player-light.component';
import { PlayerLightModalActivityComponent } from '@components/player-light/modals/modal-activity/modal-activity.component';
import { PlayerLightModalEmbedComponent } from '@components/player-light/modals/modal-embed/modal-embed.component';
import { PlayerLightModalIframeComponent } from '@components/player-light/modals/modal-iframe/modal-iframe.component';
import { PlayerLightModalProgressComponent } from '@components/player-light/modals/modal-progress/modal-progress.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { RangeSliderComponent } from '@components/range-slider/range-slider.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { SortComponent } from '@components/sort/sort.component';
import { TagComponent } from '@components/tag/tag.component';
import { TimelineAsideFiltersComponent } from '@components/timeline/aside/aside-filters/aside-filters.component';
import { TimelineCommentComponent } from '@components/timeline/comment/timeline-comment.component';
import { TimelineComponent } from '@components/timeline/timeline.component';
import { TimelineModalReactionComponent } from '@components/timeline/post/modal-reaction/modal-reaction.component';
import { TimelinePostComponent } from '@components/timeline/post/timeline-post.component';
import { VimeoComponent } from '@components/vimeo/vimeo.component';
import { WindowEmbedComponent } from '@components/window-embed/window-embed.component';

// Shared components
import { SharedEventCalendarComponent } from '@components/shared/event-calendar/event-calendar.component';
import { SharedExportReportingComponent } from '@components/shared/aside-export-reporting/aside-export-reporting.component';
import { SharedExtensionCampsComponent } from '@components/shared/aside-extension/aside-extension-camps/aside-extension-camps.component';
import { SharedExtensionChoiceComponent } from '@components/shared/aside-extension/aside-extension-choice/aside-extension-choice.component';
import { SharedExtensionCommunityComponent } from '@components/shared/aside-extension/aside-extension-community/aside-extension-community.component';
import { SharedExtensionComponent } from '@components/shared/aside-extension/extension.component';
import { SharedExtensionFormComponent } from '@components/shared/aside-extension/aside-extension-form/extension-form.component';
import { SharedExtensionInfosComponent } from '@components/shared/aside-extension/aside-extension-infos/extension-infos.component';
import { SharedExtensionSkillsComponent } from '@components/shared/aside-extension/aside-extension-skills/aside-extension-skills.component';
import { SharedExtensionSuccessComponent } from '@components/shared/aside-extension/aside-extension-success/extension-success.component';
import { SharedExtensionSummaryComponent } from '@components/shared/aside-extension/aside-extension-summary/extension-summary.component';
import { SharedForbidSkillComponent } from '@components/shared/aside-forbid-skill/forbid-skill.component';
import { SharedIntegrationLinkComponent } from '@components/shared/aside-integration-link/integration-link.component';
import { SharedIntegrationPlaylistComponent } from '@components/shared/aside-integration-playlist/integration-playlist.component';
import { SharedMailMultipleUsersComponent } from '@components/shared/aside-mail-multiple-users/aside-mail-multiple-users.component';
import { SharedMailUserComponent } from '@components/shared/aside-mail-user/aside-mail-user.component';
import { SharedResourceComponent } from '@components/shared/resource/resource.component';
import { SharedStatsProviderManagerComponent } from '@components/shared/stats-provider-manager/stats-provider-manager.component';
import { SharedStatsProvidersManagerComponent } from '@components/shared/stats-providers-manager/stats-providers-manager.component';
import { SharedStatsUsageManagerComponent } from '@components/shared/stats-usage-manager/stats-usage-manager.component';
import { SharedTopbarFiltersComponent } from '@components/shared/topbar-filters/topbar-filters.component';

// Directives
import {
    AsideDirective,
    AutoCloseDirective,
    AutoSizeDirective,
    DebounceDirective,
    DraggableDirective,
    DropzoneDirective,
    FileUploadDirective,
    HoveringDirective,
    ModalDirective,
} from './directives';

// Pipes
import {
    CleanPipe,
    CurrencyPipe,
    DurationPipe,
    EmptyPipe,
    FileSizePipe,
    FilterPipe,
    HhmmPipe,
    LocalizedDatePipe,
    Nl2brPipe,
    ReplacePipe,
    SafePipe,
    TimeAgoPipe,
    TimeElapsedPipe,
    ToArrayPipe,
    TruncatePipe,
    UrlPipe,
} from './pipes';

import { LanguageDropdown } from '@components/language-dropdown/language-dropdown.component';
import { ReadMoreDirective } from '@common/directives/read-more.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [
        // Components
        AppcuesComponent,
        AudioPlayerComponent,
        AvatarComponent,
        CardExternalContentComponent,
        CardInstanceComponent,
        CardJourneyComponent,
        CardManagerComponent,
        CardManagerModalDeleteSubscriptionComponent,
        CardTemplateComponent,
        ChartBarComponent,
        ChartLineComponent,
        ChartPieComponent,
        ComposerAsideEmbedComponent,
        ComposerComponent,
        ContentCardComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DetailCorrectionComponent,
        EmptyStateComponent,
        FiltersComponent,
        FroalaComponent,
        FunnelComponent,
        HeaderComponent,
        LanguageDropdown,
        LinkManagerComponent,
        LinkPreviewComponent,
        ListCorrectionComponent,
        LoadingComponent,
        LoadMoreComponent,
        MediaComponent,
        MediaManagerComponent,
        NavigationProgressComponent,
        OnboardingTopbarFiltersComponent,
        PlayerLightComponent,
        PlayerLightModalActivityComponent,
        PlayerLightModalEmbedComponent,
        PlayerLightModalIframeComponent,
        PlayerLightModalProgressComponent,
        ProgressBarComponent,
        RangeSliderComponent,
        SharedEventCalendarComponent,
        SharedExportReportingComponent,
        SharedExtensionCampsComponent,
        SharedExtensionChoiceComponent,
        SharedExtensionCommunityComponent,
        SharedExtensionComponent,
        SharedExtensionFormComponent,
        SharedExtensionInfosComponent,
        SharedExtensionSkillsComponent,
        SharedExtensionSuccessComponent,
        SharedExtensionSummaryComponent,
        SharedForbidSkillComponent,
        SharedForbidSkillComponent,
        SharedIntegrationLinkComponent,
        SharedIntegrationLinkComponent,
        SharedIntegrationPlaylistComponent,
        SharedIntegrationPlaylistComponent,
        SharedMailMultipleUsersComponent,
        SharedMailUserComponent,
        SharedResourceComponent,
        SharedStatsProviderManagerComponent,
        SharedStatsProvidersManagerComponent,
        SharedStatsUsageManagerComponent,
        SharedTopbarFiltersComponent,
        SidebarComponent,
        SortComponent,
        TagComponent,
        TimelineAsideFiltersComponent,
        TimelineCommentComponent,
        TimelineComponent,
        TimelineModalReactionComponent,
        TimelinePostComponent,
        VimeoComponent,
        WindowEmbedComponent,
        // Directives
        AsideDirective,
        AutoCloseDirective,
        AutoSizeDirective,
        DebounceDirective,
        DraggableDirective,
        DropzoneDirective,
        FileUploadDirective,
        HoveringDirective,
        ModalDirective,
        ReadMoreDirective,
        // Pipes
        CleanPipe,
        CurrencyPipe,
        DurationPipe,
        EmptyPipe,
        FileSizePipe,
        FilterPipe,
        HhmmPipe,
        LocalizedDatePipe,
        Nl2brPipe,
        ReplacePipe,
        SafePipe,
        TimeAgoPipe,
        TimeElapsedPipe,
        ToArrayPipe,
        TruncatePipe,
        UrlPipe,
    ],
    exports: [
        // Components
        AppcuesComponent,
        AudioPlayerComponent,
        AvatarComponent,
        CardExternalContentComponent,
        CardInstanceComponent,
        CardJourneyComponent,
        CardManagerComponent,
        CardTemplateComponent,
        ChartBarComponent,
        ChartLineComponent,
        ChartPieComponent,
        ComposerAsideEmbedComponent,
        ComposerComponent,
        ContentCardComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DetailCorrectionComponent,
        EmptyStateComponent,
        FiltersComponent,
        FroalaComponent,
        FunnelComponent,
        HeaderComponent,
        LanguageDropdown,
        LinkManagerComponent,
        LinkPreviewComponent,
        ListCorrectionComponent,
        LoadingComponent,
        LoadMoreComponent,
        MediaComponent,
        MediaManagerComponent,
        NavigationProgressComponent,
        OnboardingTopbarFiltersComponent,
        PlayerLightComponent,
        ProgressBarComponent,
        RangeSliderComponent,
        SharedEventCalendarComponent,
        SharedExportReportingComponent,
        SharedExtensionCampsComponent,
        SharedExtensionChoiceComponent,
        SharedExtensionCommunityComponent,
        SharedExtensionComponent,
        SharedExtensionFormComponent,
        SharedExtensionInfosComponent,
        SharedExtensionSkillsComponent,
        SharedExtensionSuccessComponent,
        SharedExtensionSummaryComponent,
        SharedForbidSkillComponent,
        SharedForbidSkillComponent,
        SharedIntegrationLinkComponent,
        SharedIntegrationLinkComponent,
        SharedIntegrationPlaylistComponent,
        SharedIntegrationPlaylistComponent,
        SharedMailMultipleUsersComponent,
        SharedMailUserComponent,
        SharedResourceComponent,
        SharedStatsProviderManagerComponent,
        SharedStatsProvidersManagerComponent,
        SharedStatsUsageManagerComponent,
        SharedTopbarFiltersComponent,
        SidebarComponent,
        SortComponent,
        TagComponent,
        TimelineAsideFiltersComponent,
        TimelineCommentComponent,
        TimelineComponent,
        TimelineModalReactionComponent,
        TimelinePostComponent,
        VimeoComponent,
        WindowEmbedComponent,
        CardManagerModalDeleteSubscriptionComponent,
        // Directives
        AsideDirective,
        AutoCloseDirective,
        AutoSizeDirective,
        DebounceDirective,
        DraggableDirective,
        DropzoneDirective,
        FileUploadDirective,
        HoveringDirective,
        ModalDirective,
        ReadMoreDirective,
        // Pipes
        CleanPipe,
        CurrencyPipe,
        DurationPipe,
        EmptyPipe,
        FileSizePipe,
        FilterPipe,
        HhmmPipe,
        LocalizedDatePipe,
        Nl2brPipe,
        ReplacePipe,
        SafePipe,
        TimeAgoPipe,
        TimeElapsedPipe,
        ToArrayPipe,
        TruncatePipe,
        UrlPipe,
        // Modules
        OverlayModule,
        BreadcrumbModule,
        SwiperModule,
        CommonModule,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        AppCommon2Module,
        OverlayModule,
        BreadcrumbModule,
        SwiperModule,
    ],
    entryComponents: [
        CardManagerModalDeleteSubscriptionComponent,
        PlayerLightModalIframeComponent,
        PlayerLightModalProgressComponent,
        PlayerLightModalActivityComponent,
        PlayerLightModalEmbedComponent,
    ],
    providers: [
        BreadcrumbService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        }
    ]
})
export class SharedModule { }
