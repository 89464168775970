import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, IntegrationProvider, Pagination } from '@lighty';
import { EmitterService, ExternalAppsService, StorageService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-organize-integration-watch-mapping',
    templateUrl: './mapping.component.html',
    styleUrls: ['./mapping.component.scss'],
})

export class OrganizeIntegrationWatchMappingComponent {
    private company: Company;
    public provider: any;
    public editMode: boolean = false;
    public searchQuery: string = '';
    public display: any[] = [];
    public associations: any[] = [];
    public loaders: any = {
        skills: false,
        saveAssociation: false,
        associations: false
    };
    public skillsPagination: Pagination;
    public mapping: any[] = [];
    public selectedAssociation: any;

    constructor(
        private route: ActivatedRoute,
        private integrationProvider: IntegrationProvider,
        private storageService: StorageService,
        private router: Router,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.route.data.subscribe(() => {
            this.provider = this.route.snapshot.parent.parent.data.integration;
            this.loaders.associations = true;
            this.editMode = false;

            this.integrationProvider.getMappingSkills(this.company.id, this.provider.id).subscribe((data) => {
                this.associations = data.skills;
                this.skillsPagination = data.pagination;
                this.loaders.associations = false;
            });
        });
    }

    switchMode(): void {
        this.editMode = !this.editMode;
        this.emitterService.set('mapping-edit', this.editMode);
    }

    displayed(item: any): void {
        this.display[item] = !this.display[item];
    }

    deleteMapping(): void {
        this.integrationProvider.deleteMappingSkill(this.company.id, this.provider.id, this.selectedAssociation.id).subscribe(() => {
            this.selectedAssociation.providerSkills = [];
        });
    }

    saveMapping(): void {
        this.loaders.saveAssociation = true;
        const params = {
            mapping: this.mapping
        };

        this.integrationProvider.updateMappingSkill(this.company.id, this.provider.id, params).subscribe(() => {
            this.integrationProvider.getMappingSkills(this.company.id, this.provider.id).subscribe((data) => {
                this.associations = data.skills;
                this.skillsPagination = data.pagination;
                this.loaders.associations = false;
                this.loaders.saveAssociation = false;
                this.switchMode();
            });
        });
    }

    searchAssociation(query?): void {
        this.searchQuery = query;
        const params = HttpHelper.cleanParams({
            q: this.searchQuery
        });

        this.loaders.skills = true;
        this.integrationProvider.getMappingSkills(this.company.id, this.provider.id, params).subscribe((data) => {
            this.loaders.skills = false;
            this.skillsPagination = data.pagination;
            this.associations = data.skills;
        });
    }

    loadMoreSkills(): void {
        const params = HttpHelper.cleanParams({
            q: this.searchQuery,
            page: this.skillsPagination.currentPage + 1
        });

        this.loaders.skills = true;
        this.integrationProvider.getMappingSkills(this.company.id, this.provider.id, params).subscribe((data) => {
            this.loaders.skills = false;
            this.skillsPagination = data.pagination;

            for (const skill of data.skills) {
                this.associations.push(skill);
            }
        });
    }

    redirectToSkillCreation(): void {
        this.router.navigateByUrl(`organize/${this.company.slug}/knowledge`);
    }

    openDeleteModal(association: any): void {
        this.selectedAssociation = association;
    }
}
