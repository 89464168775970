<div>
    <p>{{ 'organize.marketplace.catalogue.modal.content.items.available' | translate : { count: total } }}</p>
    <msc-common-search [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>
    <button class="btn-filter" type="button" (click)="modal.onOpen()">
        <i class="icon icon-options-2"></i>
        <span>{{ 'common.filter' | translate }}</span>
        <ng-container *ngIf="appliedFiltersLength">
            <span class="filter-counter">{{ appliedFiltersLength }}</span>
        </ng-container>
    </button>
</div>

<div>
    <msc-button class="msc-button--primary" [disabled]="!counter" icon="icon-plus-1" (click)="onAdd()">
        {{ 'organize.marketplace.catalogue.modal.content.btn.add' | translate }}
    </msc-button>
</div>

<msc-common-modal #modal size="20vw" [title]="'common.filter' | translate">
    <ng-template>
        <ng-container *ngIf="filters">
            <div class="modal-select">
                <ng-container *ngIf="filters.languages && filters.languages.length">
                    <div class="modal-select-segment">
                        <h6>{{ 'words.language' | translate }}</h6>
                        <msc-common-select type="multi" [data]="filters.languages">
                            <ng-template #buttonTemplate let-data="data">
                                <ng-container *ngIf="data.length; else emptyTemplate">
                                    <div class="selections-content">
                                        <ng-container *ngFor="let datum of data">
                                            <span class="selections">{{ datum.label }}</span>&nbsp;
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-template #emptyTemplate>&nbsp;</ng-template>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <div class="option">
                                    <msc-common2-checkbox type="tick" [value]="datum.isSelected" [label]="datum.label" (changeEvent)="onSet($event, datum, 'languages')"></msc-common2-checkbox>
                                </div>
                            </ng-template>
                        </msc-common-select>
                    </div>
                </ng-container>

                <ng-container *ngIf="filters.types && filters.types.length">
                    <div class="modal-select-segment">
                        <h6>{{ 'words.types' | translate }}</h6>
                        <msc-common-select type="multi" [data]="filters.types">
                            <ng-template #buttonTemplate let-data="data">
                                <ng-container *ngIf="data.length; else emptyTemplate">
                                    <div class="selections-content">
                                        <ng-container *ngFor="let datum of data">
                                            <span class="selections">{{ datum.label }}</span>&nbsp;
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-template #emptyTemplate>&nbsp;</ng-template>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <div class="option">
                                    <msc-common2-checkbox type="tick" [value]="datum.isSelected" [label]="datum.label" (changeEvent)="onSet($event, datum, 'types')"></msc-common2-checkbox>
                                </div>
                            </ng-template>
                        </msc-common-select>
                    </div>
                </ng-container>

                <div class="flex justify-end mt-4 space-x-2">
                    <msc-button (click)="modal.onClose()">{{ 'common.cancel' | translate }}</msc-button>
                    <msc-button class="msc-button--primary" palette="primary" (click)="onFilter(); modal.onClose()">{{ 'common.apply' | translate }}</msc-button>
                </div>
            </div>
        </ng-container>
    </ng-template>
</msc-common-modal>
