<div class="container-integration__body col-xs-12 col-md-9 col-9 no-gutter" [ngClass]="{'col-12 col-md-12': editMode}">
    <div class="content-integration content-integration-mapping box" [ngClass]="{'content-integration-mapping--default': !editMode}">
        <div class="content-integration-mapping__header">
            <h3 class="content-integration-mapping__title">
                {{ 'integrations.mapping.skill-association' | translate }}
            </h3>

            <msc-button class="msc-button--primary" palette="secondary" icon="icon-edit" (click)="switchMode()" *ngIf="!editMode && !provider.companyProvider.deploymentRunning">
                {{ 'words.edit' | translate }}
            </msc-button>
        </div>

        <div class="content-integration-mapping__list" *ngIf="!editMode">
            <div class="flex">
                <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="searchAssociation($event.value)"></msc-common-search>
            </div>

            <div class="content-integration-mapping__list__items empty" *ngIf="associations.length === 0">
                <msc-empty-state [title]="'integrations.no-skill-in-company' | translate"></msc-empty-state>

                <msc-button class="msc-button--primary" palette="secondary" (click)="redirectToSkillCreation()" *ngIf="!provider.companyProvider.deploymentRunning">
                    {{ 'words.create' | translate }}
                </msc-button>
            </div>

            <div class="content-integration-mapping__list__items content-integration-mapping__list__items--limit" *ngIf="!loaders.associations && associations.length > 0">
                <div class="content-integration-mapping__selector__map" *ngFor="let association of associations; let index = index">
                    <div class="block-provider-skill" [ngClass]="{'active': display[index], 'block-provider-skill--disable': provider.companyProvider.deploymentRunning}">
                        <div class="block-provider-skill__header">
                            <div class="block-provider-skill__title">
                                <i *ngIf="association.thematic" class="icon icon-grid"></i>

                                {{ association.name }}
                            </div>

                            <div class="block-provider-skill__content">
                                <div class="block-provider-skill__tags" *ngIf="association.providerSkills?.length > 0">
                                    <div class="skill skill--shadow-none skill--level">
                                        <div class="skill__content" [ngClass]="{'tooltip tooltip--top tooltip--large' : association.providerSkills[0].category?.name.length > 15 || association.providerSkills[0].subcategory?.name.length > 15 }"
                                             [attr.data-tooltip]="association.providerSkills[0].category ? association.providerSkills[0].category.name : association.providerSkills[0].subcategory.name">
                                            <p class="skill__text">
                                                {{ association.providerSkills[0].category ? (association.providerSkills[0].category.name | truncate: 15) : (association.providerSkills[0].subcategory.name | truncate: 15) }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="skill skill--shadow-none skill--level" *ngIf="association.providerSkills?.length > 1 && !display[index]">
                                        <div class="skill__content">
                                            <p class="skill__text">+ {{ association.providerSkills.length - 1 }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="block-provider-skill__actions">
                                    <div class="action-trash">
                                        <i class="icon icon-trash-2" (click)="modalDeleteSkills.onOpen(); openDeleteModal(association)" *ngIf="association.providerSkills?.length > 0"></i>
                                    </div>

                                    <div  class="action-toggle">
                                        <i class="icon icon-arrow-ios-down" (click)="displayed(index)" *ngIf="association.providerSkills?.length > 1"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="block-provider-skill__body">
                            <div class="block-provider-skill__empty"></div>

                            <div class="block-provider-skill__content">
                                <div class="block-provider-skill__tags">
                                    <div class="skill skill--shadow-none skill--level no-cursor" *ngFor="let skill of association.providerSkills | slice: 1">
                                        <div class="skill__content" [tooltip]="skill.category ? skill.category.name : skill.subcategory.name">
                                            <p class="skill__text">
                                                {{ skill.category ? (skill.category.name | truncate: 15) : (skill.subcategory.name | truncate: 15) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <msc-load-more [loading]="loaders.skills" [pagination]="skillsPagination" (onLoad)="loadMoreSkills()"></msc-load-more>

            <msc-load-more [loading]="loaders.associations"></msc-load-more>
        </div>

        <msc-organize-integration-watch-mapping-edit *ngIf="editMode" [provider]="provider" [(mapping)]="mapping"></msc-organize-integration-watch-mapping-edit>

        <div class="flex between content-integration-mapping__actions" *ngIf="editMode">
            <msc-button (click)="switchMode()">
                {{ 'words.cancel' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="secondary" [disabled]="mapping.length === 0" (click)="saveMapping()" [loading]="loaders.saveAssociation">
                {{ 'words.validate' | translate }}
            </msc-button>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #modalDeleteSkills [title]="'words.delete' | translate">
    <ng-template>
        <p>{{ 'integrations.mapping.delete-skills' | translate : {value: selectedAssociation ? selectedAssociation.name : ''} }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalDeleteSkills.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteMapping(); modalDeleteSkills.onClose()">{{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
