import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class CourseProvider {

    constructor(private http: HttpClient) {}

    get(id: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/course/${id}`, {params}).pipe(
            map((data) => data)
        );
    }

    createSubscription(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/course-subscription-sessions`, params).pipe(
            map((data) => data)
        );
    }

    createSectionContentSession(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/session/section-content-sessions`, params).pipe(
            map((data) => data)
        );
    }

    updateSectionContentSessions(id: number, body: any, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/session/section-content-sessions/${id}`, body, {params}).pipe(
            map((data) => data)
        );
    }
}
