import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AdminGroupUserProvider {

    constructor(private http: HttpClient) { }

    getUsers(company_id: string, group_id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/users`, { params }).pipe(
            map(data => data)
        );
    }

    getUsersMeta(company_id: string|number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${company_id}/users/meta`);
    }

    addUser(company_id: string, group_id: number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/users`, data).pipe(
            map(data => data)
        );
    }

    updateUserRole(company_id: string, group_id: number, user_id: number, data: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/users/${user_id}`, data).pipe(
            map(data => data)
        );
    }

    deleteUser(company_id: string, group_id: number, user_id: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/users/${user_id}`).pipe(
            map(data => data)
        );
    }

    updateUserTeam(company_id: string, data) {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${company_id}/users/groups`, data);
    }

    createConversation(companyId: string|number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/conversations`, data);
    }

    sendEmailToUsers(companyId: string|number, data: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/email`, data);
    }

    updateUserExperience(company_id: string, data) {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${company_id}/users/experience`, data);
    }

    // storeUser(campId: number, groupId: number, params: any): Observable<any> {
    //     return this.http.post(`${environment.envVar.API_URL}/camp/${campId}/admin/group/${groupId}/user`, params).pipe(
    //         map(data => data)
    //     );
    // }

    // updateUser(campId: number, groupId: number, params: any): Observable<any> {
    //     return this.http.put(`${environment.envVar.API_URL}/camp/${campId}/admin/group/${groupId}/user`, params).pipe(
    //         map(data => data)
    //     );
    // }

    getAvailableUsers(groupId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/group/${groupId}/users/available`, { params }).pipe(
            map(data => data)
        );
    }

    getUserGroups(company_id: string, user_id: number, params) {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${company_id}/users/${user_id}/groups`, { params });
    }

    updateUserPermission(company_id: string, group_id: number, user_id: number, data: any) {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${company_id}/groups/${group_id}/users/${user_id}/permission`, data)
    }
}
