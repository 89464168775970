export class MediaHelper {

    public static getVideo(url: string): string {
        const vimeo = 'https://player.vimeo.com/video/';
        const id = url.split('//www.vimeo.com/')[1];

        return `${vimeo}${id}?dnt=1`;
    }

    public static createUrl(file: File): any {
        return URL.createObjectURL(file);
    }

    public static getIcon(media: any): string {
        switch (media.type) {
            case 'DOC':
                const url = media.originalUrl || media.url;
                const extension = url ? url.split('.')[1] : 'pdf';
                switch (extension) {
                    case 'ods':
                    case 'xls':
                    case 'xlsx':
                        return 'https://static.myskillcamp.com/images/icon-xls.svg';
                    case 'doc':
                    case 'docx':
                    case 'odt':
                        return 'https://static.myskillcamp.com/images/icon-doc.svg';
                    case 'ppt':
                    case 'pptx':
                    case 'odp':
                        return 'https://static.myskillcamp.com/images/icon-ppt.svg';
                    default:
                        return 'https://static.myskillcamp.com/images/icon-pdf.svg';
                }
            case 'PDF':
                return 'https://static.myskillcamp.com/images/icon-pdf.svg';
            case 'VID':
                return 'https://static.myskillcamp.com/images/icon-media.svg';
            case 'IMG':
                return 'https://static.myskillcamp.com/images/icon-img.svg';
            case 'SND':
                return 'https://static.myskillcamp.com/images/icon-sound.svg';
            case 'TXT':
                return 'https://static.myskillcamp.com/images/icon-txt.svg';
            default:
                return 'https://static.myskillcamp.com/images/icon-media.svg';
        }
    }
}
