import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpHelper } from '@helpers';
import { Company, ManageFollowUpProvider, Pagination, ExternalContentProvider, ReportingProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-manage-instance-follow-up',
    templateUrl: './follow-up.component.html'
})

export class ManageInstanceFollowUpComponent {
    private company: Company;
    private instance: any;
    public users: any;
    public pagination: Pagination;
    public selectedUser: any;
    public query: string;
    public externalContentSession: any;
    public sessions: any[] = [];
    public selectedSession: any;
    public duration: number = 0;
    public displays: any;
    public loading: any;
    public data: any;

    constructor(
        private route: ActivatedRoute,
        private followUpProvider: ManageFollowUpProvider,
        private storageService: StorageService,
        private translateService: TranslateService,
        private toastService: CommonToastService,
        private externalContentProvider: ExternalContentProvider,
        private reportingProvider: ReportingProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.instance = this.storageService.get('content');
        this.users = this.route.snapshot.data.users.accounts;
        this.pagination = this.route.snapshot.data.users.pagination;

        if (this.users.length > 0) {
            this.selectUser(this.users[0]);
        }

        this.resetDisplays();
        this.resetLoading();
        this.resetData();
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query
        });

        this.followUpProvider.getUsers(this.company.id, this.instance.id, params).subscribe((data) => {
            this.users = data.accounts;
            this.pagination = data.pagination;
        });
    }

    loadMore(): void {
        this.loading.loadMore = true;
        const params = HttpHelper.cleanParams({
            page: this.pagination.currentPage + 1
        });

        this.followUpProvider.getUsers(this.company.id, this.instance.id, params).subscribe((data) => {
            this.users = this.users.concat(data.accounts);
            this.pagination = data.pagination;
            this.loading.loadMore = false;
        });
    }

    selectUser(user: any): void {
        this.resetDisplays();
        this.resetLoading();
        this.resetData();
        this.reset();
        this.selectedUser = user;
        this.getLogs();
    }

    displayed(key: string): void {
        this.displays[key] = !this.displays[key];
    }

    displayedDatepickers(index: number): void {
        const enable = !this.displays.datepickers[index];
        this.displays.datepickers = [];
        this.displays.datepickers[index] = enable;
    }

    openModalDeleteLog(session: any): void {
        this.selectedSession = session;
    }

    openModalEditLog(session: any, index: number): void {
        let durationDiff = 0;
        switch (session.timeUnit) {
            case 'days':
                durationDiff = session.durationDiff / (60 * 60 * 24);
                break;
            case 'hours':
                durationDiff = session.durationDiff / (60 * 60);
                break;
            case 'minutes':
                durationDiff = session.durationDiff / 60;
                break;
        }

        this.data.value = durationDiff;
        this.data.unit = session.timeUnit;
        this.data.updateDate = session.time;

        this.displays.modals.edit = [];
        this.displays.modals.edit[index] = true;
    }

    displayedModals(type: string, index: number, enable: boolean = true): void {
        this.displays.modals[type][index] = enable;
    }

    getDuration(type: string, duration?: number): string {
        let time = duration ? Math.floor(duration / 60 % 60) : Math.floor(this.duration / 60 % 60);
        let unit = time > 1 ? this.translateService.instant('words.minutes') : this.translateService.instant('words.minute');

        switch (type) {
            case 'days':
                time = duration ? Math.floor(duration / 86400) : Math.floor(this.duration / 86400);
                unit = time > 1 ? this.translateService.instant('words.days') : this.translateService.instant('words.day');
                break;
            case 'hours':
                time = duration ? Math.floor(duration / 3600 % 24) : Math.floor(this.duration / 3600 % 24);
                unit = time > 1 ? this.translateService.instant('words.hours') : this.translateService.instant('words.hour');
                break;
        }

        return `${time > 0 ? time + ' ' + unit.toLocaleLowerCase() : ''}`;
    }

    convertSessionDuration(session: any): string {
        if (!session.durationDiff) {
            return '';
        }
        const days = Math.floor(session.durationDiff / 86400);
        const hours = Math.floor(session.durationDiff / 3600 % 24);
        const minutes = Math.floor(session.durationDiff / 60 % 60);
        const seconds = Math.floor(session.durationDiff % 60);
        session.timeUnit = seconds > 0 ? 'seconds' : (minutes > 0 ? 'minutes' : (hours > 0 ? 'hours' : (days > 0 ? 'days' : '')));

        return this.getDuration(session.timeUnit, session.durationDiff);
    }

    private getLogs(): void {
        this.reportingProvider.getContentLogs(this.storageService.get('company').id, this.instance.id, this.selectedUser.id, this.instance.type === 'video' ? 'external_video' : 'external_content').subscribe(data => {
            this.sessions = data.map(({ source }) => ({ date: source.date, ...(({ duration, durationDiff }) => ({ duration, durationDiff }))(source.context) }));
            this.duration = this.sessions.length > 0 ? this.sessions[0].duration : 0;
            this.loading.logs = false;

            if (this.sessions.length > 0) {
                this.externalContentSession = this.sessions[0].externalContentSession;
            }
        });
    }

    addLog(): void {
        this.loading.createLog = true;
        let duration = 0;

        switch (this.data.unit) {
            case 'minutes':
                duration = this.data.value * 60;
                break;
            case 'hours':
                duration = this.data.value * 60 * 60;
                break;
            case 'days':
                duration = this.data.value * 60 * 60 * 24;
                break;
        }

        const params = {
            companyId: this.company.id,
            date: this.data.date,
            durationDiff: duration
        };

        this.followUpProvider.addLog(this.company.id, this.instance.id, this.selectedUser.sessionId, params).subscribe(data => {
            this.loading.createLog = false;
            this.sessions.push(data);
            this.duration = data.duration;
            this.toastService.onSuccess(this.translateService.instant('toast.log-created'));
            this.displayed('addTime');
        });
    }

    updateLog(log: any): void {
        let duration = 0;
        this.loading.updateLog = true;

        this.duration -= log.durationDiff;

        switch (this.data.unit) {
            case 'minutes':
                duration = this.data.value * 60;
                break;
            case 'hours':
                duration = this.data.value * 60 * 60;
                break;
            case 'days':
                duration = this.data.value * 60 * 60 * 24;
                break;
        }

        const params = {
            date: this.data.updateDate,
            durationDiff: duration
        };

        this.followUpProvider.updateLog(this.company.id, this.instance.id, this.selectedUser.sessionId, log.id, params).subscribe(data => {
            log.time = this.data.updateDate;
            log.durationDiff = duration;
            this.duration += duration;
            this.loading.updateLog = false;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.displays.modals.edit = [];
        });
    }

    deleteLog(): void {
        this.loading.deleteLog = true;

        this.followUpProvider.deleteLog(this.company.id, this.instance.id, this.selectedUser.sessionId, this.selectedSession.id).subscribe(data => {
            this.sessions = this.sessions.filter((session) => session.id !== this.selectedSession.id);
            this.duration -= this.selectedSession.durationDiff;
            this.toastService.onSuccess(this.translateService.instant('toast.log-deleted'));
            this.loading.deleteLog = false;
        });
    }

    updateDate(date: any): void {
        this.data.date = date;
    }

    updateDateEdit(date: any): void {
        this.data.updateDate = date;
    }

    resetDisplays(): void {
        this.displays = {
            time: true,
            datepicker: false,
            addTime: false,
            datepickers: [],
            modals: {
                edit: []
            }
        };
    }

    resetLoading(): void {
        this.loading = {
            logs: true,
            createLog: false,
            updateLog: false,
            deleteLog: false,
            loadMore: false
        };
    }

    resetData(): void {
        this.data = {
            value: 0,
            unit: 'minutes',
            date: moment().format('YYYY-MM-DD'),
            updateDate: null
        };
    }

    reset(): void {
        this.externalContentSession = null;
        this.selectedSession = null;
    }

    finished(): void {
        const params = {
            progress: 100
        };

        this.externalContentProvider.updateSessionFromManage(this.company.id, this.instance.id, this.externalContentSession.id, params).subscribe(() => {
            this.externalContentSession.doneAt = moment().format('YYYY-MM-DD');
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }
}
