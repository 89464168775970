import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import localeNL from '@angular/common/locales/nl';

export function registerLocales() {
    registerLocaleData(localeDE);
    registerLocaleData(localeEN);
    registerLocaleData(localeES);
    registerLocaleData(localeFR);
    registerLocaleData(localeIT);
    registerLocaleData(localeNL);
}
