<ng-container *ngIf="!controlName; else formTemplate">
    <input type="checkbox" [id]="id" (change)="onChange($event)" [checked]="value" [disabled]="isDisabled">
</ng-container>

<ng-template #formTemplate>
    <input type="checkbox" [id]="id" (change)="onChange($event)" [formControlName]="controlName">
</ng-template>

<label [for]="id" class="{{ type }}"></label>
<ng-container *ngIf="label">
    <label [for]="id" class="description">{{ label }}</label>
</ng-container>
