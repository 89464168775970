import { Component, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JourneyProvider, InstanceProvider, Company, ContentProvider, ExternalContentProvider } from '@lighty';
import { HttpHelper, DataHelper, UrlHelper } from '@helpers';
import { EmitterService, ScriptInjectorService, StorageService, TranslateService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionCollection } from '@classes';
import { CommonToastService } from '@common2/services/toast.service';
import * as _ from 'lodash';
import { environment } from '@env/environment';

const CONTENT_NAVIGATION = (that: LearnDetailComponent, translateService: TranslateService) => {
    return [
        {
            label: translateService.instant('words.presentation'),
            isHidden: false,
            routerLink: that.context !== 'external-content' ? [that.context, that.contextId, 'presentation'] : [that.context, that.type, that.contextId, 'presentation']
        },
        {
            label: translateService.instant('words.table-contents'),
            isHidden: !(that.context !== 'journey' && that.context !== 'external-content' || (that.context === 'journey' && that.subscribed)),
            routerLink: [that.context, that.contextId, 'summary']
        },
        {
            label: translateService.instant('words.corrections'),
            isHidden: !(that.context === 'instance' || (that.context === 'journey' && that.subscribed) && that.hasCorrections),
            routerLink: [that.context, that.contextId, 'corrections']
        },
        {
            label: translateService.instant('words.conversation'),
            isHidden: !(that.enableDiscussion && ((that.context === 'journey' && that.subscribed) || that.context === 'instance')),
            routerLink: [that.context, that.contextId, 'discussion']
        },
        {
            label: translateService.instant('words.activities-log'),
            isHidden: !(that.context === 'external-content' && that.subscribed),
            routerLink: [that.context, that.type, that.contextId, 'result']
        },
    ]
}

@Component({
    selector: 'msc-learn-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})

export class LearnDetailComponent {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private company: Company;
    public background: string = 'https://static.myskillcamp.com/lxp/images/hero-catalog.png';
    public context: string;
    public contextId: number;
    public type: string;
    public message: string;
    public subscribed: boolean = false;
    public saved: boolean = false;
    public onDemand: boolean = false;
    public translation: string;
    public isVisibleSubscriptionMenu: boolean = false;
    public hasCorrections: boolean = false;
    public canUnsubscribe: boolean = true;
    public navigation;
    public account;
    public title: string;
    public isFromSubscriptions: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private check: ChangeDetectorRef,
        private instanceProvider: InstanceProvider,
        private journeyProvider: JourneyProvider,
        private storageService: StorageService,
        private contentProvider: ContentProvider,
        private externalContentProvider: ExternalContentProvider,
        private emitterService: EmitterService,
        private toastService: CommonToastService,
        private cookieService: CookieService,
        private scriptInjectorService: ScriptInjectorService,
        private translateService: TranslateService,
    ) {
        this.scriptInjectorService.platformEmbedly();
    }

    get enableDiscussion(): boolean { return this.getEnableDiscussion(); }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.background = this.company.experience && this.company.experience.cataloguePicture ? this.company.experience.cataloguePicture + '?size=raw' : this.background;
        this.context = this.router.url.split('/')[4];
        this.isFromSubscriptions = (this.context === 'instance' || this.context === 'journey' || this.context === 'external-content') && this.subscribed;

        this.emitterService.get('has-corrections').subscribe((hasCorrections: boolean) => {
            this.hasCorrections = hasCorrections ? hasCorrections : false;
        });

        if (this.context === 'external-content') {
            this.contextId = parseInt(this.router.url.split('/')[6], 10);
            this.type = this.router.url.split('/')[5];
        } else {
            this.contextId = parseInt(this.router.url.split('/')[5], 10);
        }

        this.route.firstChild.params.subscribe(() => {
            if (this.context === 'external-content') {
                this.saved = this.route.firstChild.snapshot.data.externalContent.saved
                this.subscribed = this.route.firstChild.snapshot.data.externalContent.subscribed;
            } else if (this.context === 'journey') {
                this.saved = this.storageService.get('journey') ? this.storageService.get('journey').saved : this.route.firstChild.snapshot.data[this.context].saved;
                this.subscribed = this.storageService.get('journey') ? this.storageService.get('journey').subscribed : this.route.firstChild.snapshot.data[this.context].subscribed;
                this.account = this.storageService.get('journey') ? this.storageService.get('journey').account : this.route.firstChild.snapshot.data[this.context].account;
            } else {
                this.saved = this.route.firstChild.snapshot.data[this.context].saved;
                this.onDemand = this.route.firstChild.snapshot.data[this.context].onDemand;
                this.subscribed = this.route.firstChild.snapshot.data[this.context].subscribed;
                this.account = this.route.firstChild.snapshot.data[this.context].account;
            }
            this.title = this.route.firstChild.snapshot.data[_.camelCase(this.context)].title || 'Untitled';

            if (this.route.firstChild.snapshot.data.summary?.subscription?.isFromGroup) {
                this.canUnsubscribe = false;
            }
            this.setSubscriptionMenuVisibility(this.context, this.subscribed);
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('refreshSubscription').subscribe((data) => {
            if (data && data.subscribed) {
                this.subscribed = data.subscribed;
            }
            this.setSubscriptionMenuVisibility(this.context, this.subscribed);
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('clipboardWhiteURL').subscribe(data => {
            if (typeof data === 'boolean') {
                this.clipboard();
            }
        });

        this.translation = this.getTranslation();
        this.navigation = CONTENT_NAVIGATION(this, this.translateService);

        this.setReferrer();
    }

    getEnableDiscussion() {
        switch (true) {
            case this.route.firstChild.snapshot?.data.hasOwnProperty('instance'): return this.route.firstChild?.snapshot?.data?.instance?.discussionEnabled;
            case this.route.firstChild.snapshot?.data.hasOwnProperty('journey'): return this.route.firstChild?.snapshot?.data?.journey?.discussionEnabled;
            default: return false;
        }
    }

    ngAfterViewChecked(): void {
        this.check.detectChanges();
    }

    ngOnDestroy(): void {
        this.storageService.delete('journey');
        this.subscriptionCollection.unsubscribe();
    }

    deleteContent(data): void {
        if (this.context === 'journey') {
            this.journeyProvider.unsubscribe(this.contextId).subscribe(() => {
                this.router.navigate(['/learn', this.company.slug, 'contents']);
            });
        } else if (this.context === 'instance') {
            const params = HttpHelper.cleanParams({
                message: this.message
            });

            this.instanceProvider.unsubscribe(this.contextId, params).subscribe(() => {
                this.toastService.onSuccess(this.translateService.instant('toast.unregister'));
                this.router.navigate(['/learn', this.company.slug, 'contents']);
            });
        } else {
            if (this.type === 'external_video') {
                const params = {
                    provider: 'external_video'
                };

                this.externalContentProvider.unsubscribeVideo(this.contextId, params).subscribe(() => {
                    this.router.navigate(['/learn', this.company.slug, 'contents']);
                    this.toastService.onSuccess(this.translateService.instant('toast.unregister'));
                });
            } else {
                this.externalContentProvider.unsubscribe(this.contextId).subscribe(() => {
                    this.router.navigate(['/learn', this.company.slug, 'contents']);
                    this.toastService.onSuccess(this.translateService.instant('toast.unregister'));
                });
            }
        }
    }

    navigate(): void {
        if (this.cookieService.get('referrer_content')) {
            this.router.navigateByUrl(this.cookieService.get('referrer_content'));
        } else {
            if (this.isFromSubscriptions) {
                this.router.navigate(['/learn', this.company.slug, 'contents']);
            } else {
                this.router.navigate(['/learn', this.company.slug, 'catalogue']);
            }
        }
    }

    private getTranslation(): string {
        if (this.context === 'instance' || ((this.context === 'journey' || this.context === 'external-content') && this.subscribed)) {
            return 'words.my-courses';
        } else {
            return 'words.catalogue';
        }
    }

    private setSubscriptionMenuVisibility(context: string, subscribed: boolean): void {
        this.isVisibleSubscriptionMenu = (context === 'instance' || ((context === 'journey' || context === 'external-content') && subscribed)) && this.canUnsubscribe;
    }

    clipboard(): void {
        if (this.company.customUrl) {
            if (this.company.customUrl.slice(-1) === '/') {
                DataHelper.clipboard(`${this.company.customUrl}${location.pathname.substring(1)}`);
            } else {
                DataHelper.clipboard(`${this.company.customUrl}${location.pathname}`);
            }
        } else {
            DataHelper.clipboard(location.href);
        }
        this.toastService.onSuccess(this.translateService.instant('toast.link-clipboard'));
    }

    private setReferrer(): void {
        if (this.storageService.get('referrer')) {
            this.cookieService.set('referrer_content', this.storageService.get('referrer'), 10, '/', UrlHelper.getDomain(), false, 'Lax');
        }
    }


    changeLearnLaterContent(): void {
        this.saved = !this.saved;
        let context = '';
        let context_id = 0;

        if(this.route.firstChild.snapshot.data.hasOwnProperty('instance')) {
            context = 'course_template';
            context_id = this.route.firstChild.snapshot.data.instance.id;
        } else if (this.route.firstChild.snapshot.data.hasOwnProperty('journey')) {
            context = 'traject';
            context_id = this.route.firstChild.snapshot.data.journey.id;
        } else if(this.route.firstChild.snapshot.data.hasOwnProperty('external_content')) {
            context = 'external_content';
            context_id = this.route.firstChild.snapshot.data.externalContent.id;
        } else {
            this.emitterService.set('changeLearnLaterContent', this.saved);
        }

        let params = {
            context: context,
            context_id: context_id
        }

        if(this.saved){
            this.contentProvider.addSavedContent(params).subscribe(() => {
                console.log('saving')
                this.toastService.onSuccess(this.translateService.instant('toast.learn-later.saved'));
            });
        } else {
            this.contentProvider.deleteSavedContent(params).subscribe(() => {
                console.log('deleting')
                this.toastService.onSuccess(this.translateService.instant('toast.learn-later.deleted'));
            });
        }
    }

    openConversation(): void {
        this.storageService.set('conversationUsers', [this.account]);
        this.router.navigate(['conversation', 'create']);
    }
}
