<div class="container-integration__body col-xs-12 col-12 no-gutter box">
    <div class="form-filter__header row col-12 flex">
        <div class="form-filter__header__cancel" [routerLink]="getRouterBack()">
            <i class="icon icon-close"></i>
        </div>

        <div class="slide__ariane">
                <span class="slide__ariane__step" *ngFor="let navigation of navigations; let index=index" (click)="onArianeClick(index)"
                      [ngClass]="{ 'disabled': !navigation.unlocked, 'slide__ariane__step--current': index === navigationIndex, 'slide__ariane__step--success': index < navigationIndex}"
                      [tooltip]="navigation.name | translate" direction="bottom">
                </span>
        </div>
    </div>

    <form class="container-integration__form-content form-filter__content row">
        <msc-organize-integration-watch-rules-edit-name-panel [rule]="currentRule" *ngIf="navigationIndex === 0" [(allowNextNavigation)]="allowNextNavigation"></msc-organize-integration-watch-rules-edit-name-panel>

        <msc-organize-integration-watch-rules-edit-accessibility-panel [rule]="currentRule" *ngIf="navigationIndex === 1" (allowNavigation)="checkNavigation($event)"></msc-organize-integration-watch-rules-edit-accessibility-panel>

        <msc-organize-integration-watch-rules-edit-selection-panel [rule]="currentRule" *ngIf="navigationIndex === 2 && subNavigationIndex === 0" (allowNavigation)="checkNavigation($event)"></msc-organize-integration-watch-rules-edit-selection-panel>

        <msc-organize-integration-watch-rules-edit-selection-panel-manual [rule]="currentRule" *ngIf="navigationIndex === 2 && subNavigationIndex === 1 && currentRule.selectionType === 'manual'" (allowNavigation)="checkNavigation($event)"></msc-organize-integration-watch-rules-edit-selection-panel-manual>

        <msc-organize-integration-watch-rules-edit-selection-panel-filtered [rule]="currentRule" *ngIf="navigationIndex === 2 && subNavigationIndex === 1 && currentRule.selectionType === 'filters'" (allowNavigation)="checkNavigation($event)"></msc-organize-integration-watch-rules-edit-selection-panel-filtered>

        <msc-organize-integration-watch-rules-edit-selection-panel-manual [rule]="currentRule" [excludeMode]="true" *ngIf="navigationIndex === 2 && subNavigationIndex === 2 && currentRule.selectionType === 'filters'" (allowNavigation)="checkNavigation($event)"></msc-organize-integration-watch-rules-edit-selection-panel-manual>

        <msc-organize-integration-watch-rules-edit-summary-panel [rule]="currentRule" [navigationIndex]="navigationIndex" [providerName]="provider.name" [navigations]="navigations" *ngIf="navigationIndex === 3"></msc-organize-integration-watch-rules-edit-summary-panel>

        <msc-organize-integration-watch-rules-recap [currentRule]="currentRule" [navigationIndex]="navigationIndex" [navigations]="navigations" [providerName]="provider.name" *ngIf="navigationIndex !== navigations.length -1"></msc-organize-integration-watch-rules-recap>
    </form>

    <div class="form-filter__actions row col-12">
        <div *ngIf="navigationIndex <= 0"></div>

        <msc-button class="msc-button--outline" *ngIf="navigationIndex > 0" (click)="navigateThroughPanelsDown()">
            {{ 'words.back' | translate }}
        </msc-button>

        <div class="flex space-x-2">
            <msc-button class="msc-button--outline" palette="secondary" *ngIf="isEditing && navigationIndex < 1" (click)="renameRule()">
                {{ 'words.rename' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="secondary" *ngIf="navigationIndex < navigations.length - 1" (click)="navigationIndex === 2 && subNavigationIndex === 1 && currentRule.selectionType === 'filters' ? excludeModal.onOpen() : navigateThroughPanelsUp()" [disabled]="!allowNextNavigation">
                {{ 'words.next' | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="secondary" *ngIf="navigationIndex === navigations.length - 1" [disabled]="isLoading" (click)="saveRule()">
                {{ 'words.save' | translate }}
            </msc-button>
        </div>
    </div>
</div>

<msc-common-modal size="30vw" #excludeModal [title]="'organize.experience.watch.mail.list.modal.title' | translate">
    <ng-template>
        <p>{{ 'integrations.exclusion-modal-description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="onExcludeModalCancel(); excludeModal.onClose()">{{'words.no' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="onExcludeModalSave(); excludeModal.onClose()">{{'words.yes' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
