import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Worker service
 */
@Injectable({providedIn: 'root'})
export class WorkerService {
    /**
     * Worker collection
     */
    private workerCollection: any[] = [];

    /**
     * Run worker
     * @param {string} name - Worker's name
     * @param {Function} func - Function using inside worker
     * @param {boolean} listener - Enable listener from this worker
     */
    public run(name: string, func: Function, listener?: boolean): void {
        const url = this.createUrl(func, listener);
        const worker = new Worker(url);
        const item = {name, worker, emitter: new EventEmitter()};
        this.workerCollection.push(item);
        if (listener) {
            this.attachListener(item);
        }
    }

    /**
     * Stop worker
     * @param {string} name - Worker's name
     */
    public stop(name: string): void {
        const worker = this.getWorker(name);
        if (worker) {
            worker.terminate();
            this.deleteWorker(name);
        }
    }

    /**
     * Post a message inside worker
     * @param {string} name - Worker's name
     * @param {any} data - Data
     */
    public post(name: string, data: any): void {
        const worker = this.getWorker(name);
        if (worker) {
            worker.postMessage(data);
        }
    }

    /**
     * Listen worker
     * @param {string} name - Worker's name
     * @return {Observable<any>} Observable
     */
    public listen(name: string): Observable<any> {
        const item = this.workerCollection.find((worker) => {
            return worker.name === name;
        });

        return item ? item.emitter : null;
    }

    /**
     * Create and return url from an object
     * @param {Function} func - Function using inside worker
     * @param {boolean} listener - Enable listener from this worker
     * @return {string} URL
     */
    private createUrl(func: Function, listener?: boolean): string {
        const template = listener ? `self.addEventListener('message', ${func});` : `${func}`;
        const blob = new Blob([template], {type: 'application/json'});

        return URL.createObjectURL(blob);
    }

    /**
     * Get a specific worker
     * @param {string} name - Worker's name
     * @return {Worker} - Worker
     */
    private getWorker(name: string): Worker {
        const item = this.workerCollection.find((worker) => {
            return worker.name === name;
        });

        return item ? item.worker : null;
    }

    /**
     * Delete worker
     * @param {string} name - Worker's name
     */
    private deleteWorker(name: string): void {
        const index = this.workerCollection.findIndex((worker) => {
            return worker.name === name;
        });

        if (index > -1) {
            this.workerCollection.splice(index, 1);
        }
    }

    /**
     * Attach a listener to a worker
     * @param {any} item - Worker
     */
    private attachListener(item: any): void {
        item.worker.addEventListener('message', (event) => {
            item.emitter.emit(event.data);
        });
    }
}
