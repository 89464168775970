import { Component, OnInit } from '@angular/core';
import { IntegrationProvider, Company, Pagination } from '@lighty';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-organize-integration-watch-exception',
    templateUrl: './exception.component.html',
    styleUrls: ['./exception.component.scss']
})
export class OrganizeIntegrationWatchExceptionComponent implements OnInit {
    public company: Company;
    public currLang;
    public provider: any;
    public collection: any;
    displays = {
        filter: {
            skill: false,
        },
        aside: {
            manage: false,
        }
    }
    skillFilterQuery;
    loading: boolean;
    contents: any[] = [];
    pagination: any = new Pagination({
        perPage: 5,
    });
    public query: string;
    public filters: any = {
        contentSkills: [],
        myRules: false
    };
    skillFilterActive = false;
    currentEdit;
    emptyState: boolean = true;
    loadingRules: boolean = true;
    public selectData: any = {
        contentSkills: []
    }

    constructor(private route: ActivatedRoute, private integrationProvider: IntegrationProvider, private storageService: StorageService, ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.currLang = this.storageService.get('me').language.code;
        this.currLang = this.currLang.charAt(0).toUpperCase() + this.currLang.slice(1);


        this.route.data.subscribe(() => {
            this.contents = this.route.snapshot.data.contents.contents.map(this.processContent);
            this.pagination = this.route.snapshot.data.contents.pagination;
            this.provider = this.route.snapshot.parent.parent.data.integration;
            this.collection = this.route.snapshot.parent.parent.data.collections.collections[0];
            this.getRules();
            this.getMeta();
        });
    }

    getRules() {
        this.loadingRules = true;
        this.integrationProvider.getCollectionRules(this.company.id, this.provider.id, this.collection.collectionId).subscribe((data) => {
            this.emptyState = data.rules.length === 0;
            this.loadingRules = false;
        });
    }

    updateContent(content) {
        let contentIndex = this.contents.findIndex((el)=>el.data.id == content.id);
        this.contents[contentIndex].data.skills = content.skills;
        this.contents[contentIndex] = this.processContent(this.contents[contentIndex]);
    }

    setCurrentEdit(content){
        this.currentEdit = {
            companyId: this.company.id,
            providerId: this.provider.id,
            contentId: content.data.id,
            context: content?.data?.type,
            slug: this.company.slug
        };
    }

    openAside(type: string, content): void {
        this.setCurrentEdit(content);
        this.displays.aside[type] = true;
    }

    closeAside(type: string): void {
        this.currentEdit = null;
        this.displays.aside[type] = false;
    }

    displayed(type, key) {
        this.displays[type][key] = !this.displays[type][key];
    }

    getMeta() {
        this.integrationProvider.getProviderContentMeta(this.company.id, this.provider.id).subscribe((meta) => {
            this.filters.contentSkills = meta.contentSkills;
            this.selectData.contentSkills = this.getSelectData(meta.contentSkills);
        });
    }

    getSelectData(data) {
        return data.map(d => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: false
        }));
    }

    onSelectMultiOption(option) {
        option.isSelected = !option.isSelected;

        this.select(option.value);
    }

    loadMore(initial?) {
        let params = HttpHelper.cleanParams({
            q: this.query,
            page: this.pagination && this.pagination.currentPage ? this.pagination.currentPage + 1 : null,
            perPage: this.pagination && this.pagination.perPage ? this.pagination.perPage : null,
            skills: this.filters && this.filters.contentSkills && this.filters.contentSkills.length > 0 ? this.filters.contentSkills.filter(skill => skill.selected).map(skill => skill.id).join() : null,
            rules: this.filters && this.filters.myRules ? 1 : null
        });
        this.loading = true;
        this.integrationProvider.getProviderContents(this.company.id, this.provider.id, params).subscribe((data) => {
            this.contents = [...this.contents, ...data.contents.map(this.processContent)];
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    search(query?) {
        this.query = query;
        let params = HttpHelper.cleanParams({
            q: this.query,
            perPage: this.pagination && this.pagination.perPage ? this.pagination.perPage : null,
            skills: this.filters && this.filters.contentSkills && this.filters.contentSkills.length > 0 ? this.filters.contentSkills.filter(skill => skill.selected).map(skill => skill.id).join() : null,
            rules: this.filters && this.filters.myRules ? 1 : null
        });

        this.loading = true;
        this.integrationProvider.getProviderContents(this.company.id, this.provider.id, params).subscribe((data) => {
            this.contents = data.contents.map(this.processContent);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    processContent = (content) => {
        let getSkillName = (skill) => skill[`name${this.currLang}`] ? skill[`name${this.currLang}`] : skill.name;
        return {
            ...{
                ...content, data: {
                    ...content.data, skills: content.data.skills.map((skill) => ({
                        ...skill,
                        displayName: getSkillName(skill)
                    }))
                }
            },
            remainingSkillsName: content.data.skills.length > 4 ? content.data.skills.slice(4).map(skill => getSkillName(skill)).join(', ') : ''
        }
    }

    select(skill) {
        skill.selected = !skill.selected;
        this.skillFilterActive = this.filters.contentSkills.filter(skill => skill.selected).length > 0;
        this.search();
    }
}
