import { Component, OnInit } from '@angular/core';
import { KnowledgeSkill } from '@lighty';
import { TranslateService } from '@services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'msc-organize-knowledge-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.scss']
})
export class OrganizeKnowledgeWatchComponent implements OnInit {
    public skill: KnowledgeSkill;
    public navData: Array<any>;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.skill = this.route.snapshot.data.skill;
        this.navData = this.getNav();
    }

    /**
     * Get the nav data of the page
     */
    getNav(): Array<any> {
        return [
            {
                label: this.translateService.instant(`words.details`),
                value: {
                    routerLink: 'summary'
                }
            },
            {
                label: this.translateService.instant(`words.contents`),
                value: {
                    routerLink: 'contents'
                }
            },
        ]
    }
}
