import { Component } from '@angular/core';
import { EmitterService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { ActivatedRoute } from '@angular/router';
import { ProviderRule } from '@interfaces';
import { Company, IntegrationProvider, Pagination } from '@lighty';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-integration-watch-rules',
    templateUrl: './rules.component.html'
})

export class OrganizeIntegrationWatchRulesComponent {
    public company: Company;
    public currentProvider: any;
    public collectionId: number;
    public rules: ProviderRule[];
    public pagination: Pagination;
    public currentRule: ProviderRule = {};
    public loading: boolean = false;
    public ruleToDelete: ProviderRule;

    constructor(
        private storageService: StorageService,
        private emitterService: EmitterService,
        private route: ActivatedRoute,
        private integrationProvider: IntegrationProvider,
        private toastService: CommonToastService,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
    ) { }

    private addCampsNames(rule): any {
        if (rule.campsCount > 0 && rule.camps) {
            rule.displayedCampsNames = [];
            for (const camp of rule.camps) {
                rule.displayedCampsNames.push(camp.name);
            }
        }

        return rule;
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.currentProvider = this.storageService.get('currentRuleProvider');

        this.route.parent.data.subscribe((data: any) => {
            this.rules = (data?.rules?.rules || []).map(this.addCampsNames);
            this.pagination = data?.rules?.pagination;
            this.collectionId = data?.collection?.collectionId;
        });
    }

    loadMore(): void {
        this.loading = true;

        const params = {
            page: this.pagination.currentPage + 1
        };

        this.integrationProvider.getCollectionRules(this.company.id, this.currentProvider.id, this.collectionId, params).subscribe((data) => {
            this.rules.push(...data.rules.map(this.addCampsNames));
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    openDeleteModal(rule: any): void {
        this.ruleToDelete = rule;
    }

    deleteRule(): void {
        const collectionId = this.route.snapshot.params.collectionId;
        this.integrationProvider.deleteCollectionRule(this.company.id, this.currentProvider.id, collectionId, this.ruleToDelete.id).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.integration.rule-deleted'));
            const index = this.rules.findIndex(rule => rule.id === this.ruleToDelete.id);
            this.rules.splice(index, 1);
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        });
    }
}
