import { Component, OnInit, OnChanges, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@services';
import { Chart } from 'chart.js';
import * as moment from 'moment';

@Component({
    selector: 'msc-chart-line',
    templateUrl: './chart-line.component.html'
})
export class ChartLineComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('chart', {static: true}) chartComponent: ElementRef;
    @Input() data: any;
    @Input() options: any;
    private active: boolean = false;
    public chart: any;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        if (!this.options) {
            this.options = {
                legend: false,
                responsive: true,
                tooltips: {
                    enabled: false
                },
                hover: {
                    mode: null
                },
                elements: {
                    line: {
                        fill: false,
                        borderColor: '#D3D5E4'
                    },
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    xAxes: [{
                        offset: true,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            fontColor: '#D3D5E4'
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            borderDash: [5, 10],
                            borderDashOffset: 100,
                            drawTicks: false,
                            drawBorder: false
                        },
                        ticks: {
                            stepSize: 10,
                            fontColor: '#D3D5E4'
                        }
                    }]
                }
            };
        }
    }

    ngOnChanges(): void {
        if (this.active) {
            this.chart.destroy();
            this.processChart();
        }
    }

    ngAfterViewInit(): void {
        moment.locale(this.translateService.getCurrentLang());
        this.processChart();
        this.active = true;
    }

    private processChart(): void {
        this.chart = new Chart(this.chartComponent.nativeElement, {
            type: 'line',
            data: this.data,
            options: this.options
        });
    }
}
