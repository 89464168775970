<input  #rdbtn
        type="radio" 
        [id]="id" 
        (change)="onInputChange($event)" 
        [name]="groupName || formControl?.name" 
        [value]="valueName"
        [disabled]="disabled">

<label [for]="id"></label>
<label [for]="id" class="description">{{ label }}</label>
