import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
	selector: 'field-errors',
	templateUrl: './field-errors.component.html'
})
export class FieldErrorsComponent {
	@Input()
	public formField: FormControl;
}