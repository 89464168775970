import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';
import { map, tap, takeUntil, repeatWhen, finalize } from 'rxjs/operators';

import { CompanyProvider, CompanySenderProvider, CompanyExperienceProvider, ApiCompanySenderSubject, ApiCompanySenderSubjectStatus, ApiCompanySenderItem } from '@lighty';
import { StorageService, ExternalAppsService, TranslateService } from '@services';

import { ComponentSmartSimpleModel } from '@models2/component-smart-simple.model';

import { UiCompanySenderItem, getRemappedCompanySenderApiToUi } from '../mail-item/mail-item.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

const CUSTOM_SENDER_DEFAULT_EMAIL: string = 'notifications@myskillcamp.com';
const CUSTOM_SENDER_DEFAULT_NAME: string = 'myskillcamp';

@Component({
    selector: 'msc-organize-experience-watch-mail-list',
    templateUrl: './mail-list.component.html',
    styleUrls: ['./mail-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchMailListComponent extends ComponentSmartSimpleModel<any> {
    public source$: Observable<ApiCompanySenderSubject>;
    public items$: Observable<any[]>;
    public status$: Observable<boolean>;
    public count: number;
    public isBannerVisible: boolean;

    constructor(
        protected readonly ref: ChangeDetectorRef,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly companyProvider: CompanyProvider,
        protected readonly companySenderProvider: CompanySenderProvider,
        protected readonly companyExperienceProvider: CompanyExperienceProvider,
        protected readonly storageService: StorageService,
        protected readonly toastService: CommonToastService,
        protected readonly externalAppsService: ExternalAppsService,
        protected readonly translateService: TranslateService,
    ) {
        super(); // check if getter can work
        this.isBannerVisible = this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.isBannerVisible;
    }

    get email(): string { return this.companyCurrentExperience && this.companyCurrentExperience.sender && this.companyCurrentExperience.sender.email || CUSTOM_SENDER_DEFAULT_EMAIL; }
    get name(): string { return this.companyCurrentExperience && this.companyCurrentExperience.sender && this.companyCurrentExperience.sender.name || CUSTOM_SENDER_DEFAULT_NAME; }
    get lang(): string { return this.getFaqByLang(this.me.language.code); }

    /**
     * Sets the component observables
     */
    setObs(): void {
        this.source$ = this.companySenderProvider
            .getSubjectCompanySender()
            .pipe(takeUntil(this.destroy$));

        this.items$ = this.source$.pipe(map((r: ApiCompanySenderSubject) => this.getSendersRemapped(r.items)));
        this.status$ = this.source$.pipe(map((r: ApiCompanySenderSubject) => r.status !== ApiCompanySenderSubjectStatus.SUCCESS));
    }

    /**
     * Query init data
     */
    onQuery(): void {
        this.onQueryAggregated();
        this.onQueryExperience(); // Bogdan: temp - this must be reworked after we refactor storage services
    }

    /**
     * Query an aggregated call
     * Note: Senders are populated in companySenderProvider.getSenders on tap
     */
    onQueryAggregated(): void {
        forkJoin([
            this.companySenderProvider.getSenders(this.company.id),
            this.companyProvider.getSendersLimit(this.company.slug),
        ])
            .pipe(
                repeatWhen(() => this.repeat$),
                tap(this.onTapQueryAggregated.bind(this)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Query the current experience
     * // Bogdan: temp - this must be reworked after we refactor storage services
     */
    onQueryExperience(): void {
        this.companyExperienceProvider
            .get(this.company.id, this.companyCurrentExperience.id)
            .pipe(
                repeatWhen(() => this.repeat$),
                tap(this.onTapQueryExperience.bind(this)),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     * Get a list of remapped senders
     */
    getSendersRemapped(data: ApiCompanySenderItem[]): UiCompanySenderItem[] {
        return data.map((datum: any) => getRemappedCompanySenderApiToUi(datum, this.companyCurrentExperience));
    }

    /**
     * Get a FAQ link based on the lang set
     */
    getFaqByLang(lang: string): string {
        return `https://support.myskillcamp.com/hc/${lang}/articles/4922037409437-Custom-sender-email-address`;
    }

    /**
     * Resolver for tap of onQueryAggregated
     */
    onTapQueryAggregated([subject, sendersLimit]: [ApiCompanySenderSubject, number]): void {
        this.count = sendersLimit - subject.items.length;
        this.ref.detectChanges();
    }

    /**
     * Resolver for tap of onQueryExperience
     */
    onTapQueryExperience(experience: any): void {
        this.companyCurrentExperience = experience;
        this.ref.detectChanges();
    }

    /**
     * Event handler for activate of url
     */
    onActivate(sender: UiCompanySenderItem): void {
        this.companySenderProvider
            .editExperience(this.company.id, this.companyCurrentExperience.id, { sender_id: sender.id })
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onActivateSuccess(sender),
                error: (error: HttpErrorResponse) => this.onActivateError(error),
            });
    }

    /**
     * Event handler for success on onActivate
     */
    onActivateSuccess(sender: UiCompanySenderItem): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.activate.success'));
        this.repeat$.next();
    }

    /**
     * Event handler for error on onActivate
     */
    onActivateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.activate.error', error.message));
    }

    /**
     * Event handler for quota ask
     */
    onQuota(modal: CommonModalComponent): void {
        this.companySenderProvider
            .editQuote(this.company.id, this.companyCurrentExperience.id, { quote: true })
            .pipe(
                tap(() => this.onNotify()),
                finalize(() => this.onQuotaFinally(modal)),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: () => this.onQuotaSuccess(),
                error: (error: HttpErrorResponse) => this.onQuotaError(error),
            });
    }

    /**
     * Event handler for success on onQuota
     */
    onQuotaSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.quota.success'));
    }

    /**
     * Event handler for error on onQuota
     */
    onQuotaError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.quota.error', error.message));
    }

    /**
     * Event handler for finally on onQuota
     */
    onQuotaFinally(modal: CommonModalComponent): void {
        modal.onClose();
        this.ref.detectChanges();
    }

    /**
     * Event handler for notify sales
     */
    onNotify(): void {
        this.companySenderProvider
            .postNotify(this.company.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.onNotifySuccess(),
                error: (error: HttpErrorResponse) => this.onNotifyError(error),
            });
    }

    /**
     * Event handler for success on onNotify
     */
    onNotifySuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-sender.notify.success'));
    }

    /**
     * Event handler for error on onNotify
     */
    onNotifyError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-sender.notify.error', error.message));
    }

    /**
     * Resolves navigation to create section
     */
    goToCreate(): void {
        this.router.navigate(['..', 'create'], { relativeTo: this.route });
    }

    /**
     * Resolves navigation to details section
     */
    goToDetails(id: number): void {
        this.router.navigate(['..', 'details', id], { relativeTo: this.route });
    }
}
