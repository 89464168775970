import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService, TranslateService } from '@services';
import { BillingProvider, CompanySettings, Company } from '@lighty';
import { UrlHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-company-subscription',
    templateUrl: './subscription.component.html'
})
export class OrganizeCompanySubscriptionComponent implements OnInit {
    private company: Company;
    public settings: CompanySettings;
    public products: any[];
    public addresses: any[] = [];
    public cards: any[] = [];
    public currentPlan: any;
    public selectedCurrency: string = 'eur';
    public selectedPlan: any;
    public selectedAddress: any;
    public selectedCard: any;
    public displayAsides: any;
    public displayModals: any;
    public dropdown: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private billingProvider: BillingProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.settings = this.route.parent.snapshot.data.settings;
        this.products = this.route.snapshot.data.products;

        this.currentPlan = this.selectedPlan = this.products.find((product) => {
            if (product.subscribed) {
                return product;
            }
        });

        this.getAddresses();
        this.getCards();

        this.displayAsides = {
            address: false,
            card: false
        };

        this.displayModals = {
            payment: false
        };
    }

    private getAddresses(): void {
        const params = {
            billable_id: this.company.slug,
            billable_type: 'company'
        };

        this.billingProvider.getAddresses(params).subscribe((data) => {
            this.addresses = data;

            this.addresses.forEach((address) => {
                if (address.default) {
                    this.selectAddress(address);
                }
            });
        });
    }

    private getCards(): void {
        const params = {
            billable_id: this.company.slug,
            billable_type: 'company'
        };

        this.billingProvider.getCards(params).subscribe((data) => {
            this.cards = data;

            this.cards.forEach((card) => {
                if (card.default) {
                    this.selectCard(card);
                }
            });
        });
    }

    selectPlan(product: any): void {
        this.selectedPlan = product;
    }

    isSelectedPlan(product: any): boolean {
        if (!this.selectedPlan) {
            return false;
        }

        return this.selectedPlan.id === product.id;
    }

    openAside(type: string): void {
        this.displayAsides[type] = true;
    }

    closeAside(type: string): void {
        this.displayAsides[type] = false;
    }

    openModal(type: string): void {
        this.displayModals[type] = true;
    }

    closeModal(type: string): void {
        this.displayModals[type] = false;
    }

    createAddress(address: any): void {
        this.billingProvider.createAddress(this.company.slug, 'company', address).subscribe((data) => {
            this.setDefaultAddresses();
            this.addresses.push(data);
            this.closeAside('address');
            this.toastService.onSuccess(this.translateService.instant('toast.address-added'));
        });
    }

    defaultAddress(address: any): void {
        if (!address.default) {
            this.setDefaultAddresses();
            address.default = true;
            this.selectAddress(address);
            this.updateAddress(address);
        }
    }

    updateAddress(address: any): void {
        this.billingProvider.updateAddress(address.id, this.company.slug, 'company').subscribe(() => { });
    }

    deleteAddress(address: any): void {
        this.billingProvider.deleteAddress(address.id, this.company.slug, 'company').subscribe(() => {
            const index = this.addresses.findIndex((data) => {
                return data.id === address.id;
            });

            if (index !== -1) {
                this.addresses.splice(index, 1);
                this.toastService.onSuccess(this.translateService.instant('toast.address-deleted'));
            }
        });
    }

    private setDefaultAddresses(): void {
        this.addresses.forEach((address) => {
            address.default = false;
        });
    }

    private selectAddress(address: any): void {
        this.selectedAddress = address;
    }

    addCard(card: any): void {
        this.setDefaultCards();
        this.cards.push(card);
        this.closeAside('card');
    }

    defaultCard(card: any): void {
        if (!card.default) {
            this.setDefaultCards();
            card.default = true;
            this.selectCard(card);
            this.updateCard(card);
        }
    }

    updateCard(card: any): void {
        this.billingProvider.updateCard(card.id, this.company.slug, 'company').subscribe(() => { });
    }

    deleteCard(card: any): void {
        this.billingProvider.deleteCard(card.id, this.company.slug, 'company').subscribe(() => {
            const index = this.cards.findIndex((data) => {
                return data.id === card.id;
            });

            if (index !== -1) {
                this.cards.splice(index, 1);
                this.toastService.onSuccess(this.translateService.instant('toast.card-deleted'));
            }
        });
    }

    private selectCard(card: any): void {
        this.selectedCard = card;
    }

    private setDefaultCards(): void {
        this.cards.forEach((card) => {
            card.default = false;
        });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    closePlan(): void {
        this.selectedPlan = false;
    }

    redirect(): void {
        UrlHelper.navigate('https://info.myskillcamp.com/prendre-rendez-vous-demo-plateforme-onboarding');
    }

    changePlan(plan: any): void {
        const settings = this.settings;
        settings.usersLimit = plan.allowedUsers;
        this.storageService.set('companySettings', settings);
        this.currentPlan = plan;
        this.closeModal('payment');
    }
}
