import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ViewContainerRef, QueryList, TemplateRef, ContentChildren } from '@angular/core';
import { Subject } from 'rxjs';


interface MyOverlayConfig {
    panelClass?: string
    hasBackdrop?: boolean
    backdropClass?: string
    disableClose?: boolean
    data?: any
    height?: string | number
    width?: string | number
}
const DEFAULT_CONFIG: MyOverlayConfig = {
    hasBackdrop: true,
    width: '35vw',
    height: '100vh'
}
@Component({
    selector: 'msc-common-aside',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonAsideComponent {
    @ContentChildren('headerTemplate') headerTemplate: QueryList<TemplateRef<any>>;
    @ContentChildren('asideTemplate') asideTemplate: QueryList<TemplateRef<any>>;
    @ContentChildren('footerTemplate') footerTemplate: QueryList<TemplateRef<any>>;
    @Input() title: string;
    @Input() hasActionButtons = true;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() openEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('data') data;
    public isOpen = false;
    private _ref;

    get templateHeader(): TemplateRef<any> { return (this.headerTemplate?.toArray() || [])[0]; }
    get templateAside(): TemplateRef<any> { return (this.asideTemplate?.toArray() || [])[0]; }
    get templateFooter(): TemplateRef<any> { return (this.footerTemplate?.toArray() || [])[0]; }

    constructor(
        private overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) { }

    private getOverlayConfig(config: MyOverlayConfig): OverlayConfig {
        const positionStrategy = this.overlay.position()
            .global()
            .right()
            .centerVertically();

        return new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            height: config.height,
            width: config.width,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });
    }

    private createOverlay(config: MyOverlayConfig): OverlayRef {
        const overlayConfig = this.getOverlayConfig(config);

        return this.overlay.create(overlayConfig)
    }

    onSave(){
        this.isOpen = false;
        this._ref.close()
        this.saveEvent.emit();
    }

    onOpen(config?: MyOverlayConfig) {
        this.openEvent.emit();
        this.isOpen = true;
        const dialogConfig = { ...DEFAULT_CONFIG, ...config };
        const overlay = this.createOverlay(dialogConfig);
        this._ref = new CustomOverlayRef(overlay);

        overlay.attach(new TemplatePortal(this.data, this._viewContainerRef));
        overlay.backdropClick().subscribe(() => {
            if (!config?.disableClose) {
                this.onClose();
            }
            this._ref.backdropClick.next(true);
        });

        return this._ref;
    }

    onClose() {
        this.isOpen = false;
        this._ref.close()
        this.closeEvent.emit();
    }
}

export class CustomOverlayRef {

    afterClosed = new Subject<any>();
    backdropClick = new Subject<any>();

    constructor(private overlayRef: OverlayRef) { }

    close(data?: any): void {
        this.overlayRef.dispose();

        this.afterClosed.next(data);
        this.afterClosed.complete();
    }
}
