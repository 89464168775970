<msc-common-aside #aside>
    <ng-template #headerTemplate>
        <div class="flex flex-col space-y-4 relative">
            <msc-button class="absolute right-2 top-2" type="fab" icon="icon-close" (click)="aside.onClose()">
            </msc-button>

            <div class="flex flex-col">
                <div class="flex space-x-2 items-center">
                    <msc-button type="fab" icon="icon-arrow-ios-left" (click)="back()" *ngIf="tab === 'users'">
                    </msc-button>
                    <h3>
                        {{ (tab === 'list') ? ('group.user.list-members' | translate) : 'group.user.add-member' |
                        translate
                        }} :
                        {{ currentGroup.title | truncate: 30 }}
                    </h3>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4 slide-community-members">
            <div class="flex justify-between">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
                    (searchEvent)="search($event.value)"></msc-common-search>

                <msc-button class="msc-button--primary" palette="secondary" icon="icon-plus-circle" *ngIf="tab === 'list'"
                    (click)="changeTab('users')">
                    {{ 'words.add' | translate }}
                </msc-button>
            </div>
            <div class="dynamic-table" *ngIf="tab === 'list'">
                <div class="dynamic-table__head">
                    <div class="dynamic-table__cell cell-title--small">
                        <div>{{'words.name' | translate}}</div>
                    </div>

                    <div class="dynamic-table__cell cell-important cell-large">
                        <div>{{'words.role' | translate}}</div>
                    </div>

                    <div class="dynamic-table__cell cell-large">
                        {{'group.user.registration.date' | translate}}
                    </div>

                    <div class="dynamic-table__cell cell-actions"></div>
                </div>

                <div class="dynamic-table__body">
                    <div class="dynamic-table__row row-radius row-shadow"
                        *ngFor="let member of members; let index = index">
                        <div class="dynamic-table__cell cell-title--small">
                            <div class="avatar-profile">
                                <msc-common-avatar [label]="member.account?.name"
                                    [pictureURL]="member.account?.picture ? (member.account?.picture + '?size=128') : null">
                                </msc-common-avatar>
                                <p>{{ member.account.name | truncate: 20 }}</p>
                            </div>
                        </div>

                        <div class="dynamic-table__cell cell-important cell-large">
                            <div *ngIf="member.account.id !== me.id" class="dropdown-type"
                                [ngClass]="{'active': displayDropdowns.role[index]}" (click)="displayed('role', index)"
                                [(autoClose)]=displayDropdowns.role[index]>
                                <div class="dropdown-type__selected">
                                    <p>{{ 'words.' + member.roles[0].name | translate }}</p>
                                    <i class="icon icon-chevron-down"></i>
                                </div>

                                <div class="dropdown-type__content ">
                                    <ul class="dropdown-type__list">
                                        <li class="dropdown-type__item" *ngFor="let role of roles"
                                            (click)="updateMember(member, role)">
                                            <code>{{ role | json }}</code>
                                            <a>{{ 'words.group_' + role | translate }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <span *ngIf="member.account.id === me.id">{{ 'words.' + (member.role ||
                                member.roles[0].name) | translate }}</span>
                        </div>

                        <div class="dynamic-table__cell cell-large">
                            {{ (member.startAt) ? (member.startAt | date ) : (member.roles[0].createdAt) ?
                            (member.roles[0].createdAt | date ) : '---' }}
                        </div>

                        <div class="dynamic-table__cell cell-actions">
                            <a *ngIf="member.account.id !== me.id" (click)="deleteFromGroup(member)">
                                <i class="cell-actions__icon icon icon-trash"></i>
                            </a>
                        </div>
                    </div>

                    <msc-empty-state *ngIf="(!members || members.length <= 0) && !loadingUsers"
                        [title]="'words.members'" [description]="'empty.nothing.members'">
                    </msc-empty-state>

                    <msc-common-spinner *ngIf="loadingUsers"></msc-common-spinner>
                </div>
            </div>

            <div class="slide__body" *ngIf="tab === 'users'">
                <div class="box box-add-participant flex align-center" *ngFor="let member of availableMembers">
                    <msc-common2-checkbox [value]="isMemberSelected(member.id)" (changeEvent)="addToMember(member.id)">
                    </msc-common2-checkbox>

                    <div class="avatar-contact">
                        <msc-common-avatar class="mr-2" [label]="member.name"
                            [pictureURL]="member.picture ? (member.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="avatar__content">
                            <p class="profile__name text-secondary-800">{{ member.name | truncate: 20}}</p>
                            <div class="profile__job">{{ member.headline | truncate: 20}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <msc-load-more [loading]="loading" [pagination]="(tab === 'list') ? pagination.users : pagination.addUsers"
                (onLoad)="loadMore(tab)">
            </msc-load-more>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div class="flex items-center justify-between grow col-gap-xs " *ngIf="tab === 'users'">
            <msc-common2-checkbox [value]="isAllSelected" [label]="'group.selection-all-users' | translate"
                (changeEvent)="addToMemberAll()"></msc-common2-checkbox>

            <div class="flex space-x-2">
                <msc-button (click)="changeTab('list')">
                    {{ 'words.cancel'| translate }}
                </msc-button>

                <msc-button class="msc-button--primary" palette="secondary" (click)="save(); aside.onClose()">
                    {{ 'words.save'| translate }}
                </msc-button>
            </div>
        </div>
    </ng-template>
</msc-common-aside>
