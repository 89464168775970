import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationProvider } from '@lighty';

@Component({
    selector: 'msc-external',
    template: ''
})
export class ExternalComponent implements OnInit {

    constructor(private route: ActivatedRoute, private router: Router, private integrationProvider: IntegrationProvider) {}

    ngOnInit(): void {
        // this.route.queryParams.subscribe((params: any) => {
        //     if (params.code) {
        //         this.integrationProvider.linkGotowebinar({code: params.code}).subscribe(() => {
        //             this.router.navigate(['account/integrations']);
        //         });
        //     }
        // });
    }
}
