<section class="head">
    <div class="toolbar">
        <div class="flex items-center space-x-2 col-gap-xs">
            <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)"></msc-common-search>

            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                {{ 'words.filters' | translate }}
            </msc-button>

            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>
        </div>
        <div class="flex items-center space-x-2 col-gap-xs">
            <msc-button *ngIf="instanceType == 'traject'" class="msc-button--primary" palette="secondary" icon="icon-cloud-download" (click)="generateReport()">
                {{ 'words.generate-report' | translate }}
            </msc-button>

            <msc-button class="msc-button--outline" [icon]="displays.registered ? 'icon-eye' : 'icon-eye-off'" (click)="displayed('registered')">
                {{ (!displays.registered ? 'manage.instance.learner.hide-unsubscribers' : 'manage.instance.learner.show-unsubscribers') | translate }}
            </msc-button>

            <msc-button class="msc-button--primary" palette="secondary" icon="icon-person-add" (click)="learnerListAside.onOpen()">
                {{ 'words.add' | translate }}
            </msc-button>
        </div>
    </div>
</section>

<section class="body">
    <div class="content">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async">
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <ng-container *ngIf="instance.hasOutdatedScormVersions">
                                <i class="icon icon-alert-triangle color--red mr-1" [tooltip]="'tooltip.scorm-version.warning-generic' | translate"></i>
                            </ng-container>
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)"></msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <div class="wrapper overflow-x-hidden">
                                <msc-common-avatar class="tiny" [label]="datum.avatarLabel" [pictureURL]="datum.avatarUrl"></msc-common-avatar>
                                <span class="ml-2 truncate">{{ datum.avatarLabel }}</span>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.dateActiveSince }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.currentlySubscribed ? ((datum.duration + datum.eventDuration) || 0 | duration) : ('words.unregistered' | translate) }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-chart-pie [value]="datum.progress" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-chart-pie [value]="datum.score" [size]="'small'" [defer]="index * 50"></msc-chart-pie>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.dateLastActivity }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common-select [isButton]="true" [data]="datum.dataSelect">
                                <ng-template #buttonTemplate let-data="data"><span></span></ng-template>
                                <ng-template #optionTemplate let-datum="datum">
                                    <ng-container *ngIf="datum.value.isVisible">
                                        <button class="option wrapper" (click)="datum.value.action()">
                                            <i class="mr-1 icon icon-{{ datum.value.icon }}"></i>
                                            <span>{{ datum.label }}</span>
                                        </button>
                                    </ng-container>
                                </ng-template>
                            </msc-common-select>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'common.empty' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <msc-common-spinner *ngIf="true"></msc-common-spinner>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="hasLoadMore">
        <div class="load-more">
            <msc-button class="msc-button--primary" palette="primary" [loading]="(isLoadingMore$ | async) === 'loading'" (click)="onLoad()">{{ 'words.load-more' | translate }}</msc-button>
        </div>
    </ng-container>
</section>

<msc-manage-instance-learner-list-aside-available
#learnerListAside
[companyId]="company.id"
[instance]="instance"
(onSave)="addLearners($event)">
</msc-manage-instance-learner-list-aside-available>

<msc-shared-aside-mail-user #mailUserAside></msc-shared-aside-mail-user>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>

<msc-common-modal size="30vw" #modalDeleteLearner [title]="'manage.instance.learner.modal.delete.title' | translate">
    <ng-template>
        <p>{{ 'manage.instance.learner.modal.delete.description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="closeModalDelete(); modalDeleteLearner.onClose()">{{'words.no' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="deleteLearner(); modalDeleteLearner.onClose()">{{'words.yes' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
