import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
//
import { Camp, CampProvider, Company } from '@lighty';
import { StorageService, EmitterService, TranslateService } from '@services';
//
import { CommonToastService } from '@common2/services/toast.service';
import { ComponentFormModel } from '@models/component-form.model';
//

@Component({
    selector: 'msc-camp-settings-general',
    templateUrl: './settings-general.component.html',
    styleUrls: ['./settings-general.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CampSettingsGeneralComponent extends ComponentFormModel {
    @Input() patch: any;
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();

    public company: Company;
    public camp: Camp;

    constructor(
        protected readonly fb: FormBuilder,
        protected readonly router: Router,
        protected readonly storageService: StorageService,
        protected readonly campProvider: CampProvider,
        protected readonly toastService: CommonToastService,
        protected readonly emitterService: EmitterService,
        protected readonly translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company')
        this.camp = this.storageService.get('camp');
        this.setFormGroup(this.patch);
    }

    /**
     * Get the form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'name': [this.camp.name],
            'headline': [this.camp.headline],
            'provider': [this.camp.provider],
        });
    }

    /**
     *
     */
    onDelete(): void {
        this.campProvider.deleteCamp(this.camp.id).subscribe(() => {
            const url = `organize/${this.company.slug}/camp`;
            this.router.navigateByUrl(url);
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.emitterService.set('delete.camp', null);
        });
    }

    /**
     *
     */
    onSubmit(): void {
        this.campProvider
            .updateSettings({ ...this.camp, ...this.form.value })
            .pipe()
            .subscribe({
                next: () => this.toastService.onSuccess(this.translateService.instant('toast.saved')),
                error: () => this.toastService.onSuccess(this.translateService.instant('toast.error')),
            })
    }
}
