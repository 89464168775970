<div class="content-account-settings animate">
    <div class="block-settings">
        <div class="block-settings__content">
            <div class="blocks-settings__form">
                <div class="block-settings__input">
                    <label class="block-settings__label">{{ 'words.interface-language' | translate }}</label>
                    <ng-container *ngIf="selectData.interfaceLanguages.length">
                        <msc-common-select [data]="selectData.interfaceLanguages">
                            <ng-template #buttonTemplate let-data="data">
                                <button class="flex items-center text-sm">
                                    <ng-container *ngIf="data?.length">
                                        <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                                        <span>{{ data[0].label }}</span>
                                    </ng-container>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <button class="flex items-center text-sm py-2 w-full" (click)="onSelectOption(datum)">
                                    <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                                    <span>{{ datum.label }}</span>
                                </button>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>

                <div class="block-settings__input">
                    <label class="block-settings__label">{{ 'words.learning-languages' | translate }}</label>
                    <ng-container *ngIf="selectData.contentLanguages.length; else selectLoader">
                        <msc-common-select [data]="selectData.contentLanguages" type="multi" hasSearch="true">
                            <ng-template #buttonTemplate let-data="data">
                                <button class="flex text-sm w-full">
                                    <ng-container *ngIf="data?.length; else emptyTemplate">
                                        <span class="truncate">{{ contentLanguagesSelectedLabel }}</span>
                                    </ng-container>
                                    <ng-template #emptyTemplate>
                                        <span>{{'words.languages' | translate}}</span>
                                    </ng-template>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <label class="flex items-center justify-between text-sm py-2 w-full">
                                    <span>{{ datum.label }}</span>
                                    <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectMultiOption(datum)"></msc-common2-checkbox>
                                </label>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                    <ng-template #selectLoader>
                        <msc-common-spinner></msc-common-spinner>
                    </ng-template>
                </div>
            </div>

            <div class="block-settings__link" *ngIf="!displayPassword && company.mode === 'default'">
                <msc-button palette="secondary" (click)="activePassword()">{{ 'words.update-password' | translate }}</msc-button>
            </div>

            <div class="block-settings__password" *ngIf="displayPassword">
                <div class="block-settings__select">
                    <msc-input [label]="'words.old-password' | translate" type="password" [(ngModel)]="credentials.old_password" [placeholder]="'********'"></msc-input>
                </div>

                <div class="block-settings__select">
                    <msc-input [label]="'words.new-password' | translate" type="password" [(ngModel)]="credentials.password" [placeholder]="'********'" [debounce]="800" (onDebounce)="checkPasswordMatch()"></msc-input>
                </div>

                <div class="block-settings__select">
                    <msc-input [label]="'words.confirm-new-password' | translate" type="password" [(ngModel)]="credentials.password_confirmation" [debounce]="800" (onDebounce)="checkPasswordMatch()" [placeholder]="'words.password' | translate"></msc-input>

                    <p class="color--grey text-error" [ngClass]="{'active' : error.short, 'valid' : valid.short }">
                        <i *ngIf="error.short" class="icon icon-close"></i>
                        <i *ngIf="valid.short" class="icon icon-checkmark-1"></i>
                        {{ "error.password.too-short" | translate }}
                    </p>

                    <p class="color--grey text-error" [ngClass]="{ 'active' : error.number, 'valid': valid.number }">
                        <i *ngIf="error.number" class="icon icon-close"></i>
                        <i *ngIf="valid.number" class="icon icon-checkmark-1"></i>
                        {{ "error.password.number" | translate }}
                    </p>

                    <p class="color--grey text-error" [ngClass]="{ 'active' : error.uppercase, 'valid': valid.uppercase }">
                        <i *ngIf="error.uppercase" class="icon icon-close"></i>
                        <i *ngIf="valid.uppercase" class="icon icon-checkmark-1"></i>
                        {{ "error.password.uppercase" | translate }}
                    </p>

                    <p class="color--grey text-error" [ngClass]="{ 'active' : error.lowercase, 'valid': valid.lowercase }">
                        <i *ngIf="error.lowercase" class="icon icon-close"></i>
                        <i *ngIf="valid.lowercase" class="icon icon-checkmark-1"></i>
                        {{ "error.password.lowercase" | translate }}
                    </p>

                    <p class="color--red text-error" *ngIf="error.confirm && credentials.password_confirmation.length > 0">{{ "error.password.no-match" | translate }}</p>
                    <p class="color--red text-error" *ngIf="error.credential">{{ "error.password.credential" | translate }}</p>
                    <p class="color--red text-error" *ngIf="credentials.password === credentials.old_password && credentials.password.length > 0">{{ "error.password.same" | translate }}</p>
                </div>

                <msc-button class="msc-button--primary" palette="primary" (click)="save()" [disabled]="credentials.old_password === '' || credentials.password === '' || credentials.password !== credentials.password_confirmation || error.number || error.lowercase || error.uppercase">
                    {{ 'words.save' | translate }}
                </msc-button>
            </div>
        </div>
    </div>
</div>
