<msc-common-aside #aside [title]="'skill.add.content' | translate" [hasActionButtons]="false">
    <ng-template #asideTemplate>
        <!--modal pin-->
        <div class="flex flex-col space-y-2">
            <ng-container *ngIf="displays.alert">
                <div class="top-0 absolute z-20 bg-slate-200 p-4 w-full">
                    <p class="text-center text-secondary-600">{{ 'extension.alert' | translate }}</p>

                    <div class="flex justify-center gap-6 mt-4">
                        <a class="text-success-500" (click)="requestEdit()">{{ 'words.yes' | translate }}</a>
                        <a class="text-error-500" (click)="requestEdit(false)">{{ 'words.no' | translate }}</a>
                    </div>
                </div>
            </ng-container>

            <div [ngSwitch]="page" class="grow">
                <div class="slide-external-link__header" *ngIf="page !== 'success'">

                    <div class="slide-external-link__header__controls">
                        <div class="slide-external-link__back">
                            <a><i class="icon icon-arrow-ios-left" (click)="prevPanel()"
                                    *ngIf="page !== 'choice' && page !== 'edit'"></i></a>
                        </div>

                        <div class="slide__ariane">
                            <span class="slide__ariane__step" (click)="onArianeClick('choice')" *ngIf="!isEditing"
                                [ngClass]="{'disabled': page === 'choice' || page === 'camp', 'slide__ariane__step--current': (page === 'choice' || page === 'camps'), 'slide__ariane__step--success': (page !== 'choice' && page !== 'camps')}">
                                <div [tooltip]="'extension.header.title.choice' | translate" direction="bottom"></div>
                            </span>

                            <span class="slide__ariane__step" (click)="onArianeClick('edit')" *ngIf="isEditing"
                                [ngClass]="{'disabled': isDisabled('edit') || page === 'edit', 'slide__ariane__step--current': page === 'edit', 'slide__ariane__step--success': page !== 'edit'}">
                                <div [tooltip]="'extension.header.title.edit' | translate" direction="bottom"></div>
                            </span>

                            <span class="slide__ariane__step" (click)="onArianeClick('form')" *ngIf="!isEditing"
                                [ngClass]="{'disabled': isDisabled('form') || page === 'form', 'slide__ariane__step--current': page === 'form', 'slide__ariane__step--success': page === 'infos' || page === 'summary' || page === 'skills'}">
                                <div [tooltip]="'extension.header.title.form' | translate" direction="bottom"></div>
                            </span>

                            <span class="slide__ariane__step" (click)="onArianeClick('infos')" *ngIf="!isEditing"
                                [ngClass]="{'disabled': isDisabled('infos') || page === 'infos', 'slide__ariane__step--current': page === 'infos', 'slide__ariane__step--success': page === 'skills' || page === 'summary'}">
                                <div [tooltip]="'extension.header.title.infos' | translate" direction="bottom"></div>
                            </span>

                            <span class="slide__ariane__step" (click)="onArianeClick('skills')" *ngIf="!isEditing"
                                [ngClass]="{'disabled': isDisabled('skills') || page === 'skills', 'slide__ariane__step--current': page === 'skills', 'slide__ariane__step--success': page === 'summary'}">
                                <div [tooltip]="'extension.header.title.skills' | translate" direction="bottom"></div>
                            </span>

                            <span class="slide__ariane__step" (click)="onArianeClick('summary')"
                                [ngClass]="{'disabled': isDisabled('summary') || page === 'summary', 'slide__ariane__step--current': page === 'summary'}">
                                <div [tooltip]="'extension.header.title.summary' | translate" direction="bottom"></div>
                            </span>
                        </div>
                    </div>
                </div>

                <msc-shared-aside-extension-choice *ngSwitchCase="'choice'" [(extensionLink)]="extensionLink">
                </msc-shared-aside-extension-choice>

                <msc-shared-aside-extension-community *ngSwitchCase="'community'" [(extensionLink)]="extensionLink">
                </msc-shared-aside-extension-community>

                <msc-shared-aside-extension-camps *ngSwitchCase="'camps'" [(extensionLink)]="extensionLink">
                </msc-shared-aside-extension-camps>

                <msc-shared-aside-extension-form *ngSwitchCase="'form'" [(extensionLink)]="extensionLink"
                    (onChange)="onChangeURL()"></msc-shared-aside-extension-form>

                <msc-shared-aside-extension-infos *ngSwitchCase="'infos'" [(extensionLink)]="extensionLink">
                </msc-shared-aside-extension-infos>

                <msc-shared-aside-extension-skills *ngSwitchCase="'skills'" [(extensionLink)]="extensionLink">
                </msc-shared-aside-extension-skills>

                <msc-shared-aside-extension-summary *ngSwitchCase="'summary'" [(extensionLink)]="extensionLink">
                </msc-shared-aside-extension-summary>

                <msc-shared-aside-extension-success *ngSwitchCase="'success'" [(extensionLink)]="extensionLink"
                    (onCloseAside)="aside.onClose()"></msc-shared-aside-extension-success>

                <div class="slide__content__wrapper" *ngSwitchCase="'edit'">
                    <msc-shared-aside-extension-form [(extensionLink)]="extensionLink" (onChange)="onChangeURL()">
                    </msc-shared-aside-extension-form>

                    <msc-shared-aside-extension-infos [(extensionLink)]="extensionLink">
                    </msc-shared-aside-extension-infos>

                    <msc-shared-aside-extension-skills [(extensionLink)]="extensionLink">
                    </msc-shared-aside-extension-skills>
                </div>
            </div>
        </div>

    </ng-template>
</msc-common-aside>
