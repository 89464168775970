import { Account } from '../../account';
import { Media } from '../../media';

export class ConversationMessage {
    id?: number;
    account: Account;
    content: string;
    createdAt: Date;
    media: Media[];

    constructor(message: object) {
        this.assign(message);
    }

    assign(message: object): void {
        Object.assign(this, message);

        if (this.media && this.media.length > 0) {
            this.media = this.media.map((media) => new Media(media));
        }
    }
}
