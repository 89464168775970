import { Pipe, PipeTransform } from '@angular/core';

/**
 * Add a <br> into a value
 */
@Pipe({
    name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

    transform(value: string): any {
        return value ? value.split('\n').join('<br>') : '';
    }
}
