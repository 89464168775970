import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//
import { AppCommon2Module } from '@common2/common.module';
import { AppCoreModule } from '@core/core.module';
import { SharedModule } from '../../shared.module'; // TEMP
//
import { Organize2RoutingModule } from './organize-routing.module';
// Pages
import { OrganizeMarketplacePageComponent } from './pages/marketplace/organize-marketplace-page.component';
import { OrganizeMarketplaceCataloguePageComponent } from './pages/marketplace-catalogue/organize-marketplace-catalogue-page.component';
import { OrganizeMarketplaceCustomersPageComponent } from './pages/marketplace-customers/organize-marketplace-customers-page.component';
import { OrganizeMarketplaceSettingsPageComponent } from './pages/marketplace-settings/organize-marketplace-settings-page.component';
// Components
import { OrganizeFormMarketplaceCustomersComponent } from './components/form-marketplace-customers/organize-form-marketplace-customers.component';
import { OrganizeFormMarketplaceSettingsComponent } from './components/form-marketplace-settings/organize-form-marketplace-settings.component';
import { OrganizeTableMarketplaceCatalogueComponent } from './components/table-marketplace-catalogue/organize-table-marketplace-catalogue.component';
import { OrganizeTableMarketplaceContentComponent } from './components/table-marketplace-content/organize-table-marketplace-content.component';
import { OrganizeTableMarketplaceCustomersComponent } from './components/table-marketplace-customers/organize-table-marketplace-customers.component';
import { OrganizeToolbarMarketplaceCatalogueComponent } from './components/toolbar-marketplace-catalogue/organize-toolbar-marketplace-catalogue.component';
import { OrganizeToolbarMarketplaceContentComponent } from './components/toolbar-marketplace-content/organize-toolbar-marketplace-content.component';
// ??
import { CommonTdMenuComponent } from './components/td-menu/td-menu.component';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppCommon2Module,
        SharedModule,
        AppCoreModule,
        Organize2RoutingModule,
    ],
    declarations: [
        // Pages
        OrganizeMarketplacePageComponent,
        OrganizeMarketplaceCataloguePageComponent,
        OrganizeMarketplaceCustomersPageComponent,
        OrganizeMarketplaceSettingsPageComponent,
        // Components
        OrganizeFormMarketplaceCustomersComponent,
        OrganizeFormMarketplaceSettingsComponent,
        OrganizeTableMarketplaceCatalogueComponent,
        OrganizeTableMarketplaceContentComponent,
        OrganizeTableMarketplaceCustomersComponent,
        OrganizeToolbarMarketplaceCatalogueComponent,
        OrganizeToolbarMarketplaceContentComponent,
        CommonTdMenuComponent
    ]
})

export class Organize2Module { }
