import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { YoutubeIntegrationProvider } from '../../models/integration';
import { AdminYoutubeIntegrationProvider } from '../../models/admin/integration/youtube';

@Injectable()
export class IntegrationFactory {

    constructor(private http: HttpClient) {}

    get(type: string): any {
        switch (type) {
            case 'youtube-learning': {
                return new YoutubeIntegrationProvider(this.http);
            }
            case 'youtube-admin': {
                return new AdminYoutubeIntegrationProvider(this.http);
            }
        }
    }
}
