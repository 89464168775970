import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChild, TemplateRef, ViewChild } from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';

@Component({
    selector: 'msc-common-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonModalComponent {
    @Input() size?: string = '70vw';
    @Input() title: string;
    @Input() tooltip: {
        icon: string,
        text: string
    };
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() openEvent: EventEmitter<any> = new EventEmitter<any>();
    @ContentChild(TemplateRef) bodyTemplate: TemplateRef<any>;
    @ViewChild('factoryTemplate') factoryTemplate: TemplateRef<any>;

    private dialogRef: DialogRef<any>;

    constructor(
        private readonly cdkDialog: Dialog,
    ) { }

    /**
     * Event handler for close
     */
    onClose(): void {
        this.closeEvent.emit();
        this.dialogRef?.close();
    }

    /**
     * Event handler for open
     */
    onOpen(): void {
        this.openEvent.emit();
        this.dialogRef = this.cdkDialog.open(this.factoryTemplate);
    }
}
