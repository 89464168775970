<div>
    <div class="slide-external-link__body">
        <div class="slide-external-link__centerg">
            <div class="slide-external-link__text">
                <h1>🎉</h1>

                <p class="subtitle">Bravo !</p>
                <p>{{ 'extension.success.text' | translate:{value: extensionLink.title} }}</p>
            </div>

            <div class="flex justify-center mt-10">
                <msc-button class="msc-button--primary" palette="secondary" (click)="onCloseAside.emit()">
                    {{ 'words.close' | translate }}
                </msc-button>

                <msc-button class="msc-button--primary" palette="secondary" (click)="navigate(); onCloseAside.emit()" *ngIf="extensionLink.price">
                    {{ 'extension.buy.content' | translate }}
                </msc-button>
            </div>
        </div>
    </div>
</div>
