<msc-common-aside #aside [title]="'words.reassign-manager' | translate">
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4">
            <div class="grid grid-cols-2 border p-2 gap-2">
                <div class="flex flex-col">
                    <h3>{{ 'words.current-manager' | translate }}</h3>

                    <div class="flex items-center space-x-2 mt-2">
                        <msc-common-avatar class="mr-1" [label]="me?.name"
                            [pictureURL]="me?.picture ? (me?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="overflow-hidden">
                            <p class="truncate">{{ me?.name }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col" *ngIf="selectedManager">
                    <h3>{{ 'words.reassing-to' | translate }}</h3>

                    <div class="flex space-x-2">
                        <msc-common-avatar [label]="selectedManager?.name"
                            [pictureURL]="selectedManager?.picture ? (selectedManager?.picture + '?size=128') : null">
                        </msc-common-avatar>

                        <div class="overflow-hidden">
                            <p class="truncate">{{ selectedManager.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col space-y-2">
                <msc-common-search name="search" trigger="auto" [placeholder]="'common.search' | translate"
                    (searchEvent)="getAvailableGroups($event.value)"></msc-common-search>
                <div class="p-2">
                    <div class="flex flex-col space-y-2">
                        <ng-container *ngFor="let group of availableGroups">
                            <div class="flex space-x-3 border px-3 py-2 items-center"
                                (click)="selectGroup(group)">
                                <msc-common2-radio groupName="availableUser" [ngModel]="data.groupId"
                                    [valueName]="group.id">
                                </msc-common2-radio>

                                <div class="flex space-x-2 items-center overflow-hidden">
                                    <msc-common-avatar [label]="group.teamManager?.account?.name"
                                        [pictureURL]="group.teamManager?.account?.picture ? (group.teamManager?.account?.picture + '?size=128') : null">
                                    </msc-common-avatar>

                                    <p class="truncate">{{ group.teamManager?.account.name }}</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <msc-common-spinner *ngIf="!loaders.ready"></msc-common-spinner>

                    <msc-load-more [loading]="loaders.loadMore" [pagination]="pagination" (onLoad)="loadMore()">
                    </msc-load-more>

                    <span *ngIf="availableGroups && availableGroups.length === 0">
                        {{ 'empty.nothing.members' | translate }}
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div class="flex items-center justify-between flex-grow">
            <msc-common2-checkbox [value]="data.notify" [label]="'words.notify-participants' | translate"
                (changeEvent)="data.notify = !data.notify"></msc-common2-checkbox>

            <div class="flex space-x-2">
                <msc-button (click)="aside.onClose()">
                    {{ 'words.cancel' | translate }}
                </msc-button>
                <msc-button class="msc-button--primary" palette="primary" (click)="save(); aside.onSave()"
                    [loading]="loaders.save">
                    {{ 'words.save' | translate }}
                </msc-button>
            </div>
        </div>
    </ng-template>
</msc-common-aside>