<!--CUSTOM V2 LOGIN RESSET-->
<div id="bg-login" class="min-h-screen flex items-center justify-center login animate h-auto bg-color-primary">

    <div class="my-2 flex p-5">
        <div class=" absolute translate-reset sm-translate-reset">
            <msc-common-select [data]="selectData">
                <ng-template #buttonTemplate let-data="data">
                    <button class="flex align-center" style="border-radius: 0px !important;">
                        <ng-container *ngIf="data?.length">
                            <img class="mr-1 w-[24px]"
                                src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                            <span>{{ data[0].label }}</span>
                        </ng-container>
                    </button>
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <button class="flex align-center py-1" (click)="onSelectOption(datum)">
                        <img class="w-[24px] mr-1"
                            src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                        <span>{{ datum.label }}</span>
                    </button>
                </ng-template>
            </msc-common-select>
        </div>

        <div class="w-1/2  hidden ">
        </div>

        <div class="flex flex-col px-6 content-login"
            style="box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">

            <div class="login__header m-0">
                <div class="login__logo">
                    <div class="login__logo__container mx-auto h-15 w-3/5 " *ngIf="!display.logo"
                        [style.backgroundImage]="'url(https://webservices.griky.co/cdn/img/Griky-Logotipo.webp)'"></div>
                    <div class="login__logo__container mx-auto h-15 w-3/5" *ngIf="display.logo"
                        [style.backgroundImage]="'url(' + display.logo + '?size=512)'"></div>
                </div>
            </div>

            <router-outlet></router-outlet>
        </div>
    </div>
</div>
