import { Component, OnInit, ViewChild } from '@angular/core';
import { Company, ManageTeamProvider } from '@lighty';
import { ActivatedRoute } from '@angular/router';
import { StorageService, TranslateService } from '@services';
import { first } from 'rxjs/operators';
import { CommonInputComponent } from '@common2/components/input/input.component';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-manage-team-settings',
    templateUrl: './settings.component.html'
})
export class ManageTeamSettingsComponent implements OnInit {
    @ViewChild('nameInput') nameInput: CommonInputComponent;
    @ViewChild('modalConfirm') modalConfirm: CommonModalComponent;
    public company: Company;
    public toggle: boolean = false;
    public editMode: boolean = false;
    public team: any = {};
    public profileMedia: any;
    public backgroundMedia: any;
    public loadProfile: boolean = false;
    public loadBackground: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private teamProvider: ManageTeamProvider,
        private translateService: TranslateService,
    ) {
        this.team = this.route.snapshot.data.team;
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.backgroundMedia = this.team.background ? this.team.background.pictureUrl + '?size=1024' : '';
        this.profileMedia = this.team.media ? this.team.media.pictureUrl + '?size=128' : '';
        this.team.backgroundId = this.team.background ? this.team.background.id : null;
        this.team.mediaId = this.team.media ? this.team.media.id : null;
    }

    updateProfileMedia(event: any): void {
        this.loadProfile = true;
        if (event.type === 'finished') {
            this.team.mediaId = event.value.id;
            this.profileMedia = event.value.pictureUrl + '?size=128';
            this.loadProfile = false;
            this.save();
        }

        if (event.type === 'error') {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }


    updateBackgroundMedia(event: any): void {
        this.loadBackground = true;
        if (event.type === 'finished') {
            this.loadBackground = false;
            this.team.backgroundId = event.value.id;
            this.backgroundMedia = event.value.pictureUrl + '?size=128';
            this.save();
        }

        if (event.type === 'error') {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    enableEdit(): void {
        this.editMode = true;
        this.nameInput.focusInput();
    }

    save(): void {
        if (!this.team.timelineVisibility) {
            this.modalConfirm.onOpen();
            // this.modalConfirm.onSave.pipe(first()).subscribe(() => {
            //     this.confirmSave();
            //     this.modalConfirm.close();
            // });
        } else {
            this.confirmSave();
        }
    }

    confirmSave() {
        this.teamProvider.updateGroup(this.company.id, this.team.id, this.team).subscribe(
            (data) => {
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            }, (err) => {
                this.toastService.onError(this.translateService.instant('toast.group.name-already-exists'));
            })
    }
}
