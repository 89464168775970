<div class="mt-8">
<h2> {{ 'dasboard.title' | translate }}</h2>
<h2></h2>
<ng-container *ngIf="selectList.length > 0">
    <h6 class="mt-8"> {{ 'dasboard.select' | translate }}</h6>
    <msc-common-select class="text-sm w-1/4" [data]="selectList" >
       <ng-template #buttonTemplate let-data="data">
           <button class="select-main">
               <ng-container>
                   <span>{{ currentSelection }}</span>
               </ng-container>
           </button>
       </ng-template>
       <ng-template #optionTemplate let-datum="datum">
           <button class="select-option" (click)="loadDashboard(datum.uuid, datum.name)">
               <span>{{ datum.name }}</span>
           </button>
       </ng-template>
   </msc-common-select>
</ng-container>

</div>
<div class="flex justify-center mt-8">
    <msc-common-spinner *ngIf="isLoading"></msc-common-spinner>
</div>

<iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>