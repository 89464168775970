import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ContentProvider,
    Company,
    ExternalContentProvider,
} from '@lighty';
import {
    EmitterService,
    ErrorStreamService,
    StorageService,
    TranslateService
} from '@services';
import { CookieService } from 'ngx-cookie-service';
import { DownloadHelper, UrlHelper } from '@helpers';
import { Subscription, Observable, Subject } from 'rxjs';
import { skip, tap, finalize, take } from 'rxjs/operators';
import moment from "moment";
import { environment } from '@env/environment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-learn-detail-external-content-presentation',
    templateUrl: './presentation.component.html',
})
export class LearnDetailExternalContentPresentationComponent
    implements OnInit, AfterViewInit, OnDestroy {
    private company: Company;
    public externalContent: any;
    public externalContentType: string;
    public externalContentApiCallCopy: () => Observable<any>;
    private repeat$: Subject<void> = new Subject<void>();
    public callToAction: string;
    public dropdowns: any;
    public card: any;
    public contentSections;
    public loading: any = {
        bookboon: false,
        trainingexpress: false,
        hasCard: false,
        sections: false,
    };
    changeLearnLaterContent: Subscription;

    constructor(
        private route: ActivatedRoute,
        private toastService: CommonToastService,
        private contentProvider: ContentProvider,
        private storageService: StorageService,
        private router: Router,
        private externalContentProvider: ExternalContentProvider,
        private translateService: TranslateService,
        private errorStreamService: ErrorStreamService,
        private emitterService: EmitterService,
        private cookieService: CookieService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.externalContent = this.route.parent.snapshot.data.externalContent;
        this.externalContentApiCallCopy =
            this.route.parent.snapshot?.data?.externalContentApiCallCopy;

        this.dropdowns = {
            card: false,
            request: false,
        };

        this.route.parent.params.subscribe((params) => {
            this.externalContentType = params.externalContentContext;
            this.defineCallToActionText();

            if (
                this.externalContent.subscribed &&
                this.externalContentType === 'bookboon' &&
                this.externalContent.type === 'audio'
            ) {
                this.getSections();
            }
        });

        this.changeLearnLaterContent = this.emitterService
            .get('changeLearnLaterContent')
            .pipe(skip(1))
            .subscribe((saved) => {
                if (saved) {
                    this.deleteSavedContent();
                } else {
                    this.addToSavedContents();
                }
            });
    }

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe((params) => {
            const timeOut = setTimeout(() => {
                if (params.direct_play) {
                    this.subscribeOrAction();
                } else if (params.action) {
                    this.doAction();
                }

                clearTimeout(timeOut);
            }, 1000);
        });
    }

    get isButtonLoading() {
        return this.loading.bookboon || this.loading.trainingexpress;
    }

    private defineCallToActionText(): void {
        let translationString;
        if (this.externalContentType == 'bookboon') {
            if (this.externalContent.type === 'audio') {
                translationString = 'words.subscribe';
            } else {
                translationString = 'words.download';
            }
        } else {
            translationString = this.externalContent.subscribed
                ? 'words.access-content'
                : 'words.subscribe';
        }
        this.callToAction = this.translateService.instant(translationString);
    }

    private download(): void {
        this.loading.bookboon = true;
        this.errorStreamService.locked();
        this.toastService.onSuccess(this.translateService.instant('toast.downloading.soon'));
        this.externalContentProvider
            .download(this.externalContent.id)
            .subscribe(
                (data) => {
                    DownloadHelper.downloadBlob(
                        data,
                        this.externalContent.title
                    );
                    this.toastService.onSuccess(this.translateService.instant('toast.bookboon.download-success'));
                    this.emitterService.set('player-light.open', {
                        type: 'external',
                        content: this.externalContent,
                        more: this.externalContent,
                        progressEnabled: this.externalContent.provider
                            ? this.externalContent.provider.progressEnabled
                            : true,
                    });
                },
                () => {
                    this.errorStreamService.unlocked();
                    this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                },
                () => {
                    this.loading.bookboon = false;
                }
            );
    }

    private doAction(): void {
        switch (this.externalContentType) {
            case 'bookboon':
                if (this.externalContent.type === 'audio') {
                    this.getSections();
                } else {
                    this.download();
                }
                break;
            case 'external_video':
                this.openExternalUrl('video');
                break;
            default:
                // only if udemy content and custom URL
                if(
                    (this.externalContent?.url?.includes('udemy.com')
                        || this.externalContent?.url?.includes('linkedin.com')
                        || this.externalContent?.url?.includes('contentraven.com')
                        || this.externalContent?.url?.includes('learninghubz.com')
                        || this.externalContent?.url?.includes('coursera.org')
                        || this.externalContent?.url?.includes('platzi.com')
                        )
                    && `${UrlHelper.getHost()}/` !== environment.envVar.APP_URL.replace('https://', '').replace('http://', '')){
                    this.externalContentProvider.storeUserToken(this.externalContent.url)
                        .subscribe((token:string)=>{
                            UrlHelper.navigate(`${environment.envVar.CLOUD_URL}/authenticated-redirect?token=${token}`);
                    })
                } else {
                    this.openExternalUrl('external');
                }

                break;
        }

        this.defineCallToActionText();
        this.emitterService.set('refreshSubscription', {
            subscribed: this.externalContent.subscribed,
        });
    }

    private getSections(): void {
        this.loading.sections = true;
        this.externalContentProvider
            .getExternalContentSections(this.externalContent.id)
            .subscribe(
                (data) => {
                    this.contentSections = data.sections;
                    this.loading.sections = false;
                },
                () => {
                    this.loading.sections = false;
                }
            );
    }

    private getNewUrl() {
        return this.externalContentProvider
            .generateUrl({
                company_id: this.company.id,
                provider_id: this.externalContent.provider.id,
                context_id: this.externalContent.id,
                context_type: 'external_content',
            })
            .pipe(
                take(1),
                tap(({ url }) => {
                    this.externalContent.url = url;
                    UrlHelper.navigate(url || this.externalContent.url);
                }),
                finalize(() => {
                    this.loading.trainingexpress = false;
                })
            );
    }

    public openExternalUrl(type, url?) {
        if (this.externalContent.requireUrlGeneration) {
            this.loading.trainingexpress = true;
            this.getNewUrl().subscribe();
        } else {

            UrlHelper.navigate(url || this.externalContent.url);

            this.emitterService.set('player-light.open', {
                type: type,
                content: { ...this.externalContent, type: type },
                more: { ...this.externalContent, type: type },
                progressEnabled: this.externalContent.provider
                    ? this.externalContent.provider.progressEnabled
                    : true,
            });
        }
    }

    private subscribeAndDoAction(): void {
        if (this.externalContentType === 'external_video') {
            this.externalContentProvider
                .subscribeVideo(this.externalContent.id, this.externalContent)
                .subscribe((data) => {
                    if (data) {
                        this.externalContent.subscribed = true;
                    }
                    this.doAction();
                });
        } else {
            this.externalContentProvider
                .subscribeContent(this.externalContent.id)
                .subscribe((data) => {
                    if (data) {
                        this.externalContent.subscribed = true;
                    }
                    this.doAction();
                });
        }
    }

    addToSavedContents(): void {
        this.externalContent.saved = true;

        const params = {
            context: 'external_content',
            context_id: this.externalContent.id,
        };

        this.contentProvider.addSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.saved'));
        });
    }

    deleteSavedContent(): void {
        this.externalContent.saved = false;

        const params = {
            context: 'external_content',
            context_id: this.externalContent.id,
        };

        this.contentProvider.deleteSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.deleted'));
        });
    }

    subscribeOrAction(): void {
        this.externalContent.subscribed
            ? this.doAction()
            : this.subscribeAndDoAction();
    }

    colorType(): string {
        switch (this.externalContentType) {
            case 'bookboon':
                return 'orange';
            case 'resource':
                switch (this.externalContent.type) {
                    case 'course':
                        return 'purple';
                    case 'video':
                        return 'blue';
                    case 'article':
                        return 'orange';
                    case 'other':
                        return 'red';
                }
                break;
            default:
                return 'purple';
        }
    }

    openDropdowns(type: string): void {
        this.dropdowns[type] = !this.dropdowns[type];
    }

    changeComma(value: string): void {
        value = value.replace(',', '.');
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    ngOnDestroy(): void {
        this.changeLearnLaterContent.unsubscribe();
        this.repeat$.complete();
    }
}
