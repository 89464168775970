import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { environment } from '@env/environment';
import { ReportingProvider } from '../..';
import { StorageService } from '@services';

@Injectable()
export class ManageLearnerProvider {

    constructor(private http: HttpClient, private reportingProvider: ReportingProvider, private storageService: StorageService) {}

    get(id: string | number, instanceId: number, params?: any): Observable<any> {
        const response = {
            learners: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/learners`, { params }).pipe(
            concatMap(
                (data: any) => this.reportingProvider.getContentSubscriptionOverview(this.storageService.get('company').id, instanceId, data.learners.map(learner => learner.accountId), params.context).pipe(map(res => {
                    data.learners = data.learners.map((learner) => ({...learner, ...{ progress: 0, eventDuration: 0, duration: 0, score: 0 }, ...res[learner.accountId]?.context }))
                    return data;
                }),
                    catchError(() => of(data)))
            ),
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.learners = data.learners;

                return response;
            })
        );
    }

    getAvailableLearners(id: string | number, instanceId: number, params?: any): Observable<any> {
        const response = {
            available: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/available-learners`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.available = data.available;

                return response;
            })
        );
    }

    addLearners(id: number, instanceId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/learners`, params).pipe(
            map(data => data)
        );
    }

    delete(id: string | number, instanceId: number, subscriptionId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/learners/${subscriptionId}`, { params }).pipe(
            map(data => data)
        );
    }

    public generateReports(company_id: any, traject_id: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${company_id}/manage/traject/${traject_id}/learning-path-report`).pipe(
            map(data => data)
        );
    }
}
