<div class="idp-container">
    <msc-common-select [data]="selectData">
        <ng-template #buttonTemplate let-data="data">
            <button class="flex align-center">
                <ng-container *ngIf="data?.length">
                    <img class="mr-1 w-[24px]"
                        src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                    <span>{{ data[0].label }}</span>
                </ng-container>
            </button>
        </ng-template>
        <ng-template #optionTemplate let-datum="datum">
            <button class="flex align-center py-1" (click)="onSelectOption(datum)">
                <img class="w-[24px] mr-1"
                    src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                <span>{{ datum.label }}</span>
            </button>
        </ng-template>
    </msc-common-select>

    <div class="idp-container__header">
        <div class="flex justify-center">
            <div class="idp-container__header__logo"
                [style.backgroundImage]="'url(https://webservices.griky.co/cdn/img/Griky-Logotipo.webp)'"></div>
        </div>
    </div>

    <div class="idp-container__body flex flex-col gap-4 text-center">
        <ng-container *ngIf="loggedIn">
            <i class="icon icon-checkmark-circle text-5xl text-success-500"></i>
            <div>
                <h2>Logged in</h2>
                <small>Redirecting...</small>
            </div>
        </ng-container>
        <ng-container *ngIf="!loggedIn">
            <h2 class="text-center">
                {{ 'login.connect' | translate }}
            </h2>

            <form class="flex flex-col space-y-4" (ngSubmit)="login()">
                <msc-input name="email" id="login" autocomplete="on" [label]="'words.login-id' | translate" type="text"
                    [(ngModel)]="credentials.email"></msc-input>

                <div>
                    <msc-input name="password" id="password" [label]="'words.password' | translate" type="password"
                        [(ngModel)]="credentials.password" [debounce]="500" (onDebounce)="trimPassword()"></msc-input>

                    <a class="link" (click)="passwordForgotten()">
                        {{ 'words.password-forgotten' | translate }}
                    </a>
                </div>
                <div class="flex justify-center">
                    <msc-button htmlType="submit" class="msc-button--primary" [loading]="loading" palette="primary"
                        [disabled]="!credentials.email || !credentials.password">{{ 'words.login' | translate }}
                    </msc-button>
                </div>
            </form>
        </ng-container>
    </div>
</div>