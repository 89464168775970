import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ManageAttendanceProvider, Pagination, ManageLearnerProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { HttpHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-manage-instance-agenda-aside-attendance',
    templateUrl: './aside-attendance.component.html',
    styleUrls: ['./aside-attendance.component.scss']
})

export class ManageInstanceAgendaAsideAttendanceComponent {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();

    private instance: any;
    private company: any;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public accountsMissing: number[] = [];
    public attendancesList: any = [];
    public learnersSelected: any[] = [];
    public usersToAdd: any[] = [];
    public filteredAttendances: any[] = [];
    public periodTypeSelected: string;
    public query: string;
    public queryAttendee: string;
    public tab: string = 'periods';
    public periodId: number;
    public loadingAttendancesList: boolean = false;
    public isUsersNotified: boolean = false;
    public loadingPeriod: boolean = false;
    public learnerAll: boolean = false;
    public missingAll: boolean = false;
    public inviteAll: boolean = false;
    public pagination: Pagination;
    public availableLearners: any;
    public selectedPeriod: any;
    public loaders: any;
    public event: any;

    constructor(
        private attendanceProvider: ManageAttendanceProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
        private learnerProvider: ManageLearnerProvider,
    ) { }

    ngOnInit(): void {
    }

    onOpen(event) {
        this.event = event;
        this.aside.onOpen();
        this.company = this.storageService.get('company');
        this.instance = this.storageService.get('content');
        this.usersToAdd = [];
        this.accountsMissing = [];
        this.loaders = {
            ready: false,
            loadMore: false,
            save: false
        };
        this.tab = 'periods';
        if (!this.event.periodConfirmed) {
            this.tab = 'settings';
        }
        this.getAttendancesList();
    }

    searchAttendances(query?) {
        this.queryAttendee = query;
        const value = this.queryAttendee.toLowerCase().trim();
        this.filteredAttendances = this.selectedPeriod.attendances.filter(att => att.account.name.toLowerCase().includes(value));
    }

    getStateTitleTranslation(): string {
        switch (this.tab) {
            case 'periods':
                return this.translateService.instant('manage.instance.event.attendance.window.periods.v2', { eventName: this.event.title || this.translateService.instant('words.untitled') });
            case 'period':
                return this.translateService.instant('manage.instance.event.attendance.window.period.v2');
            case 'settings':
                if (this.event.periodConfirmed) {
                    return this.translateService.instant('manage.instance.event.attendance.window.settings.v2.helper');
                } else {
                    return this.translateService.instant('manage.instance.event.attendance.window.settings.v2');
                }
            case 'add-user':
                return this.translateService.instant('manage.instance.event.attendance.window.register-new-participants', { eventName: this.event.title || this.translateService.instant('words.untitled') });
        }
    }

    getAvailableLearners(): void {
        const params = HttpHelper.cleanParams({
            q: this.query,
            context: this.instance.type
        });

        this.learnerProvider.getAvailableLearners(this.company.id, this.instance.id, params).subscribe((data) => {
            this.availableLearners = data.available;
            this.pagination = data.pagination;
            this.loaders.ready = true;
        });
    }

    getAttendancesList(): void {
        this.loadingAttendancesList = true;

        const params = HttpHelper.cleanParams({
            context: this.instance.type,
        });

        this.attendanceProvider.getPeriods(this.company.id, this.instance.id, this.event.id, params).subscribe((data) => {
            this.attendancesList = data.periods;
            this.loadingAttendancesList = false;
        });
    }

    getAttendanceList(): void {
        this.loadingPeriod = true;

        const params = HttpHelper.cleanParams({
            context: this.instance.type,
        });

        this.attendanceProvider.getPeriod(this.company.id, this.instance.id, this.event.id, this.periodId, params).subscribe((data) => {
            this.selectedPeriod = { attendances: [], ...data };
            this.loadingPeriod = false;
        });
    }

    setSelectedPeriod(periodId: any): void {
        this.periodId = periodId;
        this.loadingPeriod = true;
        const params = HttpHelper.cleanParams({
            context: this.instance.type,
        });

        this.attendanceProvider.getPeriod(this.company.id, this.instance.id, this.event.id, periodId, params)
            .subscribe((data) => {
                this.selectedPeriod = { attendances: [], ...data };;
                this.tab = (this.event.periodConfirmed === 0 && this.event.type !== 'virtual-class') ? 'settings' : 'period';

                if (this.selectedPeriod.attendances && this.selectedPeriod.attendances.length > 0) {
                    this.selectedPeriod.attendances.forEach((attendance) => {
                        if (attendance.status === 0) {
                            this.accountsMissing.push(attendance.account.id);
                        }
                    });
                }

                this.filteredAttendances = [...this.selectedPeriod.attendances];
                this.missingAll = this.accountsMissing.length == 0;
                this.loadingPeriod = false;
            });
    }

    updatePeriod(): void {
        const params = HttpHelper.cleanParams({
            context: this.instance.type,
            missing: this.accountsMissing
        });

        this.attendanceProvider.updatePeriod(this.company.id, this.instance.id, this.event.id, this.selectedPeriod.id, params)
            .subscribe(() => {
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
                this.close();
            });
    }

    updatePeriodsSettings(type: string): void {
        this.periodTypeSelected = type;
    }

    confirmUpdatePeriodsSettings(): void {
        this.event.periodType = this.periodTypeSelected;
        this.event.periodConfirmed = 1;
        const params = HttpHelper.cleanParams({
            context: this.instance.type,
            periodType: this.event.periodType
        });

        this.attendanceProvider.updatePeriodsSettings(this.company.id, this.instance.id, this.event.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.getAttendancesList();
            this.tab = 'periods';
        });
    }

    changeTab(tab: string): void {
        this.tab = tab;

        if (this.tab === 'add-user') {
            this.getAvailableLearners();
        }
    }

    backAsideState(): void {
        if (this.tab === 'settings') {
            this.tab = 'periods';
        } else if (this.tab === 'period') {
            this.tab = 'periods';
        } else if (this.tab === 'add-user') {
            this.learnersSelected = [];
            this.tab = 'period';
        } else if (this.tab === 'periods') {
            this.close();
        }
    }

    addToLearnerAll(): void {
        this.learnerAll = !this.learnerAll;
        this.learnersSelected = [];
        if (this.learnerAll) {
            const learners = this.availableLearners;
            for (const learner of learners) {
                this.addToLearner(learner);
            }
        }
    }

    addToMissingAll(): void {
        this.missingAll = !this.missingAll;
        this.accountsMissing = [];
        if (!this.missingAll) {
            const attendees = this.selectedPeriod.attendances;
            for (const attendee of attendees) {
                this.addToMissing(attendee);
            }
        }
    }

    addToMissing(attendee: any): void {
        const index = this.accountsMissing.findIndex((id) => {
            return id === attendee.account.id;
        });

        if (index === -1) {
            this.accountsMissing.push(attendee.account.id);
        } else {
            this.accountsMissing.splice(index, 1);
        }
        this.missingAll = this.accountsMissing.length == 0;
    }

    isInMissing(attendee: any): boolean {
        return this.accountsMissing.findIndex((id) => {
            return id === attendee.account.id;
        }) > -1;
    }

    loadMore(): void {
        this.loaders.loadMore = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            context: this.instance.type,
            page: this.pagination.currentPage + 1
        });

        this.learnerProvider.getAvailableLearners(this.company.id, this.instance.id, params).subscribe((data) => {
            this.availableLearners = this.availableLearners.concat(data.available);
            this.pagination = data.pagination;
            this.loaders.loadMore = false;
        });
    }

    addToLearner(learner: any): void {
        const index = this.learnersSelected.findIndex((learnerSelected) => learnerSelected === learner);
        if (index > -1) {
            this.learnersSelected.splice(index, 1);
        } else {
            this.learnersSelected.push(learner);
        }
    }

    isLearnerSelected(learner: any): boolean {
        return this.learnersSelected.indexOf(learner) > -1;
    }

    isUsersNotify(): void {
        this.isUsersNotified = !this.isUsersNotified;
    }

    close(): void {
        if (this.tab === 'add-user') {
            this.learnersSelected = [];
        }
        this.onClose.emit(true);
    }

    save(): void {
        if (this.tab === 'period') {
            this.updatePeriod();
        } else if (this.tab === 'add-user') {
            const params = {
                context: this.instance.type,
                inviteAll: this.inviteAll,
                accountIds: this.learnersSelected.map((learner) => learner.accountId),
                notify: this.isUsersNotified
            };
            this.learnerProvider.addLearners(this.company.id, this.instance.id, params).subscribe(() => {
                this.toastService.onSuccess(this.translateService.instant('toast.saved'));
                this.backAsideState();
                this.getAttendanceList();
                this.accountsMissing = [];
            });
        } else if (this.tab === 'settings') {
            this.updatePeriodsSettings('one_per_day');
        } else {
            this.onSave.emit();
        }
    }
}
