import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-manage-instance-dashboard',
    templateUrl: './dashboard.component.html'
})
export class ManageInstanceDashboardComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
