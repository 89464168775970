<msc-common-aside #aside [title]="'manage.instance.event.edit.title' | translate">
    <ng-template #asideTemplate>
        <div class="slide__body slide-update-calendar">
            <div class="slide__form">
                <div class="slide__input">
                    <msc-input name="input" [label]="'words.title' | translate" [(ngModel)]="clonedEvent.title"
                        [disabled]="readOnly" [placeholder]="'placeholder.title' | translate"></msc-input>
                </div>

                <div class="slide__input">
                    <div class="">
                        <div class="select__label">{{ 'manage.instance.event.edit.select-date' | translate }}</div>

                        <div class="select__date">
                            <div class="date">
                                <p class="date__start">{{ 'words.start-date' | translate }}</p>

                                <a class="date__anchor" (click)="readOnly ? null : openDateRangePicker()">
                                    <div class="tag tag--date">
                                        <div class="tag__text">
                                            <span class="text__day">{{ startDate | date: 'dd' }}</span>
                                            <span class="text__month">{{ startDate | localizedDate:'MMM' }}</span>
                                            <span class="text__year">{{ startDate | date: 'YYYY' }}</span>
                                        </div>
                                    </div>
                                </a>

                                <div class="date__icon">
                                    <i class="icon icon-calendar"></i>
                                </div>

                                <msc-input name="input" [(ngModel)]="startHour" [disabled]="readOnly"></msc-input>
                            </div>

                            <div class="date">
                                <p class="date__start">{{ 'words.end-date' | translate }}</p>
                                <a class="date__anchor" (click)="readOnly ? null : openDateRangePicker()">
                                    <div class="tag tag--date">
                                        <div class="tag__text">
                                            <span class="text__day">{{ endDate | date: 'dd' }}</span>
                                            <span class="text__month">{{ endDate | localizedDate:'MMM' }}</span>
                                            <span class="text__year">{{ endDate | date: 'YYYY' }}</span>
                                        </div>
                                    </div>
                                </a>

                                <div class="date__icon">
                                    <i class="icon icon-calendar"></i>
                                </div>

                                <msc-input name="input" [(ngModel)]="endHour" [disabled]="readOnly"></msc-input>
                            </div>
                        </div>

                        <msc-daterangepicker *ngIf="dateRangePicker" [overlay]="true" [class]="'daterangepicker--menu'"
                            [(dates)]="dates" (onClose)="openDateRangePicker()" (onSave)="saveDates()">
                        </msc-daterangepicker>
                    </div>
                </div>

                <div class="slide__input" *ngIf="clonedEvent.type === 'place'">
                    <msc-input icon="icon-pin" [label]="'words.address' | translate" name="input"
                        [placeholder]="'placeholder.address' | translate" [(ngModel)]="clonedEvent.address"
                        [disabled]="readOnly"></msc-input>
                </div>

                <div class="slide__input flex between align-end" *ngIf="clonedEvent.type !== 'place'">
                    <msc-input icon="icon-link" [label]="'words.visio-link' | translate" name="input"
                        [placeholder]="'placeholder.visio' | translate" [(ngModel)]="clonedEvent.link"
                        [disabled]="readOnly"></msc-input>
                </div>

                <div class="slide__input">
                    <msc-input [label]="'words.description' | translate" name="input"
                        [placeholder]="'placeholder.description' | translate" [(ngModel)]="clonedEvent.description"
                        [disabled]="readOnly">
                    </msc-input>
                </div>

                <div class="slide__input">
                    <msc-common2-checkbox [value]="clonedEvent.isAttendanceMandatory"
                        [label]="'words.presence-mandatory' | translate"
                        (changeEvent)="clonedEvent.isAttendanceMandatory = $event;"
                        [tooltip]="'words.presence-mandatory.tooltip' | translate" direction="left"
                        [isDisabled]="readOnly"></msc-common2-checkbox>
                </div>

                <div class="slide__input">
                    <div #selectTrainers>
                        <div class="inline-flex items-center text-sm text-black-200 space-x-1 cursor-pointer hover:text-secondary-500"
                            *ngIf="!readOnly" (click)="overlayTrainers.open(selectTrainers)">
                            <i class="icon icon-plus-circle text-secondary-500 text-lg"></i>
                            <span>{{ 'words.trainers' | translate }}</span>
                        </div>
                        <msc-common-overlay #overlayTrainers>
                            <ng-template>
                                <div class="p-2 min-w-[300px]">
                                    <msc-common-search class="tiny" trigger="auto"
                                        [placeholder]="'common.search' | translate"
                                        (searchEvent)="searchTrainers($event.value)"></msc-common-search>

                                    <ng-container *ngFor="let trainer of availableTrainers">
                                        <div class="flex justify-between items-center text-sm p-2">
                                            <div class="flex items-center space-x-2">
                                                <msc-common-avatar class="tiny" [label]="trainer.name"
                                                    [pictureURL]="trainer.picture ? (trainer.picture + '?size=64') : null">
                                                </msc-common-avatar>
                                                <span>{{ trainer.name }}</span>
                                            </div>

                                            <msc-button class="msc-button--primary" type="fab" palette="primary"
                                                icon="icon-plus" (click)="addTrainer(trainer)"></msc-button>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </msc-common-overlay>
                    </div>

                    <div class="former-tag" *ngIf="clonedEvent.trainers && clonedEvent.trainers.length > 0">
                        <div class="avatar-tag" *ngFor="let trainer of clonedEvent.trainers">
                            <msc-common-avatar class="tiny" [label]="trainer.account?.name"
                                [pictureURL]="trainer.account?.picture ? (trainer.account?.picture + '?size=64') : null">
                            </msc-common-avatar>

                            <p>{{ trainer.name }}</p>
                            <i class="icon icon-close" (click)="deleteTrainer(trainer)"></i>
                        </div>
                    </div>
                </div>

                <div class="slide__fieldset">
                    <div class="form form-advanced" [ngClass]="{'active': isSettingsVisible}">
                        <div class="form__toggle" (click)="toggleSettings()">
                            <h3>
                                <i class="icon icon-settings-2"></i>
                                {{ 'words.advanced-parameters' | translate }}
                            </h3>

                            <a><i class="icon icon-arrow-ios-down"></i></a>
                        </div>

                        <div class="form__content">
                            <div class="slide__input">
                                <div class="input-upload-media">
                                    <div class="input-upload-media__label">{{ 'words.picture' | translate }}</div>

                                    <div class="input-upload-media__desc">{{ 'group.upload.upload-cover.description' |
                                        translate }}</div>

                                    <div class="input-upload-media__container">
                                        <div class="input-upload-media__image" *ngIf="!preview && event.media"
                                            [style.backgroundImage]="'url(' + clonedEvent.media.pictureUrl + '?size=1024)'">
                                        </div>
                                        <div class="input-upload-media__image" *ngIf="preview"
                                            [style.backgroundImage]="'url(' + preview + ')'"></div>

                                        <div class="input-upload-media__buttons" (click)="uploadImage.click()">
                                            <i class="icon icon-image"></i>
                                        </div>

                                        <input #uploadImage class="display-none" type="file" fileUpload
                                            (change)="loadPicture($event.target.files[0])"
                                            (fileStatus)="load($event, 'image')" [disabled]="readOnly">
                                    </div>
                                </div>
                            </div>

                            <div class="slide__input">
                                <label class="input__label">
                                    {{ 'words.language' | translate }}
                                </label>

                                <ng-container *ngIf="selectData.languages.length">
                                    <msc-common-select class="max-w-full"
                                        [data]="selectData.languages" hasSearch="true" [disabled]="readOnly">
                                        <ng-template #buttonTemplate let-data="data">
                                            <button>
                                                <ng-container *ngIf="data?.length; else emptyTemplate">
                                                    <span>{{ data[0].label }}</span>
                                                </ng-container>
                                                <ng-template #emptyTemplate>
                                                    <span>{{'words.languages' | translate}}</span>
                                                </ng-template>
                                            </button>
                                        </ng-template>
                                        <ng-template #optionTemplate let-datum="datum">
                                            <button class="flex text-sm py-1 w-full" (click)="onSelectOption(datum)">
                                                <span>{{ datum.label }}</span>
                                            </button>
                                        </ng-template>
                                    </msc-common-select>
                                </ng-container>
                            </div>

                            <div class="slide__input">
                                <msc-input name="input" [label]="'words.additional-information' | translate"
                                    [(ngModel)]="clonedEvent.info"
                                    [placeholder]="'placeholder.additional-informations' | translate"
                                    [disabled]="readOnly"></msc-input>
                            </div>

                            <a class="link link-form-add mt-2" (click)="uploadResource.click()" *ngIf="!readOnly">
                                <i class="icon icon-plus-circle text-secondary-500 text-lg"></i>
                                {{ 'words.add-resources' | translate }}
                            </a>

                            <msc-shared-resource [size]="'mini'" [resource]="resource" [truncate]="20"
                                *ngFor="let resource of clonedEvent.resources"></msc-shared-resource>

                            <input #uploadResource class="display-none" type="file" fileUpload
                                (fileStatus)="load($event, 'resource')" [disabled]="readOnly">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div class="flex items-center justify-between grow col-gap-xs ">
            <msc-common2-checkbox [value]="notify" [label]="'notify.members.email' | translate"
                (changeEvent)="notify = !notify" [isDisabled]="readOnly"></msc-common2-checkbox>
            <div class="flex space-x-2">
                <msc-button (click)="aside.onClose()">
                    {{ 'words.cancel' | translate }}
                </msc-button>

                <msc-button class="msc-button--primary" *ngIf="!readOnly" palette="secondary"
                    (click)="save();aside.onClose()">
                    {{ 'words.save' | translate }}
                </msc-button>
            </div>
        </div>
    </ng-template>
</msc-common-aside>
