<div class="flex flex-col">
    <div class="slide-external-link__body grow">
        <div class="slide-external-link__form">
            <div class="slide-external-link__fieldset">
                <div class="slide-external-link__input">
                    <msc-input [label]="'extension.infos.title-label' | translate"
                        [placeholder]="'placeholder.extension-infos-title' | translate"
                        [(ngModel)]="extensionLink.title"></msc-input>
                </div>

                <div class="slide-external-link__input">
                    <msc-textarea id="description" name="input"
                        [label]="'extension.infos.description-label' | translate"
                        [(ngModel)]="extensionLink.description"
                        [placeholder]="'placeholder.extension-infos-description' | translate"></msc-textarea>
                </div>

                <div class="slide-external-link__input">
                    <label class="input__label">{{ 'words.language' | translate }}</label>

                    <ng-container *ngIf="selectData.languages.length">
                        <msc-common-select class="text-sm max-w-full" [data]="selectData.languages" hasSearch="true">
                            <ng-template #buttonTemplate let-data="data">
                                <button class="select-main">
                                    <ng-container *ngIf="data?.length">
                                        <span>{{ data[0].label }}</span>
                                    </ng-container>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <button class="select-option" (click)="onSelectOption(datum)">
                                    <span>{{ datum.label }}</span>
                                </button>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>

                <div class="slide-external-link__input">
                    <div class="input-upload">
                        <div class="input-upload__ctn" [ngClass]="{'content__picture--empty': !extensionLink.image}">
                            <div class="picture__delete" *ngIf="extensionLink.image"
                                (click)="updatePicture('display')">
                                <i class="icon icon-close"></i>
                            </div>

                            <msc-media class="input-upload__preview" [picture]="extensionLink.image"
                                [loadEmitter]="loadDisplayEmitter" (onUploaded)="updatePicture('display', $event)">
                            </msc-media>
                        </div>

                        <div class="ml-4">
                            <msc-button class="msc-button--primary" palette="secondary" icon="icon-image"
                                (click)="loadPicture('display')">
                                {{ 'words.upload' | translate }}
                            </msc-button>

                            <p class="text-sm">{{ 'words.upload-info.256' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex justify-end">
        <msc-button class="msc-button--primary" palette="secondary" (click)="nextPanel()">{{ 'words.next' | translate }}
        </msc-button>
    </div>
</div>