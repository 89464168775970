import { Component, Input } from '@angular/core';
import { StorageService } from '@services';
import { OrganizeUserStepBaseComponent } from '../configure.component.base';
import { FORMSTEPS } from '../configure.enum';

@Component({
    selector: 'msc-organize-user-configure-personal-data',
    templateUrl: './personal-data.component.html',
    styleUrls: ['./personal-data.component.scss'],
    providers: [{ provide: OrganizeUserStepBaseComponent, useExisting: OrganizeUserConfigurePersonalDataComponent }]
})
export class OrganizeUserConfigurePersonalDataComponent extends OrganizeUserStepBaseComponent {
    @Input() set availableManagers(value) {
        if (value) {
            this._availableManagers = this.getSelectManagersData(value);
        }
    }
    formErrors: any = {};
    public data = {
        email: null,
        firstname: null,
        lastname: null,
        headline: null,
        manager_id: null
    };
    public _availableManagers: any[] = [];
    public managerSelected: any;
    private emailRegex: RegExp = new RegExp(/^[a-zA-Z0-9.!#$'%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    constructor(
        private storageService: StorageService
    ) {
        super();

        this.formId = FORMSTEPS.PERSONAL_INFO;
    }

    getSelectManagersData(data) {
        return data.map(d => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: this.data.manager_id === d.id
        }));
    }

    public isValid(): boolean {
        this.formErrors = {};
        if (!this.data.email || !this.emailRegex.test(this.data.email)) {
            this.formErrors.email = true;
        }

        if (!this.data.firstname) {
            this.formErrors.firstname = true;
        }

        if (!this.data.lastname) {
            this.formErrors.lastname = true;
        }

        if (Object.values(this.formErrors).some(error => error)) {
            return false;
        }

        return true;
    }

    selectManager(manager?: any): void {
        this.managerSelected = manager;
        this.data.manager_id = manager ? manager.id : null;
    }

    onSelectUserManager(option) {
        this._availableManagers = this._availableManagers.map((manager) => {
            if (manager.id === option.id) {
                manager.isSelected = !manager.isSelected;
            } else {
                manager.isSelected = false;
            }
            return manager;
        });
        const manager = option.isSelected ? option.value : undefined;
        this.selectManager(manager);
    }

    public toApiData(): any {
        return {
            email: this.data.email,
            firstname: this.data.firstname,
            lastname: this.data.lastname,
            headline: this.data.headline,
            manager_id: this.data.manager_id,
        };
    }

    public toReadableData(): any {
        return {
            label: 'words.personal-data',
            data: [
                this.data.email,
                `${this.data.firstname} ${this.data.lastname}`,
                this.data.headline ? {
                    label: `words.job`,
                    data: [`${this.data.headline}`]
                } : null,
                this.managerSelected ? {
                    label: `words.manager`,
                    data: [`${this.managerSelected.firstname} ${this.managerSelected.lastname}`]
                } : null
            ]
        };
    }
}
