import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-organize-experience',
    templateUrl: './experience.component.html'
})
export class OrganizeExperienceComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
