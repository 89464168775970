import { Component, ChangeDetectionStrategy } from '@angular/core';
//
import { TranslateService } from '@services';
//
import { CommonNavDatum } from '@common2/components/nav/nav.component';
import { PageHubModel } from '@models/page-hub.model';
import { SHOWROOM_EXHIBIT_NAV_KEY_LIST } from '@modules/showroom/showroom.constants';

@Component({
    selector: 'msc-showroom-exhibit-page',
    templateUrl: './showroom-exhibit-page.component.html',
    styleUrls: ['./showroom-exhibit-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitPageComponent extends PageHubModel {
    constructor(
        private readonly translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setNav();
    }

    /**
     * Get the nav data of the page
     */
    getNav(): Array<CommonNavDatum> {
        return SHOWROOM_EXHIBIT_NAV_KEY_LIST.map((key) => ({
            label: key,
            value: {
                routerLink:  `./${key}`,
            }
        }));
    }
}
