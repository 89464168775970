<div class="container-integration__selection col-xs-12 col-9">
    <h1 class="container-integration__fieldset__title">{{ (excludeMode ? 'integrations.rules.content-exclusion' : 'integrations.rules.manual-content-selection') | translate }}</h1>
    <p class="container-integration__fieldset__description">{{ (excludeMode ? 'integrations.rules.content-exclusion-description' : 'integrations.rules.manual-content-selection-description') | translate }}</p>

    <div class="form-selection__header flex center between">
        <div class="form-selection__search flex align-center justify-start">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

            <div class="form-selection__switch flex align-center">
                <msc-common2-switch [value]="onlySelected || excludeMode" [label]="'words.my-selection-contents' | translate" (changeEvent)="showMySelection()"></msc-common2-switch>
            </div>
        </div>
    </div>

    <div class="color--red form-selection__warning" *ngIf="excludeMode">
        <i class="icon icon-alert-triangle"></i>
        {{ 'integrations.exclusion-mode-warning' | translate }}
    </div>

    <div class="form-selection__body">
        <msc-empty-state [title]="'integrations.rules.no-content-selected' | translate" *ngIf="rule.contents?.length === 0 && contents?.length === 0"></msc-empty-state>

        <ul class="form-selection__list flex center justify-start wrap">
            <li class="form-selection__item" *ngFor="let content of contents">
                <div class="card card--min" [ngClass]="{'card--checked': content.checked || (excludeMode && !content.excluded), 'card--excluded': content.excluded}" (click)="checkContent(content)">
                    <div class="card__header">
                        <div class="card__radio"></div>

                        <div class="card__tags flex justify-end">
                            <div class="tag">
                                <div class="tag__text">{{ content.uiTypeLabel }}</div>
                            </div>
                        </div>

                        <div class="card__image">
                            <div [style.backgroundImage]="content.mediaUrl"></div>
                        </div>
                    </div>

                    <div class="card__body">
                        <div class="card__time" *ngIf="content.duration">
                            <span class="card_time_text">{{ content.duration | duration }}</span>
                        </div>

                        <div class="flex" *ngIf="content.categories?.length > 0">
                            <div class="skill skill--level skill--no-hover" *ngIf="content.categories.length > 0">
                                <div class="skill__content" [tooltip]="content.categories[0].name.length > 15 ? content.categories[0].name : ''">
                                    <p class="skill__text">{{ content.categories[0].name | truncate: 15 }}</p>
                                </div>
                            </div>
                            <div class="skill skill--more" *ngIf="content.categories.length > 1" [tooltip]="getCategoriesName(content)">
                                <div class="skill__content">
                                    <div class="skill__text"></div>
                                </div>
                            </div>
                        </div>

                        <p class="card__title" [tooltip]="content.title.length > 45 ? content.title : ''">{{ content.title | truncate: 45 }}</p>
                    </div>
                </div>
            </li>
        </ul>

        <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
    </div>
</div>
