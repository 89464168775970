import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class TemplateProvider {

    constructor(private http: HttpClient) {}

    get(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/learning/courses/${id}`, {params}).pipe(
            map((data) => data)
        );
    }

    getSummary(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/template/${id}/summary`, {params}).pipe(
            map((data) => data)
        );
    }

    subscribe(params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/course-subscriptions`, params).pipe(
            map((data) => data)
        );
    }

    requestOnDemand(id: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/template/${id}/request-instance`, params).pipe(
            map((data) => data)
        );
    }
}
