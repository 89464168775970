import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable, of, forkJoin } from 'rxjs';
import { map, tap, concatMap, takeUntil, repeatWhen, finalize, delay } from 'rxjs/operators';

import { CompanyUrlProvider2, MediaProvider, ApiCompanyUrlItem } from '@lighty';
import { StorageService, ExternalAppsService, TranslateService } from '@services';

import { ComponentSmartFormModel } from '@models2/component-smart-form.model';

import { UiCompanyUrlItem, getRemappedCompanyUrlApiToUi } from '../url-item/url-item.component';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-experience-watch-url-details',
    templateUrl: './url-details.component.html',
    styleUrls: ['./url-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrganizeExperienceWatchUrlDetailsComponent extends ComponentSmartFormModel<any> {
    @ViewChild('file') file: ElementRef;
    @ViewChild('img') img: ElementRef;

    public url$: Observable<ApiCompanyUrlItem>;
    public isDefaultBranding: boolean = false;
    public isLoadingEdit: boolean = false;
    public isLoadingValidate: boolean = false;

    constructor(
        protected readonly fb: FormBuilder,
        protected readonly ref: ChangeDetectorRef,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly renderer: Renderer2,
        protected readonly companyUrlProvider: CompanyUrlProvider2,
        protected readonly mediaProvider: MediaProvider,
        protected readonly storageService: StorageService,
        protected readonly toastService: CommonToastService,
        protected readonly externalAppsService: ExternalAppsService,
        protected readonly translateService: TranslateService,
    ) {
        super();
    }

    get experience(): string { return this.companyCurrentExperience && this.companyCurrentExperience.name || ''; }
    get lang(): string { return this.getFaqByLang(this.me.language.code); }

    /**
     * Sets the component observables
     */
    setObs(): void {
        this.url$ = this.companyUrlProvider
            .getUrl(this.company.id, this.route.snapshot.params['urlId'])
            .pipe(
                repeatWhen(() => this.repeat$.pipe(delay(500))),
                map((d: any) => this.onMapGetUrl(d)),
                tap((d: any) => this.onTapGetUrl(d)),
                takeUntil(this.destroy$),
            );
    }

    /**
     * Get a form group
     */
    getFormGroup(): FormGroup {
        return this.fb.group({
            'file': [null, [Validators.required]],
            'name': ['', [Validators.required]]
        });
    }

    /**
     * Gets a list of init observables (usually media related or nothing)
     */
    getForkEditUrl(): Array<Observable<any>> {
        switch (true) {
            case !!this.form.value.file: return [this.mediaProvider.uploadMedia(this.form.value.file)];
            default: return [of(null)];
        }
    }

    /**
     * Get a list of params for editUrl
     */
    getParamsEditUrl(url: UiCompanyUrlItem, media?: any) {
        const { file, ...datum } = this.form.value;
        return {
            media_id: media ? media.id : null,
            experience_id: this.companyCurrentExperience.id,
            url: url.label,
            ...datum,
        };
    }

    /**
     * Resolver for concatMap of onEdit
     */
    getConcatMapEditUrl(url: UiCompanyUrlItem, media: any): Observable<any> {
        return this.companyUrlProvider
            .editUrl(this.company.id, url.id, this.getParamsEditUrl(url, media))
            .pipe(takeUntil(this.destroy$))
    }

    /**
     * Get a FAQ link based on the lang set
     */
    getFaqByLang(lang: string): string {
        switch (lang) {
            case 'en': return 'https://support.myskillcamp.com/hc/en/articles/4922084506525-Custom-URL';
            case 'fr': return 'https://support.myskillcamp.com/hc/fr/articles/4922084506525-URL-personnalis%C3%A9e';
            case 'nl': return 'https://support.myskillcamp.com/hc/nl/articles/4922084506525-Aangepaste-URL';
            case 'de': return 'https://support.myskillcamp.com/hc/de/articles/4922084506525-Benutzerdefinierte-URL';
            case 'it': return 'https://support.myskillcamp.com/hc/it/articles/4922084506525-URL-personalizzato';
            case 'es': return 'https://support.myskillcamp.com/hc/es/articles/4922084506525-URL-personalizada';
        }
    }

    /**
     * Resolver for tap of onEdit
     */
    onTapEditUrl(url: UiCompanyUrlItem) {
        this.companyUrlProvider
            .editExperience(this.company.id, this.companyCurrentExperience.id, { url_id: this.isDefaultBranding ? url.id : null })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
    }

    /**
     * Resolver for map of getUrl
     */
    onMapGetUrl(datum: ApiCompanyUrlItem): UiCompanyUrlItem {
        return getRemappedCompanyUrlApiToUi(datum, this.companyCurrentExperience);
    }

    /**
     * Resolver for tap of getUrl
     */
    onTapGetUrl(url: UiCompanyUrlItem): void {
        this.isDefaultBranding = url.isActive;
    }

    /**
     * Query init data
     */
    onQuery(): void {
        // leave it empty
    }


    /**
     * Event handler for adding an image
     */
    onImageAdd(): void {
        const element: HTMLElement = this.file.nativeElement as HTMLElement;
        element.click();
    }

    /**
     * Event handler for removing an image
     */
    onImageRemove(): void {
        this.renderer.removeAttribute(this.img.nativeElement, 'src');
        this.form.patchValue({ file: null });
    }

    /**
     * Event handler for change an image
     */
    onImageChange(files: FileList): void {
        const [file] = Array.from(files);
        this.img.nativeElement.src = URL.createObjectURL(file);
        this.form.patchValue({ file });
    }

    /**
     * Event handler for switch
     */
    onSwitch(value: boolean): void {
        this.isDefaultBranding = value;
    }

    /**
     * Event handler for activate of url
     */
    onActivate(url: UiCompanyUrlItem): void {
        this.companyUrlProvider
            .editExperience(this.company.id, this.companyCurrentExperience.id, { url_id: url.id })
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onActivateSuccess(url),
                (error: HttpErrorResponse) => this.onActivateError(error),
            );
    }

    /**
     * Event handler for success on onActivate
     */
    onActivateSuccess(url: UiCompanyUrlItem): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.activate.success'));
        this.repeat$.next();
    }

    /**
     * Event handler for error on onActivate
     */
    onActivateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-url.activate.error', error.message));
    }

    /**
     * Event handler for url deactivation
     */
    onDeactivate(): void {
        this.companyUrlProvider
            .editExperience(this.company.id, this.companyCurrentExperience.id, { url_id: null })
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onDeactivateSuccess(),
                (error: HttpErrorResponse) => this.onDeactivateError(error),
            );
    }

    /**
     * Handler for success on onDeactivate
     */
    onDeactivateSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.deactivate.success'));
        this.repeat$.next();
    }

    /**
     * Handler for error on onDeactivate
     */
    onDeactivateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-url.deactivate.error', error.message));
    }

    /**
     * Event handler for url delete
     */
    onDelete(url: UiCompanyUrlItem): void {
        this.companyUrlProvider
            .deleteUrl(this.company.id, url.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => this.onDeleteSuccess(),
                (error: HttpErrorResponse) => this.onDeleteError(error),
            );
    }

    /**
     * Handler for success on onDelete
     */
    onDeleteSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.delete.success'));
        this.repeat$.next();
    }

    /**
     * Handler for error on onDelete
     */
    onDeleteError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-url.delete.error', error.message));
    }

    /**
     * Event handler for url edit
     */
    onEdit(url: UiCompanyUrlItem): void {
        this.isLoadingEdit = true;
        forkJoin(this.getForkEditUrl())
            .pipe(
                concatMap(([media]) => this.getConcatMapEditUrl(url, media)),
                tap(() => this.onTapEditUrl(url)),
                finalize(() => this.onEditFinally()),
                takeUntil(this.destroy$)
            )
            .subscribe(
                () => this.onEditSuccess(),
                (error: HttpErrorResponse) => this.onEditError(error),
            );
    }

    /**
     * Handler for success on onEdit
     */
    onEditSuccess(): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.edit.success'));
        this.repeat$.next();
    }

    /**
     * Handler for error on onEdit
     */
    onEditError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-url.edit.error', error.message));
    }

    /**
     * Handler for fianally/finalize on onEdit
     */
    onEditFinally(): void {
        this.isLoadingEdit = false;
        this.ref.detectChanges();
    }

    /**
     * Event handler for url validate
     */
    onValidate(url: UiCompanyUrlItem): void {
        this.isLoadingValidate = true;
        this.companyUrlProvider
            .postUrlValidate(this.company.id, url.id, { experience_id: this.companyCurrentExperience.id, })
            .pipe(
                finalize(() => this.onValidateFinally()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                () => this.onValidateSuccess(url),
                (error: HttpErrorResponse) => this.onValidateError(error),
            );
    }

    /**
     * Handler for success on onValidate
     */
    onValidateSuccess(url: UiCompanyUrlItem): void {
        this.toastService.onSuccess(this.translateService.instant('toast.custom-url.validate.success'));
        this.repeat$.next();
    }

    /**
     * Handler for error on onValidate
     */
    onValidateError(error: HttpErrorResponse): void {
        this.toastService.onError(this.translateService.instant('toast.custom-url.validate.error', error.message));
    }

    /**
     * Handler for fianally/finalize on onValidate
     */
    onValidateFinally(): void {
        this.isLoadingValidate = false;
        this.ref.detectChanges();
    }

    /**
     * Resolves navigation to list section
     */
    goToList(): void {
        this.router.navigate(['../..', 'list'], { relativeTo: this.route });
    }
}
