<div class="bg-white p-4 m-4 flex flex-col space-y-4 rounded">
    <div class="flex justify-end" *ngIf="externalContentSession && !externalContentSession.doneAt">
        <msc-button class="msc-button--primary" palette="secondary" icon="icon-checkmark" (click)="finished()">
            {{ 'words.i-finished' | translate }}
        </msc-button>
    </div>
    <div class="bg-gray-100 p-4 rounded-sm" *ngIf="externalContent.provider?.key !== 'coursera'">
        <div class="cursor-pointer flex justify-between grow" (click)="displayed('time')">
            <h3>{{ 'session-log.total-time-for' | translate }} "{{ externalContent.title }}"</h3>

            <div class="flex space-x-2 flex-nowrap items-center">
                <span class="flex space-x-2 flex-nowrap" *ngIf="duration">
                    <i class="icon icon-clock text-primary-500"></i>
                    <span>{{ 'session-log.total-time' | translate }} : {{ getDuration('days') }} {{ getDuration('hours')
                        }} {{
                        getDuration('minutes') }}</span>
                </span>

                <span *ngIf="!duration">
                    {{ 'session-log.no-time' | translate }}
                </span>

                <i class="icon icon-arrow-ios-up transition-all" [ngClass]="{'rotate-180': displays.time}"></i>
            </div>
        </div>

        <div class="py-4" *ngIf="displays.time">
            <div class="flex flex-col space-y-2 items-center">
                <div class="border bg-white p-4 flex space-x-4 items-center w-full"
                    *ngFor="let session of sessions; let index = index">
                    <h3>{{ session.date | date: 'dd MMMM YYYY' }}</h3>
                    <p>{{ convertSessionDuration(session) || 'words.no-duration' | translate
                        }}</p>
                </div>
                <msc-common-spinner *ngIf="loading.logs"></msc-common-spinner>
                <div class="border-2 border-dashed p-4 flex space-x-4 items-center cursor-pointer w-full"
                    (click)="displayed('addTime'); resetData()" *ngIf="!displays.addTime">
                    <i class="icon icon-plus-circle text-primary-500"></i>
    
                    <h3>{{ 'session-log.add-time' | translate }}</h3>
                </div>
            </div>

            <div class="time-container__time__add-modal" *ngIf="displays.addTime">
                <div class="time-container__time__add-modal__inputs">
                    <div>
                        <div class="input input--date input--icon-right">
                            <input autocomplete="off" type="text" name="input" class="input__text"
                                [ngModel]="data.date | date" disabled>

                            <span class="input__icon">
                                <i class="icon icon-calendar text-secondary-500"></i>
                            </span>
                        </div>
                    </div>

                    <div class="flex align-center">
                        <input type="number" class="input-number" [(ngModel)]="data.value" min="0">

                        <select class="select" [(ngModel)]="data.unit">
                            <option value="minutes">{{ 'words.minutes' | translate }}</option>
                            <option value="hours">{{ 'words.hours' | translate}}</option>
                            <option value="days">{{ 'words.days' | translate }}</option>
                        </select>
                    </div>
                </div>

                <div class="time-container__time__add-modal__actions">
                    <msc-button icon="icon-close" (click)="displayed('addTime')">
                        {{ 'words.cancel' | translate }}
                    </msc-button>

                    <msc-button class="msc-button--primary" palette="secondary" icon="icon-checkmark"
                        [loading]="loading.createLog" *ngIf="data.value && data.date" (click)="addLog()">
                        {{ 'words.validate' | translate }}
                    </msc-button>
                </div>
            </div>
        </div>
    </div>
</div>