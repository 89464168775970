export class CompanyExperienceResource {
    id: number;
    title: string;
    mediaId: number;
    mediaUrl: string;

    constructor(resource: object) {
        this.assign(resource);
    }

    assign(resource: object): void {
        Object.assign(this, resource);
    }
}
