import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-stats-providers',
    templateUrl: './stats-providers.component.html'
})
export class CampStatsProvidersComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {}
}
