import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { KnowledgeProvider, Company, KnowledgeSkill, LanguageProvider } from '@lighty';
import { HttpHelper } from '@helpers';
import { ErrorStreamService, TranslateService } from '@services';
import { Language } from '@interfaces';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-knowledge-aside-create',
    templateUrl: './create-skill.component.html',
})
export class OrganizeKnowledgeAsideCreateComponent implements OnInit {
    @Input() company: Company;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @ViewChild('aside') aside;
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public skills: KnowledgeSkill[] = [];
    public languages: Language[] = [];
    public data: any;
    public selectedSkill: KnowledgeSkill;
    public loading: boolean = false;
    public query: string = '';
    public suggest: boolean = false;
    public createAnotherSkill: boolean = false;

    constructor(
        private knowledgeProvider: KnowledgeProvider,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private languageProvider: LanguageProvider,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.languageProvider.get('interface').subscribe(data => this.languages = data);

        this.resetData();
        this.search();
    }

    public onOpen(){
        this.aside.onOpen();
    }

    private resetData(): void {
        this.data = {
            company_id: this.company.id,
            translations: {
                fr: '',
                es: '',
                en: '',
                nl: '',
                de: '',
                it: '',
            },
            validated: true,
            visible: true,
            thematic: false,
            suggestion_id: null
        };
    }

    close(): void {
        this.onClose.emit(true);
    }

    change(type: string, value?: boolean): void {
        this.data[type] = (value !== undefined) ? value : !this.data[type];
    }

    create(): void {
        this.errorStreamService.locked();
        this.data.suggestion_id = this.selectedSkill ? this.selectedSkill.id : null;
        this.knowledgeProvider.create(this.data).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant('toast.skill-name-added'));
            this.skills.push(data);
            this.onSave.emit(data);
            this.resetData();

            if (!this.createAnotherSkill) {
                this.aside.onSave();
            }
            this.errorStreamService.unlocked();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.skill-name-already-used'));
            this.errorStreamService.unlocked();
        });
    }

    search(query?): void {
        this.query = query;
        this.loading = true;
        const params = HttpHelper.cleanParams({
            q: this.query,
            order_by: 'total_usage',
            order_way: 'desc'
        });

        this.knowledgeProvider.get(this.company.slug, params).subscribe((data) => {
            this.skills = this.filtering(data.skills);
            this.loading = false;
        });
    }

    private filtering(skills: KnowledgeSkill[]): KnowledgeSkill[] {
        return skills.filter((skill) => {
            return skill.visible;
        });
    }

    selectSkill(skill: KnowledgeSkill): void {
        this.selectedSkill = this.isActive(skill) ? null : skill;
    }

    isActive(skill: KnowledgeSkill): boolean {
        return this.selectedSkill && this.selectedSkill.id === skill.id;
    }

    changeCreateAnotherSkill(): void {
        this.createAnotherSkill = !this.createAnotherSkill;
    }
}
