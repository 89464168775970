import * as d3 from 'd3';

export class GraphicUtils {

    /**
     *
     */
    static getConvertToRadians(angle: number): number {
        return angle * Math.PI / 180;
    }

    /**
     *
     */
    static getConvertToDegrees(angle: number): number {
        return angle * 180 / Math.PI;
    }

    /**
     *
     */
    static getCoordXByRadial(angle: number, radial: number): number {
        return Math.sin(angle) * radial;
    }

    /**
     *
     */
    static getCoordYByRadial(angle: number, radial: number): number {
        return Math.cos(angle) * radial * -1;
    }

    /**
     * Resolves a radius between a given width & height (with offset as optional)
     * @param ..args - Object with dimension data {width, height, offset}
     */
    static getRadiusByDimensions({ height = 0, width = 0, offset = 0 }): number {
        return Math.min(height / 2, width / 2) - offset;
    }

    /**
     * Resolves the length of the rendered text
     * @param element - the text element in a Svg content
     */
    static getRenderedTextLength(element: SVGTextContentElement): number {
        return Math.ceil(element.getComputedTextLength());
    }

    /**
     * Resolves an arc path
     */
    static getArc() {
        return d3.arc()
            .innerRadius((d) => d.innerRadius)
            .outerRadius((d) => d.outerRadius)
            .startAngle((d) => d.startAngle)
            .endAngle((d) => d.endAngle);
    }

    /**
     * Resolves the length of an arc defined by start, end angles and radius
     * @param startAngle
     * @param endAngle
     * @param radius
     */
    static getArcLength(startAngle: number, endAngle: number, radius: number): number {
        return Math.abs(endAngle - startAngle) * radius;
    }

    /**
     * Resolves an outer segment angle
     * @param data - Band segment data
     * @param index - Index of current segment
     * @param key - Key based on which we prepare our scale
     */
    static getArcAngle(data: any[], index: number, key?: string) {
        let scale = this.getLinearScaleByKey(key, data);
        return data.reduce((acc, item, i) => {
            if (i >= index) { return acc; }
            return Array.isArray(item[key]) ? acc + scale(item[key].length) : acc + scale(item[key]);
        }, 0);
    }

    /**
     * Resolves a chordBandInnerSegment scale by provided key
     * This method will resolve a scale by:
     * 1. item count if no key provided
     * 2. item value if key provided and value is non-array  i.e obj[key] = 144;
     * 3. item value length if key provided and value is array i.e obj[key] = [{a: b}, {c: d}]
     */
    static getLinearScaleByKey<T>(key: string, data: T[] = [], range: number[] = [0, 2 * Math.PI]) {
        let total = data.reduce((acc, item: T) => {
            if (!key || !item.hasOwnProperty(key)) { return acc; }
            return Array.isArray(item[key]) ? acc + item[key].length : acc + item[key];
        }, 0);
        return d3.scaleLinear().domain([0, total]).range(range);
    }
}
