<div class="content-template-summary content-summary__main row">
    <div class="summary__main col-12 col-xxs-12">
        <div class="dropdown-module" *ngFor="let section of summary.sections; let index=index"
             [ngClass]="{'active' : openSections[index]}">
            <div class="block-section" (click)="openDropdownSections(index)">
                <div class="block-section__wrapper">
                    <div class="block-section__header">
                        <div class="block-section__title">
                            {{ section.title || ('words.untitled' | translate) }}
                        </div>
                    </div>

                    <div class="block-section__toggle"
                         [ngClass]="{'block-section__toggle-active': !section.sectionContents}">
                        <i class="icon icon-arrow-ios-down text-secondary-500" *ngIf=section.sectionContents></i>
                    </div>
                </div>
            </div>

            <div class="content-summary__content" *ngIf="openSections[index]">
                <div class="block-task" *ngFor="let activity of section.sectionContents">
                    <div class="block-task__wrapper">
                        <div class="block-task__header">
                            <div class="block-task__title">
                                <i class="icon" [ngClass]="getActivityIcon(activity.context)"></i>
                                {{ activity.context === 'exercise' ? ('words.exercise' | translate) : activity.title ? activity.title : ('words.untitled' | translate) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

