<div class="content-knowledge-summary box">
    <div class="content-knowledge-summary__header">
        <h3 class="content-knowledge-summary__title">
            {{ 'words.details' | translate }}
        </h3>
    </div>

    <div class="content-knowledge-summary__content">
        <div class="col-md-4 col-xxs-12 no-gutter box">
            <div class="content-knowledge__input" *ngFor="let language of languages">
                <p class="content-knowledge__label">
                    {{ language.name }}
                </p>

                <div class="input">
                    <input autocomplete="off" name="input" class="input__text" type="text"
                        [(ngModel)]="skill.translations[language.code]" [debounce]="800" (onDebounce)="updateSkill()">
                </div>
            </div>

            <div class="content-knowledge__input">
                <p class="content-knowledge__label">
                    {{ 'words.thematic' | translate }}
                </p>

                <div class="content-knowledge__switch">
                    <msc-common2-switch [value]="skill.thematic" (changeEvent)="skill.thematic = !skill.thematic; updateSkill()"></msc-common2-switch>
                </div>
            </div>

            <div class="content-knowledge__input">
                <p class="content-knowledge__label">
                    {{ 'words.approval' | translate }}
                </p>

                <div class="content-knowledge__switch">
                    <msc-common2-switch [value]="skill.validated" (changeEvent)="skill.validated = !skill.validated; updateSkill()"></msc-common2-switch>
                </div>
            </div>

            <div class="content-knowledge__input">
                <p class="content-knowledge__label">
                    {{ 'company.management.usage' | translate }}
                </p>

                <div class="content-knowledge__switch content-knowledge__switch--flex">
                    <div (click)="modalVisibility.onOpen(); $event.preventDefault()">
                        <msc-common2-switch [value]="skill.visible"></msc-common2-switch>
                    </div>

                    <p>{{ (skill.visible ? 'words.allowed' : 'words.forbidden') | translate }}</p>
                </div>
            </div>
        </div>

        <aside class="col-md-8 col-xxs-12 no-gutter">
            <div class="box">
                <div class="flex direction-column">
                    <div class="flex">
                        <div class="box box--info flex">
                            <div class="box__title">
                                <i class="icon icon-arrow-circle-right text-secondary-500"></i>
                                <span>{{ (skill.usage.total > 1 ? 'words.resources' : 'words.resource') | translate }}</span>
                            </div>

                            <div class="box__content">
                                <p>{{ skill.usage.total }}</p>
                            </div>
                        </div>

                        <div class="box box--info flex">
                            <div class="box__title">
                                <i class="icon icon-cube text-secondary-500"></i>
                                <span>{{ (skill.usage.courses > 1 ? 'words.templates' : 'words.template') | translate | lowercase }}</span>
                            </div>

                            <div class="box__content">
                                <p>{{ skill.usage.courses }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="box box--info flex">
                            <div class="box__title">
                                <i class="icon icon-trending-up text-secondary-500"></i>
                                <span>{{ (skill.usage.trajects > 1 ? 'words.journeys' : 'words.journey') | translate | lowercase }}</span>
                            </div>

                            <div class="box__content">
                                <p>{{ skill.usage.trajects }} </p>
                            </div>
                        </div>

                        <div class="box box--info flex">
                            <div class="box__title">
                                <i class="icon icon-map text-secondary-500"></i>
                                <span>{{ 'words.content-external' | translate | lowercase }}</span>
                            </div>

                            <div class="box__content">
                                <p>{{ skill.usage.externalContents }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box" *ngIf="skill.validatedBy">
                <div class="box__title">
                    {{ 'words.validated' | translate }}  {{ 'words.powered-by' | translate }}
                </div>

                <div class="box__content">
                    <div class="box__avatar">
                        <msc-common-avatar [label]="skill.validatedBy?.name"
                            [pictureURL]="skill.validatedBy?.picture ? (skill.validatedBy?.picture + '?size=128') : null">
                        </msc-common-avatar>
                    </div>

                    <div class="box__text">
                        <p>
                            {{ skill.validatedBy.name }}
                            <span>{{ skill.validatedAt | date }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="box" *ngIf="skill.account">
                <div class="box__title">
                    {{ 'words.created.f' | translate }}  {{ 'words.powered-by' | translate }}
                </div>

                <div class="box__content">
                    <div class="box__avatar">
                        <msc-common-avatar [label]="skill.account?.name"
                            [pictureURL]="skill.account?.picture ? (skill.account?.picture + '?size=128') : null">
                        </msc-common-avatar>
                    </div>

                    <div class="box__text">
                        <p>
                            {{ skill.account.name }}
                            <span>{{ skill.createdAt | date }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </aside>
    </div>
</div>

<msc-shared-aside-forbid-skill #forbidSkillAside
                               [company]="company"
                               [selectedSkill]="skill"
                               (onSave)="refresh()">
</msc-shared-aside-forbid-skill>

<msc-common-modal size="30vw" #modalVisibility [title]="'company.management.usage' | translate">
    <ng-template>
        <p>{{ 'words.question.want-do' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalVisibility.onClose()">{{'common.cancel' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="forbidSkillAside.onOpen(); modalVisibility.onClose()">{{'words.forbid' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="allow(); modalVisibility.onClose()">{{'words.authorize' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
