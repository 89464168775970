import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//
import { Subject, BehaviorSubject, of } from 'rxjs';
import { catchError, mergeScan, map, tap, repeat, finalize, concatMap, takeUntil } from 'rxjs/operators';
//
import { StatisticsProvider2 } from '@lighty';
import { StorageService, TranslateService } from '@services';
//
import { CommonSubjectStatus } from '@common2/common.types';
import { CommonSearchOutput } from '@common2/components/search/search.component';
import { UserContentProvider } from 'src/app/lighty/models/user-content';


@Component({
    selector: 'msc-camp-stats-template',
    templateUrl: './stats-template.component.html',
    styleUrls: ['./stats-template.component.scss'],
})

export class CampStatsTemplateComponent {
    private company: any;
    private courseId: number;
    private templateId: number;
    private query: string;
    private destroy$: Subject<void> = new Subject<void>();
    private repeat$: Subject<void> = new Subject<void>();
    public cards: Array<any>;
    public columns: Array<any>;
    public rows$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public rowsTemp$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public status$: BehaviorSubject<CommonSubjectStatus> = new BehaviorSubject<CommonSubjectStatus>(CommonSubjectStatus.LOADING);
    public hasLoadMore: boolean = false;
    public content: any;
    public user: any;
    public template: any;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly storageService: StorageService,
        private readonly userContentProvider: UserContentProvider,
        private readonly statisticsProvider2: StatisticsProvider2,
    ) {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state) {
            this.storageService.setCache('v2TemplateStats', this.router.getCurrentNavigation().extras.state.datum, null, true);
        }
        this.content = this.storageService.getCache('v2ContentStats');
        this.user = this.storageService.getCache('v2UserStats');
        this.template = this.storageService.getCache('v2TemplateStats');
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.courseId = this.route.parent.parent.snapshot.params['contextId'];
        this.templateId = this.route.snapshot.params['templateId'];
        this.setCards(this.user);
        this.setContent();
        this.onQueryContentUserStatList();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.repeat$.complete();
    }

    /**
     *
     */
    setCards(user: any): void {
        this.cards = [
            {
                label: this.translateService.instant('words.status'),
                value: this.translateService.instant(user.isSubscribed ? 'words.subscribed' : 'words.unsubscribed'),
                isSubscribed: user.isSubscribed,
                type: 'status',
            },
            {
                label: this.translateService.instant('stats.hour'),
                value: user.duration,
                type: 'duration',
            },
            {
                label: this.translateService.instant('words.progress-average'),
                value: user.chartProgress,
                type: 'chart',
            },
            {
                label: this.translateService.instant('words.score-average'),
                value: user.chartScore,
                type: 'chart',
            },
        ];
    }

    /**
     * Set the table component content
     */
    setContent(): void {
        this.columns = this.getColumns();
    }

    /**
     *
     */
    getColumns() {
        return [
            {
                key: 'name',
                label: this.translateService.instant('words.name'),
                width: '30%',
            },
            {
                key: 'time',
                label: this.translateService.instant('stats.hour'),
                width: '20%',
            },
            {
                key: 'progress',
                label: this.translateService.instant('words.progress'),
                width: '25%',
            },
            {
                key: 'result',
                label: this.translateService.instant('words.score'),
                width: '25%',
            },
        ];
    }

    /**
     *
     */
    getParamsOnQueryContentUserStatList(pageValue: number) {
        return Object.assign({
            page: pageValue,
            traject_id: this.content.id,
        }, this.query ? { q: this.query } : {});
    }

    /**
     *
     */
    onQueryContentUserStatList(isAppend = true) {
        this.userContentProvider
            .getUserCourse(this.templateId, this.user.id, this.getParamsOnQueryContentUserStatList(this.page$.getValue()))
            .pipe(
                tap((response) => this.onTapOnQueryContentUserStatList(response.pagination)),
                map((response) => this.onMapOnQueryContentUserStatList(response.activities)),
                mergeScan((acc, items) => of([...acc, ...items]), isAppend ? this.rowsTemp$.getValue() : []),
                tap((rows) => this.rowsTemp$.next(rows)),
                concatMap(() => this.onConcatMapOnQueryContentUserStatList()),
                repeat({ delay: () => this.repeat$ }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    /**
     *
     */
    onTapOnQueryContentUserStatList(pagination: any) {
        this.hasLoadMore = pagination.currentPage !== pagination.lastPage;
    }

    /**
     *
     */
    onMapOnQueryContentUserStatList(data: Array<any>) {
        if (!Array.isArray(data)) { return []; }
        return data.map((datum) => ({
            id: datum.id,
            label: this.translateService.instant(`stats.content.type-${datum.context}`),
            contextId: datum.contextId,
            title: datum.title,
        }));
    }

    /**
     *
     */
    onConcatMapOnQueryContentUserStatList() {
        const ids = this.rowsTemp$.getValue().map((row) => row.id);
        return this.statisticsProvider2
            .getContentSubscriptionActivityList(this.company.id, this.templateId, this.user.id, { context: this.content.context, content_ids: ids })
            .pipe(
                catchError(() => of([])),
                tap((datum) => this.onTapOnConcatMapOnQueryContentUserStatList(datum)),
                finalize(() => this.status$.next(CommonSubjectStatus.SUCCESS)),
                takeUntil(this.destroy$),
            );
    }

    /**
     *
     */
    onTapOnConcatMapOnQueryContentUserStatList(datum: any) {
        const rows = this.rowsTemp$.getValue().map((row) => ({
            ...row,
            isChartProgressVisible: typeof datum[row.id]?.context?.progress === 'number',
            isChartScoreVisible: typeof datum[row.id]?.context?.score === 'number',
            chartProgress: datum[row.id]?.context?.progress ?? 0,
            chartScore: datum[row.id]?.context?.score ?? 0,
            duration: datum[row.id] ? datum[row.id]?.context?.duration ?? 0 : 0,
        }));
        this.rows$.next(rows);
    }

    /**
     *
     */
    onSearch({ value }: CommonSearchOutput<any>): void {
        this.query = value || null;
        this.page$.next(1);

    }

    /**
     *
     */
    onLoad(): void {
        this.page$.next(this.page$.getValue() + 1);

    }

    /**
     *
     */
    goBack(level: number, start?: boolean): void {
        const fragment = this.router.url.split('/');

        if (start) {
            fragment.splice(fragment.length - ((level * 2) + 3), (level * 2) + 3);
            fragment.push('content');
        } else {
            fragment.splice(fragment.length - (level * 2), level * 2);
        }

        this.router.navigate(fragment);
    }
}
