import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';

export interface CommonFeedUserDatum {
    datum?: any;
    userAvatar: string;
    userName: string;
    userRole?: string;
}

@Component({
    selector: 'msc-common-feed-user',
    templateUrl: './feed-user.component.html',
    styleUrls: ['./feed-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonFeedUserComponent {
    @Input() datum: CommonFeedUserDatum;
    @Output() chat?: EventEmitter<any> = new EventEmitter<any>();
    @Output() email?: EventEmitter<any> = new EventEmitter<any>();

    private destroy$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Event handler for chat
     */
    onChat(): void {
        this.chat && this.chat.emit();
    }

    /**
     * Event handler for email
     */
    onEmail(): void {
        this.email && this.email.emit();
    }
}
