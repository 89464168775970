import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@services';
import * as moment from 'moment';

/**
 * Transform seconds
 */
@Pipe({
    name: 'hhmm'
})
export class HhmmPipe implements PipeTransform {
    private singleUnits: any = {
        fr: {
            h: '1 heure',
            min: '1 minute',
        },
        en: {
            h: '1 hour',
            min: '1 minute',
        },
        es: {
            h: '1 hora',
            min: '1 minuto',
        },
        nl: {
            h: '1 uur',
            min: '1 minute',
        },
        it: {
            h: '1 ora',
            min: '1 minuto',
        },
        de: {
            h: '1 Stunde',
            min: '1 Minute',
        }
    };
    private pluralUnits: any = {
        fr: {
            h: '{time} heures',
            min: '{time} minutes'
        },
        en: {
            h: '{time} hours',
            min: '{time} minutes'
        },
        es: {
            h: '{time} horas',
            min: '{time} minutos'
        },
        nl: {
            h: '{time} uren',
            min: '{time} minuten'
        },
        it: {
            h: '{time} ore',
            min: '{time} minuti'
        },
        de: {
            h: '{time} Stunden',
            min: '{time} Minuten'
        }
    };

    constructor(private translateService: TranslateService) {

    }

    /**
     * Transform seconds to hours and minutes
     * @param {number} seconds - Value
     * @return {string} Transformed value
     */
    transform(seconds: number, h: true, m: true): string {
        let lang = this.translateService.getCurrentLang();

        if (seconds) {
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            minutes = minutes % 60;

            return (minutes < 1)
                ? `< ${this.singleUnits[lang]['min'].replace('{time}', minutes)}`
                : hours
                    ? `${this.pluralUnits[lang]['h'].replace('{time}', hours)} ${this.pluralUnits[lang]['min'].replace('{time}', minutes)}`
                    : `${this.pluralUnits[lang]['min'].replace('{time}', minutes)}`
        }

        return null;
    }
}
