
<section class="head">
    <h3>{{ 'words.demand-services' | translate }}</h3>
</section>

<div class="container-experience__main row">
    <aside class="container-experience__aside col-xxs-12 col-md-3 col-3">
        <div class="navigations-aside">
            <msc-common-spinner *ngIf="isLoadingProviders"></msc-common-spinner>
            <ul class="navigations-aside-content" *ngIf="!isLoadingProviders">
                <li *ngFor="let option of menuOptions" class="navigations-aside-content__item" (click)="loadLearners(option.companyProviderId)">
                    <p>
                        <i class="icon icon-cube"></i>
                        {{ option.name }}
                    </p>
                </li>
            </ul>
        </div>
    </aside>

    <div class="container-experience__body col-xxs-12 col-md-9 col-9 no-gutter">
        <ng-container *ngIf="currentCompanyProvideId != undefined">
        <h3>{{ 'words.demand-admin-access' | translate }}</h3>
            <div class="toolbar flex justify-between mb-3 mt-3">
                <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearchTable($event)"></msc-common-search>
                <msc-button [disabled]="isLoadingAvailableMembers || availableMembers == undefined" class="msc-button--primary msc-button--tiny" palette="secondary" (click)="aside.onOpen()">
                    {{ 'words.invite-member' | translate }}
                </msc-button>
            </div>
            <msc-common-spinner *ngIf="isLoadingTable"></msc-common-spinner>
            <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                <msc-common-table [columns]="columns" [rows]="rows$ | async"
                    (onScrollEnd)="hasLoadMore ? onLoad() : null">
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate let-datum="datum">
                        <span>{{ datum.label }}</span>
                    </ng-template>
                    <ng-template #thTemplate>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <div class="flex space-x-2">
                            <div>{{ datum.firstname }}</div>
                        </div>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <div class="flex space-x-2">
                            <div>{{ datum.lastname }}</div>
                        </div>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <div class="flex space-x-2">
                            <div>{{ datum.email }}</div>
                        </div>
                    </ng-template>
                    <ng-template #tdTemplate let-datum="datum">
                        <div class="flex space-x-2 cursor-pointer" (click)="deleteMember(datum.companyUserId)">
                            <i [tooltip]="'common.delete' | translate" class="icon icon-trash text-primary-500"></i>
                        </div>
                    </ng-template>
                </msc-common-table>
            </ng-container>
            <ng-template #emptyTemplate *ngIf="!isLoadingTable">
                <msc-common-empty [message]="'common.empty' | translate">
                    <ng-template></ng-template>
                </msc-common-empty>
            </ng-template>
        </ng-container>
    </div>
</div>

<msc-common-aside #aside [title]="'words.invite-member' | translate">
    <ng-template #asideTemplate>
        <div class="w-96">
            <msc-common-spinner *ngIf="isLoadingAvailableMembers"></msc-common-spinner>
        </div>
        <ng-container *ngIf="!isLoadingAvailableMembers">
                <div class="slide__search mb-3 w-96">
                    <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearchAside($event)"></msc-common-search>
                </div>
                <div *ngFor="let user of availableMembers.companyUsers" class="flex between">
                    <div class="flex align-center mb-3 mr-12">
                        <msc-common-avatar class="mr-2" [label]="user.firstname" [pictureURL]="user.picture ? (user.picture + '?size=128') : null"></msc-common-avatar>
                        <p>{{ user.firstname }} {{user.lastname}}</p>
                    </div>
                    <msc-common2-checkbox
                    [value]="false"
                    (changeEvent)="selectMember(user.companyUserId)">
                </msc-common2-checkbox>
                </div>
        </ng-container>
    </ng-template>

    <ng-template #footerTemplate>
        <msc-button class="msc-button--primary" palette="secondary" 
        (click)="addMembers()" 
        [disabled]="isLoadingAvailableMembers">
            {{ 'words.add' | translate }}
        </msc-button>
    </ng-template>
</msc-common-aside>