<div class="tag"
     [ngClass]="{'tag--red': color === 'red',
     'tag--green': color === 'green',
     'tag--blue': color === 'blue',
     'tag--orange': color === 'orange',
     'tag--gray': color === 'gray',
     'tag--graylight': color === 'graylight',
     'tag--secondary-default': color === 'secondary-default',
     'tag--white': color === 'white',
     'tag--time': type === 'time',
     'tag--date': type === 'date',
     'tag--icon': icon}">
    <i [attr.class]="'icon ' + icon" *ngIf="icon"></i>
    <div class="tag__text" *ngIf="type !== 'date'">{{ text }}</div>

    <div class="tag__text" *ngIf="type === 'date'">
        <span class="text__day">{{ day }}</span>

        <span class="text__month">{{ month }}</span>
    </div>
</div>
