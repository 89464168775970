import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, CompanyColorProvider, CompanyExperience, CompanyExperienceProvider } from '@lighty';
import { EmitterService, StorageService, TranslateService } from '@services';
import { SubscriptionCollection } from '@classes';
import { DataHelper } from '@helpers';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { ThemingService } from '@common2/services/theming.service';

const EXPERIENCE_LIST_TOOLTIP_LIMIT = 5;

@Component({
    selector: 'msc-organize-experience-watch-theme',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss']
})

export class OrganizeExperienceWatchThemeComponent {
    @ViewChild('modalRequest') modalRequest: CommonModalComponent;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private me: any;
    public company: Company;
    public experience: CompanyExperience;
    public themes: any = [];
    public defaultTheme: any;
    public variantTheme: any;
    public selectedTheme: any;
    public displays: any;

    constructor(
        private route: ActivatedRoute,
        private companyColorProvider: CompanyColorProvider,
        private companyExperienceProvider: CompanyExperienceProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private translateService: TranslateService,
        private themingService: ThemingService
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.experience = this.storageService.get('currentExperience');

        this.defaultTheme = this.route.snapshot.data.themes.templates[0];
        this.variantTheme = this.route.snapshot.data.themes.templates[1];
        this.themes = this.filterThemes(this.route.snapshot.data.themes.templates);

        this.displays = {
            create: false
        };

        this.listeners();
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    private filterThemes(themes: any): any {
        return themes.filter((theme) => {
            return !theme.stylesheet || (theme.stylesheet && (theme.stylesheet.indexOf('default') === -1 && theme.stylesheet.indexOf('variant') === -1));
        });
    }

    private listeners(): void {
        this.subscriptionCollection.subscribe = this.emitterService.get('theme.apply').subscribe((theme) => {
            if (!DataHelper.isEmpty(theme)) {
                this.apply(theme);
            }
        });

        this.subscriptionCollection.subscribe = this.emitterService.get('theme.request.sale').subscribe((data) => {
            if (typeof data === 'boolean') {
                this.openModalRequest(this.modalRequest);
                this.emitterService.set('theme.request.sale', {});
            }
        });
    }

    refresh(): void {
        this.companyColorProvider.getThemes(this.company.id).subscribe(data => {
            this.themes = this.filterThemes(data.templates);
        });
    }

    openModalPreview(modal: CommonModalComponent, theme: any): void {
        this.selectedTheme = theme;
        modal.onOpen();
    }

    openModalDelete(modal: CommonModalComponent, theme: any): void {
        this.selectedTheme = theme;
        modal.onOpen();
    }

    openModalRequest(modal: CommonModalComponent): void {
        modal.onOpen();
    }

    setSelectedTheme(theme) {
        this.selectedTheme = theme;
    }

    // displayed(type: string): void {
    //     this.selectedTheme = null;
    //     this.displays[type] = !this.displays[type];
    // }

    // edit(theme: any): void {
    //     this.selectedTheme = theme;
    //     this.displays.create = true;
    // }

    preview(theme): void {
        this.themingService.apply({ primary: theme.primaryColor, secondary: theme.secondaryColor});
        this.toastService.onInfo(this.translateService.instant('toast.theme.applied'));
    }

    apply(theme: any): void {
        const params = {
            experience_id: this.experience.id
        };
        
        this.themingService.apply({ primary: theme.primaryColor, secondary: theme.secondaryColor});
        this.companyColorProvider.enable(this.company.id, theme.id, params).subscribe(() => {
            this.experience.colorTemplate.id = theme.id;
            this.toastService.onSuccess(this.translateService.instant('toast.theme.applied'));
            this.themingService.apply();

            if (this.experience.id === this.company.experience.id) {
                this.emitterService.set('theme.change', theme);
            }

            this.refresh();
        });
    }

    delete(): void {
        this.companyColorProvider.delete(this.company.id, this.selectedTheme.id).subscribe(() => {
            const index = this.themes.findIndex((theme) => {
                return theme.id === this.selectedTheme.id;
            });

            if (index > -1) {
                this.themes.splice(index, 1);
            }

            this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
        });
    }

    request(): void {
        this.companyColorProvider.notify(this.company.id).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.theme.request.mail'));
        });
    }

    process(theme: any): void {
        if (theme.id) {
            this.companyColorProvider.update(this.company.id, theme.id, theme).subscribe((data) => {
                this.refresh();
            });
        } else {
            this.create(theme);
        }
    }

    create(theme: any): void {
        this.companyColorProvider.create(this.company.id, theme).subscribe(() => {
            this.refresh();
        });
    }

    isActivate(id: number): boolean {
        return this.experience.colorTemplate.id === id;
    }

    getExperiences(experiences: any): string {
        const collection = experiences.map((experience) => {
            return experience.name;
        });

        if (collection.length > EXPERIENCE_LIST_TOOLTIP_LIMIT) {
            return `${collection.slice(0, EXPERIENCE_LIST_TOOLTIP_LIMIT).join(', ')} + ${collection.length - EXPERIENCE_LIST_TOOLTIP_LIMIT}`;
        }

        return collection.join(',');
    }
}
