import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//
import { map } from 'rxjs/operators';
//
import { HttpUtils } from '@common2/utils/http.utils';
import { ADMIN_COMPANY_LIST_API_URL_ROOT, CREATE_API_URL_ROOT } from '@core/tokens';
import { ApiMarketplaceGetCatalogueResponse, ApiMarketplaceDeleteCatalogueParams, ApiMarketplacePostAddCatalogueParams, ApiMarketplaceEditCourseModeParams } from '@modules/organize/organize.types';

@Injectable({ providedIn: 'root' })
export class OrganizeMarketplaceCatalogueApiService {
    constructor(
        @Inject(ADMIN_COMPANY_LIST_API_URL_ROOT) private readonly adminCompanyListApi: string,
        @Inject(CREATE_API_URL_ROOT) private readonly createApi: string,
        private readonly http: HttpClient,
    ) { }

    /**
     * Get the content list
     */
    getContentList(companyId: number, collectionId: number, params: any = {}) {
        return this.http.get(`${this.adminCompanyListApi}/${companyId}/collections/${collectionId}/contents`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<ApiMarketplaceGetCatalogueResponse>) => response.body)
        );
    }

    /**
     * Get the content list meta
     */
    getContentListMeta(companyId: number, collectionId: number, params: any = {}) {
        return this.http.get(`${this.adminCompanyListApi}/${companyId}/collections/${collectionId}/contents/meta`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<any>) => response.body)
        );
    }

    /**
     * Get the content list available
     */
    getContentListAvailable(companyId: number, collectionId: number, params: any = {}) {
        return this.http.get(`${this.adminCompanyListApi}/${companyId}/collections/${collectionId}/contents/available`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<ApiMarketplaceGetCatalogueResponse>) => response.body)
        );
    }

    /**
     * Get the content list available meta
     */
    getContentListAvailableMeta(companyId: number, collectionId: number, params: any = {}) {
        return this.http.get(`${this.adminCompanyListApi}/${companyId}/collections/${collectionId}/contents/available/meta`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((response: HttpResponse<any>) => response.body)
        );
    }

    /**
     * Delete the content list
     */
    deleteContentList(companyId: number, collectionId: number, params: ApiMarketplaceDeleteCatalogueParams) {
        return this.http.delete(`${this.adminCompanyListApi}/${companyId}/collections/${collectionId}/contents`, {
            observe: 'body',
            body: params,
        });
    }

    /**
     * Post (add) the content list
     */
    postAddContentList(companyId: number, collectionId: number, params: ApiMarketplacePostAddCatalogueParams) {
        return this.http.post(`${this.adminCompanyListApi}/${companyId}/collections/${collectionId}/contents`, params, {
            observe: 'body',
        });
    }

    /**
     *
     */
    editCourseMode(courseId: number, params: ApiMarketplaceEditCourseModeParams) {
        return this.http.put(`${this.createApi}/courses/${courseId}/mode`, params, {
            observe: 'body',
        });
    }
}
