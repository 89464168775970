import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//
import { AppCommon2Module } from '@common2/common.module';
import { AppCoreModule } from '@core/core.module';
import { SharedModule } from '../../shared.module'; // TEMP
//
import { Coach2RoutingModule } from './coach-routing.module';
// Pages
// Components

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppCommon2Module,
        SharedModule,
        AppCoreModule,
        Coach2RoutingModule,
    ],
    declarations: [
        // Pages
        // Components
    ]
})

export class Coach2Module { }
