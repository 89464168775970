import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'msc-organize-knowledge-watch-content',
    templateUrl: './content.component.html'
})
export class OrganizeKnowledgeWatchContentComponent implements OnInit {
    public mode: string = 'list';

    constructor() {}

    ngOnInit(): void {}

    changeMode(mode: string): void {
        this.mode = mode;
    }
}
