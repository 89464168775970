import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataHelper, UrlHelper } from '@helpers';

@Component({
    selector: 'msc-composer-aside-embed',
    templateUrl: './embed.component.html'
})
export class ComposerAsideEmbedComponent implements OnInit {
    @ViewChild('aside') aside;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public embed: string;
    public parsed: any;
    public ready: boolean = false;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.resetParse();
    }

    private resetParse(): void {
        this.parsed = {
            url: null,
            data: null
        };
    }

    public onOpen(){
        this.aside.onOpen();
    }

    process(): void {
        this.ready = false;
        this.resetParse();
        this.ready = true;
    }

    getEmbed(): SafeResourceUrl {
        return this.parsed.url ? this.parsed.url : this.parsed.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.processUrl());
    }

    private processUrl(): string {
        if (this.embed.indexOf('<iframe') > -1) {
            return this.parsed.data = DataHelper.urlFromIframe(this.embed);
        }
        return UrlHelper.parse(this.embed, this.parsed, 'data');
    }

    save(): void {
        this.onSave.emit(this.parsed);
    }
}
