import { HttpParams } from '@angular/common/http';

export class HttpUtils {

    /**
     * Get an angular http params object
     * @param obj - Object datum
     */
    static getHttpParams(obj: object = {}): HttpParams {
        return Object.keys(obj).reduce((acc, key) => acc.append(key, obj[key]), new HttpParams());
    }

    /**
     * Get a form data params object
     * @param obj - Object datum
     */
    static getFormDataParams(obj: object = {}): FormData {
        return Object.keys(obj).reduce((acc, key) => { acc.append(key, obj[key]); return acc; }, new FormData());
    }
}
