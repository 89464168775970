<msc-common-aside #aside>
    <ng-template #headerTemplate>
        <div class="flex flex-col">
            <div class="flex space-x-2 items-center">
                <msc-button *ngIf="tab === 'community'" type="fab" icon="icon-arrow-ios-left"
                    (click)="changeTab('list')">
                </msc-button>
                <h3>
                    {{ 'instance.add-group' | translate }}
                </h3>
            </div>
            <small class="mt-2 text-left">{{ courseCamp ? courseCamp.title : externalContent.title }}</small>
        </div>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="flex flex-col space-y-4 slide-sessions-community">
            <div class="flex justify-between">
                <msc-common-search *ngIf="tab === 'community'" name="search" id="searchGroup" trigger="auto"
                    [placeholder]="'common.search' | translate" (searchEvent)="searchAvailable($event.value)">
                </msc-common-search>
                <msc-button *ngIf="tab === 'list'" class="msc-button--primary" palette="secondary" icon="icon-plus-circle"
                    (click)="changeTab('community')">
                    {{ 'words.add' | translate }}
                </msc-button>
            </div>

            <div class="dynamic-table" *ngIf="tab === 'community'">
                <div class="dynamic-table__head">
                    <div class="dynamic-table__cell cell-title--small">
                        <div>{{ 'words.name' | translate }}</div>
                    </div>

                    <div class="dynamic-table__cell">
                        {{ 'instance.number-users' | translate }}
                    </div>
                </div>

                <div class="dynamic-table__body" *ngIf="availableGroups?.length > 0 && !loading">
                    <div class="dynamic-table__row row-radius row-shadow" *ngFor="let group of availableGroups">
                        <div class="dynamic-table__cell cell-title--small flex">
                            <msc-common2-checkbox [value]="isChecked(group.id)" [label]="group.title"
                                (changeEvent)="checkGroup(group.id)"></msc-common2-checkbox>
                        </div>

                        <div class="dynamic-table__cell">
                            {{ group.usersCount }}
                        </div>
                    </div>
                </div>

                <msc-common-spinner *ngIf="loading"></msc-common-spinner>
            </div>

            <div class="dynamic-table" *ngIf="tab === 'list' && groups?.length > 0">
                <div class="dynamic-table__head">
                    <div class="dynamic-table__cell cell-title--thin">
                        {{ 'words.name' | translate }}
                    </div>

                    <div class="dynamic-table__cell">
                        {{ 'instance.number-users' | translate }}
                    </div>

                    <div class="dynamic-table__cell cell-actions"></div>
                </div>

                <div class="dynamic-table__body">
                    <div class="dynamic-table__row row-radius row-shadow" *ngFor="let group of groups">
                        <div class="dynamic-table__cell cell-title--thin">
                            {{ group.title }}
                        </div>

                        <div class="dynamic-table__cell">
                            {{ group.usersCount }}
                        </div>

                        <div class="dynamic-table__cell cell-actions">
                            <a (click)="removeGroup(group)">
                                <i class="cell-actions__icon icon icon-trash"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="tab === 'list' && groups?.length === 0">
                <h3 class="slide__subtitle"> {{ 'empty.template.groups' | translate }} </h3>
            </div>

            <msc-common-spinner *ngIf="!groups"></msc-common-spinner>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div class="flex justify-end space-x-2 grow">
            <msc-button (click)="aside.onClose()">{{ 'words.cancel' | translate }}</msc-button>
            <msc-button *ngIf="tab === 'community'" class="msc-button--primary" palette="secondary" (click)="addGroup()"
                [ngClass]="{'disabled': loading}">
                {{ 'words.save' | translate }}
            </msc-button>
        </div>
    </ng-template>
</msc-common-aside>