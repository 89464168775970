<div class="container-catalog">
    <div class="msc-banner">
        <div class="msc-banner__gradient">
            <div [ngStyle]="{'background-image': 'url(' + background + ')'}"></div>
        </div>
    </div>

    <div class="container-catalog__main animate">
        <msc-shared-topbar-filters [type]="'catalogue'" [color]="'white'" [buildFilters]="buildFilters"
            [(query)]="query" [(mode)]="mode" [(filters)]="filters" [(activeFilters)]="activeFilters"
            (modeChange)="change()" (queryChange)="search()" (filtersChange)="search()"></msc-shared-topbar-filters>

        <ul class="navigation-breadcrumb" *ngIf="mode === 'calendar'">
            <li>
                <a (click)="changeMode()">{{ 'words.catalogue' | translate }}</a>
            </li>

            <li>
                <a class="active">{{ 'words.events-calendar' | translate }}</a>
            </li>
        </ul>

        <div class="container-catalog__session"
            *ngIf="mode === 'list' && !isSearching && !searching && featured?.length > 0">
            <h2 class="container-catalog__title container-catalog__title--white">
                <span>{{ 'words.starting-soon' | translate}}</span>

                <a class="link link-showmore link-showmore--light" (click)="changeMode()">
                    {{ 'words.events-calendar' | translate }}
                    <i class="icon icon-arrow-right-1 text-primary-600"></i>
                </a>
            </h2>

            <div id="slider" class="catalogue-glide">
                <div class="catalogue-glide__track" data-glide-el="track"
                    [ngClass]="{'catalogue-glide__track--light': featured?.length > 0 && featured?.length <=2}">
                    <div class="catalogue-glide__track__content"
                        [ngClass]="{'catalogue-glide__track__content--modal-on': modalOn}">
                        <div class="card-highlight catalogue-glide__margin-card col-md-6 col-xxs-12"
                            *ngFor="let feature of featured">
                            <div class="card__header">
                                <msc-tag *ngIf="feature.data.nextEvent"
                                         [type]="'date'"
                                         [day]="feature.data.nextEvent | date: 'dd'"
                                         [month]="feature.data.nextEvent | localizedDate: 'MMM'">
                                </msc-tag>

                                <div class="card__image"
                                    [style.backgroundImage]="feature.data.picture ? 'url(' + feature.data.picture + '?size=512)' : 'url(https://static.myskillcamp.com/lxp/images/logo-empty-state-' + feature.data.id % 7 + '.jpg)'">
                                </div>

                                <msc-tag [color]="feature.type !== 'course_template' ? 'red' : ''"
                                    [text]="(feature.type === 'course_template' ? 'words.template' : 'words.journey') | translate">
                                </msc-tag>
                            </div>

                            <div class="card__content">
                                <div class="card__brand" *ngIf="feature.data.logo">
                                    <img [src]="feature.data.logo + '?size=64ar'">
                                </div>

                                <div class="card__title">
                                    <p>{{ feature.data.title }}</p>
                                </div>

                                <div class="card__skills" *ngIf="feature.data.skills && feature.data.skills.length > 0">
                                    <div class="skill skill--level skill--no-hover"
                                        *ngFor="let skill of feature.data.skills | slice:0:1" [tooltip]="skill.name">
                                        <div class="skill__content">
                                            <div class="skill__level">
                                                <span [ngClass]="{'active': skill.level > 0}"></span>
                                                <span [ngClass]="{'active': skill.level > 1}"></span>
                                                <span [ngClass]="{'active': skill.level > 2}"></span>
                                            </div>

                                            <p class="skill__text">{{ skill.name | truncate: 12 }}</p>
                                        </div>
                                    </div>

                                    <div class="skill skill--more" *ngIf="feature.data.skills.length > 1">
                                        <div class="skill__content">
                                            <div class="skill__text"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card__desc leading-3">
                                    <p
                                        [innerHTML]="feature.data.description ? feature.data.description : ('words.undescribed-content' | translate )">
                                    </p>
                                </div>

                                <div class="card__skills"
                                    *ngIf="feature.data.skills.skills && feature.data.skills.skills.length > 0">
                                    <div class="skill skill--level"
                                        *ngFor="let skill of feature.data.skills.skills | slice:0:2">
                                        <div class="skill__content">
                                            <div class="skill__level" *ngIf="skill.level > 0">
                                                <span [ngClass]="{'active': skill.level >= 1}"></span>
                                                <span [ngClass]="{'active': skill.level >= 2}"></span>
                                                <span [ngClass]="{'active': skill.level >= 3}"></span>
                                            </div>

                                            <p class="skill__text">{{ skill.name | truncate: 7 }}</p>
                                        </div>
                                    </div>

                                    <div class="skill skill--more" *ngIf="feature.data.skills.skills.length > 2">
                                        <div class="skill__content">
                                            <div class="skill__text"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card__footer">
                                    <div class="card__button">
                                        <msc-button class="msc-button--primary" palette="primary"
                                            (click)="details(feature.data)">{{ 'words.details' | translate }}
                                        </msc-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="catalogue-glide__controls hidden-md" data-glide-el="controls" *ngIf="featured?.length > 2">
                    <button class="glide-arrow glide-arrow--left text-secondary-500" data-glide-dir="<"
                        [ngClass]="{'glide-arrow--hidden': !action.back}">
                        <i class="icon icon icon-arrow-left"></i>
                    </button>

                    <button class="glide-arrow glide-arrow--right text-secondary-500" data-glide-dir=">"
                        [ngClass]="{'glide-arrow--hidden': !action.next}">
                        <i class="icon icon icon-arrow-right-1"></i>
                    </button>
                </div>

                <div class="catalogue-glide__bullets" data-glide-el="controls[nav]" *ngIf="featured?.length > 2">
                    <button class="catalogue-glide__bullets__item" [attr.data-glide-dir]="'='+index"
                        *ngFor="let index of (featured.length-(glideView-1)) | toArray"></button>
                </div>
            </div>
        </div>

        <div class="container-catalog__session"
            *ngIf="mode === 'list' && !isSearching && !searching && contents?.length > 0">
            <h2 class="container-catalog__title"
                [ngClass]="{'container-catalog__title--white': featured?.length === 0}">
                <span>
                    {{ 'words.contents' | translate }} {{ company.experience && company.experience.displayName ?
                    company.experience.displayName : company.name }}
                </span>

                <a class="link link-showmore" [routerLink]="['internal']"
                    [ngClass]="{'link-showmore--light': featured?.length === 0}">
                    {{ 'words.show-more' | translate }}
                    <i class="icon icon-arrow-right-1 text-primary-600"></i>
                </a>
            </h2>

            <div>
                <msc-learn-catalogue-slider [contents]="contents"></msc-learn-catalogue-slider>

                <div class="container-catalog__themes" *ngIf="thematics.contents?.length > 0">
                    <div class="themes-list">
                        <div class="themes-list__item" *ngFor="let thematic of thematics.contents | slice:0:10">
                            <div class="theme-tag" [routerLink]="['internal', 'thematic', thematic.id]">
                                <div class="theme-tag__content">
                                    <p class="theme-tag__text">{{ thematic.name }}</p>
                                    <p class="theme-tag__counter">{{ thematic.count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-catalog__session"
            *ngIf="mode === 'list' && !isSearching && !searching && externals?.length > 0">
            <h2 class="container-catalog__title"
                [ngClass]="{'container-catalog__title--white': featured?.length === 0 && contents?.length === 0}">
                <span>
                    {{ 'words.external-contents' | translate }}
                </span>

                <a class="link link-showmore" [routerLink]="['external']"
                    [ngClass]="{'link-showmore--light': featured?.length === 0 && contents?.length === 0}">
                    {{ 'words.show-more' | translate }}
                    <i class="icon icon-arrow-right-1 text-primary-600"></i>
                </a>
            </h2>

            <div>
                <msc-learn-catalogue-slider [contents]="externals"></msc-learn-catalogue-slider>

                <div class="container-catalog__themes">
                    <div class="themes-list" *ngIf="thematics.externals?.length > 0">
                        <div class="themes-list__item" *ngFor="let thematic of thematics.externals | slice:0:10">
                            <div class="theme-tag" [routerLink]="['external', 'thematic', thematic.id]">
                                <div class="theme-tag__content">
                                    <p class="theme-tag__text">{{ thematic.name }}</p>
                                    <p class="theme-tag__counter">{{ thematic.count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-catalog__themes">
                    <div class="themes-list" *ngIf="providers.externals?.length > 0">
                        <div class="themes-list__item" *ngFor="let external of providers.externals"
                            (click)="filter(external)">
                            <div class="theme-tag">
                                <div class="theme-tag__content">
                                    <p class="theme-tag__text">{{ translate(external) | translate }}</p>
                                    <p class="theme-tag__counter">{{ external.count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-catalog__session" *ngIf="mode === 'list' && isSearching">
            <h2 class="content-catalog__title color--white"
                *ngIf="mode === 'list' && isSearching && catalogue && catalogue?.length > 0">
                <span>
                    {{ 'words.search-results' | translate }}
                </span>
            </h2>

            <div class="container-catalog__list animate">
                <div class="card-container">
                    <content-card *ngFor="let content of catalogue" [content]="content"></content-card>
                </div>

                <msc-empty-state *ngIf="catalogue && catalogue?.length === 0" [title]="'empty.content.search.title'"
                    [description]="'empty.content.search.description'">
                </msc-empty-state>

                <div class="container__load-more col-xxs-12">
                    <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
                </div>
            </div>
        </div>

        <div class="container-catalogue-calendar__main row animate" *ngIf="mode === 'calendar'">
            <msc-shared-event-calendar [type]="'catalogue'" [events]="events" *ngIf="events?.length > 0">
            </msc-shared-event-calendar>

            <msc-load-more [loading]="loaders.calendar" [pagination]="paginationEvent" (onLoad)="loadMoreEvents()">
            </msc-load-more>

            <msc-empty-state *ngIf="events?.length === 0 && loaders.calendar === false"
                [title]="'empty.content.search.title'" [description]="'empty.content.search.description'">
            </msc-empty-state>
        </div>

        <msc-common-spinner *ngIf="searching"></msc-common-spinner>
    </div>
</div>