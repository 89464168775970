import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-common-wave',
    templateUrl: './wave.component.html',
    styleUrls: ['./wave.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonWaveComponent {

}
