import { Component, ChangeDetectionStrategy } from '@angular/core';
//
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-showroom-exhibit-toast-page',
    templateUrl: './showroom-exhibit-toast-page.component.html',
    styleUrls: ['./showroom-exhibit-toast-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitToastPageComponent {
    constructor(
        private readonly toastService: CommonToastService,
    ) { }

    /**
     *
     */
    onError(): void {
        this.toastService.onError(`Error-${Math.random()}`);
    }

    /**
     *
     */
    onInfo(): void {
        this.toastService.onInfo(`Info-${Math.random()}`);
    }

    /**
     *
     */
    onSuccess(): void {
        this.toastService.onSuccess(`Success-${Math.random()}`);
    }

    /**
     *
     */
    onWarning(): void {
        this.toastService.onWarning(`Warning-${Math.random()}`);
    }

    /**
     *
     */
    onActionBlock(): void {
        this.toastService.onInfo(`Connect simple in use design system. Connect simple in use design system`, {
            display: 'block',
            actions: [
                {
                    label: 'Action 1',
                    type: 'action',
                },
                {
                    label: 'Action 2',
                    type: 'action',
                }
            ]
        });
    }

    /**
     *
     */
    onActionInline(): void {
        this.toastService.onInfo(`Connect simple in use design system.`, {
            display: 'inline',
            actions: [
                {
                    label: 'Action 1',
                    type: 'action',
                }
            ]
        });
    }

    /**
     *
     */
    onButtonBlock(): void {
        this.toastService.onInfo(`Connect simple in use design system. Connect simple in use design system`, {
            display: 'block',
            actions: [
                {
                    label: 'Action 1',
                    type: 'button',
                    callback: (label) => {
                        alert(label);
                    }
                },
                {
                    label: 'Action 2',
                    type: 'button',
                    buttonClassType: 'primary',
                    palette: 'secondary',
                    callback: (label) => {
                        alert(label);
                    }
                }
            ]
        });
    }

    /**
     *
     */
    onButtonInline(): void {
        this.toastService.onInfo(`Connect simple in use design system.`, {
            display: 'inline',
            actions: [
                {
                    label: 'Action 1',
                    type: 'button',
                    buttonClassType: 'primary',
                    palette: 'secondary',
                    callback: (label) => {
                        alert(label);
                    }
                }
            ]
        });
    }

    /**
     *
     */
    onInline(): void {
        this.toastService.onInfo(`Connect simple in use design system.`, { display: 'inline' });
    }
}
