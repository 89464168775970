import { Component } from '@angular/core';
import { ThemingService } from '@common2/services/theming.service';

@Component({
    selector: 'msc-showroom-exhibit-theming-showroom',
    templateUrl: './showroom-exhibit-theming-page.component.html',
    styleUrls: ['./showroom-exhibit-theming-page.component.scss'],
})

export class ShowroomExhibitThemingPageComponent {
    colors = {
        primary: '',
        secondary: '',
        success: '',
        danger: '',
        error: '',
        info: ''
    }
    palettes = [];

    constructor(private themingService: ThemingService) {
        this.colors = this.themingService.getColors();
    }

    ngOnInit(): void {
        this.apply();
    }

    apply() {
        this.themingService.apply(this.colors);
        this.palettes = this.themingService.getPalettes();
    }

}
