import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpUtils } from '@common2/utils/http.utils';

import { environment } from '@env/environment';

import { COMMON_FEED_REACTIONS } from '@common/components/feed-reaction/feed-reaction.component';

export interface ApiDiscussionThreadAccountTeam {
    id: number;
    name: string;
    subscriptionType: string;
    teamManager: object;
    timelineVisibility: number;
    type: string;
}

export interface ApiDiscussionThreadAccount {
    companyName: string | null;
    conversationId: number | null;
    department: string | null;
    email: string;
    firstname: string;
    headline: string;
    id: number;
    invitation: any;
    lang: string;
    lastConnection: string | Date;
    lastname: string;
    name: string;
    phoneNumber: number | null;
    picture: string;
    teams: ApiDiscussionThreadAccountTeam[];
}

export interface ApiDiscussionThreadMedia {
    account: Partial<ApiDiscussionThreadAccount>[]; // needs to be checked
    createdAt: string | Date;
    id: number;
    isArchived: boolean;
    isDownloadable: number;
    originalUrl: string;
    picto: string;
    pictureUrl: string;
    size: number;
    status: number;
    storage: string;
    title: string;
    type: string;
    updatedAt: string | Date;
    url?: string;
    video?: any;
}

export interface ApiDiscussionThreadReaction {
    accounts: Partial<ApiDiscussionThreadAccount>[]; // only id/name/picture are sent
    count: number;
    selected: boolean;
    type: 'like' | 'not_understood' | 'love' | 'useful';
}

export interface ApiDiscussionThread {
    account: ApiDiscussionThreadAccount;
    comments: Partial<ApiDiscussionThread>[];
    commentsTotal: number;
    content: { mediaId?: number; message: string; };
    createdAt: string | Date;
    id: number;
    media?: ApiDiscussionThreadMedia;
    modified: any;
    notifiedAt: any;
    reactions: ApiDiscussionThreadReaction[];
    scope: any;
    timelineId: any;
    type: string;
    validatedAt: any;
}

export interface ApiDiscussionGetThreadsParams {
    company_id: number;
    context: string;
    context_id: number;
}

export interface ApiDiscussionGetThreadsResponse {
    items: ApiDiscussionThread[];
    lastPage: number;
    pagination: any;
    timelineId: number;
    totalItems: number;
}

export interface ApiDiscussionPostThreadParams {
    company_id: number;
    content: any;
    context: string;
    context_id: number;
    timeline_id: number;
    type: string;
}

export interface ApiDiscussionEditThreadParams extends ApiDiscussionPostThreadParams {
    //
}

export enum ApiDiscussionSubjectStatus {
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

export interface ApiDiscussionSubject {
    id: number;
    items: ApiDiscussionThread[];
    status: ApiDiscussionSubjectStatus;
}

@Injectable({ providedIn: 'root' })
export class DiscussionProvider {
    private readonly root = `${environment.envVar.API_URL}`;
    private readonly subject: BehaviorSubject<ApiDiscussionSubject> = new BehaviorSubject<ApiDiscussionSubject>({
        id: null,
        items: [],
        status: ApiDiscussionSubjectStatus.LOADING,
    });

    constructor(
        private http: HttpClient,
    ) { }

    /**
     *
     */
    setSubjectDiscussion(datum: Partial<ApiDiscussionSubject>) {
        const aux = this.subject.getValue();
        Object.keys(datum).forEach((key: string) => { aux[key] = datum[key]; });
        this.subject.next(aux);
    }

    /**
     *
     */
    getSubjectDiscussion(): Observable<any> {
        return this.subject.asObservable();
    }

    /**
     *
     */
    getSubjectDiscussionId(): number {
        return this.subject.getValue().id;
    }

    /**
     * Gets a list of threads
     */
    getThreads(params: ApiDiscussionGetThreadsParams): Observable<any> {
        return this.http.get<ApiDiscussionGetThreadsResponse>(`${this.root}/timeline/merged`, {
            observe: 'response',
            params: HttpUtils.getHttpParams(params),
        }).pipe(
            map((r: HttpResponse<ApiDiscussionGetThreadsResponse>) => this.onMapGetThreads(r.body)),
            tap((d: ApiDiscussionSubject) => this.onTapGetThreads(d))
        );
    }

    /**
     * Delete a thread
     */
    deleteThread(id: number): Observable<any> {
        return this.http.delete(`${this.root}/timelines/${this.getSubjectDiscussionId()}/items/${id}`, {
            observe: 'body'
        }).pipe(
            tap(() => this.onTapDeleteThread(id))
        );
    }

    /**
     * Edits a thread
     */
    editThread(id: number, params: ApiDiscussionEditThreadParams): Observable<ApiDiscussionThread> {
        return this.http.put(`${this.root}/timelines/${this.getSubjectDiscussionId()}/items/${id}`, params, {
            observe: 'body',
        }).pipe(
            tap((r: ApiDiscussionThread) => this.onTapEditThread(r))
        );
    }

    /**
     * Posts the creation of a thread
     */
    postThread(params: ApiDiscussionPostThreadParams): Observable<any> {
        return this.http.post<ApiDiscussionThread>(`${this.root}/timeline`, params, {
            observe: 'body',
        }).pipe(
            tap((r: ApiDiscussionThread) => this.onTapPostThread(r))
        );
    }

    /**
     * Posts the notification of a thread
     */
    notifyThread(id: number, params: any = {}): Observable<any> {
        return this.http.post(`${this.root}/timeline-item/${id}/notify`, params, {
            observe: 'body',
        }).pipe(
            //
        );
    }

    /**
     * Deletes a comment
     */
    deleteComment(id: number): Observable<any> {
        return this.http.delete(`${this.root}/timeline/comment/${id}`, {
            observe: 'body'
        }).pipe(
            //
            tap(() => this.onTapDeleteComment(id))
        );
    }

    /**
     * Posts the creation of a comment
     */
    postComment(id: number, params: any = {}): Observable<any> {
        return this.http.post(`${this.root}/timeline/${id}/content`, params, {
            observe: 'body',
        }).pipe(
            //
            tap((r: any) => this.onTapPostComment(r))
        );
    }

    /**
     * Handler for map on getThreads
     */
    onMapGetThreads(response: ApiDiscussionGetThreadsResponse) {
        const id = response.timelineId;
        const items = response.items;
        const status = ApiDiscussionSubjectStatus.SUCCESS;
        return { id, items, status };
    }

    /**
     * Handler for tap on getThreads
     */
    onTapGetThreads(response: ApiDiscussionSubject): void {
        this.setSubjectDiscussion(response);
    }

    /**
     * Handler for tap on deleteThread
     */
    onTapDeleteThread(id: number): void {
        const { items } = this.subject.getValue();
        const itemsAfterDelete = items.filter((item: any) => item.id !== id);
        this.setSubjectDiscussion({ items: itemsAfterDelete });
    }

    /**
     * Handler for tap on editThread
     */
    onTapEditThread(response: ApiDiscussionThread): void {
        const { items } = this.subject.getValue();
        const itemsAfterEdit = items.map((item) => item.id === response.id ? response : item);
        this.setSubjectDiscussion({ items: itemsAfterEdit });
    }

    /**
     * Handler for tap on postThread
     */
    onTapPostThread(response: ApiDiscussionThread): void {
        const { items } = this.subject.getValue();
        const itemsAfterPost = [response, ...items];
        this.setSubjectDiscussion({ items: itemsAfterPost });
    }

    /**
     * Handler for tap on deleteComment
     */
    onTapDeleteComment(id: number): void {
        const { items } = this.subject.getValue();
        const itemsAfterDeleteComment = items.map((item) => {
            if (item.comments) {
                item.comments = item.comments.filter((c: any) => c.id !== id);
            }
            return item;
        });
        this.setSubjectDiscussion({ items: itemsAfterDeleteComment });
    }

    /**
     * Handler for tap on postComment
     */
    onTapPostComment(response: any): void {
        const { items } = this.subject.getValue();
        const itemsAfterPostComment = items.map((item) => {
            if (item.id == parseInt(response.timelineItemId, 10)) {
                item.comments = Array.isArray(item.comments) ? [...item.comments, response] : [response];
            }
            return item;
        });
        this.setSubjectDiscussion({ items: itemsAfterPostComment });
    }

    /**
     * Handler for tap on postComment
     */
    onUpdateReaction(d: any, reactionId: number, isAdd: boolean = true) {
        const { items } = this.subject.getValue();
        const itemsAfterReactionUpdate = items.map((item) => {
            if (item.id === d.id) {
                const r = COMMON_FEED_REACTIONS.find((r) => r.id === reactionId);
                item.reactions.forEach((reaction) => {
                    if (reaction.type === r.type) {
                        if (isAdd) {
                            reaction.count = reaction.count + 1;
                        } else {
                            reaction.count = reaction.count - 1;
                        }
                    }
                });
            }
            return item;
        });
        this.setSubjectDiscussion({ items: itemsAfterReactionUpdate });
    }
}
