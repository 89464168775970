import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Camp, Company, CompanyProvider, ReportingProvider, IntegrationProvider } from '@lighty';
import { ErrorStreamService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { HttpHelper, DownloadHelper } from '@helpers';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-stats-providers-detail',
    templateUrl: './detail.component.html'
})
export class CampStatsProvidersDetailComponent implements OnInit {
    private company: Company;
    private camp: Camp;
    public provider: any;
    public providerStat: any;
    public query: string;
    public loaders: any;
    public availableCamps: Camp[];
    public dates: any;
    public displays: any;
    public granularity: string = 'month';
    public warning: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private storageService: StorageService,
        private reportingProvider: ReportingProvider,
        private errorStreamService: ErrorStreamService,
        private toastService: CommonToastService,
        private externalAppsService: ExternalAppsService,
        private companyProvider: CompanyProvider,
        private translateService: TranslateService,
        private integrationProvider: IntegrationProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.camp = this.storageService.get('camp');

        this.provider = this.route.snapshot.data.provider;

        this.dates = {
            start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            startQual: moment(moment().subtract(30, 'days')).subtract(3, 'months').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            diff: 30,
            diffQual: 122,
            type: 'last30days'
        };

        if (this.provider.name === 'bookboon' || this.provider.name === 'openclassroom' || this.provider.name === 'udemy') {
            this.warning = true;
        }

        this.displays = {
            date: false,
            granularity: false,
            export: false,
            setUp: false
        };

        this.loaders = {
            loading: true,
            download: false
        };

        this.getProvider();
        this.getLicenceUsed();
        this.getAvailableCamps();
    }

    private getProvider(): void {
        const params = {
            filters: {
                calendar: [this.dates.startQual, this.dates.end],
                camp: [this.camp.id]
            }
        };

        this.reportingProvider.provider(this.company.id, this.provider.id, params).subscribe((data) => {
            this.providerStat = data;
            this.loaders.loading = false;
        });
    }

    private getStartDate(): string {
        switch (this.dates.type) {
            case 'last30days':
            case 'currentMonth':
            case 'lastMonth':
                return moment(this.dates.start).subtract(3, 'month').format('YYYY-MM-DD');
            case 'currentQuarter':
            case 'lastQuarter':
                return moment(this.dates.start).subtract(3, 'quarter').format('YYYY-MM-DD');
            case 'currentYear':
            case 'lastYear':
                return moment(this.dates.start).subtract(3, 'year').format('YYYY-MM-DD');
            default:
                return this.dates.start;
        }
    }

    private getAvailableCamps(): void {
        this.companyProvider.getCamps(this.company.id).subscribe((data) => {
            this.availableCamps = data.camps;
        });
    }

    search(): void {
        this.loaders.loading = true;
        const params = {
            filters: HttpHelper.cleanParams({
                calendar: [this.dates.startQual, this.dates.end],
                camp: [this.camp.id]
            })
        };

        this.reportingProvider.provider(this.company.id, this.provider.id, params).subscribe((data) => {
            this.providerStat = data;
            this.loaders.loading = false;
        });
    }

    download(options: any): void {
        this.loaders.download = true;
        this.errorStreamService.locked();
        this.displayed('export');

        const params = {
            filters: HttpHelper.cleanParams({
                calendar: [this.dates.start, this.dates.end],
                camp: [this.camp.id]
            }),
            export: options
        };

        if (options.granularity !== 'days') {
            this.toastService.onInfo(this.translateService.instant('toast.downloading.soon'));
        }

        this.reportingProvider.exportProvider(this.company.id, this.provider.id, params).subscribe((data) => {
            if (data) {
                DownloadHelper.downloadBlob(data, `export-${this.company.id}.${options.format}`);
            } else {
                this.toastService.onSuccess(this.translateService.instant('toast.send-file-mail'));
            }

            this.loaders.download = false;
            this.errorStreamService.unlocked();
        }, (error) => {
            this.loaders.download = false;
            this.errorStreamService.unlocked();
        });
    }

    displayed(type: string, force?: boolean): void {
        this.displays[type] = force ? false : !this.displays[type];
    }

    updateDates(): void {
        this.dates.startQual = this.getStartDate();
        this.dates.diffQual = moment(this.dates.end).diff(this.dates.startQual, 'days');

        if (this.dates.diffQual > 365) {
            this.granularity = 'year';
        } else if (this.dates.diffQual > 60) {
            this.granularity = 'month';
        } else {
            this.granularity = 'day';
        }

        this.displays.granularity = false;
        this.displayed('date');
        this.search();
    }

    total(stats: any): number {
        if (this.dates.type === 'custom') {
            return null;
        }

        return stats.reduce((total, stat) => {
            return total + stat.count;
        }, 0);
    }

    getDuration(type: string, seconds: number): string {
        let time = Math.floor(seconds / 60) % 60;
        let unit = this.translateService.instant('unit.minute');

        switch (type) {
            case 'hours':
                time = Math.floor(seconds / 3600) % 24;
                unit = this.translateService.instant('unit.hour');
                break;
            case 'days':
                time = Math.floor(seconds / 86400);
                unit = this.translateService.instant('unit.day');
                break;
        }

        return `${time > 0 ? time + unit.toLocaleLowerCase() : ''}`;
    }

    updateLicense(provider: any): void {
        const params = {
            licenses_available: provider.licensesAvailable
        };

        this.integrationProvider.updateProvider(this.company.id, provider.id, params).subscribe((data) => {
            this.displays.setUp = false;
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    getLicenceUsed(): void {
        const params = {
            filters: HttpHelper.cleanParams({
                calendar: [this.company.createdAt, this.dates.end],
                camp: [this.camp.id],
                provider: [this.provider.id]
            })
        };

        this.reportingProvider.providers(this.company.id, params).subscribe((data: any) => {
            this.provider.licensesUsed = data.learnerContentProviderSubscribed.length > 0 ? data.learnerContentProviderSubscribed[0].count : 0;
        });
    }
}
