import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, ExternalContentProvider, ReportingProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-learn-detail-external-content-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})

export class LearnDetailExternalContentResultComponent {
    private company: Company;
    public externalContent: any;
    public externalContentSession: any;
    public sessions: any = [];
    public selectedSession: any;
    public duration: number = 0;
    public displays: any;
    public loading = {
        logs: true,
        createLog: false,
        updateLog: false,
        deleteLog: false,
    };
    public data: any;

    constructor(
        private externalContentProvider: ExternalContentProvider,
        private route: ActivatedRoute,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private translateService: TranslateService,
        private reportingProvider: ReportingProvider,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.externalContent = this.route.parent.snapshot.data.externalContent;
        this.getSession();
        this.getLogs();

        this.displays = {
            time: true,
            datepicker: false,
            addTime: false,
            datepickers: [],
            modals: {
                edit: []
            }
        };

        this.resetData();
    }

    displayed(key: string): void {
        this.displays[key] = !this.displays[key];
    }

    displayedDatepickers(index: number): void {
        const enable = !this.displays.datepickers[index];
        this.displays.datepickers = [];
        this.displays.datepickers[index] = enable;
    }

    displayedModals(type: string, index: number, enable: boolean = true): void {
        this.displays.modals[type][index] = enable;
    }

    getDuration(type: string, duration?: number): string {
        let time = duration ? Math.floor(duration / 60 % 60) : Math.floor(this.duration / 60 % 60);
        let unit = time > 1 ? this.translateService.instant('words.minutes') : this.translateService.instant('words.minute');

        switch (type) {
            case 'days':
                time = duration ? Math.floor(duration / 86400) : Math.floor(this.duration / 86400);
                unit = time > 1 ? this.translateService.instant('words.days') : this.translateService.instant('words.day');
                break;
            case 'hours':
                time = duration ? Math.floor(duration / 3600 % 24) : Math.floor(this.duration / 3600 % 24);
                unit = time > 1 ? this.translateService.instant('words.hours') : this.translateService.instant('words.hour');
                break;
        }

        return `${time > 0 ? time + ' ' + unit.toLocaleLowerCase() : ''}`;
    }

    convertSessionDuration(session: any): string {
        if (!session.durationDiff) {
            return '';
        }
        const days = Math.floor(session.durationDiff / 86400);
        const hours = Math.floor(session.durationDiff / 3600 % 24);
        const minutes = Math.floor(session.durationDiff / 60 % 60);
        const seconds = Math.floor(session.durationDiff % 60);
        session.timeUnit = seconds > 0 ? 'seconds' : (minutes > 0 ? 'minutes' : (hours > 0 ? 'hours' : (days > 0 ? 'days' : '')));

        return this.getDuration(session.timeUnit, session.durationDiff);
    }

    private getSession(): void {
        this.externalContentProvider.getSession(this.externalContent.id).subscribe((data) => {
            this.externalContentSession = data;
        });
    }

    private getLogs(): void {
        this.loading.logs = true;
        if (this.externalContent.type === 'video') {
            this.reportingProvider.getContentLogs(this.storageService.get('company').id, this.externalContent.id, this.storageService.get('me').id, 'external_video').subscribe(data => {
                this.sessions = data.map(({ source }) => ({ date: source.date, ...(({ duration, durationDiff }) => ({ duration, durationDiff }))(source.context) }));
                this.duration = this.sessions.length > 0 ? this.sessions[0].duration : 0;
                this.loading.logs = false;
            });
        } else {
            this.reportingProvider.getContentLogs(this.storageService.get('company').id, this.externalContent.id, this.storageService.get('me').id, 'external_content').subscribe(data => {
                this.sessions = data.map(({ source }) => ({ date: source.date, ...(({ duration, durationDiff }) => ({ duration, durationDiff }))(source.context) }));
                this.duration = this.sessions.length > 0 ? this.sessions[0].duration : 0;
                this.loading.logs = false;
            });
        }
    }

    addLog(): void {
        this.loading.createLog = true;
        let duration = 0;

        switch (this.data.unit) {
            case 'minutes':
                duration = this.data.value * 60;
                break;
            case 'hours':
                duration = this.data.value * 60 * 60;
                break;
            case 'days':
                duration = this.data.value * 60 * 60 * 24;
                break;
        }

        const params = {
            companyId: this.company.id,
            date: this.data.date,
            durationDiff: duration
        };

        if (this.externalContent.type === 'video') {
            this.externalContentProvider.createSessionLogVideo(this.externalContent.id, params).subscribe(() => {
                this.loading.createLog = false;
                this.getLogs();
                this.toastService.onSuccess(this.translateService.instant('toast.log-created'));
                this.displayed('addTime');
            });
        } else {
            this.externalContentProvider.createSessionLog(this.externalContent.id, params).subscribe(() => {
                this.loading.createLog = false;
                this.getLogs();
                this.toastService.onSuccess(this.translateService.instant('toast.log-created'));
                this.displayed('addTime');
            });
        }
    }

    updateDate(date: any): void {
        this.data.date = date;
    }

    updateDateEdit(date: any): void {
        this.data.updateDate = date;
    }

    resetData(): void {
        this.data = {
            value: 0,
            unit: 'minutes',
            date: moment().format('YYYY-MM-DD H:mm:ss'),
            updateDate: null,
        };
    }

    reset(): void {
        this.selectedSession = null;
    }

    finished(): void {
        const params = {
            date: moment().format('YYYY-MM-DD'),
            progress: 100,
            context: this.externalContent.type === 'video' ? 'video' : 'content',
            company_id: this.storageService.get('company').id
        };

        this.externalContentProvider.updateSession(this.externalContent.id, params).subscribe(() => {
            this.externalContentSession.doneAt = moment().format('YYYY-MM-DD');
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }
}
