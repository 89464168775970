import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Media } from './media';
import { environment } from '@env/environment';

@Injectable()
export class MediaProvider {

    constructor(private http: HttpClient) {}

    getMedia(id: number): Observable<Media> {
        if (typeof id !== 'number') { return; }
        return this.http.get(`${environment.envVar.API_URL}/media/${id}/show`).pipe(
            map((data: any) => new Media(data.media))
        );
    }

    getMedias(type: string): Observable<any> {
        const response = {
            medias: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/folder/0?type=${type}`).pipe(
            map((data: any) => {
                data.folder.medias.map((media) => {
                    response.medias.push(new Media(media));
                });
                response.pagination = data.pagination;
                return response;
            })
        );
    }

    getStatus(id: number): Observable<any> {
        if (typeof id !== 'number') { return; }
        return this.http.get(`${environment.envVar.API_URL}/media/${id}/status`).pipe(
            map(data => data)
        );
    }

    uploadMedia(file: File): Observable<any> {
        const formdata: FormData = new FormData();
        formdata.append('file', file);

        return this.http.post(`${environment.envVar.API_URL}/media`, formdata).pipe(
            map(data => data)
        );
    }

    uploadEmbed(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/media/embed`, params).pipe(
            map(data => data)
        );
    }

    deleteMedia(id: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/media/${id}`, {params}).pipe(
            map(data => data)
        );
    }
}
