<div class="navigation-filter" [ngClass]="{'navigation-filter--white': color === 'white'}">
    <div class="navigation-filter__separator flex flex-col items-start gap-4 xl:flex-row">

        <msc-common-search *ngIf="mode === 'list' || !mode" trigger="auto"
        [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

        <div class="flex space-x-2">
            <ng-container *ngIf="availableTypes">
                <msc-common-select class="msc-select--small" [data]="availableTypes" [isButton]="true" type="multi">
                    <ng-template #buttonTemplate let-data="data">
                        <span class="font-bold text-xs text-gray-600">{{ 'words.types' | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <label class="flex w-full cursor-pointer">
                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectType(datum)">
                            </msc-common2-checkbox>
                            <span class="ml-2 text-sm truncate">{{ datum.label }}</span>
                        </label>
                    </ng-template>
                </msc-common-select>
            </ng-container>

            <div class="hidden sm:block">
                <msc-button element #selectDuration="element"
                    (click)="onDurationOpen(); overlayDuration.open(selectDuration.elementRef)">{{ 'words.duration' |
                    translate }}</msc-button>

                <msc-common-overlay #overlayDuration direction="bottom-start">
                    <ng-template>
                        <div class="container-rangeslider">
                            <div class="py-6 mb-6">
                                <msc-range-slider [(config)]="tempSlider"></msc-range-slider>
                            </div>

                            <div class="flex justify-end gap-2">
                                <msc-button class="msc-button--tiny" (click)="overlayDuration.close()">{{ 'words.cancel'
                                    | translate }}</msc-button>
                                <msc-button class="msc-button--primary msc-button--tiny" palette="secondary"
                                    (click)="saveDuration(); overlayDuration.close()">{{ 'words.save' | translate }}
                                </msc-button>
                            </div>
                        </div>
                    </ng-template>
                </msc-common-overlay>
            </div>

            <div class="hidden sm:block">
                <msc-button element #selectDates="element" (click)="overlayDates.open(selectDates.elementRef)">{{ 'words.dates' |
                    translate }}</msc-button>
                <msc-common-overlay #overlayDates direction="bottom-start">
                    <ng-template>
                        <msc-daterangepicker [(dates)]="dates" (onClose)="overlayDates.close()"
                            (onSave)="saveFilters()"></msc-daterangepicker>
                    </ng-template>
                </msc-common-overlay>
            </div>

            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                {{ 'words.filters' | translate }}
            </msc-button>

            <ng-container *ngIf="activeFilters">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh"
                    (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>
        </div>

        <div class="separator__actions hidden-sm hidden-md">
            <msc-common-select class="msc-select--small w-[240px] text-sm" [data]="selectOrderOptions"
                (selectEvent)="onSelectOption($event)">
                <ng-template #buttonTemplate let-data="data">
                    <button class="text-slate-500 truncate">
                        <ng-container *ngIf="data?.length">
                            <span>{{ 'filter.sorted-by' | translate }} : {{ data[0].label | translate }}</span>
                        </ng-container>
                    </button>
                </ng-template>
            </msc-common-select>

            <div class="divider divider--margin" *ngIf="type === 'catalogue' || type === 'content'"></div>

            <div class="separator__actions__later" *ngIf="type === 'catalogue'" (click)="navigateSavedContent()">
                <i class="icon icon-bookmark-1"></i>
            </div>

            <div class="separator__actions__extension" *ngIf="type === 'content'">
                <msc-button class="msc-button--primary" type="fab" palette="secondary" icon="icon-pin-2"
                    (click)="toggleExtension()"></msc-button>
            </div>

            <div class="divider" *ngIf="type === 'catalogue' || type === 'content'"></div>

            <div class="separator__actions__text tooltip tooltip--top" *ngIf="mode"
                [attr.data-tooltip]="'words.events-calendar' | translate">
                <i class="icon icon-calendar"></i>
            </div>

            <ng-container *ngIf="mode">
                <msc-common2-switch [value]="mode === 'calendar'" (changeEvent)="changeMode()"></msc-common2-switch>
            </ng-container>
        </div>
    </div>
</div>

<msc-filters-modal #modalFilters [filters]="filtersModal" (saveEvent)="onFilterSave($event)"></msc-filters-modal>
