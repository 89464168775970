import { Component, Renderer2, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import { CompanyExperience, CompanyUserProvider, Company, LanguageProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { of, Observable, take } from 'rxjs';
import { OrganizeUserStepBaseComponent } from '../configure/configure.component.base';
import { CommonToastService } from '@common2/services/toast.service';
import { CommonModalComponent } from '@common2/components/modal/modal.component';

@Component({
    selector: 'msc-organize-user-invitation',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.scss']
})
export class OrganizeUserUpdateComponent extends OrganizeUserStepBaseComponent implements OnInit {
    @ViewChild('csvFile') csvFile: ElementRef;
    @ViewChild('downloadUrl') downloadUrl: ElementRef;
    @ViewChild('onboardingForm') onboardingForm;
    @ViewChild('modalConfirmation') modalConfirmation: CommonModalComponent;
    private company: Company;
    private me;
    public isImport: boolean = false;
    public currentStep: number = 0;
    public data: any;
    public campsSelected: any[] = [];
    public campsSelectedLabel: string = '';
    public emailsSelected: any[] = [];
    public experienceSelected: CompanyExperience;
    public formErrors: any = {};
    public exampleUrl: string = (environment.envVar.API_URL + '/').replace('/api/', '/template/import-users-example.xlsx');
    public loading: boolean;
    public invitationForm: OrganizeUserUpdateComponent;
    saved: boolean = false;
    customMessage: any;
    languages;
    customMessagesCount: any;
    emailTemplate: string;
    emailTemplateRaw: string;
    public selectData: any = {
        interfaceLanguages: [],
        messageLanguages: [],
        camps: [],
        experiences: []
    }

    constructor(
        private companyUserProvider: CompanyUserProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private translateService: TranslateService,
        public languageProvider: LanguageProvider,
        private router: Router
    ) {
        super();
        this.invitationForm = this;
    }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.data = {
            file: undefined,
            experience_id: null,
        };
    
        this.isImport = !!this.route.snapshot.queryParamMap.get('type');
        this.company = this.storageService.get('company');
        this.getAvailableExperiences();
    }


    private getAvailableExperiences(): void {
        this.companyUserProvider.getExperiences(this.company.slug).subscribe((data) => {
            this.selectExperience(data[0]);
            this.selectData.experiences = this.getSelectData(data);
        });
    }

    getSelectData(data) {
        return data.map((d, index) => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: index === 0
        }));
    }

    onSelectOption(option, key: string) {
        this.selectData[key] = this.selectData[key].map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });

        switch (key) {
            case 'experiences': return this.selectExperience(option.value);
        }
    }

    private resetData(): void {
        this.data.experience_id = null;
        this.data.file = undefined;
    }

    isValid(): boolean {
        this.formErrors = {};
        this.data.experience_id = this.experienceSelected.id;



        if (this.isImport && !this.data.file) {
            this.toastService.onError(this.translateService.instant('toast.import.file.required'));
            return false;
        }

        return !Object.values(this.formErrors).some(error => error);
    }

    selectExperience(experience: CompanyExperience): void {
        this.experienceSelected = experience;
        this.data.experience_id = experience.id;
    }

    save(): void {
        if (this.isValid()) {
            this.resetData();
        }
    }

    toApiData(): void {
        return;
    }


    toReadableData(): any {
        return {
            label: 'words.personal-data',
            data: [
                {
                    label: 'words.upload',
                    data: [this.data.file?.name]
                },
                {
                    label: 'words.learn',
                    data: this.campsSelected.map(camp => camp.name)
                },
                {
                    label: 'words.experience',
                    data: [this.experienceSelected.name]
                },
                {
                    label: 'words.email-notification',
                    data: [
                        `words.notification.${this.data.emailStatus}`
                    ]
                },
            ]
        };
    }

    private processErrorResponse(data: any): void {
        if (data.error && data.error.missingColumns) {
            this.toastService.onError(this.translateService.instant('toast.missing.columns', { value: data.error.missingColumns.join(', ') }));
        } else {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    private processInviteResponse(data: any): void {
        if (data.createdUsers.length > 0) {
            this.toastService.onSuccess(this.translateService.instant(data.createdUsers.length > 1 ? 'toast.users-added' : 'toast.user-added', { value: data.createdUsers.length }));
        }

        if (data.noPlaceLeft.length > 0) {
            this.toastService.onWarning(this.translateService.instant('toast.limit-users-reached'));
        }

        if (data.undefinedError.length > 0) {
            this.toastService.onError(this.translateService.instant(data.undefinedError.length > 1 ? 'toast.invitations-users-error' : 'toast.invitations-user-error', { value: data.undefinedError.length }));
        }
    }

    importCSV(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.csvFile.nativeElement).dispatchEvent(event);
    }

    updateFile(file?: File): void {
        this.data.file = file;
    }

    downloadExample(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.downloadUrl.nativeElement).dispatchEvent(event);
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (!this.saved && (this.data.file)) {
            const result = window.confirm(this.translateService.getTranslate('integrations.rules.leave-unsaved'));
            return of(result);
        }
        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    leavePage($event: any): void {
        if (!this.saved && (this.data.file)) {
            $event.returnValue = 'Your data will be lost!';
        }
    }

    public openConfirmacionModal(): void {
        this.modalConfirmation.onOpen();
    }

    public updateUsers(): void {
        this.companyUserProvider.updateUsers(this.company.id,this.experienceSelected.id ,this.data).pipe(take(1)).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant('toast.users-added-undefined'));
            this.modalConfirmation.onClose();
            this.router.navigate(['../../../users'], { relativeTo: this.route });
            this.resetData();
        },
        (err) => {
            this.toastService.onError(this.translateService.instant('toast.users-bad-file'));
        })
    }
}
