<div class="flex flex-col">
    <div class="slide-external-link__body grow">
        <div class="slide-external-link__form">
            <label class="slide-external-link__choice-block" [ngClass]="{'checked': extensionLink.savein.content}" tabindex="0" (keyup.enter)="checkTheBox('content')">
                <msc-common2-checkbox [value]="extensionLink.savein.content" (changeEvent)="extensionLink.savein.content = !extensionLink.savein.content"></msc-common2-checkbox>

                <i class="icon icon-catalogue"></i>

                <div class="slide-external-link__choice-block__infos">
                    <p>{{ 'extension.choice.content' | translate }}</p>
                    <span>{{ 'extension.choice.content.description' | translate }}</span>
                </div>
            </label>

            <label class="slide-external-link__choice-block" [ngClass]="{'checked': extensionLink.savein.camps}" *ngIf="extensionLink.roles.companyAdministrator" tabindex="0" (keyup.enter)="checkTheBox('camps')">
                <msc-common2-checkbox [value]="extensionLink.savein.camps" (changeEvent)="extensionLink.savein.camps = !extensionLink.savein.camps"></msc-common2-checkbox>
                <i class="icon icon-people"></i>
                <div class="slide-external-link__choice-block__infos">
                    <p>{{ 'extension.choice.camp' | translate }}</p>
                    <span>{{ 'extension.choice.camp.description' | translate }}</span>
                </div>
            </label>

            <label class="slide-external-link__choice-block" [ngClass]="{'checked': extensionLink.savein.community}" tabindex="0" (keyup.enter)="checkTheBox('community')">
                <msc-common2-checkbox [value]="extensionLink.savein.community" (changeEvent)="extensionLink.savein.community = !extensionLink.savein.community"></msc-common2-checkbox>
                <i class="icon icon-people"></i>
                <div class="slide-external-link__choice-block__infos">
                    <p>{{ 'extension.choice.community' | translate }}</p>
                    <span>{{ 'extension.choice.community.description' | translate }}</span>
                </div>
            </label>
        </div>
    </div>

    <div class="flex justify-end">
        <msc-button class="msc-button--primary" palette="secondary" (click)="nextPanel()" [disabled]="disableClick()">{{ 'words.next' | translate }}</msc-button>
    </div>
</div>
