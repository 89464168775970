import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {
    ContentProvider, InstanceProvider, JourneyProvider,
    TemplateProvider, GroupProvider,
    ExternalContentProvider, OnboardingProvider
} from '@lighty';
import { HttpHelper } from '@helpers';

@Injectable()
export class OnboardingResolver implements Resolve<any> {
    constructor(private onboardingProvider: OnboardingProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.paramMap.get('slug')) {
            const params = {
                company_id: route.parent.paramMap.get('slug')
            };
            return this.onboardingProvider.getOnboardingContent(params);
        }
    }
}

@Injectable()
export class ContentResolver implements Resolve<any> {
    constructor(private contentProvider: ContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            const params = {
                company_id: route.parent.parent.paramMap.get('slug')
            };
            return this.contentProvider.getContents(params);
        }
    }
}

@Injectable()
export class ContentSavedResolver implements Resolve<any> {
    constructor(private contentProvider: ContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            const params = {
                company_id: route.parent.parent.paramMap.get('slug')
            };
            return this.contentProvider.getSavedContents(params);
        }
    }
}

@Injectable()
export class CatalogueResolver implements Resolve<any> {
    constructor(private contentProvider: ContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            const params = HttpHelper.cleanParams({
                company_id: route.parent.parent.paramMap.get('slug'),
                // langs: route.parent.parent.data.me.learningLanguages.map(lang => lang.id).join(',')
            });
            return this.contentProvider.getOverview(params);
        }
    }
}

@Injectable()
export class CatalogueSkillsResolver implements Resolve<any> {
    constructor(private contentProvider: ContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            const params = {
                company_id: route.parent.parent.paramMap.get('slug'),
                browse: route.paramMap.get('type'),
            };

            if (route.paramMap.get('thematicId')) {
                if (route.paramMap.get('level')) {
                    return this.contentProvider.getCatalogueSkillLevel(parseInt(route.paramMap.get('thematicId'), 10), parseInt(route.paramMap.get('level'), 10), params);
                }
                return this.contentProvider.getCatalogueSkill(parseInt(route.paramMap.get('thematicId'), 10), params);
            }
            return this.contentProvider.getCatalogueSkills(params);
        }
    }
}

@Injectable()
export class CommunityResolver implements Resolve<any> {
    constructor(private groupProvider: GroupProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            if (route.paramMap.get('communityId')) {
                const paramsGroup = {
                    include: 'account,users_preview',
                };
                return this.groupProvider.getGroup(
                    route.parent.parent.paramMap.get('slug'),
                    parseInt(route.paramMap.get('communityId'), 10),
                    paramsGroup
                );
            }

            const params = {
                include: 'account,subscription,users_preview',
                status: 'allGroups'
            };
            return this.groupProvider.get(route.parent.parent.paramMap.get('slug'), params);
        }
    }
}

@Injectable()
export class LearningGroupsResolver implements Resolve<any> {
    constructor(private groupProvider: GroupProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.groupProvider.getLearningGroups(route.parent.parent.paramMap.get('slug'));
    }
}

@Injectable()
export class LearningGroupResolver implements Resolve<any> {
    constructor(private groupProvider: GroupProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return this.groupProvider.getLearningGroup(route.parent.parent.paramMap.get('slug'), route.params.groupId);
    }
}

@Injectable()
export class InstanceResolver implements Resolve<any> {
    constructor(private instanceProvider: InstanceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.instanceProvider.get(parseInt(route.paramMap.get('instanceId'), 10));
    }
}

@Injectable()
export class InstanceCorrectionsResolver implements Resolve<any> {
    constructor(private instanceProvider: InstanceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.instanceProvider.getCorrections(parseInt(route.paramMap.get('instanceId'), 10));
    }
}

@Injectable()
export class InstanceSummaryResolver implements Resolve<any> {
    constructor(private instanceProvider: InstanceProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.instanceProvider.getSummary(parseInt(route.paramMap.get('instanceId'), 10));
    }
}

@Injectable()
export class TemplateResolver implements Resolve<any> {
    constructor(private templateProvider: TemplateProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            const params = HttpHelper.cleanParams({
                include: 'course_camps,course_camps.courseCampSessions,course_camps.course.events,course_camps.course.events.event_sessions,course_camps.seats.users',
                company_id: route.parent.parent.paramMap.get('slug')
            });
            return this.templateProvider.get(parseInt(route.paramMap.get('templateId'), 10), params);
        }
    }
}

@Injectable()
export class TemplateSummaryResolver implements Resolve<any> {
    constructor(private templateProvider: TemplateProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.templateProvider.getSummary(parseInt(route.paramMap.get('templateId'), 10));
    }
}

@Injectable()
export class JourneyResolver implements Resolve<any> {
    constructor(private journeyProvider: JourneyProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            return this.journeyProvider.get(route.parent.parent.paramMap.get('slug'), parseInt(route.paramMap.get('journeyId'), 10));
        }
    }
}

@Injectable()
export class JourneyDetailsCorrectionsResolver implements Resolve<any> {
    constructor(private journeyProvider: JourneyProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            return this.journeyProvider.getCorrections(route.parent.parent.paramMap.get('slug'), parseInt(route.paramMap.get('journeyId'), 10));
        }
    }
}

@Injectable()
export class EdflexResolver implements Resolve<any> {
    constructor(private externalContentProvider: ExternalContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            const params = HttpHelper.cleanParams({
                company_id: route.parent.parent.paramMap.get('slug'),
            });
            return this.externalContentProvider.getEdflex(parseInt(route.paramMap.get('edflexId'), 10), params);
        }
    }
}

@Injectable()
export class ExternalContentResolver implements Resolve<any> {
    constructor(private externalContentProvider: ExternalContentProvider) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (route.parent.parent.paramMap.get('slug')) {
            let context = '';
            switch (route.paramMap.get('externalContentContext')) {
                case 'video':
                case 'external_video':
                    context = 'video';
                    break;
                default:
                    context = 'content';
                    break;
            }

            const params = HttpHelper.cleanParams({
                context,
                company_id: route.queryParams.companyId ? route.queryParams.companyId : route.parent.parent.paramMap.get('slug'),
            });

            return this.externalContentProvider.getExternalContentDetails(parseInt(route.paramMap.get('externalContentId'), 10), params);
        }
    }
}

@Injectable()
export class ExternalContentApiCallCopyResolver implements Resolve<any> {
    constructor(private externalContentProvider: ExternalContentProvider) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.parent.parent.paramMap.get('slug')) {
            let context = '';
            switch (route.paramMap.get('externalContentContext')) {
                case 'video':
                case 'external_video':
                    context = 'video';
                    break;
                default:
                    context = 'content';
                    break;
            }
            const params = HttpHelper.cleanParams({
                context,
                company_id: route.queryParams.companyId ? route.queryParams.companyId : route.parent.parent.paramMap.get('slug'),
            });
            return () => this.externalContentProvider.getExternalContentDetails(parseInt(route.paramMap.get('externalContentId'), 10), params);
        }
    }
}
