import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '@lighty';
import { ExtensionLink } from '@interfaces';
import { EmitterService, StorageService } from '@services';

@Component({
    selector: 'msc-shared-aside-extension-success',
    templateUrl: './extension-success.component.html'
})
export class SharedExtensionSuccessComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();
    @Output() onCloseAside: EventEmitter<any> = new EventEmitter();
    private company: Company;

    constructor(private emitterService: EmitterService, private router: Router, private storageService: StorageService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.emitterService.set('refresh.extension', true);
    }

    navigate(): void {
        this.router.navigate(['learn', this.company.slug, 'detail', 'external-content', 'external_resource', this.extensionLink?.id, 'presentation']);
    }
}
