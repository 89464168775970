import { Component } from '@angular/core';

@Component({
    selector: 'msc-learn-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})

export class LearnContentComponent {

}
