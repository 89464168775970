<msc-common-aside #aside [hasActionButtons]="false">
    <ng-template #headerTemplate>
        <h3>{{ category.name }}</h3>

        <!-- <i class="icon icon-close" (click)="aside.onClose()"></i> -->
    </ng-template>

    <ng-template #asideTemplate>
        <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

        <msc-common-spinner *ngIf="loaders.contents"></msc-common-spinner>

        <msc-empty-state *ngIf="contents?.length === 0 && !loaders.contents" [title]="'integrations.rules.no-content-selected' | translate"></msc-empty-state>
    
        <ul class="flex wrap gap-2 mt-2" *ngIf="!loaders.contents && contents.length > 0">
            <li *ngFor="let content of contents">
                <div class="card card--min">
                    <div class="card__header">
                        <div class="card__radio"></div>

                        <div class="card__tags flex justify-end">
                            <div class="tag">
                                <div class="tag__text">{{ content.uiTypeLabel }}</div>
                            </div>
                        </div>

                        <div class="card__image">
                            <div [style.backgroundImage]="content.mediaUrl"></div>
                        </div>
                    </div>

                    <div class="card__body">
                        <div class="card__time">
                            <span class="card_time_text">{{ content.duration | duration }}</span>
                        </div>

                        <div class="flex" *ngIf="content.categories?.length > 0">
                            <div class="skill skill--level skill--no-hover" *ngIf="content.categories.length > 0">
                                <div class="skill__content" [tooltip]="content.categories[0].name.length > 15 ? content.categories[0].name : ''">
                                    <p class="skill__text">{{ content.categories[0].name | truncate: 15 }}</p>
                                </div>
                            </div>
                            <div class="skill skill--more" *ngIf="content.categories.length > 1" [tooltip]="getCategoriesName(content)">
                                <div class="skill__content">
                                    <div class="skill__text"></div>
                                </div>
                            </div>
                        </div>

                        <p class="card__title" [tooltip]="content.title.length > 45 ? content.title : ''">{{ content.title | truncate: 45 }}</p>
                    </div>
                </div>
            </li>
        </ul>
    
        <msc-load-more *ngIf="!loaders.contents && pagination && pagination.currentPage < pagination.lastPage" [pagination]="pagination" [loading]="loaders.loadMore" (onLoad)="loadMore()"></msc-load-more>
    </ng-template>
</msc-common-aside>