import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class CompanyUrlProvider {

    constructor(private http: HttpClient) {}

    get(slug: string | number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${slug}/urls`, {params}).pipe(
            map((data: any) => data.urls)
        );
    }

    create(slug: string | number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${slug}/urls`, params).pipe(
            map(data => data)
        );
    }

    getUrl(slug: string | number, urlId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${slug}/urls/${urlId}`, {params}).pipe(
            map(data => data)
        );
    }

    getHash(slug: string | number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${slug}/urls/hashes`, {params}).pipe(
            map(data => data)
        );
    }

    update(slug: string | number, urlId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${slug}/urls/${urlId}`, params).pipe(
            map(data => data)
        );
    }

    delete(slug: string | number, urlId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${slug}/urls/${urlId}`, {params}).pipe(
            map(data => data)
        );
    }

    validate(slug: string | number, urlId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${slug}/urls/${urlId}/validate`, params).pipe(
            map(data => data)
        );
    }

    notify(id: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/urls/notify-sales`, params).pipe(
            map(data => data)
        );
    }

    updateExperienceUrl(slug: string | number, experienceId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${slug}/experiences/${experienceId}/url`, params).pipe(
            map(data => data)
        );
    }
}
