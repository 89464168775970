<div class="content-subscription">
    <div class="content-subscription__main col-md-9 col-xxs-12 no-gutter">
        <div class="content-subscription__section row">
            <div class="col-md-4 col-sm-6 col-xxs-12" *ngFor="let product of products" (click)="selectPlan(product)">
                <div class="block-subscription-cards" [ngClass]="{'active': isSelectedPlan(product)}">
                    <div class="block-subscription-cards__content">
                        <h4>{{ product.name }}</h4>

                        <p class="block-subscription-cards__price">{{ product.plans[selectedCurrency]?.price }} {{ 'currency.' + selectedCurrency + '' | translate }}</p>
                        <p class="block-subscription-cards__details">{{ product.allowedUsers }} {{ 'words.users' | translate | lowercase }}</p>
                        <p class="block-subscription-cards__recurrence">{{ 'company.subscription.plan-type-' + product.plans[selectedCurrency]?.billingInterval | translate }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-subscription__section">
            <div class="col-md-4 col-sm-6 col-xxs-12">
                <h3 class="text-center content-subscription__title text-secondary-500">{{ 'company.subscription.address' | translate }}</h3>

                <div class="block-subscription-infos">
                    <p *ngIf="addresses.length === 0">
                        {{ 'company.subscription.empty-address' | translate }}
                    </p>

                    <div class="block-subscription-infos__content" *ngFor="let address of addresses"
                         (click)="defaultAddress(address)">
                        <div class="block-subscription-infos__icon" *ngIf="address.default">
                            <i class="icon icon-checkmark-1"></i>
                        </div>

                        <div class="block-subscription-infos__text">
                            <p class="block-subscription-infos__title">{{ address.name }}</p>
                            <p class="block-subscription-infos__data">{{ address.address }}</p>
                        </div>
                    </div>

                    <div class="block-subscription-infos__action" (click)="subscriptionAddressAside.onOpen()">
                        <msc-button class="msc-button--primary" palette="secondary">{{ 'company.subscription.add-address' | translate }}</msc-button>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-6 col-xxs-12">
                <h3 class="text-center content-subscription__title">{{ 'company.subscription.card' | translate }}</h3>

                <div class="block-subscription-infos">
                    <p *ngIf="cards.length === 0">
                        {{ 'company.subscription.empty-card' | translate }}
                    </p>

                    <div class="block-subscription-infos__content" *ngFor="let card of cards"
                         (click)="defaultCard(card)">
                        <div class="block-subscription-infos__icon" *ngIf="card.default">
                            <i class="icon icon-checkmark-1"></i>
                        </div>

                        <div class="block-subscription-infos__text">
                            <p class="block-subscription-infos__title">{{ card.name }}</p>
                            <p class="block-subscription-infos__data">{{ card.brand }} :
                                ***-****-****-{{ card.last4 }}</p>
                            <p class="block-subscription-infos__data">{{ 'company.subscription.card.expire-date' | translate }}
                                : {{ card.expMonth }}/{{ card.expYear }}</p>
                        </div>
                    </div>

                    <div class="block-subscription-infos__action" (click)="openAside('card')">
                        <msc-button class="msc-button--primary" palette="secondary">{{ 'company.subscription.add-card' | translate }}</msc-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <aside class="col-md-3 col-xxs-12" *ngIf="selectedPlan">
        <div class="block-company-subscription">
            <div class="block-company-subscription__header">
                <h4>{{ 'words.summary' | translate }}</h4>

                <p class="block-company-subscription__currency text-secondary-500">
                    {{ 'currency.' + selectedCurrency + '' | translate }}
                </p>
            </div>

            <div class="block-company-subscription__content">
                <div class="block-company-subscription__infos">
                    <div class="block-company-subscription__bloc">
                        <div class="block-company-progress">
                            <div class="block-company-progress__header">
                                <h5>{{ selectedPlan?.name }}</h5>
                                <p class="block-company-progress__price">{{ selectedPlan?.plans[selectedCurrency].price }}</p>
                            </div>

                            <div class="block-company-progress__bar">
                                <msc-progress-bar
                                    [progress]="settings.usersCount > selectedPlan.allowedUsers ? 100 : (settings.usersCount / selectedPlan.allowedUsers) * 100"
                                    [color]="'orange'" [label]="false" [icon]="false"></msc-progress-bar>
                            </div>

                            <p [innerHtml]="'company.management.active-accounts.range' | translate : { active: settings.usersCount, total: selectedPlan?.allowedUsers }">
                            </p>
                        </div>
                    </div>

                    <div class="block-company-subscription__bloc">
                        <h5>{{ 'company.subscription.address.billing' | translate }}</h5>
                        <p>{{ selectedAddress?.name }}</p>
                        <p>{{ selectedAddress?.address }}</p>
                    </div>

                    <div class="block-company-subscription__bloc">
                        <h5>{{ 'company.subscription.address' | translate }}</h5>
                        <p>{{ selectedCard?.name }}</p>
                        <p>{{ selectedCard?.brand }} : ***-****-****-{{ selectedCard?.last4 }}</p>
                    </div>

                    <div class="block-company-subscription__bloc no-border">
                        <div class="block-company-subscription__price">
                            <p>{{ 'company.subscription.sum' | translate }}</p>
                            <p class="color-red">{{ selectedPlan?.plans[selectedCurrency].price }}</p>
                        </div>
                    </div>

                    <div class="block-company-subscription__footer"
                         *ngIf="selectedPlan?.allowedUsers >= settings.usersCount && selectedPlan?.id !== currentPlan.id && addresses.length > 0">
                        <div class="btn__group">
                            <msc-button (click)="closePlan()">
                                {{ 'words.cancel' | translate }}
                            </msc-button>

                            <msc-button class="msc-button--primary" palette="primary" (click)="openModal('payment')">
                                {{ 'words.save' | translate }}
                            </msc-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</div>

<msc-aside-company-subscription-address #subscriptionAddressAside (onSave)="createAddress($event)"></msc-aside-company-subscription-address>
