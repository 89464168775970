import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmitterService, ErrorStreamService, StorageService, TranslateService, RedirectService } from '@services';
import { Company, Instance, InstanceProvider } from '@lighty';
import { DataHelper, HttpHelper } from '@helpers';
import { finalize } from 'rxjs';
import { CommonToastService } from '@common2/services/toast.service';

const DEFAULT_PROVIDER_LIST_KEYS: ReadonlyArray<string> = [
    'appcues',
    'bookboon',
    'cefora',
    'cegos',
    'coursera',
    'edflex-blue',
    'email-apside',
    'freshchat',
    'goodhabitz',
    'gotowebinar',
    'learncube',
    'mymooc',
    'openclassroom',
    'trainingexpress',
    'udemy',
    'youtube',
    'linkedin',
    'aws',
    'learninghubz',
    'coursera-for-campus',
    'boclips',
    'netzun',
    'onementor',
    'coursera-career-academy',
    'xertify',
    'power_bi_dashboards',
    'platzi',
    'coursera-multi-programs'
];

@Component({
    selector: 'msc-card-instance',
    templateUrl: './card-instance.component.html',
    styleUrls: ['./card-instance.component.scss']
})

export class CardInstanceComponent {
    @Input() instance: Instance;
    @Input() saved: boolean;
    @Input() params?: any;
    @Input() deleteEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() onEvent: EventEmitter<any> = new EventEmitter();
    public company: Company;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private instanceProvider: InstanceProvider,
        private storageService: StorageService,
        private errorStreamService: ErrorStreamService,
        private toastService: CommonToastService,
        private emitterService: EmitterService,
        private redirectService: RedirectService,
    ) { }

    get logo(): string { return this.getLogo(this.instance); }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.deleteEmitter.subscribe((data: any) => {
            this.errorStreamService.locked();

            const params = HttpHelper.cleanParams({
                message: data.message
            });

            this.instanceProvider.unsubscribe(this.instance.id, params).pipe(finalize(() => {
                this.onEvent.emit('completeDelete');
            })).subscribe(() => {
                this.errorStreamService.unlocked();
                this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
            }, () => {
                this.toastService.onError(this.translateService.instant('toast.unsubscribe.group.forbidden'));
                this.errorStreamService.unlocked();
            });
        });
    }

    deleteCourse(refused: boolean = false): void {
        if (refused) {
            this.onEvent.emit('refused');
        } else {
            this.onEvent.emit('delete');
        }
    }

    goToSession(): void {
        const url = `learn/${this.company.slug}/detail/instance/${this.instance.id}`;

        this.router.navigateByUrl(url);
    }

    redirectPlayer(): void {
        if (this.instance.playerLight && !DataHelper.isEmpty(this.instance.playerLight) && this.instance.playerLight.context === 'internal') {
            this.emitterService.set('player-light.open', { type: this.instance.playerLight.type, content: this.instance.playerLight, more: this.instance });
        } else if (!DataHelper.isEmpty(this.instance.playerLight) && this.instance.playerLight.context === 'external_link') {
            this.emitterService.set('player-light.open', { type: 'activity', subscriptionCourseId: this.instance.id, more: this.instance });
        } else {
            this.redirectService.navigate('player', ['course', this.instance.id]);
        }
    }

    redirectSession(): void {
        if (!this.instance.isLocked && this.instance.state !== 'pending' && this.instance.state !== 'refused') {
            if (this.instance.playerLight && !DataHelper.isEmpty(this.instance.playerLight) && this.instance.playerLight.context === 'internal') {
                this.emitterService.set('player-light.open', { type: this.instance.playerLight.type, content: this.instance.playerLight, more: this.instance });
            } else if (!DataHelper.isEmpty(this.instance.playerLight) && this.instance.playerLight.context === 'external_link') {
                this.emitterService.set('player-light.open', { type: 'activity', subscriptionCourseId: this.instance.id, more: this.instance });
            } else {
                this.redirectService.navigate('player', ['course', this.instance.id]);
            }
        } else {
            this.goToSession();
        }
    }


    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    getLogo(datum: any) {
        switch (true) {
            // If there is a customCampMedia , you need to display this media
            case !!datum && datum.hasOwnProperty('customCampMedia') && !!datum.customCampMedia:
                return `url(${datum?.customCampMedia?.pictureUrl}?size=256)`;
            // If there is NO customCampMedia , you need to check whether there is a provider.media
            case !!datum && datum.hasOwnProperty('provider') && !!datum.provider && datum.provider.hasOwnProperty('media') && !!datum?.provider?.media:
                // If company.id === provider.responsibleId && provider.responsibleType === 'company')  => display the company_logo and not the provider.media logo
                return this.company?.id === datum.provider.responsibleId && datum.provider.responsibleType === 'company' ?
                    `url(${this.company?.experience?.displayPicture}?size=256)` : `url(${datum.provider.media.pictureUrl}?size=256)`;
            // If there is no  provider.media  check if provider is Coursera, Goodhabitz, Bookboon etc...
            case !!datum && datum.hasOwnProperty('provider') && DEFAULT_PROVIDER_LIST_KEYS.includes(datum?.provider?.key):
                return `url(https://static.myskillcamp.com/images/integrations/${datum.provider.key}.svg)`;
            // If there is no  provider.media + NOT IN know providers , you need to display the company media image
            default:
                return `url(${this.company?.experience?.displayPicture}?size=256)`;
        }
    }
}
