import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { ContentProvider } from '@lighty';
import { EmitterService, TranslateService } from '@services';
import { CardManagerModalDeleteSubscriptionComponent } from './modal/delete-subscription/delete-subscription.component';

@Component({
    selector: 'msc-card-manager',
    templateUrl: './card-manager.component.html'
})
export class CardManagerComponent implements OnInit, OnDestroy {
    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
    @Input() content: any;
    @Input() params?: any;
    @Input() saved?: boolean;
    @Output() onDelete: EventEmitter<any> = new EventEmitter(); // FALSE - loading, Content - success delete
    private modal: ComponentRef<CardManagerModalDeleteSubscriptionComponent>;
    public deleteEmitter: EventEmitter<any> = new EventEmitter();
    public data: any;

    constructor(
        private contentProvider: ContentProvider,
        private toastService: CommonToastService,
        private factory: ComponentFactoryResolver,
        private emitterService: EmitterService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.data = {
            message: null
        };
    }

    ngOnDestroy(): void {
        if (this.modal) {
            this.modal.destroy();
        }
    }

    eventDispatcher(type: any): void {
        switch (type) {
            case 'addSavedContent':
                this.addSavedContent();
                break;
            case 'deleteSavedContent':
                this.deleteSavedContent();
                break;
            case 'delete':
                this.emitterService.set('catalogue.modal', true);
                const timeOut = setTimeout(() => {
                    this.createModal();
                    clearTimeout(timeOut);
                }, 300);
                break;
            case 'refused':
                const timeOutRefused = setTimeout(() => {
                    this.deleteContent({ message: '' });
                    clearTimeout(timeOutRefused);
                }, 300);
                break;
            case 'completeDelete':
                this.onDelete.emit(this.content);
                break;
        }
    }

    private deleteContent(data: any): void {
        this.deleteEmitter.emit(data);
    }

    private createModal(): void {
        this.container.clear();

        const factory = this.factory.resolveComponentFactory(CardManagerModalDeleteSubscriptionComponent);

        this.modal = this.container.createComponent(factory);
        this.modal.instance.type = this.content.type;
        this.modal.instance.open(this.content.data.title || this.translateService.instant('words.untitled'));

        this.modal.instance.onClose.subscribe(() => {
            this.emitterService.set('catalogue.modal', false);
            this.modal.destroy();
        });

        this.modal.instance.onSave.subscribe((data) => {
            this.onDelete.emit(false);
            this.deleteContent(data);
            this.modal.destroy();
        });
    }

    private addSavedContent(): void {
        const params = {
            context: this.content.type,
            context_id: this.content.data.id
        };

        this.contentProvider.addSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.saved'));
        });
    }

    private deleteSavedContent(): void {
        const params = {
            context: this.content.type,
            context_id: this.content.data.id
        };

        this.contentProvider.deleteSavedContent(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.learn-later.deleted'));
        });
    }
}
