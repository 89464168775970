<div class="showroom-container">
    <div class="demo">
        <div>
            <h1>Theming</h1>
            <div class="flex align-center my-3">
                <div class="m-2 flex align-center">
                    <label class="leading-none" for="primary">Primary</label>
                    <div class="w-12 h-12 rounded-full overflow-hidden ml-3">
                        <input class="h-24 w-24 cursor-pointer -translate-x-1/4 -translate-y-1/4" name="primary" type="color" [(ngModel)]="colors.primary">
                    </div>
                </div>
                <div class="m-2 flex align-center">
                    <label class="leading-none" for="secondary">Secondary</label>
                    <div class="w-12 h-12 rounded-full overflow-hidden ml-3">
                        <input class="h-24 w-24 cursor-pointer -translate-x-1/4 -translate-y-1/4" name="secondary" type="color" [(ngModel)]="colors.secondary">
                    </div>
                </div>
                <div class="m-2 flex align-center">
                    <label class="leading-none" for="success">Success</label>
                    <div class="w-12 h-12 rounded-full overflow-hidden ml-3">
                        <input class="h-24 w-24 cursor-pointer -translate-x-1/4 -translate-y-1/4" name="success" type="color" [(ngModel)]="colors.success">
                    </div>
                </div>
                <div class="m-2 flex align-center">
                    <label class="leading-none" for="danger">Danger</label>
                    <div class="w-12 h-12 rounded-full overflow-hidden ml-3">
                        <input class="h-24 w-24 cursor-pointer -translate-x-1/4 -translate-y-1/4" name="danger" type="color" [(ngModel)]="colors.danger">
                    </div>
                </div>
                <div class="m-2 flex align-center">
                    <label class="leading-none" for="error">Error</label>
                    <div class="w-12 h-12 rounded-full overflow-hidden ml-3">
                        <input class="h-24 w-24 cursor-pointer -translate-x-1/4 -translate-y-1/4" name="error" type="color" [(ngModel)]="colors.error">
                    </div>
                </div>
                <div class="m-2 flex align-center">
                    <label class="leading-none" for="info">Info</label>
                    <div class="w-12 h-12 rounded-full overflow-hidden ml-3">
                        <input class="h-24 w-24 cursor-pointer -translate-x-1/4 -translate-y-1/4" name="info" type="color" [(ngModel)]="colors.info">
                    </div>
                </div>
                <div>
                    <msc-button color="primary" (click)="apply()">Apply</msc-button>
                </div>
            </div>
        </div>
        <div>
            <h1>Pallette</h1>
            <div class="flex w-full align-center my-5" *ngFor="let palette of palettes; let i=index">
                <div class="m-3">
                    <p>{{palette.name | titlecase}}</p>
                </div>
                <div class="flex justify-end w-full align-center">
                    <p class="mr-3">Lighten</p>
                    <div class="flex flex-col w-16 relative" *ngFor="let color of palette.map | keyvalue">
                        <div class="w-12 h-12 rounded flex justify-center align-center" [ngStyle]="{'background-color': color.value.c}">
                            <p *ngIf="color.key == 500" class="text-white">Base</p>
                        </div>
                        <p>{{color.key}}</p>
                        <small class="text-gray-300">{{color.value.c}}</small>
                        <!-- <p>{{color.value.debug}}</p> -->
                    </div>
                    <p>Darken</p>
                </div>
            </div>
        </div>
        <div class="input-type">
            <div class="type-name">
                <h1>Components</h1>
            </div>
            <div class="type-content">
                <form class="flex">
                    <msc-input class="w-52" type="text" label="Primary" placeholder="Focus to see color"></msc-input>
                    <msc-input class="w-52 msc-input--secondary ml-3" type="text" label="Secondary" placeholder="Focus to see color"></msc-input>
                </form>
            </div>
        </div>
    </div>
</div>
