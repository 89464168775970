import { Component } from '@angular/core';

@Component({
    selector: 'msc-manage-instance',
    templateUrl: './instance.component.html',
    styleUrls: ['./instance.component.scss'],
})

export class ManageInstanceComponent {

}
