import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-showroom-exhibit-inline-edit-page',
    templateUrl: './showroom-exhibit-inline-edit-page.component.html',
    styleUrls: ['./showroom-exhibit-inline-edit-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ShowroomExhibitInlineEditPageComponent {
    public text: string = 'Lorem ipsum';

    constructor() { }

    /**
     *
     */
    onChange(name: string): void {
		this.text = name;
	}
}
