import { Component, Input, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Chart, ChartTypeRegistry } from 'chart.js';

@Component({
    selector: 'msc-chart-pie',
    templateUrl: './chart-pie.component.html',
    styleUrls: ['./chart-pie.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ChartPieComponent {
    @ViewChild('chart', { static: true }) chartComponent: ElementRef;
    @Input() backgroundColor: string = '#D8DAE6';
    @Input() dataType: string = '%';
    @Input() defer: number;
    @Input() displayValue: boolean = true;
    @Input() options: any;
    @Input() size: string = 'regular';
    @Input() type: keyof ChartTypeRegistry = 'doughnut';
    @Input() valueColor: string = '#8290FD';
    private _value: number = 0;
    private initialized = false;

    @Input() set value(value: number) {
        this._value = value < 0 ? 0 : value >= 100 ? 100 : value;
        if (this._value === 100) {
            this.valueColor = '#79E2BD';
        } else {
            this.valueColor = '#8290FD';
        }
        if (this.initialized) {
            this.setChart();
        }
    }

    get value(): number {
        return this._value;
    }

    public chart: any;

    ngAfterViewInit(): void {
        if (this.defer) {
            const timeOut = setTimeout(() => {
                this.setChart();
                this.initialized = true;
                clearTimeout(timeOut);
            }, this.defer);
        } else {
            this.setChart();
            this.initialized = true;
        }
    }

    setChart(): void {
        this.chart = new Chart(this.chartComponent.nativeElement, {
            type: this.type,
            data: {
                datasets: [{
                    data: [this._value, 100 - this._value],
                    backgroundColor: [this.valueColor, this.backgroundColor]
                }]
            },
            options: this.getOptions(),
        });
    }

    getOptions() {
        return {
            responsive: true,
            cutoutPercentage: 84,
            tooltips: {
                enabled: false
            },
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
            hover: {
                mode: null
            },
            ...this.options,
        };
    }
}
