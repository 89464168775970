<div class="msc-banner">
    <div class="msc-banner__gradient">
        <div [ngStyle]="{'background-image': 'url(' + background + ')'}"></div>
    </div>
</div>

<div class=" container-catalog-internal__main animate">
    <msc-shared-topbar-filters [type]="'catalogue'" [color]="'white'" [(query)]="query" [(mode)]="mode" [(activeFilters)]="activeFilters" [(filters)]="filters" (queryChange)="search()" (filtersChange)="search()"></msc-shared-topbar-filters>

    <div class="container-catalog-internal__breadcrumb" style="display:none;">
        <ul class="navigation-breadcrumb navigation-breadcrumb--light">
            <li (click)="navigate('catalogue', true)" *ngIf="company.experience?.catalogueDisplay === 'overview'">
                <a>{{ 'words.catalogue' | translate }}</a>
            </li>

            <li [ngClass]="{'disable': !thematic && !level}" (click)="navigate(params.type, !!thematic || !!level)">
                <a>{{ 'words.content-' + params.type | translate }}</a>
            </li>

            <li [ngClass]="{'disable': !level}" (click)="navigate('thematic', !!level)">
                <a *ngIf="thematic?.name !== 'temp_name_for_empty_thematic'">{{ thematic?.name || level?.name }}</a>
                <a *ngIf="thematic?.name === 'temp_name_for_empty_thematic'">{{ 'words.miscellaneous' | translate }}</a>
            </li>

            <li *ngIf="level">
                {{ getLevel() | translate }}
            </li>
        </ul>
    </div>

    <div class="col-12 container-catalog-internal__list animate" *ngIf="mode === 'list' && !isSearching && !searching">
        <msc-learn-catalogue-watch-explore [explore]="explore" *ngIf="explore"></msc-learn-catalogue-watch-explore>

        <msc-learn-catalogue-watch-thematic [thematic]="thematic" *ngIf="thematic"></msc-learn-catalogue-watch-thematic>

        <msc-learn-catalogue-watch-level [level]="level" *ngIf="level"></msc-learn-catalogue-watch-level>

        <msc-empty-state *ngIf="(explore && explore.contents && explore.contents.length === 0)
                     || (thematic && thematic.contents && thematic.contents.length === 0)
                     || (level && level.contents && level.contents.length === 0)"
                         [title]="'empty.content.search.title'"
                         [description]="'empty.content.search.description'">
        </msc-empty-state>
    </div>

    <div class="container-catalog-internal__list row animate" *ngIf="mode === 'list' && isSearching">
        <div class="card-container">
            <content-card *ngFor="let content of contents" [content]="content"></content-card>
        </div>

        <msc-empty-state *ngIf="!searching && contents && contents.length === 0"
                         [title]="'empty.content.search.title'"
                         [description]="'empty.content.search.description'">
        </msc-empty-state>
    </div>

    <div class="container-catalog-internal__list row animate" *ngIf="mode === 'calendar'">
        <msc-shared-event-calendar [type]="'catalogue'" [events]="events"></msc-shared-event-calendar>
    </div>

    <div class="container-catalog-internal__more col-12 col-xxs-12 flex justify-center">
        <msc-load-more *ngIf="!searching" [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
    </div>

    <msc-common-spinner *ngIf="searching"></msc-common-spinner>
</div>
