import { environment } from '@env/environment';
import { Media } from '@lighty';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

export class DownloadHelper {

    public static download(url: string, title: string, storage: boolean = true): void {
        const link = document.createElement('a');
        link.href = storage ? environment.envVar.API_URL + '/stockage/' + url : url;
        link.setAttribute('target', '_blank');
        link.download = title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public static downloadBlob(blob: any, title: string): void {
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, title);
        } else {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = title;
            a.click();
            URL.revokeObjectURL(url);
            a.remove();
        }
    }

    public static downloadMedia(media: Media, title: string): void {
        let url: string;
        if (media.type === 'IMG') {
            url = media.url + '?size=raw';
        } else if (media.type === 'PDF') {
            url = media.url;
        } else if (media.type === 'VID') {
            url = media.url.replace('//www.vimeo.com/', 'https://player.vimeo.com/video/');
        } else {
            url = media.originalUrl || media.url;
        }

        const isExternalLink = media.storage !== 'ext' && media.storage !== 'vimeo';

        this.download(url, title, isExternalLink);
    }
}
