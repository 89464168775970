<!--application = aplicacion-->
<section class="head">
    <h2>
        {{ 'words.my-applications' | translate }}
    </h2>
</section>

<section class="body gap-4 sm:grid-cols-1  md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3">
    <ng-container *ngIf="!loading">
        <ng-container *ngIf="externalLinks.length; else emptyTemplate">
            <div class="integrations-container">
                <a class="card-integration" *ngFor="let link of externalLinks; let index=index" target="_blank"
                    [href]="link.url">
                    <div class="card-integration__icon">
                        <i class="icon icon-globe text-secondary-500" *ngIf="link.type === 'lti'"></i>

                        <i class="icon icon-link-2 text-secondary-500" *ngIf="link.type === 'url'"></i>
                    </div>

                    <div class="card-integration__picture"
                        [style.backgroundImage]="'url(' + (link.picture ? (link.picture + '?size=256') : (googleParse + link.url)) + ')'">
                    </div>

                    <div class="card-integration__name">
                        <p>{{ link.name | truncate: 30 }}</p>
                    </div>
                </a>
            </div>
        </ng-container>
        <ng-template #emptyTemplate>
            <msc-common-empty class="m-0" [message]="'integrations.empty-state' | translate"></msc-common-empty>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="loading">
        <msc-common-spinner></msc-common-spinner>
    </ng-container>
</section>
