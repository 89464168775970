import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CompanyUserProvider, YoutubePlaylist, Camp, IntegrationFactory } from '@lighty';
import { HttpHelper, DataHelper } from '@helpers';

@Component({
    selector: 'msc-shared-aside-integration-playlist',
    templateUrl: './integration-playlist.component.html',
    styleUrls: ['./integration-playlist.component.scss']
})
export class SharedIntegrationPlaylistComponent {
    @ViewChild('aside') aside;
    @Input() manage: boolean = false;
    @Input() companyId: number;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    private url: string = 'https://www.youtube.com/playlist?list=';
    public playlist: YoutubePlaylist;
    public data: any;
    public currentTab: string = 'playlist';
    public camps: Camp[];
    public availableCamps: Camp[];
    public videos: any[] = [];
    public query: string;
    public loading: boolean = false;
    public closeByUser: EventEmitter<any> = new EventEmitter();

    constructor(private companyUserProvider: CompanyUserProvider, private integrationFactory: IntegrationFactory) { }

    init(): void {
        this.data = {
            id: this.playlist ? this.playlist.id : null,
            url: this.playlist ? this.url + this.playlist.externalId : '',
            name: this.playlist ? this.playlist.name : '',
            lang: this.playlist ? this.playlist.language || 'multi' : 'multi'
        };
        this.videos = [];

        if (this.manage) {
            this.data['camps'] = [];
            this.getCampsPermissions();
        }

        if (this.playlist) {
            this.getVideos();
        }
    }

    public onOpen(playlist?) {
        this.playlist = playlist;
        this.init();
        this.aside.onOpen();
    }

    private getVideos(): void {
        this.loading = true;

        this.integrationFactory.get('youtube-learning').getVideos(this.playlist.id).subscribe((data) => {
            this.videos = data.videos;
            this.loading = false;
        });
    }

    private getCampsPermissions(): void {
        if (this.playlist) {
            this.integrationFactory.get('youtube-admin').getPermissions(this.playlist.id).subscribe((data) => {
                this.data['allCamps'] = data.allCamps;
                this.camps = data.camps;
                this.availableCamps = data.availableCamps;
            });
        } else {
            this.data['allCamps'] = true;
            this.camps = [];

            this.companyUserProvider.getCamps(this.companyId).subscribe((camps) => {
                this.availableCamps = camps;
            });
        }
    }

    private getCampsId(): any {
        return this.camps.map((camp) => {
            return camp.id;
        });
    }

    search(query?): void {
        this.query = query;
        if (this.playlist) {
            const params = HttpHelper.cleanParams({
                q: this.query
            });

            this.integrationFactory.get('youtube-admin').getPermissions(this.playlist.id, params).subscribe((data) => {
                this.camps = data.camps;
                this.availableCamps = data.availableCamps;
            });
        } else {
            const params = HttpHelper.cleanParams({
                q: this.query
            });

            this.companyUserProvider.getCamps(this.companyId, params).subscribe((camps) => {
                this.availableCamps = camps;
            });
        }
    }

    addCamp(camp: Camp): void {
        this.camps.push(camp);

        const index = this.availableCamps.findIndex((data) => {
            return data.id === camp.id;
        });

        if (index !== -1) {
            this.availableCamps.splice(index, 1);
        }
    }

    removeCamp(camp: Camp): void {
        this.availableCamps.push(camp);

        const index = this.camps.findIndex((data) => {
            return data.id === camp.id;
        });

        if (index !== -1) {
            this.camps.splice(index, 1);
        }
    }

    changeTab(tab: string): void {
        this.currentTab = tab;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (!this.data.allCamps && this.manage) {
            this.data.camps = this.getCampsId();
        }

        const params: any = DataHelper.clone(this.data);
        if (params.lang === 'multi') {
            params.lang = null;
        }
        this.onSave.emit({ playlist: params, isEdit: !!this.playlist });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }
}
