import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanySettings } from './company-settings';
import { Account } from '../../account';
import { environment } from '@env/environment';

@Injectable()
export class CompanySettingsProvider {

    constructor(private http: HttpClient) {}

    get(slug: string, params?: any): Observable<CompanySettings> {
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/settings`, {params}).pipe(
            map(data => new CompanySettings(data))
        );
    }

    update(slug: string, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/company/${slug}/settings`, params).pipe(
            map(data => data)
        );
    }

    getAvailableContacts(slug: string, params?: any): Observable<Account[]> {
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/settings/available-contacts`, {params}).pipe(
            map( (contacts: any) => {
                return contacts.map((contact) => {
                    return new Account(contact);
                });
            })
        );
    }
}
