import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { addClass, removeClass } from '@functions';
import { StatsProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import * as moment from 'moment';
import { take } from 'rxjs';
import { CommonToastService } from './../../../common2/services/toast.service';
@Component({
    selector: 'msc-stats-export-modal',
    templateUrl: './stats-export-modal.component.html',
    styleUrls: ['./stats-export-modal.component.scss']
})
export class StatsExportModal {
    @Input() title: string;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onSend: EventEmitter<any> = new EventEmitter();
    @ViewChild('emailsContainer') emailsContainer;
    @ViewChild('dateFrom') dateFrom;
    @ViewChild('dateTo') dateTo;

    public dates: any;
    public displays: any;
    public today: any;
    public filters: any;
    public dataFilters: any;
    public isFilterActive: boolean = false;

    public active = false;
    public options: any = {};

    public setRange = false;

    data = {
        granularity: 'day',
        format: 'csv',
        emails: [],
        range: '1'
    }
    invalid = false;
    public exportName = '';

    /* displays = {
        granularity: true
    } */
    private emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    constructor(
        private storageService: StorageService,
        private statsProvider: StatsProvider,
        private commonToastService: CommonToastService,
        private translateService: TranslateService,
    ) {
        this.resetFields();
    }

    
    ngOnInit(): void {

        this.today = moment();
        this.dates = {
            from: moment().format('YYYY-MM-DD'),
            upto: moment().format('YYYY-MM-DD')
        };

        this.displays = {
            date_form: false,
            date_to: false,
            export: false
        };



    }


    resetFields() {
        this.data.emails = [
            {
                value: this.storageService.get('me').email,
                isValid: true,
                readonly: true
            }
        ];
    }

    displayed(key: string, force: boolean = null): void {
        this.displays[key] = force !== null ? force : !this.displays[key];
    }

    change(key: string, value: any): void {
        this.data[key] = value;
    }

    open(options?: any): void {
        addClass('.app-main', 'disable-overflow');
        this.options = options;
        this.active = true;
        console.log(this.options);
        const date = new Date();
        this.exportName = `${this.options.name.replaceAll(' ', '-')}-${this.options.type}`;
    }

    isValidEmail(emailField) {
        emailField.isValid = !emailField.value || this.emailRegex.test(emailField.value);
        this.data.emails.filter(field => !field.isValid).map(field => {
            if (!field.value) {
                field.isValid = true;
            }
        })
        this.invalid = !this.canSubmit();
    }

    canSubmit(): boolean {
        return !this.data.emails.some((email) => !email.isValid);
    }

    addEmailField() {
        let emptyFields;
        this.data.emails.map(email => {
            if (!email.value) {
                email.isValid = false;
                emptyFields = true;
            }
        });

        if (emptyFields) {
            return;
        }

        this.data.emails.push({
            value: '',
            isValid: true
        })

        setTimeout(() => {
            this.emailsContainer.nativeElement.scrollTop = this.emailsContainer.nativeElement.scrollHeight;
        }, 200);
    }

    removeEmailField(index) {
        this.data.emails.splice(index, 1);
    }

    close(): void {
        removeClass('.app-main', 'disable-overflow');
        this.active = false;
        this.onClose.emit(true);
    }

    isActive(): boolean {
        return this.active;
    }

    save() {
        console.log(this.dates)
       // this.onSave.emit({ emails: this.data.emails.filter(emailField => emailField.value).map(emailField => emailField.value),type: this.options.type, report_type: this.data.range, format: this.data.format, granularity: this.data.granularity, date_from: this.dates.from, date_to: this.dates.upto });

        const params = {
             emails: this.data.emails.filter(emailField => emailField.value).map(emailField => emailField.value),
             type: this.options.type,
             report_type: this.setRange ? 'P' : this.data.range,
             date_from: this.dates.from,
             date_to: this.dates.upto
        };
        
        if(this.options.type != 'learner-trajects') {
            this.statsProvider.exportByRange(this.storageService.get('company').id, params).pipe(take(1)).subscribe((data) => {
                this.commonToastService.onSuccess(this.translateService.instant('words.send-report'));
                this.onSend.emit();
            }, (error: any) => {
                this.commonToastService.onError(this.translateService.instant('words.send-report-error'));
            });
        }
        else {
            this.statsProvider.requestTrajectReport(this.storageService.get('company').id, params).pipe(take(1)).subscribe((data) => {
                this.commonToastService.onSuccess(this.translateService.instant('words.send-report'));
                this.onSend.emit();
            }, (error: any) => {
                this.commonToastService.onError(this.translateService.instant('words.send-report-error'));
            });
        }



        this.close();
    }

    updateDateFrom(date: string): void {
        this.dates.from = moment(date).format('YYYY-MM-DD');
        this.displayed('date_from');
        this.search();
        this.dateFrom.close();
    }

    updateDateTo(date: string): void {
        this.dates.upto = moment(date).format('YYYY-MM-DD');
        this.displayed('date_to');
        //this.dateTo.save();
        this.dateTo.close();
    }

    search(): void {

    }

    moment(): void {

    }

    setFilters(): void {
        this.filters = [
            {
                name: 'camps',
                type: 'select',
                label: ['Hoy', 'Semana', 'Mes'],
                options: ['Hoy', 'Semana', 'Mes']
            }
        ];
    }

    onFilterSave(data): void {
        this.dataFilters = data;
        this.isFilterActive = true;
        this.search();
    }

    onFilterReset() {
        this.dataFilters = {};
        this.isFilterActive = false;
        this.search();
    }

    displayRange(setRange: boolean ) {
        this.setRange = setRange;
    }
}
