import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Integration } from './integration';
import { Pagination } from '../pagination';
import { environment } from '@env/environment';

@Injectable()
export class IntegrationProvider {

    constructor(private http: HttpClient) {}

    get(slug?: string, params?: any): Observable<any> {
        const response = {
            connectedIntegrations: [],
            availableIntegrations: [],
            externalLinks: [],
            connectedApplications: [],
            availableApplications: []
        };

        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${slug}/integrations`, {params}).pipe(
            map((data: any) => {
                response.externalLinks = data.externalLinks.map((integration) => {
                    return new Integration(integration);
                });

                if (data.applications) {
                    response.connectedApplications = data.applications.map((application) => {
                        return application;
                    });
                }

                if (data.availableApplications) {
                    response.availableApplications = data.availableApplications.map((application) => {
                        return application;
                    });
                }

                return response;
            })
        );
    }

    getConnectedProviders(id: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            providers: []
        };

        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/providers`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.providers = data.providers;

                return response;
            })
        );
    }

    getAvailableProviders(params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/providers`, {params}).pipe(
            map(data => data)
        );
    }

    getProvider(providerId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/providers/${providerId}`, {params}).pipe(
            map(data => data)
        );
    }

    updateProvider(id: number, providerId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${id}/providers/${providerId}`, params).pipe(
            map(data => data)
        );
    }

    testProvider(id: number, providerId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/providers/${providerId}/test`, params).pipe(
            map(data => data)
        );
    }

    updateStatus(id: number, providerId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${id}/providers/${providerId}/status`, params).pipe(
            map(data => data)
        );
    }

    updatePermissions(id: number, providerId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${id}/providers/${providerId}/camps`, params).pipe(
            map(data => data)
        );
    }

    addPersonalLink(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/external-links`, params).pipe(
            map(data => data)
        );
    }

    updatePersonalLink(linkId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/external-links/${linkId}`, params).pipe(
            map(data => data)
        );
    }

    deletePersonalLink(linkId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/external-links/${linkId}`).pipe(
            map(data => data)
        );
    }

    addCompanyLink(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/external-links`, params).pipe(
            map(data => data)
        );
    }

    getCompanyLink(id: number, linkId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/external-links/${linkId}`, {params}).pipe(
            map(data => data)
        );
    }

    updateCompanyLink(id: number, linkId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${id}/external-links/${linkId}`, params).pipe(
            map(data => data)
        );
    }

    updateCompanyCampsLink(id: number, linkId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${id}/external-links/${linkId}/camps`, params).pipe(
            map(data => data)
        );
    }

    deleteCompanyLink(id: number, linkId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${id}/external-links/${linkId}`).pipe(
            map(data => data)
        );
    }

    getCollections(companyId: number, providerId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections`, {params}).pipe(
            map(data => data)
        );
    }

    getCollection(companyId: number, providerId: number, collectionId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}`, {params}).pipe(
            map(data => data)
        );
    }

    getCollectionRules(companyId: number, providerId: number, collectionId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/rules`, {params}).pipe(
            map(data => data)
        );
    }

    getCollectionRule(companyId: number, providerId: number, collectionId: number, ruleId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/rules/${ruleId}`, {params}).pipe(
            map(data => data)
        );
    }

    createCollectionRule(companyId: number, providerId: number, collectionId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/rules`, params).pipe(
            map(data => data)
        );
    }

    updateCollectionRule(companyId: number, providerId: number, collectionId: number, ruleId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/rules/${ruleId}`, params).pipe(
            map(data => data)
        );
    }

    deleteCollectionRule(companyId: number, providerId: number, collectionId: number, ruleId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/rules/${ruleId}`, {params}).pipe(
            map(data => data)
        );
    }

    getCollectionLanguages(companyId: number, providerId: number, collectionId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/languages`, {params}).pipe(
            map(data => data)
        );
    }

    getCollectionContents(companyId: number, providerId: number, collectionId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/contents/search`, params).pipe(
            map(data => data)
        );
    }

    getCollectionCategories(companyId: number, providerId: number, collectionId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/categories`, {params}).pipe(
            map(data => data)
        );
    }

    contactAdministrator(providerId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/providers/${providerId}/contact`, params).pipe(
            map(data => data)
        );
    }

    getMappingCategories(companyId: number, providerId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/categories`, {params}).pipe(
            map(data => data)
        );
    }

    getMappingSkills(companyId: number, providerId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/skills`, {params}).pipe(
            map(data => data)
        );
    }

    updateMappingSkill(companyId: number, providerId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/skills`, params).pipe(
            map(data => data)
        );
    }

    deleteMappingSkill(companyId: number, providerId: number, associationId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/skills/${associationId}`, params).pipe(
            map(data => data)
        );
    }

    deploy(id: number, providerId: number, params?: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/providers/${providerId}/deploy`, params).pipe(
            map(data => data)
        );
    }

    exportCatalogue(companyId: number, providerId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/contents/export`, {responseType: 'blob'}).pipe(
            map(data => data)
        );
    }

    renameRule(companyId: number, providerId: number, collectionId: number, ruleId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/collections/${collectionId}/rules/${ruleId}/name`, params).pipe(
            map(data => data)
        );
    }

    getProviderContentMeta(companyId: number, providerId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/contents/meta`).pipe(
            map((data: any) => data.meta)
        );
    }

    getProviderContents(companyId: number, providerId: number, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/contents`, {params}).pipe(
            map(data => data)
        );
    }

    getProviderContent(companyId: number, providerId: number, contentId: any, params: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/contents/${contentId}`, {params}).pipe(
            map(data => data)
        );
    }

    updateProviderContent(companyId: number, providerId: number, contentId: any, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${companyId}/providers/${providerId}/contents/${contentId}/skills/rules`, params).pipe(
            map(data => data)
        );
    }

    getMeta(){
        return this.http.get(`${environment.envVar.CLOUD_URL}/saml/new/metadata.xml`, { responseType: 'blob' });
    }
}
