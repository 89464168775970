import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmitterService, StorageService } from '@services';
import { ExtensionLink } from '@interfaces';
import { HttpHelper } from '@helpers';
import { KnowledgeProvider, Pagination, ExternalContentProvider } from '@lighty';

@Component({
    selector: 'msc-shared-aside-extension-skills',
    templateUrl: './aside-extension-skills.component.html'
})
export class SharedExtensionSkillsComponent implements OnInit {
    @Input() extensionLink: ExtensionLink;
    @Output() extensionLinkChange: EventEmitter<ExtensionLink> = new EventEmitter();
    private company: any;
    public availableSkills: any[] = [];
    public loading: boolean = true;
    public pagination: Pagination;
    public skillSearchName: string;

    constructor(private emitterService: EmitterService, private externalContentProvider: ExternalContentProvider, private knowledgeProvider: KnowledgeProvider,
                private storageService: StorageService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        if (this.extensionLink.exists) {
            this.externalContentProvider.getExternalContentsInfos(this.extensionLink.id, { company_id: this.company.id }).subscribe(data => {
                this.extensionLink.skills = data.skills;
                this.loadAvailableSkills();
            });
        } else {
            this.loadAvailableSkills();
        }
    }

    loadMore(): void {
        this.loading = true;
        this.loadAvailableSkills(this.pagination.currentPage + 1);
    }

    loadAvailableSkills(pagination?: number): void {
        const params = HttpHelper.cleanParams({
            page: pagination
        });

        this.knowledgeProvider.get(this.storageService.get('company').id, params).subscribe(data => {
            this.availableSkills = this.availableSkills.concat(
                data.skills.filter((skill) => this.extensionLink.skills.findIndex((response) => response.id === skill.id) === -1).filter((response) => response.name)
            );
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    addSkill(index: number, level: number): void {
        this.availableSkills[index].level = level;
        this.extensionLink.skills.push(this.availableSkills[index]);

        const toRemoveIndex = this.extensionLink.removedSkills.findIndex(res => res.id === this.availableSkills[index].id);
        if (toRemoveIndex !== -1) {
            this.extensionLink.removedSkills.splice(toRemoveIndex, 1);
        }
        this.availableSkills.splice(index, 1);
    }

    removeSkill(index: number): void {
        this.availableSkills.push(this.extensionLink.skills[index]);
        this.extensionLink.removedSkills.push(this.extensionLink.skills[index]);

        const toRemoveIndex = this.extensionLink.modifiedSkills.findIndex(res => res.id === this.extensionLink.skills[index].id);
        if (toRemoveIndex !== -1) {
            this.extensionLink.modifiedSkills.splice(toRemoveIndex, 1);
        }
        this.extensionLink.skills.splice(index, 1);
    }

    setLevel(level: number, index: number): void {
        this.extensionLink.skills[index].level = level;
        this.extensionLink.modifiedSkills.push(this.extensionLink.skills[index]);
    }

    searchSkill(query?): void {
        this.skillSearchName = query;
        const params = HttpHelper.cleanParams({
            q: this.skillSearchName
        });

        this.knowledgeProvider.get(this.company.slug, params).subscribe((data) => {
            this.availableSkills = data.skills;
            this.pagination = data.pagination;
        });
    }

    nextPanel(): void {
        this.emitterService.extensionPage.emit('summary');
    }
}
