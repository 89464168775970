import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'msc-learn-catalogue-watch-explore',
    templateUrl: './explore.component.html'
})
export class LearnCatalogueWatchExploreComponent implements OnInit {
    @Input() explore: any;

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {}

    checkDisplayContents(): boolean {
        return this.explore.length === 1 && this.explore[0].name === 'temp_name_for_empty_thematic';
    }

    navigate(thematic: any): void {
        if (thematic.name === 'temp_name_for_empty_thematic' || thematic.levels?.length > 1) {
            this.router.navigate(['thematic', thematic.id], { relativeTo: this.route });
        } else {
            if (thematic.levels?.length) {
                this.router.navigate(['thematic', thematic.id, 'level', thematic.levels[0]], { relativeTo: this.route });
            } else {
                this.router.navigate(['thematic', thematic.id], { relativeTo: this.route });
            }
        }
    }
}
