import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ManageInstanceProvider, Company, Pagination } from '@lighty';
import { ActivatedRoute } from '@angular/router';
import { StorageService} from '@services';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-manage-instance-settings-aside-trainers',
    templateUrl: './aside-trainers.component.html',
    styleUrls: ['./aside-trainers.component.scss']
})
export class ManageInstanceSettingsAsideTrainersComponent {
    @ViewChild('aside') aside;
    @Input() instanceId: number;
    @Input() companyId: number;
    @Input() managers: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    private managersSelected: number[] = [];
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public loading: boolean = false;
    public pagination: Pagination;
    public company: Company;
    public trainers: any;
    public query: string;
    public instance: any;
    public loaders: any;

    constructor(private instanceProvider: ManageInstanceProvider, private storageService: StorageService, private route: ActivatedRoute) {}

    init(): void {
        this.loaders = {
            ready: false,
            save: false
        };
        this.company = this.storageService.get('company');
        this.instance = this.route.parent.snapshot.data.instance;
        this.getAvailableManagers();
    }

    public onOpen() {
        this.init();
        this.aside.onOpen();
    }

    private getAvailableManagers(page: number = 1): void {
        const params = HttpHelper.cleanParams({
            context: this.instance.type,
            q: this.query,
            page
        });

        this.loaders.ready = false;
        this.instanceProvider.getAvailableManagers(this.company.id, this.instance.id, params).subscribe((data) => {
            this.pagination = data.pagination;
            if (this.pagination.currentPage > 1) {
                this.trainers = [...this.trainers, ...this.filterManagers(data.managers)];
            } else {
                this.trainers = this.filterManagers(data.managers);
            }
            this.loaders.ready = true;
        });
    }

    filterManagers(managers: any): any {
        return managers.filter((manager) => {
            const managersAlreadyAdded = this.managers.map((data) => data.id);
            return managersAlreadyAdded.indexOf(manager.id) === -1;
        });
    }

    search(query?): void {
        this.query = query;
        this.getAvailableManagers();
    }

    addManager(id: number): void {
        const index = this.managersSelected.indexOf(id);

        if (index > -1) {
            this.managersSelected.splice(index, 1);
        } else {
            this.managersSelected.push(id);
        }
    }

    isManagerSelected(id: number): boolean {
        return this.managersSelected.indexOf(id) > -1;
    }

    loadMore(): void {
       this.getAvailableManagers(this.pagination.currentPage + 1);
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (!this.loaders.save) {
            this.loaders.save = true;
            this.onSave.emit(this.managersSelected);
        }
    }
}
