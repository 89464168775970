import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService, TranslateService } from '@services';
import { UtilityProvider } from '@lighty';

@Component({
    selector: 'msc-maintenance',
    templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit {
    public displays: any;
    public currentLang: string = 'fr';
    public status: any = {};

    constructor(private router: Router, private route: ActivatedRoute, private translateService: TranslateService, private storageService: StorageService, private utilityProvider: UtilityProvider) { }

    ngOnInit(): void {
        this.utilityProvider.checkMaintenance().subscribe((data) => {
            if (!data.state) {
                this.router.navigate(['login']);
            } else {
                this.status = data.details;
            }
        });

        this.displays = {
            flag: false
        };

        this.route.queryParams.subscribe((params: any) => {
            if (params.force_access) {
                this.storageService.setLocal('force_access', true);
            }
        });
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    setLang(lang: string): void {
        this.currentLang = lang;
        this.translateService.setLang(this.currentLang);
    }
}
