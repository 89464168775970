<div class="content" dragDrop (dropEvent)="onDrop($event)">
    <div class="segment">
        <ng-container *ngIf="!img?.src">
            <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
        </ng-container>
        <img #img>
    </div>

    <div class="segment">
        <msc-button class="msc-button--secondary" palette="primary" (click)="onTrigger()">Change</msc-button>
    </div>
</div>

<input type="file" #file (change)="onUpload($event.target.files)">
