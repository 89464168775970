<div class="load-more" *ngIf="pagination?.currentPage < pagination?.lastPage">
    <ng-container *ngIf="loading; else loadMoreTemplate">
        <msc-common-spinner></msc-common-spinner>
    </ng-container>

    <ng-template #loadMoreTemplate>
        <msc-button class="msc-button--primary" palette="secondary" (click)="loadMore($event)" data-cy="load-more-button">
            {{ 'words.load-more' | translate }}
        </msc-button>
    </ng-template>
</div>
