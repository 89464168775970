import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { environment } from '@env/environment';

@Injectable()
export class ManageInstanceProvider {

    constructor(private http: HttpClient) {}

    unsubscribe(id: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/subscriptions/courses/${id}`, {params}).pipe(
            map((data) => data)
        );
    }

    list(id: string|number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances`, {params}).pipe(
            map((data) => data)
        );
    }

    getMeta(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/meta`, {params}).pipe(
            map((data: any) => data.meta)
        );
    }

    update(id: number, instanceId: number, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}`, params).pipe(
            map((data) => data)
        );
    }

    get(id: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}`, {params}).pipe(
            map((data) => data)
        );
    }

    getSettings(id: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/settings`, {params}).pipe(
            map((data) => data)
        );
    }

    updateSettings(id: number, instanceId: number, context: string, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/settings?context=${context}`, params).pipe(
            map((data) => data)
        );
    }

    getManagers(id: string|number, instanceId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/managers`, {params}).pipe(
            map((data) => data)
        );
    }

    getAvailableManagers(id: number, instanceId: number, params?: any): Observable<any> {
        const response = {
            managers: [],
            pagination: {}
        };

        return this.http.get(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/available-managers`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.managers = data.available;
                return response;
            })
        );
    }

    addManagers(id: number, instanceId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/managers`, params).pipe(
            map((data) => data)
        );
    }

    deleteManager(id: number, instanceId: number, managerId: number, params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/managers/${managerId}`, {params}).pipe(
            map((data) => data)
        );
    }

    archive(id: number, instanceId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/companies/${id}/manage/instances/${instanceId}/hide`, params).pipe(
            map((data) => data)
        );
    }
}
