<section class="head">
    <h3>Empty</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</p>
</section>

<section class="body">
    <div class="segment">
        <h4>Empty</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-empty></msc-common-empty>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Empty with message</h4>
        <hr>
        <div class="partition cards">
            <div class="segment">
                <msc-common-empty [message]="'Hello darkness, my old friend'"></msc-common-empty>
            </div>
            <div class="segment">
                <msc-common-empty [message]="'Ive come to talk with you again'"></msc-common-empty>
            </div>
            <div class="segment">
                <msc-common-empty [message]="'Because a vision softly creeping'"></msc-common-empty>
            </div>
            <div class="segment">
                <msc-common-empty [message]="'Left its seeds while I was sleeping'"></msc-common-empty>
            </div>
        </div>
    </div>

    <div class="segment">
        <h4>Empty with message and body</h4>
        <hr>
        <div class="partition">
            <div class="segment">
                <msc-common-empty [message]="'Lost! Lost! My Precious is lost!!'">
                    <ng-template>
                        <p>We wants it. We needs it. Must have the precious. They stole it from us. Sneaky little Hobbitses. Wicked. Tricksy. False.</p>
                        <msc-button class="msc-button--primary" palette="primary" (click)="onEvent()">Get the ring!</msc-button>
                    </ng-template>
                </msc-common-empty>
            </div>
        </div>
    </div>
</section>
