import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class CompanyUserPermissionsProvider {

    constructor(private http: HttpClient) {}

    addCompanyPermission(companyId: number|string, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/permissions`, params).pipe(
            map(data => data)
        );
    }

    addPermission(companyId: number|string, accountId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/${accountId}/permissions`, params).pipe(
            map(data => data)
        );
    }

    deletePermission(companyId: number|string, accountId: number, params: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/${accountId}/permissions`, {params}).pipe(
            map(data => data)
        );
    }
}
