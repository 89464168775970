import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'msc-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
    @Input() color: string = '';
    @Input() progress: number = 0;
    @Input() label: boolean = true;
    @Input() icon: boolean = true;
    @Input() force: boolean = false;

    constructor() {}

    ngOnInit(): void {
        // this.setProgress();
    }

    setProgress() {
        if (!this.progress) {
            this.progress = 0;
        }
        if (typeof this.progress === 'string') {
            this.progress = Number(this.progress);
        }
        this.progress = Number.parseFloat(this.progress.toFixed(2));
    }
}
