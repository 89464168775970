import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';
import { DataHelper } from '@helpers';

import { Company, CompanyCertificatesProvider } from '@lighty';
import { StorageService, TranslateService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  @ViewChild('modalEdition') modalEdition: CommonModalComponent;
  @ViewChild('modalAdd') modalAdd: CommonModalComponent;
  public emitedCertificates: any;
  public isLoading: boolean = false;
  public isLoadingSend: boolean = false;
  public currentTransactionEditItem: any;
  public TransactionEditForm: FormGroup;
  public company: Company;
  public filters: Array<any> = [];
  public filteredFilters: Array<any> = [];
  public currentSelectedFilter: any = undefined;
  public currentSelectedFilteredFilters: any = undefined;
  public inputFilter: string = '';
  public isLoadingFilter: boolean = false;
  public paginator: any;
  public paginationItems: any;

  constructor(
    private companyCertificatesProvider: CompanyCertificatesProvider,
    private cookieService: CookieService,
    private toastService: CommonToastService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.company = this.storageService.get('company');
    this.getCertificatesRequest();
    this.TransactionEditForm = this.fb.group({
    'firstname': ['', [Validators.required]],
    'lastname': ['', [Validators.required]],
    items: this.fb.array([])
    });

    this.filters = [
        {
            key: this.translateService.instant('words.email'),
            value: 'email'
        },
        {
            key: this.translateService.instant('common.status'),
            value: 'status'
        },
        {
            key: this.translateService.instant('words.transaction-experience-name'),
            value: 'experience'
        },
        {
            key: this.translateService.instant('words.transaction-template-name'),
            value: 'template'
        },
    ]
  }

  public getCertificatesRequest(): void {
    this.isLoading = true;
    this.companyCertificatesProvider
      .getCertificatesRequest(this.cookieService.get('current_company'))
      .pipe(take(1))
      .subscribe(
        (data) => {
          console.log(data)
          this.isLoading = false;
          this.buildPaginator(data.pagination);
          this.paginator = data.pagination;
          this.emitedCertificates = data.data;        
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
  }

  formatData(dateFormat: Date): String {
    const date = new Date(dateFormat);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  public openModalEdit(entryItem: any): void {
    this.currentTransactionEditItem = entryItem;
    console.log(this.currentTransactionEditItem);
    this.TransactionEditForm.controls.firstname.setValue(entryItem.firstname);
    this.TransactionEditForm.controls.lastname.setValue(entryItem.lastname);
    this.clearFormArray();
    this.buildFormArray();
    this.modalEdition.onOpen();
  }

  public closeModalEdit(): void {
    this.modalEdition.onClose();
  }

  public openModalAdd(): void {
    this.modalAdd.onOpen();
  }

  public copyId(id: string): void {
    DataHelper.clipboard(id);
    this.toastService.onInfo(this.translateService.instant('words.copied'));
  }

  get items(): FormArray {
    return this.TransactionEditForm.get('items') as FormArray;
  }

  public createItem(entryItem: any): FormGroup {
    console.log(entryItem)
    return this.fb.group({
      field: [entryItem.field || '', Validators.required],
      value: [entryItem.value || '', Validators.required]
    });
  }

  public addItem(entryItem: any): void {
    console.log(entryItem);
    this.items.push(this.createItem(entryItem));
  }


  public buildFormArray(): void {
    const dynamicData = JSON.parse(this.currentTransactionEditItem.dynamicData);
    dynamicData.forEach(element => {
        console.log(element);
      this.addItem(element);
    });
  }

  public clearFormArray(): void {
    while (this.items.length !== 0) {
      this.items.removeAt(0);
    }
  }

  public updateTransaction(): void {
    const transformedArray = this.TransactionEditForm.get('items').value.map(item => {
        return { [item.field]: item.value };
      });

    const params =  {
      company_id: this.company.id,
      firstname: this.TransactionEditForm.get('firstname').value,
      lastname: this.TransactionEditForm.get('lastname').value,
      dynamicData: transformedArray,
      xertify_transaction_request_id: this.currentTransactionEditItem.id
  }
    this.companyCertificatesProvider.updateTransaction(this.cookieService.get('current_company') ,params).pipe(take(1)).subscribe({
        next: (data) => {
            console.log(data);
            this.toastService.onSuccess(this.translateService.instant(data.message));
            this.getCertificatesRequest();
            this.modalEdition.onClose();
        },
        error: (err) => {
            this.toastService.onError(this.translateService.instant(err.error.error));
            console.log(err);
        }
    })
  }


  public sendTransaction(xertify_id: any): void {
    this.isLoadingSend = true;
    const params =   {
        company_id: this.company.id,
        xertify_transaction_request_id: xertify_id
    }

    this.companyCertificatesProvider.sendTransaction(this.cookieService.get('current_company') ,params).pipe(take(1)).subscribe({
        next: (data) => {
            console.log(data);
            this.toastService.onSuccess(this.translateService.instant(data.message));
            this.getCertificatesRequest();
            this.isLoadingSend = false;
        },
        error: (err) => {
            console.log(err);
            this.toastService.onError(this.translateService.instant(err.error.error));
            this.isLoadingSend = false;
        }
    })
  }

  public onSelectType(filter: any, origin: any): void {
    if(origin == 'filter') {
      this.currentSelectedFilter = filter;
      this.currentSelectedFilteredFilters = undefined;
      if(filter.value != 'email') {
        this.inputFilter = '';
        this.isLoadingFilter = true;
        const params = {
          filter: this.currentSelectedFilter.value
        };
        this.companyCertificatesProvider.getFilter(params).pipe(take(1)).subscribe({
          next: (data) => {       
            this.isLoadingFilter = false;
            console.log(data);
            this.filteredFilters = data.data;
          },
          error: (err) => {
            this.isLoading = false;
            this.isLoadingFilter = false;
            console.log(err);
          }
        })
      }
    } else {
      this.currentSelectedFilteredFilters = filter;
    }
  }

  public clearFilters(): void {
    this.currentSelectedFilter = undefined;
    this.currentSelectedFilteredFilters = undefined;
    this.inputFilter = '';
    this.getCertificatesRequest();
  }

  public buildPaginator(paginationInfo: any): void {
    this.paginator = undefined;
    this.paginationItems = [];
    this.paginationItems = Array(paginationInfo.lastPage).fill(0).map((x, i) => i);
    setTimeout(() => {
          const paginationNumbers = document.querySelectorAll('.paginationItem');
    paginationNumbers[0].className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white';
    }, 100);
  }

  public setPaginator(index: number, e: any): void {
    const element = e.target as HTMLElement;
    const paginationNumbers = document.querySelectorAll('.paginationItem');
    paginationNumbers.forEach(item => {
      (item as HTMLElement).className = 'paginationItem flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
    });
    element.className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white';
    this.applyPagination(index);
  }

  public lastFirstPage(origin: string): void {
    const paginationNumbers = document.querySelectorAll('.paginationItem');
    paginationNumbers.forEach(item => {
      (item as HTMLElement).className = 'paginationItem flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
    });
    if(origin == 'first') {
      paginationNumbers[0].className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
      this.applyPagination(1);
    } else {
      paginationNumbers[this.paginator.lastPage -1].className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
      this.applyPagination(this.paginator.lastPage);
    }
  }

  public applyPagination(pageIndex: number): void {
    this.isLoading = true;
    let params;
    if(this.currentSelectedFilter && (this.currentSelectedFilteredFilters || this.inputFilter)) {
       params = {
        page: pageIndex,
        filter: this.currentSelectedFilter != undefined ? this.currentSelectedFilter?.value : '',
        value: this.inputFilter == '' ? this.currentSelectedFilteredFilters?.value : this.inputFilter
      }
    } else {
      params = {
        page: pageIndex,
      }
    }
    this.companyCertificatesProvider
    .getCertificatesRequestPaginated(this.cookieService.get('current_company'), params)
    .pipe(take(1))
    .subscribe(
      (data) => {
          console.log(data)
          this.isLoading = false;
        if(data){
          this.paginator = data?.pagination;
          this.emitedCertificates = data?.data;
         // this.buildPaginator(this.paginator);
        } else {
          this.paginator = undefined;
          this.emitedCertificates = [];
        }
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  public filter(): void {
    this.paginator = undefined;
    this.paginationItems = [];
    this.isLoading = true;
    const params = {
      filter: this.currentSelectedFilter.value,
      value: this.inputFilter == '' ? this.currentSelectedFilteredFilters.value : this.inputFilter
    }
    this.companyCertificatesProvider
    .getCertificatesRequestFiltered(this.cookieService.get('current_company'), params)
    .pipe(take(1))
    .subscribe(
      (data) => {
        console.log(data)
        this.isLoading = false;
      if(data){
        this.emitedCertificates = data?.data;
        this.buildPaginator(data?.pagination);
        this.paginator = data?.pagination;
        console.log(this.paginator)
      } else {
        this.paginator = undefined;
        this.emitedCertificates = [];
      }
    },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }
}
