<div class="content-camps content-camps__sessions">
    <div class="content-camps__title font-bold" *ngIf="!breadcrumb">
        <div><h3>{{ 'instance.title' | translate }}</h3></div>
    </div>

    <ul class="navigation-breadcrumb" *ngIf="breadcrumb">
        <li (click)="goToTemplate()">
            <a>{{ 'words.my-templates' | translate }}</a>
        </li>

        <li class="disable">
            <a>{{ templates[0].title ? templates[0].title : 'instance.untitled' | translate }}</a>
        </li>
    </ul>

    <div class="flex justify-between div-filter-campament">
        <div class="flex gap-2 filter-campament">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>

            <ng-container *ngIf="availableTypes">
                <msc-common-select class="msc-select--small" [data]="availableTypes" [isButton]="true" type="multi">
                    <ng-template #buttonTemplate let-data="data">
                        <span class="font-bold text-xs text-gray-600">{{ 'words.types' | translate }}</span>
                    </ng-template>
                    <ng-template #optionTemplate let-datum="datum">
                        <label class="flex py-2 w-full">
                            <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectType(datum)"></msc-common2-checkbox>
                            <span class="ml-2">{{ datum.label }}</span>
                        </label>
                    </ng-template>
                </msc-common-select>
            </ng-container>

            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                {{ 'words.filters' | translate }}
            </msc-button>

            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>
        </div>

        <div *ngIf="!breadcrumb">
            <msc-button class="msc-button--primary" palette="primary" (click)="openExternalResourceAside()" icon="icon-pin-2">
                {{ 'words.pin-content' | translate }}
            </msc-button>
        </div>
    </div>

    <div class="content-camps__list animate" *ngIf="!breadcrumb">
        <div class="dynamic-table" *ngFor="let template of templates; let index = index">
            <msc-common-accordion [title]="(template.data.title | truncate: 50) || ('instance.untitled' | translate)">
                <ng-template>
                    <div *ngIf="template.type === 'external_content'">
                        <div class="dynamic-table__container" [ngClass]="{'active': !displays.dropdowns.contents[index]}" [(autoClose)]="!displays.dropdowns.contents[index]">
                            <!-- <div class="dynamic-table__displayer" (click)="displayedExternal('dropdowns', 'contents', index)">
                                <div class="dynamic-table__displayer__title">
                                    {{ (template.data.title | truncate: 50) || ('instance.untitled' | translate) }}
                                </div>

                                <i class="icon-displayer icon icon-arrow-ios-down"></i>
                            </div> -->

                            <div class="dynamic-table__body">
                                <div class="dynamic-table__row row-radius row-shadow">
                                    <div class="dynamic-table__cell cell-logo" [tooltip]="template.data?.provider?.name ? template.data?.provider?.name : ''">
                                        <i class="icon icon-pin-2 color--orange" *ngIf="!template.data?.provider"></i>

                                        <div class="cell-logo__picture" [style.backgroundImage]="'url(' + getLogo(template) + ')'" *ngIf="template.data?.provider"></div>
                                    </div>

                                    <div class="dynamic-table__cell cell-title cell-title--tooltip cell-session flex">
                                        <div class="cell-session__title" [tooltip]="template.data.title && template.data.title.length > 50 ? template.data.title : ''">
                                            {{ (template.data.title | truncate: 50) || ('instance.untitled' | translate) }}
                                        </div>
                                    </div>

                                    <div class="dynamic-table__cell cell-actions cell-actions-icon hidden-xs">
                                        <div class="cell-actions-dropdown">
                                            <div class="dropdown-actions dropdown-manage-stats" [ngClass]="{'active': displays.dropdowns.people[index]}" [(autoClose)]="displays.dropdowns.people[index]">
                                                <div class="dropdown-actions__selected" (click)="displayedExternal('dropdowns','people', index)" [tooltip]="'words.members' | translate">
                                                    <i class="cell-actions__icon icon icon-add-people"></i>
                                                </div>

                                                <div class="dropdown-actions__content">
                                                    <ul class="dropdown-actions__list">
                                                        <li class="dropdown-actions__item" (click)="setCurrentContent(template.data, 'externalResource'); campMembersAside.onOpen(currentExternalContent, 'externalResource')">
                                                            <a>
                                                                {{ 'words.members' | translate }}
                                                            </a>
                                                        </li>

                                                        <li class="dropdown-actions__item" (click)="setCurrentExternalContent(template.data); campTrainersAside.onOpen(currentExternalContent)">
                                                            <a>
                                                                {{ 'words.trainers' | translate }}
                                                            </a>
                                                        </li>

                                                        <li class="dropdown-actions__item" (click)="openManageCommunities(template.data)">
                                                            <a>
                                                                {{ 'words.communities' | translate }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div [tooltip]="'settings.group.title' | translate" *ngIf="template.type === 'external_resource'">
                                            <i class="cell-actions__icon icon icon-edit" (click)="openEditExternalResourceAside(template.data)"></i>
                                        </div>

                                        <!-- <ng-container *ngIf="template.data?.type !== 'external_resource'">
                                            <div [tooltip]="'manage.follow-up' | translate" (click)="navigateAttestation(template.data)">
                                                <i class="cell-actions__icon icon icon-file-text"></i>
                                            </div>
                                        </ng-container> -->

                                        <div [tooltip]="template.tooltipUnpublish">
                                            <i class="cell-actions__icon icon icon-checkmark-circle" [ngClass]="{'disabled': !template.isUnpublishable}"
                                                (click)="onOpenModalUnpublish(modalUnpublish, template)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="template.type !== 'external_content'">
                        <div class="dynamic-table__container" [ngClass]="{'active': !displayDropdowns.contents[index]}" [(autoClose)]="!displayDropdowns.contents[index]" *ngIf="template.type === 'course_template'">
                            <!-- <div class="dynamic-table__displayer" (click)="displayed('contents', index)">
                                <div [tooltip]="'instance.not-visible-in-catalogue' | translate" direction="bottom" *ngIf="template.data.isVisibleInCatalogue">
                                    <i class="icon icon-eye-off color--grey"></i>
                                </div>

                                <div class="dynamic-table__displayer__title" (click)="goToTemplate(template.data.slug)">
                                    {{ (template.data.title | truncate: 40) || ('instance.untitled' | translate) }} - {{ template.settings?.instancesCount }}
                                </div>

                                <i class="icon-displayer icon icon-arrow-ios-down"></i>
                            </div> -->

                            <div class="dynamic-table__body">
                                <div class="dynamic-table__body__ondemand" *ngIf="template.settings?.onDemand">
                                    <div class="dynamic-table__body__ondemand__text">
                                        {{ 'words.content-od' | translate }}
                                    </div>

                                    <div class="dynamic-table__body__ondemand__block">
                                        <div class="dynamic-table__body__ondemand__member" (click)="campOndemandAside.onOpen(template.data)">
                                            <i class="icon icon-people"></i>
                                            {{ template.data.savedContentsUsersCount | empty: '0' }}
                                        </div>

                                        <!-- <i class="icon icon-trash" (click)="modalDisableOnDemand.onOpen(); openModalDisableOnDemand(template.data)"></i> -->
                                    </div>
                                </div>

                                <div class="dynamic-table__row row-radius row-shadow" [ngClass]="{'dynamic-table__row--margin' : template.settings?.onDemand}" *ngFor="let courseCamp of template.courseCamps ; let subindex = index">
                                    <div class="dynamic-table__cell cell-logo" [tooltip]="template.data?.provider?.name ? template.data?.provider?.name: ''">
                                        <i class="icon icon-cube color--orange" *ngIf="!template.data?.provider"></i>

                                        <div class="cell-logo__picture" [style.backgroundImage]="'url(' + getLogo(template) + ')'" *ngIf="template.data?.provider"></div>
                                    </div>

                                    <div class="dynamic-table__cell cell-title cell-title--tooltip cell-session">
                                        <div class="cell-session__title" [tooltip]="courseCamp.title" direction="bottom" *ngIf="courseCamp.title && courseCamp.title.length >= 30">
                                            {{ (courseCamp.title | truncate: 30) || ('instance.untitled' | translate) }}
                                        </div>

                                        <div class="cell-session__title flex align-center" *ngIf="courseCamp.title && courseCamp.title.length < 30">
                                            {{ (courseCamp.title | truncate: 30) || ('instance.untitled' | translate) }}
                                        </div>

                                        <div class="cell-session__options">
                                            <div [tooltip]="'words.catalogue' | translate" *ngIf="courseCamp.isCatalogue">
                                                <i class="icon icon-catalogue"></i>
                                            </div>

                                            <div [tooltip]="'instance.locked-course' | translate" *ngIf="courseCamp.isLocked">
                                                <i class="icon icon-lock"></i>
                                            </div>

                                            <div [tooltip]="'words.survey' | translate" *ngIf="courseCamp.hasSurvey">
                                                <i class="icon icon-content"></i>
                                            </div>

                                            <div [tooltip]="'instance.respect-section' | translate" *ngIf="courseCamp.isOrdered">
                                                <i class="icon icon-height"></i>
                                            </div>

                                            <div [tooltip]="'instance.manager-validation' | translate" *ngIf="courseCamp.needValidation">
                                                <i class="icon icon-person-done"></i>
                                            </div>

                                            <div [tooltip]="'instance.publication.notify-manager' | translate" *ngIf="courseCamp.notifyManager">
                                                <i class="icon icon-checkmark"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="courseCamp.state">
                                        <div class="dynamic-table__cell cell-status">
                                            <div class="state-tag" [ngClass]="courseCamp.tagClass">
                                                <span>{{ ('words.instance-' + courseCamp.state) | translate }}</span>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="dynamic-table__cell cell-with-icon">
                                        <div *ngIf="courseCamp.nextEvent" [tooltip]="courseCamp.nextEvent.address ? courseCamp.nextEvent.address : ('instance.address.no-specified' | translate)">
                                            <i class="icon icon-pin"></i>
                                        </div>

                                        <i class="icon icon-people"></i>
                                        <div>{{ courseCamp.usersCount }}</div>
                                        <div *ngIf="courseCamp.usersLimit >= 1">/ {{ courseCamp.usersLimit }}</div>
                                        <div *ngIf="courseCamp.usersLimit < 1"></div>
                                        <!--                            <div *ngIf="courseCamp.usersLimit < 1">/ -</div>-->
                                    </div>

                                    <div class="dynamic-table__cell cell-actions cell-actions-icon hidden-xs">
                                        <div class="cell-actions-dropdown">
                                            <div class="dropdown-actions dropdown-manage-stats"
                                                 [ngClass]="{'active': displayDropdowns.preview[index + '' + subindex]}"
                                                 [(autoClose)]="displayDropdowns.preview[index + '' + subindex]">
                                                <div class="dropdown-actions__selected"
                                                     [tooltip]="'extension.header.title.summary' | translate"
                                                     (click)="displayed('preview', index + '' + subindex)">
                                                    <i class="cell-actions__icon icon icon-eye"></i>
                                                </div>

                                                <div class="dropdown-actions__content">
                                                    <ul class="dropdown-actions__list">
                                                        <li class="dropdown-actions__item">
                                                            <a (click)="navigatePlayer(courseCamp.courseId || courseCamp.course.id)">
                                                                {{ 'words.preview' | translate }}
                                                            </a>
                                                        </li>

                                                        <li class="dropdown-actions__item" (click)="copyLink(courseCamp)">
                                                            <a>
                                                                {{ 'template.copy-clipboard' | translate }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="cell-actions-dropdown">
                                            <div class="dropdown-actions dropdown-manage-stats"
                                                 [ngClass]="{'active': displayDropdowns.people[index + '' + subindex]}"
                                                 [(autoClose)]="displayDropdowns.people[index + '' + subindex]">
                                                <div class="dropdown-actions__selected"
                                                     (click)="displayed('people', index + '' + subindex)"
                                                     [tooltip]="'words.members' | translate">
                                                    <i class="cell-actions__icon icon icon-add-people"></i>
                                                </div>

                                                <div class="dropdown-actions__content">
                                                    <ul class="dropdown-actions__list">
                                                        <li class="dropdown-actions__item" (click)="setCurrentContent(courseCamp); campMembersAside.onOpen(currentCourseCamp)">
                                                            <a>{{ 'words.members' | translate }}</a>
                                                        </li>

                                                        <li class="dropdown-actions__item" (click)="openManageGroups(courseCamp)">
                                                            <a>{{ 'words.communities' | translate }}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="cell-actions-dropdown">
                                            <div class="dropdown-actions dropdown-manage-stats"
                                                 [ngClass]="{'active': displayDropdowns.attach[index + '' + subindex]}"
                                                 [(autoClose)]="displayDropdowns.attach[index + '' + subindex]">
                                                <div class="dropdown-actions__selected"
                                                     (click)="displayed('attach', index + '' + subindex)"
                                                     [tooltip]="'words.file' | translate">
                                                    <i class="cell-actions__icon icon icon-attach-1"></i>
                                                </div>

                                                <div class="dropdown-actions__content">
                                                    <ul class="dropdown-actions__list">
                                                        <li class="dropdown-actions__item" (click)="downloadSurvey(courseCamp)">
                                                            <a>{{ 'instance.survey' | translate }}</a>
                                                        </li>

                                                        <li class="dropdown-actions__item" (click)="downloadAttendance(courseCamp)">
                                                            <a>{{ 'instance.attendance-list' | translate }}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div [tooltip]="'settings.group.title' | translate">
                                            <i class="cell-actions__icon icon icon-settings-2" (click)="openAsidePublication(courseCamp)"></i>
                                        </div>

                                        <div [tooltip]="courseCamp.tooltipUnpublish">
                                            <i class="cell-actions__icon icon icon-checkmark-circle" [ngClass]="{'disabled': !courseCamp.isUnpublishable}"
                                                (click)="onOpenModalUnpublish(modalUnpublish, template, courseCamp)"></i>
                                        </div>
                                    </div>

                                    <div class="dynamic-table__cell cell-actions-dropdown cell-actions-dropdown--responsive">
                                        <div class="dropdown-actions dropdown-manage dropdown-manage-stats"
                                             [ngClass]="{'active': displayDropdowns.responsive[index + '' + subindex]}"
                                             [(autoClose)]="displayDropdowns.responsive[index + '' + subindex]">
                                            <div class="dropdown-actions__selected"
                                                 (click)="displayed('responsive', index + '' + subindex)">
                                                <i class="icon icon-more-horizontal"></i>
                                            </div>

                                            <div class="dropdown-actions__content">
                                                <ul class="dropdown-actions__list">
                                                    <li class="dropdown-actions__item">
                                                        <a (click)="navigatePlayer(courseCamp.courseId || courseCamp.course.id)">
                                                            <i class="icon icon-eye"></i>
                                                            {{ 'words.preview' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item" (click)="copyLink(courseCamp)">
                                                        <a>
                                                            <i class="icon icon-eye"></i>
                                                            {{ 'template.copy-clipboard' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item" (click)="setCurrentContent(courseCamp); campMembersAside.onOpen(currentCourseCamp)">
                                                        <a>
                                                            <i class="icon icon-add-people"></i>
                                                            {{ 'words.members' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item" (click)="openManageGroups(courseCamp)">
                                                        <a>
                                                            <i class="icon icon-add-people"></i>
                                                            {{ 'words.communities' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item" (click)="downloadSurvey(courseCamp)">
                                                        <a>
                                                            <i class="icon icon-attach-1"></i>
                                                            {{ 'instance.survey' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item" (click)="downloadAttendance(courseCamp)">
                                                        <a>
                                                            <i class="icon icon-attach-1"></i>
                                                            {{ 'instance.attendance-list' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item" (click)="openAsidePublication(courseCamp)">
                                                        <a>
                                                            <i class="icon icon icon-settings-2"></i>
                                                            {{ 'words.settings' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item"
                                                        (click)="openDeleteCourse(courseCamp, template.data)">
                                                        <a>
                                                            <i class="icon icon-trash-2"></i>
                                                            {{ 'words.delete' | translate }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dynamic-table__container" [ngClass]="{'active': !displayDropdowns.contents[index]}" [(autoClose)]="!displayDropdowns.preview[index]" *ngIf="template.type === 'traject'">
                        <!-- <div class="dynamic-table__displayer" (click)="displayed('contents', index)">
                            <div class="dynamic-table__displayer__title">
                                {{ template.data.title | truncate: 40 }}

                                <div class="dynamic-table__displayer__alert" [tooltip]="'instance.tooltip.learning-path.unpublished' | translate" *ngIf="template.data.isUnpublished">
                                    <i class="icon icon-alert-triangle"></i>
                                </div>
                            </div>

                            <i class="icon-displayer icon icon-arrow-ios-down"></i>
                        </div> -->

                        <div class="dynamic-table__body">
                            <div class="dynamic-table__row row-radius row-shadow">
                                <div class="dynamic-table__cell cell-logo">
                                    <i class="icon icon-trending-up color--orange"></i>
                                </div>

                                <div class="dynamic-table__cell cell-title cell-title--tooltip cell-session">
                                    <div class="cell-session__title" *ngIf="template.data.title && template.data.title.length < 40">
                                        {{ template.data.title | truncate: 40 }}
                                    </div>

                                    <div class="cell-session__title" [tooltip]="template.data.title" direction="bottom" *ngIf="template.data.title && template.data.title.length >= 40">
                                        {{ (template.data.title | truncate: 30) || ('instance.untitled' | translate) }}
                                    </div>

                                    <div class="cell-session__options">
                                        <div [tooltip]="'words.catalogue' | translate" direction="bottom" *ngIf="template.settings?.isCatalogue">
                                            <i class="icon icon-catalogue"></i>
                                        </div>

                                        <div [tooltip]="'instance.publication.notify-manager' | translate" direction="bottom" *ngIf="template.settings?.notifyManager">
                                            <i class="icon icon-checkmark"></i>
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="template.data.state">
                                    <div class="dynamic-table__cell cell-status">
                                        <div class="state-tag" [ngClass]="template.tagClass">
                                            <span>{{ ('words.instance-' + template.data.state) | translate }}</span>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="dynamic-table__cell cell-with-icon">
                                    <i class="icon icon-people"></i>
                                    <div *ngIf="template.settings?.usedSeats">{{ template.settings.usedSeats }}</div>
                                    <div *ngIf="template.settings?.usersLimit >= 1">/ {{ template.settings.usersLimit }}</div>
                                    <div *ngIf="template.settings?.usersLimit < 1"></div>
                                </div>

                                <div class="dynamic-table__cell cell-actions cell-actions-icon hidden-xs">
                                    <div class="cell-actions-dropdown">
                                        <div class="dropdown-actions dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.people[index]}" [(autoClose)]="displayDropdowns.people[index]">
                                            <div class="dropdown-actions__selected" (click)="displayed('people', index)" [tooltip]="'words.members' | translate">
                                                <i class="cell-actions__icon icon icon-add-people"></i>
                                            </div>

                                            <div class="dropdown-actions__content">
                                                <ul class="dropdown-actions__list">
                                                    <li class="dropdown-actions__item" (click)="setCurrentContent(template.data, 'journey'); campMembersAside.onOpen(currentJourney, 'journey')">
                                                        <a>
                                                            {{ 'words.members' | translate }}
                                                        </a>
                                                    </li>

                                                    <li class="dropdown-actions__item" (click)="openAsideSubscription(template.data, template.settings)">
                                                        <a>
                                                            {{ 'words.add-new-participants' | translate }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="cell-actions-dropdown">
                                        <div class="dropdown-actions dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.attach[index]}" [(autoClose)]="displayDropdowns.attach[index]">
                                            <div class="dropdown-actions__selected" (click)="displayed('attach', index)" [tooltip]="'words.file' | translate">
                                                <i class="cell-actions__icon icon icon-attach-1"></i>
                                            </div>

                                            <div class="dropdown-actions__content">
                                                <ul class="dropdown-actions__list">
                                                    <li class="dropdown-actions__item" (click)="downloadAttendanceJourney(template.data)">
                                                        <a>
                                                            {{ 'instance.attendance-list' | translate }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div [tooltip]="'settings.group.title' | translate">
                                        <i class="cell-actions__icon icon icon-settings-2" (click)="openAsidePublication(template.data, template.settings, 'journey')"></i>
                                    </div>

                                    <div [tooltip]="template.tooltipUnpublish">
                                        <i class="cell-actions__icon icon icon-checkmark-circle" [ngClass]="{'disabled': !template.isUnpublishable}"
                                            (click)="onOpenModalUnpublish(modalUnpublish, template)"></i>
                                    </div>
                                </div>

                                <div class="dynamic-table__cell cell-actions-dropdown cell-actions-dropdown--responsive">
                                    <div class="dropdown-actions dropdown-manage dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.responsive[index]}" [(autoClose)]="displayDropdowns.responsive[index]">
                                        <div class="dropdown-actions__selected" (click)="displayed('responsive', index)">
                                            <i class="icon icon-more-horizontal"></i>
                                        </div>

                                        <div class="dropdown-actions__content">
                                            <ul class="dropdown-actions__list">
                                                <li class="dropdown-actions__item" (click)="setCurrentContent(template.data, 'journey'); campMembersAside.onOpen(currentJourney, 'journey')">
                                                    <a>
                                                        <i class="icon icon-add-people"></i>
                                                        {{ 'words.members' | translate }}
                                                    </a>
                                                </li>

                                                <li class="dropdown-actions__item" (click)="openAsideSubscription(template.data, template.settings)">
                                                    <a>
                                                        <i class="icon icon-add-people"></i>
                                                        {{ 'words.add-new-participants' | translate }}
                                                    </a>
                                                </li>

                                                <li class="dropdown-actions__item" (click)="downloadAttendanceJourney(template.data)">
                                                    <a>
                                                        <i class="icon icon-attach-1"></i>
                                                        {{ 'instance.attendance-list' | translate }}
                                                    </a>
                                                </li>

                                                <li class="dropdown-actions__item" (click)="openAsidePublication(template.data)">
                                                    <a>
                                                        <i class="icon icon icon-settings-2"></i>
                                                        {{ 'words.settings' | translate }}
                                                    </a>
                                                </li>

                                                <li class="dropdown-actions__item" (click)="openDeleteCourse(template.data, template.data)">
                                                    <a>
                                                        <i class="icon icon icon-trash-2"></i>
                                                        {{ 'words.delete' | translate }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dynamic-table__container" [ngClass]="{'active': !displayDropdowns.contents[index]}" [(autoClose)]="!displayDropdowns.preview[index]" *ngIf="template.type === 'external_resource'">
                        <!-- <div class="dynamic-table__displayer" (click)="displayed('contents', index)">
                            <div class="dynamic-table__displayer__title">
                                {{ template.data.title | truncate: 40 }}
                            </div>

                            <i class="icon-displayer icon icon-arrow-ios-down"></i>
                        </div> -->

                        <div class="dynamic-table__body">
                            <div class="dynamic-table__row row-radius row-shadow">
                                <div class="dynamic-table__cell cell-logo">
                                    <i class="icon icon-pin-2 color--orange"></i>
                                </div>

                                <div class="dynamic-table__cell cell-title cell-title--tooltip cell-session">
                                    <div class="cell-session__title" *ngIf="template.data.title && template.data.title.length < 40">{{ template.data.title | truncate: 40 }}</div>

                                    <div class="cell-session__title" [tooltip]="template.data.title" direction="bottom" *ngIf="template.data.title && template.data.title.length >= 40">
                                        {{ (template.data.title | truncate: 30) || ('instance.untitled' | translate) }}
                                    </div>

                                    <div class="cell-session__options">
                                        <div [tooltip]="'words.catalogue' | translate" direction="bottom">
                                            <i class="icon icon-catalogue"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="dynamic-table__cell cell-with-icon">
                                    <i class="icon icon-people"></i>
                                    <div>{{ template.settings?.usersCount }}</div>
                                </div>

                                <div class="dynamic-table__cell cell-actions cell-actions-icon hidden-xs cell-actions-dropdown">
                                    <div class="dropdown-actions dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.people[index]}" [(autoClose)]="displayDropdowns.people[index]">
                                        <div class="dropdown-actions__selected" (click)="displayed('people', index)" [tooltip]="'words.member' | translate">
                                            <i class="cell-actions__icon icon icon-add-people"></i>
                                        </div>

                                        <div class="dropdown-actions__content">
                                            <ul class="dropdown-actions__list">
                                                <li class="dropdown-actions__item" (click)="setCurrentContent(template.data, 'externalResource'); campMembersAside.onOpen(currentExternalContent, 'externalResource')">
                                                    <a>
                                                        {{ 'words.members' | translate }}
                                                    </a>
                                                </li>

                                                <li class="dropdown-actions__item" (click)="openManageGroups(template.data, 'externalResource')">
                                                    <a>
                                                        {{ 'words.communities' | translate }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div [tooltip]="'settings.group.title' | translate">
                                        <i class="cell-actions__icon icon icon-edit" (click)="openEditExternalResourceAside(template.data)"></i>
                                    </div>

                                    <div [tooltip]="'words.delete' | translate">
                                        <i class="cell-actions__icon con icon-trash-2" (click)="modalDeleteExternalContent.onOpen(); openDeleteExternalContent(template.data)"></i>
                                    </div>
                                </div>

                                <div class="dynamic-table__cell cell-actions-dropdown cell-actions-dropdown--responsive">
                                    <div class="dropdown-actions dropdown-manage dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.responsive[index]}" [(autoClose)]="displayDropdowns.responsive[index]">
                                        <div class="dropdown-actions__selected" (click)="displayed('responsive', index)">
                                            <i class="icon icon-more-horizontal"></i>
                                        </div>

                                        <div class="dropdown-actions__content">
                                            <ul class="dropdown-actions__list">
                                                <li class="dropdown-actions__item" (click)="modalDeleteExternalContent.onOpen(); openDeleteExternalContent(template.data)">
                                                    <a>
                                                        <i class="icon icon-trash-2"></i>
                                                        {{ 'words.delete' | translate }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </msc-common-accordion>
        </div>

        <msc-load-more [loading]="loading" [pagination]="pagination" (onLoad)="loadMore()"></msc-load-more>
    </div>

    <div class="content-camps__list" *ngIf="breadcrumb">
        <div class="dynamic-table" *ngFor="let template of templates; let index=index">
            <div class="dynamic-table__container active">
                <div class="dynamic-table__displayer">
                    <div class="dynamic-table__displayer__title">
                        {{ templates[0].title ? templates[0].title : 'instance.untitled' | translate }}
                    </div>
                </div>

                <div class="dynamic-table__body">
                    <div class="dynamic-table__body__ondemand" *ngIf="template.settings?.onDemand">
                        <div class="dynamic-table__body__ondemand__text">
                            {{ 'words.content-od' | translate }}
                        </div>

                        <div class="dynamic-table__body__ondemand__block">
                            <div class="dynamic-table__body__ondemand__member" (click)="campOndemandAside.onOpen(template)">
                                <i class="icon icon-people"></i>
                                {{ template.savedContentsUsersCount | empty: '0' }}
                            </div>

                            <!-- <i class="icon icon-trash" (click)="modalDisableOnDemand.onOpen(); openModalDisableOnDemand(template.data)"></i> -->
                        </div>
                    </div>

                    <div class="dynamic-table__row row-radius row-shadow" [ngClass]="{'dynamic-table__row--margin' : template.settings?.onDemand}" *ngFor="let courseCamp of template.courseCamps ; let subindex = index">
                        <div class="dynamic-table__cell cell-title cell-title--tooltip cell-session">
                            <div class="cell-session__title" [tooltip]="courseCamp.internalName" direction="bottom" *ngIf="courseCamp.internalName.length >= 30">
                                {{ (courseCamp.internalName ? (courseCamp.internalName | truncate: 30) : ('instance.untitled' | translate)) }}
                            </div>

                            <div class="cell-session__title" *ngIf="courseCamp.internalName.length < 30">
                                {{ (courseCamp.internalName ? (courseCamp.internalName | truncate: 30) : ('instance.untitled' | translate)) }}
                            </div>

                            <div class="cell-session__options">
                                <div [tooltip]="'words.catalogue' | translate" direction="bottom" *ngIf="courseCamp.isCatalogue">
                                    <i class="icon icon-catalogue"></i>
                                </div>

                                <div [tooltip]="'instance.locked-course' | translate" direction="bottom" *ngIf="courseCamp.isLocked">
                                    <i class="icon icon-lock"></i>
                                </div>

                                <div [tooltip]="'words.survey' | translate" direction="bottom" *ngIf="courseCamp.hasSurvey">
                                    <i class="icon icon-content"></i>
                                </div>

                                <div [tooltip]="'instance.respect-section' | translate" direction="bottom" *ngIf="courseCamp.isOrdered">
                                    <i class="icon icon-height"></i>
                                </div>

                                <div [tooltip]="'instance.manager-validation' | translate" direction="bottom" *ngIf="courseCamp.needValidation">
                                    <i class="icon icon-person-done"></i>
                                </div>

                                <div [tooltip]="'instance.publication.notify-manager' | translate" direction="bottom" *ngIf="courseCamp.notifyManager">
                                    <i class="icon icon-checkmark"></i>
                                </div>
                            </div>
                        </div>

                        <div class="dynamic-table__cell cell-with-icon">
                            <div *ngIf="courseCamp.nextEvent" [tooltip]="courseCamp.nextEvent.address ? courseCamp.nextEvent.address : ('instance.address.no-specified' | translate)">
                                <i class="icon icon-pin"></i>
                            </div>

                            <i class="icon icon-people"></i>
                            <div>{{ courseCamp.usedSeats }}</div>
                            <div *ngIf="courseCamp.limit >= 1">/ {{ courseCamp.limit }}</div>
                            <div *ngIf="courseCamp.limit < 1">/ -</div>
                        </div>

                        <div class="dynamic-table__cell cell-actions cell-actions-icon hidden-xs">
                            <div class="cell-actions-dropdown">
                                <div class="dropdown-actions dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.preview[index + '' + subindex]}" [(autoClose)]="displayDropdowns.preview[index + '' + subindex]">
                                    <div class="dropdown-actions__selected" (click)="displayed('preview', index + '' + subindex)" [tooltip]="'extension.header.title.summary' | translate">
                                        <i class="cell-actions__icon icon icon-eye"></i>
                                    </div>

                                    <div class="dropdown-actions__content">
                                        <ul class="dropdown-actions__list">
                                            <li class="dropdown-actions__item">
                                                <a (click)="navigatePlayer(courseCamp.courseId || courseCamp.course.id)">
                                                    {{ 'words.preview' | translate }}
                                                </a>
                                            </li>

                                            <li class="dropdown-actions__item" (click)="copyLink(courseCamp)">
                                                <a>
                                                    {{ 'template.copy-clipboard' | translate }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="cell-actions-dropdown">
                                <div class="dropdown-actions dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.people[index + '' + subindex]}" [(autoClose)]="displayDropdowns.people[index + '' + subindex]">
                                    <div class="dropdown-actions__selected" (click)="displayed('people', index + '' + subindex)" [tooltip]="'words.members' | translate">
                                        <i class="cell-actions__icon icon icon-add-people"></i>
                                    </div>

                                    <div class="dropdown-actions__content">
                                        <ul class="dropdown-actions__list">
                                            <li class="dropdown-actions__item" (click)="setCurrentContent(courseCamp); campMembersAside.onOpen(currentCourseCamp)">
                                                <a>
                                                    {{ 'words.members' | translate }}
                                                </a>
                                            </li>

                                            <li class="dropdown-actions__item" (click)="openManageGroups(courseCamp)">
                                                <a>
                                                    {{ 'words.communities' | translate }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="cell-actions-dropdown">
                                <div class="dropdown-actions dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.attach[index + '' + subindex]}" [(autoClose)]="displayDropdowns.attach[index + '' + subindex]">
                                    <div class="dropdown-actions__selected" (click)="displayed('attach', index + '' + subindex)" [tooltip]="'words.file' | translate">
                                        <i class="cell-actions__icon icon icon-attach-1"></i>
                                    </div>

                                    <div class="dropdown-actions__content">
                                        <ul class="dropdown-actions__list">
                                            <li class="dropdown-actions__item" (click)="downloadSurvey(courseCamp)">
                                                <a>
                                                    {{ 'instance.survey' | translate }}
                                                </a>
                                            </li>

                                            <li class="dropdown-actions__item" (click)="downloadAttendance(courseCamp)">
                                                <a>
                                                    {{ 'instance.attendance-list' | translate }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div [tooltip]="'settings.group.title' | translate">
                                <i class="cell-actions__icon icon icon-settings-2" (click)="openAsidePublication(courseCamp)"></i>
                            </div>

                            <div [tooltip]="'words.delete' | translate">
                                <i class="cell-actions__icon con icon-trash-2" (click)="openDeleteCourse(courseCamp, template.data)"></i>
                            </div>
                        </div>

                        <div class="dynamic-table__cell cell-actions-dropdown cell-actions-dropdown--responsive">
                            <div class="dropdown-actions dropdown-manage dropdown-manage-stats" [ngClass]="{'active': displayDropdowns.responsive[index + '' + subindex]}" [(autoClose)]="displayDropdowns.responsive[index + '' + subindex]">
                                <div class="dropdown-actions__selected" (click)="displayed('responsive', index + '' + subindex)">
                                    <i class="icon icon-more-horizontal"></i>
                                </div>

                                <div class="dropdown-actions__content">
                                    <ul class="dropdown-actions__list">
                                        <li class="dropdown-actions__item">
                                            <a (click)="navigatePlayer(courseCamp.courseId || courseCamp.course.id)">
                                                <i class="icon icon-eye"></i>
                                                {{ 'words.preview' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="copyLink(courseCamp)">
                                            <a>
                                                <i class="icon icon-eye"></i>
                                                {{ 'template.copy-clipboard' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="setCurrentContent(courseCamp); campMembersAside.onOpen(currentCourseCamp)">
                                            <a>
                                                <i class="icon icon-add-people"></i>
                                                {{ 'words.members' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="openManageGroups(courseCamp)">
                                            <a>
                                                <i class="icon icon-add-people"></i>
                                                {{ 'words.communities' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="downloadSurvey(courseCamp)">
                                            <a>
                                                <i class="icon icon-attach-1"></i>
                                                {{ 'instance.survey' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="downloadAttendance(courseCamp)">
                                            <a>
                                                <i class="icon icon-attach-1"></i>
                                                {{ 'instance.attendance-list' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="openAsidePublication(courseCamp)">
                                            <a>
                                                <i class="icon icon icon-settings-2"></i>
                                                {{ 'words.settings' | translate }}
                                            </a>
                                        </li>

                                        <li class="dropdown-actions__item" (click)="openDeleteCourse(courseCamp, template.data)">
                                            <a>
                                                <i class="icon icon-trash-2"></i>
                                                {{ 'words.delete' | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <msc-camp-aside-members #campMembersAside [camp]="camp" (onSave)="refresh()"></msc-camp-aside-members>

    <msc-camp-aside-trainers #campTrainersAside [camp]="camp" (onSave)="refresh()"></msc-camp-aside-trainers>

    <msc-camp-aside-groups #campGroupsAside [camp]="camp">
    </msc-camp-aside-groups>

    <msc-camp-aside-publication #campPublicationAside
                                [camp]="camp"
                                (onSave)="refresh();">
    </msc-camp-aside-publication>

    <msc-camp-aside-subscription #campSubscriptionAside
                                 [camp]="camp"
                                 (onSave)="refresh();">
    </msc-camp-aside-subscription>

    <msc-camp-aside-ondemand #campOndemandAside>
    </msc-camp-aside-ondemand>
</div>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>

<msc-common-modal size="40vw" #modalUnpublish [title]="'template.' + content?.type + '.modal-unpublish.title'  | translate">
    <ng-template>
        <p [innerHtml]="'template.' + content?.type + '.modal-unpublish.text' | translate"></p>
        <msc-common2-checkbox type="tick" [label]="'template.modal-unpublish.checkbox-label' | translate" (changeEvent)="onToggleNotifyUsers($event)"></msc-common2-checkbox>
        <div class="flex justify-end space-x-2">
            <msc-button (click)="modalUnpublish.onClose()">{{ 'words.cancel' | translate }}</msc-button>
            <msc-button class="msc-button--primary" palette="secondary" (click)="onUnpublishContent(modalUnpublish)">{{ 'words.confirm' | translate }}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>


