import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApplicationProvider } from '@lighty';
import { DataHelper } from '@helpers';

@Component({
    selector: 'msc-organize-integration-aside-freshchat',
    templateUrl: './aside-freshchat.component.html'
})
export class OrganizeIntegrationAsideFreshChatComponent {
    @ViewChild('aside') aside;
    @Input() freshchat: any;
    @Input() companyId: number;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public settings: any;
    public application: any;

    constructor(private applicationProvider: ApplicationProvider) {}

    init(): void {
        this.application = {
            learn: {
                id: 1,
                enable: false
            },
            create: {
                id: 2,
                enable: false
            },
            manage: {
                id: 3,
                enable: false
            },
            organize: {
                id: 4,
                enable: false
            }
        };

        this.getApplication();
    }

    onOpen() {
        this.init();
        this.aside.onOpen();
    }

    getApplication(): void {
        this.applicationProvider.get(this.companyId).subscribe(data => {
            if (data.freshchat) {
                this.settings = data.freshchat.settings;
                this.processDefaultChat();
            } else {
                this.settings = {};
            }
            this.processDefaultSettings();
        });
    }

    private processDefaultChat(): void {
        for (const name in this.settings) {
            if (this.settings.hasOwnProperty(name)) {
                this.application[name].enable = true;
            }
        }
    }

    private processDefaultSettings(): void {
        for (const name in this.application) {
            if (this.application.hasOwnProperty(name) && !this.settings.hasOwnProperty(name)) {
                this.settings[name] = {
                    areaId: this.application[name].id,
                    connectionString: null
                };
            }
        }
    }

    setChat(type: string, enable: boolean): void {
        this.application[type].enable = enable;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        for (const name in this.application) {
            if (this.application.hasOwnProperty(name) && !this.application[name].enable) {
                delete this.settings[name];
            }
        }

        this.settings = DataHelper.clone(this.settings);

        this.onSave.emit(this.settings);
    }
}
