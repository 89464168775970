import { Component } from '@angular/core';
import { CommonToastService } from '@common2/services/toast.service';
import { Company, Camp, StatsProvider } from '@lighty';
import { ErrorStreamService, ExternalAppsService, StorageService, TranslateService } from '@services';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { take } from 'rxjs';

@Component({
    selector: 'msc-exports',
    styleUrls: ['./exports.component.scss'],
    templateUrl: './exports.component.html'
})

export class OrganizeExportsComponent {
    public company: Company;
    public displays: any;
    public reports: any;
    public loading: boolean = true;
    public showOverview: boolean = false; 
    public showEngagement: boolean = false;
    public showTraject: boolean = false;
    public showLicense: boolean = false;
    public firtsOverview: any = undefined; 
    public firstEngagement: any = undefined;
    public firstTraject: any = undefined;
    public firstLicense: any = undefined;

    constructor(
        private storageService: StorageService,
        private statsProvider: StatsProvider,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');

        this.displays = {
            export: false
        };
        this.getReports();
    }

    download(modal: CommonModalComponent, ev: any): void {
        const params = {
            type: ev.type,
            format: ev.format,
            emails: ev.emails,
            year: ev.year || undefined
        };

        this.statsProvider.getExport(this.company.id, params).subscribe({
            next: (data) => {
                this.toastService.onSuccess(this.translateService.instant(data.delayed ? 'toast.send-file-mail.tonight' : 'toast.send-file-mail'));
                modal.onOpen();
                this.errorStreamService.unlocked();
            },
            error: () => {
                this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                this.errorStreamService.unlocked();
            }
        });
    }

    displayed(type: string, force?: boolean): void {
        this.displays[type] = force ? false : !this.displays[type];
    }

    public downloadReport(id: any, name: any): void {
        this.statsProvider.downloadByRange(this.company.id, id, name).pipe(take(1)).subscribe((data) => {
            console.log(data)
        })
    }

    public toggleOverview(): void {
        this.showOverview = !this.showOverview;
        this.showEngagement = false;
        this.showLicense = false;
        this.showTraject = false;

    }

    public toggleEngagement(): void {
        this.showEngagement = !this.showEngagement;
        this.showOverview = false;
        this.showLicense = false;
        this.showTraject = false;
    }

    public toggleTraject(): void {
        this.showTraject = !this.showTraject;
        this.showOverview = false;
        this.showEngagement = false;
        this.showLicense = false;
    }

    public toggleLicense(): void {
        this.showLicense = !this.showLicense;
        this.showOverview = false;
        this.showEngagement = false;
        this.showTraject = false;
    }

    public formatDate(entryDate: any): string {
            var date = new Date(entryDate);
            var year = date.getFullYear();
            var month = ('0' + (date.getMonth() + 1)).slice(-2);
            var day = ('0' + date.getDate()).slice(-2);
            var formattedDate = year + '-' + month + '-' + day;

        return formattedDate;
    }

    public getTimeWithAMPM(entryDate: string): string {
        const date: Date = new Date(entryDate);
        const hours: number = date.getHours();
        const minutes: number = date.getMinutes();
        const ampm: string = hours < 12 ? 'AM' : 'PM';
        const formattedHours: number = (hours % 12) || 12;
        const formattedTime: string = `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    
        return formattedTime;
    }

    public getReports(): void {
        this.loading = true;
        this.statsProvider.getReports(this.storageService.get('company').id).pipe(take(1)).subscribe((data) => {
            this.reports = data;  
            console.log(this.reports)
            this.firstEngagement = data.learnerEngagement.length != 0? data.learnerEngagement[0] : undefined;
            this.firtsOverview = data.learnerOverview.length != 0? data.learnerOverview[0] : undefined;
            this.firstTraject = data.learnerTrajects.length != 0? data.learnerTrajects[0] : undefined;
            this.firstLicense = data.learnerLicenses.length != 0? data.learnerLicenses[0] : undefined;
            this.reports.learnerEngagement.shift();
            this.reports.learnerOverview.shift();
            this.reports.learnerTrajects.shift();
            this.reports.learnerLicenses.shift();
            this.loading = false;
          });
    }
    
}
