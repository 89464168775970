<div class="content-invitation w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4">
    <div class="content-invitation__header ">
        <ng-container *ngIf="!isImport">
            <h3>{{ 'user.fast-invite.title' | translate }}</h3>
            <msc-button class="msc-button--outline" palette="primary" icon="icon-arrow-ios-right" [routerLink]="['../configure']">
                {{ 'user.slow-invite.title' | translate }}
            </msc-button>
        </ng-container>

        <ng-container *ngIf="isImport">
            <div class="flex flex-import">
                <h3>{{ 'user.import-invite.title' | translate }}</h3>
                <msc-button class="msc-button--outline ml-2" (click)="downloadExample()">
                    {{ 'user.import-invite.download-example' | translate }}
                </msc-button>

                <a #downloadUrl [href]="exampleUrl" target="_blank" download class="display-none"></a>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="currentStep === 0">
        <p class="content-invitation__subheader">
            <ng-container *ngIf="!isImport">
                {{ 'user.fast-invite.description' | translate }}
            </ng-container>

            <ng-container *ngIf="isImport">
                {{ 'user.import-invite.description' | translate }}
            </ng-container>
        </p>

        <div class="content-invitation__content">
            <div class="content-invitation__dataset" *ngIf="!isImport">
                <div class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5">
                    <msc-input name="input" #emailInput [label]="'words.email' | translate" required
                        [hasErrors]="formErrors.email" (keyup.enter)="addEmail(emailInput)"
                        [hints]="[('words.email.multiple' | translate)]"></msc-input>
                </div>

                <div class="flex align-center wrap">
                    <div class="content-invitation__tag" [ngClass]="{'content-invitation__tag--error': !email.valid}"
                        *ngFor="let email of emailsSelected">
                        <p>{{email.value}}</p>
                        <span (click)="removeEmail(email)"><i class="icon icon-close"></i></span>
                    </div>
                </div>
            </div>

            <div class="content-invitation__dataset" *ngIf="isImport">
                <div class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5">
                    <msc-button class="msc-button--primary" icon="icon-upload" palette="secondary" (click)="importCSV()"
                        *ngIf="!data.file">
                        {{ 'words.upload' | translate }}
                    </msc-button>

                    <input #csvFile class="display-none" type="file" accept=".xlsx,.xls,.csv"
                        (change)="updateFile($event.target.files[0]); csvFile.value = null">

                    <div class="flex">
                        <div class="content-invitation__tag content-invitation__tag--file" *ngIf="data.file">
                            <i class="icon icon-file"></i>
                            <p>{{ data.file.name }}</p>
                            <span (click)="updateFile()"><i class="icon icon-close"></i></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-invitation__dataset">
                <div class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5">
                    <label class="text-sm mb-1">{{'words.learn' | translate}}*</label>
                    <ng-container *ngIf="selectData.camps.length">
                        <msc-common-select [ngClass]="{'has-errors': formErrors.camp}" [data]="selectData.camps" type="multi" hasSearch="true">
                            <ng-template #buttonTemplate let-data="data">
                                <button class="flex items-center text-sm text-black-400 w-full">
                                    <ng-container *ngIf="data?.length; else emptyTemplate">
                                        <i class="icon icon-camp"></i>
                                        <span class="truncate ml-2">{{ campsSelectedLabel }}</span>
                                    </ng-container>
                                    <ng-template #emptyTemplate>
                                        <i class="icon icon-camp"></i>
                                        <span class="ml-2">{{ 'words.select.camp' | translate }}</span>
                                    </ng-template>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <label class="flex items-center justify-between text-sm py-2 w-full">
                                    <div class="flex items-center mr-1">
                                        <i class="icon icon-camp icon-camp-option"></i>
                                        <span>{{ datum.label }}</span>
                                    </div>
                                    <msc-common2-checkbox [value]="datum.isSelected"
                                        (changeEvent)="onSelectMultiOption(datum)"></msc-common2-checkbox>
                                </label>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>

                <div class="flex align-center wrap">
                    <div class="content-invitation__tag" *ngFor="let camp of campsSelected">
                        <p>{{camp.name}}</p>
                        <span (click)="updateCamp(camp)"><i class="icon icon-close"></i></span>
                    </div>
                </div>
            </div>

            <div class="content-invitation__dataset">
                <div class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5">
                    <label class="flex text-sm mb-1">{{ 'words.experience' | translate }}</label>
                    <ng-container *ngIf="selectData.experiences.length">
                        <msc-common-select [data]="selectData.experiences">
                            <ng-template #buttonTemplate let-data="data">
                                <button class="flex text-sm text-black-400 w-full">
                                    <ng-container *ngIf="data?.length">
                                        <span class="truncate">{{ data[0].label }}</span>
                                    </ng-container>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <button class="flex text-left text-sm py-1" (click)="onSelectOption(datum, 'experiences')">
                                    <span>{{ datum.label }}</span>
                                </button>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>
            </div>

            <div class="content-invitation__dataset" *ngIf="!isImport">
                <div class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5">
                    <label class="flex text-sm mb-1">{{ 'words.interface-language' | translate }}</label>
                    <ng-container *ngIf="selectData.interfaceLanguages.length">
                        <msc-common-select [data]="selectData.interfaceLanguages">
                            <ng-template #buttonTemplate let-data="data">
                                <button class="flex items-center text-sm text-black-400 w-full">
                                    <ng-container *ngIf="data?.length">
                                        <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                                        <span>{{ data[0].label }}</span>
                                    </ng-container>
                                </button>
                            </ng-template>
                            <ng-template #optionTemplate let-datum="datum">
                                <button class="flex items-center text-sm py-2 w-full" (click)="onSelectOption(datum, 'interfaceLanguages')">
                                    <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                                    <span>{{ datum.label }}</span>
                                </button>
                            </ng-template>
                        </msc-common-select>
                    </ng-container>
                </div>
            </div>

            <div class="content-invitation__dataset">
                <div class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5" >
                    <label class="flex text-sm mb-2">{{ 'user.mail-notification' | translate }}*</label>

                    <div class="group__radio">
                        <msc-common2-radio class="text-sm mb-2" groupName="invitationNotification" [(ngModel)]="data.emailStatus"
                            [valueName]="'auto'" [label]="'words.notification.auto' | translate"></msc-common2-radio>

                        <div class="content-invitation__dataset" *ngIf="data.emailStatus === 'auto'">
                            <div class="content-invitation__input ">
                                <label class="flex text-sm mb-1">{{ 'words.message' | translate }}</label>
                                <div class="textarea" [innerHTML]="emailTemplate"></div>
                            </div>
                        </div>

                        <div class="flex space-x-2 mb-2">
                            <msc-common2-radio class="text-sm" groupName="invitationNotification" [(ngModel)]="data.emailStatus"
                                [valueName]="'custom'" [label]="'words.notification.custom' | translate">
                            </msc-common2-radio>
                            <msc-tag *ngIf="customMessagesCount && isImport" [text]="customMessagesCount" [color]="'gray'"></msc-tag>
                        </div>

                        <div class="content-configuration__dataset" *ngIf="data.emailStatus === 'custom' && !isImport">
                            <div class="content-configuration__input col-md-6 col-sm-6 col-xs-12">
                                <msc-textarea [label]="'words.custom-text' | translate"
                                    [hasErrors]="formErrors.customMessage" rows="5" cols="33"
                                    [(ngModel)]="data.custom_message" [placeholder]="'placeholder.message' | translate">
                                </msc-textarea>
                            </div>
                        </div>

                        <div class="content-invitation__dataset" *ngIf="data.emailStatus === 'custom' && isImport">
                            <div class="content-invitation__input col-md-3 col-sm-4 col-xs-6">
                                <label class="flex text-sm mb-1">{{ 'words.language' | translate }}</label>
                                <div class="relative flex items-center gap-2">
                                    <ng-container *ngIf="selectData.messageLanguages.length">
                                        <msc-common-select class="w-full" [data]="selectData.messageLanguages">
                                            <ng-template #buttonTemplate let-data="data">
                                                <button class="flex items-center text-sm text-black-400 w-full">
                                                    <ng-container *ngIf="data?.length">
                                                        <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{data[0].value.code}}.svg">
                                                        <span>{{ data[0].label }}</span>
                                                    </ng-container>
                                                </button>
                                            </ng-template>
                                            <ng-template #optionTemplate let-datum="datum">
                                                <button class="flex items-center justify-between text-sm py-2 w-full" (click)="onSelectOption(datum, 'messageLanguages')">
                                                    <div class="flex items-center">
                                                        <img class="mr-2 w-6" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{datum.value.code}}.svg">
                                                        <span>{{ datum.label }}</span>
                                                    </div>
                                                    <ng-container *ngIf="!getMessage(datum.id) && hasMessages()">
                                                        <i class="icon icon-alert-triangle self-end text-error-500" [tooltip]="'experience.custom-message.tooltip' | translate"></i>
                                                    </ng-container>
                                                </button>
                                            </ng-template>
                                        </msc-common-select>
                                    </ng-container>

                                    <ng-container *ngIf="hasMessages() && messageMissing()">
                                        <span class="message-warning">
                                            <i class="icon icon-alert-triangle" [tooltip]="'invitation.custom-message.missing.tooltip' | translate"></i>
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="content-invitation__input col-md-6 col-sm-6 col-xs-12">
                                <msc-textarea [label]="'words.custom-text' | translate" name="textarea"
                                    [hasErrors]="formErrors.customMessage" cols="33"  rows="5"
                                    [(ngModel)]="customMessage" (ngModelChange)="updateMessage()" [placeholder]="'placeholder.message' | translate">
                                </msc-textarea>
                            </div>
                        </div>

                        <msc-common2-radio class="text-sm" groupName="invitationNotification" [(ngModel)]="data.emailStatus"
                            [valueName]="'none'" [label]="'words.notification.none' | translate">
                        </msc-common2-radio>
                    </div>
                </div>
            </div>

            <div class="content-invitation__dataset" *ngIf="isImport">
                <div class="content-invitation__input w-full lg:w-3/5 xl:w-3/5 2xl:w-3/5">
                    <label class="content-invitation__label">{{ 'user.import-invite.guided-onboarding' | translate
                        }}</label>
                    <div>
                        <msc-common2-radio class="mb-2" groupName="invitationOnboarding" [(ngModel)]="data.onboarding"
                            [valueName]="false" [label]="'words.no' | translate">
                        </msc-common2-radio>
                        <msc-common2-radio groupName="invitationOnboarding" [(ngModel)]="data.onboarding"
                            [valueName]="true" [label]="'words.yes' | translate">
                        </msc-common2-radio>
                    </div>
                </div>
            </div>
            <msc-button *ngIf="isImport" class="msc-button--primary" palette="secondary" (click)="nextStep()"
                [disabled]="loading">{{ 'words.continue' | translate }}</msc-button>

            <msc-button *ngIf="!isImport" class="msc-button--primary" palette="secondary" (click)="save()"
                [disabled]="loading">{{ 'words.invite' | translate }}</msc-button>
        </div>
    </ng-container>

    <msc-organize-user-configure-onboarding #onboardingForm *ngIf="data.onboarding" [hidden]="currentStep !== 1"
        [selectedCamps]="campsSelected || []" (onValidate)="nextStep($event)" [context]="'import'">
    </msc-organize-user-configure-onboarding>

    <div class="content-configuration" *ngIf="currentStep === 2">
        <msc-organize-user-configure-recap
            [data]="{'invitationForm': invitationForm, 'onboardingForm': onboardingForm}">
        </msc-organize-user-configure-recap>

        <msc-button class="msc-button--primary" palette="secondary" (click)="save()" [disabled]="loading">
            {{ 'words.invite' | translate }}
        </msc-button>
    </div>
</div>
