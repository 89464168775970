import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pagination, ContentProvider, Company } from '@lighty';
import { EmitterService, ParamsService, LoaderService, StorageService } from '@services';
import { SubscriptionCollection } from '@classes';

@Component({
    selector: 'msc-learn-content-saved',
    templateUrl: './saved.component.html'
})
export class LearnContentSavedComponent implements OnInit, OnDestroy {
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    private me: any;
    private company: Company;
    public contents: any;
    public pagination: Pagination;
    public searching: boolean = false;
    public loading: boolean = false;
    public query: string;
    public filters: any = {};
    public cardParams: any;

    constructor(private route: ActivatedRoute, private contentProvider: ContentProvider, private storageService: StorageService,
        private loaderService: LoaderService, private emitterService: EmitterService, private paramsService: ParamsService) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.contents = this.route.snapshot.data.contents.contents.map(content => ({ ...content, saved: true }));
        this.pagination = this.route.snapshot.data.contents.pagination;

        this.cardParams = {
            saved: true
        };

        this.subscriptionCollection.subscribe = this.emitterService.get('search.contents').subscribe((query) => {
            if (typeof query === 'string') {
                this.contents = null;
                this.search(query);
                this.emitterService.set('search.contents', {});
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    search(query?: string): void {
        this.searching = true;
        this.loaderService.run();

        const params = this.paramsService.getCatalogue(this.filters, this.company.id, this.query || query, null, null, this.me);

        this.contentProvider.getSavedContents(params).subscribe((data) => {
            this.pagination = data.pagination;
            this.contents = data.contents.map(content => ({ ...content, saved: true }));
            this.searching = false;
        });
    }

    loadMore(): void {
        this.loading = true;
        this.loaderService.run();

        const params = this.paramsService.getCatalogue(this.filters, this.company.id, null, this.pagination, null, this.me);

        this.contentProvider.getSavedContents(params).subscribe((data) => {
            this.pagination = data.pagination;
            this.contents = this.contents.concat(data.contents.map(content => ({ ...content, saved: true })));
            this.loading = false;
        });
    }
}
