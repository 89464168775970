<!--habilidades = skills-->

<section class="head">
    <h3>{{ 'words.skills' | translate }}</h3>

    <div class="flex items-center justify-between gap-2">
        <!--search-->
        <div class="w-full 2xl:w-1/2 xl:w-1/2 md:w-1/2 lg:w-1/2">
            <msc-common-search trigger="auto" [placeholder]="'common.search' | translate"
                (searchEvent)="onSearch($event)"></msc-common-search>
        </div>

        <div class="flex items-center space-x-2">
            <msc-button icon="icon-options-2" (click)="modalFilters.onOpen()">
                {{ 'words.filters' | translate }}
            </msc-button>

            <ng-container *ngIf="isFilterActive">
                <msc-button class="msc-button--primary" palette="secondary" icon="icon-refresh" (click)="onFilterReset(); modalFilters.onReset()">
                    {{ 'words.reset-filters' | translate }}
                </msc-button>
            </ng-container>

            <!--btn crear-->
            <msc-button class="msc-button--primary hidden 2xl:block lg:block xl:block md:block" palette="secondary" icon="icon-plus" (click)="createSkillAside.onOpen()">
                {{ 'words.create' | translate }}
            </msc-button>
        </div>
    </div>


</section>

<section class="body">
    <div class="content">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async" (onScrollEnd)="hasLoadMore ? onLoad() : null">
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-checkbox [isPartial]="thCheckboxIsPartial" [value]="thCheckboxIsSelected"
                                (changeEvent)="onModify($event)"></msc-common-th-checkbox>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span>{{ datum.label }}</span>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <!-- TODO thematic -->
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common2-checkbox type="tick" [value]="datum.isSelected"
                                (changeEvent)="onChange($event, datum)"></msc-common2-checkbox>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <ng-container *ngIf="datum.thematic">
                                <div [tooltip]="'words.thematic' | translate" direction="bottom">
                                    <i class="icon icon-dashboard icon-table"></i>
                                </div>
                            </ng-container>
                            <span class="cursor-pointer hover:underline" (click)="goToDetails(datum.id)">{{ datum.name }} </span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <div class="wrapper">
                                <div class="mr-2"
                                    [tooltip]="datum.usage.courses + ' ' + ((datum.usage.courses > 1 ? 'words.templates': 'words.template') | translate | lowercase)"
                                    direction="bottom">
                                    <i class="icon icon-cube"></i>
                                    <span>{{ datum.usage.courses }}</span>
                                </div>

                                <div class="mr-2"
                                    [tooltip]="datum.usage.trajects + ' ' + ((datum.usage.trajects > 1 ? 'words.journeys': 'words.journey') | translate | lowercase)"
                                    direction="bottom">
                                    <i class="icon icon-trending-up"></i>
                                    <span>{{ datum.usage.trajects }}</span>
                                </div>

                                <div [tooltip]="datum.usage.externalContents + ' ' + ('words.content-external' | translate | lowercase)"
                                    direction="bottom">
                                    <i class="icon icon-map"></i>
                                    <span>{{ datum.usage.externalContents }}</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <i class="icon icon-checkmark-circle icon-table"></i>
                            <span>{{ datum.use }} </span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.validated }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.date }}</span>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'integrations.no-skill-in-company' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <div class="flex justify-center">
                    <msc-common-spinner></msc-common-spinner>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="(isLoadingMore$ | async) === 'loading'">
        <div class="flex justify-center">
            <msc-common-spinner></msc-common-spinner>
        </div>
    </ng-container>
</section>

<ng-container *ngIf="displayBottom">
    <footer class="bar bar--bottom">
        <div class="bar__data">
            <span class="color--red">{{ selectedSkills.length }}</span>
            {{ (selectedSkills.length > 1 ? 'skill.selected-skills' : 'skill.selected-skill') | translate }}
        </div>

        <div class="bar__actions">
            <a class="bar__actions__item" [tooltip]="'words.merge' | translate" direction="top"
                (click)="mergeSkillAside.onOpen()">
                <i class="icon icon-copy"></i>
            </a>

            <a class="bar__actions__item" [tooltip]="'words.save' | translate" direction="top"
                (click)="modalValidation.onOpen()">
                <i class="icon icon-all-done"></i>
            </a>

            <a class="bar__actions__item" [tooltip]="'words.forbid' | translate" direction="top"
                (click)="forbidSkillAside.onOpen()">
                <i class="icon icon-slash-1"></i>
            </a>

            <a class="bar__actions__item" [tooltip]="'words.authorize' | translate" direction="top"
                (click)="modalVisibility.onOpen()">
                <i class="icon icon-checkmark-circle"></i>
            </a>
        </div>
    </footer>
</ng-container>

<msc-organize-knowledge-aside-create #createSkillAside [company]="company" (onSave)="onRefresh()">
</msc-organize-knowledge-aside-create>

<msc-organize-knowledge-aside-merge #mergeSkillAside [company]="company" [selectedSkills]="selectedSkills"
    (onSave)="onRefresh()">
</msc-organize-knowledge-aside-merge>

<msc-shared-aside-forbid-skill #forbidSkillAside [company]="company" [selectedSkill]="selectedSkills[0]"
    (onSave)="onRefresh()">
</msc-shared-aside-forbid-skill>

<msc-filters-modal #modalFilters [filters]="filters" (saveEvent)="onFilterSave($event)"></msc-filters-modal>

<msc-common-modal size="30vw" #modalValidation [title]="'words.validation' | translate">
    <ng-template>
        <p>{{ 'words.question.want-do' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="validation(false); modalValidation.onClose()">
                {{'words.invalidate' | translate}}</msc-button>
            <msc-button class="msc-button--primary" palette="primary"
                (click)="validation(true); modalValidation.onClose()">{{'words.validate' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="30vw" #modalVisibility [title]="'company.management.usage' | translate">
    <ng-template>
        <p>{{ (selectedSkills.length > 1 ? 'skill.allow-skills' : 'skill.allow-skill') | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button class="" palette="primary" (click)="modalVisibility.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="allow(); modalVisibility.onClose()">
                {{'words.authorize' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>
