<div class="content-camps-stats__header">
    <div class="content-camps-stats__filter flex align-center between">
        <div class="content-camps-stats__search flex align-center">
            <div class="date-till flex align-center justify-start">
                <p>{{ 'words.period' | translate }}</p>

                <div class="select" [ngClass]="{'active': displays.date}" (click)="displayed('date')">
                    <div class="select__content">
                        <p class="select__placeholder">
                            {{ dates.start | date: 'dd MMM YYYY' }} - {{ dates.end | date: 'dd MMM YYYY' }}
                            <i class="icon icon-arrow-ios-down"></i>
                        </p>

                        <div class="select__dropdown" (click)="$event.stopPropagation()">
                            <msc-daterangepicker [range]="true" [(dates)]="dates" (onSave)="updateDates()"
                                                 (onClose)="displayed('date', true)">
                            </msc-daterangepicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-camps-stats__action hidden-xs">
            <msc-button class="msc-button--primary" palette="primary" (click)="exportReportingAside.onOpen()" type="fab" icon="icon-download-1" [disabled]="loaders.download" [loading]="loaders.download"></msc-button>
        </div>
    </div>

    <div class="content-camps-stats__filter-by filter-by flex wrap">
        <div class="multi-select">
            <div class="multi-select multi-select--tiny" (click)="displayed('providers')">
                <div class="select multi-select__content">
                    {{ 'words.providers' | translate }}
                </div>

                <div class="dropdown-actions dropdown-camp" [ngClass]="{'active': displays.providers}" [(autoClose)]="displays.providers">
                    <div class="dropdown-actions__content" (click)="stopPropagation($event)">
                        <ul class="dropdown-actions__list">
                            <li class="dropdown-actions__item" *ngFor="let provider of providersCollection" (click)="selectProvider(provider)">
                                <msc-common2-checkbox [value]="isProviderChecked(provider.id)" [label]="'words.' + provider.name | translate"></msc-common2-checkbox>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="filter-by__item" *ngFor="let provider of selectedProviders">
            <div [attr.class]="'tag tag-provider flex align-center ' + getColor(provider)">
                <span>{{ 'words.' + provider.name | translate }}</span>

                <i class="icon icon-close" (click)="selectProvider(provider)"></i>
            </div>
        </div>
    </div>
</div>

<div class="content-camps-stats__body">
    <ul class="content-camps-stats__list flex align-center justify-start wrap">
        <li class="content-camps-stats__item card-stats-provider flex align-center justify-start" *ngFor="let provider of selectedProviders">
            <div class="card-stats-provider__logo">
                <img [src]="'https://static.myskillcamp.com/images/integrations/' + provider.name + '-picto.svg'">
            </div>

            <div class="card-stats-provider__content">
                <p class="card-stats-provider__text flex align-center between">
                    <span>{{ 'providers.licenses-used' | translate }}</span>

                    <i class="icon icon-maximize" (click)="navigate(provider)"></i>
                </p>

                <div class="card-stats-provider__stats">
                    <div class="card-stats-provider__progress">
                        <div [attr.class]="'progress ' + getColor(provider, true)">
                            <div class="progress-content">
                                <div class="progress-content__bar"></div>

                                <div class="progress-content__full" [ngStyle]="{'width': getProgress(provider)}"></div>
                            </div>
                        </div>
                    </div>

                    <p class="card-stats-provider__number flex align-center" *ngIf="provider.licensesAvailable && !isSet(provider)">
                        <span>{{ provider.licensesUsed || 0 }} / {{ provider.licensesAvailable }}</span>

                        <i class="icon icon-edit" (click)="setLicense(provider)"></i>
                    </p>

                    <msc-button class="card-stats-provider__button msc-button--outline msc-button--tiny" icon="icon-edit" *ngIf="!provider.licensesAvailable && !isSet(provider)" (click)="setLicense(provider)">
                        {{ 'providers.set-up.license' | translate }}
                    </msc-button>

                    <div class="card-stats-provider__set" *ngIf="isSet(provider)">
                        <div class="card-stats-provider__set__title">
                            {{ 'providers.licenses-available' | translate }} :
                        </div>

                        <div class="card-stats-provider__set__actions">
                            <input type="number" min="0" class="input-number input-number--tiny" [(ngModel)]="provider.licensesAvailable">

                            <div class="">
                                <i class="icon icon-checkmark" (click)="updateLicense(provider)"></i>

                                <i class="icon icon-close" (click)="setLicense(provider)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>

    <msc-common-spinner *ngIf="loaders.loading"></msc-common-spinner>

    <div class="stats-usage" *ngIf="!loaders.loading">
        <div class="col-12 col-separator no-gutter">
            <div class="stats-usage__item col-6 col-md-6 usage-item--orange">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-calendar text-primary-500"></i>

                        <span>{{ 'providers.total-subscriptions' | translate }}</span>
                    </div>
                </div>

                <msc-shared-stats-providers-manager [title]="'providers.total-subscriptions'" [stats]="providers.providerContentSubscription" [providers]="providersCollection"></msc-shared-stats-providers-manager>
            </div>

            <div class="stats-usage__item col-6 col-md-6 usage-item--green">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-people"></i>

                        <span>{{ 'providers.learners-subscribed' | translate }}</span>
                    </div>
                </div>

                <msc-shared-stats-providers-manager [title]="'providers.learners-subscribed'" [stats]="providers.learnerContentProviderSubscribed" [providers]="providersCollection"></msc-shared-stats-providers-manager>
            </div>
        </div>

        <div class="col-12 col-separator no-gutter">
            <div class="stats-usage__item col-6 col-md-6 usage-item--red">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-people"></i>

                        <span>{{ 'providers.learners-content-done' | translate }}</span>
                    </div>
                </div>

                <msc-shared-stats-providers-manager [title]="'providers.learners-content-done'" [stats]="providers.learnerContentProviderDone" [providers]="providersCollection"></msc-shared-stats-providers-manager>
            </div>

            <div class="stats-usage__item col-6 col-md-6 usage-item--grey">
                <div class="stats-usage__item__header">
                    <div class="stats-usage__item__header__title">
                        <i class="icon icon-content"></i>

                        <span>{{ 'providers.contents-done' | translate }}</span>
                    </div>
                </div>

                <msc-shared-stats-providers-manager [title]="'providers.contents-done'" [stats]="providers.providerContentDone" [providers]="providersCollection"></msc-shared-stats-providers-manager>
            </div>
        </div>
    </div>
</div>

<msc-shared-aside-export-reporting #exportReportingAside [diff]="dates.diff" [options]="{providers: true}" (onSave)=download($event)></msc-shared-aside-export-reporting>
