<div class="col-8 col-md-8 col-xxs-12 content-journey animate relative">
    <div class="container__desc">
        <div class="block-course">
            <div class="block-course__desc">
                <div>
                    <div class="flex between">
                        <h2 class="block-course__title">{{ journey.title || ('words.untitled' | translate) }}</h2>
                        <msc-tag [text]="'words.journey' | translate" [color]="'red'"></msc-tag>
                    </div>


                    <p readMore #readMoreEl="readMore" class="inner-html"
                        [innerHTML]="journey.description ? (journey.description | safe:'html') : ('words.undescribed-content' | translate)">
                    </p>

                    <a *ngIf="readMoreEl.overflow" (click)="readMoreEl.toggleExtend()">{{ 'words.read-more.' +
                        readMoreEl.extended | translate }} <span><i class="icon icon-arrow-ios-down"
                                [class.icon-arrow-ios-up]="readMoreEl.extended"
                                [class.icon-arrow-ios-down]="!readMoreEl.extended"></i></span></a>
                </div>
            </div>
        </div>
    </div>

    <div class="flex justify-between items-center bg-white rounded shadow-md px-6 py-4 my-4"
        *ngIf="!journey.subscribed || journey.hasToSubscribe">
        <ul class="footer-run__infos">
            <li>
                <p>
                    <span class="color-red">
                        <i class="icon icon-content"></i>
                    </span>
                    {{ this.templates[0]?.instancesCount }} {{ this.templates?.instancesCount > 1 ? ('words.instances' |
                    translate | lowercase) :
                    ('words.instance' | translate | lowercase)}}
                </p>
            </li>

            <li>
                <p>
                    <span class="color-red">
                        <i class="icon icon-calendar"></i>
                    </span>
                    {{ detailsFooter.numberEvents }} {{ detailsFooter.numberEvents > 1 ? ('words.events' | translate |
                    lowercase) :
                    ('words.event' | translate | lowercase)}}
                </p>
            </li>
        </ul>

        <p class="footer-run__message color--red" *ngIf="courseSessionFull">
            <i class="icon icon-alert-triangle"></i>
            {{ 'journey.subscribe.sessions-content-full' | translate }}
        </p>

        <p class="footer-run__message color--red" *ngIf="journeyIsFull">
            <i class="icon icon-alert-triangle"></i>
            {{ 'journey.subscribe.journey-full' | translate }}
        </p>

        <p class="footer-run__message color--red" *ngIf="!journey.subscribable">
            <i class="icon icon-alert-triangle"></i>
            {{ 'journey.subscribe.not-subscribable' | translate }}
        </p>

        <div class="flex space-x-2">
            <msc-button class="msc-button--flat" palette="secondary" [disabled]="!journey.subscribable"
                *ngIf="!courseSessionFull && !journeyIsFull" (click)="checkSubscription()">
                {{ 'words.subscribe' | translate }}
            </msc-button>

            <msc-button class="msc-button--flat" palette="secondary" [loading]="sendingMessage"
                *ngIf="courseSessionFull || journeyIsFull" (click)="notifyAdmin()" [disabled]="sendingMessage">
                {{ 'words.contact-us' | translate }}
            </msc-button>
        </div>
    </div>

    <div *ngIf="!journey.subscribed || journey.hasToSubscribe"
        [ngClass]="{ 'disabled': courseSessionFull || journeyIsFull }">
        <h3 class="content-journey__title">{{ 'journey.subscribe.choose' | translate }}</h3>

        <ng-container *ngFor="let template of journeyCollection; let templateIndex = index">
            <div class="obsolete-container-detail bg-white flex px-6 py-4 my-4 shadow-md rounded"
                *ngIf="template.playerContext === 'event' || template.schema === 'template'">
                <div class="content-journey__list"
                    *ngIf="checkInstancesToDisplay(template) === 'nosession' && template.schema === 'template'">
                    <h3>
                        {{ template.title || ('words.untitled' | translate) }}
                    </h3>

                    <div class="content-journey__list__main">
                        <p class="content-journey__list__info">{{ 'journey.subscribe.nosession' | translate }}</p>
                    </div>
                </div>

                <div class="content-journey__list"
                    *ngIf="checkInstancesToDisplay(template) === 'onesessiondigital' && template.schema === 'template'">
                    <h3>
                        {{ template.title || ('words.untitled' | translate) }}
                    </h3>

                    <div class="content-journey__list__main">
                        <p class="content-journey__list__info">{{ 'journey.subscribe.onesession' | translate }}</p>
                    </div>
                </div>

                <div class="content-journey__list"
                    *ngIf="checkInstancesToDisplay(template) === 'sessionsfull' && template.schema === 'template'">
                    <h3>
                        {{ template.title || ('words.untitled' | translate) }}
                    </h3>

                    <div class="content-journey__list__main">
                        <p class="content-journey__list__info">{{ 'journey.subscribe.sessionsfull' | translate }}</p>
                    </div>
                </div>

                <div class="content-journey__list"
                    *ngIf="checkInstancesToDisplay(template) === 'alreadysubscribed' && template.schema === 'template'">
                    <h3>
                        {{ template.title || ('words.untitled' | translate) }}
                    </h3>

                    <div class="content-journey__list__main">
                        <p class="content-journey__list__info">{{ 'journey.choose-session.auto-subscribe' | translate }}
                        </p>
                    </div>
                </div>


                <div class="content-journey__list"
                    *ngIf="checkInstancesToDisplay(template) === 'display' && template.schema === 'template'">
                    <h3>
                        {{ template.title || ('words.untitled' | translate) }}
                    </h3>

                    <div class="content-journey__list__main">
                        <p class="content-journey__item__label">{{ 'journey.choose-session' | translate }}</p>

                        <div class="content-journey__item"
                            *ngFor="let instance of template.instances; let instanceIndex = index"
                            (click)="openDropdownSession(templateIndex, instanceIndex)">
                            <div class="block-date-presentation"
                                [ngClass]="{'active' : dropdownSession[templateIndex] === instanceIndex}">
                                <div class="block-date-presentation__header">
                                    <div class="block-date-presentation__infos">
                                        <label class="radio">
                                            <input [attr.name]="template.id" type="radio"
                                                [disabled]="(instance.usersCount >= instance.usersLimit) && instance.usersLimit > 0"
                                                (change)="addInstance(templateIndex, instanceIndex, instance)"
                                                [checked]="isChecked(templateIndex, instanceIndex)">
                                            <span class="radio__checkmark"></span>
                                        </label>

                                        <msc-tag [color]="'purple'" [type]="'date'"
                                            [day]="instance.nextEvent | date:'dd'"
                                            [month]="instance.nextEvent | localizedDate:'MMM'"></msc-tag>

                                        <div class="block-date-presentation__desc">
                                            <p class="block-date-presentation__title">{{ instance.internalName }}</p>
                                            <p class="block-date-presentation__camp">{{ instance.title ||
                                                ('words.untitled'
                                                | translate) }}</p>
                                            <p class="block-date-presentation__events">
                                                <i class="icon icon-calendar"></i>
                                                <span class="text-primary-500">{{ instance.events?.length }}</span>
                                                {{ (instance.events?.length > 1 ? 'words.events' : 'words.event') |
                                                translate }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="block-date-presentation__stats">
                                        <span><i class="icon icon-people"></i> {{ instance.usersCount }}</span>
                                        <span><i class="icon icon-message-circle"></i> {{ instance.language.name
                                            }}</span>
                                    </div>

                                    <a [tooltip]="'journey.subscribe.sessionfull' | translate" direction="bottom"
                                        *ngIf="instance.usersCount >= instance.usersLimit && instance.userLimit > 0">
                                        <i class="icon icon-lock"></i>
                                    </a>

                                    <div class="block-date-presentation__toggle">
                                        <i class="icon icon-arrow-ios-down-1 text-secondary-500"></i>
                                    </div>
                                </div>

                                <div class="block-date-presentation__body">
                                    <div class="block-date-presentation__icons" *ngIf="instance.resume">
                                        <p *ngIf="instance.resume.media"><i class="icon icon-play-circle"></i><span>{{
                                                instance.resume.media }}</span>{{ 'words.media' | translate }}</p>
                                        <p *ngIf="instance.resume.certificate"><i class="icon icon-award"></i><span>{{
                                                instance.resume.certificate }}</span>{{ 'words.certification' |
                                            translate }}
                                        </p>
                                        <p *ngIf="instance.resume.exchange"><i
                                                class="icon icon-message-circle"></i><span>{{
                                                instance.resume.exchange }}</span>{{ (instance.resume.exchange.length >
                                            1 ?
                                            'words.exchanges' : 'words.exchange') | translate }}</p>
                                        <p *ngIf="instance.resume.project"><i class="icon icon-file-text"></i><span>{{
                                                instance.resume.project }}</span>{{ instance.resume.project.length > 1 ?
                                            'words.projects' : 'words.project' | translate }}</p>
                                        <p *ngIf="instance.resume.exercise"><i
                                                class="icon icon-checkmark-square"></i><span>{{ instance.resume.exercise
                                                }}</span>{{ 'words.exercise' | translate }}</p>
                                        <p *ngIf="instance.resume.events"><i class="icon icon-calendar"></i><span>{{
                                                instance.resume.events }}</span>{{ instance.resume.events.length > 1 ?
                                            'words.events' : 'words.event' | translate }}</p>
                                    </div>

                                    <div class="block-date-presentation__list">
                                        <div class="block-date-presentation__item"
                                            *ngFor="let event of instance.events">
                                            <div class="block-date-presentation">
                                                <div class="block-date-presentation__header">
                                                    <msc-tag [color]="'orange'" [type]="'date'"
                                                        [day]="event.startAt | date:'dd'"
                                                        [month]="event.startAt | localizedDate:'MMM'"></msc-tag>

                                                    <div class="block-date-presentation__time">
                                                        <p class="text-primary-500">{{ event.startAt | date: 'HH:mm' }}
                                                        </p>
                                                        <p>{{ event.endAt | date: 'HH:mm' }}</p>
                                                    </div>

                                                    <div class="block-date-presentation__desc">
                                                        <p class="block-date-presentation__title">{{ event.title ||
                                                            ('words.untitled' | translate) }}</p>
                                                        <p class="block-date-presentation__localisation"><i
                                                                class="icon icon-pin"></i>{{ event.address }}</p>
                                                    </div>

                                                    <div class="block-date-presentation__add">
                                                        <i class="icon icon-calendar"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container__item container__item--line" *ngIf="template.playerContext === 'event'">
                    <div class="block-date-presentation">
                        <div class="block-date-presentation__header">
                            <div class="block-date-presentation__header--event block-date-presentation__header--light">
                                <msc-tag [color]="'orange'" [type]="'date'"
                                    [day]="template.data.event.eventSessions[0].startAt | date:'dd'"
                                    [month]="template.data.event.eventSessions[0].startAt | localizedDate:'MMM'">
                                </msc-tag>

                                <div class="block-date-presentation__time">
                                    <p class="text-primary-500">{{ template.data.event.eventSessions[0].startAt |
                                        date:'HH:mm'
                                        }}</p>
                                    <p>{{ template.data.event.eventSessions[0].endAt | date:'HH:mm' }}</p>
                                </div>

                                <div class="block-date-presentation__desc">
                                    <p class="block-date-presentation__title">{{ template.data.event.title ||
                                        ('words.untitled' | translate) }}</p>
                                    <p class="block-date-presentation__localisation">
                                        <i class="icon icon-pin"></i>
                                        {{ template.data.event.eventSessions[0].address ||
                                        ('instance.address.no-specified'
                                        | translate) }}
                                    </p>
                                </div>
                            </div>

                            <div class="block-date-presentation__add" (click)="addToCalendar(template)">
                                <i class="icon icon-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="container__list" *ngIf="journey.subscribed && !loaderSessions && !journey.hasToSubscribe">
        <div class="container__item" *ngFor="let event of journey.contents">
            <div class="block-date-presentation" *ngIf="event.playerContext === 'event'">
                <div class="block-date-presentation__header">
                    <div class="block-date-presentation__header--event block-date-presentation__header--light">
                        <msc-tag [color]="'orange'" [type]="'date'"
                            [day]="event.data.event.eventSessions[0].startAt | date:'dd'"
                            [month]="event.data.event.eventSessions[0].startAt | localizedDate:'MMM'"></msc-tag>

                        <div class="block-date-presentation__time">
                            <p class="text-primary-500">{{ event.data.event.eventSessions[0].startAt | date:'HH:mm' }}
                            </p>
                            <p>{{ event.data.event.eventSessions[0].endAt | date:'HH:mm' }}</p>
                        </div>

                        <div class="block-date-presentation__desc">
                            <p class="block-date-presentation__title">{{ event.data.event.title || ('words.untitled' |
                                translate)}}</p>
                            <p class="block-date-presentation__localisation">
                                <i class="icon icon-pin"></i>
                                {{ event.data.event.eventSessions[0].address || ('instance.address.no-specified' |
                                translate) }}
                            </p>
                        </div>
                    </div>

                    <div class="block-date-presentation__add" (click)="addToCalendar(event)">
                        <i class="icon icon-calendar"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<aside class="col-4 col-md-4 col-xxs-12 container__aside">
    <presentation-additional-details [content]="journey" [backgroundImage]="journey.picture ? journey.picture + '?size=1024' :
    ('https://static.myskillcamp.com/lxp/images/logo-empty-state-' + journey.id % 7 + '.jpg')">
    </presentation-additional-details>
</aside>