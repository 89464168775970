<msc-common-aside #aside [hasActionButtons]="false">
    <ng-template #headerTemplate>
        <div class="slide__close" (click)="aside.onClose()">
            <i class="icon icon-close"></i>
        </div>
        <div>
            <h2><strong>{{ 'words.changes' | translate }}, </strong>{{ learningPath.lastLogCreatedAt | date: 'dd MMMM
                YYYY' }}</h2>
            <p>{{ 'words.publication-date' | translate }}: {{learningPath.creationDate | date: 'dd MMMM YYYY' }}</p>
            <p>{{ 'words.publisher' | translate }}: {{learningPath.authorName}}</p>
        </div>
    </ng-template>
    <ng-template #asideTemplate>
        <div class="p-6 overflow-y-scroll" infinite-scroll [scrollWindow]="false" [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="0" (scrolled)="getLogs(true)">
            <ng-container *ngIf="data">
                <div *ngFor="let log of data | keyvalue : keyDescOrder">
                    <h3 class="mb-3">
                        {{log.key | date: 'dd MMMM' }}
                    </h3>
                    <ng-container *ngFor="let action of log.value">
                        <div class="mb-2">
                            <p class="leading-none">
                                <span class="mr-1">{{ 'words.' + action.type | translate }}</span>
                                <strong class="mr-1" *ngIf="action.contentTitle">
                                    <ng-container *ngIf="action.type === 'traject_content_deleted'">
                                        <span>{{action.contentTitle}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="action.type !== 'traject_content_deleted'">
                                        <span>{{action.contentTitle}}</span>
                                        <!-- in the close future we will add link here -->
                                    </ng-container>
                                </strong>
                                <span>{{ 'api.content-type.' + action.contentType | translate | lowercase }}</span>
                            </p>
                            <small class="leading-none">{{action.author ? (action.author.name + ', ') : ''}} {{
                                action.date
                                | date: 'HH:mm' }}</small>
                        </div>
                    </ng-container>
                    <div class="w-full border-b mb-5"></div>
                </div>
                <div>
                    <p><strong>{{learningPath.title}}</strong> {{ 'words.published' | translate }}</p>
                    <small class="leading-none">{{ learningPath.creationDate | date: 'dd MMMM YYYY' }}</small>
                </div>
            </ng-container>
            <msc-common-spinner *ngIf="loading"></msc-common-spinner>
        </div>
    </ng-template>
</msc-common-aside>