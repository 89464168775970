import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Media, Camp, CampProvider } from '@lighty';
import { CommonToastService } from '@common2/services/toast.service';
import { TranslateService } from '@services';

@Component({
    selector: 'msc-camp-aside-catalogue',
    templateUrl: './aside-catalogue.component.html'
})
export class CampAsideCatalogueComponent {
    @ViewChild('aside') aside;
    @Input() camp: Camp;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @ViewChild('upload') upload: ElementRef;
    public onCloseByUser: EventEmitter<any> = new EventEmitter();
    public loading: boolean = false;
    public picture: any;
    public media: any = {
        content: null,
        id: null
    };

    constructor(
        private campProvider: CampProvider,
        private toastService: CommonToastService,
        private renderer: Renderer2,
        private translateService: TranslateService,
    ) { }

    init(): void {
        this.picture = this.camp.settings.catalogueContentMedia ? this.camp.settings.catalogueContentMedia.pictureUrl : null;
    }

    public onOpen() {
        this.init();
        this.aside.onOpen();
    }

    loadPicture(): void {
        const event = new MouseEvent('click', { bubbles: true });
        this.renderer.selectRootElement(this.upload.nativeElement).dispatchEvent(event);
    }

    uploadPicture(event: any): void {
        this.loading = true;
        if (event.type === 'finished') {
            this.loading = false;
            this.updatePicture(event.value);
        }

        if (event.type === 'error') {
            this.loading = false;
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
        }
    }

    updatePicture(media?: Media): void {
        this.picture = media ? media.pictureUrl : null;
        this.media.content = media || null;
        this.media.id = media ? media.id : null;
    }

    update(): void {
        this.camp.settings.catalogueContentMedia = this.media.content;
        this.camp.settings.catalogueContentMediaId = this.media.id;

        this.campProvider.updateSettings(this.camp).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.aside.onClose();
        });
    }

    close(): void {
        this.onClose.emit(true);
    }
}
