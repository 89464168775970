import { Component, Input, EventEmitter, OnInit } from '@angular/core';
import { Camp, ContentProvider, CompanyUserAccessManageProvider, Pagination } from '@lighty';
import * as moment from 'moment';
import { ParamsService, StorageService, TranslateService } from '@services';
import { OrganizeUserStepBaseComponent } from '../configure.component.base';
import { FORMSTEPS } from '../configure.enum';
import { DatePipe } from '@angular/common';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-user-configure-accesses',
    templateUrl: './accesses.component.html',
    styleUrls: ['./accesses.component.scss'],
    providers: [{ provide: OrganizeUserStepBaseComponent, useExisting: OrganizeUserConfigureAccessesComponent }]
})
export class OrganizeUserConfigureAccessesComponent extends OrganizeUserStepBaseComponent implements OnInit {
    @Input() set availableCamps(value) {
        this._availableCamps = value;
        this.storedCamps = value;

        if (value) {
            this.selectData.campsLearn = this.getSelectData(value);
            this.selectData.campsOrganise = this.getSelectData(value);
        }
    }
    private me: any;
    private company: any;
    public data: any = {
        roles: null,
        accessStartAt: null,
        accessEndAt: null
    };
    public filters: any = {};
    public activeFilters: boolean = false;
    public query: string;
    public buildFilters: EventEmitter<any> = new EventEmitter();
    pagination: any = new Pagination({
        perPage: 5
    });
    catalogue: any[] = [];
    cachedCatalogue: any[] = [];
    loading: boolean;
    private storedCamps: Camp[];
    displayDropdowns = {
        startDate: false,
        endDate: false
    };
    public _availableCamps: Camp[] = [];
    public dates: any = {};
    public roles = {
        learn: {
            status: false,
            displayDropdowns: {
                camps: false,
            },
            campsSelected: [],
            campsSelectedLabel: '',
            query: {
                camp: '',
            },
            formErrors: {
                camp: false
            }
        },
        organise: {
            status: false,
            displayDropdowns: {
                camps: false,
            },
            campsSelected: [],
            campsSelectedLabel: '',
            query: {
                camp: '',
            },
            super_admin: false,
            formErrors: {
                camp: false
            }
        },
        create: {
            status: false,
            option: false
        },
        coach: {
            status: false,
            selectedSessions: []
        }
    };
    public selectData: any = {
        campsLearn: [],
        campsOrganise: []
    };

    constructor(
        private toastService: CommonToastService,
        private companyUserAccessManageProvider: CompanyUserAccessManageProvider,
        private contentProvider: ContentProvider,
        private paramsService: ParamsService,
        private storageService: StorageService,
        private translateService: TranslateService,
    ) {
        super();

        this.formId = FORMSTEPS.ACCESSES;
    }

    ngOnInit(): void {
        this.dates = {
            now: moment(),
            tomorrow: moment().add(1, 'day')
        };
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.loadMore();
    }

    getSelectData(data) {
        return data.map(d => ({
            id: d.id,
            label: d.name,
            value: d,
            isSelected: false
        }));
    }

    onSelectOption(option, context, key: string) {
        option.isSelected = !option.isSelected;
        this.selectData[key] = [...this.selectData[key]];

        this.updateCamp(option.value, context, key);
    }

    displayed(key: string, context?: any, force: boolean = null): void {
        if (!context) {
            context = this;
        }

        context.displayDropdowns[key] = force !== null ? force : !context.displayDropdowns[key];
    }

    searchCamp(context: any = this): void {
        if (context.query.camp) {
            this._availableCamps = this.storedCamps.filter((camp: any) => camp.name.toLowerCase().match(context.query.camp.toLowerCase()));
        } else {
            this._availableCamps = this.storedCamps;
        }
    }

    updateCamp(camp: Camp, context: any = this, key): void {
        if (this.isCampSelected(camp, context)) {
            this.removeCamp(camp, context, key);
        } else {
            this.addCamp(camp, context);
        }
        context.campsSelectedLabel = this.getSelectedCamps(3, context);
        this.storageService.setCache('context.campIds', context.campsSelected);
    }


    private addCamp(camp: Camp, context: any = this): void {
        context.campsSelected = [...context.campsSelected, camp];
        context.formErrors.camp = false;
    }

    public removeCamp(camp: Camp, context: any = this, key): void {
        delete (camp as any).configure;
        const index = context.campsSelected.findIndex((data) => {
            return data.id === camp.id;
        });

        if (index !== -1) {
            context.campsSelected.splice(index, 1);
            this.selectData[key] = this.selectData[key].map(d => {
                if (d.id === camp.id) { d.isSelected = false }
                return d;
            });

        }

        if (context.campsSelected.length === 0) {
            context.formErrors.camp = true;
        }

        context.campsSelected = [...context.campsSelected];
    }

    isCampSelected(camp: Camp, context: any = this): boolean {
        return context.campsSelected.findIndex((data) => data.id === camp.id) !== -1;
    }

    getSelectedCamps(limit: number, context: any = this): string {
        return context.campsSelected.slice(0, limit).map((camp) => {
            return camp.name;
        }).join(', ');
    }

    resetDateInput(input: string): void {
        this.data[input] = null;
    }

    updateDate(type: string, date: any): void {
        this.data[type] = date.split('T')[0];
    }

    search(query?: string): void {
        this.loading = true;

        const params = this.paramsService.getOnboarding(this.filters, this.query || query, null);

        this.companyUserAccessManageProvider.getAvailableForCoach(this.company.id, params).subscribe((data) => {
            this.pagination = data.pagination;
            this.catalogue = data.contents;
            this.loading = false;
        });
    }

    loadMore(): void {
        this.loading = true;

        const params = this.paramsService.getOnboarding(this.filters, null, null, this.pagination);

        this.companyUserAccessManageProvider.getAvailableForCoach(this.company.id, params).subscribe((data) => {
            this.catalogue = this.catalogue.concat(data.contents);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    filter(provider: any): void {
        const params = {
            company: false
        };
        params[provider.id] = true;

        const filter = {
            providers: params
        };

        this.buildFilters.emit(filter);
    }

    public isValid(): boolean {
        this.roles.learn.formErrors.camp = false;
        this.roles.organise.formErrors.camp = false;

        if (Object.values(this.roles).filter(role => role.status).length === 0) {
            this.toastService.onError(this.translateService.instant('toast.invite.access.role.required'));
            return false;
        }

        if (this.roles.learn.status && this.roles.learn.campsSelected.length === 0) {
            this.roles.learn.formErrors.camp = true;
        }

        if (this.roles.organise.status && !this.roles.organise.super_admin && this.roles.organise.campsSelected.length === 0) {
            this.roles.organise.formErrors.camp = true;
        }

        this.data.roles = this.roles;

        return ![...Object.values(this.roles.learn.formErrors), ...Object.values(this.roles.organise.formErrors)].some(error => error);
    }

    public toApiData() {
        return {
            roles: {
                ...{
                    company_owner: (this.roles.organise.status && this.roles.organise.super_admin) ? true : undefined,
                    company_administrator: (this.roles.organise.status && !this.roles.organise.super_admin) ? this.roles.organise.campsSelected.map(camp => ({ id: camp.id, manage_settings: camp.configure })) : undefined,
                    company_learner: this.roles.learn.status ? this.roles.learn.campsSelected.map(camp => camp.id) : undefined,
                    company_author: this.roles.create.status ? {
                        access_company_courses: this.roles.create.option
                    } : undefined,
                    company_manager: this.roles.coach.status ? this.roles.coach.selectedSessions.map(sel => ({ id: sel.data.id, context: sel.type })) : undefined
                }
            },
            access_start_at: this.data.accessStartAt,
            access_end_at: this.data.accessEndAt
        }
    }

    public toReadableData() {
        let datePipe;
        try {
            datePipe = new DatePipe(this.me.language.code);
        } catch (e) {
            datePipe = new DatePipe('en');
        }

        return {
            label: 'words.roles-access',
            data: [
                this.roles.learn.status ? {
                    label: 'words.learn',
                    data: this.roles.learn.campsSelected.map(camp => camp.name)
                } : null,
                this.roles.organise.status ? {
                    label: 'words.organise',
                    data: this.roles.organise.super_admin ? ['words.super-admin'] : this.roles.organise.campsSelected.map(camp => camp.name)
                } : null,
                this.roles.create.status ? {
                    label: 'words.create',
                    data: [!this.roles.create.option ? 'access.create.private' : 'access.create.company']
                } : null,
                this.roles.coach.status ? {
                    label: 'words.coach',
                    data: this.roles.coach.selectedSessions.map(session => session.data.title || 'words.untitled')
                } : null,
                (this.data.accessStartAt || this.data.accessEndAt) ? {
                    label: 'words.access',
                    data: [
                        this.data.accessStartAt ? `${this.translateService.instant('words.access-start.date')}: ${datePipe.transform(this.data.accessStartAt)}` : null,
                        this.data.accessEndAt ? `${this.translateService.instant('words.access-end.date')}: ${datePipe.transform(this.data.accessEndAt)}` : null,
                    ]
                } : null
            ]
        }
    }
}
