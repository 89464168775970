<dl>
    <ng-container *ngFor="let datum of data; let i=index; trackBy: onTrackByIndex">
        <dd [ngClass]="{'item--has-children': datum?.children && datum.children.length, 'item--in-overlay': expandWithOverlay}">
            <input [ngClass]="{'input--no-rotate': expandWithOverlay}" type="checkbox"
                id="leaf-{{ datum?.id }}-{{ i }}">
            <div>
                <div class="flex w-full">
                    <ng-container
                        *ngTemplateOutlet="leafTemplate || template || defaultLeafTemplate; context: { datum: datum }">
                    </ng-container>
                </div>
                <div class="flex items-center">
                    <msc-common-overlay #overlay direction="list" [closeOnClickOutside]="false">
                        <ng-template>
                            <msc-common-tree class="msc-common-tree--overlay" [expandWithOverlay]="expandWithOverlay"
                                (treeUpdate)="treeUpdate.emit($event)" [data]="datum.children"
                                [template]="leafTemplate || template || defaultLeafTemplate"></msc-common-tree>
                        </ng-template>
                    </msc-common-overlay>
                    <ng-container *ngIf="datum?.children && datum.children.length">
                        <ng-container *ngIf="expandWithOverlay; else defaultExpandToggle">
                            <i class="item-expansion-icon icon icon-arrow-ios-right" element #origin="element"
                                (mouseenter)="openTree(datum, overlay, origin.elementRef)"></i>
                        </ng-container>
                        <ng-template #defaultExpandToggle>
                            <label for="leaf-{{ datum?.id }}-{{ i }}">
                                <i class="item-expansion-icon icon icon-arrow-ios-right"></i>
                            </label>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="!expandWithOverlay">
                <msc-common-tree *ngIf="datum?.children && datum.children.length" [data]="datum.children"
                    [template]="leafTemplate || template || defaultLeafTemplate"></msc-common-tree>
            </ng-container>
        </dd>
    </ng-container>
</dl>

<ng-template #defaultLeafTemplate let-datum="datum">
    <span>{{ datum?.label }}</span>
</ng-template>
