<section class="head">
    <h3>Icons</h3>
    <p>Complete list of icons</p>
    <small>To add more icons please refer to <a class="text-primary-500 font-bold" target="_blank" href="https://gitlab.com/myskillcamp/app/-/blob/develop/recipes/icons.md">recipes/icons.md</a> guide</small>
</section>

<section class="body">
    <div class="flex">
        <msc-input class="msc-input--no-border" type="text" label="Quick find" palette="primary" icon="icon-search" placeholder="type icon name..." [(ngModel)]="filter"></msc-input>
    </div>
    <div class="grid xl:grid-cols-6 lg:grid-cols-4 grid-cols-3 gap-2">
        <div class="flex flex-col p-4 space-y-4 items-center justify-center bg-white" *ngFor="let icon of iconList | filter: 'key' : filter">
            <i class="icon {{icon.key}} text-3xl text-primary-500"></i>
            <small>{{icon.key}}</small>
        </div>
    </div>
</section>
