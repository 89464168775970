<section class="head">
    <div class="toolbar">
        <msc-common-search trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="onSearch($event)">
        </msc-common-search>
        <ng-container *ngIf="selectData.headlines.length">
            <msc-common-select class="ml-2 msc-select--small" [data]="selectData.headlines" type="multi">
                <ng-template #buttonTemplate let-data="data">
                    <span class="font-bold text-xs text-gray-600">
                        <ng-container *ngIf="!allHeadlinesSelected; else allSelectedTemplate">
                            {{ 'words.positions' | translate }}
                            <ng-container *ngIf="data?.length">
                                <msc-tag [text]="selectedHeadlines.length" [color]="'secondary-default'"></msc-tag>
                            </ng-container>
                        </ng-container>
                        <ng-template #allSelectedTemplate>
                            <span>{{ 'group.team.all-positions' | translate }}</span>
                        </ng-template>
                    </span>
                </ng-template>
                <ng-template #optionTemplate let-datum="datum">
                    <div class="flex gap-1">
                        <msc-common2-checkbox [value]="datum.isSelected" (changeEvent)="onSelectHeadline(datum)">
                        </msc-common2-checkbox>
                        <span>{{ datum.label }}</span>
                    </div>
                </ng-template>
            </msc-common-select>
        </ng-container>
    </div>

    <div class="team-members__actions__bottom">
        <div *ngIf="pagination">
            <i class="icon-people"></i>
            <span>{{ pagination.total }}</span>&nbsp;
            <span [innerHTML]="'group.team.members-in-team' | translate"></span>
        </div>

        <msc-button class="msc-button-outline msc-button--tiny" (click)="manageTeamAside.onOpen()"
            icon="icon-add-people" *ngIf="hasPermissionToAdd">
            {{ 'group.team.add-member' | translate }}
        </msc-button>
    </div>
</section>

<section class="body">
    <div class="content">
        <ng-container [ngSwitch]="status$ | async">
            <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="(rows$ | async).length; else emptyTemplate">
                    <msc-common-table [columns]="columns" [rows]="rows$ | async">
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-checkbox [isPartial]="thCheckboxIsPartial" [value]="thCheckboxIsSelected"
                                (changeEvent)="onModify($event)"></msc-common-th-checkbox>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <msc-common-th-sort [label]="datum.label" (sortEvent)="onSort(datum.key, $event)">
                            </msc-common-th-sort>
                        </ng-template>
                        <ng-template #thTemplate let-datum="datum">
                            <span></span>
                        </ng-template>

                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common2-checkbox type="tick" [value]="datum.isSelected"
                                (changeEvent)="onChange($event, datum)"></msc-common2-checkbox>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-common-avatar [label]="datum.avatarLabel" [pictureURL]="datum.avatarUrl">
                            </msc-common-avatar>
                            <span class="ml-2">{{ datum.avatarLabel }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.headline }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ datum.dateLastActivity }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <span>{{ (datum.learningTime | hhmm) || '-' }}</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-tag *ngIf="datum.actionToProcess" [text]="datum.actionToProcess"
                                [color]="'secondary-default'"></msc-tag>
                            <span *ngIf="!datum.actionToProcess">-</span>
                        </ng-template>
                        <ng-template #tdTemplate let-datum="datum">
                            <msc-button class="msc-button--tiny" type="fab" icon="icon-arrow-ios-right"
                                (click)="goToMember(datum.account.id)"></msc-button>
                        </ng-template>
                    </msc-common-table>
                </ng-container>

                <ng-template #emptyTemplate>
                    <msc-common-empty [message]="'common.empty' | translate">
                        <ng-template></ng-template>
                    </msc-common-empty>
                </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'loading'">
                <msc-common-spinner *ngIf="true"></msc-common-spinner>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="hasLoadMore">
        <div class="load-more">
            <msc-button class="msc-button--primary" palette="primary" [loading]="(isLoadingMore$ | async) === 'loading'"
                (click)="onLoad()">{{ 'words.load-more' | translate }}</msc-button>
        </div>
    </ng-container>
</section>

<ng-container *ngIf="selectedMembers.length || selectAll">
    <footer class="team-members__footer">
        <div class="team-members__footer__info">
            <i class="icon icon-minus-square"></i>
            <p><span>{{selectAll ? (paginationTeam.total - excludedMembers.length) : selectedMembers.length}}</span> {{
                'words.selected-participants' | translate }}</p>
        </div>

        <div class="team-members__footer__icons">
            <div *ngIf="selectedMembers.length === 1" [tooltip]="'words.subscribe' | translate"
                (click)="openComponent('modal', 'subscribe')">
                <i class="icon icon-plus-circle"></i>
            </div>

            <div [tooltip]="'words.discuss' | translate" (click)="openConversationWithMembers()">
                <i class="icon icon-message-circle"></i>
            </div>

            <div [tooltip]="'words.notify-by-email' | translate" (click)="mailMultipleUsersAside.onOpen()">
                <i class="icon icon-email"></i>
            </div>

            <div [tooltip]="'words.reassign-manager' | translate" (click)="reassignManagerAside.onOpen()">
                <i class="icon icon-shuffle-1"></i>
            </div>
        </div>
    </footer>
</ng-container>

<msc-manage-team-aside-add #manageTeamAside [companyId]="company.id" [groupId]="me.teamOwnerId"
    (onSave)="addTeamMembers($event)">
</msc-manage-team-aside-add>

<msc-shared-aside-mail-multiple-users #mailMultipleUsersAside [selectedUsers]="selectedMembers"
    (onSave)="sendMailToMembers($event)">
</msc-shared-aside-mail-multiple-users>

<msc-manage-team-aside-reassign #reassignManagerAside [companyId]="company.id" [me]="me"
    (onSave)="reassignManager($event)">
</msc-manage-team-aside-reassign>

<ng-container *ngIf="displayComponents.modal.subscribe">
    <msc-manage-team-modal-subscribe-users [companyId]="company.id" [accountId]="selectedMembers[0].account.id"
        (onClose)="closeComponent('modal', 'subscribe')" (onSave)="subscribeMembersToContent($event)">
    </msc-manage-team-modal-subscribe-users>
</ng-container>