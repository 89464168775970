import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../pagination';
import { environment } from '@env/environment';

@Injectable()
export class KnowledgeProvider {

    constructor(private http: HttpClient) {}

    get(slug: string, params?: any): Observable<any> {
        const response = {
            pagination: {},
            skills: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/skills`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.skills = data.skills.map((skill) => {
                    return skill;
                });

                return response;
            })
        );
    }

    getSkill(id: number|string, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/skills/${id}`, {params}).pipe(
            map((data) => data)
        );
    }

    getContents(id: number|string, params?: any): Observable<any> {
        const response = {
            pagination: {},
            contents: []
        };

        return this.http.get(`${environment.envVar.API_URL}/skills/${id}/contents`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.contents = data.contents.map((content) => {
                    return content;
                });

                return response;
            })
        );
    }

    getMeta(id: number|string, skillId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/skills/${skillId}/contents/meta`, {params}).pipe(
            map((data: any) => data.meta)
        );
    }

    create(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/skills`, params).pipe(
            map(data => data)
        );
    }

    update(id: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/skills/${id}`, params).pipe(
            map(data => data)
        );
    }

    merge(params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/skills/merge`, params).pipe(
            map(data => data)
        );
    }

    validation(params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/skills/validation`, params).pipe(
            map(data => data)
        );
    }

    visibility(params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/skills/visibility`, params).pipe(
            map(data => data)
        );
    }

    unlink(id: number, skillId: number, contentId: number, params?: any): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/admin/companies/${id}/skills/${skillId}/contents/${contentId}`, {params}).pipe(
            map(data => data)
        );
    }

    addContent(id: number, skillId: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/skills/${skillId}/contents`, params).pipe(
            map(data => data)
        );
    }

    getAvailableContents(id: number|string, skillId: number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            contents: []
        };

        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/skills/${skillId}/contents/available`, {params}).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                response.contents = data.contents.map((content) => {
                    return content;
                });

                return response;
            })
        );
    }

    getAvailableMeta(id: number|string, skillId: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/skills/${skillId}/contents/available/meta`, {params}).pipe(
            map((data: any) => data.meta)
        );
    }
}
