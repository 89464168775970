import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, EMPTY, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@services';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

const EXPIRED_TOKEN = 'expired_token';

/**
 * Error Interceptor Service
 * Push an error into error stream service
 */
@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

    isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    /**
     * Import ErrorStreamService and RefreshAuthService
     * @constructor
     * @param errorStreamService
     * @param refreshAuthService
     */
    constructor(private authService: AuthService, private router: Router, private cookieService: CookieService) { }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;

            return this.authService.refresh().pipe(
                switchMap((auth: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(auth.access_token);
                    return next.handle(this.addToken(request, auth.access_token));
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    return next.handle(this.addToken(request, token));
                })
            );
        }
    }

    /**
     * Intercept logic
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 401) {
                    if (this.cookieService.get(environment.refreshTokenName) && (err.error === EXPIRED_TOKEN || err.error.message === EXPIRED_TOKEN)) {
                        return this.handle401Error(request, next);
                    }

                    if(this.cookieService.get(environment.tokenName)){
                        this.cookieService.delete(environment.tokenName);
                    }

                    this.router.navigate(['login']);
                    return throwError(err);

                } else if (err.status === 503) {
                    this.router.navigate(['maintenance']);
                    return EMPTY;
                } else if (err.status === 400) {
                    return throwError(err);
                }

                const error = {
                    message: '',
                    status: null
                };

                if (err.error || err.statusText) {
                    error.message = err.error?.message || err.statusText;
                    error.status = err.status;
                } else {
                    error.message = 'error-occurred';
                    error.status = 500;
                }

                return throwError(err);
            })
        );
    }
}
