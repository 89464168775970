import { environment } from '@env/environment';

export class UrlHelper {
    private static sites: any = {
        youtube: {
            url: '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
            reg: /^watch\?v=([\w-_]*)/gi
        },
        youtu: {
            url: '//www.youtube.com/embed/{id}?rel=0&showinfo=0',
            reg: /^watch\?v=([\w-_]*)/gi
        },
        vimeo: {
            url: '//player.vimeo.com/video/{id}?dnt=1',
            reg: /^(.*)/
        },
        dailymotion: {
            url: '//www.dailymotion.com/embed/video/{id}',
            reg: /^video\/([\w_-]*)/gi
        }
    };

    private static regex: any = /((https?|ftp)\:\/\/)([a-z0-9+!*(),;?&=\$_.-]+(\:[a-z0-9+!*(),;?&=\$_.-]+)?@)?([a-z0-9-.]*)\.([a-z]{2,3})(\:[0-9]{2,5})?(\/([a-z0-9+\$_\-~@\(\)\%]\.?)+)*\/?(\?[a-z+&\$_.-][a-z0-9;:@&#%=+\/\$_.-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?/i;

    public static parse(url: string, reference?: any, key?: string): string {
        const re = /^(?:https:|http:)?\/\/(?:www\.)?([\w-]*)\.(?:[^\s\/]*)\/(.*)/i;
        const result = re.exec(url);
        let parsedUrl: string;

        try {
            if (this.sites[result[1]]) {
                url = this.sites[result[1]].url;
                let id = this.sites[result[1]].reg.exec(result[2]);
                if (!id) {
                    id = this.sites[result[1]].reg.exec(result[2]);
                }
                parsedUrl = url.replace('{id}', id ? id[1] : result[2]);

                if (reference && key) {
                    reference[key] = parsedUrl;
                }
            }
        } catch (e) { }

        return parsedUrl || url;
    }

    public static getHttpUrl(url: string): string {
        return url ? (url.indexOf('http') !== -1) ? url : location.protocol + '//' + url : '';
    }

    public static navigate(url: any, target: string = '_blank'): void {
        let link = url;
        if (url && typeof url === 'object') {
            link = url.parts.join('/');

            let params = null;
            if (url.params) {
                params = link.indexOf('&') > -1 ? '&' : '?';
                let i = 0;
                for (const param in url.params) {
                    if (url.params.hasOwnProperty(param)) {
                        if (i > 0) {
                            params += '&';
                        }
                        params += param + '=' + url.params[param];
                        i++;
                    }
                }
                link += params;
            }
        }
        window.open(this.getHttpUrl(link), target);
    }

    public static mailTo(mail: string): void {
        document.location.href = 'mailto:' + mail;
    }

    public static test(url: string): boolean {
        return this.regex.test(url);
    }

    public static getProtocol(): string {
        return location.protocol;
    }

    public static getHost(): string {
        return location.host;
    }

    public static getHostname(): string {
        return location.hostname;
    }

    public static getDomain(): string {
        if (`${UrlHelper.getHost()}/` === environment.envVar.APP_URL.replace('https://', '').replace('http://', '')) {
            return location.hostname.split('.').slice(1).join('.');
        } else {
            return location.hostname;
        }
    }

    public static getUrl(applicationName: string = 'app'): string {
        if (`${UrlHelper.getHost()}/` === environment.envVar.APP_URL.replace('https://', '').replace('http://', '')) {
            return environment.envVar[applicationName.toUpperCase() + '_URL'];
        } else {
            switch (applicationName) {
                case 'studio':
                case 'player':
                    return location.protocol + '//' + UrlHelper.getHostname() + '/' + applicationName;
                case 'app':
                    return location.protocol + '//' + UrlHelper.getHostname();
            }
        }
    }

    public static getProtectedUrls(): string[] {
        return [
            'app', 'studio', 'player', 'cloud', 'dash', 'api',
            'cdn', 'chat', 'blog', 'admin', 'camp', 'git',
            'lrs', 'marketplace', 'sftp', 'ftp', 'support', 'info',
            'landing', 'learn', 'smtp', 'salon', 'static', 'mail',
            'imap', 'scorm', 'showcase', 'api-reporting', 'pop3'
        ];
    }
}
