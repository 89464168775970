import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ManageTeamProvider, Pagination } from '@lighty';
import { HttpHelper } from '@helpers';

@Component({
    selector: 'msc-manage-team-aside-reassign',
    templateUrl: './reassign-manager.component.html'
})
export class ManageTeamAsideReassignComponent {
    @ViewChild('aside') aside;
    @Input() companyId: number;
    @Input() me: any;
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    public closeByUser: EventEmitter<any> = new EventEmitter();
    public pagination: Pagination;
    public availableGroups: any;
    public selectedManager: any;
    public query: string;
    public data: any;
    public loaders: any;

    constructor(private teamProvider: ManageTeamProvider) {}

    init(): void {
        this.data = {
            notify: false,
            groupId: null
        };

        this.loaders = {
            ready: false,
            loadMore: false,
            save: false
        };

        this.getAvailableGroups();
    }

    public onOpen(){
        this.init();
        this.aside.onOpen();
    }

    getAvailableGroups(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            q: this.query,
            type: 'team',
            groupId: this.me.teamOwnerId
        });

        this.teamProvider.getAvailableGroups(this.companyId, params).subscribe((data) => {
            this.availableGroups = data.groups;
            this.pagination = data.pagination;

            this.loaders.ready = true;
        });
    }

    loadMore(): void {
        this.loaders.loadMore = true;

        const params = HttpHelper.cleanParams({
            q: this.query,
            type: 'team',
            groupId: this.me.teamOwnerId,
            page: this.pagination.currentPage + 1
        });

        this.teamProvider.getAvailableGroups(this.companyId, params).subscribe((data) => {
            this.availableGroups.push(...data.groups);
            this.pagination = data.pagination;

            this.loaders.loadMore = false;
        });
    }

    selectGroup(group: any): void {
        this.data.groupId = group.id;
        this.selectedManager = group.teamManager?.account;
    }

    close(): void {
        this.onClose.emit(true);
    }

    save(): void {
        if (!this.loaders.save) {
            this.loaders.save = true;
            const params = {
                groupId: this.data.groupId,
                notify: this.data.notify
            };

            this.onSave.emit(params);
        }
    }
}