import { Component, ViewChild, ElementRef, Input, EmbeddedViewRef, QueryList, ContentChildren, TemplateRef, Output, EventEmitter } from '@angular/core';
import { CommonOverlayDirection } from '../overlay/overlay.component';

export interface CommonMenuItem {
    label: string,
    iconLeft?: string,
    iconRight?: string,
    callback?: CallableFunction,
    routerLink?: Array<string>,
    queryParams?: any
}

@Component({
    selector: 'msc-common-overlay-menu',
    templateUrl: './overlay-menu.component.html',
    styleUrls: ['./overlay-menu.component.scss'],
})

export class CommonOverlayMenuComponent {
    @ContentChildren('menuTemplate') menuTemplate: QueryList<TemplateRef<any>>;
    @Input() menuItems: Array<CommonMenuItem>;
    @Input() direction: CommonOverlayDirection = "bottom-start";
    @Output() onSelection: EventEmitter<CommonMenuItem> = new EventEmitter<CommonMenuItem>();

    @ViewChild('overlay') overlay;

    get templateMenu(): TemplateRef<any> { return (this.menuTemplate?.toArray() || [])[0]; }

    /**
     * Event handler for hide
     */
    close(): void {
        this.overlay.close();
    }

    /**
     * Event handler for show
     */
    open(origin: ElementRef): EmbeddedViewRef<any> {
        return this.overlay.open(origin);
    }
}
